import {
  Button,
  Flex,
  Flyout,
  KeyValueStore,
  Text,
} from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import { Component, useEffect, useRef } from 'react'
import './ColourSelect.scss'

interface ColourSelectProps {
  selectedColour: string
  setSelectedColour: (selectedColour: string) => void
  selectableColours: KeyValueStore
}

interface ColourSelectState {
  isOpen: boolean
  activeOption?: number
}

class ColourSelect extends Component<ColourSelectProps, ColourSelectState> {
  private shouldIgnoreMouse = false
  private readonly lastColour =
    Object.keys(this.props.selectableColours).length - 1

  constructor(props: ColourSelectProps) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyboardEvents)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyboardEvents)

    if (this.shouldIgnoreMouse)
      window.removeEventListener('mousemove', this.onMouseActivity)
  }

  selectColour = (colour: string, index?: number) =>
    this.setState(
      {
        isOpen: false,
        activeOption: index,
      },
      () => {
        this.props.setSelectedColour(colour)
      }
    )

  onMouseActivity = () => {
    this.shouldIgnoreMouse = false
    window.removeEventListener('mousemove', this.onMouseActivity)
  }

  onArrowNavigation = (index: number) => {
    this.shouldIgnoreMouse = true
    document
      .querySelector(
        `.colour-option[data-id="${
          Object.keys(this.props.selectableColours)[index]
        }"]`
      )
      ?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    window.addEventListener('mousemove', this.onMouseActivity)
  }

  onArrowUp = () => {
    const activatingOption = this.state.activeOption
      ? this.state.activeOption - 1
      : this.lastColour

    this.setState({ activeOption: activatingOption })
    this.onArrowNavigation(activatingOption)
  }

  onArrowDown = () => {
    const activatingOption =
      this.state.activeOption !== undefined &&
      this.state.activeOption !== this.lastColour
        ? this.state.activeOption + 1
        : 0

    this.setState({ activeOption: activatingOption })
    this.onArrowNavigation(activatingOption)
  }

  onEnter = (e: Event) => {
    e.preventDefault()
    if (this.state.activeOption !== undefined) {
      const correspondingColour = Object.keys(this.props.selectableColours)[
        this.state.activeOption
      ]
      this.selectColour(correspondingColour)
    }
  }

  handleKeyboardEvents = (e: KeyboardEvent) => {
    if (!this.state.isOpen) return
    switch (e.key) {
      case 'ArrowUp':
        return this.onArrowUp()
      case 'ArrowDown':
        return this.onArrowDown()
      case 'Enter':
        return this.onEnter(e)
    }
  }

  activateOptionOnHover = (index?: number) => {
    if (!this.shouldIgnoreMouse) {
      this.setState({ activeOption: index })
    }
  }

  render() {
    return (
      <Flyout
        className='colour-select'
        isActive={this.state.isOpen}
        setIsActive={(isOpen: boolean) => this.setState({ isOpen })}
        trigger={
          <Button
            className='colour-select-button'
            onClick={() => this.setState({ isOpen: !this.state.isOpen })}
            variant='shell'
          >
            <div
              className='colour-swatch'
              style={{ backgroundColor: this.props.selectedColour || 'grey' }}
            />
            <Icon className='expand-icon' icon='expand' size={IconSize.Tiny} />
          </Button>
        }
        triggerIsButton
      >
        <Flex direction='vertical'>
          {Object.keys(this.props.selectableColours).map((colour, index) => (
            <ColourOption
              key={colour}
              activeOption={this.state.activeOption}
              index={index}
              colour={colour}
              name={this.props.selectableColours[colour]}
              setSelectedColour={this.props.setSelectedColour}
              isOpen={this.state.isOpen}
              onClick={() => this.selectColour(colour, index)}
              onMouseEnter={() => this.activateOptionOnHover(index)}
              onMouseLeave={() => this.activateOptionOnHover(undefined)}
            />
          ))}
        </Flex>
      </Flyout>
    )
  }
}

interface ColourOptionProps {
  setSelectedColour: (selectedColour: string) => void
  activeOption: number | undefined
  index: number
  colour: string
  name: string
  isOpen: boolean
  onClick: () => void
  onMouseEnter: () => void
  onMouseLeave: () => void
}

const ColourOption = (props: ColourOptionProps) => {
  const colourOptionRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (props.isOpen) {
      colourOptionRef.current?.addEventListener('mouseover', () =>
        props.onMouseEnter()
      )
      colourOptionRef.current?.addEventListener('mouseleave', () =>
        props.onMouseLeave()
      )
    }

    return () => {
      colourOptionRef.current?.removeEventListener('mouseover', () =>
        props.onMouseEnter()
      )
      colourOptionRef.current?.removeEventListener('mouseleave', () =>
        props.onMouseLeave()
      )
    }
  }, [props.isOpen])

  return (
    <div
      className={classNames('colour-option ui-button shell', {
        active: props.activeOption === props.index,
      })}
      role='button'
      onClick={() => props.onClick()}
      onKeyDown={(e) => {
        if (e.key === 'Enter') props.onClick()
      }}
      data-id={props.colour}
      ref={colourOptionRef}
      tabIndex={0}
    >
      <Flex verticalAlignment='baseline'>
        <div className='swatch' style={{ backgroundColor: props.colour }} />
        <Text className='name'>{props.name}</Text>
      </Flex>
    </div>
  )
}

export default ColourSelect
