import { FinancialHealthAssessment } from './FinancialHealthAssessment'
import FinancialHealthContext from './FinancialHealthContext'

abstract class FinancialHealthMetric {
  abstract readonly title: string
  abstract readonly description?: string

  protected readonly context: FinancialHealthContext

  constructor(context: FinancialHealthContext) {
    this.context = context
  }

  isRelevant = () => true

  abstract getAssessment: () => FinancialHealthAssessment
  abstract getStatusText: () => string

  getActualValue = (): string | undefined => undefined
  getTarget = (): string | undefined => undefined

  static assessmentFromStatus = (
    status?: string
  ): FinancialHealthAssessment => {
    switch (status) {
      case 'Sehr gut':
      case 'Gut':
      case 'Erfüllt':
        return FinancialHealthAssessment.Positive
      case 'i.O.':
        return FinancialHealthAssessment.Acceptable
      case 'Nicht erfüllt':
      case 'Kritisch':
      default:
        return FinancialHealthAssessment.Negative
    }
  }

  assessmentClassName = () => {
    const assessment = this.getAssessment()
    switch (assessment) {
      case FinancialHealthAssessment.Positive:
        return 'positive'
      case FinancialHealthAssessment.Acceptable:
        return 'acceptable'
      case FinancialHealthAssessment.Negative:
        return 'negative'
      default:
        return 'unknown'
    }
  }
}

export default FinancialHealthMetric
