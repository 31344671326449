import { Flex, Heading } from '@nextbusiness/infinity-ui'
import { HeadingProps } from '@nextbusiness/infinity-ui/dist/layout/heading/Heading'
import React from 'react'
import './MagicSheetTitleBar.scss'

interface MagicSheetTitleBarProps {
  className?: string
  title?: React.ReactNode
  children?: React.ReactNode
  headingProps?: Partial<HeadingProps>
}

const MagicSheetTitleBar = (props: MagicSheetTitleBarProps) => (
  <Flex
    className={
      'magic-sheet-title-bar' + (props.className ? ' ' + props.className : '')
    }
    verticalAlignment='center'
  >
    <Heading type='h2' divider {...props.headingProps}>
      {props.title}
    </Heading>
    <div style={{ flexGrow: 1 }} />
    <div className='header-actions'>{props.children}</div>
  </Flex>
)

export default MagicSheetTitleBar
