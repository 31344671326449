import { Flex } from '@nextbusiness/infinity-ui'
import ICustomerInvoice from 'model/CustomerInvoice'
import DateUtilities from 'utility/DateUtilites'

interface PublicInvoiceHeaderProps {
  invoice: ICustomerInvoice
}

const PublicInvoiceHeader = (props: PublicInvoiceHeaderProps) => (
  <Flex className='invoice-header' gap={1.2} direction='vertical'>
    <div className='date'>
      {DateUtilities.humanFormat(props.invoice.openingDate)}
    </div>
    <div className='title'>{props.invoice.title}</div>
  </Flex>
)

export default PublicInvoiceHeader
