import AdvancedFilteringGraphic from 'assets/hero-imagery/advanced-filtering-upgrade.gif'
import AdvancedReportingGraphic from 'assets/hero-imagery/advanced-reporting-upgrade.png'
import AdvancedVATGraphic from 'assets/hero-imagery/advanced-vat-upgrade.png'
import CustomBackgroundGraphic from 'assets/hero-imagery/custom-background-upgrade.png'
import ProductsGraphic from 'assets/hero-imagery/products-upgrade.png'

enum SubscriptionCapability {
  AdvancedReporting,
  AdvancedVAT,
  AdvancedFiltering,
  Products,
  AdvancedTemplating,
}

export const SubscriptionCapabilities: {
  [key in SubscriptionCapability]: {
    description: string
    image: string
    shortname: string
    upgradeTo?: string
  }
} = {
  [SubscriptionCapability.AdvancedReporting]: {
    description:
      'Analysiere deine Aufwände, Erträge und Profitabilität tief ins Detail.',
    shortname: 'Erweiterte Berichte',
    image: AdvancedReportingGraphic,
  },
  [SubscriptionCapability.AdvancedVAT]: {
    description: 'Effektive Mehrwertsteuerabrechnung leicht gemacht.',
    shortname: 'Effektive MWST-Abrechnung',
    image: AdvancedVATGraphic,
  },
  [SubscriptionCapability.AdvancedFiltering]: {
    description: 'Erweiterte Filteroptionen für Transaktionen in Konten',
    shortname: 'Erweiterte Filteroptionen',
    image: AdvancedFilteringGraphic,
  },
  [SubscriptionCapability.Products]: {
    description: 'Produkte für Forderungen und Offerten',
    shortname: 'Produkte',
    image: ProductsGraphic,
  },
  [SubscriptionCapability.AdvancedTemplating]: {
    description: 'Erweiterte Designoptionen für Forderungen und Offerten',
    shortname: 'Erweiterte Designoptionen',
    image: CustomBackgroundGraphic,
  },
}

export default SubscriptionCapability
