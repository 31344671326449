import { APIError, Finance } from '@nextbusiness/infinity-finance-api'
import {
  Button,
  DatePicker,
  Dialog,
  FeedbackBanner,
  Flex,
  MoneyField,
  RadioGroup,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import AccountSelect from 'ledger/accounts/account-select/AccountSelect'
import { ResolvedSetOfSuggestedAccounts } from 'ledger/accounts/data/AccountRules'
import { ErrorType, isBackendError } from 'libs/networking/Errors'
import { observer } from 'mobx-react'
import ICustomerInvoice, {
  ICustomerInvoicePayment,
} from 'model/CustomerInvoice'
import { IVendorInvoice, IVendorInvoicePayment } from 'model/VendorInvoice'
import VendorInvoices from 'networking/VendorInvoices'
import { useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import DateUtilities from 'utility/DateUtilites'
import { generateRandomId } from 'utility/StringUtilities'
import Utilities from 'utility/Utilities'
import './PayInvoiceModal.scss'

interface PayInvoiceModalProps {
  type: 'vendor invoice' | 'customer invoice'
  invoice: IVendorInvoice | ICustomerInvoice
  isOpen: boolean
  onDismiss: () => void
  onSaved: () => void
  isCustomerInvoiceDiscount?: boolean
}

type PaymentMode = 'full' | 'part'

interface PartialPayment {
  internalId: string
  date: number
  contraAccount: number
  amount: number
}

export const ROUNDING_DIFFERENCE_SYSTEM_ACCOUNT_NUMBER = 6945

const paymentAccounts: ResolvedSetOfSuggestedAccounts = [
  { title: 'Flüssige Mittel', accounts: [[1000, 1059]] },
  {
    title: 'Rundungsdifferenzen',
    accounts: [ROUNDING_DIFFERENCE_SYSTEM_ACCOUNT_NUMBER],
  },
]

const isPartialPaymentComplete = (partialPayment: Partial<PartialPayment>) =>
  !!partialPayment.amount &&
  !!partialPayment.contraAccount &&
  !!partialPayment.date

const sumOfPartialPayments = (partialPayments: Partial<PartialPayment>[]) => {
  let sum = 0
  partialPayments.forEach((payment) => {
    sum += payment.amount ?? 0
  })
  return sum
}

const containsIncompletePartialPayments = (
  partialPayments: Partial<PartialPayment>[]
) => {
  let isComplete = true
  partialPayments.forEach((partialPayment) => {
    if (!isPartialPaymentComplete(partialPayment)) isComplete = false
  })
  return !isComplete
}

const PayInvoiceModal = (props: PayInvoiceModalProps) => {
  const { authenticationStore, preferencesStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [selectedPaymentMode, setSelectedPaymentMode] = useState<PaymentMode>(
    props.isCustomerInvoiceDiscount ? 'part' : 'full'
  )
  const [isSavingPayment, setIsSavingPayment] = useState<boolean>(false)

  const [fullPaymentDate, setFullPaymentDate] = useState<Date>()
  const [fullPaymentContraAccount, setFullPaymentContraAccount] =
    useState<number>()

  const [partialPayments, setPartialPayments] = useState<
    Partial<PartialPayment>[]
  >([])

  const isAllowedToSubmitFullPayment =
    !!fullPaymentDate && !!fullPaymentContraAccount

  const maximumPayment = Math.max(0, props.invoice.totalRestAmount)
  const minimumPayment = Math.min(0, props.invoice.totalRestAmount)

  const doesSumOfPaymentsExceedUnpaidInvoiceAmount =
    sumOfPartialPayments(partialPayments) > maximumPayment ||
    sumOfPartialPayments(partialPayments) < minimumPayment

  const isInvalidAmount =
    props.invoice.totalRestAmount >= 0 &&
    sumOfPartialPayments(partialPayments) < 0

  const isAllowedToSubmitPartialPayments =
    !containsIncompletePartialPayments(partialPayments) &&
    !doesSumOfPaymentsExceedUnpaidInvoiceAmount

  const isAllowedToSubmitPayment =
    selectedPaymentMode === 'full'
      ? isAllowedToSubmitFullPayment
      : isAllowedToSubmitPartialPayments

  useEffect(() => {
    if (props.isOpen) {
      setFullPaymentDate(DateUtilities.startOfDay(new Date()))
      setFullPaymentContraAccount(
        props.isCustomerInvoiceDiscount ? 3800 : undefined
      )
      setPartialPayments([
        {
          internalId: generateRandomId(),
          contraAccount: props.isCustomerInvoiceDiscount ? 3800 : undefined,
        },
      ])
    }
  }, [props.isOpen])

  const commitPayment = async () => {
    setIsSavingPayment(true)
    try {
      const payments: IVendorInvoicePayment[] | ICustomerInvoicePayment[] =
        selectedPaymentMode === 'full'
          ? [
              {
                amount: props.invoice.totalRestAmount,
                contraAccount: fullPaymentContraAccount!,
                date: +fullPaymentDate!,
              },
            ]
          : (partialPayments as
              | IVendorInvoicePayment[]
              | ICustomerInvoicePayment[])

      if (props.type === 'vendor invoice') {
        await Promise.all(
          (payments as IVendorInvoicePayment[]).map((payment) =>
            VendorInvoices.payVendorInvoice(
              authenticationStore.organisationIdentifier,
              props.invoice.id,
              payment
            )
          )
        )
        MixpanelAnalytics.event('Vendor invoice payment recorded')
      } else {
        await Promise.all(
          (payments as ICustomerInvoicePayment[]).map((payment) =>
            Finance.CustomerInvoice.payInvoice(props.invoice.id, {
              ...payment,
              contraAccount: props.isCustomerInvoiceDiscount
                ? 3800
                : payment.contraAccount,
            })
          )
        )
        MixpanelAnalytics.event('Customer invoice payment recorded')
      }

      props.onSaved()
      props.onDismiss()
    } catch (error: any) {
      let reason = error.message
      if (isBackendError(error) && error.type === ErrorType.NotAllowed) {
        reason = preferencesStore.organisationPreferences?.VAT
          ? 'Bitte prüfe, ob das gewählte Zahlungsdatum in einem offenen Geschäftsjahr und einer offenen MWST-Periode liegt.'
          : 'Bitte prüfe, ob das gewählte Zahlungsdatum in einem offenen Geschäftsjahr liegt.'
      }
      if (error instanceof APIError) {
        reason = error.humanText()
      }
      notificationCenter.addNotification({
        variant: 'error',
        title:
          selectedPaymentMode === 'full'
            ? 'Die Zahlung konnte nicht verbucht werden.'
            : 'Eine oder mehrere Teilzahlungen konnten nicht verbucht werden. Lade die Seite neu und versuche es erneut.',
        children: reason,
      })
    } finally {
      setIsSavingPayment(false)
    }
  }

  const amountQuestionCopy = () =>
    props.isCustomerInvoiceDiscount
      ? 'Welcher Betrag wird nachgelassen?'
      : 'Welcher Betrag wurde bezahlt?'

  return (
    <Dialog
      className='pay-invoice-modal'
      title={
        props.isCustomerInvoiceDiscount
          ? 'Nachlass verbuchen'
          : 'Zahlung verbuchen'
      }
      titleProps={{
        divider: true,
      }}
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      actions={[
        {
          children: 'Abbrechen',
          disabled: isSavingPayment,
          onClick: () => props.onDismiss(),
        },
        {
          children: props.isCustomerInvoiceDiscount
            ? 'Nachlass verbuchen'
            : 'Zahlung verbuchen',
          isLoading: isSavingPayment,
          onClick: () => commitPayment(),
          disabled: !isAllowedToSubmitPayment,
          variant: 'primary',
        },
      ]}
    >
      <Text>{amountQuestionCopy()}</Text>
      <RadioGroup
        value={selectedPaymentMode}
        onChange={(selectedMode) =>
          setSelectedPaymentMode(selectedMode as PaymentMode)
        }
        options={[
          {
            label: `Gesamter offener Betrag (${Utilities.centsToCHF(
              props.invoice.totalRestAmount
            )} CHF)`,
            value: 'full',
          },
          {
            label: props.isCustomerInvoiceDiscount
              ? 'Teilnachlass'
              : 'Teilzahlung',
            value: 'part',
          },
        ]}
      />
      {selectedPaymentMode === 'full' ? (
        <div className='payment-details'>
          <Flex gap='tiny'>
            <AccountSelect
              onlyAllowSuggestedAccounts
              initialAccountNumber={
                props.isCustomerInvoiceDiscount ? 3800 : undefined
              }
              suggestions={paymentAccounts}
              currentAccountNumber={fullPaymentContraAccount}
              onChange={(selectedAccountNumber) =>
                setFullPaymentContraAccount(selectedAccountNumber)
              }
              inputFieldProps={{
                placeholder: 'Zahlungsmittel wählen',
              }}
            />
            <DatePicker
              value={fullPaymentDate}
              onChange={(date) => setFullPaymentDate(date)}
              placeholder={
                props.isCustomerInvoiceDiscount
                  ? 'Nachlassdatum'
                  : 'Zahlungsdatum'
              }
            />
          </Flex>
        </div>
      ) : (
        <div className='payment-details'>
          {partialPayments.map((partialPayment, index) => {
            const updatePartialPayment = (changes: Partial<PartialPayment>) => {
              const updatedPayment = { ...partialPayment, ...changes }
              const updatedPayments = [...partialPayments]

              updatedPayments[index] = updatedPayment
              setPartialPayments(updatedPayments)
            }
            const isDeletable = partialPayments.length > 1

            return (
              <Flex
                className='payment-row'
                gap='tiny'
                key={partialPayment.internalId}
                verticalAlignment='center'
              >
                <MoneyField
                  value={
                    partialPayment.amount ? partialPayment.amount / 100 : null
                  }
                  onChange={(amount) =>
                    updatePartialPayment({
                      amount: amount ? amount * 100 : undefined,
                    })
                  }
                />
                <AccountSelect
                  onlyAllowSuggestedAccounts
                  initialAccountNumber={partialPayment.contraAccount}
                  suggestions={paymentAccounts}
                  currentAccountNumber={partialPayment.contraAccount}
                  onChange={(contraAccount) =>
                    updatePartialPayment({ contraAccount })
                  }
                  inputFieldProps={{
                    placeholder: 'Zahlungsmittel wählen',
                  }}
                />
                <DatePicker
                  value={
                    typeof partialPayment.date === 'number'
                      ? new Date(partialPayment.date)
                      : undefined
                  }
                  onChange={(date) =>
                    updatePartialPayment({ date: date ? +date : undefined })
                  }
                  placeholder={
                    props.isCustomerInvoiceDiscount
                      ? 'Nachlassdatum'
                      : 'Zahlungsdatum'
                  }
                />
                {isDeletable && (
                  <Button
                    className='delete-payment-button'
                    iconOnly='delete'
                    variant='quaternary'
                    onClick={() => {
                      const updatedPayments = [...partialPayments]
                      updatedPayments.splice(index, 1)

                      setPartialPayments(updatedPayments)
                    }}
                    tooltip='Teilzahlung löschen'
                  />
                )}
              </Flex>
            )
          })}
          {!props.isCustomerInvoiceDiscount && (
            <Button
              className='add-payment-button'
              variant='quaternary'
              iconLeft='plus'
              onClick={() =>
                setPartialPayments([
                  ...partialPayments,
                  {
                    internalId: generateRandomId(),
                  },
                ])
              }
            >
              Teilzahlung hinzufügen
            </Button>
          )}
          {isInvalidAmount ? (
            <FeedbackBanner variant='warning'>
              Bitte gib einen positiven Betrag ein.
            </FeedbackBanner>
          ) : doesSumOfPaymentsExceedUnpaidInvoiceAmount ? (
            <FeedbackBanner variant='warning'>
              Die Summe der eingegebenen Teilzahlungen (
              {Utilities.centsToCHF(sumOfPartialPayments(partialPayments))} CHF)
              ist grösser als der noch offene Betrag der Rechnung (
              {Utilities.centsToCHF(props.invoice.totalRestAmount)} CHF).
            </FeedbackBanner>
          ) : null}
        </div>
      )}
    </Dialog>
  )
}

export default observer(PayInvoiceModal)
