import { useContext, useEffect, useRef } from 'react'
import EditableDocumentText from '../controls/EditableDocumentText'
import DocumentRendererContext from '../DocumentRendererContext'
import DocumentElementProps from './DocumentElementProps'

const DocumentElementClosingText = (props: DocumentElementProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const renderContext = useContext(DocumentRendererContext)

  useEffect(() => {
    renderContext.updateHeights({
      closingText: ref.current?.clientHeight || 0,
    })
  }, [props.document])

  return props.document.closingText !== null ? (
    <div className='page-end-text' ref={ref}>
      <EditableDocumentText
        onChange={(closingText) => props.registerEdit({ closingText })}
        isReadOnly={!props.isEditable}
        value={props.document.closingText ?? ''}
        placeholder='Schlusstext hier eingeben'
        isMultiline
      />
    </div>
  ) : null
}

export default DocumentElementClosingText
