import { Flex } from '@nextbusiness/infinity-ui'
import { CustomerInvoiceEditorSidebarProps } from '../CustomerInvoiceEditorSidebar'
import EditorAutosaveStatus from './EditorAutosaveStatus'
import EditorDeleteAction from './EditorDeleteAction'
import EditorDiscardAction from './EditorDiscardAction'
import EditorDoneAction from './EditorDoneAction'
import EditorEditModeAction from './EditorEditModeAction'
import EditorMoreAction from './EditorMoreAction'
import EditorPDFAction from './EditorPDFAction'
import EditorSaveAction from './EditorSaveAction'

export interface CustomerInvoiceEditorSidebarTopActionsProps
  extends CustomerInvoiceEditorSidebarProps {
  showSaveValidationMessage: () => void
  clearSaveValidationMessage: () => void
  discardChanges: () => void
}

const CustomerInvoiceEditorSidebarTopActions = (
  props: CustomerInvoiceEditorSidebarTopActionsProps
) => {
  const isInEditMode = !!props.hasChanges || !props.isExistingDocument
  return (
    <Flex horizontalAlignment='flex-end' className='document-actions'>
      {isInEditMode ? (
        <>
          {props.document.isDraft ? (
            <EditorAutosaveStatus {...props} />
          ) : (
            <EditorDiscardAction {...props} />
          )}
          <EditorSaveAction {...props} />
        </>
      ) : (
        <>
          <EditorDoneAction />
          <div style={{ flexGrow: 1 }} />
          {props.document.isDraft ? (
            <EditorDeleteAction {...props} />
          ) : (
            <EditorMoreAction {...props} />
          )}
          <EditorPDFAction {...props} />
          <EditorEditModeAction {...props} />
        </>
      )}
    </Flex>
  )
}

export default CustomerInvoiceEditorSidebarTopActions
