import { ICompleteDocument } from '@nextbusiness/infinity-finance-api'
import { Button, Spacer } from '@nextbusiness/infinity-ui'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import CreateExpenditureViewCreateAction from './CreateExpenditureViewCreateAction'

interface CreateExpenditureHeaderProps {
  document: ICompleteDocument | undefined
  onDismiss: () => void
}

const CreateExpenditureHeader = (props: CreateExpenditureHeaderProps) => {
  return (
    <MagicSheetTitleBar title='Neuer Aufwand'>
      <Button className='expenditure-dismiss-button' onClick={props.onDismiss}>
        Abbrechen
      </Button>
      <Spacer size='tiny' direction='horizontal' />
      {props.document && (
        <CreateExpenditureViewCreateAction
          document={props.document.document}
          onDismiss={props.onDismiss}
        />
      )}
    </MagicSheetTitleBar>
  )
}

export default CreateExpenditureHeader
