import {
  APIError,
  BankingTransaction,
  Finance,
  TransactionType,
} from '@nextbusiness/infinity-finance-api'
import {
  LoadingSpinner,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { AnimatePresence, motion } from 'framer-motion'
import { useAccountSheetContext } from 'ledger/accounts/AccountSheetContext'
import { useState } from 'react'
import { useTransactionEditorContext } from '../../TransactionEditorContext'
import CustomerInvoiceSelect from './CustomerInvoiceSelect'
import './TransactionManualReconciliationPicker.scss'
import VendorInvoiceSelect from './VendorInvoiceSelect'

interface TransactionManualReconciliationPickerProps {
  details: BankingTransaction
}

const TransactionManualReconciliationPicker = (
  props: TransactionManualReconciliationPickerProps
) => {
  const { originalTransaction } = useTransactionEditorContext()
  const { replaceTransactions } = useAccountSheetContext()

  const [isReconciling, setIsReconciling] = useState<boolean>(false)
  const notificationCenter = useNotificationCenter()

  const isIncomingPayment =
    props.details.transactionType === TransactionType.CREDIT

  const reconcile = async (
    reconciliation: { vendorInvoiceId: string } | { customerInvoiceId: string }
  ) => {
    setIsReconciling(true)
    try {
      const updatedTransactions =
        await Finance.Ledger.manuallyReconcileTransaction(
          originalTransaction.id,
          reconciliation
        )
      replaceTransactions([originalTransaction], updatedTransactions)
    } catch (error) {
      notificationCenter.addNotification({
        variant: 'error',
        children: (error as APIError).humanText('de'),
      })
    } finally {
      setIsReconciling(false)
    }
  }

  const reconcileVendorInvoice = (vendorInvoiceId: string | undefined) => {
    if (vendorInvoiceId) reconcile({ vendorInvoiceId })
  }
  const reconcileCustomerInvoice = (customerInvoiceId: string | undefined) => {
    if (customerInvoiceId) reconcile({ customerInvoiceId })
  }

  return (
    <motion.div
      className='reconciliation-picker'
      key='manual-reconciliation-content'
      initial={{ opacity: 0, y: 40, filter: 'blur(10px)' }}
      animate={{ opacity: 1, y: 0, filter: 'blur(0)' }}
      transition={{
        duration: 0.35,
        ease: [0.22, 1, 0.36, 1],
        delay: 0.1,
      }}
    >
      <AnimatePresence>
        {isIncomingPayment ? (
          <CustomerInvoiceSelect
            onCustomerInvoiceSelected={reconcileCustomerInvoice}
            filter={{ maxAmount: originalTransaction.amount }}
          />
        ) : (
          <VendorInvoiceSelect
            onVendorInvoiceSelected={reconcileVendorInvoice}
            filter={{ maxOpenAmount: originalTransaction.amount }}
          />
        )}
        {isReconciling && <LoadingSpinner />}
      </AnimatePresence>
    </motion.div>
  )
}

export default TransactionManualReconciliationPicker
