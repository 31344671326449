import React, { useEffect, useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { generateRandomId } from 'utility/StringUtilities'

interface DocumentPositionsDropZoneProps {
  children?: React.ReactNode
}

const DocumentPositionsDropZone = (props: DocumentPositionsDropZoneProps) => {
  const [droppableId, setDroppableId] = useState<string>('list')

  useEffect(() => {
    const id = generateRandomId()
    setDroppableId(id)
  }, [])

  return (
    <Droppable droppableId={droppableId}>
      {(provided) => (
        <div
          className='positions-table-body'
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {props.children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default DocumentPositionsDropZone
