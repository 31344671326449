import {
  AccountCardProperties,
  AccountCardTimeframe,
  FinancialHealthCardProperties,
} from '@nextbusiness/infinity-finance-api'
import { Checkbox, Flex, Option, Select, Text } from '@nextbusiness/infinity-ui'
import AccountSelect from 'ledger/accounts/account-select/AccountSelect'
import { AccountCardGranularity } from '../cards/account/charts/AccountCardChartConfiguration'
import './AccountCardEditFields.scss'

interface AccountCardEditFieldsProps {
  properties: AccountCardProperties
  editCard: (properties: Partial<FinancialHealthCardProperties>) => void
}

const CHART_TYPE_OPTIONS = [
  { label: 'Saldoverlauf', value: 'balance' },
  { label: 'Veränderung', value: 'change' },
]
const TIMEFRAME_OPTIONS = [
  { label: 'Letzten Monat', value: 'last-month' },
  { label: 'Letzte 3 Monate', value: 'last-3-months' },
  { label: 'Letztes Jahr', value: 'last-year' },
  { label: 'Laufendes Geschäftsjahr', value: 'fiscal-year-to-date' },
]
const GRANULARITY_OPTIONS = [
  { label: 'Täglich', value: 'day' },
  { label: 'Wöchentlich', value: 'week' },
  { label: 'Monatlich', value: 'month' },
  { label: 'Quartale', value: 'quarter' },
]

const optionFromValue = (
  value: string,
  options: Option[]
): Option | undefined => options.find((option) => option.value === value)

const AccountCardEditFields = (props: AccountCardEditFieldsProps) => {
  const { accountNumber, chartType, timeframe, granularity } = props.properties

  return (
    <Flex
      className='account-card-edit-fields'
      direction='vertical'
      horizontalAlignment='center'
      gap={2.4}
    >
      <div className='account-card-edit-fields-grid'>
        <div>
          <Text>Konto</Text>
          <AccountSelect
            className='card-editor-account-select'
            currentAccountNumber={accountNumber}
            initialAccountNumber={accountNumber}
            suggestions={[]}
            onChange={(accountNumber) => {
              if (accountNumber) props.editCard({ accountNumber })
            }}
          />
        </div>
        <div>
          <Text>Anzeigen</Text>
          <Select
            options={CHART_TYPE_OPTIONS}
            placeholder='Darstellung wählen'
            onChange={(option) =>
              props.editCard({
                chartType: option.value as 'balance' | 'change',
              })
            }
            value={optionFromValue(chartType, CHART_TYPE_OPTIONS)}
          />
        </div>
        <div>
          <Text>Zeitraum</Text>
          <Select
            options={TIMEFRAME_OPTIONS}
            placeholder='Zeitraum wählen'
            onChange={(option) =>
              props.editCard({
                timeframe: option.value as AccountCardTimeframe,
              })
            }
            value={optionFromValue(timeframe, TIMEFRAME_OPTIONS)}
          />
        </div>
        <div>
          <Text>Granularität</Text>
          <Select
            options={GRANULARITY_OPTIONS}
            placeholder='Art wählen'
            onChange={(option) =>
              props.editCard({
                granularity: option.value as AccountCardGranularity,
              })
            }
            value={optionFromValue(granularity, GRANULARITY_OPTIONS)}
          />
        </div>
      </div>
      <Checkbox
        label='Vorjahresvergleich'
        isChecked={props.properties.showYoYComparison}
        onChange={(showYoYComparison) => props.editCard({ showYoYComparison })}
      />
    </Flex>
  )
}

export default AccountCardEditFields
