import { Text } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React from 'react'
import { useRootStore } from 'stores/RootStoreContext'

interface ExtendedInvoiceListItemContactProps {
  contactId?: string
}

const ExtendedInvoiceListItemContact = (
  props: ExtendedInvoiceListItemContactProps
) => {
  const { contactStore } = useRootStore()

  return (
    <Text className='creditor' type='inline'>
      {contactStore.displayNameForContactWithId(props.contactId)}
    </Text>
  )
}

export default observer(ExtendedInvoiceListItemContact)
