import { RadialGaugeFillLevel } from '../../../components/gauges/RadialGauge'
import FinancialHealthContext from './FinancialHealthContext'
import FinancialHealthStatusGroup, {
  FinancialHealthGroupStatus,
} from './FinancialHealthStatusGroup'
import StatusGroupMaturities from './groups/StatusGroupMaturities'
import StatusGroupProfitability from './groups/StatusGroupProfitability'
import StatusGroupSolvency from './groups/StatusGroupSolvency'

export enum FinancialHealthSummaryStatus {
  Perfect,
  Good,
  Okay,
  Critical,
  VeryCritical,
}

class FinancialHealthStatus {
  private readonly context: FinancialHealthContext
  public metricGroups: FinancialHealthStatusGroup[] = []

  constructor(context: FinancialHealthContext) {
    this.context = context
    this.setupMetricGroups()
  }

  private setupMetricGroups() {
    if (this.context.financialSolvency)
      this.metricGroups.push(new StatusGroupSolvency(this.context))

    if (this.context.maturities)
      this.metricGroups.push(new StatusGroupMaturities(this.context))

    if (this.context.profitability)
      this.metricGroups.push(new StatusGroupProfitability(this.context))
  }

  private static downgradedStatusFrom = (
    status: FinancialHealthSummaryStatus
  ): FinancialHealthSummaryStatus => {
    switch (status) {
      case FinancialHealthSummaryStatus.Perfect:
        return FinancialHealthSummaryStatus.Good
      case FinancialHealthSummaryStatus.Good:
        return FinancialHealthSummaryStatus.Okay
      case FinancialHealthSummaryStatus.Okay:
        return FinancialHealthSummaryStatus.Critical
      case FinancialHealthSummaryStatus.Critical:
        return FinancialHealthSummaryStatus.VeryCritical
      default:
        return status
    }
  }

  get summaryStatusGauge(): RadialGaugeFillLevel {
    const status = this.getSummaryStatus()
    switch (status) {
      case FinancialHealthSummaryStatus.Perfect:
        return '100%'
      case FinancialHealthSummaryStatus.Good:
        return '75%'
      case FinancialHealthSummaryStatus.Okay:
        return '50%'
      case FinancialHealthSummaryStatus.Critical:
        return '25%'
      case FinancialHealthSummaryStatus.VeryCritical:
      default:
        return '0%'
    }
  }

  get summaryStatusLabel(): string {
    const status = this.getSummaryStatus()
    switch (status) {
      case FinancialHealthSummaryStatus.Perfect:
        return 'Sehr gut'
      case FinancialHealthSummaryStatus.Good:
        return 'Gut'
      case FinancialHealthSummaryStatus.Okay:
        return 'In Ordnung'
      case FinancialHealthSummaryStatus.Critical:
        return 'Schlecht'
      case FinancialHealthSummaryStatus.VeryCritical:
      default:
        return 'Kritisch'
    }
  }

  get hasFullyLoaded(): boolean {
    return (
      !!this.context.financialSolvency &&
      !!this.context.maturities &&
      !!this.context.profitability
    )
  }

  get isEmpty(): boolean {
    return (
      this.context.financialSolvency?.sumOfLiquidAssets === 0 &&
      this.context.financialSolvency.balanceOfAccount2000 === 0 &&
      this.context.fiscalYear?.year === 0
    )
  }

  getSummaryStatus = (): FinancialHealthSummaryStatus => {
    let summaryStatus: FinancialHealthSummaryStatus =
      FinancialHealthSummaryStatus.Perfect

    if (this.isEmpty) return summaryStatus

    const groupStatus = this.metricGroups.map((metricGroup) =>
      metricGroup.getOverallStatus()
    )
    const numberOfNegatives = groupStatus.filter(
      (status) => status === FinancialHealthGroupStatus.Critical
    ).length
    const numberOfAcceptables = groupStatus.filter(
      (status) => status === FinancialHealthGroupStatus.Warnings
    ).length

    if (numberOfNegatives > 0) {
      for (let i = 0; i < numberOfNegatives; i++) {
        if (summaryStatus === FinancialHealthSummaryStatus.Perfect) {
          summaryStatus = FinancialHealthSummaryStatus.Okay
        } else {
          summaryStatus =
            FinancialHealthStatus.downgradedStatusFrom(summaryStatus)
        }
      }
    } else {
      for (let i = 0; i < numberOfAcceptables; i++) {
        if (summaryStatus !== FinancialHealthSummaryStatus.Okay) {
          summaryStatus =
            FinancialHealthStatus.downgradedStatusFrom(summaryStatus)
        }
      }
    }

    return summaryStatus
  }
}

export default FinancialHealthStatus
