import {
  Finance,
  IOrganisation,
  VATCalculationMode,
} from '@nextbusiness/infinity-finance-api'
import { action, computed, makeObservable, observable } from 'mobx'
import { persist } from 'mobx-persist'
import RootStore from './RootStore'
import Store from './Store'

class PreferencesStore extends Store {
  @persist('object') @observable organisationPreferences: IOrganisation | null =
    null

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  @action
  public reset() {
    this.organisationPreferences = null
  }

  public async loadPreferences() {
    this.setPreferences(await Finance.Organisation.current())
  }

  @action
  public setPreferences = (preferences: IOrganisation) => {
    this.organisationPreferences = preferences
  }

  @computed
  public get showAccountNumbers() {
    return this.organisationPreferences?.features?.includes('account-numbers')
  }

  @computed
  public get isUsingEffectiveVAT() {
    return Boolean(
      this.organisationPreferences?.VAT &&
        this.organisationPreferences.vatCalculationMode ===
          VATCalculationMode.Effective
    )
  }

  @computed
  public get betaFeatures() {
    const features = this.organisationPreferences?.metadata?.betaFeatures ?? []
    return features as string[]
  }

  @computed
  public get shouldDisplayVATTransactions() {
    return Boolean(
      this.organisationPreferences?.metadata?.shouldDisplayVATTransactions
    )
  }

  public shouldShowTransactionIndicators() {
    // The ledger does not re-render properly when this is a computed property.
    return Boolean(
      this.organisationPreferences?.metadata?.shouldShowTransactionIndicators
    )
  }

  public isUsingBetaFeature(feature: string) {
    return this.betaFeatures.includes(feature)
  }

  public get seenCoachmarks(): string[] {
    return (
      (this.organisationPreferences?.metadata?.seenCoachmarks as string[]) ?? []
    )
  }

  public hasSeenCoachmark(coachmarkId: string) {
    return this.seenCoachmarks.includes(coachmarkId)
  }

  public async markCoachmarkAsSeen(coachmarkId: string) {
    try {
      const seenCoachmarks = [...this.seenCoachmarks, coachmarkId]
      await this.setFlags({ seenCoachmarks })
    } catch {
      // Silently fail
    }
  }

  public async setFlags(flags: Record<string, unknown>) {
    const updatedOrganisation = await Finance.Organisation.setFlags(flags)
    this.setPreferences(updatedOrganisation)
  }
}

export default PreferencesStore
