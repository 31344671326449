import { Card, Flex } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import DocumentIcon from 'components/document-icon/DocumentIcon'
import { observer } from 'mobx-react'
import IDocument from 'model/Document'
import './DocumentCard.scss'
import DocumentCardActions from './DocumentCardActions'
import DocumentCardDepiction from './DocumentCardDepiction'

interface DocumentCardProps {
  document: IDocument
  numberOfCardsOnBackStack?: number
  previewDocument: () => void
  createExpenditure: () => void
}

const DocumentCard = (props: DocumentCardProps) => (
  <Flex
    direction='vertical'
    verticalAlignment='center'
    horizontalAlignment='center'
  >
    <Card
      className={classNames('inbox-stack-card top-card', {
        'no-card-behind': !props.numberOfCardsOnBackStack,
      })}
    >
      <Flex className='main-content' gap='small'>
        <DocumentIcon
          thumbnailURL={props.document.thumbnail}
          onPreview={props.previewDocument}
        />
        <DocumentCardDepiction
          document={props.document}
          actions={
            <DocumentCardActions
              document={props.document}
              previewDocument={props.previewDocument}
              createExpenditure={props.createExpenditure}
            />
          }
        />
      </Flex>
    </Card>
    {!!props.numberOfCardsOnBackStack && (
      <>
        {props.numberOfCardsOnBackStack > 0 && (
          <Card className='inbox-stack-card behind' />
        )}
        {props.numberOfCardsOnBackStack > 1 && (
          <Card className='inbox-stack-card behind' />
        )}
      </>
    )}
  </Flex>
)

export default observer(DocumentCard)
