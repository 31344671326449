import { FeedbackBanner } from '@nextbusiness/infinity-ui'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

interface LiveAccountingWarning {
  title: string
  description: string
  validity?: {
    from: string
    to: string
  }
  variant?: 'info' | 'warning'
}

interface LiveAccountingWarnings {
  activeWarnings?: LiveAccountingWarning[]
}

const LiveAccountingSetupWarnings = () => {
  const { data } = useQuery<LiveAccountingWarnings>({
    queryKey: ['live-accounting-warnings'],
    queryFn: async () => {
      const response = await fetch('/data/live-accounting-warnings.json')
      return response.json()
    },
  })
  const visibleWarnings = useMemo(
    () =>
      data?.activeWarnings?.filter((warning) => {
        if (!warning.validity) return true

        const now = new Date()
        const from = new Date(warning.validity.from)
        const to = new Date(warning.validity.to)

        return now >= from && now <= to
      }),
    [data]
  )
  if (!visibleWarnings) return null

  return (
    <div className='warnings'>
      {visibleWarnings.map((warning, index) => (
        <FeedbackBanner
          key={index}
          title={warning.title}
          variant={warning.variant}
        >
          {warning.description}
        </FeedbackBanner>
      ))}
    </div>
  )
}

export default LiveAccountingSetupWarnings
