import './AssistantStepper.scss'

export interface AssistantStep {
  id: string
  displayTitle: string
}

interface AssistantStepperProps {
  steps: AssistantStep[]
  currentStepId?: string
}

const AssistantStepper = (props: AssistantStepperProps) => {
  const currentStepIndex = props.steps.findIndex(
    (step) => step.id === props.currentStepId
  )

  return (
    <div className='assistant-stepper'>
      {props.steps.map((step, index) => {
        let stepClass = index === currentStepIndex ? ' active' : ''
        stepClass += index < currentStepIndex ? ' completed' : ''

        return (
          <div className={`step${stepClass}`} key={step.id}>
            <div className='step-title'>{step.displayTitle}</div>
            <div className='step-bar' />
          </div>
        )
      })}
    </div>
  )
}

export default AssistantStepper
