import { useEffect } from 'react'
import { useTransactionEditorContext } from '../TransactionEditorContext'

const useEditorKeybindings = () => {
  const { closeEditor, isDraftIncomplete, isDeletionModalOpen, onSaveChanges } =
    useTransactionEditorContext()

  useEffect(() => {
    const escapeListener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') closeEditor()
      if (e.key === 'Enter') {
        const isCurrentlyInResourceSelect = (
          e.target as HTMLElement
        ).parentElement?.matches(
          '.account-select-input, .resource-select-input, .currency-select-input'
        )
        const canSave = !isDraftIncomplete

        if (canSave && !isCurrentlyInResourceSelect && !isDeletionModalOpen)
          onSaveChanges()
      }
    }
    document.addEventListener('keydown', escapeListener)
    return () => document.removeEventListener('keydown', escapeListener)
  }, [closeEditor, isDraftIncomplete, isDeletionModalOpen, onSaveChanges])
}

export default useEditorKeybindings
