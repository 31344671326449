import ExpenditureAccountSelect from './ExpenditureAccountSelect'
import { useExpenditureCreateContext } from './ExpenditureCreateContext'
import ExpenditureMoneyInputField from './ExpenditureMoneyInputField'

interface ExpenditureCreditInputFormProps {
  errorFields: (condition: boolean) => Record<string, unknown>
}

const ExpenditureCreditInputForm = (props: ExpenditureCreditInputFormProps) => {
  const expenditureCreateContext = useExpenditureCreateContext()
  return (
    <div className='expenditure-credit-input-container'>
      <div className='expenditure-flex'>
        <ExpenditureAccountSelect
          title='Zahlungsmittel'
          onChange={(creditAccount) =>
            expenditureCreateContext.updateTransaction({ creditAccount })
          }
          suggestions={[{ title: 'Flüssige Mittel', accounts: [[1000, 1059]] }]}
          currentAccountNumber={
            expenditureCreateContext.transaction.debitAccount
          }
          initialAccountNumber={
            expenditureCreateContext.transaction.creditAccount
          }
          inputFieldProps={props.errorFields(
            !expenditureCreateContext.transaction.creditAccount
          )}
        />
      </div>
      <div className='expenditure-flex'>
        <ExpenditureMoneyInputField errorFields={props.errorFields} />
      </div>
    </div>
  )
}

export default ExpenditureCreditInputForm
