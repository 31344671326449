import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export type CustomerInvoiceStatusFilter =
  | 'paid'
  | 'unpaid'
  | 'cancelled'
  | 'open'
  | 'accepted'
  | 'rejected'

interface CustomerInvoiceFilter {
  recipient?: string | null
  status?: CustomerInvoiceStatusFilter | null
  openingMonth?: string | null
}

type CustomerInvoicesFilterContextType = {
  activeFilter: CustomerInvoiceFilter
  editFilter: (changes: Partial<CustomerInvoiceFilter>) => void
  resetFilter: () => void
}

const CustomerInvoicesFilterContext =
  createContext<CustomerInvoicesFilterContextType | null>(null)

export const CustomerInvoicesFilterProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [currentFilter, setCurrentFilter] = useState<CustomerInvoiceFilter>({})
  const editFilter = useCallback(
    (changes: Partial<CustomerInvoiceFilter>) =>
      setCurrentFilter((currentFilter) => ({ ...currentFilter, ...changes })),
    []
  )
  const resetFilter = useCallback(() => setCurrentFilter({}), [])

  const contextValue = useMemo(
    () => ({ activeFilter: currentFilter, editFilter, resetFilter }),
    [currentFilter, editFilter, resetFilter]
  )

  return (
    <CustomerInvoicesFilterContext.Provider value={contextValue}>
      {children}
    </CustomerInvoicesFilterContext.Provider>
  )
}

export const useCustomerInvoicesFilter =
  (): CustomerInvoicesFilterContextType => {
    const context = useContext(CustomerInvoicesFilterContext)
    if (!context)
      throw new Error(
        'useCustomerInvoicesFilter must be used within a CustomerInvoicesFilterProvider'
      )
    return context
  }
