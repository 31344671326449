import { Finance } from '@nextbusiness/infinity-finance-api'
import { useQuery } from '@tanstack/react-query'

export const useLiveCaptureAnalysis = (uploadId: string | undefined | null) => {
  const { data, refetch } = useQuery({
    queryKey: ['attachment', uploadId],
    queryFn: async () => {
      if (!uploadId) return null
      return Finance.Documents.document(uploadId)
    },
  })
  return {
    liveCapture: {
      analyis: data?.document?.analysis,
      qrSlip: data?.document?.qrSlip,
    },
    refetch: refetch,
  }
}
