import { IOrganisation } from '@nextbusiness/infinity-finance-api'
import { store } from 'stores/RootStoreContext'
import ICustomerInvoice, {
  ICustomerInvoicePayment,
  ICustomerInvoicePreview,
  ICustomerInvoiceQueryParams,
  INewCustomerInvoice,
  QuoteStatus,
} from '../model/CustomerInvoice'
import { KeyValueStore } from '../utility/types'
import { Current, currentApplicationAccessToken } from './Authentication'
import NetworkingConstants from './NetworkingConstants'
import NetworkingErrorHandler from './NetworkingErrorHandler'

const getCustomerInvoices = async (
  organisationId: string,
  fiscalYear: number,
  queryParams?: ICustomerInvoiceQueryParams
): Promise<ICustomerInvoicePreview[]> => {
  const params = queryParams
    ? new URLSearchParams(queryParams as KeyValueStore)
    : null
  const response = await fetch(
    `${
      NetworkingConstants.HOST
    }/organisation/${organisationId}/fiscalYear/${fiscalYear}?${
      params?.toString() ?? ''
    }`,
    { method: 'GET', headers: Current.defaultHeaders }
  )

  const body = await response.json()

  if (!response.ok || !body.customerInvoices) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.customerInvoices as ICustomerInvoicePreview[]
}

const getCustomerInvoice = async (
  invoiceId: string
): Promise<ICustomerInvoice> => {
  const response = await fetch(
    `${NetworkingConstants.HOST}/customerInvoice/${invoiceId}`,
    { method: 'GET', headers: Current.defaultHeaders }
  )

  const body = await response.json()

  if (!response.ok || !body.customerInvoice) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.customerInvoice as ICustomerInvoice
}

const createCustomerInvoice = async (
  organisationIdentifier: string,
  customerInvoice: Partial<INewCustomerInvoice>,
  isDraft = false
): Promise<ICustomerInvoice> => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationIdentifier}/customerInvoice/isDraft/${isDraft}`,
    {
      method: 'POST',
      headers: Current.defaultHeaders,
      body: JSON.stringify(customerInvoice),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.customerInvoice) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.customerInvoice as ICustomerInvoice
}

const getPDFBlob = async (invoiceId: string, fabricId: string) => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/customerInvoice/${invoiceId}/export/invoice?fabricId=${fabricId}`,
    {
      method: 'POST',
      headers: Current.defaultHeaders,
      body: JSON.stringify({
        token: currentApplicationAccessToken,
      }),
    }
  )
  if (response.ok) {
    const body = await response.blob()
    return body
  } else {
    const body = await response.json()
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
}

const updateCustomerInvoice = async (
  invoiceId: string,
  changes: Partial<INewCustomerInvoice>
): Promise<ICustomerInvoice> => {
  const response = await fetch(
    `${NetworkingConstants.HOST}/customerInvoice/${invoiceId}`,
    {
      method: 'PATCH',
      headers: Current.defaultHeaders,
      body: JSON.stringify({
        ...changes,
        transactionDate: changes.openingDate,
      }),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.customerInvoice) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.customerInvoice as ICustomerInvoice
}

const annulCustomerInvoice = async (
  invoiceId: string,
  atDate: number
): Promise<ICustomerInvoice> => {
  const response = await fetch(
    `${NetworkingConstants.HOST}/organisation/${store.authenticationStore.organisationIdentifier}/customer-invoice/${invoiceId}/annulment`,
    {
      method: 'POST',
      headers: Current.defaultHeaders,
      body: JSON.stringify({ atDate }),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.customerInvoice) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.customerInvoice as ICustomerInvoice
}

const restoreAnnulledCustomerInvoice = async (
  invoiceId: string
): Promise<ICustomerInvoice> => {
  const response = await fetch(
    `${NetworkingConstants.HOST}/organisation/${store.authenticationStore.organisationIdentifier}/customer-invoice/${invoiceId}/annulment`,
    {
      method: 'DELETE',
      headers: Current.defaultHeaders,
    }
  )
  const body = await response.json()

  if (!response.ok || !body.customerInvoice) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.customerInvoice as ICustomerInvoice
}

const deleteCustomerInvoice = async (
  invoiceId: string,
  organisationId: string
) => {
  const response = await fetch(
    `${NetworkingConstants.HOST}/organisation/${organisationId}/customerInvoice/${invoiceId}`,
    { method: 'DELETE', headers: Current.defaultHeaders }
  )
  const body = await response.json()

  if (!response.ok || body.status !== 200) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
}

const payCustomerInvoice = async (
  organisationIdentifier: string,
  customerInvoiceId: string,
  payment: ICustomerInvoicePayment
): Promise<ICustomerInvoice> => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationIdentifier}/customerInvoice/${customerInvoiceId}/payment`,
    {
      method: 'POST',
      headers: Current.defaultHeaders,
      body: JSON.stringify({ ...payment }),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.customerInvoice) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.customerInvoice as ICustomerInvoice
}

const shareCustomerInvoice = async (
  customerInvoiceId: string
): Promise<string> => {
  const response = await fetch(
    NetworkingConstants.HOST + `/customerInvoice/${customerInvoiceId}/share`,
    {
      method: 'POST',
      headers: Current.defaultHeaders,
      body: JSON.stringify({ token: currentApplicationAccessToken }),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.shareId) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.shareId as string
}

const retrievePublicInvoiceOrQuote = async (
  shareId: string
): Promise<[ICustomerInvoice, IOrganisation, string]> => {
  const response = await fetch(
    NetworkingConstants.HOST + `/public-quote/${shareId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const body = await response.json()

  if (!response.ok || !body.customerInvoice) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return [
    body.customerInvoice as ICustomerInvoice,
    body.vatSettings as IOrganisation,
    body.invoiceNumber as string,
  ]
}

const setQuoteStatus = async (
  quoteStatus: QuoteStatus,
  organisationId: string,
  invoiceId: string
): Promise<ICustomerInvoice> => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationId}/customerInvoice/${invoiceId}/quote-status`,
    {
      method: 'POST',
      headers: Current.defaultHeaders,
      body: JSON.stringify({ quoteStatus }),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.customerInvoice) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.customerInvoice as ICustomerInvoice
}

const acceptPublicQuote = async (
  shareId: string,
  signingPerson: string
): Promise<ICustomerInvoice> => {
  const response = await fetch(
    NetworkingConstants.HOST + `/public-quote/${shareId}/accept`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ signingPerson }),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.customerInvoice) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.customerInvoice as ICustomerInvoice
}

const CustomerInvoices = {
  getCustomerInvoices,
  getCustomerInvoice,
  createCustomerInvoice,
  getPDFBlob,
  updateCustomerInvoice,
  deleteCustomerInvoice,
  payCustomerInvoice,
  shareCustomerInvoice,
  retrievePublicInvoiceOrQuote,
  setQuoteStatus,
  acceptPublicQuote,
  annulCustomerInvoice,
  restoreAnnulledCustomerInvoice,
}

export default CustomerInvoices
