import { Checkbox, Flex } from '@nextbusiness/infinity-ui'
import DocumentPositionDiscountUtilities from '../DocumentPositionDiscountUtilities'
import { DocumentPositionFlyoutProps } from './DocumentPositionFlyout'

const DocumentDiscountPositionFlyout = (props: DocumentPositionFlyoutProps) => (
  <Flex verticalAlignment='center'>
    <Checkbox
      label='In Prozent'
      isChecked={'percentage' in props.position}
      onChange={(isChecked) => {
        const shouldSwitchToPercentage = isChecked
        if (shouldSwitchToPercentage) {
          DocumentPositionDiscountUtilities.switchToPercentage(props)
        } else {
          DocumentPositionDiscountUtilities.switchToFixedAmount(props)
        }
      }}
    />
  </Flex>
)

export default DocumentDiscountPositionFlyout
