import {
  AmountMode,
  Finance,
  VATCalculationMode,
  VATPresentationOptions,
} from '@nextbusiness/infinity-finance-api'
import {
  Checkbox,
  FeedbackBanner,
  Flex,
  InputField,
  LoadingIndicator,
  RadioGroup,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import VATIcon from 'assets/oobe/oobe-vat.svg'
import FeatureGateButton from 'components/billing/feature-gate/FeatureGateButton'
import useSubscription from 'components/billing/subscription-context/useSubscription'
import { useMixpanelEvent } from 'integrations/useMixpanelEvent'
import useAPIQuery from 'libs/networking/APIQuery'
import SubscriptionCapability from 'libs/subscription/SubscriptionCapability'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { IVATSettings } from 'model/Setup'
import { useEffect, useState } from 'react'
import SettingsFieldList from 'settings/general/SettingsFieldList'
import SettingsGroup from 'settings/general/SettingsGroup'
import SettingsPage from 'settings/general/SettingsPage'
import { useRootStore } from 'stores/RootStoreContext'

const VATSettingsPage = () => {
  const { preferencesStore } = useRootStore()
  const notificationCenter = useNotificationCenter()
  const subscription = useSubscription()

  const [loadedSettings, isLoading, error] = useAPIQuery(() =>
    Finance.Organisation.current()
  )
  const [settings, setSettings] = useState<IVATSettings>({
    hasVATRequirement: false,
  })
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)

  const isValid = () => {
    if (settings.hasVATRequirement) {
      return !!settings.vatCalculationMode && !!settings.vatRegistrationNumber
    } else {
      return true
    }
  }
  const requiresAdvancedVAT = () => {
    return (
      settings.hasVATRequirement &&
      settings.vatCalculationMode === VATCalculationMode.Effective
    )
  }

  const modifyVATPresentationOptions = (
    changes: Partial<VATPresentationOptions>
  ) => {
    const options = settings.vatPresentationOptions ?? {}
    setSettings({
      ...settings,
      vatPresentationOptions: { ...options, ...changes },
    })
  }

  const saveChanges = async () => {
    setShouldValidate(true)
    if (!isValid()) return
    setIsSaving(true)
    try {
      const preferences = await Finance.Organisation.setPreferences({
        VAT: settings.hasVATRequirement,
        vatCalculationMode: settings.vatCalculationMode,
        vatRegistrationNumber: settings.vatRegistrationNumber,
        customerInvoiceAmountMode:
          settings.customerInvoiceAmountMode ?? AmountMode.Gross,
        vatPresentationOptions: settings.vatPresentationOptions,
      })
      runInAction(() => {
        preferencesStore.organisationPreferences = preferences
      })
      notificationCenter.addNotification({
        children: 'Einstellungen gespeichert',
        variant: 'success',
      })
      setShouldValidate(false)
    } catch (error: any) {
      notificationCenter.addNotification({
        title: 'Einstellungen konnten nicht gespeichert werden',
        children: error?.message,
        variant: 'error',
      })
    } finally {
      setIsSaving(false)
    }
  }

  useEffect(() => {
    if (loadedSettings)
      setSettings({
        hasVATRequirement: loadedSettings.VAT ?? false,
        vatCalculationMode:
          loadedSettings.vatCalculationMode ?? VATCalculationMode.Effective,
        vatRegistrationNumber: loadedSettings.vatRegistrationNumber,
        customerInvoiceAmountMode:
          loadedSettings.customerInvoiceAmountMode ?? AmountMode.Gross,
        vatPresentationOptions: loadedSettings.vatPresentationOptions,
      })
  }, [loadedSettings])

  useMixpanelEvent('Settings viewed - VAT')

  if (isLoading)
    return (
      <Flex
        horizontalAlignment='center'
        verticalAlignment='center'
        fillContainer
      >
        <LoadingIndicator />
      </Flex>
    )

  return (
    <SettingsPage
      title='Mehrwertsteuer'
      iconURL={VATIcon}
      className='vat-settings-page'
    >
      {error?.code}
      <SettingsGroup
        title='Steuerpflicht'
        description='Hier kannst du einstellen, ob deine Unternehmung mehrwertsteuerpflichtig ist.'
      >
        <RadioGroup
          value={settings?.hasVATRequirement}
          onChange={(hasVATRequirement) =>
            setSettings({ ...settings, hasVATRequirement })
          }
          options={[
            {
              value: false,
              label: 'Ich bin noch nicht mehrwertsteuerpflichtig',
            },
            {
              value: true,
              label: 'Ich bin bereits mehrwertsteuerpflichtig',
            },
          ]}
        />
      </SettingsGroup>
      {settings?.hasVATRequirement && (
        <>
          <SettingsGroup title='MWST-Nummer'>
            <SettingsFieldList>
              <InputField
                placeholder='Mehrwertsteuernummer'
                value={settings?.vatRegistrationNumber ?? ''}
                onChange={(vatRegistrationNumber) =>
                  setSettings({ ...settings, vatRegistrationNumber })
                }
                helperText='Im Format CHE-123.456.789 MWST'
                hasError={
                  shouldValidate &&
                  settings.hasVATRequirement &&
                  !settings.vatRegistrationNumber
                }
              />
            </SettingsFieldList>
          </SettingsGroup>
          <SettingsGroup
            title='Abrechnungsmethode'
            description='Falls du deine Unternehmung bei der Steuerverwaltung für die vereinfachte Saldosteuersatz-Methode angemeldet hast, kannst du hier darauf umstellen.'
          >
            <RadioGroup
              value={settings?.vatCalculationMode ?? 'effective'}
              onChange={(vatCalculationMode) =>
                setSettings({ ...settings, vatCalculationMode })
              }
              options={[
                {
                  value: VATCalculationMode.Effective,
                  label: 'Effektive Abrechnung (Standard)',
                },
                {
                  value: VATCalculationMode.Simplified,
                  label: 'Saldosteuersatz',
                },
              ]}
            />
          </SettingsGroup>
          <SettingsGroup
            title='Beträge in neuen Forderungen'
            description='Wie sollen die eingegebenen Beträge bei Positionen in neu erstellten Forderungen und Offerten behandelt werden? Bestehende Dokumente werden nicht verändert.'
          >
            <RadioGroup
              value={settings?.customerInvoiceAmountMode}
              onChange={(customerInvoiceAmountMode) =>
                setSettings({ ...settings, customerInvoiceAmountMode })
              }
              options={[
                {
                  value: AmountMode.Gross,
                  label: 'Brutto (eingegebene Beträge sind inkl. MWST)',
                },
                {
                  value: AmountMode.Net,
                  label:
                    'Netto (zu den eingegebenen Beträgen wird die MWST hinzugerechnet)',
                },
              ]}
            />
          </SettingsGroup>
          <SettingsGroup description='Was soll in Forderungen und Offerten angezeigt werden?'>
            <Checkbox
              label='Nettobetrag als Zwischensumme zeigen'
              isChecked={settings.vatPresentationOptions?.showNetAmountSubtotal}
              onChange={(showNetAmountSubtotal) =>
                modifyVATPresentationOptions({ showNetAmountSubtotal })
              }
            />
            {settings.customerInvoiceAmountMode === AmountMode.Net && (
              <Checkbox
                label='Betrag inkl. MWST in Offerten zeigen'
                isChecked={!settings.vatPresentationOptions?.hideVATInQuotes}
                onChange={(hideVATInQuotes) =>
                  modifyVATPresentationOptions({
                    hideVATInQuotes: !hideVATInQuotes,
                  })
                }
              />
            )}
          </SettingsGroup>
        </>
      )}
      {requiresAdvancedVAT() &&
        !subscription?.hasCapability(SubscriptionCapability.AdvancedVAT) && (
          <FeedbackBanner variant='help'>
            Effektive Abrechnung erfordert Infinity Finance Pro.
          </FeedbackBanner>
        )}
      <FeatureGateButton
        alwaysEnableFeature={!requiresAdvancedVAT()}
        requiredCapability={SubscriptionCapability.AdvancedVAT}
        variant='primary'
        isLoading={isSaving}
        onClick={() => saveChanges()}
      >
        Speichern
      </FeatureGateButton>
    </SettingsPage>
  )
}

export default observer(VATSettingsPage)
