import { BackendError, ErrorType } from '../libs/networking/Errors'
import {
  IVendorInvoice,
  IVendorInvoiceCreationParams,
  IVendorInvoicePayment,
  IVendorInvoiceQueryParams,
} from '../model/VendorInvoice'
import { KeyValueStore } from '../utility/types'
import { Current } from './Authentication'
import NetworkingConstants from './NetworkingConstants'
import NetworkingErrorHandler from './NetworkingErrorHandler'

const listInvoices = async (
  organisationIdentifier: string,
  queryParams?: IVendorInvoiceQueryParams
): Promise<IVendorInvoice[]> => {
  const params = queryParams
    ? new URLSearchParams(queryParams as KeyValueStore)
    : null
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationIdentifier}/vendorInvoices/isDraft/false?${
        params?.toString() ?? ''
      }`,
    { method: 'GET', headers: Current.defaultHeaders }
  )
  const body = await response.json()

  if (!response.ok || !body.vendorInvoices) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.vendorInvoices as IVendorInvoice[]
}

const getInvoice = async (invoiceId: string): Promise<IVendorInvoice> => {
  const response = await fetch(
    NetworkingConstants.HOST + `/vendorInvoice/${invoiceId}`,
    { method: 'GET', headers: Current.defaultHeaders }
  )
  const body = await response.json()

  if (!response.ok || !body.vendorInvoice) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.vendorInvoice as IVendorInvoice
}

const createVendorInvoice = async (
  organisationIdentifier: string,
  vendorInvoice: IVendorInvoiceCreationParams
): Promise<IVendorInvoice> => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationIdentifier}/VendorInvoice/isDraft/false`,
    {
      method: 'POST',
      headers: Current.defaultHeaders,
      body: JSON.stringify(vendorInvoice),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.vendorInvoice) {
    switch (body.status) {
      case 400:
        throw new BackendError(ErrorType.MissingRequiredFields, body.message)
      default:
        if (body.message.includes('Path `text` is required.')) {
          throw new BackendError(
            ErrorType.MissingRequiredFields,
            'Eine oder mehrere Positionen haben noch keine Beschreibung.'
          )
        } else {
          NetworkingErrorHandler.throwFromResponse(response, body)
        }
    }
  }
  return body.vendorInvoice as IVendorInvoice
}

const updateVendorInvoice = async (
  organisationIdentifier: string,
  vendorInvoiceId: string,
  changes: Partial<IVendorInvoiceCreationParams>
): Promise<IVendorInvoice> => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationIdentifier}/vendorInvoice/${vendorInvoiceId}`,
    {
      method: 'PATCH',
      headers: Current.defaultHeaders,
      body: JSON.stringify(changes),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.vendorInvoice) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.vendorInvoice as IVendorInvoice
}

const deleteVendorInvoice = async (
  organisationIdentifier: string,
  vendorInvoiceId: string
): Promise<IVendorInvoice> => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationIdentifier}/vendorInvoice/${vendorInvoiceId}`,
    {
      method: 'DELETE',
      headers: Current.defaultHeaders,
    }
  )
  const body = await response.json()

  if (!response.ok || !body.vendorInvoice) {
    switch (body.status) {
      case 403:
        throw new BackendError(
          ErrorType.PastFiscalYearNonModifyable,
          body.message
        )
      default:
        NetworkingErrorHandler.throwFromResponse(response, body)
    }
  }
  return body.vendorInvoice as IVendorInvoice
}

const payVendorInvoice = async (
  organisationIdentifier: string,
  vendorInvoiceId: string,
  payment: IVendorInvoicePayment
): Promise<IVendorInvoice> => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationIdentifier}/vendorInvoice/${vendorInvoiceId}`,
    {
      method: 'POST',
      headers: Current.defaultHeaders,
      body: JSON.stringify({ ...payment, transactionDate: payment.date }),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.vendorInvoice) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.vendorInvoice as IVendorInvoice
}

const VendorInvoices = {
  listInvoices,
  getInvoice,
  createVendorInvoice,
  updateVendorInvoice,
  deleteVendorInvoice,
  payVendorInvoice,
}

export default VendorInvoices
