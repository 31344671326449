import './Filters.scss'

interface FiltersProps {
  children?: React.ReactNode
}

const Filters = (props: FiltersProps) => (
  <div className='ui-filters'>{props.children}</div>
)

export default Filters
