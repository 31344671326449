import { Flex } from '@nextbusiness/infinity-ui'
import ContentLoader from 'react-content-loader'

interface TransactionListLoadingStateProps {
  isContinuation?: boolean
}

const TransactionListLoadingState = (
  props: TransactionListLoadingStateProps
) => (
  <div style={{ width: '100%' }}>
    {[...Array(props.isContinuation ? 3 : 6)].map((_, i) => (
      <TransactionLoadingItem key={i} />
    ))}
  </div>
)

const TransactionLoadingItem = () => (
  <Flex horizontalAlignment='flex-start'>
    <div style={{ flexGrow: 1 }}>
      <ContentLoader
        speed={2}
        width={400}
        height={36}
        viewBox='0 0 400 36'
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'
      >
        <rect x='16' y='7' rx='5' ry='5' width='80' height='14' />
        <rect x='130' y='7' rx='5' ry='5' width='270' height='14' />
      </ContentLoader>
    </div>
    <ContentLoader
      speed={2}
      width={380}
      height={36}
      viewBox='0 0 380 36'
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
    >
      <rect x='4' y='9' rx='3' ry='3' width='12' height='12' />
      <rect x='30' y='9' rx='5' ry='5' width='120' height='14' />
      <rect x='280' y='9' rx='5' ry='5' width='100' height='14' />
    </ContentLoader>
  </Flex>
)

export default TransactionListLoadingState
