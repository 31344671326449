import { Finance, IProject } from '@nextbusiness/infinity-finance-api'
import { Contact } from 'model/Contact'
import { CurrentCustomerInvoicesNavigationStackRef } from 'pages/CustomerInvoicesPage'
import EditOrCreateProjectModal from './EditOrCreateProjectModal'

export interface CreateProjectModalProps {
  isOpen: boolean
  onDismiss: () => void
  options?: {
    contact?: Contact
  }
  /** If set, the default behaviour of opening the newly created project will be overwritten. */
  onCreateProject?: (project: IProject) => void
}

const CreateProjectModal = (props: CreateProjectModalProps) => {
  const createProject = async (
    title: string,
    description: string,
    client?: string
  ) => {
    const createdProject = await Finance.Projects.createProject({
      title,
      client: client!,
      description,
    })
    if (props.onCreateProject) {
      props.onCreateProject(createdProject)
    } else {
      CurrentCustomerInvoicesNavigationStackRef?.openProject(createdProject.id)
    }
  }

  return (
    <EditOrCreateProjectModal
      titleOfModal='Projekt erstellen'
      saveActionCopy='Erstellen'
      onSave={createProject}
      {...props}
    />
  )
}

export default CreateProjectModal
