import DetailedRichSelect from 'base-components/detailed-rich-select/DetailedRichSelect'
import { AccrualType } from 'model/financial-close/Accrual'
import React from 'react'

interface AccrualTypeSelectProps {
  type?: AccrualType
  onChange: (type: AccrualType) => void
}

const AccrualTypeSelect = (props: AccrualTypeSelectProps) => (
  <DetailedRichSelect
    value={props.type}
    onChange={(type) => props.onChange(type as AccrualType)}
    placeholder='Typ auswählen…'
    options={[
      {
        label: 'Zukünftiges Geschehen',
        options: [
          {
            label: 'Bereits bezahlter Aufwand',
            value: AccrualType.PrePaidExpense,
            icon: 'add',
            description:
              'Wenn du bereits für Leistungen bezahlt hast, die du erst im Folgejahr erhältst',
          },
          {
            label: 'Bereits erhaltener Ertrag',
            value: AccrualType.PrePaidRevenue,
            icon: 'add',
            description:
              'Wenn du bereits Umsatz erhalten hast, aber noch Leistungen an deine Kunden ausstehen',
          },
        ],
      },
      {
        label: 'Vergangenes Geschehen',
        options: [
          {
            label: 'Noch nicht bezahlter Aufwand',
            value: AccrualType.AccruedExpense,
            icon: 'add',
            description:
              'Wenn du bereits eine Leistung erhalten, für diese aber noch keine Rechnung erhalten hast',
          },
          {
            label: 'Noch nicht erhaltener Ertrag',
            value: AccrualType.DeferredRevenue,
            icon: 'add',
            description:
              'Wenn du bereits eine Leistung an deine Kunden geliefert hast, aber noch keine Rechnung gestellt hast',
          },
        ],
      },
    ]}
  />
)

export default AccrualTypeSelect
