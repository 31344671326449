import { NonIdealState } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { IPresetListItem, PRESET_CATEGORIES } from 'model/Preset'
import { useRootStore } from 'stores/RootStoreContext'
import PresetModalContentSection from './PresetModalContentSection'

interface PresetSearchResultSectionProps {
  searchValue: string
  activeCategory: keyof typeof PRESET_CATEGORIES
  presets: IPresetListItem[]
  isNewDocumentQuote: boolean
  openDocumentEditor: () => void
}

const PresetSearchResultSection = (props: PresetSearchResultSectionProps) => {
  const { presetStore } = useRootStore()
  const hasNotFoundResults = props.presets.length === 0
  const nameOfCategory = PRESET_CATEGORIES[props.activeCategory]

  const emptyState = () => (
    <NonIdealState
      title={`Keine Vorlagen für "${props.searchValue}" gefunden`}
      actions={[
        {
          variant: 'primary',
          children: 'Leere Vorlage verwenden',
          iconLeft: 'plus',
          onClick: () => {
            presetStore.setSelectedPreset(null)
            props.openDocumentEditor()
          },
        },
      ]}
    />
  )

  return (
    <PresetModalContentSection
      {...props}
      showBlankPreset={false}
      sectionTitle={`Resultate in "${nameOfCategory}"`}
      emptyState={emptyState()}
      displayEmptyState={hasNotFoundResults}
    />
  )
}

export default observer(PresetSearchResultSection)
