import Lockup from 'components/text/Lockup'
import { useTransactionEditorContext } from './TransactionEditorContext'

const TransactionNoEditMessage = () => {
  const { noEditReason } = useTransactionEditorContext()
  return (
    <Lockup className='no-edit-message' icon='no-edit' subtle truncate>
      {noEditReason}
    </Lockup>
  )
}

export default TransactionNoEditMessage
