import FinancialHealthMetric from '../FinancialHealthMetric'
import { FinancialHealthAssessment } from '../FinancialHealthAssessment'

class MetricTargetNetProfitMargin extends FinancialHealthMetric {
  title = 'Ziel-Reingewinnmarge'
  description = 'Gewinn in % vom Umsatz entspricht dem festgelegten Ziel'

  isRelevant = () =>
    !!this.context.profitability?.netProfitMargin.targetNetProfitMargin

  getAssessment = (): FinancialHealthAssessment =>
    FinancialHealthMetric.assessmentFromStatus(
      this.context.profitability?.netProfitMargin
        .comparedToTargetNetProfitMargin
    )

  getStatusText = (): string =>
    this.context.profitability?.netProfitMargin
      .comparedToTargetNetProfitMargin ?? 'Keine Angabe'

  getActualValue = () => {
    const margin = this.context.profitability?.netProfitMargin.currentYear
    if (!margin) return '–'
    return `${(margin * 100).toFixed(1)}%`
  }

  getTarget = () => {
    const targetMargin =
      this.context.profitability?.netProfitMargin.targetNetProfitMargin
    if (!targetMargin) return '–'
    return `${(targetMargin * 100).toFixed(1)}%`
  }
}

export default MetricTargetNetProfitMargin
