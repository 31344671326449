import { ITransaction } from '@nextbusiness/infinity-finance-api'
import { useEffect, useState } from 'react'
import AccountStore from 'stores/AccountStore'
import VATPeriodStore from 'stores/VATPeriodStore'
import { useIsKeyDown } from 'utility/hooks'
import { useBatchEditingContext } from '../batch-editing/BatchEditingContext'
import TransactionEditorUtilities from '../editor/TransactionEditorUtilities'

const useHandleBatchEditSelection = (
  transactions: ITransaction[] | undefined,
  accountStore: AccountStore,
  vatPeriodStore: VATPeriodStore
) => {
  const batchEditingContext = useBatchEditingContext()
  const isShiftDown = useIsKeyDown('Shift')

  const [lastSelectedTransaction, setLastSelectedTransaction] =
    useState<string>()
  const [lastSelectionEnd, setLastSelectionEnd] = useState<number>()

  const lastSelectedTransactionIndex = () => {
    const index =
      transactions?.findIndex((t) => t.id === lastSelectedTransaction) ?? 0
    if (index === -1) return 0

    return index
  }

  const canEditTransaction = (transaction: ITransaction) =>
    !TransactionEditorUtilities.isTransactionEditingRestricted(
      transaction,
      accountStore,
      vatPeriodStore
    )

  const handleBatchEditSelection = (
    transaction: ITransaction,
    isTransactionSelected: boolean
  ) => {
    if (isShiftDown) {
      rangeSelectToTransaction(transaction)
    } else {
      toggleSelectTransaction(transaction, isTransactionSelected)
    }
  }

  const toggleSelectTransaction = (
    transaction: ITransaction,
    isAlreadySelected: boolean
  ) => {
    if (isAlreadySelected) {
      batchEditingContext.deselectTransaction(transaction)
    } else if (canEditTransaction(transaction)) {
      batchEditingContext.selectTransaction(transaction)
    }
    setLastSelectedTransaction(transaction.id)
    setLastSelectionEnd(undefined)
  }

  const rangeSelectToTransaction = (transaction: ITransaction) => {
    const [start, end] = selectionRange(transaction)

    const transactionsToSelect = transactionsInSelectRange(start, end)
    const transactionsToDeselect = transactionsInDeselectRange(start, end)

    const selectedTransactions: string[] = [
      ...batchEditingContext.selectedTransactions,
    ]

    transactionsToSelect.forEach((t) => {
      if (!batchEditingContext.selectedTransactions.includes(t)) {
        selectedTransactions.push(t)
      }
    })
    const finalSelectedTransactions = selectedTransactions.filter(
      (t) => !transactionsToDeselect.includes(t)
    )

    batchEditingContext.setSelectedTransactions(finalSelectedTransactions)
    setLastSelectionEnd(end)
  }

  const selectionRange = (selectedTransaction: ITransaction) => {
    const transactionIndex =
      transactions?.findIndex((t) => t.id === selectedTransaction.id) ?? 0
    const lastSelectedIndex = lastSelectedTransactionIndex()

    const selectionStart = Math.min(transactionIndex, lastSelectedIndex)
    const selectionEnd = Math.max(transactionIndex, lastSelectedIndex)

    return [selectionStart, selectionEnd]
  }

  const transactionsInSelectRange = (start: number, end: number) => {
    const transactionIds: string[] = []

    for (let i = start; i <= end; i++) {
      if (!transactions?.[i]) continue
      if (!canEditTransaction(transactions[i])) continue
      transactionIds.push(transactions[i].id)
    }
    return transactionIds
  }

  const transactionsInDeselectRange = (start: number, end: number) => {
    let deselectionStart: number
    let deselectionEnd: number

    const transactionIds: string[] = []

    if (lastSelectionEnd !== undefined) {
      if (lastSelectionEnd < start) {
        deselectionStart = lastSelectionEnd
        deselectionEnd = start - 1
      } else {
        deselectionStart = end + 1
        deselectionEnd = lastSelectionEnd
      }
      for (let i = deselectionStart; i <= deselectionEnd; i++) {
        transactionIds.push(transactions![i].id)
      }
    }
    return transactionIds
  }

  useEffect(() => {
    if (batchEditingContext.isInMultiEdit) {
      setLastSelectedTransaction(undefined)
      setLastSelectionEnd(undefined)
    }
  }, [batchEditingContext.isInMultiEdit])

  return handleBatchEditSelection
}

export default useHandleBatchEditSelection
