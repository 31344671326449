import {
  IAccount,
  IOrganisation,
  TaxCode,
  VATCalculationMode,
} from '@nextbusiness/infinity-finance-api'
import {
  IStructuredTaxCodes,
  TaxRate,
  TaxRateEra,
  TaxRateType,
} from 'components/vat/vat-picker/TaxCodes'

export const taxRateTypeFromTaxCode = (code: TaxCode): TaxRateType => {
  const taxRate = taxRateForTaxCode(code)
  switch (taxRate) {
    case 8.1:
    case 7.7:
    case undefined:
      return 'normal'
    case 3.8:
    case 3.7:
      return 'special'
    case 2.6:
    case 2.5:
      return 'reduced'
  }
}

export const taxRateExplanation = (taxRate: TaxRate) => {
  switch (taxRate) {
    case 3.8:
    case 3.7:
      return `${taxRate}% ist der Sondersatz für Beherbergung und fällt bei Unterkünften und dazugehörigem Frühstück an.`
    case 2.6:
    case 2.5:
      return `${taxRate}% ist der Satz für reduzierte Artikel wie bestimmte Nahrungsmittel, Zeitungen, Bücher, etc.`
    default:
      return undefined
  }
}

export const taxRateEraOfTaxCode = (code: TaxCode): TaxRateEra => {
  switch (taxRateForTaxCode(code)) {
    case 8.1:
    case 3.8:
    case 2.6:
    case undefined:
      return '2024-reform'
    case 7.7:
    case 3.7:
    case 2.5:
      return '2018-reform'
  }
}

export const taxRateEraOfDate = (date: number): TaxRateEra =>
  new Date(date).getFullYear() >= 2024 ? '2024-reform' : '2018-reform'

export const taxRateReportEraOfDate = (date: number): TaxRateEra => {
  const defaultEra: TaxRateEra = '2018-reform'
  const reportingStartPerEra: Record<TaxRateEra, Date> = {
    // The reporting periods for VAT reforms start earlier than
    // the new tax rates are applied. These are the start dates of the
    // VAT reporting periods in which the tax form for the new
    // tax rates goes into use.
    '2024-reform': new Date('2023-07-01T00:00:00'),
    '2018-reform': new Date('2018-07-01T00:00:00'),
  }
  const eraForDate = Object.keys(reportingStartPerEra).find((era) => {
    if (date >= reportingStartPerEra[era as TaxRateEra].getTime())
      return era as TaxRateEra
  })
  return eraForDate ? (eraForDate as TaxRateEra) : defaultEra
}

export const displayTextForTaxCode = (code: TaxCode) => {
  const rate = taxRateForTaxCode(code)
  const shortened = shortenedTaxCodeName(code)
  return `${rate}% ${shortened}`
}

export const swappedTaxCodeEra = (code: TaxCode): TaxCode => {
  switch (code) {
    case TaxCode.UN81:
      return TaxCode.UN77
    case TaxCode.UN77:
      return TaxCode.UN81
    case TaxCode.US38:
      return TaxCode.US37
    case TaxCode.US37:
      return TaxCode.US38
    case TaxCode.UR26:
      return TaxCode.UR25
    case TaxCode.UR25:
      return TaxCode.UR26
    case TaxCode.VM81:
      return TaxCode.VM77
    case TaxCode.VM77:
      return TaxCode.VM81
    case TaxCode.VM38:
      return TaxCode.VM37
    case TaxCode.VM37:
      return TaxCode.VM38
    case TaxCode.VM26:
      return TaxCode.VM25
    case TaxCode.VM25:
      return TaxCode.VM26
    case TaxCode.VB81:
      return TaxCode.VB77
    case TaxCode.VB77:
      return TaxCode.VB81
    case TaxCode.VB38:
      return TaxCode.VB37
    case TaxCode.VB37:
      return TaxCode.VB38
    case TaxCode.VB26:
      return TaxCode.VB25
    case TaxCode.VB25:
      return TaxCode.VB26
    case TaxCode.ES81:
      return TaxCode.ES77
    case TaxCode.ES77:
      return TaxCode.ES81
    case TaxCode.ES26:
      return TaxCode.ES25
    case TaxCode.ES25:
      return TaxCode.ES26
    case TaxCode.BZB81:
      return TaxCode.BZB77
    case TaxCode.BZB77:
      return TaxCode.BZB81
    default:
      return code
  }
}

export const adjustedTaxCodeByDate = (code: TaxCode, date: number): TaxCode => {
  const eraOfDate = taxRateEraOfDate(date)
  const eraOfTaxCode = taxRateEraOfTaxCode(code)
  if (eraOfTaxCode === eraOfDate) return code
  else return swappedTaxCodeEra(code)
}

export const nameForTaxCode = (code: TaxCode) => {
  switch (code) {
    case TaxCode.UN81:
    case TaxCode.UN77:
      return 'Normaler Umsatz'
    case TaxCode.US38:
    case TaxCode.US37:
      return 'Umsatz aus Beherbergung'
    case TaxCode.UR26:
    case TaxCode.UR25:
      return 'Umsatz mit reduziertem Satz'
    case TaxCode.UEX:
      return 'Warenexport ins Ausland'
    case TaxCode.ULA:
      return 'Leistungen im Ausland'
    case TaxCode.UNO:
      return 'Ausgenommene Umsätze'
    case TaxCode.VM81:
    case TaxCode.VM77:
    case TaxCode.VM38:
    case TaxCode.VM37:
    case TaxCode.VM26:
    case TaxCode.VM25:
      return 'Einkauf Material/Dienstleistungen'
    case TaxCode.VB81:
    case TaxCode.VB77:
    case TaxCode.VB38:
    case TaxCode.VB37:
    case TaxCode.VB26:
    case TaxCode.VB25:
      return 'Betriebsaufwand'
    case TaxCode.ES81:
    case TaxCode.ES77:
    case TaxCode.ES26:
    case TaxCode.ES25:
      return 'Wareneinfuhr vom Ausland'
    case TaxCode.BZB81:
    case TaxCode.BZB77:
      return 'Leistungen vom Ausland'
    case TaxCode.None:
      return 'Keine Mehrwertsteuer'
  }
}

export const shortenedTaxCodeName = (code: TaxCode) => {
  const name = nameForTaxCode(code)
  switch (name) {
    case 'Normaler Umsatz':
      return 'Umsatz'
    case 'Umsatz aus Beherbergung':
      return 'Beherbergung'
    case 'Umsatz mit reduziertem Satz':
      return 'Reduziert'
    case 'Warenexport ins Ausland':
      return 'Export'
    case 'Leistungen im Ausland':
      return 'Ausland'
    case 'Ausgenommene Umsätze':
      return 'Ausgenommen'
    case 'Einkauf Material/Dienstleistungen':
      return 'Material/DL'
    case 'Betriebsaufwand':
      return 'Betriebsaufw.'
    case 'Wareneinfuhr vom Ausland':
      return 'Einfuhrsteuer'
    case 'Leistungen vom Ausland':
      return 'Bezugssteuer'
    case 'Keine Mehrwertsteuer':
      return 'Keine'
  }
}

export const descriptionForTaxCode = (
  code: TaxCode,
  type?: keyof IStructuredTaxCodes
) => {
  switch (code) {
    case TaxCode.UN81:
    case TaxCode.UN77:
      return 'Steuerpflichtige Einnahmen im Inland'
    case TaxCode.US38:
    case TaxCode.US37:
      return 'Erlöse aus Gewähren von Unterkunft oder dazugehörigem Frühstück'
    case TaxCode.UR26:
    case TaxCode.UR25:
      return 'Erlöse aus Lieferungen wie bestimmten Nahrungsmitteln, Zeitungen, Bücher, …'
    case TaxCode.UEX:
      return 'Wenn du Güter für den Export ins Ausland verkauft hast'
    case TaxCode.ULA:
      return 'Dienstleistungen, die du nachweislich im Ausland erbracht hast'
    case TaxCode.UNO:
      return 'Leistungen in Gesundheit, Sozialwesen, Kultur, etc. sind steuerfrei, müssen aber deklariert werden.'
    case TaxCode.VM81:
    case TaxCode.VM77:
    case TaxCode.VM38:
    case TaxCode.VM37:
    case TaxCode.VM26:
    case TaxCode.VM25:
      return 'Für Ausgaben, die direkt mit Kundenaufträgen zusammenhängen'
    case TaxCode.VB81:
    case TaxCode.VB77:
    case TaxCode.VB38:
    case TaxCode.VB37:
    case TaxCode.VB26:
    case TaxCode.VB25:
      return 'Anderweitige Geschäftsausgaben und Investitionen'
    case TaxCode.ES81:
    case TaxCode.ES77:
    case TaxCode.ES26:
    case TaxCode.ES25:
      return 'Import von physischen Waren und zugehörige Leistungen wie Montage'
    case TaxCode.BZB81:
    case TaxCode.BZB77:
      return 'Dienstleistungen aus dem Ausland ab kumuliert 10’000 Fr./Jahr'
    case TaxCode.None:
      // When no type is specified, we forego a description to not just repeat the title 'Keine Mehrwertsteuer'
      if (!type) return undefined
      if (type === 'income') return 'Nur für nicht umsatzrelevante Buchungen'
      if (type === 'expense')
        return 'Wenn keine Mehrwertsteuer auf diese Ausgabe bezahlt wurde'
  }
}

export const taxRateForTaxCode = (code: TaxCode): TaxRate | undefined => {
  switch (code) {
    case TaxCode.UN81:
    case TaxCode.VM81:
    case TaxCode.VB81:
    case TaxCode.ES81:
    case TaxCode.BZB81:
      return 8.1
    case TaxCode.UN77:
    case TaxCode.VM77:
    case TaxCode.VB77:
    case TaxCode.ES77:
    case TaxCode.BZB77:
      return 7.7
    case TaxCode.US38:
    case TaxCode.VM38:
    case TaxCode.VB38:
      return 3.8
    case TaxCode.US37:
    case TaxCode.VM37:
    case TaxCode.VB37:
      return 3.7
    case TaxCode.UR26:
    case TaxCode.VM26:
    case TaxCode.VB26:
    case TaxCode.ES26:
      return 2.6
    case TaxCode.UR25:
    case TaxCode.VM25:
    case TaxCode.VB25:
    case TaxCode.ES25:
      return 2.5
    case TaxCode.UEX:
    case TaxCode.ULA:
    case TaxCode.UNO:
    case TaxCode.None:
      return undefined
  }
}

export const iconForTaxCode = (code: TaxCode) => {
  switch (code) {
    case TaxCode.UN81:
    case TaxCode.UN77:
      return 'billing-machine'
    case TaxCode.US38:
    case TaxCode.US37:
      return 'open-sign'
    case TaxCode.UR26:
    case TaxCode.UR25:
      return 'percentage'
    case TaxCode.UEX:
      return 'package-delivery-logistics'
    case TaxCode.ULA:
      return 'address'
    case TaxCode.UNO:
      return 'paid'
    case TaxCode.VM81:
    case TaxCode.VM77:
    case TaxCode.VM38:
    case TaxCode.VM37:
    case TaxCode.VM26:
    case TaxCode.VM25:
      return 'factory'
    case TaxCode.VB81:
    case TaxCode.VB77:
    case TaxCode.VB38:
    case TaxCode.VB37:
    case TaxCode.VB26:
    case TaxCode.VB25:
      return 'bar-chart'
    case TaxCode.ES81:
    case TaxCode.ES77:
    case TaxCode.ES26:
    case TaxCode.ES25:
      return 'package-delivery-logistics'
    case TaxCode.BZB81:
    case TaxCode.BZB77:
      return 'direction'
    case TaxCode.None:
      return 'not-allowed'
  }
}

const suggestedExpenseTaxCodeForRate = (rate: number): TaxCode => {
  switch (rate as TaxRate) {
    case 8.1:
      return TaxCode.VB81
    case 7.7:
      return TaxCode.VB77
    case 3.8:
      return TaxCode.VB38
    case 3.7:
      return TaxCode.VB37
    case 2.6:
      return TaxCode.VB26
    case 2.5:
      return TaxCode.VB25
    default:
      return TaxCode.None
  }
}

const autoSuggestVAT = (
  contraAccount: { number?: number; account?: IAccount } | undefined,
  preferences: IOrganisation | null,
  date = Date.now()
) => {
  if (preferences && !preferences.VAT) return null
  if (
    !contraAccount ||
    (contraAccount.number === undefined && contraAccount.account === undefined)
  )
    return null

  if (
    contraAccount.account?.defaultTaxCode !== undefined &&
    contraAccount.account?.defaultTaxCode !== null
  ) {
    return adjustedTaxCodeByDate(contraAccount.account.defaultTaxCode, date)
  }

  const contraAccountNumber =
    contraAccount.number ?? contraAccount.account!.accountNumber // if neither number nor account are defined, the function would have returned already

  if (contraAccountNumber >= 3000 && contraAccountNumber < 4000) {
    return adjustedTaxCodeByDate(TaxCode.UN77, date)
  }
  if (preferences?.vatCalculationMode === VATCalculationMode.Simplified) {
    // All expense-type VAT suggestions are disabled in simplified VAT mode,
    // but not the income-type ones (e.g. UN77).
    return null
  }
  if (contraAccountNumber >= 4000 && contraAccountNumber < 5000) {
    return adjustedTaxCodeByDate(TaxCode.VM77, date)
  }
  if (contraAccountNumber >= 6000 && contraAccountNumber < 7000) {
    return adjustedTaxCodeByDate(TaxCode.VB77, date)
  }
  return null
}

export default {
  taxRateTypeFromTaxCode,
  taxRateEraOfTaxCode,
  swappedTaxCodeEra,
  nameForTaxCode,
  shortenedTaxCodeName,
  descriptionForTaxCode,
  taxRateForTaxCode,
  iconForTaxCode,
  suggestedExpenseTaxCodeForRate,
  autoSuggestVAT,
  displayTextForTaxCode,
}
