import StatusLabel from 'components/status-label/StatusLabel'
import { QuoteStatus as QuoteStatusEnum } from 'model/CustomerInvoice'

interface QuoteStatusProps {
  quoteStatus?: QuoteStatusEnum
}

const swatchColourFor = (status?: QuoteStatusEnum) => {
  switch (status) {
    case QuoteStatusEnum.Rejected:
      return '#e0139b'
    case QuoteStatusEnum.Accepted:
      return '#31c35d'
    case QuoteStatusEnum.Open:
    default:
      return '#0aa8bf'
  }
}
const textFor = (status?: QuoteStatusEnum) => {
  switch (status) {
    case QuoteStatusEnum.Rejected:
      return 'Abgelehnt'
    case QuoteStatusEnum.Accepted:
      return 'Akzeptiert'
    case QuoteStatusEnum.Open:
    default:
      return 'Offen'
  }
}

const QuoteStatus = (props: QuoteStatusProps) => {
  return (
    <StatusLabel
      swatchColour={swatchColourFor(props.quoteStatus)}
      text={textFor(props.quoteStatus)}
    />
  )
}

export default QuoteStatus
