import React from 'react'
import './AssistantStepScreen.scss'

export enum AssistantStepVisual {
  Welcome = 'welcome',
  Accruals = 'accruals',
  Depreciations = 'depreciations',
  Vat = 'vat',
  ProfitClose = 'profitclose',
  Checks = 'checks',
  Confirmation = 'confirmation',
}

interface AssistantStepScreenProps {
  visual?: AssistantStepVisual
  depiction?: React.ReactNode
  children?: React.ReactNode
  actions?: React.ReactNode
}

const AssistantStepScreen = (props: AssistantStepScreenProps) => (
  <div className='assistant-step-screen'>
    <div className='assistant-step-inner'>
      <div
        className={`assistant-step-visual visual-${props.visual ?? 'none'}`}
      />
      <div className='assistant-step-content'>
        <div className='assistant-step-depiction'>{props.depiction}</div>
        {props.children}
      </div>
    </div>
    <div className='assistant-step-actions'>{props.actions}</div>
  </div>
)

export default AssistantStepScreen
