import { Button } from '@nextbusiness/infinity-ui'
import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import { useMixpanelEvent } from 'integrations/useMixpanelEvent'
import { CurrentLedgerNavigationStackRef } from 'ledger/LedgerNavigationStack'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import ChartOfAccountsActions from './ChartOfAccountsActions'
import ChartOfAccountsList from './ChartOfAccountsList'
import './ChartOfAccountsPage.scss'

interface ChartOfAccountsPageProps {
  onDismiss: () => void
}

const ChartOfAccountsPage = (props: ChartOfAccountsPageProps) => {
  const { ledgerStore } = useRootStore()
  const [showAllAccounts, setShowAllAccounts] = useState<boolean>(false)

  useMixpanelEvent('Chart of accounts viewed')

  return (
    <MagicSheetPage
      header={
        <MagicSheetTitleBar title='Kontenplan'>
          <Button onClick={props.onDismiss}>Fertig</Button>
        </MagicSheetTitleBar>
      }
    >
      <MagicSheetContainer>
        <ChartOfAccountsActions
          showAllAccounts={showAllAccounts}
          setShowAllAccounts={setShowAllAccounts}
        />
        <ChartOfAccountsList
          showAllAccounts={showAllAccounts}
          openAccount={(account) => {
            if (ledgerStore.currentRootView === account) {
              CurrentLedgerNavigationStackRef?.goToRootView()
            }
            ledgerStore.setCurrentRootView(account)
          }}
        />
      </MagicSheetContainer>
    </MagicSheetPage>
  )
}

export default observer(ChartOfAccountsPage)
