import {
  LegalType,
  VATCalculationMode,
} from '@nextbusiness/infinity-finance-api'
import React, { createContext, useContext, useMemo, useState } from 'react'
import DateUtilities from 'utility/DateUtilites'
import { OnboardingStep } from './OnboardingStepper'

export enum FiscalYearType {
  CalendarYear = 'calendar-year',
  CustomYear = 'custom',
  CustomLongOrShortYear = 'custom-long-or-short',
}

export enum UsageType {
  Startup = 'startup',
  SelfEmployed = 'self-employed',
  SmallBusiness = 'small-business',
  Accountant = 'accountant',
}

type InitialSetupContextType = {
  isOnboardingCardExpanded: boolean
  expandOnboardingCard: () => void
  shouldValidate: boolean
  setShouldValidate: (shouldValidate: boolean) => void
  hasEnabledCommunications: boolean
  setHasEnabledCommunications: (hasEnabledCommunications: boolean) => void
  isVATEnabled?: boolean
  setIsVATEnabled: (isVATEnabled: boolean) => void
  vatRegistrationNumber: string
  setVatRegistrationNumber: (vatRegistrationNumber: string) => void
  vatCalculationMode: VATCalculationMode
  setVatCalculationMode: (vatCalculationMode: VATCalculationMode) => void
  fiscalYearType?: FiscalYearType
  setFiscalYearType: (fiscalYearType: FiscalYearType) => void
  fiscalYearStart?: Date
  setFiscalYearStart: (fiscalYearStart: Date | undefined) => void
  fiscalYearEnd?: Date
  setFiscalYearEnd: (fiscalYearEnd: Date | undefined) => void
  currentStep: OnboardingStep
  setCurrentStep: (currentStep: OnboardingStep) => void
  legalType?: LegalType
  setLegalType: (legalType: LegalType) => void
  usageType?: UsageType
  setUsageType: (usageType: UsageType) => void
}

const InitialSetupContext = createContext<InitialSetupContextType | null>(null)

type InitialSetupContextProviderProps = {
  children?: React.ReactNode
}

export const InitialSetupContextProvider = ({
  children,
}: InitialSetupContextProviderProps) => {
  const [isOnboardingCardExpanded, setIsOnboardingCardExpanded] =
    useState<boolean>(false)
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)

  const [hasEnabledCommunications, setHasEnabledCommunications] =
    useState<boolean>(true)
  const [legalType, setLegalType] = useState<LegalType>(
    LegalType.SoleProprietorship
  )
  const [usageType, setUsageType] = useState<UsageType>()

  const [isVATEnabled, setIsVATEnabled] = useState<boolean>()
  const [vatRegistrationNumber, setVatRegistrationNumber] = useState<string>('')
  const [vatCalculationMode, setVatCalculationMode] =
    useState<VATCalculationMode>(VATCalculationMode.Effective)

  const [fiscalYearType, setFiscalYearType] = useState<FiscalYearType>(
    FiscalYearType.CalendarYear
  )
  const [fiscalYearStart, setFiscalYearStart] = useState<Date | undefined>(
    DateUtilities.startOfYear()
  )
  const [fiscalYearEnd, setFiscalYearEnd] = useState<Date | undefined>(
    DateUtilities.yearsAfter(fiscalYearStart!, 1, -1)
  )

  const [currentStep, setCurrentStep] = useState<OnboardingStep>(
    OnboardingStep.UsageType
  )

  const contextValue: InitialSetupContextType = useMemo(
    () => ({
      isOnboardingCardExpanded,
      expandOnboardingCard: () => setIsOnboardingCardExpanded(true),
      shouldValidate,
      setShouldValidate,
      hasEnabledCommunications,
      setHasEnabledCommunications,
      isVATEnabled,
      setIsVATEnabled,
      vatRegistrationNumber,
      setVatRegistrationNumber,
      vatCalculationMode,
      setVatCalculationMode,
      fiscalYearType,
      setFiscalYearType,
      fiscalYearStart,
      setFiscalYearStart,
      fiscalYearEnd,
      setFiscalYearEnd,
      currentStep,
      setCurrentStep,
      legalType,
      setLegalType,
      usageType,
      setUsageType,
    }),
    [
      isOnboardingCardExpanded,
      shouldValidate,
      hasEnabledCommunications,
      isVATEnabled,
      vatRegistrationNumber,
      vatCalculationMode,
      fiscalYearType,
      fiscalYearStart,
      fiscalYearEnd,
      currentStep,
      legalType,
      usageType,
    ]
  )

  return (
    <InitialSetupContext.Provider value={contextValue}>
      {children}
    </InitialSetupContext.Provider>
  )
}

export const useInitialSetupContext = () => {
  const context = useContext(InitialSetupContext)
  if (!context) {
    throw new Error(
      'useInitialSetupContext must be used within a InitialSetupContextProvider'
    )
  }
  return context
}
