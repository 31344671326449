import { Finance, ITemplate } from '@nextbusiness/infinity-finance-api'
import TemplateInspectorBackgroundUploadArea from './TemplateInspectorBackgroundUploadArea'

export interface TemplateInspectorBackgroundUploadProps {
  template: ITemplate
  reloadTemplate: () => void
}

const TemplateInspectorCoverBackgroundUpload = (
  props: TemplateInspectorBackgroundUploadProps
) => (
  <TemplateInspectorBackgroundUploadArea
    kind='Hintergrund für erste Seite'
    uploadBackground={(upload) =>
      Finance.CustomerInvoice.uploadTemplateBackground(
        props.template.id,
        upload.file
      )
    }
    removeBackground={() =>
      Finance.CustomerInvoice.resetTemplateBackground(props.template.id)
    }
    hasBackground={props.template.backgroundUrl !== undefined}
    onSuccessfulUpload={props.reloadTemplate}
    hideUpgradeButton
  />
)

export default TemplateInspectorCoverBackgroundUpload
