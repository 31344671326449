import { Finance } from '@nextbusiness/infinity-finance-api'
import ErrorScreen from 'base-components/layout/ErrorScreen'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import useAPIQuery from 'libs/networking/APIQuery'
import DocumentsPage from 'pages/DocumentsPage'
import CreateExpenditureScreen from './CreateExpenditureScreen'
import './CreateExpenditureView.scss'

interface CreateExpenditureViewProps {
  page: DocumentsPage
}

const CreateExpenditureView = (props: CreateExpenditureViewProps) => {
  const [document, isLoading, error, retry] = useAPIQuery(() =>
    Finance.Documents.document(props.page.currentParam!)
  )

  const onDismiss = () => props.page.popCurrentView()
  if (error)
    return (
      <ErrorScreen
        error={error}
        title='Der Beleg konnte nicht geladen werden.'
        onRetry={retry}
      />
    )

  return isLoading ? (
    <LoadingScreen />
  ) : (
    document && (
      <CreateExpenditureScreen document={document} onDismiss={onDismiss} />
    )
  )
}

export default CreateExpenditureView
