import { MoneyField } from '@nextbusiness/infinity-ui'

type ValueSign = 'plus' | 'minus' | 'equals'

interface VATFormHeader2Props {
  title: string
  digitColumn1?: string
  digitColumn2?: string
  value1?: number
  value2?: number
  value1Sign?: ValueSign
  value2Sign?: ValueSign
}

const VATFormRow = (props: VATFormHeader2Props) => (
  <div className='form-row'>
    <div className='title'>{props.title}</div>
    <div className='digit-column-1'>{props.digitColumn1}</div>
    <div className='value-column-1'>
      <div className='sign'>
        {props.value1Sign === 'plus' && '+'}
        {props.value1Sign === 'minus' && '–'}
        {props.value1Sign === 'equals' && '='}
      </div>
      <div className='value'>
        {props.value1 !== undefined && (
          <MoneyField value={props.value1 / 100} onChange={() => {}} disabled />
        )}
      </div>
    </div>
    <div className='value-column-2'>
      <div className='sign'>
        {props.value2Sign === 'plus' && '+'}
        {props.value2Sign === 'minus' && '–'}
        {props.value2Sign === 'equals' && '='}
      </div>
      <div className='value'>
        {props.value2 !== undefined && (
          <MoneyField value={props.value2 / 100} onChange={() => {}} disabled />
        )}
      </div>
    </div>
    <div className='digit-column-2'>{props.digitColumn2}</div>
  </div>
)

export default VATFormRow
