import { motion } from 'framer-motion'
import React from 'react'

interface TransactionManualReconciliationLayoutProps {
  isManualReconciliationOpen: boolean
  children?: React.ReactNode
}

const TransactionManualReconciliationLayout = (
  props: TransactionManualReconciliationLayoutProps
) => (
  <motion.div
    className='manual-reconciliation'
    layout
    layoutRoot
    variants={{
      closed: { width: 'auto' },
      open: { width: '30rem' },
    }}
    transition={{
      duration: 0.2,
      ease: [0.22, 1, 0.36, 1],
    }}
    animate={props.isManualReconciliationOpen ? 'open' : 'closed'}
  >
    {props.children}
  </motion.div>
)

export default TransactionManualReconciliationLayout
