import {
  APIError,
  Finance,
  INewCurrency,
} from '@nextbusiness/infinity-finance-api'
import { useNotificationCenter } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import EditOrCreateCurrencyModal from './EditOrCreateCurrencyModal'

interface AddCurrencyModalProps {
  isOpen: boolean
  onDismiss: () => void
}

const AddCurrencyModal = (props: AddCurrencyModalProps) => {
  const { currencyStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const addNewCurrency = async (
    newCurrency: INewCurrency
  ): Promise<boolean> => {
    try {
      await Finance.Currencies.createCurrency(newCurrency)
      await currencyStore.loadCurrencies()
      return true
    } catch (error: any) {
      notificationCenter.addNotification({
        children: (error as APIError).humanText('de'),
        variant: 'error',
      })
      return false
    }
  }

  return (
    <EditOrCreateCurrencyModal
      titleOfModal='Neue Währung'
      saveActionCopy='Währung hinzufügen'
      onSave={(currencyChanges: INewCurrency) => {
        return addNewCurrency(currencyChanges)
      }}
      {...props}
    />
  )
}

export default observer(AddCurrencyModal)
