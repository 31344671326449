import ContentLoader from 'react-content-loader'

const AccountTitleBarAmountLoader = () => (
  <ContentLoader
    width={100}
    height={24}
    viewBox='0 0 100 24'
    backgroundColor='#f0f0f0'
    foregroundColor='#dedede'
  >
    <rect x='0' y='8' rx='4' ry='4' width='94' height='16' />
  </ContentLoader>
)

export default AccountTitleBarAmountLoader
