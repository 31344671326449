import { InputField, MoneyField } from '@nextbusiness/infinity-ui'

interface HourlyRateFieldsProps {
  hours: number | undefined
  minutes: number | undefined
  hourlyRate: number | null
  setHours: (hours: number) => void
  setMinutes: (minutes: number) => void
  setHourlyRate: (hourlyRate: number | null) => void
  handleEnter: (event: React.KeyboardEvent<HTMLInputElement>) => void
  hasEnteredInvalidMinutes: boolean
}

const HourlyRateFields = (props: HourlyRateFieldsProps) => {
  const parsePositiveTwoDigitInt = (input: string) =>
    Math.abs(parseInt(input.replaceAll('-', '').substring(0, 2)))
  const setHours = (input: string) =>
    props.setHours(parsePositiveTwoDigitInt(input))
  const setMinutes = (input: string) =>
    props.setMinutes(parsePositiveTwoDigitInt(input))

  return (
    <>
      <div className='hours'>
        <InputField
          placeholder='0'
          className='hours-input'
          value={props.hours?.toString() ?? ''}
          onChange={setHours}
          type='number'
          onKeyDown={props.handleEnter}
        />
        <span className='hours-label'>h</span>
      </div>
      <div className='minutes'>
        <InputField
          placeholder='0'
          className='minutes-input'
          value={props.minutes?.toString() ?? ''}
          onChange={setMinutes}
          type='number'
          onKeyDown={props.handleEnter}
          hasError={props.hasEnteredInvalidMinutes}
        />
        <span className='minutes-label'>min</span>
      </div>
      <MoneyField
        className='hourly-rate'
        placeholder='Satz'
        value={props.hourlyRate}
        onChange={(input) => props.setHourlyRate(input)}
        currency='CHF/h'
        onKeyDown={props.handleEnter}
      />
    </>
  )
}

export default HourlyRateFields
