import { SpecialAccount } from 'components/transactions/SpecialAccounts'
import { AccountType, IAccount } from 'model/Account'
import { KeyValueStore } from 'utility/types'

type AccountNumberRange = [number, number]
type AccountNumber = number

export type SetOfAccounts = (AccountNumber | AccountNumberRange | AccountType)[]
export type EntrySide = 'debit' | 'credit' | 'ask'

export const translatedAccountTypes: KeyValueStore = {
  assets: 'Vermögen',
  liabilities: 'Schulden',
  income: 'Erträge',
  expense: 'Aufwände',
}

type AccountSetSuggestion = { title: string; accounts: SetOfAccounts }
type SetOfSuggestedAccounts = (AccountSetSuggestion | AccountType)[]
export type ResolvedSetOfSuggestedAccounts = AccountSetSuggestion[]

export interface AccountAction {
  displayName: string | ((account: IAccount | SpecialAccount) => string)
  suggestedAccounts:
    | SetOfSuggestedAccounts
    | ((account: IAccount | SpecialAccount) => SetOfSuggestedAccounts)
  currentAccountSide: EntrySide
  isTertiaryAction?: boolean
}

interface RuleSet {
  ruleDescription?: string
  rulePriority?: number
  appliesToAccounts: SetOfAccounts
  actions: AccountAction[]
  discourageEditingWithDisclaimer?: string
}

export interface ResolvedAccountAction extends AccountAction {
  displayName: string
  suggestedAccounts: ResolvedSetOfSuggestedAccounts
}

export interface ResolvedRuleSet extends RuleSet {
  actions: ResolvedAccountAction[]
}

const AccountRules: RuleSet[] = [
  {
    ruleDescription: 'Flüssige Mittel',
    appliesToAccounts: [[1000, 1059]],
    actions: [
      {
        displayName: 'Neue Einnahme',
        suggestedAccounts: ['income'],
        currentAccountSide: 'debit',
      },
      {
        displayName: 'Neue Ausgabe',
        suggestedAccounts: ['expense'],
        currentAccountSide: 'credit',
      },
      {
        displayName: 'Neuer Übertrag',
        suggestedAccounts: ['assets'],
        currentAccountSide: 'ask',
        isTertiaryAction: true,
      },
    ],
  },
  {
    ruleDescription: 'Forderungen aus L+L',
    appliesToAccounts: [1100],
    discourageEditingWithDisclaimer:
      'Dieses Konto wird automatisch bebucht. Wechsle zu Forderungen, wenn du eine neue Forderung gegenüber deinen Kunden eintragen möchtest.',
    actions: [
      {
        displayName: 'Neue Forderung',
        suggestedAccounts: ['income'],
        currentAccountSide: 'debit',
      },
      {
        displayName: 'Neuer Zahlungseingang',
        suggestedAccounts: [
          { title: 'Flüssige Mittel', accounts: [[1000, 1059]] },
        ],
        currentAccountSide: 'credit',
        isTertiaryAction: true,
      },
    ],
  },
  {
    ruleDescription: 'Wertberichtigungen (Minusaktivkonti)',
    appliesToAccounts: [
      1069, 1109, 1149, 1409, 1449, 1489, 1509, 1519, 1529, 1539, 1549, 1609,
      1709, 1779,
    ],
    rulePriority: 10,
    actions: [
      {
        displayName: 'Neue Abschreibung',
        suggestedAccounts: (account) => {
          return [
            {
              title: 'Abschreibungsaufwände',
              accounts:
                account.accountNumber === 1109 ? [3805] : [[6800, 6899]],
            },
          ]
        },
        currentAccountSide: 'credit',
      },
      {
        displayName: 'Neue Verrechnung',
        suggestedAccounts: (account) => {
          return (
            {
              1069: [{ title: 'Anlagevermögen', accounts: [1060] }],
              1109: [{ title: 'Anlagevermögen', accounts: [1100] }],
              1149: [{ title: 'Anlagevermögen', accounts: [1140] }],
              1409: [{ title: 'Anlagevermögen', accounts: [1400] }],
              1449: [{ title: 'Anlagevermögen', accounts: [1440, 1441] }],
              1489: [{ title: 'Anlagevermögen', accounts: [1480] }],
              1509: [{ title: 'Anlagevermögen', accounts: [1500] }],
              1519: [{ title: 'Anlagevermögen', accounts: [1510] }],
              1529: [{ title: 'Anlagevermögen', accounts: [1520] }],
              1539: [{ title: 'Anlagevermögen', accounts: [1530] }],
              1549: [{ title: 'Anlagevermögen', accounts: [1540] }],
              1609: [{ title: 'Anlagevermögen', accounts: [1600] }],
              1709: [{ title: 'Anlagevermögen', accounts: [1700] }],
              1779: [{ title: 'Anlagevermögen', accounts: [1770] }],
            }[account.accountNumber] ?? ['assets']
          )
        },
        currentAccountSide: 'debit',
        isTertiaryAction: true,
      },
    ],
  },
  {
    ruleDescription: 'Vorsteuer MWST',
    appliesToAccounts: [[1170, 1171]],
    rulePriority: 10,
    discourageEditingWithDisclaimer:
      'Dieses Konto wird automatisch bebucht. Wechsle zu Forderungen, wenn du eine neue Forderung gegenüber deinen Kunden eintragen möchtest.',
    actions: [
      {
        displayName: 'Neue Vorsteuerforderung',
        suggestedAccounts: [
          { title: 'Flüssige Mittel', accounts: [[1000, 1059]] },
        ],
        currentAccountSide: 'debit',
      },
      {
        displayName: 'Neue Verrechnung',
        suggestedAccounts: [
          { title: 'Abrechnungskonto MWST', accounts: [2201] },
        ],
        currentAccountSide: 'credit',
        isTertiaryAction: true,
      },
    ],
  },
  {
    ruleDescription: 'Vorräte',
    appliesToAccounts: [[1200, 1299]],
    actions: [
      {
        displayName: 'Neuer Zugang',
        suggestedAccounts: [
          { title: 'Flüssige Mittel', accounts: [[1000, 1059]] },
        ],
        currentAccountSide: 'debit',
      },
      {
        displayName: 'Neuer Abgang',
        suggestedAccounts: (account) => {
          if (account.accountNumber >= 1200 && account.accountNumber < 1210) {
            return [{ title: 'Handelswarenaufwände', accounts: [[4200, 4399]] }]
          } else if (
            account.accountNumber >= 1210 &&
            account.accountNumber < 1250
          ) {
            return [{ title: 'Materialaufwände', accounts: [[4000, 4199]] }]
          } else {
            return [
              {
                title:
                  'Aufwand für Material, Handelswaren, Dienstleistungen und Energie',
                accounts: [[4000, 4899]],
              },
            ]
          }
        },
        currentAccountSide: 'credit',
        isTertiaryAction: true,
      },
    ],
  },
  {
    ruleDescription:
      'Transitorische Aktiven: Bezahlter Aufwand des Folgejahres',
    appliesToAccounts: [1300],
    actions: [
      {
        displayName: 'Neue Abgrenzung',
        currentAccountSide: 'debit',
        suggestedAccounts: ['expense'],
      },
      {
        displayName: 'Andere Buchung',
        currentAccountSide: 'ask',
        suggestedAccounts: [],
        isTertiaryAction: true,
      },
    ],
  },
  {
    ruleDescription: 'Transitorische Aktiven: Noch nicht erhaltener Ertrag',
    appliesToAccounts: [1301],
    actions: [
      {
        displayName: 'Neue Abgrenzung',
        currentAccountSide: 'debit',
        suggestedAccounts: ['income'],
      },
      {
        displayName: 'Andere Buchung',
        currentAccountSide: 'ask',
        suggestedAccounts: [],
        isTertiaryAction: true,
      },
    ],
  },
  {
    ruleDescription: 'Anlagevermögen',
    appliesToAccounts: [[1400, 1999]],
    actions: [
      {
        displayName: 'Neue Anlage',
        currentAccountSide: 'debit',
        suggestedAccounts: [
          { title: 'Flüssige Mittel', accounts: [[1000, 1059]] },
        ],
      },
      {
        displayName: 'Neuer Abgang',
        currentAccountSide: 'credit',
        suggestedAccounts: [
          { title: 'Flüssige Mittel', accounts: [[1000, 1059]] },
          {
            title: 'Ausserordentlicher Aufwand und Ertrag',
            accounts: [8500, 8510],
          },
        ],
        isTertiaryAction: true,
      },
    ],
  },
  {
    ruleDescription: 'Verbindlichkeiten aus L+L',
    appliesToAccounts: [2000],
    discourageEditingWithDisclaimer:
      'Dieses Konto wird automatisch bebucht. Erstelle stattdessen eine Rechnung, wenn du eine neue Verbindlichkeit eintragen möchtest.',
    actions: [
      {
        displayName: 'Neue Verbindlichkeit',
        currentAccountSide: 'credit',
        suggestedAccounts: ['expense'],
      },
      {
        displayName: 'Neuer Zahlungsausgang',
        currentAccountSide: 'debit',
        suggestedAccounts: [
          { title: 'Flüssige Mittel', accounts: [[1000, 1059]] },
        ],
        isTertiaryAction: true,
      },
    ],
  },
  {
    ruleDescription: 'Andere Verbindlichkeiten',
    appliesToAccounts: [
      [2100, 2299],
      [2400, 2599],
    ],
    actions: [
      {
        displayName: 'Neue Verbindlichkeit',
        currentAccountSide: 'credit',
        suggestedAccounts: [
          { title: 'Flüssige Mittel', accounts: [[1000, 1059]] },
        ],
      },
      {
        displayName: 'Neue Rückzahlung',
        currentAccountSide: 'debit',
        suggestedAccounts: [
          { title: 'Flüssige Mittel', accounts: [[1000, 1059]] },
        ],
        isTertiaryAction: true,
      },
    ],
  },
  {
    ruleDescription: 'Transitorische Passiven: Noch nicht bezahlter Aufwand',
    appliesToAccounts: [2300],
    actions: [
      {
        displayName: 'Neue Abgrenzung',
        currentAccountSide: 'credit',
        suggestedAccounts: ['expense'],
      },
      {
        displayName: 'Andere Buchung',
        currentAccountSide: 'ask',
        suggestedAccounts: [],
        isTertiaryAction: true,
      },
    ],
  },
  {
    ruleDescription:
      'Transitorische Passiven: Erhaltener Ertrag des Folgejahres',
    appliesToAccounts: [2301],
    actions: [
      {
        displayName: 'Neue Abgrenzung',
        currentAccountSide: 'credit',
        suggestedAccounts: ['income'],
      },
      {
        displayName: 'Andere Buchung',
        currentAccountSide: 'ask',
        suggestedAccounts: [],
        isTertiaryAction: true,
      },
    ],
  },
  {
    ruleDescription: 'Rückstellungen',
    appliesToAccounts: [
      [2330, 2399],
      [2600, 2799],
    ],
    actions: [
      {
        displayName: 'Neue Rückstellung',
        currentAccountSide: 'credit',
        suggestedAccounts: ['expense'],
      },
      {
        displayName: 'Neue Auszahlung oder Auflösung',
        currentAccountSide: 'debit',
        suggestedAccounts: [
          { title: 'Flüssige Mittel', accounts: [[1000, 1059]] },
          {
            title: 'Auflösung',
            accounts: [8510],
          },
        ],
        isTertiaryAction: true,
      },
    ],
  },
  {
    ruleDescription: 'Aufwände',
    appliesToAccounts: ['expense'],
    actions: [
      {
        displayName: 'Neuer Aufwand',
        currentAccountSide: 'debit',
        suggestedAccounts: [
          { title: 'Flüssige Mittel', accounts: [[1000, 1059]] },
        ],
      },
      {
        displayName: 'Andere Buchung',
        currentAccountSide: 'ask',
        suggestedAccounts: [],
        isTertiaryAction: true,
      },
    ],
  },
  {
    ruleDescription: 'Erträge',
    appliesToAccounts: ['income'],
    actions: [
      {
        displayName: 'Neuer Ertrag',
        currentAccountSide: 'credit',
        suggestedAccounts: [
          { title: 'Flüssige Mittel', accounts: [[1000, 1059]] },
        ],
      },
      {
        displayName: 'Andere Buchung',
        currentAccountSide: 'ask',
        suggestedAccounts: [],
        isTertiaryAction: true,
      },
    ],
  },
  {
    ruleDescription: 'Erlösminderungen',
    appliesToAccounts: [[3800, 3899]],
    rulePriority: 1,
    actions: [
      {
        displayName: 'Neue Erlösminderung',
        currentAccountSide: 'debit',
        suggestedAccounts: [
          { title: 'Flüssige Mittel', accounts: [[1000, 1059]] },
          { title: 'Verluste aus Forderungen', accounts: [1109] },
        ],
      },
      {
        displayName: 'Andere Buchung',
        currentAccountSide: 'ask',
        suggestedAccounts: [],
        isTertiaryAction: true,
      },
    ],
  },
  {
    ruleDescription: 'Aufwandsminderungen',
    appliesToAccounts: [[4900, 4999]],
    rulePriority: 1,
    actions: [
      {
        displayName: 'Neue Aufwandsminderung',
        currentAccountSide: 'credit',
        suggestedAccounts: [
          { title: 'Flüssige Mittel', accounts: [[1000, 1059]] },
        ],
      },
      {
        displayName: 'Andere Buchung',
        currentAccountSide: 'ask',
        suggestedAccounts: [],
        isTertiaryAction: true,
      },
    ],
  },
]

export const DefaultAccountRules: { [key: string]: RuleSet } = {
  assets: {
    ruleDescription: 'Aktivkonto',
    appliesToAccounts: ['assets'],
    rulePriority: -1,
    actions: [
      {
        displayName: 'Neuer Vermögenswert',
        currentAccountSide: 'debit',
        suggestedAccounts: [],
      },
      {
        displayName: 'Neuer Abgang',
        currentAccountSide: 'credit',
        suggestedAccounts: [],
      },
    ],
  },
  liabilities: {
    ruleDescription: 'Passivkonten',
    appliesToAccounts: ['liabilities'],
    rulePriority: -1,
    actions: [
      {
        displayName: 'Neue Schuld',
        currentAccountSide: 'credit',
        suggestedAccounts: [],
      },
      {
        displayName: 'Neuer Abgang',
        currentAccountSide: 'debit',
        suggestedAccounts: [],
      },
    ],
  },
  expense: {
    ruleDescription: 'Aufwandskonten',
    appliesToAccounts: ['expense'],
    rulePriority: -1,
    actions: [
      {
        displayName: 'Neuer Aufwand',
        currentAccountSide: 'debit',
        suggestedAccounts: [],
      },
      {
        displayName: 'Andere Buchung',
        currentAccountSide: 'ask',
        suggestedAccounts: [],
        isTertiaryAction: true,
      },
    ],
  },
  income: {
    ruleDescription: 'Ertragskonten',
    appliesToAccounts: ['income'],
    rulePriority: -1,
    actions: [
      {
        displayName: 'Neuer Ertrag',
        currentAccountSide: 'credit',
        suggestedAccounts: [],
      },
      {
        displayName: 'Andere Buchung',
        currentAccountSide: 'ask',
        suggestedAccounts: [],
        isTertiaryAction: true,
      },
    ],
  },
}

export const doesAccountMatchFilter = (
  account: IAccount | SpecialAccount,
  filter: SetOfAccounts
) => {
  let matches = false
  filter.forEach((set) => {
    if (matches) return
    switch (typeof set) {
      case 'string':
        if (isDefinedAccount(account) && set === account.accountType)
          matches = true
        break
      case 'number':
        if (set === account.accountNumber) matches = true
        break
      case 'object':
        const rangeFrom = set[0]
        const rangeTo = set[1]
        if (
          account.accountNumber >= rangeFrom &&
          account.accountNumber <= rangeTo
        )
          matches = true
        break
    }
  })
  return matches
}

const resolveRuleSet = (
  resolveForAccount: IAccount | SpecialAccount,
  ruleSet?: RuleSet
): ResolvedRuleSet => {
  if (!ruleSet)
    return {
      actions: [],
      appliesToAccounts: [],
      ruleDescription: 'Other accounts',
      rulePriority: -100,
    }
  return {
    ...ruleSet,
    actions: ruleSet.actions.map((action) => ({
      ...action,
      displayName:
        typeof action.displayName === 'function'
          ? action.displayName(resolveForAccount)
          : action.displayName,
      suggestedAccounts: (typeof action.suggestedAccounts === 'function'
        ? action.suggestedAccounts(resolveForAccount)
        : action.suggestedAccounts
      ).map((suggestion) =>
        typeof suggestion === 'string'
          ? {
              title: translatedAccountTypes[suggestion],
              accounts: [suggestion],
            }
          : suggestion
      ),
    })),
  }
}

export const isDefinedAccount = (
  account: IAccount | SpecialAccount
): account is IAccount => !!(account as IAccount).id

export const resolveMatchingRulesForAccount = (
  account: IAccount | SpecialAccount
): ResolvedRuleSet => {
  if (!isDefinedAccount(account)) {
    return resolveRuleSet(account, undefined)
  }
  const applicableRules = AccountRules.filter((rule) =>
    doesAccountMatchFilter(account, rule.appliesToAccounts)
  )
  if (applicableRules.length === 0)
    return resolveRuleSet(account, DefaultAccountRules[account.accountType])

  applicableRules.sort((a, b) => (b.rulePriority ?? 0) - (a.rulePriority ?? 0))
  return resolveRuleSet(account, applicableRules[0])
}
