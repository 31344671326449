import { Flex, InputField } from '@nextbusiness/infinity-ui'
import { TemplateInspectorProps } from '../TemplateInspector'
import TemplateInspectorGroup from '../TemplateInspectorGroup'

const TemplateInspectorSender = (props: TemplateInspectorProps) => (
  <TemplateInspectorGroup title='Absender'>
    <InputField
      placeholder='Firmenname'
      value={props.template.organisationName}
      onChange={(organisationName) =>
        props.modifyTemplate({ organisationName })
      }
      fullWidth
    />
    <InputField
      placeholder='Adresszeile'
      value={props.template.addressLine}
      onChange={(addressLine) => props.modifyTemplate({ addressLine })}
      fullWidth
    />
    <Flex gap='tiny'>
      <InputField
        placeholder='PLZ'
        type='number'
        value={String(props.template.zipCode ?? '')}
        onChange={(zipCode) =>
          props.modifyTemplate({ zipCode: parseInt(zipCode) || undefined })
        }
        fullWidth
        style={{ width: '45%' }}
      />
      <InputField
        placeholder='Ort'
        value={props.template.city}
        onChange={(city) => props.modifyTemplate({ city })}
        fullWidth
      />
    </Flex>
  </TemplateInspectorGroup>
)

export default TemplateInspectorSender
