import { useEffect } from 'react'
import MixpanelAnalytics from './MixpanelProductAnalytics'

export const useMixpanelEvent = (
  eventName: string,
  properties?: Record<string, any>
) => {
  useEffect(() => {
    MixpanelAnalytics.event(eventName, properties)
  }, [eventName, properties])
}
