import { useEffect } from 'react'

export const usePeriodRefresh = (
  action: () => Promise<void>,
  intervalInSeconds = 10
) => {
  useEffect(() => {
    const periodicRefresh = setInterval(async () => {
      try {
        await action()
      } catch (error) {
        // Fail silently
      }
    }, intervalInSeconds * 1000)
    return () => clearInterval(periodicRefresh)
  }, [])
}
