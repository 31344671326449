import { Finance, ITransaction } from '@nextbusiness/infinity-finance-api'
import { Flex } from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'
import { RouterHistory } from '@nextbusiness/infinity-ui/dist/layout/header/HeaderNavigationTabs'
import SpecialAccounts from 'components/transactions/SpecialAccounts'
import { CurrentLedgerNavigationStackRef } from 'ledger/LedgerNavigationStack'
import { store } from 'stores/RootStoreContext'
import Utilities from 'utility/Utilities'
import EverythingSearchProviderResult from '../EverythingSearchProviderResult'
import EverythingSearchResultsProvider from '../EverythingSearchResultsProvider'
import './TransactionSearchProvider.scss'

class TransactionSearchProvider implements EverythingSearchResultsProvider {
  providerName = 'Buchung'

  private resultForTransaction(
    transaction: ITransaction
  ): EverythingSearchProviderResult {
    const debitAccount =
      store.accountStore.find(transaction.debitAccount) ??
      SpecialAccounts.find(transaction.debitAccount)
    const creditAccount =
      store.accountStore.find(transaction.creditAccount) ??
      SpecialAccounts.find(transaction.creditAccount)

    return {
      id: transaction.id,
      title: transaction.description,
      type: 'transaction',
      icon: 'transaction-list',
      reference: transaction.debitAccount,
      details: (
        <div className='transaction-preview'>
          <Flex
            verticalAlignment='center'
            gap='tiny'
            className='preview-accounts'
          >
            <div className='account-preview'>
              <div
                className='account-swatch'
                style={{ backgroundColor: debitAccount?.colour }}
              />
              <span>{debitAccount?.name}</span>
            </div>
            <Icon icon='arrow-left' size={14} />
            <div className='account-preview'>
              <div
                className='account-swatch'
                style={{ backgroundColor: creditAccount?.colour }}
              />
              <span>{creditAccount?.name}</span>
            </div>
          </Flex>
          <Flex className='preview-second-row'>
            <div className='preview-date'>
              {new Date(transaction.date).toLocaleDateString('de-CH', {
                dateStyle: 'short',
              })}
            </div>
            <div className='preview-amount'>
              {Utilities.centsToCHF(transaction.amount)} CHF
            </div>
          </Flex>
        </div>
      ),
    }
  }

  async resultsForQuery(
    query: string
  ): Promise<EverythingSearchProviderResult[]> {
    const results: EverythingSearchProviderResult[] = []

    if (query.length > 2) {
      try {
        const transactions = await Finance.Ledger.transactions(
          {
            description: query,
          },
          {
            sortingOrder: 'desc',
            orderBy: 'date',
            limit: 5,
          }
        )
        results.push(
          ...transactions.map((transaction) =>
            this.resultForTransaction(transaction)
          )
        )
      } catch {
        // Don't bother
      }
    }
    return results
  }

  onResultSelected(
    result: EverythingSearchProviderResult,
    history?: RouterHistory
  ) {
    const accountNumber = result.reference as number
    if (CurrentLedgerNavigationStackRef) {
      CurrentLedgerNavigationStackRef?.switchRootToAccount(accountNumber)
    } else {
      store.ledgerStore.setCurrentRootView(accountNumber)
      history?.push('/buchen')
    }
  }
}

export default TransactionSearchProvider
