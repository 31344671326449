import './ExtendedInvoiceIcon.scss'

interface ExtendedInvoiceIconContainerProps {
  children?: React.ReactNode
  small?: boolean
}

const ExtendedInvoiceIconContainer = (
  props: ExtendedInvoiceIconContainerProps
) => (
  <div className={'extended-invoice-icon' + (props.small ? ' small' : '')}>
    {props.children}
  </div>
)

export default ExtendedInvoiceIconContainer
