export interface Bank {
  iid: number
  bankOrInstitutionName: string
  bic: string
}

class BankLookup {
  private SIX_BANKING_MASTER = '/data/bank-lookup.json'
  private banks: Bank[] | null = null

  private async ensureData() {
    if (this.banks === null) this.banks = await this.getBanks()
  }

  private async getBanks(): Promise<Bank[]> {
    const response = await fetch(this.SIX_BANKING_MASTER)
    const data = await response.json()

    return data as Bank[]
  }

  public async getBankByIid(iid: string): Promise<Bank | null> {
    try {
      const numericIid = parseInt(iid, 10)
      await this.ensureData()
      return this.banks?.find((bank) => bank.iid === numericIid) ?? null
    } catch {
      return null
    }
  }

  public async getBankByBic(bic: string): Promise<Bank | null> {
    await this.ensureData()
    return this.banks?.find((bank) => bank.bic === bic) ?? null
  }
}

const sharedBankLookup = new BankLookup()

export default sharedBankLookup
