import { Flex, Text } from '@nextbusiness/infinity-ui'
import VendorInvoiceDetailDateRow from './VendorInvoiceDetailDateRow'
import VendorInvoiceDetailPaymentRow from './VendorInvoiceDetailPaymentRow'
import VendorInvoiceDetailTransactions from './VendorInvoiceDetailTransactions'

const VendorInvoiceDetailTabPayments = () => (
  <Flex direction='vertical'>
    <Text>
      Hier findest du alle Zahlungen, die du für diese Rechnung verbucht hast.
    </Text>
    <VendorInvoiceDetailPaymentRow />
    <VendorInvoiceDetailDateRow />
    <VendorInvoiceDetailTransactions />
  </Flex>
)

export default VendorInvoiceDetailTabPayments
