import Lozenge from './Lozenge'

interface BetaLozengeProps {
  prominent?: boolean
}

const BetaLozenge = (props: BetaLozengeProps) => (
  <Lozenge small inline tint='lavender' {...props}>
    Beta
  </Lozenge>
)

export default BetaLozenge
