import { Button, NonIdealState } from '@nextbusiness/infinity-ui'
import { IconItem, IconSize } from '@nextbusiness/infinity-ui-icons'
import { useContext } from 'react'
import ContactUtilities from 'utility/ContactUtilites'
import { ContactContext } from './ContactContext'
import './ContactListEmptyState.scss'

interface ContactListEmptyStateProps {
  listType: 'projects' | 'quotes' | 'customerInvoices' | 'vendorInvoices'
  onActionClick: () => void
}

const ContactListEmptyState = (props: ContactListEmptyStateProps) => {
  const contact = useContext(ContactContext)
  const personName = ContactUtilities.personName(contact)
  const avatarName = contact?.companyName || personName
  const displayName =
    avatarName === contact?.companyName
      ? avatarName
      : ContactUtilities.personName(contact, true)
  const listTypeMap = {
    projects: {
      title: 'Projekte',
      text: 'Mit Projekten kannst du laufende Arbeiten für Kunden abrechnen.',
      icon: 'outline' as IconItem,
      action: 'Neues Projekt',
    },
    quotes: {
      title: 'Offerten',
      text: 'Offerten sind Angebote, die du an deine Kunden versendest.',
      icon: 'brochure' as IconItem,
      action: 'Neue Offerte',
    },
    customerInvoices: {
      title: 'Forderungen',
      text: 'Forderungen sind Rechnungen, die du an deine Kunden stellst.',
      icon: 'purchase-order' as IconItem,
      action: 'Neue Forderung',
    },
    vendorInvoices: {
      title: 'Rechnungen',
      text: `Trage hier Rechnungen ein, die ${displayName} dir gesendet hat.`,
      icon: 'bill' as IconItem,
      action: 'Rechnung hinzufügen',
    },
  }

  return (
    <NonIdealState
      icon={listTypeMap[props.listType].icon}
      iconSize={IconSize.Medium}
      title={`Noch keine ${listTypeMap[props.listType].title}`}
      className='contact-list-empty-state'
    >
      <div className='contact-list-empty-state-depiction'>
        <div className='contact-list-empty-state-text'>
          {listTypeMap[props.listType].text}
        </div>
        <Button onClick={props.onActionClick} iconLeft='plus'>
          {listTypeMap[props.listType].action}
        </Button>
      </div>
    </NonIdealState>
  )
}

export default ContactListEmptyState
