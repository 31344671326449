import { ErrorCode } from '@nextbusiness/infinity-finance-api'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import { BackendError, ErrorType } from '../libs/networking/Errors'
import { Contact } from '../model/Contact'
import Contacts from '../networking/Contacts'
import RootStore from './RootStore'
import Store from './Store'

export default class ContactStore extends Store {
  @persist('list') @observable allContacts: Contact[] = []
  @persist @observable hasContactsPermissions = true
  @observable contactBeingAttached: Contact | undefined = undefined

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  displayNameForContactWithId(id?: string) {
    if (!id) return ''
    if (id === 'no-contact') return 'Kein Kontakt'
    const contact = this.allContacts.find((contact) => contact.id === id)
    if (contact) {
      return ContactStore.nameOfContact(contact)
    } else if (id.length === 24) {
      return 'Unbekannter Kontakt'
    }
    return id
  }

  async loadContacts() {
    if (
      !this.rootStore.authenticationStore.organisationIdentifier ||
      !this.rootStore.authenticationStore.fabricOrganisationIdentifier ||
      !this.rootStore.authenticationStore.applicationAccessToken
    )
      return this.rootStore.authenticationStore.logout()
    try {
      const allContacts = await Contacts.getAllContacts(
        this.rootStore.authenticationStore.fabricOrganisationIdentifier,
        this.rootStore.authenticationStore.applicationAccessToken
      )
      runInAction(() => {
        this.allContacts = allContacts
        this.hasContactsPermissions = true
      })
    } catch (error: any) {
      if (error.code === ErrorCode.Unauthorised) {
        return this.rootStore.authenticationStore.logout()
      }
      const errorType = (error as BackendError).type
      if (errorType === ErrorType.InsufficientPermissions) {
        this.hasContactsPermissions = false
      } else {
        throw error
      }
    }
  }

  public static nameOfContact(currentContact: Contact) {
    if (!currentContact.firstname && !currentContact.lastname)
      return currentContact.companyName!

    return `${currentContact.firstname ?? ''} ${
      currentContact.lastname ?? ''
    }`.trim()
  }

  @action
  attachContactToNewInvoice = (contact: Contact | undefined) => {
    this.contactBeingAttached = contact
  }

  @computed
  get sorted(): Contact[] {
    if (!this.allContacts) return []
    return [...this.allContacts].sort((a, b) =>
      ContactStore.nameOfContact(a) < ContactStore.nameOfContact(b) ? -1 : 1
    )
  }
}
