import { Button, Flex, Text } from '@nextbusiness/infinity-ui'
import './Tabs.scss'
import TabsCountNumber from './TabsCountNumber'

export type Tab<ID = string> = {
  identifier: ID
  title: string
  countNumber?: number
  onClick?: () => void
}

interface TabsProps<ID> {
  tabs: Tab<ID>[]
  currentTabId: ID
  onTabSelected?: (tab: Tab<ID>) => void
}

const Tabs = <ID,>(props: TabsProps<ID>) => {
  return (
    <Flex className='tabs'>
      {props.tabs.map((tab) => (
        <Button
          key={tab.title}
          variant='shell'
          onClick={() => {
            if (props.onTabSelected) props.onTabSelected(tab)
            if (tab.onClick) tab.onClick()
          }}
          className={`tab ${
            tab.identifier === props.currentTabId ? 'current' : ''
          }`.trim()}
        >
          <Flex
            direction='vertical'
            className='content'
            horizontalAlignment='center'
          >
            <Flex
              gap={0.6}
              direction='horizontal'
              horizontalAlignment='center'
              verticalAlignment='center'
            >
              <Text type='inline' className='title'>
                {tab.title}
              </Text>
              {tab.countNumber !== undefined && (
                <TabsCountNumber number={tab.countNumber} />
              )}
            </Flex>
            <div className='indicator' />
          </Flex>
        </Button>
      ))}
    </Flex>
  )
}

export default Tabs
