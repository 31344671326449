import { ITransaction } from '@nextbusiness/infinity-finance-api'
import React, { useContext, useMemo, useState } from 'react'
import { BatchEditingAction } from './BatchEditingModal'

interface BatchEditingContextType {
  isInMultiEdit: boolean
  selectedBatchEditingAction: BatchEditingAction | undefined
  isBatchActionModalOpen: boolean
  selectedTransactions: string[]
  setIsInMultiEdit: (isInMultiEdit: boolean) => void
  setSelectedBatchEditingAction: (action?: BatchEditingAction) => void
  setIsBatchActionModalOpen: (isOpen: boolean) => void
  setSelectedTransactions: (transactionIds: string[]) => void
  selectTransaction: (transaction: ITransaction) => void
  deselectTransaction: (transaction: ITransaction) => void
}

const BatchEditingContext = React.createContext<BatchEditingContextType | null>(
  null
)

export const useBatchEditingContext = () => {
  const context = useContext(BatchEditingContext)
  if (!context) {
    throw new Error(
      'useBatchEditingContext must be used within an BatchEditingProvider'
    )
  }
  return context
}

const BatchEditingProvider = (props: React.PropsWithChildren) => {
  const [selectedTransactions, setSelectedTransactions] = useState<string[]>([])
  const [isInMultiEdit, setIsInMultiEdit] = useState<boolean>(false)
  const [selectedBatchEditingAction, setSelectedBatchEditingAction] =
    useState<BatchEditingAction>()
  const [isBatchActionModalOpen, setIsBatchActionModalOpen] =
    useState<boolean>(false)

  const selectTransaction = (transaction: ITransaction) =>
    setSelectedTransactions([...selectedTransactions, transaction.id])
  const deselectTransaction = (transaction: ITransaction) =>
    setSelectedTransactions((transactions) =>
      transactions.filter((transactionId) => transactionId !== transaction.id)
    )

  const value: BatchEditingContextType = useMemo(
    () => ({
      isInMultiEdit,
      selectedBatchEditingAction,
      isBatchActionModalOpen,
      selectedTransactions,
      setIsInMultiEdit,
      setSelectedBatchEditingAction,
      setIsBatchActionModalOpen,
      setSelectedTransactions,
      selectTransaction,
      deselectTransaction,
    }),
    [
      isInMultiEdit,
      selectedBatchEditingAction,
      isBatchActionModalOpen,
      selectedTransactions,
    ]
  )

  return (
    <BatchEditingContext.Provider value={value}>
      {props.children}
    </BatchEditingContext.Provider>
  )
}

export default BatchEditingProvider
