import { Text } from '@nextbusiness/infinity-ui'
import { LiveCaptureAttachmentChildProps } from './LiveCaptureAttachment'

const LiveCaptureDocumentCounterparty = (
  props: LiveCaptureAttachmentChildProps
) => {
  const counterparty = props.document.document.analysis.counterpartyName

  return (
    <Text type='inline' className='document-counterparty'>
      {counterparty}
    </Text>
  )
}

export default LiveCaptureDocumentCounterparty
