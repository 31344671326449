interface PublicInvoiceFooterProps {
  organisationName: string
  isQuote?: boolean
}

const PublicInvoiceFooter = (props: PublicInvoiceFooterProps) => (
  <div className='public-invoice-footer'>
    {props.organisationName} versendet{' '}
    {props.isQuote ? 'Offerten' : 'Rechnungen'} digital mit{' '}
    <a
      href='https://www.infinity.swiss/finance?utm_source=invoice-link'
      target='_blank'
      rel='noreferrer'
    >
      Infinity Finance.
    </a>
  </div>
)

export default PublicInvoiceFooter
