import Filter from 'base-components/table/Filter'
import { useVendorInvoicesFilter } from './VendorInvoicesFilterContext'

const VendorInvoiceStatusFilter = () => {
  const { activeFilter, editFilter } = useVendorInvoicesFilter()
  return (
    <Filter
      icon='checked-checkbox'
      label='Status'
      items={[
        { label: 'Unbezahlt', value: 'unpaid', icon: 'bill' },
        { label: 'Bezahlt', value: 'paid', icon: 'paid-bill' },
      ]}
      selectedValue={activeFilter.status}
      onValueSelected={(status) => editFilter({ status })}
    />
  )
}

export default VendorInvoiceStatusFilter
