import { FinancialHealthCardType } from '@nextbusiness/infinity-finance-api'
import { Flex, Text } from '@nextbusiness/infinity-ui'
import React from 'react'
import AccountGraphic from '../../../assets/financial-health-graphics/account.svg'
import FinancialSolvencyGraphic from '../../../assets/financial-health-graphics/financial-solvency.svg'
import HintsAndProblemsGraphic from '../../../assets/financial-health-graphics/hints-and-problems.svg'
import InspectorExpensesGraphic from '../../../assets/financial-health-graphics/inspector-expenses.svg'
import InspectorIncomeGraphic from '../../../assets/financial-health-graphics/inspector-income.svg'
import MaturitiesGraphic from '../../../assets/financial-health-graphics/maturities.png'
import MetricGraphic from '../../../assets/financial-health-graphics/metric.svg'
import ProfitabilityGraphic from '../../../assets/financial-health-graphics/profitability.svg'
import RunwayGraphic from '../../../assets/financial-health-graphics/runway.svg'
import './FinancialHealthCardExplainer.scss'

const descriptions = {
  [FinancialHealthCardType.HintsAndProblems]:
    'Hinweise und Probleme erkennt und informiert dich bei Problemen in der Buchhaltung.',
  [FinancialHealthCardType.FinancialSolvency]:
    'Zahlungsfähigkeit zeigt dir deine verfügbaren Mittel in Verhältnis zu deinen Rechnungen.',
  [FinancialHealthCardType.Maturities]:
    'Fälligkeiten zeigt dir bald fällige und überfällige Rechnungen und Forderungen an.',
  [FinancialHealthCardType.InspectorExpenses]:
    'Aufwände zeigt dir deine Aufwandskonten, geordnet nach ihrer Grösse.',
  [FinancialHealthCardType.InspectorIncome]:
    'Erträge zeigt dir deine Ertragskonten, geordnet nach ihrer Grösse.',
  [FinancialHealthCardType.Profitability]:
    'Profitabilität berechnet laufend die Gewinnkennzahlen deiner Unternehmung.',
  [FinancialHealthCardType.Runway]:
    'Runway analysiert die Geschwindigkeit der Verwendung der finanziellen Mittel.',
  [FinancialHealthCardType.Account]:
    'Konto zeigt dir den Saldoverlauf oder die Veränderung eines beliebigen Kontos.',
  [FinancialHealthCardType.Metric]:
    'Kennzahl berechnet laufend eine beliebige Formel, optional auch mit einem Ziel.',
}

const graphics = {
  [FinancialHealthCardType.HintsAndProblems]: HintsAndProblemsGraphic,
  [FinancialHealthCardType.FinancialSolvency]: FinancialSolvencyGraphic,
  [FinancialHealthCardType.Maturities]: MaturitiesGraphic,
  [FinancialHealthCardType.InspectorExpenses]: InspectorExpensesGraphic,
  [FinancialHealthCardType.InspectorIncome]: InspectorIncomeGraphic,
  [FinancialHealthCardType.Profitability]: ProfitabilityGraphic,
  [FinancialHealthCardType.Runway]: RunwayGraphic,
  [FinancialHealthCardType.Account]: AccountGraphic,
  [FinancialHealthCardType.Metric]: MetricGraphic,
}

interface FinancialHealthCardExplainerProps {
  kind: FinancialHealthCardType
  action?: React.ReactNode
}

const FinancialHealthCardExplainer = (
  props: FinancialHealthCardExplainerProps
) => (
  <Flex
    className='financial-health-card-explainer'
    direction='vertical'
    horizontalAlignment='center'
  >
    <Flex
      direction='vertical'
      verticalAlignment='center'
      gap={props.action ? 2 : 3.2}
    >
      <img
        className='graphic'
        src={graphics[props.kind]}
        height={76}
        width={76}
        alt=''
      />
      <Text type='inline' className='description'>
        {descriptions[props.kind]}
      </Text>
      {props.action}
    </Flex>
  </Flex>
)

export default FinancialHealthCardExplainer
