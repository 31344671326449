import InvoiceDateFilter from 'invoices/shared-components/filters/InvoiceDateFilter'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import { useVendorInvoicesFilter } from './VendorInvoicesFilterContext'

const VendorInvoiceDateFilter = () => {
  const { vendorInvoiceStore } = useRootStore()
  const { activeFilter, editFilter } = useVendorInvoicesFilter()

  const allVendorInvoices = vendorInvoiceStore.allInvoices

  return (
    <InvoiceDateFilter
      invoices={allVendorInvoices}
      selectedValue={activeFilter.openingMonth}
      onValueSelected={(openingMonth) => editFilter({ openingMonth })}
    />
  )
}

export default observer(VendorInvoiceDateFilter)
