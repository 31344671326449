import { AmountMode } from '@nextbusiness/infinity-finance-api'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import Utilities from 'utility/Utilities'
import EditableDocumentText from '../../../controls/EditableDocumentText'
import { DocumentCustomProductPositionProps } from '../DocumentCustomProductPosition'

const PositionSingleAmountField = (
  props: DocumentCustomProductPositionProps
) => {
  const { preferencesStore } = useRootStore()

  const displayAmount = props.position.singleAmount
    ? String(props.position.singleAmount / 100)
    : ''
  const updateSingleAmount = (value: string) =>
    props.onChange({
      singleAmount: Utilities.parseToCents(value, { treatZeroAsEmpty: true }),
    })

  if (!props.columns?.includes('singleAmount')) return null
  return (
    <div className='positions-column single-amount'>
      <EditableDocumentText
        isReadOnly={props.isReadOnly}
        placeholder={
          preferencesStore?.organisationPreferences?.VAT
            ? props.amountMode === AmountMode.Gross
              ? 'Bruttopreis'
              : 'Nettopreis'
            : 'Einzelpreis'
        }
        isCurrency
        value={displayAmount}
        onChange={updateSingleAmount}
        style={{ textAlign: 'right' }}
        // Force re-rendering when product is selected due to internal
        // money field not updating correctly
        key={props.position.product}
      />
      {props.position.singleAmount ? ' CHF' : ''}
    </div>
  )
}

export default observer(PositionSingleAmountField)
