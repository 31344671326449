import { Button } from '@nextbusiness/infinity-ui'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import CreateProjectModal from 'projects/project-view/edit-or-create-project/CreateProjectModal'
import { useState } from 'react'

const ProjectBoardTitleBar = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false)

  return (
    <MagicSheetTitleBar title='Projekte'>
      <Button
        variant='primary'
        iconLeft='plus'
        onClick={() => setIsCreateModalOpen(true)}
      >
        Neues Projekt
      </Button>
      <CreateProjectModal
        isOpen={isCreateModalOpen}
        onDismiss={() => setIsCreateModalOpen(false)}
      />
    </MagicSheetTitleBar>
  )
}

export default ProjectBoardTitleBar
