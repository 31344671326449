import { VATCalculationMode } from '@nextbusiness/infinity-finance-api'
import { observer } from 'mobx-react'
import SplitView from 'proto-ui-components/SplitView'
import React from 'react'
import { useRootStore } from 'stores/RootStoreContext'

interface VendorInvoiceSplitViewProps {
  leftPane: React.ReactNode
  rightPane: React.ReactNode
}

const VendorInvoiceSplitView = (props: VendorInvoiceSplitViewProps) => {
  const { preferencesStore } = useRootStore()
  const requiresMoreHorizontalSpace =
    preferencesStore.organisationPreferences?.VAT &&
    preferencesStore.organisationPreferences.vatCalculationMode ===
      VATCalculationMode.Effective

  return (
    <SplitView
      className='vendor-invoice-detail'
      leftPane={props.leftPane}
      rightPane={props.rightPane}
      snapLeftAt={45}
      snapRightAt={72.5}
      breakpoints={[
        ['lg', requiresMoreHorizontalSpace ? 65 : 60],
        ['xl', 76],
      ]}
    />
  )
}

export default observer(VendorInvoiceSplitView)
