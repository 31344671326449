import { Avatar } from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import { Contact } from 'model/Contact'
import ContactStore from 'stores/ContactStore'

interface ContactSelectOptionAvatarProps {
  contact: Contact | undefined
}

const ContactSelectOptionAvatar = (props: ContactSelectOptionAvatarProps) => {
  return props.contact ? (
    <Avatar name={ContactStore.nameOfContact(props.contact)} />
  ) : (
    <Icon icon='address-book' size={IconSize.Button} />
  )
}

export default ContactSelectOptionAvatar
