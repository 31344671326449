import { LegalType } from '@nextbusiness/infinity-finance-api'
import { Button, Flex, RadioGroup, Text } from '@nextbusiness/infinity-ui'
import IconSection from 'components/icon-section/IconSection'
import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import { useMixpanelEvent } from 'integrations/useMixpanelEvent'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import DateUtilities from 'utility/DateUtilites'
import { TypedKeyValueStore } from 'utility/types'
import SetupAccountBalanceFromExisting from './SetupAccountBalanceFromExisting'
import SetupAccountBalanceSimpleForm from './SetupAccountBalanceSimpleForm'
import SetupAccountBalancesModal from './SetupAccountBalancesModal'
import './SetupAccountBalancesPage.scss'
import useSetupAccountBalanceSums from './useSetupAccountBalanceSums'

const defaultEquityBalanceForLegalType = (legalType: LegalType | undefined) => {
  switch (legalType) {
    case LegalType.LLC:
      return 20000
    case LegalType.LimitedCompany:
      return 100000
    default:
      return 0
  }
}

interface SetupAccountBalancesPageProps {
  dismiss: () => void
  openChartOfAccountsPage: () => void
}

type AccountBalanceSetupChoice = 'new-accounting' | 'existing-accounting'

const SetupAccountBalancesPage = (props: SetupAccountBalancesPageProps) => {
  const { accountStore, preferencesStore } = useRootStore()

  const [setupChoice, setSetupChoice] = useState<AccountBalanceSetupChoice>()
  const [isApplyModalOpen, setIsApplyModalOpen] = useState<boolean>(false)
  const [accountBalances, setAccountBalances] = useState<
    TypedKeyValueStore<number | null>
  >({
    2800: defaultEquityBalanceForLegalType(
      preferencesStore.organisationPreferences?.legalType
    ),
  })

  const fiscalYear = accountStore.ealiestOpenFiscalYear!
  const openingDate = DateUtilities.humanFormat(fiscalYear.from)

  const { sumOfAssets, sumOfLiabilities } = useSetupAccountBalanceSums(
    accountStore.allAccounts,
    accountBalances
  )
  const editBalance = (accountNumber: number, balance: number | null) =>
    setAccountBalances((balances) => ({
      ...balances,
      [accountNumber]: balance,
    }))

  useMixpanelEvent('Setup account balances page viewed')

  return (
    <MagicSheetPage
      header={<MagicSheetTitleBar title='Buchhaltung eröffnen' />}
      noTrailingNegativeSpace
    >
      <MagicSheetContainer>
        <div className='setup-account-balances-page'>
          <IconSection className='setup-choice' icon='firework-explosion'>
            <Text className='instructions'>
              Eröffne hiermit deine Buchhaltung per {openingDate}. Hast du
              bereits eine bestehende Buchhaltung?
            </Text>
            <RadioGroup
              className='setup-choice-options'
              options={[
                {
                  label: 'Dies ist die erste Buchhaltung für diese Firma',
                  value: 'new-accounting',
                },
                {
                  label:
                    'Ich habe für meine Firma bereits eine Buchhaltung geführt',
                  value: 'existing-accounting',
                },
              ]}
              value={setupChoice}
              onChange={setSetupChoice}
            />
          </IconSection>
          {setupChoice === 'new-accounting' && (
            <SetupAccountBalanceSimpleForm
              accountBalances={accountBalances}
              editBalance={editBalance}
              openingDate={openingDate}
            />
          )}
          {setupChoice === 'existing-accounting' && (
            <SetupAccountBalanceFromExisting
              accountBalances={accountBalances}
              editBalance={editBalance}
              openChartOfAccountsPage={props.openChartOfAccountsPage}
            />
          )}
        </div>
        <Flex verticalAlignment='center'>
          <div style={{ flexGrow: 1 }} />
          <Button
            variant={sumOfAssets === sumOfLiabilities ? 'primary' : 'tertiary'}
            onClick={() => setIsApplyModalOpen(true)}
            disabled={!setupChoice}
          >
            Anfangsbestände eintragen
          </Button>
        </Flex>
        <SetupAccountBalancesModal
          isOpen={isApplyModalOpen}
          onDismiss={() => setIsApplyModalOpen(false)}
          sumOfAssets={sumOfAssets}
          sumOfLiabilities={sumOfLiabilities}
          accountBalances={accountBalances}
          onCompleted={() => {
            setIsApplyModalOpen(false)
            props.dismiss()
          }}
        />
      </MagicSheetContainer>
    </MagicSheetPage>
  )
}

export default observer(SetupAccountBalancesPage)
