import { ReactComponent as SmallForeignCurrencyIcon } from 'assets/foreign-currency-small.svg'
import { centsToCHF } from 'utility/Utilities'
import './TransactionAmount.scss'

interface TransactionAmountProps {
  amount?: number
  isHidden?: boolean
  originalCurrency?: string
  shouldDisplayNegativeAmount?: boolean
}

const TransactionAmount = (props: TransactionAmountProps) => (
  <div className='transaction-amount'>
    <div className={'base-currency' + (props.isHidden ? ' hidden' : '')}>
      {centsToCHF(props.amount, props.shouldDisplayNegativeAmount)}
      <div className='currency'>CHF</div>
    </div>
    {props.originalCurrency && (
      <div className='original-currency'>
        <SmallForeignCurrencyIcon />
        <div className='original-currency-code'>{props.originalCurrency}</div>
      </div>
    )}
  </div>
)

export default TransactionAmount
