import { IPresetPreview } from '@nextbusiness/infinity-finance-api'
import { PresetSectionCategory } from 'invoices/presets/PresetCategorySection'

export type PresetType = PresetSectionCategory | 'blank'

export interface IPresetListItem extends IPresetPreview {
  type: PresetType
}

export interface IDefaultPresets {
  freelancing: IPresetListItem[]
  consulting: IPresetListItem[]
  services: IPresetListItem[]
}

export const PRESET_CATEGORIES = {
  all: 'Alle Vorlagen',
  custom: 'Eigene Vorlagen',
  freelancing: 'Freelancing',
  consulting: 'Consulting',
  services: 'Dienstleistungen',
}
