import classNames from 'classnames'
import { formatInvoiceNumber } from 'invoices/customer-invoices/editor/document-renderer/elements/DocumentElementInvoiceNumber'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import { TemplatePreviewProps } from './TemplatePreview'

const TemplatePreviewInfoBlock = (props: TemplatePreviewProps) => {
  const { preferencesStore } = useRootStore()
  const invoiceNumberingStart =
    preferencesStore.organisationPreferences?.customerInvoiceNumberingStart ?? 1

  const today = DateTime.now().toLocaleString({
    dateStyle: 'long',
    locale: 'de-CH',
  })

  return (
    <div
      className={classNames('page-info-block', {
        'no-background': props.template.disableInfoBlockBackground,
      })}
    >
      <span className='opening-date'>{today}</span>
      {props.template.showCustomerInvoiceNumber && (
        <span className='invoice-number'>
          {formatInvoiceNumber('RE-', 0, invoiceNumberingStart)}
        </span>
      )}
      <span className='payment-terms'>Zahlbar innert 30 Tagen</span>
    </div>
  )
}

export default observer(TemplatePreviewInfoBlock)
