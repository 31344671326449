import { INewProduct } from '@nextbusiness/infinity-finance-api'
import { Flex } from '@nextbusiness/infinity-ui'
import './ProductEditFields.scss'
import ProductFieldsAccounting from './fields/ProductFieldsAccounting'
import ProductFieldsDetails from './fields/ProductFieldsDetails'
import ProductFieldsPricing from './fields/ProductFieldsPricing'
import ProductFieldsTitle from './fields/ProductFieldsTitle'

export interface ProductEditFieldsProps {
  product: Partial<INewProduct>
  editProduct: (changes: Partial<INewProduct>) => void
  shouldValidate?: boolean
}

export const isValidProduct = (
  product: Partial<INewProduct>
): product is INewProduct =>
  !!product.name?.trim() && !!product.sellingPrice && product.name.length <= 60

const ProductEditFields = (props: ProductEditFieldsProps) => (
  <Flex direction='vertical' className='product-edit-fields'>
    <ProductFieldsTitle {...props} />
    <ProductFieldsPricing {...props} />
    <ProductFieldsAccounting {...props} />
    <ProductFieldsDetails {...props} />
  </Flex>
)

export default ProductEditFields
