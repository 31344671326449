import { Heading, Textarea } from '@nextbusiness/infinity-ui'
import { TemplateInspectorProps } from '../TemplateInspector'
import TemplateInspectorGroup from '../TemplateInspectorGroup'

const TemplateInspectorQuoteDefaultText = (props: TemplateInspectorProps) => (
  <TemplateInspectorGroup>
    <Heading type='h4'>Eröffnungstext</Heading>
    <Textarea
      value={props.template.defaultQuoteOpeningText || ''}
      onChange={(defaultQuoteOpeningText) =>
        props.modifyTemplate({ defaultQuoteOpeningText })
      }
      placeholder='Leer lassen, um den Standardtext zu verwenden'
      fullWidth
    />
    <Heading type='h4'>Schlusstext</Heading>
    <Textarea
      value={props.template.defaultQuoteClosingText || ''}
      onChange={(defaultQuoteClosingText) =>
        props.modifyTemplate({ defaultQuoteClosingText })
      }
      placeholder='Leer lassen, um den Standardtext zu verwenden'
      fullWidth
    />
  </TemplateInspectorGroup>
)

export default TemplateInspectorQuoteDefaultText
