import { TaxRateEra } from 'components/vat/vat-picker/TaxCodes'
import { VATReport } from 'model/VAT'
import './VATForm.scss'
import VATFormGroupIncome from './VATFormGroupIncome'
import VATFormGroupNonIncome from './VATFormGroupNonIncome'
import VATFormGroupTaxCalculation from './VATFormGroupTaxCalculation'

interface VATFormProps {
  report: VATReport
  era: TaxRateEra
}

const VATForm = (props: VATFormProps) => (
  <div className='vat-form'>
    <VATFormGroupIncome {...props} />
    <VATFormGroupTaxCalculation {...props} />
    <VATFormGroupNonIncome {...props} />
  </div>
)

export default VATForm
