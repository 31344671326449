import { Finance } from '@nextbusiness/infinity-finance-api'
import { INotificationCenter } from '@nextbusiness/infinity-ui'
import { inject, observer } from 'mobx-react'
import IDocument from 'model/Document'
import { Component } from 'react'
import AuthenticationStore from 'stores/AuthenticationStore'
import DocumentUploadArea from './DocumentUploadArea'

interface DocumentUploadState {
  isUploading?: boolean
}

interface DocumentUploadProps {
  authenticationStore?: AuthenticationStore
  onDocumentUploaded: (document: IDocument) => void
  notificationCenter: INotificationCenter
  disableAnalysisOnUpload?: boolean
}

@inject('authenticationStore')
@observer
class DocumentUpload extends Component<
  DocumentUploadProps,
  DocumentUploadState
> {
  state: DocumentUploadState = {}

  uploadFile = async (file: File) => {
    this.setState({ isUploading: true })
    try {
      const document: IDocument = await Finance.Documents.uploadDocument(
        file,
        !this.props.disableAnalysisOnUpload,
        { title: file.name.replace(/\.[^/.]+$/, '') }
      ).then((document) => {
        // Necessary transformation to match the IDocument interface,
        // which is widely used in the rest of the application but not compatible with the API response.
        // Feel free to take it upon you to change it all, but it might make you cry and give up.
        return {
          ...document.document,
          file: document.cloudFile,
          thumbnail: document.thumbnail ?? undefined,
        }
      })
      this.props.onDocumentUploaded(document)
      this.props.notificationCenter.addNotification({
        children: 'Belege wurde erfolgreich hochgeladen',
        variant: 'success',
      })
    } catch (error) {
      this.props.notificationCenter.addNotification({
        children: 'Beleg konnte nicht hochgeladen werden',
        variant: 'error',
      })
    } finally {
      this.setState({ isUploading: false })
    }
  }

  render() {
    return (
      <div className='document-upload'>
        <DocumentUploadArea
          onFileSelected={(file) => this.uploadFile(file)}
          isUploading={this.state.isUploading}
        />
      </div>
    )
  }
}

export default DocumentUpload
