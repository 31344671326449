import { ITransaction } from '@nextbusiness/infinity-finance-api'
import { Flex, Heading } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import { useAccountPageContext } from 'ledger/accounts/AccountPageContext'
import { useAccountSheetContext } from 'ledger/accounts/AccountSheetContext'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import TransactionUtilities from 'utility/TransactionUtilities'
import Transaction from '../Transaction'
import { useBatchEditingContext } from '../batch-editing/BatchEditingContext'
import TransactionBatchSelectionCheckbox from '../batch-editing/TransactionBatchSelectionCheckbox'

interface TransactionListSectionProps {
  title?: string
  transactions: ITransaction[]
  handleBatchEditSelection: (
    transaction: ITransaction,
    isTransactionSelected: boolean
  ) => void
}

const TransactionListSection = (props: TransactionListSectionProps) => {
  const { preferencesStore } = useRootStore()
  const batchEditingContext = useBatchEditingContext()
  const {
    transactionBeingEdited,
    transactionsToBeDeleted,
    hasUnsavedChanges,
    setTransactionBeingEdited,
    setHasUnsavedChanges,
    setAbortToTransaction,
  } = useAccountSheetContext()
  const { currentAccount } = useAccountPageContext()

  return (
    <div className='transaction-list-section'>
      {props.title && (
        <Heading
          type='h3'
          className={classNames(
            'transaction-list-section-title',
            batchEditingContext.isInMultiEdit && 'multi-edit-heading'
          )}
        >
          {props.title}
        </Heading>
      )}
      {props.transactions.map((transaction) => {
        const isTransactionSelected =
          batchEditingContext.selectedTransactions.includes(transaction.id)
        const isTransactionBeingEdited =
          !batchEditingContext.isInMultiEdit &&
          transactionBeingEdited === transaction.id
        const isTransactionBeingDeleted =
          transactionsToBeDeleted?.includes(transaction.id) ?? false
        const isTransactionHidden =
          !preferencesStore.shouldDisplayVATTransactions &&
          TransactionUtilities.shouldHideVATTransaction(
            transaction,
            currentAccount
          )

        const onStartEditing = () => {
          if (!hasUnsavedChanges) {
            setTransactionBeingEdited(transaction.id)
          } else {
            setAbortToTransaction(transaction.id)
          }
        }

        const onBatchSelection = () => {
          if (!batchEditingContext.isInMultiEdit)
            batchEditingContext.setIsInMultiEdit(true)
          queueMicrotask(() => {
            props.handleBatchEditSelection(transaction, isTransactionSelected)
          })
        }

        const closeEditor = () => {
          setTransactionBeingEdited(undefined)
          setHasUnsavedChanges(false)
        }

        if (isTransactionHidden) return null

        return (
          <Flex verticalAlignment='center' key={transaction.id}>
            {batchEditingContext.isInMultiEdit && (
              <TransactionBatchSelectionCheckbox
                transaction={transaction}
                handleBatchEditSelection={props.handleBatchEditSelection}
              />
            )}
            <Transaction
              transaction={transaction}
              onStartEditing={onStartEditing}
              onBatchSelection={onBatchSelection}
              isBeingEdited={isTransactionBeingEdited}
              isBeingDeleted={isTransactionBeingDeleted}
              closeEditor={closeEditor}
            />
          </Flex>
        )
      })}
    </div>
  )
}

export default observer(TransactionListSection)
