import { Heading, Textarea } from '@nextbusiness/infinity-ui'
import { TemplateInspectorProps } from '../TemplateInspector'
import TemplateInspectorGroup from '../TemplateInspectorGroup'

const TemplateInspectorInvoiceDefaultText = (props: TemplateInspectorProps) => (
  <TemplateInspectorGroup>
    <Heading type='h4'>Eröffnungstext</Heading>
    <Textarea
      value={props.template.defaultOpeningText || ''}
      onChange={(defaultOpeningText) =>
        props.modifyTemplate({ defaultOpeningText })
      }
      placeholder='Leer lassen, um den Standardtext zu verwenden'
      fullWidth
    />
    <Heading type='h4'>Schlusstext</Heading>
    <Textarea
      value={props.template.defaultClosingText || ''}
      onChange={(defaultClosingText) =>
        props.modifyTemplate({ defaultClosingText })
      }
      placeholder='Leer lassen, um den Standardtext zu verwenden'
      fullWidth
    />
  </TemplateInspectorGroup>
)

export default TemplateInspectorInvoiceDefaultText
