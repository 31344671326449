import { Flex } from '@nextbusiness/infinity-ui'
import { RouterHistory } from '@nextbusiness/infinity-ui/dist/layout/header/HeaderNavigationTabs'
import ICustomerInvoice, {
  ICustomerInvoicePreview,
} from 'model/CustomerInvoice'
import CustomerInvoices from 'networking/CustomerInvoices'
import DateUtilities from 'utility/DateUtilites'
import ContactSwatch from '../../../invoices/shared-components/ContactSwatch'
import { store } from '../../../stores/RootStoreContext'
import EverythingSearchProviderResult from '../EverythingSearchProviderResult'
import EverythingSearchResultsProvider from '../EverythingSearchResultsProvider'

class CustomerInvoiceSearchProvider implements EverythingSearchResultsProvider {
  providerName = 'Forderungen'

  private resultForInvoice(
    invoice: ICustomerInvoicePreview,
    query: string
  ): EverythingSearchProviderResult {
    const contact = invoice.recipient
    const contactName =
      store.contactStore.displayNameForContactWithId(contact) ?? 'Kein Kontakt'
    const containsInPositions = !invoice.title
      .toLowerCase()
      .includes(query.toLowerCase())

    return {
      id: invoice.id,
      title: invoice.title,
      type: 'invoice',
      icon: 'bill',
      reference: invoice,
      details: (
        <div className='invoice-preview'>
          <Flex
            className='invoice-details'
            gap='tiny'
            verticalAlignment='center'
          >
            <ContactSwatch contactId={contact} small />
            <span className='contact-name'>{contactName}</span>
            <span className='date'>
              {DateUtilities.humanFormatNumeric(invoice.openingDate)}
            </span>
          </Flex>
          {containsInPositions && (
            <div>
              Beinhaltet <b>«{query}»</b> in Positionen
            </div>
          )}
        </div>
      ),
    }
  }

  async resultsForQuery(
    query: string
  ): Promise<EverythingSearchProviderResult[]> {
    const results: EverythingSearchProviderResult[] = []

    if (query.length > 2) {
      try {
        const invoices = await CustomerInvoices.getCustomerInvoices(
          store.authenticationStore.organisationIdentifier,
          0,
          {
            text: query,
          }
        )
        results.push(
          ...invoices.map((transaction) =>
            this.resultForInvoice(transaction, query)
          )
        )
      } catch {
        // Don't bother
      }
    }
    return results
  }

  onResultSelected(
    result: EverythingSearchProviderResult,
    history?: RouterHistory
  ) {
    const invoice = result.reference as ICustomerInvoice
    history?.push(`/forderungen/detail/${invoice.id}`)
  }
}

export default CustomerInvoiceSearchProvider
