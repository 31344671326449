import { INewTransaction } from '@nextbusiness/infinity-finance-api'
import { useAccountPageContext } from 'ledger/accounts/AccountPageContext'
import { useEffect } from 'react'
import { TransactionCreatorProps } from '../TransactionCreator'
import TransactionCreatorUtilities from '../TransactionCreatorUtilities'

const useCorrectTransactionSides = (
  transaction: Partial<INewTransaction>,
  updateTransaction: (transaction: Partial<INewTransaction>) => void,
  utilities: TransactionCreatorUtilities,
  props: TransactionCreatorProps
) => {
  const { currentAccount } = useAccountPageContext()
  const { activeAction, shouldCurrentAccountIncrease } = props

  const correctTransactionSides = () => {
    let debitAccount = transaction.debitAccount
    let creditAccount = transaction.creditAccount

    const newCurrentAccountSide = utilities.currentAccountSide

    const shouldSidesSwitch =
      transaction[newCurrentAccountSide] !== currentAccount.accountNumber

    if (shouldSidesSwitch) {
      debitAccount = transaction.creditAccount
      creditAccount = transaction.debitAccount

      updateTransaction({ debitAccount, creditAccount })
    }
  }

  useEffect(() => {
    correctTransactionSides()
  }, [activeAction, shouldCurrentAccountIncrease])
}

export default useCorrectTransactionSides
