import { DatePicker, Text } from '@nextbusiness/infinity-ui'
import FormField from 'base-components/layout/FormField'
import SettingsGroup from 'settings/general/SettingsGroup'

interface AccountSheetRangeSelectionProps {
  startDate: Date | undefined
  setStartDate: (value: Date | undefined) => void
  endDate: Date | undefined
  setEndDate: (value: Date | undefined) => void
  shouldValidate?: boolean
}

const AccountSheetRangeSelection = (props: AccountSheetRangeSelectionProps) => (
  <SettingsGroup title='Zeitraum' compact>
    <FormField label='Startdatum'>
      <DatePicker
        value={props.startDate}
        onChange={props.setStartDate}
        inputFieldProps={{
          fullWidth: true,
          hasError: props.shouldValidate && !props.startDate,
        }}
      />
    </FormField>
    <FormField label='Enddatum'>
      <DatePicker
        value={props.endDate}
        onChange={props.setEndDate}
        inputFieldProps={{
          fullWidth: true,
          hasError: props.shouldValidate && !props.endDate,
        }}
      />
    </FormField>
    {props.shouldValidate &&
      props.startDate &&
      props.endDate &&
      props.startDate > props.endDate && (
        <Text variant='error'>
          Das Startdatum darf nicht vor dem Enddatum liegen.
        </Text>
      )}
    {props.shouldValidate && (!props.startDate || !props.endDate) && (
      <Text variant='error'>Bitte wähle einen Zeitraum aus.</Text>
    )}
  </SettingsGroup>
)

export default AccountSheetRangeSelection
