import {
  Finance,
  IProjectPreview,
  Work,
} from '@nextbusiness/infinity-finance-api'
import {
  Button,
  DetailSheet,
  Flex,
  Heading,
  InputField,
  MoneyField,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import ProjectSelect from 'components/project-select/ProjectSelect'
import { Duration } from 'luxon'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { useLocalStorage } from 'utility/hooks'
import './TimeTrackingSaveSheet.scss'

interface TimeTrackingSaveSheetProps {
  isOpen: boolean
  closeSheet: () => void
  startingTime: number | null
  timeToBeTracked: Duration | null
  stopTimeTracking: () => void
  selectedProjectId: string | undefined
  setSelectedProjectId: (projectId: string | undefined) => void
  projects: IProjectPreview[]
}

const TimeTrackingSaveSheet = (props: TimeTrackingSaveSheetProps) => {
  const notificationCenter = useNotificationCenter()
  const { authenticationStore } = useRootStore()

  const [description, setDescription] = useState<string>('')
  const [hours, setHours] = useState<number | null>(null)
  const [minutes, setMinutes] = useState<number | null>(null)

  const lastUsedRateKey = `time-tracking-last-used-rate-${authenticationStore.organisationIdentifier}`
  const [rate, setRate] = useLocalStorage<number | null>(lastUsedRateKey, null)

  const [isSaving, setIsSaving] = useState<boolean>(false)

  const parseNaturalNumber = (value: string) =>
    Math.abs(parseInt(value.replaceAll('-', '')))

  const timeFieldDisplayValue = (value: number | null) =>
    value === null || value === 0 ? '' : value.toString()

  const normaliseTime = () => {
    const normalisedDuration = Duration.fromObject({
      hours: hours ?? 0,
      minutes: minutes ?? 0,
    }).normalize()
    setHours(normalisedDuration.hours)
    setMinutes(normalisedDuration.minutes)
  }

  const canSave = () =>
    rate &&
    props.selectedProjectId &&
    props.startingTime &&
    (hours || minutes) &&
    description

  const saveTimeEntryToProject = async () => {
    if (!canSave()) return
    setIsSaving(true)
    await Finance.Projects.addWork(
      {
        rate: rate! * 100,
        date: props.startingTime,
        description,
        hours,
        minutes,
        wasBilled: false,
      } as Work,
      props.selectedProjectId!
    )
    setIsSaving(false)
    props.stopTimeTracking()
    resetFields()
    props.closeSheet()
    notificationCenter.addNotification({
      children: 'Die erledigte Arbeit wurde erfolgreich erfasst.',
      variant: 'success',
    })
  }

  const resetFields = () => {
    setDescription('')
    setHours(null)
    setMinutes(null)
  }

  const deleteTimeEntry = () => {
    props.stopTimeTracking()
    resetFields()
    props.closeSheet()
  }

  useEffect(() => {
    if (props.isOpen) {
      const normalisedDuration = props.timeToBeTracked
        ?.shiftTo('hours', 'minutes')
        .normalize()
      setHours(Math.round(normalisedDuration?.hours ?? 0) ?? null)
      setMinutes(Math.round(normalisedDuration?.minutes ?? 0) ?? null)
    }
  }, [props.isOpen])

  return (
    <DetailSheet
      className='time-tracking-save-sheet'
      isOpen={props.isOpen}
      onDismiss={() => {
        props.closeSheet()
      }}
    >
      <Flex
        direction='vertical'
        fillContainer
        horizontalAlignment='space-between'
        className='fields'
      >
        <div>
          <Heading type='h3' bareTop>
            Zeit erfassen
          </Heading>
          <Text className='label'>Projekt</Text>
          <ProjectSelect
            className='time-tracking-project-select'
            selectedProjectId={props.selectedProjectId}
            setSelectedProjectId={props.setSelectedProjectId}
            projects={props.projects}
            flyoutProps={{
              positionOptions: {
                prefer: 'below',
              },
            }}
          />
          <Text className='label'>Arbeit</Text>
          <InputField
            value={description}
            onChange={setDescription}
            fullWidth
            placeholder='Was hast du gemacht?'
          />
          <Text className='label'>Zeit</Text>
          <Flex gap={2}>
            <div className='hours'>
              <InputField
                fullWidth
                placeholder='0'
                className='hours-input'
                value={timeFieldDisplayValue(hours)}
                onChange={(input) => {
                  const parsed = parseNaturalNumber(input)
                  setHours(isNaN(parsed) ? null : parsed)
                }}
                onBlur={normaliseTime}
                type='number'
              />
              <span className='hours-label'>h</span>
            </div>
            <div className='minutes'>
              <InputField
                fullWidth
                placeholder='0'
                className='minutes-input'
                value={timeFieldDisplayValue(minutes)}
                onChange={(input) => {
                  const parsed = parseNaturalNumber(input)
                  setMinutes(isNaN(parsed) ? null : parsed)
                }}
                onBlur={normaliseTime}
                type='number'
              />
              <span className='minutes-label'>min</span>
            </div>
          </Flex>
          <Text className='label'>Stundensatz</Text>
          <MoneyField
            fullWidth
            className='hourly-rate'
            placeholder='Satz'
            value={rate}
            onChange={setRate}
            currency='CHF/h'
          />
        </div>
        <Flex gap={1.6}>
          <Button
            isLoading={isSaving}
            variant='primary'
            onClick={saveTimeEntryToProject}
            disabled={!canSave()}
          >
            Erfassen
          </Button>
          <div className='divider' />
          <Button
            aria-label='Zeiteintrag löschen'
            variant='tertiary'
            iconOnly='delete'
            iconTint='danger'
            onClick={deleteTimeEntry}
          />
        </Flex>
      </Flex>
    </DetailSheet>
  )
}

export default observer(TimeTrackingSaveSheet)
