import {
  APIError,
  Finance,
  ICompleteDocument,
  ITransaction,
} from '@nextbusiness/infinity-finance-api'
import { Button } from '@nextbusiness/infinity-ui'
import { useEffect, useState } from 'react'
import { useTransactionEditorContext } from '../TransactionEditorContext'
import AttachmentButton from './AttachmentButton'
import UploadTransactionAttachmentModal from './UploadTransactionAttachmentModal'

const fetchAttachments = async (transaction: ITransaction) => {
  const completedPromises = await Promise.allSettled(
    transaction.attachments?.map((attachment) =>
      Finance.Documents.document(attachment)
    ) ?? []
  )
  return completedPromises.map((promise) =>
    promise.status === 'fulfilled'
      ? promise.value
      : (promise.reason as APIError)
  )
}

const TransactionAttachments = () => {
  const { originalTransaction, canMakeChanges } = useTransactionEditorContext()
  const hasAttachments = !!originalTransaction.attachments?.length

  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false)
  const [transactionAttachmentsOrErrors, setTransactionAttachmentsOrErrors] =
    useState<(ICompleteDocument | APIError)[]>([])
  const [isLoadingAttachments, setIsLoadingAttachments] = useState(false)

  const loadAttachments = async () => {
    setIsLoadingAttachments(true)
    const fetchedAttachmentsOrErrors: (ICompleteDocument | APIError)[] = []
    try {
      const resultsFromAPI = await fetchAttachments(originalTransaction)
      fetchedAttachmentsOrErrors.push(...resultsFromAPI)
    } finally {
      setTransactionAttachmentsOrErrors(fetchedAttachmentsOrErrors)
      setIsLoadingAttachments(false)
    }
  }

  useEffect(() => {
    loadAttachments()
  }, [originalTransaction.attachments])

  if (!canMakeChanges && !hasAttachments) return null

  return (
    <>
      {hasAttachments ? (
        <AttachmentButton
          isLoadingAttachments={isLoadingAttachments}
          transaction={
            originalTransaction as ITransaction & { attachments: string[] }
          }
          transactionAttachmentsOrErrors={transactionAttachmentsOrErrors}
          openUploadModal={() => setIsUploadModalOpen(true)}
        />
      ) : (
        <Button
          iconOnly='attach'
          onClick={() => setIsUploadModalOpen(true)}
          tooltip='Beleg anhängen'
          className='editor-action'
        />
      )}
      <UploadTransactionAttachmentModal
        isOpen={isUploadModalOpen}
        onDismiss={() => setIsUploadModalOpen(false)}
        transaction={originalTransaction}
      />
    </>
  )
}

export default TransactionAttachments
