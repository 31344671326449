import { AmountMode } from '@nextbusiness/infinity-finance-api'
import CustomerInvoicePositions from 'invoices/customer-invoices/CustomerInvoicePositions'
import { useContext, useEffect, useRef } from 'react'
import Utilities from 'utility/Utilities'
import DocumentRendererContext from '../../DocumentRendererContext'
import { TaxRate } from 'components/vat/vat-picker/TaxCodes'

interface DocumentPositionsVATRowProps {
  rate: TaxRate
  vatSum: number
  amountMode?: AmountMode
  positions?: CustomerInvoicePositions
}

export const vatSumRow = (taxRate: TaxRate) => {
  switch (taxRate) {
    case 7.7:
      return 'vatSumRow77'
    case 3.7:
      return 'vatSumRow37'
    case 2.5:
      return 'vatSumRow25'
    case 8.1:
      return 'vatSumRow81'
    case 3.8:
      return 'vatSumRow38'
    case 2.6:
      return 'vatSumRow26'
    default:
      return 'vatSumRow81'
  }
}

const DocumentPositionsVATRow = (props: DocumentPositionsVATRowProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const renderContext = useContext(DocumentRendererContext)

  const textClarification = () => {
    const amountMode = props.amountMode ?? AmountMode.Gross
    let clarification = ''
    if (props.positions?.totalDiscount) {
      clarification += ' nach Rabatt'
    }
    if (amountMode === AmountMode.Gross) {
      clarification += ' (in Preisen enthalten)'
    }
    return clarification
  }

  useEffect(() => {
    renderContext.updateHeights({
      [vatSumRow(props.rate)]: ref.current?.clientHeight ?? 0,
    })

    return () =>
      renderContext.updateHeights({
        [vatSumRow(props.rate)]: 0,
      })
  }, [])

  return (
    <div className='positions-row minor-total' ref={ref}>
      <div className='positions-column text'>
        {props.rate.toString() + '% Mehrwertsteuer' + textClarification()}
      </div>
      <div className='positions-column total-amount'>
        {Utilities.centsToCHF(props.vatSum)} CHF
      </div>
    </div>
  )
}

export default DocumentPositionsVATRow
