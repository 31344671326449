import AcceptedQuotesView from 'invoices/customer-invoices/quotes/AcceptedQuotesView'
import RejectedQuotesView from 'invoices/customer-invoices/quotes/RejectedQuotesView'
import ArchivedProjectsView from 'projects/ArchivedProjectsView'
import ProjectView from 'projects/project-view/ProjectView'
import MagicSheet from '../components/magic-sheet/MagicSheet'
import CustomerInvoices from '../invoices/customer-invoices/CustomerInvoices'

/**
 * This is a global reference to the current CustomerInvoicesPage.
 * Only use this in components that will be rendered ontop of the CustomerInvoicesPage.
 */
export let CurrentCustomerInvoicesNavigationStackRef: CustomerInvoicesPage | null =
  null

class CustomerInvoicesPage extends MagicSheet {
  rootView = () => {
    return <CustomerInvoices />
  }

  openAcceptedQuotesPile = () => {
    this.presentViewModally({
      title: () => 'Akzeptiere Offerten',
      view: <AcceptedQuotesView />,
    })
  }

  openRejectedQuotesPile = () => {
    this.presentViewModally({
      title: () => 'Abgelehnte Offerten',
      view: <RejectedQuotesView />,
    })
  }

  openArchivedProjectsStack = () => {
    this.presentViewModally({
      title: () => 'Archivierte Projekte',
      view: <ArchivedProjectsView openProject={this.openProject} />,
    })
  }

  openProject = (withId: string) => {
    this.presentViewModally({
      title: () => 'Projekt',
      view: (
        <ProjectView
          projectId={withId}
          dismissView={() => this.popCurrentView()}
        />
      ),
    })
  }

  componentDidMount() {
    CurrentCustomerInvoicesNavigationStackRef = this
    this.presentViewModally({
      title: () => 'Forderungen von mir',
      view: this.rootView(),
    })
  }
}

export default CustomerInvoicesPage
