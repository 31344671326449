import { VATReport } from 'model/VAT'
import VATFormHeader1 from './VATFormHeader1'
import VATFormHeader2 from './VATFormHeader2'
import VATFormRow from './VATFormRow'

interface VATFormGroupIncomeProps {
  report: VATReport
}

const VATFormGroupIncome = (props: VATFormGroupIncomeProps) => (
  <div className='vat-form-group'>
    <VATFormHeader1
      title='I. Umsatz'
      note='Alle Umsatzangaben sind netto (exkl. MWST)'
    />
    <VATFormHeader2
      title='Entgelte'
      digitColumn1='Ziffer'
      valueColumn1='Umsatz'
      valueColumn2='Umsatz'
      digitColumn2='Ziffer'
    />
    <VATFormRow
      title='Total der vereinbarten bzw. vereinnahmten Entgelte, inkl. optierte Leistungen, Entgelte aus Übertragungen im Meldeverfahren sowie aus Leistungen im Ausland (weltweiter Umsatz)'
      digitColumn1='200'
      value2={props.report['200'].amount}
    />
    <VATFormRow
      title='In Ziffer 200 enthaltene Entgelte aus von der Steuer ausgenommenen Leistungen (Art. 21), für welche nach Art. 22 optiert wird'
      digitColumn1='205'
      value1={props.report['205'].amount}
    />
    <VATFormHeader2
      title='Abzüge'
      digitColumn1='Ziffer'
      valueColumn1='Umsatz'
      valueColumn2='Umsatz'
      digitColumn2='Ziffer'
    />
    <VATFormRow
      title='Von der Steuer befreite Leistungen (u.a. Exporte, Art. 23), von der Steuer befreite Leistungen an begünstigte Einrichtungen und Personen (Art. 107 Abs. 1 Bst. a)'
      digitColumn1='220'
      value1={props.report['220'].amount}
    />
    <VATFormRow
      title='Leistungen im Ausland (Ort der Leistung im Ausland)'
      digitColumn1='221'
      value1={props.report['221'].amount}
      value1Sign='plus'
    />
    <VATFormRow
      title='Übertragungen im Meldeverfahren (Art. 38, bitte zusätzlich Form. 764 einreichen)'
      digitColumn1='225'
      value1={props.report['225'].amount}
      value1Sign='plus'
    />
    <VATFormRow
      title='Von der Steuer ausgenommene Inlandleistungen (Art. 21), für die nicht nach Art. 22 optiert wird'
      digitColumn1='230'
      value1={props.report['230'].amount}
      value1Sign='plus'
    />
    <VATFormRow
      title='Entgeltsminderungen wie Skonti, Rabatte usw.'
      digitColumn1='235'
      value1={props.report['235'].amount}
      value1Sign='plus'
    />
    <VATFormRow
      title='Diverses (z. B. Wert des Bodens)'
      digitColumn1='280'
      value1={props.report['280'].amount}
      value1Sign='plus'
      value2={props.report['289'].amount}
      value2Sign='minus'
      digitColumn2='289'
    />
    <VATFormRow
      title='Steuerbarer Gesamtumsatz (Ziff. 200 abzüglich Ziff. 289)'
      digitColumn1='299'
      value2={props.report['299'].amount}
      value2Sign='equals'
    />
  </div>
)

export default VATFormGroupIncome
