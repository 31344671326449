import { InputField, RadioGroup } from '@nextbusiness/infinity-ui'
import FormField from 'base-components/layout/FormField'
import SettingsGroup from 'settings/general/SettingsGroup'

export enum AccountSelection {
  All,
  Range,
  Single,
}

interface AccountSheetRangeSelectionProps {
  accountSelection: AccountSelection
  setAccountSelection: (value: AccountSelection) => void
  accountRangeFrom: number | undefined
  setAccountRangeFrom: (value: number | undefined) => void
  accountRangeTo: number | undefined
  setAccountRangeTo: (value: number | undefined) => void
}

const AccountSheetRangeSelection = (props: AccountSheetRangeSelectionProps) => (
  <SettingsGroup title='Konten' compact>
    <RadioGroup
      value={props.accountSelection}
      onChange={props.setAccountSelection}
      options={[
        { value: AccountSelection.All, label: 'Alle Konten' },
        { value: AccountSelection.Range, label: 'Kontenbereich' },
        { value: AccountSelection.Single, label: 'Einzelnes Konto' },
      ]}
    />
    {props.accountSelection === AccountSelection.Single && (
      <FormField label='Konto'>
        <InputField
          value={String(props.accountRangeFrom ?? '')}
          onChange={(value) =>
            props.setAccountRangeFrom(value ? Number(value) : undefined)
          }
          fullWidth
        />
      </FormField>
    )}
    {props.accountSelection === AccountSelection.Range && (
      <>
        <FormField label='Konten von'>
          <InputField
            value={String(props.accountRangeFrom ?? '')}
            onChange={(value) =>
              props.setAccountRangeFrom(value ? Number(value) : undefined)
            }
            placeholder='1000'
            fullWidth
          />
        </FormField>
        <FormField label='Konten bis'>
          <InputField
            value={String(props.accountRangeTo ?? '')}
            onChange={(value) =>
              props.setAccountRangeTo(value ? Number(value) : undefined)
            }
            placeholder='9999'
            fullWidth
          />
        </FormField>
      </>
    )}
  </SettingsGroup>
)

export default AccountSheetRangeSelection
