import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import { observer } from 'mobx-react-lite'
import DocumentsPage, { DocumentsPagePaths } from 'pages/DocumentsPage'
import { useRootStore } from 'stores/RootStoreContext'
import FiscalYearListItem from './FiscalYearListItem'

interface FinancialClosingOverviewProps {
  page: DocumentsPage
}

const FinancialClosingOverview = (props: FinancialClosingOverviewProps) => {
  const { accountStore } = useRootStore()

  return (
    <MagicSheetPage header={<MagicSheetTitleBar title='Jahresabschluss' />}>
      <MagicSheetContainer>
        <div className='fiscal-year-list'>
          {accountStore.allFiscalYears?.map((fiscalYear) => (
            <FiscalYearListItem
              key={fiscalYear.id}
              fiscalYear={fiscalYear}
              openBalanceSheet1={() =>
                props.page.open(
                  DocumentsPagePaths.FinancialClosingBalanceSheet1,
                  String(fiscalYear.year)
                )
              }
              openBalanceSheet2={() =>
                props.page.open(
                  DocumentsPagePaths.FinancialClosingBalanceSheet2,
                  String(fiscalYear.year)
                )
              }
              openIncomeStatement={() =>
                props.page.open(
                  DocumentsPagePaths.FinancialClosingIncomeStatement,
                  String(fiscalYear.year)
                )
              }
            />
          ))}
        </div>
      </MagicSheetContainer>
    </MagicSheetPage>
  )
}

export default observer(FinancialClosingOverview)
