import { Flex } from '@nextbusiness/infinity-ui'
import ContentLoader from 'react-content-loader'

const InvoiceListItemLoading = () => (
  <div>
    <div style={{ height: '2rem' }} />
    {[...Array(4)].map((_, i) => (
      <Flex key={i} horizontalAlignment='space-between'>
        <div>
          <ContentLoader
            speed={2}
            width={400}
            height={70}
            viewBox='0 0 400 70'
            backgroundColor='#f3f3f3'
            foregroundColor='#ecebeb'
          >
            <rect x='62' y='12' rx='4' ry='4' width='36' height='48' />
            <rect x='123' y='23' rx='3' ry='3' width='75' height='10' />
            <rect x='123' y='42' rx='3' ry='3' width='146' height='10' />
          </ContentLoader>
        </div>
        <div>
          <ContentLoader
            speed={2}
            width={600}
            height={70}
            viewBox='0 0 600 70'
            backgroundColor='#f3f3f3'
            foregroundColor='#ecebeb'
          >
            <rect x='14' y='30' rx='3' ry='3' width='12' height='12' />
            <rect x='42' y='30' rx='3' ry='3' width='100' height='12' />
            <rect x='270' y='28' rx='3' ry='3' width='124' height='12' />
            <rect x='470' y='28' rx='3' ry='3' width='80' height='12' />
          </ContentLoader>
        </div>
      </Flex>
    ))}
  </div>
)

export default InvoiceListItemLoading
