import { Button } from '@nextbusiness/infinity-ui'
import { useTransactionEditorContext } from '../TransactionEditorContext'

const TransactionEditorDeleteAction = () => {
  const { originalTransaction, onDeleteClicked } = useTransactionEditorContext()
  const deleteHint = !originalTransaction.taxCode
    ? 'Transaktion löschen'
    : 'Transaktion inkl. MWST löschen'

  return (
    <Button
      className='editor-action'
      iconOnly='delete'
      aria-label={deleteHint}
      tooltip={deleteHint}
      onClick={() => onDeleteClicked()}
    />
  )
}

export default TransactionEditorDeleteAction
