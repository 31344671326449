import {
  DefaultAccountsTemplate,
  Finance,
  IFeature,
  LegalType,
} from '@nextbusiness/infinity-finance-api'
import { Button, useNotificationCenter } from '@nextbusiness/infinity-ui'
import * as Sentry from '@sentry/react'
import { intercomCompanyAttributesFromOrganisation } from 'integrations/IntercomBoot'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import SprigIntegration from 'integrations/SprigIntegration'
import mixpanel from 'mixpanel-browser'
import { observer } from 'mobx-react'
import FiscalYears from 'networking/FiscalYears'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { useRootStore } from 'stores/RootStoreContext'
import { UsageType, useInitialSetupContext } from './InitialSetupContext'

const defaultFeatures = (usageType: UsageType | undefined): IFeature[] => {
  const DEFAULT_FEATURES: IFeature[] = [
    'accounts-payable',
    'accounts-receivable',
  ]
  if (usageType === UsageType.Accountant) {
    return [...DEFAULT_FEATURES, 'account-numbers']
  }
  return DEFAULT_FEATURES
}

const OnboardingSetupAction = () => {
  const history = useHistory()
  const intercom = useIntercom()
  const { authenticationStore, accountStore, preferencesStore } = useRootStore()

  const setupContext = useInitialSetupContext()
  const notificationCenter = useNotificationCenter()

  const [isSettingUp, setIsSettingUp] = useState<boolean>(false)

  const validateSetup = () => {
    const isVATValid =
      !setupContext.isVATEnabled ||
      (setupContext.vatRegistrationNumber && setupContext.vatCalculationMode)
    const isFiscalYearValid =
      setupContext.fiscalYearStart && setupContext.fiscalYearEnd

    return isVATValid && isFiscalYearValid
  }

  const setupCommunications = async () => {
    if (!setupContext.hasEnabledCommunications) return
    try {
      await Finance.Organisation.setFlags({
        marketingCommunicationsOptIn: true,
      })
    } catch (error) {
      // Big whoop – they won't get our newsletter.
      // That shouldn't block the user from continuing.
      Sentry.captureException(error)
    }
  }

  const setupPreferences = async () => {
    await Finance.Organisation.setPreferences({
      VAT: setupContext.isVATEnabled,
      ...(setupContext.isVATEnabled
        ? {
            vatCalculationMode: setupContext.vatCalculationMode,
            vatRegistrationNumber: setupContext.vatRegistrationNumber,
          }
        : {}),
      features: defaultFeatures(setupContext.usageType),
      legalName: authenticationStore.currentOrganisationName,
      legalType: setupContext.legalType,
    })
    await Finance.Organisation.setFlags({
      usageType: setupContext.usageType,
      useGuidedLedgerOnboarding: true,
      shouldShowTransactionIndicators: true,
    })
  }

  const setupFiscalYear = async () => {
    if (accountStore.currentFiscalYear) {
      // Fiscal year was already created. We may be in a retry scenario
      // where another setup action failed, so we're skipping this step.
      return
    }
    await FiscalYears.createFiscalYear(
      authenticationStore.organisationIdentifier,
      {
        year: 0,
        from: setupContext.fiscalYearStart!.getTime(),
        to: setupContext.fiscalYearEnd!.getTime(),
      }
    )
  }

  const setupLedgerAccounts = async () => {
    let openAccounts = []
    try {
      openAccounts = await Finance.Ledger.allOpenAccounts()
    } catch {
      // Ignore errors. We'll just create the accounts if they don't exist.
      // We're just checking if they exist to avoid creating them multiple times.
    }
    if (openAccounts.length > 0) {
      // Accounts were already created. We may be in a retry scenario.
      return
    }
    const legalType = setupContext.legalType
    const template =
      legalType === LegalType.SoleProprietorship ||
      legalType === LegalType.GeneralPartnership
        ? DefaultAccountsTemplate.SingleCompany
        : DefaultAccountsTemplate.LegalEntity

    await Finance.Ledger.setupDefaultAccounts(template)
  }

  const performSetup = async () => {
    setupContext.setShouldValidate(true)
    if (!validateSetup()) {
      notificationCenter.addNotification({
        children: 'Einige Angaben sind noch unvollständig.',
      })
      return
    }
    setIsSettingUp(true)
    try {
      await setupPreferences()
      await setupFiscalYear()
      await setupCommunications()
      await setupLedgerAccounts()

      history.push('/welcome')
    } catch (error: any) {
      notificationCenter.addNotification({
        title: 'Einrichtung konnte nicht abgeschlossen werden.',
        children: `${error.message} – Versuche es in einigen Momenten erneut, oder überprüfe deine Angaben, falls das Problem wiederholt auftritt.`,
        variant: 'error',
      })
    } finally {
      setIsSettingUp(false)
      await Promise.all([
        accountStore.getFiscalYear(),
        preferencesStore.loadPreferences(),
      ])
      trackSetupEvents()
    }
  }

  const trackSetupEvents = () => {
    try {
      intercom.trackEvent('oobe-completed')
      intercom.update({
        company: intercomCompanyAttributesFromOrganisation(
          authenticationStore.fabricOrganisationIdentifier!,
          authenticationStore.organisationIdentifier,
          preferencesStore.organisationPreferences
        ),
      })
      mixpanel.people.set({
        usageType: setupContext.usageType,
      })
      MixpanelAnalytics.event('Onboarding completed')
      SprigIntegration.sprig?.identifyAndTrack({
        eventName: 'OOBE_COMPLETE',
        userId: authenticationStore.user?.id,
      })
    } catch {
      // We don't want to block the user from continuing if the tracking fails.
    }
  }

  return (
    <Button variant='primary' onClick={performSetup} isLoading={isSettingUp}>
      Los geht's
    </Button>
  )
}

export default observer(OnboardingSetupAction)
