import { Finance } from '@nextbusiness/infinity-finance-api'
import {
  Button,
  Flex,
  LoadingIndicator,
  MoneyField,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { useMixpanelEvent } from 'integrations/useMixpanelEvent'
import useBackendQuery from 'libs/networking/BackendQuery'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import SettingsFieldList from 'settings/general/SettingsFieldList'
import SettingsGroup from 'settings/general/SettingsGroup'
import SettingsPage from 'settings/general/SettingsPage'
import { useRootStore } from 'stores/RootStoreContext'
import TargetIcon from '../../assets/settings-icons/target-settings-icon.svg'

const TargetSettingsPage = () => {
  const { preferencesStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [loadedSettings, isLoading] = useBackendQuery(() =>
    Finance.Organisation.current()
  )
  const [targetGrossProfitMargin, setTargetGrossProfitMargin] = useState<
    number | null
  >(null)
  const [targetNetProfitMargin, setTargetNetProfitMargin] = useState<
    number | null
  >(null)

  const [isSaving, setIsSaving] = useState<boolean>(false)

  const [showGrossProfitValidationError, setShowGrossProfitValidationError] =
    useState<boolean>(false)
  const [showNetProfitValidationError, setShowNetProfitValidationError] =
    useState<boolean>(false)

  const saveChanges = async () => {
    setIsSaving(true)
    try {
      const hasEnteredValidGrossProfit =
        targetGrossProfitMargin === null ||
        (targetGrossProfitMargin >= 0 && targetGrossProfitMargin <= 100)
      const hasEnteredValidNetProfit =
        targetNetProfitMargin === null ||
        (targetNetProfitMargin >= 0 && targetNetProfitMargin <= 100)

      setShowGrossProfitValidationError(!hasEnteredValidGrossProfit)
      setShowNetProfitValidationError(!hasEnteredValidNetProfit)

      if (hasEnteredValidGrossProfit && hasEnteredValidNetProfit) {
        const preferences = await Finance.Organisation.setPreferences({
          targetGrossProfitMargin: targetGrossProfitMargin
            ? targetGrossProfitMargin / 100
            : null,
          targetNetProfitMargin: targetNetProfitMargin
            ? targetNetProfitMargin / 100
            : null,
        })
        runInAction(() => {
          preferencesStore.organisationPreferences = preferences
        })
        notificationCenter.addNotification({
          children: 'Einstellungen gespeichert',
          variant: 'success',
        })
      }
    } catch (error: any) {
      notificationCenter.addNotification({
        title: 'Einstellungen konnten nicht gespeichert werden',
        children: error?.message,
        variant: 'error',
      })
    } finally {
      setIsSaving(false)
    }
  }

  useEffect(() => {
    if (loadedSettings?.targetGrossProfitMargin) {
      setTargetGrossProfitMargin(loadedSettings.targetGrossProfitMargin * 100)
    }
    if (loadedSettings?.targetNetProfitMargin) {
      setTargetNetProfitMargin(loadedSettings.targetNetProfitMargin * 100)
    }
  }, [loadedSettings])

  useEffect(() => {
    const keyDownEventHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') saveChanges()
    }
    document.addEventListener('keyup', keyDownEventHandler)
    return () => document.removeEventListener('keyup', keyDownEventHandler)
  }, [saveChanges])

  useMixpanelEvent('Settings viewed - Profitability targets')

  if (isLoading)
    return (
      <Flex
        horizontalAlignment='center'
        verticalAlignment='center'
        fillContainer
      >
        <LoadingIndicator />
      </Flex>
    )

  return (
    <SettingsPage
      title='Profitabilitätsziele'
      iconURL={TargetIcon}
      className='target-settings-page'
    >
      <SettingsGroup
        title='Ziele für Gewinnmargen'
        description='Wenn du bestimmte Gewinnmargen anstrebst, kannst du diese hier einsetzen, damit sie unter "Finanzielle Gesundheit" als Faktor berücksichtigt werden.'
      >
        <SettingsFieldList>
          <Flex gap='tiny'>
            <MoneyField
              className='percentage-field'
              placeholder='Ziel-Bruttogewinnmarge'
              value={targetGrossProfitMargin}
              currency='%'
              onChange={(value) => setTargetGrossProfitMargin(value)}
              hasError={showGrossProfitValidationError}
              helperText={
                showGrossProfitValidationError
                  ? 'Du musst eine Zahl von 0 bis 100 eingeben'
                  : ''
              }
              fullWidth
            />
            <MoneyField
              className='percentage-field'
              placeholder='Ziel-Nettogewinnmarge'
              value={targetNetProfitMargin}
              currency='%'
              onChange={(value) => setTargetNetProfitMargin(value)}
              hasError={showNetProfitValidationError}
              helperText={
                showNetProfitValidationError
                  ? 'Du musst eine Zahl von 0 bis 100 eingeben'
                  : ''
              }
              fullWidth
            />
          </Flex>
        </SettingsFieldList>
      </SettingsGroup>
      <Button
        variant='primary'
        isLoading={isSaving}
        onClick={() => saveChanges()}
      >
        Speichern
      </Button>
    </SettingsPage>
  )
}

export default observer(TargetSettingsPage)
