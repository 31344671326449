import { LiveAccountingProvider } from '@nextbusiness/infinity-finance-api'
import { Button, Flex, Text } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { IAccount } from 'model/Account'
import { useRootStore } from 'stores/RootStoreContext'
import LiveAccountingProviderCard from './LiveAccountingProviderCard'

interface LiveAccountingSetupProvidersProps {
  account: IAccount
  providers: LiveAccountingProvider[]
  dismiss: () => void
}

const PROVIDER_LOGO_OVERRIDES: { [key: string]: string } = {
  IIDP00230: '/assets/bank-providers/UBS_rgb.svg',
}
const PROVIDER_LIMITATION_ARTICLES: { [key: string]: string } = {
  // Limitations for UBS customers
  IIDP00230:
    'https://help.infinity.swiss/de/articles/8826051-einschrankungen-der-live-accounting-preview#h_5e79cb4d65',
}

const LiveAccountingSetupProviders = (
  props: LiveAccountingSetupProvidersProps
) => {
  const { preferencesStore } = useRootStore()
  const useManualAccounting = () => {
    props.dismiss()
    preferencesStore.setFlags({ ledgerConnectionStepCompleted: true })
  }
  return (
    <>
      <div className='provider-grid'>
        {props.providers.map((provider) => (
          <LiveAccountingProviderCard
            account={props.account}
            key={provider.providerId}
            provider={provider}
            overrideLogo={PROVIDER_LOGO_OVERRIDES[provider.providerId]}
            limitationsArticle={
              PROVIDER_LIMITATION_ARTICLES[provider.providerId]
            }
          />
        ))}
      </div>
      <Flex
        className='unsupported-bank-notice'
        direction='vertical'
        verticalAlignment='center'
      >
        <Text type='paragraph'>
          Deine Bank nicht gefunden?
          <br />
          Dann unterstüzt deine Bank noch nicht die neusten digitalen Schweizer
          Standards.
        </Text>
        <Button onClick={useManualAccounting}>
          Stattdessen manuell buchen
        </Button>
      </Flex>
    </>
  )
}

export default observer(LiveAccountingSetupProviders)
