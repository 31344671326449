import { useContext, useEffect, useRef } from 'react'
import { DocumentPageProps } from '../DocumentPage'
import DocumentRendererContext from '../DocumentRendererContext'
import DocumentElementEnvelopeSenderRow from '../elements/DocumentElementEnvelopeSenderRow'
import DocumentElementLogo from '../elements/DocumentElementLogo'
import DocumentElementRecipient from '../elements/DocumentElementRecipient'
import DocumentElementSender from '../elements/DocumentElementSender'
import './DocumentPageHeader.scss'

const DocumentPageHeader = (props: DocumentPageProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const renderContext = useContext(DocumentRendererContext)

  useEffect(() => {
    renderContext.updateHeights({
      header: ref.current?.clientHeight || 0,
    })
  }, [props.document])

  return (
    <div
      className={`document-page-header recipient-positioned-${props.template.addressLinePosition}`}
      ref={ref}
    >
      <div className='document-page-sender'>
        <DocumentElementLogo {...props} />
        <DocumentElementSender {...props} />
      </div>
      <div
        className='document-page-recipient'
        style={{
          /** Since the vertical shift is in mm, we need to convert it to px.
           * The ratio was chosen such that the top lines of both addresses are roughly aligned at a shift of -21mm. */
          marginTop: (props.template.receiverAddressVerticalShift ?? 0) * 2.62,
        }}
      >
        <div className='envelope-window'>
          <DocumentElementEnvelopeSenderRow {...props} />
          <DocumentElementRecipient {...props} />
        </div>
      </div>
    </div>
  )
}

export default DocumentPageHeader
