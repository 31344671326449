import { Button, Flex, Text } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { useRef } from 'react'
import { generateRandomId } from 'utility/StringUtilities'
import { IPosition } from '../../../model/VendorInvoice'
import { useVendorInvoiceEditor } from '../detail/VendorInvoiceEditorContext'
import VendorInvoiceEditorUtilities from '../detail/VendorInvoiceEditorUtilitites'
import TotalRows from './TotalRows'
import VendorInvoicePosition from './VendorInvoicePosition'
import './VendorInvoicePositionList.scss'

interface VendorInvoicePositionListProps {
  showTotal?: boolean
  positions: Partial<IPosition>[] | undefined
  setPositions: (updatedPositions: Partial<IPosition>[]) => void
  isReadOnly?: boolean
}

const VendorInvoicePositionList = (props: VendorInvoicePositionListProps) => {
  const addPositionButtonRef = useRef<HTMLButtonElement>(null)

  const { shouldValidate } = useVendorInvoiceEditor()
  const isIncompletePositionsWarningShown =
    shouldValidate &&
    !VendorInvoiceEditorUtilities.areAllPositionsComplete(props.positions)

  return (
    <Flex
      className={classNames('position-list', {
        'read-only': props.isReadOnly,
      })}
      direction='vertical'
      gap='tiny'
    >
      {props.showTotal && (
        <TotalRows positions={props.positions} isReadOnly={props.isReadOnly} />
      )}
      {isIncompletePositionsWarningShown && (
        <Text variant='warning' type='inline'>
          Einige Positionen haben noch unvollständige Angaben.
        </Text>
      )}
      {props.positions?.map((position, index) => (
        <Flex
          className={classNames('position', {
            'non-deletable': props.isReadOnly,
          })}
          key={position.renderId}
          gap='tiny'
        >
          <VendorInvoicePosition
            position={position}
            setPosition={(updatedPosition) => {
              const updatedPositions = [...props.positions!]
              updatedPositions[index] = updatedPosition

              props.setPositions(updatedPositions)
            }}
            isReadOnly={props.isReadOnly}
          />
          {!props.isReadOnly && (
            <Button
              className='delete-position-button'
              iconOnly='delete'
              variant='quaternary'
              onClick={() => {
                const updatedPositions = [...props.positions!]
                updatedPositions.splice(index, 1)

                props.setPositions(updatedPositions)
              }}
              tooltip='Position löschen'
            />
          )}
        </Flex>
      ))}

      {!props.isReadOnly && (
        <Button
          ref={addPositionButtonRef}
          className='add-position-button'
          variant='quaternary'
          iconLeft='plus'
          onClick={() => {
            props.setPositions([
              ...(props.positions ?? []),
              {
                quantity: 1,
                renderId: generateRandomId(),
              },
            ])
            setTimeout(
              () =>
                addPositionButtonRef.current?.scrollIntoView({
                  behavior: 'smooth',
                }),
              0
            )
          }}
        >
          Position hinzufügen
        </Button>
      )}
    </Flex>
  )
}

export default observer(VendorInvoicePositionList)
