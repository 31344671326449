import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import DeleteAccountModal from 'ledger/accounts/account-editing/DeleteAccountModal'
import EditAccountModal from 'ledger/accounts/account-editing/EditAccountModal'
import { observer } from 'mobx-react'
import { IAccount } from 'model/Account'
import { useMemo, useState } from 'react'
import LedgerStore from 'stores/LedgerStore'
import { useRootStore } from 'stores/RootStoreContext'
import ChartOfAccountsListItem from './ChartOfAccountsListItem'

interface ChartOfAccountsListProps {
  showAllAccounts: boolean
  openAccount: (accountNumber: number) => void
}

const ChartOfAccountsList = (props: ChartOfAccountsListProps) => {
  const { accountStore, ledgerStore } = useRootStore()

  const [selectedAccount, setSelectedAccount] = useState<IAccount | null>(null)

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)

  const accounts = useMemo(() => {
    if (props.showAllAccounts) return accountStore.allAccounts
    return accountStore.allAccounts.filter(
      (account) => !account.isSystemAccount
    )
  }, [accountStore.allAccounts, props.showAllAccounts])

  return (
    <div className='chart-of-accounts-list'>
      {accounts.map((account) => (
        <ChartOfAccountsListItem
          key={account.id}
          account={account}
          onEdit={() => {
            setSelectedAccount(account)
            queueMicrotask(() => setIsEditModalOpen(true))
          }}
          onDelete={() => {
            setSelectedAccount(account)
            setIsDeleteModalOpen(true)
          }}
          onOpen={() => {
            MixpanelAnalytics.event('Account opened - from chart of accounts')
            props.openAccount(account.accountNumber)
          }}
        />
      ))}
      {selectedAccount && (
        <>
          <EditAccountModal
            account={selectedAccount}
            isOpen={isEditModalOpen}
            closeModal={() => setIsEditModalOpen(false)}
            hideDeleteAccountAction
          />
          <DeleteAccountModal
            account={selectedAccount}
            isOpen={isDeleteModalOpen}
            closeModal={() => setIsDeleteModalOpen(false)}
            onDeleteCompleted={(replacementAccount) => {
              if (ledgerStore.currentRootView === selectedAccount.accountNumber)
                ledgerStore.setCurrentRootView(
                  replacementAccount ?? LedgerStore.INITIAL_DEFAULT_ROOT_VIEW
                )
            }}
          />
        </>
      )}
    </div>
  )
}

export default observer(ChartOfAccountsList)
