import { Text } from '@nextbusiness/infinity-ui'
import './FinancialHealthCardHeader.scss'

interface FinancialHealthCardHeaderProps {
  title?: string
}

const FinancialHealthCardHeader = (props: FinancialHealthCardHeaderProps) => (
  <div className='financial-health-card-header'>
    <Text className='card-title'>{props.title}</Text>
  </div>
)

export default FinancialHealthCardHeader
