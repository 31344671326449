import { FinancialHealthCardType } from '@nextbusiness/infinity-finance-api'
import { Button, Card, Flex } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import FinancialHealthCardExplainer from '../components/FinancialHealthCardExplainer'
import FinancialHealthCardHeader from '../components/FinancialHealthCardHeader'
import { titles } from './FinancialHealthEditCard'
import './FinancialHealthEditCard.scss'

interface FinancialHealthAddCardProps {
  kind: FinancialHealthCardType
  addCard: (kind: FinancialHealthCardType) => void
  className?: string
}

const FinancialHealthAddCard = (props: FinancialHealthAddCardProps) => {
  return (
    <Card
      elevation='none'
      className={classNames(
        'financial-health-card edit-card add-card',
        props.className
      )}
    >
      <Flex
        direction='vertical'
        style={{ alignItems: 'stretch' }}
        fillContainer
      >
        <FinancialHealthCardHeader title={titles[props.kind]} />
        <FinancialHealthCardExplainer
          kind={props.kind}
          action={
            <Button
              iconLeft='plus'
              variant='secondary'
              onClick={() => props.addCard(props.kind)}
            >
              Hinzufügen
            </Button>
          }
        />
      </Flex>
    </Card>
  )
}

export default FinancialHealthAddCard
