import { InputField } from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import { forwardRef } from 'react'

interface CurrencySearchProps {
  searchValue: string
  setSearchValue: (searchValue: string) => void
  onArrowUp: () => void
  onArrowDown: () => void
  onEnter: () => void
}

const CurrencySearch = forwardRef<HTMLInputElement, CurrencySearchProps>(
  function CurrencySearch(props, ref) {
    return (
      <div className='currency-select-search'>
        <InputField
          ref={ref}
          bare
          className='currency-select-input'
          fullWidth
          placeholder='Währung suchen'
          value={props.searchValue}
          onChange={(value) => props.setSearchValue(value)}
          onKeyDown={(event) => {
            if (event.key === 'ArrowUp') {
              props.onArrowUp()
            } else if (event.key === 'ArrowDown') {
              props.onArrowDown()
            } else if (event.key === 'Enter') {
              props.onEnter()
            }
          }}
        />
        <Icon icon='search' size={IconSize.Small} tint='concrete' />
      </div>
    )
  }
)

export default CurrencySearch
