import { TaxRateEra } from 'components/vat/vat-picker/TaxCodes'
import { VATReport } from 'model/VAT'
import VATFormGroupTaxEra2018 from './VATFormGroupTaxEra2018'
import VATFormGroupTaxEra2024 from './VATFormGroupTaxEra2024'
import VATFormHeader2 from './VATFormHeader2'
import VATFormRow from './VATFormRow'

export interface VATFormGroupTaxCalculationProps {
  report: VATReport
  era: TaxRateEra
}

const VATFormGroupTaxCalculation = (props: VATFormGroupTaxCalculationProps) => (
  <div className='vat-form-group'>
    {props.era === '2024-reform' ? (
      <VATFormGroupTaxEra2024 {...props} />
    ) : (
      <VATFormGroupTaxEra2018 {...props} />
    )}
    <VATFormHeader2
      title='Vorsteuer'
      digitColumn1='Ziffer'
      valueColumn1='Steuer'
      valueColumn2='Steuer'
      digitColumn2='Ziffer'
    />
    <VATFormRow
      title='Vorsteuer auf Material- und Dienstleistungsaufwand'
      digitColumn1='400'
      value1={props.report['400'].amount}
    />
    <VATFormRow
      title='Vorsteuer auf Investitionen und übrigem Betriebsaufwand'
      digitColumn1='405'
      value1={props.report['405'].amount}
      value1Sign='plus'
    />
    <VATFormRow
      title='Einlageentsteuerung (Art. 32, bitte detaillierte Aufstellung einreichen)'
      digitColumn1='410'
      value1={props.report['410'].amount}
      value1Sign='plus'
    />
    <VATFormRow
      title='Vorsteuerkorrekturen: gemischte Verwendung (Art. 30), Eigenverbrauch (Art. 31)'
      digitColumn1='415'
      value1={props.report['415'].amount}
      value1Sign='minus'
    />
    <VATFormRow
      title='Vorsteuerkürzungen: Nicht-Entgelte wie Subventionen, Tourismusabgaben usw. (Art. 33 Abs. 2)'
      digitColumn1='420'
      value1={props.report['420'].amount}
      value1Sign='minus'
      value2={props.report['479'].amount}
      value2Sign='equals'
      digitColumn2='479'
    />
    {props.report['510'].amount > 0 ? (
      <>
        <VATFormRow title='Zu bezahlender Betrag' digitColumn1='500' />
        <VATFormRow
          title='Guthaben der steuerpflichtigen Person'
          digitColumn1='510'
          value2={props.report['510'].amount}
          value2Sign='equals'
        />
      </>
    ) : (
      <>
        <VATFormRow
          title='Zu bezahlender Betrag'
          digitColumn1='500'
          value2={props.report['500'].amount}
          value2Sign='equals'
        />
        <VATFormRow
          title='Guthaben der steuerpflichtigen Person'
          digitColumn1='510'
        />
      </>
    )}
  </div>
)

export default VATFormGroupTaxCalculation
