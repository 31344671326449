import { Button, Flex, Heading, NonIdealState } from '@nextbusiness/infinity-ui'
import { IconSize } from '@nextbusiness/infinity-ui-icons'
import { motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import ReportPage, { ReportPagePaths } from 'pages/ReportPage'
import { useHistory } from 'react-router-dom'
import { useRootStore } from '../../stores/RootStoreContext'
import FinancialHealthCards from './FinancialHealthCards'

interface ReportingFinancialHealthProps {
  reportPage: ReportPage
}

const ReportingFinancialHealth = (props: ReportingFinancialHealthProps) => {
  const { reportingStore, preferencesStore } = useRootStore()
  const history = useHistory()

  const status = reportingStore.status()
  const isEmpty = status.isEmpty

  return (
    <div
      className={
        'reporting-section financial-health' + (isEmpty ? ' is-empty' : '')
      }
    >
      <Flex
        className='financial-health-header'
        horizontalAlignment='space-between'
      >
        <Heading type='h3' bare>
          Finanzielle Gesundheit
        </Heading>
        {!isEmpty &&
          preferencesStore.isUsingBetaFeature(
            'financial-health-for-startups'
          ) && (
            <Button
              iconLeft='edit'
              variant='quaternary'
              onClick={() =>
                props.reportPage.open(
                  ReportPagePaths.FinancialHealthCustomisation
                )
              }
            >
              Kacheln anpassen
            </Button>
          )}
      </Flex>
      <FinancialHealthCards />
      {isEmpty && (
        <motion.div
          className='empty-hint'
          initial={{ opacity: 0, scale: 0.75 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            type: 'spring',
            mass: 1,
            stiffness: 680,
            damping: 80,
            delay: 0.2,
          }}
        >
          <NonIdealState
            icon='chart'
            iconSize={IconSize.Regular}
            title='Analysiere deine finanzielle Gesundheit im Detail.'
            actions={[
              {
                children: 'Buchungen erfassen',
                iconLeft: 'plus',
                variant: 'primary',
                onClick: () => history.push('/buchen'),
              },
            ]}
          >
            Nachdem du erste Buchungen getätigt hast, erscheinen hier weitere
            Auswertungen, die du analysieren kannst.
          </NonIdealState>
        </motion.div>
      )}
    </div>
  )
}

export default observer(ReportingFinancialHealth)
