import { Text } from '@nextbusiness/infinity-ui'
import OnboardScreen from 'components/onboard-screen/OnboardScreen'
import VendorInvoicesSetupHeroImage from '../../assets/hero-imagery/vendor-invoices-setup.png'

const VendorInvoicesEmptyScreen = () => {
  return (
    <OnboardScreen
      className='vendor-invoices-onboarding'
      image={VendorInvoicesSetupHeroImage}
      title='Verbuche Rechnungen von deinen Lieferanten ganz einfach.'
    >
      <Text type='paragraph'>
        Rechnungen, die du von deinen Lieferanten erhältst, kannst du in
        Infinity Finance verbuchen. So kannst siehst du immer, was wann fällig
        ist und kannst sie später einfach wieder finden.
      </Text>
      <Text type='paragraph'>
        Um loszulegen, erstelle eine neue Rechnung oder scanne ein Dokument ein.
      </Text>
    </OnboardScreen>
  )
}

export default VendorInvoicesEmptyScreen
