import { RadioGroup } from '@nextbusiness/infinity-ui'
import { TemplateInspectorProps } from '../TemplateInspector'
import TemplateInspectorGroup from '../TemplateInspectorGroup'
import RangeSlider from 'components/range-slider/RangeSlider'

const TemplateInspectorAddressPosition = (props: TemplateInspectorProps) => (
  <>
    <TemplateInspectorGroup>
      <RadioGroup
        options={[
          { label: 'Adresse links', value: 'left' },
          { label: 'Adresse rechts', value: 'right' },
        ]}
        value={props.template.addressLinePosition}
        onChange={(addressLinePosition) =>
          props.modifyTemplate({ addressLinePosition })
        }
      />
    </TemplateInspectorGroup>
    <TemplateInspectorGroup title='Vertikale Position'>
      <RangeSlider
        min={-21}
        max={0}
        step={1}
        value={props.template.receiverAddressVerticalShift ?? 0}
        onChange={(receiverAddressVerticalShift) =>
          props.modifyTemplate({ receiverAddressVerticalShift })
        }
        onDoubleClick={() =>
          props.resetPropertyToDefault('receiverAddressVerticalShift')
        }
      />
    </TemplateInspectorGroup>
  </>
)

export default TemplateInspectorAddressPosition
