import { Flex, RadioGroup, Text } from '@nextbusiness/infinity-ui'
import Lockup from 'components/text/Lockup'
import { useEffect } from 'react'
import DateUtilities from 'utility/DateUtilites'
import FiscalYearOnboardingField from './FiscalYearOnboardingField'
import './FiscalYearOnboardingOption.scss'
import { FiscalYearType, useInitialSetupContext } from './InitialSetupContext'
import OnboardingOption from './OnboardingOption'
import OnboardingOptionProperty from './OnboardingOptionProperty'

const FiscalYearOnboardingOption = () => {
  const {
    fiscalYearType,
    setFiscalYearType,
    fiscalYearStart,
    setFiscalYearStart,
    fiscalYearEnd,
    setFiscalYearEnd,
    shouldValidate,
  } = useInitialSetupContext()
  const currentYear = new Date().getFullYear()

  useEffect(() => {
    if (fiscalYearType === FiscalYearType.CalendarYear) {
      const startOfYear = DateUtilities.startOfYear()

      setFiscalYearStart(startOfYear)
      setFiscalYearEnd(DateUtilities.yearsAfter(startOfYear, 1, -1))
    } else if (
      fiscalYearType === FiscalYearType.CustomYear &&
      fiscalYearStart
    ) {
      setFiscalYearEnd(DateUtilities.yearsAfter(fiscalYearStart, 1, -1))
    }
  }, [fiscalYearType, setFiscalYearStart, setFiscalYearEnd])

  return (
    <OnboardingOption
      icon='schedule'
      title='Erstes Geschäftsjahr'
      description={`Kalenderjahr beginnend ${currentYear}`}
    >
      <RadioGroup
        value={fiscalYearType}
        onChange={setFiscalYearType}
        options={[
          {
            value: FiscalYearType.CalendarYear,
            label: 'Dieses Kalenderjahr (Standard)',
          },
          { value: FiscalYearType.CustomYear, label: 'Eigenes Geschäftsjahr' },
          {
            value: FiscalYearType.CustomLongOrShortYear,
            label: 'Eigenes Geschäftsjahr mit erstem Lang- oder Kurzjahr',
          },
        ]}
      />
      {fiscalYearType !== FiscalYearType.CalendarYear && (
        <OnboardingOptionProperty>
          <Flex className='fiscal-year-fields' gap='tiny'>
            <FiscalYearOnboardingField
              title='Beginn 1. Geschäftsjahr'
              value={fiscalYearStart}
              onChange={(date) => setFiscalYearStart(date)}
              updateOtherField={(date) => {
                if (fiscalYearType !== FiscalYearType.CustomLongOrShortYear)
                  setFiscalYearEnd(DateUtilities.yearsAfter(date, 1, -1))
              }}
              shouldValidate={shouldValidate}
            />
            <FiscalYearOnboardingField
              title='Ende 1. Geschäftsjahr'
              value={fiscalYearEnd}
              onChange={(date) => setFiscalYearEnd(date)}
              updateOtherField={(date) => {
                if (fiscalYearType !== FiscalYearType.CustomLongOrShortYear)
                  setFiscalYearStart(DateUtilities.yearsAfter(date, -1, 1))
              }}
              shouldValidate={shouldValidate}
            />
          </Flex>
          {fiscalYearType === FiscalYearType.CustomLongOrShortYear && (
            <Text variant='subtle'>
              Das darauffolgende 2. Geschäftsjahr wird dann automatisch wieder
              mit normaler Länge erstellt.
            </Text>
          )}
          <Text variant='subtle'>
            Bei saisonalen Geschäften ist es oft sinnvoll, das Geschäftsjahr so
            zu wählen, dass eine volle Saison abgedeckt wird.
          </Text>
        </OnboardingOptionProperty>
      )}
      <Lockup icon='ask-question' cushion='tiny'>
        Dies kann im Nachhinein durch eine Supportanfrage geändert werden.
      </Lockup>
    </OnboardingOption>
  )
}

export default FiscalYearOnboardingOption
