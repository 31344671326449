import { Button } from '@nextbusiness/infinity-ui'
import { useTransactionEditorContext } from '../TransactionEditorContext'

interface TransactionEditorCancelButtonProps {
  title: string
}

const TransactionEditorCancelButton = (
  props: TransactionEditorCancelButtonProps
) => {
  const { closeEditor } = useTransactionEditorContext()

  return (
    <Button className='cancel-button' onClick={() => closeEditor()}>
      {props.title}
    </Button>
  )
}

export default TransactionEditorCancelButton
