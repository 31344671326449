import ContentLoader from 'react-content-loader'

const ExtendedInvoiceListItemLoading = () => (
  <div>
    <ContentLoader
      speed={2}
      width={154}
      height={270}
      viewBox='0 0 154 270'
      backgroundColor='#f3f3f3'
      foregroundColor='#ecebeb'
    >
      <rect x='4' y='12' rx='4' ry='4' width='104' height='144' />
      <rect x='4' y='184' rx='2' ry='2' width='67' height='12' />
      <rect x='4' y='210' rx='2' ry='2' width='87' height='14' />
    </ContentLoader>
  </div>
)

export default ExtendedInvoiceListItemLoading
