import { Button, Text } from '@nextbusiness/infinity-ui'
import DocumentsDuePrompt from 'documents/DocumentsDuePrompt'
import { OpenVATPeriod } from 'model/VAT'
import DocumentsPage, { DocumentsPagePaths } from 'pages/DocumentsPage'
import VATIcon from 'assets/financial-close/financial-close-vat.svg'

interface VATPeriodDuePromptProps {
  page: DocumentsPage
  period: OpenVATPeriod
}

const VATPeriodDuePrompt = (props: VATPeriodDuePromptProps) => (
  <DocumentsDuePrompt
    className='vat-period-due-prompt'
    title='Mehrwertsteuerabrechnung fällig'
    icon={<img src={VATIcon} alt='' />}
  >
    <Text>
      Für diese Periode kann die Mehrwertsteuer abgerechnet und die Zahlen der
      Steuerverwaltung gemeldet werden.
    </Text>
    <Button
      variant='primary'
      onClick={() =>
        props.page.open(DocumentsPagePaths.VATReport, props.period._id)
      }
    >
      Zur Abrechnung
    </Button>
  </DocumentsDuePrompt>
)

export default VATPeriodDuePrompt
