import { ArcElement, ChartData, Chart as ChartJS } from 'chart.js'
import useBackendQuery from 'libs/networking/BackendQuery'
import { observer } from 'mobx-react'
import { IProfitability } from 'model/FinancialHealth'
import FinancialHealth from 'networking/FinancialHealth'
import {
  CurrentReportStackNavigatorRef,
  ReportPagePaths,
} from 'pages/ReportPage'
import { Pie } from 'react-chartjs-2'
import FinancialHealthCard from 'reports/financial-health/components/FinancialHealthCard'
import FinancialHealthPercentageRow from 'reports/financial-health/components/FinancialHealthPercentageRow'
import LoaderFinancialHealthProfitability from 'reports/financial-health/loaders/LoaderFinancialHealthProfitability'
import { useRootStore } from 'stores/RootStoreContext'
import Utilities from 'utility/Utilities'
import './FinancialHealthProfitabilityCard.scss'

ChartJS.register(ArcElement)

const FinancialHealthProfitabilityCard = () => {
  const { authenticationStore, reportingStore } = useRootStore()
  const [profitability, isLoading, error] = useBackendQuery<IProfitability>(
    () =>
      FinancialHealth.getProfitability(
        authenticationStore.organisationIdentifier,
        +new Date()
      ),
    reportingStore.setProfitability
  )

  const revenue = profitability?.revenue.currentYear ?? 0
  const netProfit = profitability?.netProfit.currentYear ?? 0
  const costs = revenue - netProfit

  const data: ChartData<'pie', number[], unknown> = {
    datasets: [
      {
        label: 'Profitability',
        data: [Math.max(netProfit, 0), costs],
        backgroundColor: ['#098C70', '#D8D8D8'],
        offset: 0,
        spacing: 0,
        borderWidth: 0,
      },
    ],
  }

  return (
    <FinancialHealthCard
      title='Profitabilität'
      isLoading={isLoading}
      customLoadingState={<LoaderFinancialHealthProfitability />}
      error={error}
      className='financial-health-card-profitability'
      onClick={() =>
        CurrentReportStackNavigatorRef?.open(
          ReportPagePaths.FinancialHealthProfitability
        )
      }
    >
      <div className='profitability-chart'>
        <div className='chart-visual'>
          <Pie data={data} />
        </div>
        <div className='chart-labels'>
          <div className='chart-label label-revenue'>
            <div className='label-icon' />
            <div className='label-title'>Umsatz</div>
            <div className='label-amount'>
              CHF {Utilities.centsToCHF(revenue)}
            </div>
          </div>
          <div className='chart-label label-profit'>
            <div className='label-icon' />
            <div className='label-title'>
              {netProfit >= 0 ? 'Gewinn' : 'Verlust'}
            </div>
            <div className='label-amount'>
              CHF {Utilities.centsToCHF(netProfit)}
            </div>
          </div>
        </div>
      </div>
      <div className='profitability-metrics'>
        <FinancialHealthPercentageRow
          percentage={profitability?.netProfitMargin?.currentYear}
          label='Reingewinn'
          description='Marge in % vom Umsatz'
        />
        <FinancialHealthPercentageRow
          percentage={profitability?.grossProfitMargin?.currentYear}
          label='Bruttogewinn'
          description='Marge in % vom Umsatz'
        />
        <FinancialHealthPercentageRow
          percentage={profitability?.returnOnEquity?.currentYear}
          label='Return on Equity'
          description='Effizienz des investierten Kapitals'
        />
      </div>
    </FinancialHealthCard>
  )
}

export default observer(FinancialHealthProfitabilityCard)
