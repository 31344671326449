import SidebarGroup from '../SidebarGroup'
import DocumentSidebarPanelProps from './DocumentSidebarPanelProps'
import ColumnPicker from './components/ColumnPicker'

const DocumentSidebarCustomiseColumnsPanel = (
  props: DocumentSidebarPanelProps
) => (
  <SidebarGroup title='Spalten'>
    <ColumnPicker document={props.document} registerEdit={props.registerEdit} />
  </SidebarGroup>
)

export default DocumentSidebarCustomiseColumnsPanel
