import { Finance } from '@nextbusiness/infinity-finance-api'
import { Checkbox, Dialog, Heading } from '@nextbusiness/infinity-ui'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { useAPIAction } from 'utility/hooks/useAPIAction'
import './LedgerDisplayOptionsModal.scss'
import LedgerDisplayTransactionPreview from './LedgerDisplayTransactionPreview'

interface LedgerDisplayOptionsModalProps {
  isOpen: boolean
  onDismiss: () => void
}

const LedgerDisplayOptionsModal = (props: LedgerDisplayOptionsModalProps) => {
  const { preferencesStore } = useRootStore()

  const [displayVATTransactions, setDisplayVATTransactions] =
    useState<boolean>(false)
  const [showTransactionIndicators, setShowTransactionIndicators] =
    useState<boolean>(false)

  useEffect(() => {
    if (props.isOpen) {
      setDisplayVATTransactions(preferencesStore.shouldDisplayVATTransactions)
      setShowTransactionIndicators(
        preferencesStore.shouldShowTransactionIndicators()
      )
    }
  }, [props.isOpen, preferencesStore])

  const { perform, isLoading } = useAPIAction(
    async () => {
      await Finance.Organisation.setFlags({
        shouldDisplayVATTransactions: displayVATTransactions,
        shouldShowTransactionIndicators: showTransactionIndicators,
      })
      MixpanelAnalytics.event('Ledger display options changed')
    },
    {
      successMessage: 'Einstellungen gespeichert',
      errorMessage: 'Einstellungen konnten nicht gespeichert werden.',
      onSuccess: props.onDismiss,
      onFinally: () => preferencesStore.loadPreferences(),
    }
  )

  return (
    <Dialog
      className='ledger-display-options-modal'
      title='Anzeigeoptionen'
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      actions={[
        {
          children: 'Abbrechen',
          onClick: props.onDismiss,
        },
        {
          children: 'Speichern',
          variant: 'primary',
          onClick: perform,
          isLoading,
        },
      ]}
    >
      <LedgerDisplayTransactionPreview
        displayVATTransactions={displayVATTransactions}
        showTransactionIndicators={showTransactionIndicators}
      />
      <Heading type='h4'>Optionen</Heading>
      {preferencesStore.organisationPreferences?.VAT && (
        <Checkbox
          label='Automatische MWST-Buchungen anzeigen'
          isChecked={displayVATTransactions}
          onChange={setDisplayVATTransactions}
        />
      )}
      <Checkbox
        label='Buchungsindikatoren anzeigen'
        isChecked={showTransactionIndicators}
        onChange={setShowTransactionIndicators}
      />
    </Dialog>
  )
}

export default observer(LedgerDisplayOptionsModal)
