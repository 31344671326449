import { IconItem } from '@nextbusiness/infinity-ui-icons'

export interface NavigationItem {
  href: string
  title: string
  icon?: IconItem
  relatedTerms?: string[]
}

export const NAVIGATION_ITEMS: NavigationItem[] = [
  {
    href: '/auswerten',
    title: 'Auswerten',
    icon: 'chart',
    relatedTerms: [
      'Bilanz',
      'Erfolgsrechnung',
      'Finanzielle Gesundheit',
      'Ausgaben',
      'Einnahmen',
      'Profitabilität',
      'Fälligkeiten',
      'Finanzen',
    ],
  },
  {
    href: '/buchen',
    title: 'Buchen',
    icon: 'transaction-list',
    relatedTerms: [
      'Kontenplan',
      'Kontenblatt',
      'Live Accounting',
      'Finanzen',
      'Buchhaltung',
      'Journal',
    ],
  },
  {
    href: '/dokumente/jahresabschluss',
    title: 'Jahresabschlüsse',
    icon: 'success',
    relatedTerms: ['Jahresabschluss', 'Finanzen'],
  },
  {
    href: '/dokumente/mwst',
    title: 'MWST-Abschlüsse',
    icon: 'success',
    relatedTerms: ['MWST', 'Mehrwertsteuer', 'Quartalsabschluss', 'Finanzen'],
  },
  {
    href: '/rechnungen',
    title: 'Rechnungen',
    icon: 'bill',
    relatedTerms: ['Einkauf', 'Kreditoren'],
  },
  {
    href: '/forderungen',
    title: 'Forderungen',
    icon: 'payment',
    relatedTerms: ['Verkauf', 'Debitoren', 'QR-Rechnungen'],
  },
  {
    href: '/offerten',
    title: 'Offerten',
    icon: 'purchase-order',
    relatedTerms: ['Verkauf', 'Debitoren', 'Forderungen'],
  },
  {
    href: '/projekte',
    title: 'Projekte',
    icon: 'outline',
    relatedTerms: ['Verkauf', 'Debitoren'],
  },
  {
    href: '/dokumente',
    title: 'Dokumente',
    icon: 'document',
    relatedTerms: ['Belege', 'Einkauf'],
  },
  { href: '/optionen/design', title: 'Dokumentvorlage', icon: 'appearance' },
  { href: '/optionen/qr', title: 'QR-Rechnung', icon: 'qr-code' },
  { href: '/optionen/mwst', title: 'Mehrwertsteuer', icon: 'tax' },
  {
    href: '/optionen/features',
    title: 'Funktionen',
    icon: 'checked-checkbox',
  },
  {
    href: '/optionen/benachrichtigungen',
    title: 'Benachrichtigungen',
    icon: 'notification',
  },
  {
    href: '/optionen/profitabilitaet',
    title: 'Profitabilitätsziele',
    icon: 'target',
  },
  {
    href: '/kontakte',
    title: 'Kontakte',
    icon: 'contacts',
    relatedTerms: ['Kunden', 'Lieferanten', 'CRM'],
  },
  { href: '/welcome', title: 'Willkommenscenter', icon: 'in-progress' },
]
