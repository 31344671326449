import { Button } from '@nextbusiness/infinity-ui'

interface VATReportActionsProps {
  isPeriodDue: boolean
  isPeriodBlocked: boolean
  isReportInvalid: boolean
  openTransactionsPage: () => void
  openSettlementModal: () => void
}

const VATReportActions = (props: VATReportActionsProps) => (
  <div className='report-actions'>
    <Button
      iconLeft='bulleted-list'
      onClick={() => props.openTransactionsPage()}
    >
      Transaktionen prüfen
    </Button>
    {props.isPeriodDue && !props.isPeriodBlocked && (
      <Button
        iconLeft='check'
        variant={props.isReportInvalid ? 'tertiary' : 'primary'}
        onClick={() => props.openSettlementModal()}
      >
        Abrechnen
      </Button>
    )}
  </div>
)

export default VATReportActions
