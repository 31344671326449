import { NonIdealState, Text } from '@nextbusiness/infinity-ui'

interface DocumentAttachmentsErrorProps {
  retry: () => void
}

const DocumentAttachmentsError = (props: DocumentAttachmentsErrorProps) => (
  <NonIdealState
    icon='error'
    actions={[
      {
        children: 'Erneut versuchen',
        onClick: () => props.retry(),
        variant: 'primary',
      },
    ]}
  >
    <Text style={{ textAlign: 'center' }}>
      Beim Laden des angehängten Beleges ist ein Fehler aufgetreten.
    </Text>
  </NonIdealState>
)

export default DocumentAttachmentsError
