import { Avatar } from '@nextbusiness/infinity-ui'
import { RouterHistory } from '@nextbusiness/infinity-ui/dist/layout/header/HeaderNavigationTabs'
import ContactStore from 'stores/ContactStore'
import { Contact } from '../../../model/Contact'
import { store } from '../../../stores/RootStoreContext'
import EverythingSearchProviderResult from '../EverythingSearchProviderResult'
import EverythingSearchResultsProvider from '../EverythingSearchResultsProvider'
import './ContactSearchProvider.scss'

class ContactSearchProvider implements EverythingSearchResultsProvider {
  providerName = 'Kontakte'

  private resultForAccount(contact: Contact): EverythingSearchProviderResult {
    const contactName = ContactStore.nameOfContact(contact)
    return {
      id: contact.id,
      customIconView: (
        <div className='contact-avatar-preview'>
          <Avatar name={contactName} />
        </div>
      ),
      title: contactName,
      type: 'contact',
    }
  }

  resultsForQuery(query: string): Promise<EverythingSearchProviderResult[]> {
    const results: EverythingSearchProviderResult[] = []

    for (const item of store.contactStore.allContacts) {
      if (
        ContactStore.nameOfContact(item)
          .toLowerCase()
          .includes(query.toLowerCase())
      ) {
        results.push(this.resultForAccount(item))
      }
    }
    return Promise.resolve(results)
  }

  onResultSelected(
    result: EverythingSearchProviderResult,
    history?: RouterHistory
  ) {
    const contactId = result.id
    history?.push('/kontakte/' + contactId)
  }
}

export default ContactSearchProvider
