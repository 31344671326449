import { TemplatePreviewProps } from './TemplatePreview'

const TemplatePreviewRecipient = (props: TemplatePreviewProps) => (
  <div
    className='template-page-recipient'
    style={{
      /** Since the vertical shift is in mm, we need to convert it to px.
       * The ratio was chosen such that the top lines of both addresses are roughly aligned at a shift of -21mm. */
      marginTop: (props.template.receiverAddressVerticalShift ?? 0) * 2.88,
    }}
  >
    <div className='envelope-window'>
      <div
        className='envelope-sender-row'
        style={{
          visibility: props.template.disableSenderRowLine
            ? 'hidden'
            : 'visible',
        }}
      >
        {props.template.organisationName}
        {props.template.organisationName && props.template.addressLine
          ? `, `
          : ''}
        {props.template.addressLine}
        {(props.template.addressLine || props.template.organisationName) &&
        (props.template.zipCode || props.template.city)
          ? ', '
          : ''}
        {props.template.zipCode} {props.template.city}
      </div>
      <div className='envelope-recipient'>
        <span>Frau</span>
        <span>Sabine Holzberg</span>
        <span>Börsenstrasse 10</span>
        <span>8000 Zürich</span>
      </div>
    </div>
  </div>
)

export default TemplatePreviewRecipient
