import {
  FeedbackBanner,
  Flex,
  InputField,
  RichSelect,
  Textarea,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useEffect, useRef } from 'react'
import { translatedContactKeys } from '../../model/Contact'
import contactDialogStore from '../../stores/ContactDialogStore'
import './ContactEditor.scss'
import KeyValuePair from './KeyValuePair'

interface ContactEditorProps {
  localErrorMessage: string | undefined
  missingName: boolean
}

const ContactEditor = (props: ContactEditorProps) => {
  const { draftContact, setDraftContact } = contactDialogStore

  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    const textarea = textareaRef?.current
    if (!textarea) return

    textarea.style.height = '0.5rem'
    textarea.style.height = `${textarea.scrollHeight / 10}rem`
  }, [draftContact['note']])

  return (
    <>
      <Flex direction='vertical' className='contact-filter'>
        <KeyValuePair
          className='edit-mode'
          fieldKey={translatedContactKeys['category']}
          fieldValue={
            <div className='edit-field select'>
              <RichSelect
                value={draftContact['category']}
                options={[
                  {
                    label: 'Ohne Kategorie',
                    value: null,
                  },
                  {
                    label: 'Kunden',
                    value: 'clients',
                  },
                  {
                    label: 'Lieferanten',
                    value: 'suppliers',
                  },
                  {
                    label: 'Partner',
                    value: 'partners',
                  },
                  {
                    label: 'Team',
                    value: 'team',
                  },
                ]}
                onChange={(value) =>
                  setDraftContact({
                    ...draftContact,
                    category: value as string,
                  })
                }
              />
            </div>
          }
        />
      </Flex>
      <Flex direction='vertical' className='contact-info'>
        {[
          'address',
          'secondaryAddressLine',
          'postCode',
          'country',
          'phone',
          'mobile',
          'email',
          'website',
          'vatNumber',
        ].map((key) => (
          <KeyValuePair
            key={key}
            className='edit-mode'
            fieldKey={translatedContactKeys[key]}
            fieldValue={
              <InputField
                className='edit-field'
                value={draftContact[key]}
                onChange={(value: string) =>
                  setDraftContact({ ...draftContact, [key]: value })
                }
              />
            }
          />
        ))}
        <KeyValuePair
          className='edit-mode'
          fieldKey='Bemerkungen'
          fieldValue={
            <Textarea
              ref={textareaRef}
              className='edit-field'
              value={draftContact['note']}
              onChange={(value: string) =>
                setDraftContact({ ...draftContact, note: value })
              }
            />
          }
        />
      </Flex>
      {props.localErrorMessage && (
        <FeedbackBanner variant='error'>
          {props.localErrorMessage}
        </FeedbackBanner>
      )}
    </>
  )
}

export default observer(ContactEditor)
