import { computed, makeObservable, observable, runInAction } from 'mobx'
import {
  ICustomerInvoicePreview,
  ICustomerInvoiceQueryParams,
} from '../model/CustomerInvoice'
import CustomerInvoices from '../networking/CustomerInvoices'
import RootStore from './RootStore'
import Store from './Store'

class CustomerInvoiceStore extends Store {
  @observable allCustomerInvoices: ICustomerInvoicePreview[] | undefined
  @observable unpaidCustomerInvoices: ICustomerInvoicePreview[] | undefined

  @observable allDrafts: ICustomerInvoicePreview[] | undefined
  @observable allQuotes: ICustomerInvoicePreview[] | undefined

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  public loadAll() {
    if (!this.rootStore.accountStore.currentFiscalYear) return
    this.rootStore.templateStore.loadCurrentTemplate()

    this.rootStore.customerInvoiceStore.loadDrafts()
    this.rootStore.customerInvoiceStore.loadUnpaidCustomerInvoices()
    this.rootStore.customerInvoiceStore.loadQuotes()
    this.rootStore.customerInvoiceStore.loadAllCustomerInvoices()
  }

  private loadCustomerInvoices = async (
    queryParams?: ICustomerInvoiceQueryParams
  ) => {
    return CustomerInvoices.getCustomerInvoices(
      this.rootStore.authenticationStore.organisationIdentifier,
      this.rootStore.accountStore.currentFiscalYear!.year,
      queryParams
    )
  }

  loadAllCustomerInvoices = async () => {
    const invoices = (
      await this.loadCustomerInvoices({
        isQuote: false,
        isDraft: false,
      })
    ).sort((a, b) => {
      if (
        new Date(b.openingDate).getTime() !== new Date(a.openingDate).getTime()
      ) {
        return (
          new Date(b.openingDate).getTime() - new Date(a.openingDate).getTime()
        )
      }
      return b.invoiceNumber - a.invoiceNumber
    })
    runInAction(() => {
      this.allCustomerInvoices = invoices
    })
  }

  loadUnpaidCustomerInvoices = async () => {
    const unpaidInvoices = await this.loadCustomerInvoices({
      isQuote: false,
      isDraft: false,
      isPaid: false,
    })
    runInAction(() => {
      this.unpaidCustomerInvoices = unpaidInvoices
    })
  }

  loadDrafts = async () => {
    const drafts = await this.loadCustomerInvoices({ isDraft: true })
    runInAction(() => {
      this.allDrafts = drafts
    })
  }

  loadQuotes = async () => {
    const quotes = await this.loadCustomerInvoices({
      isQuote: true,
      isDraft: false,
    })
    runInAction(() => {
      this.allQuotes = quotes.toSorted((a, b) => b.openingDate - a.openingDate)
    })
  }

  @computed
  public get invoiceDrafts() {
    return this.allDrafts?.filter((invoice) => !invoice.isQuote)
  }

  @computed
  public get quoteDrafts() {
    return this.allDrafts?.filter((invoice) => invoice.isQuote)
  }

  @computed
  get allDocuments() {
    return [
      ...(this.allCustomerInvoices ?? []),
      ...(this.allDrafts ?? []),
      ...(this.allQuotes ?? []),
    ].sort((a, b) => {
      return b.openingDate - a.openingDate
    })
  }
}

export default CustomerInvoiceStore
