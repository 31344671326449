import { InputField } from '@nextbusiness/infinity-ui'
import { forwardRef } from 'react'
import './FilterSearchInput.scss'

interface FilterSearchInputProps {
  searchQuery: string
  onSearchQueryChanged: (searchQuery: string) => void
  onArrowUp: () => void
  onArrowDown: () => void
  onEnter: () => void
}

const FilterSearchInput = forwardRef<HTMLInputElement, FilterSearchInputProps>(
  function FilterSearchInput(props, ref) {
    return (
      <InputField
        bare
        className='ui-filter-search-input'
        ref={ref}
        placeholder='Suche'
        value={props.searchQuery}
        onChange={props.onSearchQueryChanged}
        fullWidth
        onKeyDown={(event) => {
          if (event.key === 'ArrowUp') {
            props.onArrowUp()
          } else if (event.key === 'ArrowDown') {
            props.onArrowDown()
          } else if (event.key === 'Enter') {
            props.onEnter()
          }
        }}
      />
    )
  }
)

export default FilterSearchInput
