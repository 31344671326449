import VendorInvoicePage from 'pages/VendorInvoicePage'
import VendorInvoiceDetailView from './VendorInvoiceDetailView'

interface VendorInvoiceDetailSheetProps {
  page: VendorInvoicePage
}

const VendorInvoiceDetailSheet = (props: VendorInvoiceDetailSheetProps) => (
  <VendorInvoiceDetailView
    invoiceId={props.page.currentParam!}
    onDismiss={() => props.page.popCurrentView()}
  />
)

export default VendorInvoiceDetailSheet
