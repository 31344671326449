import { Tooltip } from '@nextbusiness/infinity-ui'
import { Icon, IconItem } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'

interface TransactionIndicatorProps {
  icon: IconItem
  tooltip: string
  className?: string
}

const TransactionIndicator = (props: TransactionIndicatorProps) => (
  <Tooltip useChildAsTrigger content={props.tooltip}>
    <div className={classNames('transaction-indicator', props.className)}>
      <Icon icon={props.icon} size={18} />
    </div>
  </Tooltip>
)

export default TransactionIndicator
