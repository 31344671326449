import MoreMenu from 'components/more-menu/MoreMenu'
import { useIntercom } from 'react-use-intercom'

export const ONBOARDING_VIDEO_URL =
  'https://www.youtube.com/watch?v=YRJ3qC0d9yU'
export const HELP_CENTER_URL = 'https://help.infinity.swiss'

const WelcomeCentreHelpMenu = () => {
  const intercom = useIntercom()
  return (
    <MoreMenu
      customIcon='help'
      customButtonText='Hilfe'
      actions={[
        {
          buttons: [
            {
              text: 'Einführungsvideo',
              icon: 'video-camera',
              onClick: () => window.open(ONBOARDING_VIDEO_URL),
            },
            {
              text: 'Hilfecenter',
              icon: 'help',
              onClick: () => window.open(HELP_CENTER_URL),
            },
            {
              text: 'Chatte mit uns',
              icon: 'chat',
              onClick: () => intercom.showNewMessages(),
            },
          ],
        },
      ]}
    />
  )
}

export default WelcomeCentreHelpMenu
