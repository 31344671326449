import { ITransaction } from '@nextbusiness/infinity-finance-api'
import { TransactionFilterContext } from 'ledger/transactions/filter/TransactionFilterContext'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useRootStore } from 'stores/RootStoreContext'

interface AccountSheetContextType {
  isTransactionCreatorOpen: boolean
  highlightedTransactionIds?: string[]
  hasUnsavedChanges: boolean
  transactionBeingEdited?: string
  transactionsToBeDeleted?: string[]
  abortToTransaction?: string
  hasScrolled: boolean
  isHeaderCollapsed: boolean
  hasListReachedBottom: boolean
  setIsTransactionCreatorOpen: (isOpen: boolean) => void
  highlightTransactionWithIds: (transactionIds: string[] | undefined) => void
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void
  setTransactionBeingEdited: (transactionId?: string) => void
  setTransactionsToBeDeleted: (transactionIds?: string[]) => void
  setAbortToTransaction: (transactionId?: string) => void
  setHasScrolled: (hasScrolled: boolean) => void
  setIsHeaderCollapsed: (isHeaderCollapsed: boolean) => void
  setHasListReachedBottom: (hasListReachedBottom: boolean) => void
  replaceTransactions: (
    previousTransactions: ITransaction[],
    newTransactions: ITransaction[]
  ) => void
}

export const AccountSheetContext =
  React.createContext<AccountSheetContextType | null>(null)

export const useAccountSheetContext = () => {
  const context = useContext(AccountSheetContext)
  if (!context) {
    throw new Error(
      'useAccountSheetContext must be used within an AccountSheetProvider'
    )
  }
  return context
}

interface AccountSheetProviderProps {
  children: React.ReactNode
}

const AccountSheetProvider = (props: AccountSheetProviderProps) => {
  const { accountStore, transactionStore } = useRootStore()

  const [isTransactionCreatorOpen, setIsTransactionCreatorOpen] =
    useState<boolean>(false)
  const [highlightedTransactionIds, setHighlightedTransactionIds] =
    useState<string[]>()
  const [transactionsToBeDeleted, setTransactionsToBeDeleted] =
    useState<string[]>()

  const filterContext = useContext(TransactionFilterContext)
  const isFiltering = filterContext?.isFiltering

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false)
  const [transactionBeingEdited, setTransactionBeingEdited] = useState<string>()
  const [abortToTransaction, setAbortToTransaction] = useState<string>()

  const [hasScrolled, setHasScrolled] = useState<boolean>(false)
  const [isHeaderCollapsed, setIsHeaderCollapsed] = useState<boolean>(false)
  const [hasListReachedBottom, setHasListReachedBottom] =
    useState<boolean>(false)

  useEffect(() => {
    if (highlightedTransactionIds)
      setTimeout(() => setHighlightedTransactionIds(undefined), 250)
  }, [highlightedTransactionIds])

  const highlightTransactionWithIds = (transactionIds: string[] | undefined) =>
    setHighlightedTransactionIds(transactionIds)

  const replaceTransactions = useCallback(
    (previousTransactions: ITransaction[], newTransactions: ITransaction[]) => {
      runInAction(() => {
        const previousTransactionIds = previousTransactions.map(
          (transaction) => transaction.id
        )
        const previousLinkedTransactionIds = previousTransactions.flatMap(
          (transaction) => transaction.linkedTransactions ?? []
        )
        transactionStore.replaceTransactionsInStore(
          [...previousTransactionIds, ...previousLinkedTransactionIds],
          newTransactions,
          isFiltering
        )
        const newTransactionIds = newTransactions.map(
          (transaction) => transaction.id
        )
        setTransactionBeingEdited(undefined)
        highlightTransactionWithIds(newTransactionIds)
        accountStore.loadAccounts()
      })
    },
    [isFiltering]
  )

  const value: AccountSheetContextType = useMemo(
    () => ({
      isTransactionCreatorOpen,
      highlightedTransactionIds,
      hasUnsavedChanges,
      transactionBeingEdited,
      transactionsToBeDeleted,
      abortToTransaction,
      hasScrolled,
      isHeaderCollapsed,
      hasListReachedBottom,
      setIsTransactionCreatorOpen,
      highlightTransactionWithIds,
      setHasUnsavedChanges,
      setTransactionBeingEdited,
      setTransactionsToBeDeleted,
      setAbortToTransaction,
      setHasScrolled,
      setIsHeaderCollapsed,
      setHasListReachedBottom,
      replaceTransactions,
    }),
    [
      isTransactionCreatorOpen,
      highlightedTransactionIds,
      hasUnsavedChanges,
      transactionBeingEdited,
      transactionsToBeDeleted,
      abortToTransaction,
      hasScrolled,
      isHeaderCollapsed,
      hasListReachedBottom,
      replaceTransactions,
    ]
  )

  return (
    <AccountSheetContext.Provider value={value}>
      {props.children}
    </AccountSheetContext.Provider>
  )
}

export default observer(AccountSheetProvider)
