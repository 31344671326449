import { Flex, Heading } from '@nextbusiness/infinity-ui'
import ContactSwatch from 'invoices/shared-components/ContactSwatch'
import InvoiceStatus from 'invoices/shared-components/InvoiceStatus'
import { useVendorInvoiceContext } from './VendorInvoiceContext'
import './VendorInvoiceDetailHeader.scss'
import VendorInvoiceEditActions from './VendorInvoiceEditActions'
import { useVendorInvoiceEditor } from './VendorInvoiceEditorContext'
import VendorInvoiceMoreMenu from './VendorInvoiceMoreMenu'
import VendorInvoicePaymentAction from './VendorInvoicePaymentAction'

const VendorInvoiceDetailHeader = () => {
  const { invoice } = useVendorInvoiceContext()
  const { invoiceDraft, isInvoiceDraftTouched } = useVendorInvoiceEditor()

  return (
    <Flex
      className='vendor-invoice-detail-header-inner'
      gap='small'
      verticalAlignment='center'
    >
      <ContactSwatch contactId={invoice.creditor} />
      <div className='depiction'>
        <Heading type='h3' bare>
          {invoiceDraft.title}
        </Heading>
        <InvoiceStatus dueDate={invoice.dueDate} isPaid={invoice.isPaid} />
      </div>
      {isInvoiceDraftTouched ? (
        <VendorInvoiceEditActions />
      ) : (
        <Flex gap='tiny' className='actions'>
          <VendorInvoiceMoreMenu />
          <VendorInvoicePaymentAction />
        </Flex>
      )}
    </Flex>
  )
}

export default VendorInvoiceDetailHeader
