import { Finance } from '@nextbusiness/infinity-finance-api'
import { Flex, Text } from '@nextbusiness/infinity-ui'
import ErrorScreen from 'base-components/layout/ErrorScreen'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import useAPIQuery from 'libs/networking/APIQuery'
import { observer } from 'mobx-react'
import { IAccount } from 'model/Account'
import { useRootStore } from 'stores/RootStoreContext'
import LiveAccountingSetupProviders from './LiveAccountingSetupProviders'
import LiveAccountingSetupWarnings from './LiveAccountingSetupWarnings'

interface LiveAccountingSetupSelectBankProps {
  account: IAccount
  dismiss: () => void
}

const LiveAccountingSetupSelectBank = (
  props: LiveAccountingSetupSelectBankProps
) => {
  const { accountStore } = useRootStore()
  const [providers, isLoadingProviders, error, reloadProviders] = useAPIQuery(
    () => Finance.Ledger.liveAccountingProviders(),
    { errorStateOnly: true }
  )
  const newestAccount = accountStore.newestAccountWithNumber(
    props.account.accountNumber
  )
  if (!newestAccount) return null

  return (
    <MagicSheetContainer>
      <Text type='inline'>
        Wähle hier die Bank aus, bei welcher du dein Geschäftskonto führst.{' '}
        <br />
        Du wirst dann zum E-Banking der Bank weitergeleitet, um es mit Infinity
        Finance zu verknüpfen.
      </Text>
      <LiveAccountingSetupWarnings />
      <Flex className='provider-list'>
        {isLoadingProviders ? (
          <LoadingScreen />
        ) : providers?.length ? (
          <LiveAccountingSetupProviders
            account={newestAccount}
            providers={providers}
            dismiss={props.dismiss}
          />
        ) : (
          <ErrorScreen
            title='Bankanbieter können zurzeit nicht geladen werden.'
            error={error}
            onRetry={reloadProviders}
          />
        )}
      </Flex>
    </MagicSheetContainer>
  )
}

export default observer(LiveAccountingSetupSelectBank)
