import { DatePicker } from '@nextbusiness/infinity-ui'
import DateUtilities from 'utility/DateUtilites'
import { useTransactionCreatorContext } from '../TransactionCreatorContext'

const TransactionCreatorDate = () => {
  const { transaction, updateTransaction } = useTransactionCreatorContext()

  const currentDate = transaction.date ? new Date(transaction.date) : undefined
  const onDateChanged = (date?: Date) =>
    updateTransaction({
      date: date ? DateUtilities.startOfDay(date).getTime() : undefined,
    })

  return (
    <DatePicker
      inputFieldProps={{ icon: undefined, className: 'date-picker-input' }}
      placeholder='Datum'
      value={currentDate}
      onChange={onDateChanged}
    />
  )
}

export default TransactionCreatorDate
