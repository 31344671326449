import { AccountCardTimeframe } from '@nextbusiness/infinity-finance-api'
import { DateTime } from 'luxon'
import { round } from 'utility/Utilities'

export type AccountCardGranularity = 'day' | 'week' | 'month' | 'quarter'

class AccountCardChartConfiguration {
  protected granularity: AccountCardGranularity
  protected timeframe: AccountCardTimeframe
  protected dataLength: number
  private _timestamps?: number[]

  constructor(
    granularity: AccountCardGranularity,
    timeframe: AccountCardTimeframe,
    dataLength: number,
    timestamps?: number[]
  ) {
    this.granularity = granularity
    this.timeframe = timeframe
    this.dataLength = dataLength
    this._timestamps = timestamps
  }

  protected granularityInDays() {
    switch (this.granularity) {
      case 'day':
        return 1
      case 'week':
        return 7
      case 'month':
        return 30
      case 'quarter':
        return 90
    }
  }

  protected timestamps() {
    if (this._timestamps) {
      return this._timestamps.map((t) => DateTime.fromMillis(t))
    }
    return Array.from({ length: this.dataLength }, (_, i) =>
      DateTime.now().minus({
        days: this.granularityInDays() * i,
      })
    ).reverse()
  }

  public labels() {
    switch (this.granularity) {
      case 'day':
        return this.timestamps().map(
          (t) => `${t.setLocale('de-CH').monthShort} ${t.day}`
        )
      case 'week':
        return this.timestamps().map((t) => t.weekNumber.toString())
      case 'month':
        return this.timestamps().map((t) => t.setLocale('de-CH').monthShort)
      case 'quarter':
        return this.timestamps().map((t) => t.quarter.toString())
    }
  }

  public formatValueLabel(value: string | number) {
    const cents = parseInt(value.toString())
    const chf = cents / 100
    return Math.abs(chf) < 1000
      ? round(chf, 2).toString()
      : `${round(chf / 1000, 2)}K`
  }
}

export default AccountCardChartConfiguration
