import ISubscription from 'model/Subscription'
import SubscriptionCapability from './SubscriptionCapability'
import SubscriptionTier from './SubscriptionTier'
import SubscriptionTiers from './SubscriptionTiers'

class Subscription {
  private readonly data: ISubscription

  constructor(from: ISubscription) {
    this.data = from
  }

  public get remainingTrialDays() {
    const expiration = new Date(this.data.billingCycleEnd)
    const now = new Date()

    const remaining = expiration.getTime() - now.getTime()
    const numberOfDays = Math.ceil(remaining / (1000 * 3600 * 24))

    return Math.max(0, numberOfDays)
  }

  public get isTrialing() {
    return this.data.type === 'trialing'
  }

  public get tier(): SubscriptionTier {
    if (this.isTrialing) return Subscription.defaultTrialTier

    const identifier = this.data.identifier
    const tier = SubscriptionTiers.find((tier) =>
      tier.identifiers.includes(identifier)
    )
    return tier ?? Subscription.defaultTier
  }

  public hasCapability(capability: SubscriptionCapability) {
    return this.tier.capabilities?.includes(capability) ?? false
  }

  public static get defaultTier(): SubscriptionTier {
    return (
      SubscriptionTiers.find((tier) => tier.isDefaultTier) ??
      SubscriptionTiers[0]
    )
  }

  public static get defaultTrialTier(): SubscriptionTier {
    return (
      SubscriptionTiers.find((tier) => tier.isDefaultTrialTier) ??
      this.defaultTier
    )
  }
}

export default Subscription
