import { Finance, IProject } from '@nextbusiness/infinity-finance-api'
import { Flex, LoadingIndicator } from '@nextbusiness/infinity-ui'
import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import ProjectPage from 'pages/ProjectPage'
import React, { Component } from 'react'
import './ProjectView.scss'
import ProjectViewContent from './ProjectViewContent'
import ProjectViewSidebar from './ProjectViewSidebar'

interface IProjectContext {
  refreshProject: () => Promise<void>
}

export const ProjectContext = React.createContext<IProjectContext | null>(null)

interface ProjectViewProps {
  projectId?: string
  projectPage?: ProjectPage
  dismissView: () => void
}

interface ProjectViewState {
  project?: IProject
}

class ProjectView extends Component<ProjectViewProps, ProjectViewState> {
  state: ProjectViewState = {}

  async componentDidMount() {
    await this.fetchProject()
  }

  fetchProject = async () => {
    const project = await Finance.Projects.project(
      this.props.projectId ?? this.props.projectPage!.currentParam!
    )
    this.setState({ project })
    MixpanelAnalytics.event('Project viewed')
  }

  render() {
    return (
      <MagicSheetPage
        containerClassName='project-view'
        header={<MagicSheetTitleBar title='Projekt' />}
        noTrailingNegativeSpace
      >
        <MagicSheetContainer>
          <Flex fillContainer>
            {!this.state.project ? (
              <LoadingIndicator />
            ) : (
              <ProjectContext.Provider
                value={{ refreshProject: this.fetchProject }}
              >
                <ProjectViewSidebar project={this.state.project} />
                <div className='seperator' />
                <ProjectViewContent project={this.state.project} />
              </ProjectContext.Provider>
            )}
          </Flex>
        </MagicSheetContainer>
      </MagicSheetPage>
    )
  }
}

export default ProjectView
