import { VATCalculationMode } from '@nextbusiness/infinity-finance-api'
import { Heading } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react-lite'
import DocumentsPage, { DocumentsPagePaths } from 'pages/DocumentsPage'
import ReportCard from 'reports/ReportCard'
import { useRootStore } from 'stores/RootStoreContext'
import FinancialCloseIcon from '../assets/financial-close/card-icon-financial-close.svg'
import VATSettlementIcon from '../assets/financial-close/card-icon-vat-settlement.svg'

interface DocumentsHomeClosingsProps {
  page: DocumentsPage
}

const DocumentsHomeClosings = (props: DocumentsHomeClosingsProps) => {
  const { accountStore, preferencesStore } = useRootStore()
  const anyFiscalYearDueForClose = accountStore.anyFiscalYearDueForClose

  const isVATSettlementPossible =
    preferencesStore.organisationPreferences?.VAT === true &&
    preferencesStore.organisationPreferences?.vatCalculationMode !==
      VATCalculationMode.Simplified

  return (
    <div className='documents-section reporting-section closings'>
      <Heading type='h3'>Abschlüsse</Heading>
      <div className='reports'>
        <ReportCard
          tintColor='#026594'
          badge={anyFiscalYearDueForClose}
          onClick={() =>
            props.page.open(DocumentsPagePaths.FinancialClosingOverview)
          }
          iconURL={FinancialCloseIcon}
          title='Jahresabschluss'
          description='Dokumente für jedes abgeschlossene Geschäftsjahr'
        />
        {isVATSettlementPossible && (
          <ReportCard
            tintColor='#983686'
            onClick={() =>
              props.page.open(DocumentsPagePaths.VATPeriodsOverview)
            }
            iconURL={VATSettlementIcon}
            title='Mehrwertsteuer'
            description='Dokumente für die Abrechnung der Mehrwertsteuerperioden'
          />
        )}
      </div>
    </div>
  )
}

export default observer(DocumentsHomeClosings)
