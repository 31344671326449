import VendorInvoiceEditorProvider from '../detail/VendorInvoiceEditorContext'
import VendorInvoiceSplitView from '../detail/VendorInvoiceSplitView'
import VendorInvoiceCreationProvider from './VendorInvoiceCreationContext'
import VendorInvoiceCreationMainPane from './VendorInvoiceCreationMainPane'
import VendorInvoiceCreationPreviewPane from './VendorInvoiceCreationPreviewPane'

interface VendorInvoiceDetailViewProps {
  onDismiss: () => void
}

const VendorInvoiceCreationView = (props: VendorInvoiceDetailViewProps) => {
  return (
    <VendorInvoiceEditorProvider>
      <VendorInvoiceCreationProvider>
        <VendorInvoiceSplitView
          leftPane={<VendorInvoiceCreationMainPane {...props} />}
          rightPane={<VendorInvoiceCreationPreviewPane />}
        />
      </VendorInvoiceCreationProvider>
    </VendorInvoiceEditorProvider>
  )
}

export default VendorInvoiceCreationView
