import { TaxCode } from '@nextbusiness/infinity-finance-api'
import { Flex } from '@nextbusiness/infinity-ui'
import ResourceSelect, {
  ResourceSelectOption,
  ResourceSelectSection,
} from 'components/resource-select/ResourceSelect'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import {
  swappedTaxCodeEra,
  taxRateEraOfTaxCode,
  taxRateTypeFromTaxCode,
} from 'utility/TaxUtilities'
import {
  IStructuredTaxCodes,
  ITaxOption,
  STRUCTURED_TAX_RATES,
  TaxRateEra,
  TaxRateType,
  taxOptionGroups,
} from './TaxCodes'
import TaxRateExplainer from './TaxRateExplainer'
import TaxRateSelection from './TaxRateSelection'
import './VATPicker.scss'
import VATSelectOption, {
  displayTextForSelectedTaxOption,
  searchTextForTaxOption,
} from './VATSelectOption'

interface VATPickerProps {
  taxCode: TaxCode
  onTaxCodeSelected?: (taxCode: string) => void
  type?: keyof IStructuredTaxCodes
  isDisabled?: boolean
  hideNoneOption?: boolean
}

const toResourceSelectOption = (
  option: ITaxOption
): ResourceSelectOption<TaxCode, ITaxOption> => ({
  value: option.code,
  item: option,
})

const VATPicker = (props: VATPickerProps) => {
  const [selectedTaxRateType, setSelectedTaxRateType] = useState<TaxRateType>(
    taxRateTypeFromTaxCode(props.taxCode)
  )
  const [taxRateEra, setTaxRateEra] = useState<TaxRateEra>(
    taxRateEraOfTaxCode(props.taxCode)
  )

  const taxGroups = taxOptionGroups({
    type: props.type,
    taxRate: STRUCTURED_TAX_RATES[taxRateEra][selectedTaxRateType],
    includeNoneOption: !props.hideNoneOption,
  })
  const sections: ResourceSelectSection<TaxCode, ITaxOption>[] = taxGroups.map(
    (taxGroup) => ({
      title: taxGroup.title,
      options: taxGroup.options.map(toResourceSelectOption),
      dividerAbove:
        (props.type === 'income' && taxGroup.title === 'Ausland') ||
        (props.type === 'expense' && taxGroup.title === 'Keine'),
    })
  )

  const searchableItems = taxOptionGroups({
    type: props.type,
    includeNoneOption: !props.hideNoneOption,
  })
    .map((group) => group.options)
    .flat()
    .filter((option) => !!option.code)
    .map(toResourceSelectOption)

  const onTaxRateEraChange = (newTaxRateEra: TaxRateEra) => {
    newTaxRateEra !== taxRateEra &&
      props.onTaxCodeSelected?.(swappedTaxCodeEra(props.taxCode))
    setTaxRateEra(newTaxRateEra)
  }

  const matchSearchTextToTaxOption = (item: ITaxOption, searchText: string) => {
    if (!searchText) return false
    const searchableText = searchTextForTaxOption(item)

    const isExactTextMatch =
      searchableText.toLowerCase() === searchText.toLowerCase()
    const isTaxCodeMatch = item.code?.toLowerCase() === searchText.toLowerCase()

    return isExactTextMatch || isTaxCodeMatch
  }

  return (
    <ResourceSelect
      className='vat-picker'
      placeholderText='MWST auswählen'
      flyoutHeaderContent={
        <Flex direction='vertical'>
          <TaxRateSelection
            selectedTaxRateType={selectedTaxRateType}
            onSelectTaxRateType={setSelectedTaxRateType}
            taxRateEra={taxRateEra}
            onTaxRateEraChange={onTaxRateEraChange}
          />
          <TaxRateExplainer
            taxRate={STRUCTURED_TAX_RATES[taxRateEra][selectedTaxRateType]}
          />
        </Flex>
      }
      onFlyoutActivation={() => {
        setSelectedTaxRateType(taxRateTypeFromTaxCode(props.taxCode))
      }}
      displayExpandable
      value={props.taxCode ?? ''}
      onChange={(taxCode) => props.onTaxCodeSelected?.(taxCode ?? '')}
      sections={sections}
      searchableItems={searchableItems}
      optionForItem={(taxOption, optionProps) => (
        <VATSelectOption
          key={optionProps.key}
          taxOption={taxOption}
          optionProps={optionProps}
        />
      )}
      searchableTextForItem={searchTextForTaxOption}
      customEnteredTextMatcher={matchSearchTextToTaxOption}
      displayTextForCurrentItem={displayTextForSelectedTaxOption}
      inputFieldProps={{
        disabled: props.isDisabled,
      }}
    />
  )
}

export default observer(VATPicker)
