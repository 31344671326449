import LoadingScreen from 'base-components/layout/LoadingScreen'
import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import { observer } from 'mobx-react'
import DocumentsPage from 'pages/DocumentsPage'
import { useCallback, useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { taxRateReportEraOfDate } from 'utility/TaxUtilities'
import { displayNameForPeriod } from '../overview/VATPeriodSection'
import VATSettlementModal from '../settlement/VATSettlementModal'
import VATForm from './VATForm'
import VATReportErrorPage from './VATReportErrorPage'
import VATReportFooter from './VATReportFooter'
import VATReportHeader from './VATReportHeader'
import './VATReportPage.scss'

interface VATReportPageProps {
  page: DocumentsPage
}

const VATReportPage = (props: VATReportPageProps) => {
  const { authenticationStore, vatPeriodStore } = useRootStore()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error>()

  const periodId = props.page.currentParam
  const [isSettlementModalOpen, setIsSettlementModalOpen] =
    useState<boolean>(false)

  const loadPeriod = useCallback(async () => {
    if (!periodId) return
    setIsLoading(true)
    setError(undefined)
    try {
      await vatPeriodStore.loadPeriodToView(periodId)
    } catch (loadingError) {
      setError(loadingError as Error)
    } finally {
      setIsLoading(false)
    }
  }, [periodId, authenticationStore.organisationIdentifier])

  useEffect(() => {
    loadPeriod()
  }, [loadPeriod])

  if (isLoading)
    return (
      <MagicSheetPage>
        <LoadingScreen loadingText='Bericht wird geladen.' />
      </MagicSheetPage>
    )

  if (error || !vatPeriodStore.periodBeingViewed)
    return (
      <VATReportErrorPage
        onRetry={() => loadPeriod()}
        onClose={() => props.page.popCurrentView()}
        error={error}
      />
    )

  const taxRateEra = taxRateReportEraOfDate(
    vatPeriodStore.periodBeingViewed.start
  )

  return (
    <MagicSheetPage
      className='vat-report-page'
      header={
        <MagicSheetTitleBar
          title={displayNameForPeriod(vatPeriodStore.periodBeingViewed)}
        />
      }
      noTrailingNegativeSpace
    >
      <MagicSheetContainer>
        <div className='vat-report'>
          <VATReportHeader />
          <VATForm
            report={vatPeriodStore.periodBeingViewed.report}
            era={taxRateEra}
          />
          <VATReportFooter
            period={vatPeriodStore.periodBeingViewed}
            page={props.page}
            openSettlementModal={() => setIsSettlementModalOpen(true)}
          />
        </div>
        <VATSettlementModal
          isOpen={isSettlementModalOpen}
          onDismiss={() => setIsSettlementModalOpen(false)}
          onSettlementSuccessful={() => {
            props.page.popCurrentView()
            vatPeriodStore.loadPeriods()
          }}
          period={vatPeriodStore.periodBeingViewed}
        />
      </MagicSheetContainer>
    </MagicSheetPage>
  )
}

export default observer(VATReportPage)
