import { useHotkeys } from 'react-hotkeys-hook'

interface CustomerInvoiceEditorShortcutsProps {
  save: () => void
  generatePDF: () => void
}

const CustomerInvoiceEditorShortcuts = (
  props: CustomerInvoiceEditorShortcutsProps
) => {
  useHotkeys(
    'mod+s',
    (event) => {
      event.preventDefault()
      props.save()
    },
    { enableOnFormTags: ['input', 'textarea', 'select'] }
  )
  useHotkeys(
    'mod+p',
    (event) => {
      event.preventDefault()
      props.generatePDF()
    },
    { enableOnFormTags: ['input', 'textarea', 'select'] }
  )
  return null
}

export default CustomerInvoiceEditorShortcuts
