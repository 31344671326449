import { ButtonProps, Dialog } from '@nextbusiness/infinity-ui'
import AssistantStepper, {
  AssistantStep,
} from 'documents/financial-closing/assistant/AssistantStepper'
import React, { useEffect, useState } from 'react'
import './Assistant.scss'

export type FullAssistantStep = AssistantStep & {
  view: React.ReactNode
  hasEnteredRequiredInformation?: () => boolean
}

interface AssistantProps {
  isOpen: boolean
  onDismiss: () => void
  title: string
  steps: FullAssistantStep[]
  finalAction: ButtonProps
  onReset?: () => void
  className?: string
}

const Assistant = (props: AssistantProps) => {
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0)
  const currentView = props.steps[currentStepIndex].id
  const canMoveForward = currentStepIndex !== props.steps.length - 1
  const canMoveBackward = currentStepIndex > 0

  const incrementViewByOne = () => {
    const nextStep = currentStepIndex + 1
    if (canMoveForward) setCurrentStepIndex(nextStep)
  }
  const decrementViewByOne = () => {
    const previousStep = currentStepIndex - 1
    if (canMoveBackward) setCurrentStepIndex(previousStep)
  }

  const cancelButtonAction: ButtonProps = {
    children: 'Abbrechen',
    onClick: props.onDismiss,
    style: {
      marginLeft: '-1rem',
      marginRight: 'auto',
    },
  }
  const backButtonAction: ButtonProps = {
    children: 'Zurück',
    onClick: () => decrementViewByOne(),
  }
  const continueButtonAction: ButtonProps = {
    variant: 'primary',
    children: 'Fortfahren',
    disabled: !props.steps[currentStepIndex].hasEnteredRequiredInformation?.(),
    onClick: () => incrementViewByOne(),
  }

  const buttonsToBeShown = () => {
    switch (currentStepIndex) {
      case 0:
        return [cancelButtonAction, continueButtonAction]
      case props.steps.length - 1:
        return [cancelButtonAction, backButtonAction, props.finalAction]
      default:
        return [cancelButtonAction, backButtonAction, continueButtonAction]
    }
  }

  useEffect(() => {
    const resetModal = () => {
      setCurrentStepIndex(0)
      props.onReset && props.onReset()
    }
    if (props.isOpen) {
      resetModal()
    }
  }, [props.isOpen])

  return (
    <Dialog
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      className={'assistant' + (props.className ? ' ' + props.className : '')}
      title={props.title}
      actions={buttonsToBeShown()}
    >
      <AssistantStepper steps={props.steps} currentStepId={currentView} />
      {props.steps[currentStepIndex].view}
    </Dialog>
  )
}

export default Assistant
