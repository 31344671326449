import {
  Flex,
  LoadingIndicator,
  NonIdealState,
} from '@nextbusiness/infinity-ui'
import { DateSuggestion } from '@nextbusiness/infinity-ui/dist/input/datepicker/DatePicker'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import { IFiscalYear } from '../../model/FiscalYear'
import Reports from '../../networking/Reports'
import { useRootStore } from '../../stores/RootStoreContext'
import DateUtilities from '../../utility/DateUtilites'
import { useLocalStorage } from '../../utility/hooks'
import IncomeStatement from './IncomeStatement'
import IncomeStatementHeader from './IncomeStatementHeader'
import './IncomeStatementPage.scss'
import {
  ISegmentizedIncomeStatement,
  segmentizeIncomeStatement,
} from './IncomeStatementUtil'

export const yearBefore = (date: Date) =>
  DateUtilities.dateSubtract(date, { weeks: 8 })

const IncomeStatementPage = () => {
  const { authenticationStore, accountStore } = useRootStore()
  const intercom = useIntercom()

  const [incomeStatement, setIncomeStatement] =
    useState<ISegmentizedIncomeStatement>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [fiscalYearStartDates, setFiscalYearStartDates] = useState<
    DateSuggestion[]
  >([])

  const [fiscalYearEndDates, setFiscalYearEndDates] = useState<
    DateSuggestion[]
  >([])

  const [fromDate, setFromDate] = useLocalStorage<Date | undefined>(
    'income-statement-from-date-' + authenticationStore.organisationIdentifier,
    undefined
  )
  const [toDate, setToDate] = useLocalStorage<Date | undefined>(
    'income-statement-to-date-' + authenticationStore.organisationIdentifier,
    undefined
  )

  const [fromDateCopy, setFromDateCopy] = useState<Date>(new Date())
  const [toDateCopy, setToDateCopy] = useState<Date>(new Date())

  const [hasScrolled, setHasScrolled] = useState<boolean>(false)

  const [errorMessage, setErrorMessage] = useState<string>()
  const [isRetryableError, setIsRetryableError] = useState<boolean>(false)

  const getIncomeStatement = async (from?: Date, to?: Date) => {
    if (!authenticationStore.organisationIdentifier)
      return authenticationStore.logout()
    if (!from || !to) {
      setErrorMessage('Bitte wähle einen gültigen Zeitraum.')
      setIsRetryableError(false)
      return
    }
    setIsLoading(true)
    try {
      const incomeStatement = (
        await Reports.getIncomeStatement(
          authenticationStore.organisationIdentifier,
          DateUtilities.startOfDay(from).valueOf(),
          DateUtilities.endOfDay(to).valueOf()
        )
      ).groups

      setIncomeStatement(segmentizeIncomeStatement(incomeStatement))
      setErrorMessage(undefined)
    } catch (error: any) {
      if (error.message === 'Period from cannot be after period to') {
        setErrorMessage('Das Startdatum muss vor dem Enddatum liegen.')
        setIsRetryableError(false)
      } else {
        setErrorMessage(
          `Die Erfolgsrechnung konnte aufgrund von einem Fehler nicht geladen werden: ${error.message}`
        )
        setIsRetryableError(true)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const getFiscalYear = async () => {
    if (!accountStore.currentFiscalYear)
      try {
        await accountStore.getFiscalYear()
        setErrorMessage(undefined)
      } catch (error: any) {
        if (error.message === 'Could not find a current fiscal year') {
          setErrorMessage(
            'Es konnte kein aktuelles Geschäftsjahr gefunden werden.'
          )
          setIsRetryableError(true)
        } else {
          setErrorMessage(
            'Das Geschäftsjahr konnte aufgrund von einem technischen Problem nicht geladen werden.'
          )
          setIsRetryableError(true)
        }
      }

    populateFiscalYearDates(accountStore.currentFiscalYear!)
  }

  const populateFiscalYearDates = (fiscalYear: IFiscalYear) => {
    const fiscalYearStartDates: DateSuggestion[] = []
    const fiscalYearEndDates: DateSuggestion[] = []

    for (let i = fiscalYear.year; i > -1 && fiscalYear.year - i < 5; i--) {
      const fiscalYearNumber = ` ${i + 1}`

      const startDate: DateSuggestion = {
        icon: 'event',
        description: 'Start Geschäftsjahr' + fiscalYearNumber,
        date: DateUtilities.dateSubtract(new Date(fiscalYear.from), {
          years: fiscalYear.year - i,
        }),
      }

      const endDate: DateSuggestion = {
        icon: 'event',
        description: 'Ende Geschäftsjahr' + fiscalYearNumber,
        date: DateUtilities.dateSubtract(new Date(fiscalYear.to), {
          years: fiscalYear.year - i,
        }),
      }

      fiscalYearStartDates.push(startDate)
      fiscalYearEndDates.push(endDate)
    }

    setFiscalYearStartDates(fiscalYearStartDates)
    setFiscalYearEndDates(fiscalYearEndDates)
  }

  useEffect(() => {
    accountStore.loadAccounts()
    getFiscalYear()
    intercom.trackEvent('income-statement-viewed')
  }, [])

  useEffect(() => {
    if (fiscalYearStartDates[0]?.date) setFromDate(fiscalYearStartDates[0].date)
    if (fiscalYearEndDates[0]?.date && !toDate)
      setToDate(fiscalYearEndDates[0].date)
  }, [fiscalYearStartDates, fiscalYearEndDates])

  useEffect(() => {
    if (fromDate) {
      setFromDateCopy(fromDate)
      getIncomeStatement(fromDate, toDate)
    }
  }, [fromDate])

  useEffect(() => {
    if (toDate) {
      setToDateCopy(toDate)
      getIncomeStatement(fromDate, toDate)
    }
  }, [toDate])

  return (
    <div className='income-statement-page'>
      <IncomeStatementHeader
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        fiscalYearStartDates={fiscalYearStartDates}
        fiscalYearEndDates={fiscalYearEndDates}
        hasScrolled={hasScrolled}
        dataHasFullyLoaded={!!incomeStatement}
      />
      {errorMessage ? (
        <NonIdealState
          icon='error'
          actions={
            isRetryableError
              ? [
                  {
                    children: 'Erneut versuchen',
                    onClick: () => getIncomeStatement(fromDate, toDate),
                    isLoading,
                  },
                ]
              : []
          }
        >
          {errorMessage}
        </NonIdealState>
      ) : !isLoading && incomeStatement ? (
        <IncomeStatement
          incomeStatement={incomeStatement}
          fromDateCopy={fromDateCopy}
          toDateCopy={toDateCopy}
          setHasScrolled={setHasScrolled}
        />
      ) : (
        <Flex
          className='loading-indicator-wrapper'
          verticalAlignment='center'
          horizontalAlignment='center'
        >
          <LoadingIndicator />
        </Flex>
      )}
    </div>
  )
}

export default observer(IncomeStatementPage)
