import {
  AuthorisedAccount,
  ErrorCode,
  Finance,
  LiveAccountingPermissionStatus,
} from '@nextbusiness/infinity-finance-api'
import { Flex, useNotificationCenter } from '@nextbusiness/infinity-ui'
import * as Sentry from '@sentry/react'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { useAccountPageContext } from 'ledger/accounts/AccountPageContext'
import { isDefinedAccount } from 'ledger/accounts/data/AccountRules'
import useAPIQuery from 'libs/networking/APIQuery'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import DateUtilities from 'utility/DateUtilites'
import BankAccountAbortConnectionFlow from './BankAccountAbortConnectionFlow'
import BankAccountAssociateAccountStep from './BankAccountAssociateAccountStep'
import BankAccountInitialSyncStep from './BankAccountInitialSyncStep'
import './BankAccountMethodSetup.scss'

enum ConnectionStep {
  SelectAccount = 'select-account',
  InitialSync = 'initial-sync',
}

const BankAccountSelectConnectionSetup = () => {
  const { accountStore } = useRootStore()
  const { currentAccount } = useAccountPageContext()

  if (!isDefinedAccount(currentAccount))
    throw new Error(
      'Bank account selection can not be used for special accounts.'
    )
  const newestAccount = accountStore.newestAccountWithNumber(
    currentAccount.accountNumber
  )

  const [step, setStep] = useState<ConnectionStep>(ConnectionStep.SelectAccount)
  const [connection, isLoading, connectionLoadingError] = useAPIQuery(() =>
    Finance.Ledger.liveAccountingConnectionOfAccount(currentAccount.id)
  )
  const [selectedAccount, setSelectedAccount] = useState<AuthorisedAccount>()

  const [syncFrom, setSyncFrom] = useState<Date | undefined>(
    DateUtilities.startOfDay(new Date())
  )
  const [syncTo, setSyncTo] = useState<Date | undefined>(
    DateUtilities.startOfDay(new Date())
  )

  const [isSaving, setIsSaving] = useState<boolean>(false)
  const notificationCenter = useNotificationCenter()

  const connectAndSync = async () => {
    if (!selectedAccount || !newestAccount || !syncFrom || !syncTo) return
    setIsSaving(true)
    try {
      await Finance.Ledger.completeLiveAccountingConnection(
        newestAccount.id,
        undefined,
        selectedAccount.id
      )
      void accountStore.syncAccount(newestAccount, syncFrom, syncTo)
      await accountStore.loadAccounts()

      const daysToSync = DateUtilities.daysBetween(syncFrom, syncTo) + 1
      MixpanelAnalytics.event('Live accounting connection completed', {
        syncRange: daysToSync,
      })
    } catch (error) {
      notificationCenter.addNotification({
        variant: 'error',
        title: 'Fehler beim Einrichten',
        children:
          'Das Konto konnte nicht eingerichtet werden. Bitte versuche es erneut.',
      })
      Sentry.captureException(error)
    } finally {
      setIsSaving(false)
    }
  }

  const hasInvalidConnection =
    connection && connection.status !== LiveAccountingPermissionStatus.Valid
  const hasAbortedConnectionAttempt =
    connectionLoadingError?.code === ErrorCode.LiveAccountingRequired ||
    connectionLoadingError?.code === ErrorCode.LiveAccountingSetupFailed ||
    connectionLoadingError?.code === ErrorCode.LiveAccountingSetupAborted

  if (isLoading)
    return <LoadingScreen loadingText='Live Accounting wird vorbereitet.' />
  if (newestAccount && (hasInvalidConnection || hasAbortedConnectionAttempt)) {
    return <BankAccountAbortConnectionFlow account={newestAccount} />
  }

  return (
    <MagicSheetPage
      header={<MagicSheetTitleBar title={newestAccount?.name} />}
      noTrailingNegativeSpace
    >
      <Flex
        verticalAlignment='center'
        horizontalAlignment='center'
        fillContainer
      >
        {step === ConnectionStep.SelectAccount && (
          <BankAccountAssociateAccountStep
            connection={connection}
            connectionLoadingError={connectionLoadingError}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            onContinue={() => setStep(ConnectionStep.InitialSync)}
          />
        )}
        {step === ConnectionStep.InitialSync && (
          <BankAccountInitialSyncStep
            isSaving={isSaving}
            onComplete={connectAndSync}
            syncFrom={syncFrom}
            setSyncFrom={setSyncFrom}
            syncTo={syncTo}
            setSyncTo={setSyncTo}
          />
        )}
      </Flex>
    </MagicSheetPage>
  )
}

export default observer(BankAccountSelectConnectionSetup)
