import { VATCalculationMode } from '@nextbusiness/infinity-finance-api'
import { InputField, RadioGroup } from '@nextbusiness/infinity-ui'
import { useInitialSetupContext } from './InitialSetupContext'
import OnboardingOption from './OnboardingOption'
import OnboardingOptionProperty from './OnboardingOptionProperty'

const VATOnboardingOption = () => {
  const {
    isVATEnabled,
    setIsVATEnabled,
    vatRegistrationNumber,
    setVatRegistrationNumber,
    vatCalculationMode,
    setVatCalculationMode,
    shouldValidate,
  } = useInitialSetupContext()

  return (
    <OnboardingOption
      icon='tax'
      title='Mehrwertsteuer'
      description={
        isVATEnabled ? vatRegistrationNumber : 'Nicht mehrwertsteuerpflichtig'
      }
    >
      <RadioGroup
        value={isVATEnabled}
        onChange={setIsVATEnabled}
        options={[
          {
            value: false,
            label: 'Ich bin noch nicht mehrwertsteuerpflichtig',
          },
          {
            value: true,
            label: 'Ich bin bei der MWST angemeldet',
          },
        ]}
      />
      {isVATEnabled && (
        <>
          <OnboardingOptionProperty title='MWST-Nummer'>
            <InputField
              placeholder='Mehrwertsteuernummer'
              value={vatRegistrationNumber}
              onChange={setVatRegistrationNumber}
              helperText='Im Format CHE-123.456.789 MWST'
              fullWidth
              hasError={shouldValidate && !vatRegistrationNumber}
            />
          </OnboardingOptionProperty>
          <OnboardingOptionProperty title='Abrechnungsmethode'>
            <RadioGroup
              onChange={setVatCalculationMode}
              value={vatCalculationMode}
              options={[
                {
                  value: VATCalculationMode.Effective,
                  label: 'Effektive Abrechnung (Standard)',
                },
                {
                  value: VATCalculationMode.Simplified,
                  label: 'Saldosteuersatz',
                },
              ]}
            />
          </OnboardingOptionProperty>
        </>
      )}
    </OnboardingOption>
  )
}

export default VATOnboardingOption
