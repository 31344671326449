import { Finance } from '@nextbusiness/infinity-finance-api'
import { INotificationCenter } from '@nextbusiness/infinity-ui'
import { inject, observer } from 'mobx-react'
import { Component } from 'react'
import AuthenticationStore from 'stores/AuthenticationStore'
import DocumentStore from 'stores/DocumentStore'
import DocumentUploadArea from './DocumentUploadArea'

export interface Upload {
  file: File
  title?: string
  contactId?: string
  note?: string
}

interface DocumentUploadState {
  isUploading?: boolean
  uploads: Upload[]
}

interface DocumentUploadProps {
  authenticationStore?: AuthenticationStore
  documentStore?: DocumentStore
  notificationCenter: INotificationCenter
  onDismiss?: () => void
}

@inject('authenticationStore', 'documentStore')
@observer
class DocumentUpload extends Component<
  DocumentUploadProps,
  DocumentUploadState
> {
  state: DocumentUploadState = { uploads: [] }

  addFiles = (uploads: Upload[]) => {
    this.setState({ uploads: [...this.state.uploads, ...uploads] })
  }

  removeFile = (fileToDelete: File) => {
    const filesAfterDeletion = this.state.uploads.filter(
      (upload) => upload.file !== fileToDelete
    )
    this.setState({ uploads: filesAfterDeletion })
  }

  editUpload = (index: number, changes: Partial<Upload>) => {
    const uploads = [...this.state.uploads]
    uploads[index] = { ...uploads[index], ...changes }
    this.setState({ uploads: uploads })
  }

  uploadFile = async (uploads: Upload[]) => {
    this.setState({ isUploading: true })
    try {
      const documentsToUpload = uploads.map((upload) =>
        Finance.Documents.uploadDocument(upload.file, true, {
          title: upload.title,
          contactId: upload.contactId,
          note: upload.note,
        })
      )
      await Promise.all(documentsToUpload)
      this.props.documentStore!.loadAllDocuments()
      this.props.notificationCenter.addNotification({
        children: 'Belege wurden erfolgreich hochgeladen',
        variant: 'success',
      })
      this.props.onDismiss?.()
      this.setState({
        uploads: [],
      })
    } catch (error) {
      this.props.notificationCenter.addNotification({
        children: 'Belege konnten nicht hochgeladen werden',
        variant: 'error',
      })
    } finally {
      this.setState({ isUploading: false })
    }
  }

  render() {
    return (
      <div>
        <DocumentUploadArea
          uploadedFiles={this.state.uploads}
          onUploadSelected={(uploads: Upload[]) => this.addFiles(uploads)}
          isUploading={this.state.isUploading}
          editUpload={this.editUpload}
          removeFile={this.removeFile}
          onDismiss={this.props.onDismiss}
          saveDocuments={this.uploadFile}
        />
      </div>
    )
  }
}

export default DocumentUpload
