import { AccountType } from 'model/Account'

interface AccountGroup {
  title: string
  subgroups: AccountSubgroup[]
}

interface AccountSubgroup {
  icon?: string
  title: string
  description?: string
  isCommon?: boolean
  mapsToChartOfAccounts: string[]
}

export const AssetsAccountGroups: AccountGroup[] = [
  {
    title: 'Flüssige Mittel und Wertschriften',
    subgroups: [
      {
        title: 'Kasse',
        mapsToChartOfAccounts: [
          'Aktiven',
          'Umlaufvermögen',
          'Flüssige Mittel',
          'Kasse',
        ],
      },
      {
        title: 'Bankkonten',
        mapsToChartOfAccounts: [
          'Aktiven',
          'Umlaufvermögen',
          'Flüssige Mittel',
          'Bankguthaben',
        ],
      },
      {
        title: 'Kurzfristige Wertschriften',
        mapsToChartOfAccounts: [
          'Aktiven',
          'Umlaufvermögen',
          'Kurzfristig gehaltene Aktiven mit Börsenkurs',
          'Wertschriften',
        ],
      },
    ],
  },
  {
    title: 'Vorräte',
    subgroups: [
      {
        title: 'Handelswaren',
        mapsToChartOfAccounts: [
          'Aktiven',
          'Umlaufvermögen',
          'Vorräte und nicht fakturierte Dienstleistungen',
          'Handelswaren',
        ],
      },
      {
        title: 'Rohstoffe',
        mapsToChartOfAccounts: [
          'Aktiven',
          'Umlaufvermögen',
          'Vorräte und nicht fakturierte Dienstleistungen',
          'Rohstoffe',
        ],
      },
      {
        title: 'Werkstoffe',
        mapsToChartOfAccounts: [
          'Aktiven',
          'Umlaufvermögen',
          'Vorräte und nicht fakturierte Dienstleistungen',
          'Werkstoffe',
        ],
      },
      {
        title: 'Hilfs- und Verbrauchsmaterial',
        mapsToChartOfAccounts: [
          'Aktiven',
          'Umlaufvermögen',
          'Vorräte und nicht fakturierte Dienstleistungen',
          'Hilfs- und Verbrauchsmaterial',
        ],
      },
      {
        title: 'Handelswaren in Konsignation',
        mapsToChartOfAccounts: [
          'Aktiven',
          'Umlaufvermögen',
          'Vorräte und nicht fakturierte Dienstleistungen',
          'Handelswaren in Konsignation',
        ],
      },
      {
        title: 'Fertige Erzeugnisse',
        mapsToChartOfAccounts: [
          'Aktiven',
          'Umlaufvermögen',
          'Vorräte und nicht fakturierte Dienstleistungen',
          'Fertige Erzeugnisse',
        ],
      },
      {
        title: 'Unfertige Erzeugnisse',
        mapsToChartOfAccounts: [
          'Aktiven',
          'Umlaufvermögen',
          'Vorräte und nicht fakturierte Dienstleistungen',
          'Unfertige Erzeugnisse',
        ],
      },
    ],
  },
  {
    title: 'Übrige kurzfristige Forderungen',
    subgroups: [
      {
        title: 'Vorschüsse und Darlehen an andere',
        mapsToChartOfAccounts: [
          'Aktiven',
          'Umlaufvermögen',
          'Übrige kurzfristige Forderungen',
          'Vorschüsse und Darlehen',
        ],
      },
      {
        title:
          'Forderungen gegenüber Sozialversicherungen und Vorsorgeeinrichtungen',
        mapsToChartOfAccounts: [
          'Aktiven',
          'Umlaufvermögen',
          'Übrige kurzfristige Forderungen',
          'Forderungen gegenüber Sozialversicherungen und Vorsorgeeinrichtungen',
        ],
      },
      {
        title: 'Guthaben Verrechnungssteuer',
        mapsToChartOfAccounts: [
          'Aktiven',
          'Umlaufvermögen',
          'Übrige kurzfristige Forderungen',
          'Verrechnungssteuer',
        ],
      },
      {
        title: 'Sonstige kurzfristige Forderungen',
        mapsToChartOfAccounts: [
          'Aktiven',
          'Umlaufvermögen',
          'Übrige kurzfristige Forderungen',
          'Sonstige kurzfristige Forderungen',
        ],
      },
    ],
  },
  {
    title: 'Beteiligungen',
    subgroups: [
      {
        title: 'Beteiligungen',
        mapsToChartOfAccounts: [
          'Aktiven',
          'Anlagevermögen',
          'Beteiligungen',
          'Beteiligungen',
        ],
      },
    ],
  },
  {
    title: 'Bewegliche Sachanlagen',
    subgroups: [
      {
        title: 'Maschinen und Apparate',
        mapsToChartOfAccounts: [
          'Aktiven',
          'Anlagevermögen',
          'Mobile Sachanlagen',
          'Maschinen und Apparate',
        ],
      },
      {
        title: 'Büromaschinen und Informatik',
        mapsToChartOfAccounts: [
          'Aktiven',
          'Anlagevermögen',
          'Mobile Sachanlagen',
          'Büromaschinen, Informatik, Kommunikationstechnologie',
        ],
      },
      {
        title: 'Werkzeuge und Geräte',
        mapsToChartOfAccounts: [
          'Aktiven',
          'Anlagevermögen',
          'Mobile Sachanlagen',
          'Werkzeuge und Geräte',
        ],
      },
      {
        title: 'Mobiliar und Einrichtungen',
        mapsToChartOfAccounts: [
          'Aktiven',
          'Anlagevermögen',
          'Mobile Sachanlagen',
          'Mobiliar und Einrichtungen',
        ],
      },
      {
        title: 'Fahrzeuge',
        mapsToChartOfAccounts: [
          'Aktiven',
          'Anlagevermögen',
          'Mobile Sachanlagen',
          'Fahrzeuge',
        ],
      },
    ],
  },
  {
    title: 'Unbewegliche Sachanlegen',
    subgroups: [
      {
        title: 'Geschäftsliegenschaft',
        mapsToChartOfAccounts: [
          'Aktiven',
          'Anlagevermögen',
          'Immobile Sachanlagen',
          'Geschäftsliegenschaften',
        ],
      },
    ],
  },
  {
    title: 'Finanzanlagen',
    subgroups: [
      {
        title: 'Wertschriften',
        mapsToChartOfAccounts: [
          'Aktiven',
          'Anlagevermögen',
          'Finanzanlagen',
          'Wertschriften',
        ],
      },
      {
        title: 'Darlehen an andere',
        mapsToChartOfAccounts: ['Anlagevermögen', 'Finanzanlagen', 'Darlehen'],
      },
      {
        title: 'Hypotheken an andere',
        mapsToChartOfAccounts: [
          'Aktiven',
          'Anlagevermögen',
          'Finanzanlagen',
          'Hypotheken',
        ],
      },
    ],
  },
  {
    title: 'Immaterielle Werte',
    subgroups: [
      {
        title: 'Patente, Know-How, Lizenzen, Rechte, Entwicklungen',
        mapsToChartOfAccounts: [
          'Aktiven',
          'Anlagevermögen',
          'Immaterielle Werte',
          'Patente, Know-how, Lizenzen, Rechte, Entwicklungen',
        ],
      },
    ],
  },
]

export const LiabilityAccountGroups: AccountGroup[] = [
  {
    title: 'Kurzfristige verzinsliche Verbindlichkeiten',
    subgroups: [
      {
        title: 'Kurzfristige Bankverbindlichkeiten',
        mapsToChartOfAccounts: [
          'Passiven',
          'Kurzfristiges Fremdkapital',
          'Kurzfristige verzinsliche Verbindlichkeiten',
          'Bankverbindlichkeiten',
        ],
      },
      {
        title: 'Verbindlichkeit aus Finanzierungsleasing',
        mapsToChartOfAccounts: [
          'Passiven',
          'Kurzfristiges Fremdkapital',
          'Kurzfristige verzinsliche Verbindlichkeiten',
          'Verbindlichkeiten aus Finanzierungsleasing',
        ],
      },
      {
        title: 'Übrige verzinsliche Verbindlichkeiten',
        mapsToChartOfAccounts: [
          'Passiven',
          'Kurzfristiges Fremdkapital',
          'Kurzfristige verzinsliche Verbindlichkeiten',
          'Übrige verzinsliche Verbindlichkeiten',
        ],
      },
    ],
  },
  {
    title: 'Kurzfristige Rückstellungen',
    subgroups: [
      {
        title: 'Kurzfristige Rückstellungen',
        mapsToChartOfAccounts: [
          'Passiven',
          'Kurzfristiges Fremdkapital',
          'Passive Rechnungsabgrenzungen und kurzfristige Rückstellungen',
          'Kurzfristige Rückstellungen',
        ],
      },
    ],
  },
  {
    title: 'Übrige kurzfristige Verbindlichkeiten',
    subgroups: [
      {
        title: 'Verrechnungssteuer',
        mapsToChartOfAccounts: [
          'Passiven',
          'Kurzfristiges Fremdkapital',
          'Übrige kurzfristige Verbindlichkeiten',
          'Verrechnungssteuer',
        ],
      },
      {
        title: 'Quellensteuer',
        mapsToChartOfAccounts: [
          'Passiven',
          'Kurzfristiges Fremdkapital',
          'Übrige kurzfristige Verbindlichkeiten',
          'Quellensteuer',
        ],
      },
      {
        title: 'Direkte Steuer',
        mapsToChartOfAccounts: [
          'Passiven',
          'Kurzfristiges Fremdkapital',
          'Übrige kurzfristige Verbindlichkeiten',
          'Direkte Steuer',
        ],
      },
      {
        title: 'Sonstige kurzfristige Verbindlichkeiten',
        mapsToChartOfAccounts: [
          'Passiven',
          'Kurzfristiges Fremdkapital',
          'Übrige kurzfristige Verbindlichkeiten',
          'Sonstige kurzfristige Verbindlichkeiten',
        ],
      },
      {
        title: 'Beschlossene Ausschüttungen',
        mapsToChartOfAccounts: [
          'Passiven',
          'Kurzfristiges Fremdkapital',
          'Übrige kurzfristige Verbindlichkeiten',
          'Beschlossene Ausschüttungen',
        ],
      },
      {
        title: 'Sozialversicherungen und Vorsorgeeinrichtungen',
        mapsToChartOfAccounts: [
          'Passiven',
          'Kurzfristiges Fremdkapital',
          'Übrige kurzfristige Verbindlichkeiten',
          'Sozialversicherungen und Vorsorgeeinrichtungen',
        ],
      },
    ],
  },
  {
    title: 'Langfristige verzinsliche Verbindlichkeiten',
    subgroups: [
      {
        title: 'Hypotheken',
        mapsToChartOfAccounts: [
          'Passiven',
          'Langfristiges Fremdkapital',
          'Langfristige verzinsliche Verbindlichkeiten',
          'Hypotheken',
        ],
      },
      {
        title: 'Darlehen',
        mapsToChartOfAccounts: [
          'Passiven',
          'Langfristiges Fremdkapital',
          'Langfristige verzinsliche Verbindlichkeiten',
          'Darlehen',
        ],
      },
      {
        title: 'Obligationenanleihen',
        mapsToChartOfAccounts: [
          'Passiven',
          'Langfristiges Fremdkapital',
          'Langfristige verzinsliche Verbindlichkeiten',
          'Obligationenanleihen',
        ],
      },
      {
        title: 'Verbindlichkeiten aus Finanzierungsleasing',
        mapsToChartOfAccounts: [
          'Passiven',
          'Langfristiges Fremdkapital',
          'Langfristige verzinsliche Verbindlichkeiten',
          'Verbindlichkeiten aus Finanzierungsleasing',
        ],
      },
      {
        title: 'Langfristige Bankverbindlichkeiten',
        mapsToChartOfAccounts: [
          'Passiven',
          'Langfristiges Fremdkapital',
          'Langfristige verzinsliche Verbindlichkeiten',
          'Bankverbindlichkeiten',
        ],
      },
    ],
  },
  {
    title: 'Übrige langfristige Verbindlichkeiten und Rückstellungen',
    subgroups: [
      {
        title: 'Übrige langfristige Verbindlichkeiten (unverzinslich)',
        mapsToChartOfAccounts: [
          'Passiven',
          'Langfristiges Fremdkapital',
          'Übrige langfristige Verbindlichkeiten',
          'Übrige langfristige Verbindlichkeiten (unverzinslich)',
        ],
      },
      {
        title: 'Rückstellungen',
        mapsToChartOfAccounts: [
          'Passiven',
          'Langfristiges Fremdkapital',
          'Rückstellungen sowie vom Gesetz vorgesehene ähnliche Positionen',
          'Rückstellungen',
        ],
      },
    ],
  },
  {
    title: 'Grund-, Gesellschafter- oder Stiftungskapital',
    subgroups: [
      {
        title: 'Aktien-, Stamm-, Anteilschein- oder Stiftungskapital',
        mapsToChartOfAccounts: [
          'Passiven',
          'Eigenkapital (juristische Personen)',
          'Grund-, Gesellschafter- oder Stiftungskapital',
          'Aktien-, Stamm-, Anteilschein- oder Stiftungskapital',
        ],
      },
      {
        title: 'Eigene Aktien; Stammanteile oder Anteilscheine (Minusposten)',
        mapsToChartOfAccounts: [
          'Passiven',
          'Eigenkapital (juristische Personen)',
          'Reserven und Jahresgewinn oder Jahresverlust',
          'Eigene Aktien, Stammanteile oder Anteilscheine (Minusposten)',
        ],
      },
    ],
  },
  {
    title: 'Reserven und Jahresgewinn oder Jahresverlust',
    subgroups: [
      {
        title: 'Gesetzliche Kapitalreserve',
        mapsToChartOfAccounts: [
          'Passiven',
          'Eigenkapital (juristische Personen)',
          'Reserven und Jahresgewinn oder Jahresverlust',
          'Gesetzliche Kapitalreserve',
        ],
      },
      {
        title: 'Gewinnvortrag oder Verlustvortrag',
        mapsToChartOfAccounts: [
          'Passiven',
          'Eigenkapital (juristische Personen)',
          'Reserven und Jahresgewinn oder Jahresverlust',
          'Gewinnvortrag oder Verlustvortrag',
        ],
      },
      {
        title: 'Jahresgewinn oder Jahresverlust',
        mapsToChartOfAccounts: [
          'Passiven',
          'Eigenkapital (juristische Personen)',
          'Reserven und Jahresgewinn oder Jahresverlust',
          'Jahresgewinn oder Jahresverlust',
        ],
      },
      {
        title: 'Aufwertungsreserve',
        mapsToChartOfAccounts: [
          'Passiven',
          'Eigenkapital (juristische Personen)',
          'Reserven und Jahresgewinn oder Jahresverlust',
          'Aufwertungsreserve',
        ],
      },
      {
        title: 'Gesetzliche Gewinnreserve',
        mapsToChartOfAccounts: [
          'Passiven',
          'Eigenkapital (juristische Personen)',
          'Reserven und Jahresgewinn oder Jahresverlust',
          'Gesetzliche Gewinnreserve',
        ],
      },
      {
        title: 'Freiwillige Gewinnreserve',
        mapsToChartOfAccounts: [
          'Passiven',
          'Eigenkapital (juristische Personen)',
          'Reserven und Jahresgewinn oder Jahresverlust',
          'Freiwillige Gewinnreserven',
        ],
      },
    ],
  },
]

export const IncomeAccountGroups: AccountGroup[] = [
  {
    title: 'Umsatz',
    subgroups: [
      {
        title: 'Produktionserlöse',
        mapsToChartOfAccounts: [
          'Betrieblicher Ertrag aus Lieferungen und Leistungen',
          'Produktionserlöse',
        ],
      },
      {
        title: 'Handelserlöse',
        mapsToChartOfAccounts: [
          'Betrieblicher Ertrag aus Lieferungen und Leistungen',
          'Handelserlöse',
        ],
      },
      {
        title: 'Dienstleistungserlöse',
        mapsToChartOfAccounts: [
          'Betrieblicher Ertrag aus Lieferungen und Leistungen',
          'Dienstleistungserlöse',
        ],
      },
      {
        title: 'Übrige Erlöse',
        mapsToChartOfAccounts: [
          'Betrieblicher Ertrag aus Lieferungen und Leistungen',
          'Übrige Erlöse aus Lieferungen und Leistungen',
        ],
      },
      {
        title: 'Eigenleistungen',
        mapsToChartOfAccounts: [
          'Betrieblicher Ertrag aus Lieferungen und Leistungen',
          'Eigenleistungen',
        ],
      },
      {
        title: 'Eigenverbrauch',
        mapsToChartOfAccounts: [
          'Betrieblicher Ertrag aus Lieferungen und Leistungen',
          'Eigenverbrauch',
        ],
      },
      {
        title: 'Erlösminderungen',
        mapsToChartOfAccounts: [
          'Betrieblicher Ertrag aus Lieferungen und Leistungen',
          'Erlösminderungen',
        ],
      },
      {
        title: 'Verluste aus Forderungen',
        mapsToChartOfAccounts: [
          'Betrieblicher Ertrag aus Lieferungen und Leistungen',
          'Verluste Forderungen (Debitoren), Veränderungen Delkredere',
        ],
      },
      {
        title: 'Bestandesänderungen unfertige Erzeugnisse',
        mapsToChartOfAccounts: [
          'Betrieblicher Ertrag aus Lieferungen und Leistungen',
          'Bestandesänderungen unfertige Erzeugnisse',
        ],
      },
      {
        title: 'Bestandesänderungen fertige Erzeugnisse',
        mapsToChartOfAccounts: [
          'Betrieblicher Ertrag aus Lieferungen und Leistungen',
          'Bestandesänderungen fertige Erzeugnisse',
        ],
      },
      {
        title: 'Bestandesänderungen nicht fakturierte Dienstleistungen',
        mapsToChartOfAccounts: [
          'Betrieblicher Ertrag aus Lieferungen und Leistungen',
          'Bestandesänderungen nicht fakturierte Dienstleistungen',
        ],
      },
    ],
  },
  {
    title: 'Finanzergebnis',
    subgroups: [
      {
        title: 'Finanzertrag',
        mapsToChartOfAccounts: [
          'Übriger betrieblicher Aufwand, Abschreibungen und Wertberichtigungen sowie Finanzergebnis',
          'Finanzertrag',
        ],
      },
    ],
  },
  {
    title: 'Andere',
    subgroups: [
      {
        title: 'Ertrag Nebenbetrieb',
        mapsToChartOfAccounts: [
          'Betrieblicher Nebenerfolg',
          'Ertrag Nebenbetrieb',
        ],
      },
      {
        title: 'Ertrag betriebliche Liegenschaft',
        mapsToChartOfAccounts: [
          'Betrieblicher Nebenerfolg',
          'Ertrag betriebliche Liegenschaft',
        ],
      },
      {
        title: 'Betriebsfremder Ertrag',
        mapsToChartOfAccounts: [
          'Betriebsfremder, ausserordentlicher, einmaliger oder periodenfremder Aufwand und Ertrag',
          'Betriebsfremder Ertrag',
        ],
      },
      {
        title: 'Ausserordentlicher, einmaliger oder periodenfremder Ertrag',
        mapsToChartOfAccounts: [
          'Betriebsfremder, ausserordentlicher, einmaliger oder periodenfremder Aufwand und Ertrag',
          'Ausserordentlicher, einmaliger oder periodenfremder Ertrag',
        ],
      },
    ],
  },
]

export const ExpenseAccountGroups: AccountGroup[] = [
  {
    title: 'Produktion und Dienstleistungen',
    subgroups: [
      {
        title: 'Material für Produktion',
        mapsToChartOfAccounts: [
          'Aufwand für Material, Handelswaren, Dienstleistungen und Energie',
          'Materialaufwand Produktion',
        ],
      },
      {
        title: 'Handelswaren',
        mapsToChartOfAccounts: [
          'Aufwand für Material, Handelswaren, Dienstleistungen und Energie',
          'Handelswarenaufwand',
        ],
      },
      {
        title: 'Bezogene Dienstleistungen',
        mapsToChartOfAccounts: [
          'Aufwand für Material, Handelswaren, Dienstleistungen und Energie',
          'Aufwand für bezogene Dienstleistungen',
        ],
      },
      {
        title: 'Energie für Produktion',
        mapsToChartOfAccounts: [
          'Aufwand für Material, Handelswaren, Dienstleistungen und Energie',
          'Energieaufwand zur Leistungserstellung',
        ],
      },
      {
        title: 'Aufwandminderungen',
        mapsToChartOfAccounts: [
          'Aufwand für Material, Handelswaren, Dienstleistungen und Energie',
          'Aufwandminderungen',
        ],
      },
    ],
  },
  {
    title: 'Personal',
    subgroups: [
      {
        title: 'Löhne',
        mapsToChartOfAccounts: ['Personalaufwand', 'Lohnaufwand'],
      },
      {
        title: 'Sozialversicherungen',
        mapsToChartOfAccounts: [
          'Personalaufwand',
          'Sozialversicherungsaufwand',
        ],
      },
      {
        title: 'Übriger Personalaufwand',
        mapsToChartOfAccounts: ['Personalaufwand', 'Übriger Personalaufwand'],
      },
      {
        title: 'Leistungen Dritter',
        mapsToChartOfAccounts: ['Personalaufwand', 'Leistungen Dritter'],
      },
    ],
  },
  {
    title: 'Transport, Leasing und Unterhalt',
    subgroups: [
      {
        title: 'Unterhalt, Reparaturen, Ersatz mobiler Sachanlagen',
        mapsToChartOfAccounts: [
          'Übriger betrieblicher Aufwand, Abschreibungen und Wertberichtigungen sowie Finanzergebnis',
          'Unterhalt, Reparaturen, Ersatz mobile Sachanlagen',
        ],
      },
      {
        title: 'Leasing von mobilen Sachanlagen',
        mapsToChartOfAccounts: [
          'Übriger betrieblicher Aufwand, Abschreibungen und Wertberichtigungen sowie Finanzergebnis',
          'Leasingaufwand mobile Sachanlagen',
        ],
      },
      {
        title: 'Fahrzeugleasing und -mieten',
        mapsToChartOfAccounts: [
          'Übriger betrieblicher Aufwand, Abschreibungen und Wertberichtigungen sowie Finanzergebnis',
          'Fahrzeugleasing und -mieten',
        ],
      },
      {
        title: 'Fahrzeug und Transportaufwand',
        mapsToChartOfAccounts: [
          'Übriger betrieblicher Aufwand, Abschreibungen und Wertberichtigungen sowie Finanzergebnis',
          'Fahrzeug- und Transportaufwand',
        ],
      },
    ],
  },
  {
    title: 'Betriebskosten',
    subgroups: [
      {
        title: 'Räumlichkeiten',
        mapsToChartOfAccounts: [
          'Übriger betrieblicher Aufwand, Abschreibungen und Wertberichtigungen sowie Finanzergebnis',
          'Raumaufwand',
        ],
      },
      {
        title: 'IT-Aufwand',
        mapsToChartOfAccounts: [
          'Übriger betrieblicher Aufwand, Abschreibungen und Wertberichtigungen sowie Finanzergebnis',
          'Informatikaufwand inkl. Leasing',
        ],
      },
      {
        title: 'Energie und Entsorgung',
        mapsToChartOfAccounts: [
          'Übriger betrieblicher Aufwand, Abschreibungen und Wertberichtigungen sowie Finanzergebnis',
          'Energie- und Entsorgungsaufwand',
        ],
      },
      {
        title: 'Werbung',
        mapsToChartOfAccounts: [
          'Übriger betrieblicher Aufwand, Abschreibungen und Wertberichtigungen sowie Finanzergebnis',
          'Werbeaufwand',
        ],
      },
      {
        title: 'Verwaltung',
        mapsToChartOfAccounts: [
          'Übriger betrieblicher Aufwand, Abschreibungen und Wertberichtigungen sowie Finanzergebnis',
          'Verwaltungsaufwand',
        ],
      },
      {
        title: 'Sachversicherungen, Abgaben und Bewilligungen',
        mapsToChartOfAccounts: [
          'Übriger betrieblicher Aufwand, Abschreibungen und Wertberichtigungen sowie Finanzergebnis',
          'Sachversicherungen, Abgaben, Gebühren, Bewilligungen',
        ],
      },
      {
        title: 'Abschreibungen',
        mapsToChartOfAccounts: [
          'Übriger betrieblicher Aufwand, Abschreibungen und Wertberichtigungen sowie Finanzergebnis',
          'Abschreibungen und Wertberichtigungen auf Positionen des Anlagevermögens',
        ],
      },
      {
        title: 'Sonstiger betrieblicher Aufwand',
        mapsToChartOfAccounts: [
          'Übriger betrieblicher Aufwand, Abschreibungen und Wertberichtigungen sowie Finanzergebnis',
          'Sonstiger betrieblicher Aufwand',
        ],
      },
    ],
  },
  {
    title: 'Finanzergebnis',
    subgroups: [
      {
        title: 'Finanzaufwand',
        mapsToChartOfAccounts: [
          'Übriger betrieblicher Aufwand, Abschreibungen und Wertberichtigungen sowie Finanzergebnis',
          'Finanzaufwand',
        ],
      },
    ],
  },
  {
    title: 'Andere',
    subgroups: [
      {
        title: 'Aufwand Nebenbetrieb',
        mapsToChartOfAccounts: [
          'Betrieblicher Nebenerfolg',
          'Aufwand Nebenbetrieb',
        ],
      },
      {
        title: 'Aufwand betriebliche Liegenschaft',
        mapsToChartOfAccounts: [
          'Betrieblicher Nebenerfolg',
          'Aufwand betriebliche Liegenschaft',
        ],
      },
      {
        title: 'Betriebsfremder Aufwand',
        mapsToChartOfAccounts: [
          'Betriebsfremder, ausserordentlicher, einmaliger oder periodenfremder Aufwand und Ertrag',
          'Betriebsfremder Aufwand',
        ],
      },
      {
        title: 'Ausserordentlicher, einmaliger oder periodenfremder Aufwand',
        mapsToChartOfAccounts: [
          'Betriebsfremder, ausserordentlicher, einmaliger oder periodenfremder Aufwand und Ertrag',
          'Ausserordentlicher, einmaliger oder periodenfremder Aufwand',
        ],
      },
      {
        title: 'Direkte Steuern',
        mapsToChartOfAccounts: [
          'Betriebsfremder, ausserordentlicher, einmaliger oder periodenfremder Aufwand und Ertrag',
          'Direkte Steuern',
        ],
      },
    ],
  },
]

export const accountGroupForType = (accountType: AccountType | undefined) => {
  switch (accountType) {
    case 'assets':
      return AssetsAccountGroups
    case 'liabilities':
      return LiabilityAccountGroups
    case 'income':
      return IncomeAccountGroups
    case 'expense':
      return ExpenseAccountGroups
    default:
      return []
  }
}

export const subgroupForChartOfAccountsMapping = (
  accountType: AccountType | undefined,
  chartOfAccountGroups: string[]
): AccountSubgroup | null => {
  const accountGroups = accountGroupForType(accountType)
  for (const accountGroup of accountGroups) {
    const matchingSubGroup = accountGroup.subgroups.find(
      (subGroup) => chartOfAccountGroups === subGroup.mapsToChartOfAccounts
    )
    if (matchingSubGroup) {
      return matchingSubGroup
    }
  }
  return null
}

export default AccountGroup
