import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import DocumentElementProps from './DocumentElementProps'

type InvoiceNumberPrefix = 'RE-' | 'OF-'

export const formatInvoiceNumber = (
  prefix: InvoiceNumberPrefix,
  invoiceNumber: number,
  numberingStart = 1
) => {
  const displayNumber = invoiceNumber + numberingStart
  const formattedDisplayNumber = displayNumber.toString().padStart(5, '0')

  return `${prefix}${formattedDisplayNumber}`
}

const DocumentElementInvoiceNumber = (props: DocumentElementProps) => {
  const { preferencesStore } = useRootStore()
  const preferences = preferencesStore.organisationPreferences

  const hasInvoiceNumber = props.document.invoiceNumber !== undefined

  const showInvoiceNumber =
    props.template.showCustomerInvoiceNumber && !props.document.isQuote
  const showQuoteNumber =
    props.template.showQuoteNumber && props.document.isQuote

  const invoiceNumberingStart = preferences?.customerInvoiceNumberingStart
  const quoteNumberingStart = preferences?.quoteNumberingStart

  if (!showInvoiceNumber && !showQuoteNumber) return null

  return (
    <div className='document-invoice-number'>
      {!hasInvoiceNumber ? (
        <span className='unsaved'>Noch ungespeichert</span>
      ) : showInvoiceNumber ? (
        <span>
          {formatInvoiceNumber(
            'RE-',
            props.document.invoiceNumber!,
            invoiceNumberingStart
          )}
        </span>
      ) : (
        <span>
          {formatInvoiceNumber(
            'OF-',
            props.document.invoiceNumber!,
            quoteNumberingStart
          )}
        </span>
      )}
    </div>
  )
}

export default observer(DocumentElementInvoiceNumber)
