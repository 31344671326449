import {
  BillableWork,
  Finance,
  Work,
  WorkBillingMethod,
} from '@nextbusiness/infinity-finance-api'
import Assistant, { FullAssistantStep } from 'components/assistant/Assistant'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import WorkUtilities from '../WorkUtilities'
import './ProjectInvoicingAssistant.scss'
import TitleAndDateStep from './TitleAndDateStep'
import WorkBillingMethodStep from './WorkBillingMethodStep'
import WorkSelectionStep from './WorkSelectionStep'

interface ProjectInvoicingAssistantProps {
  projectId: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  unbilledWork: Work[]
}

const ProjectInvoicingAssistant = (props: ProjectInvoicingAssistantProps) => {
  const history = useHistory()
  const [title, setTitle] = useState<string>()
  const [date, setDate] = useState<number | undefined>(Date.now())
  const [payableInDays, setPayableInDays] = useState<number | undefined>(30)
  const billableWork = props.unbilledWork.filter(
    (workItem) => WorkUtilities.valueOf(workItem) !== undefined
  ) as BillableWork[]
  const [selectedWorkItems, setSelectedWorkItems] =
    useState<BillableWork[]>(billableWork)
  const [workBillingMethod, setWorkBillingMethod] =
    useState<WorkBillingMethod>()
  const [isInvoiceBeingCreated, setIsInvoiceBeingCreated] =
    useState<boolean>(false)

  const billWork = async () => {
    setIsInvoiceBeingCreated(true)
    const createdInvoice = (
      await Finance.Projects.billWork(
        selectedWorkItems.map((workItem) => workItem.id),
        props.projectId,
        workBillingMethod!,
        {
          title,
          openingDate: date!.valueOf(),
          payableInDays,
        } as {
          // TODO: Remove type casting once API types are fixed
          title?: string
          dueDate?: number
          payableInDays?: number
        }
      )
    ).invoice
    setIsInvoiceBeingCreated(false)
    history.push(`/forderungen/detail/${createdInvoice}`)
    MixpanelAnalytics.event('Project billed', {
      method: workBillingMethod,
    })
  }

  useEffect(() => {
    setSelectedWorkItems(billableWork)
  }, [props.isOpen])

  const steps: FullAssistantStep[] = [
    {
      id: 'selection',
      displayTitle: 'Auswahl',
      view: (
        <WorkSelectionStep
          unbilledWork={billableWork}
          selectedWorkItems={selectedWorkItems}
          setSelectedWorkItems={setSelectedWorkItems}
        />
      ),
      hasEnteredRequiredInformation: () => selectedWorkItems.length > 0,
    },
    {
      id: 'workBillingMethod',
      displayTitle: 'Art der Positionen',
      view: (
        <WorkBillingMethodStep
          workBillingMethod={workBillingMethod}
          setWorkBillingMethod={setWorkBillingMethod}
        />
      ),
      hasEnteredRequiredInformation: () => workBillingMethod !== undefined,
    },
    {
      id: 'titleAndDate',
      displayTitle: 'Titel und Datum',
      view: (
        <TitleAndDateStep
          title={title}
          setTitle={setTitle}
          date={date}
          setDate={setDate}
          payableInDays={payableInDays}
          setPayableInDays={setPayableInDays}
        />
      ),
      hasEnteredRequiredInformation: () => !!title?.trim(),
    },
  ]

  return (
    <Assistant
      className='project-invoicing-assistant'
      isOpen={props.isOpen}
      onDismiss={() => props.setIsOpen(false)}
      title='In Rechnung stellen'
      steps={steps}
      finalAction={{
        children: 'Forderung erstellen',
        variant: 'primary',
        onClick: async () => {
          await billWork()
          props.setIsOpen(false)
        },
        isLoading: isInvoiceBeingCreated,
        disabled: !steps.every((step) =>
          step.hasEnteredRequiredInformation?.()
        ),
      }}
    />
  )
}

export default observer(ProjectInvoicingAssistant)
