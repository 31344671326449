import { APIError, Finance, TaxCode } from '@nextbusiness/infinity-finance-api'
import {
  ButtonProps,
  Dialog,
  FeedbackBanner,
  Flex,
  InputField,
  Text,
} from '@nextbusiness/infinity-ui'
import ColourSelect from 'components/colour-select/ColourSelect'
import VATSelection from 'components/vat/vat-selection/VATSelection'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { CurrentLedgerNavigationStackRef } from 'ledger/LedgerNavigationStack'
import { observer } from 'mobx-react'
import { IAccount } from 'model/Account'
import { useEffect, useState } from 'react'
import LedgerStore from 'stores/LedgerStore'
import { useRootStore } from 'stores/RootStoreContext'
import TaxUtilities from 'utility/TaxUtilities'
import { accountColours } from '../account-creation/CreateAccountAssistant'
import DeleteAccountModal from './DeleteAccountModal'
import './EditAccountModal.scss'

interface EditAccountModalProps {
  account: IAccount
  isOpen: boolean
  closeModal: () => void
  hideDeleteAccountAction?: boolean
}

const canDeleteAccount = (account: IAccount): boolean =>
  !account.isSystemAccount

const EditAccountModal = (props: EditAccountModalProps) => {
  const { authenticationStore, accountStore, ledgerStore, preferencesStore } =
    useRootStore()

  const [errorMessage, setErrorMessage] = useState<string>()
  const [account, setAccount] = useState<Partial<IAccount>>({
    organisation: authenticationStore.organisationIdentifier,
  })
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] =
    useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [showVatPicker, setShowVatPicker] = useState<boolean>(false)

  const showDefaultVATSelect =
    preferencesStore.organisationPreferences?.VAT &&
    (account.accountType === 'income' || account.accountType === 'expense')
  const suggestedTaxCode = TaxUtilities.autoSuggestVAT(
    {
      number: account.accountNumber,
      account: account as IAccount,
    },
    preferencesStore.organisationPreferences
  )

  const editAccount = async (updatedAccount: IAccount) => {
    if (!authenticationStore.organisationIdentifier)
      return authenticationStore.logout()
    if (!updatedAccount.name) return

    try {
      setIsSaving(true)
      await Finance.Ledger.modifyAccount(updatedAccount.id, {
        description: updatedAccount.description ?? '',
        name: updatedAccount.name,
        colour: updatedAccount.colour,
      })
      accountStore.loadAccounts()
      setErrorMessage(undefined)
      props.closeModal()
      MixpanelAnalytics.event('Account edited', {
        accountNumber: updatedAccount.accountNumber,
      })
    } catch (error: any) {
      setErrorMessage((error as APIError).humanText('de'))
    } finally {
      setIsSaving(false)
    }
  }

  useEffect(() => {
    if (props.isOpen) {
      setAccount({
        ...props.account,
      })
    }
  }, [props.isOpen])

  useEffect(() => {
    setAccount({
      ...account,
      defaultTaxCode: showVatPicker ? suggestedTaxCode : null,
    })
  }, [showVatPicker])

  return (
    <>
      <Dialog
        className='edit-account-modal'
        isOpen={props.isOpen}
        onDismiss={() => props.closeModal()}
        title={`Konto ${props.account.name} bearbeiten`}
        initialFocusIndex={1}
        actions={[
          ...(!props.hideDeleteAccountAction
            ? [
                {
                  iconOnly: 'delete',
                  className: 'delete-account-action',
                  onClick: () => setIsDeleteAccountModalOpen(true),
                  disabled: !canDeleteAccount(props.account),
                  tooltip: props.account.isSystemAccount
                    ? 'Systemkonten können nicht gelöscht werden'
                    : 'Konto löschen',
                  'aria-label': 'Konto löschen',
                } as ButtonProps,
              ]
            : []),
          {
            children: 'Abbrechen',
            onClick: () => props.closeModal(),
          },
          {
            children: 'Änderungen speichern',
            variant: 'primary',
            isLoading: isSaving,
            onClick: () => {
              editAccount(account as IAccount)
            },
            disabled: (account.name || '').trim() === '',
          },
        ]}
      >
        <Text className='first-paragraph'>
          Hier kannst du dein Konto bearbeiten oder löschen.
        </Text>
        <Flex className='colour-and-name-wrapper' gap='tiny'>
          <ColourSelect
            selectedColour={account.colour || 'grey'}
            setSelectedColour={(colour: string) =>
              setAccount({ ...account, colour })
            }
            selectableColours={accountColours}
          />
          <InputField
            className='name'
            placeholder='Name'
            value={account.name || ''}
            onChange={(name: string) => setAccount({ ...account, name })}
          />
        </Flex>
        <InputField
          className='description'
          placeholder='Beschreibung (optional)'
          value={account.description || ''}
          onChange={(description: string) =>
            setAccount({ ...account, description })
          }
        />
        {showDefaultVATSelect && (
          <VATSelection
            label='Standard-MWST für dieses Konto festlegen'
            showVatPicker={showVatPicker}
            setShowVatPicker={setShowVatPicker}
            taxCode={account.defaultTaxCode as string}
            setTaxCode={(defaultTaxCode: string) =>
              setAccount({
                ...account,
                defaultTaxCode: defaultTaxCode as TaxCode,
              })
            }
          />
        )}
        <AccountNameWarning
          name={account.name}
          ignoreAccountWithNumber={props.account.accountNumber}
        />
        {props.account.isSystemAccount && (
          <FeedbackBanner
            variant='info'
            title='Dieses Konto ist ein Systemkonto.'
          >
            Systemkonten können nicht gelöscht werden, da sie für die
            Funktionsfähigkeit deiner Buchhaltung unerlässlich sind.
          </FeedbackBanner>
        )}
        {errorMessage && (
          <FeedbackBanner variant='error'>{errorMessage}</FeedbackBanner>
        )}
      </Dialog>
      <DeleteAccountModal
        account={props.account}
        isOpen={isDeleteAccountModalOpen}
        closeModal={() => setIsDeleteAccountModalOpen(false)}
        onDeleteCompleted={(selectedReplacementAccount) => {
          props.closeModal()
          accountStore.loadAccounts()
          if (
            props.account.accountNumber ===
            LedgerStore.INITIAL_DEFAULT_ROOT_VIEW
          ) {
            document.location.reload()
          } else {
            const targetAccount =
              selectedReplacementAccount ??
              LedgerStore.INITIAL_DEFAULT_ROOT_VIEW

            ledgerStore.setCurrentRootView(targetAccount)
            CurrentLedgerNavigationStackRef?.switchRootToAccount(targetAccount)
          }
        }}
      />
    </>
  )
}

export const AccountNameWarning = observer(
  (props: { name: string | undefined; ignoreAccountWithNumber?: number }) => {
    const { accountStore } = useRootStore()

    return props.name &&
      accountStore
        .findAccountsWithName(props.name)
        .filter(
          (searchAccount) =>
            searchAccount.accountNumber !== props.ignoreAccountWithNumber
        ).length > 0 ? (
      <FeedbackBanner
        variant='info'
        title='Konto mit gleichem Namen existiert bereits'
      >
        Du kannst das Konto zwar so nennen, aber wir empfehlen dir für bessere
        Unterschiedbarkeit beim Buchen einen eindeutigen Namen zu wählen.
      </FeedbackBanner>
    ) : null
  }
)

export default observer(EditAccountModal)
