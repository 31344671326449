import { IFixedAmountDiscountPosition } from '@nextbusiness/infinity-finance-api'
import EditableDocumentText from '../../controls/EditableDocumentText'

interface DocumentFixedAmountDiscountPositionProps {
  position: IFixedAmountDiscountPosition
  onChange: (changes: Partial<IFixedAmountDiscountPosition>) => void
  isReadOnly?: boolean
}

const DocumentFixedAmountDiscountPosition = (
  props: DocumentFixedAmountDiscountPositionProps
) => {
  return (
    <>
      <div className='positions-column name'>
        <EditableDocumentText
          placeholder='Beschreibung'
          value='Rabatt'
          isReadOnly
        />
      </div>
      <div className='positions-column single-amount'>
        <EditableDocumentText
          isReadOnly={props.isReadOnly}
          placeholder='Rabatt'
          isCurrency
          value={
            props.position.amount ? String(props.position.amount / 100) : ''
          }
          onChange={(value) =>
            props.onChange({
              amount:
                value === ''
                  ? 0
                  : !isNaN(parseFloat(value))
                  ? Math.floor(parseFloat(value) * 100)
                  : props.position.amount,
            })
          }
          style={{ textAlign: 'right' }}
        />
        {props.position.amount ? ' CHF' : ''}
      </div>
    </>
  )
}

export default DocumentFixedAmountDiscountPosition
