import { Spacer } from '@nextbusiness/infinity-ui'
import React, { useState } from 'react'
import './MagicSheetPage.scss'

interface MagicSheetPageProps {
  className?: string
  containerClassName?: string

  header?: React.ReactNode
  children?: React.ReactNode

  noTrailingNegativeSpace?: boolean
}

const MagicSheetPage = (props: MagicSheetPageProps) => {
  const [hasScrolled, setHasScrolled] = useState<boolean>(false)

  const onContainerScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const scrollTop = (e.nativeEvent.target as HTMLDivElement).scrollTop
    setHasScrolled(scrollTop !== 0)
  }

  return (
    <div
      className={
        'magic-sheet-page' +
        (hasScrolled ? ' has-scrolled' : '') +
        (props.className ? ' ' + props.className : '')
      }
    >
      {props.header}
      <div
        className={
          'magic-sheet-page-container' +
          (props.containerClassName ? ' ' + props.containerClassName : '')
        }
        onScroll={(e) => onContainerScroll(e)}
      >
        {props.children}
        {!props.noTrailingNegativeSpace && (
          <Spacer direction='vertical' size='large' />
        )}
      </div>
    </div>
  )
}

export default MagicSheetPage
