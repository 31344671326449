import { Button, useNotificationCenter } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { INewCustomerInvoice } from 'model/CustomerInvoice'
import CustomerInvoices from 'networking/CustomerInvoices'
import { useRootStore } from 'stores/RootStoreContext'
import DocumentSidebarPanelProps from '../DocumentSidebarPanelProps'

const CustomerInvoiceQuoteToBill = (props: DocumentSidebarPanelProps) => {
  const { authenticationStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const duplicateInvoice = async () => {
    try {
      const duplicatedCustomerInvoice =
        await CustomerInvoices.createCustomerInvoice(
          authenticationStore.organisationIdentifier,
          {
            ...props.document,
            title: props.document.title,
            isQuote: false,
            transactionDate: +new Date(),
            openingDate: +new Date(),
            createdFromQuote: props.existingInvoice?.id,
            isQrInvoice: true,
          } as INewCustomerInvoice,
          true
        )
      props.navigateToInvoice(duplicatedCustomerInvoice.id)
      notificationCenter.addNotification({
        title: 'Forderung erfolgreich erstellt',
        children: 'Du befindest dich nun in der erstellten Forderung',
        variant: 'success',
      })
    } catch (error: any) {
      notificationCenter.addNotification({
        title: 'Forderung konnte nicht erstellt werden',
        children: error?.message,
        variant: 'error',
      })
    }
  }
  return (
    <>
      {props.existingInvoice?.invoicesFromQuote?.length == 0 && (
        <Button
          variant='quaternary'
          iconLeft='check'
          className='sidebar-action'
          onClick={() => duplicateInvoice()}
          disabled={props.existingInvoice?.invoicesFromQuote?.length != 0}
          tooltip='Stelle die Offerte in Rechnung'
        >
          In Rechnung stellen
        </Button>
      )}
    </>
  )
}
export default observer(CustomerInvoiceQuoteToBill)
