import { IProduct } from '@nextbusiness/infinity-finance-api'
import { Button, Dialog, Flex, Spacer, Text } from '@nextbusiness/infinity-ui'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { useState } from 'react'
import ProductsList from './ProductsList'

interface SelectProductModalProps {
  isOpen: boolean
  onDismiss: () => void
  onProductSelected: (product: IProduct) => void
}

const SelectProductModal = (props: SelectProductModalProps) => {
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null)

  const selectProduct = () => {
    if (selectedProduct) {
      props.onDismiss()
      props.onProductSelected(selectedProduct)
      MixpanelAnalytics.event('Product selected for invoice')
    }
  }

  return (
    <Dialog
      title='Produkt wählen'
      titleProps={{ divider: true }}
      dismissable
      style={{ maxWidth: '64rem' }}
      {...props}
    >
      <Text type='inline'>
        Wähle ein Produkt aus der Liste oder erstelle ein neues.
      </Text>
      <Spacer size='tiny' />
      <ProductsList
        selectedProduct={selectedProduct}
        onProductSelected={(product) => {
          setSelectedProduct(product)
          if (product) {
            props.onProductSelected(product)
            props.onDismiss()
          }
        }}
        isSelectable
        actions={
          <Flex gap='tiny'>
            <Button onClick={props.onDismiss}>Abbrechen</Button>
            <Button
              variant='primary'
              disabled={!selectedProduct}
              onClick={selectProduct}
            >
              Produkt wählen
            </Button>
          </Flex>
        }
      />
    </Dialog>
  )
}

export default SelectProductModal
