import {
  DatePicker,
  Dialog,
  Flex,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import AccountSelect from 'ledger/accounts/account-select/AccountSelect'
import { ResolvedSetOfSuggestedAccounts } from 'ledger/accounts/data/AccountRules'
import { observer } from 'mobx-react'
import { SettledVATPeriod } from 'model/VAT'
import VAT from 'networking/VAT'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { centsToCHF } from 'utility/Utilities'
import './VATPaymentModal.scss'
import { displayNameForPeriod } from './VATPeriodSection'

interface VATPaymentModalProps {
  isOpen: boolean
  onClose: () => void
  period: SettledVATPeriod
  amount: number
  isTaxCredit: boolean
}

const VATPaymentModal = (props: VATPaymentModalProps) => {
  const { authenticationStore, vatPeriodStore } = useRootStore()
  const notificationCenter = useNotificationCenter()
  const suggestedAccounts: ResolvedSetOfSuggestedAccounts = [
    { title: 'Aktiven', accounts: ['assets'] },
  ]
  const periodName = displayNameForPeriod(props.period)
  const modalTitle = props.isTaxCredit
    ? 'Steuerguthaben im ' + periodName
    : 'Steuerzahlung im ' + periodName
  const [paymentAccount, setPaymentAccount] = useState<number>()
  const [paymentDate, setPaymentDate] = useState<Date | undefined>(new Date())
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const postPayment = async () => {
    if (!paymentAccount || !paymentDate) {
      return
    }
    if (!authenticationStore.organisationIdentifier) {
      return authenticationStore.logout()
    }
    setIsLoading(true)
    try {
      await VAT.postPeriodPayment(
        props.period._id,
        authenticationStore.organisationIdentifier,
        paymentAccount,
        paymentDate.valueOf()
      )
      await vatPeriodStore.loadPeriods()
      props.onClose()
      notificationCenter.addNotification({
        children: `${
          props.isTaxCredit ? 'Steuerguthaben' : 'Steuerzahlung'
        } erfolgreich verbucht.`,
        variant: 'success',
      })
    } catch (error: any) {
      notificationCenter.addNotification({
        children: error.message,
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog
      className='vat-payment-modal'
      isOpen={props.isOpen}
      onDismiss={props.onClose}
      title={modalTitle}
      titleProps={{ divider: true }}
      actions={[
        {
          children: 'Abbrechen',
          onClick: props.onClose,
        },
        {
          children: 'Verbuchen',
          disabled: !paymentAccount || !paymentDate,
          isLoading,
          onClick: () => {
            postPayment()
          },
          variant: 'primary',
        },
      ]}
    >
      <div className='payment-details'>
        <Text>
          {`Mit wechem Konto soll ${
            props.isTaxCredit ? 'das Steuerguthaben' : 'die Steuerzahlung'
          } von ${centsToCHF(props.amount)} CHF verbucht werden?`}
        </Text>
        <Flex gap={1}>
          <AccountSelect
            onlyAllowSuggestedAccounts
            suggestions={suggestedAccounts}
            currentAccountNumber={paymentAccount}
            onChange={(selectedAccountNumber) =>
              setPaymentAccount(selectedAccountNumber)
            }
            inputFieldProps={{
              placeholder: props.isTaxCredit
                ? 'Empfangskonto wählen'
                : 'Zahlungsmittel wählen',
            }}
          />
          <DatePicker
            value={paymentDate}
            onChange={(date) => setPaymentDate(date)}
            placeholder={props.isTaxCredit ? 'Empfangsdatum' : 'Zahlungdatum'}
          />
        </Flex>
      </div>
    </Dialog>
  )
}

export default observer(VATPaymentModal)
