import {
  Button,
  Flex,
  LoadingIndicator,
  RightPanel,
} from '@nextbusiness/infinity-ui'
import ProjectSelect from 'components/project-select/ProjectSelect'
import { Duration } from 'luxon'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { useLocalStorage } from 'utility/hooks'
import { ReactComponent as StartTrackingButton } from '../../assets/time-tracking/start-button.svg'
import { ReactComponent as StopTrackingButton } from '../../assets/time-tracking/stop-button.svg'
import './TimeTrackingPanel.scss'
import TimeTrackingSaveSheet from './TimeTrackingSaveSheet'

interface TimeTrackingPanelProps {
  isTimeTrackingPanelOpen: boolean
  setTimeTrackingStatusText: (text: string | undefined) => void
}

export const useNow = (interval = 1000) => {
  const [now, setNow] = useLocalStorage<number>('now', Date.now())

  useEffect(() => {
    const intervalId = setInterval(() => {
      setNow(Date.now())
    }, interval)
    return () => clearInterval(intervalId)
  }, [])

  return now
}

export const useElapsedTime = (startingTime: number | null) => {
  const now = useNow()

  if (!startingTime) return null
  const elapsedTime = Math.max(0, now - startingTime)
  return Duration.fromMillis(elapsedTime)
}

const TimeTrackingPanel = (props: TimeTrackingPanelProps) => {
  const { projectStore, authenticationStore } = useRootStore()

  const organisationId = authenticationStore.organisationIdentifier
  const isReady = authenticationStore.isHydrated

  const startingTimeKey = `time-tracking-starting-time-${organisationId}`
  const projectIdKey = `time-tracking-project-id-${organisationId}`

  const [startingTime, setStartingTime] = useLocalStorage<number | null>(
    startingTimeKey,
    null
  )
  const [projectId, setProjectId] = useLocalStorage<string | null>(
    projectIdKey,
    null
  )
  const elapsedTime = useElapsedTime(startingTime)
  const elapsedTimeFormatted = elapsedTime?.toFormat('hh:mm:ss')

  const [timeToBeTracked, setTimeToBeTracked] = useState<Duration | null>(null)
  const [isTimeTrackingSaveSheetOpen, setIsTimeTrackingSaveSheetOpen] =
    useState<boolean>(false)

  const projects = projectStore.activeProjects ?? []

  const startTimeTracking = () => setStartingTime(Date.now())
  const stopTimeTracking = () => {
    setStartingTime(null)
    setProjectId(null)
    setTimeToBeTracked(null)
    props.setTimeTrackingStatusText(undefined)
  }
  const promptSavingTimeEntry = () => {
    setTimeToBeTracked(elapsedTime)
    setIsTimeTrackingSaveSheetOpen(true)
  }

  useEffect(() => {
    props.setTimeTrackingStatusText(elapsedTimeFormatted)
  }, [elapsedTimeFormatted])

  useEffect(() => {
    if (!props.isTimeTrackingPanelOpen) {
      setIsTimeTrackingSaveSheetOpen(false)
    }
  }, [props.isTimeTrackingPanelOpen])

  const initialiseLocalStorageStates = () => {
    const initialStartingTime = window.localStorage.getItem(startingTimeKey)
    const initialProjectId = window.localStorage.getItem(projectIdKey)

    if (initialStartingTime) {
      const parsedStartingTime = parseInt(initialStartingTime)
      setStartingTime(isNaN(parsedStartingTime) ? null : parsedStartingTime)
    } else {
      // reset required for correct behaviour when switching
      // from an organisation with a running timer to one without
      // where the key is not set in localStorage
      setStartingTime(null)
    }
    if (initialProjectId) {
      setProjectId(JSON.parse(initialProjectId))
    } else {
      // same as above
      setProjectId(null)
    }
  }

  useEffect(() => {
    if (isReady) {
      initialiseLocalStorageStates()
      projectStore.fetchAllProjects()
    }
  }, [organisationId, isReady])

  return (
    <RightPanel
      isVisible={props.isTimeTrackingPanelOpen}
      className='time-tracking-panel'
    >
      <Flex
        horizontalAlignment='center'
        verticalAlignment='center'
        fillContainer
        direction='vertical'
      >
        {startingTime === null ? (
          <div className='time-and-track-button'>
            <Button variant='shell' onClick={startTimeTracking}>
              <StartTrackingButton className='start-tracking-button-icon' />
            </Button>
            <h1>00:00:00</h1>
          </div>
        ) : (
          <div className='time-and-track-button'>
            <Button variant='shell' onClick={promptSavingTimeEntry}>
              <StopTrackingButton className='stop-tracking-button-icon' />
            </Button>
            <h1>{elapsedTimeFormatted}</h1>
          </div>
        )}
        {projectStore.areProjectsLoaded ? (
          <ProjectSelect
            className='time-tracking-project-select'
            selectedProjectId={projectId ?? undefined}
            setSelectedProjectId={(id) => setProjectId(id ?? null)}
            projects={projects}
            flyoutProps={{ positionOptions: { prefer: 'below' } }}
          />
        ) : (
          <LoadingIndicator />
        )}
        <TimeTrackingSaveSheet
          isOpen={isTimeTrackingSaveSheetOpen}
          startingTime={startingTime}
          timeToBeTracked={timeToBeTracked}
          stopTimeTracking={stopTimeTracking}
          selectedProjectId={projectId ?? undefined}
          setSelectedProjectId={(id) => setProjectId(id ?? null)}
          projects={projects}
          closeSheet={() => setIsTimeTrackingSaveSheetOpen(false)}
        />
      </Flex>
    </RightPanel>
  )
}

export default observer(TimeTrackingPanel)
