import LedgerNavigationStack from 'ledger/LedgerNavigationStack'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useRootStore } from '../stores/RootStoreContext'

const LedgerPage = () => {
  const { ledgerStore } = useRootStore()

  const [rootAccountNumber, setRootAccountNumber] = useState<number>(
    ledgerStore.currentRootView
  )
  useEffect(() => {
    setRootAccountNumber(ledgerStore.currentRootView)
  }, [ledgerStore.currentRootView])

  useEffect(() => {
    const handleUnload = () => {
      if (!ledgerStore.hasUnsavedChanges()) return undefined
      return true
    }
    window.addEventListener('beforeunload', handleUnload)
  }, [])

  return <LedgerNavigationStack rootAccountNumber={rootAccountNumber} />
}

export default observer(LedgerPage)
