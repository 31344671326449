import InvoiceContactFilter from 'invoices/shared-components/filters/InvoiceContactFilter'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import { useVendorInvoicesFilter } from './VendorInvoicesFilterContext'

const VendorInvoiceCreditorFilter = () => {
  const { vendorInvoiceStore } = useRootStore()
  const { activeFilter, editFilter } = useVendorInvoicesFilter()

  const allVendorInvoices = vendorInvoiceStore.allInvoices

  return (
    <InvoiceContactFilter
      contactKey='creditor'
      invoices={allVendorInvoices}
      label='Lieferant'
      selectedValue={activeFilter.creditor}
      onValueSelected={(contactId) => editFilter({ creditor: contactId })}
    />
  )
}

export default observer(VendorInvoiceCreditorFilter)
