import ContactSelectOptionAvatar from 'components/contact-select/ContactSelectOptionAvatar'
import ResourceOption, {
  ResourceOptionProps,
} from 'components/resource-select/ResourceOption'
import { observer } from 'mobx-react'
import { IVendorInvoice } from 'model/VendorInvoice'
import { useRootStore } from 'stores/RootStoreContext'
import DateUtilities from 'utility/DateUtilites'
import Utilities from 'utility/Utilities'

interface VendorInvoiceSelectOptionProps {
  vendorInvoice: IVendorInvoice
  optionProps: ResourceOptionProps<string, IVendorInvoice>
}

const VendorInvoiceSelectOption = (props: VendorInvoiceSelectOptionProps) => {
  const { contactStore } = useRootStore()
  const contact = contactStore.allContacts.find(
    (contact) => contact.id === props.vendorInvoice.creditor
  )
  const amount = `CHF ${Utilities.centsToCHF(
    props.vendorInvoice.totalRestAmount
  )} offen`
  const date = DateUtilities.humanFormatNumeric(props.vendorInvoice.openingDate)
  return (
    <ResourceOption
      {...props.optionProps}
      leftAccessory={<ContactSelectOptionAvatar contact={contact} />}
      description={`${date} · ${amount}`}
    />
  )
}

export default observer(VendorInvoiceSelectOption)
