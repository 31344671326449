import { IUserOrganisation } from 'model/Organisation'
import { store } from '../../../stores/RootStoreContext'
import EverythingSearchProviderResult from '../EverythingSearchProviderResult'
import EverythingSearchResultsProvider from '../EverythingSearchResultsProvider'

class OrganisationSearchProvider implements EverythingSearchResultsProvider {
  providerName = 'Organisation wechseln'

  private resultForOrganisation(
    organisation: IUserOrganisation
  ): EverythingSearchProviderResult {
    return {
      id: organisation.IDForAPI,
      icon: 'organization',
      title: `Zu ${organisation.name} wechseln`,
      type: 'organisation',
    }
  }

  resultsForQuery(query: string): Promise<EverythingSearchProviderResult[]> {
    const results: EverythingSearchProviderResult[] = []

    const organisations = [...store.authenticationStore.organisations]
    for (const item of organisations) {
      if (
        store.authenticationStore.fabricOrganisationIdentifier === item.IDForAPI
      )
        continue
      const action = `Zu ${item.name} wechseln`
      if (action.toLowerCase().includes(query.toLowerCase())) {
        results.push(this.resultForOrganisation(item))
      }
    }
    return Promise.resolve(results)
  }

  onResultSelected(result: EverythingSearchProviderResult) {
    const organisation = store.authenticationStore.organisations.find(
      (organisation) => organisation.IDForAPI === result.id
    )
    if (!organisation) return
    store.authenticationStore.switchToOrganisation(organisation.IDForAPI)
  }
}

export default OrganisationSearchProvider
