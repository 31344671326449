import FinancialHealthMetric from '../FinancialHealthMetric'
import { FinancialHealthAssessment } from '../FinancialHealthAssessment'

class MetricDegreeOfLiquidity2 extends FinancialHealthMetric {
  title = 'Liquiditätsgrad 2'
  description =
    'Zahlungsfähigkeit der offenen Rechnungen mit Cash und Forderungen'

  getAssessment = (): FinancialHealthAssessment =>
    FinancialHealthMetric.assessmentFromStatus(
      this.context.financialSolvency?.degreeOfLiquidity2?.status
    )

  getStatusText = (): string =>
    this.context.financialSolvency?.degreeOfLiquidity2?.status ?? 'Keine Angabe'

  getActualValue = () =>
    `${(
      (this.context.financialSolvency?.degreeOfLiquidity2?.value ?? 1) * 100
    ).toFixed(1)}%`
}

export default MetricDegreeOfLiquidity2
