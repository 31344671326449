import { NonIdealState } from '@nextbusiness/infinity-ui'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import { observer } from 'mobx-react'
import { QuoteStatus } from 'model/CustomerInvoice'
import { useHistory } from 'react-router-dom'
import { useRootStore } from 'stores/RootStoreContext'
import CustomerInvoiceListItem from '../regular-list/CustomerInvoiceListItem'

const AcceptedQuotesView = () => {
  const { customerInvoiceStore } = useRootStore()
  const history = useHistory()

  const acceptedQuotes = customerInvoiceStore.allQuotes
    ?.filter((quote) => quote.quoteStatus === QuoteStatus.Accepted)
    .reverse()
  const hasAcceptedQuotes = acceptedQuotes?.length

  return (
    <MagicSheetPage
      header={<MagicSheetTitleBar title='Akzeptierte Offerten' />}
      containerClassName='invoices-page-container'
    >
      {!hasAcceptedQuotes ? (
        <NonIdealState>Noch keine akzeptieren Offerten</NonIdealState>
      ) : (
        acceptedQuotes?.map((acceptedQuote) => (
          <CustomerInvoiceListItem
            invoice={acceptedQuote}
            key={acceptedQuote.id}
            onClick={() =>
              history.push(`/forderungen/detail/${acceptedQuote.id}`)
            }
          />
        ))
      )}
    </MagicSheetPage>
  )
}

export default observer(AcceptedQuotesView)
