import { observer } from 'mobx-react'
import { IAccount } from 'model/Account'
import { useRootStore } from 'stores/RootStoreContext'
import { truncateString } from 'utility/Utilities'
import './Account.scss'
import SpecialAccounts, { SpecialAccount } from './SpecialAccounts'

interface AccountProps {
  accountNumber?: number
  fiscalYear?: number
  maxCharacters?: number
  disableTrunaction?: boolean
}

const Account = (props: AccountProps) => {
  const { accountStore } = useRootStore()
  const account: IAccount | SpecialAccount | undefined =
    accountStore.specificAccounts.find(
      (account) =>
        account.accountNumber === props.accountNumber &&
        account.fiscalYear === props.fiscalYear
    ) ??
    accountStore.find(props.accountNumber) ??
    SpecialAccounts.find(props.accountNumber)

  const name =
    account?.name ?? (props.accountNumber ? `Konto ${props.accountNumber}` : '')

  return (
    <div className='ui-view account'>
      <div
        className='colour-swatch'
        style={{
          backgroundColor: account?.colour || 'grey',
        }}
      />
      <span className='name'>
        {props.disableTrunaction
          ? name
          : truncateString(name, props.maxCharacters ?? 18)}
      </span>
    </div>
  )
}

export default observer(Account)
