import {
  AmountMode,
  VATPresentationOptions,
} from '@nextbusiness/infinity-finance-api'
import { Checkbox, Dialog, RadioGroup, Text } from '@nextbusiness/infinity-ui'
import VATPreviewGrossWithNetRow from 'assets/settings-graphics/vat-preview/vat-preview-gross-with-net-row.svg'
import VATPreviewGross from 'assets/settings-graphics/vat-preview/vat-preview-gross.svg'
import VATPreviewNetExclQuotes from 'assets/settings-graphics/vat-preview/vat-preview-net-excl-quotes.svg'
import VATPreviewNetInclQuotes from 'assets/settings-graphics/vat-preview/vat-preview-net-incl-quotes.svg'
import { useState } from 'react'
import SettingsGroup from 'settings/general/SettingsGroup'
import { useEnter } from 'utility/hooks'
import './SetupModalVATPresentation.scss'

interface SetupModalVATPresentationProps {
  isOpen: boolean
  onPreviousStep: () => void
  onContinue: (
    amountMode: AmountMode,
    vatPresentation: VATPresentationOptions
  ) => void
}

const SetupModalVATPresentation = (props: SetupModalVATPresentationProps) => {
  const [amountMode, setAmountMode] = useState<AmountMode>(AmountMode.Net)
  const [vatPresentation, setVatPresentation] =
    useState<VATPresentationOptions>({
      showNetAmountSubtotal: true,
    })

  const onContinue = () => props.onContinue(amountMode, vatPresentation)
  useEnter(onContinue, props.isOpen)

  const previewGraphic = () => {
    if (amountMode === AmountMode.Gross) {
      return vatPresentation.showNetAmountSubtotal
        ? VATPreviewGrossWithNetRow
        : VATPreviewGross
    } else if (vatPresentation.hideVATInQuotes) {
      return VATPreviewNetExclQuotes
    } else {
      return VATPreviewNetInclQuotes
    }
  }

  return (
    <Dialog
      isOpen={props.isOpen}
      className='customer-invoice-editor-setup-modal modal-vat-presentation'
      title='Mehrwertsteuer einrichten'
      titleProps={{ divider: true }}
      actions={[
        { children: 'Zurück', onClick: props.onPreviousStep },
        { children: 'Fortfahren', variant: 'primary', onClick: onContinue },
      ]}
    >
      <div className='vat-options-fields'>
        <Text className='instruction'>
          Wähle aus, wie du die Mehrwertsteuer auf Offerten und Forderungen
          ausweisen möchtest.
        </Text>
        <SettingsGroup title='Mehrwertsteuer'>
          <Text>Wie möchtest du die MWST verrechnen?</Text>
          <RadioGroup
            value={amountMode}
            onChange={setAmountMode}
            options={[
              {
                value: AmountMode.Net,
                label: 'Zu meinen Preisen dazurechnen (Netto)',
              },
              {
                value: AmountMode.Gross,
                label: 'In Preisen bereits enthalten (Brutto)',
              },
            ]}
          />
          {amountMode === AmountMode.Net && (
            <div>
              <Text>Wie möchtest du MWST in Offerten zeigen?</Text>
              <Checkbox
                isChecked={!vatPresentation.hideVATInQuotes}
                onChange={(isChecked) =>
                  setVatPresentation({
                    ...vatPresentation,
                    hideVATInQuotes: !isChecked,
                  })
                }
                label='Betrag inkl. MWST zeigen'
              />
            </div>
          )}
          {amountMode === AmountMode.Gross && (
            <div>
              <Text>Zusätzliche Optionen</Text>
              <Checkbox
                isChecked={vatPresentation.showNetAmountSubtotal}
                onChange={(isChecked) =>
                  setVatPresentation({
                    ...vatPresentation,
                    showNetAmountSubtotal: isChecked,
                  })
                }
                label='Nettobetrag als Zwischensumme zeigen'
              />
            </div>
          )}
        </SettingsGroup>
      </div>
      <div className='vat-options-preview'>
        <img src={previewGraphic()} alt='Vorschau Netto' />
      </div>
    </Dialog>
  )
}

export default SetupModalVATPresentation
