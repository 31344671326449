import { makeObservable, observable, runInAction } from 'mobx'
import IFinancialClose from 'model/financial-close/FinancialClose'
import FinancialClose from 'networking/FinancialClose'
import RootStore from './RootStore'
import Store from './Store'

export default class FinancialCloseStore extends Store {
  @observable financialCloseSettings: IFinancialClose | null = null

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  async loadSettings() {
    const settings = await FinancialClose.getSettings(
      this.rootStore.authenticationStore.organisationIdentifier
    )
    runInAction(() => {
      this.financialCloseSettings = settings
    })
  }

  async update(changes: Partial<IFinancialClose>) {
    const updatedSettings = await FinancialClose.updateSettings(
      this.rootStore.authenticationStore.organisationIdentifier,
      changes
    )
    runInAction(() => {
      this.financialCloseSettings = updatedSettings
    })
  }
}
