import { FinancialHealthAssessment } from './FinancialHealthAssessment'
import FinancialHealthContext from './FinancialHealthContext'
import FinancialHealthMetric from './FinancialHealthMetric'

export enum FinancialHealthGroupStatus {
  Perfect,
  Warnings,
  Critical,
}

abstract class FinancialHealthStatusGroup {
  abstract readonly title: string

  protected readonly context: FinancialHealthContext
  public metrics: FinancialHealthMetric[]

  constructor(context: FinancialHealthContext) {
    this.context = context
    this.metrics = this.setupMetrics(context)
  }

  abstract setupMetrics(
    context: FinancialHealthContext
  ): FinancialHealthMetric[]

  get assessments(): FinancialHealthAssessment[] {
    return this.metrics
      .filter((metric) => metric.isRelevant())
      .map((metric) => metric.getAssessment())
  }

  get warningsCount() {
    return this.assessments.filter(
      (status) => status === FinancialHealthAssessment.Acceptable
    ).length
  }

  get problemsCount() {
    return this.assessments.filter(
      (status) => status === FinancialHealthAssessment.Negative
    ).length
  }

  getOverallStatus = (): FinancialHealthGroupStatus => {
    const numberOfNegatives = this.problemsCount
    const numberOfAcceptables = this.warningsCount

    if (numberOfNegatives > 0) return FinancialHealthGroupStatus.Critical
    else if (numberOfAcceptables > 0) return FinancialHealthGroupStatus.Warnings

    return FinancialHealthGroupStatus.Perfect
  }
}

export default FinancialHealthStatusGroup
