import { IProduct } from '@nextbusiness/infinity-finance-api'
import { Flex, InputField } from '@nextbusiness/infinity-ui'
import ScrollWell from 'base-components/layout/ScrollWell'
import { useDeferredValue, useEffect, useMemo, useRef, useState } from 'react'
import { useEnter } from 'utility/hooks'
import { ProductsListProps } from './ProductsList'
import ProductsListEmpty from './ProductsListEmpty'
import ProductsListItems from './ProductsListItems'

interface ProductsSearchableListProps extends ProductsListProps {
  allProducts: IProduct[]
}

const ProductsSearchableList = (props: ProductsSearchableListProps) => {
  const { allProducts } = props

  const [searchQuery, setSearchQuery] = useState<string>('')
  const searchFieldRef = useRef<HTMLInputElement>(null)

  const deferredSearchQuery = useDeferredValue(searchQuery)
  const listedProducts = useMemo(() => {
    if (!searchQuery.trim()) return allProducts
    return allProducts.filter((product) => {
      return (
        product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.articleNumber?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    })
  }, [deferredSearchQuery, allProducts])

  useEnter(() => {
    const isSearchFocused = document.activeElement === searchFieldRef.current
    if (isSearchFocused) {
      const firstProduct = listedProducts[0]
      if (firstProduct) props.onProductSelected?.(firstProduct)
    }
  })

  useEffect(() => {
    searchFieldRef.current?.focus()
  }, [])

  return (
    <Flex direction='vertical'>
      <InputField
        icon='search'
        placeholder='Nach Name oder Artikelnummer suchen'
        value={searchQuery}
        onChange={setSearchQuery}
        ref={searchFieldRef}
        fullWidth
      />
      <ScrollWell className='product-scroll-well'>
        <div className='product-list'>
          {!listedProducts.length ? (
            <ProductsListEmpty
              isSearching={deferredSearchQuery.trim() === ''}
            />
          ) : (
            <ProductsListItems {...props} listedProducts={listedProducts} />
          )}
        </div>
      </ScrollWell>
    </Flex>
  )
}

export default ProductsSearchableList
