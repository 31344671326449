import { ICustomerInvoiceColumn } from 'model/CustomerInvoice'
import React from 'react'
import IDocumentSectionHeights from './IDocumentSectionHeights'

export const DEFAULT_HEIGHTS: IDocumentSectionHeights = {
  header: 0,
  title: 0,
  infoBlock: 0,
  introductoryText: 0,
  positionsHeaderRow: 0,
  positions: [],
  positionsSumRowExclVAT: 0,
  vatSumRow77: 0,
  vatSumRow37: 0,
  vatSumRow25: 0,
  vatSumRow81: 0,
  vatSumRow38: 0,
  vatSumRow26: 0,
  positionsSumRowInclVAT: 0,
  closingText: 0,
}

export interface IDocumentRendererContext {
  heights: IDocumentSectionHeights
  tableColumns?: ICustomerInvoiceColumn[]
  setPageHeight: (pageHeight: number) => void
  setPositionTableMargin: (positionTableMargin: number) => void
  setFooterHeight: (footerHeight: number) => void
  updateHeights: (newHeights: Partial<IDocumentSectionHeights>) => void
  updatePositionHeights: (index: number, newHeight: number) => void
  deletePositionHeight: (index: number, identifier: string) => void
}

const DocumentRendererContext = React.createContext<IDocumentRendererContext>({
  heights: DEFAULT_HEIGHTS,
  setPageHeight: () => {},
  setPositionTableMargin: () => {},
  setFooterHeight: () => {},
  updateHeights: () => {},
  updatePositionHeights: () => {},
  deletePositionHeight: () => {},
})

export default DocumentRendererContext
