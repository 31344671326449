import { Flex, Heading, NonIdealState } from '@nextbusiness/infinity-ui'
import { IconItem, IconSize } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import React from 'react'
import ExtendedInvoiceListLoading from './ExtendedInvoiceListLoading'
import ExtendedInvoiceListScrollView from './ExtendedInvoiceListScrollView'
import './ExtendedInvoiceListSection.scss'

interface ExtendedInvoiceListSectionProps {
  bare?: boolean
  children?: React.ReactNode
  title?: string
  isLoading: boolean
  isEmpty: boolean
  emptyStateContent: React.ReactNode
  emptyStateIcon?: IconItem
  hasError?: boolean
  errorStateContent?: React.ReactNode
  className?: string
  stacks?: React.ReactNode
}

const ExtendedInvoiceListSection = (props: ExtendedInvoiceListSectionProps) => {
  const extraordinaryStateApplies =
    props.isLoading || props.hasError || props.isEmpty

  const extraordinaryState = () => {
    if (props.isLoading) {
      return <ExtendedInvoiceListLoading />
    } else if (props.hasError) {
      return <NonIdealState>{props.errorStateContent}</NonIdealState>
    } else
      return (
        <NonIdealState
          icon={props.emptyStateIcon ?? 'check'}
          iconSize={IconSize.Medium}
        >
          {props.emptyStateContent}
        </NonIdealState>
      )
  }

  return (
    <div
      className={classNames('extended-invoice-list-section', props.className, {
        bare: props.bare,
      })}
    >
      {props.title && <Heading type='h3'>{props.title}</Heading>}
      {extraordinaryStateApplies ? (
        extraordinaryState()
      ) : (
        <Flex className='inner-container'>
          <ExtendedInvoiceListScrollView>
            {props.children}
          </ExtendedInvoiceListScrollView>
          {props.stacks}
        </Flex>
      )}
    </div>
  )
}

export default ExtendedInvoiceListSection
