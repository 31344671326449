import React from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import DocumentElementProps from '../DocumentElementProps'

interface DocumentPositionsDragDropContextProps extends DocumentElementProps {
  children?: React.ReactNode
}

function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const DocumentPositionsDragDropContext = (
  props: DocumentPositionsDragDropContextProps
) => {
  const onDragEnd = (result: DropResult) => {
    if (!props.document.positions) return
    if (!result.destination) return
    if (result.destination.index === result.source.index) return

    const positions = reorder(
      props.document.positions ?? [],
      result.source.index,
      result.destination.index
    )
    props.registerEdit({
      positions,
    })
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>{props.children}</DragDropContext>
  )
}

export default DocumentPositionsDragDropContext
