import { AccountType } from 'model/Account'

export interface ChartGroup {
  title: string
  from: number
  to: number
  groups?: ChartGroup[]
}

export const chartOfAccounts: ChartGroup[] = [
  {
    title: 'Aktiven',
    from: 1000,
    to: 1999,
    groups: [
      {
        title: 'Umlaufvermögen',
        from: 1000,
        to: 1399,
        groups: [
          {
            title: 'Flüssige Mittel',
            from: 1000,
            to: 1059,
            groups: [
              {
                title: 'Kasse',
                from: 1000,
                to: 1019,
              },
              {
                title: 'Bankguthaben',
                from: 1020,
                to: 1059,
              },
            ],
          },
          {
            title: 'Kurzfristig gehaltene Aktiven mit Börsenkurs',
            from: 1060,
            to: 1099,
            groups: [
              {
                title: 'Wertschriften',
                from: 1060,
                to: 1068,
              },
              {
                title: 'Wertberichtigungen Wertschriften',
                from: 1069,
                to: 1069,
              },
            ],
          },
          {
            title: 'Forderungen aus Lieferungen und Leistungen',
            from: 1100,
            to: 1139,
            groups: [
              {
                title: 'Forderungen aus Lieferungen und Leistungen (Debitoren)',
                from: 1100,
                to: 1108,
              },
              {
                title: 'Delkredere',
                from: 1109,
                to: 1109,
              },
            ],
          },
          {
            title: 'Übrige kurzfristige Forderungen',
            from: 1140,
            to: 1199,
            groups: [
              {
                title: 'Vorschüsse und Darlehen',
                from: 1140,
                to: 1148,
              },
              {
                title: 'Wertberichtigungen Vorschüsse und Darlehen',
                from: 1149,
                to: 1169,
              },
              {
                title:
                  'Vorsteuer MWST Material, Waren, Dienstleistungen, Energie',
                from: 1170,
                to: 1170,
              },
              {
                title: 'Vorsteuer MWST Investitionen, übriger Betriebsaufwand',
                from: 1171,
                to: 1171,
              },
              {
                title: 'Verrechnungssteuer',
                from: 1172,
                to: 1176,
              },
              {
                title:
                  'Forderungen gegenüber Sozialversicherungen und Vorsorgeeinrichtungen',
                from: 1177,
                to: 1180,
              },
              {
                title: 'Quellensteuer',
                from: 1181,
                to: 1189,
              },
              {
                title: 'Sonstige kurzfristige Forderungen',
                from: 1190,
                to: 1190,
              },
              {
                title: 'Wertberichtigungen sonstige kurzfristige Forderungen',
                from: 1191,
                to: 1191,
              },
            ],
          },
          {
            title: 'Vorräte und nicht fakturierte Dienstleistungen',
            from: 1200,
            to: 1299,
            groups: [
              {
                title: 'Handelswaren',
                from: 1200,
                to: 1209,
              },
              {
                title: 'Rohstoffe',
                from: 1210,
                to: 1219,
              },
              {
                title: 'Werkstoffe',
                from: 1220,
                to: 1229,
              },
              {
                title: 'Hilfs- und Verbrauchsmaterial',
                from: 1230,
                to: 1249,
              },
              {
                title: 'Handelswaren in Konsignation',
                from: 1250,
                to: 1259,
              },
              {
                title: 'Fertige Erzeugnisse',
                from: 1260,
                to: 1269,
              },
              {
                title: 'Unfertige Erzeignisse',
                from: 1270,
                to: 1279,
              },
              {
                title: 'Nicht fakturierte Dienstleistungen',
                from: 1280,
                to: 1299,
              },
            ],
          },
          {
            title: 'Aktive Rechnungsabgrenzung',
            from: 1300,
            to: 1301,
            groups: [
              {
                title: 'Bezahlter Aufwand des Folgejahres',
                from: 1300,
                to: 1300,
              },
              {
                title: 'Noch nicht erhaltener Ertrag',
                from: 1301,
                to: 1301,
              },
            ],
          },
        ],
      },
      {
        title: 'Anlagevermögen',
        from: 1400,
        to: 1999,
        groups: [
          {
            title: 'Finanzanlagen',
            from: 1400,
            to: 1479,
            groups: [
              {
                title: 'Wertschriften',
                from: 1400,
                to: 1408,
              },
              {
                title: 'Wertberechtigungen Wertschriften',
                from: 1409,
                to: 1439,
              },
              {
                title: 'Darlehen',
                from: 1440,
                to: 1440,
              },
              {
                title: 'Hypotheken',
                from: 1441,
                to: 1448,
              },
              {
                title: 'Wertberechtigungen langfristige Forderungen',
                from: 1449,
                to: 1479,
              },
            ],
          },
          {
            title: 'Beteiligungen',
            from: 1480,
            to: 1499,
            groups: [
              {
                title: 'Beteiligungen',
                from: 1480,
                to: 1488,
              },
              {
                title: 'Wertberechtigungen Beteiligungen',
                from: 1489,
                to: 1499,
              },
            ],
          },
          {
            title: 'Mobile Sachanlagen',
            from: 1500,
            to: 1599,
            groups: [
              {
                title: 'Maschinen und Apparate',
                from: 1500,
                to: 1508,
              },
              {
                title: 'Wertberechtigungen Maschinen und Apparate',
                from: 1509,
                to: 1509,
              },
              {
                title: 'Mobiliar und Einrichtungen',
                from: 1510,
                to: 1518,
              },
              {
                title: 'Wertberichtigungen Mobiliar und Einrichtungen',
                from: 1519,
                to: 1519,
              },
              {
                title: 'Büromaschinen, Informatik, Kommunikationstechnologie',
                from: 1520,
                to: 1528,
              },
              {
                title:
                  'Wertberichtigungen Büromaschinen, Informatik, Kommunikationstechnologie',
                from: 1529,
                to: 1529,
              },
              {
                title: 'Fahrzeuge',
                from: 1530,
                to: 1538,
              },
              {
                title: 'Wertberichtigungen Fahrzeuge',
                from: 1539,
                to: 1539,
              },
              {
                title: 'Werkzeuge und Geräte',
                from: 1540,
                to: 1548,
              },
              {
                title: 'Wertberichtigungen Werkzeuge und Geräte',
                from: 1549,
                to: 1599,
              },
            ],
          },
          {
            title: 'Immobile Sachanlagen',
            from: 1600,
            to: 1699,
            groups: [
              {
                title: 'Geschäftsliegenschaft',
                from: 1600,
                to: 1608,
              },
              {
                title: 'Wertberechtigungen Geschäftsliegenschaften',
                from: 1609,
                to: 1699,
              },
            ],
          },
          {
            title: 'Immaterielle Werte',
            from: 1700,
            to: 1799,
            groups: [
              {
                title: 'Patente, Know-how, Lizenzen, Rechte, Entwicklungen',
                from: 1700,
                to: 1708,
              },
              {
                title:
                  'Wertberichtigungen Patente, Know-how, Lizenzen, Rechte, Entwicklungen',
                from: 1709,
                to: 1769,
              },
              {
                title: 'Goodwill',
                from: 1770,
                to: 1778,
              },
              {
                title: 'Wertberechtigungen Goodwill',
                from: 1770,
                to: 1799,
              },
            ],
          },
          {
            title:
              'Nicht einbezahltes Grund-, Gesellschafter- oder Stiftungskapital',
            from: 1800,
            to: 1999,
            groups: [
              {
                title:
                  'Nicht einbezahltes Aktien-, Stamm-, Anteilschein- oder Stiftungskapital',
                from: 1850,
                to: 1999,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Passiven',
    from: 2000,
    to: 2999,
    groups: [
      {
        title: 'Kurzfristiges Fremdkapital',
        from: 2000,
        to: 2399,
        groups: [
          {
            title: 'Verbindlichkeiten aus Lieferungen und Leistungen',
            from: 2000,
            to: 2099,
            groups: [
              {
                title:
                  'Verbindlichkeiten aus Lieferungen und Leistungen (Kreditoren)',
                from: 2000,
                to: 2029,
              },
              {
                title: 'Erhaltene Anzahlungen',
                from: 2030,
                to: 2099,
              },
            ],
          },
          {
            title: 'Kurzfristige verzinsliche Verbindlichkeiten',
            from: 2100,
            to: 2199,
            groups: [
              {
                title: 'Bankverbindlichkeiten',
                from: 2100,
                to: 2119,
              },
              {
                title: 'Verbindlichkeiten aus Finanzierungsleasing',
                from: 2120,
                to: 2139,
              },
              {
                title: 'Übrige verzinsliche Verbindlichkeiten',
                from: 2140,
                to: 2199,
              },
            ],
          },
          {
            title: 'Übrige kurzfristige Verbindlichkeiten',
            from: 2200,
            to: 2299,
            groups: [
              {
                title: 'Geschuldete MWST (Umsatzsteuer)',
                from: 2200,
                to: 2200,
              },
              {
                title: 'Abrechnungskonto MWST',
                from: 2201,
                to: 2205,
              },
              {
                title: 'Verrechnungssteuer',
                from: 2206,
                to: 2207,
              },
              {
                title: 'Direkte Steuern',
                from: 2208,
                to: 2209,
              },
              {
                title: 'Sonstige kurzfristige Verbindlichkeiten',
                from: 2210,
                to: 2260,
              },
              {
                title: 'Beschlossene Ausschüttungen',
                from: 2261,
                to: 2269,
              },
              {
                title: 'Sozialversicherungen und Vorsorgeeinrichtungen',
                from: 2270,
                to: 2278,
              },
              {
                title: 'Quellensteuer',
                from: 2279,
                to: 2299,
              },
            ],
          },
          {
            title:
              'Passive Rechnungsabgrenzungen und kurzfristige Rückstellungen',
            from: 2300,
            to: 2399,
            groups: [
              {
                title: 'Noch nicht bezahlter Aufwand',
                from: 2300,
                to: 2300,
              },
              {
                title: 'Erhaltener Ertrag des Folgejahres',
                from: 2301,
                to: 2329,
              },
              {
                title: 'Kurzfristige Rückstellungen',
                from: 2330,
                to: 2399,
              },
            ],
          },
        ],
      },
      {
        title: 'Langfristiges Fremdkapital',
        from: 2400,
        to: 2799,
        groups: [
          {
            title: 'Langfristige verzinsliche Verbindlichkeiten',
            from: 2400,
            to: 2499,
            groups: [
              {
                title: 'Bankverbindlichkeiten',
                from: 2400,
                to: 2419,
              },
              {
                title: 'Verbindlichkeiten aus Finanzierungsleasing',
                from: 2420,
                to: 2429,
              },
              {
                title: 'Obligationenanleihen',
                from: 2430,
                to: 2449,
              },
              {
                title: 'Darlehen',
                from: 2450,
                to: 2459,
              },
              {
                title: 'Hypotheken',
                from: 2460,
                to: 2499,
              },
            ],
          },
          {
            title: 'Übrige langfristige Verbindlichkeiten',
            from: 2500,
            to: 2599,
            groups: [
              {
                title: 'Übrige langfristige Verbindlichkeiten (unverzinslich)',
                from: 2500,
                to: 2599,
              },
            ],
          },
          {
            title:
              'Rückstellungen sowie vom Gesetz vorgesehene ähnliche Positionen',
            from: 2600,
            to: 2799,
            groups: [
              {
                title: 'Rückstellungen',
                from: 2600,
                to: 2799,
              },
            ],
          },
        ],
      },
      {
        title: 'Eigenkapital (juristische Personen)',
        from: 2800,
        to: 2999,
        groups: [
          {
            title: 'Grund-, Gesellschafter- oder Stiftungskapital',
            from: 2800,
            to: 2899,
            groups: [
              {
                title: 'Aktien-, Stamm-, Anteilschein- oder Stiftungskapital',
                from: 2800,
                to: 2899,
              },
            ],
          },
          {
            title: 'Reserven und Jahresgewinn oder Jahresverlust',
            from: 2900,
            to: 2999,
            groups: [
              {
                title: 'Gesetzliche Kapitalreserve',
                from: 2900,
                to: 2939,
              },
              {
                title: 'Aufwertungsreserve',
                from: 2940,
                to: 2949,
              },
              {
                title: 'Gesetzliche Gewinnreserve',
                from: 2950,
                to: 2959,
              },
              {
                title: 'Freiwillige Gewinnreserven',
                from: 2960,
                to: 2969,
              },
              {
                title: 'Gewinnvortrag oder Verlustvortrag',
                from: 2970,
                to: 2978,
              },
              {
                title: 'Jahresgewinn oder Jahresverlust',
                from: 2979,
                to: 2979,
              },
              {
                title:
                  'Eigene Aktien, Stammanteile oder Anteilscheine (Minusposten)',
                from: 2980,
                to: 2999,
              },
            ],
          },
        ],
      },
      {
        title: 'Eigenkapital (Einzelunternehmen)',
        from: 2800,
        to: 2999,
        groups: [
          {
            title: 'Eigenkapital',
            from: 2800,
            to: 2999,
            groups: [
              {
                title: 'Eigenkapital zu Beginn des Geschäftsjahres',
                from: 2800,
                to: 2819,
              },
              {
                title: 'Kapitaleinlagen und Kapitalrückzüge',
                from: 2820,
                to: 2849,
              },
              {
                title: 'Privat',
                from: 2850,
                to: 2890,
              },
              {
                title: 'Jahresgewinn oder Jahresverlust',
                from: 2891,
                to: 2999,
              },
            ],
          },
        ],
      },
      {
        title: 'Eigenkapital (Personengesellschaft)',
        from: 2800,
        to: 2999,
        groups: [
          {
            title: 'Eigenkapital',
            from: 2800,
            to: 2999,
            groups: [
              {
                title:
                  'Eigenkapital Gesellschafter A zu Beginn des Geschäftsjahres',
                from: 2800,
                to: 2809,
              },
              {
                title: 'Kapitaleinlagen und Kapitalrückzüge Gesellschafter A',
                from: 2810,
                to: 2819,
              },
              {
                title: 'Privat Gesellschafter A',
                from: 2820,
                to: 2830,
              },
              {
                title: 'Jahresgewinn oder Jahresverlust Gesellschafter A',
                from: 2831,
                to: 2849,
              },
              {
                title:
                  'Eigenkapital Kommanditär A zu Beginn des Geschäftsjahres',
                from: 2850,
                to: 2859,
              },
              {
                title: 'Kapitaleinlagen und Kapitalrückzüge Kommanditär A ',
                from: 2860,
                to: 2869,
              },
              {
                title: 'Privat Kommanditär A',
                from: 2870,
                to: 2880,
              },
              {
                title: 'Jahresgewinn oder Jahresverlust Kommanditär A',
                from: 2881,
                to: 2999,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Betrieblicher Ertrag aus Lieferungen und Leistungen',
    from: 3000,
    to: 3999,
    groups: [
      {
        title: 'Produktionserlöse',
        from: 3000,
        to: 3199,
      },
      {
        title: 'Handelserlöse',
        from: 3200,
        to: 3399,
      },
      {
        title: 'Dienstleistungserlöse',
        from: 3400,
        to: 3599,
      },
      {
        title: 'Übrige Erlöse aus Lieferungen und Leistungen',
        from: 3600,
        to: 3699,
      },
      {
        title: 'Eigenleistungen',
        from: 3700,
        to: 3709,
      },
      {
        title: 'Eigenverbrauch',
        from: 3710,
        to: 3799,
      },
      {
        title: 'Erlösminderungen',
        from: 3800,
        to: 3804,
      },
      {
        title: 'Verluste Forderungen (Debitoren), Veränderung Delkredere',
        from: 3805,
        to: 3899,
      },
      {
        title: 'Bestandesänderungen unfertige Erzeugnisse',
        from: 3900,
        to: 3900,
      },
      {
        title: 'Bestandesänderungen fertige Erzeugnisse',
        from: 3901,
        to: 3939,
      },
      {
        title: 'Bestandesänderungen nicht fakturierte Dienstleistungen',
        from: 3940,
        to: 3999,
      },
    ],
  },
  {
    title: 'Aufwand für Material, Handelswaren, Dienstleistungen und Energie',
    from: 4000,
    to: 4999,
    groups: [
      {
        title: 'Materialaufwand Produktion',
        from: 4000,
        to: 4199,
      },
      {
        title: 'Handelswarenaufwand',
        from: 4200,
        to: 4399,
      },
      {
        title: 'Aufwand für bezogene Dienstleistungen',
        from: 4400,
        to: 4499,
      },
      {
        title: 'Energieaufwand zur Leistungserstellung',
        from: 4500,
        to: 4899,
      },
      {
        title: 'Aufwandminderungen',
        from: 4900,
        to: 4999,
      },
    ],
  },
  {
    title: 'Personalaufwand',
    from: 5000,
    to: 5999,
    groups: [
      {
        title: 'Lohnaufwand',
        from: 5000,
        to: 5699,
      },
      {
        title: 'Sozialversicherungaufwand',
        from: 5700,
        to: 5799,
      },
      {
        title: 'Übriger Personalaufwand',
        from: 5800,
        to: 5899,
      },
      {
        title: 'Leistungen Dritter',
        from: 5900,
        to: 5999,
      },
    ],
  },
  {
    title:
      'Übriger betrieblicher Aufwand, Abschreibungen und Wertberechtigungen sowie Finanzergebnis',
    from: 6000,
    to: 6999,
    groups: [
      {
        title: 'Raumaufwand',
        from: 6000,
        to: 6099,
      },
      {
        title: 'Unterhalt, Reparaturen, Ersatz mobile Sachanlagen',
        from: 6100,
        to: 6104,
      },
      {
        title: 'Leasingaufwand mobile Sachanlagen',
        from: 6105,
        to: 6199,
      },
      {
        title: 'Fahrzeug- und Transportaufwand',
        from: 6200,
        to: 6259,
      },
      {
        title: 'Fahrzeugleasing und -mieten',
        from: 6260,
        to: 6299,
      },

      {
        title: 'Sachversicherungen, Abgaben, Gebühren, Bewilligungen',
        from: 6300,
        to: 6399,
      },
      {
        title: 'Energie- und Entsorgungsaufwand',
        from: 6400,
        to: 6499,
      },
      {
        title: 'Verwaltungsaufwand',
        from: 6500,
        to: 6569,
      },
      {
        title: 'Informatikaufwand inkl. Leasing',
        from: 6570,
        to: 6599,
      },
      {
        title: 'Werbeaufwand',
        from: 6600,
        to: 6699,
      },
      {
        title: 'Sonstiger betrieblicher Aufwand',
        from: 6700,
        to: 6799,
      },
      {
        title:
          'Abschreibungen und Wertberichtigungen auf Positionen des Anlagevermögen',
        from: 6800,
        to: 6899,
      },
      {
        title: 'Finanzaufwand',
        from: 6900,
        to: 6949,
      },
      {
        title: 'Finanzertrag',
        from: 6950,
        to: 6999,
      },
    ],
  },
  {
    title: 'Betrieblicher Nebenerfolg',
    from: 7000,
    to: 7999,
    groups: [
      {
        title: 'Ertrag Nebenbetrieb',
        from: 7000,
        to: 7009,
      },
      {
        title: 'Aufwand Nebenbetrieb',
        from: 7010,
        to: 7499,
      },
      {
        title: 'Ertrag betriebliche Liegenschaft',
        from: 7500,
        to: 7509,
      },
      {
        title: 'Aufwand betriebliche Liegenschaft',
        from: 7510,
        to: 7999,
      },
    ],
  },
  {
    title:
      'Betriebsfremder, ausserordentlicher, einmaliger oder periodenfremder Aufwand und Ertrag',
    from: 8000,
    to: 8999,
    groups: [
      {
        title: 'Betriebsfremder Aufwand',
        from: 8000,
        to: 8099,
      },
      {
        title: 'Betriebsfremder Ertrag',
        from: 8100,
        to: 8499,
      },
      {
        title: 'Ausserordentlicher, einmaliger oder periodenfremder Aufwand',
        from: 8500,
        to: 8509,
      },
      {
        title: 'Ausserordentlicher, einmaliger oder periodenfremder Ertrag',
        from: 8510,
        to: 8899,
      },
      {
        title: 'Direkte Steuern',
        from: 8900,
        to: 8999,
      },
    ],
  },
  {
    title: 'Abschluss',
    from: 9000,
    to: 9999,
    groups: [
      {
        title: 'Jahresgewinn oder Jahresverlust',
        from: 9200,
        to: 9999,
      },
    ],
  },
]

const chartOfAssets = chartOfAccounts.find(
  (group) => group.title === 'Aktiven'
)!.groups

const chartOfLiabilities = chartOfAccounts.find(
  (group) => group.title === 'Passiven'
)!.groups

const chartOfIncome = chartOfAccounts.filter(
  (group) =>
    group.from.toString().startsWith('3') ||
    group.from.toString().startsWith('6') ||
    group.from.toString().startsWith('7') ||
    group.from.toString().startsWith('8')
)

const chartOfExpense = chartOfAccounts.filter(
  (group) =>
    group.from.toString().startsWith('4') ||
    group.from.toString().startsWith('5') ||
    group.from.toString().startsWith('6') ||
    group.from.toString().startsWith('7') ||
    group.from.toString().startsWith('8')
)

export const chartOfAccountsGrabber = (accountType?: AccountType) => {
  if (!accountType) return chartOfAccounts

  switch (accountType) {
    case 'assets':
      return chartOfAssets
    case 'liabilities':
      return chartOfLiabilities
    case 'income':
      return chartOfIncome
    case 'expense':
      return chartOfExpense
  }
}
