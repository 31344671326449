import { VATReport } from 'model/VAT'
import VATFormHeader1 from './VATFormHeader1'
import VATFormHeader2 from './VATFormHeader2'
import VATFormRow from './VATFormRow'

interface VATFormGroupIncomeProps {
  report: VATReport
}

const VATFormGroupNonIncome = (props: VATFormGroupIncomeProps) => (
  <div className='vat-form-group'>
    <VATFormHeader1 title='III. Andere Mitteleinflüsse' />
    <VATFormHeader2 digitColumn1='Ziffer' valueColumn1='Umsatz' />
    <VATFormRow
      title='Subventionen, durch Kurvereine eingenommene Tourismusabgaben, Entsorgungs- und Wasserwerkbeiträge (Bst. a-c)'
      digitColumn1='900'
      value2={props.report['900'].amount}
    />
    <VATFormRow
      title='Spenden, Dividenden, Schadenersatz usw. (Bst. d-l)'
      digitColumn1='910'
      value2={props.report['910'].amount}
    />
  </div>
)

export default VATFormGroupNonIncome
