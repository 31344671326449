import { Heading, Text } from '@nextbusiness/infinity-ui'
import { HELP_CENTER_URL } from './WelcomeCentreHelpMenu'

const WelcomeCentreHelpTip = () => (
  <section className='other-section'>
    <Heading type='h4'>Hilfe & Support</Heading>
    <Text>
      Du kannst jederzeit in Infinity Finance unten rechts unseren Messenger
      öffnen, um Hilfe zu erhalten, oder durchstöbere online unser{' '}
      <a
        href={HELP_CENTER_URL}
        target='_blank'
        rel='noreferrer'
        className='link'
      >
        Hilfecenter
      </a>
      {'.'}
    </Text>
  </section>
)

export default WelcomeCentreHelpTip
