import { IProduct } from '@nextbusiness/infinity-finance-api'
import { Button, Card, RadioOption } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import Utilities from 'utility/Utilities'
import './ProductListItem.scss'

interface ProductListItemProps {
  product: IProduct
  isChecked?: boolean
  isSelectable?: boolean
  onOptionSelected?: () => void
  onEdit?: () => void
}

const ProductListItem = (props: ProductListItemProps) => (
  // Element is keyboard selectable through the radio button
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
  <div
    className={classNames('product-list-item', {
      selectable: props.isSelectable,
    })}
    onClick={props.onOptionSelected}
  >
    <Card className='product-list-item-card' bare>
      <div className='radio'>
        {props.isSelectable && (
          <RadioOption
            className='radio-option'
            isChecked={props.isChecked}
            onChange={(isChecked) => {
              if (isChecked) props.onOptionSelected?.()
            }}
          />
        )}
      </div>
      <div className='depiction'>
        <div className='title'>{props.product.name}</div>
        <div className='article'>{props.product.articleNumber}</div>
        <div className='amount'>
          {Utilities.centsToCHF(props.product.sellingPrice)}{' '}
          <span className='currency'>CHF</span>
        </div>
      </div>
      <div className='actions'>
        <Button
          iconOnly='edit'
          aria-label='Bearbeiten'
          variant='quaternary'
          onClick={(event) => {
            event?.stopPropagation()
            props.onEdit?.()
          }}
          tooltip='Produkt bearbeiten'
        />
      </div>
    </Card>
  </div>
)

export default ProductListItem
