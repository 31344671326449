import { Finance } from '@nextbusiness/infinity-finance-api'
import {
  ButtonProps,
  Dialog,
  KeyValueStore,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import AssistantStepper, {
  AssistantStep,
} from 'documents/financial-closing/assistant/AssistantStepper'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react'
import { IAccount, INewAccount } from 'model/Account'
import { useEffect, useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import { useRootStore } from 'stores/RootStoreContext'
import CategoryStep from './CategoryStep'
import ChecksStep from './ChecksStep'
import './CreateAccountAssistant.scss'
import NameAndColorStep from './NameAndColorStep'
import TypeOfAccountStep from './TypeOfAccountStep'

interface CreateAccountAssistantProps {
  isOpen: boolean
  closeModal: () => void
  onAccountCreated?: (account: IAccount) => void
}

export const accountColours: KeyValueStore = {
  '#F29A29': 'Carrot',
  '#E47400': 'Princeton',
  '#eb3636': 'Cinnabar',
  '#D1366B': 'Ruber',
  '#ed4197': 'Magenta',
  '#B83FAD': 'Byzantine',
  '#8046C5': 'Orchid',
  '#0746C8': 'Royal Blue',
  '#3581AE': 'Azure Blue',
  '#07A2C8': 'Cerulean',
  '#00B5B4': 'Turqouise',
  '#00B583': 'Meadow',
  '#098c70': 'Teal',
  '#388932': 'Forest',
  '#648932': 'Olive',
  '#325467': 'Asphalt',
  '#9ca3b0': 'Concrete',
  '#bac9cb': 'Paper',
}

const steps: AssistantStep[] = [
  { id: 'typeOfAccount', displayTitle: 'Kontoart' },
  { id: 'category', displayTitle: 'Kategorie' },
  { id: 'nameAndColor', displayTitle: 'Name und Farbe' },
  { id: 'checks', displayTitle: 'Überprüfen' },
]

const CreateAccountAssistant = (props: CreateAccountAssistantProps) => {
  const { authenticationStore, accountStore } = useRootStore()
  const notificationCenter = useNotificationCenter()
  const intercom = useIntercom()

  const [isCreating, setIsCreating] = useState<boolean>(false)

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0)

  const [newAccount, setNewAccount] = useState<Partial<INewAccount>>({
    organisation: authenticationStore.organisationIdentifier,
  })

  const currentView = steps[currentStepIndex].id

  const componentForCurrentView = () => {
    switch (currentView) {
      case 'typeOfAccount':
        return (
          <TypeOfAccountStep
            accountType={newAccount.accountType}
            setAccountType={(accountType) =>
              setNewAccount({ ...newAccount, accountType })
            }
          />
        )
      case 'category':
        return (
          <CategoryStep
            newAccount={newAccount}
            setNewAccount={setNewAccount}
            setAccountGroups={(accountGroups) =>
              setNewAccount({ ...newAccount, accountGroups })
            }
          />
        )
      case 'nameAndColor':
        return (
          <NameAndColorStep
            newAccount={newAccount}
            setNewAccount={setNewAccount}
            accountColours={accountColours}
          />
        )
      case 'checks':
        return (
          <ChecksStep
            accountType={newAccount.accountType}
            newAccount={newAccount}
            accountColours={accountColours}
          />
        )
    }
  }

  const hasEnteredRequiredInformation = (): boolean => {
    switch (currentView) {
      case 'typeOfAccount':
        return newAccount.accountType !== undefined
      case 'category':
        return newAccount.accountGroups !== undefined
      case 'nameAndColor':
        return newAccount.name !== undefined && newAccount.name !== ''
      default:
        return false
    }
  }

  const canMoveForward = currentView !== 'checks'
  const canMoveBackward = currentView !== 'typeOfAccount'

  const incrementViewByOne = () => {
    const nextStep = currentStepIndex + 1
    if (canMoveForward) setCurrentStepIndex(nextStep)
  }

  const decrementViewByOne = () => {
    const previousStep = currentStepIndex - 1
    if (canMoveBackward) setCurrentStepIndex(previousStep)
  }

  const cancelButtonAction: ButtonProps = {
    children: 'Abbrechen',
    onClick: () => closeModal(),
    style: {
      marginLeft: '-1rem',
      marginRight: 'auto',
    },
  }

  const backButtonAction: ButtonProps = {
    children: 'Zurück',
    onClick: () => decrementViewByOne(),
  }

  const continueButtonAction: ButtonProps = {
    variant: 'primary',
    children: 'Fortfahren',
    disabled: !hasEnteredRequiredInformation(),
    onClick: () => incrementViewByOne(),
  }

  const createButtonAction: ButtonProps = {
    variant: 'primary',
    children: 'Konto erstellen',
    isLoading: isCreating,
    onClick: () => {
      createAccount(newAccount as INewAccount)
    },
  }

  const buttonsToBeShown = () => {
    switch (currentView) {
      case 'typeOfAccount':
        return [cancelButtonAction, continueButtonAction]
      case 'checks':
        return [cancelButtonAction, backButtonAction, createButtonAction]
      default:
        return [cancelButtonAction, backButtonAction, continueButtonAction]
    }
  }

  const closeModal = () => {
    props.closeModal()
  }

  const createAccount = async (account: INewAccount) => {
    if (!authenticationStore.organisationIdentifier)
      return authenticationStore.logout()

    setIsCreating(true)
    try {
      const createdAccount = await Finance.Ledger.createAccount(account)
      await accountStore.loadAccounts()
      if (props.onAccountCreated) props.onAccountCreated(createdAccount)
      intercom.trackEvent('account-created')
      MixpanelAnalytics.event('Custom account created', {
        accountNumber: createdAccount.accountNumber,
      })
      props.closeModal()
    } catch (error: any) {
      notificationCenter.addNotification({
        title: 'Konto konnte nicht erstellt werden',
        children: error?.message,
        variant: 'error',
      })
    } finally {
      setIsCreating(false)
    }
  }

  useEffect(() => {
    const resetModal = () => {
      setCurrentStepIndex(0)
      setNewAccount({
        organisation: authenticationStore.organisationIdentifier,
      })
    }
    if (props.isOpen) {
      resetModal()
      MixpanelAnalytics.event('New account assistant opened')
    }
  }, [props.isOpen])

  return (
    <Dialog
      isOpen={props.isOpen}
      onDismiss={() => closeModal()}
      className='create-account-assistant'
      title={'Neues Konto'}
      actions={buttonsToBeShown()}
    >
      <AssistantStepper steps={steps} currentStepId={currentView} />
      {componentForCurrentView()}
    </Dialog>
  )
}

export default observer(CreateAccountAssistant)
