import SuggestionStar from 'assets/suggestion-star.svg'
import { ResourceSelectOption, ResourceSelectSection } from './ResourceSelect'

interface ResourceSelectSegmentProps<ValueType, ItemType> {
  section: ResourceSelectSection<ValueType, ItemType>
  sectionIndex: number
  renderOption: (
    option: ResourceSelectOption<ValueType, ItemType>,
    optionIndex: number,
    sectionIndex: number
  ) => React.ReactNode
}

const ResourceSelectSegment = <ValueType, ItemType>(
  props: ResourceSelectSegmentProps<ValueType, ItemType>
) => {
  if (!props.section.options.length) return null
  return (
    <div key={props.section.title}>
      {props.section.dividerAbove && (
        <div className='divider section-divider' />
      )}
      {props.section.isSuggestedSection ? (
        <div className='group-heading suggested-group'>
          <img className='star' src={SuggestionStar} alt='' />
          {props.section.title}
        </div>
      ) : (
        <div className='group-heading'>{props.section.title}</div>
      )}
      {props.section.options.map((option, index) =>
        props.renderOption(option, index, props.sectionIndex)
      )}
    </div>
  )
}

export default ResourceSelectSegment
