import Filter from 'base-components/table/Filter'
import { useCustomerInvoicesFilter } from './CustomerInvoicesFilterContext'

const CustomerInvoiceStatusFilter = () => {
  const { activeFilter, editFilter } = useCustomerInvoicesFilter()
  return (
    <Filter
      icon='checked-checkbox'
      label='Status'
      items={[
        { label: 'Offen', value: 'unpaid', icon: 'bill' },
        { label: 'Bezahlt', value: 'paid', icon: 'paid-bill' },
        { label: 'Storniert', value: 'cancelled', icon: 'error' },
      ]}
      selectedValue={activeFilter.status}
      onValueSelected={(status) => editFilter({ status })}
    />
  )
}

export default CustomerInvoiceStatusFilter
