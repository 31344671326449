import { IDefaultPresets } from 'model/Preset'

const DEFAULT_PRESETS: IDefaultPresets = {
  freelancing: [
    {
      id: 'freelancing-photography',
      type: 'freelancing',
      title: 'Fotografie',
      description: 'Offerte für Fotoshoot',
      invoice: { title: 'Fototermin' },
    },
    {
      id: 'freelancing-webdesign',
      type: 'freelancing',
      title: 'Webdesign',
      description: 'Erstellung einer Website',
      invoice: { title: 'Webdesign' },
    },
    {
      id: 'freelancing-branding',
      type: 'freelancing',
      title: 'Branding',
      description: 'Branding-Projekt',
      invoice: { title: 'Branding' },
    },
    {
      id: 'freelancing-social-media',
      type: 'freelancing',
      title: 'Social Media',
      description: 'Social Media-Kampagne',
      invoice: { title: 'Social Media' },
    },
  ],
  consulting: [
    {
      id: 'consulting-business-strategy',
      type: 'consulting',
      title: 'Business Consulting',
      description: 'Marktanalyse & Business-Plan',
      invoice: { title: 'Strategische Beratung' },
    },
    {
      id: 'consulting-financial',
      type: 'consulting',
      title: 'Finanzberatung',
      description: 'Finanzcoaching und Steuern',
      invoice: { title: 'Finanzberatung' },
    },
    {
      id: 'consulting-it',
      type: 'consulting',
      title: 'IT-Beratung',
      description: 'IT-Analyse und -Beratung',
      invoice: { title: 'IT-Beratung' },
    },
    {
      id: 'consulting-marketing',
      type: 'consulting',
      title: 'Marketingberatung',
      description: 'Kampagnenberatung',
      invoice: { title: 'Marketingberatung' },
    },
    {
      id: 'consulting-legal',
      type: 'consulting',
      title: 'Rechtsberatung',
      description: 'Contract Review und Beratung',
      invoice: { title: 'Rechtsberatung' },
    },
  ],
  services: [
    {
      id: 'services-landscaping',
      type: 'services',
      title: 'Landschaftsgestaltung',
      description: 'Gartenpflege',
      invoice: { title: 'Landschaftsgestaltung' },
    },
    {
      id: 'services-electrical',
      type: 'services',
      title: 'Elektroarbeiten',
      description: 'Elektroinstallation',
      invoice: { title: 'Elektroarbeiten' },
    },
    {
      id: 'services-pet-care',
      type: 'services',
      title: 'Tierbetreuung',
      description: 'Betreuung von Haustieren',
      invoice: { title: 'Tierbetreuung' },
    },
    {
      id: 'services-cybersecurity',
      type: 'services',
      title: 'Cybersecurity-Audit',
      description: 'Review der IT-Sicherheit',
      invoice: { title: 'Cybersecurity-Audit' },
    },
  ],
}

export default DEFAULT_PRESETS
