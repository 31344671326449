import './WelcomeCentreAdditionalResources.scss'
import WelcomeCentreFeedback from './WelcomeCentreFeedback'
import WelcomeCentreGuides from './WelcomeCentreGuides'
import WelcomeCentreHelpTip from './WelcomeCentreHelpTip'

const WelcomeCentreAdditionalResources = () => (
  <div className='welcome-centre-additional-resources'>
    <WelcomeCentreGuides />
    <WelcomeCentreHelpTip />
    <WelcomeCentreFeedback />
  </div>
)

export default WelcomeCentreAdditionalResources
