import { motion } from 'framer-motion'
import React from 'react'

interface BankAccountSetupStepProps {
  children: React.ReactNode
}

const BankAccountSetupStep = (props: BankAccountSetupStepProps) => (
  <motion.div
    initial={{ translateY: 40, opacity: 0 }}
    animate={{ translateY: 0, opacity: 1 }}
    transition={{ ease: [0, 0.55, 0.45, 1], duration: 0.6, delay: 0.2 }}
  >
    {props.children}
  </motion.div>
)

export default BankAccountSetupStep
