import ContactSelectOptionAvatar from 'components/contact-select/ContactSelectOptionAvatar'
import ResourceOption, {
  ResourceOptionProps,
} from 'components/resource-select/ResourceOption'
import { observer } from 'mobx-react'
import { ICustomerInvoicePreview } from 'model/CustomerInvoice'
import { useRootStore } from 'stores/RootStoreContext'
import DateUtilities from 'utility/DateUtilites'
import Utilities from 'utility/Utilities'

interface CustomerInvoiceSelectOptionProps {
  customerInvoice: ICustomerInvoicePreview
  optionProps: ResourceOptionProps<string, ICustomerInvoicePreview>
}

const CustomerInvoiceSelectOption = (
  props: CustomerInvoiceSelectOptionProps
) => {
  const { contactStore } = useRootStore()
  const contact = contactStore.allContacts.find(
    (contact) => contact.id === props.customerInvoice.recipient
  )
  const amount = `CHF ${Utilities.centsToCHF(
    props.customerInvoice.totalAmount
  )}`
  const date = DateUtilities.humanFormatNumeric(
    props.customerInvoice.openingDate
  )
  return (
    <ResourceOption
      {...props.optionProps}
      leftAccessory={<ContactSelectOptionAvatar contact={contact} />}
      description={`${date} · ${amount}`}
    />
  )
}

export default observer(CustomerInvoiceSelectOption)
