import Lockup from 'components/text/Lockup'
import TransactionLiveAcountingDetailProps from './TransactionLiveAcountingDetailProps'

const CommonPaymentFamilyCodes: Record<string, string> = {
  RCDT: 'Erhaltene Zahlung',
  ICDT: 'Zahlungsauftrag',
  RDDT: 'Empfangene Lastschrift',
  IDDT: 'Ausgestellte Lastschrift',
  CCRD: 'Kartenzahlung',
  MCRD: 'Kartenzahlung',
  CNTR: 'Schaltertransaktion',
}

const CommonIssuedPaymentSubfamilyCodes: Record<string, string> = {
  BOOK: 'Übertrag',
  STDO: 'Dauerauftrag',
  XBST: 'Grenzüberschreitender Dauerauftrag',
  ESCT: 'SEPA-Überweisung',
  DMCT: 'Inlandüberweisung',
  SALA: 'Lohnzahlung',
  AUTT: 'Automatische Überweisung',
}

const LiveAccountingTransactionKind = (
  props: TransactionLiveAcountingDetailProps
) => {
  if (!props.details.bankTransactionCode?.domainCode) return null

  const domainCode = props.details.bankTransactionCode?.domainCode
  const familyCode = props.details.bankTransactionCode?.familyCode
  const subfamilyCode = props.details.bankTransactionCode?.subFamilyCode

  let transactionKind: string | undefined

  if (domainCode === 'PMNT' && familyCode) {
    transactionKind = CommonPaymentFamilyCodes[familyCode]
    if (familyCode === 'ICDT' && subfamilyCode) {
      transactionKind =
        CommonIssuedPaymentSubfamilyCodes[subfamilyCode] ?? transactionKind
    }
  }

  if (!transactionKind) return null

  return (
    <Lockup icon='info' tooltip='Art der Bankbewegung' small subtle truncate>
      {transactionKind}
    </Lockup>
  )
}

export default LiveAccountingTransactionKind
