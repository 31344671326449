import { Button, Tooltip } from '@nextbusiness/infinity-ui'
import { IconSize } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import SpecialAccounts from 'components/transactions/SpecialAccounts'
import { useLedgerContext } from 'ledger/LedgerContext'
import { isDefinedAccount } from 'ledger/accounts/data/AccountRules'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import { truncateString } from 'utility/Utilities'

interface TransactionAccountButtonProps {
  displayedAccount: number
  interactive?: boolean
}

const TransactionAccountButton = (props: TransactionAccountButtonProps) => {
  const ledgerContext = useLedgerContext()
  const { accountStore, preferencesStore, ledgerStore } = useRootStore()

  const contraAccount =
    accountStore.find(props.displayedAccount) ??
    SpecialAccounts.find(props.displayedAccount)
  const isViewingDisabled =
    contraAccount &&
    !isDefinedAccount(contraAccount) &&
    contraAccount.viewingDisabled

  const accountName = contraAccount?.name ?? `Konto ${props.displayedAccount}`
  const trunactedAccountName = truncateString(accountName, 19)
  const isTruncated = trunactedAccountName !== accountName

  const onOpenAccount = (event?: React.MouseEvent) => {
    event?.stopPropagation()
    ledgerStore.setNewestView(
      ledgerContext.openAccountPageModally(props.displayedAccount)
    )
  }

  return (
    <div
      className={classNames(
        'account',
        preferencesStore.showAccountNumbers && 'with-number'
      )}
    >
      <div
        className='colour-swatch'
        style={{ backgroundColor: contraAccount?.colour ?? 'grey' }}
      />
      {preferencesStore.showAccountNumbers && props.displayedAccount !== 0 && (
        <div className='account-number'>{contraAccount?.accountNumber}</div>
      )}
      {isTruncated ? (
        <Tooltip useChildAsTrigger content={accountName}>
          <span className='account-name'>{trunactedAccountName}</span>
        </Tooltip>
      ) : (
        <span className='account-name'>{accountName}</span>
      )}
      {!isViewingDisabled && props.interactive && (
        <Button
          className='open-account-action'
          iconOnly='enlarge'
          iconSize={IconSize.Tiny}
          onClick={onOpenAccount}
          tooltip={`Konto ${accountName} öffnen`}
          variant='quaternary'
        />
      )}
    </div>
  )
}

export default observer(TransactionAccountButton)
