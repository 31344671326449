import Lozenge from 'components/lozenge/Lozenge'

interface CurrencyListRateProps {
  exchangeRate: number
  basisUnit: number
  syncAutomatically: boolean
}

const CurrencyListRate = (props: CurrencyListRateProps) => {
  const syncAuto = props.syncAutomatically

  return (
    <div className='currency-list-item-rate'>
      <div className='currency-list-item-rate-frame'>
        {`${props.basisUnit} = ${props.exchangeRate} `}
        <div className='currency-list-item-currency'>CHF</div>
      </div>
      <div className='currency-list-item-lozenge-wrapper'>
        <Lozenge
          tint={syncAuto ? 'lavender' : 'concrete'}
          normalCasing
          iconLeft={syncAuto ? 'available-updates' : undefined}
          small={false}
        >
          {syncAuto ? 'ESTV' : 'Manuell'}
        </Lozenge>
      </div>
    </div>
  )
}

export default CurrencyListRate
