class AuthenticationCredentials {
  public readonly sessionToken: string
  public readonly applicationAccessToken: string
  public readonly userDataToken: string
  public readonly hmac: string

  constructor(
    sessionToken: string,
    applicationAccessToken: string,
    userDataToken: string,
    hmac: string
  ) {
    this.sessionToken = sessionToken
    this.applicationAccessToken = applicationAccessToken
    this.userDataToken = userDataToken
    this.hmac = hmac
  }

  public get authHeader(): string {
    return `Bearer ${this.sessionToken}`
  }
}

export default AuthenticationCredentials
