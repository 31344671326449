import { useState } from 'react'

interface BareMoneyFieldProps {
  value?: string
  onChange?: (value: string) => void
  className?: string
  placeholder?: string
  disabled?: boolean
  style?: React.CSSProperties
}

const BareMoneyField = (props: BareMoneyFieldProps) => {
  const number = /\d+\.?\d*/
  const unfinishedDecimal = /\d*\.0{0,2}(?!.)/

  const clean = (amount: string) =>
    amount.replaceAll(',', '').replaceAll("'", '')

  const truncatedFloat = (amount: string) =>
    Math.round(parseFloat(clean(amount)) * 100) / 100

  const formatCHF = (amount: string, isInitialFormatting?: boolean) => {
    const currencisedAmount = new Intl.NumberFormat('en-EN', {
      minimumFractionDigits: isInitialFormatting ? 2 : undefined,
      maximumFractionDigits: 2,
    })
      .format(truncatedFloat(amount))
      .replaceAll(',', "'")

    if (currencisedAmount === 'NaN') return ''

    if (RegExp(/[.][1-9]0/).exec(amount)) {
      return currencisedAmount.concat('0')
    }

    return currencisedAmount
  }

  const [displayValue, setDisplayValue] = useState<string>(
    formatCHF(props.value?.toString() ?? '', true)
  )

  return (
    <input
      {...props}
      value={displayValue}
      onChange={
        props.onChange
          ? (event: React.ChangeEvent<HTMLInputElement>) => {
              const enteredValue = event.target.value
              if (enteredValue === '') {
                setDisplayValue(enteredValue)
                props.onChange!('')
              } else if (
                (RegExp(number).exec(enteredValue) || enteredValue === '.') &&
                !RegExp(/\.+\d*\.+/).exec(enteredValue)
              ) {
                const decimalPosition = enteredValue.indexOf('.')
                const hasDecimalPoint = decimalPosition !== -1
                const hasTwoDecimals =
                  hasDecimalPoint &&
                  enteredValue.substring(decimalPosition + 1).length > 2
                if (hasTwoDecimals) return
                const isUnsafeInteger =
                  parseFloat(clean(enteredValue)) > Number.MAX_SAFE_INTEGER
                if (isUnsafeInteger) return
                if (
                  isNaN(parseFloat(clean(enteredValue))) ||
                  RegExp(unfinishedDecimal).exec(enteredValue)
                ) {
                  setDisplayValue(enteredValue)
                  props.onChange!(clean(enteredValue))
                } else {
                  setDisplayValue(formatCHF(enteredValue))
                  props.onChange!(truncatedFloat(enteredValue).toString())
                }
              }
            }
          : undefined
      }
    />
  )
}

export default BareMoneyField
