import { useMemo } from 'react'
import DocumentRendererContext, {
  IDocumentRendererContext,
} from './DocumentRendererContext'

type DocumentRendererContextProviderProps = IDocumentRendererContext & {
  children: React.ReactNode
}

const DocumentRendererContextProvider: React.FC<
  DocumentRendererContextProviderProps
> = (props) => {
  const contextValue = useMemo(
    () => ({ ...props }),
    [
      props.heights,
      props.tableColumns,
      props.setPageHeight,
      props.setPositionTableMargin,
      props.setFooterHeight,
      props.updateHeights,
      props.updatePositionHeights,
      props.deletePositionHeight,
    ]
  )
  return (
    <DocumentRendererContext.Provider value={contextValue}>
      {props.children}
    </DocumentRendererContext.Provider>
  )
}

export default DocumentRendererContextProvider
