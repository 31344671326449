import { Flex } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import { useAccountPageContext } from 'ledger/accounts/AccountPageContext'
import { useAccountSheetContext } from 'ledger/accounts/AccountSheetContext'
import { observer } from 'mobx-react'
import { useEffect, useRef } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { useBatchEditingContext } from '../batch-editing/BatchEditingContext'

export const COOLDOWN_AFTER_PAGINATION_REHYDRATION = 150

interface TransactionListScrollViewProps {
  paginationControl?: React.ReactNode
}

const TransactionListScrollView = (
  props: React.PropsWithChildren<TransactionListScrollViewProps>
) => {
  const { transactionStore } = useRootStore()

  const transactionListRef = useRef<HTMLDivElement>(null)
  const batchEditingContext = useBatchEditingContext()
  const { loadTransactions, currentAccount } = useAccountPageContext()
  const { setHasScrolled, setIsHeaderCollapsed, setHasListReachedBottom } =
    useAccountSheetContext()

  const paginationControlRef = useRef<HTMLDivElement>(null)
  const lastScrollPositionY = useRef<number>(0)

  const accountNumber = currentAccount.accountNumber

  const filteredTransactions =
    transactionStore.filteredTransactions[accountNumber]
  const allTransactions = transactionStore.transactions[accountNumber]

  const handlePagination = async () => {
    const paginationControl = paginationControlRef.current
    if (
      paginationControl &&
      window.innerHeight - paginationControl.getBoundingClientRect().top > 0
    ) {
      await loadTransactions()
    }
  }

  useEffect(() => {
    window.setTimeout(() => {
      handlePagination()
    }, COOLDOWN_AFTER_PAGINATION_REHYDRATION)
  }, [filteredTransactions?.length, allTransactions?.length])

  useEffect(() => {
    const transactionList = transactionListRef.current

    const handleScroll = () => {
      if (transactionList && transactionList.scrollTop > 0) setHasScrolled(true)
      else setHasScrolled(false)

      const reachedBottom =
        transactionList!.scrollTop + transactionList!.offsetTop >=
        transactionList!.scrollHeight - transactionList!.clientHeight
      setHasListReachedBottom(reachedBottom)

      if (
        transactionList!.scrollTop > lastScrollPositionY.current &&
        transactionList!.scrollTop > 1
      ) {
        setIsHeaderCollapsed(true)
      } else {
        setIsHeaderCollapsed(false)
      }
      lastScrollPositionY.current = transactionList!.scrollTop
    }

    transactionList?.addEventListener('scroll', handleScroll)
    transactionList?.addEventListener('scroll', handlePagination)

    return () => {
      transactionList?.removeEventListener('scroll', handleScroll)
      transactionList?.removeEventListener('scroll', handlePagination)
    }
  }, [])

  return (
    <div
      className={classNames('magic-sheet-view transaction-list', {
        'multi-edit-view': batchEditingContext.isInMultiEdit,
      })}
      ref={transactionListRef}
    >
      {props.children}
      <Flex
        className='pagination-control'
        ref={paginationControlRef}
        horizontalAlignment='center'
        verticalAlignment='center'
      >
        {props.paginationControl}
      </Flex>
    </div>
  )
}

export default observer(TransactionListScrollView)
