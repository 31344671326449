import React from 'react'

interface VATFormHeader2Props {
  title?: string
  digitColumn1?: string
  digitColumn2?: string
  valueColumn1?: string
  valueColumn2?: string
}

const VATFormHeader2 = (props: VATFormHeader2Props) => (
  <div className='form-header-2'>
    <div className='title'>{props.title}</div>
    <div className='digit-column-1'>{props.digitColumn1}</div>
    <div className='value-column-1'>{props.valueColumn1}</div>
    <div className='value-column-2'>{props.valueColumn2}</div>
    <div className='digit-column-2'>{props.digitColumn2}</div>
  </div>
)

export default VATFormHeader2
