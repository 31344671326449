import { ProjectStageColour } from '@nextbusiness/infinity-finance-api'
import { Icon, IconItem } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import './Lozenge.scss'

type LozengeColour = ProjectStageColour | `${ProjectStageColour}` | 'default'

interface LozengeProps {
  children?: React.ReactNode
  tint?: LozengeColour
  prominent?: boolean
  small?: boolean
  inline?: boolean
  normalCasing?: boolean
  iconLeft?: IconItem
}

const Lozenge = (props: LozengeProps) => (
  <small
    className={classNames(
      'lozenge',
      props.normalCasing && 'normal-casing',
      props.tint,
      props.prominent && 'prominent',
      props.small && 'small',
      props.inline && 'inline'
    )}
  >
    {props.iconLeft && (
      <Icon icon={props.iconLeft} className='lozenge-icon' size={20} />
    )}
    {props.children}
  </small>
)

export default Lozenge
