import {
  APIError,
  Finance,
  ICurrency,
  ICurrencyChanges,
} from '@nextbusiness/infinity-finance-api'
import { useNotificationCenter } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import EditOrCreateCurrencyModal from './EditOrCreateCurrencyModal'

interface EditCurrencyModalProps {
  isOpen: boolean
  onDismiss: () => void
  currencyToBeEdited: ICurrency
}

const EditCurrencyModal = (props: EditCurrencyModalProps) => {
  const { currencyStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const editCurrency = async (
    id: string,
    currencyChanges: ICurrencyChanges
  ): Promise<boolean> => {
    try {
      await Finance.Currencies.updateCurrency(id, currencyChanges)
      await currencyStore.loadCurrencies()
      return true
    } catch (error: any) {
      notificationCenter.addNotification({
        children: (error as APIError).humanText('de'),
        variant: 'error',
      })
      return false
    }
  }

  return (
    <EditOrCreateCurrencyModal
      titleOfModal='Währung bearbeiten'
      saveActionCopy='Speichern'
      onSave={(currencyChanges: ICurrencyChanges) => {
        return editCurrency(props.currencyToBeEdited.id, currencyChanges)
      }}
      {...props}
    />
  )
}

export default observer(EditCurrencyModal)
