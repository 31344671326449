import { APIError, IProduct } from '@nextbusiness/infinity-finance-api'
import ErrorScreen from 'base-components/layout/ErrorScreen'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import './ProductsList.scss'
import ProductsListActions from './ProductsListActions'
import ProductsSearchableList from './ProductsSearchableList'

export interface ProductsListProps {
  isSelectable?: boolean
  selectedProduct?: IProduct | null
  onProductSelected?: (product: IProduct | null) => void
  actions?: React.ReactNode
}

const ProductsList = (props: ProductsListProps) => {
  const { productStore } = useRootStore()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loadingError, setLoadingError] = useState<APIError>()

  const allProducts = productStore.products

  const loadProducts = async () => {
    setIsLoading(true)
    setLoadingError(undefined)
    try {
      await productStore.loadAllProducts()
    } catch (error) {
      setLoadingError(error as APIError)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadProducts()
  }, [])

  return (
    <div className='products-list'>
      {isLoading && !allProducts.length ? (
        <LoadingScreen className='products-loading' />
      ) : loadingError ? (
        <ErrorScreen
          title='Produkte können nicht geladen werden'
          error={loadingError}
          onRetry={loadProducts}
          small
          contained
        />
      ) : (
        <ProductsSearchableList {...props} allProducts={allProducts} />
      )}
      <ProductsListActions>{props.actions}</ProductsListActions>
    </div>
  )
}

export default observer(ProductsList)
