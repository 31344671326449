import { BackendError, ErrorType } from '../libs/networking/Errors'
import { IBalanceSheet, IIncomeStatement } from '../model/Report'
import { Current } from './Authentication'
import NetworkingConstants from './NetworkingConstants'
import NetworkingErrorHandler from './NetworkingErrorHandler'

const getBalanceSheet = async (
  organisationIdentifier: string,
  date: number
): Promise<IBalanceSheet> => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationIdentifier}/date/${date}/balanceSheet`,
    { method: 'GET', headers: Current.defaultHeaders }
  )
  const body = await response.json()

  if (!response.ok || !body.balanceSheet) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.balanceSheet as IBalanceSheet
}

const getIncomeStatement = async (
  organisationIdentifier: string,
  from: number,
  to: number
): Promise<IIncomeStatement> => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationIdentifier}/from/${from}/to/${to}/incomeStatement`,
    { method: 'GET', headers: Current.defaultHeaders }
  )
  const body = await response.json()

  if (!response.ok || !body.incomeStatement) {
    switch (body.status) {
      case 500:
        throw new BackendError(ErrorType.ServerError, body.message)
      default:
        NetworkingErrorHandler.throwFromResponse(response, body)
    }
  }
  return body.incomeStatement as IIncomeStatement
}

const Reports = {
  getBalanceSheet,
  getIncomeStatement,
}

export default Reports
