import {
  Button,
  Flyout,
  KeyValueStore,
  LoadingIndicator,
  MenuItems,
} from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import contactDialogStore from 'stores/ContactDialogStore'
import ContactPicker from '../../../../../contacts/ContactPicker'
import useBackendQuery from '../../../../../libs/networking/BackendQuery'
import Contacts from '../../../../../networking/Contacts'
import { useRootStore } from '../../../../../stores/RootStoreContext'
import DocumentElementProps from './DocumentElementProps'
import './DocumentElementRecipient.scss'

const DocumentElementRecipient = (props: DocumentElementProps) => {
  const {
    setIsContactsOpen,
    isContactsOpen,
    setActiveContact,
    setDraftContact,
    setMode,
  } = contactDialogStore
  const [isOptionsFlyoutOpen, setIsOptionsFlyoutOpen] = useState<boolean>(false)

  const { authenticationStore, contactStore } = useRootStore()
  const [fullContact, isLoading, error, invalidateQuery] = useBackendQuery(
    async () => {
      if (!props.document.recipient) return undefined
      return Contacts.getContact(
        authenticationStore.fabricOrganisationIdentifier!,
        props.document.recipient,
        authenticationStore.applicationAccessToken!
      )
    }
  )

  useEffect(() => {
    invalidateQuery()
  }, [props.document.recipient])

  useEffect(() => {
    // Reload should only be forced on close (it could be that the contact has been
    // edited in the contact dialog).
    if (!isContactsOpen) invalidateQuery()
  }, [isContactsOpen])

  if (!props.document.recipient)
    return (
      <div className='envelope-recipient unset'>
        <ContactPicker
          disabled={!props.isEditable}
          setContactId={(recipient) =>
            props.registerEdit({ recipient: recipient ?? '' })
          }
          customView={
            <>
              <Icon icon='contacts' size={20} />
              <span>Empfänger auswählen</span>
            </>
          }
        />
      </div>
    )

  const name = contactStore.displayNameForContactWithId(
    props.document.recipient
  )

  if (isLoading)
    return (
      <div className='envelope-recipient'>
        <LoadingIndicator />
      </div>
    )

  const hasFirstOrLastName = !!fullContact?.firstname || !!fullContact?.lastname

  const recipientView = (
    <Button
      disabled={!props.isEditable}
      onClick={() => setIsOptionsFlyoutOpen(!isOptionsFlyoutOpen)}
      variant='quaternary'
      aria-label='Empfänger ändern'
      className='change-recipient-button'
      tooltip='Empfänger ändern oder bearbeiten'
    >
      {error ? (
        'Empfänger nicht gefunden. Möglicherweise wurde der Kontakt gelöscht. Klicke, um einen neuen Kontakt auszuwählen.'
      ) : (
        <>
          {fullContact?.formOfAddress && (
            <span>{fullContact.formOfAddress}</span>
          )}
          {hasFirstOrLastName && <span>{fullContact?.companyName}</span>}
          <span>{name}</span>
          <span>{fullContact?.address}</span>
          <span>{fullContact?.secondaryAddressLine}</span>
          <span>{fullContact?.postCode}</span>
          {fullContact?.country && <span>{fullContact.country}</span>}
        </>
      )}
    </Button>
  )

  return (
    <Flyout
      className='envelope-recipient'
      isActive={isOptionsFlyoutOpen}
      setIsActive={setIsOptionsFlyoutOpen}
      trigger={recipientView}
      triggerIsButton
    >
      <ContactPicker
        setContactId={(recipient) =>
          props.registerEdit({ recipient: recipient ?? '' })
        }
        hideInterface
      />
      <MenuItems
        closeMenu={() => setIsOptionsFlyoutOpen(false)}
        groups={[
          {
            title: '',
            buttons: [
              {
                icon: 'edit',
                text: 'Kontakt bearbeiten',
                onClick: () => {
                  setIsContactsOpen(true)
                  queueMicrotask(() => {
                    if (!fullContact) return
                    setActiveContact(fullContact)
                    setDraftContact(fullContact as unknown as KeyValueStore)
                    setMode('editing')
                  })
                },
              },
              {
                icon: 'contacts',
                text: 'Kontakt wechseln',
                onClick: () => {
                  setIsContactsOpen(true)
                  queueMicrotask(() => setActiveContact(fullContact))
                },
              },
              {
                icon: 'link-broken',
                text: 'Auswahl entfernen',
                onClick: () => props.registerEdit({ recipient: '' }),
              },
            ],
          },
        ]}
      />
    </Flyout>
  )
}

export default observer(DocumentElementRecipient)
