import { Button, Card, Heading, Text } from '@nextbusiness/infinity-ui'

import './ReportCard.scss'

interface ReportCardProps {
  iconURL: string
  title: string
  description?: string
  onClick: () => void
  tintColor: string
  badge?: boolean
}

const ReportCard = (props: ReportCardProps) => (
  <Button variant='shell' onClick={props.onClick} className='report-card'>
    <Card elevation='low'>
      <div style={{ backgroundColor: props.tintColor }} className='tint-area' />
      <img src={props.iconURL} alt='' />
      <div>
        <Heading type='h2' className={props.badge ? 'with-badge' : ''}>
          {props.title}
        </Heading>
      </div>
      {props.description && (
        <Text className='description'>{props.description}</Text>
      )}
    </Card>
  </Button>
)

export default ReportCard
