import TransactionAmount from 'components/transactions/TransactionAmount'
import { useAccountPageContext } from 'ledger/accounts/AccountPageContext'
import AccountUtilities from 'utility/AccountUtilities'
import { useTransactionEditorContext } from '../TransactionEditorContext'

const TransactionEditorNetAmount = () => {
  const { currentAccount } = useAccountPageContext()
  const { originalTransaction } = useTransactionEditorContext()

  return (
    <div className='amount-net'>
      <small>Netto</small>
      <TransactionAmount
        amount={originalTransaction.amount}
        shouldDisplayNegativeAmount={AccountUtilities.shouldDisplayNegativeAmount(
          currentAccount,
          originalTransaction
        )}
      />
    </div>
  )
}

export default TransactionEditorNetAmount
