import { action, makeObservable, observable } from 'mobx'
import { persist } from 'mobx-persist'
import { AccountType } from '../model/Account'
import RootStore from './RootStore'
import Store from './Store'

export default class LedgerStore extends Store {
  public static INITIAL_DEFAULT_ROOT_VIEW = 1020
  public static MAX_RECENTS = 4

  @persist @observable currentRootView: number =
    LedgerStore.INITIAL_DEFAULT_ROOT_VIEW
  @persist('list') @observable recentAccountNumbers: number[] = [
    LedgerStore.INITIAL_DEFAULT_ROOT_VIEW,
  ]

  @observable unsavedEdits: Set<string> = new Set<string>()
  @observable unpostedTransactions: Set<string> = new Set<string>()
  @observable freshlyAddedView = 'rootView'
  @observable abortIntent?: 'root view' | 'previous view' = undefined
  @observable accountTypeBeingCreated?: AccountType

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  @action
  reset() {
    this.currentRootView = LedgerStore.INITIAL_DEFAULT_ROOT_VIEW
    this.recentAccountNumbers = [LedgerStore.INITIAL_DEFAULT_ROOT_VIEW]
  }

  @action
  setCurrentRootView(accountNumber: number) {
    this.currentRootView = accountNumber
    this.addRecentAccountNumber(accountNumber)
  }

  @action
  public removeFromRecents(accountNumber: number) {
    this.recentAccountNumbers = this.recentAccountNumbers.filter(
      (num: number) => num !== accountNumber
    )
  }

  private addRecentAccountNumber(accountNumber: number) {
    const updatedRecents = this.recentAccountNumbers.filter(
      (num: number) => num !== accountNumber
    )
    updatedRecents.unshift(accountNumber)
    this.recentAccountNumbers = updatedRecents.slice(0, LedgerStore.MAX_RECENTS)
  }

  @action
  setAbortIntent(isAborting: 'root view' | 'previous view' | undefined) {
    this.abortIntent = isAborting
  }

  canNavigateToRoot(): boolean {
    const allChanges: Set<string> = new Set<string>()

    this.unsavedEdits.forEach((viewId: string) => allChanges.add(viewId))
    this.unpostedTransactions.forEach((viewId: string) =>
      allChanges.add(viewId)
    )

    return (
      allChanges.size === 0 ||
      (allChanges.size === 1 && allChanges.has('rootView'))
    )
  }

  @action
  addUnsavedEdit(viewId: string) {
    this.unsavedEdits.add(viewId)
  }

  @action
  resolveUnsavedEdit(viewId: string) {
    this.unsavedEdits.delete(viewId)
  }

  @action
  addUnpostedTransaction(viewId: string) {
    this.unpostedTransactions.add(viewId)
  }

  @action
  resolveUnpostedTransaction(viewId: string) {
    this.unpostedTransactions.delete(viewId)
  }

  hasUnsavedChanges(viewId?: string): boolean {
    if (viewId)
      return (
        this.unsavedEdits.has(viewId) || this.unpostedTransactions.has(viewId)
      )
    else return this.unsavedEdits.size > 0 || this.unpostedTransactions.size > 0
  }

  @action
  clearUnsavedChanges(viewId?: string) {
    if (viewId) {
      this.resolveUnsavedEdit(viewId)
      this.resolveUnpostedTransaction(viewId)
    } else {
      this.unsavedEdits.clear()
      this.unpostedTransactions.clear()
    }
  }

  @action
  clearAllExceptRoot() {
    const rootHadChanges = this.unsavedEdits.has('rootView')
    const rootHadUnposted = this.unpostedTransactions.has('rootView')

    this.unsavedEdits.clear()
    this.unpostedTransactions.clear()

    if (rootHadChanges) this.unsavedEdits.add('rootView')
    if (rootHadUnposted) this.unpostedTransactions.add('rootView')
  }

  @action
  setNewestView(newestView: string) {
    this.freshlyAddedView = newestView
  }

  @action
  setAccountTypeBeingCreated(accountType?: AccountType) {
    this.accountTypeBeingCreated = accountType
  }
}
