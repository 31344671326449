import { IFiscalYear, IFiscalYearCreationOptions } from '../model/FiscalYear'
import { Current } from './Authentication'
import NetworkingConstants from './NetworkingConstants'
import NetworkingErrorHandler from './NetworkingErrorHandler'

const getFiscalYear = async (
  organisationIdentifier: string
): Promise<IFiscalYear> => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationIdentifier}/currentFiscalYear`,
    { method: 'GET', headers: Current.defaultHeaders }
  )
  const body = await response.json()

  if (!response.ok || !body.fiscalYear) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.fiscalYear as IFiscalYear
}

const createFiscalYear = async (
  organisationIdentifier: string,
  fiscalYear: IFiscalYearCreationOptions
): Promise<IFiscalYear> => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationIdentifier}/fiscalYear`,
    {
      method: 'POST',
      headers: Current.defaultHeaders,
      body: JSON.stringify(fiscalYear),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.fiscalYear) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.fiscalYear as IFiscalYear
}

const listAllFiscalYears = async (
  organisationIdentifier: string
): Promise<IFiscalYear[]> => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationIdentifier}/fiscalYears`,
    {
      headers: Current.defaultHeaders,
    }
  )
  const body = await response.json()

  if (!response.ok || !body.fiscalYears) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.fiscalYears as IFiscalYear[]
}

const FiscalYears = {
  getFiscalYear,
  createFiscalYear,
  listAllFiscalYears,
}

export default FiscalYears
