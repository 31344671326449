import Lockup from 'components/text/Lockup'
import TransactionLiveAcountingDetailProps from './TransactionLiveAcountingDetailProps'

const LiveAccountingRemittanceInfo = (
  props: TransactionLiveAcountingDetailProps
) => {
  if (!props.details.remittanceInformation) return null

  return (
    <Lockup icon='get-quote' tooltip='Mitteilung' small subtle truncate>
      {props.details.remittanceInformation}
    </Lockup>
  )
}

export default LiveAccountingRemittanceInfo
