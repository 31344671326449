import { IOrganisation } from '@nextbusiness/infinity-finance-api'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { DataAttributesCompany, useIntercom } from 'react-use-intercom'
import { useRootStore } from 'stores/RootStoreContext'

interface IntercomBootProps {
  children: React.ReactNode
}

export const intercomCompanyAttributesFromOrganisation = (
  fabricId: string,
  oragnisationIdentifier: string,
  organisation: IOrganisation | null
): DataAttributesCompany => ({
  companyId: fabricId,
  name: organisation?.legalName,
  customAttributes: {
    finance_id: oragnisationIdentifier,
    legal_type: organisation?.legalType,
    vat: organisation?.VAT,
    vat_mode: organisation?.vatCalculationMode,
    features: organisation?.features?.join(', '),
    marketing_opt_in: organisation?.metadata?.marketingCommunicationsOptIn,
  },
})

export const isIntercomAvailable = () => {
  return !!import.meta.env.VITE_INTERCOM_APP_ID
}

const IntercomBoot = (props: IntercomBootProps) => {
  const { authenticationStore, preferencesStore } = useRootStore()
  const intercom = useIntercom()
  const location = useLocation()

  useEffect(() => {
    intercom.update()
  }, [location])

  useEffect(() => {
    if (!import.meta.env.VITE_INTERCOM_APP_ID) return
    if (
      authenticationStore.isAuthenticated &&
      authenticationStore.user &&
      authenticationStore.organisationIdentifier &&
      authenticationStore.fabricOrganisationIdentifier &&
      authenticationStore.hmac
    ) {
      intercom.boot({
        email: authenticationStore.user.email,
        userId: authenticationStore.user.id,
        userHash: authenticationStore.hmac,
        name: authenticationStore.fullNameOfUser,
        company: intercomCompanyAttributesFromOrganisation(
          authenticationStore.fabricOrganisationIdentifier,
          authenticationStore.organisationIdentifier,
          preferencesStore.organisationPreferences
        ),
      })
    }

    return () => intercom.shutdown()
  }, [
    authenticationStore.isAuthenticated,
    authenticationStore.user,
    authenticationStore.fabricOrganisationIdentifier,
  ])

  return <>{props.children}</>
}

export default observer(IntercomBoot)
