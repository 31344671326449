import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import './Coachmark.scss'

interface CoachmarkProps {
  coachmarkId: string
  children?: React.ReactNode
}

const Coachmark = (props: CoachmarkProps) => {
  const { preferencesStore } = useRootStore()

  const hasSeenCoachmark = preferencesStore.hasSeenCoachmark(props.coachmarkId)
  if (hasSeenCoachmark) return null

  return (
    <div className='coachmark'>
      <div className='coachmark-instruction'>{props.children}</div>
    </div>
  )
}

export default observer(Coachmark)
