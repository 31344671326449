import { NonIdealState, Spacer } from '@nextbusiness/infinity-ui'
import { IconSize } from '@nextbusiness/infinity-ui-icons'
import SmallTransactionView from 'invoices/customer-invoices/editor/sidebar/panels/payment/SmallTransactionView'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import { useVendorInvoiceContext } from './VendorInvoiceContext'

const VendorInvoiceDetailTransactions = () => {
  const { vendorInvoiceStore } = useRootStore()
  const { invoice, reloadInvoice } = useVendorInvoiceContext()

  const paymentAndDiscountTransactions = [
    ...(invoice?.paymentTransactions ?? []),
    ...(invoice?.discountTransactions ?? []),
  ]

  return (
    <div className='payment-transactions'>
      {paymentAndDiscountTransactions.length > 0 ? (
        paymentAndDiscountTransactions.map((transactionId) => (
          <SmallTransactionView
            key={transactionId}
            transactionId={transactionId}
            invalidateQuery={() => {
              reloadInvoice()
              vendorInvoiceStore.onInvoicesChanged()
            }}
            accountDisplay='credit'
          />
        ))
      ) : (
        <>
          <Spacer />
          <NonIdealState icon='payment' iconSize={IconSize.Medium}>
            Noch keine Zahlungen
          </NonIdealState>
        </>
      )}
    </div>
  )
}

export default observer(VendorInvoiceDetailTransactions)
