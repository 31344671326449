import Utilities from 'utility/Utilities'
import './ExtendedInvoiceIcon.scss'

interface ExtendedInvoiceIconAmountProps {
  totalAmount: number
}

const ExtendedInvoiceIconAmount = (props: ExtendedInvoiceIconAmountProps) => (
  <div className='total-amount'>
    {props.totalAmount / 100 > 1000 || props.totalAmount / 100 < -1000
      ? `${parseFloat((props.totalAmount / 100000).toFixed(2))}K`
      : Utilities.centsToCHF(props.totalAmount)}
  </div>
)

export default ExtendedInvoiceIconAmount
