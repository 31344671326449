import { IProject } from '@nextbusiness/infinity-finance-api'
import VendorInvoiceDetailView from 'invoices/vendor-invoices/detail/VendorInvoiceDetailView'
import ProjectView from 'projects/project-view/ProjectView'
import MagicSheet, { View } from '../../components/magic-sheet/MagicSheet'
import { IVendorInvoice } from '../../model/VendorInvoice'
import ContactStack from './ContactStack'
import SingleContactPageOverview from './SingleContactPageOverview'

interface SingleContactPageStackProps {
  contactId: string
}

class SingleContactPageStack
  extends MagicSheet<SingleContactPageStackProps>
  implements ContactStack
{
  componentDidMount() {
    this.presentViewModally(this.rootViewForContact(this.props.contactId))
  }

  componentDidUpdate(prevProps: SingleContactPageStackProps) {
    if (prevProps.contactId !== this.props.contactId)
      this.setNewRootView(this.rootViewForContact(this.props.contactId))
  }

  private rootViewForContact(contactId: string): Omit<View, 'id'> {
    return {
      title: () => 'Kontakt',
      view: <SingleContactPageOverview contactId={contactId} stack={this} />,
    }
  }

  public openVendorInvoice(vendorInvoice: IVendorInvoice) {
    this.presentViewModally({
      title: () => vendorInvoice.title,
      view: (
        <VendorInvoiceDetailView
          invoiceId={vendorInvoice.id}
          onDismiss={() => this.goToRootView()}
        />
      ),
    })
  }

  public openProject(project: IProject) {
    this.presentViewModally({
      title: () => project.title,
      view: (
        <ProjectView
          projectId={project.id}
          dismissView={() => this.goToRootView()}
        />
      ),
    })
  }
}

export default SingleContactPageStack
