import { makeObservable, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import {
  IVendorInvoice,
  IVendorInvoiceQueryParams,
} from '../model/VendorInvoice'
import VendorInvoices from '../networking/VendorInvoices'
import RootStore from './RootStore'
import Store from './Store'

export default class VendorInvoiceStore extends Store {
  @persist('list') @observable allInvoices: IVendorInvoice[] = []
  @observable unpaidInvoices: IVendorInvoice[] = []

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  public async onInvoicesChanged() {
    await Promise.all([this.loadInvoices(), this.loadUnpaidInvoices()])
  }

  public async loadUnpaidInvoices() {
    await this.loadInvoices({
      isPaid: false,
      orderBy: 'dueDate',
      sortingOrder: 'desc',
    })
  }

  public async loadInvoices(queryParams?: IVendorInvoiceQueryParams) {
    if (!this.rootStore.authenticationStore.organisationIdentifier)
      return this.rootStore.authenticationStore.logout()

    const loadedInvoices = await VendorInvoices.listInvoices(
      this.rootStore.authenticationStore.organisationIdentifier,
      queryParams ?? {
        orderBy: 'openingDate',
        sortingOrder: 'desc',
      }
    )
    runInAction(() => {
      if (queryParams) {
        // This is a temporary workaround that is necessary because the backend
        // doesn't seem to be filtering by 'isPaid: false' correctly at the moment.
        // Remove once IYFB-212 (https://nxb.atlassian.net/browse/IYFB-212) is fixed.
        this.unpaidInvoices = loadedInvoices.filter(
          (invoice) => !invoice.isPaid
        )
      } else {
        this.allInvoices = loadedInvoices
      }
    })
  }
}
