import { FeedbackBanner } from '@nextbusiness/infinity-ui'
import { CustomerInvoiceEditorSidebarProps } from './CustomerInvoiceEditorSidebar'

const CustomerInvoiceDraftSaveValidationMessage = (
  props: CustomerInvoiceEditorSidebarProps
) => (
  <FeedbackBanner variant='warning'>
    {props.document.isDraft
      ? 'Um den Entwurf zu speichern, müssen mindestens der Titel und ein Empfänger gesetzt sein.'
      : `Um die ${
          props.document.isQuote ? 'Offerte' : 'Forderung'
        } zu speichern, müssen Titel, Empfänger und die Positionen vollständig sein.`}
  </FeedbackBanner>
)

export default CustomerInvoiceDraftSaveValidationMessage
