import { APIError } from '@nextbusiness/infinity-finance-api'
import { Button, useNotificationCenter } from '@nextbusiness/infinity-ui'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import CustomerInvoices from 'networking/CustomerInvoices'
import { useState } from 'react'
import DocumentSidebarPanelProps from '../DocumentSidebarPanelProps'
import CreateAnnulmentModal from './CreateAnnulmentModal'

const CustomerInvoiceToggleAnnulmentAction = (
  props: DocumentSidebarPanelProps
) => {
  const [isAnnulmentModalOpen, setIsAnnulmentModalOpen] =
    useState<boolean>(false)
  const [isInProgress, setIsInProgress] = useState<boolean>(false)
  const notificationCenter = useNotificationCenter()

  if (!props.existingInvoice) return null
  const invoiceHasPayments = props.existingInvoice.paymentTransactions.length
  if (invoiceHasPayments) return null

  const performAction = async (action: () => Promise<void>) => {
    setIsInProgress(true)
    try {
      await action()
    } catch (error) {
      notificationCenter.addNotification({
        variant: 'error',
        children: (error as APIError).humanText('de'),
      })
    } finally {
      setIsInProgress(false)
    }
  }

  const annulCustomerInvoice = async (atDate: Date) =>
    performAction(async () => {
      await CustomerInvoices.annulCustomerInvoice(
        props.existingInvoice!.id,
        atDate.getTime()
      )
      await props.reload('Forderung storniert')
      MixpanelAnalytics.event('Annulled customer invoice')
    })

  const restoreCustomerInvoice = async () =>
    performAction(async () => {
      await CustomerInvoices.restoreAnnulledCustomerInvoice(
        props.existingInvoice!.id
      )
      await props.reload('Forderung wiederhergestellt')
      MixpanelAnalytics.event('Restored customer invoice')
    })

  if (props.existingInvoice?.isAnnulled) {
    return (
      <Button
        variant='quaternary'
        iconLeft='undo'
        isLoading={isInProgress}
        className='sidebar-action'
        onClick={restoreCustomerInvoice}
      >
        Forderung wiederherstellen
      </Button>
    )
  }

  return (
    <>
      <Button
        variant='quaternary'
        iconLeft='close'
        isLoading={isInProgress}
        className='sidebar-action'
        onClick={() => setIsAnnulmentModalOpen(true)}
        tooltip='Löse die Forderung in deiner Buchhaltung auf und erstelle eine Stornorechnung.'
      >
        Forderung stornieren
      </Button>
      <CreateAnnulmentModal
        isOpen={isAnnulmentModalOpen}
        closeModal={() => setIsAnnulmentModalOpen(false)}
        onPerformAnnulment={annulCustomerInvoice}
        invoice={props.existingInvoice}
      />
    </>
  )
}

export default CustomerInvoiceToggleAnnulmentAction
