enum ProfitCloseMethod {
  /** Keep the earned profit in the company. */
  KeepProfit = 'keep-profit',

  /** Pay out the earned profit as dividends. */
  DistributeProfit = 'distribute-profit',

  /** Reduce the owner's equity by the loss. */
  ReduceEquityByLoss = 'reduce-equity-by-loss',

  /** No automatic profit close transaction. */
  Manual = 'manual',
}

export default ProfitCloseMethod
