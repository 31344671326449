import { ICurrency } from '@nextbusiness/infinity-finance-api'
import { Flyout } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import CurrencyOptions from './CurrencyOptions'
import CurrencySearch from './CurrencySearch'
import './CurrencySelect.scss'

interface CurrencySelectProps {
  setCurrency: (currency: ICurrency) => void
  flyoutTrigger: React.ReactElement
  isFlyoutOpen: boolean
  setIsFlyoutOpen: (isActive: boolean) => void
  presetCurrencyCode?: string
}

const CurrencySelect = (props: CurrencySelectProps) => {
  const { currencyStore } = useRootStore()

  const searchInputRef = useRef<HTMLInputElement>(null)

  const foreignCurrencies = currencyStore.currencies
  const baseCurrency = currencyStore.baseCurrency

  const allCurrencies = useMemo(() => {
    let currencies: ICurrency[] = []
    if (foreignCurrencies) currencies = [...foreignCurrencies]
    if (!currencies?.find((currency) => currency.id === 'base'))
      currencies?.unshift(baseCurrency)
    return currencies
  }, [foreignCurrencies])

  const [searchValue, setSearchValue] = useState<string>('')
  const [listedCurrencies, setListedCurrencies] =
    useState<ICurrency[]>(allCurrencies)
  const numberOfListedCurrencies = listedCurrencies.length
  const presetCurrency = allCurrencies?.find(
    (currency) => currency.currencyCode === props.presetCurrencyCode
  )
  const [selectedCurrency, setSelectedCurrency] = useState<ICurrency>(
    presetCurrency ?? baseCurrency
  )
  const selectedCurrencyIndex = listedCurrencies.findIndex(
    (currency) => currency.id === selectedCurrency.id
  )

  const [highlightedItemIndex, setHighlightedItemIndex] = useState<number>(
    selectedCurrencyIndex ?? 0
  )
  const highlightedCurrencyId = listedCurrencies[highlightedItemIndex]?.id

  useEffect(() => {
    setHighlightedItemIndex(selectedCurrencyIndex ?? 0)
  }, [selectedCurrencyIndex])

  useEffect(() => {
    if (allCurrencies) {
      const filteredCurrencies = allCurrencies.filter((currency) => {
        const searchLower = searchValue.toLowerCase()
        return (
          currency.currencyName.toLowerCase().includes(searchLower) ||
          currency.currencyCode.toLowerCase().includes(searchLower)
        )
      })
      setListedCurrencies(filteredCurrencies)
      if (searchValue) setHighlightedItemIndex(0)
    }
  }, [searchValue, allCurrencies])

  const selectCurrency = (currency: ICurrency) => {
    setSelectedCurrency(currency)
    props.setCurrency(currency)
    props.setIsFlyoutOpen(false)
  }

  const moveDown = () => {
    if (highlightedItemIndex < numberOfListedCurrencies - 1) {
      setHighlightedItemIndex(highlightedItemIndex + 1)
    } else {
      setHighlightedItemIndex(0)
    }
  }
  const moveUp = () => {
    if (highlightedItemIndex > 0) {
      setHighlightedItemIndex(highlightedItemIndex - 1)
    } else {
      setHighlightedItemIndex(numberOfListedCurrencies - 1)
    }
  }
  const selectHighlightedItem = () => {
    const currency = listedCurrencies[highlightedItemIndex]
    if (currency) selectCurrency(currency)
  }

  useEffect(() => {
    if (props.isFlyoutOpen) {
      searchInputRef.current?.focus()
      setSearchValue('')
    }
  }, [props.isFlyoutOpen])

  return (
    <Flyout
      className='currency-select-flyout'
      isActive={props.isFlyoutOpen}
      setIsActive={props.setIsFlyoutOpen}
      trigger={props.flyoutTrigger}
      triggerIsButton
    >
      <div className='currency-select'>
        <CurrencySearch
          ref={searchInputRef}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onArrowDown={moveDown}
          onArrowUp={moveUp}
          onEnter={selectHighlightedItem}
        />
        <CurrencyOptions
          selectedCurrencyId={selectedCurrency.id}
          highlightedCurrencyId={highlightedCurrencyId}
          currencies={listedCurrencies}
          setCurrency={selectCurrency}
        />
      </div>
    </Flyout>
  )
}

export default observer(CurrencySelect)
