import {
  APIError,
  Finance,
  ICurrency,
} from '@nextbusiness/infinity-finance-api'
import {
  Button,
  LoadingIndicator,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import AddCurrencyModal from './AddCurrencyModal'
import CurrencyListItem from './CurrencyListItem'
import './CurrencyTable.scss'
import EditCurrencyModal from './EditCurrencyModal'

const CurrencyTable = () => {
  const { currencyStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [currencyToBeEdited, setCurrencyToBeEdited] = useState<ICurrency>(
    {} as ICurrency
  )

  const currencies: ICurrency[] | null = currencyStore.currencies
  const [currencyListItems, setCurrencyListItems] = useState<
    ICurrency[] | null
  >(currencies)

  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [idToBeDeleted, setIdToBeDeleted] = useState<string | null>(null)

  const openEditCurrency = (id: string) => {
    if (currencyListItems === null) return
    const currencyToBeEdited = currencyListItems.find(
      (currencyListItem) => currencyListItem.id === id
    )
    if (currencyToBeEdited) {
      setCurrencyToBeEdited(currencyToBeEdited)
      setIsEditModalOpen(true)
    }
  }

  const deleteCurrency = async (id: string) => {
    try {
      setIsDeleting(true)
      setIdToBeDeleted(id)
      await Finance.Currencies.deleteCurrency(id)
    } catch (error: any) {
      notificationCenter.addNotification({
        children: (error as APIError).humanText('de'),
        variant: 'error',
      })
    } finally {
      await currencyStore.loadCurrencies()
      setIsDeleting(false)
      setIdToBeDeleted(null)
    }
  }

  useEffect(() => {
    setCurrencyListItems(currencies)
  }, [currencies])

  const isLoading = currencyListItems === null
  const isCurrencyListEmpty = currencyListItems?.length === 0

  if (isLoading) return <LoadingIndicator />

  return (
    <div className='currency-table'>
      <div className='currencies'>
        {isCurrencyListEmpty ? (
          <div className='empty-state'>Keine Fremdwährung vorhanden</div>
        ) : (
          currencyListItems.map((currency) => (
            <CurrencyListItem
              key={currency.id}
              currency={currency}
              onEdit={() => openEditCurrency(currency.id)}
              onDelete={() => deleteCurrency(currency.id)}
              isDeleting={isDeleting && currency.id === idToBeDeleted}
            />
          ))
        )}
      </div>
      <Button
        variant='secondary'
        iconLeft='plus'
        onClick={() => {
          setIsAddModalOpen(true)
        }}
      >
        {isCurrencyListEmpty ? 'Währung hinzufügen' : 'Weitere hinzufügen'}
      </Button>
      {isEditModalOpen && (
        <EditCurrencyModal
          currencyToBeEdited={currencyToBeEdited}
          isOpen={isEditModalOpen}
          onDismiss={() => setIsEditModalOpen(false)}
        />
      )}
      {isAddModalOpen && (
        <AddCurrencyModal
          isOpen={isAddModalOpen}
          onDismiss={() => setIsAddModalOpen(false)}
        />
      )}
    </div>
  )
}

export default observer(CurrencyTable)
