import VATOptions from 'ledger/transactions/VATOptions'
import { useTransactionCreatorContext } from '../TransactionCreatorContext'

const TransactionCreatorVATOptions = () => {
  const { transaction, updateTransaction, utilities } =
    useTransactionCreatorContext()
  return (
    <VATOptions
      transaction={transaction}
      contraAccountSide={utilities.contraAccountSide}
      onChange={(taxCode) => updateTransaction({ taxCode })}
      taxCode={transaction.taxCode}
    />
  )
}

export default TransactionCreatorVATOptions
