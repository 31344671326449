import { IDocument } from '@nextbusiness/infinity-finance-api'
import { Avatar } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import './NoteBox.scss'

interface NoteBoxProps {
  document: IDocument
}

const NoteBox = (props: NoteBoxProps) => {
  const { organisationStore } = useRootStore()
  const uploaderName = organisationStore.fullNameOfMember(
    props.document.uploadedBy
  )
  return (
    <div className='note-box-container'>
      <Avatar name={uploaderName} />
      <div className='note-box-title'>
        {props.document.note
          ? `„${props.document.note}“`
          : 'Ohne Kommentar hochgeladen'}
      </div>
    </div>
  )
}

export default observer(NoteBox)
