import { observer } from 'mobx-react'
import { ReactComponent as DraftStamp } from '../../../assets/customer-invoice-icons/stamp-draft.svg'
import { ReactComponent as QuoteStamp } from '../../../assets/customer-invoice-icons/stamp-offer.svg'
import { ReactComponent as OverdueStamp } from '../../../assets/customer-invoice-icons/stamp-overdue.svg'
import { useRootStore } from '../../../stores/RootStoreContext'
import './CustomerInvoiceIcon.scss'

interface CustomerInvoiceIconProps {
  showOverdueStamp?: boolean
  showDraftStamp?: boolean
  showQuoteStamp?: boolean
  small?: boolean
}

const CustomerInvoiceIcon = (props: CustomerInvoiceIconProps) => {
  const { templateStore } = useRootStore()

  return (
    <div className={'customer-invoice-icon' + (props.small ? ' small' : '')}>
      {props.showOverdueStamp && (
        <OverdueStamp className='overdue-stamp' title='Überfällig' />
      )}
      {props.showDraftStamp && (
        <DraftStamp className='draft-stamp' title='Entwurf' />
      )}
      {props.showQuoteStamp && (
        <QuoteStamp className='quote-stamp' title='Offerte' />
      )}
      {!props.small && (
        <div
          className='logo'
          style={
            templateStore.currentTemplate?.logo
              ? {
                  backgroundImage: `url('${templateStore.currentTemplate?.logo}')`,
                  backgroundSize: `auto ${
                    (templateStore.currentTemplate?.logoSize ?? 1) * 100
                  }%`,
                }
              : undefined
          }
        />
      )}
      {templateStore.currentTemplate?.colourValues && (
        <div
          className='colour-gradient'
          style={{
            backgroundImage: `linear-gradient(90deg, ${templateStore.currentTemplate?.colourValues[0]} , ${templateStore.currentTemplate?.colourValues[1]})`,
          }}
        />
      )}
    </div>
  )
}

export default observer(CustomerInvoiceIcon)
