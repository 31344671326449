import { useContext, useEffect, useRef } from 'react'
import DocumentRendererContext from '../DocumentRendererContext'
import DocumentElementProps from './DocumentElementProps'

const DocumentElementGradient = (props: DocumentElementProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const renderContext = useContext(DocumentRendererContext)

  useEffect(() => {
    if (ref.current) renderContext.setFooterHeight(ref.current.clientHeight)
  }, [])

  if (props.template.disableDecorativeGradient) return null

  return (
    <div
      className='page-gradient'
      ref={ref}
      style={{
        backgroundImage: `linear-gradient(90deg, ${props.template.colourValues[0]}, ${props.template.colourValues[1]})`,
      }}
    />
  )
}

export default DocumentElementGradient
