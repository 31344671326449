import {
  APIError,
  AuthenticationSession,
  ErrorCode,
  Finance,
} from '@nextbusiness/infinity-finance-api'
import { APPLICATION_IDENTIFIER } from 'application/ApplicationConstants'
import { BackendError, ErrorType } from '../libs/networking/Errors'
import AuthenticationCredentials from './AuthenticationCredentials'
import NetworkingConstants from './NetworkingConstants'

export let currentApplicationAccessToken: string | undefined
export let currentUserDataToken: string | undefined

const isRunningInDevelopmentEnvironment =
  document.location.hostname === 'localhost'

export const ORIGIN = NetworkingConstants.HOME
export const REDIRECT_URL = (relaunchWithOrganisation?: string) => {
  const BASE_REDIRECT_URL = ORIGIN + '/app/' + APPLICATION_IDENTIFIER
  const options: string[] = []

  if (isRunningInDevelopmentEnvironment)
    options.push('modifier=development-mode')
  if (relaunchWithOrganisation)
    options.push(`organisation=${relaunchWithOrganisation}`)

  if (!options.length) return BASE_REDIRECT_URL
  return `${BASE_REDIRECT_URL}?${options.join('&')}`
}
export const DASHBOARD_URL = ORIGIN + '/dashboard'

export class Current {
  public static credentials: AuthenticationCredentials | null

  public static get defaultHeaders() {
    return {
      'Content-Type': 'application/json',
      Authorization: this.authHeader,
    }
  }

  public static get authHeader() {
    if (!this.credentials?.authHeader)
      throw new APIError(ErrorCode.Unauthorised)
    return this.credentials.authHeader
  }
}

/**
 * Sets the applicationAccessToken that is passed with every request and the userDataToken which is needed for datalake requests
 * @param applicationAccessToken Infinity backend token
 * @param userDataToken Infinity backend token
 */
const setCurrentTokens = (
  applicationAccessToken: string,
  userDataToken: string,
  sessionToken: string,
  hmac: string,
  organisationId: string
) => {
  if (!applicationAccessToken || !userDataToken || !sessionToken || !hmac)
    return

  currentApplicationAccessToken = applicationAccessToken
  currentUserDataToken = userDataToken

  Current.credentials = new AuthenticationCredentials(
    sessionToken,
    applicationAccessToken,
    userDataToken,
    hmac
  )
  Finance.Configuration.session = new AuthenticationSession(
    sessionToken,
    organisationId
  )
}

/**
 * Authenticates with the backend and Fabric.
 */
const authenticate = async (
  organisationId: string,
  token: string
): Promise<AuthenticationCredentials> => {
  const response = await fetch(NetworkingConstants.HOST + `/authenticate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      organisationId,
      token,
    }),
  })
  const body = await response.json()

  if (
    !response.ok ||
    !body.sessionToken ||
    !body.applicationAccessToken ||
    !body.userDataToken ||
    !body.hmac
  ) {
    switch (body.message) {
      case 'Unauthorised':
      case 'jwt expired':
      case 'jwt malformed':
        throw new APIError(
          ErrorCode.Unauthorised,
          'Deine Sitzung ist abgelaufen. Bitte logge dich erneut ein.'
        )
      case 'This application does not exist':
        throw new BackendError(ErrorType.NotFound, body.message)
      case 'This organisation does not exist':
        throw new BackendError(ErrorType.NotFound, body.message)
      case 'No active subscription':
        throw new BackendError(ErrorType.InsufficientSubscription, body.message)
      default:
        throw new BackendError(ErrorType.ServerError, body?.message)
    }
  }
  return new AuthenticationCredentials(
    body.sessionToken,
    body.applicationAccessToken,
    body.userDataToken,
    body.hmac
  )
}

/**
 * Gets the applicationAccessToken and the userDataToken using a one-time-access-token
 * @param organisationIdentifier ID of the organisation from which the app was started
 * @param applicationIdentifier Name of the application that was started
 * @param token One-time-access-token
 * @returns
 * @deprecated
 */
const getAppAccessAndUserDataToken = async (
  organisationIdentifier: string,
  applicationIdentifier: string,
  token: string
): Promise<[string, string]> => {
  const response = await fetch(
    NetworkingConstants.FABRIC_HOST +
      `/organisation/${organisationIdentifier}/application/${encodeURIComponent(
        applicationIdentifier
      )}/authenticate?token=${token}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const body = await response.json()

  if (!response.ok || !body.token) {
    switch (body.message) {
      case 'Unauthorised':
      case 'jwt expired':
      case 'jwt malformed':
        throw new APIError(
          ErrorCode.Unauthorised,
          'Deine Sitzung ist abgelaufen. Bitte logge dich erneut ein.'
        )
      case 'This application does not exist':
        throw new BackendError(ErrorType.NotFound, body.message)
      case 'This organisation does not exist':
        throw new BackendError(ErrorType.NotFound, body.message)
      case 'No active subscription':
        throw new BackendError(ErrorType.InsufficientSubscription, body.message)
      default:
        throw new BackendError(ErrorType.ServerError, body?.message)
    }
  }
  return [body.token, body.userDataToken]
}

const Authentication = {
  setCurrentTokens,
  getAppAccessAndUserDataToken,
  authenticate,
}

export default Authentication
