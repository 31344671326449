import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import TransactionAmount from 'components/transactions/TransactionAmount'
import { KeyboardEvent, MouseEvent, useState } from 'react'
import { SolvencyDropdownGroupPart } from './FinancialHealthSolvencyPage'

interface SolvencyDropdownGroupProps {
  title: string
  sumAmount: number
  parts: SolvencyDropdownGroupPart[]
}

const SolvencyDropdownGroup = (props: SolvencyDropdownGroupProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const onGroupSelected = (e: MouseEvent | KeyboardEvent) => {
    e.stopPropagation()
    setIsExpanded(!isExpanded)
  }

  return (
    <div className={'solvency-group' + (isExpanded ? ' expanded' : '')}>
      <div
        className='solvency-group-header'
        role='rowheader'
        tabIndex={0}
        onClick={onGroupSelected}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onGroupSelected(e)
        }}
      >
        <div className='depiction'>
          <Icon
            className={'icon' + (isExpanded ? ' expanded' : '')}
            icon='expand'
            size={IconSize.ExtraTiny}
          />

          <div className='title'>{props.title}</div>
        </div>
        <div className='sum-amount'>
          <TransactionAmount amount={Math.abs(props.sumAmount)} />
        </div>
      </div>
      <div className='solvency-group-content'>
        {props.parts.map((part, index) => (
          <div className='solvency-group-row' key={`${part.title}-${index}`}>
            <div className='depiction'>
              <div
                className='colour-swatch'
                style={{
                  backgroundColor: part.color,
                }}
              />
              <div className='title'>{part.title}</div>
            </div>
            <div className='amount'>
              <TransactionAmount amount={part.amount} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SolvencyDropdownGroup
