import { TransactionFilter } from '@nextbusiness/infinity-finance-api'
import React, { useMemo } from 'react'

interface ITransactionFilterContext {
  isFiltering: boolean
  setIsFiltering: (isFiltering: boolean) => void
  appliedFilters: TransactionFilter | null
  updateAppliedFilters: (appliedFilters: TransactionFilter) => void
  clearFilters: (filtersToClear?: (keyof TransactionFilter)[]) => void
  resetPaginationForFilteredTransactions: () => void
}

export const TransactionFilterContext =
  React.createContext<ITransactionFilterContext | null>(null)

export const TransactionFilterProvider = (
  props: React.PropsWithChildren<ITransactionFilterContext>
) => {
  const {
    isFiltering,
    appliedFilters,
    clearFilters,
    setIsFiltering,
    updateAppliedFilters,
    resetPaginationForFilteredTransactions,
  } = props

  const contextValue = useMemo(
    () => ({
      isFiltering,
      appliedFilters,
      clearFilters,
      setIsFiltering,
      updateAppliedFilters,
      resetPaginationForFilteredTransactions,
    }),
    [
      isFiltering,
      appliedFilters,
      clearFilters,
      setIsFiltering,
      updateAppliedFilters,
      resetPaginationForFilteredTransactions,
    ]
  )

  return (
    <TransactionFilterContext.Provider value={contextValue}>
      {props.children}
    </TransactionFilterContext.Provider>
  )
}
