import { IProject } from '@nextbusiness/infinity-finance-api'
import MagicSheet from 'components/magic-sheet/MagicSheet'
import VendorInvoiceDetailView from 'invoices/vendor-invoices/detail/VendorInvoiceDetailView'
import { IVendorInvoice } from 'model/VendorInvoice'
import ProjectView from 'projects/project-view/ProjectView'
import { ContactRelatedItemsTab } from './ContactPageStackContext'
import ContactsPage from './ContactsPage'
import ContactStack from './page/ContactStack'
import SingleContactPageOverview from './page/SingleContactPageOverview'

class ContactsPageStack extends MagicSheet implements ContactStack {
  rootView = () => {
    return <ContactsPage openContactDetail={this.openContactDetail} />
  }

  componentDidMount() {
    this.presentViewModally({
      title: () => 'Kontakte',
      view: this.rootView(),
    })
  }

  public openContactDetail = (
    contactId: string,
    tab?: ContactRelatedItemsTab
  ) => {
    this.presentViewModally({
      title: () => 'Kontakt',
      view: (
        <SingleContactPageOverview
          contactId={contactId}
          stack={this}
          initialTab={tab}
        />
      ),
    })
  }

  public openVendorInvoice(vendorInvoice: IVendorInvoice) {
    this.presentViewModally({
      title: () => vendorInvoice.title,
      view: (
        <VendorInvoiceDetailView
          invoiceId={vendorInvoice.id}
          onDismiss={() => this.goToRootView()}
        />
      ),
    })
  }

  public openProject(project: IProject) {
    this.presentViewModally({
      title: () => project.title,
      view: (
        <ProjectView
          projectId={project.id}
          dismissView={() => this.goToRootView()}
        />
      ),
    })
  }
}

export default ContactsPageStack
