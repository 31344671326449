import IDocument from 'model/Document'
import DocumentAttachments from '../DocumentAttachments'
import { useVendorInvoiceCreator } from './VendorInvoiceCreationContext'

const VendorInvoiceCreationPreviewPane = () => {
  const { upload, setUpload, performLiveCaptureAnalysis } =
    useVendorInvoiceCreator()

  const onUpload = (uploadedDocument: IDocument) => {
    setUpload(uploadedDocument)
    performLiveCaptureAnalysis(uploadedDocument)
  }

  return (
    <div className='vendor-invoice-detail-preview creation-view'>
      <DocumentAttachments
        invoiceId={null}
        attachmentIds={upload ? [upload.id] : undefined}
        onUpload={onUpload}
        disableAnalysisOnUpload
      />
    </div>
  )
}

export default VendorInvoiceCreationPreviewPane
