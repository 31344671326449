import React from 'react'
import { ReactComponent as CheckOK } from '../../../../assets/financial-close/check-ok.svg'
import { ReactComponent as CheckNotOK } from '../../../../assets/financial-close/check-not-ok.svg'
import './Check.scss'

interface CheckProps {
  isFulfilled?: boolean
  children: React.ReactNode
}

const Check = (props: CheckProps) => (
  <div
    className={'check' + (props.isFulfilled ? ' fulfilled' : ' unfulfilled')}
  >
    <div className='check-icon'>
      {props.isFulfilled ? <CheckOK /> : <CheckNotOK />}
    </div>
    <div className='check-depiction'>
      <span className='title'>{props.children}</span>
    </div>
  </div>
)

export default Check
