import { useMixpanelEvent } from 'integrations/useMixpanelEvent'
import BatchEditingProvider from 'ledger/transactions/batch-editing/BatchEditingContext'
import BatchEditingModal from 'ledger/transactions/batch-editing/BatchEditingModal'
import TransactionList from '../transactions/list/TransactionList'
import { useAccountPageContext } from './AccountPageContext'
import './AccountSheet.scss'
import AccountSheetContainer from './AccountSheetContainer'
import AccountSheetProvider from './AccountSheetContext'
import AccountSheetError from './AccountSheetError'
import { isDefinedAccount } from './data/AccountRules'
import AccountHeader from './header/AccountHeader'
import BankAccountSelectConnectionSetup from './onboarding/live-accounting-setup/BankAccountSelectConnectionSetup'

const AccountSheet = () => {
  const { loadingError, currentAccount } = useAccountPageContext()

  const isPerformingLiveAccountingSetup =
    isDefinedAccount(currentAccount) &&
    currentAccount.hasLiveAccountingConnection &&
    !currentAccount.bankAccountId

  useMixpanelEvent('Account viewed', {
    accountNumber: currentAccount.accountNumber,
  })

  if (isPerformingLiveAccountingSetup) {
    return <BankAccountSelectConnectionSetup />
  }

  return (
    <AccountSheetProvider>
      <BatchEditingProvider>
        <AccountSheetContainer>
          <div className='magic-sheet-header'>
            <AccountHeader />
          </div>
          {loadingError ? (
            <AccountSheetError error={loadingError} />
          ) : (
            <TransactionList />
          )}
          <BatchEditingModal />
        </AccountSheetContainer>
      </BatchEditingProvider>
    </AccountSheetProvider>
  )
}

export default AccountSheet
