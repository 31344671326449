import { TemplatePreviewProps } from './TemplatePreview'

const TemplatePreviewPageEndText = (props: TemplatePreviewProps) => {
  if (!props.template.organisationName) return null
  return (
    <div className='page-end-text'>
      {props.showQuotePreview ? (
        props.template.defaultQuoteClosingText ? (
          <p>{props.template.defaultQuoteClosingText}</p>
        ) : (
          <p>
            Freundliche Grüsse,
            <br />
            Ihr {props.template.organisationName}-Team
          </p>
        )
      ) : props.template.defaultClosingText ? (
        <p>{props.template.defaultClosingText}</p>
      ) : (
        <p>
          Freundliche Grüsse,
          <br />
          Ihr {props.template.organisationName}-Team
        </p>
      )}
    </div>
  )
}

export default TemplatePreviewPageEndText
