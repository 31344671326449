import {
  APIError,
  ICompleteDocument,
  ITransaction,
} from '@nextbusiness/infinity-finance-api'
import { Flyout } from '@nextbusiness/infinity-ui'
import { useState } from 'react'
import AttachmentShowButton from './AttachmentShowButton'
import TransactionAttachmentFlyoutContent from './TransactionAttachmentFlyoutContent'

interface AttachmentButtonProps {
  transaction: ITransaction & { attachments: string[] }
  isLoadingAttachments: boolean
  transactionAttachmentsOrErrors: (ICompleteDocument | APIError)[]
  openUploadModal: () => void
}

const AttachmentButton = (props: AttachmentButtonProps) => {
  const [isActive, setIsActive] = useState(false)
  return (
    <>
      <Flyout
        isActive={isActive}
        setIsActive={() => setIsActive(!isActive)}
        trigger={
          <AttachmentShowButton
            onClick={() => setIsActive(!isActive)}
            transaction={props.transaction}
          />
        }
        triggerIsButton
      >
        <TransactionAttachmentFlyoutContent
          closeMenu={() => setIsActive(false)}
          isLoadingAttachments={props.isLoadingAttachments}
          transactionAttachments={props.transactionAttachmentsOrErrors}
          openUploadModal={props.openUploadModal}
        />
      </Flyout>
    </>
  )
}

export default AttachmentButton
