import {
  ICompleteDocument,
  INewTransaction,
} from '@nextbusiness/infinity-finance-api'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import SprigIntegration from 'integrations/SprigIntegration'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import LedgerStore from 'stores/LedgerStore'
import { useRootStore } from 'stores/RootStoreContext'

interface ExpenditureCreateContextType {
  transaction: Partial<INewTransaction>
  updateTransaction: (transaction: Partial<INewTransaction>) => void
  shouldValidate: boolean
  setShouldValidate: (shouldValidate: boolean) => void
  isValid: boolean
}

const ExpenditureCreateContext =
  React.createContext<ExpenditureCreateContextType | null>(null)

interface ExpenditureCreateContextProviderProps {
  document?: ICompleteDocument
  children: React.ReactNode
}

const ExpenditureCreateContextProvider = (
  props: ExpenditureCreateContextProviderProps
) => {
  const { accountStore } = useRootStore()
  const analysis = props.document?.document.analysis

  const [transaction, setTransaction] = useState<Partial<INewTransaction>>({
    creditAccount: LedgerStore.INITIAL_DEFAULT_ROOT_VIEW,
    description: analysis?.title,
    date: analysis?.documentDate,
    amount: analysis?.amountTotal ? analysis.amountTotal / 100 : undefined,
  })

  const updateTransaction = (changes: Partial<INewTransaction>) =>
    setTransaction({ ...transaction, ...changes })

  const [shouldValidate, setShouldValidate] = useState<boolean>(false)

  const isValid =
    transaction.date !== undefined &&
    transaction.description?.trim() !== '' &&
    transaction.debitAccount !== undefined &&
    transaction.creditAccount !== undefined &&
    transaction.amount !== undefined &&
    transaction.amount > 0 &&
    accountStore.isDateWithinOpenFiscalYear(new Date(transaction.date))

  useEffect(() => {
    if (analysis?.title) {
      SprigIntegration.sprig?.('track', 'LIVE_CAPTURE_USED')
      MixpanelAnalytics.event('Live capture applied - Expenditures')
    }
  }, [analysis])

  const contextValue = useMemo(
    () => ({
      transaction,
      updateTransaction,
      shouldValidate,
      setShouldValidate,
      isValid,
    }),
    [transaction, updateTransaction, shouldValidate, setShouldValidate, isValid]
  )

  return (
    <ExpenditureCreateContext.Provider value={contextValue}>
      {props.children}
    </ExpenditureCreateContext.Provider>
  )
}

export const useExpenditureCreateContext = () => {
  const context = useContext(ExpenditureCreateContext)
  if (!context)
    throw new Error(
      'useExpenditureCreateContext must be used within an ExpenditureCreateContextProvider'
    )
  return context
}

export default observer(ExpenditureCreateContextProvider)
