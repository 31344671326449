import { Icon } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import React, { Component } from 'react'
import { generateRandomId } from '../../utility/StringUtilities'
import './MagicSheet.scss'

interface IView {
  id: string
  header?: React.ReactNode
  view: React.ReactNode
  title: () => string
}

export type View = Omit<IView, 'id'>
interface MagicSheetState {
  navigationStack: IView[]
  newestItem?: string
  isTransitioning: boolean
  isShowingNavigationHint: boolean
}

export enum NavigationIntent {
  PresentViewModally,
  ReplaceRootView,
  PopCurrentView,
  GoToRootView,
}
class MagicSheet<T = object> extends Component<T, MagicSheetState> {
  protected className?: string

  private backSheetLongPressTimer?: number
  private readonly backSheetLongPressTriggerDuration: number = 500
  private readonly cssTransitionsDuration: number = 500

  private readonly navigationHintDuration: number = 2500
  private readonly navigationHintShowCountLocalStorageKey: string =
    'ms-hint-show-count'

  state: MagicSheetState = {
    navigationStack: [],
    newestItem: undefined,
    isTransitioning: false,
    isShowingNavigationHint: false,
  }

  public presentViewModally(view: Omit<View, 'id'>): string | null {
    if (!this.isNavigationAllowed(NavigationIntent.PresentViewModally))
      return null
    const id = generateRandomId()
    this.setState(
      {
        navigationStack: [...this.state.navigationStack, { ...view, id }],
        newestItem: id,
        isTransitioning: true,
      },
      () => this.onInternalNavigationChanged()
    )
    if (this.shouldDisplayNavigationHint()) this.displayNavigationHint()
    return id
  }

  public setCurrentNavigationStack(views: Omit<View, 'id'>[]): View[] | null {
    if (!this.isNavigationAllowed(NavigationIntent.ReplaceRootView)) return null
    if (views.length === 0) return null

    const identifiableViews = views.map((view) => ({
      ...view,
      id: generateRandomId(),
    }))
    const newestItemId = identifiableViews[identifiableViews.length - 1].id
    this.setState(
      {
        navigationStack: [...identifiableViews],
        newestItem: newestItemId,
        isTransitioning: true,
      },
      () => this.onInternalNavigationChanged()
    )
    return identifiableViews
  }

  get currentViews(): View[] {
    return this.state.navigationStack
  }

  public setNewRootView(view: View): string | null {
    if (!this.isNavigationAllowed(NavigationIntent.ReplaceRootView)) return null
    const id = generateRandomId()
    this.setState(
      {
        navigationStack: [{ ...view, id }],
        newestItem: id,
        isTransitioning: true,
      },
      () => this.onInternalNavigationChanged()
    )
    return id
  }

  public popCurrentView() {
    if (!this.isNavigationAllowed(NavigationIntent.PopCurrentView)) return
    if (this.state.navigationStack.length < 1) return

    this.setState(
      {
        navigationStack: this.state.navigationStack.slice(
          0,
          this.state.navigationStack.length - 1
        ),
        newestItem: undefined,
        isTransitioning: true,
      },
      () => this.onInternalNavigationChanged()
    )
    this.setupTransitionEndTimer()
  }

  public goToRootView() {
    if (!this.isNavigationAllowed(NavigationIntent.GoToRootView)) return
    if (this.state.navigationStack.length < 1) return

    this.setState(
      {
        navigationStack: [this.state.navigationStack[0]],
        newestItem: undefined,
        isTransitioning: true,
      },
      () => this.onInternalNavigationChanged()
    )
    this.setupTransitionEndTimer()
  }

  private onInternalNavigationChanged() {
    this.setupTransitionEndTimer()
    this.onNavigationStackChanged()
  }

  private setupTransitionEndTimer() {
    window.setTimeout(
      () => this.setState({ isTransitioning: false }),
      this.cssTransitionsDuration
    )
  }

  private shouldDisplayNavigationHint(): boolean {
    return this.navigationHintDisplayCount < 4
  }

  private displayNavigationHint() {
    this.countNavigationHintDisplay()
    this.setState({ isShowingNavigationHint: true })

    window.setTimeout(() => {
      this.setState({ isShowingNavigationHint: false })
    }, this.navigationHintDuration)
  }

  private get navigationHintDisplayCount() {
    const localStorageValue = localStorage.getItem(
      this.navigationHintShowCountLocalStorageKey
    )
    return localStorageValue ? parseInt(localStorageValue) : 0
  }

  private countNavigationHintDisplay() {
    const timesShown = this.navigationHintDisplayCount
    window.localStorage.setItem(
      this.navigationHintShowCountLocalStorageKey,
      String(timesShown + 1)
    )
  }

  /**
   * This method is called when a new view is pushed to the navigation
   * stack or if the user navigates back one view or to the root view.
   * You can simply override this method with your own implementation.
   */
  protected onNavigationStackChanged() {
    return
  }

  /**
   * If false, any navigation calls such as presentViewModally, setNewRootView,
   * popCurrentView or goToRootView will be ignored.
   */
  protected isNavigationAllowed(_navigationIntent: NavigationIntent): boolean {
    return true
  }

  render() {
    return (
      <div
        className={classNames(
          'magic-sheet-wrapper',
          this.className,
          this.state.isTransitioning && 'transitioning'
        )}
      >
        <div
          className={classNames(
            'magic-sheet-stack',
            this.state.isShowingNavigationHint && 'show-hint'
          )}
        >
          {this.state.navigationStack.map((view, index) => {
            const isCurrent = index === this.state.navigationStack.length - 1
            const isInvisible = index < this.state.navigationStack.length - 2
            const isNewest = view.id === this.state.newestItem

            const isFocusable = !isCurrent && !isInvisible
            const isRoot = index === 0

            return (
              <div
                className={classNames('magic-sheet', {
                  current: isCurrent,
                  below: isInvisible,
                  newest: isNewest,
                  root: isRoot,
                })}
                key={view.id}
                role={isFocusable ? 'button' : undefined}
                tabIndex={isFocusable ? 0 : undefined}
                onClick={isFocusable ? () => this.popCurrentView() : undefined}
                onKeyDown={
                  isFocusable
                    ? (e) => {
                        if (e.key === 'Enter') {
                          if (e.shiftKey) {
                            this.goToRootView()
                          } else {
                            this.popCurrentView()
                          }
                        }
                      }
                    : undefined
                }
                onMouseDown={(e) => {
                  if (isCurrent) return
                  this.backSheetLongPressTimer = window.setTimeout(() => {
                    e.preventDefault()
                    this.goToRootView()
                  }, this.backSheetLongPressTriggerDuration)
                }}
                onMouseUp={() =>
                  window.clearTimeout(this.backSheetLongPressTimer)
                }
                aria-label={isFocusable ? view.title() : undefined}
              >
                {!view.header ? (
                  <div className='magic-sheet-custom-view'>{view.view}</div>
                ) : (
                  <>
                    <div className='magic-sheet-header'>{view.header}</div>
                    <div className='magic-sheet-view' hidden={!isCurrent}>
                      {view.view}
                    </div>
                  </>
                )}
                <div className='magic-sheet-hint'>
                  <div className='hint-inner'>
                    <Icon icon='back' size={16} />
                    <span>Zurück zu {view.title()}</span>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default MagicSheet
