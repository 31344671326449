import CardRadioGroup from 'ledger/accounts/account-creation/CardRadioGroup'
import { observer } from 'mobx-react'
import IDocument from 'model/Document'
import { useRootStore } from 'stores/RootStoreContext'

interface AttachDocumentFromInboxRadioGroupProps {
  selectedDocument: IDocument | null
  onDocumentSelected: (document: IDocument) => void
}

const AttachDocumentFromInboxRadioGroup = (
  props: AttachDocumentFromInboxRadioGroupProps
) => {
  const { documentStore } = useRootStore()
  return (
    <CardRadioGroup
      options={
        documentStore.inboxDocuments?.map((document) => ({
          label: document.title,
          value: document.id,
        })) ?? []
      }
      onChange={(value) => {
        const selectedDocument = documentStore.inboxDocuments!.find(
          (document) => document.id === value
        )!
        props.onDocumentSelected(selectedDocument)
      }}
      value={props.selectedDocument?.id}
    />
  )
}

export default observer(AttachDocumentFromInboxRadioGroup)
