import { ITemplate } from '@nextbusiness/infinity-finance-api'
import { useContext, useEffect, useRef } from 'react'
import ICustomerInvoice, {
  INewCustomerInvoice,
} from '../../../../model/CustomerInvoice'
import { ITemplateDraft } from '../../../../model/Template'
import './DocumentPage.scss'
import DocumentRendererContext from './DocumentRendererContext'
import IDocumentPage from './IDocumentPage'
import DocumentPageBody from './sections/DocumentPageBody'
import DocumentPageFooter from './sections/DocumentPageFooter'
import DocumentPageHeader from './sections/DocumentPageHeader'

export interface DocumentPageProps {
  template: ITemplate | ITemplateDraft
  document: Partial<INewCustomerInvoice>
  isEditable?: boolean
  registerEdit: (changes: Partial<INewCustomerInvoice>) => void
  page: IDocumentPage
  existingInvoice?: ICustomerInvoice
}

const DocumentPage = (props: DocumentPageProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const renderContext = useContext(DocumentRendererContext)

  const isFirstPage = props.page.pageNumber === 1
  const backgroundUrl = isFirstPage
    ? props.template.backgroundUrl
    : props.template.subsequentBackgroundUrl

  useEffect(() => {
    if (ref.current) renderContext.setPageHeight(ref.current?.clientHeight)
  }, [])

  return (
    <div
      className={`document-page page-font-${props.template?.font ?? 'default'}`}
      ref={ref}
      style={{
        backgroundImage: backgroundUrl ? `url(${backgroundUrl})` : undefined,
      }}
    >
      {props.page.header && <DocumentPageHeader {...props} />}
      {!isFirstPage && <div style={{ height: '3rem' }} />}
      <DocumentPageBody {...props} />
      <DocumentPageFooter {...props} />
    </div>
  )
}

export default DocumentPage
