import Filter from 'base-components/table/Filter'
import { FilterItem } from 'base-components/table/FilterList'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react'
import { useMemo } from 'react'

export interface FilterableByDate {
  openingDate: number
}

interface InvoiceDateFilterProps {
  invoices: FilterableByDate[]
  selectedValue?: string | null
  onValueSelected: (value: string | null) => void
}

const InvoiceDateFilter = (props: InvoiceDateFilterProps) => {
  const allInvoices = props.invoices
  const monthFilterItems = useMemo(() => {
    return allInvoices
      ?.reduce<FilterItem[]>((accumulator, invoice) => {
        const openingDate = DateTime.fromMillis(invoice.openingDate)
        const beginningOfMonth = openingDate.startOf('month')
        const monthLabel = beginningOfMonth.toLocaleString({
          locale: 'de-CH',
          month: 'long',
          year: 'numeric',
        })
        const hasMonth = accumulator.some(
          (item) => item.value === beginningOfMonth.toISO()
        )
        if (!hasMonth) {
          accumulator.push({
            label: monthLabel,
            value: beginningOfMonth.toISO(),
          })
        }
        return accumulator
      }, [])
      .toSorted((a, b) => b.value.localeCompare(a.value))
  }, [allInvoices])

  return (
    <Filter
      icon='schedule'
      label='Rechnungsdatum'
      items={monthFilterItems}
      selectedValue={props.selectedValue}
      onValueSelected={props.onValueSelected}
    />
  )
}

export default observer(InvoiceDateFilter)
