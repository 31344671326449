import {
  Finance,
  ITransaction,
  ITransactionChanges,
} from '@nextbusiness/infinity-finance-api'
import { Dialog, useNotificationCenter } from '@nextbusiness/infinity-ui'
import { useAccountPageContext } from 'ledger/accounts/AccountPageContext'
import { observer } from 'mobx-react'
import { useContext, useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { TransactionFilterContext } from '../filter/TransactionFilterContext'
import { useBatchEditingContext } from './BatchEditingContext'
import BatchEditingEditor from './BatchEditingEditor'
import './BatchEditingModal.scss'
import BatchEditingPreviewPanel from './BatchEditingPreviewPanel'

export type BatchEditingAction =
  | 'rename'
  | 'changeDate'
  | 'changeContraAccount'
  | 'moveToDifferentAccount'
  | 'delete'

const BatchEditingModal = () => {
  const { transactionStore, accountStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const { currentAccount } = useAccountPageContext()
  const batchEditingContext = useBatchEditingContext()
  const filterContext = useContext(TransactionFilterContext)

  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isDeletionInProgress, setIsDeletionInProgress] =
    useState<boolean>(false)

  const [changesToTransaction, setChangesToTransaction] = useState<
    Partial<ITransactionChanges>
  >({})

  const [hasWarning, setHasWarning] = useState<boolean>(false)

  const [newAccountNumber, setNewAccountNumber] = useState<number>()

  const action = batchEditingContext.selectedBatchEditingAction
  const initialFocusIndex = action === 'rename' ? 0 : 1

  useEffect(() => {
    if (batchEditingContext.isBatchActionModalOpen) {
      setChangesToTransaction({})
      setNewAccountNumber(undefined)
    }
  }, [batchEditingContext.isBatchActionModalOpen])

  if (!batchEditingContext.selectedBatchEditingAction) return null

  const closeModal = () => batchEditingContext.setIsBatchActionModalOpen(false)

  const mainButtonActionText = () => {
    switch (batchEditingContext.selectedBatchEditingAction) {
      case 'rename':
        return 'Ausgewählte umbenennen'
      case 'changeDate':
        return 'Ausgewählte umdatieren'
      case 'changeContraAccount':
        return 'Gegenkonten wechseln'
      case 'moveToDifferentAccount':
        return 'Ausgewählte verschieben'
      case 'delete':
        return 'Ausgewählte löschen'
    }
  }

  const isSelectedActionDelete = () =>
    batchEditingContext.selectedBatchEditingAction === 'delete'

  const mainButtonType = () =>
    isSelectedActionDelete() ? 'destructive' : 'primary'
  const mainButtonAction = () =>
    isSelectedActionDelete() ? deleteTransactions() : editTransactions()

  const dialogTitle = () => {
    const numberOfSelected = batchEditingContext.selectedTransactions.length
    const isPlural = numberOfSelected > 1
    const numberOfTransactionsText = `${numberOfSelected} ${
      isPlural ? 'Transaktionen' : 'Transaktion'
    }`
    switch (batchEditingContext.selectedBatchEditingAction) {
      case 'rename':
        return `${numberOfTransactionsText} umbenennen`
      case 'changeDate':
        return `${numberOfTransactionsText} umdatieren`
      case 'changeContraAccount':
        return `Gegenkonto von ${numberOfTransactionsText} wechseln`
      case 'moveToDifferentAccount':
        return `${numberOfTransactionsText} verschieben`
      case 'delete':
        return `${numberOfTransactionsText} unwiderruflich löschen`
    }
  }

  const filteringForSelectedTransactions = () => {
    return transactionStore.transactions[currentAccount.accountNumber]?.filter(
      (transaction) =>
        batchEditingContext.selectedTransactions.includes(transaction.id)
    )
  }

  const isCurrentAccountDebit = (currentTransaction: ITransaction) =>
    currentTransaction.debitAccount === currentAccount.accountNumber

  const sameAccountOnBothSidesError = () => {
    notificationCenter.addNotification({
      title: 'Änderungen konnten nicht gespeichert werden',
      children:
        'Das aktuelle Konto und das Gegenkonto müssen sich unterscheiden',
      variant: 'warning',
    })
  }

  const accountSideForNewAccountNumber = (transaction: ITransaction) => {
    setHasWarning(false)
    if (isCurrentAccountDebit(transaction)) {
      if (transaction.debitAccount === newAccountNumber) {
        setHasWarning(true)
        sameAccountOnBothSidesError()
        return
      }
      if (
        batchEditingContext.selectedBatchEditingAction === 'changeContraAccount'
      ) {
        return 'creditAccount'
      }
      if (
        batchEditingContext.selectedBatchEditingAction ===
        'moveToDifferentAccount'
      ) {
        return 'debitAccount'
      }
    } else {
      if (transaction.creditAccount === newAccountNumber) {
        setHasWarning(true)
        sameAccountOnBothSidesError()
        return
      }
      if (
        batchEditingContext.selectedBatchEditingAction === 'changeContraAccount'
      ) {
        return 'debitAccount'
      }
      if (
        batchEditingContext.selectedBatchEditingAction ===
        'moveToDifferentAccount'
      ) {
        return 'creditAccount'
      }
    }
  }

  const resetChangesToTransaction = () => {
    setChangesToTransaction({
      ...changesToTransaction,
      debitAccount: undefined,
      creditAccount: undefined,
    })
  }

  const editTransactions = async () => {
    if (!batchEditingContext.selectedTransactions) return
    try {
      setIsSaving(true)

      let newTransactions: ITransaction[] = []
      const existingTransactionIds = batchEditingContext.selectedTransactions

      if (newAccountNumber) {
        await Promise.all(
          filteringForSelectedTransactions()!.map(async (transaction) => {
            const correctAccountSide =
              accountSideForNewAccountNumber(transaction)

            if (!hasWarning) {
              const modifiedTransactions =
                await Finance.Ledger.modifyTransaction(transaction.id, {
                  [correctAccountSide!]: newAccountNumber,
                })
              newTransactions.push(...modifiedTransactions)
            }
          })
        )
        setChangesToTransaction({
          ...changesToTransaction,
          debitAccount: undefined,
          creditAccount: undefined,
        })
      } else {
        const transactionIds = batchEditingContext.selectedTransactions
        newTransactions = await Finance.Ledger.modifyTransactions(
          transactionIds,
          changesToTransaction
        )
      }

      transactionStore.replaceTransactionsInStore(
        existingTransactionIds,
        newTransactions,
        filterContext?.isFiltering
      )
      accountStore.loadAccounts()
      batchEditingContext.setSelectedTransactions([])
      closeModal()
    } catch (error: any) {
      notificationCenter.addNotification({
        title: 'Änderungen konnten nicht gespeichert werden',
        children: error?.message,
        variant: 'error',
      })
    } finally {
      if (!hasWarning) {
        resetChangesToTransaction()
        setNewAccountNumber(undefined)
      }
      setIsSaving(false)
    }
  }

  const deleteTransactions = async () => {
    setIsDeletionInProgress(true)
    try {
      await transactionStore.deleteTransactions(
        transactionStore.transactions[currentAccount.accountNumber]!.filter(
          (transaction) =>
            batchEditingContext.selectedTransactions.includes(transaction.id)
        )
      )

      accountStore.loadAccounts()
      batchEditingContext.setSelectedTransactions([])
      closeModal()
    } catch (error: any) {
      notificationCenter.addNotification({
        title: 'Eine oder mehrere Transaktionen konnten nicht gelöscht werden.',
        children: error?.message,
        variant: 'error',
      })
    } finally {
      setIsDeletionInProgress(false)
    }
  }

  return (
    <Dialog
      isOpen={batchEditingContext.isBatchActionModalOpen}
      title={dialogTitle()}
      titleProps={{ divider: true }}
      initialFocusIndex={initialFocusIndex}
      className='batch-editing-modal'
      actions={[
        {
          children: 'Abbrechen',
          onClick: () => closeModal(),
        },
        {
          variant: mainButtonType(),
          children: mainButtonActionText(),
          isLoading: isSelectedActionDelete() ? isDeletionInProgress : isSaving,
          onClick: () => mainButtonAction(),
          disabled: changesToTransaction.description?.trim() === '',
        },
      ]}
    >
      <BatchEditingEditor
        changesToTransaction={changesToTransaction}
        setChangesToTransaction={setChangesToTransaction}
        newAccountNumber={newAccountNumber}
        setNewAccountNumber={setNewAccountNumber}
      />
      <BatchEditingPreviewPanel
        changesToTransaction={changesToTransaction}
        filteringForSelectedTransactions={filteringForSelectedTransactions}
        newAccountNumber={newAccountNumber}
      />
    </Dialog>
  )
}
export default observer(BatchEditingModal)
