import CustomerInvoicePositions from 'invoices/customer-invoices/CustomerInvoicePositions'
import { observer } from 'mobx-react'
import { useContext, useEffect, useRef } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { INewCustomerInvoice } from '../../../../../../model/CustomerInvoice'
import Utilities from '../../../../../../utility/Utilities'
import DocumentRendererContext from '../../DocumentRendererContext'
import DocumentElementProps from '../DocumentElementProps'
import VATPresentationOptionsFlyout from './flyout/VATPresentationOptionsFlyout'
import { AmountMode } from '@nextbusiness/infinity-finance-api'

interface DocumentPositionsTotalsProps extends DocumentElementProps {
  vatSumRows: React.ReactNode
}

const DocumentPositionsTotals = (props: DocumentPositionsTotalsProps) => {
  const { preferencesStore } = useRootStore()

  const refTotalIncl = useRef<HTMLDivElement>(null)
  const refTotalExcl = useRef<HTMLDivElement>(null)
  const renderContext = useContext(DocumentRendererContext)

  const positions = new CustomerInvoicePositions(
    props.document as INewCustomerInvoice
  )
  const totalLineText = preferencesStore.organisationPreferences?.VAT
    ? 'Gesamttotal inkl. MWST'
    : 'Gesamttotal (MWST-befreit)'

  const isAnnulled = props.existingInvoice?.isAnnulled

  const hideVAT =
    props.document.vatPresentationOptions?.hideVATInQuotes &&
    props.document.isQuote &&
    props.document.amountMode === AmountMode.Net &&
    preferencesStore.organisationPreferences?.VAT

  const showNetAmount =
    (props.document.vatPresentationOptions?.showNetAmountSubtotal || hideVAT) &&
    preferencesStore.organisationPreferences?.VAT

  useEffect(() => {
    if (!props.page || props.page.positionsSumRowExclVAT) {
      renderContext.updateHeights({
        positionsSumRowExclVAT: refTotalExcl.current?.clientHeight ?? 0,
      })
    }
    if (!props.page || props.page.positionsSumRowInclVAT) {
      renderContext.updateHeights({
        positionsSumRowInclVAT: refTotalIncl.current?.clientHeight ?? 0,
      })
    }
  }, [
    props.page?.positionsSumRowInclVAT,
    props.page?.positionsSumRowExclVAT,
    showNetAmount,
  ])

  return (
    <div className='positions-table-footer'>
      {showNetAmount && (!props.page || props.page.positionsSumRowExclVAT) && (
        <VATPresentationOptionsFlyout
          readonly={!props.isEditable || !hideVAT}
          document={props.document}
          registerEdit={props.registerEdit}
        >
          <div className='positions-row' ref={refTotalExcl}>
            <div className='positions-column text'>Gesamttotal exkl. MWST</div>
            <div className='positions-column total-amount'>
              {Utilities.centsToCHF(positions.netTotal)} CHF
            </div>
          </div>
        </VATPresentationOptionsFlyout>
      )}
      {!hideVAT && props.vatSumRows}
      {!hideVAT && (!props.page || props.page.positionsSumRowInclVAT) && (
        <VATPresentationOptionsFlyout
          readonly={!props.isEditable}
          document={props.document}
          registerEdit={props.registerEdit}
        >
          <div className='positions-row' ref={refTotalIncl}>
            <div className='positions-column text'>{totalLineText}</div>
            <div className='positions-column total-amount'>
              {Utilities.centsToCHF(positions.grossTotal)} CHF
            </div>
          </div>
        </VATPresentationOptionsFlyout>
      )}
      {isAnnulled && (
        <>
          <div className='positions-row minor-total'>
            <div className='positions-column text'>Stornogutschrift</div>
            <div className='positions-column total-amount'>
              {Utilities.centsToCHF(-positions.grossTotal)} CHF
            </div>
          </div>
          <div className='positions-row'>
            <div className='positions-column text'>Gesamttotal nach Storno</div>
            <div className='positions-column total-amount'>0.00 CHF</div>
          </div>
        </>
      )}
    </div>
  )
}

export default observer(DocumentPositionsTotals)
