import { InputField } from '@nextbusiness/infinity-ui'
import { useEffect, useRef } from 'react'
import { useTransactionEditorContext } from '../TransactionEditorContext'

const TransactionEditorDescription = () => {
  const { draftTransaction, updateDraftTransaction, canChangeDescription } =
    useTransactionEditorContext()
  const descriptionInputFieldRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    descriptionInputFieldRef.current?.focus()
  }, [])

  return (
    <InputField
      className='description'
      placeholder='Beschreibung'
      ref={descriptionInputFieldRef}
      bare
      value={draftTransaction.description}
      onChange={(description) => updateDraftTransaction({ description })}
      disabled={!canChangeDescription}
    />
  )
}

export default TransactionEditorDescription
