import FinancialHealthContext from '../FinancialHealthContext'
import FinancialHealthMetric from '../FinancialHealthMetric'
import FinancialHealthStatusGroup from '../FinancialHealthStatusGroup'
import MetricOverdueAccountsPayable from '../metrics/MetricOverdueAccountsPayable'
import MetricOverdueAccountsReceivable from '../metrics/MetricOverdueAccountsReceivable'
import MetricOverdueAccountsReceivableRatio from '../metrics/MetricOverdueAccountsReceivableRatio'

class StatusGroupMaturities extends FinancialHealthStatusGroup {
  title = 'Fälligkeiten'

  setupMetrics(context: FinancialHealthContext): FinancialHealthMetric[] {
    return [
      new MetricOverdueAccountsPayable(context),
      new MetricOverdueAccountsReceivable(context),
      new MetricOverdueAccountsReceivableRatio(context),
    ]
  }
}

export default StatusGroupMaturities
