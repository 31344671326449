import { KeyValueStore } from './types'

export const linkify = (link: string) => {
  const escapedLink = preventXSS(link)
  if (escapedLink.startsWith('http://') || escapedLink.startsWith('https://')) {
    return escapedLink
  } else {
    return `https://${escapedLink}`
  }
}

export const preventXSS = (link: string) => {
  return link.replaceAll('javascript:', '')
}

export const areStringsEmpty = (keyValueStore: KeyValueStore) => {
  let hasContent = false
  Object.keys(keyValueStore).forEach((key) => {
    if (keyValueStore[key] !== '') hasContent = true
  })
  return !hasContent
}

export const areIdentical = (
  a: KeyValueStore,
  b: KeyValueStore,
  shouldReturn?: boolean
): boolean => {
  if (!a || !b) return false
  for (const key in a) {
    if (!a.hasOwnProperty(key)) continue

    const areDifferent = a[key] !== b[key]
    const areBothEmpty = !a[key] && !b[key]

    if (areDifferent && !areBothEmpty) return false
  }
  if (shouldReturn) return true
  else return areIdentical(b, a, true) // NOSONAR
}

export const truncateString = (string: string | undefined, limit: number) => {
  if (!string) return

  if (string.length > limit) return string.slice(0, limit) + '...'
  else return string
}

export const centsToCHF = (
  amount: number | undefined,
  shouldDisplayNegative?: boolean
) => {
  if (!amount) return '0.00'
  const chf = new Intl.NumberFormat('en-EN', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format((shouldDisplayNegative ? -amount : amount) / 100)
    .replaceAll(',', "'")

  return chf
}

export const parseToCents = (
  amount: string,
  options?: { treatZeroAsEmpty?: boolean }
) => {
  const parsedAmount = amount.replaceAll("'", '').replaceAll(' ', '')
  const numericAmount = parseFloat(parsedAmount)

  if (isNaN(numericAmount)) return undefined
  if (!numericAmount && options?.treatZeroAsEmpty) return undefined

  const amountInCents = Math.floor(numericAmount * 100)
  return amountInCents
}

export const queueNavigationTask = (task: () => void) => {
  const NAVIGATION_DELAY = 400
  window.setTimeout(() => task(), NAVIGATION_DELAY)
}

export const round = (number: number, decimals: number) =>
  Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals)

export const scrollToTop = (of: Element | null, options?: ScrollToOptions) =>
  of?.scrollTo({ top: 0, ...options })

export const scrollToBottom = (of: Element | null, options?: ScrollToOptions) =>
  of?.scrollTo({
    top: of.scrollHeight,
    ...options,
  })

export const deduplicate = <T>(array: T[]) => Array.from(new Set(array))

const onlyDefinedProperties = <T extends Record<string, any>>(
  obj: T
): Partial<T> => {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => value !== undefined)
  ) as Partial<T>
}

const Utilities = {
  linkify,
  preventXSS,
  areStringsEmpty,
  areIdentical,
  truncateString,
  centsToCHF,
  parseToCents,
  queueNavigationTask,
  round,
  scrollToTop,
  scrollToBottom,
  onlyDefinedProperties,
}

export default Utilities
