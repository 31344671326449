import { ResolvedAccountAction } from 'ledger/accounts/data/AccountRules'
import './TransactionCreator.scss'
import TransactionCreatorPostButton from './TransactionCreatorPostButton'
import TransactionCreatorProvider from './TransactionCreatorProvider'
import TransactionCreatorTabs from './TransactionCreatorTabs'
import TransactionCreatorAmount from './fields/TransactionCreatorAmount'
import TransactionCreatorContraAccount from './fields/TransactionCreatorContraAccount'
import TransactionCreatorDate from './fields/TransactionCreatorDate'
import TransactionCreatorDescription from './fields/TransactionCreatorDescription'
import TransactionCreatorExchangeRate from './fields/TransactionCreatorExchangeRate'
import TransactionCreatorContainer from './layout/TransactionCreatorContainer'
import TransactionCreatorFields from './layout/TransactionCreatorFields'
import TransactionCreatorFooter from './layout/TransactionCreatorFooter'
import TransactionCreatorInboundCheckbox from './options/TransactionCreatorInboundCheckbox'
import TransactionCreatorVATOptions from './options/TransactionCreatorVATOptions'

export interface TransactionCreatorProps {
  activeAction: ResolvedAccountAction
  setActiveAction: (activeAction: ResolvedAccountAction) => void
  shouldCurrentAccountIncrease: boolean
  setShouldCurrentAccountIncrease: (
    shouldCurrentAccountIncrease: boolean
  ) => void
}

const TransactionCreator = (props: TransactionCreatorProps) => (
  <TransactionCreatorProvider {...props}>
    <TransactionCreatorContainer>
      <TransactionCreatorTabs />
      <TransactionCreatorFields>
        <TransactionCreatorDate />
        <TransactionCreatorDescription />
        <TransactionCreatorContraAccount />
        <TransactionCreatorAmount />
        <TransactionCreatorExchangeRate />
      </TransactionCreatorFields>
      <TransactionCreatorFooter>
        <TransactionCreatorVATOptions />
        <TransactionCreatorInboundCheckbox />
        <TransactionCreatorPostButton />
      </TransactionCreatorFooter>
    </TransactionCreatorContainer>
  </TransactionCreatorProvider>
)

export default TransactionCreator
