import MobileHint from 'components/mobile-guard/MobileHint'

const MobileMorePage = () => (
  <MobileHint
    title='Auf dem Computer kannst du alle Funktionen von Infinity Finance nutzen.'
    showMobileAppTeaser
    inline
  />
)

export default MobileMorePage
