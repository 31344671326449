import {
  Dialog,
  FeedbackBanner,
  Flex,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import { BackendError, ErrorType, isBackendError } from 'libs/networking/Errors'
import { observer } from 'mobx-react-lite'
import { DetailedVATPeriod } from 'model/VAT'
import VAT from 'networking/VAT'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import './VATSettlementModal.scss'

interface VATSettlementModalProps {
  isOpen: boolean
  onDismiss: () => void
  onSettlementSuccessful: () => void
  period: DetailedVATPeriod
}

const VATSettlementModal = (props: VATSettlementModalProps) => {
  const { authenticationStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [isPerfomingSettlement, setIsPerfomingSettlement] =
    useState<boolean>(false)
  const [invalidReportError, setInvalidReportError] = useState<BackendError>()

  const periodHasCriticalCalculationMismatch =
    props.period.report['299'].amount !== props.period.report['379'].amount

  const settlePeriod = async () => {
    setIsPerfomingSettlement(true)
    setInvalidReportError(undefined)
    try {
      await VAT.settlePeriod(
        props.period._id,
        authenticationStore.organisationIdentifier
      )
      notificationCenter.addNotification({
        children: 'Die MWST-Periode wurde erfolgreich abgerechnet.',
        variant: 'success',
      })
      props.onSettlementSuccessful()
      props.onDismiss()
    } catch (error) {
      if (isBackendError(error) && error.type === ErrorType.NotAllowed) {
        setInvalidReportError(error)
        return
      } else if (error instanceof Error) {
        notificationCenter.addNotification({
          title:
            'Die MWST-Periode konnte nicht abgerechnet werden. Prüfe die Angaben und versuche es erneut.',
          children: error.message,
          variant: 'error',
        })
      }
    } finally {
      setIsPerfomingSettlement(false)
    }
  }

  return (
    <Dialog
      className='vat-settlement-modal'
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      title='Periode abrechnen'
      titleProps={{ divider: true }}
      actions={[
        {
          children: 'Abbrechen',
          onClick: () => props.onDismiss(),
        },
        {
          children: 'Periode abrechnen',
          variant: 'primary',
          onClick: () => settlePeriod(),
          isLoading: isPerfomingSettlement,
          disabled: periodHasCriticalCalculationMismatch,
        },
      ]}
    >
      <Text>
        Wenn du die Angaben eingereicht hast, kannst du die Periode abrechnen.
        Dabei werden die Steuerschulden und -gutschriften verrechnet.
      </Text>
      <Flex
        className='no-edit-after-settlement'
        verticalAlignment='center'
        gap='tiny'
      >
        <Icon icon='no-edit' size={IconSize.Small} />
        <Text type='inline'>
          Die Transaktionen aus dieser Periode können danach nicht mehr
          bearbeitet werden.
        </Text>
      </Flex>
      {periodHasCriticalCalculationMismatch && (
        <FeedbackBanner
          variant='error'
          title='Die MWST-Periode kann noch nicht abgerechnet werden, weil noch Fehler bestehen.'
        >
          <Text>
            Die Beträge für die Ziffern des steuerbaren Gesamtumsatz (Ziffern
            299 und 379) müssen übereinstimmen, damit die Periode abgerechnet
            werden kann.
          </Text>
          <Text>
            Diese stimmen in der Regel nicht überein, wenn noch Fehler in der
            Buchhaltung bestehen. Bitte überprüfe, ob die Einnahmen in dieser
            Periode korrekt verbucht wurden.
          </Text>
        </FeedbackBanner>
      )}
      {invalidReportError && (
        <FeedbackBanner
          variant='error'
          title='Die MWST-Periode konnte nicht abgerechnet werden, weil noch Fehler bestehen.'
        >
          {invalidReportError.additionalData?.invalidDigits ? (
            <div>
              <Text>
                Die folgenden Ziffern weisen einen ungültigen Saldo auf. Prüfe
                bei deren Transaktionen, ob sie korrekt verbucht sind:
              </Text>
              <ul>
                {invalidReportError.additionalData.invalidDigits.map(
                  (invalidDigit: string) => (
                    <li key={invalidDigit}>Ziffer {invalidDigit}</li>
                  )
                )}
              </ul>
            </div>
          ) : (
            <Text>
              Bitte überprüfe die Transaktionen in dieser Periode.
              Möglicherweise sind einige noch nicht korrekt verbucht.
            </Text>
          )}
        </FeedbackBanner>
      )}
    </Dialog>
  )
}

export default observer(VATSettlementModal)
