import { AccountMethod } from '@nextbusiness/infinity-finance-api'
import { Flex, Heading } from '@nextbusiness/infinity-ui'
import { useLedgerContext } from 'ledger/LedgerContext'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import { centsToCHF } from 'utility/Utilities'
import AccountLiveMenu from './AccountLiveMenu'
import './AccountTitleBar.scss'
import AccountTitleBarAmountLoader from './AccountTitleBarAmountLoader'
import AccountWaffleMenu from './AccountWaffleMenu'

interface AccountTitleBarProps {
  title: string
  balance?: number
  accountNumber?: number
  showAccountNumber?: boolean
}

const AccountTitleBar = (props: AccountTitleBarProps) => {
  const ledgerContext = useLedgerContext()
  const { accountStore } = useRootStore()

  const account = accountStore.find(props.accountNumber)
  const isLiveEnabled = account?.accountMethod === AccountMethod.Live

  return (
    <Flex
      className='view-title-bar'
      horizontalAlignment='space-between'
      verticalAlignment='baseline'
    >
      <Flex verticalAlignment='center'>
        {ledgerContext.openChartOfAccountsPage && (
          <AccountWaffleMenu
            openChartOfAccountsPage={ledgerContext.openChartOfAccountsPage}
          />
        )}
        <Heading type='h2' divider>
          {props.accountNumber && props.showAccountNumber && (
            <span className='account-number'>{props.accountNumber}</span>
          )}
          <span className='title'>{props.title}</span>
        </Heading>
      </Flex>
      <Flex gap='tiny' verticalAlignment='center'>
        {props.balance !== undefined && (
          <Flex className='balance' verticalAlignment='center'>
            <Heading className='amount' type='h2'>
              {accountStore.isLoadingAccounts ? (
                <AccountTitleBarAmountLoader />
              ) : (
                <span>{centsToCHF(props.balance)}</span>
              )}
            </Heading>
            <div className='currency'>CHF</div>
          </Flex>
        )}
        {isLiveEnabled && <AccountLiveMenu />}
      </Flex>
    </Flex>
  )
}

export default observer(AccountTitleBar)
