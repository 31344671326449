import { translatedContactKeys } from 'model/Contact'
import { useContext } from 'react'
import { ContactContext } from './ContactContext'
import SingleContactInfoRow from './SingleContactInfoRow'
import './SingleContactTripletInfo.scss'

interface ComponentTripletInfoProps {
  infoList: string[]
}

const SingleContactTripletInfo = (props: ComponentTripletInfoProps) => {
  const contact = useContext(ContactContext)
  const infoList = props.infoList
  return (
    <div className='contact-main-info-column'>
      {infoList.map((contactKey: string) => (
        <SingleContactInfoRow
          key={contactKey}
          contactKey={translatedContactKeys[contactKey]}
          contactValue={contact?.[contactKey as keyof typeof contact]}
        />
      ))}
    </div>
  )
}

export default SingleContactTripletInfo
