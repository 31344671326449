import { Flex } from '@nextbusiness/infinity-ui'
import { Contact } from 'model/Contact'
import ContactUtilities from 'utility/ContactUtilites'

interface ContactHeadingAdditionalInformationProps {
  contact: Contact
}

const ContactHeadingAdditionalInformation = (
  props: ContactHeadingAdditionalInformationProps
) => {
  const { contact } = props
  const personName = ContactUtilities.personName(contact, true)
  if (!personName && !contact.position) {
    return null
  }
  const showPersonName = contact.companyName && personName
  const showSeparator = showPersonName && contact.position
  return (
    <Flex direction='horizontal' className='name-position' gap='tiny'>
      <p className='contact-heading-additional-information'>
        {`${showPersonName ? personName : ''}${showSeparator ? ' · ' : ''}${
          contact.position ?? ''
        }
        `}
      </p>
    </Flex>
  )
}

export default ContactHeadingAdditionalInformation
