import { KeyValueStore } from '../utility/types'

export interface Contact {
  id: string
  companyName?: string
  firstname?: string
  lastname?: string
  address?: string
  secondaryAddressLine?: string
  postCode?: string
  position?: string
  email?: string
  mobile?: string
  phone?: string
  website?: string
  note?: string
  organisationId?: string
  category: string
  keywords?: string[]
  vatNumber?: string
  formOfAddress?: string
  country?: string
}

export const translatedContactKeys: KeyValueStore = {
  address: 'Adresse',
  secondaryAddressLine: 'Adresszusatz',
  postCode: 'PLZ und Ort',
  email: 'E-Mail',
  mobile: 'Mobilnummer',
  phone: 'Telefonnummer',
  website: 'Webseite',
  keywords: 'Schlüsselwörter',
  category: 'Kategorie',
  vatNumber: 'MWST-Nummer',
  formOfAddress: 'Anrede',
  country: 'Land',
  note: 'Bemerkungen',
}
