import classNames from 'classnames'
import ContactSwatch from 'invoices/shared-components/ContactSwatch'
import ExtendedInvoiceIconAmount from 'invoices/shared-components/invoice-icon/ExtendedInvoiceIconAmount'
import './ExtendedProjectIcon.scss'
import ProjectIcon from './ProjectIcon'

interface ExtendedProjectIconProps {
  value: number
  client: string
  small?: boolean
}

const ExtendedProjectIcon = (props: ExtendedProjectIconProps) => (
  <div className={classNames('extended-project-icon', props.small && 'small')}>
    <ProjectIcon />
    {!props.small && <ExtendedInvoiceIconAmount totalAmount={props.value} />}
    <ContactSwatch contactId={props.client} small={props.small} />
  </div>
)

export default ExtendedProjectIcon
