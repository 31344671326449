import { Font } from '@nextbusiness/infinity-finance-api/dist/models/ITemplate'
import { Button, Flex, Text } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import { useEffect } from 'react'
import './TemplateInspectorTypefacePicker.scss'

const LEGACY_FONTS = ['rounded'] as const

type AvailableFont = Exclude<Font, (typeof LEGACY_FONTS)[number]>

const FONT_DISPLAY_NAMES: Record<AvailableFont, string> = {
  default: 'Standard',
  professional: 'Modern',
  'rounded-v2': 'Rund',
  serif: 'Serifen',
  'transitional-serif': 'Transitional',
  'swiss-style': 'Swiss',
  'constructed-style': 'Constructed',
  neutral: 'Neutral',
  suisse: 'Suisse',
}

interface TemplateInspectorTypefacePickerProps {
  selectedFont: Font
  onFontChange: (font: Font) => void
}

const TemplateInspectorTypefacePicker = (
  props: TemplateInspectorTypefacePickerProps
) => {
  const isUsingLegacyFont = (LEGACY_FONTS as readonly string[]).includes(
    props.selectedFont
  )
  const selectableFonts = Object.keys(FONT_DISPLAY_NAMES) as AvailableFont[]

  const scrollSelectionIntoView = () =>
    document.querySelector(`.font-item.selected`)?.scrollIntoView({
      block: 'nearest',
    })
  useEffect(() => {
    scrollSelectionIntoView()
  }, [props.selectedFont])

  return (
    <div className='template-inspector-typeface-picker'>
      <Flex direction='vertical' gap={0.8}>
        {isUsingLegacyFont && (
          <Text className='legacy-warning' variant='warning' type='inline'>
            Die zurzeit ausgewählte Schrift steht nicht mehr zur Auswahl. Wenn
            du die Schrift änderst, wirst du nicht mehr zurückkehren können.
          </Text>
        )}
        <Flex className='fonts' gap={1}>
          {selectableFonts.map((font) => (
            <Button
              key={font}
              className={classNames('font-item', {
                selected: props.selectedFont === font,
              })}
              variant='shell'
              onClick={() => props.onFontChange(font)}
            >
              <Flex
                className={'preview ' + font}
                verticalAlignment='center'
                horizontalAlignment='center'
              >
                Aa
              </Flex>
              <Text className='name' type='inline'>
                {FONT_DISPLAY_NAMES[font]}
              </Text>
            </Button>
          ))}
        </Flex>
      </Flex>
    </div>
  )
}

export default TemplateInspectorTypefacePicker
