import {
  Button,
  DatePicker,
  DateSuggestion,
  Text,
} from '@nextbusiness/infinity-ui'
import IncomeStatementIcon from 'assets/income-statement-icon.svg'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import './IncomeStatementHeader.scss'

interface IncomeStatementHeaderProps {
  fromDate: Date | undefined
  toDate: Date | undefined
  setFromDate: (fromDate: Date | undefined) => void
  setToDate: (toDate: Date | undefined) => void
  fiscalYearStartDates: DateSuggestion[]
  fiscalYearEndDates: DateSuggestion[]
  hasScrolled: boolean
  dataHasFullyLoaded: boolean
}

const IncomeStatementHeader = (props: IncomeStatementHeaderProps) => {
  const fiscalYears = props.fiscalYearStartDates.map((entry, index) => {
    return {
      index,
      from: entry.date,
      to: props.fiscalYearEndDates[index]?.date,
    }
  })

  return (
    <div
      className={
        'income-statement-header' + (props.hasScrolled ? ' has-scrolled' : '')
      }
    >
      <MagicSheetTitleBar title='Erfolgsrechnung' />
      <div className='depiction-grid'>
        <div className='illustration'>
          <img src={IncomeStatementIcon} alt='' height='96' />
        </div>
        <Text className='description'>
          In der Erfolgsrechnung siehst du deine Einnahmen und Ausgaben, die
          über einen Zeitraum anfallen. Die Differenz zwischen Einnahmen und
          Ausgaben ergibt dein Betriebsergebnis.
        </Text>
        <DatePicker
          value={props.fromDate}
          onChange={(date) => {
            const fromDateIsAfterToDate =
              date && props.toDate && date.valueOf() > props.toDate.valueOf()

            props.setFromDate(date)

            if (fromDateIsAfterToDate) {
              const indexOfFiscalYear = fiscalYears.find(
                (fiscalYear) =>
                  fiscalYear.from.valueOf() <= date.valueOf() &&
                  fiscalYear.to.valueOf() >= date.valueOf()
              )

              if (indexOfFiscalYear)
                props.setToDate(
                  props.fiscalYearEndDates[indexOfFiscalYear.index]?.date
                )
            }
          }}
          inputFieldProps={{ icon: 'date-from' }}
          additionalDates={props.fiscalYearStartDates}
        />
        <div className='date-picker-to'>
          <DatePicker
            value={props.toDate}
            onChange={(date) => {
              const toDateIsBeforeFromDate =
                date &&
                props.fromDate &&
                date.valueOf() < props.fromDate.valueOf()

              props.setToDate(date)

              if (toDateIsBeforeFromDate) {
                const indexOfFiscalYear = fiscalYears.find(
                  (fiscalYear) =>
                    fiscalYear.from.valueOf() <= date.valueOf() &&
                    fiscalYear.to.valueOf() >= date.valueOf()
                )

                if (indexOfFiscalYear) {
                  props.setFromDate(
                    props.fiscalYearStartDates[indexOfFiscalYear.index]?.date
                  )
                }
              }
            }}
            inputFieldProps={{ icon: 'date-to' }}
            additionalDates={props.fiscalYearEndDates}
          />
        </div>
        {props.dataHasFullyLoaded && (
          <Button
            className='pdf-button'
            onClick={() => window.print()}
            iconLeft='print'
            tooltip='Erfolgsrechnung drucken oder als PDF abspeichern'
          >
            Drucken
          </Button>
        )}
      </div>
    </div>
  )
}

export default IncomeStatementHeader
