import { AmountMode } from '@nextbusiness/infinity-finance-api'
import { observer } from 'mobx-react'
import { IVendorInvoice, IVendorInvoiceDraft } from 'model/VendorInvoice'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { generateRandomId } from 'utility/StringUtilities'
import VendorInvoiceEditorUtilities from './VendorInvoiceEditorUtilitites'

interface VendorInvoiceEditorContextType {
  invoiceDraft: IVendorInvoiceDraft
  isInvoiceDraftTouched: boolean
  invoiceDraftTotal: number
  makeEdit: (changes: Partial<IVendorInvoiceDraft>) => void
  resetInvoiceDraft: () => void
  shouldValidate: boolean
  setShouldValidate: (shouldValidate: boolean) => void
  isValid: boolean
  amountMode: AmountMode
}

const VendorInvoiceEditorContext =
  React.createContext<VendorInvoiceEditorContextType | null>(null)

interface VendorInvoiceEditorProviderProps {
  existingInvoice?: IVendorInvoice
  children: React.ReactNode
}

const VendorInvoiceEditorProvider = (
  props: VendorInvoiceEditorProviderProps
) => {
  const { accountStore } = useRootStore()
  const { existingInvoice } = props

  const [invoiceDraft, setInvoiceDraft] = useState<IVendorInvoiceDraft>({})

  const [isInvoiceDraftTouched, setIsInvoiceDraftTouched] =
    useState<boolean>(false)
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)

  const invoiceDraftTotal = useMemo(
    () => VendorInvoiceEditorUtilities.totalSumOfInvoiceWithVAT(invoiceDraft),
    [invoiceDraft]
  )
  const isValid = useMemo(() => {
    return VendorInvoiceEditorUtilities.isValid(invoiceDraft, accountStore)
  }, [invoiceDraft])

  const amountMode = invoiceDraft.amountMode ?? AmountMode.Gross

  const makeEdit = useCallback((changes: Partial<IVendorInvoiceDraft>) => {
    setInvoiceDraft((previousDraft) => ({
      ...previousDraft,
      ...changes,
    }))
    setIsInvoiceDraftTouched(true)
  }, [])

  const resetInvoiceDraft = useCallback(() => {
    setInvoiceDraft({
      ...existingInvoice,
      positions: existingInvoice?.positions.map((position) => ({
        ...position,
        renderId: generateRandomId(),
      })) ?? [{ renderId: generateRandomId() }],
    })
    setIsInvoiceDraftTouched(false)
    setShouldValidate(false)
  }, [existingInvoice])

  useEffect(() => {
    resetInvoiceDraft()
  }, [existingInvoice])

  const contextValue = useMemo(
    () => ({
      invoiceDraft,
      isInvoiceDraftTouched,
      invoiceDraftTotal,
      makeEdit,
      resetInvoiceDraft,
      shouldValidate,
      setShouldValidate,
      isValid,
      amountMode,
    }),
    [
      invoiceDraft,
      isInvoiceDraftTouched,
      invoiceDraftTotal,
      makeEdit,
      resetInvoiceDraft,
      shouldValidate,
      setShouldValidate,
      isValid,
      amountMode,
    ]
  )

  return (
    <VendorInvoiceEditorContext.Provider value={contextValue}>
      {props.children}
    </VendorInvoiceEditorContext.Provider>
  )
}

export const useVendorInvoiceEditor = () => {
  const context = useContext(VendorInvoiceEditorContext)
  if (!context)
    throw new Error(
      'useVendorInvoiceEditor must be used within a VendorInvoiceEditorProvider'
    )
  return context
}

export default observer(VendorInvoiceEditorProvider)
