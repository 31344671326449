import { IPresetListItem, PRESET_CATEGORIES } from 'model/Preset'
import PresetModalContentSection from './PresetModalContentSection'

export type PresetSectionCategory = keyof Omit<typeof PRESET_CATEGORIES, 'all'>

interface PresetCategorySectionProps {
  category: PresetSectionCategory
  presets: IPresetListItem[]
  isNewDocumentQuote: boolean
  showBlankPreset: boolean
  openDocumentEditor: () => void
}

const PresetCategorySection = (props: PresetCategorySectionProps) => (
  <PresetModalContentSection
    {...props}
    sectionTitle={PRESET_CATEGORIES[props.category]}
  />
)

export default PresetCategorySection
