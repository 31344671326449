import { PointerSensor } from '@dnd-kit/core'
import type { PointerEvent } from 'react'

/**
 * An extended "PointerSensor" that prevent some
 * interactive html element(button, input, textarea, select, option...) from dragging
 */
class SmartPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown' as any,
      handler: ({ nativeEvent: event }: PointerEvent) => {
        return (
          event.isPrimary &&
          event.button === 0 &&
          !isInteractiveElement(event.target as Element)
        )
      },
    },
  ]
}

function isInteractiveElement(element: Element | null) {
  const interactiveElements = [
    'button',
    'input',
    'textarea',
    'select',
    'option',
  ]
  return (
    (element?.tagName &&
      interactiveElements.includes(element.tagName.toLowerCase())) ||
    element?.role === 'button' ||
    element?.className?.includes('ui-select__option') ||
    element?.className?.includes('ui-checkbox')
  )
}

export default SmartPointerSensor
