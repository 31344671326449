import {
  FinancialHealthCard,
  FinancialHealthCardType,
} from '@nextbusiness/infinity-finance-api'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import FinancialHealthAccountCard from './cards/account/FinancialHealthAccountCard'
import FinancialHealthExpensesCard from './cards/expenses-or-income/FinancialHealthExpensesCard'
import FinancialHealthIncomeCard from './cards/expenses-or-income/FinancialHealthIncomeCard'
import FinancialHealthMaturitiesCard from './cards/maturities/FinancialHealthMaturitiesCard'
import FinancialHealthProfitabilityCard from './cards/profitability/FinancialHealthProfitabilityCard'
import FinancialHealthSolvencyCard from './cards/solvency/FinancialHealthSolvencyCard'
import FinancialHealthStatusCard from './cards/status/FinancialHealthStatusCard'

const FinancialHealthCards = () => {
  const { preferencesStore } = useRootStore()

  const financialHealthCards = preferencesStore?.organisationPreferences
    ?.financialHealthCards as FinancialHealthCard[] | undefined

  const cardToComponent = (card: FinancialHealthCard) => {
    // TODO: Replace keys with card ids once they remain consistent between requests
    switch (card.kind) {
      case FinancialHealthCardType.HintsAndProblems:
        return <FinancialHealthStatusCard key={card.kind} />
      case FinancialHealthCardType.FinancialSolvency:
        return <FinancialHealthSolvencyCard key={card.kind} />
      case FinancialHealthCardType.Maturities:
        return <FinancialHealthMaturitiesCard key={card.kind} />
      case FinancialHealthCardType.Profitability:
        return <FinancialHealthProfitabilityCard key={card.kind} />
      case FinancialHealthCardType.InspectorExpenses:
        return <FinancialHealthExpensesCard key={card.kind} />
      case FinancialHealthCardType.InspectorIncome:
        return <FinancialHealthIncomeCard key={card.kind} />
      case FinancialHealthCardType.Account:
        return <FinancialHealthAccountCard key={card.id} card={card} />
      case FinancialHealthCardType.Runway:
      case FinancialHealthCardType.Metric:
        return null
    }
  }

  return (
    <div className='financial-health-cards'>
      {financialHealthCards?.map(cardToComponent)}
    </div>
  )
}

export default observer(FinancialHealthCards)
