import DocumentElementProps from './DocumentElementProps'

const DocumentElementEnvelopeSenderRow = (props: DocumentElementProps) => (
  <div
    className='envelope-sender-row'
    style={{
      visibility: props.template.disableSenderRowLine ? 'hidden' : 'visible',
    }}
  >
    {props.template.organisationName}
    {props.template.organisationName && props.template.addressLine ? `, ` : ''}
    {props.template.addressLine}
    {(props.template.addressLine || props.template.organisationName) &&
    (props.template.zipCode || props.template.city)
      ? ', '
      : ''}
    {props.template.zipCode} {props.template.city}
  </div>
)

export default DocumentElementEnvelopeSenderRow
