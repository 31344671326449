import { Card, Flex, NonIdealState, Text } from '@nextbusiness/infinity-ui'

const PublicInvoicePageError = () => (
  <Flex
    className='public-invoice-page'
    direction='vertical'
    verticalAlignment='center'
    gap={3.4}
  >
    <Card className='public-invoice' bare>
      <NonIdealState
        icon='error'
        title='Dieses Dokument ist nicht mehr verfügbar.'
      >
        <Flex direction='vertical'>
          <Text>
            Dieser Link ist nicht mehr verfügbar. Möglicherweise ist der Link
            abgelaufen oder die zugehörige Offerte oder Rechnung steht nicht
            mehr zur Verfügung.
          </Text>
          <Text>
            Kontaktiere die Unternehmung, welche dir den Link gesendet haben,
            damit sie dir einen neuen Link senden können.
          </Text>
        </Flex>
      </NonIdealState>
    </Card>
  </Flex>
)

export default PublicInvoicePageError
