import {
  Button,
  ButtonProps,
  Flex,
  InputFieldProps,
  ThemeProvider,
  TypedKeyValueStore,
} from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import ResourceSelect, {
  ResourceSelectOption,
} from 'components/resource-select/ResourceSelect'
import ContactPickerDialog from 'contacts/ContactPickerDialog'
import { observer } from 'mobx-react'
import { Contact } from 'model/Contact'
import { useMemo } from 'react'
import contactDialogStore from 'stores/ContactDialogStore'
import ContactStore from 'stores/ContactStore'
import { useRootStore } from 'stores/RootStoreContext'
import { ReactComponent as AiSparkle } from '../../assets/live-accounting/ai-sparkle.svg'
import './ContactSelect.scss'
import ContactSelectOption from './ContactSelectOption'
import ContactSelectOptionAvatar from './ContactSelectOptionAvatar'

interface ContactSelectProps {
  contactId: string | undefined
  onChange: (contactId: string | undefined) => void
  inputFieldProps?: Partial<InputFieldProps>
  liveCaptureSuggestion?: Contact
}

const ContactSelect = (props: ContactSelectProps) => {
  const { contactStore } = useRootStore()
  const { setIsContactsOpen, setDraftContact, setMode, setIsPickerMode } =
    contactDialogStore

  const newContactIsBeingSuggested =
    !!props.liveCaptureSuggestion &&
    props.contactId === props.liveCaptureSuggestion.id

  const allContacts = contactStore.sorted
  const sections = useMemo(() => {
    const contactsByLetter: TypedKeyValueStore<
      ResourceSelectOption<string, Contact>[]
    > = {}

    allContacts.forEach((contact) => {
      const name = ContactStore.nameOfContact(contact)
      const firstLetter = name.substring(0, 1).toLocaleUpperCase()

      const option = {
        value: contact.id,
        item: contact,
      }
      if (!contactsByLetter[firstLetter])
        contactsByLetter[firstLetter] = [option]
      else contactsByLetter[firstLetter].push(option)
    })

    const sections = Object.entries(contactsByLetter).map(
      ([letter, contacts]) => ({
        title: letter,
        options: contacts,
      })
    )
    if (newContactIsBeingSuggested) {
      sections.unshift({
        title: 'Neuer Kontakt',
        options: [
          {
            value: props.liveCaptureSuggestion!.id,
            item: props.liveCaptureSuggestion!,
          },
        ],
      })
    }
    return sections
  }, [allContacts, props.liveCaptureSuggestion])

  const actions: ButtonProps[] = [
    {
      children: 'Kontakte verwalten',
      iconLeft: 'contacts',
      onClick: () => setIsContactsOpen(true),
    },
  ]

  return (
    <>
      <ThemeProvider
        primaryColor={newContactIsBeingSuggested ? 'lavender' : 'teal'}
      >
        <Flex fillContainer gap={1}>
          <ResourceSelect
            className={classNames('contact-select', {
              'with-live-capture-suggestion': newContactIsBeingSuggested,
            })}
            sections={sections}
            value={props.contactId}
            onChange={props.onChange}
            optionForItem={(contact, props) => (
              <ContactSelectOption
                key={props.key}
                contact={contact}
                optionProps={props}
              />
            )}
            leftAccessory={(selectedItem) => {
              if (newContactIsBeingSuggested) {
                return <AiSparkle />
              }
              return <ContactSelectOptionAvatar contact={selectedItem} />
            }}
            placeholderText='Kontakt wählen'
            searchableTextForItem={ContactStore.nameOfContact}
            inputFieldProps={{ fullWidth: true, ...props.inputFieldProps }}
            actions={actions}
            key={props.contactId}
          />
          {newContactIsBeingSuggested && (
            <Button
              variant='primary'
              onClick={() => {
                setMode('creating')
                setDraftContact(props.liveCaptureSuggestion as any)
                setIsContactsOpen(true)
              }}
            >
              Kontakt hinzufügen
            </Button>
          )}
        </Flex>
      </ThemeProvider>
      <ContactPickerDialog
        onContactPicked={props.onChange}
        onContactCreated={() => {
          setIsPickerMode(true)
        }}
        retainState={newContactIsBeingSuggested}
      />
    </>
  )
}

export default observer(ContactSelect)
