import { FeedbackBanner } from '@nextbusiness/infinity-ui'
import Accordion from 'components/accordion/Accordion'
import { IAccordionItem } from 'components/accordion/AccordionItem'
import { TemplateInspectorProps } from '../TemplateInspector'
import TemplateInspectorAdditionalData from './TemplateInspectorAdditionalData'
import TemplateInspectorInvoiceDefaultText from './TemplateInspectorInvoiceDefaultText'
import TemplateInspectorQuoteDefaultText from './TemplateInspectorQuoteDefaultText'
import TemplateInspectorSender from './TemplateInspectorSender'

const TemplateInspectorTextTab = (props: TemplateInspectorProps) => {
  const { organisationName, addressLine, city, zipCode } = props.template
  const isIncomplete = !organisationName || !addressLine || !city || !zipCode

  const accordionItems: IAccordionItem[] = [
    {
      title: 'Absenderangaben',
      content: (
        <>
          <TemplateInspectorSender {...props} />
          <TemplateInspectorAdditionalData {...props} />
        </>
      ),
    },
    {
      title: 'Standardtext für Forderungen',
      content: <TemplateInspectorInvoiceDefaultText {...props} />,
      onOpen: () => props.changePreviewType(false),
    },
    {
      title: 'Standardtext für Offerten',
      content: <TemplateInspectorQuoteDefaultText {...props} />,
      onOpen: () => props.changePreviewType(true),
    },
  ]

  return (
    <div className='template-inspector-tab tab-text'>
      {isIncomplete && (
        <FeedbackBanner variant='info'>
          Vervollständige den Absender, um das Design speichern zu können.
        </FeedbackBanner>
      )}
      <Accordion accordionItems={accordionItems} openFirstByDefault />
    </div>
  )
}

export default TemplateInspectorTextTab
