import { ICurrency } from '@nextbusiness/infinity-finance-api'
import { Flex, MoneyField } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import CurrencySelect from 'components/multi-currency/currency-picker/CurrencySelect'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { useTransactionEditorContext } from '../TransactionEditorContext'
import TransactionEditorUtilities from '../TransactionEditorUtilities'
import TransactionEditorNetAmount from './TransactionEditorNetAmount'

const TransactionEditorAmount = () => {
  const { currencyStore, preferencesStore } = useRootStore()
  const {
    draftTransaction,
    updateDraftTransaction,
    canMakeChanges,
    originalTransaction,
  } = useTransactionEditorContext()

  const isMultiCurrencyBetaEnabled =
    preferencesStore.isUsingBetaFeature('multi-currency')

  const showNetAmount =
    TransactionEditorUtilities.shouldDisplayNetAmount(originalTransaction)

  const amount = draftTransaction.amount ? draftTransaction.amount / 100 : null

  const onAmountChanged = (change: number | null) =>
    updateDraftTransaction({
      amount: change ? Math.round(change * 100) : undefined,
    })
  const baseCurrency = currencyStore.baseCurrency
  const originalCurrencyCode = draftTransaction.originalCurrency
  const originalCurrency = currencyStore.currencies?.find(
    (currency) => currency.currencyCode === originalCurrencyCode
  )
  const [currency, setCurrency] = useState<ICurrency>(
    originalCurrency ?? baseCurrency
  )
  const [isActive, setIsActive] = useState<boolean>(false)

  const updateCurrency = (currency: ICurrency) => {
    setCurrency(currency)
    if (currency.currencyCode === 'CHF') {
      updateDraftTransaction({
        originalCurrency: undefined,
        exchangeRate: undefined,
      })
    } else {
      updateDraftTransaction({
        originalCurrency: currency.currencyCode,
        exchangeRate: currency.effectiveExchangeRate,
      })
    }
  }

  return (
    <Flex
      className={classNames(
        'amount-container',
        showNetAmount && 'has-net-amount'
      )}
      direction='vertical'
      verticalAlignment='flex-end'
    >
      <CurrencySelect
        setCurrency={(currency) => {
          updateCurrency(currency)
        }}
        isFlyoutOpen={isActive}
        setIsFlyoutOpen={setIsActive}
        presetCurrencyCode={originalCurrencyCode}
        flyoutTrigger={
          <MoneyField
            bare
            className='amount'
            value={amount}
            onChange={onAmountChanged}
            disabled={!canMakeChanges}
            currency={currency.currencyCode}
            onUnitClick={
              isMultiCurrencyBetaEnabled
                ? () => {
                    if (canMakeChanges) setIsActive(!isActive)
                  }
                : undefined
            }
          />
        }
      />
      {showNetAmount && <TransactionEditorNetAmount />}
    </Flex>
  )
}

export default observer(TransactionEditorAmount)
