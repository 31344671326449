import {
  IExpensesOrIncome,
  IFinancialSolvency,
  IMaturities,
  IProfitability,
} from '../model/FinancialHealth'
import { Current } from './Authentication'
import NetworkingConstants from './NetworkingConstants'
import NetworkingErrorHandler from './NetworkingErrorHandler'

const getFinancialSolvency = async (
  organisationIdentifier: string
): Promise<IFinancialSolvency> => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationIdentifier}/financialSolvency`,
    { method: 'GET', headers: Current.defaultHeaders }
  )
  const body = await response.json()

  if (!response.ok) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body as IFinancialSolvency
}

const getIncome = async (
  organisationIdentifier: string,
  timestamp: number,
  shouldLoadAll?: boolean
): Promise<IExpensesOrIncome> => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationIdentifier}/date/${timestamp}/income${
        shouldLoadAll ? '/all' : ''
      }`,
    { method: 'GET', headers: Current.defaultHeaders }
  )
  const body = await response.json()

  if (!response.ok || !body.income) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.income as IExpensesOrIncome
}

const getExpenses = async (
  organisationIdentifier: string,
  timestamp: number,
  shouldLoadAll?: boolean
): Promise<IExpensesOrIncome> => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationIdentifier}/date/${timestamp}/expense${
        shouldLoadAll ? '/all' : ''
      }`,
    { method: 'GET', headers: Current.defaultHeaders }
  )
  const body = await response.json()

  if (!response.ok || !body.expense) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.expense as IExpensesOrIncome
}

const getMaturities = async (
  organisationIdentifier: string
): Promise<IMaturities> => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationIdentifier}/maturities`,
    { method: 'GET', headers: Current.defaultHeaders }
  )
  const body = await response.json()

  if (!response.ok || !body.maturities) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.maturities as IMaturities
}

const getProfitability = async (
  organisationIdentifier: string,
  timestamp: number
): Promise<IProfitability> => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationIdentifier}/date/${timestamp}/profitability`,
    { method: 'GET', headers: Current.defaultHeaders }
  )
  const body = await response.json()

  if (!response.ok || !body.profitability) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.profitability as IProfitability
}

const FinancialHealth = {
  getFinancialSolvency,
  getExpenses,
  getIncome,
  getMaturities,
  getProfitability,
}

export default FinancialHealth
