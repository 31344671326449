import { TooltipProvider } from '@nextbusiness/infinity-ui'
import './DocumentSidebarActionsPanel.scss'
import DocumentSidebarDraftView from './DocumentSidebarDraftView'
import DocumentSidebarPanelProps from './DocumentSidebarPanelProps'
import DocumentSidebarPostedView from './DocumentSidebarPostedView'

const DocumentSidebarActionsPanel = (props: DocumentSidebarPanelProps) => {
  return (
    <TooltipProvider delay={1000}>
      {props.document.isDraft ? (
        <DocumentSidebarDraftView {...props} />
      ) : (
        <DocumentSidebarPostedView {...props} />
      )}
    </TooltipProvider>
  )
}

export default DocumentSidebarActionsPanel
