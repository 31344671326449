import { Flex, Text } from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import { taxRateExplanation } from 'utility/TaxUtilities'
import { TaxRate } from './TaxCodes'
import './TaxRateExplainer.scss'

interface TaxRateExplainerProps {
  taxRate?: TaxRate
}

const TaxRateExplainer = (props: TaxRateExplainerProps) => {
  if (!props.taxRate || !taxRateExplanation(props.taxRate)) return null
  return (
    <div className='tax-rate-explainer'>
      <Flex className='info-box' gap={1}>
        <Icon icon='info' size={IconSize.Small} />
        <Text>{taxRateExplanation(props.taxRate)}</Text>
      </Flex>
    </div>
  )
}

export default TaxRateExplainer
