import { TemplatePreviewProps } from './TemplatePreview'

const TemplatePreviewFooter = (props: TemplatePreviewProps) => (
  <div className='template-page-footer'>
    {!props.template.disableDecorativeGradient && (
      <div
        className='page-gradient'
        style={{
          backgroundImage: `linear-gradient(90deg, ${props.template.colourValues[0]}, ${props.template.colourValues[1]})`,
        }}
      />
    )}
  </div>
)

export default TemplatePreviewFooter
