import {
  AmountMode,
  Finance,
  IPreset,
} from '@nextbusiness/infinity-finance-api'
import {
  Flex,
  LoadingIndicator,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { inject, observer } from 'mobx-react'
import { Contact } from 'model/Contact'
import { Component } from 'react'
import PreferencesStore from 'stores/PreferencesStore'
import { queueNavigationTask } from 'utility/Utilities'
import ICustomerInvoice, {
  INewCustomerInvoice,
} from '../../../model/CustomerInvoice'
import CustomerInvoices from '../../../networking/CustomerInvoices'
import AccountStore from '../../../stores/AccountStore'
import AuthenticationStore from '../../../stores/AuthenticationStore'
import TemplateStore from '../../../stores/TemplateStore'
import CustomerInvoiceAskForAccountsDialog from './CustomerInvoiceAskForAccountsDialog'
import CustomerInvoiceAskForModifyingSharedInvoiceDialog from './CustomerInvoiceAskForModifyingSharedInvoiceDialog'
import './CustomerInvoiceEditor.scss'
import CustomerInvoiceEditorDocument from './CustomerInvoiceEditorDocument'
import CustomerInvoiceEditorShortcuts from './CustomerInvoiceEditorShortcuts'
import DocumentRenderer from './document-renderer/DocumentRenderer'
import { formatInvoiceNumber } from './document-renderer/elements/DocumentElementInvoiceNumber'
import CustomerInvoiceEditorSetupScreen from './setup/CustomerInvoiceEditorSetupScreen'
import CustomerInvoiceEditorSidebar from './sidebar/CustomerInvoiceEditorSidebar'

const EDIT_SHARED_INVOICE_COMMON_TEXT = 'Diese Forderung wurde bereits geteilt.'
const REGULAR_EDIT_SHARED_INVOICE_WARNING = `${EDIT_SHARED_INVOICE_COMMON_TEXT} Wenn du Änderungen vornimmst, wird das Dokument, welches du mit deinen Kunden geteilt hast, ebenfalls angepasst.`
const TURN_SHARED_INVOICE_TO_DRAFT_WARNING = `${EDIT_SHARED_INVOICE_COMMON_TEXT} Wenn du sie zu einem Entwurf zurückstellst, werden deine Kunden nicht mehr auf sie zugreifen können.`

interface CustomerInvoiceEditorProps {
  navigateToInvoice: (id: string, shouldPush?: boolean) => void
  templateStore?: TemplateStore
  accountStore?: AccountStore
  authenticationStore?: AuthenticationStore
  currentInvoice?: ICustomerInvoice
  preferencesStore?: PreferencesStore
  preset?: IPreset
  contact?: Contact
  isQuote?: boolean
  notificationCenter: ReturnType<typeof useNotificationCenter>
  onInvoiceCreated: (document: CustomerInvoiceEditorDocument) => void
}

interface CustomerInvoiceEditorState {
  currentInvoiceId?: string
  document?: Partial<INewCustomerInvoice>
  existingInvoice?: ICustomerInvoice
  loadingError?: Error
  savingError?: Error
  autosaveError?: Error
  isSaving?: boolean
  isAutosaving?: boolean
  isGeneratingPDF?: boolean
  hasChanges?: boolean
  isAskingForAccountsModalOpen?: boolean
  isAskingForModifyingSharedInvoiceModalOpen?: boolean
  hasConfirmedModifyingSharedInvoice?: boolean
  showCustomerInvoiceEmailDialog?: boolean
  modifySharedInvoiceWarning: string
}

@inject(
  'templateStore',
  'accountStore',
  'authenticationStore',
  'preferencesStore'
)
@observer
class CustomerInvoiceEditor extends Component<
  CustomerInvoiceEditorProps,
  CustomerInvoiceEditorState
> {
  private previousAutosaveHandler?: number

  constructor(props: CustomerInvoiceEditorProps) {
    super(props)
    this.state = {
      existingInvoice: props.currentInvoice,
      modifySharedInvoiceWarning: REGULAR_EDIT_SHARED_INVOICE_WARNING,
    }
  }

  componentDidMount() {
    this.loadTemplates()
  }

  get currentTemplate() {
    return this.props.templateStore!.currentTemplate
  }

  loadTemplates = async () => {
    try {
      await this.props.templateStore?.loadCurrentTemplate()
      if (this.props.templateStore!.currentTemplate) {
        this.loadDocumentFromProps()
      }
    } catch (error: any) {
      this.setState({
        loadingError: error as Error,
      })
    }
  }

  private loadDocumentFromProps() {
    this.loadDocumentFromInvoice(this.props.currentInvoice)
  }

  private loadDocumentFromInvoice = (invoice?: ICustomerInvoice) => {
    if (!this.currentTemplate) return
    const document = new CustomerInvoiceEditorDocument(
      this.currentTemplate,
      invoice,
      this.props.isQuote,
      this.props.preset
    )
    if (this.props.contact) document.invoice.recipient = this.props.contact.id
    this.setState({
      existingInvoice: invoice,
      document: document.invoice,
      currentInvoiceId: document.id ?? undefined,
      hasChanges: this.props.currentInvoice === undefined,
    })
    if (window.location.search.includes('edit')) {
      window.history.replaceState({}, '', window.location.pathname)
      this.registerEdit({})
    }
  }

  private documentFromState = () => {
    if (!this.state.document || !this.currentTemplate) return null

    const document = new CustomerInvoiceEditorDocument(this.currentTemplate)

    document.invoice = this.state.document

    if (this.state.currentInvoiceId) document.id = this.state.currentInvoiceId

    return document
  }

  canSave = () => this.state.hasChanges && this.documentFromState()?.isValid
  canSaveAsNonDraft = () => this.documentFromState()?.isValidNonDraft

  saveDocument = async (
    saveMessage: string | null = 'Dokument erfolgreich gespeichert.'
  ) => {
    const document = this.documentFromState()

    if (
      !this.state.existingInvoice?.isDraft &&
      !this.state.hasConfirmedModifyingSharedInvoice &&
      this.state.existingInvoice?.shareId
    ) {
      return this.setState({ isAskingForModifyingSharedInvoiceModalOpen: true })
    }

    if (!this.isOpeningDateAllowed())
      return this.props.notificationCenter.addNotification({
        children:
          'Das gewählte Eröffnungsdatum befindet sich ausserhalb aller offenen Geschäftsjahre.',
        variant: 'error',
      })

    const requiresTax =
      this.props.preferencesStore?.organisationPreferences?.VAT ?? false
    if (
      !document?.isDraft &&
      document?.hasPositionsWithMissingAccount(requiresTax)
    )
      return this.setState({ isAskingForAccountsModalOpen: true })

    this.setState({ isSaving: true })
    try {
      if (!document)
        throw new Error('Dokument konnte nicht gespeichert werden.')
      const savedDocument = await document.save(
        this.props.authenticationStore!.organisationIdentifier
      )
      if (!this.props.currentInvoice) {
        this.props.navigateToInvoice(savedDocument.id!)
        this.props.onInvoiceCreated(savedDocument)
      }

      if (this.state.hasConfirmedModifyingSharedInvoice) {
        await Finance.CustomerInvoice.shareInvoice(
          savedDocument.id!,
          this.props.authenticationStore!.applicationAccessToken!
        )
      }

      this.setState({
        currentInvoiceId: savedDocument.id!,
        document: savedDocument?.invoice,
        existingInvoice: savedDocument.originalInvoice ?? undefined,
        isSaving: false,
        savingError: undefined,
        hasChanges: false,
      })
      if (saveMessage !== null)
        this.props.notificationCenter.addNotification({
          children: saveMessage,
          variant: 'success',
        })
    } catch (error: any) {
      this.setState({
        isSaving: false,
        savingError: error,
      })
    }
  }

  private reloadDocument = async (reloadMessage?: string) => {
    if (!this.state.currentInvoiceId) return
    this.setState({ isSaving: true })
    try {
      const invoice = await CustomerInvoices.getCustomerInvoice(
        this.state.currentInvoiceId
      )
      this.loadDocumentFromInvoice(invoice)
      this.props.notificationCenter.addNotification({
        children: reloadMessage,
        variant: 'success',
      })
    } catch (error: any) {
      this.setState({ loadingError: error })
    } finally {
      this.setState({ isSaving: false })
    }
  }

  async loadPDF() {
    this.setState({ isGeneratingPDF: true })
    try {
      const blob = await CustomerInvoices.getPDFBlob(
        this.state.currentInvoiceId!,
        this.props.authenticationStore!.fabricOrganisationIdentifier!
      )
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)

      link.download = this.pdfDownloadTitle().trim() + '.pdf'
      link.click()

      this.setState({
        isGeneratingPDF: false,
        savingError: undefined,
      })
    } catch (error: any) {
      this.setState({
        isGeneratingPDF: false,
        savingError: error,
      })
    }
  }

  private pdfDownloadTitle() {
    const titlePrefix = this.state.document?.isQuote ? 'Offerte' : 'Rechnung'
    const useInvoiceNumber =
      this.props.templateStore?.currentTemplate?.showCustomerInvoiceNumber
    const formattedInvoiceNumber = this.formattedInvoiceNumber()

    if (
      useInvoiceNumber &&
      formattedInvoiceNumber &&
      !this.state.document?.isQuote
    ) {
      return `${titlePrefix} ${formattedInvoiceNumber}`
    } else {
      const documentTitle = (this.state.document?.title ?? '').replaceAll(
        '.',
        '-'
      )
      const isRepeatingPrefix = documentTitle
        .toLowerCase()
        .startsWith(titlePrefix.toLowerCase())
      return isRepeatingPrefix
        ? documentTitle
        : `${titlePrefix} ${documentTitle}`
    }
  }

  private formattedInvoiceNumber() {
    if (this.state.existingInvoice?.invoiceNumber === undefined)
      return undefined
    return formatInvoiceNumber(
      'RE-',
      this.state.existingInvoice.invoiceNumber,
      this.props.preferencesStore?.organisationPreferences
        ?.customerInvoiceNumberingStart
    )
  }

  private onChangesConfirmed = () => {
    this.setState(
      {
        hasConfirmedModifyingSharedInvoice: true,
      },
      () => {
        this.saveDocument().then(() =>
          this.setState({
            hasConfirmedModifyingSharedInvoice: false,
            showCustomerInvoiceEmailDialog: true,
            isAskingForModifyingSharedInvoiceModalOpen: false,
          })
        )
      }
    )
  }

  registerEdit = (
    changes: Partial<INewCustomerInvoice>,
    shouldSave?: boolean,
    saveMessage?: string | null
  ) => {
    return this.setState(
      (previousState) => ({
        hasChanges: true,
        document: {
          ...previousState.document,
          ...changes,
        },
        modifySharedInvoiceWarning: changes.isDraft
          ? TURN_SHARED_INVOICE_TO_DRAFT_WARNING
          : REGULAR_EDIT_SHARED_INVOICE_WARNING,
      }),
      () => {
        this.autosave(changes, shouldSave)
        if (changes.isQrInvoice) {
          this.scrollToQRSlipPreview()
        }
        if (shouldSave && this.props.currentInvoice) {
          this.saveDocument(saveMessage)
        }
      }
    )
  }

  private autosave(
    changes: Partial<INewCustomerInvoice>,
    willSaveAnyway = false
  ) {
    if (
      !this.state.document?.isDraft ||
      !this.state.currentInvoiceId ||
      JSON.stringify(changes) === '{}' ||
      willSaveAnyway
    ) {
      return
    }
    if (this.previousAutosaveHandler) {
      clearTimeout(this.previousAutosaveHandler)
    }
    this.setState({ isAutosaving: true, autosaveError: undefined })
    this.previousAutosaveHandler = setTimeout(async () => {
      try {
        await CustomerInvoices.updateCustomerInvoice(
          this.state.currentInvoiceId!,
          changes
        )
      } catch (error: any) {
        this.setState({ autosaveError: error })
      } finally {
        this.setState({ isAutosaving: false })
      }
    }, 300)
  }

  private scrollToQRSlipPreview() {
    const preview = document.querySelector('.document-qr-slip-preview')
    if (preview) preview.scrollIntoView({ behavior: 'smooth' })
  }

  private isOpeningDateAllowed() {
    const openingDate = this.state.document?.openingDate
    if (!openingDate) return false

    return this.props.accountStore!.isDateWithinOpenFiscalYear(
      new Date(openingDate)
    )
  }

  private get isEditable() {
    if (this.state.existingInvoice?.isDraft) return true

    const isInvoiceInClosedFiscalYear = Boolean(
      this.state.existingInvoice?.openingDate &&
        !this.props.accountStore!.isDateWithinOpenFiscalYear(
          new Date(this.state.existingInvoice.openingDate)
        )
    )
    return (
      !this.state.existingInvoice?.paymentTransactions.length &&
      !this.state.existingInvoice?.isAnnulled &&
      !isInvoiceInClosedFiscalYear
    )
  }

  private onSetupComplete = async (amountMode?: AmountMode) => {
    await this.loadTemplates()
    await this.saveDocument(null)

    if (amountMode) this.registerEdit({ amountMode })
  }

  private discardChanges = () => {
    this.loadDocumentFromInvoice(this.state.existingInvoice)
    this.setState({ hasChanges: false })
  }

  render() {
    if (this.props.templateStore?.templates?.length === 0) {
      return (
        <CustomerInvoiceEditorSetupScreen
          onSetupComplete={this.onSetupComplete}
        />
      )
    }
    if (
      !this.state.document ||
      !this.props.templateStore?.currentTemplate ||
      !this.props.accountStore?.currentFiscalYear ||
      !this.props.accountStore.allAccounts.length
    )
      return (
        <Flex
          verticalAlignment='center'
          horizontalAlignment='center'
          fillContainer
        >
          <LoadingIndicator />
        </Flex>
      )

    return (
      <div className='document-editor-wrapper'>
        <DocumentRenderer
          template={this.props.templateStore.currentTemplate}
          document={this.state.document}
          registerEdit={this.registerEdit}
          isEditable={this.isEditable}
          existingInvoice={this.state.existingInvoice}
        />
        <CustomerInvoiceEditorSidebar
          template={this.props.templateStore.currentTemplate}
          document={this.state.document}
          existingInvoice={this.state.existingInvoice}
          setExistingInvoice={(invoice: ICustomerInvoice) =>
            this.setState({ existingInvoice: invoice })
          }
          registerEdit={this.registerEdit}
          canSave={this.canSave()}
          isSaving={this.state.isSaving}
          isAutosaving={this.state.isAutosaving}
          isEditable={this.isEditable}
          hasChanges={this.state.hasChanges}
          save={this.saveDocument}
          reload={this.reloadDocument}
          isExistingDocument={this.state.currentInvoiceId !== undefined}
          loadPDF={() => this.loadPDF()}
          isGeneratingPDF={this.state.isGeneratingPDF}
          canSaveAsNonDraft={this.canSaveAsNonDraft()}
          savingError={this.state.savingError}
          autosavingError={this.state.autosaveError}
          navigateToInvoice={(invoiceId) => {
            this.props.navigateToInvoice(invoiceId)
            queueNavigationTask(() => this.loadDocumentFromProps())
          }}
          discardChanges={this.discardChanges}
        />
        <CustomerInvoiceAskForAccountsDialog
          isOpen={this.state.isAskingForAccountsModalOpen ?? false}
          onDismiss={() =>
            this.setState({ isAskingForAccountsModalOpen: false })
          }
          document={this.state.document}
          registerEdit={this.registerEdit}
          save={(saveMessage) => this.saveDocument(saveMessage)}
        />
        <CustomerInvoiceEditorShortcuts
          save={() => {
            if (this.canSave()) this.saveDocument()
          }}
          generatePDF={() => {
            if (this.state.currentInvoiceId) this.loadPDF()
          }}
        />
        <CustomerInvoiceAskForModifyingSharedInvoiceDialog
          isModificationsInProgress={this.state.isSaving ?? false}
          isOpen={
            this.state.isAskingForModifyingSharedInvoiceModalOpen ?? false
          }
          onChangesConfirmed={this.onChangesConfirmed}
          onDismiss={() => {
            this.setState(
              {
                isAskingForModifyingSharedInvoiceModalOpen: false,
                hasChanges: false,
              },
              () => this.discardChanges()
            )
          }}
          text={this.state.modifySharedInvoiceWarning}
        />
      </div>
    )
  }
}

export default CustomerInvoiceEditor
