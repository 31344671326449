import {
  Dialog,
  FeedbackBanner,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { isBackendError } from '../../../libs/networking/Errors'
import ICustomerInvoice from '../../../model/CustomerInvoice'
import CustomerInvoices from '../../../networking/CustomerInvoices'
import { useRootStore } from '../../../stores/RootStoreContext'

interface CustomerInvoiceDeleteConfirmationDialogProps {
  invoice: ICustomerInvoice
  isOpen: boolean
  onDismiss: () => void
  onInvoiceDeleted: () => void
}

const CustomerInvoiceDeleteConfirmationDialog = (
  props: CustomerInvoiceDeleteConfirmationDialogProps
) => {
  const { authenticationStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [isDeletionInProgress, setIsDeletionInProgress] =
    useState<boolean>(false)

  const invoiceType = props.invoice.isQuote ? 'Offerte' : 'Forderung'
  const deleteInvoice = async () => {
    setIsDeletionInProgress(true)
    try {
      await CustomerInvoices.deleteCustomerInvoice(
        props.invoice.id,
        authenticationStore.organisationIdentifier
      )
      notificationCenter.addNotification({
        children: `Die ${invoiceType} wurde erfolgreich gelöscht.`,
        variant: 'success',
      })
      props.onDismiss()
      props.onInvoiceDeleted()
    } catch (error: any) {
      notificationCenter.addNotification({
        children:
          (isBackendError(error) ? error.displayMessage : error.message) ||
          `Die ${invoiceType} konnte nicht gelöscht werden. Bitte versuche es erneut.`,
        variant: 'error',
      })
    } finally {
      setIsDeletionInProgress(false)
    }
  }

  return (
    <Dialog
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      icon='delete'
      title={`${invoiceType} unwiderruflich löschen?`}
      actions={[
        {
          children: 'Abbrechen',
          disabled: isDeletionInProgress,
          onClick: () => props.onDismiss(),
        },
        {
          children: 'Löschen',
          variant: 'destructive',
          onClick: () => deleteInvoice(),
          isLoading: isDeletionInProgress,
        },
      ]}
      style={{ maxWidth: '60rem' }}
    >
      <Text>
        Möchtest du die {invoiceType} "{props.invoice.title}" vom{' '}
        {new Date(props.invoice.openingDate).toLocaleDateString()} wirklich
        löschen?
      </Text>
      {!props.invoice.isQuote && (
        <Text>
          Alle zugehörigen automatisch erstellten Buchungen und Zahlungen werden
          ebenfalls entfernt.
        </Text>
      )}
      {!props.invoice.isQuote && !props.invoice.isDraft && (
        <FeedbackBanner variant='warning'>
          Falls du die Forderung bereits an Kunden versendet hast, darfst du sie
          möglicherweise nicht aus deiner Buchhaltung löschen. In diesem Fall
          solltest du die Forderung stattdessen stornieren.
        </FeedbackBanner>
      )}
    </Dialog>
  )
}

export default observer(CustomerInvoiceDeleteConfirmationDialog)
