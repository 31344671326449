import { Button, Flyout } from '@nextbusiness/infinity-ui'
import { IconItem } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import { useState } from 'react'
import './ConfigurableAreaFlyout.scss'

interface ConfigurableAreaFlyoutProps {
  readonly: boolean | undefined
  title?: string
  className?: string
  tooltip: string
  children: React.ReactNode
  settings?: React.ReactNode
  icon?: IconItem
}

const ConfigurableAreaFlyout = (props: ConfigurableAreaFlyoutProps) => {
  const [isActive, setIsActive] = useState<boolean>(false)
  if (props.readonly) return <>{props.children}</>
  return (
    <Flyout
      className='configurable-area-flyout'
      isActive={isActive}
      setIsActive={setIsActive}
      trigger={
        <div className={classNames('configurable-area', { active: isActive })}>
          <Button
            iconOnly={props.icon ?? 'settings'}
            className='configure-action'
            onClick={() => setIsActive(!isActive)}
            tooltip={props.tooltip}
            variant='secondary'
          />
          {props.children}
        </div>
      }
      triggerIsButton
    >
      <div
        className={classNames('configurable-area-settings', props.className)}
      >
        {props.title && (
          <span className='configurable-area-title'>{props.title}</span>
        )}
        {props.settings}
      </div>
    </Flyout>
  )
}

export default ConfigurableAreaFlyout
