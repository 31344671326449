import { IPreset, ITemplate } from '@nextbusiness/infinity-finance-api'
import ICustomerInvoice, {
  INewCustomerInvoice,
  INewCustomerInvoicePosition,
} from '../../../model/CustomerInvoice'
import CustomerInvoices from '../../../networking/CustomerInvoices'
import CustomerInvoiceFactory from './CustomerInvoiceFactory'

class CustomerInvoiceEditorDocument {
  public id: string | null = null
  public invoice: Partial<INewCustomerInvoice>
  public readonly originalInvoice: ICustomerInvoice | null = null
  public preset?: IPreset
  public template: ITemplate

  constructor(
    template: ITemplate,
    invoice?: ICustomerInvoice,
    isQuote?: boolean,
    preset?: IPreset
  ) {
    this.template = template
    this.preset = preset

    if (invoice) this.invoice = CustomerInvoiceFactory.fromInvoice(invoice)
    else if (preset)
      this.invoice = CustomerInvoiceFactory.fromPreset(
        preset,
        template,
        isQuote
      )
    else this.invoice = CustomerInvoiceFactory.emptyDocument(template, isQuote)

    if (invoice) this.originalInvoice = invoice
    if (invoice?.id) this.id = invoice.id
  }

  get isDraft() {
    return this.invoice.isDraft
  }

  get isValid() {
    if (this.isDraft) return this.isValidDraft
    return this.isValidNonDraft
  }

  hasPositionsWithMissingAccount(requiresTax: boolean) {
    return this.invoice.positions?.some((position) => {
      if (position.type !== 'custom-product') return false
      if (!position.taxCode && requiresTax) return true
      return !position.contraAccount
    })
  }

  get isValidNonDraft() {
    return Boolean(
      this.invoice.recipient &&
        this.invoice.title &&
        this.invoice.positions &&
        this.invoice.positions.length > 0 &&
        this.invoice.template &&
        this.invoice.openingDate &&
        this.invoice.payableInDays &&
        this.invoice.positions.every(this.isPositionValid)
    )
  }

  get isValidDraft() {
    return Boolean(this.invoice.template && this.invoice.openingDate)
  }

  private isPositionValid(position: INewCustomerInvoicePosition) {
    switch (position.type) {
      case 'custom-product':
        return Boolean(
          position.name && position.singleAmount && position.quantity
        )
      case 'discount':
        return 'percentage' in position || 'amount' in position
      case 'text-line':
        return position.content !== undefined && position.content.trim() !== ''
      default:
        return false
    }
  }

  async save(organisationId: string) {
    if (!this.isValid)
      throw new Error("Invalid customer invoices can't be saved.")

    if (this.id) {
      return this.update(organisationId)
    } else {
      return this.create(organisationId)
    }
  }

  private async create(organisationId: string) {
    const invoiceResponse = await CustomerInvoices.createCustomerInvoice(
      organisationId,
      { ...this.invoice, transactionDate: +new Date() } as INewCustomerInvoice,
      this.isDraft
    )
    return new CustomerInvoiceEditorDocument(this.template, invoiceResponse)
  }

  private async update(_organisationId: string) {
    const { invoiceNumber: _invoiceNumber, ...changes } = this.invoice
    const invoiceResponse = await CustomerInvoices.updateCustomerInvoice(
      this.id!,
      changes
    )
    return new CustomerInvoiceEditorDocument(this.template, invoiceResponse)
  }
}

export default CustomerInvoiceEditorDocument
