import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import { AnimatePresence, motion } from 'framer-motion'
import { IAccount } from 'model/Account'
import { useState } from 'react'
import './LiveAccountingSetup.scss'
import LiveAccountingSetupSelectBank from './LiveAccountingSetupSelectBank'
import LiveAccountingWelcomeScreen from './LiveAccountingWelcomeScreen'

interface LiveAccountingSetupProps {
  account: IAccount
  dismiss: () => void
}

enum SetupStep {
  Welcome = 'welcome',
  SelectBank = 'select-bank',
}

const LiveAccountingSetup = (props: LiveAccountingSetupProps) => {
  const [currentStep, setCurrentStep] = useState<SetupStep>(SetupStep.Welcome)
  return (
    <MagicSheetPage
      className='live-accounting-setup'
      header={<MagicSheetTitleBar title='Live Accounting einrichten' />}
      noTrailingNegativeSpace
    >
      <AnimatePresence>
        {currentStep === SetupStep.Welcome && (
          <motion.div
            key='welcome'
            initial={{ opacity: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.2 }}
          >
            <LiveAccountingWelcomeScreen
              onContinue={() => setCurrentStep(SetupStep.SelectBank)}
            />
          </motion.div>
        )}
        {currentStep === SetupStep.SelectBank && (
          <motion.div
            key='select-bank'
            initial={{ opacity: 0, translateY: 40 }}
            animate={{ opacity: 1, translateY: 0 }}
            transition={{ duration: 0.4, delay: 0.2 }}
          >
            <LiveAccountingSetupSelectBank {...props} />
          </motion.div>
        )}
      </AnimatePresence>
    </MagicSheetPage>
  )
}

export default LiveAccountingSetup
