import { Button, Flex } from '@nextbusiness/infinity-ui'
import { KeyValueStore } from '../../utility/types'
import './CategoryNavigation.scss'

interface CategoryNavigationProps<T extends KeyValueStore> {
  categories: T
  activeCategory: keyof T
  setActiveCategory: (activeCategory: keyof T) => void
}

const CategoryNavigation = <T extends KeyValueStore>(
  props: CategoryNavigationProps<T>
) => {
  return (
    <Flex direction='vertical' className='categories-options'>
      {Object.keys(props.categories).map((key) => (
        <Button
          key={key}
          variant='shell'
          className={`category-item ${
            props.activeCategory === key ? 'active' : ''
          }`}
          onClick={() => props.setActiveCategory(key)}
        >
          {props.categories[key]}
        </Button>
      ))}
    </Flex>
  )
}

export default CategoryNavigation
