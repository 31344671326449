import {
  AccountCardChangeDatapoint,
  AccountCardChartData,
  AccountCardTimeframe,
} from '@nextbusiness/infinity-finance-api'
import { ChartData, Chart as ChartJS, PointElement } from 'chart.js'
import 'chartjs-adapter-luxon'
import { Bar } from 'react-chartjs-2'
import AccountCardBarChartConfiguration from './AccountCardBarChartConfiguration'
import { AccountCardGranularity } from './AccountCardChartConfiguration'

interface AccountCardBarChartProps {
  timeframe: AccountCardTimeframe
  data: AccountCardChartData<AccountCardChangeDatapoint>
  granularity: AccountCardGranularity
}

ChartJS.register(PointElement)

const AccountCardBarChart = (props: AccountCardBarChartProps) => {
  const config = new AccountCardBarChartConfiguration(
    props.granularity,
    props.timeframe,
    props.data.current.length,
    props.data.current.map((d) => d.to)
  )
  const data: ChartData<'bar', number[], string> = {
    labels: config.labels(),
    datasets: [
      {
        data: props.data.current.map((d) => d.influx),
        ...config.barSettings(),
        backgroundColor: '#098c70',
      },
      {
        data: props.data.current.map((d) => -d.outflux),
        ...config.barSettings(),
        backgroundColor: '#e1273e',
      },
    ],
  }

  return (
    <div className='account-card-chart'>
      <Bar
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: { left: -8, bottom: -3 },
          },
          scales: {
            x: {
              stacked: true,
              border: { display: false },
              bounds: 'ticks',
              offset: true,
              grid: { drawTicks: false, color: '#f2f4f7' },
              ticks: {
                font: { family: 'Neue Plak Text, sans-serif' },
                color: '#222',
                padding: 10,
                autoSkip: true,
                maxRotation: 0,
                autoSkipPadding: 10,
              },
            },
            y: {
              stacked: true,
              border: { color: '#f2f4f7' },
              grid: { drawTicks: false, color: '#f2f4f7' },
              suggestedMax: 1,
              ticks: {
                callback: config.formatValueLabel,
                font: { family: 'Neue Plak Text, sans-serif' },
                color: '#222',
                padding: 10,
                maxTicksLimit: 5,
              },
              grace: '7%',
            },
          },
          elements: {
            bar: {
              borderWidth: 0.01, // border artifacts appear when set to 0
              borderColor: '#ffffff',
            },
          },
        }}
      />
    </div>
  )
}

export default AccountCardBarChart
