import {
  Transaction as BankTransaction,
  TransactionType,
} from '@nextbusiness/infinity-finance-api'
import Lockup from 'components/text/Lockup'
import TransactionLiveAcountingDetailProps from './TransactionLiveAcountingDetailProps'

const counterpartyDisplayName = (
  counterparty: BankTransaction['counterparty']
) => {
  if (counterparty?.name) {
    return counterparty.name
  } else if (counterparty?.postalAddress?.unstructured?.addressLines.length) {
    // Some banks provide the counterparty name in the first address line for
    // card payments.
    return counterparty.postalAddress.unstructured.addressLines[0]
  } else {
    return null
  }
}

const LiveAccountingCounterparty = (
  props: TransactionLiveAcountingDetailProps
) => {
  const counterpartyName = counterpartyDisplayName(props.details.counterparty)
  if (!counterpartyName) return null

  const counterpartyLabel =
    props.details.transactionType === TransactionType.DEBIT
      ? 'Zahlungsempfänger'
      : 'Auftraggeber'
  return (
    <Lockup icon='contacts' tooltip={counterpartyLabel} small subtle truncate>
      {counterpartyName}
    </Lockup>
  )
}

export default LiveAccountingCounterparty
