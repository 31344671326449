import { Icon } from '@nextbusiness/infinity-ui-icons'
import { motion } from 'framer-motion'

interface SplitViewSnapIndicatorProps {
  side: 'left' | 'right'
  isSnappingFromEdge: boolean
}

const SplitViewSnapIndicator = (props: SplitViewSnapIndicatorProps) => {
  const isOpeningFromLeft = props.side === 'left' && props.isSnappingFromEdge
  const isClosingRight = props.side === 'right' && !props.isSnappingFromEdge
  const showLeftArrowIcon = isOpeningFromLeft || isClosingRight

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className='ui-split-view-snap-indicator'
    >
      <Icon icon={showLeftArrowIcon ? 'close-pane' : 'open-pane'} />
    </motion.div>
  )
}

export default SplitViewSnapIndicator
