import InvoiceContactFilter from 'invoices/shared-components/filters/InvoiceContactFilter'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import { useCustomerInvoicesFilter } from './CustomerInvoicesFilterContext'

const CustomerInvoiceRecipientFilter = () => {
  const { customerInvoiceStore } = useRootStore()
  const { activeFilter, editFilter } = useCustomerInvoicesFilter()

  const allDocuments = customerInvoiceStore.allDocuments

  return (
    <InvoiceContactFilter
      contactKey='recipient'
      invoices={allDocuments}
      label='Empfänger'
      selectedValue={activeFilter.recipient}
      onValueSelected={(contactId) => editFilter({ recipient: contactId })}
    />
  )
}

export default observer(CustomerInvoiceRecipientFilter)
