import { ITemplate } from '@nextbusiness/infinity-finance-api'
import { ITemplateDraft } from '../../../model/Template'
import './TemplatePreview.scss'
import TemplatePreviewFooter from './TemplatePreviewFooter'
import TemplatePreviewInfoBlock from './TemplatePreviewInfoBlock'
import TemplatePreviewPageEndText from './TemplatePreviewPageEndText'
import TemplatePreviewPageStartText from './TemplatePreviewPageStartText'
import TemplatePreviewRecipient from './TemplatePreviewRecipient'
import TemplatePreviewSender from './TemplatePreviewSender'
import TemplatePreviewTable from './TemplatePreviewTable'

export interface TemplatePreviewProps {
  template: ITemplate | ITemplateDraft
  showNotice?: boolean
  isFirstTimeCreation?: boolean
  showQuotePreview?: boolean
}

const TemplatePreview = (props: TemplatePreviewProps) => (
  <div className='template-preview-panel'>
    <div>
      {props.showNotice && (
        <div className='template-notice'>
          {props.isFirstTimeCreation
            ? 'Bevor du deine erste Offerte oder Forderung erstellen kannst, musst du dein Dokumentdesign vervollständigen.'
            : 'Du bearbeitest das Dokumentdesign für alle Forderungen und Offerten.'}
        </div>
      )}
      <div
        className={`template-preview page-font-${props.template.font}`}
        style={{
          backgroundImage: props.template.backgroundUrl
            ? `url(${props.template.backgroundUrl})`
            : undefined,
        }}
      >
        <div
          className={`template-page-header recipient-positioned-${props.template.addressLinePosition}`}
        >
          <TemplatePreviewSender {...props} />
          <TemplatePreviewRecipient {...props} />
        </div>
        <div className='template-page-main'>
          {props.showQuotePreview ? (
            <div className='page-title'>Beispielofferte für Dokumentdesign</div>
          ) : (
            <div className='page-title'>
              Beispielforderung für Dokumentdesign
            </div>
          )}
          <TemplatePreviewInfoBlock {...props} />
          <TemplatePreviewPageStartText {...props} />
          <TemplatePreviewTable />
          <TemplatePreviewPageEndText {...props} />
        </div>
        <TemplatePreviewFooter {...props} />
      </div>
    </div>
  </div>
)

export default TemplatePreview
