import {
  Button,
  ErrorBoundary,
  Flex,
  LoadingIndicator,
  NonIdealState,
} from '@nextbusiness/infinity-ui'
import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import { observer } from 'mobx-react'
import Reports from 'networking/Reports'
import DocumentsPage from 'pages/DocumentsPage'
import { useEffect, useState } from 'react'
import IncomeStatement from 'reports/income-statement/IncomeStatement'
import {
  ISegmentizedIncomeStatement,
  segmentizeIncomeStatement,
} from 'reports/income-statement/IncomeStatementUtil'
import { useRootStore } from 'stores/RootStoreContext'
import './ClosedFiscalYearIncomeStatement.scss'

interface ClosedFiscalYearIncomeStatementProps {
  page: DocumentsPage
}

const ClosedFiscalYearIncomeStatement = (
  props: ClosedFiscalYearIncomeStatementProps
) => {
  const { authenticationStore, accountStore } = useRootStore()

  const year = props.page.currentParam
    ? parseInt(props.page.currentParam)
    : null
  const fiscalYear = accountStore.allFiscalYears?.find((fy) => fy.year === year)

  const fromDate = fiscalYear ? new Date(fiscalYear?.from) : null
  const toDate = fiscalYear ? new Date(fiscalYear?.to) : null

  const [incomeStatement, setIncomeStatement] =
    useState<ISegmentizedIncomeStatement>()
  const [error, setError] = useState<Error>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const loadIncomeStatement = async () => {
    if (!fiscalYear) {
      setError(new Error('Geschäftsjahr nicht gefunden.'))
      return
    }
    try {
      setIsLoading(true)
      const fetchedIncomeStatement = await Reports.getIncomeStatement(
        authenticationStore.organisationIdentifier,
        fiscalYear.from,
        fiscalYear.to
      )
      setIncomeStatement(
        segmentizeIncomeStatement(fetchedIncomeStatement.groups)
      )
    } catch (error) {
      setError(error as Error)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    loadIncomeStatement()
  }, [year])

  return (
    <MagicSheetPage
      containerClassName='income-statement-page closed-fiscal-year-income-statement'
      noTrailingNegativeSpace
      header={
        <MagicSheetTitleBar title='Erfolgsrechnung'>
          {!!incomeStatement && (
            <Button
              className='pdf-button'
              onClick={() => window.print()}
              iconLeft='print'
              tooltip='Erfolgsrechnung drucken oder als PDF abspeichern'
            >
              Drucken
            </Button>
          )}
        </MagicSheetTitleBar>
      }
    >
      <MagicSheetContainer>
        {isLoading ? (
          <Flex
            className='loading-indicator-wrapper'
            verticalAlignment='center'
            horizontalAlignment='center'
            fillContainer
          >
            <LoadingIndicator />
          </Flex>
        ) : !!incomeStatement && !!fromDate && !!toDate ? (
          <ErrorBoundary
            errorView={
              <NonIdealState>
                Beim Anzeigen der Erfolgsrechnung ist ein Fehler aufgetreten.
              </NonIdealState>
            }
          >
            <IncomeStatement
              incomeStatement={incomeStatement}
              fromDateCopy={fromDate}
              toDateCopy={toDate}
            />
          </ErrorBoundary>
        ) : (
          error && (
            <Flex
              className='error-wrapper'
              verticalAlignment='center'
              horizontalAlignment='center'
              fillContainer
            >
              <NonIdealState icon='error'>{error.message}</NonIdealState>
            </Flex>
          )
        )}
      </MagicSheetContainer>
    </MagicSheetPage>
  )
}

export default observer(ClosedFiscalYearIncomeStatement)
