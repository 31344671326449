import Coachmark from 'components/coachmark/Coachmark'
import Lockup from 'components/text/Lockup'
import SidebarGroup from '../SidebarGroup'
import DocumentSidebarNoEditMessage from './DocumentSidebarNoEditMessage'
import { DocumentSidebarDraftViewProps } from './DocumentSidebarPanelProps'
import CreatePresetAction from './actions/CreatePresetAction'
import CustomerInvoiceDuplicateAction from './actions/CustomerInvoiceDuplicateAction'
import CustomerInvoiceToggleDraftAction from './actions/CustomerInvoiceToggleDraftAction'
import CustomerInvoiceToggleQuoteAction from './actions/CustomerInvoiceToggleQuoteAction'

const DocumentSidebarDraftView = (props: DocumentSidebarDraftViewProps) => {
  const canEdit = props.isEditable

  return (
    <>
      {canEdit && (
        <SidebarGroup
          title='Entwurf'
          className='customer-invoice-actions-panel'
        >
          {props.canSaveAsNonDraft ? (
            <>
              <Coachmark coachmarkId='customer-invoice-draft'>
                Stelle die {props.document.isQuote ? 'Offerte' : 'Forderung'},
                um ein PDF zu erstellen oder sie per E-Mail zu versenden.
              </Coachmark>
              <CustomerInvoiceToggleDraftAction {...props} />
            </>
          ) : (
            <Lockup icon='info' gap='small'>
              Vervollständige Titel, Empfänger und Positionen, um die{' '}
              {props.document.isQuote ? 'Offerte' : 'Forderung'} zu stellen.
            </Lockup>
          )}
        </SidebarGroup>
      )}
      <SidebarGroup title='Dokument' className='customer-invoice-actions-panel'>
        <CustomerInvoiceDuplicateAction {...props} />
        {canEdit && <CustomerInvoiceToggleQuoteAction {...props} />}
        <DocumentSidebarNoEditMessage {...props} />
        <CreatePresetAction {...props} />
      </SidebarGroup>
    </>
  )
}

export default DocumentSidebarDraftView
