import { Flex } from '@nextbusiness/infinity-ui'
import ExtendedInvoiceListItemLoading from './ExtendedInvoiceListItemLoading'

const ExtendedInvoiceListLoading = () => (
  <Flex>
    {[...Array(4)].map((_, i) => (
      <ExtendedInvoiceListItemLoading key={i} />
    ))}
  </Flex>
)

export default ExtendedInvoiceListLoading
