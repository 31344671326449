import { TaxCode } from '@nextbusiness/infinity-finance-api'
import { Checkbox, Flex } from '@nextbusiness/infinity-ui'
import VATPicker from '../vat-picker/VATPicker'
import './VATSelection.scss'

interface VATSelectionProps {
  label: string
  showVatPicker: boolean
  setShowVatPicker: (showVatPicker: boolean) => void
  taxCode?: string
  setTaxCode: (taxCode: string) => void
}

const VATSelection = (props: VATSelectionProps) => {
  return (
    <Flex className='vat-selection' verticalAlignment='center' gap='small'>
      <Checkbox
        className='vat-checkbox'
        isChecked={props.showVatPicker}
        label={props.label}
        onChange={() => props.setShowVatPicker(!props.showVatPicker)}
      />
      {props.showVatPicker && (
        <VATPicker
          taxCode={(props.taxCode ?? '') as TaxCode}
          onTaxCodeSelected={props.setTaxCode}
        />
      )}
    </Flex>
  )
}

export default VATSelection
