import { useParams } from 'react-router-dom'
import SingleContactPageStack from './SingleContactPageStack'

const SingleContactPage = () => {
  const { id } = useParams<{ id: string }>()

  return (
    <div className='page page-single-contact'>
      <SingleContactPageStack contactId={id} />
    </div>
  )
}

export default SingleContactPage
