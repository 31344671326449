import ErrorScreen from 'base-components/layout/ErrorScreen'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'

interface VATReportTransactionsErrorPageProps {
  onRetry: () => void
  onClose: () => void
  error?: Error
}

const VATReportTransactionsErrorPage = (
  props: VATReportTransactionsErrorPageProps
) => (
  <MagicSheetPage>
    <ErrorScreen
      title='Transaktionen können nicht geladen werden'
      onRetry={props.onRetry}
      onClose={props.onClose}
      defaultMessage='Beim Laden der Transaktionen ist ein Fehler aufgetreten. Bitte versuche es erneut.'
      error={props.error}
    />
  </MagicSheetPage>
)

export default VATReportTransactionsErrorPage
