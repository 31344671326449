import { useAccountPageContext } from 'ledger/accounts/AccountPageContext'
import OpeningSpecialAccountActions, {
  OPENING_ACCOUNT_NUMBER,
} from './OpeningSpecialAccountActions'

const SpecialAccountActions = () => {
  const { currentAccount } = useAccountPageContext()

  if (currentAccount.accountNumber === OPENING_ACCOUNT_NUMBER)
    return <OpeningSpecialAccountActions />

  return null
}

export default SpecialAccountActions
