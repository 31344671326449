import { AmountMode } from '@nextbusiness/infinity-finance-api'
import { Flex } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { IPosition } from 'model/VendorInvoice'
import { useRootStore } from 'stores/RootStoreContext'
import Utilities from 'utility/Utilities'
import { useVendorInvoiceEditor } from '../detail/VendorInvoiceEditorContext'
import VendorInvoiceEditorUtilities from '../detail/VendorInvoiceEditorUtilitites'
import AmountModeSwitcher from './AmountModeSwitcher'
import './TotalRows.scss'

interface TotalRowsProps {
  positions?: Partial<IPosition>[]
  isReadOnly?: boolean
}

const TotalRows = (props: TotalRowsProps) => {
  const { preferencesStore } = useRootStore()
  const { makeEdit, amountMode, invoiceDraft } = useVendorInvoiceEditor()

  const toggleAmountMode = () =>
    makeEdit({
      amountMode:
        invoiceDraft.amountMode !== AmountMode.Net
          ? AmountMode.Net
          : AmountMode.Gross,
    })

  return (
    <Flex className='total-rows' direction='vertical'>
      <Flex
        className={classNames(
          'total-row',
          amountMode === AmountMode.Net && 'net-total'
        )}
        gap='tiny'
      >
        <span className='label'>
          <AmountModeSwitcher
            isUsingEffectiveVAT={preferencesStore.isUsingEffectiveVAT}
            onAmountModeChange={toggleAmountMode}
            amountMode={amountMode}
            isReadOnly={props.isReadOnly}
          />
        </span>
        <span className='sum'>
          {Utilities.centsToCHF(
            VendorInvoiceEditorUtilities.totalSumOfInvoice(invoiceDraft)
          )}{' '}
          CHF
        </span>
      </Flex>
      {amountMode === AmountMode.Net && (
        <Flex className='total-row'>
          <span className='label'>Gesamttotal</span>
          <span className='sum'>
            {Utilities.centsToCHF(
              VendorInvoiceEditorUtilities.sumOfPositionsWithVAT(
                props.positions!,
                AmountMode.Net
              )
            )}{' '}
            CHF
          </span>
        </Flex>
      )}
    </Flex>
  )
}

export default observer(TotalRows)
