import { Button, Flex } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useMemo, useState } from 'react'
import ContactStore from 'stores/ContactStore'
import AbortEditDialog from '../../components/dialogs/AbortEditDialog'
import { ErrorType } from '../../libs/networking/Errors'
import { Contact } from '../../model/Contact'
import contactDialogStore from '../../stores/ContactDialogStore'
import { useRootStore } from '../../stores/RootStoreContext'
import { TypedKeyValueStore } from '../../utility/types'
import { AbortIntent } from '../ContactsDialog'
import './ContactsListSegment.scss'
import ListSegmentHeader from './ListSegmentHeader'

interface ContactsListSegmentProps {
  setWasContactFound: (wasContactFound: boolean) => void
  searchQuery?: string
  activeCategory?: string
}

const filterContactsByCategory = (
  contacts: Contact[],
  activeCategory: string
) => {
  return contacts.filter((contact) => contact.category === activeCategory)
}

const filterContactsBySearchQuery = (contacts: Contact[], query: string) => {
  const caseInsensitiveQuery = query.toLowerCase().trim()

  return contacts.filter((contact) => {
    const fullName =
      contact.firstname && contact.lastname
        ? contact.firstname + ' ' + contact.lastname
        : null

    return (
      contact.firstname?.toLowerCase().includes(caseInsensitiveQuery) ||
      contact.lastname?.toLowerCase().includes(caseInsensitiveQuery) ||
      contact.companyName?.toLowerCase().includes(caseInsensitiveQuery) ||
      fullName?.toLowerCase().includes(caseInsensitiveQuery)
    )
  })
}

const ContactsListSegment = (props: ContactsListSegmentProps) => {
  const { contactStore } = useRootStore()
  const { cancelAbort } = contactDialogStore

  const [clickedContactId, setClickedContactId] = useState<string>('')

  const {
    mode,
    setMode,
    activeContact,
    getContact,
    reasonForAborting,
    abortEditing,
    setErrorMessage,
  } = contactDialogStore

  const handleError = (error: any) => {
    switch (error.type) {
      case ErrorType.NotFound:
        return props.setWasContactFound(false)
      default:
        return setErrorMessage(
          error.message ||
            'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut.'
        )
    }
  }

  const sectionList = useMemo(() => {
    const accumulator: TypedKeyValueStore<Contact[]> = {}
    let contacts = contactStore.sorted

    if (props.activeCategory && props.activeCategory !== 'all') {
      contacts = filterContactsByCategory(contacts, props.activeCategory)
    }
    if (props.searchQuery && props.searchQuery.trim() !== '') {
      contacts = filterContactsBySearchQuery(contacts, props.searchQuery)
    }

    contacts.forEach((contact) => {
      const name = ContactStore.nameOfContact(contact)
      const firstLetter = name.substring(0, 1).toLocaleUpperCase()

      if (!accumulator[firstLetter]) accumulator[firstLetter] = [contact]
      else accumulator[firstLetter].push(contact)
    })

    return accumulator
  }, [contactStore.sorted, props.searchQuery, props.activeCategory])

  return (
    <div className='contacts-list-segment'>
      {Object.keys(sectionList).map((key, index) => (
        <div key={index}>
          <ListSegmentHeader letter={key} />

          <Flex direction='vertical'>
            {sectionList[key].map((contact, index) => (
              <Button
                key={index}
                variant='shell'
                className={`contacts-list-element ${
                  activeContact?.id === contact.id && mode !== 'creating'
                    ? 'active'
                    : ''
                }`}
                onClick={async () => {
                  if (
                    (mode === 'creating' || mode === 'editing') &&
                    contact.id !== activeContact?.id
                  ) {
                    setClickedContactId(contact.id)
                    abortEditing(async () => {
                      await getContact(contact.id)
                      setMode('viewing')
                    }, AbortIntent.SelectOtherContact)
                  } else {
                    try {
                      await getContact(contact.id)
                    } catch (error: any) {
                      handleError(error)
                    }
                  }
                }}
              >
                {ContactStore.nameOfContact(contact)}
              </Button>
            ))}
          </Flex>
        </div>
      ))}
      <AbortEditDialog
        isAbortingEdit={reasonForAborting === AbortIntent.SelectOtherContact}
        onAbort={async () => {
          try {
            await getContact(clickedContactId)
            setMode('viewing')
          } catch (error: any) {
            handleError(error)
          }
        }}
        onCancel={cancelAbort}
      />
    </div>
  )
}

export default observer(ContactsListSegment)
