import PositionContraAccount from '../fields/PositionContraAccount'
import PositionVATPicker from '../fields/PositionVATPicker'
import DocumentCustomProductPositionMoreMenu from './DocumentCustomProductPositionMoreMenu'
import { DocumentPositionFlyoutProps } from './DocumentPositionFlyout'

const DocumentCustomProductPositionFlyout = (
  props: DocumentPositionFlyoutProps
) => (
  <>
    <DocumentCustomProductPositionMoreMenu {...props} />
    <PositionContraAccount {...props} />
    <PositionVATPicker {...props} />
  </>
)

export default DocumentCustomProductPositionFlyout
