import { AccountSheetFormat, Finance } from '@nextbusiness/infinity-finance-api'
import { Dialog, Text, useNotificationCenter } from '@nextbusiness/infinity-ui'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import useFileDownload from 'utility/hooks/useFileDownload'
import AccountSheetDateSelection from './AccountSheetDateSelection'
import AccountSheetOptions from './AccountSheetOptions'
import AccountSheetRangeSelection, {
  AccountSelection,
} from './AccountSheetRangeSelection'
import ReportOptions from './ReportOptions'

interface DownloadAccountSheetDialogProps {
  isOpen: boolean
  onDismiss: () => void
}

const DownloadAccountSheetDialog = (props: DownloadAccountSheetDialogProps) => {
  const { accountStore, ledgerStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [startDate, setStartDate] = useState<Date | undefined>()
  const [endDate, setEndDate] = useState<Date | undefined>()

  const [accountSelection, setAccountSelection] = useState<AccountSelection>(
    AccountSelection.All
  )
  const [accountRangeFrom, setAccountRangeFrom] = useState<number | undefined>(
    1000
  )
  const [accountRangeTo, setAccountRangeTo] = useState<number | undefined>(9999)
  const [format, setFormat] = useState<AccountSheetFormat>('pdf')

  const [skipEmptyAccounts, setSkipEmptyAccounts] = useState<boolean>(true)
  const [grossTransactionsOnly, setGrossTransactionsOnly] =
    useState<boolean>(false)

  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const isValid = () => {
    return startDate && endDate && startDate <= endDate
  }

  const { download: downloadAccountSheet, isPending } = useFileDownload(
    async () => {
      setShouldValidate(true)
      if (!isValid()) return null
      MixpanelAnalytics.event('Account sheet export downloaded', {
        format,
        grossTransactionsOnly,
      })
      const accountSheet = await Finance.Reporting.accountSheet({
        format,
        rangeStart: accountRangeFrom ?? 1000,
        rangeEnd: accountRangeTo ?? 9999,
        from: startDate!.getTime(),
        to: endDate!.getTime(),
        grossTransactionsOnly,
        skipEmptyAccounts,
      })
      props.onDismiss()
      return accountSheet
    },
    {
      fileName:
        accountSelection === AccountSelection.Single
          ? `Kontenblatt ${accountRangeFrom}`
          : `Kontenblatt ${accountRangeFrom}-${accountRangeTo}`,
      customErrorHandler: () =>
        notificationCenter.addNotification({
          title: 'Kontenblatt konnte nicht erstellt werden.',
          children:
            'Möglicherweise sind keine Buchungen für die gewählten Kriterien vorhanden.',
        }),
    }
  )

  const fiscalYear = accountStore.currentFiscalYear

  useEffect(() => {
    if (fiscalYear) {
      setStartDate(new Date(fiscalYear.from))
      setEndDate(new Date(fiscalYear.to))
    }
  }, [fiscalYear])

  useEffect(() => {
    if (accountSelection === AccountSelection.All) {
      setAccountRangeFrom(1000)
      setAccountRangeTo(9999)
    } else if (accountSelection === AccountSelection.Single) {
      setAccountRangeFrom(ledgerStore.currentRootView)
      setAccountRangeTo(ledgerStore.currentRootView)
    } else if (accountSelection === AccountSelection.Range) {
      if (!accountRangeFrom) setAccountRangeFrom(1000)
      if (!accountRangeTo) setAccountRangeTo(9999)
    }
  }, [accountSelection])

  useEffect(() => {
    if (
      accountRangeFrom &&
      accountRangeTo &&
      accountRangeFrom > accountRangeTo
    ) {
      setAccountRangeTo(accountRangeFrom)
    }
    if (accountSelection === AccountSelection.Single) {
      setAccountRangeTo(accountRangeFrom)
    }
  }, [accountRangeFrom, accountRangeTo, accountSelection])

  return (
    <Dialog
      title='Kontenblatt herunterladen'
      icon='report-file'
      actions={[
        {
          children: 'Abbrechen',
          onClick: props.onDismiss,
        },
        {
          children: 'Herunterladen',
          variant: 'primary',
          isLoading: isPending,
          onClick: downloadAccountSheet,
        },
      ]}
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      dismissable
      style={{ maxWidth: '56rem' }}
    >
      <Text type='inline'>
        Das Kontenblatt beinhaltet alle Buchungen mit laufendem Saldo, welche
        das jeweilige Konto betreffen.
      </Text>
      <ReportOptions>
        <AccountSheetDateSelection
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          shouldValidate={shouldValidate}
        />
        <AccountSheetRangeSelection
          accountRangeFrom={accountRangeFrom}
          accountRangeTo={accountRangeTo}
          accountSelection={accountSelection}
          setAccountRangeFrom={setAccountRangeFrom}
          setAccountRangeTo={setAccountRangeTo}
          setAccountSelection={setAccountSelection}
        />
        <AccountSheetOptions
          format={format}
          setFormat={setFormat}
          skipEmptyAccounts={skipEmptyAccounts}
          setSkipEmptyAccounts={setSkipEmptyAccounts}
          grossTransactionsOnly={grossTransactionsOnly}
          setGrossTransactionsOnly={setGrossTransactionsOnly}
        />
      </ReportOptions>
    </Dialog>
  )
}

export default observer(DownloadAccountSheetDialog)
