import { TemplatePreviewProps } from './TemplatePreview'

const TemplatePreviewPageStartText = (props: TemplatePreviewProps) => (
  <div className='page-start-text'>
    {props.showQuotePreview ? (
      props.template.defaultQuoteOpeningText ? (
        <p>{props.template.defaultQuoteOpeningText}</p>
      ) : (
        <p>
          Vielen Dank für Ihr Interesse.
          <br />
          Gerne unterbreiten wir Ihnen folgendes Angebot.
        </p>
      )
    ) : props.template.defaultOpeningText ? (
      <p>{props.template.defaultOpeningText}</p>
    ) : (
      <p>
        Vielen Dank für Ihr Vertrauen in unser Geschäft.
        <br />
        Hiermit stellen wir Ihnen folgende Posten in Rechnung:
      </p>
    )}
  </div>
)

export default TemplatePreviewPageStartText
