import { useAccountPageContext } from 'ledger/accounts/AccountPageContext'
import { useAccountSheetContext } from 'ledger/accounts/AccountSheetContext'
import { useEffect } from 'react'
import { store } from 'stores/RootStoreContext'
import { useTransactionEditorContext } from '../TransactionEditorContext'

const useMarkUnsavedEdit = () => {
  const { draftTransaction, hasEditedTransaction } =
    useTransactionEditorContext()
  const { viewId } = useAccountPageContext()
  const { setHasUnsavedChanges } = useAccountSheetContext()

  useEffect(() => {
    if (hasEditedTransaction) {
      store.ledgerStore.addUnsavedEdit(viewId)
      setHasUnsavedChanges(true)
    } else {
      store.ledgerStore.resolveUnsavedEdit(viewId)
      setHasUnsavedChanges(false)
    }
  }, [draftTransaction])
}

export default useMarkUnsavedEdit
