import { INewTransaction } from '@nextbusiness/infinity-finance-api'
import { ResolvedAccountAction } from 'ledger/accounts/data/AccountRules'
import { createContext, useContext } from 'react'
import TransactionCreatorUtilities from './TransactionCreatorUtilities'

export interface TransactionCreatorContextType {
  activeAction: ResolvedAccountAction
  changeAction: (action: ResolvedAccountAction) => void
  closeTransactionCreator: () => void
  transaction: Partial<INewTransaction>
  updateTransaction: (transaction: Partial<INewTransaction>) => void
  numberKey: number
  incrementNumberKey: () => void
  utilities: TransactionCreatorUtilities
  createTransaction: () => void
  isCreating: boolean
  setShouldCurrentAccountIncrease: (shouldIncrease: boolean) => void
}

export const TransactionCreatorContext =
  createContext<TransactionCreatorContextType | null>(null)

export const useTransactionCreatorContext = () => {
  const context = useContext(TransactionCreatorContext)
  if (!context)
    throw new Error(
      'useTransactionCreatorContext must be used within a TransactionCreatorProvider'
    )
  return context
}
