import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import ResourceOption, {
  ResourceOptionProps,
} from 'components/resource-select/ResourceOption'
import { ITaxOption } from './TaxCodes'
import { TaxCode } from '@nextbusiness/infinity-finance-api'

interface VATSelectOptionProps {
  taxOption: ITaxOption
  optionProps: ResourceOptionProps<TaxCode, ITaxOption>
}

export const searchTextForTaxOption = (option: ITaxOption) => {
  const { code } = option

  const displayText = displayTextForSelectedTaxOption(option)
  const codeSuffix = code ? ' (' + code + ')' : ''

  return displayText + codeSuffix
}

export const displayTextForSelectedTaxOption = (option: ITaxOption) => {
  const { rate, name } = option
  return `${rate ? rate + '% ' : ''}${name}`
}

const VATSelectOption = (props: VATSelectOptionProps) => {
  const { name, icon, description } = props.taxOption
  const isSearching =
    props.optionProps.hasSearched && !!props.optionProps.enteredValue.trim()

  return (
    <ResourceOption
      customTitle={isSearching ? searchTextForTaxOption(props.taxOption) : name}
      description={!isSearching && description}
      leftAccessory={<Icon icon={icon} size={IconSize.Small} />}
      {...props.optionProps}
    />
  )
}

export default VATSelectOption
