import { APIError } from '@nextbusiness/infinity-finance-api'
import { useNotificationCenter } from '@nextbusiness/infinity-ui'
import { useEffect, useState } from 'react'

export type APIQuery<T> = [
  T | undefined,
  boolean,
  APIError | undefined,
  (invalidateWithNewState?: T) => Promise<void>
]

/**
 * Convenience hook that simplifies an API query, with some built-in error handling.
 * May only be used for queries that don't affect any store.
 *
 * @param networkFunction The promise that will be executed on load
 * @returns A tuple consisting of the requested object or undefined, a boolean indicating whether
 * the request is still loading, an optional error and a function to invalidate/refresh the data.
 */
const useAPIQuery = <T>(
  networkFunction: () => Promise<T>,
  options?: {
    completionHandler?: (requestedObject: T) => void
    errorHandler?: (error: APIError) => void
    errorStateOnly?: boolean
  }
): APIQuery<T> => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [data, setData] = useState<T>()
  const [error, setError] = useState<APIError>()

  const notificationCenter = useNotificationCenter()

  const resolveWithData = (data: T) => {
    setError(undefined)
    setData(data)
    if (options?.completionHandler) options.completionHandler(data)
  }

  const loadData = async () => {
    setIsLoading(true)
    try {
      const response = await networkFunction()
      resolveWithData(response)
    } catch (error: any) {
      setError(error)
      if (options?.errorHandler) {
        options.errorHandler(error)
      } else if (error instanceof APIError && !options?.errorStateOnly) {
        notificationCenter.addNotification({
          variant: 'error',
          children: error.humanText('de'),
        })
      }
    } finally {
      setIsLoading(false)
    }
  }
  const invalidateData = async (invalidateWithNewState?: T) => {
    if (isLoading) return
    if (invalidateWithNewState) {
      resolveWithData(invalidateWithNewState)
      return
    }
    await loadData()
  }

  useEffect(() => {
    loadData()
  }, [])

  return [data, isLoading, error, invalidateData]
}

export default useAPIQuery
