import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import { observer } from 'mobx-react'
import DocumentsPage, { DocumentsPagePaths } from 'pages/DocumentsPage'
import { useEffect } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { usePeriodRefresh } from 'utility/hooks/usePeriodRefresh'
import './DocumentsHome.scss'
import DocumentsHomeClosings from './DocumentsHomeClosings'
import DocumentsHomeHeader from './DocumentsHomeHeader'
import NewInboxDocuments from './inbox-documents/NewInboxDocuments'
import DocumentLiveCaptureSetupBanner from './live-capture-setup/DocumentLiveCaptureSetupBanner'
import RecentlyProcessedDocuments from './processed-documents/RecentlyProcessedDocuments'

interface DocumentsHomeProps {
  page: DocumentsPage
}

const DocumentsHome = (props: DocumentsHomeProps) => {
  const { documentStore } = useRootStore()

  useEffect(() => {
    documentStore.loadAllDocuments()
  }, [])
  usePeriodRefresh(documentStore.loadAllDocuments)

  return (
    <MagicSheetPage
      header={
        <DocumentsHomeHeader
          openCreateReceiptModal={() =>
            props.page.open(DocumentsPagePaths.DocumentUpload)
          }
        />
      }
      className='documents-home'
    >
      <DocumentLiveCaptureSetupBanner />
      <NewInboxDocuments page={props.page} />
      <RecentlyProcessedDocuments page={props.page} />
      <MagicSheetContainer>
        <DocumentsHomeClosings page={props.page} />
      </MagicSheetContainer>
    </MagicSheetPage>
  )
}

export default observer(DocumentsHome)
