import { ICustomerInvoicePreview } from 'model/CustomerInvoice'
import { CustomerInvoicesFilterProvider } from './filters/CustomerInvoicesFilterContext'
import CustomerInvoicesFilteredList from './filters/CustomerInvoicesFilteredList'
import CustomerInvoicesFilterOptions from './filters/CustomerInvoicesFilterOptions'

interface CustomerInvoicesListProps {
  invoices: ICustomerInvoicePreview[] | undefined
  type: 'invoices' | 'quotes'
}

const CustomerInvoicesList = (props: CustomerInvoicesListProps) => {
  return (
    <CustomerInvoicesFilterProvider>
      <CustomerInvoicesFilterOptions type={props.type} />
      <CustomerInvoicesFilteredList
        invoices={props.invoices}
        emptyStateText={
          props.type === 'invoices' ? 'Keine Forderungen' : 'Keine Offerten'
        }
      />
    </CustomerInvoicesFilterProvider>
  )
}

export default CustomerInvoicesList
