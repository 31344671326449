import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import LinearGauge from 'components/gauges/LinearGauge'
import TransactionAmount from 'components/transactions/TransactionAmount'
import { DateTime } from 'luxon'
import { IAccount } from 'model/Account'
import { KeyboardEvent, MouseEvent, useState } from 'react'
import { ITransactionGroup } from './StringSimilarity'

interface AccountDetailTransactionGroupProps {
  account: IAccount
  group: ITransactionGroup
  maxGroupSum: number
}

const AccountDetailTransactionGroup = (
  props: AccountDetailTransactionGroupProps
) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)

  const groupPercentage = props.group.sum / (props.account.balance || 1)
  const maxGroupPercentage = props.maxGroupSum / (props.account.balance || 1)

  const onGroupSelected = (e: MouseEvent | KeyboardEvent) => {
    e.stopPropagation()
    setIsCollapsed(!isCollapsed)
  }

  return (
    <div className='report-group'>
      <div
        className='report-group-row'
        role='row'
        tabIndex={0}
        onClick={onGroupSelected}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onGroupSelected(e)
        }}
      >
        <Icon
          className={'icon' + (isCollapsed ? ' collapsed' : '')}
          icon='expand'
          size={IconSize.ExtraTiny}
        />
        <div className='group-title'>{props.group.title}</div>
        <span className='group-percentage'>
          {(groupPercentage * 100).toFixed(2)}%
        </span>
        <LinearGauge
          value={groupPercentage / (maxGroupPercentage || 1)}
          colour={props.account.colour ?? '#ddd'}
        />
        <TransactionAmount amount={props.group.sum} />
      </div>
      {isCollapsed && (
        <div className='report-group-transactions'>
          {props.group.transactions.map((transaction) => (
            <div key={transaction.id} className='transaction-row'>
              <span className='date'>
                {DateTime.fromMillis(transaction.date).toLocaleString({
                  month: '2-digit',
                  day: '2-digit',
                })}
              </span>
              <span className='title'>
                {transaction.description.replaceAll('Eröffnung ', '')}
              </span>
              <TransactionAmount amount={transaction.amount} />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default AccountDetailTransactionGroup
