import { LegalType } from '@nextbusiness/infinity-finance-api'
import { INewTemplate } from '@nextbusiness/infinity-finance-api/dist/models/ITemplate'
import { Dialog, Flex, InputField, Text } from '@nextbusiness/infinity-ui'
import FormField from 'base-components/layout/FormField'
import Lockup from 'components/text/Lockup'
import { observer } from 'mobx-react'
import { BLANK_TEMPLATE } from 'model/Template'
import { useState } from 'react'
import SettingsGroup from 'settings/general/SettingsGroup'
import { useRootStore } from 'stores/RootStoreContext'
import { useEnter } from 'utility/hooks'
import './SetupModalAddress.scss'

interface SetupModalAddressProps {
  isOpen: boolean
  onAbort: () => void
  onContinue: (template: INewTemplate) => void
}

const SetupModalAddress = (props: SetupModalAddressProps) => {
  const { authenticationStore, preferencesStore } = useRootStore()

  const [organisationName, setOrganisationName] = useState<string>(
    authenticationStore.currentOrganisationName
  )
  const [addressLine, setAddressLine] = useState<string>('')
  const [zipCode, setZipCode] = useState<string>('')
  const [city, setCity] = useState<string>('')

  const isValidZipCode = /^\d+$/.test(zipCode)
  const isTemplateComplete =
    organisationName.length > 0 &&
    addressLine.length > 0 &&
    zipCode.length > 0 &&
    city.length > 0
  const isTemplateValid = isTemplateComplete && isValidZipCode

  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const onContinue = () => {
    setShouldValidate(true)
    if (!isTemplateValid) return

    props.onContinue({
      ...BLANK_TEMPLATE,
      name: 'default',
      organisationName,
      addressLine,
      zipCode: parseInt(zipCode),
      city,
    })
  }

  useEnter(onContinue, props.isOpen)

  return (
    <Dialog
      isOpen={props.isOpen}
      className='customer-invoice-editor-setup-modal modal-address'
      title='Firmenanschrift vervollständigen'
      titleProps={{ divider: true }}
      actions={[
        { children: 'Abbrechen', onClick: props.onAbort },
        { children: 'Fortfahren', variant: 'primary', onClick: onContinue },
      ]}
    >
      <Text className='instruction'>
        Vervollständige deine Firmenanschrift, welche dann auf all deinen
        Forderungen und Offerten als Absender erscheint.
      </Text>
      <SettingsGroup title='Deine Firma'>
        <FormField
          label={
            preferencesStore.organisationPreferences?.legalType ===
            LegalType.SoleProprietorship
              ? 'Firmenname / dein Name'
              : 'Firmenname'
          }
        >
          <InputField
            value={organisationName}
            onChange={setOrganisationName}
            fullWidth
            hasError={shouldValidate && !organisationName}
          />
        </FormField>
        <FormField label='Adresse'>
          <InputField
            value={addressLine}
            onChange={setAddressLine}
            fullWidth
            hasError={shouldValidate && !addressLine}
          />
        </FormField>
        <FormField label='Postleitzahl und Ort'>
          <Flex gap='tiny'>
            <InputField
              value={zipCode}
              onChange={setZipCode}
              className='postal-code-input'
              hasError={shouldValidate && (!zipCode || !isValidZipCode)}
            />
            <InputField
              value={city}
              onChange={setCity}
              fullWidth
              hasError={shouldValidate && !city}
            />
          </Flex>
        </FormField>
        {shouldValidate && !isTemplateValid && (
          <Text variant='error'>
            {!isTemplateComplete
              ? 'Diese Pflichtangaben sind auf Schweizer Rechnungen erforderlich.'
              : !isValidZipCode
              ? 'Es sind nur Zahlen in der Postleitzahl erlaubt.'
              : null}
          </Text>
        )}
      </SettingsGroup>
      <Lockup icon='info'>
        Du kannst später in den Einstellungen diese Angaben ändern.
      </Lockup>
    </Dialog>
  )
}

export default observer(SetupModalAddress)
