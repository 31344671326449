import { Button, Flex, Heading, Text } from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import React, {
  KeyboardEvent,
  MouseEvent,
  useContext,
  useEffect,
  useState,
} from 'react'
import { ReactComponent as TriangleFilledDown } from '../../assets/triangle-filled-down.svg'
import { ReactComponent as TriangleFilledUp } from '../../assets/triangle-filled-up.svg'
import { IAccount } from '../../model/Account'
import { useRootStore } from '../../stores/RootStoreContext'
import { centsToCHF } from '../../utility/Utilities'
import IncomeStatementContext from './IncomeStatementContext'

interface IncomeStatementLayerProps {
  name: string
  balance: number
  depth: 0 | 1 | 2
  pastBalance?: number
  account?: IAccount
  nestedLayer?: React.ReactNode
  className?: string
}

const IncomeStatementLayer = (props: IncomeStatementLayerProps) => {
  const { ledgerStore, preferencesStore } = useRootStore()
  const {
    areAllExpanded,
    areAllCollapsed,
    setAreAllExpanded,
    setAreAllCollapsed,
    history,
  } = useContext(IncomeStatementContext)

  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    props.depth > 1 ? true : false
  )

  const relativeChange = () => {
    const relativeChange = props.pastBalance
      ? Math.round(((1 / props.pastBalance) * props.balance - 1) * 1000) / 10
      : 0

    const changeIsVerySmall = 1 > relativeChange && relativeChange > -1

    return changeIsVerySmall ? relativeChange : Math.round(relativeChange)
  }

  const relativeChangeContent = () => (
    <span className='content'>
      {!relativeChange() ? '–' : `${relativeChange()}%`}
      {!relativeChange() ? null : relativeChange() > 0 ? (
        <TriangleFilledUp className='change-icon triangle-filled-up' />
      ) : (
        <TriangleFilledDown className='change-icon triangle-filled-down' />
      )}
    </span>
  )

  useEffect(() => {
    if (areAllExpanded) setIsCollapsed(false)
  }, [areAllExpanded])

  useEffect(() => {
    if (areAllCollapsed) setIsCollapsed(true)
  }, [areAllCollapsed])

  const onLayerSelected = (e: MouseEvent | KeyboardEvent) => {
    e.stopPropagation()
    setIsCollapsed(!isCollapsed)
    setAreAllExpanded(false)
    setAreAllCollapsed(false)
  }

  return (
    <div
      className={classNames('income-statement-layer', props.className, {
        collapsed: isCollapsed,
      })}
      role='row'
      tabIndex={0}
      onClick={onLayerSelected}
      onKeyDown={(e) => {
        if (e.key === 'Enter') onLayerSelected(e)
      }}
    >
      {props.depth === 0 ? (
        <Flex className='row'>
          <Heading className='name depth-0' type='h3'>
            {props.nestedLayer && (
              <Icon
                className={'icon' + (isCollapsed ? ' collapsed' : '')}
                icon='expand'
                size={IconSize.ExtraTiny}
              />
            )}
            {props.name}
          </Heading>
          <Heading className='balance depth-0' type='h3'>
            {centsToCHF(props.balance)}
          </Heading>
        </Flex>
      ) : (
        <Flex className='row'>
          <Text className={`name depth-${props.depth}`} type='inline'>
            {props.account ? (
              <Button
                className='account-button'
                variant='quaternary'
                onClick={
                  ((e: Event) => {
                    e.stopPropagation()
                    ledgerStore.setCurrentRootView(props.account!.accountNumber)
                    history?.push('/buchen')
                  }) as () => void
                }
              >
                <div
                  className='colour-swatch'
                  style={{
                    backgroundColor: props.account.colour || 'grey',
                  }}
                />
                {preferencesStore.showAccountNumbers && (
                  <span className='account-number'>
                    {props.account.accountNumber}
                  </span>
                )}
                <span>{props.name}</span>
              </Button>
            ) : (
              <>
                {props.nestedLayer && (
                  <Icon
                    className={'icon' + (isCollapsed ? ' collapsed' : '')}
                    icon='expand'
                    size={IconSize.ExtraTiny}
                  />
                )}
                {props.name}
              </>
            )}
          </Text>

          {[props.pastBalance, props.balance].map((balance, index) => (
            <Text
              key={index}
              className={`balance past-column depth-${props.depth} ${
                balance === undefined || index === 0 ? 'hidden' : ''
              }`}
              type='inline'
            >
              <span className='content'>{centsToCHF(balance)}</span>
            </Text>
          ))}

          <Text
            className={`balance change depth-${props.depth} ${
              props.pastBalance === undefined ? 'hidden' : ''
            }`}
            type='inline'
          >
            {relativeChangeContent()}
          </Text>
        </Flex>
      )}

      <div
        className='nested-layer'
        style={{
          height: isCollapsed ? 0 : 'fit-content',
        }}
      >
        {props.nestedLayer}
      </div>
    </div>
  )
}

export default observer(IncomeStatementLayer)
