import React from 'react'
import { INewTextLinePosition } from '../../../../../../model/CustomerInvoice'
import EditableDocumentText from '../../controls/EditableDocumentText'
import DocumentPositionProps from './DocumentPositionProps'

interface DocumentTextLinePositionProps extends DocumentPositionProps {
  position: INewTextLinePosition
}

const DocumentTextLinePosition = (props: DocumentTextLinePositionProps) => (
  <div className='positions-column text'>
    <EditableDocumentText
      value={props.position.content}
      onChange={(content) => props.onChange({ content })}
      placeholder='Text hier eingeben'
      isMultiline
      isReadOnly={props.isReadOnly}
    />
  </div>
)

export default DocumentTextLinePosition
