import { Checkbox, Flex, Text } from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import Tabs, { Tab } from 'proto-ui-components/Tabs'
import { STRUCTURED_TAX_RATES, TaxRateEra, TaxRateType } from './TaxCodes'
import './TaxRateSelection.scss'

interface TaxRateSelectionProps {
  selectedTaxRateType: TaxRateType
  onSelectTaxRateType: (taxRate: TaxRateType) => void
  taxRateEra: TaxRateEra
  onTaxRateEraChange: (taxRateEra: TaxRateEra) => void
}

const TaxRateSelection = (props: TaxRateSelectionProps) => {
  const taxRates = STRUCTURED_TAX_RATES[props.taxRateEra]
  const helpcentreArticle =
    'https://help.infinity.swiss/de/articles/6580083-wie-verbuche-ich-mwst'

  const tabs: Tab<TaxRateType>[] = [
    {
      identifier: 'normal',
      title: `${taxRates.normal}% Normal`,
      onClick: () => props.onSelectTaxRateType('normal'),
    },
    {
      identifier: 'special',
      title: `${taxRates.special}%`,
      onClick: () => props.onSelectTaxRateType('special'),
    },
    {
      identifier: 'reduced',
      title: `${taxRates.reduced}%`,
      onClick: () => props.onSelectTaxRateType('reduced'),
    },
  ]

  return (
    <Flex gap={2.2} direction='vertical' className='tax-rate-selection'>
      <Flex horizontalAlignment='space-between' verticalAlignment='center'>
        <Tabs currentTabId={props.selectedTaxRateType} tabs={tabs} />
        <a
          className='help'
          href={helpcentreArticle}
          target='_blank'
          rel='noreferrer'
        >
          <Icon icon='help' size={IconSize.Small} />
        </a>
      </Flex>
      <Checkbox
        isChecked={props.taxRateEra === '2024-reform'}
        onChange={(isChecked) =>
          props.onTaxRateEraChange(isChecked ? '2024-reform' : '2018-reform')
        }
        label={
          <Flex direction='vertical'>
            <Text type='inline'>
              Neue Mehrwertsteuersätze (ab 2024) verwenden
            </Text>
            <a
              className='learn-more'
              href={
                'https://help.infinity.swiss/de/articles/8682081-die-neuen-mwst-satze-verwenden'
              }
              target='_blank'
              rel='noreferrer'
            >
              Mehr erfahren
            </a>
          </Flex>
        }
      />
    </Flex>
  )
}

export default TaxRateSelection
