import { IAccount } from '@nextbusiness/infinity-finance-api'
import { TypedKeyValueStore } from '@nextbusiness/infinity-ui'
import { useCallback } from 'react'

const useSetupAccountBalanceSums = (
  allAccounts: IAccount[],
  accountBalances: TypedKeyValueStore<number | null>
) => {
  const assetAccounts = allAccounts.filter(
    (account) =>
      account.accountType === 'assets' && account.accountNumber < 9000
  )
  const liabilityAccounts = allAccounts.filter(
    (account) => account.accountType === 'liabilities'
  )

  const sumOfAccountBalances = useCallback(
    (accounts: IAccount[]) =>
      Math.round(
        accounts
          .map((account) => accountBalances[account.accountNumber] ?? 0)
          .reduce((a, b) => a + b, 0) * 100
      ),
    [accountBalances]
  )
  const sumOfAssets = sumOfAccountBalances(assetAccounts)
  const sumOfLiabilities = sumOfAccountBalances(liabilityAccounts)

  return { sumOfAssets, sumOfLiabilities, assetAccounts, liabilityAccounts }
}

export default useSetupAccountBalanceSums
