import { Heading } from '@nextbusiness/infinity-ui'
import React from 'react'
import './SettingsPage.scss'

interface SettingsPageProps {
  className?: string
  title: string
  children?: React.ReactNode
  iconURL?: string
}

const SettingsPage = (props: SettingsPageProps) => (
  <div
    className={'settings-page' + (props.className ? ' ' + props.className : '')}
  >
    <div className='settings-page-sheet'>
      <div className='settings-page-header'>
        {props.iconURL && (
          <div className='icon'>
            <img src={props.iconURL} alt='' />
          </div>
        )}
        <Heading type='h1'>{props.title}</Heading>
      </div>
      <div className='settings-page-content'>{props.children}</div>
    </div>
  </div>
)

export default SettingsPage
