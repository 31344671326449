import { Textarea } from '@nextbusiness/infinity-ui'
import { TemplateInspectorProps } from '../TemplateInspector'
import TemplateInspectorGroup from '../TemplateInspectorGroup'

const TemplateInspectorAdditionalData = (props: TemplateInspectorProps) => {
  const currentAdditionalData = props.template.additionalData ?? ''
  const isTextTooLong = currentAdditionalData.length > 300

  return (
    <TemplateInspectorGroup title='Zusätzlicher Text'>
      <Textarea
        placeholder='Hier bis zu 6 Zeilen weiteren Text einfügen, z.B. Webseite, E-Mail, …'
        value={currentAdditionalData}
        onChange={(additionalData) => props.modifyTemplate({ additionalData })}
        hasError={isTextTooLong}
        helperText={
          isTextTooLong
            ? `Text ist ${currentAdditionalData.length - 300} Zeichen zu lang.`
            : undefined
        }
        fullWidth
      />
    </TemplateInspectorGroup>
  )
}

export default TemplateInspectorAdditionalData
