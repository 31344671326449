import { Button } from '@nextbusiness/infinity-ui'
import LinearGauge from 'components/gauges/LinearGauge'
import Account from 'components/transactions/Account'
import TransactionAmount from 'components/transactions/TransactionAmount'
import { IExpensesOrIncome } from 'model/FinancialHealth'
import {
  CurrentReportStackNavigatorRef,
  ReportPagePaths,
} from 'pages/ReportPage'

interface ExpensesOrIncomeListProps {
  expensesOrIncome: IExpensesOrIncome
  type: 'expense' | 'income'
}

const ExpensesOrIncomeList = (props: ExpensesOrIncomeListProps) => {
  const maxPercentage = props.expensesOrIncome.accountsCurrentYear
    ? props.expensesOrIncome.accountsCurrentYear[0].percentage
    : 1

  return (
    <div className='expenses-or-income-list'>
      {props.expensesOrIncome.accountsCurrentYear?.map((account) => {
        const barPercentage = (1 / (maxPercentage || 1)) * account.percentage
        return (
          <Button
            className='list-item'
            key={account._id}
            variant='quaternary'
            onClick={() =>
              CurrentReportStackNavigatorRef?.open(
                props.type === 'expense'
                  ? ReportPagePaths.FinancialHealthExpenseDetail
                  : ReportPagePaths.FinancialHealthIncomeDetail,
                `${String(account.fiscalYear)}-${String(account.accountNumber)}`
              )
            }
          >
            <Account
              accountNumber={account.accountNumber}
              fiscalYear={account.fiscalYear}
              disableTrunaction
            />
            <span className='percentage-label'>
              {(account.percentage * 100).toFixed(2)}%
            </span>
            <LinearGauge value={barPercentage} colour={account.colour} />
            <TransactionAmount amount={account.balance} />
          </Button>
        )
      })}
    </div>
  )
}

export default ExpensesOrIncomeList
