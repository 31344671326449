import { DateTime, Interval } from 'luxon'
import ICustomerInvoice, { INewCustomerInvoice } from 'model/CustomerInvoice'
import SidebarGroup from '../SidebarGroup'
import QuoteStatusActions from './quote-status/QuoteStatusActions'
import QuoteStatusBox from './quote-status/QuoteStatusBox'

interface DocumentSidebarQuoteStatusPanelProps {
  document: Partial<INewCustomerInvoice>
  existingInvoice?: ICustomerInvoice
  setExistingInvoice: (invoice: ICustomerInvoice) => void
}

const DocumentSidebarQuoteStatusPanel = (
  props: DocumentSidebarQuoteStatusPanelProps
) => {
  if (!props.document.openingDate) return null

  const openingDate = DateTime.fromMillis(props.document.openingDate)
  const today = DateTime.now()

  const daysSinceOpened =
    Interval.fromDateTimes(openingDate, today).count('days') - 1

  return (
    <SidebarGroup title='Status'>
      <QuoteStatusBox
        daysSinceOpened={daysSinceOpened}
        quoteStatus={props.existingInvoice?.quoteStatus}
        signingPerson={props.existingInvoice?.signingPerson}
        signedAt={props.existingInvoice?.signedAt}
        invoicesFromQuote={props.existingInvoice?.invoicesFromQuote}
      />
      {props.existingInvoice ? (
        <QuoteStatusActions
          existingInvoice={props.existingInvoice}
          setExistingInvoice={(invoice: ICustomerInvoice) =>
            props.setExistingInvoice(invoice)
          }
        />
      ) : null}
    </SidebarGroup>
  )
}

export default DocumentSidebarQuoteStatusPanel
