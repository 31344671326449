import { DocumentLocation } from '@nextbusiness/infinity-finance-api'
import { Button } from '@nextbusiness/infinity-ui'
import { IconItem } from '@nextbusiness/infinity-ui-icons'
import DocumentIcon from 'components/document-icon/DocumentIcon'
import Lockup from 'components/text/Lockup'
import IDocument from 'model/Document'
import DateUtilities from 'utility/DateUtilites'
import './DocumentListItem.scss'

interface DocumentListItemProps {
  document: IDocument
  onClick?: () => void
}

const DocumentListItem = (props: DocumentListItemProps) => (
  <Button
    className='document-list-item'
    variant='shell'
    onClick={props.onClick}
  >
    <div className='document-item-inner'>
      <DocumentIcon thumbnailURL={props.document.thumbnail} tiny />
      <div className='document-depiction'>
        <div className='title'>{props.document.title}</div>
        {props.document.note && (
          <Lockup icon='note' className='note' small subtle truncate>
            “{props.document.note}“
          </Lockup>
        )}
      </div>
      <div className='document-status'>
        <DocumentStatus document={props.document} />
      </div>
      <div className='document-date'>
        {props.document.uploadedAt &&
          DateUtilities.humanFormatNumeric(props.document.uploadedAt)}
      </div>
    </div>
  </Button>
)

const DocumentStatus = (props: { document: IDocument }) => {
  const labels: Record<DocumentLocation, { icon: IconItem; label: string }> = {
    inbox: { icon: 'inbox', label: 'Eingang' },
    invoices: { icon: 'bill', label: 'Verbucht als Rechnung' },
    receipts: { icon: 'attach', label: 'Verbucht als Beleg' },
    'custom-folder': { icon: 'folder', label: 'Abgelegt' },
  }
  const placement = labels[props.document.location]
  if (!placement) {
    return null
  }
  return (
    <Lockup icon={placement.icon} small>
      {placement.label}
    </Lockup>
  )
}

export default DocumentListItem
