import { Button } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import './DocumentIcon.scss'

interface DocumentIconProps {
  thumbnailURL?: string
  isFiledAsReceipt?: boolean
  small?: boolean
  tiny?: boolean
  onPreview?: () => void
}

const DocumentIcon = (props: DocumentIconProps) => (
  <div
    className={classNames('document-icon', {
      small: props.small,
      tiny: props.tiny,
    })}
  >
    <div
      className='preview'
      style={{
        backgroundImage: props.thumbnailURL
          ? `url('${encodeURI(props.thumbnailURL)}')`
          : undefined,
      }}
    />
    {props.isFiledAsReceipt && <div className='stamp stamp-filed' />}
    {props.onPreview && (
      <Button
        className='preview-document'
        iconOnly='view'
        variant='tertiary'
        onClick={props.onPreview}
        tooltip='Vorschau'
      />
    )}
  </div>
)

export default DocumentIcon
