import { Button, Flex, useNotificationCenter } from '@nextbusiness/infinity-ui'
import { isBackendError } from 'libs/networking/Errors'
import { observer } from 'mobx-react'
import VendorInvoices from 'networking/VendorInvoices'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { useVendorInvoiceContext } from './VendorInvoiceContext'
import { useVendorInvoiceEditor } from './VendorInvoiceEditorContext'

const VendorInvoiceEditActions = () => {
  const { authenticationStore, vendorInvoiceStore } = useRootStore()
  const { invoice, reloadInvoice } = useVendorInvoiceContext()
  const { invoiceDraft, resetInvoiceDraft, setShouldValidate, isValid } =
    useVendorInvoiceEditor()

  const [isSavingInvoice, setIsSavingInvoice] = useState<boolean>(false)
  const notificationCenter = useNotificationCenter()

  const commitChanges = async () => {
    setShouldValidate(true)
    if (!isValid) return
    try {
      setIsSavingInvoice(true)
      const updatedInvoice = await VendorInvoices.updateVendorInvoice(
        authenticationStore.organisationIdentifier,
        invoice.id,
        {
          positions: invoiceDraft.positions,
          amountMode: invoiceDraft.amountMode,
        }
      )
      reloadInvoice(updatedInvoice)
      vendorInvoiceStore.onInvoicesChanged()
      setShouldValidate(false)
    } catch (error: any) {
      notificationCenter.addNotification({
        variant: 'error',
        children:
          (isBackendError(error) ? error.displayMessage : error.message) ??
          'Die Änderungen konnten nicht gespeichert werden.',
      })
    } finally {
      setIsSavingInvoice(false)
    }
  }

  return (
    <Flex gap='tiny' className='actions'>
      <Button
        variant='tertiary'
        onClick={resetInvoiceDraft}
        disabled={isSavingInvoice}
      >
        Verwerfen
      </Button>
      <Button
        variant='primary'
        onClick={commitChanges}
        isLoading={isSavingInvoice}
      >
        Änderungen speichern
      </Button>
    </Flex>
  )
}

export default observer(VendorInvoiceEditActions)
