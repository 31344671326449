import {
  APIError,
  AccountType,
  Finance,
  INewAccount,
} from '@nextbusiness/infinity-finance-api'
import {
  Dialog,
  Flex,
  InputField,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import {
  ChartGroup,
  chartOfAccounts,
} from 'ledger/accounts/data/ChartOfAccounts'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { generateRandomId } from 'utility/StringUtilities'
import './BatchAccountCreationModal.scss'

interface BatchAccountCreationModalProps {
  isOpen: boolean
  onDismiss: () => void
}

interface BatchAccountItem {
  id: string
  accountNumber: string
  name: string
}

const isBatchAccountItemEmpty = (batchAccountItem: BatchAccountItem) =>
  batchAccountItem.accountNumber === '' && batchAccountItem.name === ''

const removeEmptyBatchAccountItems = (
  items: BatchAccountItem[]
): BatchAccountItem[] => {
  if (items.length === 1) return items
  return items.filter(
    (batchAccountItem) => !isBatchAccountItemEmpty(batchAccountItem)
  )
}

const addEmptyLastBatchAccountItem = (
  items: BatchAccountItem[]
): BatchAccountItem[] => {
  const lastItem = items[items.length - 1]
  if (!lastItem || !isBatchAccountItemEmpty(lastItem)) {
    const emptyItem = { id: generateRandomId(), accountNumber: '', name: '' }
    return [...items, emptyItem]
  }
  return items
}

const isValidAccountNumber = (accountNumber: string) => {
  const convertedNumber = Number(accountNumber)
  return (
    !isNaN(convertedNumber) &&
    convertedNumber >= 1000 &&
    convertedNumber <= 9999
  )
}

const determineDefaultAccountType = (accountNumber: number): AccountType => {
  if (accountNumber >= 1000 && accountNumber <= 1999) return 'assets'
  if (accountNumber >= 2000 && accountNumber <= 2999) return 'liabilities'
  if (accountNumber >= 3000 && accountNumber <= 3999) return 'income'
  return 'expense'
}

const findGroupInRange = (
  accountNumber: number,
  groups: ChartGroup[] = chartOfAccounts
): string[] => {
  for (const group of groups) {
    if (accountNumber >= group.from && accountNumber <= group.to) {
      if (!group.groups) return [group.title]
      return [group.title, ...findGroupInRange(accountNumber, group.groups)]
    }
  }
  return []
}

const BatchAccountCreationModal = (props: BatchAccountCreationModalProps) => {
  const { authenticationStore, accountStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [batchAccountItems, setBatchAccountItems] = React.useState<
    BatchAccountItem[]
  >([{ id: generateRandomId(), accountNumber: '', name: '' }])
  const [creationError, setCreationError] =
    useState<{ id: string; reason: string }[]>()
  const [isCreating, setIsCreating] = useState<boolean>(false)

  const isDuplicateAccount = useCallback(
    (accountNumber: string) => {
      const convertedNumber = Number(accountNumber)
      return accountStore.allAccounts.some(
        (account) => account.accountNumber === convertedNumber
      )
    },
    [accountStore.allAccounts]
  )
  const isAccountValid = useCallback(
    (accountNumber: string, name: string) =>
      isValidAccountNumber(accountNumber) &&
      !isDuplicateAccount(accountNumber) &&
      name.trim() !== '',
    [isDuplicateAccount]
  )

  const editBatchAccountItem = useCallback(
    (id: string, changes: Omit<Partial<BatchAccountItem>, 'id'>) => {
      let newBatchAccountItems = batchAccountItems.map((batchAccountItem) => {
        if (batchAccountItem.id === id)
          return { ...batchAccountItem, ...changes }
        return batchAccountItem
      })
      newBatchAccountItems = addEmptyLastBatchAccountItem(
        removeEmptyBatchAccountItems(newBatchAccountItems)
      )
      setBatchAccountItems(newBatchAccountItems)
    },
    [batchAccountItems]
  )

  const accountsToCreate = useMemo(
    () => batchAccountItems.filter((item) => !isBatchAccountItemEmpty(item)),
    [batchAccountItems]
  )

  useEffect(() => {
    if (props.isOpen) setShouldValidate(false)
  }, [props.isOpen])

  const createAccounts = useCallback(async () => {
    setCreationError(undefined)

    if (accountsToCreate.length === 0) return
    const areAccountsValid = accountsToCreate.every((item) =>
      isAccountValid(item.accountNumber, item.name)
    )
    if (!areAccountsValid) return

    setIsCreating(true)

    const successfulAccounts: string[] = []
    const failedAccounts: { id: string; reason: string }[] = []

    const accountPromises = accountsToCreate.map(async (item) => {
      try {
        await Finance.Ledger.createAccount({
          accountNumber: Number(item.accountNumber),
          fiscalYear: accountStore.currentFiscalYear?.year ?? 0,
          name: item.name.trim(),
          accountGroups: findGroupInRange(Number(item.accountNumber)),
          accountType: determineDefaultAccountType(Number(item.accountNumber)),
          organisation: authenticationStore.organisationIdentifier,
        } as INewAccount)
        successfulAccounts.push(item.id)
      } catch (error) {
        failedAccounts.push({
          id: item.id,
          reason: (error as APIError).humanText('de'),
        })
      }
    })
    await Promise.all(accountPromises)

    if (successfulAccounts.length === accountsToCreate.length) {
      await accountStore.loadAccounts()
      notificationCenter.addNotification({
        variant: 'success',
        children: 'Konten wurden erfolgreich erstellt.',
      })
      MixpanelAnalytics.event('Batch accounts created', {
        count: successfulAccounts.length,
      })
      setBatchAccountItems([
        { id: generateRandomId(), accountNumber: '', name: '' },
      ])
      props.onDismiss()
    } else {
      notificationCenter.addNotification({
        variant: 'error',
        children: 'Einige Konten konnten nicht erstellt werden.',
      })
      setCreationError(failedAccounts)
      setBatchAccountItems((items) =>
        items.filter((item) => failedAccounts.some((a) => a.id === item.id))
      )
    }
    setIsCreating(false)
  }, [accountsToCreate])

  return (
    <Dialog
      className='batch-account-creation-modal'
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      title='Konten mit Nummern eintragen'
      titleProps={{ divider: true }}
      actions={[
        { children: 'Abbrechen', onClick: props.onDismiss },
        {
          children: 'Konten erstellen',
          variant: 'primary',
          onClick: () => {
            setShouldValidate(true)
            createAccounts()
          },
          isLoading: isCreating,
          disabled: accountsToCreate.length === 0,
        },
      ]}
    >
      <Text type='inline'>
        Hier kannst du mehrere Konten nach ihren Nummern erstellen.
      </Text>
      <div className='batch-account-list'>
        {batchAccountItems.map((item) => {
          const isValid = isValidAccountNumber(item.accountNumber)
          const isDuplicate = isDuplicateAccount(item.accountNumber)
          const hasError = shouldValidate && (!isValid || isDuplicate)
          const isEmpty = isBatchAccountItemEmpty(item)

          const shouldValidateItem = shouldValidate && !isEmpty
          const errorText =
            shouldValidateItem && !isValid
              ? 'Ungültig'
              : shouldValidateItem && isDuplicate
              ? 'Vergeben'
              : undefined

          const isTextMissing = shouldValidateItem && item.name.trim() === ''
          const creationErrorText = creationError?.find(
            (error) => error.id === item.id
          )?.reason

          return (
            <Flex key={item.id} gap='tiny' className='batch-account-item'>
              <div className='column column-number'>
                <InputField
                  value={item.accountNumber}
                  type='number'
                  placeholder='Nummer'
                  onChange={(text) =>
                    editBatchAccountItem(item.id, {
                      accountNumber: text,
                    })
                  }
                  bare
                  fullWidth
                  hasError={shouldValidateItem && hasError}
                  helperText={errorText}
                />
              </div>
              <div className='column column-name'>
                <InputField
                  value={item.name}
                  placeholder='Kontoname'
                  onChange={(text) => {
                    editBatchAccountItem(item.id, {
                      name: text,
                    })
                  }}
                  bare
                  fullWidth
                  hasError={
                    (shouldValidateItem && isTextMissing) || !!creationErrorText
                  }
                  helperText={
                    shouldValidateItem && isTextMissing
                      ? 'Name erforderlich'
                      : creationErrorText
                  }
                />
              </div>
            </Flex>
          )
        })}
      </div>
    </Dialog>
  )
}

export default observer(BatchAccountCreationModal)
