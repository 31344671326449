import { Button } from '@nextbusiness/infinity-ui'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import CustomerInvoiceDeleteConfirmationDialog from '../../CustomerInvoiceDeleteConfirmationDialog'
import { CustomerInvoiceEditorSidebarTopActionsProps } from './CustomerInvoiceEditorSidebarTopActions'

const EditorDeleteAction = (
  props: CustomerInvoiceEditorSidebarTopActionsProps
) => {
  const history = useHistory()

  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
    useState<boolean>(false)
  const canDeleteExistingInvoice = Boolean(
    props.existingInvoice?.id && props.isEditable
  )
  if (!props.existingInvoice) return null
  return (
    <>
      {canDeleteExistingInvoice && (
        <Button
          onClick={() => setShowConfirmDeleteDialog(true)}
          iconOnly='delete'
          aria-label='Löschen'
          tooltip='Löschen'
        />
      )}
      <CustomerInvoiceDeleteConfirmationDialog
        invoice={props.existingInvoice}
        isOpen={showConfirmDeleteDialog}
        onDismiss={() => setShowConfirmDeleteDialog(false)}
        onInvoiceDeleted={() => history.push('/forderungen')}
      />
    </>
  )
}

export default EditorDeleteAction
