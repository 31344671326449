import {
  Finance,
  TransactionCompletion,
} from '@nextbusiness/infinity-finance-api'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { useEffect, useState } from 'react'
import { useDebounce } from 'utility/hooks'
import { useTransactionCreatorContext } from '../TransactionCreatorContext'

const useTypeaheadCompletion = () => {
  const { transaction, updateTransaction, incrementNumberKey, utilities } =
    useTransactionCreatorContext()

  const [completion, setCompletion] = useState<TransactionCompletion | null>()
  const debouncedDescription = useDebounce(transaction.description, 200)

  const completionText =
    !!transaction.description &&
    completion?.description.substring(transaction.description?.length ?? 0)

  useEffect(() => {
    const getSuggestion = async (query: string) => {
      try {
        const completion = await Finance.Ledger.suggestTypeahead(
          query,
          utilities.currentAccount.accountNumber,
          utilities.currentAccountSide
        )
        setCompletion(completion)
      } catch {
        setCompletion(null)
      }
    }
    if (debouncedDescription) {
      getSuggestion(debouncedDescription)
    }
  }, [debouncedDescription])

  const onSuggestedCompletionAccepted = () => {
    updateTransaction({
      description: completion?.description,
      [utilities.contraAccountSide]: completion?.contraAccount,
      amount: completion?.amount,
    })
    incrementNumberKey()
    setCompletion(null)
    MixpanelAnalytics.event('Typeahead suggestion accepted')
  }

  return {
    completionText,
    onSuggestedCompletionAccepted,
  }
}

export default useTypeaheadCompletion
