import { NavigationTab } from '@nextbusiness/infinity-ui/dist/layout/header/HeaderNavigationTabs'

export const primaryNavigationTabs = (
  showDocumentBadge?: boolean
): NavigationTab[] => {
  return [
    { description: 'Auswerten', path: '/auswerten' },
    { description: 'Buchen', path: '/buchen' },
    { description: 'Einkauf', path: '/rechnungen' },
    {
      description: 'Verkauf',
      path: '/forderungen',
      subItems: [
        {
          description: 'Forderungen',
          icon: 'purchase-order',
          path: '/forderungen',
        },
        {
          description: 'Offerten',
          icon: 'estimates',
          path: '/offerten',
        },
        {
          description: 'Projekte',
          icon: 'outline',
          path: '/projekte',
        },
      ],
    },
    { description: 'Kontakte', path: '/kontakte' },
    { description: 'Dokumente', path: '/dokumente', badge: showDocumentBadge },
  ]
}
