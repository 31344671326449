import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import { useMixpanelEvent } from 'integrations/useMixpanelEvent'
import './WelcomeCentre.scss'
import WelcomeCentreAdditionalResources from './WelcomeCentreAdditionalResources'
import WelcomeCentreHelpMenu from './WelcomeCentreHelpMenu'
import WelcomeCentreSuggestions from './WelcomeCentreSuggestions'

const WelcomeCentre = () => {
  useMixpanelEvent('Welcome centre viewed')
  return (
    <MagicSheetPage
      className='welcome-centre'
      header={
        <MagicSheetTitleBar title='Willkommen'>
          <WelcomeCentreHelpMenu />
        </MagicSheetTitleBar>
      }
    >
      <MagicSheetContainer>
        <WelcomeCentreSuggestions />
        <WelcomeCentreAdditionalResources />
      </MagicSheetContainer>
    </MagicSheetPage>
  )
}

export default WelcomeCentre
