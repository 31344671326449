import { ITemplate } from '@nextbusiness/infinity-finance-api'
import { Flex } from '@nextbusiness/infinity-ui'
import ICustomerInvoice from 'model/CustomerInvoice'
import PublicInvoiceSidebarActions from './PublicInvoiceSidebarActions'
import PublicInvoiceSidebarInfo from './PublicInvoiceSidebarInfo'
import PublicQuoteSidebarActions from './PublicQuoteSidebarActions'
import PublicQuoteSidebarInfo from './PublicQuoteSidebarInfo'

export interface PublicInvoiceSidebarProps {
  shareId: string
  invoice: ICustomerInvoice
  invoiceNumber: string
  template: ITemplate
}

const PublicInvoiceSidebar = (props: PublicInvoiceSidebarProps) => {
  const onDownload = () => {
    if (!props.invoice.publicPdfUrl) return
    const link = document.createElement('a')

    link.href = props.invoice.publicPdfUrl
    link.download = props.invoice.title
    link.target = '_blank'
    link.setAttribute('download', props.invoice.publicPdfUrl)

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  if (props.invoice.isQuote) {
    return (
      <Flex className='invoice-sidebar' direction='vertical' gap={5.4}>
        <PublicQuoteSidebarActions
          shareId={props.shareId}
          quote={props.invoice}
          template={props.template}
          onDownload={onDownload}
        />
        <PublicQuoteSidebarInfo {...props} />
      </Flex>
    )
  }

  return (
    <Flex className='invoice-sidebar' direction='vertical' gap={5.4}>
      <PublicInvoiceSidebarActions
        onDownload={onDownload}
        isQrInvoice={props.invoice.isQrInvoice}
      />
      <PublicInvoiceSidebarInfo {...props} />
    </Flex>
  )
}

export default PublicInvoiceSidebar
