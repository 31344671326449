import DocumentElementProps from './DocumentElementProps'

interface DocumentElementLogoProps extends DocumentElementProps {
  alwaysVisible?: boolean
}

const DocumentElementLogo = (props: DocumentElementLogoProps) => (
  <div
    className='document-page-logo'
    style={{
      visibility:
        props.template.disableLogo && !props.alwaysVisible
          ? 'hidden'
          : 'visible',
    }}
  >
    {props.template.logo ? (
      <img
        src={props.template.logo}
        alt='Logo'
        style={{
          height: 16 * 2.835 * (props.template.logoSize ?? 1),
          display: 'none',
        }}
        onLoad={(event) => {
          ;(event.target as HTMLImageElement).style.display = 'inline-block'
        }}
        onError={(event) => {
          ;(event.target as HTMLImageElement).style.display = 'none'
        }}
      />
    ) : null}
  </div>
)

export default DocumentElementLogo
