import { TransactionType } from '@nextbusiness/infinity-finance-api'
import Lockup from 'components/text/Lockup'
import { useEffect, useState } from 'react'
import sharedBankLookup, { Bank } from './BankLookup'
import TransactionLiveAcountingDetailProps from './TransactionLiveAcountingDetailProps'

const LiveAccountingBank = (props: TransactionLiveAcountingDetailProps) => {
  const counterparty = props.details.counterparty

  const [bank, setBank] = useState<Bank | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const bankIdentifier =
    counterparty?.agent?.bic ??
    counterparty?.agent?.clearingSystemMemberIdentification?.memberId

  const findBank = async () => {
    const agent = counterparty?.agent
    if (agent?.bic) {
      return sharedBankLookup.getBankByBic(agent.bic)
    } else if (agent?.clearingSystemMemberIdentification?.code === 'CHBCC') {
      return sharedBankLookup.getBankByIid(
        agent.clearingSystemMemberIdentification.memberId
      )
    }
    return null
  }

  useEffect(() => {
    const loadBank = async () => {
      setIsLoading(true)
      try {
        const bank = await findBank()
        setBank(bank)
      } catch {
        setBank(null)
      } finally {
        setIsLoading(false)
      }
    }
    if (counterparty?.agent) loadBank()
  }, [counterparty])

  if (!bank && !bankIdentifier) return null

  const institutionLabel =
    props.details.transactionType === TransactionType.DEBIT
      ? 'Zahlungsinstitut des Zahlungsempfängers'
      : 'Zahlungsinstitut des Auftraggebers'
  return (
    <Lockup icon='library' tooltip={institutionLabel} small subtle truncate>
      {bank?.bankOrInstitutionName ?? (isLoading ? '...' : bankIdentifier)}
    </Lockup>
  )
}

export default LiveAccountingBank
