import { Dialog, Flex, Spacer, Text } from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import ProductsList from './ProductsList'

interface ManageProductsModalProps {
  isOpen: boolean
  onDismiss: () => void
}

const ManageProductsModal = (props: ManageProductsModalProps) => (
  <Dialog
    title='Produkte verwalten'
    titleProps={{ divider: true }}
    dismissable
    style={{ maxWidth: '64rem' }}
    {...props}
  >
    <Flex verticalAlignment='center' gap='small'>
      <Icon icon='package-delivery-logistics' size={IconSize.Small} />
      <Text type='inline'>
        Lege beliebige Produkte an, welche du dann innerhalb von Forderungen und
        Offerten einsetzen kannst.
      </Text>
    </Flex>
    <Spacer size='tiny' />
    <ProductsList />
  </Dialog>
)

export default ManageProductsModal
