import { APIError, Finance } from '@nextbusiness/infinity-finance-api'
import {
  Dialog,
  Heading,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import LiveCaptureSetupGraphic from '../../assets/hero-imagery/live-capture-modal-hero.png'
import './LiveCaptureSetupDialog.scss'

interface LiveCaptureSetupDialogProps {
  isOpen: boolean
  onDismiss: () => void
}

const LiveCaptureSetupDialog = (props: LiveCaptureSetupDialogProps) => {
  const { preferencesStore } = useRootStore()
  const [isActivating, setIsActivating] = useState<boolean>(false)
  const notificationCenter = useNotificationCenter()

  const activateLiveCapture = async () => {
    setIsActivating(true)
    try {
      const updatedPreferences = await Finance.Organisation.setPreferences({
        isLiveCaptureEnabled: true,
      })
      preferencesStore.setPreferences(updatedPreferences)
      notificationCenter.addNotification({
        variant: 'success',
        title: 'Live Capture aktiviert',
        children:
          'Neue Belege, die du hochlädst, werden nun mit AI analysiert.',
      })
      props.onDismiss()
    } catch (error) {
      notificationCenter.addNotification({
        variant: 'error',
        title: 'Live Capture konnte nicht aktiviert werden.',
        children: (error as APIError).humanText('de'),
      })
    } finally {
      setIsActivating(false)
    }
  }

  return (
    <Dialog
      dismissable
      className='live-capture-setup-dialog'
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      actions={[
        {
          children: 'Abbrechen',
          onClick: () => props.onDismiss(),
        },
        {
          children: 'Live Capture aktivieren',
          variant: 'primary',
          onClick: activateLiveCapture,
          isLoading: isActivating,
        },
      ]}
      style={{ width: '54rem' }}
    >
      <img src={LiveCaptureSetupGraphic} alt='' className='hero-image' />
      <div className='setup-content'>
        <Heading type='h3'>Nie mehr Belege manuell abtöggeln</Heading>
        <Text>
          Mit Live Capture kannst du automatisch hochgeladene Belege mit AI ohne
          Abtippen ausfüllen.
        </Text>
        <Text type='paragraph' className='legal-note'>
          Live Capture nutzt künstliche Intelligenz. Deine Daten sind immer
          sicher.{' '}
          <a
            href='https://www.infinity.swiss/policies/datenverarbeitung-mit-live-accounting'
            target='_blank'
            rel='noopener noreferrer'
            className='link teal'
          >
            Mehr erfahren
          </a>
        </Text>
      </div>
    </Dialog>
  )
}

export default observer(LiveCaptureSetupDialog)
