import { FeedbackBanner } from '@nextbusiness/infinity-ui'
import { useMixpanelEvent } from 'integrations/useMixpanelEvent'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import SettingsFieldList from 'settings/general/SettingsFieldList'
import SettingsGroup from 'settings/general/SettingsGroup'
import SettingsPage from 'settings/general/SettingsPage'
import { useRootStore } from 'stores/RootStoreContext'
import PresetIcon from '../../assets/settings-icons/invoice-preset-icon.svg'
import PresetSettingsCard from './PresetSettingsCard'

const PresetSettingsPage = () => {
  const { presetStore } = useRootStore()

  useEffect(() => {
    presetStore.loadPresets()
  }, [])

  useMixpanelEvent('Settings viewed - Presets')

  return (
    <SettingsPage
      title='Vorlagen'
      iconURL={PresetIcon}
      className='preset-settings-page'
    >
      <SettingsGroup
        title='Eigene Vorlage für Forderungen'
        description='Erstelle eigene Vorlagen, die du für neue Offerten und Forderungen jederzeit wiederverwenden kannst.'
      >
        <SettingsFieldList gap={0.2}>
          {presetStore.customPresets.map((preview) => (
            <PresetSettingsCard
              key={preview.id}
              presetId={preview.id}
              title={preview.title}
            />
          ))}
        </SettingsFieldList>
      </SettingsGroup>
      <FeedbackBanner variant='info' title='Wie erstelle ich eine Vorlage?'>
        Wenn du bei einer Offerte/Forderung die Dokumentoption "Als Vorlage
        speichern" anwählst, kannst du eine Vorlage mit dem entsprechenden
        Inhalt erstellen. Die eigentliche Offerte/Forderung wird dabei nicht
        verändert.
      </FeedbackBanner>
      <FeedbackBanner
        variant='info'
        title='Wie bearbeite ich den Inhalt einer Vorlage?'
      >
        Erstelle zuerst ein Dokument mit der Vorlage, die du bearbeiten
        möchtest. Nachdem du den Inhalt angepasst hast, kannst du aus diesem
        Dokument wiederum eine Vorlage erstellen. Die alte Vorlage kannst du
        danach löschen.
      </FeedbackBanner>
    </SettingsPage>
  )
}

export default observer(PresetSettingsPage)
