import { IProjectPreview } from '@nextbusiness/infinity-finance-api'
import { FlyoutProps } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import ResourceOption from 'components/resource-select/ResourceOption'
import ResourceSelect from 'components/resource-select/ResourceSelect'
import ContactSwatch from 'invoices/shared-components/ContactSwatch'
import { observer } from 'mobx-react'
import CreateProjectModal from 'projects/project-view/edit-or-create-project/CreateProjectModal'
import { useMemo, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import './ProjectSelect.scss'

interface ProjectSelectProps {
  className?: string
  selectedProjectId: string | undefined
  setSelectedProjectId: (projectId: string | undefined) => void
  projects: IProjectPreview[]
  /** Any props that should be passed to the flyout in the resource select.
   *  The following props are managed internally and cannot be overridden:
   * - `trigger`
   * - `isActive`
   * - `setIsActive`
   * - `className` (use the `className` prop of the resource select instead)
   */
  flyoutProps?: Partial<FlyoutProps>
}

const ProjectSelect = (props: ProjectSelectProps) => {
  const { contactStore, projectStore } = useRootStore()
  const [isProjectCreationModalOpen, setIsProjectCreationModalOpen] =
    useState<boolean>(false)
  const projectsGroupedByStage = projectStore.groupProjectsByStage(
    props.projects
  )
  // Update to use the current stage order saved in the organisation
  // once we support custom stages.
  const stageOrder = ['In Arbeit', 'Als nächstes', 'Nicht begonnen', 'Fertig']
  const sections = useMemo(
    () =>
      stageOrder.compactMap((title) => {
        if (!projectsGroupedByStage[title]) return null
        const projects = projectsGroupedByStage[title]
        return {
          title,
          isSuggestedSection: title === 'In Arbeit',
          options: projects.map((project) => ({
            value: project.id,
            item: project,
          })),
        }
      }),
    [props.projects]
  )

  return (
    <>
      <ResourceSelect
        className={classNames('project-select', props.className)}
        placeholderText='Projekt wählen'
        value={props.selectedProjectId}
        onChange={props.setSelectedProjectId}
        resetOnMismatchingQuery
        sections={sections}
        optionForItem={(project, props) => (
          <ResourceOption
            {...props}
            customTitle={project.title}
            description={contactStore.displayNameForContactWithId(
              project.client
            )}
            leftAccessory={<ContactSwatch contactId={project.client} small />}
          />
        )}
        displayTextForCurrentItem={(project: IProjectPreview) =>
          project.title ? project.title : 'hello'
        }
        leftAccessory={(project) => {
          if (project?.client)
            return <ContactSwatch contactId={project.client} small />
        }}
        searchableTextForItem={(project: IProjectPreview) => {
          return `${project.title} ${contactStore.displayNameForContactWithId(
            project.client
          )}`
        }}
        customEnteredTextMatcher={(item, query) =>
          item.title.toLowerCase() === query.toLowerCase()
        }
        actions={[
          {
            children: 'Projekt erstellen',
            iconLeft: 'plus',
            onClick: () => setIsProjectCreationModalOpen(true),
          },
        ]}
        flyoutProps={props.flyoutProps}
      />
      <CreateProjectModal
        isOpen={isProjectCreationModalOpen}
        onDismiss={() => setIsProjectCreationModalOpen(false)}
        onCreateProject={async (project) => {
          await projectStore.fetchAllProjects()
          props.setSelectedProjectId(project.id)
        }}
      />
    </>
  )
}

export default observer(ProjectSelect)
