import {
  INewTransaction,
  ITransaction,
  ITransactionChanges,
  TransactionOrigin,
} from '@nextbusiness/infinity-finance-api'
import { IAccount } from 'model/Account'
import AccountStore from 'stores/AccountStore'
import VATPeriodStore from 'stores/VATPeriodStore'
import AccountUtilities from 'utility/AccountUtilities'
import TransactionUtilities from 'utility/TransactionUtilities'
import TransactionNoEditReason from './TransactionNoEditReason'

class TransactionEditorUtilities {
  private transaction: ITransaction
  private draftTransaction: INewTransaction
  private currentAccount: IAccount

  private accountStore: AccountStore
  private vatPeriodStore: VATPeriodStore

  constructor(
    transaction: ITransaction,
    draftTransaction: INewTransaction,
    currentAccount: IAccount,
    accountStore: AccountStore,
    vatPeriodStore: VATPeriodStore
  ) {
    this.transaction = transaction
    this.draftTransaction = draftTransaction
    this.currentAccount = currentAccount
    this.accountStore = accountStore
    this.vatPeriodStore = vatPeriodStore
  }

  public get isCurrentAccountDebit() {
    return this.transaction.debitAccount === this.currentAccount.accountNumber
  }

  public get contraAccountSide() {
    return this.isCurrentAccountDebit ? 'creditAccount' : 'debitAccount'
  }

  public get shouldDisplayNegativeAmount() {
    return AccountUtilities.shouldDisplayNegativeAmount(
      this.currentAccount,
      this.transaction
    )
  }

  public get accountsShouldSwitch() {
    return (
      (this.shouldDisplayNegativeAmount && this.draftTransaction.amount > 0) ||
      (!this.shouldDisplayNegativeAmount && this.draftTransaction.amount < 0)
    )
  }

  public get isDraftIncomplete() {
    return (
      !this.draftTransaction.date ||
      !this.draftTransaction.description ||
      !this.draftTransaction.creditAccount ||
      !this.draftTransaction.debitAccount ||
      !this.draftTransaction.amount ||
      !!(
        this.draftTransaction.originalCurrency &&
        !this.draftTransaction.exchangeRate
      )
    )
  }

  public get hasEditedTransaction() {
    const hasEditedDate = this.draftTransaction.date !== this.transaction.date
    const hasEditedDescription =
      this.draftTransaction.description !== this.transaction.description

    const hasEditedDebitAccount =
      this.draftTransaction.debitAccount !== this.transaction.debitAccount
    const hasEditedCreditAccount =
      this.draftTransaction.creditAccount !== this.transaction.creditAccount
    const hasEditedContraAccount = this.isCurrentAccountDebit
      ? hasEditedCreditAccount
      : hasEditedDebitAccount

    const hasEditedCurrency =
      this.draftTransaction.originalCurrency !==
      this.transaction.originalCurrency

    const hasEditedExchangeRate =
      this.draftTransaction.exchangeRate !== this.transaction.exchangeRate

    const hasEditedAmount =
      Math.abs(this.draftTransaction.amount) !==
      Math.abs(
        TransactionEditorUtilities.defaultEditorAmount(
          this.transaction,
          this.currentAccount
        )
      )

    const hasEditedTaxCode =
      this.draftTransaction.taxCode !== this.transaction.taxCode

    return (
      hasEditedDate ||
      hasEditedDescription ||
      hasEditedContraAccount ||
      hasEditedAmount ||
      hasEditedTaxCode ||
      hasEditedCurrency ||
      hasEditedExchangeRate ||
      this.accountsShouldSwitch
    )
  }

  public get changesToSave() {
    const changes: ITransactionChanges = {}
    if (this.accountsShouldSwitch) {
      const debitAccount = this.draftTransaction.debitAccount
      const creditAccount = this.draftTransaction.creditAccount

      changes['debitAccount'] = creditAccount
      changes['creditAccount'] = debitAccount
    } else {
      if (
        this.transaction.creditAccount !== this.draftTransaction.creditAccount
      )
        changes['creditAccount'] = this.draftTransaction.creditAccount
      if (this.transaction.debitAccount !== this.draftTransaction.debitAccount)
        changes['debitAccount'] = this.draftTransaction.debitAccount
    }
    if (this.transaction.date !== this.draftTransaction.date)
      changes['date'] = this.draftTransaction.date
    if (this.transaction.description !== this.draftTransaction.description)
      changes['description'] = this.draftTransaction.description
    if (
      this.transaction.originalAmount !== Math.abs(this.draftTransaction.amount)
    ) {
      changes['amount'] = Math.abs(this.draftTransaction.amount)
    }
    if (this.transaction.taxCode !== this.draftTransaction.taxCode)
      changes['taxCode'] = this.draftTransaction.taxCode
    if (
      this.transaction.originalCurrency !==
      this.draftTransaction.originalCurrency
    ) {
      changes['originalCurrency'] = this.draftTransaction.originalCurrency
    }
    if (
      this.transaction.originalCurrency &&
      this.draftTransaction.originalCurrency === undefined
    ) {
      changes['originalCurrency'] = 'CHF'
    }
    if (this.transaction.exchangeRate !== this.draftTransaction.exchangeRate) {
      changes['exchangeRate'] = this.draftTransaction.exchangeRate
    }
    return changes
  }

  public get isTransactionEditingRestricted() {
    return this.noEditReasonForTransaction !== null
  }

  public get isDescriptionEditingRestricted() {
    return this.changingDescriptionIsRestricted != null
  }

  public get isTransactionDeletionRestricted() {
    const noEditReason = this.noEditReasonForTransaction
    const isInvoicePayment =
      noEditReason ===
        TransactionNoEditReason.VendorInvoicePaymentTransaction ||
      noEditReason === TransactionNoEditReason.CustomerInvoicePaymentTransaction

    return this.isTransactionEditingRestricted && !isInvoicePayment
  }

  public get noEditReasonForTransaction(): TransactionNoEditReason | null {
    return TransactionEditorUtilities.noEditReasonForTransaction(
      this.transaction,
      this.accountStore,
      this.vatPeriodStore
    )
  }

  public get changingDescriptionIsRestricted(): TransactionNoEditReason | null {
    return TransactionEditorUtilities.isDescriptionEditingRestricted(
      this.transaction,
      this.accountStore,
      this.vatPeriodStore
    )
  }

  public static isTransactionEditingRestricted(
    transaction: ITransaction,
    accountStore: AccountStore,
    vatPeriodStore: VATPeriodStore
  ) {
    return (
      TransactionEditorUtilities.noEditReasonForTransaction(
        transaction,
        accountStore,
        vatPeriodStore
      ) !== null
    )
  }

  public static isDescriptionEditingRestricted(
    transaction: ITransaction,
    accountStore: AccountStore,
    vatPeriodStore: VATPeriodStore
  ): TransactionNoEditReason | null {
    if (accountStore.fiscalYear(transaction.fiscalYear)?.isClosed)
      return TransactionNoEditReason.FiscalYearClosed
    if (vatPeriodStore.periodForDate(transaction.date)?.wasSettled)
      return TransactionNoEditReason.VatPeriodSettled
    return null
  }

  public static noEditReasonForTransaction(
    transaction: ITransaction,
    accountStore: AccountStore,
    vatPeriodStore: VATPeriodStore
  ): TransactionNoEditReason | null {
    if (accountStore.fiscalYear(transaction.fiscalYear)?.isClosed)
      return TransactionNoEditReason.FiscalYearClosed
    if (vatPeriodStore.periodForDate(transaction.date)?.wasSettled)
      return TransactionNoEditReason.VatPeriodSettled
    switch (transaction.origin) {
      case TransactionOrigin.FinancialOpening:
        return TransactionNoEditReason.OpeningTransaction
      case TransactionOrigin.CustomerInvoicePayment:
      case TransactionOrigin.CustomerInvoiceRebate:
        return TransactionNoEditReason.CustomerInvoicePaymentTransaction
      case TransactionOrigin.VendorInvoicePayment:
      case TransactionOrigin.VendorInvoiceDiscount:
        return TransactionNoEditReason.VendorInvoicePaymentTransaction
      case TransactionOrigin.VatSettlement:
        return TransactionNoEditReason.VatSettlementTransaction
      case TransactionOrigin.VAT:
        return TransactionNoEditReason.VatTransaction
      case TransactionOrigin.Annulment:
        return TransactionNoEditReason.AnnulledTransaction
      default:
        return null
    }
  }

  public static requiresTallEditor(transaction: ITransaction) {
    return this.shouldDisplayNetAmount(transaction)
  }

  public static shouldDisplayNetAmount(transaction: ITransaction) {
    return !!transaction.taxCode && transaction.origin !== TransactionOrigin.VAT
  }

  public static defaultEditorAmount(
    transaction: ITransaction,
    currentAccount: IAccount
  ) {
    const absoluteAmount = TransactionEditorUtilities.shouldDisplayNetAmount(
      transaction
    )
      ? TransactionUtilities.grossAmountForNetTransaction(transaction)
      : transaction.originalAmount ?? transaction.amount

    return AccountUtilities.shouldDisplayNegativeAmount(
      currentAccount,
      transaction
    )
      ? -absoluteAmount
      : absoluteAmount
  }
}

export default TransactionEditorUtilities
