import DocumentIcon from 'components/document-icon/DocumentIcon'

interface LiveCaptureAttachmentThumbnailProps {
  thumbnailURL: string | null
}

const LiveCaptureAttachmentThumbnail = (
  props: LiveCaptureAttachmentThumbnailProps
) => (
  <div className='live-capture-attachment-thumbnail'>
    {!!props.thumbnailURL && <DocumentIcon thumbnailURL={props.thumbnailURL} />}
  </div>
)

export default LiveCaptureAttachmentThumbnail
