import {
  APIError,
  ErrorCode,
  ICompleteDocument,
} from '@nextbusiness/infinity-finance-api'
import { MenuItem } from '@nextbusiness/infinity-ui'

const menuItemForAttachment = (
  attachment: ICompleteDocument,
  onAttachmentClicked: (attachment: ICompleteDocument) => void
): MenuItem => ({
  onClick: () => onAttachmentClicked(attachment),
  text: attachment.document.title,
  icon: 'document',
})

const menuItemForError = (
  error: APIError,
  count: number,
  onErrorClicked: (error: APIError) => void
): MenuItem => {
  const errorText = error.humanText('de')
  const formattedCount = count > 1 ? `(${count} Belege)` : ''
  return {
    text: `${errorText} ${formattedCount}`,
    icon: 'error',
    onClick: () => onErrorClicked(error),
  }
}

export const menuItemsFromAttachments = (
  items: (ICompleteDocument | APIError)[],
  onAttachmentClicked: (attachment: ICompleteDocument) => void,
  onErrorClicked: (error: APIError) => void
) => {
  const attachments: MenuItem[] = []
  const errors: MenuItem[] = []

  const errorOccurrences: Partial<Record<ErrorCode, number>> = {}

  const countErrorCode = (code: ErrorCode) => {
    if (!errorOccurrences.hasOwnProperty(code)) errorOccurrences[code] = 0
    errorOccurrences[code]!++
  }
  const addAttachmentMenuItem = (attachment: ICompleteDocument) =>
    attachments.push(menuItemForAttachment(attachment, onAttachmentClicked))
  const addErrorMenuItem = (error: APIError, count: number) =>
    errors.push(menuItemForError(error, count, onErrorClicked))

  items.forEach((item) => {
    if (item instanceof APIError) {
      countErrorCode(item.code)
    } else {
      addAttachmentMenuItem(item)
    }
  })

  Object.entries(errorOccurrences).forEach(([code, count]) => {
    const error = new APIError(code as ErrorCode)
    addErrorMenuItem(error, count)
  })

  return { attachments, errors }
}
