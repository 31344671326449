import { AnimatePresence } from 'framer-motion'
import { useAccountPageContext } from 'ledger/accounts/AccountPageContext'
import { isDefinedAccount } from 'ledger/accounts/data/AccountRules'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import SyncingBankingTransactionsBanner from './SyncingBankingTransactionsBanner'
import UnreviewedBankingTransactions from './UnreviewedBankingTransactions'

const TransactionLiveAccountingSection = () => {
  const { accountStore, transactionStore } = useRootStore()
  const { currentAccount } = useAccountPageContext()

  if (!isDefinedAccount(currentAccount)) return null

  const isSyncingAccount = accountStore.currentlySyncingAccountIds.has(
    currentAccount.id
  )

  const allTransactions =
    transactionStore.transactions[currentAccount.accountNumber]
  const unreviewedTransactions = allTransactions?.filter(
    (transaction) => transaction.reviewed === false
  )

  return (
    <AnimatePresence>
      {isSyncingAccount ? (
        <SyncingBankingTransactionsBanner />
      ) : unreviewedTransactions?.length ? (
        <UnreviewedBankingTransactions
          unreviewedTransactions={unreviewedTransactions}
        />
      ) : null}
    </AnimatePresence>
  )
}

export default observer(TransactionLiveAccountingSection)
