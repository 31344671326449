import { Flex } from '@nextbusiness/infinity-ui'
import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import { useMixpanelEvent } from 'integrations/useMixpanelEvent'
import LedgerOnboardingScreen from './LedgerOnboardingScreen'

export interface OnboardingSheetProps {
  openLedger: () => void
}

const OnboardingSheet = (props: OnboardingSheetProps) => {
  useMixpanelEvent('Onboarding screen viewed')

  return (
    <MagicSheetPage
      className='ledger-onboarding-sheet'
      header={<MagicSheetTitleBar title='Buchen' />}
      noTrailingNegativeSpace
    >
      <MagicSheetContainer>
        <Flex verticalAlignment='center' horizontalAlignment='center'>
          <LedgerOnboardingScreen openLedger={props.openLedger} />
        </Flex>
      </MagicSheetContainer>
    </MagicSheetPage>
  )
}

export default OnboardingSheet
