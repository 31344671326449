import { DatePicker } from '@nextbusiness/infinity-ui'
import DateUtilities from 'utility/DateUtilites'
import { useTransactionEditorContext } from '../TransactionEditorContext'

const TransactionEditorDate = () => {
  const { draftTransaction, updateDraftTransaction, canMakeChanges } =
    useTransactionEditorContext()

  const currentDate = draftTransaction.date
    ? new Date(draftTransaction.date)
    : undefined
  const onDateChanged = (date?: Date) =>
    updateDraftTransaction({
      date: date ? DateUtilities.startOfDay(date).getTime() : undefined,
    })

  return (
    <DatePicker
      placeholder='Datum'
      value={currentDate}
      onChange={onDateChanged}
      inputFieldProps={{ disabled: !canMakeChanges }}
    />
  )
}

export default TransactionEditorDate
