import { Flex, Tooltip } from '@nextbusiness/infinity-ui'
import { useQuery } from '@tanstack/react-query'
import { BackendError } from 'libs/networking/Errors'
import { observer } from 'mobx-react'
import { IFinancialSolvency } from 'model/FinancialHealth'
import FinancialHealth from 'networking/FinancialHealth'
import {
  CurrentReportStackNavigatorRef,
  ReportPagePaths,
} from 'pages/ReportPage'
import { useEffect } from 'react'
import FinancialHealthCard from 'reports/financial-health/components/FinancialHealthCard'
import FinancialHealthPercentageRow from 'reports/financial-health/components/FinancialHealthPercentageRow'
import LoaderFinancialHealthLiquidity from 'reports/financial-health/loaders/LoaderFinancialHealthLiquidity'
import { useRootStore } from 'stores/RootStoreContext'
import Utilities from 'utility/Utilities'
import './FinancialHealthSolvencyCard.scss'

const FinancialHealthSolvencyCard = () => {
  const { authenticationStore, reportingStore } = useRootStore()

  const { data, isPending, error } = useQuery<IFinancialSolvency, BackendError>(
    {
      queryKey: [
        'financialSolvency',
        authenticationStore.organisationIdentifier,
      ],
      queryFn: () =>
        FinancialHealth.getFinancialSolvency(
          authenticationStore.organisationIdentifier
        ),
    }
  )

  useEffect(() => {
    reportingStore.setFinancialSolvency(data)
  }, [data])

  // check is up here to get type narrowing on data so it is no longer possibly undefined
  if (isPending || error) {
    return (
      <FinancialHealthCard
        title='Zahlungsfähigkeit'
        isLoading={isPending}
        customLoadingState={<LoaderFinancialHealthLiquidity />}
        error={error ?? undefined}
        className='financial-health-card-solvency'
      />
    )
  }

  const sumOfAssets =
    Math.max(data.sumOfAccountsReceivable, 0) +
    Math.max(data.sumOfInventories, 0) +
    Math.max(data.sumOfLiquidAssets, 0)

  const barChartBenchmark = Math.max(sumOfAssets, data?.balanceOfAccount2000)

  const metricsAreNonsense = data.balanceOfAccount2000 <= 0
  const nonsenseMetricPlaceholderText =
    data.balanceOfAccount2000 === 0
      ? 'Keine Rechnungen zu bezahlen.'
      : 'Das Konto Verbindlichkeiten weist einen negativen Saldo auf.'

  return (
    <FinancialHealthCard
      title='Zahlungsfähigkeit'
      className='financial-health-card-solvency'
      onClick={() =>
        CurrentReportStackNavigatorRef?.open(
          ReportPagePaths.FinancialHealthSolvency
        )
      }
    >
      <Flex>
        <div className='cash-information'>
          <span className='label'>Verfügbar</span>
          <span className='value'>CHF {Utilities.centsToCHF(sumOfAssets)}</span>
        </div>
        <div className='cash-information'>
          <span className='label'>Rechnungen</span>
          <span className='value'>
            CHF {Utilities.centsToCHF(data?.balanceOfAccount2000)}
          </span>
        </div>
      </Flex>
      <div className='liquidity-bars'>
        <div className='liquidity-bar-row'>
          <span className='label'>Verfügbar</span>
          <div className='bar'>
            <BarPart
              amountInCents={data?.sumOfLiquidAssets}
              benchmarkInCents={barChartBenchmark}
              label='Cash'
              className='cash'
            />
            <BarPart
              amountInCents={data?.sumOfAccountsReceivable}
              benchmarkInCents={barChartBenchmark}
              label='Forderungen'
              className='accounts-receivable'
            />
            <BarPart
              amountInCents={data?.sumOfInventories}
              benchmarkInCents={barChartBenchmark}
              label='Lager'
              className='inventories'
            />
          </div>
        </div>
        <div className='liquidity-bar-row'>
          <span className='label'>Rechnungen</span>
          <div className='bar'>
            <BarPart
              amountInCents={data?.balanceOfAccount2000}
              benchmarkInCents={barChartBenchmark}
              className='accounts-payable'
            />
          </div>
        </div>
      </div>
      <div className='liquidity-percentages'>
        {metricsAreNonsense ? (
          nonsenseMetricPlaceholderText
        ) : (
          <>
            <FinancialHealthPercentageRow
              percentage={data?.degreeOfLiquidity1.value}
              label='von Rechnungen bezahlbar mit Cash'
              defaultFallback={1}
            />
            <FinancialHealthPercentageRow
              percentage={data?.degreeOfLiquidity2.value}
              label='von Rechnungen bezahlbar gesamt'
              defaultFallback={1}
            />
          </>
        )}
      </div>
    </FinancialHealthCard>
  )
}

interface BarPartProps {
  amountInCents?: number
  benchmarkInCents: number
  label?: string
  className?: string
}

const BarPart = (props: BarPartProps) => {
  if ((props.amountInCents || 0) < 0) return null

  const width = Math.round(
    ((props.amountInCents ?? 0) /
      (props.benchmarkInCents === 0 ? 1 : props.benchmarkInCents)) *
      100
  )

  if (width < 1) return null

  return (
    <div
      className={'bar-part' + (props.className ? ' ' + props.className : '')}
      style={{
        width: `${width}%`,
      }}
    >
      {
        <div className='bar-part-label' title={props.label}>
          {props.label}
        </div>
      }
      <Tooltip content={`CHF ${Utilities.centsToCHF(props.amountInCents)}`}>
        <div className='bar-part-block' />
      </Tooltip>
    </div>
  )
}

export default observer(FinancialHealthSolvencyCard)
