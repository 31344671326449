import { Button, Flex } from '@nextbusiness/infinity-ui'
import './InvoiceListItemContainer.scss'

interface InvoiceListItemContainerProps {
  children?: React.ReactNode
  onClick?: () => void
}

const InvoiceListItemContainer = (props: InvoiceListItemContainerProps) => (
  <Button className='invoice-item' variant='shell' onClick={props.onClick}>
    <Flex
      className='invoice-item-inner'
      direction='horizontal'
      verticalAlignment='center'
    >
      {props.children}
    </Flex>
  </Button>
)

export default InvoiceListItemContainer
