import { Finance } from '@nextbusiness/infinity-finance-api'
import { useNotificationCenter } from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import FeatureOption from 'oobe/FeatureOption'
import SettingsGroup from 'settings/general/SettingsGroup'
import { useRootStore } from 'stores/RootStoreContext'

const BetaFeatureSettings = () => {
  const { preferencesStore } = useRootStore()
  const notificationCenter = useNotificationCenter()
  const features = preferencesStore.betaFeatures

  const toggleBetaFeature = (feature: string) => {
    if (features.includes(feature)) {
      setBetaFeatures(features.filter((f) => f !== feature))
    } else {
      setBetaFeatures([...features, feature])
    }
  }

  const setBetaFeatures = async (updatedFeatures: string[]) => {
    try {
      const updatedOrganisation = await Finance.Organisation.setFlags({
        betaFeatures: updatedFeatures,
      })
      runInAction(() => {
        preferencesStore.organisationPreferences = updatedOrganisation
      })
      notificationCenter.addNotification({
        children: 'Beta-Feature wurde aktualisiert.',
        variant: 'success',
      })
    } catch {
      notificationCenter.addNotification({
        children: 'Beta-Feature konnte nicht aktualisiert werden.',
        variant: 'error',
      })
    }
  }

  return (
    <SettingsGroup title='Beta-Funktionen'>
      <FeatureOption
        label='Financial Health for Startups'
        description='Fortgeschrittene Auswertungen mit Customisation'
        icon={<Icon icon='chart' />}
        onChange={() => toggleBetaFeature('financial-health-for-startups')}
        isChecked={features.includes('financial-health-for-startups')}
      />
      <FeatureOption
        label='Multi-Currency'
        description='Unterstützung für mehrere Währungen'
        icon={<Icon icon='bonds' />}
        onChange={() => toggleBetaFeature('multi-currency')}
        isChecked={features.includes('multi-currency')}
      />
    </SettingsGroup>
  )
}

export default observer(BetaFeatureSettings)
