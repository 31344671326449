import {
  ITransaction,
  ITransactionChanges,
} from '@nextbusiness/infinity-finance-api'
import {
  Card,
  DateDisplayFormatOptions,
  Flex,
  Text,
} from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import VirtualAccount from 'components/transactions/VirtualAccount'
import { useAccountPageContext } from 'ledger/accounts/AccountPageContext'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { centsToCHF } from 'utility/Utilities'
import { useBatchEditingContext } from './BatchEditingContext'
import './BatchEditingPreviewPanel.scss'

interface BatchEditingPreviewPanelProps {
  changesToTransaction: Partial<ITransactionChanges>
  filteringForSelectedTransactions: () => ITransaction[] | undefined
  newAccountNumber: number | undefined
}

const BatchEditingPreviewPanel = (props: BatchEditingPreviewPanelProps) => {
  const { accountStore } = useRootStore()

  const { currentAccount } = useAccountPageContext()
  const { selectedBatchEditingAction } = useBatchEditingContext()

  const { description, date } = props.changesToTransaction

  const [filteredTransactions, setFilteredTransactions] = useState<
    ITransaction[] | undefined
  >([])

  const formatDate = (dateToFormat: number | string | undefined) => {
    if (dateToFormat)
      return new Date(dateToFormat).toLocaleString(
        'de-CH',
        DateDisplayFormatOptions
      )
  }

  const formatAmount = (amount: any) => {
    return centsToCHF(amount)
  }

  const accountNumberFromTransaction = (transaction: ITransaction) => {
    const { creditAccount, debitAccount } = transaction

    const isCurrentAccountDebit = currentAccount.accountNumber === debitAccount
    const displayedAccount = isCurrentAccountDebit
      ? creditAccount
      : debitAccount

    return displayedAccount
  }

  const accountNameFromNumber = (transaction: ITransaction) => {
    const accountNumber = accountNumberFromTransaction(transaction)
    return accountStore.find(accountNumber)?.name ?? ''
  }

  const accountColourFromNumber = (transaction: ITransaction) => {
    const accountNumber = accountNumberFromTransaction(transaction)
    return accountStore.find(accountNumber)?.colour
  }

  const displayNewAccountName = (accountNumber: number) => {
    return accountStore.find(accountNumber)?.name ?? ''
  }

  const displayNewAccountColour = (accountNumber: number) => {
    return accountStore.find(accountNumber)?.colour ?? ''
  }

  useEffect(() => {
    setFilteredTransactions(props.filteringForSelectedTransactions())
  }, [])

  return (
    <>
      <Text className='transactions-preview-title'>Vorschau</Text>
      <Flex className='transactions-preview-list' direction='vertical'>
        {filteredTransactions?.map((transaction) => (
          <Card className='transactions-preview-card' key={transaction.id}>
            <Text
              className={classNames(
                'transactions-preview-list-card-description',
                selectedBatchEditingAction === 'rename' && 'is-being-edited'
              )}
            >
              {!description ? transaction.description : description}
            </Text>
            <Flex
              gap='regular'
              verticalLineAlignment='center'
              verticalAlignment='center'
              className='transactions-preview-flex'
            >
              <Flex gap='tiny' verticalAlignment='center' className='accounts'>
                <VirtualAccount
                  name={
                    selectedBatchEditingAction === 'changeContraAccount' &&
                    props.newAccountNumber
                      ? displayNewAccountName(props.newAccountNumber)
                      : accountNameFromNumber(transaction)
                  }
                  colour={
                    selectedBatchEditingAction === 'changeContraAccount' &&
                    props.newAccountNumber
                      ? displayNewAccountColour(props.newAccountNumber)
                      : accountColourFromNumber(transaction)
                  }
                  isBeingEdited={
                    selectedBatchEditingAction === 'changeContraAccount'
                  }
                />
                <Icon
                  className='arrow-icon'
                  size={IconSize.Tiny}
                  icon='arrow-left'
                />
                <VirtualAccount
                  name={
                    selectedBatchEditingAction === 'moveToDifferentAccount' &&
                    props.newAccountNumber
                      ? displayNewAccountName(props.newAccountNumber)
                      : currentAccount.name
                  }
                  colour={
                    selectedBatchEditingAction === 'moveToDifferentAccount' &&
                    props.newAccountNumber
                      ? displayNewAccountColour(props.newAccountNumber)
                      : currentAccount.colour
                  }
                  isBeingEdited={
                    selectedBatchEditingAction === 'moveToDifferentAccount'
                  }
                />
              </Flex>
              <Text
                className={classNames(
                  'text-date',
                  selectedBatchEditingAction === 'changeDate' &&
                    'is-being-edited'
                )}
              >
                {date ? formatDate(date) : formatDate(transaction.date)}
              </Text>
              <Flex
                verticalAlignment='center'
                gap='tiny'
                className='flex-amount'
              >
                {formatAmount(transaction.amount)}
                <Text className='chf' type='inline'>
                  CHF
                </Text>
              </Flex>
            </Flex>
          </Card>
        ))}
      </Flex>
    </>
  )
}

export default observer(BatchEditingPreviewPanel)
