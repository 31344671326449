import { ICompleteDocument } from '@nextbusiness/infinity-finance-api'
import { IAccount } from 'model/Account'
import React, { createContext, useMemo } from 'react'

interface LedgerContextType {
  currentAccountNumber: number
  openAccountPageModally: (accountNumber: number) => string
  popCurrentView: () => void
  goToRootView: () => void
  openSetupAccountBalancesPage: () => void
  openChartOfAccountsPage: () => void
  openDocumentPageModally: (document: ICompleteDocument) => void
  openSetupLiveAccountingPage: (account: IAccount) => void
}

export const LedgerContext = createContext<LedgerContextType | null>(null)

export const useLedgerContext = (): LedgerContextType => {
  const context = React.useContext(LedgerContext)
  if (!context) {
    throw new Error('useLedgerContext must be used within a LedgerProvider')
  }
  return context
}

const LedgerProvider = (props: React.PropsWithChildren<LedgerContextType>) => {
  const { currentAccountNumber, children, ...methods } = props
  const contextValue = useMemo(
    () => ({ currentAccountNumber, ...methods }),
    [currentAccountNumber]
  )
  return (
    <LedgerContext.Provider value={contextValue}>
      {children}
    </LedgerContext.Provider>
  )
}

export default LedgerProvider
