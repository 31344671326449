import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'

interface ProjectBoardDragDropContextProps {
  moveProject: (projectId: string, stageId: string) => void
  children: React.ReactNode
}

const ProjectBoardDragDropContext = (
  props: ProjectBoardDragDropContextProps
) => {
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 8 } })
  )
  const onProjectDragEnd = (event: DragEndEvent) => {
    const draggedProjectId = event.active.id as string
    const targetStageId = event.over?.id as string

    if (targetStageId) props.moveProject(draggedProjectId, targetStageId)
  }
  return (
    <DndContext sensors={sensors} onDragEnd={onProjectDragEnd}>
      {props.children}
    </DndContext>
  )
}

export default ProjectBoardDragDropContext
