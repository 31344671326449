import MobileHint from 'components/mobile-guard/MobileHint'
import MobileDocumentsPage from 'pages/mobile/MobileDocumentsPage'
import MobileMorePage from 'pages/mobile/MobileMorePage'
import AuthenticatedRoute from 'routes/AuthenticatedRoute'
import CommonApplicationSwitch from './CommonApplicationSwitch'
import MobileApplicationFrame from './MobileApplicationFrame'

const MobileApplicationSwitch = () => (
  <MobileApplicationFrame>
    <CommonApplicationSwitch defaultPage='/dokumente'>
      <AuthenticatedRoute path='/dokumente'>
        <MobileDocumentsPage />
      </AuthenticatedRoute>
      <AuthenticatedRoute path='/mehr'>
        <MobileMorePage />
      </AuthenticatedRoute>
      <AuthenticatedRoute path='/setup'>
        <MobileHint title='Logge dich an einem Computer auf finance.infinity.swiss ein, um fortzufahren.' />
      </AuthenticatedRoute>
      <AuthenticatedRoute>
        <MobileHint
          title='Logge dich an einem Computer auf finance.infinity.swiss ein, um diese Funktion zu nutzen.'
          inline
        />
      </AuthenticatedRoute>
    </CommonApplicationSwitch>
  </MobileApplicationFrame>
)

export default MobileApplicationSwitch
