import { Flex, InputField } from '@nextbusiness/infinity-ui'
import CategoryNavigation from 'components/category-navigation/CategoryNavigation'
import { PRESET_CATEGORIES } from 'model/Preset'

interface PresetModalNavigationProps {
  searchValue: string
  setSearchValue: (searchValue: string) => void
  activeCategory: keyof typeof PRESET_CATEGORIES
  setActiveCategory: (activeCategory: keyof typeof PRESET_CATEGORIES) => void
}

const PresetModalNavigation = (props: PresetModalNavigationProps) => (
  <Flex direction='vertical' className='preset-modal-navigation'>
    <InputField
      fullWidth
      placeholder='Suchen'
      icon='search'
      value={props.searchValue}
      onChange={props.setSearchValue}
    />
    <CategoryNavigation
      categories={PRESET_CATEGORIES}
      activeCategory={props.activeCategory}
      setActiveCategory={props.setActiveCategory}
    />
  </Flex>
)

export default PresetModalNavigation
