import { Finance, IDocumentAnalysis } from '@nextbusiness/infinity-finance-api'
import { observer } from 'mobx-react'
import { Contact } from 'model/Contact'
import IDocument from 'model/Document'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import LiveCaptureInvoiceTransformer from '../live-capture/LiveCaptureInvoiceTransformer'
import { useLiveCaptureAnalysis } from './hooks/useLiveCaptureAnalysis'

interface VendorInvoiceCreationContextType {
  contact?: Contact
  upload: IDocument | null
  setUpload: (upload: IDocument | null) => void
  documentAnalysis?: IDocumentAnalysis
  documentQrSlip?: string[]
  performLiveCaptureAnalysis: (document: IDocument) => void
  isPerformingAnalysis: boolean
  liveCaptureTransformer?: LiveCaptureInvoiceTransformer
}

export const VendorInvoiceCreationContext =
  React.createContext<VendorInvoiceCreationContextType | null>(null)

interface VendorInvoiceCreationProviderProps {
  children: React.ReactNode
}

const VendorInvoiceCreationProvider = (
  props: VendorInvoiceCreationProviderProps
) => {
  const { contactStore, documentStore, preferencesStore } = useRootStore()

  const [upload, setUpload] = useState<IDocument | null>(null)
  const [contact, setContact] = useState<Contact | undefined>(undefined)
  const [isPerformingAnalysis, setIsPerformingAnalysis] =
    useState<boolean>(false)
  const uploadId = upload?.id

  const { liveCapture, refetch } = useLiveCaptureAnalysis(uploadId)
  const performLiveCaptureAnalysis = async (document: IDocument) => {
    if (!preferencesStore.organisationPreferences?.isLiveCaptureEnabled) return
    setIsPerformingAnalysis(true)
    try {
      await Finance.Documents.analyseDocumentForLiveCapture(document.id)
      await refetch()
    } catch (error) {
      console.error('Live Capture analysis failed')
      console.error(error)
      // Fail silently, treat like analysis without detection result
    } finally {
      setIsPerformingAnalysis(false)
    }
  }

  const liveCaptureTransformer = useMemo(
    () =>
      liveCapture?.analyis
        ? new LiveCaptureInvoiceTransformer(
            liveCapture.analyis,
            preferencesStore.isUsingEffectiveVAT
          )
        : undefined,
    [liveCapture?.analyis]
  )

  useEffect(() => {
    if (documentStore.documentBeingAttached) {
      setUpload(documentStore.documentBeingAttached)
      documentStore.attachDocumentToNewInvoice(null)
    }
  }, [documentStore.documentBeingAttached])

  useEffect(() => {
    if (contactStore.contactBeingAttached) {
      setContact(contactStore.contactBeingAttached)
      contactStore.attachContactToNewInvoice(undefined)
    }
  }, [contactStore.contactBeingAttached])

  const contextValue = useMemo(
    (): VendorInvoiceCreationContextType => ({
      contact,
      upload,
      setUpload,
      documentAnalysis: liveCapture?.analyis,
      documentQrSlip: liveCapture?.qrSlip,
      performLiveCaptureAnalysis,
      isPerformingAnalysis,
      liveCaptureTransformer,
    }),
    [upload, setUpload, liveCapture]
  )

  return (
    <VendorInvoiceCreationContext.Provider value={contextValue}>
      {props.children}
    </VendorInvoiceCreationContext.Provider>
  )
}

export const useVendorInvoiceCreator = () => {
  const context = useContext(VendorInvoiceCreationContext)
  if (!context)
    throw new Error(
      'useVendorInvoiceCreator must be used within a VendorInvoiceCreationProvider'
    )
  return context
}

export default observer(VendorInvoiceCreationProvider)
