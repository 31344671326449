import { Button } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'

interface CurrencyOptionProps {
  currencyName: string
  currencyCode: string
  isSelected?: boolean
  isHighlighted?: boolean
  onClick: () => void
}

const CurrencyOption = (props: CurrencyOptionProps) => {
  return (
    <Button
      variant='shell'
      className={classNames('currency-select-option', {
        'currency-select-option-selected': props.isSelected,
        'currency-select-option-highlighted': props.isHighlighted,
      })}
      onClick={props.onClick}
    >
      <div className='currency-select-option-wrapper'>
        <div className='currency-select-option-name'>{props.currencyName}</div>
        <div className='currency-select-option-code'>{props.currencyCode}</div>
      </div>
    </Button>
  )
}

export default CurrencyOption
