import { DateTime, DurationInput } from 'luxon'

const startOfDay = (date: Date) => {
  return DateTime.fromJSDate(date).startOf('day').toJSDate()
}

const endOfDay = (date: Date) => {
  return DateTime.fromJSDate(date).endOf('day').toJSDate()
}

/**
 * Calculates the number of full days between two dates.
 * @param from The first date.
 * @param to The second date.
 */
const daysBetween = (from: Date, to: Date) => {
  const start = DateTime.fromJSDate(from).startOf('day')
  const end = DateTime.fromJSDate(to).startOf('day')

  const diff = end.diff(start, 'days').toObject()
  return Math.floor(diff.days || 0)
}

const dateSubtract = (date: Date, amount: DurationInput) => {
  return DateTime.fromJSDate(date).minus(amount).toJSDate()
}

const dateAdd = (date: Date, amount: DurationInput) => {
  return DateTime.fromJSDate(date).plus(amount).toJSDate()
}

const dateFromISO = (isoDate: string) => {
  return DateTime.fromISO(isoDate, {
    zone: 'Europe/Zurich',
  }).toJSDate()
}

const humanFormat = (timestamp: number) => {
  return new Date(timestamp).toLocaleDateString('de-DE', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })
}

const humanFormatNumeric = (timestamp: number) => {
  return new Date(timestamp).toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
}

const startOfYear = () => {
  const date = new Date()

  date.setMonth(0, 1)
  date.setHours(0, 0, 0, 0)

  return date
}

const yearsAfter = (
  fromDate: Date,
  numberOfYears: number,
  dayOffset: number
) => {
  const endDate = new Date(+fromDate)

  endDate.setFullYear(endDate.getFullYear() + numberOfYears)
  endDate.setDate(endDate.getDate() + dayOffset)

  return endDate
}

const DateUtilities = {
  startOfDay,
  endOfDay,
  dateFromISO,
  dateSubtract,
  dateAdd,
  humanFormat,
  humanFormatNumeric,
  startOfYear,
  yearsAfter,
  daysBetween,
}

export default DateUtilities
