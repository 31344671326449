import {
  BankingTransaction,
  TransactionOrigin,
  TransactionType,
} from '@nextbusiness/infinity-finance-api'
import { Button } from '@nextbusiness/infinity-ui'
import { IconSize } from '@nextbusiness/infinity-ui-icons'
import { motion } from 'framer-motion'
import { useTransactionEditorContext } from '../../TransactionEditorContext'
import TransactionResetManualReconciliation from './TransactionResetManualReconciliation'

interface TransactionManualReconciliationButtonProps {
  toggleManualReconciliation: () => void
  details: BankingTransaction
}

const TransactionManualReconciliationButton = (
  props: TransactionManualReconciliationButtonProps
) => {
  const { originalTransaction } = useTransactionEditorContext()

  const isIncomingPayment =
    props.details.transactionType === TransactionType.CREDIT
  const invoiceType = isIncomingPayment ? 'Forderung' : 'Rechnung'

  return (
    <motion.div
      key='manual-reconciliation-button'
      animate={{ opacity: 1, y: 0, filter: 'blur(0)' }}
      exit={{ opacity: 0, y: -40, filter: 'blur(10px)' }}
      transition={{
        duration: 0.1,
        ease: [0.22, 1, 0.36, 1],
      }}
    >
      {originalTransaction.origin === TransactionOrigin.LiveAccounting ? (
        <Button
          onClick={props.toggleManualReconciliation}
          variant='secondary'
          iconLeft='plus'
          iconSize={IconSize.Tiny}
          tooltip={`Markiere diese Buchung als Zahlung einer offenen ${invoiceType}`}
        >
          Bestehende {invoiceType}
        </Button>
      ) : (
        <>
          <Button
            onClick={props.toggleManualReconciliation}
            variant='quaternary'
            iconLeft='edit'
            iconSize={IconSize.Tiny}
          >
            {invoiceType} ändern
          </Button>
          <TransactionResetManualReconciliation />
        </>
      )}
    </motion.div>
  )
}

export default TransactionManualReconciliationButton
