import { Heading } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import React from 'react'
import './SettingsGroup.scss'

interface SettingsGroupProps {
  title?: string
  description?: string
  children?: React.ReactNode
  compact?: boolean
}

const SettingsGroup = (props: SettingsGroupProps) => (
  <div className={classNames('settings-group', props.compact && 'compact')}>
    <div className='settings-group-header'>
      {props.title && (
        <Heading className='settings-group-title' type='h3'>
          {props.title}
        </Heading>
      )}
      {props.description && (
        <div className='settings-group-description'>{props.description}</div>
      )}
    </div>
    <div className='settings-group-content'>{props.children}</div>
  </div>
)

export default SettingsGroup
