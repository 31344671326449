import { ApplicationError } from '@nextbusiness/infinity-ui'
import * as Sentry from '@sentry/react'
import React from 'react'
import MixpanelAnalytics from './MixpanelProductAnalytics'

interface SentryErrorBoundaryProps {
  children?: React.ReactNode
}

const SentryErrorBoundary = (props: SentryErrorBoundaryProps) => (
  <Sentry.ErrorBoundary
    onError={() => {
      MixpanelAnalytics.event('Application crash screen encountered')
    }}
    fallback={(sentryError) => (
      <ApplicationError
        error={
          sentryError.error instanceof Error ? sentryError.error : undefined
        }
        errorInfo={
          sentryError.componentStack
            ? { componentStack: sentryError.componentStack }
            : undefined
        }
      />
    )}
  >
    {props.children}
  </Sentry.ErrorBoundary>
)

export default SentryErrorBoundary
