import { Finance, ProjectStatus } from '@nextbusiness/infinity-finance-api'
import {
  Dialog,
  RadioGroup,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { CurrentCustomerInvoicesNavigationStackRef } from 'pages/CustomerInvoicesPage'
import { useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'

export type ArchivedStatus = Exclude<ProjectStatus, ProjectStatus.Active>

interface ArchiveProjectModalProps {
  projectId: string
  isOpen: boolean
  onDismiss: () => void
}

const ArchiveProjectModal = (props: ArchiveProjectModalProps) => {
  const { projectStore } = useRootStore()
  const notificationCenter = useNotificationCenter()
  const [archiveStatus, setArchiveStatus] = useState<ArchivedStatus>(
    ProjectStatus.Completed
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const archiveProject = async (toStatus: ArchivedStatus) => {
    setIsLoading(true)
    await Finance.Projects.modifyProject(props.projectId, { status: toStatus })
    await projectStore.fetchActiveProjects()
    await projectStore.fetchArchivedProjects()
    setIsLoading(false)
    props.onDismiss()
    CurrentCustomerInvoicesNavigationStackRef?.popCurrentView()
    notificationCenter.addNotification({
      children: 'Das Projekt wurde erfolgreich archiviert.',
      variant: 'success',
    })
  }

  useEffect(() => {
    setArchiveStatus(ProjectStatus.Completed)
  }, [props.isOpen])

  return (
    <Dialog
      icon='archive'
      title='Projekt archivieren'
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      actions={[
        {
          children: 'Abbrechen',
          onClick: props.onDismiss,
        },
        {
          children: 'Archivieren',
          onClick: () => archiveProject(archiveStatus),
          variant: 'primary',
          isLoading,
        },
      ]}
    >
      <Text>
        Wähle, mit welchem Status das Projekt archiviert werden soll. Du kannst
        Projekte jederzeit wiederherstellen.
      </Text>
      <RadioGroup
        value={archiveStatus}
        onChange={(value) => setArchiveStatus(value)}
        options={[
          {
            label: 'Das Projekt ist abgeschlossen',
            value: ProjectStatus.Completed,
          },
          {
            label: 'Das Projekt wurde abgebrochen',
            value: ProjectStatus.Abandoned,
          },
        ]}
      />
    </Dialog>
  )
}

export default observer(ArchiveProjectModal)
