import NavigatableMagicSheet from 'components/magic-sheet/NavigatableMagicSheet'
import FinancialHealthEditView from 'reports/financial-health/customisation/FinancialHealthEditView'
import AccountDetailReportPage from 'reports/financial-health/detail/expenses-or-income/AccountDetailReportPage'
import FinancialHealthExpensesDetailPage from 'reports/financial-health/detail/expenses-or-income/FinancialHealthExpensesDetailPage'
import FinancialHealthIncomeDetailPage from 'reports/financial-health/detail/expenses-or-income/FinancialHealthIncomeDetailPage'
import ProfitabilityReportPage from 'reports/financial-health/detail/profitability/ProfitabilityReportPage'
import FinancialHealthSolvencyPage from 'reports/financial-health/detail/solvency/FinancialHealthSolvencyPage'
import ReportingScreen from '../reports/ReportingScreen'
import BalanceSheetPage from '../reports/balance-sheet/BalanceSheetPage'
import FinancialHealthStatusDetailPage from '../reports/financial-health/detail/status-detail/FinancialHealthStatusDetailPage'
import IncomeStatementPage from '../reports/income-statement/IncomeStatementPage'

export let CurrentReportStackNavigatorRef: ReportPage | null = null

export enum ReportPagePaths {
  ReportsHome = '/auswerten',
  BalanceSheet = '/auswerten/bilanz',
  IncomeStatement = '/auswerten/erfolgsrechnung',
  FinancialHealthCustomisation = '/auswerten/anpassen',
  FinancialHealthStatus = '/auswerten/finanzielle-gesundheit',
  FinancialHealthSolvency = '/auswerten/solvency',
  FinancialHealthExpenses = '/auswerten/aufwaende',
  FinancialHealthIncome = '/auswerten/ertraege',
  FinancialHealthExpenseDetail = '/auswerten/aufwaende/:param',
  FinancialHealthIncomeDetail = '/auswerten/ertraege/:param',
  FinancialHealthProfitability = '/auswerten/profitabilitaet',
}

class ReportPage extends NavigatableMagicSheet {
  className = 'report-page-stack'
  basePath = ReportPagePaths.ReportsHome

  protected registerPaths(): void {
    this.registerView({
      url: ReportPagePaths.ReportsHome,
      title: () => 'Auswerten',
      view: <ReportingScreen reportPage={this} />,
    })
    this.registerView({
      url: ReportPagePaths.BalanceSheet,
      title: () => 'Bilanz',
      view: <BalanceSheetPage />,
    })
    this.registerView({
      url: ReportPagePaths.IncomeStatement,
      title: () => 'Erfolgsrechnung',
      view: <IncomeStatementPage />,
    })
    this.registerView({
      url: ReportPagePaths.FinancialHealthSolvency,
      title: () => 'Zahlungsfähigkeit',
      view: <FinancialHealthSolvencyPage />,
    })
    this.registerView({
      url: ReportPagePaths.FinancialHealthStatus,
      title: () => 'Finanzielle Gesundheit',
      view: <FinancialHealthStatusDetailPage />,
    })
    this.registerView({
      url: ReportPagePaths.FinancialHealthExpenses,
      title: () => 'Aufwände',
      view: <FinancialHealthExpensesDetailPage />,
    })
    this.registerView({
      url: ReportPagePaths.FinancialHealthIncome,
      title: () => 'Erträge',
      view: <FinancialHealthIncomeDetailPage />,
    })
    this.registerView({
      url: ReportPagePaths.FinancialHealthExpenseDetail,
      title: () => 'Aufwandskonto',
      view: <AccountDetailReportPage />,
    })
    this.registerView({
      url: ReportPagePaths.FinancialHealthIncomeDetail,
      title: () => 'Ertragskonto',
      view: <AccountDetailReportPage />,
    })
    this.registerView({
      url: ReportPagePaths.FinancialHealthProfitability,
      title: () => 'Profitabilität',
      view: <ProfitabilityReportPage />,
    })
    this.registerView({
      url: ReportPagePaths.FinancialHealthCustomisation,
      title: () => 'Kacheln anpassen',
      view: <FinancialHealthEditView />,
    })
  }

  open(path: ReportPagePaths, param?: string) {
    if (param) return this.props.history.push(path.replace(':param', param))
    this.props.history.push(path)
  }

  override componentDidMount() {
    super.componentDidMount()
    CurrentReportStackNavigatorRef = this
  }

  override componentWillUnmount() {
    super.componentWillUnmount()
    CurrentReportStackNavigatorRef = null
  }
}

export default ReportPage
