import {
  Finance,
  IPreset,
  IPresetInvoice,
} from '@nextbusiness/infinity-finance-api'
import DEFAULT_PRESETS from 'invoices/presets/DefaultPresets'
import { BackendError, ErrorType } from 'libs/networking/Errors'
import { action, computed, makeObservable, observable } from 'mobx'
import { IDefaultPresets, IPresetListItem } from 'model/Preset'
import RootStore from './RootStore'
import Store from './Store'

class PresetStore extends Store {
  defaultPresets: IDefaultPresets = DEFAULT_PRESETS

  @observable customPresets: IPresetListItem[] = []
  @observable selectedPreset: IPresetListItem | null = null

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  @computed
  get presetsByCategory() {
    return {
      custom: this.customPresets,
      ...this.defaultPresets,
    }
  }

  public async loadPresets() {
    const presets = await Finance.CustomerInvoice.presets()
    this.customPresets = presets.map((preset) => ({
      ...preset,
      type: 'custom',
    }))
  }

  @action
  setSelectedPreset(preset: IPresetListItem | null) {
    this.selectedPreset = preset
  }

  public async getSelectedPresetContent() {
    if (!this.selectedPreset) return undefined

    if (this.selectedPreset.type === 'custom') {
      return Finance.CustomerInvoice.preset(this.selectedPreset.id)
    }
    return this.loadPresetFromJSON(this.selectedPreset)
  }

  private async loadPresetFromJSON(preset: IPresetListItem): Promise<IPreset> {
    const presetURL = `/data/presets/${preset.id}.json`
    const response = await fetch(presetURL)
    if (!response.ok)
      throw new BackendError(
        ErrorType.NotFound,
        'Vorlage konnte nicht geladen werden'
      )
    const json = await response.json()
    return {
      ...preset,
      invoice: json as IPresetInvoice,
    }
  }
}

export default PresetStore
