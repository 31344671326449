import { Text } from '@nextbusiness/infinity-ui'
import './SingleContactInfoRow.scss'

interface SingleContactInfoRowProps {
  contactKey?: string
  contactValue?: string | string[]
}

const SingleContactInfoRow = (props: SingleContactInfoRowProps) => {
  if (!props.contactValue) {
    return null
  }

  return (
    <div className='contact-info-field'>
      <Text type='inline' className='contact-info-field-key'>
        {props.contactKey}
      </Text>
      <div className='contact-info-field-value'>
        <Text type='inline'>{props.contactValue}</Text>
      </div>
    </div>
  )
}

export default SingleContactInfoRow
