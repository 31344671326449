import React from 'react'
import useSubscription from '../subscription-context/useSubscription'
import FeatureUpgradeScreen, {
  FeatureUpgradeScreenProps,
} from './FeatureUpgradeScreen'

export interface FeatureGateProps extends FeatureUpgradeScreenProps {
  children?: React.ReactNode
}

const FeatureGate = (props: FeatureGateProps) => {
  const subscription = useSubscription()
  if (!subscription?.hasCapability(props.requiredCapability)) {
    return <FeatureUpgradeScreen {...props} />
  }
  return <>{props.children}</>
}

export default FeatureGate
