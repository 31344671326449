import { observer } from 'mobx-react-lite'
import ICustomerInvoice, {
  ICustomerInvoicePreview,
} from '../../../model/CustomerInvoice'
import InvoiceStatus from '../../shared-components/InvoiceStatus'
import InvoiceListItemContainer from '../../shared-components/regular-invoice-list/InvoiceListItemContainer'
import '../../shared-components/regular-invoice-list/InvoiceListItemContainer.scss'
import InvoiceListItemDepiction from '../../shared-components/regular-invoice-list/InvoiceListItemDepiction'
import InvoiceListItemOpeningDate from '../../shared-components/regular-invoice-list/InvoiceListItemOpeningDate'
import InvoiceListItemTotalAmount from '../../shared-components/regular-invoice-list/InvoiceListItemTotalAmount'
import ExtendedCustomerInvoiceIcon from '../invoice-icons/ExtendedCustomerInvoiceIcon'
import QuoteStatus from '../quotes/QuoteStatus'

interface CustomerInvoiceListItemProps {
  invoice: ICustomerInvoice | ICustomerInvoicePreview
  onClick?: () => void
}

const CustomerInvoiceListItem = (props: CustomerInvoiceListItemProps) => {
  return (
    <InvoiceListItemContainer onClick={props.onClick}>
      <ExtendedCustomerInvoiceIcon invoice={props.invoice} small />
      <InvoiceListItemDepiction
        contactId={props.invoice.recipient}
        title={props.invoice.title}
      />
      {props.invoice.isQuote ? (
        <QuoteStatus quoteStatus={props.invoice.quoteStatus} />
      ) : (
        <InvoiceStatus
          dueDate={props.invoice.dueDate}
          isPaid={props.invoice.isPaid}
          isDraft={props.invoice.isDraft}
          isAnnulled={props.invoice.isAnnulled}
        />
      )}
      <InvoiceListItemOpeningDate openingDate={props.invoice.openingDate} />
      <InvoiceListItemTotalAmount totalAmount={props.invoice.totalAmount} />
    </InvoiceListItemContainer>
  )
}

export default observer(CustomerInvoiceListItem)
