import {
  Dialog,
  FeedbackBanner,
  Flex,
  InputField,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { isBackendError } from 'libs/networking/Errors'
import ICustomerInvoice from 'model/CustomerInvoice'
import CustomerInvoices from 'networking/CustomerInvoices'
import { useEffect, useState } from 'react'
import './PublicQuotePageDialog.scss'

interface PublicQuotePageDialogProps {
  isOpen: boolean
  onClose: () => void
  organisationName: string
  shareId: string
  invalidateUsingNewQuote: (updatedQuote: ICustomerInvoice) => void
}

const PublicQuotePageDialog = (props: PublicQuotePageDialogProps) => {
  const notificationCenter = useNotificationCenter()
  const [receiverName, setReceiverName] = useState<string>('')

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const changePublicOfferStatus = async () => {
    setIsLoading(true)

    try {
      const updatedQuote = await CustomerInvoices.acceptPublicQuote(
        props.shareId,
        receiverName
      )
      props.invalidateUsingNewQuote(updatedQuote)
      props.onClose()

      notificationCenter.addNotification({
        children: 'Sie haben das Angebot akzeptiert.',
        variant: 'success',
      })
    } catch (error: any) {
      notificationCenter.addNotification({
        children:
          (isBackendError(error) ? error.displayMessage : error.message) ||
          'Das Angebot konnte nicht akzeptiert werden. Bitte versuchen Sie es erneut.',
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const keyDownEventHandler = (event: KeyboardEvent) => {
      if (!props.isOpen) return
      if (event.key === 'Enter' && receiverName !== '')
        changePublicOfferStatus()
    }
    document.addEventListener('keydown', keyDownEventHandler)
    return () => document.removeEventListener('keydown', keyDownEventHandler)
  }, [props.isOpen])

  return (
    <Dialog
      isOpen={props.isOpen}
      title='Angebot akzeptieren'
      titleProps={{ divider: true }}
      initialFocusIndex={1}
      dismissable
      className='name-requirement-dialog'
      onDismiss={() => props.onClose()}
      actions={[
        {
          children: 'Abbrechen',
          variant: 'tertiary',
          className: 'close-button',
          onClick: () => props.onClose(),
        },
        {
          children: 'Bestätigen',
          variant: 'primary',
          className: 'accept-button',
          isLoading: isLoading,
          disabled: !receiverName,
          onClick: () => changePublicOfferStatus(),
        },
      ]}
    >
      <Text className='instruction text'>
        {props.organisationName} wird informiert werden, dass das Angebot
        akzeptiert wurde.
      </Text>
      <Flex gap='small' verticalAlignment='center'>
        <span className='input-name'>Ihr Name</span>
        <InputField
          className='input-field'
          type='text'
          fullWidth
          value={receiverName}
          onChange={(value) => setReceiverName(value)}
        />
      </Flex>
      {!receiverName ? (
        <FeedbackBanner variant='info'>
          Ihr Name ist erforderlich.
        </FeedbackBanner>
      ) : null}
    </Dialog>
  )
}

export default PublicQuotePageDialog
