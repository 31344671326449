import { IProduct } from '@nextbusiness/infinity-finance-api'
import useSubscription from 'components/billing/subscription-context/useSubscription'
import SelectProductModal from 'invoices/customer-invoices/products/SelectProductModal'
import SubscriptionCapability from 'libs/subscription/SubscriptionCapability'
import { useEffect, useState } from 'react'
import { DocumentCustomProductPositionProps } from '../DocumentCustomProductPosition'
import DocumentPositionEmptyAction from '../DocumentPositionEmptyAction'

const PositionProductSelectionAction = (
  props: DocumentCustomProductPositionProps
) => {
  const subscription = useSubscription()

  const [isSelectProductModalOpen, setIsSelectProductModalOpen] =
    useState<boolean>(false)

  useEffect(() => {
    props.setSuppressFlyout(isSelectProductModalOpen)
  }, [isSelectProductModalOpen])

  if (!subscription?.hasCapability(SubscriptionCapability.Products)) return

  const selectProduct = (product: IProduct) => {
    const textOnInvoice = product.nameOnInvoice || product.name

    props.setSuppressFlyout(false)
    props.onChange({
      name: textOnInvoice,
      unit: product.unit,
      singleAmount: product.sellingPrice,
      taxCode: product.taxCode,
      contraAccount: product.incomeAccount,
      articleNumber: product.articleNumber,
      product: product.id,
      quantity: props.position.quantity ?? 1,
    })
  }

  return (
    <>
      <DocumentPositionEmptyAction
        icon='package-delivery-logistics'
        onClick={() => setIsSelectProductModalOpen(true)}
      >
        Produkt wählen
      </DocumentPositionEmptyAction>
      <SelectProductModal
        isOpen={isSelectProductModalOpen}
        onDismiss={() => setIsSelectProductModalOpen(false)}
        onProductSelected={selectProduct}
      />
    </>
  )
}

export default PositionProductSelectionAction
