import { Button } from '@nextbusiness/infinity-ui'
import { useBatchEditingContext } from 'ledger/transactions/batch-editing/BatchEditingContext'
import { BatchEditingAction } from 'ledger/transactions/batch-editing/BatchEditingModal'
import { useEffect } from 'react'
import ActionBar from './ActionBar'
import './BatchEditingActionBar.scss'

const BatchEditingActionBar = () => {
  const {
    selectedTransactions,
    setIsBatchActionModalOpen,
    setSelectedBatchEditingAction,
    setIsInMultiEdit,
  } = useBatchEditingContext()

  const textForSelectedTransactions = () => {
    switch (selectedTransactions.length) {
      case 0:
        return 'Keine Transaktionen ausgewählt'
      case 1:
        return '1 Transaktion ausgewählt'
      default:
        return `${selectedTransactions.length} Transaktionen ausgewählt`
    }
  }

  useEffect(() => {
    textForSelectedTransactions()
  }, [selectedTransactions])

  const onDoneClicked = () => {
    setIsInMultiEdit(false)
    selectedTransactions.splice(0)
  }

  const onActionClicked = (action: BatchEditingAction) => {
    setIsBatchActionModalOpen(true)
    setSelectedBatchEditingAction(action)
  }

  const areBatchActionsDisabled = selectedTransactions.length === 0

  return (
    <ActionBar
      className='batch-editing-action-bar'
      leftHandText={textForSelectedTransactions()}
      onDone={onDoneClicked}
    >
      <Button
        disabled={areBatchActionsDisabled}
        onClick={() => onActionClicked('rename')}
      >
        Umbenennen
      </Button>
      <Button
        disabled={areBatchActionsDisabled}
        onClick={() => onActionClicked('changeDate')}
      >
        Datum ändern
      </Button>
      <Button
        disabled={areBatchActionsDisabled}
        onClick={() => onActionClicked('changeContraAccount')}
      >
        Gegenkonto wechseln
      </Button>
      <Button
        disabled={areBatchActionsDisabled}
        onClick={() => onActionClicked('moveToDifferentAccount')}
      >
        In anderes Konto verschieben
      </Button>
      <Button
        disabled={areBatchActionsDisabled}
        onClick={() => onActionClicked('delete')}
      >
        Löschen
      </Button>
    </ActionBar>
  )
}

export default BatchEditingActionBar
