import { Text } from '@nextbusiness/infinity-ui'
import { centsToCHF } from 'utility/Utilities'
import './InvoiceListItemTotalAmount.scss'

interface InvoiceListItemTotalAmountProps {
  totalAmount: number
}

const InvoiceListItemTotalAmount = (props: InvoiceListItemTotalAmountProps) => (
  <>
    <Text className='total-amount' type='inline'>
      {centsToCHF(props.totalAmount)}
    </Text>
    <div className='currency-sign'>CHF</div>
  </>
)

export default InvoiceListItemTotalAmount
