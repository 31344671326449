import { Button } from '@nextbusiness/infinity-ui'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import CustomerInvoiceDiscardChangesConfirmationDialog from '../../CustomerInvoiceDiscardChangesConfirmationDialog'
import { CustomerInvoiceEditorSidebarTopActionsProps } from './CustomerInvoiceEditorSidebarTopActions'

const EditorDiscardAction = (
  props: CustomerInvoiceEditorSidebarTopActionsProps
) => {
  const history = useHistory()
  const [showConfirmDiscardChangesDialog, setShowConfirmDiscardChangesDialog] =
    useState<boolean>(false)

  const discardChanges = () => {
    setShowConfirmDiscardChangesDialog(false)

    if (!props.isExistingDocument) {
      history.push('/forderungen')
    } else {
      props.discardChanges()
    }
  }

  return (
    <>
      <Button
        disabled={props.isSaving}
        onClick={() => {
          if (!props.hasChanges) discardChanges()
          else setShowConfirmDiscardChangesDialog(true)
        }}
      >
        Verwerfen
      </Button>
      <CustomerInvoiceDiscardChangesConfirmationDialog
        isOpen={showConfirmDiscardChangesDialog}
        onDismiss={() => setShowConfirmDiscardChangesDialog(false)}
        onDiscardChanges={discardChanges}
      />
    </>
  )
}

export default EditorDiscardAction
