import TransactionAbortEditDialog from '../editor/TransactionAbortEditDialog'
import TransactionLiveAccountingSection from '../live-accounting/TransactionLiveAccountingSection'
import TransactionListItems from './TransactionListItems'
import TransactionListPaginationControl from './TransactionListPaginationControl'
import TransactionListScrollView from './TransactionListScrollView'

const TransactionList = () => {
  return (
    <TransactionListScrollView
      paginationControl={<TransactionListPaginationControl />}
    >
      <TransactionLiveAccountingSection />
      <TransactionListItems
        state='reviewed'
        showMonthlyHeadings
        showFiscalYearBreaks
      />
      <TransactionAbortEditDialog />
    </TransactionListScrollView>
  )
}

export default TransactionList
