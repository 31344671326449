import { useNotificationCenter } from '@nextbusiness/infinity-ui'
import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import DocumentsPage from 'pages/DocumentsPage'
import DocumentUpload from './DocumentUpload'

interface DocumentCreatorProps {
  page: DocumentsPage
}

const DocumentCreator = (props: DocumentCreatorProps) => {
  const notificationCenter = useNotificationCenter()

  return (
    <MagicSheetPage
      header={<MagicSheetTitleBar title='Neue Belege' />}
      noTrailingNegativeSpace
    >
      <MagicSheetContainer>
        <DocumentUpload
          notificationCenter={notificationCenter}
          onDismiss={() => props.page.goToRootView()}
        />
      </MagicSheetContainer>
    </MagicSheetPage>
  )
}

export default DocumentCreator
