import {
  Finance,
  ITransaction,
  TransactionOrigin,
} from '@nextbusiness/infinity-finance-api'
import {
  Button,
  Card,
  DateDisplayFormatOptions,
  Dialog,
  Flex,
  LoadingIndicator,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import Account from 'components/transactions/Account'
import TransactionAmount from 'components/transactions/TransactionAmount'
import useBackendQuery from 'libs/networking/BackendQuery'
import { isBackendError } from 'libs/networking/Errors'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { centsToCHF } from 'utility/Utilities'
import './SmallTransactionView.scss'

interface SmallTransactionViewProps {
  transactionId: string
  invalidateQuery: () => void
  accountDisplay: 'credit' | 'debit'
  dissallowDeletion?: boolean
  reasonForDisallowingDeletion?: string
  showDescription?: boolean
  showGrossAmountOnly?: boolean
  readonly?: boolean
}

const SmallTransactionView = (props: SmallTransactionViewProps) => {
  const notificationCenter = useNotificationCenter()
  const { transactionStore, accountStore } = useRootStore()

  const [transaction, setTransaction] = useState<ITransaction>()
  const [isDeletionInProgress, setIsDeletionInProgress] =
    useState<boolean>(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false)

  const [, isLoadingTransaction, loadingError] = useBackendQuery(
    () =>
      Finance.Ledger.transactions({
        id: props.transactionId,
      }),
    (loadedTransactions) => setTransaction(loadedTransactions[0])
  )

  const date = new Date(transaction?.date ?? 0).toLocaleString('de-CH', {
    ...DateDisplayFormatOptions,
    year: '2-digit',
  })

  const accountNumber =
    props.accountDisplay === 'credit'
      ? transaction?.creditAccount
      : transaction?.debitAccount

  if (isLoadingTransaction) return <LoadingIndicator />

  if (loadingError) return null

  const canDelete =
    !props.dissallowDeletion &&
    transaction &&
    !accountStore.fiscalYear(transaction?.fiscalYear)?.isClosed

  if (
    props.showGrossAmountOnly &&
    transaction?.origin === TransactionOrigin.VAT
  )
    return null

  return (
    <div className='transaction small-view'>
      <Card bare>
        <Flex className='upper-half' verticalAlignment='center'>
          {transaction ? (
            <>
              <Text className='date' type='inline'>
                {date}
              </Text>
              {props.showDescription && (
                <Text className='description' type='inline'>
                  {transaction.description}
                </Text>
              )}
              <Account accountNumber={accountNumber} />
              <TransactionAmount
                amount={
                  props.showGrossAmountOnly
                    ? transaction.grossAmount
                    : transaction?.amount
                }
              />
            </>
          ) : (
            <span className='not-found'>Transaktion nicht gefunden</span>
          )}
          {!props.readonly && (
            <Button
              className='delete-button'
              iconOnly={!canDelete ? 'no-edit' : 'delete'}
              variant='quaternary'
              tooltip={
                !canDelete
                  ? props.reasonForDisallowingDeletion ??
                    'Transaktionen aus geschlossenen Geschäftsjahren können nicht mehr bearbeitet werden.'
                  : undefined
              }
              disabled={!canDelete}
              onClick={() => {
                if (!canDelete) return
                setShowDeleteConfirmation(true)
              }}
            />
          )}
          {showDeleteConfirmation && (
            <Dialog
              title='Transaktion unwiderruflich löschen?'
              actions={[
                {
                  children: 'Abbrechen',
                  onClick: () => setShowDeleteConfirmation(false),
                  disabled: isDeletionInProgress,
                },
                {
                  children: 'Löschen',
                  variant: 'destructive',
                  isLoading: isDeletionInProgress,
                  onClick: async () => {
                    setIsDeletionInProgress(true)
                    if (transaction) {
                      try {
                        await transactionStore.deleteTransactions([transaction])
                      } catch (error: any) {
                        notificationCenter.addNotification({
                          variant: 'error',
                          title: 'Transaktion konnte nicht gelöscht werden.',
                          children: isBackendError(error)
                            ? error.displayMessage
                            : error.message,
                        })
                      }
                    }
                    setIsDeletionInProgress(false)
                    setShowDeleteConfirmation(false)
                    props.invalidateQuery()
                  },
                },
              ]}
              isOpen={showDeleteConfirmation}
              onDismiss={() => setShowDeleteConfirmation(false)}
              dismissable
            >
              Möchtest du die Transaktion "{transaction?.description}" in der
              Höhe von {centsToCHF(transaction?.amount)} wirklich löschen?
            </Dialog>
          )}
        </Flex>
      </Card>
    </div>
  )
}

export default observer(SmallTransactionView)
