import { Flex } from '@nextbusiness/infinity-ui'
import React from 'react'

interface TransactionCreatorFooterProps {
  children: React.ReactNode
}

const TransactionCreatorFooter = (props: TransactionCreatorFooterProps) => (
  <Flex
    className='footer'
    horizontalAlignment='space-between'
    verticalAlignment='center'
  >
    {props.children}
  </Flex>
)

export default TransactionCreatorFooter
