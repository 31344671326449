import { Dialog } from '@nextbusiness/infinity-ui'
import { FeatureGateProps } from './FeatureGate'
import FeatureUpgradeScreen from './FeatureUpgradeScreen'
import './FeatureUpgradeModal.scss'

interface FeatureUpgradeModalProps extends Omit<FeatureGateProps, 'children'> {
  isOpen: boolean
  onDismiss: () => void
}

const FeatureUpgradeModal = (props: FeatureUpgradeModalProps) => {
  const { isOpen, onDismiss, ...upgradeScreenProps } = props
  return (
    <Dialog
      isOpen={isOpen}
      onDismiss={onDismiss}
      dismissable
      className='feature-upgrade-modal'
    >
      <FeatureUpgradeScreen {...upgradeScreenProps} />
    </Dialog>
  )
}

export default FeatureUpgradeModal
