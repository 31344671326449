import SubscriptionCapability from './SubscriptionCapability'
import SubscriptionTier from './SubscriptionTier'

const SubscriptionTiers: SubscriptionTier[] = [
  {
    title: 'Infinity Finance Start',
    identifiers: [
      'price_1LyZpnJbwTSfij8e1lBUlW8i', // Staging Start
      'price_1NWLeGJbwTSfij8e3BECtdsB', // Staging Start Annual
      'price_1LyZrhJbwTSfij8e91czc2Bn', // Production Start
      'price_1M7ecGJbwTSfij8e8x4KEnLi', // Production Start (Mach-dis-Ding)
      'price_1NWLc3JbwTSfij8ekhnwqqXo', // Production Start Annual,
    ],
    isDefaultTier: true,
  },
  {
    title: 'Infinity Finance Pro',
    identifiers: [
      'price_1LyZq0JbwTSfij8ewib78YaF', // Staging Pro
      'price_1NWLeiJbwTSfij8egHIMrWIk', // Staging Pro Annual
      'price_1LyZsZJbwTSfij8eLB0CShUG', // Production Pro
      'price_1M2tYxJbwTSfij8e8gyxJbge', // Production Pro (StartupNights)
      'price_1NWLd0JbwTSfij8eXPar4Gv6', // Production Pro Annual
      'price_1OT4h4JbwTSfij8exQ3E72yl', // Production Pro (Neujahr 2024)
      'price_1PfKs7JbwTSfij8eOy5HS0Ny', // Production Pro (JULI40)
      'price_1PfKscJbwTSfij8eqcpgbha7', // Production Pro Annual (JULI40)
    ],
    capabilities: [
      SubscriptionCapability.AdvancedFiltering,
      SubscriptionCapability.AdvancedReporting,
      SubscriptionCapability.AdvancedVAT,
      SubscriptionCapability.Products,
      SubscriptionCapability.AdvancedTemplating,
    ],
    isDefaultTrialTier: true,
  },
  {
    title: 'Infinity Finance (Launch)',
    identifiers: [
      'ch.nextbusiness.infinity.per-app-subscription',
      'price_1Lo2uyJbwTSfij8eMKOzyjWH',
    ],
    capabilities: [
      SubscriptionCapability.AdvancedFiltering,
      SubscriptionCapability.AdvancedReporting,
      SubscriptionCapability.AdvancedVAT,
      SubscriptionCapability.Products,
      SubscriptionCapability.AdvancedTemplating,
    ],
  },
]

export default SubscriptionTiers
