import { ITemplate } from '@nextbusiness/infinity-finance-api'
import { Button, Flex, Heading } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import { ITemplateDraft } from 'model/Template'
import { useRef, useState } from 'react'
import { scrollToTop } from 'utility/Utilities'
import { useHasScrolled } from 'utility/hooks'
import Tabs from '../../../proto-ui-components/Tabs'
import './TemplateInspector.scss'
import TemplateInspectorAppearanceTab from './appearance/TemplateInspectorAppearanceTab'
import TemplateInspectorElementsTab from './elements/TemplateInspectorElementsTab'
import TemplateInspectorTextTab from './text/TemplateInspectorTextTab'

export interface TemplateInspectorProps {
  template: ITemplate | ITemplateDraft
  modifyTemplate: (changes: Partial<ITemplate>) => void
  resetPropertyToDefault: (property: keyof ITemplateDraft) => void

  canSave?: boolean
  isSaving?: boolean
  onSave: () => void

  isFirstTimeCreation: boolean
  reloadTemplate: () => void
  changePreviewType: (showQuotePreview: boolean) => void
}

type TemplateInspectorTabs = 'text' | 'appearance' | 'elements'

const TemplateInspector = (props: TemplateInspectorProps) => {
  const [activeTab, setActiveTab] = useState<TemplateInspectorTabs>('text')

  const containerRef = useRef<HTMLDivElement>(null)
  const hasScrolled = useHasScrolled(containerRef)

  return (
    <div
      className={classNames('template-inspector', {
        scrolled: hasScrolled,
      })}
    >
      <div className='inspector-header'>
        <Flex verticalAlignment='flex-start'>
          <Heading type='h2' bare style={{ flexGrow: 1 }}>
            Design
          </Heading>
          <Button
            variant='primary'
            disabled={!props.canSave || props.isSaving}
            isLoading={props.isSaving}
            onClick={() => props.onSave()}
          >
            {props.isFirstTimeCreation ? 'Einrichten' : 'Speichern'}
          </Button>
        </Flex>
        <Tabs
          tabs={[
            { identifier: 'text', title: 'Text' },
            { identifier: 'appearance', title: 'Aussehen' },
            {
              identifier: 'elements',
              title: 'Elemente',
              onClick: () =>
                scrollToTop(document.querySelector('.template-preview-panel'), {
                  behavior: 'smooth',
                }),
            },
          ]}
          currentTabId={activeTab}
          onTabSelected={(tab) =>
            setActiveTab(tab.identifier as TemplateInspectorTabs)
          }
        />
      </div>
      <div className='inspector-tab-content' ref={containerRef}>
        {activeTab === 'appearance' && (
          <TemplateInspectorAppearanceTab {...props} />
        )}
        {activeTab === 'elements' && (
          <TemplateInspectorElementsTab {...props} />
        )}
        {activeTab === 'text' && <TemplateInspectorTextTab {...props} />}
      </div>
    </div>
  )
}

export default TemplateInspector
