import './ReportOptions.scss'

interface ReportOptionsProps {
  children?: React.ReactNode
}

const ReportOptions = (props: ReportOptionsProps) => (
  <div className='report-modal-options'>{props.children}</div>
)

export default ReportOptions
