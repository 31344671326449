import { Dialog, Flex } from '@nextbusiness/infinity-ui'
import CustomerInvoiceEditorDocument from 'invoices/customer-invoices/editor/CustomerInvoiceEditorDocument'
import { observer } from 'mobx-react-lite'
import { Contact } from 'model/Contact'
import { IDefaultPresets, PRESET_CATEGORIES } from 'model/Preset'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useRootStore } from 'stores/RootStoreContext'
import PresetCategorySection from './PresetCategorySection'
import './PresetModal.scss'
import PresetModalNavigation from './PresetModalNavigation'
import PresetSearchResultSection from './PresetSearchResultSection'

interface PresetSelectionModalProps {
  isOpen: boolean
  closeModal: () => void
  isNewDocumentQuote: boolean
  contact?: Contact | null
}

const PresetModal = (props: PresetSelectionModalProps) => {
  const { presetStore, contactStore, templateStore, authenticationStore } =
    useRootStore()

  const history = useHistory()
  const [activeCategory, setActiveCategory] =
    useState<keyof typeof PRESET_CATEGORIES>('all')
  const [searchValue, setSearchValue] = useState<string>('')
  const title = props.isNewDocumentQuote ? 'Neue Offerte' : 'Neue Forderung'

  const categories = Object.keys(presetStore.presetsByCategory)
  const displayedPresetCategories = () =>
    categories.filter(
      (category) => activeCategory === 'all' || activeCategory === category
    ) as (keyof IDefaultPresets)[]

  const isSearching = () => searchValue !== ''

  const searchResults = () =>
    displayedPresetCategories()
      .flatMap((category) => presetStore.presetsByCategory[category])
      .filter((preset) =>
        [preset.title, preset.invoice.title, preset.description ?? '']
          .map((value) => value?.toLowerCase())
          .some((value) => value?.includes(searchValue.toLowerCase()))
      )

  const openDocumentEditor = async () => {
    if (props.contact) contactStore.attachContactToNewInvoice(props.contact)
    if (!templateStore.currentTemplate)
      await templateStore.loadCurrentTemplate()

    props.closeModal()
    if (!templateStore.currentTemplate) {
      // Template may still not be set because it's possible it doesn't exist yet. In this case,
      // we can't auto-create the document here yet until onboarding is completed.
      history.push(
        `/forderungen/erstellen/${
          props.isNewDocumentQuote ? 'offerte' : 'forderung'
        }`
      )
      return
    }
    const preset = await presetStore.getSelectedPresetContent()
    const invoice = new CustomerInvoiceEditorDocument(
      templateStore.currentTemplate,
      undefined,
      props.isNewDocumentQuote,
      preset
    )
    const savedInvoice = await invoice.save(
      authenticationStore.organisationIdentifier
    )
    history.push(`/forderungen/detail/${savedInvoice.id}?edit`)
  }

  useEffect(() => {
    setActiveCategory('all')
    setSearchValue('')

    if (props.isOpen) presetStore.loadPresets()
  }, [props.isOpen])

  return (
    <Dialog
      isOpen={props.isOpen}
      title={title}
      titleProps={{ divider: true }}
      dismissable
      onDismiss={props.closeModal}
      className='preset-modal'
      actions={[
        {
          className: 'edit-templates',
          children: 'Eigene Vorlagen verwalten',
          onClick: () => {
            history.push('optionen/vorlagen')
            props.closeModal()
          },
        },
      ]}
    >
      <Flex fillContainer>
        <PresetModalNavigation
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
        />
        <Flex className='preset-modal-content' direction='vertical'>
          {isSearching() ? (
            <PresetSearchResultSection
              searchValue={searchValue}
              activeCategory={activeCategory}
              presets={searchResults()}
              isNewDocumentQuote={props.isNewDocumentQuote}
              openDocumentEditor={openDocumentEditor}
            />
          ) : (
            displayedPresetCategories().map((category, index) => (
              <PresetCategorySection
                key={category}
                category={category}
                presets={presetStore.presetsByCategory[category]}
                isNewDocumentQuote={props.isNewDocumentQuote}
                openDocumentEditor={openDocumentEditor}
                showBlankPreset={index === 0}
              />
            ))
          )}
        </Flex>
      </Flex>
    </Dialog>
  )
}

export default observer(PresetModal)
