import MagicSheet from 'components/magic-sheet/MagicSheet'
import WelcomeCentre from './WelcomeCentre'

class WelcomeCentrePage extends MagicSheet {
  rootView = () => {
    return <WelcomeCentre />
  }

  componentDidMount() {
    this.presentViewModally({
      title: () => 'Willkommen',
      view: this.rootView(),
    })
  }
}

export default WelcomeCentrePage
