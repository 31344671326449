import { Button, Flex, Text } from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useIntercom } from 'react-use-intercom'
import { useIsTouchDevice } from 'utility/hooks'
import { Upload } from './DocumentUpload'
import './DocumentUploadArea.scss'
import { SUPPORTED_FILE_TYPES } from './DocumentUploadConstants'
import UploadedDocumentCard from './UploadedDocumentCard'

interface DocumentUploadAreaProps {
  uploadedFiles: Upload[]
  onUploadSelected: (uploads: Upload[]) => void
  isUploading?: boolean
  editUpload: (at: number, changes: Partial<Upload>) => void
  removeFile: (fileToDelete: File) => void
  onDismiss?: () => void
  saveDocuments: (uploads: Upload[]) => void
}

const cleanupFileTitle = (fileName: string) => {
  if (fileName === 'image.jpg') {
    // iOS default name for photos
    return `Hochgeladenes Bild am ${new Date().toLocaleDateString()}`
  }
  return fileName.replace(/\.[^/.]+$/, '')
}

const DocumentUploadArea = (props: DocumentUploadAreaProps) => {
  const intercom = useIntercom()

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles) {
      const uploads: Upload[] = acceptedFiles.map((acceptedFile) => {
        return {
          file: acceptedFile,
          title: cleanupFileTitle(acceptedFile.name),
        }
      })
      props.onUploadSelected(uploads)
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: SUPPORTED_FILE_TYPES,
    noClick: props.uploadedFiles.length > 0,
  })

  const isTouchDevice = useIsTouchDevice()

  const emptyStateText = isDragActive ? (
    <Text>Belege hierhin ziehen</Text>
  ) : isTouchDevice ? (
    <>
      <Icon icon='camera' />
      <Text>Beleg fotografieren oder aus Galerie hochladen</Text>
    </>
  ) : (
    <>
      <Icon icon='bank-draft' />
      <Text>Belege hierhin ziehen oder auswählen</Text>
    </>
  )

  const stylingDrag = isDragActive ? ' dropzone' : ''
  const stylingUploadArea =
    props.uploadedFiles.length > 0 ? ' with-files' : stylingDrag

  return (
    <div className='document-upload'>
      <div
        className={'document-upload-area' + stylingUploadArea}
        {...getRootProps()}
      >
        <Flex
          direction='vertical'
          verticalAlignment='center'
          horizontalAlignment='center'
          className='document-content-area'
        >
          {props.uploadedFiles.length > 0 ? (
            props.uploadedFiles.map((upload, index) => (
              <UploadedDocumentCard
                key={`${upload.file.name}-${upload.file.lastModified}-${index}`}
                upload={upload}
                editUpload={(changes) => props.editUpload(index, changes)}
                removeFile={() => props.removeFile(upload.file)}
                hasContact={false}
              />
            ))
          ) : (
            <Flex
              direction='vertical'
              className='upload-area-empty-state'
              verticalAlignment='center'
              horizontalAlignment='center'
            >
              {emptyStateText}
            </Flex>
          )}
        </Flex>
        <input {...getInputProps()} />
      </div>
      {props.uploadedFiles.length > 0 && (
        <Flex gap='tiny' className='actions'>
          {props.onDismiss && (
            <Button onClick={props.onDismiss}>Verwerfen</Button>
          )}
          <Button
            variant='primary'
            iconLeft='check'
            isLoading={props.isUploading}
            onClick={() => {
              props.saveDocuments(props.uploadedFiles)
              intercom.trackEvent('document-uploaded')
              MixpanelAnalytics.event('Document uploaded')
            }}
          >
            {props.uploadedFiles.length > 1
              ? 'Belege speichern'
              : 'Beleg speichern'}
          </Button>
        </Flex>
      )}
    </div>
  )
}

export default DocumentUploadArea
