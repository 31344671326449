import {
  Font,
  INewTemplate,
} from '@nextbusiness/infinity-finance-api/dist/models/ITemplate'
import { Button, Dialog, Text } from '@nextbusiness/infinity-ui'
import UploadArea from 'components/upload-area/UploadArea'
import { Upload } from 'documents/document-creator/DocumentUpload'
import { observer } from 'mobx-react'
import { useState } from 'react'
import SettingsGroup from 'settings/general/SettingsGroup'
import TemplateInspectorTypefacePicker from 'settings/templates/inspector/appearance/TemplateInspectorTypefacePicker'
import TemplatePreview from 'settings/templates/preview/TemplatePreview'
import { useEnter } from 'utility/hooks'
import './SetupModalDesign.scss'

interface SetupModalDesignProps {
  isOpen: boolean
  template: INewTemplate | undefined
  onPreviousStep: () => void
  onFinish: (updatedTemplate: INewTemplate, logo?: File) => void
  isFinishing: boolean
}

const SetupModalDesign = (props: SetupModalDesignProps) => {
  const [font, setFont] = useState<Font>('default')

  const [logo, setLogo] = useState<File>()
  const [logoPreview, setLogoPreview] = useState<string>()
  const [showLogoTypeError, setShowLogoTypeError] = useState<boolean>(false)

  const onContinue = () => {
    if (!props.template) return
    props.onFinish({ ...props.template, font }, logo)
  }
  useEnter(onContinue, props.isOpen)

  const onUploadSelected = (upload: Upload) => {
    if (
      !upload.file.type.includes('image/png') &&
      !upload.file.type.includes('image/jpeg') &&
      !upload.file.type.includes('image/jpg')
    ) {
      setShowLogoTypeError(true)
      return
    }
    setLogo(upload.file)
    setLogoPreview(URL.createObjectURL(upload.file))
    setShowLogoTypeError(false)
  }

  const resetLogoSelection = () => {
    setLogo(undefined)
    setLogoPreview(undefined)
    setShowLogoTypeError(false)
  }

  return (
    <Dialog
      isOpen={props.isOpen}
      className='customer-invoice-editor-setup-modal modal-design'
      title='Design anpassen'
      titleProps={{ divider: true }}
      actions={[
        { children: 'Zurück', onClick: props.onPreviousStep },
        {
          children: 'Abschliessen',
          variant: 'primary',
          onClick: onContinue,
          isLoading: props.isFinishing,
        },
      ]}
    >
      <div className='design-fields'>
        <Text className='instruction'>
          Diese und weitere Optionen kannst du später jederzeit unter
          “Dokumentdesign” ändern.
        </Text>
        <SettingsGroup title='Logo hochladen (optional)'>
          <UploadArea
            icon='image'
            uploadItemKind='Logo'
            onUploadSelected={onUploadSelected}
            dropzoneOptions={{
              accept: { 'image/*': ['.png', '.jpeg', '.jpg'] },
            }}
            isEmpty={!logo}
          />
          {logo ? (
            <>
              <Text variant='subtle' className='logo-design-hint'>
                In den Designeinstellungen kannst du später die Grösse des Logos
                anpassen.
              </Text>
              <Button
                iconLeft='delete'
                variant='secondary'
                className='remove-logo-button'
                onClick={resetLogoSelection}
              >
                Logo entfernen
              </Button>
            </>
          ) : (
            <>
              {showLogoTypeError ? (
                <Text variant='error'>
                  Nur Bilddateien im Format .png, .jpeg oder .jpg sind erlaubt.
                </Text>
              ) : (
                <Text variant='subtle'>
                  Es werden .png, .jpeg und .jpg Dateien unterstützt.
                </Text>
              )}
            </>
          )}
        </SettingsGroup>
        <SettingsGroup title='Schrift wählen'>
          <TemplateInspectorTypefacePicker
            selectedFont={font}
            onFontChange={setFont}
          />
        </SettingsGroup>
      </div>
      {props.template && (
        <div className='design-preview'>
          <TemplatePreview
            template={{ ...props.template, font, logo: logoPreview }}
          />
        </div>
      )}
    </Dialog>
  )
}

export default observer(SetupModalDesign)
