import { DocumentPageProps } from '../DocumentPage'
import DocumentElementGradient from '../elements/DocumentElementGradient'
import './DocumentPageFooter.scss'

const DocumentPageFooter = (props: DocumentPageProps) => (
  <div className='document-page-footer'>
    <DocumentElementGradient {...props} />
  </div>
)

export default DocumentPageFooter
