import InvoiceListItemContainer from 'invoices/shared-components/regular-invoice-list/InvoiceListItemContainer'
import InvoiceListItemDepiction from 'invoices/shared-components/regular-invoice-list/InvoiceListItemDepiction'
import InvoiceListItemOpeningDate from 'invoices/shared-components/regular-invoice-list/InvoiceListItemOpeningDate'
import InvoiceListItemTotalAmount from 'invoices/shared-components/regular-invoice-list/InvoiceListItemTotalAmount'
import { observer } from 'mobx-react-lite'
import { IVendorInvoice } from '../../../model/VendorInvoice'
import InvoiceStatus from '../../shared-components/InvoiceStatus'
import ExtendedVendorInvoiceIcon from '../invoice-icons/ExtendedVendorInvoiceIcon'

interface VendorInvoiceListItemProps {
  invoice: IVendorInvoice
  onClick?: () => void
}

const VendorInvoiceListItem = (props: VendorInvoiceListItemProps) => {
  return (
    <InvoiceListItemContainer onClick={props.onClick}>
      <ExtendedVendorInvoiceIcon invoice={props.invoice} small />
      <InvoiceListItemDepiction
        contactId={props.invoice.creditor}
        title={props.invoice.title}
      />
      <InvoiceStatus
        dueDate={props.invoice.dueDate}
        isPaid={props.invoice.isPaid}
      />
      <InvoiceListItemOpeningDate openingDate={props.invoice.openingDate} />
      <InvoiceListItemTotalAmount totalAmount={props.invoice.totalAmount} />
    </InvoiceListItemContainer>
  )
}

export default observer(VendorInvoiceListItem)
