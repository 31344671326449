import { Button, Tooltip } from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import Account from 'components/transactions/Account'
import { IAccount } from 'model/Account'
import Utilities from 'utility/Utilities'

interface ChartOfAccountsListItemProps {
  account: IAccount
  onEdit: () => void
  onDelete: () => void
  onOpen: () => void
}

const ChartOfAccountsListItem = ({
  account,
  onEdit,
  onDelete,
  onOpen,
}: ChartOfAccountsListItemProps) => (
  <div
    className={classNames('account-row', {
      'system-account': account.isSystemAccount,
    })}
  >
    <div className='column column-number'>{account.accountNumber}</div>
    <div className='column column-description'>
      <Button variant='quaternary' onClick={onOpen}>
        <Account accountNumber={account.accountNumber} disableTrunaction />
      </Button>
      {account.isSystemAccount && (
        <Tooltip content='Systemkonten können nicht bearbeitet oder gelöscht werden.'>
          <span className='system-account-notice'>
            <Icon icon='lock' size={IconSize.Tiny} />
            Systemkonto
          </span>
        </Tooltip>
      )}
    </div>
    <div className='column column-amount'>
      <span className='amount'>
        {account.balance ? Utilities.centsToCHF(account.balance) : '–'}
      </span>
      <span className='currency'>CHF</span>
    </div>
    <div className='column column-actions'>
      <Button
        iconOnly='edit'
        variant='quaternary'
        onClick={onEdit}
        tooltip='Konto bearbeiten'
        disabled={account.isSystemAccount}
      />
      <Button
        iconOnly='delete'
        variant='quaternary'
        onClick={onDelete}
        tooltip='Konto löschen'
        disabled={account.isSystemAccount}
      />
    </div>
  </div>
)

export default ChartOfAccountsListItem
