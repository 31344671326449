import { Checkbox } from '@nextbusiness/infinity-ui'
import React from 'react'
import './FeatureOption.scss'

interface FeatureOptionProps {
  icon: React.ReactNode
  label: string
  description: string
  isChecked: boolean | undefined
  onChange: (isChecked: boolean) => void
}

const FeatureOption = (props: FeatureOptionProps) => (
  <div className={'feature-option' + (props.isChecked ? ' active' : '')}>
    <Checkbox
      isChecked={props.isChecked}
      onChange={(isChecked) => props.onChange(isChecked)}
    />
    <div className='icon'>{props.icon}</div>
    <div className='depiction'>
      <span className='title'>{props.label}</span>
      <span className='description'>{props.description}</span>
    </div>
  </div>
)

export default FeatureOption
