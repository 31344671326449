import { Button, Spacer } from '@nextbusiness/infinity-ui'
import MagicSheetTitleBar from '../../components/magic-sheet/MagicSheetTitleBar'

interface VendorInvoicesHeaderProps {
  openCreateInvoiceModal: () => void
}

const VendorInvoicesHeader = (props: VendorInvoicesHeaderProps) => {
  return (
    <MagicSheetTitleBar title='Rechnungen an mich'>
      <Spacer size='tiny' direction='horizontal' />
      <Button
        iconLeft='plus'
        variant='primary'
        onClick={() => props.openCreateInvoiceModal()}
      >
        Neue Rechnung
      </Button>
    </MagicSheetTitleBar>
  )
}

export default VendorInvoicesHeader
