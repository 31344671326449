import { Flex, Text } from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import { CustomerInvoiceEditorSidebarProps } from '../CustomerInvoiceEditorSidebar'

const DocumentSidebarNoEditMessage = (
  props: CustomerInvoiceEditorSidebarProps
) => {
  const { accountStore } = useRootStore()

  const hasPayments = props.existingInvoice?.paymentTransactions.length
  const isFromClosedFiscalYear =
    props.document?.openingDate &&
    !accountStore.isDateWithinOpenFiscalYear(
      new Date(props.document.openingDate)
    )

  if (props.existingInvoice?.isDraft) return null

  return hasPayments ? (
    <Flex className='no-edit-document' verticalAlignment='center' gap='small'>
      <Icon icon='no-edit' size={IconSize.Small} />
      <Text type='inline'>
        Forderungen mit Zahlungen können nicht bearbeitet werden.
      </Text>
    </Flex>
  ) : isFromClosedFiscalYear ? (
    <Flex className='no-edit-document' verticalAlignment='center' gap='small'>
      <Icon icon='no-edit' size={IconSize.Small} />
      <Text type='inline'>
        Forderungen aus geschlossenen Geschäftsjahren können nicht bearbeitet
        werden.
      </Text>
    </Flex>
  ) : null
}

export default observer(DocumentSidebarNoEditMessage)
