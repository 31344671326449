import { VATFormGroupTaxCalculationProps } from './VATFormGroupTaxCalculation'
import VATFormHeader1 from './VATFormHeader1'
import VATFormHeader2 from './VATFormHeader2'
import VATFormRow from './VATFormRow'

const VATFormGroupTaxEra2018 = (props: VATFormGroupTaxCalculationProps) => (
  <>
    <VATFormHeader1 title='II. Steuerberechnung' />
    <VATFormHeader2
      title='Entgelte'
      digitColumn1='Ziffer'
      valueColumn1='Leistungen'
      valueColumn2='Steuer'
      digitColumn2='Satz'
    />
    <VATFormRow
      title='Leistungen zum Normalsatz'
      digitColumn1='302'
      value1={props.report['302'].amount}
      value2={Math.round(props.report['302'].amount * 0.077)}
      value2Sign='plus'
      digitColumn2='7.7%'
    />
    <VATFormRow
      title='Leistungen zum reduzierten Satz'
      digitColumn1='312'
      value1={props.report['312'].amount}
      value2={Math.round(props.report['312'].amount * 0.025)}
      value2Sign='plus'
      digitColumn2='2.5%'
    />
    <VATFormRow
      title='Leistungen zum Beherbergungssatz'
      digitColumn1='342'
      value1={props.report['342'].amount}
      value2={Math.round(props.report['342'].amount * 0.037)}
      value2Sign='plus'
      digitColumn2='3.7%'
    />
    <VATFormRow
      title='Steuerbarer Gesamtumsatz (wie Ziff. 299)'
      digitColumn1='379'
      value1={props.report['379'].amount}
      value1Sign='equals'
    />
    <VATFormHeader2
      title='Bezugsteuer'
      digitColumn1='Ziffer'
      valueColumn1='Leistungen'
      valueColumn2='Steuer'
    />
    <VATFormRow
      title='Bezugsteuer (netto, exkl. MWST)'
      digitColumn1='382'
      value1={props.report['382'].amount}
      value2={Math.round(props.report['382'].amount * 0.077)}
      value2Sign='plus'
    />
    <VATFormRow
      title='Total geschuldete Steuer (Ziff. 301 bis 382)'
      digitColumn1='399'
      value2={props.report['399'].amount}
      value2Sign='equals'
    />
  </>
)

export default VATFormGroupTaxEra2018
