import { Text } from '@nextbusiness/infinity-ui'
import { DateTime } from 'luxon'
import DateUtilities from 'utility/DateUtilites'
import './InvoiceListItemOpeningDate.scss'

interface InvoiceListItemOpeningDateProps {
  openingDate: string | number | undefined
}

const InvoiceListItemOpeningDate = (props: InvoiceListItemOpeningDateProps) => {
  const openingDate = props.openingDate
    ? (typeof props.openingDate === 'string' //TODO: fix when backend response is fixed
        ? DateTime.fromISO(props.openingDate)
        : DateTime.fromMillis(props.openingDate)
      ).endOf('day')
    : undefined

  return (
    <Text type='inline' className='opening-date'>
      {openingDate
        ? DateUtilities.humanFormatNumeric(openingDate.toMillis())
        : '–'}
    </Text>
  )
}

export default InvoiceListItemOpeningDate
