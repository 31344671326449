import { IProduct } from '@nextbusiness/infinity-finance-api'
import { useState } from 'react'
import EditProductModal from './EditProductModal'
import ProductListItem from './ProductListItem'
import { ProductsListProps } from './ProductsList'

interface ProductsListItemsProps extends ProductsListProps {
  listedProducts: IProduct[]
}

const ProductsListItems = (props: ProductsListItemsProps) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [productBeingEdited, setProductBeingEdited] = useState<IProduct>()

  return (
    <>
      {props.listedProducts.map((product) => (
        <ProductListItem
          key={product.id}
          product={product}
          isSelectable={props.isSelectable}
          isChecked={props.selectedProduct?.id === product.id}
          onOptionSelected={() => props.onProductSelected?.(product)}
          onEdit={() => {
            setProductBeingEdited(product)
            setIsEditModalOpen(true)
          }}
        />
      ))}
      {productBeingEdited && (
        <EditProductModal
          isOpen={isEditModalOpen}
          onDismiss={() => setIsEditModalOpen(false)}
          product={productBeingEdited}
        />
      )}
    </>
  )
}

export default ProductsListItems
