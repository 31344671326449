import { Dialog } from '@nextbusiness/infinity-ui'

interface AbortEditDialogProps {
  onAbort: () => void
  onCancel: () => void
  isAbortingEdit: boolean
}

const AbortEditDialog = (props: AbortEditDialogProps) => {
  return (
    <Dialog
      title='Änderungen verwerfen?'
      icon='warning'
      initialFocusIndex={0}
      isOpen={props.isAbortingEdit}
      actions={[
        {
          children: 'Abbrechen',
          variant: 'tertiary',
          onClick: () => {
            props.onCancel()
          },
        },
        {
          children: 'Fortfahren',
          variant: 'destructive',
          onClick: () => {
            props.onAbort()
            props.onCancel()
          },
        },
      ]}
    >
      Wenn du fortfährst, werden deine bisherigen Änderungen verworfen.
    </Dialog>
  )
}

export default AbortEditDialog
