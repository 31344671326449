import { Flex } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import { useInitialSetupContext } from './InitialSetupContext'
import InitialSetupTransitionGroup from './InitialSetupTransitionGroup'
import './OnboardingCard.scss'

interface OnboardingCardProps {
  icon?: React.ReactNode
  title?: React.ReactNode
  subtitle?: React.ReactNode
  children?: React.ReactNode
  actions?: React.ReactNode
}

const OnboardingCard = (props: OnboardingCardProps) => {
  const { isOnboardingCardExpanded } = useInitialSetupContext()
  return (
    <Flex
      fillContainer
      horizontalAlignment='center'
      className='onboarding-screen'
    >
      <div
        className={classNames('onboarding-container', {
          expanded: isOnboardingCardExpanded,
        })}
      >
        <InitialSetupTransitionGroup>
          <div className='onboarding-card-header'>
            {props.icon && (
              <div className='onboarding-card-icon'>{props.icon}</div>
            )}
            {props.title && (
              <h1 className='onboarding-card-title'>{props.title}</h1>
            )}
            {props.subtitle && (
              <p className='onboarding-card-subtitle'>{props.subtitle}</p>
            )}
          </div>
        </InitialSetupTransitionGroup>
        <InitialSetupTransitionGroup staggerDelay={0.2}>
          <div className='onboarding-card-content'>{props.children}</div>
        </InitialSetupTransitionGroup>
        <InitialSetupTransitionGroup staggerDelay={0.4}>
          <div className='onboarding-card-actions'>{props.actions}</div>
        </InitialSetupTransitionGroup>
      </div>
    </Flex>
  )
}

export default OnboardingCard
