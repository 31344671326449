import CommonApplicationFrame from './CommonApplicationFrame'

interface MobileApplicationFrameProps {
  children: React.ReactNode
}

const MobileApplicationFrame = (props: MobileApplicationFrameProps) => (
  <CommonApplicationFrame
    overrideIdentifier='finance-mobile'
    primaryNavigation={[
      { path: '/dokumente', description: 'Dokumente' },
      { path: '/mehr', description: 'Mehr' },
    ]}
  >
    {props.children}
  </CommonApplicationFrame>
)

export default MobileApplicationFrame
