import { Icon, IconItem, IconSize } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import './IconSection.scss'

interface IconSectionProps {
  icon: IconItem
  children?: React.ReactNode
  className?: string
}

const IconSection = (props: IconSectionProps) => (
  <div className={classNames('icon-section', props.className)}>
    <div className='icon-section-icon'>
      <Icon icon={props.icon} size={IconSize.Medium} />
    </div>
    <div className='icon-section-depiction'>{props.children}</div>
  </div>
)

export default IconSection
