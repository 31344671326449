import { Button, Flex, NonIdealState, Spacer } from '@nextbusiness/infinity-ui'
import { IconSize } from '@nextbusiness/infinity-ui-icons'
import ErrorScreen from 'base-components/layout/ErrorScreen'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import { observer } from 'mobx-react'
import IDocument from 'model/Document'
import { useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import AttachDocumentFromInboxRadioGroup from './AttachDocumentFromInboxRadioGroup'

interface AttachDocumentFromInboxListProps {
  onTransactionSelected: (document: IDocument) => void
  isAttaching: boolean
}

const AttachDocumentFromInboxList = (
  props: AttachDocumentFromInboxListProps
) => {
  const { documentStore } = useRootStore()
  const [selectedDocument, setSelectedDocument] = useState<IDocument | null>(
    null
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loadingError, setLoadingError] = useState<Error>()

  const loadDocuments = async () => {
    setIsLoading(true)
    setLoadingError(undefined)
    try {
      await documentStore.loadAllDocuments()
    } catch (error) {
      setLoadingError(error as Error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadDocuments()
  }, [])

  const inboxDocuments = documentStore.inboxDocuments

  return (
    <>
      <div className='attach-document-list'>
        {isLoading && !inboxDocuments?.length ? (
          <LoadingScreen />
        ) : loadingError ? (
          <ErrorScreen
            error={loadingError}
            title='Belege in Inbox konnten nicht geladen werden.'
            onRetry={loadDocuments}
          />
        ) : !inboxDocuments?.length ? (
          <NonIdealState icon='select-none' iconSize={IconSize.Small}>
            Keine neuen Belege in der Inbox
          </NonIdealState>
        ) : (
          <AttachDocumentFromInboxRadioGroup
            selectedDocument={selectedDocument}
            onDocumentSelected={setSelectedDocument}
          />
        )}
      </div>
      <Spacer size='small' />
      <Flex horizontalAlignment='flex-end'>
        <Button
          disabled={!selectedDocument}
          variant='primary'
          onClick={() => props.onTransactionSelected(selectedDocument!)}
          isLoading={props.isAttaching}
        >
          Anhängen
        </Button>
      </Flex>
    </>
  )
}

export default observer(AttachDocumentFromInboxList)
