import { Button, Checkbox, Flex } from '@nextbusiness/infinity-ui'
import CreateAccountAssistant from 'ledger/accounts/account-creation/CreateAccountAssistant'
import { useState } from 'react'
import BatchAccountCreationModal from './BatchAccountCreationModal'

interface ChartOfAccountsActionsProps {
  showAllAccounts: boolean
  setShowAllAccounts: (showAllAccounts: boolean) => void
}

const ChartOfAccountsActions = (props: ChartOfAccountsActionsProps) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false)
  const [isBatchCreateModalOpen, setIsBatchCreateModalOpen] =
    useState<boolean>(false)

  return (
    <Flex
      gap='tiny'
      className='chart-of-accounts-actions'
      verticalAlignment='center'
    >
      <Button
        iconLeft='plus'
        variant='primary'
        onClick={() => setIsCreateModalOpen(true)}
      >
        Neues Konto
      </Button>
      <Button
        iconLeft='numbers-input-form'
        onClick={() => setIsBatchCreateModalOpen(true)}
        tooltip='Erstelle Konten nach ihrer Kontonummer. Nützlich, um einen bisherigen Kontenplan zu übernehmen.'
      >
        Mit Nummern eintragen
      </Button>
      <div style={{ flexGrow: 1 }} />
      <Checkbox
        label='Systemkonten anzeigen'
        isChecked={props.showAllAccounts}
        onChange={props.setShowAllAccounts}
      />
      <CreateAccountAssistant
        isOpen={isCreateModalOpen}
        closeModal={() => setIsCreateModalOpen(false)}
      />
      <BatchAccountCreationModal
        isOpen={isBatchCreateModalOpen}
        onDismiss={() => setIsBatchCreateModalOpen(false)}
      />
    </Flex>
  )
}

export default ChartOfAccountsActions
