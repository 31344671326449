export interface SpecialAccount {
  name: string
  accountNumber: number
  colour?: string
  viewingDisabled?: boolean
}

class SpecialAccounts {
  private static readonly SPECIAL_ACCOUNTS: SpecialAccount[] = [
    {
      name: 'Nachlass',
      accountNumber: 3800,
    },
    {
      name: 'Eröffnung',
      accountNumber: 9100,
      colour: '#6F1DC3',
    },
    {
      name: 'Unverbuchter Gewinn',
      accountNumber: 9200,
      colour: '#6F1DC3',
    },
    {
      name: 'Konto wählen',
      accountNumber: 0,
      colour: '#bbc5cb',
      viewingDisabled: true,
    },
  ]

  public static find(accountNumber?: number): SpecialAccount | undefined {
    return SpecialAccounts.SPECIAL_ACCOUNTS.find(
      (account) => account.accountNumber === accountNumber
    )
  }
}

export default SpecialAccounts
