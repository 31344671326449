import {
  Button,
  ErrorBoundary,
  Flex,
  LoadingIndicator,
  NonIdealState,
} from '@nextbusiness/infinity-ui'
import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import { observer } from 'mobx-react'
import { IBalanceSheet } from 'model/Report'
import FinancialClose from 'networking/FinancialClose'
import DocumentsPage from 'pages/DocumentsPage'
import { useEffect, useState } from 'react'
import BalanceSheet from 'reports/balance-sheet/BalanceSheet'
import { useRootStore } from 'stores/RootStoreContext'
import './ClosedFiscalYearBalanceSheet.scss'

interface ClosedFiscalYearBalanceSheetProps {
  page: DocumentsPage
  afterProfitClose?: boolean
}

const ClosedFiscalYearBalanceSheet = (
  props: ClosedFiscalYearBalanceSheetProps
) => {
  const { authenticationStore, accountStore } = useRootStore()

  const year = props.page.currentParam
    ? parseInt(props.page.currentParam)
    : null
  const fiscalYear = accountStore.allFiscalYears?.find((fy) => fy.year === year)
  const balanceSheetDate = fiscalYear ? new Date(fiscalYear?.to) : null

  const [balanceSheet, setBalanceSheet] = useState<IBalanceSheet>()
  const [error, setError] = useState<Error>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const loadBalanceSheet = async () => {
    if (!fiscalYear) {
      setError(new Error('Geschäftsjahr nicht gefunden.'))
      return
    }
    try {
      setIsLoading(true)
      const fetchedBalanceSheet = await FinancialClose.getPastBalanceSheet(
        authenticationStore.organisationIdentifier,
        fiscalYear.year,
        props.afterProfitClose === true
      )
      setBalanceSheet(fetchedBalanceSheet)
    } catch (error) {
      setError(error as Error)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    loadBalanceSheet()
  }, [year])

  return (
    <MagicSheetPage
      containerClassName='balance-sheet-page closed-fiscal-year-balance-sheet'
      noTrailingNegativeSpace
      header={
        <MagicSheetTitleBar
          title={`Schlussbilanz ${props.afterProfitClose ? 2 : 1}`}
        >
          {!!balanceSheet && (
            <Button
              className='pdf-button'
              onClick={() => window.print()}
              iconLeft='print'
              tooltip='Schlussbilanz drucken oder als PDF abspeichern'
            >
              Drucken
            </Button>
          )}
        </MagicSheetTitleBar>
      }
    >
      <MagicSheetContainer>
        {isLoading ? (
          <Flex
            className='loading-indicator-wrapper'
            verticalAlignment='center'
            horizontalAlignment='center'
            fillContainer
          >
            <LoadingIndicator />
          </Flex>
        ) : !!balanceSheet && !!balanceSheetDate ? (
          <ErrorBoundary
            errorView={
              <NonIdealState>
                Beim Anzeigen der Bilanz ist ein Fehler aufgetreten.
              </NonIdealState>
            }
          >
            <BalanceSheet
              balanceSheet={balanceSheet}
              date={balanceSheetDate}
              dateCopy={balanceSheetDate}
            />
          </ErrorBoundary>
        ) : (
          error && (
            <Flex
              className='error-wrapper'
              verticalAlignment='center'
              horizontalAlignment='center'
              fillContainer
            >
              <NonIdealState icon='error'>{error.message}</NonIdealState>
            </Flex>
          )
        )}
      </MagicSheetContainer>
    </MagicSheetPage>
  )
}

export default observer(ClosedFiscalYearBalanceSheet)
