import { Button, ButtonVariant } from '@nextbusiness/infinity-ui'
import { Icon, IconItem } from '@nextbusiness/infinity-ui-icons'
import { ReactComponent as HighlightArrow } from 'assets/highlight-arrow.svg'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import './OnboardingSuggestion.scss'

interface OnboardingSuggestionProps {
  highlighted?: boolean
  icon: IconItem
  tint?: 'default' | 'teal' | 'magenta' | 'lavender'
  variant?: ButtonVariant
  title: string
  description: string
  actionText: string
  onActionClick: () => void
  onMouseOver?: () => void
  done?: boolean
}

const OnboardingSuggestion = (props: OnboardingSuggestionProps) => (
  <div
    className={classNames('onboarding-suggestion', {
      highlighted: props.highlighted,
    })}
    onMouseOver={props.onMouseOver}
    onFocus={props.onMouseOver}
  >
    <div className={classNames('icon', `tint--${props.tint ?? 'default'}`)}>
      <Icon icon={props.icon} tint={props.tint} size={32} />
    </div>
    <div className='depiction'>
      <h4>{props.title}</h4>
      <p>{props.description}</p>
    </div>
    <div className='action'>
      {props.done ? (
        <Button iconLeft='check' disabled>
          Erledigt
        </Button>
      ) : (
        <Button variant={props.variant} onClick={props.onActionClick}>
          {props.actionText}
        </Button>
      )}
    </div>
    <AnimatePresence>
      {props.highlighted && (
        <motion.div
          className='suggestion-highlight-arrow'
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <HighlightArrow />
        </motion.div>
      )}
    </AnimatePresence>
  </div>
)

export default OnboardingSuggestion
