import {
  Finance,
  ICurrency,
  INewCurrency,
} from '@nextbusiness/infinity-finance-api'
import {
  Dialog,
  Flex,
  InputField,
  NumberField,
  RadioGroup,
  Text,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import SettingsGroup from 'settings/general/SettingsGroup'
import { useRootStore } from 'stores/RootStoreContext'
import './EditOrCreateCurrencyModal.scss'

interface EditOrCreateCurrencyModalProps {
  currencyToBeEdited?: ICurrency
  titleOfModal: string
  saveActionCopy: string
  onSave: (currencyChanges: INewCurrency) => Promise<boolean>
  isOpen: boolean
  onDismiss: () => void
}

const EditOrCreateCurrencyModal = (props: EditOrCreateCurrencyModalProps) => {
  const { currencyStore } = useRootStore()
  const isCreatingNewCurrency = !props.currencyToBeEdited

  const [currencyCode, setCurrencyCode] = useState<string>(
    props.currencyToBeEdited?.currencyCode ?? ''
  )
  const [currencyName, setCurrencyName] = useState<string>(
    props.currencyToBeEdited?.currencyName ?? ''
  )
  const [currencyRate, setCurrencyRate] = useState<number>(
    props.currencyToBeEdited?.exchangeRate ?? 1
  )
  const [currencyBasisUnit, setCurrencyBasisUnit] = useState<number>(
    props.currencyToBeEdited?.basisUnit ?? 1
  )
  const [isEstvCompatible, setIsEstvCompatible] = useState<boolean>(
    !isCreatingNewCurrency
      ? props.currencyToBeEdited?.syncAutomatically ?? false
      : false
  )
  const [syncAutomatically, setSyncAutomatically] = useState<boolean>(
    props.currencyToBeEdited?.syncAutomatically ?? false
  )

  const fetchNameFromCode = async () => {
    try {
      const knownCurrency = await Finance.Currencies.knownCurrencyWithCode(
        currencyCode
      )
      if (!knownCurrency) {
        setIsEstvCompatible(false)
        return
      }
      setIsEstvCompatible(true)
      if (isCreatingNewCurrency) {
        setCurrencyName(knownCurrency.currencyName)
        setSyncAutomatically(true)
      }
    } catch (error) {
      console.log(error)
      // Fail silently
    }
  }

  useEffect(() => {
    if (currencyCode.length !== 3) {
      if (isEstvCompatible) {
        setCurrencyName('')
        setIsEstvCompatible(false)
      }
      return
    }
    fetchNameFromCode()
  }, [currencyCode])

  useEffect(() => {
    if (syncAutomatically && !isEstvCompatible) {
      setSyncAutomatically(false)
    }
  }, [syncAutomatically, isEstvCompatible])

  const [isAdding, setIsAdding] = useState<boolean>(false)

  const commitChange = async () => {
    let isCommitted = false
    if (syncAutomatically) {
      setIsAdding(true)
      isCommitted = await props.onSave({
        currencyCode,
        currencyName,
        syncAutomatically: true,
      })
      setIsAdding(false)
    } else {
      setIsAdding(true)
      isCommitted = await props.onSave({
        currencyCode,
        currencyName,
        syncAutomatically: false,
        exchangeRate: currencyRate,
        basisUnit: currencyBasisUnit,
      })
      setIsAdding(false)
    }
    if (isCommitted) {
      props.onDismiss()
      await currencyStore.loadCurrencies()
    }
  }

  const canSaveChanges = () => {
    if (currencyCode === 'CHF') return false
    if (isNotUniqueCurrencyCode()) return false
    if (currencyCode.length !== 3) return false
    if (currencyName.length === 0) return false
    if (
      !syncAutomatically &&
      (currencyRate === 0 || currencyRate === undefined)
    )
      return false
    return (
      syncAutomatically ||
      !(currencyBasisUnit === 0 && currencyBasisUnit === undefined)
    )
  }

  const isNotUniqueCurrencyCode = () => {
    const existingCurrencies = currencyStore.currencies
    const currencyCodeExists = existingCurrencies?.find(
      (currency) => currency.currencyCode === currencyCode
    )
    return currencyCodeExists && isCreatingNewCurrency
  }

  return (
    <Dialog
      className='edit-or-create-currency-modal'
      title={props.titleOfModal}
      titleProps={{ divider: true }}
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      actions={[
        {
          children: 'Abbrechen',
          onClick: props.onDismiss,
        },
        {
          children: props.saveActionCopy,
          disabled: !canSaveChanges(),
          isLoading: isAdding,
          onClick: () => {
            commitChange()
          },
          variant: 'primary',
        },
      ]}
    >
      <Flex direction='vertical' gap={0.5}>
        {isCreatingNewCurrency && (
          <Text className='label' type='inline'>
            Welche Währung möchtest du hinzufügen?
          </Text>
        )}
        <Flex className='currency-selection' direction='horizontal'>
          <InputField
            className='input-field-currency-code'
            value={currencyCode}
            onChange={(code) => {
              if (code.length > 3) return
              setCurrencyCode(code.toUpperCase())
            }}
            placeholder='Währungscode'
            fullWidth
            disabled={!isCreatingNewCurrency}
          />
          <InputField
            disabled={isEstvCompatible}
            className='input-field-currency-name'
            value={currencyName}
            onChange={(title) => {
              if (title.length > 56) return
              setCurrencyName(title)
            }}
            placeholder='Name der Währung'
            fullWidth
          />
        </Flex>
        {currencyCode === 'CHF' && (
          <Text variant='error' type='inline'>
            Die Hauptwährung deiner Buchhaltung kann nicht als Fremdwährung
            hinzugefügt werden.
          </Text>
        )}
        {isNotUniqueCurrencyCode() && (
          <Text variant='error' type='inline'>
            Es besteht bereits eine Währung mit diesem Währungscode.
          </Text>
        )}
        <SettingsGroup title='Wechselkurs' compact>
          <Text className='label' type='inline'>
            Welcher Wechselkurs soll verwendet werden, wenn du diese Währung
            verwendest?
          </Text>
          <RadioGroup
            value={syncAutomatically}
            onChange={(type) => setSyncAutomatically(type)}
            options={[
              {
                isDisabled: !isEstvCompatible,
                label: `ESTV-Monatsmittelkurs verwenden ${
                  isEstvCompatible ? '(empfohlen)' : '(nicht verfügbar)'
                }`,
                value: true,
              },
              { label: 'Wechselkurs manuell festlegen', value: false },
            ]}
          />
          {!syncAutomatically && (
            <div className='manual-rate-input'>
              <NumberField
                className='input-field-manual-rate'
                value={currencyBasisUnit}
                onChange={(basisUnit) => setCurrencyBasisUnit(basisUnit ?? 0)}
                fullWidth
                unit={currencyCode}
                decimalPlaces={5}
              />
              <Text className='manual-rate-equal' type='inline'>
                =
              </Text>
              <NumberField
                className='input-field-manual-rate'
                value={currencyRate}
                onChange={(rate) => setCurrencyRate(rate ?? 0)}
                decimalPlaces={5}
                unit={'CHF'}
                fullWidth
              />
            </div>
          )}
        </SettingsGroup>
      </Flex>
    </Dialog>
  )
}

export default observer(EditOrCreateCurrencyModal)
