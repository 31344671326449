import { IBalanceSheet } from 'model/Report'
import ClosingBalances from 'model/financial-close/ClosingBalances'
import { ClosingTransactionsPreview } from 'model/financial-close/ClosingTransactionPreview'
import IFinancialClose from 'model/financial-close/FinancialClose'
import PreCloseChecks from 'model/financial-close/PreCloseChecks'
import { Current } from './Authentication'
import NetworkingConstants from './NetworkingConstants'
import NetworkingErrorHandler from './NetworkingErrorHandler'

const getSettings = async (organisationId: string) => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationId}/financial-close`,
    { method: 'GET', headers: Current.defaultHeaders }
  )
  const body = await response.json()

  if (!response.ok || !body.financialClose) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.financialClose as IFinancialClose
}

const updateSettings = async (
  organisationId: string,
  changes: Partial<IFinancialClose>
) => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationId}/financial-close`,
    {
      method: 'PATCH',
      headers: Current.defaultHeaders,
      body: JSON.stringify(changes),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.financialClose) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.financialClose as IFinancialClose
}

const getPreCloseChecks = async (organisationId: string) => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationId}/financial-close/status`,
    { method: 'GET', headers: Current.defaultHeaders }
  )
  const body = await response.json()

  if (!response.ok || !body.preCloseCheckStatus) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.preCloseCheckStatus as PreCloseChecks
}

const previewClosingBalanceSheet = async (
  includeProfitClose: boolean,
  organisationId: string
) => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationId}/financial-close/preview/balance-sheet-${
        includeProfitClose ? 2 : 1
      }`,
    { method: 'POST', headers: Current.defaultHeaders }
  )
  const body = await response.json()

  if (!response.ok || !body.balanceSheet) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.balanceSheet as IBalanceSheet
}

const getClosingBalances = async (organisationId: string) => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationId}/financial-close/balances`,
    { method: 'GET', headers: Current.defaultHeaders }
  )
  const body = await response.json()

  if (!response.ok || !body.closingBalances) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.closingBalances as ClosingBalances
}

const getClosingTransactions = async (organisationId: string) => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationId}/financial-close/preview/transactions`,
    { method: 'POST', headers: Current.defaultHeaders }
  )
  const body = await response.json()

  if (!response.ok || !body.closingTransactionsPreview) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.closingTransactionsPreview as ClosingTransactionsPreview
}

const closeFiscalYear = async (organisationId: string) => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationId}/financial-close/close`,
    { method: 'POST', headers: Current.defaultHeaders }
  )
  const body = await response.json()

  if (!response.ok || !body.financialClose) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.financialClose as IFinancialClose
}

const getPastBalanceSheet = async (
  organisationIdentifier: string,
  year: number,
  afterProfitClose: boolean
): Promise<IBalanceSheet> => {
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationIdentifier}/fiscalyears/${year}/balance-sheet-${
        afterProfitClose ? 2 : 1
      }`,
    { method: 'GET', headers: Current.defaultHeaders }
  )
  const body = await response.json()

  if (!response.ok || !body.balanceSheet) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.balanceSheet as IBalanceSheet
}

const FinancialClose = {
  getSettings,
  updateSettings,
  getPreCloseChecks,
  previewClosingBalanceSheet,
  getClosingBalances,
  getClosingTransactions,
  closeFiscalYear,
  getPastBalanceSheet,
}

export default FinancialClose
