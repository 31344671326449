import classNames from 'classnames'
import { useAccountSheetContext } from './AccountSheetContext'

const AccountSheetContainer = (props: React.PropsWithChildren) => {
  const { isTransactionCreatorOpen } = useAccountSheetContext()
  return (
    <div
      className={classNames(
        'account-sheet',
        isTransactionCreatorOpen && 'is-posting-transaction'
      )}
    >
      {props.children}
    </div>
  )
}

export default AccountSheetContainer
