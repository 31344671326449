import {
  Finance,
  ICompleteDocument,
  IDocument,
  IDocumentAnalysis,
} from '@nextbusiness/infinity-finance-api'
import { Button, Flex } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { useAPIAction } from 'utility/hooks/useAPIAction'
import { useTransactionEditorContext } from '../../TransactionEditorContext'
import './LiveCaptureAttachment.scss'
import LiveCaptureAttachmentPreview from './LiveCaptureAttachmentPreview'

type AnalysedDocument = IDocument & {
  analysis: IDocumentAnalysis
}

type CompleteAnalysedDocument = ICompleteDocument<IDocument> & {
  document: AnalysedDocument
}

export interface LiveCaptureAttachmentChildProps {
  document: CompleteAnalysedDocument
}

const LiveCaptureAttachment = () => {
  const { transactionStore } = useRootStore()
  const { originalTransaction, canMakeChanges } = useTransactionEditorContext()
  const firstAttachmentId = originalTransaction.attachments?.[0]

  const [document, setDocument] = useState<ICompleteDocument>()
  const [isLoadingDocument, setIsLoadingDocument] = useState<boolean>(false)

  const unfileDocument = useAPIAction(
    () => Finance.Documents.unfileDocument(document!.document.id),
    {
      onSuccess: () =>
        transactionStore.refreshTransactions([originalTransaction.id]),
      successMessage: 'Beleg erfolgreich zurückgelegt',
      errorMessage: 'Beleg konnte nicht zurückgelegt werden',
    }
  )

  const loadAttachments = async () => {
    if (!firstAttachmentId) return
    try {
      setIsLoadingDocument(true)
      const loadedDocument = await Finance.Documents.document(firstAttachmentId)
      setDocument(loadedDocument)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingDocument(false)
    }
  }

  useEffect(() => {
    loadAttachments()
  }, [firstAttachmentId])

  return (
    <Flex
      className={classNames('live-capture-attachment', {
        loading: isLoadingDocument,
        empty: !document,
      })}
    >
      {document && (
        <>
          <LiveCaptureAttachmentPreview
            document={document as CompleteAnalysedDocument}
          />
          {canMakeChanges && (
            <>
              <div className='seperator' />
              <Button
                iconLeft='undo'
                className='detach-action'
                variant='quaternary'
                onClick={unfileDocument.perform}
                isLoading={unfileDocument.isLoading}
              >
                Beleg wieder zurücklegen
              </Button>
            </>
          )}
        </>
      )}
    </Flex>
  )
}

export default observer(LiveCaptureAttachment)
