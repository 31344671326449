import EditableDocumentText from '../../../controls/EditableDocumentText'
import { DocumentCustomProductPositionProps } from '../DocumentCustomProductPosition'
import PositionNameFieldKeyHandler from './PositionNameFieldKeyHandler'
import PositionProductSelectionAction from './PositionProductSelectionAction'

const PositionNameField = (props: DocumentCustomProductPositionProps) => {
  const isEmpty = !props.position.name

  const onKeyDown = (event: React.KeyboardEvent) => {
    const handler = new PositionNameFieldKeyHandler(event)
    const updatedText = handler.handleKeyDown(props.position.name ?? '')

    if (updatedText) props.onChange({ name: updatedText })
  }

  return (
    <div className='positions-column name'>
      <EditableDocumentText
        isMultiline
        placeholder='Beschreibung'
        value={props.position.name}
        onChange={(name) => props.onChange({ name })}
        onKeyDown={onKeyDown}
        isReadOnly={props.isReadOnly}
      />
      {isEmpty && <PositionProductSelectionAction {...props} />}
    </div>
  )
}

export default PositionNameField
