import React from 'react'
import ContentLoader from 'react-content-loader'

const LoaderFinancialHealthMaturities = () => (
  <ContentLoader
    speed={2}
    width={344}
    height={230}
    viewBox='0 0 344 230'
    backgroundColor='#f3f3f3'
    foregroundColor='#e5e5e5'
  >
    <rect x='0' y='13' rx='3' ry='3' width='90' height='16' />
    <rect x='106' y='13' rx='3' ry='3' width='90' height='16' />
    <rect x='1' y='61' rx='6' ry='6' width='47' height='49' />
    <rect x='1' y='138' rx='6' ry='6' width='47' height='49' />
    <rect x='64' y='63' rx='2' ry='2' width='180' height='16' />
    <rect x='64' y='88' rx='2' ry='2' width='93' height='16' />
    <rect x='65' y='141' rx='2' ry='2' width='180' height='16' />
    <rect x='64' y='165' rx='2' ry='2' width='93' height='16' />
  </ContentLoader>
)

export default LoaderFinancialHealthMaturities
