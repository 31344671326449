import { ICurrency } from '@nextbusiness/infinity-finance-api'
import { Button } from '@nextbusiness/infinity-ui'
import { IconSize } from '@nextbusiness/infinity-ui-icons'
import './CurrencyListItem.scss'
import CurrencyListItemDepiction from './CurrencyListItemDepiction'
import CurrencyListRate from './CurrencyListRate'

interface CurrencyListItemProps {
  currency: ICurrency
  onEdit: () => void
  onDelete: () => void
  isDeleting?: boolean
}

const CurrencyListItem = (props: CurrencyListItemProps) => {
  const { currency } = props
  return (
    <div className='currency-list-item'>
      <CurrencyListItemDepiction
        currencyCode={currency.currencyCode}
        currencyName={currency.currencyName}
      />
      <CurrencyListRate
        exchangeRate={currency.exchangeRate}
        basisUnit={currency.basisUnit}
        syncAutomatically={currency.syncAutomatically}
      />
      <Button
        variant='quaternary'
        iconOnly='edit'
        onClick={() => props.onEdit()}
      />
      <Button
        iconOnly='delete'
        variant='quaternary'
        iconSize={IconSize.Small}
        onClick={() => props.onDelete()}
        isLoading={props.isDeleting}
      />
    </div>
  )
}

export default CurrencyListItem
