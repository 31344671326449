import { observer } from 'mobx-react-lite'
import { IVendorInvoice } from '../../../model/VendorInvoice'
import ExtendedInvoiceListItemContainer from '../../shared-components/large-invoice-list/ExtendedInvoiceListItemContainer'
import ExtendedInvoiceListItemDepiction from '../../shared-components/large-invoice-list/ExtendedInvoiceListItemDepiction'
import ExtendedVendorInvoiceIcon from '../invoice-icons/ExtendedVendorInvoiceIcon'

interface UnpaidVendorInvoiceItemProps {
  invoice: IVendorInvoice
  onClick?: () => void
}

const UnpaidVendorInvoiceItem = (props: UnpaidVendorInvoiceItemProps) => {
  return (
    <ExtendedInvoiceListItemContainer onClick={props.onClick}>
      <ExtendedVendorInvoiceIcon invoice={props.invoice} showAmount />
      <ExtendedInvoiceListItemDepiction
        contactId={props.invoice.creditor}
        title={props.invoice.title}
      />
    </ExtendedInvoiceListItemContainer>
  )
}

export default observer(UnpaidVendorInvoiceItem)
