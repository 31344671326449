import { observer } from 'mobx-react'
import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { AnimatedRoute, AnimatedRouteProps } from 'react-router-transition'
import NotAuthenticatedPage from '../pages/NotAuthenticatedPage'
import { useRootStore } from '../stores/RootStoreContext'

interface AuthenticatedRouteProps extends RouteProps {
  redirectToIfUnauthenticated?: string
  animation?: AnimatedRouteProps
}

const AuthenticatedRoute = (routeProps: AuthenticatedRouteProps) => {
  const { authenticationStore } = useRootStore()
  const { children, redirectToIfUnauthenticated, ...rest } = routeProps

  const routeChildren = () => {
    return authenticationStore.isAuthenticated ? (
      children
    ) : redirectToIfUnauthenticated ? (
      <Redirect to={redirectToIfUnauthenticated} />
    ) : (
      <NotAuthenticatedPage />
    )
  }

  if (!authenticationStore.isHydrated) return null

  return routeProps.animation ? (
    <AnimatedRoute {...routeProps} {...routeProps.animation}>
      {routeChildren()}
    </AnimatedRoute>
  ) : (
    <Route {...rest}>{routeChildren()}</Route>
  )
}

export default observer(AuthenticatedRoute)
