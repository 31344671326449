import { ICompleteDocument } from '@nextbusiness/infinity-finance-api'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import ExpenditureCreateContextProvider from 'documents/expenditures/ExpenditureCreateContext'
import CreateExpenditureHeader from './CreateExpenditureHeader'
import CreateExpenditureSplitView from './CreateExpenditureSplitView'

interface CreateExpenditureScreenProps {
  document: ICompleteDocument
  onDismiss: () => void
}

const CreateExpenditureScreen = (props: CreateExpenditureScreenProps) => (
  <ExpenditureCreateContextProvider document={props.document}>
    <MagicSheetPage
      header={
        <CreateExpenditureHeader
          document={props.document}
          onDismiss={props.onDismiss}
        />
      }
      noTrailingNegativeSpace
      containerClassName='create-expenditure-view'
    >
      <CreateExpenditureSplitView document={props.document} />
    </MagicSheetPage>
  </ExpenditureCreateContextProvider>
)

export default CreateExpenditureScreen
