import { IIncomeStatement, IIncomeStatementGroup } from 'model/Report'
import { ProfitabilityVisualGroupPart } from './ProfitabilityVisualGroup'

export const groupWithNumber = (
  incomeStatement: IIncomeStatement,
  num: number
) => incomeStatement.groups.find((group) => group.number === num)!

export const partsForGroup = (
  group: IIncomeStatementGroup,
  granularity: 'subgroups' | 'accounts' = 'subgroups',
  isIncome = false
) => {
  const parts: ProfitabilityVisualGroupPart[] = []
  group.subgroups
    .filter((subgroup) => subgroup.balance !== 0)
    .forEach((subgroup) => {
      if (granularity === 'accounts') {
        parts.push(
          ...subgroup.accounts
            .filter((account) => account.balance !== 0)
            .map((account) => ({
              title: account.name,
              amount: account.balance * (isIncome ? 1 : -1),
            }))
        )
      } else {
        parts.push({
          title: subgroup.subgroupName,
          amount: subgroup.balance,
        })
      }
    })
  return parts
}
