import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import ICustomerInvoice, {
  ICustomerInvoicePreview,
} from '../../../model/CustomerInvoice'
import ContactSwatch from '../../shared-components/ContactSwatch'
import ExtendedInvoiceIconAmount from '../../shared-components/invoice-icon/ExtendedInvoiceIconAmount'
import ExtendedInvoiceIconContainer from '../../shared-components/invoice-icon/ExtendedInvoiceIconContainer'
import CustomerInvoiceIcon from './CustomerInvoiceIcon'

interface ExtendedCustomerInvoiceIconProps {
  invoice: ICustomerInvoice | ICustomerInvoicePreview
  showAmount?: boolean
  small?: boolean
  speciallyMarkQuotes?: boolean
}

const ExtendedCustomerInvoiceIcon = (
  props: ExtendedCustomerInvoiceIconProps
) => {
  const dueDate = DateTime.fromISO(props.invoice.dueDate)
  const overdueStart = dueDate.plus({ days: 1 })

  const isOverdue =
    !props.invoice.isQuote &&
    !props.invoice.isDraft &&
    !props.invoice.isPaid &&
    overdueStart.toMillis() <= Date.now()

  return (
    <ExtendedInvoiceIconContainer small={props.small}>
      <CustomerInvoiceIcon
        showOverdueStamp={isOverdue}
        showDraftStamp={props.invoice.isDraft}
        showQuoteStamp={props.speciallyMarkQuotes && props.invoice.isQuote}
        small={props.small}
      />
      {props.showAmount && !props.small && (
        <ExtendedInvoiceIconAmount totalAmount={props.invoice.totalAmount} />
      )}
      {props.invoice.recipient && (
        <ContactSwatch
          contactId={props.invoice.recipient}
          small={props.small}
        />
      )}
    </ExtendedInvoiceIconContainer>
  )
}

export default observer(ExtendedCustomerInvoiceIcon)
