import Lockup from 'components/text/Lockup'
import LiveCaptureInvoiceBanner from './LiveCaptureInvoiceBanner'

const LiveCaptureAnalysisPendingBanner = () => (
  <LiveCaptureInvoiceBanner pending>
    <Lockup icon='ocr'>Live Capture analysiert die Rechnung…</Lockup>
  </LiveCaptureInvoiceBanner>
)

export default LiveCaptureAnalysisPendingBanner
