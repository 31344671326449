import { useEffect, useState } from 'react'
import EditableDocumentText, {
  EditableDocumentTextProps,
} from './EditableDocumentText'

interface EditableDecimalAmountProps
  extends Omit<EditableDocumentTextProps, 'value' | 'onChange'> {
  value: number | undefined
  onChange: (value: number | undefined) => void
}

const stringifiedNumber = (value: number | undefined) =>
  value === undefined || isNaN(value) ? '' : String(value)

const EditableDecimalAmount = (props: EditableDecimalAmountProps) => {
  const { value } = props

  // This input may be potentially incomplete (e.g. "1." or ".5")
  const [decimalInput, setDecimalInput] = useState<string>(
    stringifiedNumber(value)
  )

  useEffect(() => {
    const displayedValue = parseFloat(decimalInput)
    if (value !== displayedValue)
      setDecimalInput(stringifiedNumber(displayedValue))
  }, [value])

  return (
    <EditableDocumentText
      inputMode='decimal'
      {...props}
      value={decimalInput ?? ''}
      onChange={(value) => {
        // Disallow inputs that are not numbers or decimal points
        if (!/^[0-9.]*$/.test(value)) return

        setDecimalInput(value)

        const numericValue = parseFloat(value)
        const isValidAmount = !isNaN(numericValue) && isFinite(numericValue)

        if (isValidAmount) props.onChange(numericValue)
      }}
    />
  )
}

export default EditableDecimalAmount
