import { DateTime } from 'luxon'
import { FilterableByDate } from './InvoiceDateFilter'

class InvoiceFilterUtilities {
  public static doesOpeningMonthMatch(
    invoice: FilterableByDate,
    openingMonthFilter: string | null
  ) {
    if (!openingMonthFilter) return true
    const openingDate = DateTime.fromMillis(invoice.openingDate)
    const filterDate = DateTime.fromISO(openingMonthFilter)
    return (
      openingDate.month === filterDate.month &&
      openingDate.year === filterDate.year
    )
  }
}

export default InvoiceFilterUtilities
