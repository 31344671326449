import { Button, Flex, Heading } from '@nextbusiness/infinity-ui'
import { Icon, IconItem } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import { useInitialSetupContext } from './InitialSetupContext'
import './OnboardingOption.scss'

interface OnboardingOptionProps {
  icon: IconItem
  title: string
  description: string
  children?: React.ReactNode
}

const OnboardingOption = (props: OnboardingOptionProps) => {
  const { expandOnboardingCard } = useInitialSetupContext()
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const expandOption = () => {
    setIsExpanded(true)
    expandOnboardingCard()
  }
  return (
    <section
      className={classNames('onboarding-option', { expanded: isExpanded })}
    >
      <Flex className='option-header'>
        <AnimatePresence>
          <div className='option-icon'>
            <Icon icon={props.icon} size={28} />
          </div>
          <div className='option-depiction'>
            <Heading type='h4' className='option-title' bare>
              {props.title}
            </Heading>
            {isExpanded ? (
              <motion.div
                className='option-content'
                initial={{ opacity: 0, translateY: -30 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{
                  type: 'spring',
                  stiffness: 200,
                  damping: 30,
                  mass: 0.9,
                }}
              >
                {props.children}
              </motion.div>
            ) : (
              <div className='option-description'>{props.description}</div>
            )}
          </div>
          {!isExpanded && (
            <div className='option-actions'>
              <Button onClick={expandOption}>Ändern</Button>
            </div>
          )}
        </AnimatePresence>
      </Flex>
    </section>
  )
}

export default OnboardingOption
