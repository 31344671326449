import { APIError, ErrorCode } from '@nextbusiness/infinity-finance-api'
import { BackendError, ErrorType } from 'libs/networking/Errors'
import { store } from 'stores/RootStoreContext'

class NetworkingErrorHandler {
  public static throwFromResponse(response: Response, body?: any): never {
    switch (response.status) {
      case 400:
        throw new BackendError(ErrorType.MissingRequiredFields, body.message)
      case 401:
        store.authenticationStore.handleExpiredSession()
        throw new APIError(
          ErrorCode.Unauthorised,
          'Deine Sitzung ist abgelaufen.'
        )
      case 403:
        if (body.message === 'This fiscal year is closed') {
          throw new BackendError(
            ErrorType.PastFiscalYearNonModifyable,
            'Dieses Geschäftsjahr ist bereits geschlossen und kann nicht mehr verändert werden.'
          )
        }
        throw new BackendError(ErrorType.NotAllowed, body.message)
      case 409:
        throw new BackendError(ErrorType.Conflict, body.message)
      case 404:
        throw new BackendError(ErrorType.NotFound, body.message)
      case 429:
        throw new BackendError(ErrorType.TooManyRequests, body.message)
      default:
        throw new BackendError(ErrorType.ServerError, body?.message)
    }
  }
}

export default NetworkingErrorHandler
