import { ErrorCode } from '@nextbusiness/infinity-finance-api'
import * as Sentry from '@sentry/react'
import SprigIntegration from 'integrations/SprigIntegration'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import { IUser, IUserOrganisation } from 'model/Organisation'
import Organisation from 'networking/Organisation'
import Authentication, { REDIRECT_URL } from '../networking/Authentication'
import RootStore from './RootStore'
import Store from './Store'

export default class AuthenticationStore extends Store {
  @persist @observable applicationAccessToken: string | undefined = undefined
  @persist @observable userDataToken: string | undefined = undefined
  @persist @observable sessionToken: string | undefined = undefined
  @persist @observable hmac: string | undefined = undefined

  @persist @observable organisationIdentifier = ''
  @persist @observable fabricOrganisationIdentifier: string | undefined =
    undefined

  @persist('object') @observable user: IUser | null = null

  @observable isHydrated = false
  @observable isSessionExpired = false

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  @action
  setCurrentTokens(
    applicationAccessToken: string,
    userDataToken: string,
    sessionToken: string,
    hmac: string
  ) {
    this.applicationAccessToken = applicationAccessToken
    this.userDataToken = userDataToken
    this.sessionToken = sessionToken
    this.hmac = hmac
    this.isSessionExpired = false

    Authentication.setCurrentTokens(
      applicationAccessToken,
      userDataToken,
      sessionToken,
      hmac,
      this.organisationIdentifier
    )
  }

  getUser = async () => {
    if (
      this.isHydrated &&
      (!this.applicationAccessToken || !this.userDataToken)
    )
      return this.logout()
    try {
      const user = await Organisation.getCurrentUser(
        this.applicationAccessToken!,
        this.userDataToken!
      )
      runInAction(() => {
        this.user = user
      })
      Sentry.setUser({ email: user.email, id: user.id })
      SprigIntegration.sprig?.setUserId(user.id)
    } catch (error: any) {
      if (error.code === ErrorCode.Unauthorised) this.logout()
    }
  }

  @computed
  get fullNameOfUser() {
    if (!this.user) return ''
    return `${this.user.firstname} ${this.user.lastname}`.trim()
  }

  @computed
  get organisations(): IUserOrganisation[] {
    if (!this.user) return []
    return this.user.organisations
  }

  get currentOrganisationName() {
    const organisation = this.organisations.find(
      (organisation) =>
        organisation.IDForAPI === this.fabricOrganisationIdentifier
    )
    return organisation?.name ?? 'Organisation'
  }

  @action
  setCurrentOrganisation(organisationIdentifier: string, fabricId: string) {
    if (
      this.organisationIdentifier === organisationIdentifier &&
      this.fabricOrganisationIdentifier === fabricId
    )
      return

    this.organisationIdentifier = organisationIdentifier
    this.fabricOrganisationIdentifier = fabricId

    this.rootStore.accountStore.reset()
    this.rootStore.preferencesStore.reset()
    this.rootStore.ledgerStore.reset()
    this.rootStore.templateStore.reset()
  }

  @action
  switchToOrganisation(fabricId: string) {
    this.isHydrated = false
    this.logout()
    document.location.href = REDIRECT_URL(fabricId)
  }

  async onLogin() {
    if (!this.organisationIdentifier || !this.isAuthenticated) return
    try {
      await Promise.all([
        this.rootStore.contactStore.loadContacts(),
        this.rootStore.preferencesStore.loadPreferences(),
        this.rootStore.organisationStore.loadMembers(),
        this.rootStore.templateStore.loadCurrentTemplate(),
        this.rootStore.accountStore.getFiscalYear(),
        this.rootStore.currencyStore.loadCurrencies(),
        this.rootStore.projectStore.fetchAllProjects(),
        this.getUser(),
      ])
      this.isSessionExpired = false
    } catch (error: any) {
      // Don't throw unhandled errors if the user is not authenticated
      if (error.code === ErrorCode.Unauthorised) return
      throw error
    }
  }

  @computed
  get isAuthenticated(): boolean {
    return (
      this.applicationAccessToken !== undefined &&
      this.userDataToken !== undefined &&
      this.sessionToken !== undefined
    )
  }

  @action
  logout() {
    this.applicationAccessToken = undefined
    this.userDataToken = undefined
    this.sessionToken = undefined
    this.hmac = undefined
    Sentry.setUser(null)
  }

  @action
  handleExpiredSession = () => {
    this.isSessionExpired = true
  }

  onHydrate() {
    if (
      this.applicationAccessToken &&
      this.userDataToken &&
      this.sessionToken &&
      this.hmac
    ) {
      Authentication.setCurrentTokens(
        this.applicationAccessToken,
        this.userDataToken,
        this.sessionToken,
        this.hmac,
        this.organisationIdentifier
      )
    }
    this.isHydrated = true
    this.onLogin()
  }
}
