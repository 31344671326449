import { useMixpanelEvent } from 'integrations/useMixpanelEvent'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import SettingsGroup from 'settings/general/SettingsGroup'
import SettingsPage from 'settings/general/SettingsPage'
import { useRootStore } from 'stores/RootStoreContext'
import CurrenciesIcon from '../../assets/settings-icons/currencies-icon.svg'
import CurrencyTable from './CurrencyTable'

const CurrencySettingsPage = () => {
  const { currencyStore } = useRootStore()

  useEffect(() => {
    currencyStore.loadCurrencies()
  }, [])

  useMixpanelEvent('Settings viewed - Presets')

  return (
    <SettingsPage
      title='Währungen'
      iconURL={CurrenciesIcon}
      className='currency-settings-page'
    >
      <SettingsGroup
        title='Fremdwährungen'
        description='Folgende Fremdwährungen sind in dieser Organisation aktiviert. Automatische Wechselkurse verwenden den Monatsmittelkurs der ESTV.'
      >
        <CurrencyTable />
      </SettingsGroup>
    </SettingsPage>
  )
}

export default observer(CurrencySettingsPage)
