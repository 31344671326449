import {
  NotificationCenter,
  ThemeProvider,
  TooltipProvider,
} from '@nextbusiness/infinity-ui'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import SubscriptionContextProvider from 'components/billing/subscription-context/SubscriptionContextProvider'
import MobileGuard from 'components/mobile-guard/MobileGuard'
import IntercomBoot from 'integrations/IntercomBoot'
import MixpanelProvider from 'integrations/MixpanelProvider'
import PublicInvoicePage from 'invoices/customer-invoices/public-invoice/PublicInvoicePage'
import { observer } from 'mobx-react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import EverythingMenuProvider from '../components/everything-menu/EverythingMenuProvider'
import Providers from '../components/everything-menu/providers/Providers'
import RootStore from '../stores/RootStore'
import RootStoreProvider, { useRootStore } from '../stores/RootStoreContext'
import '../styles/App.scss'
import '../styles/print.scss'
import ApplicationSwitch from './ApplicationSwitch'

const ENABLE_DEBUG_OBSERVER = import.meta.env.DEV

const StoreDebugHelperInner = (_: { rootStore: RootStore }) => <></>
const StoreDebugHelper = observer(() => {
  const rootStore = useRootStore()
  return <StoreDebugHelperInner rootStore={rootStore} />
})

const queryClient = new QueryClient()

const Application = () => {
  return (
    <RootStoreProvider>
      {ENABLE_DEBUG_OBSERVER && <StoreDebugHelper />}
      <QueryClientProvider client={queryClient}>
        <Router>
          <Switch>
            <Route path='/offerte/:id' exact>
              <NotificationCenter>
                <TooltipProvider>
                  <PublicInvoicePage />
                </TooltipProvider>
              </NotificationCenter>
            </Route>
            <Route path='/rechnung/:id' exact>
              <NotificationCenter>
                <TooltipProvider>
                  <PublicInvoicePage />
                </TooltipProvider>
              </NotificationCenter>
            </Route>
            <Route path='/'>
              <ThemeProvider primaryColor='teal'>
                <SubscriptionContextProvider>
                  <IntercomBoot>
                    <MixpanelProvider>
                      <div className='App'>
                        <MobileGuard>
                          <EverythingMenuProvider>
                            <ApplicationSwitch />
                          </EverythingMenuProvider>
                        </MobileGuard>
                      </div>
                    </MixpanelProvider>
                  </IntercomBoot>
                </SubscriptionContextProvider>
              </ThemeProvider>
            </Route>
          </Switch>
        </Router>
      </QueryClientProvider>
    </RootStoreProvider>
  )
}

Providers.setupSearchProviders()

export default Application
