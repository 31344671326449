import { InputField } from '@nextbusiness/infinity-ui'

interface ContactInputSearchFieldProps {
  searchValue: string
  setSearchValue: (value: string) => void
}

const ContactInputSearchField = (props: ContactInputSearchFieldProps) => {
  return (
    <InputField
      value={props.searchValue}
      onChange={props.setSearchValue}
      placeholder='Rechnungen, Offerten, …'
      icon='search'
    />
  )
}

export default ContactInputSearchField
