import { TemplatePreviewProps } from './TemplatePreview'
import TemplatePreviewLogo from './TemplatePreviewLogo'

const TemplatePreviewSender = (props: TemplatePreviewProps) => (
  <div
    className='template-page-sender'
    style={{
      visibility: props.template.disableSenderBlock ? 'hidden' : 'visible',
    }}
  >
    <TemplatePreviewLogo {...props} />
    <div className='sender-address'>
      <span>{props.template.organisationName}</span>
      <span>{props.template.addressLine}</span>
      <span>
        {props.template.zipCode} {props.template.city}
      </span>
    </div>
    <div className='sender-additional'>{props.template.additionalData}</div>
  </div>
)

export default TemplatePreviewSender
