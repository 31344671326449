import { ITransactionChanges } from '@nextbusiness/infinity-finance-api'
import { InputField, Text } from '@nextbusiness/infinity-ui'

interface RenameBatchEditingViewProps {
  changesToTransaction: Partial<ITransactionChanges>
  setChangesToTransaction: (
    changesToTransaction: Partial<ITransactionChanges>
  ) => void
}

const RenameBatchEditingView = (props: RenameBatchEditingViewProps) => {
  const { description } = props.changesToTransaction

  return (
    <>
      <Text>
        Hier kannst du eine neue Beschreibung für die ausgewählten Transaktionen
        setzen.
      </Text>
      <InputField
        placeholder='Beschreibung der Transaktionen'
        value={description?.toString() ?? ''}
        onChange={(changedDescription: string) =>
          props.setChangesToTransaction({
            ...props.changesToTransaction,
            description: changedDescription,
          })
        }
        className='description'
      />
    </>
  )
}

export default RenameBatchEditingView
