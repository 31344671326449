import {
  Dialog,
  FeedbackBanner,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { isBackendError } from '../../../libs/networking/Errors'
import { IVendorInvoice } from '../../../model/VendorInvoice'
import VendorInvoices from '../../../networking/VendorInvoices'
import { useRootStore } from '../../../stores/RootStoreContext'

interface DeleteVendorInvoiceModalProps {
  invoice: IVendorInvoice
  isOpen: boolean
  onDismiss: () => void
  onDeleteFinished: () => void
}

const DeleteVendorInvoiceModal = (props: DeleteVendorInvoiceModalProps) => {
  const { authenticationStore, vendorInvoiceStore, accountStore } =
    useRootStore()
  const notificationCenter = useNotificationCenter()
  const [isDeletionInProgress, setIsDeletionInProgress] =
    useState<boolean>(false)

  const isInClosedFiscalYear = !accountStore.isDateWithinOpenFiscalYear(
    new Date(props.invoice.openingDate)
  )

  const deleteInvoice = async () => {
    setIsDeletionInProgress(true)
    try {
      await VendorInvoices.deleteVendorInvoice(
        authenticationStore.organisationIdentifier,
        props.invoice.id
      )
      await vendorInvoiceStore.onInvoicesChanged()
      notificationCenter.addNotification({
        children: 'Die Rechnung wurde erfolgreich gelöscht.',
        variant: 'success',
      })
      props.onDeleteFinished()
    } catch (error: any) {
      notificationCenter.addNotification({
        children: isBackendError(error)
          ? error.displayMessage
          : error.message ||
            'Die Rechnung konnte nicht gelöscht werden. Bitte versuche es erneut.',
        variant: 'error',
      })
    } finally {
      setIsDeletionInProgress(false)
    }
  }

  return (
    <Dialog
      title='Rechnung unwiderruflich löschen?'
      isOpen={props.isOpen}
      dismissable
      onDismiss={props.onDismiss}
      actions={[
        {
          children: 'Abbrechen',
          disabled: isDeletionInProgress,
          onClick: () => props.onDismiss(),
        },
        {
          children: 'Löschen',
          variant: 'destructive',
          onClick: () => deleteInvoice(),
          isLoading: isDeletionInProgress,
          disabled: isInClosedFiscalYear,
        },
      ]}
    >
      <Text>
        Möchtest du die Rechnung "{props.invoice.title}" vom{' '}
        {new Date(props.invoice.openingDate).toLocaleDateString()} wirklich
        löschen? Alle zugehörigen automatisch erstellten Buchungen werden
        ebenfalls entfernt.
      </Text>
      {isInClosedFiscalYear && (
        <FeedbackBanner variant='warning'>
          Diese Rechnung wurde in einem geschlossenen Geschäftsjahr eröffnet und
          kann daher nicht mehr gelöscht werden.
        </FeedbackBanner>
      )}
    </Dialog>
  )
}

export default observer(DeleteVendorInvoiceModal)
