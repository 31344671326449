import {
  Dialog,
  Flex,
  InputField,
  MoneyField,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import AccountSelect from 'ledger/accounts/account-select/AccountSelect'
import { ResolvedSetOfSuggestedAccounts } from 'ledger/accounts/data/AccountRules'
import { observer } from 'mobx-react'
import Accrual, { AccrualType } from 'model/financial-close/Accrual'
import { useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import AccrualTypeSelect from './AccrualTypeSelect'
import './CreateClosingEntityModal.scss'

interface CreateAccrualModalProps {
  isOpen: boolean
  onDismiss: () => void
}

const suggestionsForAccrualType = (
  type: AccrualType
): ResolvedSetOfSuggestedAccounts => {
  switch (type) {
    case AccrualType.PrePaidExpense:
    case AccrualType.AccruedExpense:
      return [{ title: 'Aufwände', accounts: ['expense'] }]
    case AccrualType.PrePaidRevenue:
    case AccrualType.DeferredRevenue:
      return [{ title: 'Erträge', accounts: ['income'] }]
  }
}

const contraAccountPlaceholderForAccrualType = (type: AccrualType) => {
  switch (type) {
    case AccrualType.PrePaidExpense:
      return 'Zu vermindernder Aufwand'
    case AccrualType.AccruedExpense:
      return 'Zu erhöhender Aufwand'
    case AccrualType.PrePaidRevenue:
      return 'Zu vermindernder Ertrag'
    case AccrualType.DeferredRevenue:
      return 'Zu erhöhender Ertrag'
  }
}

const CreateAccrualModal = (props: CreateAccrualModalProps) => {
  const { financialCloseStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [isSaving, setIsSaving] = useState<boolean>(false)

  const [amount, setAmount] = useState<number>()
  const [contraAccount, setContraAccount] = useState<number>()
  const [description, setDescription] = useState<string>('')
  const [type, setType] = useState<AccrualType>()

  const createAccrual = async () => {
    if (!amount || !contraAccount || !description || !type) return
    setIsSaving(true)
    try {
      const accruals: Accrual[] = [
        ...(financialCloseStore.financialCloseSettings?.accruals ?? []),
        {
          amount: Math.round(amount * 100),
          contraAccount,
          description,
          type,
        },
      ]
      await financialCloseStore.update({ accruals })
      props.onDismiss()
    } catch (error) {
      notificationCenter.addNotification({
        variant: 'error',
        title: 'Fehler beim Erstellen der Abgrenzung',
        children:
          (error as Error).message ??
          'Es ist ein unbekannter Fehler aufgetreten.',
      })
    } finally {
      setIsSaving(false)
    }
  }

  const resetFields = () => {
    setAmount(undefined)
    setContraAccount(undefined)
    setDescription('')
    setType(undefined)
  }

  useEffect(() => {
    if (props.isOpen) resetFields()
  }, [props.isOpen])

  return (
    <Dialog
      className='create-accrual-modal create-closing-entity-modal'
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      title='Neue Abgrenzung'
      actions={[
        {
          children: 'Abbrechen',
          onClick: () => props.onDismiss(),
        },
        {
          children: 'Erstellen',
          variant: 'primary',
          isLoading: isSaving,
          disabled: !amount || !contraAccount || !description || !type,
          onClick: () => createAccrual(),
        },
      ]}
    >
      <Flex verticalAlignment='center' gap='small'>
        <Text>Welche Art von Abgrenzung möchtest du erstellen?</Text>
        <div className='accrual-type entity-select'>
          <AccrualTypeSelect
            key={type}
            type={type}
            onChange={(type) => setType(type)}
          />
        </div>
      </Flex>
      {type && (
        <div className='entity-fields-well'>
          <div className='entity-fields'>
            <Flex verticalAlignment='center'>
              <InputField
                placeholder='Beschreibung'
                value={description}
                onChange={(text) => setDescription(text)}
                fullWidth
              />
              <AccountSelect
                currentAccountNumber={6800}
                suggestions={suggestionsForAccrualType(type)}
                onChange={(accountNumber) => setContraAccount(accountNumber)}
                inputFieldProps={{
                  placeholder: contraAccountPlaceholderForAccrualType(type),
                  fullWidth: true,
                }}
                onlyAllowSuggestedAccounts
              />
              <MoneyField
                value={amount ?? null}
                onChange={(updatedAmount) =>
                  setAmount(updatedAmount ?? undefined)
                }
              />
            </Flex>
          </div>
        </div>
      )}
    </Dialog>
  )
}

export default observer(CreateAccrualModal)
