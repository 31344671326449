import {
  ITransaction,
  TransactionOrigin,
} from '@nextbusiness/infinity-finance-api'
import {
  Button,
  Flex,
  Heading,
  ThemeProvider,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import * as Sentry from '@sentry/react'
import { ReactComponent as LiveMagicIcon } from 'assets/live-accounting/live-magic.svg'
import { motion } from 'framer-motion'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { useAccountPageContext } from 'ledger/accounts/AccountPageContext'
import { observer } from 'mobx-react'
import { useMemo, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { usePluralise } from 'utility/hooks'
import TransactionUtilities from 'utility/TransactionUtilities'
import TransactionListItems from '../list/TransactionListItems'
import './UnreviewedBankingTransactions.scss'

interface UnreviewedBankingTransactionsProps {
  unreviewedTransactions: ITransaction[]
}

const UnreviewedBankingTransactions = (
  props: UnreviewedBankingTransactionsProps
) => {
  const { transactionStore } = useRootStore()
  const { currentAccount } = useAccountPageContext()

  const [isMarkingAsReviewed, setIsMarkingAsReviewed] = useState<boolean>(false)
  const notificationCenter = useNotificationCenter()

  const accountNumber = currentAccount.accountNumber
  const unreviewedTransactions = props.unreviewedTransactions
  const numberOfUnreviewedTransactions = useMemo(() => {
    const isVATSystemAccount =
      TransactionUtilities.isAccountVATSystemAccount(currentAccount)
    const nonVATTransactions = unreviewedTransactions.filter(
      (transaction) =>
        transaction.origin !== TransactionOrigin.VAT || isVATSystemAccount
    )
    return nonVATTransactions.length
  }, [unreviewedTransactions, currentAccount])

  const markAllReviewed = async () => {
    try {
      setIsMarkingAsReviewed(true)
      await transactionStore.markAsReviewed(
        unreviewedTransactions,
        accountNumber
      )
      MixpanelAnalytics.event('Live accounting transactions marked reviewed', {
        count: numberOfUnreviewedTransactions,
      })
    } catch (error) {
      notificationCenter.addNotification({
        variant: 'error',
        children: 'Transaktionen konnten nicht als überprüft markiert werden.',
      })
      Sentry.captureException(error)
    } finally {
      setIsMarkingAsReviewed(false)
    }
  }
  const transactionCountLabel = usePluralise(
    numberOfUnreviewedTransactions,
    'neue Bankbewegung',
    { suffix: 'en' }
  )

  return (
    <motion.div
      className='unreviewed-banking-transactions'
      layoutId='unreviewed-transactions'
      layout
      transition={{ velocity: 10, stiffness: 100, mass: 0.2, type: 'spring' }}
    >
      <Heading className='section-title' type='h3' bareTop>
        <LiveMagicIcon />
        <span>{transactionCountLabel} zu überprüfen</span>
      </Heading>
      <TransactionListItems state='unreviewed' />
      <Flex horizontalAlignment='flex-end' className='section-actions'>
        <ThemeProvider primaryColor='lavender'>
          <Button
            variant='primary'
            iconLeft='check'
            onClick={markAllReviewed}
            isLoading={isMarkingAsReviewed}
          >
            Als überprüft markieren
          </Button>
        </ThemeProvider>
      </Flex>
    </motion.div>
  )
}

export default observer(UnreviewedBankingTransactions)
