import { IFabricOrganisation, IUser } from '../model/Organisation'
import NetworkingConstants from './NetworkingConstants'
import NetworkingErrorHandler from './NetworkingErrorHandler'

const getCurrentUser = async (
  applicationAccessToken: string,
  userDataToken: string
): Promise<IUser> => {
  const response = await fetch(
    NetworkingConstants.FABRIC_HOST +
      `/user/current?applicationAccessToken=${applicationAccessToken}&userDataToken=${userDataToken}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const body = await response.json()

  if (!response.ok || !body.user) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.user as IUser
}

const getOrganisationMembers = async (
  organisationIdentifier: string,
  applicationAccessToken: string,
  userDataToken: string
): Promise<IUser[]> => {
  const response = await fetch(
    NetworkingConstants.FABRIC_HOST +
      `/organisation/${organisationIdentifier}/members?applicationAccessToken=${applicationAccessToken}&userDataToken=${userDataToken}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const body = await response.json()

  if (!response.ok || !body.members) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.members as IUser[]
}

const getFabricOrganisation = async (
  organisationId: string,
  applicationAccessToken: string
): Promise<IFabricOrganisation> => {
  const response = await fetch(
    NetworkingConstants.FABRIC_HOST +
      `/organisation/${organisationId}?applicationAccessToken=${applicationAccessToken}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
  )
  const body = await response.json()

  if (!response.ok || !body.organisation) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.organisation as IFabricOrganisation
}

const Organisation = {
  getCurrentUser,
  getOrganisationMembers,
  getFabricOrganisation,
}

export default Organisation
