import {
  INewTransaction,
  ITransaction,
  TaxCode,
} from '@nextbusiness/infinity-finance-api'
import { Checkbox, Flex } from '@nextbusiness/infinity-ui'
import { IStructuredTaxCodes } from 'components/vat/vat-picker/TaxCodes'
import VATPicker from 'components/vat/vat-picker/VATPicker'
import { observer } from 'mobx-react-lite'
import { AccountType } from 'model/Account'
import { useRootStore } from 'stores/RootStoreContext'
import TaxUtilities, { adjustedTaxCodeByDate } from 'utility/TaxUtilities'
import './VATOptions.scss'
import useIsVATDisabled from './creator/hooks/useIsVATDisabled'

type TransactionAccountTypes = [
  AccountType | undefined,
  AccountType | undefined
]

interface VATOptionsProps {
  taxCode?: string
  onChange?: (taxCode: string) => void
  transaction?: Partial<INewTransaction>
  contraAccountSide?: 'debitAccount' | 'creditAccount'
  isDisabled?: boolean
  originalTransaction?: ITransaction
  label?: string
}

const VATOptions = (props: VATOptionsProps) => {
  const { preferencesStore, accountStore } = useRootStore()

  const contraAccountNumber = () =>
    props.contraAccountSide
      ? props.transaction?.[props.contraAccountSide]
      : undefined

  const contraAccount = accountStore.find(contraAccountNumber())

  const accountTypesOfTransaction = (): TransactionAccountTypes => {
    const currentAccountSide =
      props.contraAccountSide === 'debitAccount'
        ? 'creditAccount'
        : 'debitAccount'
    const currentAccount = accountStore.find(
      props.transaction?.[currentAccountSide]
    )
    const contraAccount = accountStore.find(contraAccountNumber())
    return [currentAccount?.accountType, contraAccount?.accountType]
  }

  const VATTypeForTransaction = (): keyof IStructuredTaxCodes | undefined => {
    if (!props.transaction) return
    const accountTypes = accountTypesOfTransaction()
    const showIncomeVATOptions = accountTypes.includes('income')

    if (showIncomeVATOptions) return 'income'
    else return 'expense'
  }

  const VATType = VATTypeForTransaction()

  const { isVATDisabled } = useIsVATDisabled(
    preferencesStore,
    props.transaction
  )

  if (isVATDisabled) return <div className='vat-options' />

  return (
    <Flex className='vat-options' verticalAlignment='center' gap='small'>
      <Checkbox
        className='vat-checkbox'
        isDisabled={props.isDisabled}
        isChecked={!!props.taxCode}
        label={
          props.label ??
          (VATType === 'expense'
            ? 'Ich habe Mehrwertsteuer bezahlt'
            : 'Mehrwertsteuerpflichtiger Umsatz')
        }
        onChange={(isChecked: boolean) => {
          const suggestedTaxCode =
            TaxUtilities.autoSuggestVAT(
              { number: contraAccountNumber(), account: contraAccount },
              preferencesStore.organisationPreferences,
              props.transaction?.date
            ) ??
            adjustedTaxCodeByDate(
              TaxCode.VM77,
              props.transaction?.date ?? Date.now()
            )
          props.onChange?.(isChecked ? suggestedTaxCode : '')
        }}
      />
      {props.taxCode && (
        <VATPicker
          type={VATType}
          taxCode={(props.taxCode ?? '') as TaxCode}
          onTaxCodeSelected={props.onChange}
          isDisabled={props.isDisabled}
        />
      )}
    </Flex>
  )
}

export default observer(VATOptions)
