import {
  Dialog,
  FeedbackBanner,
  Flex,
  InputField,
} from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import { DateTime } from 'luxon'
import { useState } from 'react'
import './CustomerInvoiceCustomPaymentTermDialog.scss'

interface CustomerInvoiceCustomPaymentTermDialogProps {
  isOpen: boolean
  onDismiss: () => void
  onSelectCustomTerm: (numberOfDays: number) => void
  openingDate: number
  isQuote: boolean | undefined
}

const CustomerInvoiceCustomPaymentTermDialog = (
  props: CustomerInvoiceCustomPaymentTermDialogProps
) => {
  const [numberOfDays, setNumberOfDays] = useState<number | undefined>(30)
  const dueDate = DateTime.fromMillis(props.openingDate).plus({
    days: numberOfDays ?? 0,
  })
  const dueDateText = dueDate.isValid
    ? dueDate.toLocaleString({
        dateStyle: 'long',
        locale: 'de-CH',
      })
    : '–'

  const isTooLarge = numberOfDays && numberOfDays > 365
  const isValid = !!numberOfDays && numberOfDays > 0 && !isTooLarge

  return (
    <Dialog
      className='customer-invoice-custom-payment-term-dialog'
      title='Eigene Frist wählen'
      titleProps={{ divider: true }}
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      actions={[
        {
          children: 'Abbrechen',
          onClick: props.onDismiss,
        },
        {
          children: 'Frist setzen',
          onClick: () => {
            props.onDismiss()
            props.onSelectCustomTerm(numberOfDays!)
          },
          variant: 'primary',
          disabled: !isValid,
        },
      ]}
    >
      <div className='instruction'>
        {props.isQuote
          ? 'Wähle aus, während wie vielen Tagen diese Offerte gültig ist.'
          : 'Wähle aus, in wie vielen Tagen diese Forderung fällig ist.'}
      </div>
      <Flex verticalAlignment='center' className='payable-term'>
        <div className='payable-term-selector'>
          <span>{props.isQuote ? 'Für' : 'In'}</span>
          <InputField
            className='payable-term-input'
            type='number'
            value={numberOfDays ? String(numberOfDays) : ''}
            onChange={(value) => {
              const updatedValue = parseInt(value)
              if (isNaN(updatedValue)) return setNumberOfDays(undefined)
              return setNumberOfDays(updatedValue ?? undefined)
            }}
            placeholder='0'
          />
          <span>{props.isQuote ? 'Tage gültig' : 'Tagen fällig'}</span>
        </div>
        <Icon icon='arrow-right' size={IconSize.Small} className='to-icon' />
        <div className='new-due-date'>
          {props.isQuote ? 'Gültig bis' : 'Fällig am'} {dueDateText}
        </div>
      </Flex>
      {!isValid && (
        <FeedbackBanner>
          Wähle eine Frist zwischen 1 und 365 Tagen aus.
        </FeedbackBanner>
      )}
    </Dialog>
  )
}

export default CustomerInvoiceCustomPaymentTermDialog
