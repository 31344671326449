import {
  AccountCardBalanceDatapoint,
  AccountCardChartData,
  AccountCardTimeframe,
} from '@nextbusiness/infinity-finance-api'
import {
  ChartData,
  Chart as ChartJS,
  LineElement,
  PointElement,
} from 'chart.js'
import 'chartjs-adapter-luxon'
import { Line } from 'react-chartjs-2'
import AccountCardChartConfiguration, {
  AccountCardGranularity,
} from './AccountCardChartConfiguration'

interface AccountCardLineChartProps {
  granularity: AccountCardGranularity
  timeframe: AccountCardTimeframe
  data: AccountCardChartData<AccountCardBalanceDatapoint>
}

ChartJS.register(PointElement, LineElement)

const AccountCardLineChart = (props: AccountCardLineChartProps) => {
  const config = new AccountCardChartConfiguration(
    props.granularity,
    props.timeframe,
    props.data.current.length,
    props.data.current.map((d) => d.date)
  )
  const data: ChartData<'line', number[], string> = {
    labels: config.labels(),
    datasets: [{ data: props.data.current.map((d) => d.balance) }],
  }

  if (props.data.previous) {
    data.datasets.push({
      data: props.data.previous.map((d) => d.balance),
      backgroundColor: '#b6bcc9',
      borderDash: [2, 4],
      borderWidth: 1.5,
    })
  }

  return (
    <div className='account-card-chart'>
      <Line
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: { left: -10, bottom: -3 },
          },
          scales: {
            x: {
              border: { display: false },
              grid: { drawTicks: false, color: '#f2f4f7' },
              ticks: {
                font: { family: 'Neue Plak Text, sans-serif' },
                padding: 10,
                autoSkip: true,
                maxRotation: 0,
                autoSkipPadding: 10,
              },
            },
            y: {
              border: { display: false },
              grid: { drawTicks: false, color: '#f2f4f7' },
              ticks: {
                callback: config.formatValueLabel,
                font: { family: 'Neue Plak Text, sans-serif' },
                count: 5,
                padding: 10,
              },
            },
          },
          elements: {
            point: { radius: 0 },
            line: {
              borderWidth: 2.5,
              borderJoinStyle: 'round',
              borderCapStyle: 'round',
              borderColor: '#098c70',
            },
          },
        }}
      />
    </div>
  )
}

export default AccountCardLineChart
