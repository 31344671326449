import { LegalType } from '@nextbusiness/infinity-finance-api'
import { Button } from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'
import CardRadioGroup from 'ledger/accounts/account-creation/CardRadioGroup'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useRootStore } from 'stores/RootStoreContext'
import { useInitialSetupContext } from './InitialSetupContext'
import OnboardingCard from './OnboardingCard'
import { OnboardingStep } from './OnboardingStepper'

const OnboardingLegalFormStep = () => {
  const { setCurrentStep, setLegalType, legalType } = useInitialSetupContext()
  const history = useHistory()

  const { accountStore } = useRootStore()
  const isLoading =
    accountStore.isLoadingAccounts || accountStore.isLoadingFiscalYears

  useEffect(() => {
    if (accountStore.currentFiscalYear && accountStore.allAccounts.length > 0) {
      history.push('/')
    }
  }, [isLoading])

  return (
    <OnboardingCard
      icon={<Icon icon='business' />}
      title='Wähle deine Rechtsform'
      subtitle='Dies ist wichtig, damit wir alles korrekt für dich einrichten können.'
      actions={
        <Button
          variant='primary'
          onClick={() => setCurrentStep(OnboardingStep.VAT)}
        >
          Fortfahren
        </Button>
      }
    >
      <div>
        <CardRadioGroup
          onChange={setLegalType}
          value={legalType}
          options={[
            {
              value: LegalType.SoleProprietorship,
              label: 'Ich bin selbstständig erwerbstätig',
              description:
                'Wenn du selbstständig bist und keine andere Rechtsform gegründet hast',
              icon: 'user',
            },
            {
              value: LegalType.GeneralPartnership,
              label: 'Ich habe eine Kollektivgesellschaft',
              description:
                'Wenn du eine Kollektivgesellschaft mit anderen Personen gegründet hast',
              icon: 'teams',
            },
            {
              value: LegalType.LLC,
              label: 'Ich habe eine GmbH',
              description:
                'Wenn du eine Gesellschaft mit beschränkter Haftung gegründet hast',
              icon: 'business',
            },
            {
              value: LegalType.LimitedCompany,
              label: 'Ich habe eine AG',
              description: 'Wenn du eine Aktiengesellschaft gegründet hast',
              icon: 'company',
            },
          ]}
        />
      </div>
    </OnboardingCard>
  )
}

export default observer(OnboardingLegalFormStep)
