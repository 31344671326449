import { observer } from 'mobx-react-lite'
import { useRootStore } from 'stores/RootStoreContext'
import DocumentElementProps from './DocumentElementProps'

const DocumentElementSender = (props: DocumentElementProps) => {
  const { preferencesStore } = useRootStore()

  return (
    <div
      className='sender'
      style={{
        visibility: props.template.disableSenderBlock ? 'hidden' : 'visible',
      }}
    >
      <div className='sender-address'>
        <span>{props.template.organisationName}</span>
        <span>{props.template.addressLine}</span>
        <span>
          {props.template.zipCode} {props.template.city}
        </span>
      </div>
      <div className='sender-additional'>
        {props.template.additionalData}
        {preferencesStore.organisationPreferences?.VAT
          ? `${props.template.additionalData?.length ? '\n\n' : ''}${
              preferencesStore.organisationPreferences.vatRegistrationNumber
            }`
          : ''}
      </div>
    </div>
  )
}

export default observer(DocumentElementSender)
