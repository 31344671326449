import { Flex } from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'
import CommunicationsOption from './CommunicationsOption'
import FiscalYearOnboardingOption from './FiscalYearOnboardingOption'
import OnboardingCard from './OnboardingCard'
import OnboardingSetupAction from './OnboardingSetupAction'
import VATOnboardingOption from './VATOnboardingOption'

const OnboardingReviewStep = () => (
  <OnboardingCard
    icon={<Icon icon='approval' />}
    title='Fast geschafft'
    subtitle='Überprüfe deine Einstellungen, um die Einrichtung abzuschliessen.'
    actions={<OnboardingSetupAction />}
  >
    <Flex className='onboarding-options' direction='vertical'>
      <VATOnboardingOption />
      <FiscalYearOnboardingOption />
    </Flex>
    <CommunicationsOption />
  </OnboardingCard>
)

export default OnboardingReviewStep
