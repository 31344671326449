import { APIError, Finance } from '@nextbusiness/infinity-finance-api'
import { Button, useNotificationCenter } from '@nextbusiness/infinity-ui'
import { useAccountPageContext } from 'ledger/accounts/AccountPageContext'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useRootStore } from 'stores/RootStoreContext'
import './TransactionFiscalYearBreak.scss'

interface TransactionFiscalYearBreakProps {
  year: number
}

const TransactionFiscalYearBreak = (props: TransactionFiscalYearBreakProps) => {
  const history = useHistory()
  const { accountStore } = useRootStore()
  const accountPageContext = useAccountPageContext()

  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const notificationCentre = useNotificationCenter()

  const succeededFiscalYear = accountStore.allFiscalYears?.find(
    (fiscalYear) => fiscalYear.year === props.year
  )
  const successorFiscalYear = accountStore.allFiscalYears?.find(
    (fiscalYear) => fiscalYear.year === props.year + 1
  )

  const isDueForSuccession =
    succeededFiscalYear &&
    successorFiscalYear &&
    !succeededFiscalYear.isClosed &&
    !successorFiscalYear.isClosed

  const updateFiscalYearSuccession = async () => {
    setIsUpdating(true)
    try {
      await Finance.Ledger.updateFiscalYearSuccession(props.year)
      accountPageContext.resetTransactions()
      await Promise.all([
        accountPageContext.loadTransactions(),
        accountStore.loadAccounts(),
      ])
      notificationCentre.addNotification({
        children: 'Saldovortrag erfolgreich aktualisiert',
        variant: 'success',
      })
    } catch (error) {
      notificationCentre.addNotification({
        children: (error as APIError).humanText('de'),
        variant: 'error',
      })
    } finally {
      setIsUpdating(false)
    }
  }

  return (
    <div className='transaction-fiscal-year-break'>
      <span>Geschäftsjahr {props.year + 1}</span>
      <div className='divider' />
      {isDueForSuccession && (
        <>
          <Button
            iconLeft='accounting'
            variant='quaternary'
            onClick={() => history.push('/dokumente/jahresabschluss')}
          >
            Zum Jahresabschluss
          </Button>
          <Button
            iconLeft='available-updates'
            variant='quaternary'
            onClick={updateFiscalYearSuccession}
            isLoading={isUpdating}
          >
            Saldovortrag übertragen
          </Button>
        </>
      )}
    </div>
  )
}

export default observer(TransactionFiscalYearBreak)
