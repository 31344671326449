import { Button, Text } from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import DocumentsDuePrompt from 'documents/DocumentsDuePrompt'
import { OpenVATPeriod } from 'model/VAT'
import DocumentsPage, { DocumentsPagePaths } from 'pages/DocumentsPage'

interface VATPeriodNotDuePromptProps {
  page: DocumentsPage
  period: OpenVATPeriod
}

const VATPeriodNotDuePrompt = (props: VATPeriodNotDuePromptProps) => {
  const endDate = new Date(props.period.end)
  const endDateText = endDate.toLocaleDateString('de-DE', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  return (
    <DocumentsDuePrompt
      title='Periode noch laufend'
      icon={<Icon icon='clock' size={IconSize.Big} />}
      appearance='subtle'
    >
      <Text>
        Sobald diese Periode nach dem {endDateText} endet, kannst du die Periode
        definitiv abrechnen und die Zahlen der Steuerverwaltung melden.
      </Text>
      <Button
        onClick={() =>
          props.page.open(DocumentsPagePaths.VATReport, props.period._id)
        }
      >
        Vorschau ansehen
      </Button>
    </DocumentsDuePrompt>
  )
}

export default VATPeriodNotDuePrompt
