import { Button } from '@nextbusiness/infinity-ui'
import React from 'react'
import './OnboardingOptionProperty.scss'

interface OnboardingOptionPropertyProps {
  children?: React.ReactNode
  title?: React.ReactNode
  helpLink?: string
}

const OnboardingOptionProperty = (props: OnboardingOptionPropertyProps) => (
  <div className='onboarding-option-property'>
    <div className='property-header'>
      <div className='property-title'>{props.title}</div>
      {props.helpLink && (
        <div className='property-help'>
          <Button
            iconOnly='help'
            aria-label='Hilfe anzeigen'
            tooltip='Hilfe anzeigen'
            onClick={() => window.open(props.helpLink)}
            variant='quaternary'
          />
        </div>
      )}
    </div>
    <div className='property-content'>{props.children}</div>
  </div>
)

export default OnboardingOptionProperty
