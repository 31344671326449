import {
  Button,
  Flex,
  InputField,
  Spacer,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Permission } from '../base-components/system/Permission'
import RequestPermission from '../base-components/system/RequestPermission'
import contactDialogStore from '../stores/ContactDialogStore'
import { useRootStore } from '../stores/RootStoreContext'
import ContactsDialog from './ContactsDialog'

interface ContactPickerProps {
  contactId?: string
  setContactId: (contactId?: string) => void
  customView?: React.ReactNode
  hideInterface?: boolean
  disabled?: boolean
}

const ContactPicker = (props: ContactPickerProps) => {
  const notificationCenter = useNotificationCenter()
  const { authenticationStore, contactStore } = useRootStore()
  const { isContactsOpen, setIsContactsOpen } = contactDialogStore

  const getAllContacts = async () => {
    if (
      !authenticationStore.organisationIdentifier ||
      !authenticationStore.applicationAccessToken
    )
      return authenticationStore.logout()
    try {
      await contactStore.loadContacts()
    } catch (error: any) {
      notificationCenter.addNotification({
        children:
          error.message ||
          'Die Kontakte dieser Organisation konnten nicht geladen werden.',
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    getAllContacts()
  }, [])

  const permissionRequest = (
    <RequestPermission
      permissions={[Permission.ReadContacts, Permission.WriteContacts]}
      onPermissionDenied={() => setIsContactsOpen(false)}
      onPermissionGranted={() => {
        runInAction(() => {
          contactStore.hasContactsPermissions = true
          contactStore.loadContacts()
        })
      }}
    />
  )

  const dialog =
    !contactStore.hasContactsPermissions && isContactsOpen ? (
      permissionRequest
    ) : (
      <ContactsDialog
        mode='picker'
        onContactPicked={(contact) => props.setContactId(contact.id)}
      />
    )

  if (props.hideInterface) return dialog

  if (props.customView)
    return (
      <>
        <Button
          disabled={props.disabled}
          onClick={() => setIsContactsOpen(true)}
          className='contact-picker-trigger'
          variant='quaternary'
        >
          {props.customView}
        </Button>
        {dialog}
      </>
    )

  return (
    <Flex verticalAlignment='center' style={{ flexGrow: 1 }}>
      {props.contactId ? (
        <>
          <InputField
            className='input-field'
            value={contactStore.displayNameForContactWithId(props.contactId)}
            disabled
            placeholder='Kontakt auswählen'
          />
          <Spacer size='tiny' direction='horizontal' />
          <Button
            iconOnly='link-broken'
            onClick={() => props.setContactId(undefined)}
            tooltip='Kontaktauswahl entfernen'
            aria-label='Kontaktauswahl entfernen'
            disabled={props.disabled}
          />
        </>
      ) : (
        <Button
          iconLeft='address-book'
          onClick={() => setIsContactsOpen(true)}
          disabled={props.disabled}
          style={{ width: '100%' }}
        >
          Kontakt auswählen
        </Button>
      )}
      {dialog}
    </Flex>
  )
}

export default observer(ContactPicker)
