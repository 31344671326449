import { AccountMethod } from '@nextbusiness/infinity-finance-api'
import {
  Flyout,
  InputField,
  InputFieldProps,
  KeyValueStore,
  TypedKeyValueStore,
} from '@nextbusiness/infinity-ui'
import SuggestionStar from 'assets/suggestion-star.svg'
import classNames from 'classnames'
import CreateAccountAssistant from 'ledger/accounts/account-creation/CreateAccountAssistant'
import {
  ResolvedSetOfSuggestedAccounts,
  doesAccountMatchFilter,
} from 'ledger/accounts/data/AccountRules'
import { inject, observer } from 'mobx-react'
import { AccountType, IAccount } from 'model/Account'
import React, { Component } from 'react'
import AccountStore from 'stores/AccountStore'
import LedgerStore from 'stores/LedgerStore'
import StringUtilities from 'utility/StringUtilities'
import AccountCreationOption from './AccountCreationOptions'
import AccountOption from './AccountOption'
import './AccountSelect.scss'

interface AccountSelectProps {
  accountStore?: AccountStore
  ledgerStore?: LedgerStore

  currentAccountNumber: number | undefined
  suggestions: ResolvedSetOfSuggestedAccounts
  initialAccountNumber?: number
  onChange: (accountNumber: number | undefined) => void
  className?: string
  openAccountPageModally?: (accountNumber: number) => string
  createAccount?: (accountType: AccountType) => void
  inputFieldProps?: Partial<InputFieldProps>
  placeholderText?: string
  onlyAllowSuggestedAccounts?: boolean
  onlyAllowLiveAccounts?: boolean
  allowAccountCreation?: boolean
  fullWidth?: boolean
}

interface AccountSelectState {
  isActive: boolean
  selectedAccountNumber?: number
  enteredValue: string
  hasSearched: boolean
  shouldIgnoreMouse: boolean
  activeOption?: number
  isCreatingAccount?: boolean

  currentSuggestedAccounts: TypedKeyValueStore<IAccount[]>
  currentSuggestedAccountNumbers: number[]
  currentStaticAccounts: TypedKeyValueStore<IAccount[]>
}

@inject('accountStore', 'ledgerStore')
@observer
class AccountSelect extends Component<AccountSelectProps, AccountSelectState> {
  private readonly translatedAccountTypes: KeyValueStore = {
    assets: 'Aktiven',
    liabilities: 'Passiven',
    income: 'Erträge',
    expense: 'Aufwände',
  }
  private inputRef

  constructor(props: AccountSelectProps) {
    super(props)
    this.inputRef = React.createRef<HTMLInputElement>()
    this.state = {
      isActive: false,
      selectedAccountNumber: props.initialAccountNumber,
      enteredValue:
        props.accountStore!.find(props.initialAccountNumber)?.name ?? '',
      shouldIgnoreMouse: false,
      hasSearched: false,

      currentStaticAccounts: this.staticAccounts(),
      currentSuggestedAccounts: this.suggestedAccounts(),
      currentSuggestedAccountNumbers: this.suggestedAccountNumbers(),
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyboardEvents)
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyboardEvents)

    if (this.state.shouldIgnoreMouse)
      window.removeEventListener('mousemove', this.onMouseActivity)
  }

  searchAndRank = (accounts: IAccount[] | undefined) => {
    if (!accounts) return []

    const searchValue = this.state.enteredValue
    const isSearchNumber = !isNaN(parseInt(searchValue))

    if (isSearchNumber) {
      return accounts
        .filter((account) =>
          account.accountNumber.toString().startsWith(searchValue)
        )
        .sort((a, b) =>
          a.accountNumber.toString().indexOf(searchValue) <
          b.accountNumber.toString().indexOf(searchValue)
            ? -1
            : 1
        )
    } else {
      const searchResults = new Set(
        StringUtilities.searchDataByValue(
          accounts.map((account) => account.name),
          this.state.enteredValue
        )
      )

      return Array.from(searchResults).map((accountName) =>
        accounts.find((account) => account.name === accountName)
      ) as IAccount[]
    }
  }

  shouldSearch = () =>
    !!this.state.enteredValue.trim() && this.state.hasSearched

  get allAccounts() {
    return this.props.accountStore!.allAccounts.filter(
      (account) => account.accountNumber !== this.props.currentAccountNumber
    )
  }

  suggestedAccounts = () => {
    const suggestedAccounts: TypedKeyValueStore<IAccount[]> = {}

    this.props.suggestions.forEach((accountSet) => {
      const setTitle = accountSet.title
      const setAccounts = this.allAccounts.filter((account) =>
        doesAccountMatchFilter(account, accountSet.accounts)
      )

      suggestedAccounts[setTitle] = setAccounts
    })
    return suggestedAccounts
  }

  suggestedAccountNumbers = () => {
    const suggestedAccountNumbers: number[] = []

    this.props.suggestions.forEach((accountSet) => {
      const setAccounts = this.allAccounts.filter((account) =>
        doesAccountMatchFilter(account, accountSet.accounts)
      )

      setAccounts.forEach((account) =>
        suggestedAccountNumbers.push(account.accountNumber)
      )
    })

    return suggestedAccountNumbers
  }

  staticAccounts = () => {
    const staticAccounts: TypedKeyValueStore<IAccount[]> = {}
    const accountTypes: AccountType[] = [
      'assets',
      'liabilities',
      'income',
      'expense',
    ]

    accountTypes.forEach((accountType) => {
      const setTitle = this.translatedAccountTypes[accountType]
      const setAccounts = this.props
        .accountStore!.accountsOfType(
          accountType,
          this.props.currentAccountNumber
        )
        .filter(
          (account) =>
            !this.suggestedAccountNumbers().includes(account.accountNumber)
        )

      if (setAccounts.length !== 0) staticAccounts[setTitle] = setAccounts
    })

    return staticAccounts
  }

  displayedAccountSegments = (): TypedKeyValueStore<IAccount[]> => {
    if (this.props.onlyAllowSuggestedAccounts) {
      return this.state.currentSuggestedAccounts
    }
    if (this.props.onlyAllowLiveAccounts) {
      return {
        'Live Accounts': this.allAccounts.filter(
          (account) => account.accountMethod === AccountMethod.Live
        ),
      }
    }
    return {
      ...this.state.currentSuggestedAccounts,
      ...this.state.currentStaticAccounts,
    }
  }

  shouldRegenerateCurrentAccounts = (previousProps: AccountSelectProps) =>
    this.props.accountStore?.allAccounts !==
      previousProps.accountStore?.allAccounts ||
    this.props.currentAccountNumber !== previousProps.currentAccountNumber ||
    this.props.suggestions !== previousProps.suggestions

  componentDidUpdate(previousProps: AccountSelectProps) {
    if (this.shouldRegenerateCurrentAccounts(previousProps))
      this.setState({
        currentStaticAccounts: this.staticAccounts(),
        currentSuggestedAccounts: this.suggestedAccounts(),
        currentSuggestedAccountNumbers: this.suggestedAccountNumbers(),
      })
  }

  displayedAccounts = () => {
    if (this.shouldSearch()) return this.searchAndRank(this.allAccounts)

    const suggestedAccounts = ([] as IAccount[]).concat(
      ...Object.values(this.state.currentSuggestedAccounts)
    )
    const staticAccounts = ([] as IAccount[]).concat(
      ...Object.values(this.state.currentStaticAccounts)
    )

    return suggestedAccounts.concat(staticAccounts)
  }

  determineAccountColour = () => {
    if (this.state.selectedAccountNumber)
      return (
        this.props.accountStore!.find(this.state.selectedAccountNumber)
          ?.colour ?? 'grey'
      )
    else return '#bbc5cb'
  }

  numberOfOptionsSoFar = (index: number) => {
    let numberOfOptionsSoFar = 0
    const passedSegments = Object.keys(this.displayedAccountSegments()).slice(
      0,
      index
    )

    passedSegments.forEach(
      (segment) =>
        (numberOfOptionsSoFar +=
          this.displayedAccountSegments()[segment].length)
    )

    return numberOfOptionsSoFar
  }

  selectAccount = (account: IAccount, index: number | undefined) =>
    this.setState(
      {
        enteredValue: account.name,
        selectedAccountNumber: account.accountNumber,
        activeOption: index,
        isActive: false,
        hasSearched: false,
      },
      () => {
        this.props.onChange(account.accountNumber)
      }
    )

  onInputFieldChange = (input: string) => {
    const enteredAccount = this.allAccounts.find(
      (account) =>
        (account.name.toLowerCase() === input.toLowerCase() ||
          account.accountNumber.toString() === input) &&
        account.accountNumber !== this.props.currentAccountNumber
    )

    this.setState(
      {
        isActive: true,
        activeOption: this.displayedAccounts().length > 0 ? 0 : undefined,
        hasSearched: true,
        selectedAccountNumber: enteredAccount?.accountNumber ?? undefined,
        enteredValue: enteredAccount?.name ?? input,
      },
      () => {
        this.props.onChange(enteredAccount?.accountNumber)
      }
    )
  }

  onMouseActivity = () => {
    this.setState({ shouldIgnoreMouse: false })
    window.removeEventListener('mousemove', this.onMouseActivity)
  }

  onArrowNavigation = (index: number) => {
    this.setState({ shouldIgnoreMouse: true })
    document
      .querySelector(
        `.account-option-button[data-id="${
          this.displayedAccounts()[index]?.id
        }"]`
      )
      ?.scrollIntoView({
        block: 'nearest',
      })
    window.addEventListener('mousemove', this.onMouseActivity)
  }

  onArrowUp = () => {
    const activatingOption = this.state.activeOption
      ? this.state.activeOption - 1
      : this.displayedAccounts().length - 1

    this.setState({ activeOption: activatingOption })
    this.onArrowNavigation(activatingOption)
  }

  onArrowDown = () => {
    const activatingOption =
      this.state.activeOption !== undefined &&
      this.state.activeOption !== this.displayedAccounts().length - 1
        ? this.state.activeOption + 1
        : 0

    this.setState({ activeOption: activatingOption })
    this.onArrowNavigation(activatingOption)
  }

  onEnter = () => {
    if (this.state.activeOption !== undefined) {
      const correspondingAccount =
        this.displayedAccounts()[this.state.activeOption]

      if (!correspondingAccount) return
      this.selectAccount(correspondingAccount, this.state.activeOption)
    }
  }

  handleKeyboardEvents = (e: KeyboardEvent) => {
    if (!this.state.isActive) return
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault()
        return this.onArrowUp()
      case 'ArrowDown':
        e.preventDefault()
        return this.onArrowDown()
      case 'Enter':
        return this.onEnter()
    }
  }

  selectOptionOnHover = (index: number | undefined) => {
    if (!this.state.shouldIgnoreMouse) {
      this.setState({ activeOption: index })
    }
  }

  render() {
    return (
      <>
        <Flyout
          className={classNames('account-select', this.props.className, {
            'full-width': this.props.fullWidth,
          })}
          isActive={this.state.isActive}
          setIsActive={(isActive: boolean) => {
            if (isActive !== this.state.isActive) this.setState({ isActive })
          }}
          trigger={
            <div className='input-wrapper'>
              <div
                className='color-swatch'
                onClick={() => this.inputRef.current?.focus()}
                style={{
                  backgroundColor: this.determineAccountColour(),
                }}
                aria-hidden
              />
              <InputField
                placeholder={this.props.placeholderText ?? 'Konto wählen'}
                ref={this.inputRef}
                className='account-select-input'
                value={this.state.enteredValue}
                onChange={this.onInputFieldChange}
                fullWidth
                {...this.props.inputFieldProps}
              />
            </div>
          }
        >
          <div className='account-list'>
            {!this.shouldSearch() ? (
              Object.keys(this.displayedAccountSegments()).map(
                (segmentTitle, segmentIndex) =>
                  this.displayedAccountSegments()[segmentTitle].length !== 0 ? (
                    <div key={segmentTitle}>
                      {Object.keys(
                        this.state.currentSuggestedAccounts
                      ).includes(segmentTitle) ? (
                        <div className='group-heading suggested-group'>
                          <img className='star' src={SuggestionStar} alt='' />
                          {segmentTitle}
                        </div>
                      ) : (
                        <div className='group-heading'>{segmentTitle}</div>
                      )}

                      {this.displayedAccountSegments()[segmentTitle].map(
                        (account, index) => {
                          const optionIndex =
                            index + this.numberOfOptionsSoFar(segmentIndex)
                          return (
                            <AccountOption
                              id={account.id}
                              key={account.id}
                              index={optionIndex}
                              isActive={this.state.isActive}
                              activeOption={this.state.activeOption}
                              enteredValue={this.state.enteredValue}
                              accountNumber={account.accountNumber}
                              name={account.name}
                              description={account.description}
                              colour={account.colour}
                              onClick={() =>
                                this.selectAccount(account, optionIndex)
                              }
                              onMouseEnter={() =>
                                this.selectOptionOnHover(optionIndex)
                              }
                              onMouseLeave={() =>
                                this.selectOptionOnHover(undefined)
                              }
                              onOpenAccountClicked={
                                this.props.openAccountPageModally
                                  ? () => {
                                      this.setState({ isActive: false })
                                      this.props.ledgerStore?.setNewestView(
                                        this.props.openAccountPageModally!(
                                          account.accountNumber
                                        )
                                      )
                                    }
                                  : undefined
                              }
                            />
                          )
                        }
                      )}
                    </div>
                  ) : null
              )
            ) : (
              <>
                {!this.state.enteredValue && (
                  <div className='group-heading'>Alle Konten</div>
                )}
                {this.displayedAccounts().map((account, index) => (
                  <AccountOption
                    id={account.id}
                    key={account.id}
                    index={index}
                    isActive={this.state.isActive}
                    activeOption={this.state.activeOption}
                    enteredValue={this.state.enteredValue}
                    accountNumber={account.accountNumber}
                    name={account.name}
                    description={account.description}
                    colour={account.colour}
                    onClick={() => this.selectAccount(account, index)}
                    onMouseEnter={() => this.selectOptionOnHover(index)}
                    onMouseLeave={() => this.selectOptionOnHover(undefined)}
                    onOpenAccountClicked={
                      this.props.openAccountPageModally
                        ? () =>
                            this.props.ledgerStore?.setNewestView(
                              this.props.openAccountPageModally!(
                                account.accountNumber
                              )
                            )
                        : undefined
                    }
                    hasSearched={this.state.hasSearched}
                  />
                ))}
              </>
            )}
          </div>
          <div className='divider' />
          {this.props.allowAccountCreation && (
            <AccountCreationOption
              openAssistant={() => {
                this.setState({ isCreatingAccount: true, isActive: false })
              }}
            />
          )}
        </Flyout>
        {this.props.allowAccountCreation && (
          <CreateAccountAssistant
            isOpen={this.state.isCreatingAccount ?? false}
            closeModal={() => this.setState({ isCreatingAccount: false })}
            onAccountCreated={(account) =>
              this.selectAccount(account, undefined)
            }
          />
        )}
      </>
    )
  }
}

export default AccountSelect
