import VendorInvoiceProvider from './VendorInvoiceContext'
import VendorInvoiceDetailMainPane from './VendorInvoiceDetailMainPane'
import VendorInvoiceDetailPreviewPane from './VendorInvoiceDetailPreviewPane'
import VendorInvoiceSplitView from './VendorInvoiceSplitView'

interface VendorInvoiceDetailViewProps {
  onDismiss: () => void
  invoiceId: string
}

const VendorInvoiceDetailView = (props: VendorInvoiceDetailViewProps) => (
  <VendorInvoiceProvider {...props}>
    <VendorInvoiceSplitView
      leftPane={<VendorInvoiceDetailMainPane />}
      rightPane={<VendorInvoiceDetailPreviewPane />}
    />
  </VendorInvoiceProvider>
)

export default VendorInvoiceDetailView
