import { Button } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import ICustomerInvoice, { QuoteStatus } from 'model/CustomerInvoice'
import CustomerInvoices from 'networking/CustomerInvoices'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useRootStore } from 'stores/RootStoreContext'

interface QuoteStatusActionsProps {
  existingInvoice: ICustomerInvoice
  setExistingInvoice: (invoice: ICustomerInvoice) => void
}

const QuoteStatusActions = (props: QuoteStatusActionsProps) => {
  const { authenticationStore } = useRootStore()
  const [currentlyUpdatingToStatus, setCurrentlyUpdatingToStatus] =
    useState<QuoteStatus | null>(null)

  const setQuoteStatus = async (updatedStatus: QuoteStatus) => {
    setCurrentlyUpdatingToStatus(updatedStatus)
    const updatedQuote = await CustomerInvoices.setQuoteStatus(
      updatedStatus,
      authenticationStore.organisationIdentifier,
      props.existingInvoice.id
    )
    props.setExistingInvoice(updatedQuote)
    setCurrentlyUpdatingToStatus(null)
  }

  const quoteStatus = props.existingInvoice.quoteStatus

  const history = useHistory()

  const navigateToInvoice = (invoiceId: string) => {
    const url = `/forderungen/detail/${invoiceId}`
    history.push(url)
  }

  if (props.existingInvoice.invoicesFromQuote?.length) {
    const [newestInvoiceCreated] =
      props.existingInvoice.invoicesFromQuote.slice(-1)
    return (
      <Button
        variant='quaternary'
        iconLeft='arrow-right'
        className='sidebar-action'
        disabled={false}
        tooltip='Navigiere zu der Forderung'
        onClick={() => navigateToInvoice(newestInvoiceCreated)}
      >
        Zur Forderung
      </Button>
    )
  }

  if (
    quoteStatus === QuoteStatus.Accepted ||
    quoteStatus === QuoteStatus.Rejected
  )
    return (
      <Button
        variant='quaternary'
        iconLeft='undo'
        className='sidebar-action'
        isLoading={currentlyUpdatingToStatus === QuoteStatus.Open}
        onClick={() => setQuoteStatus(QuoteStatus.Open)}
      >
        Rückgängig machen
      </Button>
    )

  return (
    <>
      <Button
        variant='quaternary'
        iconLeft='success'
        className='sidebar-action'
        isLoading={currentlyUpdatingToStatus === QuoteStatus.Accepted}
        onClick={() => setQuoteStatus(QuoteStatus.Accepted)}
      >
        Als akzeptiert markieren
      </Button>
      <Button
        variant='quaternary'
        iconLeft='error'
        className='sidebar-action'
        isLoading={currentlyUpdatingToStatus === QuoteStatus.Rejected}
        onClick={() => setQuoteStatus(QuoteStatus.Rejected)}
      >
        Als abgelehnt markieren
      </Button>
    </>
  )
}

export default observer(QuoteStatusActions)
