import { APIError } from '@nextbusiness/infinity-finance-api'
import { useNotificationCenter } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import './ProjectBoard.scss'
import ProjectBoardDragDropContext from './ProjectBoardDragDropContext'
import ProjectStageColumn from './ProjectStageColumn'

const ProjectBoard = () => {
  const { projectStore } = useRootStore()
  const notificationCentre = useNotificationCenter()

  useEffect(() => {
    projectStore.fetchAllProjects()
  }, [])

  const moveProject = async (projectId: string, stageId: string) => {
    try {
      await projectStore.moveProject(projectId, stageId)
    } catch (error) {
      notificationCentre.addNotification({
        children: (error as APIError).humanText('de'),
        variant: 'error',
      })
    }
  }

  return (
    <div className='project-board'>
      <ProjectBoardDragDropContext moveProject={moveProject}>
        {projectStore.projectStages.map((stage, index) => {
          const isDoneColumn = index === projectStore.projectStages.length - 1
          return (
            <ProjectStageColumn
              key={stage.id}
              stage={stage}
              showArchiveAction={isDoneColumn}
              isDefaultStage={index === 0}
            />
          )
        })}
      </ProjectBoardDragDropContext>
    </div>
  )
}

export default observer(ProjectBoard)
