import { RichSelect } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'

interface FiscalYearSelectProps {
  selectedFiscalYear: number
  setSelectedFiscalYear: (year: number) => void
}

const FiscalYearSelect = (props: FiscalYearSelectProps) => {
  const { accountStore } = useRootStore()
  return (
    <RichSelect
      options={accountStore.allFiscalYears?.map((fiscalYear) => ({
        label: `Geschäftsjahr ${fiscalYear.year + 1}`,
        value: fiscalYear.id,
      }))}
      value={
        accountStore.allFiscalYears?.find(
          (year) => year.year === props.selectedFiscalYear
        )?.id
      }
      onChange={(id) => {
        const year = accountStore.allFiscalYears?.find(
          (year) => year.id === id
        )?.year
        return props.setSelectedFiscalYear(year as number)
      }}
    />
  )
}

export default observer(FiscalYearSelect)
