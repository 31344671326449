import { Flex, FlexProps } from '@nextbusiness/infinity-ui'
import React from 'react'
import './SettingsFieldList.scss'

interface SettingsFieldListProps {
  children?: React.ReactNode
  gap?: FlexProps['gap']
}

const SettingsFieldList = (props: SettingsFieldListProps) => (
  <Flex
    direction='vertical'
    verticalAlignment='stretch'
    className='settings-field-list'
    gap={props.gap}
  >
    {props.children}
  </Flex>
)

export default SettingsFieldList
