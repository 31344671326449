import { Flex, NonIdealState } from '@nextbusiness/infinity-ui'
import React from 'react'
import { BackendError } from '../../../libs/networking/Errors'

interface VendorInvoiceDetailErrorViewProps {
  onRetry: () => void
  onDismiss: () => void
  error?: BackendError
}

const VendorInvoiceDetailErrorView = (
  props: VendorInvoiceDetailErrorViewProps
) => (
  <Flex
    className='vendor-invoice-detail errored'
    fillContainer
    horizontalAlignment='center'
    verticalAlignment='center'
  >
    <NonIdealState
      title='Rechnung kann nicht angezeigt werden'
      icon='error'
      actions={[
        {
          children: 'Erneut versuchen',
          variant: 'primary',
          onClick: () => props.onRetry(),
        },
        {
          children: 'Schliessen',
          onClick: () => props.onDismiss(),
        },
      ]}
    >
      {props.error?.displayMessage ??
        'Beim Laden der Rechnungsdetails ist ein Fehler aufgetreten. Bitte versuche es erneut.'}
    </NonIdealState>
  </Flex>
)

export default VendorInvoiceDetailErrorView
