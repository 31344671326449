import { Button, Spacer } from '@nextbusiness/infinity-ui'
import PresetModal from 'invoices/presets/PresetModal'
import { useState } from 'react'
import MagicSheetTitleBar from '../../components/magic-sheet/MagicSheetTitleBar'
import CustomerInvoicesMoreMenu from './CustomerInvoicesMoreMenu'

interface CustomerInvoicesHeaderProps {
  title: string
  isQuote?: boolean
}

const CustomerInvoicesHeader = (props: CustomerInvoicesHeaderProps) => {
  const [isPresetSelectionModalOpen, setIsPresetSelectionModalOpen] =
    useState<boolean>(false)

  return (
    <MagicSheetTitleBar title={props.title}>
      <CustomerInvoicesMoreMenu />
      <Spacer size='tiny' direction='horizontal' />
      <Button
        iconLeft='plus'
        variant='primary'
        onClick={() => setIsPresetSelectionModalOpen(true)}
      >
        Erstellen
      </Button>
      <PresetModal
        isOpen={isPresetSelectionModalOpen}
        closeModal={() => setIsPresetSelectionModalOpen(false)}
        isNewDocumentQuote={props.isQuote ?? false}
      />
    </MagicSheetTitleBar>
  )
}

export default CustomerInvoicesHeader
