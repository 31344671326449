import { ITransaction } from '@nextbusiness/infinity-finance-api'
import { DateDisplayFormatOptions, Flex, Text } from '@nextbusiness/infinity-ui'
import { SpecialAccount } from 'components/transactions/SpecialAccounts'
import TransactionAmount from 'components/transactions/TransactionAmount'
import { IAccount } from 'model/Account'
import AccountUtilities from 'utility/AccountUtilities'
import TransactionAccountButton from './TransactionAccountButton'
import TransactionIndicators from './TransactionIndicators'

export interface TransactionViewProps {
  transaction: ITransaction
  currentAccount: IAccount | SpecialAccount
  interactive?: boolean
  useGrossAmount?: boolean
  indicators?: boolean
}

const TransactionView = (props: TransactionViewProps) => {
  const { description, creditAccount, debitAccount } = props.transaction

  const contraAccount =
    debitAccount === props.currentAccount.accountNumber
      ? creditAccount
      : debitAccount
  const date = new Date(props.transaction.date).toLocaleString(
    'de-CH',
    DateDisplayFormatOptions
  )
  const amount = props.useGrossAmount
    ? props.transaction.grossAmount
    : props.transaction.amount

  return (
    <Flex className='upper-half' verticalAlignment='center'>
      <Text className='date' type='inline'>
        {date}
      </Text>
      <Text className='description' type='inline'>
        {description}
      </Text>
      {props.indicators && (
        <TransactionIndicators transaction={props.transaction} />
      )}
      <TransactionAccountButton
        displayedAccount={contraAccount}
        interactive={props.interactive}
      />
      <TransactionAmount
        originalCurrency={props.transaction.originalCurrency}
        amount={amount}
        shouldDisplayNegativeAmount={AccountUtilities.shouldDisplayNegativeAmount(
          props.currentAccount,
          props.transaction
        )}
      />
    </Flex>
  )
}

export default TransactionView
