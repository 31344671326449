import { Button, Card, Flex, Text } from '@nextbusiness/infinity-ui'
import { useState } from 'react'
import DeletePresetModal from './DeletePresetModal'
import './PresetSettingsCard.scss'

interface PresetSettingsCardProps {
  presetId: string
  title: string
}

const PresetSettingsCard = (props: PresetSettingsCardProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)

  return (
    <>
      <Card className='preset-settings-card'>
        <Flex
          direction='horizontal'
          verticalAlignment='center'
          horizontalAlignment='space-between'
        >
          <Text className='preset-settings-card-title' type='inline'>
            {props.title}
          </Text>
          <div>
            <Button
              iconOnly='delete'
              onClick={() => setIsDeleteModalOpen(true)}
              variant='quaternary'
              tooltip='Vorlage löschen'
            />
          </div>
        </Flex>
      </Card>
      <DeletePresetModal
        isOpen={isDeleteModalOpen}
        closeModal={() => setIsDeleteModalOpen(false)}
        presetTitle={props.title}
        presetId={props.presetId}
      />
    </>
  )
}

export default PresetSettingsCard
