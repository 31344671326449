import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import './SplitView.scss'
import SplitViewPane from './SplitViewPane'

interface SplitViewProps {
  className?: string
  leftPane?: React.ReactNode
  rightPane?: React.ReactNode
  defaultDividerPosition?: number
  snapLeftAt?: number
  snapRightAt?: number
  breakpoints?: [string, number][]
}

const SplitView = (props: SplitViewProps) => {
  const { snapLeftAt, snapRightAt } = props

  const dividerRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const [isDragging, setIsDragging] = useState<boolean>(false)
  const [dividerPosition, setDividerPosition] = useState<number>(
    props.defaultDividerPosition ?? 60
  )

  const lastCommittedPosition = useRef<number>(dividerPosition)
  const isMouseDown = useRef<boolean>(false)

  const [isSnappingIntoPlace, setIsSnappingIntoPlace] = useState<boolean>(false)
  const isSnappingEnabled =
    snapLeftAt !== undefined || snapRightAt !== undefined
  const isSnappingLeft =
    isSnappingEnabled && dividerPosition < (props.snapLeftAt ?? 0)
  const isSnappingRight =
    isSnappingEnabled && dividerPosition > (props.snapRightAt ?? 0)

  const isSnappingFromEdge =
    lastCommittedPosition.current === 0 || lastCommittedPosition.current === 100

  const currentBreakpoint = useMemo(() => {
    if (!props.breakpoints) return undefined

    const breakpoint = props.breakpoints.reduce<string | undefined>(
      (prev, [name, minX]) => {
        if (dividerPosition >= minX) return name
        return prev
      },
      undefined
    )

    return breakpoint
  }, [props.breakpoints, dividerPosition])

  const handleMouseDown = useCallback(() => {
    isMouseDown.current = true

    setIsDragging(true)
    setIsSnappingIntoPlace(false)
  }, [])

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (!isDragging || !dividerRef.current || !containerRef.current) return

      // Prevent unwanted text selection while dragging
      event.preventDefault()

      const containerRect = containerRef.current.getBoundingClientRect()
      const dividerRect = dividerRef.current.getBoundingClientRect()

      const mouseX = event.clientX - containerRect.left - dividerRect.width / 2
      const dividerPosition = (mouseX / containerRect.width) * 100

      if (dividerPosition < 0 || dividerPosition > 100) return

      requestAnimationFrame(() => {
        // Because we don't know at what point we'll get an animation frame,
        // we have to check if the mouse button is still down to avoid a race
        // condition with the snapping behaviour on mouse up.
        if (!isMouseDown.current) return

        setDividerPosition(dividerPosition)
      })
    },
    [isDragging, isMouseDown, props.snapLeftAt, props.snapRightAt]
  )

  const handleMouseUp = useCallback(() => {
    // Don't trigger mouse up event if we haven't started dragging on the
    // resize handle.
    if (!isMouseDown.current) return

    isMouseDown.current = false
    setIsDragging(false)

    const wasDraggingFromCorner =
      lastCommittedPosition.current === 0 ||
      lastCommittedPosition.current === 100

    const isInLeftSnapRegion = snapLeftAt && dividerPosition < snapLeftAt
    const isInRightSnapRegion = snapRightAt && dividerPosition > snapRightAt

    if (isInLeftSnapRegion && isSnappingEnabled) {
      lastCommittedPosition.current = wasDraggingFromCorner ? snapLeftAt : 0
    } else if (isInRightSnapRegion && isSnappingEnabled) {
      lastCommittedPosition.current = wasDraggingFromCorner ? snapRightAt : 100
    } else {
      lastCommittedPosition.current = dividerPosition
    }

    setDividerPosition(lastCommittedPosition.current)
    setIsSnappingIntoPlace(true)
  }, [dividerPosition, snapLeftAt, snapRightAt])

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [handleMouseMove, handleMouseUp])

  return (
    <div
      className={classNames(
        'ui-split-view',
        props.className,
        {
          'is-dragging': isDragging,
          'is-snapping-into-place': isSnappingIntoPlace,
        },
        currentBreakpoint && `ui-split-view--breakpoint-${currentBreakpoint}`
      )}
      ref={containerRef}
    >
      <SplitViewPane
        side='left'
        isSnappingFromEdge={isSnappingFromEdge}
        showSnapIndicator={isSnappingLeft}
        width={dividerPosition}
      >
        {props.leftPane}
      </SplitViewPane>
      <div
        className='ui-split-view-divider'
        ref={dividerRef}
        onMouseDown={handleMouseDown}
        aria-hidden
      >
        <div className='ui-split-view-divider-handle' />
      </div>
      <SplitViewPane
        side='right'
        isSnappingFromEdge={isSnappingFromEdge}
        showSnapIndicator={isSnappingRight}
      >
        {props.rightPane}
      </SplitViewPane>
    </div>
  )
}

export default React.memo(SplitView)
