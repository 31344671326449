import { IconItem } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import { forwardRef, useEffect, useMemo, useState } from 'react'
import './FilterList.scss'
import FilterListOption from './FilterListOption'
import FilterSearchInput from './FilterSearchInput'

export interface FilterItem<T = any> {
  icon?: IconItem
  label: string
  value: T
}

interface FilterListProps<T = any> {
  searchQuery: string
  onSearchQueryChanged: (searchQuery: string) => void
  items?: FilterItem[]
  selectedValue?: T | null
  onValueSelected?: (value: T | null) => void
  closeMenu: () => void
  sortAlphabetically?: boolean
  wide?: boolean
}

const FilterList = forwardRef<HTMLInputElement, FilterListProps>(
  function FilterList(props: FilterListProps, ref) {
    const { searchQuery, onSearchQueryChanged } = props

    const listItems = useMemo(() => {
      if (!searchQuery) return props.items
      return props.items?.filter((item) =>
        item.label.toLowerCase().includes(searchQuery.toLowerCase())
      )
    }, [props.items, searchQuery])
    const sortedItems = useMemo(() => {
      return listItems?.toSorted((a, b) => {
        const hasSelectedA = props.selectedValue === a.value
        const hasSelectedB = props.selectedValue === b.value
        if (hasSelectedA && !hasSelectedB) return -1
        if (!hasSelectedA && hasSelectedB) return 1
        if (props.sortAlphabetically) {
          return a.label.localeCompare(b.label)
        } else {
          return 0
        }
      })
    }, [listItems, props.selectedValue, props.sortAlphabetically])

    const numberOfItems = listItems?.length ?? 0

    const selectedItemIndex = sortedItems?.findIndex(
      (item) => item.value === props.selectedValue
    )
    const [highlightedItemIndex, setHighlightedItemIndex] = useState<number>(
      selectedItemIndex ?? 0
    )
    const highlightedItemValue = sortedItems?.[highlightedItemIndex]?.value

    const selectValue = (value: string) => {
      if (value === props.selectedValue) {
        props.onValueSelected?.(null)
      } else {
        props.onValueSelected?.(value)
      }
      props.closeMenu()
    }

    useEffect(() => {
      setHighlightedItemIndex(selectedItemIndex ?? 0)
    }, [selectedItemIndex])

    const moveDown = () => {
      if (highlightedItemIndex < numberOfItems - 1) {
        setHighlightedItemIndex(highlightedItemIndex + 1)
      } else {
        setHighlightedItemIndex(0)
      }
    }
    const moveUp = () => {
      if (highlightedItemIndex > 0) {
        setHighlightedItemIndex(highlightedItemIndex - 1)
      } else {
        setHighlightedItemIndex(numberOfItems - 1)
      }
    }
    const selectHighlightedItem = () => {
      const value = sortedItems?.[highlightedItemIndex]?.value
      if (value) selectValue(value)
    }

    useEffect(() => {
      if (searchQuery) setHighlightedItemIndex(0)
    }, [searchQuery])

    return (
      <div className={classNames('ui-filter-list', { wide: props.wide })}>
        <FilterSearchInput
          ref={ref}
          searchQuery={searchQuery}
          onSearchQueryChanged={onSearchQueryChanged}
          onArrowUp={moveUp}
          onArrowDown={moveDown}
          onEnter={selectHighlightedItem}
        />
        <div className='ui-filter-list-options'>
          {sortedItems?.map((item) => {
            const isSelected = props.selectedValue === item.value
            const isHighlighted = highlightedItemValue === item.value
            return (
              <FilterListOption
                key={item.value}
                isSelected={isSelected}
                isHighlighted={isHighlighted}
                icon={item.icon}
                onSelected={() => selectValue(item.value)}
              >
                {item.label}
              </FilterListOption>
            )
          })}
        </div>
      </div>
    )
  }
)

export default FilterList
