import { sprig } from '@sprig-technologies/sprig-browser'

class SprigIntegration {
  public static sprig: WindowSprig | null = null

  public static initialise() {
    this.sprig = sprig.configure({
      environmentId: import.meta.env.VITE_SPRIG_TOKEN,
    })
  }

  public static get shouldInitialise() {
    return import.meta.env.VITE_SPRIG_TOKEN !== undefined
  }
}

export default SprigIntegration
