import { Button, Checkbox, Heading, Text } from '@nextbusiness/infinity-ui'
import { useState } from 'react'
import { TemplateInspectorProps } from '../TemplateInspector'
import TemplateInspectorGroup from '../TemplateInspectorGroup'
import InvoiceNumberingChangeStartDialog from './InvoiceNumberingChangeStartDialog'

const TemplateInspectorNumbering = (props: TemplateInspectorProps) => {
  const [isNumberingDialogOpen, setIsNumberingDialogOpen] =
    useState<boolean>(false)

  return (
    <TemplateInspectorGroup>
      <Heading type='h4'>Fortlaufende Nummerierung</Heading>
      <Checkbox
        isChecked={props.template.showCustomerInvoiceNumber}
        label='Rechnungsnummer'
        onChange={(isChecked) =>
          props.modifyTemplate({ showCustomerInvoiceNumber: isChecked })
        }
      />
      <Checkbox
        isChecked={props.template.showQuoteNumber}
        label='Offertennummer'
        onChange={(isChecked) =>
          props.modifyTemplate({ showQuoteNumber: isChecked })
        }
      />
      <Text variant='subtle'>
        Bei Bedarf kannst du ändern, bei welcher Zahl die Nummerierung beginnen
        soll.
      </Text>
      <Button
        iconLeft='numbers-input-form'
        onClick={() => setIsNumberingDialogOpen(true)}
      >
        Anfangszahl ändern
      </Button>
      <InvoiceNumberingChangeStartDialog
        isOpen={isNumberingDialogOpen}
        onDismiss={() => setIsNumberingDialogOpen(false)}
      />
    </TemplateInspectorGroup>
  )
}

export default TemplateInspectorNumbering
