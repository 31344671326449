import { APIError, ErrorCode } from '@nextbusiness/infinity-finance-api'
import {
  Heading,
  Spacer,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { IVendorInvoice } from '../../../model/VendorInvoice'
import { useRootStore } from '../../../stores/RootStoreContext'
import ExtendedInvoiceListSection from '../../shared-components/large-invoice-list/ExtendedInvoiceListSection'
import UnpaidVendorInvoiceItem from './UnpaidInvoiceItem'
import './UnpaidVendorInvoices.scss'

interface UnpaidVendorInvoicesProps {
  openVendorInvoice: (invoice: IVendorInvoice) => void
}

const UnpaidVendorInvoices = (props: UnpaidVendorInvoicesProps) => {
  const { authenticationStore, vendorInvoiceStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [hasLoadedUnpaidInvoices, setHasLoadedUnpaidInvoices] =
    useState<boolean>(false)

  const getUnpaidInvoices = async () => {
    if (!authenticationStore.organisationIdentifier)
      return authenticationStore.logout()
    try {
      await vendorInvoiceStore.loadUnpaidInvoices()
      setHasLoadedUnpaidInvoices(true)
    } catch (error: any) {
      if (error instanceof APIError) {
        if (error.code === ErrorCode.Unauthorised) return
      }
      notificationCenter.addNotification({
        children:
          error.displayMessage ??
          'Die Rechnungen konnten nicht geladen werden.',
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    getUnpaidInvoices()
  }, [])

  return (
    <div className='unpaid-vendor-invoices'>
      <Heading type='h3'>Unbezahlte Rechnungen</Heading>
      <Spacer size='tiny' />
      <ExtendedInvoiceListSection
        isLoading={!hasLoadedUnpaidInvoices}
        isEmpty={vendorInvoiceStore.unpaidInvoices?.length === 0}
        emptyStateContent='Alle Rechnungen bezahlt'
      >
        {vendorInvoiceStore.unpaidInvoices?.map((invoice: IVendorInvoice) => (
          <UnpaidVendorInvoiceItem
            key={invoice.id}
            invoice={invoice}
            onClick={() => props.openVendorInvoice(invoice)}
          />
        ))}
      </ExtendedInvoiceListSection>
    </div>
  )
}

export default observer(UnpaidVendorInvoices)
