import { ErrorCode } from '@nextbusiness/infinity-finance-api'
import { Flex, NonIdealState, Text } from '@nextbusiness/infinity-ui'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import Preferences from 'networking/Preferences'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ErrorType } from '../libs/networking/Errors'
import Authentication, {
  Current,
  DASHBOARD_URL,
  REDIRECT_URL,
} from '../networking/Authentication'
import { useRootStore } from '../stores/RootStoreContext'
import { useURLQuery } from '../utility/hooks'

const AuthenticationPage = () => {
  const { authenticationStore } = useRootStore()
  const history = useHistory()

  const [errorMessage, setErrorMessage] = useState<string>()
  const query = useURLQuery()

  const oneTimeToken = query.get('token')
  const fabricId = query.get('organisationIdentifier')

  const authenticationTriggered = useRef(false)

  useEffect(() => {
    if (!authenticationStore.isHydrated) return
    if (!oneTimeToken || !fabricId) return

    if (authenticationStore.fabricOrganisationIdentifier !== fabricId) {
      authenticationStore.logout()
      authenticationStore.fabricOrganisationIdentifier = fabricId
    }

    if (authenticationTriggered.current) return
    authenticationTriggered.current = true

    authenticateApp(oneTimeToken, fabricId)
  }, [authenticationStore.isHydrated, oneTimeToken, fabricId])

  const authenticateApp = async (oneTimeToken: string, fabricId: string) => {
    try {
      const credentials = await Authentication.authenticate(
        fabricId,
        oneTimeToken
      )
      if (!credentials.applicationAccessToken || !credentials.userDataToken)
        return

      Current.credentials = credentials

      const organisation =
        await Preferences.getOrganisationPreferencesByFabricId(fabricId)
      const organisationIdentifier = organisation.id

      runInAction(async () => {
        authenticationStore.fabricOrganisationIdentifier = fabricId
        authenticationStore.setCurrentOrganisation(
          organisationIdentifier,
          fabricId
        )
        authenticationStore.setCurrentTokens(
          credentials.applicationAccessToken,
          credentials.userDataToken,
          credentials.sessionToken,
          credentials.hmac
        )
        await authenticationStore.onLogin()
        queueMicrotask(() => {
          history.push('/')
        })
      })

      setErrorMessage('')
    } catch (error: any) {
      if (error.code === ErrorCode.Unauthorised) {
        document.location.href = REDIRECT_URL()
        return
      }
      switch (error.type) {
        case ErrorType.NotFound:
          if (error.message === 'This organisation does not exist')
            return setErrorMessage(
              'Die Organisation konnte nicht gefunden werden.'
            )
          else return setErrorMessage('Die App konnte nicht gefunden werden.')
        default:
          setErrorMessage(
            error.message ??
              'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut.'
          )
      }
    }
  }

  if (authenticationStore.isAuthenticated && !errorMessage) return null
  if (!oneTimeToken || !fabricId) {
    document.location.href = REDIRECT_URL()
    return null
  }

  return errorMessage ? (
    <Flex
      fillContainer
      horizontalAlignment='center'
      verticalAlignment='center'
      style={{ height: 'calc(100vh - 5.4rem)' }}
    >
      <NonIdealState
        icon='lock'
        title='Infinity Finance konnte nicht gestartet werden.'
        actions={[
          {
            children: 'Zurück zum Dashboard',
            onClick: () => {
              document.location.href = DASHBOARD_URL
            },
            variant: 'tertiary',
          },
          {
            children: 'Erneut versuchen',
            onClick: () => {
              document.location.href = REDIRECT_URL()
            },
          },
        ]}
      >
        <Flex direction='vertical'>
          <Text type='paragraph'>{errorMessage}</Text>
        </Flex>
      </NonIdealState>
    </Flex>
  ) : null
}

export default observer(AuthenticationPage)
