import useAutoSuggestVAT from 'ledger/transactions/creator/hooks/useAutoSuggestVAT'
import { useTransactionEditorContext } from './TransactionEditorContext'
import useEditorKeybindings from './hooks/useEditorKeybindings'
import useMarkUnsavedEdit from './hooks/useMarkUnsavedEdit'
import { useRootStore } from 'stores/RootStoreContext'
import { observer } from 'mobx-react'

const TransactionEditorBehaviours = () => {
  const { accountStore, preferencesStore } = useRootStore()
  const { draftTransaction, updateDraftTransaction, contraAccountSide } =
    useTransactionEditorContext()

  useEditorKeybindings()
  useAutoSuggestVAT(
    draftTransaction,
    updateDraftTransaction,
    contraAccountSide,
    accountStore,
    preferencesStore
  )
  useMarkUnsavedEdit()

  return null
}

export default observer(TransactionEditorBehaviours)
