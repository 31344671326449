import { Button } from '@nextbusiness/infinity-ui'
import { INewCustomerInvoice } from 'model/CustomerInvoice'
import { useState } from 'react'
import CreatePresetModal from './CreatePresetModal'

interface CreatePresetActionProps {
  document: Partial<INewCustomerInvoice>
}

const CreatePresetAction = (props: CreatePresetActionProps) => {
  const [isCreatePresetModalOpen, setIsCreatePresetModalOpen] =
    useState<boolean>(false)

  return (
    <>
      <Button
        variant='quaternary'
        iconLeft='project-setup-2'
        className='sidebar-action'
        onClick={() => setIsCreatePresetModalOpen(true)}
        tooltip='Speichere eine Vorlage, um neue Offerten und Forderungen basierend auf diesem Dokument erstellen zu können.'
      >
        Als Vorlage speichern
      </Button>
      <CreatePresetModal
        isOpen={isCreatePresetModalOpen}
        closeModal={() => setIsCreatePresetModalOpen(false)}
        document={props.document}
      />
    </>
  )
}

export default CreatePresetAction
