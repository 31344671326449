import LoadingScreen from 'base-components/layout/LoadingScreen'
import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import { DateTime } from 'luxon'
import { runInAction } from 'mobx'
import { inject, observer } from 'mobx-react'
import { IFiscalYear } from 'model/FiscalYear'
import FinancialClose from 'networking/FinancialClose'
import DocumentsPage from 'pages/DocumentsPage'
import { Component } from 'react'
import AccountStore from 'stores/AccountStore'
import AuthenticationStore from 'stores/AuthenticationStore'
import FinancialCloseStore from 'stores/FinancialCloseStore'
import PreferencesStore from 'stores/PreferencesStore'
import AssistantStepper, { AssistantStep } from './AssistantStepper'
import ClosingErrorModal from './components/ClosingErrorModal'
import ClosingInProgressModal from './components/ClosingInProgressModal'
import ClosingAccrualsStep from './steps/ClosingAccrualsStep'
import ClosingDepreciationsStep from './steps/ClosingDepreciationsStep'
import ClosingPreCloseChecks from './steps/ClosingPreCloseChecks'
import ClosingProfitCloseStep from './steps/ClosingProfitCloseStep'
import ClosingReviewStep from './steps/ClosingReviewStep'
import ClosingVATCheckStep from './steps/ClosingVATCheckStep'
import ClosingWelcomeStep from './steps/ClosingWelcomeStep'

interface FinancialClosingAssistantProps {
  accountStore?: AccountStore
  financialCloseStore?: FinancialCloseStore
  authenticationStore?: AuthenticationStore
  preferencesStore?: PreferencesStore
  page: DocumentsPage
}

interface FinancialClosingAssistantState {
  hasLoadedSettings?: boolean
  currentStepId: string
  isPerfomingClosing?: boolean
  isShowingClosingError?: boolean
  closingError?: Error
  dueFiscalYear?: IFiscalYear
  requiresVATSteps?: boolean
}

@inject(
  'accountStore',
  'financialCloseStore',
  'authenticationStore',
  'preferencesStore'
)
@observer
class FinancialClosingAssistant extends Component<
  FinancialClosingAssistantProps,
  FinancialClosingAssistantState
> {
  steps = (): AssistantStep[] => {
    const allSteps = [
      { id: 'welcome', displayTitle: 'Willkommen' },
      { id: 'accruals', displayTitle: 'Abgrenzungen' },
      { id: 'depreciations', displayTitle: 'Abschreibungen' },
      { id: 'vat', displayTitle: 'Mehrwertsteuer' },
      { id: 'profitclose', displayTitle: 'Erfolgsverbuchung' },
      { id: 'checks', displayTitle: 'Checks' },
      { id: 'confirmation', displayTitle: 'Überprüfen' },
    ]
    if (!this.state.requiresVATSteps) {
      return allSteps.filter((step) => step.id !== 'vat')
    }
    return allSteps
  }

  state: FinancialClosingAssistantState = {
    currentStepId: 'welcome',
  }

  componentDidMount() {
    this.loadSettings()
  }

  private async loadSettings() {
    await Promise.all([
      this.props.financialCloseStore?.loadSettings(),
      this.props.accountStore?.getAllFiscalYears(),
      this.props.preferencesStore?.loadPreferences(),
    ])
    const dueFiscalYear = this.props.accountStore?.allFiscalYears?.find(
      (fiscalYear) => !fiscalYear.isClosed && fiscalYear.to < Date.now()
    )
    this.setState({
      hasLoadedSettings: true,
      dueFiscalYear: dueFiscalYear,
      requiresVATSteps: !this.shouldSkipVATStep(),
    })
  }

  public get dueFiscalYear(): IFiscalYear | null {
    return this.state.dueFiscalYear ?? null
  }

  public get closingDate(): DateTime | null {
    const endDate = this.dueFiscalYear?.to
    return endDate ? DateTime.fromMillis(endDate) : null
  }

  public get formattedClosingDate(): string {
    const closingDate = this.closingDate
    if (!closingDate) return 'Abschlusstag'

    return closingDate.toLocaleString({
      dateStyle: 'long',
    })
  }

  public async performClosing() {
    this.setState({ isPerfomingClosing: true })
    try {
      const updatedFinancialClose = await FinancialClose.closeFiscalYear(
        this.props.authenticationStore!.organisationIdentifier
      )
      await this.props.accountStore?.getAllFiscalYears()
      await this.props.accountStore?.getFiscalYear()

      runInAction(() => {
        this.props.financialCloseStore!.financialCloseSettings =
          updatedFinancialClose
      })
      this.setState({ isPerfomingClosing: false })
      this.props.page.popCurrentView()
    } catch (error) {
      this.setState({
        closingError: error as Error,
        isShowingClosingError: true,
      })
    } finally {
      this.setState({ isPerfomingClosing: false })
    }
  }

  private viewForStep(stepId: string) {
    switch (stepId) {
      case 'welcome':
        return <ClosingWelcomeStep assistant={this} />
      case 'accruals':
        return <ClosingAccrualsStep assistant={this} />
      case 'depreciations':
        return <ClosingDepreciationsStep assistant={this} />
      case 'vat':
        return <ClosingVATCheckStep assistant={this} />
      case 'profitclose':
        return <ClosingProfitCloseStep assistant={this} />
      case 'checks':
        return <ClosingPreCloseChecks assistant={this} />
      case 'confirmation':
        return <ClosingReviewStep assistant={this} />
    }
  }

  private renderCurrentAssistantStep() {
    if (!this.state?.hasLoadedSettings) return <LoadingScreen />
    if (!this.dueFiscalYear) return <div>Kein Jahresabschluss fällig</div>

    const step = this.state.currentStepId
    return (
      <>
        <AssistantStepper steps={this.steps()} currentStepId={step} />
        {this.viewForStep(step)}
      </>
    )
  }

  private canNavigateForward = (): boolean =>
    this.state.currentStepId !== 'confirmation'

  private canNavigateBackwards = (): boolean =>
    this.state.currentStepId !== 'welcome'

  public shouldSkipVATStep = (): boolean => {
    if (!this.props.preferencesStore?.organisationPreferences) return false
    return !this.props.preferencesStore.organisationPreferences.VAT
  }

  public nextStep = () => {
    if (!this.canNavigateForward()) return
    this.advanceStep(1)
  }

  public previousStep = () => {
    if (!this.canNavigateBackwards()) return
    this.advanceStep(-1)
  }

  private advanceStep = (by: number) => {
    const nextStepId = this.nextStepId(by)
    if (!nextStepId) return
    this.setState({
      currentStepId: nextStepId,
    })
  }

  private nextStepId(by = 1) {
    const steps = this.steps()
    const currentStepIndex = steps.findIndex(
      (step) => step.id === this.state.currentStepId
    )
    const nextStepIndex = currentStepIndex + by
    if (nextStepIndex < 0 || nextStepIndex >= steps.length) return null

    return this.steps()[nextStepIndex].id
  }

  render() {
    return (
      <MagicSheetPage
        className='assistant-page financial-close-assistant'
        noTrailingNegativeSpace
      >
        <MagicSheetContainer>
          {this.renderCurrentAssistantStep()}
        </MagicSheetContainer>
        <ClosingInProgressModal
          isOpen={this.state.isPerfomingClosing}
          onDismiss={() => this.setState({ isPerfomingClosing: false })}
        />
        {this.state.closingError && (
          <ClosingErrorModal
            isOpen={this.state.isShowingClosingError}
            onDismiss={() => this.setState({ isShowingClosingError: false })}
            error={this.state.closingError}
          />
        )}
      </MagicSheetPage>
    )
  }
}

export default FinancialClosingAssistant
