import { Finance } from '@nextbusiness/infinity-finance-api'
import { Flex } from '@nextbusiness/infinity-ui'
import useAPIQuery from 'libs/networking/APIQuery'
import { useTransactionEditorContext } from '../TransactionEditorContext'
import LiveAccountingAdditionalInfo from './LiveAccountingAdditionalInfo'
import LiveAccountingBank from './LiveAccountingBank'
import LiveAccountingCounterparty from './LiveAccountingCounterparty'
import LiveAccountingIBAN from './LiveAccountingIBAN'
import LiveAccountingReference from './LiveAccountingReference'
import LiveAccountingRemittanceInfo from './LiveAccountingRemittanceInfo'
import LiveAccountingTransactionKind from './LiveAccountingTransactionKind'
import './TransactionLiveAccountingDetails.scss'
import TransactionManualReconciliation from './reconciliation/TransactionManualReconciliation'

const TransactionLiveAccountingDetails = () => {
  const { originalTransaction } = useTransactionEditorContext()
  const [details, isLoading, error] = useAPIQuery(
    () => Finance.Ledger.getBankingTransaction(originalTransaction.id),
    { errorStateOnly: true }
  )

  if (error)
    return (
      <div className='live-accounting-details error'>
        <Flex gap='small' className='details'>
          {error.humanText('de')}
        </Flex>
      </div>
    )
  if (isLoading || !details)
    return (
      <div className='live-accounting-details loading'>
        <div className='loader' />
      </div>
    )

  const { transaction } = details

  return (
    <div className='live-accounting-details'>
      <Flex gap='small' className='details' verticalAlignment='center'>
        <LiveAccountingCounterparty details={transaction} />
        <LiveAccountingIBAN details={transaction} />
        <LiveAccountingBank details={transaction} />
        <LiveAccountingTransactionKind details={transaction} />
        <LiveAccountingAdditionalInfo details={transaction} />
        <LiveAccountingRemittanceInfo details={transaction} />
        <LiveAccountingReference details={transaction} />
        <TransactionManualReconciliation details={transaction} />
      </Flex>
    </div>
  )
}

export default TransactionLiveAccountingDetails
