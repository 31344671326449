import { Flex, Heading } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import MoveToDesktopGraphic from '../../assets/mobile-preview/finance-move-to-desktop.png'
import MobilePreview from '../../assets/mobile-preview/phone-buchen.png'
import './MobileHint.scss'

interface MobileHintProps {
  title: string
  showMobileAppTeaser?: boolean
  inline?: boolean
}

const MobileHint = (props: MobileHintProps) => (
  <Flex
    className={classNames('mobile-hint', { inline: props.inline })}
    fillContainer
    verticalAlignment='center'
    horizontalAlignment='center'
    direction='vertical'
  >
    <div className='mobile-hint-container'>
      <img src={MoveToDesktopGraphic} className='move-to-desktop' alt='' />
      <Heading className='heading' type='h2'>
        {props.title}
      </Heading>
      {props.showMobileAppTeaser && (
        <div className='app-preview'>
          <img src={MobilePreview} className='app-preview-graphic' alt='' />
          <div className='app-preview-depiction'>
            <p>
              Mit der Mobile App kannst du deine Buchhaltung bald auch am Handy
              erledigen.
            </p>
            <span className='coming-soon-badge'>Bald verfügbar</span>
          </div>
        </div>
      )}
    </div>
  </Flex>
)

export default MobileHint
