import { ArcElement, ChartData, Chart as ChartJS } from 'chart.js'
import { IExpensesOrIncome } from 'model/FinancialHealth'
import { Doughnut } from 'react-chartjs-2'
import Utilities from 'utility/Utilities'

interface ExpensesOrIncomePieChartProps {
  expensesOrIncome: IExpensesOrIncome
  type: 'expense' | 'income'
}

ChartJS.register(ArcElement)

const ExpensesOrIncomePieChart = (props: ExpensesOrIncomePieChartProps) => {
  const expensesOrIncomeValues =
    props.expensesOrIncome.accountsCurrentYear?.map(
      (account) => account.balance
    ) ?? []
  const expensesOrIncomeColours =
    props.expensesOrIncome.accountsCurrentYear?.map(
      (account) => account.colour || '#DDDDDD'
    ) ?? []

  const data = {
    datasets: [
      {
        label: 'Expenses',
        data: expensesOrIncomeValues,
        backgroundColor: expensesOrIncomeColours,
        offset: 0,
        spacing: 0,
        borderWidth: 0,
        cutout: '85%',
      },
    ],
    /** This typecasting is necessary to allow for cutout property
     * which exists in chart.js but is missing in types. */
  } as unknown as ChartData<'doughnut', number[], unknown>

  return (
    <div className='expenses-or-income-pie-chart'>
      <Doughnut data={data} />
      <div className='total'>
        <span className='value'>
          {Utilities.centsToCHF(props.expensesOrIncome.sumOfAllAccounts)}
        </span>
        <span className='label'>
          Total {props.type === 'expense' ? 'Aufwände' : 'Erträge'}
        </span>
      </div>
    </div>
  )
}

export default ExpensesOrIncomePieChart
