import { Button } from '@nextbusiness/infinity-ui'
import CustomerInvoiceFactory from 'invoices/customer-invoices/editor/CustomerInvoiceFactory'
import DocumentSidebarPanelProps from '../DocumentSidebarPanelProps'

const CustomerInvoiceToggleQuoteAction = (props: DocumentSidebarPanelProps) => {
  if (!props.document.isDraft) return null
  const switchToQuote = !props.document.isQuote
  return (
    <Button
      variant='quaternary'
      iconLeft='skip'
      className='sidebar-action'
      tooltip={
        props.document.isQuote
          ? 'Ändere diese Offerte zu einer Forderung.'
          : 'Ändere diese Forderung zu einer Offerte.'
      }
      onClick={() => {
        const saveMessage = switchToQuote
          ? 'Dokument wurde zu einer Offerte umgewandelt.'
          : 'Dokument wurde zu einer Forderung umgewandelt.' +
            (props.document.isDraft
              ? ' Um ein PDF zu erstellen, stelle die Forderung.'
              : '')

        const newIntroductoryText =
          CustomerInvoiceFactory.defaultIntroductoryText(
            props.template,
            switchToQuote
          )
        const defaultIntroductoryText =
          CustomerInvoiceFactory.defaultIntroductoryText(
            props.template,
            props.document.isQuote ?? false
          )
        const isUsingDefaultIntroductoryText =
          props.document.introductoryText === defaultIntroductoryText
        const introductoryText = isUsingDefaultIntroductoryText
          ? newIntroductoryText
          : props.document.introductoryText

        props.registerEdit(
          {
            isQuote: switchToQuote,
            isQrInvoice: !switchToQuote,
            introductoryText: introductoryText,
          },
          true,
          saveMessage
        )
      }}
    >
      {switchToQuote ? 'In Offerte umwandeln' : 'In Forderung umwandeln'}
    </Button>
  )
}

export default CustomerInvoiceToggleQuoteAction
