import { AccountCard, Finance } from '@nextbusiness/infinity-finance-api'

import useAPIQuery from 'libs/networking/APIQuery'
import { observer } from 'mobx-react'
import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import FinancialHealthCard from 'reports/financial-health/components/FinancialHealthCard'
import FinancialHealthSumOverview from 'reports/financial-health/components/FinancialHealthSumOverview'
import LoaderFinancialHealthAccount from 'reports/financial-health/loaders/LoaderFinancialHealthAccount'
import { useRootStore } from 'stores/RootStoreContext'
import './FinancialHealthAccountCard.scss'
import AccountCardBarChart from './charts/AccountCardBarChart'
import AccountCardLineChart from './charts/AccountCardLineChart'

interface FinancialHealthAccountCardProps {
  card: AccountCard
}

const FinancialHealthAccountCard = (props: FinancialHealthAccountCardProps) => {
  const { properties } = props.card
  const { accountStore, ledgerStore } = useRootStore()
  const history = useHistory()

  const previousProperties = useRef(properties)

  const account = accountStore.find(properties.accountNumber)
  const title = account?.name ?? `Konto ${properties.accountNumber}`

  const [data, isLoading, error, invalidate] = useAPIQuery(() =>
    Finance.FinancialHealth.accountCard(props.card.id)
  )

  const chart = () => {
    if (!data) return null

    if (data.chart.type === 'balance') {
      return <AccountCardLineChart {...properties} data={data.chart} />
    } else {
      return <AccountCardBarChart {...properties} data={data.chart} />
    }
  }

  useEffect(() => {
    const shouldReloadData = () =>
      JSON.stringify(previousProperties.current) !== JSON.stringify(properties)
    if (shouldReloadData()) {
      invalidate()
      previousProperties.current = properties
    }
  }, [properties])

  return (
    <FinancialHealthCard
      title={title}
      className='financial-health-card-account'
      onClick={() => {
        ledgerStore.setCurrentRootView(properties.accountNumber)
        history.push('/buchen')
      }}
      isLoading={isLoading}
      customLoadingState={
        <LoaderFinancialHealthAccount
          showYoYComparison={properties.showYoYComparison}
        />
      }
      error={error}
    >
      <FinancialHealthSumOverview
        label='Saldo'
        currentSum={data?.balance.current ?? 0}
        previousSum={data?.balance.previous}
        change={data?.balance.yoyChange}
        hideComparisonCopy={!properties.showYoYComparison}
      />
      {chart()}
    </FinancialHealthCard>
  )
}

export default observer(FinancialHealthAccountCard)
