import AbortEditDialog from 'components/dialogs/AbortEditDialog'
import { useAccountSheetContext } from 'ledger/accounts/AccountSheetContext'

const TransactionAbortEditDialog = () => {
  const {
    abortToTransaction,
    setAbortToTransaction,
    setTransactionBeingEdited,
  } = useAccountSheetContext()

  const isAbortingEdit = abortToTransaction !== undefined
  const onCancel = () => {
    setAbortToTransaction(undefined)
  }
  const onAbortConfirmed = () => {
    setTransactionBeingEdited(abortToTransaction)
    setAbortToTransaction(undefined)
  }

  return (
    <AbortEditDialog
      isAbortingEdit={isAbortingEdit}
      onCancel={onCancel}
      onAbort={onAbortConfirmed}
    />
  )
}

export default TransactionAbortEditDialog
