import AccountCardChartConfiguration from './AccountCardChartConfiguration'

class AccountCardBarChartConfiguration extends AccountCardChartConfiguration {
  public barSettings() {
    if (this.dataLength < 5) {
      return {
        barThickness: 20,
        borderRadius: 3,
      } as const
    }

    if (this.dataLength < 10) {
      return {
        barThickness: 12,
        borderRadius: 3,
      } as const
    }

    if (this.dataLength < 20) {
      return {
        barThickness: 8,
        borderRadius: 2,
      } as const
    }

    if (this.dataLength < 90) {
      return {
        barPercentage: 0.6,
        borderRadius: 1,
      } as const
    }

    if (this.dataLength < 120) {
      return {
        barPercentage: 0.75,
        borderRadius: 1,
      } as const
    }

    return {
      barPercentage: 1,
      borderRadius: 0.5,
    } as const
  }
}

export default AccountCardBarChartConfiguration
