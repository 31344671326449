import { MenuItem, MenuItemGroup } from '@nextbusiness/infinity-ui'
import MoreMenu from 'components/more-menu/MoreMenu'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import CustomerInvoiceDeleteConfirmationDialog from '../../CustomerInvoiceDeleteConfirmationDialog'
import CreatePresetModal from '../panels/actions/CreatePresetModal'
import { CustomerInvoiceEditorSidebarTopActionsProps } from './CustomerInvoiceEditorSidebarTopActions'

const EditorMoreAction = (
  props: CustomerInvoiceEditorSidebarTopActionsProps
) => {
  const history = useHistory()

  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
    useState<boolean>(false)
  const [isCreatePresetModalOpen, setIsCreatePresetModalOpen] =
    useState<boolean>(false)
  const canDeleteExistingInvoice = Boolean(
    props.existingInvoice?.id && props.isEditable
  )

  const saveAsPresetAction: MenuItem = {
    text: 'Als Vorlage speichern',
    onClick: () => {
      setIsCreatePresetModalOpen(true)
    },
    icon: 'project-setup-2',
  }
  const deleteAction: MenuItem = {
    text: `${props.existingInvoice?.isQuote ? 'Offerte' : 'Forderung'} löschen`,
    onClick: () => {
      setShowConfirmDeleteDialog(true)
    },
    icon: 'delete',
  }

  const buttons: MenuItem[] = [saveAsPresetAction]
  if (canDeleteExistingInvoice) {
    buttons.push(deleteAction)
  }

  return (
    <>
      <MoreMenu actions={[{ buttons }]} iconOnly customIcon='view-more' />
      {props.existingInvoice && canDeleteExistingInvoice && (
        <CustomerInvoiceDeleteConfirmationDialog
          invoice={props.existingInvoice}
          isOpen={showConfirmDeleteDialog}
          onDismiss={() => setShowConfirmDeleteDialog(false)}
          onInvoiceDeleted={() => history.push('/forderungen')}
        />
      )}
      <CreatePresetModal
        isOpen={isCreatePresetModalOpen}
        closeModal={() => setIsCreatePresetModalOpen(false)}
        document={props.document}
      />
    </>
  )
}

export default EditorMoreAction
