import { Flex, Heading } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import DocumentLiveCaptureSetupBanner from 'documents/live-capture-setup/DocumentLiveCaptureSetupBanner'
import { useEffect, useState } from 'react'
import VendorInvoiceDetailTabPositions from '../detail/VendorInvoiceDetailTabPositions'
import { useVendorInvoiceEditor } from '../detail/VendorInvoiceEditorContext'
import VendorInvoiceLiveCapture from '../live-capture/VendorInvoiceLiveCapture'
import VendorInvoiceCreateActions from './VendorInvoiceCreateActions'
import { useVendorInvoiceCreator } from './VendorInvoiceCreationContext'
import VendorInvoiceGeneralFields from './VendorInvoiceGeneralFields'

interface VendorInvoiceCreationMainPaneProps {
  onDismiss: () => void
}

const VendorInvoiceCreationMainPane = (
  props: VendorInvoiceCreationMainPaneProps
) => {
  const { invoiceDraft, makeEdit } = useVendorInvoiceEditor()
  const { contact, upload } = useVendorInvoiceCreator()

  const [hasScrolled, setHasScrolled] = useState<boolean>(false)

  const onContainerScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const scrollTop = (e.nativeEvent.target as HTMLDivElement).scrollTop
    setHasScrolled(scrollTop !== 0)
  }

  useEffect(() => {
    if (contact) {
      makeEdit({ creditor: contact.id })
    }
  }, [contact])

  return (
    <div
      className={classNames(
        'vendor-invoice-detail-main creation-view edit-mode',
        hasScrolled && 'has-scrolled'
      )}
    >
      <div className='vendor-invoice-detail-header'>
        <Flex>
          <Heading type='h3' bare divider>
            Neue Rechnung
          </Heading>
          <VendorInvoiceCreateActions {...props} />
        </Flex>
      </div>
      {!upload && <DocumentLiveCaptureSetupBanner compact />}
      <div
        className='vendor-invoice-detail-container'
        onScroll={onContainerScroll}
      >
        <VendorInvoiceGeneralFields
          invoice={invoiceDraft}
          onChange={makeEdit}
        />
        <VendorInvoiceLiveCapture />
        <VendorInvoiceDetailTabPositions />
      </div>
    </div>
  )
}

export default VendorInvoiceCreationMainPane
