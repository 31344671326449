import FinancialHealthMetric from '../FinancialHealthMetric'
import { FinancialHealthAssessment } from '../FinancialHealthAssessment'

class MetricTargetGrossProfitMargin extends FinancialHealthMetric {
  title = 'Ziel-Bruttogewinnmarge'
  description = 'Bruttogewinn in % vom Umsatz entspricht dem festgelegten Ziel'

  isRelevant = () =>
    !!this.context.profitability?.grossProfitMargin.targetGrossProfitMargin

  getAssessment = (): FinancialHealthAssessment =>
    FinancialHealthMetric.assessmentFromStatus(
      this.context.profitability?.grossProfitMargin
        .comparedToTargetGrossProfitMargin
    )

  getStatusText = (): string =>
    this.context.profitability?.grossProfitMargin
      .comparedToTargetGrossProfitMargin ?? 'Keine Angabe'

  getActualValue = () => {
    const margin = this.context.profitability?.grossProfitMargin.currentYear
    if (!margin) return '–'
    return `${(margin * 100).toFixed(1)}%`
  }

  getTarget = () => {
    const targetMargin =
      this.context.profitability?.grossProfitMargin.targetGrossProfitMargin
    if (!targetMargin) return '–'
    return `${(targetMargin * 100).toFixed(1)}%`
  }
}

export default MetricTargetGrossProfitMargin
