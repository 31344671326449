import { Dialog, Text } from '@nextbusiness/infinity-ui'

interface CustomerInvoiceAskForModifyingSharedInvoiceDialogProps {
  isOpen: boolean
  onDismiss: () => void
  onChangesConfirmed: () => void
  isModificationsInProgress: boolean
  text: string
}

const CustomerInvoiceAskForModifyingSharedInvoiceDialog = (
  props: CustomerInvoiceAskForModifyingSharedInvoiceDialogProps
) => {
  return (
    <Dialog
      title='Diese Forderung wurde bereits geteilt.'
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      actions={[
        {
          children: 'Verwerfen',
          onClick: props.onDismiss,
        },
        {
          children: 'Änderungen bestätigen',
          variant: 'primary',
          onClick: props.onChangesConfirmed,
          isLoading: props.isModificationsInProgress,
        },
      ]}
    >
      <Text>{props.text}</Text>
    </Dialog>
  )
}

export default CustomerInvoiceAskForModifyingSharedInvoiceDialog
