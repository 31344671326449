import {
  APIError,
  Finance,
  IProduct,
  IProductChanges,
} from '@nextbusiness/infinity-finance-api'
import { Dialog, useNotificationCenter } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { useEnter } from 'utility/hooks'
import DeleteProductModal from './DeleteProductModal'
import './EditProductModal.scss'
import ProductEditFields, { isValidProduct } from './ProductEditFields'

interface EditProductModalProps {
  isOpen: boolean
  onDismiss: () => void
  product: IProduct
}

const EditProductModal = (props: EditProductModalProps) => {
  const { productStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [product, setProduct] = useState<IProductChanges>({})

  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)

  const editProduct = async () => {
    setShouldValidate(true)
    if (!isValidProduct(product)) return
    try {
      setIsLoading(true)
      await Finance.Products.modifyProduct(props.product.id, {
        ...product,
        sellingPrice: Math.round(product.sellingPrice * 100),
      })
      await productStore.loadAllProducts()
      notificationCenter.addNotification({
        children: 'Änderungen gespeichert',
        variant: 'success',
      })
      props.onDismiss()
    } catch (error) {
      notificationCenter.addNotification({
        title: 'Produkt konnte nicht bearbeitet werden',
        children: (error as APIError).humanText('de'),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!props.isOpen) return

    setProduct({
      ...props.product,
      sellingPrice: props.product.sellingPrice / 100,
    })
    setShouldValidate(false)
  }, [props.isOpen])

  useEnter(() => {
    if (props.isOpen) editProduct()
  })

  return (
    <Dialog
      className='edit-product-modal'
      title='Produkt bearbeiten'
      titleProps={{ divider: true }}
      {...props}
      actions={[
        {
          iconOnly: 'delete',
          onClick: () => setIsDeleteModalOpen(true),
          className: 'delete-action',
          tooltip: 'Produkt löschen',
        },
        {
          children: 'Abbrechen',
          onClick: props.onDismiss,
        },
        {
          children: 'Änderungen speichern',
          onClick: editProduct,
          variant: 'primary',
          isLoading,
        },
      ]}
    >
      <ProductEditFields
        product={product}
        editProduct={(changes) =>
          setProduct((currentProduct) => ({
            ...currentProduct,
            ...changes,
          }))
        }
        shouldValidate={shouldValidate}
      />
      <DeleteProductModal
        isOpen={isDeleteModalOpen}
        onDismiss={() => setIsDeleteModalOpen(false)}
        onDeleteComplete={props.onDismiss}
        product={props.product}
      />
    </Dialog>
  )
}

export default observer(EditProductModal)
