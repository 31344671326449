import { Flex } from '@nextbusiness/infinity-ui'
import { useVendorInvoiceContext } from './VendorInvoiceContext'

const VendorInvoiceDetailDateRow = () => {
  const { invoice } = useVendorInvoiceContext()
  return (
    <Flex className='date-row'>
      <div className='date-row-section opening-date'>
        <span className='label'>Datum</span>
        <span className='date'>
          {new Date(invoice.openingDate).toLocaleDateString()}
        </span>
      </div>
      <div className='date-row-section due-date'>
        <span className='label'>Fällig am</span>
        <span className='date'>
          {invoice.dueDate
            ? new Date(invoice.dueDate).toLocaleDateString()
            : '–'}
        </span>
      </div>
    </Flex>
  )
}

export default VendorInvoiceDetailDateRow
