import { Button, ButtonProps } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'

interface ResourceSelectActionProps extends ButtonProps {
  closeFlyout: () => void
}

const ResourceSelectAction = (props: ResourceSelectActionProps) => (
  <Button
    {...props}
    variant='quaternary'
    className={classNames('resource-action-button', props.className)}
    onClick={() => {
      props.closeFlyout()
      props.onClick?.()
    }}
  />
)

export default ResourceSelectAction
