import React from 'react'
import ContentLoader from 'react-content-loader'

const LoaderFinancialHealthStatus = () => (
  <ContentLoader
    speed={2}
    width={344}
    height={230}
    viewBox='0 0 344 230'
    backgroundColor='#f3f3f3'
    foregroundColor='#e5e5e5'
  >
    <circle cx='58' cy='96' r='49' />
    <rect x='6' y='164' rx='3' ry='3' width='105' height='17' />
    <rect x='140' y='56' rx='3' ry='3' width='190' height='17' />
    <rect x='140' y='92' rx='3' ry='3' width='190' height='17' />
    <rect x='140' y='131' rx='3' ry='3' width='190' height='17' />
    <rect x='140' y='166' rx='3' ry='3' width='190' height='17' />
  </ContentLoader>
)

export default LoaderFinancialHealthStatus
