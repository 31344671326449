import { Button, Flex } from '@nextbusiness/infinity-ui'
import { APPLICATION_IDENTIFIER } from 'application/ApplicationConstants'
import Subscription from 'libs/subscription/Subscription'
import { observer } from 'mobx-react-lite'
import { ORIGIN } from 'networking/Authentication'
import FinanceAppIcon from '../../../assets/app-icon/app-icon-finance.png'
import './UpgradeReminder.scss'

interface UpgradeReminderProps {
  subscription: Subscription
}

const UpgradeReminder = (props: UpgradeReminderProps) => (
  <Flex
    className='upgrade-reminder-wrapper'
    fillContainer
    horizontalAlignment='center'
  >
    <Button
      className='upgrade-reminder'
      onClick={(e) => {
        e?.preventDefault()
        e?.stopPropagation()
        document.location.href =
          ORIGIN +
          '/marketplace/apps/' +
          APPLICATION_IDENTIFIER +
          '?action=select-plan'
      }}
      variant='shell'
    >
      <img src={FinanceAppIcon} alt='' className='app-icon' height={22} />
      <span className='remaining-days'>
        {props.subscription.remainingTrialDays > 0
          ? `Testversion noch ${props.subscription.remainingTrialDays} Tage gültig`
          : 'Testversion ist abgelaufen'}
      </span>
      <span className='upgrade-prompt'>Jetzt upgraden</span>
    </Button>
  </Flex>
)

export default observer(UpgradeReminder)
