import { Button, Text } from '@nextbusiness/infinity-ui'
import DocumentsDuePrompt from 'documents/DocumentsDuePrompt'
import { DateTime } from 'luxon'
import { IFiscalYear } from 'model/FiscalYear'
import { DocumentsPagePaths } from 'pages/DocumentsPage'
import { useHistory } from 'react-router-dom'
import './FiscalYearDuePrompt.scss'

interface FiscalYearDuePromptProps {
  fiscalYear: IFiscalYear
}

const FiscalYearDuePrompt = (props: FiscalYearDuePromptProps) => {
  const history = useHistory()
  const endDate = DateTime.fromMillis(props.fiscalYear.to).toLocaleString({
    locale: 'de-CH',
    dateStyle: 'long',
  })
  return (
    <DocumentsDuePrompt
      className='fiscal-year-closing-due-prompt'
      title='Jahresabschluss fällig'
    >
      <Text>
        Dieses Geschäftsjahr endete am {endDate}. Am Ende jedes Geschäftsjahres
        muss dieses geschlossen werden. Dabei werden auch Berichte für das
        Jahresergebnis erstellt.
      </Text>
      <Button
        variant='primary'
        onClick={() =>
          history.push(DocumentsPagePaths.FinancialClosingAssistant)
        }
      >
        Zum Jahresabschluss
      </Button>
    </DocumentsDuePrompt>
  )
}

export default FiscalYearDuePrompt
