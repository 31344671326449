import { KeyValueStore } from '@nextbusiness/infinity-ui'
import {
  DefaultAccountsTemplate,
  IAccount,
  IAccountQueryParams,
} from '../model/Account'
import { Current } from './Authentication'
import NetworkingConstants from './NetworkingConstants'
import NetworkingErrorHandler from './NetworkingErrorHandler'

const getAccounts = async (
  organisationIdentifier: string,
  fiscalYearStart: number,
  fiscalYearEnd: number,
  queryParams?: IAccountQueryParams
): Promise<IAccount[]> => {
  const params = queryParams
    ? new URLSearchParams(queryParams as KeyValueStore)
    : null
  const response = await fetch(
    NetworkingConstants.HOST +
      `/organisation/${organisationIdentifier}/from/${fiscalYearStart}/to/${fiscalYearEnd}/accounts?${
        params?.toString() ?? ''
      }`,
    { method: 'GET', headers: Current.defaultHeaders }
  )
  const body = await response.json()

  if (!response.ok || !body.accounts) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.accounts as IAccount[]
}

const setupDefaultAccounts = async (
  organisationId: string,
  template: DefaultAccountsTemplate
): Promise<IAccount[]> => {
  const response = await fetch(
    `${NetworkingConstants.HOST}/organisation/${organisationId}/startAccounts?template=${template}`,
    {
      method: 'POST',
      headers: Current.defaultHeaders,
    }
  )
  const body = await response.json()

  if (!response.ok || !body.accounts) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.accounts as IAccount[]
}

const Accounts = {
  getAccounts,
  setupDefaultAccounts,
}

export default Accounts
