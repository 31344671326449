import { Button } from '@nextbusiness/infinity-ui'

interface AccountCreationOptionProps {
  openAssistant: () => void
}

const AccountCreationOption = (props: AccountCreationOptionProps) => (
  <Button
    className='create-account-button'
    variant='quaternary'
    iconLeft='plus'
    onClick={() => props.openAssistant()}
  >
    Neues Konto
  </Button>
)

export default AccountCreationOption
