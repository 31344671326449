import DocumentPositionTextUtilities from '../DocumentPositionTextUtilities'

class PositionNameFieldKeyHandler {
  private event: React.KeyboardEvent

  constructor(event: React.KeyboardEvent) {
    this.event = event
  }

  public handleKeyDown = (currentText: string) => {
    const isCreatingNewLine = this.event.key === 'Enter'
    const shouldAutoContinueBulletPoints =
      DocumentPositionTextUtilities.shouldContinueBulletPoints(
        currentText,
        this.caretPosition
      )
    if (isCreatingNewLine && shouldAutoContinueBulletPoints) {
      return this.autoContinueBulletPoint(currentText)
    }
    return null
  }

  private autoContinueBulletPoint = (currentText: string) => {
    const { updatedText, updatedCaretPosition } =
      DocumentPositionTextUtilities.lineWithNewBulletPoint(
        currentText,
        this.caretPosition
      )

    this.event.preventDefault()
    queueMicrotask(() => {
      this.textarea.selectionStart = updatedCaretPosition
      this.textarea.selectionEnd = updatedCaretPosition
    })
    return updatedText
  }

  private get textarea() {
    return this.event.target as HTMLTextAreaElement
  }

  private get caretPosition() {
    return this.textarea.selectionStart ?? 0
  }
}

export default PositionNameFieldKeyHandler
