import { Button, Flex, Heading } from '@nextbusiness/infinity-ui'
import DocumentCard from 'documents/document-card/DocumentCard'
import { observer } from 'mobx-react'
import DocumentsPage, { DocumentsPagePaths } from 'pages/DocumentsPage'
import { useRootStore } from 'stores/RootStoreContext'
import './NewInboxDocuments.scss'
interface NewInboxDocumentsProps {
  page: DocumentsPage
}

const NewInboxDocuments = (props: NewInboxDocumentsProps) => {
  const { documentStore } = useRootStore()

  const inboxDocuments = documentStore.inboxDocuments
  const topmostDocument = inboxDocuments?.[0]

  if (!topmostDocument) return null

  return (
    <div className='new-inbox-documents'>
      <div className='new-inbox-documents-content'>
        <Flex verticalAlignment='center'>
          <Heading type='h3' className='number-of-new-documents'>
            {inboxDocuments?.length
              ? `${inboxDocuments.length} ${
                  inboxDocuments.length === 1 ? ' neuer Beleg' : ' neue Belege'
                }`
              : 'Keine neuen Belege'}
          </Heading>
          <Button
            className='show-inbox-documents'
            variant='quaternary'
            onClick={() =>
              props.page.open(DocumentsPagePaths.AllInboxDocuments)
            }
          >
            Alle anzeigen
          </Button>
        </Flex>
        <DocumentCard
          document={topmostDocument}
          numberOfCardsOnBackStack={inboxDocuments.length - 1}
          previewDocument={() =>
            props.page.open(
              DocumentsPagePaths.DocumentDetailView,
              topmostDocument.id
            )
          }
          createExpenditure={() =>
            props.page.open(
              DocumentsPagePaths.CreateExpenditureFromInbox,
              topmostDocument.id
            )
          }
        />
      </div>
    </div>
  )
}

export default observer(NewInboxDocuments)
