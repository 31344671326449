import { ICurrency } from '@nextbusiness/infinity-finance-api'
import { MoneyField } from '@nextbusiness/infinity-ui'
import CurrencySelect from 'components/multi-currency/currency-picker/CurrencySelect'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { useTransactionCreatorContext } from '../TransactionCreatorContext'

const TransactionCreatorAmount = () => {
  const { currencyStore, preferencesStore } = useRootStore()
  const { transaction, updateTransaction, numberKey, createTransaction } =
    useTransactionCreatorContext()

  const isMultiCurrencyBetaEnabled =
    preferencesStore.isUsingBetaFeature('multi-currency')

  const baseCurrency = currencyStore.baseCurrency
  const [currency, setCurrency] = useState<ICurrency>(baseCurrency)
  const [isFlyoutOpen, setIsFlyoutOpen] = useState<boolean>(false)

  const updateTransactionCurrency = (currency: ICurrency) => {
    setCurrency(currency)
    if (currency.currencyCode === 'CHF') {
      updateTransaction({
        originalCurrency: undefined,
        exchangeRate: undefined,
      })
    } else {
      updateTransaction({
        originalCurrency: currency.currencyCode,
        exchangeRate: currency.effectiveExchangeRate,
      })
    }
  }

  return (
    <CurrencySelect
      setCurrency={(currency) => {
        updateTransactionCurrency(currency)
      }}
      isFlyoutOpen={isFlyoutOpen}
      setIsFlyoutOpen={setIsFlyoutOpen}
      flyoutTrigger={
        <MoneyField
          className='amount'
          key={`money-field-${numberKey}`}
          value={
            transaction.amount ? Math.round(transaction.amount) / 100 : null
          }
          onChange={(amount) =>
            updateTransaction({
              amount: amount ? Math.round(amount * 100) : undefined,
            })
          }
          onKeyDown={(e) => {
            if (e.key === 'Enter') createTransaction()
          }}
          currency={currency.currencyCode}
          onUnitClick={
            isMultiCurrencyBetaEnabled
              ? () => setIsFlyoutOpen(!isFlyoutOpen)
              : undefined
          }
        />
      }
    />
  )
}

export default observer(TransactionCreatorAmount)
