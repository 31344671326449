import { ITransaction } from '@nextbusiness/infinity-finance-api'
import { useAccountPageContext } from 'ledger/accounts/AccountPageContext'
import { observer } from 'mobx-react'
import React from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import TransactionFiscalYearBreak from './TransactionFiscalYearBreak'
import TransactionListSection from './TransactionListSection'
import useHandleBatchEditSelection from './useHandleBatchEditSelection'
import useTransactionsGroupedByMonth from './useTransactionsGroupedByMonth'

type TransactionReviewState = 'reviewed' | 'unreviewed'

interface TransactionListItemsProps {
  state: 'reviewed' | 'unreviewed'
  showMonthlyHeadings?: boolean
}

const filterByReviewed = (
  transactions: ITransaction[] | undefined,
  state: TransactionReviewState
) => {
  if (state === 'reviewed') {
    return transactions?.filter(
      (transaction) =>
        transaction.reviewed === true || transaction.reviewed === undefined
    )
  } else {
    return transactions?.filter((transaction) => transaction.reviewed === false)
  }
}
interface TransactionListItemsProps {
  showFiscalYearBreaks?: boolean
}

const TransactionListItems = (props: TransactionListItemsProps) => {
  const { currentAccount } = useAccountPageContext()
  const { transactionStore, accountStore, vatPeriodStore } = useRootStore()

  const accountNumber = currentAccount.accountNumber

  const filteredTransactions = filterByReviewed(
    transactionStore.filteredTransactions[accountNumber],
    props.state
  )
  const allTransactions = filterByReviewed(
    transactionStore.transactions[accountNumber],
    props.state
  )

  const { transactionsByMonth, transactions } = useTransactionsGroupedByMonth(
    filteredTransactions,
    allTransactions,
    accountStore.allFiscalYears
  )
  const handleBatchEditSelection = useHandleBatchEditSelection(
    transactions,
    accountStore,
    vatPeriodStore
  )

  return (
    <>
      {Object.keys(transactionsByMonth).map((month, index) => {
        const { transactions, endOfFiscalYear } = transactionsByMonth[month]
        return (
          <React.Fragment key={`${month}-${index}`}>
            {endOfFiscalYear !== undefined && props.showFiscalYearBreaks && (
              <TransactionFiscalYearBreak year={endOfFiscalYear} />
            )}
            <TransactionListSection
              key={month}
              title={props.showMonthlyHeadings ? month : undefined}
              transactions={transactions}
              handleBatchEditSelection={handleBatchEditSelection}
            />
          </React.Fragment>
        )
      })}
    </>
  )
}

export default observer(TransactionListItems)
