import { IBalanceSheet } from 'model/Report'
import React from 'react'
import BalanceSheet from 'reports/balance-sheet/BalanceSheet'
import './ClosingBalanceSheetPreview.scss'

interface ClosingBalanceSheetPreviewProps {
  balanceSheet: IBalanceSheet
  closingDate: Date
}

const ClosingBalanceSheetPreview = (props: ClosingBalanceSheetPreviewProps) => (
  <div className='balance-sheet-preview'>
    <BalanceSheet
      balanceSheet={props.balanceSheet}
      dateCopy={props.closingDate}
    />
  </div>
)

export default ClosingBalanceSheetPreview
