import { Flex, Text } from '@nextbusiness/infinity-ui'
import './StatusLabel.scss'

interface StatusLabelProps {
  swatchColour: string
  text: string
}

const StatusLabel = (props: StatusLabelProps) => (
  <Flex className='status-label' verticalAlignment='baseline'>
    <div
      className='colour-swatch'
      style={{ backgroundColor: props.swatchColour }}
    />
    <Text type='inline' className='text'>
      {props.text}
    </Text>
  </Flex>
)

export default StatusLabel
