import { Flex, Text } from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import { LiveCaptureAttachmentChildProps } from './LiveCaptureAttachment'
import LiveCaptureAttachmentDetails from './LiveCaptureAttachmentDetails'
import LiveCaptureDocumentCounterparty from './LiveCaptureDocumentCounterparty'

const LiveCaptureAttachmentDepiction = (
  props: LiveCaptureAttachmentChildProps
) => (
  <Flex className='live-capture-attachment-depiction'>
    {props.document.document.analysis ? (
      <>
        <Icon icon='ocr' size={IconSize.Small} tint='lavender' />
        <Flex className='document-information' direction='vertical'>
          <LiveCaptureDocumentCounterparty document={props.document} />
          <LiveCaptureAttachmentDetails document={props.document} />
        </Flex>
      </>
    ) : (
      <Text type='inline'>{props.document.document.title}</Text>
    )}
  </Flex>
)

export default LiveCaptureAttachmentDepiction
