import BareMoneyField from 'proto-ui-components/BareMoneyField'
import React, {
  HTMLInputTypeAttribute,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react'
import DocumentRendererContext from '../DocumentRendererContext'
import './EditableDocumentText.scss'

export interface EditableDocumentTextProps {
  value?: string
  className?: string
  onChange?: (value: string) => void
  onKeyDown?: (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  placeholder?: string
  isMultiline?: boolean
  isCurrency?: boolean
  isReadOnly?: boolean
  type?: HTMLInputTypeAttribute
  style?: React.CSSProperties
  inputMode?: React.HTMLAttributes<HTMLTextAreaElement>['inputMode']
}

const EditableDocumentText = (props: EditableDocumentTextProps) => {
  const renderContext = useContext(DocumentRendererContext)

  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const inputProps = {
    className:
      'editable-field document-text' +
      (props.className ? ' ' + props.className : '') +
      (props.value ? '' : ' is-empty') +
      (props.isMultiline ? ' is-multiline' : '') +
      (props.isCurrency ? ' is-currency' : ''),
    placeholder: props.placeholder,
    value: props.value ?? '',
    disabled: props.onChange === undefined || props.isReadOnly,
    type: props.isCurrency ? 'text' : props.type,
    style: props.style,
  }

  const resizeTextarea = useCallback(() => {
    const textarea = textareaRef?.current
    if (!textarea) return

    textarea.style.height = '0.5rem'
    textarea.style.height = `${textarea.scrollHeight / 10}rem`
  }, [])

  useEffect(() => {
    window.addEventListener('resize', resizeTextarea)
    return () => window.removeEventListener('resize', resizeTextarea)
  }, [])

  useEffect(() => {
    resizeTextarea()
  }, [props.value, renderContext?.tableColumns])

  return props.isMultiline ? (
    <textarea
      {...inputProps}
      ref={textareaRef}
      onChange={
        props.onChange
          ? (event: React.ChangeEvent<HTMLTextAreaElement>) =>
              props.onChange!(event.target.value)
          : undefined
      }
      onKeyDown={props.onKeyDown}
    />
  ) : props.isCurrency ? (
    <BareMoneyField {...inputProps} onChange={props.onChange} />
  ) : (
    <input
      {...inputProps}
      onChange={
        props.onChange
          ? (event: React.ChangeEvent<HTMLInputElement>) =>
              props.onChange!(event.target.value)
          : undefined
      }
      onKeyDown={props.onKeyDown}
    />
  )
}

export default EditableDocumentText
