import { TaxCode } from '@nextbusiness/infinity-finance-api'
import { store } from 'stores/RootStoreContext'
import {
  INewCustomProductPosition,
  INewDiscountPosition,
  INewTextLinePosition,
} from '../../../model/CustomerInvoice'
import { generateRandomId } from '../../../utility/StringUtilities'

class CustomerInvoicePositionFactory {
  public static DEFAULT_TAX_CODE = TaxCode.UN81

  public static newCustomProductPosition = (): INewCustomProductPosition => ({
    type: 'custom-product',
    identifier: generateRandomId(),
    name: '',
    taxCode: store.preferencesStore.organisationPreferences?.VAT
      ? this.DEFAULT_TAX_CODE
      : undefined,
  })

  public static newTextLinePosition = (): INewTextLinePosition => ({
    type: 'text-line',
    identifier: generateRandomId(),
    content: '',
  })

  public static newDiscountPosition = (): INewDiscountPosition => ({
    type: 'discount',
    identifier: generateRandomId(),
    amount: 0,
  })
}

export default CustomerInvoicePositionFactory
