import {
  Finance,
  IQRInvoicePreferences,
} from '@nextbusiness/infinity-finance-api'
import { Dialog, NonIdealState, Text } from '@nextbusiness/infinity-ui'
import { useEffect, useState } from 'react'
import QRSettingsPage from 'settings/qr-settings/QRSettingsPage'
import './DocumentQRSlipPreview.scss'
import DocumentQRSlipPreviewOverlay from './DocumentQRSlipPreviewOverlay'

interface DocumentQRSlipPreviewProps {
  recipientContactId: string | undefined
  amount: number
  openingDate: number
  invoiceNumber: number | undefined
  isAnnulled?: boolean
}

const DocumentQRSlipPreview = (props: DocumentQRSlipPreviewProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [settings, setSettings] = useState<IQRInvoicePreferences | null>(null)

  const [isQRSetupDialogOpen, setIsQRSetupDialogOpen] = useState<boolean>(false)

  const loadSettings = async () => {
    setIsLoading(true)
    try {
      const loadedSettings = await Finance.Organisation.QRPreferences.current()
      setSettings(loadedSettings)
    } catch (error) {
      setSettings(null)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadSettings()
  }, [])

  return (
    <div className='document-qr-slip-preview'>
      {!isLoading &&
      (settings === null || settings.invoicingMethod === 'No QR-Invoice') ? (
        <div className='document-qr-setup-message'>
          <NonIdealState
            icon='qr-code'
            actions={[
              {
                children: 'QR-Rechnung einrichten',
                variant: 'primary',
                onClick: () => setIsQRSetupDialogOpen(true),
              },
            ]}
          >
            <Text type='paragraph'>
              Bevor du PDFs mit QR-Zahlteilen erstellen kannst, musst du noch
              die QR-Rechnung fertig einrichten.
            </Text>
          </NonIdealState>
        </div>
      ) : (
        !!settings && (
          <DocumentQRSlipPreviewOverlay {...props} settings={settings} />
        )
      )}
      <Dialog
        isOpen={isQRSetupDialogOpen}
        onDismiss={() => setIsQRSetupDialogOpen(false)}
        dismissable
        className='qr-settings-setup-dialog'
      >
        <QRSettingsPage
          onSave={() => {
            setIsQRSetupDialogOpen(false)
            loadSettings()
          }}
        />
      </Dialog>
    </div>
  )
}

export default DocumentQRSlipPreview
