import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { IVendorInvoice } from '../../../model/VendorInvoice'
import ContactSwatch from '../../shared-components/ContactSwatch'
import ExtendedInvoiceIconAmount from '../../shared-components/invoice-icon/ExtendedInvoiceIconAmount'
import ExtendedInvoiceIconContainer from '../../shared-components/invoice-icon/ExtendedInvoiceIconContainer'
import VendorInvoiceIcon from './VendorInvoiceIcon'

interface ExtendedVendorInvoiceIconProps {
  invoice: IVendorInvoice
  showAmount?: boolean
  small?: boolean
}

const ExtendedVendorInvoiceIcon = (props: ExtendedVendorInvoiceIconProps) => {
  const dueDate = DateTime.fromMillis(props.invoice.dueDate)
  const overdueStart = dueDate.plus({ days: 1 })

  const isOverdue =
    !props.invoice.isPaid && overdueStart.toMillis() <= Date.now()

  return (
    <ExtendedInvoiceIconContainer small={props.small}>
      <VendorInvoiceIcon isOverdue={isOverdue} small={props.small} />
      {props.showAmount && !props.small && (
        <ExtendedInvoiceIconAmount totalAmount={props.invoice.totalAmount} />
      )}
      <ContactSwatch contactId={props.invoice.creditor} small={props.small} />
    </ExtendedInvoiceIconContainer>
  )
}

export default observer(ExtendedVendorInvoiceIcon)
