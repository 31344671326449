import { ITemplate } from '@nextbusiness/infinity-finance-api'
import { FeedbackBanner } from '@nextbusiness/infinity-ui'
import RangeSlider from '../../../../components/range-slider/RangeSlider'
import { TemplateInspectorProps } from '../TemplateInspector'
import TemplateInspectorGroup from '../TemplateInspectorGroup'
import TemplateInspectorLogoUpload from './TemplateInspectorLogoUploadArea'

const TemplateInspectorLogo = (props: TemplateInspectorProps) => (
  <>
    <TemplateInspectorGroup>
      {props.isFirstTimeCreation ? (
        <FeedbackBanner>
          Speichere das Dokumentdesign ab, um später ein Logo hinzuzufügen.
        </FeedbackBanner>
      ) : (
        <TemplateInspectorLogoUpload
          template={props.template as ITemplate}
          onSuccessfulUpload={props.reloadTemplate}
        />
      )}
    </TemplateInspectorGroup>
    {props.template.logo && (
      <TemplateInspectorGroup title='Grösse'>
        <RangeSlider
          min={0.5}
          max={1.5}
          step={0.01}
          value={props.template.logoSize ?? 1}
          onChange={(logoSize) => props.modifyTemplate({ logoSize })}
          onDoubleClick={() => props.resetPropertyToDefault('logoSize')}
        />
      </TemplateInspectorGroup>
    )}
  </>
)

export default TemplateInspectorLogo
