import { Flex } from '@nextbusiness/infinity-ui'
import { useState } from 'react'
import './Accordion.scss'
import AccordionItem, { IAccordionItem } from './AccordionItem'

interface AccordionProps {
  accordionItems: IAccordionItem[]
  openFirstByDefault?: boolean
}

const Accordion = (props: AccordionProps) => {
  const [selectedAccordionElement, setSelectedAccordionElement] =
    useState<number>(props.openFirstByDefault ? 0 : -1)

  return (
    <Flex className='accordion' direction='vertical'>
      {props.accordionItems.map((accordionItem, index) => (
        <AccordionItem
          key={accordionItem.title}
          {...accordionItem}
          isOpen={index == selectedAccordionElement}
          openItem={() => {
            setSelectedAccordionElement(index)
            accordionItem.onOpen?.()
          }}
        />
      ))}
    </Flex>
  )
}

export default Accordion
