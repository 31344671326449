import { Button, Flex, Text, ThemeProvider } from '@nextbusiness/infinity-ui'
import { APPLICATION_IDENTIFIER } from 'application/ApplicationConstants'
import OnboardScreen from 'components/onboard-screen/OnboardScreen'
import { isIntercomAvailable } from 'integrations/IntercomBoot'
import { useMixpanelEvent } from 'integrations/useMixpanelEvent'
import SubscriptionCapability, {
  SubscriptionCapabilities,
} from 'libs/subscription/SubscriptionCapability'
import { ORIGIN } from 'networking/Authentication'
import { useIntercom } from 'react-use-intercom'

export interface FeatureUpgradeScreenProps {
  requiredCapability: SubscriptionCapability
  customFeatureImage?: string
  customFeatureDescription?: string
  customFeatureRequiredSubscription?: string
}

const FeatureUpgradeScreen = (props: FeatureUpgradeScreenProps) => {
  const intercom = useIntercom()
  const feature = SubscriptionCapabilities[props.requiredCapability]

  const requiredTier =
    props.customFeatureRequiredSubscription ??
    feature.upgradeTo ??
    'Infinity Finance Pro'

  const startUpgrade = () => {
    if (isIntercomAvailable()) {
      intercom.showNewMessages(
        `Ich bin interessiert an einem Upgrade auf ${requiredTier}, um die Funktion ${feature.shortname} zu nutzen.`
      )
    } else {
      window.open(ORIGIN + '/marketplace/apps/' + APPLICATION_IDENTIFIER)
    }
  }

  useMixpanelEvent('Feature upgrade screen seen', {
    feature: props.requiredCapability,
  })

  return (
    <Flex fillContainer horizontalAlignment='center' verticalAlignment='center'>
      <OnboardScreen
        image={props.customFeatureImage ?? feature.image}
        title={props.customFeatureDescription ?? feature.description}
      >
        <ThemeProvider primaryColor='lavender'>
          <Text>
            Diese und weitere Funktionen kannst du mit einem Upgrade auf{' '}
            {requiredTier} nutzen.
          </Text>
          <Flex gap='tiny'>
            <Button variant='primary' onClick={startUpgrade}>
              Jetzt upgraden
            </Button>
            <Button
              onClick={() =>
                window.open('https://www.infinity.swiss/finance/preise')
              }
            >
              Mehr über Preise
            </Button>
          </Flex>
        </ThemeProvider>
      </OnboardScreen>
    </Flex>
  )
}

export default FeatureUpgradeScreen
