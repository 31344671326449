import EditableDocumentText from '../../../controls/EditableDocumentText'
import { DocumentCustomProductPositionProps } from '../DocumentCustomProductPosition'

const PositionArticleNumberField = (
  props: DocumentCustomProductPositionProps
) => {
  if (!props.columns?.includes('articleNumber')) return null
  return (
    <div className='positions-column article'>
      <EditableDocumentText
        placeholder='Artikel'
        value={props.position.articleNumber ?? ''}
        onChange={(articleNumber) => props.onChange({ articleNumber })}
        isReadOnly={props.isReadOnly}
      />
    </div>
  )
}

export default PositionArticleNumberField
