import { IProjectPreview } from '@nextbusiness/infinity-finance-api'
import { Flex } from '@nextbusiness/infinity-ui'
import ProjectListItem from 'projects/list/ProjectListItem'
import CreateProjectModal from 'projects/project-view/edit-or-create-project/CreateProjectModal'
import { useContext, useState } from 'react'
import { usePluralise } from 'utility/hooks'
import { ContactContext } from './ContactContext'
import { FilterContext } from './ContactFilterContext'
import ContactListCreateItem from './ContactListCreateItem'
import ContactListEmptyState from './ContactListEmptyState'
import ContactStack from './ContactStack'

interface ContactListRenderProjectsProps {
  data: IProjectPreview[]

  stack: ContactStack
}

const ContactListRenderProjects = (props: ContactListRenderProjectsProps) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const openProject = (project: IProjectPreview) =>
    props.stack.openProject(project)
  const contact = useContext(ContactContext)
  const { isFiltering, searchValue } = useContext(FilterContext)

  const resultText = usePluralise(
    props.data.length,
    'Projekt',
    {
      plural: 'Projekte',
    },
    'Keine'
  )

  if (props.data.length === 0 && !isFiltering) {
    return (
      <>
        <ContactListEmptyState
          listType='projects'
          onActionClick={() => setIsCreateModalOpen(true)}
        />
        <CreateProjectModal
          isOpen={isCreateModalOpen}
          onDismiss={() => setIsCreateModalOpen(false)}
          options={{ contact }}
        />
      </>
    )
  }

  return (
    <>
      <ContactListCreateItem onClick={() => setIsCreateModalOpen(true)}>
        Neues Projekt
      </ContactListCreateItem>
      <CreateProjectModal
        isOpen={isCreateModalOpen}
        onDismiss={() => setIsCreateModalOpen(false)}
        options={{ contact }}
      />
      {isFiltering && (
        <div className='contact-list-filtering'>{`${resultText} für «${searchValue}» gefunden`}</div>
      )}
      <Flex className='data-items' direction='vertical'>
        {props.data?.map((project: IProjectPreview) => (
          <ProjectListItem
            key={project.id}
            project={project}
            onClick={() => openProject(project)}
          />
        ))}
      </Flex>
    </>
  )
}

export default ContactListRenderProjects
