import { Button, Heading, Text } from '@nextbusiness/infinity-ui'
import { useIntercom } from 'react-use-intercom'

const WelcomeCentreFeedback = () => {
  const intercom = useIntercom()
  return (
    <section className='other-section'>
      <Heading type='h4'>Fragen oder Anregungen</Heading>
      <Text>
        Du würdest gerne wechseln, aber du vermisst eine bestimmte Funktion?
        Oder du hast grundsätzlich Feedback für uns?
      </Text>
      <Button iconLeft='chat-bubble' onClick={() => intercom.showNewMessages()}>
        Teile uns etwas mit
      </Button>
    </section>
  )
}

export default WelcomeCentreFeedback
