import NanoClamp from 'nanoclamp'

interface ExtendedInvoiceListItemTitleProps {
  title: string
}

const ExtendedInvoiceListItemTitle = (
  props: ExtendedInvoiceListItemTitleProps
) => {
  return (
    <div className='ui-text title'>
      <NanoClamp lines={2} text={props.title} />
    </div>
  )
}

export default ExtendedInvoiceListItemTitle
