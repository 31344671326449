import { BillableWork } from '@nextbusiness/infinity-finance-api'
import { Checkbox, Flex, Text } from '@nextbusiness/infinity-ui'
import DateUtilities from 'utility/DateUtilites'
import { centsToCHF } from 'utility/Utilities'
import WorkUtilities from '../WorkUtilities'
import './WorkSelectionStep.scss'

interface WorkSelectionStepProps {
  unbilledWork: BillableWork[]
  selectedWorkItems: BillableWork[]
  setSelectedWorkItems: (work: BillableWork[]) => void
}

const WorkSelectionStep = (props: WorkSelectionStepProps) => {
  const select = (workItem: BillableWork) => {
    if (props.selectedWorkItems.includes(workItem)) return
    props.setSelectedWorkItems([...props.selectedWorkItems, workItem])
  }
  const deselect = (workItem: BillableWork) =>
    props.setSelectedWorkItems(
      props.selectedWorkItems.filter((w) => w.id !== workItem.id)
    )

  const totalInvoiceAmount = props.selectedWorkItems.reduce(
    (sum, workItem) => sum + WorkUtilities.valueOf(workItem),
    0
  )

  return (
    <Flex className='work-selection-step' direction='vertical'>
      <Text type='paragraph' className='invoicing-assistant-description'>
        Hiermit erstellst du eine neue Forderung für die folgenden erledigten
        Arbeiten.
      </Text>
      <div className='work-selection-list'>
        {props.unbilledWork.map((workItem) =>
          WorkUtilities.pricingOf(workItem) === 'non-billable' ? null : (
            <Checkbox
              key={workItem.id}
              onChange={(checked) => {
                if (checked) select(workItem)
                else deselect(workItem)
              }}
              isChecked={props.selectedWorkItems.includes(workItem)}
              label={
                <>
                  <span className='work-item-date'>
                    {DateUtilities.humanFormatNumeric(workItem.date)}
                  </span>
                  <span className='work-item-description'>
                    {workItem.description}
                  </span>
                  <span className='work-item-value'>
                    {centsToCHF(WorkUtilities.valueOf(workItem))} CHF
                  </span>
                </>
              }
            />
          )
        )}
      </div>
      {props.selectedWorkItems.length > 0 && (
        <Text type='inline' className='total-invoice-amount'>
          Du stellst hiermit {centsToCHF(totalInvoiceAmount)} CHF in Rechnung.
        </Text>
      )}
    </Flex>
  )
}

export default WorkSelectionStep
