import { ITemplate } from '@nextbusiness/infinity-finance-api'

export type ITemplatePreview = Pick<
  ITemplate,
  'id' | 'name' | 'organisationName' | 'logo'
>
export type ITemplateDraft = Omit<
  ITemplate,
  'id' | 'organisation' | 'name' | 'zipCode'
> & {
  name?: string
  zipCode?: number
}

export const BLANK_TEMPLATE: ITemplateDraft = {
  organisationName: '',
  addressLine: '',
  city: '',
  additionalData: '',
  addressLinePosition: 'right',
  colourValues: ['#00BAAD', '#167BA3'],
  font: 'default',
  logoSize: 0.6,
}
