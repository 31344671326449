import {
  APIError,
  Finance,
  IDocument,
  INewTransaction,
  TransactionOrigin,
} from '@nextbusiness/infinity-finance-api'
import { Button, useNotificationCenter } from '@nextbusiness/infinity-ui'
import { useExpenditureCreateContext } from 'documents/expenditures/ExpenditureCreateContext'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'

interface CreateExpenditureViewCreateActionProps {
  document: IDocument
  onDismiss: () => void
}

const CreateExpenditureViewCreateAction = (
  props: CreateExpenditureViewCreateActionProps
) => {
  const { documentStore } = useRootStore()
  const expenditureCreateContext = useExpenditureCreateContext()
  const notificationCenter = useNotificationCenter()

  const [isCreating, setIsCreating] = useState<boolean>(false)

  const createTransaction = async (transaction: INewTransaction) => {
    const newTransactions = await Finance.Ledger.createTransaction(transaction)
    const nonVATTransaction = newTransactions.find(
      (t) => t.origin !== TransactionOrigin.VAT
    )
    if (!nonVATTransaction) return
    await Finance.Documents.attachDocumentToTransaction(
      props.document.id,
      nonVATTransaction.id
    )
  }

  const createExpenditure = async () => {
    expenditureCreateContext.setShouldValidate(true)

    if (!expenditureCreateContext.isValid) return

    const transaction = {
      ...expenditureCreateContext.transaction,
      amount: expenditureCreateContext.transaction.amount! * 100,
    } as INewTransaction

    try {
      setIsCreating(true)
      await createTransaction(transaction)
      await documentStore.loadAllDocuments()
      props.onDismiss()
      notificationCenter.addNotification({
        children: 'Aufwand wurde erfolgreich verbucht.',
        variant: 'success',
      })
    } catch (error) {
      notificationCenter.addNotification({
        title: 'Aufwand konnte nicht verbucht werden.',
        children: (error as APIError).humanText('de'),
        variant: 'error',
      })
    } finally {
      setIsCreating(false)
    }
  }

  return (
    <Button
      variant='primary'
      iconLeft='check'
      isLoading={isCreating}
      onClick={createExpenditure}
    >
      Aufwand verbuchen
    </Button>
  )
}

export default observer(CreateExpenditureViewCreateAction)
