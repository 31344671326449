import { ThemeProvider } from '@nextbusiness/infinity-ui'
import { AnimatePresence } from 'framer-motion'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import { useVendorInvoiceCreator } from '../vendor-invoice-creation/VendorInvoiceCreationContext'
import LiveCaptureAnalysedInvoiceBanner from './LiveCaptureAnalysedInvoiceBanner'
import LiveCaptureAnalysisPendingBanner from './LiveCaptureAnalysisPendingBanner'

const VendorInvoiceLiveCapture = () => {
  const { preferencesStore } = useRootStore()
  const { documentAnalysis, isPerformingAnalysis } = useVendorInvoiceCreator()

  const isAnalysisAvailable = Boolean(
    documentAnalysis?.positions?.length && documentAnalysis?.fullTextContent
  )
  if (!preferencesStore.organisationPreferences?.isLiveCaptureEnabled)
    return null

  return (
    <ThemeProvider primaryColor='lavender'>
      <AnimatePresence>
        {isPerformingAnalysis ? (
          <LiveCaptureAnalysisPendingBanner />
        ) : isAnalysisAvailable ? (
          <LiveCaptureAnalysedInvoiceBanner />
        ) : undefined}
      </AnimatePresence>
    </ThemeProvider>
  )
}

export default observer(VendorInvoiceLiveCapture)
