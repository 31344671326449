import { BankingTransaction } from '@nextbusiness/infinity-finance-api'
import { AnimatePresence } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useTransactionEditorContext } from '../../TransactionEditorContext'
import TransactionNoEditReason from '../../TransactionNoEditReason'
import TransactionManualReconciliationButton from './TransactionManualReconciliationButton'
import TransactionManualReconciliationLayout from './TransactionManualReconciliationLayout'
import TransactionManualReconciliationPicker from './TransactionManualReconciliationPicker'

interface TransactionManualReconciliationProps {
  details: BankingTransaction
}

const TransactionManualReconciliation = (
  props: TransactionManualReconciliationProps
) => {
  const { noEditReason } = useTransactionEditorContext()
  const [isManualReconciliationOpen, setIsManualReconciliationOpen] =
    useState<boolean>(false)

  const toggleManualReconciliation = () =>
    setIsManualReconciliationOpen(!isManualReconciliationOpen)

  useEffect(() => {
    window.setTimeout(() => {
      const input = document.querySelector('.manual-reconciliation input')
      if (input && input instanceof HTMLInputElement) {
        input.focus()
      }
    }, 500)
  }, [isManualReconciliationOpen])

  if (
    noEditReason &&
    noEditReason !== TransactionNoEditReason.VendorInvoicePaymentTransaction &&
    noEditReason !== TransactionNoEditReason.CustomerInvoicePaymentTransaction
  )
    return null

  return (
    <TransactionManualReconciliationLayout
      isManualReconciliationOpen={isManualReconciliationOpen}
    >
      <AnimatePresence>
        {!isManualReconciliationOpen && (
          <TransactionManualReconciliationButton
            toggleManualReconciliation={toggleManualReconciliation}
            details={props.details}
          />
        )}
        {isManualReconciliationOpen && (
          <TransactionManualReconciliationPicker details={props.details} />
        )}
      </AnimatePresence>
    </TransactionManualReconciliationLayout>
  )
}

export default TransactionManualReconciliation
