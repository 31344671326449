import { Finance } from '@nextbusiness/infinity-finance-api'
import { Button, Text, useNotificationCenter } from '@nextbusiness/infinity-ui'
import LiveAccountingOnboardingGraphic from 'assets/hero-imagery/live-accounting-hero.png'
import FeaturePresentationItem from 'components/feature-presentation/FeaturePresentationItem'
import Lozenge from 'components/lozenge/Lozenge'
import OnboardScreen from 'components/onboard-screen/OnboardScreen'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react'
import NetworkingConstants from 'networking/NetworkingConstants'
import { useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import './LiveAccountingWelcomeScreen.scss'

interface LiveAccountingWelcomeScreenProps {
  onContinue: () => void
}

const LiveAccountingWelcomeScreen = (
  props: LiveAccountingWelcomeScreenProps
) => {
  const { authenticationStore } = useRootStore()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const notificationCentre = useNotificationCenter()

  const isTwoFactorEnabled = authenticationStore.user?.isTwoFactorEnforced

  useEffect(() => {
    const reloadUser = async () => {
      await authenticationStore.getUser()
    }
    window.addEventListener('focus', reloadUser)
    return () => window.removeEventListener('focus', reloadUser)
  }, [authenticationStore])

  const enableAndContinue = async () => {
    try {
      setIsLoading(true)
      await Finance.Organisation.setPreferences({
        isLiveCaptureEnabled: true,
        isMLReconciliationEnabled: true,
      })
      props.onContinue()
    } catch (error) {
      notificationCentre.addNotification({
        children:
          'Fehler beim Vorbereiten der Live Accounting-Funktionen. Bitte versuche es später erneut.',
        variant: 'warning',
      })
    } finally {
      setIsLoading(false)
    }
  }

  MixpanelAnalytics.event('Live accounting welcome screen viewed')

  return (
    <OnboardScreen
      image={LiveAccountingOnboardingGraphic}
      title='Verbinde dein Bankkonto'
      className='live-accounting-welcome'
      beta
    >
      <Text type='paragraph' className='instruction'>
        Live Accounting schafft eine intelligente Verbindung zwischen deiner
        Buchhaltung und deiner Bank.
      </Text>
      <div className='highlights'>
        <FeaturePresentationItem
          title='Bankbewegungen erscheinen automatisch'
          description='Mit Live Accounts kannst du dein Bankkonto verbinden und intelligente Buchungsvorschläge erhalten.'
          icon='transaction-list'
        />
        <FeaturePresentationItem
          title='Belege intelligent erfassen'
          description='Zugehörige Belege im Eingang werden automatisch neuen Bankbewegungen angehängt.'
          icon='ocr'
        />
        <FeaturePresentationItem
          title='Zahlungen ans E-Banking übermitteln'
          description='Mit Live Payments kannst du Zahlungen für hochgeladene QR-Rechnungen direkt an dein E-Banking übermitteln.'
          icon='payment'
          lozenge={
            <Lozenge inline small>
              Bald
            </Lozenge>
          }
        />
      </div>
      {!isTwoFactorEnabled ? (
        <div>
          <Text variant='warning' type='inline'>
            Um dein Konto zu schützen, musst du die
            Zwei-Faktor-Authentifizierung aktivieren, bevor du dein Bankkonto
            verbinden kannst.
          </Text>
        </div>
      ) : (
        <Text type='paragraph' className='legal-note'>
          Live Accounting nutzt künstliche Intelligenz, um Vorschläge zu
          erstellen. Deine Daten sind immer sicher.{' '}
          <a
            href='https://www.infinity.swiss/policies/datenverarbeitung-mit-live-accounting'
            target='_blank'
            rel='noopener noreferrer'
            className='link teal'
          >
            Mehr erfahren
          </a>
        </Text>
      )}
      {isTwoFactorEnabled ? (
        <Button
          variant='primary'
          onClick={enableAndContinue}
          isLoading={isLoading}
        >
          Fortfahren
        </Button>
      ) : (
        <Button
          variant='primary'
          onClick={() =>
            window.open(`${NetworkingConstants.HOME}/settings/security`)
          }
          iconLeft='lock'
        >
          Zwei-Faktor-Authentifizierung aktivieren
        </Button>
      )}
    </OnboardScreen>
  )
}

export default observer(LiveAccountingWelcomeScreen)
