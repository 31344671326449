import { IPercentageDiscountPosition } from '@nextbusiness/infinity-finance-api'
import { Flex } from '@nextbusiness/infinity-ui'
import EditableDocumentText from '../../controls/EditableDocumentText'

interface DocumentPercentageDiscountPositionProps {
  position: IPercentageDiscountPosition
  onChange: (changes: Partial<IPercentageDiscountPosition>) => void
  isReadOnly?: boolean
}

const DocumentPercentageDiscountPosition = (
  props: DocumentPercentageDiscountPositionProps
) => {
  return (
    <>
      <Flex className='positions-column name'>
        <EditableDocumentText
          placeholder='Beschreibung'
          value='Rabatt'
          isReadOnly
        />
      </Flex>
      <div className='positions-column single-amount' />
      <div className='positions-column total-amount'>
        <EditableDocumentText
          isReadOnly={props.isReadOnly}
          type='number'
          placeholder='Prozent'
          value={
            props.position.percentage ? String(props.position.percentage) : ''
          }
          onChange={(value) =>
            props.onChange({
              percentage:
                value === ''
                  ? 0
                  : parseFloat(value)
                  ? Math.floor(parseFloat(value))
                  : props.position.percentage,
            })
          }
          style={{ textAlign: 'right', width: '4rem' }}
        />
        {` %`}
      </div>
    </>
  )
}

export default DocumentPercentageDiscountPosition
