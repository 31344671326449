import { InputField } from '@nextbusiness/infinity-ui'
import { useEffect, useRef } from 'react'
import { useTransactionCreatorContext } from '../TransactionCreatorContext'
import useTypeaheadCompletion from '../hooks/useTypeaheadCompletion'

const TransactionCreatorDescription = () => {
  const { transaction, updateTransaction } = useTransactionCreatorContext()
  const { completionText, onSuggestedCompletionAccepted } =
    useTypeaheadCompletion()

  const inputFieldRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    inputFieldRef.current?.focus()
  }, [])

  return (
    <InputField
      className='description'
      ref={inputFieldRef}
      placeholder='Beschreibung'
      value={transaction.description ?? ''}
      onChange={(description) => updateTransaction({ description })}
      suggestedCompletion={completionText}
      onSuggestedCompletionAccepted={onSuggestedCompletionAccepted}
    />
  )
}

export default TransactionCreatorDescription
