import { LoadingIndicator, Text } from '@nextbusiness/infinity-ui'
import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import TransactionAmount from 'components/transactions/TransactionAmount'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import useBackendQuery from 'libs/networking/BackendQuery'
import { observer } from 'mobx-react-lite'
import { IAccount } from 'model/Account'
import { IFinancialSolvency, IMaturities } from 'model/FinancialHealth'
import FinancialHealth from 'networking/FinancialHealth'
import { useEffect } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import CompactVendorInvoiceView from './CompactVendorInvoiceView'
import './FinancialHealthSolvencyPage.scss'
import SolvencyDropdownGroup from './SolvencyDropdownGroup'
import SolvencyErrorView from './SolvencyErrorView'

export interface SolvencyDropdownGroupPart {
  title: string
  amount: number
  color: string
}

const FinancialHealthSolvencyPage = () => {
  const { authenticationStore, accountStore, vendorInvoiceStore } =
    useRootStore()

  const [maturities, areMaturitiesLoading, maturitiesError] =
    useBackendQuery<IMaturities>(() =>
      FinancialHealth.getMaturities(authenticationStore.organisationIdentifier)
    )

  const [finanicalSolvency, , solvencyError] =
    useBackendQuery<IFinancialSolvency>(() =>
      FinancialHealth.getFinancialSolvency(
        authenticationStore.organisationIdentifier
      )
    )

  const allInvoices = [
    ...(maturities?.accountsPayable.dueToday ?? []),
    ...(maturities?.accountsPayable.dueTheNextSevenDays ?? []),
    ...(maturities?.accountsPayable.dueTheNext30Days ?? []),
    ...(maturities?.accountsPayable.dueLater ?? []),
    ...(maturities?.accountsPayable.overdue ?? []),
  ]

  const liquidAssetAccounts = accountStore.allAccounts.filter(
    (account) => account.accountNumber >= 1000 && account.accountNumber < 1060
  )
  const accountsReceivableAccounts = accountStore.allAccounts.filter(
    (account) => account.accountNumber >= 1100 && account.accountNumber < 1140
  )
  const inventoryAccounts = accountStore.allAccounts.filter(
    (account) => account.accountNumber >= 1200 && account.accountNumber < 1300
  )

  const sumOf = (assets: IAccount[]) =>
    assets.reduce(
      (previousTotal: number, account: IAccount) =>
        previousTotal + account.balance,
      0
    )

  const partsFor = (assets: IAccount[]) =>
    assets.map((account) => ({
      title: account.name,
      amount: account.balance,
      color: account.colour || '#DDDDDD',
    }))

  const sumOfLiquidAssets = sumOf(liquidAssetAccounts)
  const sumOfAccountsReceivableAccounts = sumOf(accountsReceivableAccounts)
  const sumOfInventoryAccounts = sumOf(inventoryAccounts)

  const liquidAssetParts = partsFor(liquidAssetAccounts)
  const accountsReceivableAccountsParts = partsFor(accountsReceivableAccounts)
  const inventoryAccountsParts = partsFor(inventoryAccounts)

  useEffect(() => {
    MixpanelAnalytics.event('Report viewed: Finanical solvency')
    vendorInvoiceStore.loadInvoices()
  }, [])

  if (solvencyError || maturitiesError)
    return (
      <MagicSheetPage
        header={<MagicSheetTitleBar title='Zahlungsfähigkeit' />}
        className='solvency-report-page'
      >
        <SolvencyErrorView error={solvencyError ?? maturitiesError} />
      </MagicSheetPage>
    )

  return (
    <MagicSheetPage
      header={<MagicSheetTitleBar title='Zahlungsfähigkeit' />}
      className='solvency-report-page'
    >
      <MagicSheetContainer>
        <div className='solvency-visualisation'>
          <div className='solvency-section-title'>
            <Text className='header-title'>Verfügbar</Text>
            <div className='V-header-amount'>
              <TransactionAmount
                amount={Math.abs(
                  sumOfLiquidAssets +
                    sumOfAccountsReceivableAccounts +
                    sumOfInventoryAccounts
                )}
              />
            </div>
          </div>

          <SolvencyDropdownGroup
            title='Flüssige Mittel'
            sumAmount={sumOfLiquidAssets}
            parts={liquidAssetParts}
          />
          <SolvencyDropdownGroup
            title='Forderungen aus Lieferungen und Leistungen'
            sumAmount={sumOfAccountsReceivableAccounts}
            parts={accountsReceivableAccountsParts}
          />
          <SolvencyDropdownGroup
            title='Vorräte'
            sumAmount={sumOfInventoryAccounts}
            parts={inventoryAccountsParts}
          />

          <div className='solvency-section-title'>
            <Text className='header-title'>Rechnungen</Text>
            <div className='R-header-amount'>
              <TransactionAmount
                amount={Math.abs(finanicalSolvency?.balanceOfAccount2000 ?? 0)}
              />
            </div>
          </div>
          {areMaturitiesLoading ? (
            <LoadingIndicator />
          ) : (
            allInvoices.map((invoiceId) => (
              <CompactVendorInvoiceView key={invoiceId} invoiceId={invoiceId} />
            ))
          )}
        </div>
      </MagicSheetContainer>
    </MagicSheetPage>
  )
}

export default observer(FinancialHealthSolvencyPage)
