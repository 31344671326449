import { APIError, Finance, IProduct } from '@nextbusiness/infinity-finance-api'
import { Dialog, useNotificationCenter } from '@nextbusiness/infinity-ui'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { useEnter } from 'utility/hooks'
import ProductEditFields, { isValidProduct } from './ProductEditFields'

interface CreateProductModalProps {
  isOpen: boolean
  onDismiss: () => void
}

const CreateProductModal = (props: CreateProductModalProps) => {
  const { productStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [product, setProduct] = useState<Partial<IProduct>>({})

  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const createProduct = async () => {
    setShouldValidate(true)
    if (!isValidProduct(product)) return
    try {
      setIsLoading(true)
      await Finance.Products.createProduct({
        ...product,
        sellingPrice: Math.round(product.sellingPrice * 100),
      })
      await productStore.loadAllProducts()
      notificationCenter.addNotification({
        children: 'Produkt erstellt',
        variant: 'success',
      })
      MixpanelAnalytics.event('Product created')
      props.onDismiss()
    } catch (error) {
      notificationCenter.addNotification({
        title: 'Produkt konnte nicht erstellt werden',
        children: (error as APIError).humanText('de'),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!props.isOpen) return

    setProduct({})
    setShouldValidate(false)
  }, [props.isOpen])

  useEnter(() => {
    if (props.isOpen) createProduct()
  })

  return (
    <Dialog
      title='Produkt erstellen'
      titleProps={{ divider: true }}
      {...props}
      actions={[
        {
          children: 'Abbrechen',
          onClick: props.onDismiss,
        },
        {
          children: 'Produkt erstellen',
          onClick: createProduct,
          variant: 'primary',
          isLoading,
        },
      ]}
    >
      <ProductEditFields
        product={product}
        editProduct={(changes) =>
          setProduct((currentProduct) => ({
            ...currentProduct,
            ...changes,
          }))
        }
        shouldValidate={shouldValidate}
      />
    </Dialog>
  )
}

export default observer(CreateProductModal)
