import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { useIntercom } from 'react-use-intercom'
import TemplateEditor from './TemplateEditor'
import './TemplateEditorPage.scss'

interface TemplateEditorPageProps {
  isInitialSetupProcess?: boolean
}

const TemplateEditorPage = (props: TemplateEditorPageProps) => {
  const intercom = useIntercom()
  return (
    <div className='page settings-page page-template-editor'>
      <TemplateEditor
        {...props}
        onTemplateSaved={() => {
          intercom.trackEvent('template-saved')
          MixpanelAnalytics.event('Invoice design saved')
        }}
      />
    </div>
  )
}

export default TemplateEditorPage
