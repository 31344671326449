import { Button, Flex } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import IDocument from 'model/Document'
import { CurrentVendorInvoiceNavigationStackRef } from 'pages/VendorInvoicePage'
import { useHistory } from 'react-router-dom'
import { useRootStore } from 'stores/RootStoreContext'
import { queueNavigationTask } from 'utility/Utilities'
import DocumentCardMoreMenu from './DocumentCardMoreMenu'

interface DocumentCardActionsProps {
  document: IDocument
  previewDocument: () => void
  createExpenditure: () => void
}

const DocumentCardActions = (props: DocumentCardActionsProps) => {
  const { documentStore } = useRootStore()
  const history = useHistory()

  const attachDocument = () => {
    documentStore.attachDocumentToNewInvoice(props.document)
    history.push('/rechnungen/erstellen')
    queueNavigationTask(() => {
      CurrentVendorInvoiceNavigationStackRef?.openCreateInvoiceModal()
    })
  }

  return (
    <Flex className='actions' gap='tiny'>
      <Button iconLeft='plus' variant='primary' onClick={attachDocument}>
        Neue Rechnung
      </Button>
      <Button iconLeft='plus' onClick={props.createExpenditure}>
        Neuer Aufwand
      </Button>
      <DocumentCardMoreMenu {...props} />
    </Flex>
  )
}

export default observer(DocumentCardActions)
