import { Finance } from '@nextbusiness/infinity-finance-api'
import { useQuery } from '@tanstack/react-query'
import ErrorScreen from 'base-components/layout/ErrorScreen'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import { ContactRelatedItemsTab } from 'contacts/ContactPageStackContext'
import { BackendError, ErrorType } from 'libs/networking/Errors'
import { observer } from 'mobx-react'
import { Contact } from 'model/Contact'
import CustomerInvoices from 'networking/CustomerInvoices'
import VendorInvoices from 'networking/VendorInvoices'
import Tabs, { Tab } from 'proto-ui-components/Tabs'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { ContactContext } from './ContactContext'
import ContactCustomerInvoicesList from './ContactCustomerInvoicesList'
import { FilterContext } from './ContactFilterContext'
import ContactProjectsList from './ContactProjectsList'
import ContactQuotesList from './ContactQuotesList'
import ContactStack from './ContactStack'
import ContactVendorInvoicesList from './ContactVendorInvoicesList'
import './LinkedItemsSection.scss'

interface LinkedItemsSectionProps {
  stack: ContactStack
  initialTab?: ContactRelatedItemsTab
  searchValue: string
}

function doesMatch(title: string, searchValue: string) {
  if (!searchValue) return true
  return title.toLowerCase().includes(searchValue.toLowerCase())
}

const LinkedItemsSection = (props: LinkedItemsSectionProps) => {
  const [activeTab, setActiveTab] = useState<ContactRelatedItemsTab>(
    props.initialTab ?? 'customer-invoices'
  )

  const { authenticationStore, accountStore } = useRootStore()
  const contact = useContext(ContactContext) as Contact

  const retrieveInvoicesAndProjects = async () => {
    const customerInvoices = await CustomerInvoices.getCustomerInvoices(
      authenticationStore.organisationIdentifier,
      accountStore.currentFiscalYear?.year ?? 0,
      {
        recipient: contact.id,
      }
    )
    const projects = await Finance.Projects.projects({
      client: contact.id,
    })

    const vendorInvoices = await VendorInvoices.listInvoices(
      authenticationStore.organisationIdentifier,
      {
        creditor: contact.id,
        orderBy: 'dueDate',
        sortingOrder: 'desc',
      }
    )

    return { customerInvoices, projects, vendorInvoices }
  }

  const { data, isLoading, error } = useQuery({
    queryKey: ['documents', contact.id],
    queryFn: retrieveInvoicesAndProjects,
    retry(failureCount, error) {
      if ((error as BackendError).type === ErrorType.NotFound) return false
      return failureCount < 3
    },
  })

  const isFiltering = !!props.searchValue

  const customerInvoices =
    data?.customerInvoices.filter(
      (invoice) =>
        !invoice.isQuote && doesMatch(invoice.title, props.searchValue)
    ) ?? []
  const quotes =
    data?.customerInvoices.filter(
      (invoice) =>
        invoice.isQuote && doesMatch(invoice.title, props.searchValue)
    ) ?? []
  const vendorInvoices =
    data?.vendorInvoices.filter((invoice) =>
      doesMatch(invoice.title, props.searchValue)
    ) ?? []
  const projects =
    data?.projects.filter((project) =>
      doesMatch(project.title, props.searchValue)
    ) ?? []

  const customerInvoiceTab = {
    identifier: 'customer-invoices' as ContactRelatedItemsTab,
    title: 'Forderungen',
    countNumber: isFiltering ? customerInvoices.length ?? 0 : undefined,
  }
  const quotesTab = {
    identifier: 'quotes' as ContactRelatedItemsTab,
    title: 'Offerten',
    countNumber: isFiltering ? quotes.length ?? 0 : undefined,
  }
  const projectsTab = {
    identifier: 'projects' as ContactRelatedItemsTab,
    title: 'Projekte',
    countNumber: isFiltering ? projects.length ?? 0 : undefined,
  }
  const vendorInvoicesTab = {
    identifier: 'vendor-invoices' as ContactRelatedItemsTab,
    title: 'Rechnungen',
    countNumber: isFiltering ? vendorInvoices.length ?? 0 : undefined,
  }

  const [tabs, setTabs] = useState<Tab<ContactRelatedItemsTab>[]>([
    vendorInvoicesTab,
    customerInvoiceTab,
    quotesTab,
    projectsTab,
  ])

  useEffect(() => {
    if (contact.category === 'clients') {
      setTabs([customerInvoiceTab, quotesTab, projectsTab, vendorInvoicesTab])
    }
  }, [])

  const filterContextValue = useMemo(
    () => ({
      isFiltering: isFiltering,
      searchValue: props.searchValue,
    }),
    [isFiltering, props.searchValue]
  )

  if (isLoading) return <LoadingScreen />
  if (error) return <ErrorScreen title='Documents did not load correctly' />

  return (
    <FilterContext.Provider value={filterContextValue}>
      <div className='linked-items-section'>
        <div className='linked-items-section-tabs'>
          <Tabs
            currentTabId={activeTab}
            tabs={tabs}
            onTabSelected={(tab) =>
              setActiveTab(tab.identifier as ContactRelatedItemsTab)
            }
          />
        </div>
        <div className='contact-list-items'>
          {activeTab === 'customer-invoices' && (
            <ContactCustomerInvoicesList data={customerInvoices} />
          )}
          {activeTab === 'quotes' && <ContactQuotesList data={quotes} />}
          {activeTab === 'projects' && (
            <ContactProjectsList stack={props.stack} data={projects} />
          )}
          {activeTab === 'vendor-invoices' && (
            <ContactVendorInvoicesList
              stack={props.stack}
              data={vendorInvoices}
            />
          )}
        </div>
      </div>
    </FilterContext.Provider>
  )
}

export default observer(LinkedItemsSection)
