import { Text } from '@nextbusiness/infinity-ui'

const VATReportHeader = () => (
  <Text className='report-header'>
    Folgende Angaben musst du in das Mehrwertsteuerformular übertragen, nachdem
    du sie überprüft hast.{' '}
    <a
      className='learn-more'
      href='https://help.infinity.swiss/de/articles/8708329-wie-verwende-ich-die-mwst-abrechnung'
      target='_blank'
      rel='noreferrer'
    >
      Mehr erfahren
    </a>
  </Text>
)

export default VATReportHeader
