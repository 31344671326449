import {
  Button,
  Card,
  Flex,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import Account from 'components/transactions/Account'
import TransactionAmount from 'components/transactions/TransactionAmount'
import { observer } from 'mobx-react-lite'
import Accrual, { AccrualType } from 'model/financial-close/Accrual'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import './ClosingAccrualCard.scss'

interface ClosingAccrualCardProps {
  accrual: Accrual
}

const displayTextForAccrualType = (type: AccrualType) => {
  switch (type) {
    case AccrualType.AccruedExpense:
      return 'Noch nicht bezahlter Aufwand'
    case AccrualType.PrePaidExpense:
      return 'Bereits bezahlter Aufwand'
    case AccrualType.DeferredRevenue:
      return 'Noch nicht erhaltener Ertrag'
    case AccrualType.PrePaidRevenue:
      return 'Bereits erhaltener Ertrag'
  }
}

const ClosingAccrualCard = (props: ClosingAccrualCardProps) => {
  const { financialCloseStore } = useRootStore()
  const notificationCenter = useNotificationCenter()
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const deleteAccrual = async () => {
    if (!props.accrual._id || !financialCloseStore.financialCloseSettings)
      return
    setIsDeleting(true)
    try {
      const accruals =
        financialCloseStore.financialCloseSettings.accruals.filter(
          (accrual) => accrual._id !== props.accrual._id
        )
      await financialCloseStore.update({ accruals })
      notificationCenter.addNotification({
        children: 'Abgrenzung erfolgreich gelöscht',
        variant: 'success',
      })
    } catch (error) {
      notificationCenter.addNotification({
        title: 'Abgrenzung konnte nicht gelöscht werden',
        children: (error as Error).message ?? 'Unbekannter Fehler',
        variant: 'error',
      })
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <Card className='closing-accrual-card' bare>
      <Flex verticalAlignment='center' className='detail'>
        <Text type='inline' className='type'>
          {displayTextForAccrualType(props.accrual.type)}
        </Text>
        <Text type='inline' className='description'>
          {props.accrual.description}
        </Text>
        <Account accountNumber={props.accrual.contraAccount} />
        <TransactionAmount amount={props.accrual.amount} />
        <Button
          iconOnly='delete'
          aria-label='Löschen'
          variant='quaternary'
          isLoading={isDeleting}
          onClick={() => deleteAccrual()}
          tooltip='Abgrenzung löschen'
        />
      </Flex>
    </Card>
  )
}

export default observer(ClosingAccrualCard)
