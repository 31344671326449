import InvoiceFilterUtilities from 'invoices/shared-components/filters/InvoiceFilterUtilities'
import { IVendorInvoice } from 'model/VendorInvoice'
import { useMemo } from 'react'
import {
  useVendorInvoicesFilter,
  VendorInvoiceStatusFilter,
} from './VendorInvoicesFilterContext'

const useFilteredVendorInvoices = (
  allInvoices: IVendorInvoice[] | undefined
) => {
  const { activeFilter } = useVendorInvoicesFilter()
  const filteredInvoices = useMemo(() => {
    const { creditor, status, openingMonth, attachments } = activeFilter

    return allInvoices?.filter((invoice) => {
      const matchesCreditor = !creditor || invoice.creditor === creditor
      const matchesStatus = !status || doesInvoiceMatchStatus(invoice, status)
      const matchesOpeningMonth =
        !openingMonth ||
        InvoiceFilterUtilities.doesOpeningMonthMatch(invoice, openingMonth)
      const matchesAttachments =
        typeof attachments !== 'boolean' ||
        !!invoice.attachments?.length === attachments

      return (
        matchesCreditor &&
        matchesStatus &&
        matchesOpeningMonth &&
        matchesAttachments
      )
    })
  }, [allInvoices, activeFilter])
  return filteredInvoices
}

const doesInvoiceMatchStatus = (
  invoice: IVendorInvoice,
  status: VendorInvoiceStatusFilter
) => {
  switch (status) {
    case 'paid':
      return invoice.isPaid
    case 'unpaid':
      return !invoice.isPaid
    default:
      return true
  }
}

export default useFilteredVendorInvoices
