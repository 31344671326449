import { APIError, Finance } from '@nextbusiness/infinity-finance-api'
import {
  Button,
  Dialog,
  Flex,
  InputField,
  Text,
  Textarea,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react-lite'
import ICustomerInvoice from 'model/CustomerInvoice'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import SettingsGroup from 'settings/general/SettingsGroup'
import contactDialogStore from 'stores/ContactDialogStore'
import { useRootStore } from 'stores/RootStoreContext'
import './CustomerInvoiceEmailDialog.scss'
import CustomerInvoiceEmailSetupDialog from './CustomerInvoiceEmailSetupDialog'

interface CustomerInvoiceEmailDialogProps {
  isOpen: boolean
  onDismiss: () => void
  onShared: () => Promise<void>
  invoice: ICustomerInvoice
}

const CustomerInvoiceEmailDialog = (props: CustomerInvoiceEmailDialogProps) => {
  const { authenticationStore, contactStore, preferencesStore } = useRootStore()
  const { setIsContactsOpen } = contactDialogStore

  const notificationCenter = useNotificationCenter()
  const intercom = useIntercom()

  const recipient = contactStore.allContacts.find(
    (contact) => contact.id === props.invoice.recipient
  )
  const [recipientEmail, setRecipientEmail] = useState<string>(
    recipient?.email ?? ''
  )
  const invoiceType = props.invoice.isQuote ? 'Offerte' : 'Forderung'

  const [note, setNote] = useState<string>('')
  const [isSending, setIsSending] = useState<boolean>(false)

  const sendEmail = async () => {
    setIsSending(true)
    try {
      const hasNote = note.trim().length > 0
      await Finance.CustomerInvoice.sendByEmail(
        props.invoice.id,
        authenticationStore.applicationAccessToken!,
        { description: hasNote ? note : undefined, email: recipientEmail }
      )
      intercom.trackEvent('invoice-shared-via-email')
      MixpanelAnalytics.event('Customer invoice sent via email', {
        hasNote,
        isQuote: props.invoice.isQuote,
      })
      await props.onShared()
      props.onDismiss()
    } catch (error) {
      notificationCenter.addNotification({
        title: 'Die E-Mail konnte nicht versendet werden.',
        children: (error as APIError).humanText('de'),
        variant: 'error',
      })
    } finally {
      setIsSending(false)
    }
  }

  useEffect(() => {
    if (recipient?.email && props.isOpen) {
      setRecipientEmail(recipient.email)
      setIsContactsOpen(false)
      notificationCenter.addNotification({
        children: 'E-Mail-Adresse gespeichert',
      })
    }
  }, [recipient?.email])

  if (!recipient) return null
  if (!recipient.email)
    return <CustomerInvoiceEmailSetupDialog recipient={recipient} {...props} />

  return (
    <Dialog
      title={`${invoiceType} via E-Mail versenden`}
      titleProps={{ divider: true }}
      isOpen={props.isOpen}
      dismissable
      className='share-invoice-dialog'
      onDismiss={() => props.onDismiss()}
      actions={[
        {
          children: 'Abbrechen',
          variant: 'tertiary',
          onClick: props.onDismiss,
        },
        {
          children: 'Per E-Mail versenden',
          variant: 'primary',
          onClick: sendEmail,
          disabled: !recipient?.email,
          isLoading: isSending,
        },
      ]}
    >
      <SettingsGroup title='Empfänger'>
        <Flex className='recipient-email' gap={1}>
          <InputField
            icon='envelope'
            value={recipientEmail}
            onChange={setRecipientEmail}
            fullWidth
          />
          {recipient.email && recipient.email !== recipientEmail && (
            <Button
              iconOnly='undo'
              onClick={() => setRecipientEmail(recipient.email!)}
              tooltip='Zu Kontakt-E-Mail zurücksetzen'
            />
          )}
        </Flex>
        <Text>
          Der Empfänger wird die {invoiceType} ansehen und als PDF herunterladen
          können.
        </Text>
      </SettingsGroup>
      <SettingsGroup title='Persönliche Notiz'>
        <Text>
          Füge eine optionale Notiz hinzu, die dem Empfänger in der E-Mail
          angezeigt wird.
        </Text>
        <Textarea
          value={note}
          onChange={(text) => setNote(text)}
          fullWidth
          placeholder='Optionale Notiz eingeben'
        />
      </SettingsGroup>
      {!preferencesStore.organisationPreferences?.notificationEmail && (
        <Text variant='help'>
          Wenn du in den{' '}
          <Link
            to='/optionen/benachrichtigungen'
            className='link'
            onClick={props.onDismiss}
          >
            Einstellungen
          </Link>{' '}
          eine E-Mail-Adresse für Benachrichtigungen hinterlegst, werden
          Antworten deiner Kunden auf Offerten und Rechnungen darauf
          weitergeleitet.
        </Text>
      )}
    </Dialog>
  )
}

export default observer(CustomerInvoiceEmailDialog)
