import {
  DatePicker,
  Dialog,
  FeedbackBanner,
  Flex,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import * as Sentry from '@sentry/react'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useRootStore } from 'stores/RootStoreContext'
import DateUtilities from 'utility/DateUtilites'

interface SynchronizeBankAccountModalProps {
  isOpen: boolean
  onDismiss: () => void
}

const SynchronizeBankAccountModal = (
  props: SynchronizeBankAccountModalProps
) => {
  const { accountStore, ledgerStore } = useRootStore()
  const notificationCenter = useNotificationCenter()
  const history = useHistory()

  const [syncFrom, setSyncFrom] = useState<Date | undefined>(
    DateUtilities.startOfDay(new Date())
  )
  const [syncTo, setSyncTo] = useState<Date | undefined>(
    DateUtilities.startOfDay(new Date())
  )
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const hasInvalidDateOrder = () => {
    if (!syncFrom || !syncTo) return false
    return syncFrom.getTime() > syncTo.getTime()
  }
  const areDatesInFuture = () => {
    if (!syncFrom || !syncTo) return false
    return syncFrom.getTime() > Date.now() || syncTo.getTime() > Date.now()
  }
  const areDatesOutsideOfFiscalYear = () => {
    if (!syncFrom || !syncTo) return false
    return (
      !accountStore.isDateWithinOpenFiscalYear(syncFrom) ||
      !accountStore.isDateWithinOpenFiscalYear(syncTo)
    )
  }
  const hasError =
    hasInvalidDateOrder() || areDatesInFuture() || areDatesOutsideOfFiscalYear()
  const isButtonDisabled = () => {
    return !syncFrom || !syncTo || hasError
  }
  const account = accountStore.newestAccountWithNumber(
    ledgerStore.currentRootView
  )

  const syncTransactions = async () => {
    if (!account || !syncFrom || !syncTo) return
    setIsSaving(true)
    try {
      void accountStore.syncAccount(account, syncFrom, syncTo)
      await accountStore.loadAccounts()

      const daysToSync = DateUtilities.daysBetween(syncFrom, syncTo) + 1
      MixpanelAnalytics.event('Live accounting manual sync performed', {
        syncRange: daysToSync,
      })
      history.push('/buchen')
    } catch (error) {
      notificationCenter.addNotification({
        variant: 'error',
        title: 'Fehler beim Synchronisieren',
        children:
          'Die Synchronisierung des Kontos konnte nicht gestartet werden. Bitte versuche es erneut.',
      })
      Sentry.captureException(error)
    } finally {
      setIsSaving(false)
      props.onDismiss()
    }
  }

  return (
    <Dialog
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      title='Bankbewegungen importieren'
      icon='synchronise'
      initialFocusIndex={2}
      actions={[
        {
          children: 'Abbrechen',
          onClick: props.onDismiss,
        },
        {
          children: 'Importieren',
          variant: 'primary',
          onClick: syncTransactions,
          isLoading: isSaving,
          disabled: isButtonDisabled(),
        },
      ]}
    >
      <Text>
        Importiere hier Bankbewegungen aus einem beliebigen Zeitraum in deine
        Buchhaltung
      </Text>
      <Flex verticalAlignment='center' gap='small'>
        <span>Von</span>
        <DatePicker
          value={syncFrom}
          onChange={setSyncFrom}
          inputFieldProps={{ fullWidth: true, hasError }}
        />
        <span>Bis</span>
        <DatePicker
          value={syncTo}
          onChange={setSyncTo}
          inputFieldProps={{ fullWidth: true, hasError }}
        />
      </Flex>
      {hasInvalidDateOrder() ? (
        <Text variant='error'>
          Das Startdatum muss vor dem Enddatum liegen.
        </Text>
      ) : areDatesInFuture() ? (
        <Text variant='error'>
          Das Start- und Enddatum darf nicht in der Zukunft liegen.
        </Text>
      ) : areDatesOutsideOfFiscalYear() ? (
        <Text variant='error'>
          Start- und Enddatum müssen beide innerhalb eines offenen
          Geschäftsjahres liegen.
        </Text>
      ) : null}
      <FeedbackBanner variant='info'>
        Stelle sicher, dass nur den Zeitraum importierst, den du noch nicht
        manuell verbucht hast, um doppelte Buchungen zu vermeiden.
      </FeedbackBanner>
    </Dialog>
  )
}

export default observer(SynchronizeBankAccountModal)
