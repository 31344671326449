import { ITransactionChanges } from '@nextbusiness/infinity-finance-api'
import { useBatchEditingContext } from './BatchEditingContext'
import ChangeContraAccount from './ChangeContraAccountView'
import ChangeDateBatchEditingView from './ChangeDateView'
import DeleteTransactionView from './DeleteTransactionView'
import MoveToDifferentAccount from './MoveToDifferentAccountView'
import RenameBatchEditingView from './RenameBatchEditingView'

interface BatchEditingEditorProps {
  changesToTransaction: Partial<ITransactionChanges>
  setChangesToTransaction: (
    changesToTransaction: Partial<ITransactionChanges>
  ) => void
  newAccountNumber: number | undefined
  setNewAccountNumber: (newAccountNumber: number | undefined) => void
}

const BatchEditingEditor = (props: BatchEditingEditorProps) => {
  const { selectedBatchEditingAction } = useBatchEditingContext()
  switch (selectedBatchEditingAction) {
    case 'rename':
      return <RenameBatchEditingView {...props} />
    case 'changeDate':
      return <ChangeDateBatchEditingView {...props} />
    case 'changeContraAccount':
      return <ChangeContraAccount {...props} />
    case 'moveToDifferentAccount':
      return <MoveToDifferentAccount {...props} />
    default:
      return <DeleteTransactionView />
  }
}

export default BatchEditingEditor
