import { VATCalculationMode } from '@nextbusiness/infinity-finance-api'
import SubscriptionCapability from 'libs/subscription/SubscriptionCapability'
import { observer } from 'mobx-react-lite'
import { useRootStore } from 'stores/RootStoreContext'
import useSubscription from '../subscription-context/useSubscription'
import './LicensingAssistants.scss'
import VATLicensingIssueAssistant from './VATLicensingIssueAssistant'

const LicensingAssistants = () => {
  const { authenticationStore, preferencesStore } = useRootStore()
  const subscription = useSubscription()

  // Because the LicensingAssistants component is rendered on ALL PAGES,
  // we need to make sure that the user is actually even authenticated.
  if (!authenticationStore.isHydrated || !authenticationStore.isAuthenticated)
    return null

  // We only want to check for licensing issues once everything has loaded
  // to avoid false positives.
  if (!subscription || !preferencesStore.organisationPreferences) return null

  const hasVATLicensingIssue =
    preferencesStore.organisationPreferences?.VAT &&
    preferencesStore.organisationPreferences?.vatCalculationMode !==
      VATCalculationMode.Simplified &&
    !subscription.hasCapability(SubscriptionCapability.AdvancedVAT)

  return (
    <VATLicensingIssueAssistant
      isOpen={hasVATLicensingIssue || false}
      onResolve={() => preferencesStore.loadPreferences()}
    />
  )
}

export default observer(LicensingAssistants)
