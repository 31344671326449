import { Finance, ITransaction } from '@nextbusiness/infinity-finance-api'
import { Dialog, useNotificationCenter } from '@nextbusiness/infinity-ui'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { TransactionFilterContext } from 'ledger/transactions/filter/TransactionFilterContext'
import { observer } from 'mobx-react'
import IDocument from 'model/Document'
import Tabs from 'proto-ui-components/Tabs'
import { useContext, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import StringUtilities from 'utility/StringUtilities'
import { useLocalStorage } from 'utility/hooks'
import AttachDocumentFromInboxList from './AttachDocumentFromInboxList'
import UploadTransactionAttachmentArea from './UploadTransactionAttachmentArea'
import './UploadTransactionAttachmentModal.scss'

interface UploadTransactionAttachmentModalProps {
  isOpen: boolean
  onDismiss: () => void
  transaction: ITransaction
}

type AttachmentSourceTab = 'upload' | 'from-inbox'

const UploadTransactionAttachmentModal = (
  props: UploadTransactionAttachmentModalProps
) => {
  const { transactionStore, documentStore } = useRootStore()
  const notificationCenter = useNotificationCenter()
  const transactionFilterContext = useContext(TransactionFilterContext)

  const [tab, setTab] = useLocalStorage<AttachmentSourceTab>(
    'finance-upload-transaction-attachment-tab',
    'upload'
  )
  const [isAttaching, setIsAttaching] = useState<boolean>(false)

  const attachDocumentToTransaction = async (document: IDocument) => {
    setIsAttaching(true)
    try {
      await Finance.Documents.attachDocumentToTransaction(
        document.id,
        props.transaction.id
      )
      const [updatedTransaction] = await Finance.Ledger.transactions({
        id: props.transaction.id,
      })
      transactionStore.replaceTransactionsInStore(
        [props.transaction.id],
        [updatedTransaction],
        transactionFilterContext?.isFiltering
      )
      props.onDismiss()
      documentStore.loadAllDocuments()
      MixpanelAnalytics.event('Document attached to transaction', {
        source: tab,
      })
    } catch (error) {
      notificationCenter.addNotification({
        children:
          'Beleg konnte nicht an die Transaktion angehängt werden. Der Beleg befindet sich nun in der Inbox.',
        variant: 'warning',
      })
    } finally {
      setIsAttaching(false)
    }
  }

  return (
    <Dialog
      {...props}
      title={`Beleg anhängen an «${StringUtilities.truncate(
        props.transaction.description,
        20
      )}»`}
      dismissable
      className='upload-transaction-attachment-modal'
    >
      <Tabs<AttachmentSourceTab>
        currentTabId={tab}
        tabs={[
          { identifier: 'upload', title: 'Hochladen' },
          { identifier: 'from-inbox', title: 'Aus der Inbox' },
        ]}
        onTabSelected={(tab) => setTab(tab.identifier)}
      />
      <div className='upload-attachment-modal-view'>
        {tab === 'upload' ? (
          <UploadTransactionAttachmentArea
            attachDocumentToTransaction={attachDocumentToTransaction}
          />
        ) : (
          <AttachDocumentFromInboxList
            onTransactionSelected={attachDocumentToTransaction}
            isAttaching={isAttaching}
          />
        )}
      </div>
    </Dialog>
  )
}

export default observer(UploadTransactionAttachmentModal)
