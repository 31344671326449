import { UniqueIdentifier } from '@dnd-kit/core'
import {
  AccountCardProperties,
  Finance,
  FinancialHealthCardProperties,
  FinancialHealthCardType,
  IFinancialHealthCard,
} from '@nextbusiness/infinity-finance-api'
import { Heading } from '@nextbusiness/infinity-ui'
import useDragDropContext from 'components/drag-and-drop/useDragDropContext'
import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { generateRandomId } from 'utility/StringUtilities'
import FinancialHealthAddCard from './FinancialHealthAddCard'
import FinancialHealthEditCard from './FinancialHealthEditCard'
import './FinancialHealthEditView.scss'

const UNSUPPORTED_CARDS = [
  FinancialHealthCardType.Metric,
  FinancialHealthCardType.Runway,
]

const DEFAULT_ACCOUNT_CARD_PROPERTIES: AccountCardProperties = {
  accountNumber: 1020,
  chartType: 'balance',
  granularity: 'day',
  timeframe: 'last-month',
}

const FinancialHealthEditView = () => {
  const { preferencesStore } = useRootStore()

  const financialHealthCards =
    preferencesStore?.organisationPreferences?.financialHealthCards ?? []
  const {
    DragDropContext,
    state: activeCards,
    setState: setActiveCards,
  } = useDragDropContext(financialHealthCards)

  const isActiveCard = (kind: FinancialHealthCardType) =>
    activeCards.find((activeCard) => activeCard.kind === kind)
  const canAppearMultipleTimes = (kind: FinancialHealthCardType) =>
    kind === FinancialHealthCardType.Account ||
    kind === FinancialHealthCardType.Metric

  const addableCardKinds = [
    ...Object.values(FinancialHealthCardType).filter(
      (kind) =>
        (!isActiveCard(kind) || canAppearMultipleTimes(kind)) &&
        !UNSUPPORTED_CARDS.includes(kind)
    ),
  ]

  const addCard = (ofKind: FinancialHealthCardType) => {
    const newCard: IFinancialHealthCard = {
      id: generateRandomId(),
      kind: ofKind,
      properties: ofKind === 'account' ? DEFAULT_ACCOUNT_CARD_PROPERTIES : {},
    }
    setActiveCards([...activeCards, newCard])
  }
  const editCard = (
    withId: UniqueIdentifier,
    properties: Partial<FinancialHealthCardProperties>
  ) => {
    const newCards = activeCards.map((card) => {
      if (card.id === withId) {
        return { ...card, properties: { ...card.properties, ...properties } }
      }
      return card
    })
    setActiveCards(newCards as IFinancialHealthCard[])
  }
  const removeCard = (withId: UniqueIdentifier) => {
    const newCards = activeCards.filter((card) => card.id !== withId)
    setActiveCards(newCards)
  }

  const syncWithBackend = async (
    financialHealthCards: IFinancialHealthCard[]
  ) => {
    const newPreferences = await Finance.Organisation.setPreferences({
      financialHealthCards,
    })
    preferencesStore.setPreferences(newPreferences)
  }

  useEffect(() => {
    syncWithBackend(activeCards)
  }, [activeCards])

  return (
    <MagicSheetPage
      containerClassName='financial-health-edit-view'
      header={<MagicSheetTitleBar title='Kacheln anpassen' />}
      noTrailingNegativeSpace
    >
      <MagicSheetContainer>
        <Heading type='h3' bareTop>
          Aktivierte Kacheln
        </Heading>
        <DragDropContext>
          <div className='financial-health-cards'>
            {activeCards.map((card) => (
              <FinancialHealthEditCard
                key={card.id}
                card={card}
                editCard={editCard}
                removeCard={removeCard}
              />
            ))}
          </div>
        </DragDropContext>
        <Heading type='h3' bareTop>
          Kacheln hinzufügen
        </Heading>
        <div className='financial-health-cards'>
          {addableCardKinds.map((kind) => (
            <FinancialHealthAddCard key={kind} kind={kind} addCard={addCard} />
          ))}
        </div>
      </MagicSheetContainer>
    </MagicSheetPage>
  )
}

export default observer(FinancialHealthEditView)
