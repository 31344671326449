import {
  IFixedAmountDiscountPosition,
  IPercentageDiscountPosition,
} from '@nextbusiness/infinity-finance-api'
import { DocumentPositionRootProps } from './DocumentPositionRootProps'

class DocumentPositionDiscountUtilities {
  public static switchToPercentage = (props: DocumentPositionRootProps) => {
    const fixedDiscount = props.position as IFixedAmountDiscountPosition
    const percentage =
      fixedDiscount.amount > 10000 ? 0 : fixedDiscount.amount / 100

    props.onChangePosition(props.position.identifier, { percentage }, [
      'amount',
    ])
  }

  public static switchToFixedAmount = (props: DocumentPositionRootProps) => {
    const percentageDiscount = props.position as IPercentageDiscountPosition
    const amount = percentageDiscount.percentage
      ? percentageDiscount.percentage * 100
      : 0

    props.onChangePosition(props.position.identifier, { amount }, [
      'percentage',
    ])
  }
}

export default DocumentPositionDiscountUtilities
