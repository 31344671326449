import { NonIdealState } from '@nextbusiness/infinity-ui'
import { IconSize } from '@nextbusiness/infinity-ui-icons'
import CustomerInvoiceListItem from 'invoices/customer-invoices/regular-list/CustomerInvoiceListItem'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router-dom'
import { useRootStore } from 'stores/RootStoreContext'
import './ProjectInvoices.scss'

interface ProjectInvoicesProps {
  invoiceIds: string[]
}

const ProjectInvoices = (props: ProjectInvoicesProps) => {
  const { customerInvoiceStore } = useRootStore()
  const history = useHistory()
  const invoices = customerInvoiceStore.allDocuments.filter((invoice) =>
    props.invoiceIds.includes(invoice.id)
  )
  const hasInvoices = invoices && invoices.length > 0

  return hasInvoices ? (
    <div className='project-invoice-list'>
      <div className='project-invoice-list-scrollview'>
        {invoices.map((invoice) => (
          <CustomerInvoiceListItem
            invoice={invoice}
            key={invoice.id}
            onClick={() => history.push(`/forderungen/detail/${invoice.id}`)}
          />
        ))}
      </div>
    </div>
  ) : (
    <NonIdealState icon='purchase-order' iconSize={IconSize.Medium}>
      Du hast noch keine Forderungen für dieses Projekt
    </NonIdealState>
  )
}

export default observer(ProjectInvoices)
