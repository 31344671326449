import { makeObservable, observable, runInAction } from 'mobx'
import { IUser } from 'model/Organisation'
import Organisation from 'networking/Organisation'
import RootStore from './RootStore'
import Store from './Store'

class OrganisationStore extends Store {
  @observable allOrganisationMembers: IUser[] | null = null

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  loadMembers = async () => {
    const allMembersFromOrganisation =
      await Organisation.getOrganisationMembers(
        this.rootStore.authenticationStore.fabricOrganisationIdentifier!,
        this.rootStore.authenticationStore.applicationAccessToken!,
        this.rootStore.authenticationStore.userDataToken!
      )

    runInAction(() => {
      this.allOrganisationMembers = allMembersFromOrganisation
    })
  }

  fullNameOfMember(memberId: string): string {
    const member = this.allOrganisationMembers?.find(
      (teamMember) => teamMember.id === memberId
    )
    if (member) {
      return member.firstname + ' ' + member.lastname
    }
    return 'Unbekanntes Mitglied'
  }
}

export default OrganisationStore
