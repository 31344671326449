import { Finance } from '@nextbusiness/infinity-finance-api'
import TemplateInspectorBackgroundUploadArea from './TemplateInspectorBackgroundUploadArea'
import { TemplateInspectorBackgroundUploadProps } from './TemplateInspectorCoverBackgroundUpload'

const TemplateInspectorSubsequentPagesBackgroundUpload = (
  props: TemplateInspectorBackgroundUploadProps
) => (
  <TemplateInspectorBackgroundUploadArea
    kind='Hintergrund für Folgeseiten'
    uploadBackground={(upload) =>
      Finance.CustomerInvoice.uploadTemplateSubsequentBackground(
        props.template.id,
        upload.file
      )
    }
    removeBackground={() =>
      Finance.CustomerInvoice.resetTemplateSubsequentBackground(
        props.template.id
      )
    }
    hasBackground={props.template.subsequentBackgroundUrl !== undefined}
    onSuccessfulUpload={props.reloadTemplate}
  />
)

export default TemplateInspectorSubsequentPagesBackgroundUpload
