import { NonIdealState } from '@nextbusiness/infinity-ui'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import FiscalYearSelect from 'components/fiscal-year-select/FiscalYearSelect'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import useBackendQuery from 'libs/networking/BackendQuery'
import { observer } from 'mobx-react'
import FinancialHealth from 'networking/FinancialHealth'
import { useEffect, useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import { useRootStore } from 'stores/RootStoreContext'
import './ExpensesOrIncomeDetailPage.scss'
import ExpensesOrIncomeList from './ExpensesOrIncomeList'
import ExpensesOrIncomePieChart from './ExpensesOrIncomePieChart'
import FinancialHealthAdvancedReportingPage from './FinancialHealthAdvancedReportingPage'

const FinancialHealthExpensesDetailPage = () => {
  const { authenticationStore, accountStore } = useRootStore()
  const [selectedFiscalYear, setSelectedFiscalYear] = useState<number>(
    accountStore.currentFiscalYear?.year ?? 0
  )
  const intercom = useIntercom()

  const [expenses, isLoading, error] = useBackendQuery(
    async () => {
      const endDate = accountStore.fiscalYear(selectedFiscalYear)?.to
      if (selectedFiscalYear !== accountStore.currentFiscalYear?.year) {
        await accountStore.loadAccountsForYear(selectedFiscalYear)
      }
      return FinancialHealth.getExpenses(
        authenticationStore.organisationIdentifier,
        endDate ?? Date.now(),
        true
      )
    },
    undefined,
    [selectedFiscalYear]
  )

  useEffect(() => {
    intercom.trackEvent('financial-health-expenses-viewed')
    MixpanelAnalytics.event('Report viewed: Inspector')
  }, [])

  return (
    <MagicSheetPage
      header={
        <MagicSheetTitleBar title='Aufwände'>
          <FiscalYearSelect
            selectedFiscalYear={selectedFiscalYear}
            setSelectedFiscalYear={setSelectedFiscalYear}
          />
        </MagicSheetTitleBar>
      }
    >
      <FinancialHealthAdvancedReportingPage>
        {isLoading ? (
          <LoadingScreen />
        ) : error || !expenses ? (
          <NonIdealState>Fehler beim Laden der Aufwände</NonIdealState>
        ) : (
          <div className='expenses-or-income-detail'>
            <ExpensesOrIncomePieChart
              expensesOrIncome={expenses}
              type='expense'
            />
            <ExpensesOrIncomeList expensesOrIncome={expenses} type='expense' />
          </div>
        )}
      </FinancialHealthAdvancedReportingPage>
    </MagicSheetPage>
  )
}

export default observer(FinancialHealthExpensesDetailPage)
