import './VendorInvoiceIcon.scss'

interface VendorInvoiceIconProps {
  isOverdue: boolean
  small?: boolean
}

const VendorInvoiceIcon = (props: VendorInvoiceIconProps) => {
  return (
    <div
      className={
        'vendor-invoice-icon' +
        (props.isOverdue ? ' is-overdue' : '') +
        (props.small ? ' small' : '')
      }
    />
  )
}

export default VendorInvoiceIcon
