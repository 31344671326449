import stringSimilarity from 'string-similarity'

export const generateRandomId = () => Math.random().toString(36).substring(0, 7)

const rateResultBySearch = (
  result: string,
  value: string
): [number, number[]] => {
  result = result.toLowerCase()
  value = value.replaceAll(' ', '')

  const wordsOfResult = result.split(' ')

  let rating = 0
  let numberOfSkippedWords = 0
  const indicesOfRelevantLetters: number[] = []

  const skipIrrelevantWords = () => {
    const initialLetters = result.split(' ').map((word) => word[0])
    const wordsToSkip = initialLetters.indexOf(value[0])

    if (wordsToSkip !== -1) numberOfSkippedWords = wordsToSkip
  }

  skipIrrelevantWords()

  const determinePoints = (searchValue: string[], startingIndex: number) => {
    if (searchValue.length === 0) return

    const skippedWords = wordsOfResult.slice(0, numberOfSkippedWords)

    const currentWord = wordsOfResult[numberOfSkippedWords]
    const characterToFind = searchValue.slice(0, 1).toString()
    const indexOfMatch = currentWord?.indexOf(characterToFind, startingIndex)

    const matchExists = indexOfMatch !== -1
    const isMatchInitialLetter = indexOfMatch === 0
    const isMatchConsecutive = indexOfMatch === startingIndex

    if (matchExists) {
      if (isMatchInitialLetter) {
        if (numberOfSkippedWords === 0) {
          rating += 10
        } else {
          rating += 5
        }
      } else if (isMatchConsecutive) {
        rating += 3
      } else {
        rating += 1
      }

      if (numberOfSkippedWords) {
        let charactersSoFar = 0
        skippedWords.forEach(
          (skippedWord) => (charactersSoFar += skippedWord.length + 1)
        )
        indicesOfRelevantLetters.push(charactersSoFar + indexOfMatch)
      } else {
        indicesOfRelevantLetters.push(indexOfMatch)
      }

      determinePoints(searchValue.slice(1), indexOfMatch + 1)
    } else {
      numberOfSkippedWords += 1
      determinePoints(searchValue, indexOfMatch)
    }
  }

  determinePoints(value.split('').slice(), 0)
  return [rating, indicesOfRelevantLetters]
}

const searchDataByValue = (haystack: string[], searchValue: string) => {
  searchValue = searchValue.toLowerCase().trim()

  const isRelevant = (dataItem: string) => {
    dataItem = dataItem.toLowerCase().trim().replace(/[()]/g, '')

    const initialLetters = dataItem.split(' ').map((word) => word[0])
    const wordsToSkip = initialLetters.indexOf(searchValue[0])
    const relevantItemWords = dataItem.split(' ').slice(wordsToSkip)

    const combinedHaystack = relevantItemWords.join('')
    const valueAppearsInOrderFromInitial = searchValue
      .replaceAll(' ', '')
      .split('')
      .every(
        (
          (index: number) => (letter: string) =>
            (index = combinedHaystack.indexOf(letter, index) + 1)
        )(0)
      )

    const valueStartsWithInitialLetter = initialLetters.includes(searchValue[0])
    const containsTermFully = dataItem.includes(searchValue)

    return (
      (valueAppearsInOrderFromInitial && valueStartsWithInitialLetter) ||
      containsTermFully
    )
  }

  const filteredData = haystack.filter((dataItem) => isRelevant(dataItem))

  const ratedDataReference = filteredData.map((dataItem, index) => {
    const [rating] = rateResultBySearch(dataItem, searchValue)
    return {
      index,
      rating,
      length: dataItem.length,
    }
  })

  const rankedDataReference = ratedDataReference.sort((a, b) => {
    if (b.rating === a.rating) return a.length - b.length
    else return b.rating - a.rating
  })

  const rankedData = rankedDataReference.map(
    (reference) => filteredData[reference.index]
  )

  return rankedData
}

const truncate = (string: string, maxLength: number) => {
  if (string.length > maxLength) return string.slice(0, maxLength) + '…'
  else return string
}

/**
 * Finds the closest string match for a given string withing a list of strings.
 * @param needle The string to search for.
 * @param haystack The list of strings to search in.
 * @param threshold The minimum similarity threshold for a match.
 * @returns The index of the closest match, or null if no match was found.
 */
const findClosestMatch = (
  needle: string | undefined,
  haystack: string[],
  threshold: number
): number | null => {
  try {
    if (!needle) return null
    const closestMatch = stringSimilarity.findBestMatch(
      needle.toLowerCase(),
      haystack.map((hay) => hay.toLowerCase())
    )
    if (closestMatch.bestMatch.rating >= threshold) {
      return closestMatch.bestMatchIndex
    }
    return null
  } catch (error) {
    return null
  }
}

/** Leading and trailing spaces are omitted. */
const insertSpaces = (
  inString: string,
  options: {
    every: number
    afterFirstSpaceAt?: number
  }
) => {
  const { every, afterFirstSpaceAt } = options
  let out = ''
  let toProcess = inString
  if (afterFirstSpaceAt) {
    out += inString.slice(0, afterFirstSpaceAt)
    out += ' '
    toProcess = inString.slice(afterFirstSpaceAt)
  }
  for (let i = 0; i < toProcess.length; i++) {
    out += toProcess[i]
    if ((i + 1) % every === 0) out += ' '
  }
  return out.trim()
}

const StringUtilities = {
  generateRandomId,
  rateResultBySearch,
  searchDataByValue,
  truncate,
  findClosestMatch,
  insertSpaces,
}

export default StringUtilities
