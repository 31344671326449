class NetworkingConstants {
  public static readonly HOME: string =
    import.meta.env.VITE_FABRIC_ENV === 'production'
      ? 'https://app.infinity.swiss'
      : 'https://staging.infinity.nextbusiness.ch'
  public static readonly HOST: string =
    import.meta.env.VITE_BACKEND_HOST ||
    'https://b1.service.finance.infinity.swiss'
  public static readonly FABRIC_HOST: string =
    import.meta.env.VITE_FABRIC_HOST ||
    'https://infinity-fabric.azurewebsites.net'
}

export default NetworkingConstants
