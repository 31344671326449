import { Button } from '@nextbusiness/infinity-ui'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import DocumentSidebarPanelProps from '../DocumentSidebarPanelProps'

const CustomerInvoiceToggleDraftAction = (props: DocumentSidebarPanelProps) => {
  const { preferencesStore } = useRootStore()
  const documentName = props.document.isQuote ? 'Offerte' : 'Forderung'

  if (props.document.isDraft) {
    return (
      <Button
        variant='quaternary'
        iconLeft='checked-checkbox'
        className='sidebar-action'
        disabled={!props.canSaveAsNonDraft}
        onClick={() => {
          let saveMessage = `${documentName} wurde gestellt.`
          if (!props.document.isQuote)
            saveMessage += ' Sie wird nun in deinen Auswertungen erscheinen.'
          props.registerEdit({ isDraft: false }, true, saveMessage)
          preferencesStore.markCoachmarkAsSeen('customer-invoice-draft')
          MixpanelAnalytics.event('Promoted customer invoice to non-draft')
        }}
        tooltip={
          props.document.isQuote
            ? `Stelle eine Offerte, um sie an deine Kunden zu senden.`
            : `Stelle eine Forderung, um sie zu verbuchen und versenden zu können.`
        }
      >
        {documentName} stellen
      </Button>
    )
  } else {
    return (
      <Button
        variant='quaternary'
        iconLeft='blueprint'
        className='sidebar-action'
        disabled={!!props.existingInvoice?.paymentTransactions.length}
        onClick={() => {
          const saveMessage = `${documentName} wurde zu einem Entwurf zurückgestellt.`
          props.registerEdit({ isDraft: true }, true, saveMessage)
        }}
        tooltip={
          props.document.isQuote
            ? 'Stellt die Offerte zurück zu den Entwürfen.'
            : 'Stellt die Forderung zurück zu den Entwürfen. Sie wird dann nicht mehr verbucht sein.'
        }
      >
        Zu Entwurf zurückstellen
      </Button>
    )
  }
}

export default observer(CustomerInvoiceToggleDraftAction)
