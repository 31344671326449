import {
  APIError,
  AuthorisedAccount,
  LiveAccountingConnection,
} from '@nextbusiness/infinity-finance-api'
import {
  Button,
  FeedbackBanner,
  Flex,
  Heading,
  Text,
} from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import CardRadioGroup from 'ledger/accounts/account-creation/CardRadioGroup'
import BankAccountSetupStep from './BankAccountSetupStep'

interface BankAccountAssociateAccountStepProps {
  connection: LiveAccountingConnection | undefined
  connectionLoadingError: APIError | undefined
  selectedAccount: AuthorisedAccount | undefined
  setSelectedAccount: (account: AuthorisedAccount | undefined) => void
  onContinue: () => void
}

const formatIBAN = (iban: string) => {
  return iban.replace(/(.{4})/g, '$1 ')
}

const BankAccountAssociateAccountStep = (
  props: BankAccountAssociateAccountStepProps
) => {
  return (
    <BankAccountSetupStep>
      <Flex className='bank-account-method-setup' gap='regular'>
        <div>
          <Icon icon='library' size={IconSize.Regular} />
        </div>
        <div>
          <Heading type='h2' bareTop>
            Konto auswählen
          </Heading>
          <Text>
            Wähle aus, welches der autorisierten Konten du mit diesem Konto
            verknüpfen möchtest.
          </Text>
          {props.connectionLoadingError && (
            <FeedbackBanner
              variant='error'
              title='Konten konnten nicht geladen werden.'
            >
              {props.connectionLoadingError.humanText('de')}
            </FeedbackBanner>
          )}
          <div className='setup-options'>
            {!!props.connection?.authorisedAccounts && (
              <CardRadioGroup
                value={props.selectedAccount?.id}
                onChange={(selectedAccountId) =>
                  props.setSelectedAccount(
                    props.connection?.authorisedAccounts?.find(
                      (account) => account.id === selectedAccountId
                    )
                  )
                }
                options={props.connection.authorisedAccounts.map((account) => ({
                  value: account.id,
                  label: account.designation,
                  description: formatIBAN(account.account.identification),
                }))}
              />
            )}
          </div>
          <Button
            variant='primary'
            disabled={!props.selectedAccount}
            onClick={props.onContinue}
          >
            Fortfahren
          </Button>
        </div>
      </Flex>
    </BankAccountSetupStep>
  )
}

export default BankAccountAssociateAccountStep
