import {
  ITransaction,
  TransactionOrigin,
} from '@nextbusiness/infinity-finance-api'
import { SpecialAccount } from 'components/transactions/SpecialAccounts'
import TransactionCard from '../TransactionCard'
import TransactionView, { TransactionViewProps } from '../TransactionView'

export enum TransactionPreviewType {
  Normal = 'normal',
  VAT = 'vat',
}

type PreviewTransactionProps = {
  type: TransactionPreviewType
  transaction?: Partial<ITransaction>
  currentAccount?: SpecialAccount
} & Omit<TransactionViewProps, 'transaction' | 'currentAccount'>

const previewTransactionByType: Record<
  TransactionPreviewType,
  Partial<ITransaction>
> = {
  [TransactionPreviewType.Normal]: {
    amount: 9250,
    debitAccount: 6570,
    creditAccount: 1020,
    description: 'Internetkosten Büro',
    grossAmount: 10000,
    origin: TransactionOrigin.Manual,
    attachments: ['attachment-1', 'attachment-2'],
    taxCode: 'UN81',
  },
  [TransactionPreviewType.VAT]: {
    amount: 750,
    debitAccount: 1171,
    creditAccount: 1020,
    description: 'Internetkosten Büro',
    grossAmount: 10000,
    origin: TransactionOrigin.VAT,
  },
}

const PreviewTransaction = (props: PreviewTransactionProps) => (
  <TransactionCard>
    <TransactionView
      {...props}
      currentAccount={
        props.currentAccount ?? {
          accountNumber: 1020,
          name: 'Bank',
        }
      }
      transaction={
        {
          date: Date.now(),
          fiscalYear: 0,
          id: 'example-transaction',
          linkedTransactions: [],
          organisation: '',
          origin: TransactionOrigin.Manual,
          ...previewTransactionByType[props.type],
          ...props.transaction,
        } as ITransaction
      }
    />
  </TransactionCard>
)

export default PreviewTransaction
