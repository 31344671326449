import { FinancialHealthCardType } from '@nextbusiness/infinity-finance-api'
import { Flex } from '@nextbusiness/infinity-ui'
import RadialGauge from 'components/gauges/RadialGauge'
import { observer } from 'mobx-react'
import {
  CurrentReportStackNavigatorRef,
  ReportPagePaths,
} from 'pages/ReportPage'
import { useEffect } from 'react'
import FinancialHealthCard from 'reports/financial-health/components/FinancialHealthCard'
import LoaderFinancialHealthStatus from 'reports/financial-health/loaders/LoaderFinancialHealthStatus'
import { useRootStore } from 'stores/RootStoreContext'
import { usePluralise } from 'utility/hooks'
import { ReactComponent as IndicatorSuccess } from '../../../../assets/indicator-success.svg'
import { ReactComponent as IndicatorWarning } from '../../../../assets/indicator-warning.svg'
import './FinancialHealthStatusCard.scss'

const FinancialHealthStatusCard = () => {
  const { reportingStore, preferencesStore } = useRootStore()
  const status = reportingStore.status()
  const financialHealthCards =
    preferencesStore.organisationPreferences?.financialHealthCards

  const ensureRequiredReportsAreLoaded = async () => {
    if (status.hasFullyLoaded) return
    const shouldLoad = (
      report:
        | FinancialHealthCardType.FinancialSolvency
        | FinancialHealthCardType.Maturities
        | FinancialHealthCardType.Profitability
    ) => !financialHealthCards?.find((card) => card.kind === report)

    const reportsToLoad = []
    if (shouldLoad(FinancialHealthCardType.FinancialSolvency)) {
      reportsToLoad.push(new Promise(reportingStore.loadFinancialSolvency))
    }
    if (shouldLoad(FinancialHealthCardType.Maturities)) {
      reportsToLoad.push(new Promise(reportingStore.loadMaturities))
    }
    if (shouldLoad(FinancialHealthCardType.Profitability)) {
      reportsToLoad.push(new Promise(reportingStore.loadProfitability))
    }
    await Promise.all(reportsToLoad)
  }

  useEffect(() => {
    ensureRequiredReportsAreLoaded()
  }, [])

  return (
    <FinancialHealthCard
      title='Hinweise und Probleme'
      className='financial-health-card-status'
      isLoading={!status.hasFullyLoaded}
      customLoadingState={<LoaderFinancialHealthStatus />}
      onClick={() =>
        CurrentReportStackNavigatorRef?.open(
          ReportPagePaths.FinancialHealthStatus
        )
      }
    >
      <Flex fillContainer verticalAlignment='center'>
        <div className='status-gauge'>
          <RadialGauge
            level={status.summaryStatusGauge}
            label={status.summaryStatusLabel}
            coloured
          />
        </div>
        <div className='status-list'>
          {status.metricGroups.map((group) => (
            <FinancialHealthStatusLine
              text={group.title}
              key={group.title}
              warningCount={group.warningsCount}
              errorCount={group.problemsCount}
            />
          ))}
        </div>
      </Flex>
    </FinancialHealthCard>
  )
}

interface FinancialHealthStatusLineProps {
  text: string
  warningCount: number
  errorCount: number
}

const FinancialHealthStatusLine = (props: FinancialHealthStatusLineProps) => {
  const hasWarnings = props.warningCount > 0
  const hasErrors = props.errorCount > 0
  const warningCopy = usePluralise(props.warningCount, 'Hinweis', {
    suffix: 'e',
  })
  const errorCopy = usePluralise(props.errorCount, 'Problem', {
    suffix: 'e',
  })

  return (
    <Flex className='financial-health-status-line'>
      <div className='depiction'>
        <span className='title'>{props.text}</span>
        {(hasWarnings || hasErrors) && (
          <span className='warnings'>
            {hasWarnings && warningCopy}
            {hasWarnings && hasErrors ? ', ' : ''}
            {hasErrors && errorCopy}
          </span>
        )}
      </div>
      <div className='indicator'>
        {hasWarnings || hasErrors ? <IndicatorWarning /> : <IndicatorSuccess />}
      </div>
    </Flex>
  )
}

export default observer(FinancialHealthStatusCard)
