import { Flex } from '@nextbusiness/infinity-ui'
import React from 'react'

interface ExtendedInvoiceListScrollViewProps {
  children?: React.ReactNode
}

const ExtendedInvoiceListScrollView = (
  props: ExtendedInvoiceListScrollViewProps
) => {
  return (
    <div className='extended-invoice-list-scroll-view'>
      <Flex direction='horizontal' className='extended-invoices-list'>
        {props.children}
      </Flex>
    </div>
  )
}

export default ExtendedInvoiceListScrollView
