import { NonIdealState } from '@nextbusiness/infinity-ui'
import { useQuery } from '@tanstack/react-query'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import { ContactRelatedItemsTab } from 'contacts/ContactPageStackContext'
import { observer } from 'mobx-react'
import Contacts from 'networking/Contacts'
import { useState } from 'react'
import MagicSheetPage from '../../components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from '../../components/magic-sheet/MagicSheetTitleBar'
import { useRootStore } from '../../stores/RootStoreContext'
import { ContactContext } from './ContactContext'
import ContactHeading from './ContactHeading'
import ContactInputSearchField from './ContactInputSearchField'
import ContactStack from './ContactStack'
import LinkedItemsSection from './LinkedItemsSection'
import SingleContactInfoSection from './SingleContactInfoSection'
import './SingleContactPageOverview.scss'
interface SingleContactPageOverviewProps {
  contactId: string
  stack: ContactStack
  initialTab?: ContactRelatedItemsTab
}

const SingleContactPageOverview = (props: SingleContactPageOverviewProps) => {
  const { authenticationStore } = useRootStore()

  const contactId = props.contactId

  const {
    data: contact,
    isPending,
    error,
    refetch,
  } = useQuery({
    queryKey: [
      'contact',
      contactId,
      authenticationStore.fabricOrganisationIdentifier,
      authenticationStore.applicationAccessToken,
    ],
    queryFn: () =>
      Contacts.getContact(
        authenticationStore.fabricOrganisationIdentifier!,
        contactId,
        authenticationStore.applicationAccessToken!
      ),
  })

  const [searchValue, setSearchValue] = useState<string>('')

  if (isPending) {
    return <LoadingScreen />
  }

  if (error) {
    return (
      <NonIdealState
        title='Kontakt konnte nicht geladen werden.'
        icon='error'
        actions={[{ children: 'Erneut versuchen', onClick: () => refetch() }]}
      />
    )
  }

  return (
    <ContactContext.Provider value={contact}>
      <MagicSheetPage
        header={
          <MagicSheetTitleBar
            title={<ContactHeading />}
            headingProps={{ divider: false, bare: true }}
          >
            <ContactInputSearchField
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          </MagicSheetTitleBar>
        }
        className='single-contact-page-overview'
        noTrailingNegativeSpace
      >
        <SingleContactInfoSection />
        <LinkedItemsSection
          stack={props.stack}
          initialTab={props.initialTab}
          searchValue={searchValue}
        />
      </MagicSheetPage>
    </ContactContext.Provider>
  )
}

export default observer(SingleContactPageOverview)
