import {
  AmountMode,
  Finance,
  VATPresentationOptions,
} from '@nextbusiness/infinity-finance-api'
import {
  INewTemplate,
  ITemplate,
} from '@nextbusiness/infinity-finance-api/dist/models/ITemplate'
import Templates from 'networking/Templates'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'

// `export default observer` is required for the component where this hook is used.
export const useCustomerInvoiceSetup = () => {
  const { templateStore, authenticationStore } = useRootStore()
  const organisationId = authenticationStore.organisationIdentifier

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const createTemplate = async (template: INewTemplate) => {
    const createdTemplate = await Finance.CustomerInvoice.createTemplate(
      template
    )
    return createdTemplate
  }

  const performSetup = async (
    template: INewTemplate,
    amountMode?: AmountMode,
    vatPresentation?: VATPresentationOptions,
    logo?: File
  ) => {
    setIsLoading(true)
    let createdTemplate: ITemplate | null = null
    try {
      createdTemplate = await createTemplate(template)
      await Finance.Organisation.setPreferences({
        customerInvoiceAmountMode: amountMode,
        vatPresentationOptions: vatPresentation,
      })
      if (logo) {
        await Templates.uploadLogo(
          logo,
          logo.name ?? '',
          organisationId,
          createdTemplate.id
        )
      }
      await Promise.all([
        templateStore.loadTemplates(),
        templateStore.loadCurrentTemplate(),
      ])
    } catch (error) {
      if (createdTemplate) await attemptRollback(createdTemplate.id)
      setIsLoading(false)
      throw error
    }
  }
  return { performSetup, isLoading }
}

const attemptRollback = async (templateId: string) => {
  try {
    await Finance.CustomerInvoice.deleteTemplate(templateId)
  } catch (error: any) {
    // Fail silently
  }
}
