import { IProject } from '@nextbusiness/infinity-finance-api'
import { Flex, Heading, Text } from '@nextbusiness/infinity-ui'
import Tabs, { Tab } from 'proto-ui-components/Tabs'
import { useState } from 'react'
import './ProjectViewContent.scss'
import ProjectInvoices from './invoices/ProjectInvoices'
import UnbilledWork from './unbilled-work/UnbilledWork'

type ProjectTab = 'unbilled-work' | 'invoices'
const PROJECT_TABS: (Tab & { identifier: ProjectTab })[] = [
  {
    identifier: 'unbilled-work',
    title: 'Erledigte Arbeiten',
  },
  {
    identifier: 'invoices',
    title: 'Forderungen',
  },
]
const PROJECT_TABS_DETAILS = {
  ['unbilled-work']:
    'Trage hier deine erledigten Arbeiten für dieses Projekt ein, die du später dem Kunden verrechnen möchtest.',
  invoices:
    'Hier siehst du alle Forderungen, die du für dieses Projekt erstellt hast.',
}

interface ProjectViewContentProps {
  project: IProject
}

const ProjectViewContent = (props: ProjectViewContentProps) => {
  const [currentTab, setCurrentTab] = useState<ProjectTab>('unbilled-work')
  const currentTabTitle = PROJECT_TABS.find(
    (tab) => tab.identifier == currentTab
  )?.title

  return (
    <Flex className='project-content' direction='vertical'>
      <Tabs
        currentTabId={currentTab}
        tabs={PROJECT_TABS}
        onTabSelected={(tab) => setCurrentTab(tab.identifier)}
      />
      <Heading className='project-tab-title' type='h4' bare>
        {currentTabTitle}
      </Heading>
      <Text className='project-tab-description'>
        {PROJECT_TABS_DETAILS[currentTab]}
      </Text>
      {currentTab === 'unbilled-work' && (
        <UnbilledWork
          projectId={props.project.id}
          unbilledWork={props.project.unbilledWork}
        />
      )}
      {currentTab === 'invoices' && (
        <ProjectInvoices invoiceIds={props.project.invoiceIds} />
      )}
    </Flex>
  )
}

export default ProjectViewContent
