import { StatusIndicatorProps } from '@nextbusiness/infinity-ui'
import { useMemo, useState } from 'react'

export const useTimeTrackingIndicator = () => {
  const [timeTrackingStatusText, setTimeTrackingStatusText] = useState<
    string | undefined
  >(undefined)
  const [isTimeTrackingPanelOpen, setIsTimeTrackingPanelOpen] =
    useState<boolean>(false)

  const timeTrackingStatusIndicator: StatusIndicatorProps = useMemo(
    () => ({
      text: timeTrackingStatusText,
      icon: 'clock',
      onClick: () => setIsTimeTrackingPanelOpen((isOpen) => !isOpen),
    }),
    [timeTrackingStatusText]
  )

  return {
    timeTrackingStatusIndicator,
    isTimeTrackingPanelOpen,
    setTimeTrackingStatusText,
    setIsTimeTrackingPanelOpen,
  }
}
