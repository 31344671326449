import EverythingSearch from '../EverythingSearch'
import AccountSearchProvider from './AccountSearchProvider'
import ContactSearchProvider from './ContactSearchProvider'
import CustomerInvoiceSearchProvider from './CustomerInvoiceSearchProvider'
import NavigationSearchProvider from './NavigationSearchProvider'
import OrganisationSearchProvider from './OrganisationSearchProvider'
import TransactionSearchProvider from './TransactionSearchProvider'
import VendorInvoiceSearchProvider from './VendorInvoiceSearchProvider'

class Providers {
  static setupSearchProviders() {
    const navigationSearchProvider = new NavigationSearchProvider()
    const accountSearchProvider = new AccountSearchProvider()
    const contactSearchProvider = new ContactSearchProvider()
    const organisationSearchProvider = new OrganisationSearchProvider()
    const vendorInvoiceSearchProvider = new VendorInvoiceSearchProvider()
    const transactionSearchProvider = new TransactionSearchProvider()
    const customerInvioceSearchProvider = new CustomerInvoiceSearchProvider()

    EverythingSearch.registerProvider(navigationSearchProvider)
    EverythingSearch.registerProvider(accountSearchProvider)
    EverythingSearch.registerProvider(contactSearchProvider)
    EverythingSearch.registerProvider(organisationSearchProvider)
    EverythingSearch.registerProvider(vendorInvoiceSearchProvider)
    EverythingSearch.registerProvider(transactionSearchProvider)
    EverythingSearch.registerProvider(customerInvioceSearchProvider)
  }
}

export default Providers
