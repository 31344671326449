import { MoneyField, Text } from '@nextbusiness/infinity-ui'
import FormField from 'base-components/layout/FormField'

interface SetupAccountBalanceSimpleFieldProps {
  label: string
  hint?: string
  value?: number | null
  onChange: (value: number | null) => void
}

const SetupAccountBalanceSimpleField = (
  props: SetupAccountBalanceSimpleFieldProps
) => (
  <FormField label={props.label}>
    <MoneyField
      value={props.value ?? 0}
      onChange={(balance) => props.onChange(balance)}
    />
    {props.hint && (
      <Text variant='subtle' type='inline'>
        {props.hint}
      </Text>
    )}
  </FormField>
)

export default SetupAccountBalanceSimpleField
