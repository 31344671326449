import { NonIdealState } from '@nextbusiness/infinity-ui'
import { ICustomerInvoicePreview } from 'model/CustomerInvoice'
import { useHistory } from 'react-router-dom'
import CustomerInvoiceListItem from '../CustomerInvoiceListItem'
import useFilteredCustomerInvoices from './useFilteredCustomerInvoices'

interface CustomerInvoicesFilteredListProps {
  invoices: ICustomerInvoicePreview[] | undefined
  emptyStateText: string
}

const CustomerInvoicesFilteredList = (
  props: CustomerInvoicesFilteredListProps
) => {
  const history = useHistory()
  const filteredInvoices = useFilteredCustomerInvoices(props.invoices)

  return (
    <>
      {!filteredInvoices?.length && (
        <div className='customer-invoices-empty'>
          <NonIdealState>{props.emptyStateText}</NonIdealState>
        </div>
      )}
      {filteredInvoices?.map((invoice) => (
        <CustomerInvoiceListItem
          invoice={invoice}
          key={invoice.id}
          onClick={() => history.push(`/forderungen/detail/${invoice.id}`)}
        />
      ))}
    </>
  )
}

export default CustomerInvoicesFilteredList
