import { IProjectPreview } from '@nextbusiness/infinity-finance-api'
import InvoiceListItemContainer from 'invoices/shared-components/regular-invoice-list/InvoiceListItemContainer'
import InvoiceListItemDepiction from 'invoices/shared-components/regular-invoice-list/InvoiceListItemDepiction'
import InvoiceListItemTotalAmount from 'invoices/shared-components/regular-invoice-list/InvoiceListItemTotalAmount'
import ExtendedProjectIcon from 'projects/icon/ExtendedProjectIcon'
import ProjectStatusIndicator from './ProjectStatusIndicator'

interface ProjectListItemProps {
  project: IProjectPreview
  onClick: () => void
}

const ProjectListItem = (props: ProjectListItemProps) => (
  <InvoiceListItemContainer onClick={props.onClick}>
    <ExtendedProjectIcon client={props.project.client} value={0} small />
    <InvoiceListItemDepiction
      contactId={props.project.client}
      title={props.project.title}
    />
    <ProjectStatusIndicator status={props.project.status} />
    <InvoiceListItemTotalAmount totalAmount={props.project.totalValue} />
  </InvoiceListItemContainer>
)

export default ProjectListItem
