import Utilities from 'utility/Utilities'

interface FinancialHealthMaturitiesRowProps {
  invoices: string[]
  timeframe: string
  total: number
}

const FinancialHealthMaturitiesRow = (
  props: FinancialHealthMaturitiesRowProps
) =>
  props.invoices.length === 0 || props.total === 0 ? null : (
    <div className='maturities-group'>
      <div className='maturities-icon' />
      <div className='maturities-details'>
        <span className='count'>
          {props.invoices.length} Rechnung
          {props.invoices.length > 1 ? 'en' : ''} {props.timeframe}
        </span>
        <span className='total'>CHF {Utilities.centsToCHF(props.total)}</span>
      </div>
    </div>
  )

export default FinancialHealthMaturitiesRow
