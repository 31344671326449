interface VATFormHeader1Props {
  title: string
  note?: string
}

const VATFormHeader1 = (props: VATFormHeader1Props) => (
  <div className='form-header-1'>
    <div className='title'>{props.title}</div>
    <div className='note'>{props.note}</div>
  </div>
)

export default VATFormHeader1
