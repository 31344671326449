import { AmountMode, TaxCode } from '@nextbusiness/infinity-finance-api'
import { MoneyField } from '@nextbusiness/infinity-ui'
import FormField from 'base-components/layout/FormField'
import VATPicker from 'components/vat/vat-picker/VATPicker'
import { observer } from 'mobx-react'
import SettingsGroup from 'settings/general/SettingsGroup'
import { useRootStore } from 'stores/RootStoreContext'
import { ProductEditFieldsProps } from '../ProductEditFields'

const ProductFieldsPricing = (props: ProductEditFieldsProps) => {
  const { preferencesStore } = useRootStore()
  const isUsingNetAmountMode =
    preferencesStore.organisationPreferences?.customerInvoiceAmountMode ===
    AmountMode.Net
  return (
    <SettingsGroup title='Preise'>
      <FormField label='Verkaufspreis'>
        <MoneyField
          // Force rerender due to issue with MoneyField component
          // not properly resetting once value is set to null
          key={props.product.name}
          value={props.product.sellingPrice ?? null}
          onChange={(sellingPrice) =>
            props.editProduct({ sellingPrice: sellingPrice ?? undefined })
          }
          hasError={props.shouldValidate && !props.product.sellingPrice}
          helperText={
            props.shouldValidate && !props.product.sellingPrice
              ? 'Erforderlich'
              : undefined
          }
          placeholder={isUsingNetAmountMode ? 'Nettopreis' : 'Bruttopreis'}
        />
      </FormField>
      {preferencesStore.organisationPreferences?.VAT && (
        <FormField label='Mehrwertsteuer-Typ'>
          <VATPicker
            taxCode={(props.product.taxCode ?? '') as TaxCode}
            onTaxCodeSelected={(taxCode) => props.editProduct({ taxCode })}
            type='income'
            // Force rerender when product changes (otherwise VATPicker
            // component will not properly reset if the value is set to null)
            key={props.product.name}
          />
        </FormField>
      )}
    </SettingsGroup>
  )
}

export default observer(ProductFieldsPricing)
