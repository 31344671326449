import { DateDisplayFormatOptions } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import ReportPrintHeader from 'reports/ReportPrintHeader'
import {
  IAccountGroup,
  IBalanceSheet,
  IGroup,
  IReportAccount,
} from '../../model/Report'
import { useRootStore } from '../../stores/RootStoreContext'
import './BalanceSheet.print.scss'
import './BalanceSheet.scss'
import BalanceSheetLayer from './BalanceSheetLayer'
import { yearBefore } from './BalanceSheetPage'

interface BalanceSheetProps {
  balanceSheet: IBalanceSheet
  date?: Date
  dateCopy: Date
  showComparison?: boolean
  pastBalanceSheet?: IBalanceSheet
  setHasScrolled?: (hasScrolled: boolean) => void
}

type BalanceSheetAccountType = 'assets' | 'liabilities'
const BALANCE_SHEET_ACCOUNT_TYPES: BalanceSheetAccountType[] = [
  'assets',
  'liabilities',
]

const BalanceSheet = (props: BalanceSheetProps) => {
  const { accountStore } = useRootStore()

  const balanceSheetRef = useRef<HTMLDivElement>(null)
  const history = useHistory()
  const date =
    props.dateCopy.toLocaleString('de-CH', DateDisplayFormatOptions) || ''

  useEffect(() => {
    const balanceSheet = balanceSheetRef.current

    const handleScroll = () => {
      if (!balanceSheet || !props.setHasScrolled) return
      if (
        balanceSheet.scrollTop > 0 &&
        balanceSheet.scrollHeight > balanceSheet.clientHeight * 1.5
      )
        props.setHasScrolled(true)
      else if (balanceSheet.scrollTop === 0) props.setHasScrolled(false)
    }

    balanceSheet?.addEventListener('scroll', handleScroll)
    return () => balanceSheet?.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div className='balance-sheet' ref={balanceSheetRef}>
      <ReportPrintHeader title={`Bilanz per ${date}`} />
      {BALANCE_SHEET_ACCOUNT_TYPES.map(
        (balanceSheetSide: BalanceSheetAccountType) => {
          const accountType = props.balanceSheet[balanceSheetSide]
          return (
            <BalanceSheetLayer
              key={accountType.number}
              name={accountType.name}
              balance={
                accountType.balance +
                (accountType.name === 'Passiven'
                  ? props.balanceSheet.unbookedProfit
                  : 0)
              }
              showComparison={props.showComparison}
              pastBalance={
                props.pastBalanceSheet
                  ? props.pastBalanceSheet[balanceSheetSide].balance +
                    (accountType.name === 'Passiven'
                      ? props.pastBalanceSheet.unbookedProfit
                      : 0)
                  : undefined
              }
              pastDate={
                props.date
                  ? yearBefore(props.date).toLocaleString(
                      'de-CH',
                      DateDisplayFormatOptions
                    )
                  : undefined
              }
              date={date}
              depth={0}
              nestedLayer={[
                ...accountType.accountGroups.map(
                  (accountGroup: IAccountGroup, accountGroupIndex) => (
                    <BalanceSheetLayer
                      key={accountGroup.number}
                      name={accountGroup.name}
                      balance={accountGroup.balance}
                      showComparison={props.showComparison}
                      pastBalance={
                        props.pastBalanceSheet
                          ? props.pastBalanceSheet[balanceSheetSide]
                              ?.accountGroups[accountGroupIndex]?.balance
                          : undefined
                      }
                      depth={1}
                      nestedLayer={accountGroup.groups.map(
                        (group: IGroup, groupIndex) => (
                          <BalanceSheetLayer
                            key={group.number}
                            name={group.name}
                            balance={group.balance}
                            showComparison={props.showComparison}
                            pastBalance={
                              props.pastBalanceSheet
                                ? props.pastBalanceSheet[balanceSheetSide]
                                    ?.accountGroups[accountGroupIndex]?.groups[
                                    groupIndex
                                  ]?.balance
                                : undefined
                            }
                            account={accountStore.find(accountGroup.number)}
                            history={history}
                            depth={2}
                            nestedLayer={group.accounts.map(
                              (account: IReportAccount, accountIndex) => (
                                <BalanceSheetLayer
                                  key={account.number}
                                  name={account.name}
                                  balance={account.balance}
                                  showComparison={props.showComparison}
                                  pastBalance={
                                    props.pastBalanceSheet
                                      ? props.pastBalanceSheet[balanceSheetSide]
                                          ?.accountGroups[accountGroupIndex]
                                          ?.groups[groupIndex]?.accounts[
                                          accountIndex
                                        ]?.balance
                                      : undefined
                                  }
                                  account={accountStore.find(account.number)}
                                  history={history}
                                  depth={3}
                                />
                              )
                            )}
                          />
                        )
                      )}
                    />
                  )
                ),
                accountType.name === 'Passiven' ? (
                  <BalanceSheetLayer
                    name={'Unverbuchter Gewinn'}
                    balance={props.balanceSheet.unbookedProfit}
                    showComparison={props.showComparison}
                    pastBalance={
                      props.pastBalanceSheet
                        ? props.pastBalanceSheet.unbookedProfit
                        : undefined
                    }
                    history={history}
                    depth={1}
                    dontShowExpansionChevron
                    sumOnly
                  />
                ) : null,
              ]}
            />
          )
        }
      )}
      <BalanceSheetLayer
        name={'Unverbuchter Gewinn'}
        balance={props.balanceSheet.unbookedProfit}
        showComparison={props.showComparison}
        pastBalance={
          props.pastBalanceSheet
            ? props.pastBalanceSheet.unbookedProfit
            : undefined
        }
        history={history}
        depth={0}
        sumOnly
      />
    </div>
  )
}

export default observer(BalanceSheet)
