import 'ts-array-extensions'

// Import global styles before other modules, which may include more specific styles
import '@nextbusiness/infinity-ui/dist/index.css'
import './styles/index.scss'

import { Finance } from '@nextbusiness/infinity-finance-api'
import { TypographyBase } from '@nextbusiness/infinity-ui-foundations'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import SentryErrorBoundary from 'integrations/SentryErrorBoundary'
import SentryIntegration from 'integrations/SentryIntegration'
import SprigIntegration from 'integrations/SprigIntegration'
import { configure } from 'mobx'
import NetworkingConstants from 'networking/NetworkingConstants'
import React from 'react'
import ReactDOM from 'react-dom'
import { IntercomProvider } from 'react-use-intercom'
import Application from './application/Application'
import reportWebVitals from './utility/reportWebVitals'

configure({
  enforceActions: 'never',
})

Finance.Configuration.setBaseURL(NetworkingConstants.HOST)

if (SentryIntegration.shouldInitialise) SentryIntegration.initialise()
if (SprigIntegration.shouldInitialise) SprigIntegration.initialise()
if (MixpanelAnalytics.shouldInitialise) MixpanelAnalytics.initialise()

ReactDOM.render(
  <React.StrictMode>
    <TypographyBase>
      <SentryErrorBoundary>
        <IntercomProvider
          appId={import.meta.env.VITE_INTERCOM_APP_ID ?? ''}
          shouldInitialize={import.meta.env.VITE_INTERCOM_APP_ID !== undefined}
        >
          <Application />
        </IntercomProvider>
      </SentryErrorBoundary>
    </TypographyBase>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
