import EditableDocumentText from '../../../controls/EditableDocumentText'
import { DocumentCustomProductPositionProps } from '../DocumentCustomProductPosition'

const PositionUnitField = (props: DocumentCustomProductPositionProps) => {
  if (!props.columns?.includes('unit')) return null
  return (
    <div className='positions-column unit'>
      <EditableDocumentText
        placeholder='Einheit'
        value={props.position.unit ?? ''}
        onChange={(unit) => {
          if (unit.length > 4) return
          props.onChange({ unit })
        }}
        style={{ width: '4rem' }}
        isReadOnly={props.isReadOnly}
      />
    </div>
  )
}

export default PositionUnitField
