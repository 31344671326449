import { Button, Text } from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import DocumentsDuePrompt from 'documents/DocumentsDuePrompt'
import { OpenVATPeriod } from 'model/VAT'
import DocumentsPage, { DocumentsPagePaths } from 'pages/DocumentsPage'

interface VATPeriodOlderPeriodsPromptProps {
  page: DocumentsPage
  period: OpenVATPeriod
}

const VATPeriodOlderPeriodsPrompt = (
  props: VATPeriodOlderPeriodsPromptProps
) => {
  const endDate = new Date(props.period.end)
  const endDateText = endDate.toLocaleDateString('de-DE', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

  return (
    <DocumentsDuePrompt
      title='Mehrwertsteuerabrechnung fällig'
      icon={<Icon icon='lock' size={IconSize.Big} />}
      appearance='subtle'
    >
      <Text>
        Die Periode hat am {endDateText} geendet. Sie kann abgerechnet werden,
        sobald alle früheren Perioden abgerechnet wurden.
      </Text>
      <Button
        onClick={() =>
          props.page.open(DocumentsPagePaths.VATReport, props.period._id)
        }
      >
        Vorschau ansehen
      </Button>
    </DocumentsDuePrompt>
  )
}

export default VATPeriodOlderPeriodsPrompt
