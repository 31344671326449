import LoadingScreen from 'base-components/layout/LoadingScreen'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const BLINK_CONTINUE_TIMEOUT_IN_SECONDS = 5

const BankAccountContinuePage = () => {
  const history = useHistory()

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.push('/buchen')
    }, BLINK_CONTINUE_TIMEOUT_IN_SECONDS * 1000)
    return () => clearTimeout(timeout)
  }, [])

  return <LoadingScreen loadingText='Einen Moment bitte…' />
}

export default BankAccountContinuePage
