import GrowthIndicator from 'components/growth-indicator/GrowthIndicator'
import Utilities from 'utility/Utilities'
import './FinancialHealthSumOverview.scss'

interface FinancialHealthSumOverviewProps {
  label: string
  currentSum: number
  previousSum?: number
  change?: number | null
  hideComparisonCopy?: boolean
}

const FinancialHealthSumOverview = (props: FinancialHealthSumOverviewProps) => {
  const comparisonCopy = () => {
    if (props.change === 0 || props.currentSum === props.previousSum)
      return 'Keine Veränderung zur Vorperiode'
    if (props.previousSum === undefined)
      return 'Keine Vergleichsdaten vorhanden'

    return `Verglichen mit ${Utilities.centsToCHF(
      props.previousSum
    )} in Vorperiode`
  }

  return (
    <div className='sum-overview'>
      <span className='label'>{props.label}</span>
      <div className='value'>
        <span className='amount'>
          CHF {Utilities.centsToCHF(props.currentSum)}
        </span>
        {!!(props.change || props.previousSum) && (
          <GrowthIndicator
            change={props.change}
            values={
              props.previousSum
                ? { new: props.currentSum, old: props.previousSum }
                : undefined
            }
          />
        )}
      </div>
      {!props.hideComparisonCopy && (
        <span className='value-change'>{comparisonCopy()}</span>
      )}
    </div>
  )
}

export default FinancialHealthSumOverview
