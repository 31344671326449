import { RichSelect } from '@nextbusiness/infinity-ui'
import React from 'react'
import './DetailedRichSelect.scss'

const DetailedRichSelect = (props: React.ComponentProps<typeof RichSelect>) => (
  <RichSelect
    {...props}
    options={props.options?.map((section) => ({
      label: (
        <span className='ui-detailed-rich-select-heading'>{section.label}</span>
      ),
      options: section.options?.map((option) => ({
        label: (
          <div className='ui-detailed-rich-select-option'>
            <span className='title'>{option.label}</span>
            {option.description && <small>{option.description}</small>}
          </div>
        ),
        value: option.value,
        icon: option.icon,
      })),
    }))}
  />
)

export default DetailedRichSelect
