import {
  Finance,
  ITransaction,
  PaginationOptions,
  TransactionFilter,
} from '@nextbusiness/infinity-finance-api'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import TransactionUtilities from 'utility/TransactionUtilities'
import RootStore from './RootStore'
import Store from './Store'

type TransactionsByAccountNumber = {
  [accountNumber: number]: ITransaction[] | undefined
}

export default class TransactionStore extends Store {
  @observable public transactions: TransactionsByAccountNumber = {}
  @observable public filteredTransactions: TransactionsByAccountNumber = {}

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  /**
   * Loads transactions of a specific account into the corresponding entry
   * of {@link transactions}.
   *
   * Newly loaded transactions are merged with the existing ones.
   *
   * @param account The account number to load the transactions for.
   * @param pagination The pagination options for loading the transactions.
   * @returns The number of loaded transactions.
   */
  public async loadTransactionsForAccount(
    account: number,
    pagination?: PaginationOptions
  ): Promise<number> {
    const transactions = await Finance.Ledger.transactionsInAccount(
      account,
      undefined,
      pagination
    )
    runInAction(() => {
      const mergedTransactions = TransactionUtilities.mergedTransactions(
        transactions,
        this.transactions[account]
      )
      this.transactions[account] = mergedTransactions
    })
    return transactions.length
  }

  /**
   * Loads transactions of a specific account into the corresponding entry
   * of {@link filteredTransactions}.
   *
   * Newly loaded transactions are merged with the existing ones,
   * so removing previously loaded transactions that no longer match
   * the filter should be handled seperately.
   *
   * @param account The account number to load the transactions for.
   * @param filter The query parameters to filter the transactions by.
   * @param pagination The pagination options for loading the transactions.
   * @returns The number of loaded transactions.
   */
  public async loadFilteredTransactionsForAccount(
    account: number,
    filter: TransactionFilter | null,
    pagination?: PaginationOptions
  ): Promise<number> {
    const transactions = await Finance.Ledger.transactionsInAccount(
      account,
      filter ?? undefined,
      pagination
    )
    runInAction(() => {
      const mergedTransactions = TransactionUtilities.mergedTransactions(
        transactions,
        this.filteredTransactions[account]
      )
      this.filteredTransactions[account] = mergedTransactions
    })
    return transactions.length
  }

  @action
  public clearFilteredTransactionsForAccount(account: number) {
    this.filteredTransactions[account] = undefined
  }

  @action
  public removeTransactionFromAccount(
    transactionId: string,
    accountNumber: number
  ) {
    this.transactions[accountNumber] = this.transactions[accountNumber]?.filter(
      (transaction) => transaction.id !== transactionId
    )
  }

  public async refreshTransactions(withIds: string[]) {
    const freshTransactions = await Promise.all(
      withIds.map((id) => Finance.Ledger.transactions({ id }))
    )
    this.replaceTransactionsInStore(withIds, freshTransactions.flat())
  }

  @action
  public replaceTransactionsInStore(
    previousTransactionIds: string[],
    updatedTransactions: ITransaction[],
    isUsingFilter = false
  ) {
    const affectedAccounts = new Set<number>()
    this.removeTransactionIdsFromStore(previousTransactionIds)
    updatedTransactions.forEach((transaction) => {
      console.log({ transaction })
      affectedAccounts.add(transaction.creditAccount)
      affectedAccounts.add(transaction.debitAccount)
    })
    affectedAccounts.forEach((accountNumber) => {
      this.replaceUnfilteredTransactions(
        accountNumber,
        previousTransactionIds,
        updatedTransactions
      )
      if (isUsingFilter) {
        this.replaceFilteredTransactions(
          accountNumber,
          previousTransactionIds,
          updatedTransactions
        )
      }
    })
  }

  @action
  private replaceFilteredTransactions(
    accountNumber: number,
    previousTransactionIds: string[],
    updatedTransactions: ITransaction[]
  ) {
    const existingFilteredTransactions = this.filteredTransactions[
      accountNumber
    ]?.filter((transaction) => !previousTransactionIds.includes(transaction.id))
    this.filteredTransactions[accountNumber] =
      TransactionUtilities.mergedTransactions(
        updatedTransactions,
        existingFilteredTransactions
      )
  }

  @action
  private replaceUnfilteredTransactions(
    accountNumber: number,
    previousTransactionIds: string[],
    updatedTransactions: ITransaction[]
  ) {
    const existingTransactions = this.transactions[accountNumber]?.filter(
      (transaction) => !previousTransactionIds.includes(transaction.id)
    )
    this.transactions[accountNumber] = TransactionUtilities.mergedTransactions(
      updatedTransactions,
      existingTransactions
    )
  }

  @action
  public async deleteTransactions(transactions: ITransaction[]) {
    const deletedTransactions = await Finance.Ledger.deleteTransactions(
      transactions.map((transaction) => transaction.id)
    )
    runInAction(() => {
      this.removeTransactionsFromStore(deletedTransactions)
    })
  }

  public removeTransactionsFromStore(transactions: ITransaction[]) {
    for (const transaction of transactions) {
      this.removeTransactionFromAccount(
        transaction.id,
        transaction.debitAccount
      )
      this.removeTransactionFromAccount(
        transaction.id,
        transaction.debitAccount
      )
    }
  }

  public removeTransactionIdsFromStore(transactionIds: string[]) {
    for (const transactionId of transactionIds) {
      const transaction = Object.values(this.transactions)
        .flat()
        .find((transaction) => transaction?.id === transactionId)

      if (!transaction) continue
      this.removeTransactionFromAccount(transactionId, transaction.debitAccount)
      this.removeTransactionFromAccount(transactionId, transaction.debitAccount)
    }
  }

  public async markAsReviewed(
    transactions: ITransaction[],
    inAccountWithNumber: number
  ) {
    await Promise.all(
      transactions.map((transaction) =>
        Finance.Ledger.modifyTransaction(transaction.id, { reviewed: true })
      )
    )
    runInAction(() => {
      this.transactions[inAccountWithNumber] = this.transactions[
        inAccountWithNumber
      ]?.map((transaction) => ({
        ...transaction,
        reviewed: true,
      }))
    })
  }

  @computed
  public get allTransactions() {
    return Object.values(this.transactions)
      .filter((t) => t !== undefined)
      .flat() as ITransaction[]
  }
}
