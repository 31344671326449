import {
  AmountMode,
  IFixedAmountDiscountPosition,
  IPercentageDiscountPosition,
  TaxCode,
} from '@nextbusiness/infinity-finance-api'
import {
  INewCustomProductPosition,
  INewCustomerInvoice,
} from 'model/CustomerInvoice'
import { taxRateForTaxCode } from 'utility/TaxUtilities'

class CustomerInvoicePositions {
  private customerInvoice: INewCustomerInvoice

  constructor(customerInvoice: INewCustomerInvoice) {
    this.customerInvoice = customerInvoice
  }

  /**
   * Returns the gross total of the invoice.
   */
  public get grossTotal() {
    return this.totals.grossTotal
  }

  /**
   * Returns the net total of the invoice.
   */
  public get netTotal() {
    return this.totals.netTotal
  }

  /**
   * Calculates the gross and net total of the invoice.
   */
  public get totals() {
    const amountMode = this.customerInvoice.amountMode

    let netAmountSum = 0
    let enteredAmountSum = 0

    let taxAmount = 0
    let taxAmountAdjustment = 0

    let fixedDiscounts = 0
    let percentageBasis = 1

    this.customerInvoice.positions.forEach((position) => {
      if (position.type === 'custom-product') {
        netAmountSum += this.netAmountForPosition(position)
        enteredAmountSum +=
          CustomerInvoicePositions.enteredAmountForPosition(position)
        taxAmount += CustomerInvoicePositions.vatDebtForPosition(
          position,
          amountMode
        )
        taxAmountAdjustment += this.vatDiscountAdjustmentForPosition(position)
      } else if (position.type === 'discount') {
        if ('amount' in position) {
          fixedDiscounts += position.amount
        } else if ('percentage' in position) {
          percentageBasis -= position.percentage * 0.01
        }
      }
    })

    let grossTotal = 0
    let netTotal = 0

    if (amountMode === AmountMode.Net) {
      netTotal = (netAmountSum - fixedDiscounts) * percentageBasis
      grossTotal = netTotal + taxAmount - taxAmountAdjustment
    } else {
      grossTotal = (enteredAmountSum - fixedDiscounts) * percentageBasis
      netTotal = grossTotal - taxAmount + taxAmountAdjustment
    }
    return {
      grossTotal: Math.round(Math.max(grossTotal, 0)),
      netTotal: Math.round(Math.max(netTotal, 0)),
    }
  }

  /**
   * Returns the total amount before discounts, based on entered amounts.
   * This is not equal to the net total, as it does not consider VAT.
   */
  public get totalAmountBeforeDiscounts(): number {
    return this.customerInvoice.positions.reduce((acc, position) => {
      if (position.type !== 'custom-product') {
        return acc
      } else {
        return acc + CustomerInvoicePositions.enteredAmountForPosition(position)
      }
    }, 0)
  }

  /**
   * Returns the total discount of the invoice.
   */
  public get totalDiscount(): number {
    const discountPositions = this.customerInvoice.positions.filter(
      (position) => position.type === 'discount'
    )
    const fixedDiscount = discountPositions
      .filter((position) => 'amount' in position)
      .reduce(
        (acc, position) =>
          acc + (position as IFixedAmountDiscountPosition).amount,
        0
      )
    const finalAmount = this.totalAmountBeforeDiscounts - fixedDiscount
    const percentageDiscount = discountPositions
      .filter((position) => 'percentage' in position)
      .reduce(
        (acc, position) =>
          acc + (position as IPercentageDiscountPosition).percentage,
        0
      )

    return Math.round(
      Math.min(
        finalAmount * percentageDiscount * 0.01 + fixedDiscount,
        this.totalAmountBeforeDiscounts
      )
    )
  }

  private netAmountForPosition(position: INewCustomProductPosition) {
    const enteredAmount =
      CustomerInvoicePositions.enteredAmountForPosition(position)
    if (this.customerInvoice.amountMode === AmountMode.Net) {
      return enteredAmount
    } else {
      const taxRate =
        0.01 * (taxRateForTaxCode((position.taxCode as TaxCode) ?? '') ?? 0)
      return enteredAmount / (1 + taxRate)
    }
  }

  public vatDiscountAdjustmentForPosition(position: INewCustomProductPosition) {
    const positionTotalAmount =
      CustomerInvoicePositions.enteredAmountForPosition(position)
    const positionVATRate =
      taxRateForTaxCode((position.taxCode ?? '') as TaxCode) ?? 0

    if (!this.totalAmountBeforeDiscounts) return 0

    const shareOfInvoiceTotal =
      positionTotalAmount / this.totalAmountBeforeDiscounts
    const totalInvoiceDiscount = this.totalDiscount

    let reduction = 0

    if (this.customerInvoice.amountMode === AmountMode.Net) {
      reduction =
        (positionVATRate / 100) * totalInvoiceDiscount * shareOfInvoiceTotal
    } else if (positionVATRate > 0) {
      const notReducedVat =
        positionTotalAmount - positionTotalAmount / (1 + positionVATRate / 100)

      const reducedTotalAmount =
        positionTotalAmount - totalInvoiceDiscount * shareOfInvoiceTotal

      const reducedVat =
        reducedTotalAmount -
        (positionTotalAmount - totalInvoiceDiscount * shareOfInvoiceTotal) /
          (1 + positionVATRate / 100)

      reduction = notReducedVat - reducedVat
    }

    return reduction
  }

  /**
   * The entered amount for a position, which may either be net or gross, depending
   * on the amount mode of the invoice.
   */
  public static enteredAmountForPosition(position: INewCustomProductPosition) {
    let quantity = position.quantity ?? 1

    // Do not remove this check, otherwise there will be an infinite loop
    // in DocumentElementPositions if the field is made empty.
    if (isNaN(quantity)) quantity = 1

    return quantity * (position.singleAmount ?? 0)
  }

  /**
   * The VAT debt for a position, which considers the amount mode of the invoice.
   */
  public static vatDebtForPosition(
    position: INewCustomProductPosition,
    amountMode = AmountMode.Gross
  ) {
    const totalAmount =
      CustomerInvoicePositions.enteredAmountForPosition(position)

    let grossAmount: number
    let netAmount: number

    const taxRate = taxRateForTaxCode((position.taxCode ?? '') as TaxCode) ?? 0

    if (amountMode === AmountMode.Gross) {
      grossAmount = totalAmount
      netAmount = Math.round(grossAmount / (1 + taxRate / 100))
    } else {
      grossAmount = Math.round(totalAmount * (1 + taxRate / 100))
      netAmount = totalAmount
    }

    return grossAmount - netAmount
  }
}

export default CustomerInvoicePositions
