import classNames from 'classnames'
import { useLedgerContext } from 'ledger/LedgerContext'
import AccountSelect from 'ledger/accounts/account-select/AccountSelect'
import { useTransactionEditorContext } from '../TransactionEditorContext'

const TransactionEditorContraAccount = () => {
  const ledgerContext = useLedgerContext()
  const {
    draftTransaction,
    updateDraftTransaction,
    contraAccountSide,
    canMakeChanges,
  } = useTransactionEditorContext()

  const hasForeignCurrency = draftTransaction.originalCurrency !== undefined
  return (
    <AccountSelect
      className={classNames('account', {
        narrow: hasForeignCurrency,
      })}
      onChange={(accountNumber: number | undefined) =>
        updateDraftTransaction({ [contraAccountSide]: accountNumber })
      }
      suggestions={[]}
      initialAccountNumber={draftTransaction[contraAccountSide]}
      currentAccountNumber={draftTransaction[contraAccountSide]}
      inputFieldProps={{ disabled: !canMakeChanges }}
      openAccountPageModally={ledgerContext.openAccountPageModally}
      allowAccountCreation
    />
  )
}

export default TransactionEditorContraAccount
