import {
  AmountMode,
  VATPresentationOptions,
} from '@nextbusiness/infinity-finance-api'
import { INewTemplate } from '@nextbusiness/infinity-finance-api/dist/models/ITemplate'
import { useNotificationCenter } from '@nextbusiness/infinity-ui'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useRootStore } from 'stores/RootStoreContext'
import SetupModalAddress from './SetupModalAddress'
import SetupModalDesign from './SetupModalDesign'
import SetupModalVATPresentation from './SetupModalVATPresentation'
import { useCustomerInvoiceSetup } from './useCustomerInvoiceSetup'

interface CustomerInvoiceEditorSetupModalProps {
  onSetupComplete: (amountMode?: AmountMode) => void
}

enum SetupStep {
  CompanyAddress,
  VATPresentation,
  Design,
}

const CustomerInvoiceEditorSetupModal = (
  props: CustomerInvoiceEditorSetupModalProps
) => {
  const history = useHistory()
  const { preferencesStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [step, setStep] = useState<SetupStep | null>(SetupStep.CompanyAddress)
  const [amountMode, setAmountMode] = useState<AmountMode>()
  const [template, setTemplate] = useState<INewTemplate>()
  const [vatPresentation, setVatPresentation] = useState<
    VATPresentationOptions | undefined
  >(preferencesStore.organisationPreferences?.vatPresentationOptions)

  const { performSetup, isLoading } = useCustomerInvoiceSetup()
  const onAbort = () => {
    setStep(null)
    history.push('/forderungen')
    MixpanelAnalytics.event('Customer invoice setup aborted')
  }
  const onComplete = async (finalTemplate: INewTemplate, logo?: File) => {
    if (!finalTemplate) return
    try {
      await performSetup(finalTemplate, amountMode, vatPresentation, logo)
      props.onSetupComplete(amountMode)
      MixpanelAnalytics.event('Customer invoice setup done')
    } catch (error: any) {
      notificationCenter.addNotification({
        title:
          'Forderungen konnten nicht eingerichtet werden. Prüfe deine Angaben und versuche es erneut.',
        children: error.message,
        variant: 'error',
      })
      MixpanelAnalytics.event('Customer invoice setup failed', {
        errorMessage: error.message,
      })
    }
  }

  return (
    <>
      <SetupModalAddress
        isOpen={step === SetupStep.CompanyAddress}
        onAbort={onAbort}
        onContinue={(newTemplate) => {
          setTemplate(newTemplate)
          setStep(
            preferencesStore.organisationPreferences?.VAT
              ? SetupStep.VATPresentation
              : SetupStep.Design
          )
        }}
      />
      <SetupModalVATPresentation
        isOpen={step === SetupStep.VATPresentation}
        onPreviousStep={() => setStep(SetupStep.CompanyAddress)}
        onContinue={(amountMode, vatPresentation) => {
          setAmountMode(amountMode)
          setVatPresentation(vatPresentation)
          setStep(SetupStep.Design)
        }}
      />
      <SetupModalDesign
        template={template}
        isOpen={step === SetupStep.Design}
        onPreviousStep={() => setStep(SetupStep.VATPresentation)}
        onFinish={(updatedTemplate, logo) => {
          onComplete(updatedTemplate, logo)
        }}
        isFinishing={isLoading}
      />
    </>
  )
}

export default observer(CustomerInvoiceEditorSetupModal)
