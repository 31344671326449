import './Account.scss'

interface VirtualAccountProps {
  name: string
  colour?: string
  isBeingEdited?: boolean
}

const VirtualAccount = (props: VirtualAccountProps) => {
  const name = props.name

  return (
    <div className='ui-view account virtual'>
      <div
        className='colour-swatch'
        style={{
          backgroundColor: props.colour || 'grey',
        }}
      />
      <span
        className={'name' + (props.isBeingEdited ? ' is-being-edited' : '')}
      >
        {name}
      </span>
    </div>
  )
}

export default VirtualAccount
