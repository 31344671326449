import { NonIdealState } from '@nextbusiness/infinity-ui'
import { IconSize } from '@nextbusiness/infinity-ui-icons'

interface ProductsListEmptyProps {
  isSearching: boolean
}

const ProductsListEmpty = (props: ProductsListEmptyProps) => (
  <NonIdealState icon='package-delivery-logistics' iconSize={IconSize.Small}>
    {props.isSearching ? 'Keine Produkte gefunden' : 'Noch keine Produkte'}
  </NonIdealState>
)

export default ProductsListEmpty
