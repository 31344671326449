import { Flex, Heading } from '@nextbusiness/infinity-ui'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react-lite'
import { IPresetListItem } from 'model/Preset'
import { useRootStore } from 'stores/RootStoreContext'
import PresetCard from './PresetCard'

interface PresetModalContentSectionProps {
  sectionTitle: string
  presets: IPresetListItem[]
  isNewDocumentQuote: boolean
  showBlankPreset: boolean
  openDocumentEditor: () => void
  emptyState?: React.ReactNode
  displayEmptyState?: boolean
}

const PresetModalContentSection = (props: PresetModalContentSectionProps) => {
  const { presetStore } = useRootStore()
  const blankPreset: IPresetListItem = {
    id: 'blank',
    type: 'blank',
    title: props.isNewDocumentQuote ? 'Neue Offerte' : 'Neue Forderung',
    description: '',
    invoice: {
      title: '',
    },
  }

  if (
    props.presets.length === 0 &&
    !props.showBlankPreset &&
    !props.displayEmptyState
  )
    return null

  return (
    <div
      className={
        'content-section' + (props.displayEmptyState ? ' empty-state' : '')
      }
    >
      <Heading className='section-title' type='h4' bareTop>
        {props.sectionTitle}
      </Heading>
      <Flex className='section-body' gap={1.4} wrap>
        {props.displayEmptyState ? (
          props.emptyState
        ) : (
          <>
            {props.showBlankPreset && (
              <PresetCard
                preset={blankPreset}
                onClick={() => {
                  MixpanelAnalytics.event('New customer invoice - from blank')
                  presetStore.setSelectedPreset(null)
                  props.openDocumentEditor()
                }}
              />
            )}
            {props.presets.map((preset) => (
              <PresetCard
                key={preset.id}
                preset={preset}
                onClick={() => {
                  MixpanelAnalytics.event('New customer invoice - from preset')
                  presetStore.setSelectedPreset(preset)
                  props.openDocumentEditor()
                }}
              />
            ))}
          </>
        )}
      </Flex>
    </div>
  )
}

export default observer(PresetModalContentSection)
