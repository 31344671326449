import FormField from 'base-components/layout/FormField'
import AccountSelect from 'ledger/accounts/account-select/AccountSelect'
import SettingsGroup from 'settings/general/SettingsGroup'
import { ProductEditFieldsProps } from '../ProductEditFields'

const ACCOUNTS_RECEIVABLE_ACCOUNT_NUMBER = 1100

const ProductFieldsAccounting = (props: ProductEditFieldsProps) => (
  <SettingsGroup title='Buchhaltung'>
    <FormField label='Ertragskonto'>
      <AccountSelect
        // Force re-render due to issue with AccountSelect component
        // where it does not properly reset once initialAccountNumber is
        // set to undefined (which is the case before the product state
        // is populated with the initial values)
        key={props.product.incomeAccount}
        currentAccountNumber={ACCOUNTS_RECEIVABLE_ACCOUNT_NUMBER}
        initialAccountNumber={props.product.incomeAccount}
        onChange={(contraAccount) => {
          props.editProduct({ incomeAccount: contraAccount })
        }}
        suggestions={[{ title: 'Erträge', accounts: ['income'] }]}
        onlyAllowSuggestedAccounts
        placeholderText='Ertragskonto'
        allowAccountCreation
      />
    </FormField>
  </SettingsGroup>
)

export default ProductFieldsAccounting
