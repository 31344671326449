import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import './OnboardingContentView.scss'

interface OnboardingContentViewProps {
  header?: React.ReactNode
  children?: React.ReactNode
}

const OnboardingContentView = (props: OnboardingContentViewProps) => {
  const contentViewRef = useRef<HTMLDivElement>(null)
  const [hasScrolled, setHasScrolled] = useState<boolean>(false)

  const handleScroll = () => {
    if (contentViewRef.current) {
      setHasScrolled(contentViewRef.current.scrollTop > 0)
    }
  }
  useEffect(() => {
    contentViewRef.current?.addEventListener('scroll', handleScroll)
    return () =>
      contentViewRef.current?.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div
      className={classNames('onboarding-setup', hasScrolled && 'has-scrolled')}
    >
      <div className='onboarding-setup-header'>{props.header}</div>
      <div className='onboarding-content-view' ref={contentViewRef}>
        {props.children}
      </div>
    </div>
  )
}

export default OnboardingContentView
