import { Button, ThemeProvider } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import Lockup from 'components/text/Lockup'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import './DocumentLiveCaptureSetupBanner.scss'
import LiveCaptureSetupDialog from './LiveCaptureSetupDialog'

interface DocumentLiveCaptureSetupBannerProps {
  compact?: boolean
}

const DocumentLiveCaptureSetupBanner = (
  props: DocumentLiveCaptureSetupBannerProps
) => {
  const { preferencesStore } = useRootStore()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  if (
    preferencesStore.organisationPreferences?.isLiveCaptureEnabled ||
    preferencesStore.hasSeenCoachmark('live-capture-setup-banner')
  )
    return null

  return (
    <>
      <ThemeProvider primaryColor='lavender'>
        <div
          className={classNames('live-capture-setup-banner', {
            compact: props.compact,
          })}
        >
          <Lockup icon='ocr' className='label' gap='small'>
            Aktiviere Live Capture, um hochgeladene Belege mit AI ohne Abtippen
            automatisch auszufüllen.
          </Lockup>
          <Button variant='primary' onClick={() => setIsModalOpen(true)}>
            Live Capture aktivieren
          </Button>
          <Button
            variant='quaternary'
            iconOnly='close'
            onClick={() =>
              preferencesStore.markCoachmarkAsSeen('live-capture-setup-banner')
            }
            className='close-button'
            tooltip='Nicht mehr anzeigen'
          />
        </div>
      </ThemeProvider>
      <LiveCaptureSetupDialog
        isOpen={isModalOpen}
        onDismiss={() => setIsModalOpen(false)}
      />
    </>
  )
}

export default observer(DocumentLiveCaptureSetupBanner)
