import { Finance, ICurrency } from '@nextbusiness/infinity-finance-api'
import { makeObservable, observable, runInAction } from 'mobx'
import RootStore from './RootStore'
import Store from './Store'

class CurrencyStore extends Store {
  @observable public currencies: ICurrency[] | null = null
  @observable public currenciesLoadingError: Error | null = null

  public baseCurrency: ICurrency = {
    id: 'base',
    currencyCode: 'CHF',
    currencyName: 'Schweizer Franken',
    basisUnit: 1,
    exchangeRate: 1,
    effectiveExchangeRate: 1,
    syncAutomatically: false,
  }

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  public onHydrate() {
    this.loadCurrencies()
  }

  async loadCurrencies() {
    try {
      const currencies = await Finance.Currencies.currencies()
      runInAction(() => {
        this.currencies = currencies
      })
    } catch (error) {
      this.currenciesLoadingError = error as Error
    }
  }
}

export default CurrencyStore
