import { IOrganisation } from '@nextbusiness/infinity-finance-api'
import { Current } from './Authentication'
import NetworkingConstants from './NetworkingConstants'
import NetworkingErrorHandler from './NetworkingErrorHandler'

const getOrganisationPreferencesByFabricId = async (
  fabricId: string
): Promise<IOrganisation> => {
  const response = await fetch(
    NetworkingConstants.HOST + `/fabric-organisation/${fabricId}`,
    { method: 'GET', headers: Current.defaultHeaders }
  )
  const body = await response.json()

  if (!response.ok || !body.organisation) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.organisation as IOrganisation
}

const Preferences = {
  getOrganisationPreferencesByFabricId,
}

export default Preferences
