import Subscription from 'libs/subscription/Subscription'
import { useContext } from 'react'
import { SubscriptionContext } from './SubscriptionContextProvider'

const useSubscription = (): Subscription | null => {
  const context = useContext(SubscriptionContext)
  if (!context)
    throw new Error(
      'useSubscription must be used within a SubscriptionContextProvider'
    )

  return context.subscription ?? null
}

export default useSubscription
