import {
  Button,
  Flex,
  Heading,
  InputField,
  NonIdealState,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { Contact } from 'model/Contact'
import { useEffect, useState } from 'react'
import contactDialogStore from 'stores/ContactDialogStore'
import { useRootStore } from 'stores/RootStoreContext'
import './Contacts.scss'
import { ContactsDialogMode } from './ContactsDialog'
import ContactsListSegment from './list-tab/ContactsListSegment'
import ContactsCategories from './sidebar/ContactsCategories'
import SingleContactTab from './single-contact-tab/SingleContactTab'

interface ContactsProps {
  dismissable?: boolean
  mode?: ContactsDialogMode
  onContactPicked?: (contact: Contact) => void
  onContactCreated?: (contact: Contact) => void
  retainState?: boolean
}

const Contacts = (props: ContactsProps) => {
  const { contactStore } = useRootStore()
  const {
    isContactsOpen,
    mode,
    setMode,
    setActiveContact,
    setDraftContact,
    blankContact,
    setIsPickerMode,
  } = contactDialogStore

  const [wasContactFound, setWasContactFound] = useState<boolean>(true)
  const [searchValue, setSearchValue] = useState<string>('')
  const [activeCategory, setActiveCategory] = useState<string>('all')

  useEffect(() => {
    if (isContactsOpen && !props.retainState) {
      setActiveContact(undefined)
      setMode('viewing')
      setIsPickerMode(props.mode === 'picker')
    }
  }, [isContactsOpen, props.mode, props.retainState])

  return (
    <Flex className='contacts-view'>
      <div className='contacts-sidebar'>
        <Heading type='h2' divider bareTop>
          Kontakte
        </Heading>
        <ContactsCategories
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
        />
      </div>
      <div className='contacts-list'>
        <Flex
          direction='vertical'
          className='list-content'
          verticalAlignment='flex-start'
        >
          <InputField
            value={searchValue}
            onChange={setSearchValue}
            className='contacts-search'
            icon='search'
            placeholder='Suche'
            fullWidth
          />
          {contactStore.allContacts.length > 0 ? (
            <ContactsListSegment
              setWasContactFound={setWasContactFound}
              searchQuery={searchValue}
              activeCategory={activeCategory}
            />
          ) : (
            <NonIdealState title='Noch keine Kontakte'>
              Erstelle zuerst einen Kontakt
            </NonIdealState>
          )}
          <Button
            iconLeft='add'
            variant='primary'
            className='new-contact-button'
            onClick={() => {
              setDraftContact(blankContact)
              setMode('creating')
              setActiveContact(undefined)
            }}
            disabled={mode !== 'viewing'}
          >
            Neuer Kontakt
          </Button>
        </Flex>
      </div>
      <SingleContactTab
        dismissable={props.dismissable}
        wasContactFound={wasContactFound}
        onContactPicked={props.onContactPicked}
        onContactCreated={props.onContactCreated}
      />
    </Flex>
  )
}

export default observer(Contacts)
