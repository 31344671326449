import { Work } from '@nextbusiness/infinity-finance-api'
import { Button, Flex, NonIdealState } from '@nextbusiness/infinity-ui'
import { IconSize } from '@nextbusiness/infinity-ui-icons'
import { useState } from 'react'
import ProjectViewContentBox from './ProjectViewContentBox'
import UnbilledWorkItem from './UnbilledWorkItem'
import AddUnbilledWorkModal from './add-unbilled-work/AddUnbilledWorkModal'
import ProjectInvoicingAssistant from './invoicing-assistant/ProjectInvoicingAssistant'

interface UnbilledWorkProps {
  projectId: string
  unbilledWork: Work[]
}

const UnbilledWork = (props: UnbilledWorkProps) => {
  const [isInvoicingModalOpen, setIsInvoicingModalOpen] =
    useState<boolean>(false)
  const openInvoicingModal = () => setIsInvoicingModalOpen(true)
  const [isAddUnbilledWorkModalOpen, setIsAddUnbilledWorkModalOpen] =
    useState<boolean>(false)
  const openAddUnbilledWorkModal = () => setIsAddUnbilledWorkModalOpen(true)
  const canBillSomething =
    props.unbilledWork.length > 0 &&
    props.unbilledWork.some(
      (workItem) => 'price' in workItem || 'rate' in workItem
    )
  const byDate = (a: Work, b: Work) => b.date - a.date
  const unbilledWorkSorted = [...props.unbilledWork].sort(byDate)
  const unbilledWorkItems = () =>
    unbilledWorkSorted.map((workItem) => (
      <UnbilledWorkItem
        key={workItem.id}
        workItem={workItem}
        projectId={props.projectId}
      />
    ))

  return (
    <>
      <ProjectViewContentBox isEmpty={props.unbilledWork.length === 0}>
        {props.unbilledWork.length > 0 ? (
          unbilledWorkItems()
        ) : (
          <NonIdealState icon='paid-bill' iconSize={IconSize.Medium}>
            Keine erledigten Arbeiten zu verrechnen
          </NonIdealState>
        )}
      </ProjectViewContentBox>
      <Flex
        className='project-content-actions'
        gap={1.2}
        direction='horizontal'
      >
        <Button
          variant='primary'
          iconLeft='plus'
          onClick={openAddUnbilledWorkModal}
        >
          Neuer Eintrag
        </Button>
        {canBillSomething && (
          <Button iconLeft='check' onClick={openInvoicingModal}>
            In Rechnung stellen
          </Button>
        )}
      </Flex>
      <AddUnbilledWorkModal
        projectId={props.projectId}
        isOpen={isAddUnbilledWorkModalOpen}
        setIsOpen={setIsAddUnbilledWorkModalOpen}
      />
      <ProjectInvoicingAssistant
        projectId={props.projectId}
        isOpen={isInvoicingModalOpen}
        setIsOpen={setIsInvoicingModalOpen}
        unbilledWork={unbilledWorkSorted}
      />
    </>
  )
}

export default UnbilledWork
