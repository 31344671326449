import classNames from 'classnames'
import { motion } from 'framer-motion'
import React from 'react'
import './LiveCaptureInvoiceBanner.scss'

interface LiveCaptureInvoiceBannerProps {
  children?: React.ReactNode
  pending?: boolean
  applied?: boolean
  className?: string
}

const LiveCaptureInvoiceBanner = (props: LiveCaptureInvoiceBannerProps) => (
  <motion.div
    className={classNames('live-capture-banner', props.className, {
      pending: props.pending,
      applied: props.applied,
    })}
    layout
    initial={{ opacity: 0, height: 0, marginTop: 0 }}
    animate={{ opacity: 1, height: 'auto', marginTop: 20 }}
    exit={{ opacity: 0, height: 0, marginTop: 0 }}
    transition={{ velocity: 10, stiffness: 100, mass: 0.2, type: 'spring' }}
  >
    {props.children}
  </motion.div>
)

export default LiveCaptureInvoiceBanner
