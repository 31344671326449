import {
  APIError,
  Finance,
  ITransaction,
} from '@nextbusiness/infinity-finance-api'
import { useNotificationCenter } from '@nextbusiness/infinity-ui'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { TransactionFilterContext } from 'ledger/transactions/filter/TransactionFilterContext'
import { runInAction } from 'mobx'
import { useContext, useState } from 'react'
import { store } from 'stores/RootStoreContext'
import TransactionEditorUtilities from '../TransactionEditorUtilities'

const useSaveTransactionChanges = (
  transaction: ITransaction,
  editorUtilities: TransactionEditorUtilities,
  closeEditor: () => void
) => {
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const filterContext = useContext(TransactionFilterContext)
  const notificationCenter = useNotificationCenter()

  const saveChanges = async () => {
    if (!editorUtilities.hasEditedTransaction) return closeEditor()
    try {
      setIsSaving(true)
      const modifiedTransactions = await Finance.Ledger.modifyTransaction(
        transaction.id,
        editorUtilities.changesToSave
      )
      runInAction(() => {
        const linkedTransactions = transaction.linkedTransactions ?? []
        const previousTransactions = [transaction.id, ...linkedTransactions]

        store.transactionStore.replaceTransactionsInStore(
          previousTransactions,
          modifiedTransactions,
          filterContext?.isFiltering
        )
      })
      store.accountStore.loadAccounts()
      MixpanelAnalytics.event('Transaction edited', {
        origin: transaction.origin,
      })
      closeEditor()
    } catch (error) {
      const apiError = error as APIError
      notificationCenter.addNotification({
        title: 'Transaktion konnte nicht bearbeitet werden.',
        children: apiError.humanText('de'),
        variant: 'error',
      })
      MixpanelAnalytics.event('Transaction editing failed', {
        code: apiError.code,
      })
    } finally {
      setIsSaving(false)
    }
  }
  return { isSaving, saveChanges }
}

export default useSaveTransactionChanges
