import { MenuItemGroup } from '@nextbusiness/infinity-ui'
import MoreMenu from 'components/more-menu/MoreMenu'
import DeleteDocumentModal from 'documents/document-card/DeleteDocumentModal'
import { observer } from 'mobx-react'
import { CurrentVendorInvoiceNavigationStackRef } from 'pages/VendorInvoicePage'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import DeleteVendorInvoiceModal from './DeleteVendorInvoiceModal'
import EditVendorInvoiceFieldsModal from './EditVendorInvoiceFieldsModal'
import { useVendorInvoiceContext } from './VendorInvoiceContext'

const VendorInvoiceMoreMenu = () => {
  const { vendorInvoiceStore } = useRootStore()
  const { invoice, reloadInvoice } = useVendorInvoiceContext()

  const [isEditDetailsModalOpen, setIsEditDetailsModalOpen] =
    useState<boolean>(false)
  const [isDeleteInvoiceModalOpen, setIsDeleteInvoiceModalOpen] =
    useState<boolean>(false)
  const [isDeleteDocumentModalOpen, setIsDeleteDocumentModalOpen] =
    useState<boolean>(false)

  const hasDocument = !!invoice.attachments?.length

  const invoiceActions: MenuItemGroup = {
    title: hasDocument ? 'Rechnung' : undefined,
    buttons: [
      {
        text: 'Angaben ändern',
        icon: 'edit',
        onClick: () => setIsEditDetailsModalOpen(true),
      },
      {
        text: 'Rechnung löschen',
        icon: 'delete',
        onClick: () => setIsDeleteInvoiceModalOpen(true),
      },
    ],
  }
  const documentActions: MenuItemGroup = {
    title: 'Beleg',
    buttons: [
      {
        text: 'Beleg löschen',
        icon: 'delete',
        onClick: () => setIsDeleteDocumentModalOpen(true),
      },
    ],
  }

  const actions = hasDocument
    ? [invoiceActions, documentActions]
    : [invoiceActions]

  return (
    <div className='actions'>
      <MoreMenu actions={actions} />
      <EditVendorInvoiceFieldsModal
        invoice={invoice}
        isOpen={isEditDetailsModalOpen}
        onDismiss={() => setIsEditDetailsModalOpen(false)}
        onSaved={(updatedInvoice) => {
          reloadInvoice(updatedInvoice)
          vendorInvoiceStore.onInvoicesChanged()
        }}
      />
      <DeleteVendorInvoiceModal
        invoice={invoice}
        isOpen={isDeleteInvoiceModalOpen}
        onDismiss={() => setIsDeleteInvoiceModalOpen(false)}
        onDeleteFinished={() => {
          setIsDeleteInvoiceModalOpen(false)
          CurrentVendorInvoiceNavigationStackRef?.popCurrentView()
        }}
      />
      {invoice.attachments?.[0] && (
        <DeleteDocumentModal
          documentId={invoice.attachments[0]}
          isOpen={isDeleteDocumentModalOpen}
          closeModal={() => setIsDeleteDocumentModalOpen(false)}
          onDeletionSuccess={reloadInvoice}
        />
      )}
    </div>
  )
}

export default observer(VendorInvoiceMoreMenu)
