import {
  Button,
  Card,
  Flex,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import Account from 'components/transactions/Account'
import TransactionAmount from 'components/transactions/TransactionAmount'
import { observer } from 'mobx-react-lite'
import Depreciation, {
  DegressiveDepreciation,
  LinearDepreciation,
} from 'model/financial-close/Depreciation'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import './ClosingDepreciationCard.scss'

interface ClosingDepreciationCardProps {
  depreciation: Depreciation
}

const ClosingDepreciationCard = (props: ClosingDepreciationCardProps) => {
  const { financialCloseStore } = useRootStore()
  const notificationCenter = useNotificationCenter()
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const deleteDepreciation = async () => {
    if (!props.depreciation._id || !financialCloseStore.financialCloseSettings)
      return
    setIsDeleting(true)
    try {
      const depreciations =
        financialCloseStore.financialCloseSettings.depreciations.filter(
          (depreciation) => depreciation._id !== props.depreciation._id
        )
      await financialCloseStore.update({ depreciations })
      notificationCenter.addNotification({
        children: 'Abschreibung erfolgreich gelöscht',
        variant: 'success',
      })
    } catch (error) {
      notificationCenter.addNotification({
        title: 'Abschreibung konnte nicht gelöscht werden',
        children: (error as Error).message ?? 'Unbekannter Fehler',
        variant: 'error',
      })
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <Card className='closing-depreciation-card' bare>
      <Flex verticalAlignment='center' className='detail'>
        <Text type='inline' className='description'>
          {props.depreciation.description ||
            (props.depreciation.type === 'linear'
              ? 'Lineare Abschreibung'
              : 'Degressive Abschreibung')}
        </Text>
        <Account accountNumber={props.depreciation.affectedAccount} />
        {props.depreciation.type === 'linear' ? (
          <TransactionAmount
            amount={(props.depreciation as LinearDepreciation).absoluteAmount}
          />
        ) : (
          <Text type='inline' className='percentage'>
            {(
              (props.depreciation as DegressiveDepreciation).percentageAmount *
              100
            ).toFixed(2)}
            %
          </Text>
        )}
        <Button
          iconOnly='delete'
          aria-label='Löschen'
          variant='quaternary'
          isLoading={isDeleting}
          onClick={() => deleteDepreciation()}
          tooltip='Abschreibung löschen'
        />
      </Flex>
    </Card>
  )
}

export default observer(ClosingDepreciationCard)
