import { TemplateInspectorProps } from '../TemplateInspector'
import TemplateInspectorNumbering from './TemplateInspectorNumbering'
import TemplateInspectorVisibility from './TemplateInspectorVisibility'

const TemplateInspectorElementsTab = (props: TemplateInspectorProps) => (
  <div className='template-inspector-tab tab-elements'>
    <TemplateInspectorVisibility {...props} />
    <TemplateInspectorNumbering {...props} />
  </div>
)

export default TemplateInspectorElementsTab
