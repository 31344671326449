import { DocumentAnalysisStatus } from '@nextbusiness/infinity-finance-api'
import { Avatar, Flex, Text, Tooltip } from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'
import { observer } from 'mobx-react'
import IDocument from 'model/Document'
import { useRootStore } from 'stores/RootStoreContext'
import DocumentCardPendingAnalysisInfo from './DocumentCardPendingAnalysisInfo'

interface DocumentCardDepictionProps {
  document: IDocument
  actions?: React.ReactNode
}

const DocumentCardDepiction = (props: DocumentCardDepictionProps) => {
  const { organisationStore, contactStore } = useRootStore()

  const uploaderName = props.document.uploadedBy
    ? organisationStore.fullNameOfMember(props.document.uploadedBy)
    : null

  const noteText = props.document?.note
    ? '"' + props.document.note + '"'
    : 'Ohne Kommentar hochgeladen'

  const isAnalysing =
    props.document.analysisStatus === DocumentAnalysisStatus.InProgress
  const hasAnalysis =
    props.document.analysisStatus === DocumentAnalysisStatus.Success

  return (
    <Flex className='document-information-area' direction='vertical' gap='tiny'>
      <Flex
        className='primary-area'
        horizontalAlignment='space-between'
        gap='tiny'
      >
        <Flex className='depiction' gap='tiny' verticalAlignment='center'>
          <Flex direction='vertical'>
            <Text className='document-contact-label' type='inline'>
              {contactStore.displayNameForContactWithId(
                props.document.contactId
              )}
            </Text>
            <div className='document-title'>
              <span>{props.document.title}</span>
              {hasAnalysis && (
                <Tooltip content='Beleg analysiert'>
                  <Icon icon='ocr' size={20} tint='lavender' />
                </Tooltip>
              )}
            </div>
            <DocumentCardPendingAnalysisInfo isAnalysing={isAnalysing} />
          </Flex>
        </Flex>
        {props.actions}
      </Flex>
      <Flex gap='tiny' verticalAlignment='center'>
        <Avatar name={uploaderName!} />
        <Text
          type='inline'
          className={`note ${!props.document.note ? 'without' : ''}`}
        >
          {noteText}
        </Text>
      </Flex>
    </Flex>
  )
}

export default observer(DocumentCardDepiction)
