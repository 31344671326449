import { Button, Flex } from '@nextbusiness/infinity-ui'
import { ReactComponent as ProcessedDocumentsStackIcon } from 'assets/documents/processed-documents-stack.svg'

interface ProcessedDocumentsStackProps {
  onClick?: () => void
}

const ProcessedDocumentsStack = (props: ProcessedDocumentsStackProps) => (
  <Flex className='stacks' verticalAlignment='flex-start'>
    <div className='seperator' />
    <Button className='stack-item' variant='shell' onClick={props.onClick}>
      <ProcessedDocumentsStackIcon title='' />
      <div className='ui-text title'>Alle erledigten Belege</div>
    </Button>
  </Flex>
)

export default ProcessedDocumentsStack
