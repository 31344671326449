import React from 'react'
import './RadialGauge.scss'

export type RadialGaugeFillLevel = '0%' | '25%' | '50%' | '75%' | '100%'

interface RadialGaugeProps {
  level: RadialGaugeFillLevel
  coloured?: boolean
  label?: string
  size?: 'default' | 'large'
}

const graphicForFillLevel = (
  level: RadialGaugeFillLevel,
  coloured?: boolean
) => {
  const assetsBase = '/assets/gauge/'
  const variant = coloured ? '-coloured' : ''
  switch (level) {
    case '0%':
      return `${assetsBase}gauge-0${variant}.png`
    case '25%':
      return `${assetsBase}gauge-25${variant}.png`
    case '50%':
      return `${assetsBase}gauge-50${variant}.png`
    case '75%':
      return `${assetsBase}gauge-75${variant}.png`
    case '100%':
      return `${assetsBase}gauge-100${variant}.png`
  }
}

const RadialGauge = (props: RadialGaugeProps) => (
  <div className={`radial-gauge size-${props.size ?? 'default'}`}>
    <img
      src={graphicForFillLevel(props.level, props.coloured)}
      alt={props.level}
      className='gauge-graphic'
    />
    {props.level && <div className='gauge-label'>{props.label}</div>}
  </div>
)

export default RadialGauge
