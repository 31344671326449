import ResourceOption, {
  ResourceOptionProps,
} from 'components/resource-select/ResourceOption'
import { Contact } from 'model/Contact'
import ContactSelectOptionAvatar from './ContactSelectOptionAvatar'

interface ContactSelectOptionProps {
  contact: Contact
  optionProps: ResourceOptionProps<string, Contact>
}

const ContactSelectOption = (props: ContactSelectOptionProps) => {
  const isPerson = !!props.contact.firstname || !!props.contact.lastname
  return (
    <ResourceOption
      {...props.optionProps}
      leftAccessory={<ContactSelectOptionAvatar contact={props.contact} />}
      description={isPerson && props.contact.companyName}
    />
  )
}

export default ContactSelectOption
