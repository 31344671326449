import { IProjectPreview } from '@nextbusiness/infinity-finance-api'
import ContactListRenderProjects from './ContactListRenderProjects'
import ContactStack from './ContactStack'

interface ContactProjectsListProps {
  data: IProjectPreview[]
  stack: ContactStack
}

const ContactProjectsList = (props: ContactProjectsListProps) => {
  return <ContactListRenderProjects data={props.data} stack={props.stack} />
}
export default ContactProjectsList
