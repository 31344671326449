import { Button } from '@nextbusiness/infinity-ui'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { INewCustomerInvoicePosition } from '../../../../../model/CustomerInvoice'
import CustomerInvoicePositionFactory from '../../CustomerInvoicePositionFactory'
import SidebarGroup from '../SidebarGroup'
import DocumentSidebarPanelProps from './DocumentSidebarPanelProps'

type PositionType = 'custom-product' | 'text-line' | 'discount'

const createPosition = (type: PositionType): INewCustomerInvoicePosition => {
  switch (type) {
    case 'custom-product':
      return CustomerInvoicePositionFactory.newCustomProductPosition()
    case 'text-line':
      return CustomerInvoicePositionFactory.newTextLinePosition()
    case 'discount':
      return CustomerInvoicePositionFactory.newDiscountPosition()
  }
}

const DocumentSidebarAddPositionsPanel = (props: DocumentSidebarPanelProps) => {
  const addPosition = (type: PositionType) => {
    const position = createPosition(type)
    const positions = [...(props.document.positions ?? []), position]

    props.registerEdit({ positions })
    MixpanelAnalytics.event('Added invoice position', { type })
  }
  return (
    <SidebarGroup title='Hinzufügen'>
      <Button
        variant='quaternary'
        iconLeft='plus'
        className='sidebar-action'
        onClick={() => addPosition('custom-product')}
      >
        Neue Position
      </Button>
      <Button
        variant='quaternary'
        iconLeft='plus'
        className='sidebar-action'
        onClick={() => addPosition('discount')}
      >
        Neuer Rabatt
      </Button>
      <Button
        variant='quaternary'
        iconLeft='plus'
        className='sidebar-action'
        onClick={() => addPosition('text-line')}
      >
        Neue Textzeile
      </Button>
    </SidebarGroup>
  )
}

export default DocumentSidebarAddPositionsPanel
