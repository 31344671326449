export enum AccrualType {
  /** When an expense is already paid for, but the actual benefit
   * is only received in the next fiscal year. */
  PrePaidExpense = 'prepaid-expense',

  /** When you have received a benefit, but haven't paid nor even
   * recorded the expense yet (usually because you haven't received
   * the invoice yet). */
  AccruedExpense = 'accrued-expense',

  /** When income is already paid for by your customer, but you
   * haven't actually delivered the benefit yet. */
  PrePaidRevenue = 'prepaid-revenue',

  /** When your customer has already received a benefit, but
   * the income hasn't yet been recorded (usually because you
   * haven't yet sent an invoice). */
  DeferredRevenue = 'deferred-revenue',
}

interface Accrual {
  /** Which type of accrual this is. Affects which
   * transaction needs to be posted. */
  type: AccrualType

  /** A description of what the accrual is for.
   * Will be used for the transaction title. */
  description: string

  /** Accrued amount in whole cents. Will be used for
   * the transaction title. */
  amount: number

  /** Account, which the accrual/deferral will be
   * booked against. */
  contraAccount: number

  _id?: string
}

export default Accrual
