enum TransactionNoEditReason {
  FiscalYearClosed = 'Buchungen aus geschlossenen Geschäftsjahren können nicht bearbeitet werden.',
  VatPeriodSettled = 'Buchungen aus abgerechneten MWST-Perioden können nicht bearbeitet werden.',
  OpeningTransaction = 'Automatische Eröffnungsbuchungen können nicht bearbeitet werden.',
  CustomerInvoicePaymentTransaction = 'Um Zahlungen oder Preisnachlässe zu bearbeiten, wechsle zur zugehörigen Forderung.',
  VendorInvoicePaymentTransaction = 'Um Zahlungen oder Skonti zu bearbeiten, wechsle zur zugehörigen Rechnung.',
  VatSettlementTransaction = 'MWST-Abschlusstransaktionen können nicht bearbeitet werden.',
  VatTransaction = 'Dies ist eine automatische MWST-Buchung.',
  AnnulledTransaction = 'Stornobuchungen können nicht bearbeitet werden.',
}

export default TransactionNoEditReason
