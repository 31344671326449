import { Button, Flex, Text } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import {
  ResolvedAccountAction,
  isDefinedAccount,
} from 'ledger/accounts/data/AccountRules'
import { accountDescriptions } from 'ledger/accounts/data/Descriptions'
import { accountIllustrations } from 'ledger/accounts/data/Illustrations'
import { useState } from 'react'
import AccountMoreMenu from '../AccountMoreMenu'
import { useAccountPageContext } from '../AccountPageContext'
import { useAccountSheetContext } from '../AccountSheetContext'
import './AccountDepiction.scss'
import SpecialAccountActions from './special-account-actions/SpecialAccountActions'

interface AccountDepictionProps {
  setActiveAction: (activeAction: ResolvedAccountAction) => void
}

const AccountDepiction = (props: AccountDepictionProps) => {
  const [hasAcknowledgedDangerousEditing, setHasAcknowledgedDangerousEditing] =
    useState<boolean>(false)

  const { currentAccount, rules } = useAccountPageContext()
  const { setIsTransactionCreatorOpen } = useAccountSheetContext()

  if (!isDefinedAccount(currentAccount)) return null

  const illustration = accountIllustrations[currentAccount.accountNumber]
  const description =
    currentAccount.description ||
    accountDescriptions[currentAccount.accountNumber]

  return (
    <Flex
      className={classNames(
        'account-depiction',
        !illustration && 'no-illustration'
      )}
      gap='regular'
    >
      {illustration && (
        <>
          <div
            className='illustration'
            style={{
              backgroundImage: `url('/assets/account-illustrations/${illustration}')`,
            }}
          />
        </>
      )}
      <Flex
        style={{ flexGrow: 1 }}
        horizontalAlignment='center'
        direction='vertical'
      >
        {description && <Text className='description'>{description}</Text>}
        {rules.discourageEditingWithDisclaimer &&
        !hasAcknowledgedDangerousEditing ? (
          <div className='discourage-editing-disclaimer'>
            <Text variant='subtle' type='paragraph'>
              {rules.discourageEditingWithDisclaimer}
            </Text>
            <Button
              variant='tertiary'
              iconLeft='no-edit'
              onClick={() => setHasAcknowledgedDangerousEditing(true)}
            >
              Trotzdem bearbeiten
            </Button>
          </div>
        ) : null}
        {(!rules.discourageEditingWithDisclaimer ||
          hasAcknowledgedDangerousEditing) && (
          <Flex className='new-transaction-actions' gap='tiny'>
            {rules.actions.map((action) => (
              <Button
                id={`new-transaction-button-${action.displayName}`}
                key={action.displayName}
                variant={action.isTertiaryAction ? 'tertiary' : 'primary'}
                iconLeft='plus'
                onClick={() => {
                  setIsTransactionCreatorOpen(true)
                  props.setActiveAction(action)
                }}
              >
                {action.displayName}
              </Button>
            ))}
            <div style={{ flexGrow: 1 }} />
            <SpecialAccountActions />
            <AccountMoreMenu />
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default AccountDepiction
