import { UniqueIdentifier } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import './Draggable.scss'
import MoveDots from './MoveDots'

/**
 * This hook is used to make components draggable.
 * It only works when used in combination with the `useDragDropContext` hook.
 *
 * Example usage:
 * ```typescript
 * const { renderDraggable, DragHandle, DragHandleWrapper } = useDragDrop(id)
 * return renderDraggable(
 *   <Component>
 *     <DragHandle />
 *     ...
 *   </Component>
 * )
 * ```
 *
 * @param id A unique identifier for the draggable component.
 * @returns An object containing `renderDraggable`, `DragHandle` and `DragHandleWrapper`.
 * The `DragHandle` will render the default drag handle, while the `DragHandleWrapper` can
 * be used to wrap a custom drag handle.
 */

const useDragDrop = (
  id: UniqueIdentifier,
  options?: {
    useDragHandle?: boolean
  }
) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id })

  const style = transform
    ? {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? '9999' : 'auto',
      }
    : undefined

  const renderDraggable = (component: React.ReactNode) => (
    <div
      className={options?.useDragHandle ? '' : 'draggable-wrapper'}
      ref={setNodeRef}
      style={style}
      {...(options?.useDragHandle ? {} : { ...listeners, ...attributes })}
    >
      {component}
    </div>
  )

  const DragHandle = () => (
    <div className='draggable-wrapper' {...attributes} {...listeners}>
      <MoveDots />
    </div>
  )

  const DragHandleWrapper = (props: { children: React.ReactNode }) => {
    return (
      <div className='draggable-wrapper' {...attributes} {...listeners}>
        {props.children}
      </div>
    )
  }

  return {
    renderDraggable,
    DragHandle,
    DragHandleWrapper,
  }
}

export default useDragDrop
