import { Checkbox } from '@nextbusiness/infinity-ui'
import { Icon, IconItem, IconSize } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import './FilterListOption.scss'

interface FilterListOptionProps {
  isSelected?: boolean
  isHighlighted?: boolean
  icon?: IconItem
  children?: React.ReactNode
  onSelected?: () => void
}

const FilterListOption = (props: FilterListOptionProps) => (
  <div
    className={classNames('ui-filter-option', {
      selected: props.isSelected,
      highlighted: props.isHighlighted,
    })}
  >
    <Checkbox
      isChecked={props.isSelected}
      label={
        <>
          {props.icon && (
            <div className='ui-filter-option-icon'>
              <Icon icon={props.icon} size={IconSize.Button} />
            </div>
          )}
          <span className='ui-filter-option-label'>{props.children}</span>
        </>
      }
      onChange={props.onSelected}
    />
  </div>
)

export default FilterListOption
