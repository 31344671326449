import { Card, Flex, MoneyField } from '@nextbusiness/infinity-ui'
import Account from 'components/transactions/Account'
import { IAccount } from 'model/Account'

interface AccountBalanceItemProps {
  account: IAccount
  balance: number | null
  onChange: (newBalance: number | null) => void
}

const AccountBalanceItem = (props: AccountBalanceItemProps) => (
  <Card bare className='account-balance-item'>
    <Flex className='inner' verticalAlignment='center'>
      <Account accountNumber={props.account.accountNumber} disableTrunaction />
      <MoneyField
        value={props.balance}
        onChange={(value) => props.onChange(value)}
      />
    </Flex>
  </Card>
)

export default AccountBalanceItem
