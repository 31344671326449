import IDocument from 'model/Document'
import { ICompleteDocument } from '@nextbusiness/infinity-finance-api'
import { Current } from './Authentication'
import NetworkingConstants from './NetworkingConstants'
import NetworkingErrorHandler from './NetworkingErrorHandler'
import { ITransaction } from '@nextbusiness/infinity-finance-api'

const uploadDocument = async (
  organisationIdentifier: string,
  file: File,
  userId: string,
  title?: string,
  contactId?: string,
  note?: string,
  analyseOnUpload = false
): Promise<IDocument> => {
  const formData = new FormData()

  formData.append('title', title ?? file.name)

  if (contactId) formData.append('contactId', contactId)
  if (note) formData.append('note', note)

  formData.append('file', file)
  formData.append('userId', userId)

  // In the future, this should be a query parameter
  if (analyseOnUpload) formData.append('analyseOnUpload', 'true')

  const response = await fetch(
    `${NetworkingConstants.HOST}/organisation/${organisationIdentifier}/documents`,
    {
      method: 'POST',
      headers: {
        Authorization: Current.authHeader,
      },
      body: formData,
    }
  )
  const body = await response.json()

  if (!response.ok || !body.document || !body.file) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }

  const document = {
    ...body.document,
    file: body.file,
  }
  return document as IDocument
}

const attachDocumentToTransaction = async (
  organisationIdentifier: string,
  accountNumber: number,
  transactionId: string,
  file: File
): Promise<{ document: ICompleteDocument; transaction: ITransaction }> => {
  const formData = new FormData()

  formData.append('title', file.name)
  formData.append('file', file)

  const response = await fetch(
    `${NetworkingConstants.HOST}/organisation/${organisationIdentifier}/account/${accountNumber}/transaction/${transactionId}/attach-document`,
    {
      method: 'POST',
      headers: {
        Authorization: Current.authHeader,
      },
      body: formData,
    }
  )
  const body = await response.json()

  if (!response.ok || !body.document || !body.transaction) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }

  const document = body.document as ICompleteDocument
  const transaction = body.transaction as ITransaction
  return { document, transaction }
}

const deleteDocument = async (
  organisationIdentifier: string,
  documentId: string
) => {
  const response = await fetch(
    `${NetworkingConstants.HOST}/organisation/${organisationIdentifier}/document/${documentId}`,
    {
      method: 'DELETE',
      headers: { Authorization: Current.authHeader },
    }
  )
  const body = await response.json()

  if (!response.ok || !body.document) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
}

const getDocument = async (
  organisationIdentifier: string,
  documentId: string
): Promise<IDocument> => {
  const response = await fetch(
    `${NetworkingConstants.HOST}/organisation/${organisationIdentifier}/document/${documentId}`,
    {
      method: 'GET',
      headers: Current.defaultHeaders,
    }
  )
  const body = await response.json()

  if (!response.ok || !body.document) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }

  const document = {
    ...body.document,
    file: body.file,
  }
  return document as IDocument
}

const attachDocumentToInvoice = async (
  organisationIdentifier: string,
  documentId: string,
  invoiceId: string,
  userId: string
): Promise<IDocument> => {
  const response = await fetch(
    `${NetworkingConstants.HOST}/organisation/${organisationIdentifier}/document/${documentId}/process`,
    {
      method: 'POST',
      headers: Current.defaultHeaders,
      body: JSON.stringify({ invoiceId, userId, destination: 'invoice' }),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.document) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }

  const document = {
    ...body.document,
    file: body.file,
  }
  return document as IDocument
}

const getAllDocumentsIn = async (
  location: 'inbox' | 'invoices' | 'receipts' | 'files' | 'all',
  organisationIdentifier: string
): Promise<IDocument[]> => {
  const response = await fetch(
    `${NetworkingConstants.HOST}/organisation/${organisationIdentifier}/documents/${location}`,
    {
      method: 'GET',
      headers: Current.defaultHeaders,
    }
  )
  const body = await response.json()

  if (!response.ok || !body.documents) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }

  return body.documents as IDocument[]
}

const editDocument = async (
  organisationIdentifier: string,
  documentId: string,
  title: string
): Promise<IDocument> => {
  const response = await fetch(
    `${NetworkingConstants.HOST}/organisation/${organisationIdentifier}/documents/${documentId}`,
    {
      method: 'PATCH',
      headers: Current.defaultHeaders,
      body: JSON.stringify({ title }),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.document) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }

  const document = {
    ...body.document,
    file: body.file,
  }
  return document as IDocument
}

const Documents = {
  uploadDocument,
  deleteDocument,
  getDocument,
  getAllDocumentsIn,
  attachDocumentToInvoice,
  attachDocumentToTransaction,
  editDocument,
}

export default Documents
