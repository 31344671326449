import { Button } from '@nextbusiness/infinity-ui'
import { INewCustomerInvoice } from 'model/CustomerInvoice'
import { useState } from 'react'
import DuplicateCustomerInvoiceModal from './DuplicateCustomerInvoiceModal'

interface CustomerInvoiceDuplicateActionProps {
  document: Partial<INewCustomerInvoice>
  navigateToInvoice: (invoiceId: string) => void
}

const CustomerInvoiceDuplicateAction = (
  props: CustomerInvoiceDuplicateActionProps
) => {
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] =
    useState<boolean>(false)

  return (
    <>
      <Button
        variant='quaternary'
        iconLeft='copy'
        className='sidebar-action'
        onClick={() => setIsDuplicateModalOpen(!isDuplicateModalOpen)}
        tooltip='Als neue Forderung oder Offerte duplizieren'
      >
        Duplizieren als…
      </Button>
      <DuplicateCustomerInvoiceModal
        isOpen={isDuplicateModalOpen}
        closeModal={() => setIsDuplicateModalOpen(false)}
        document={props.document}
        navigateToInvoice={props.navigateToInvoice}
      />
    </>
  )
}

export default CustomerInvoiceDuplicateAction
