import {
  Flex,
  InputField,
  KeyValueStore,
  Text,
} from '@nextbusiness/infinity-ui'
import AccountDescriptionExample from 'assets/account-creation/account-description-example.png'
import ColourSelect from 'components/colour-select/ColourSelect'
import { AccountNameWarning } from 'ledger/accounts/account-editing/EditAccountModal'
import { INewAccount } from 'model/Account'
import './NameAndColorStep.scss'

interface NameAndColorStepProps {
  newAccount: Partial<INewAccount>
  setNewAccount: (newAccount: Partial<INewAccount>) => void
  accountColours: KeyValueStore
}

const NameAndColorStep = (props: NameAndColorStepProps) => {
  return (
    <>
      <Text>Bennene dein Konto und wähle eine Farbe.</Text>
      <Flex className='colour-and-name-wrapper' gap='tiny'>
        <ColourSelect
          selectedColour={props.newAccount.colour || 'grey'}
          setSelectedColour={(colour: string) =>
            props.setNewAccount({ ...props.newAccount, colour })
          }
          selectableColours={props.accountColours}
        />
        <InputField
          className='name'
          placeholder='Name'
          value={props.newAccount.name || ''}
          onChange={(name: string) =>
            props.setNewAccount({ ...props.newAccount, name })
          }
        />
      </Flex>
      <Text>Beschreibe hier, wofür das Konto verwendet wird.</Text>
      <InputField
        className='description'
        placeholder='Beschreibung (optional)'
        value={props.newAccount.description || ''}
        onChange={(description: string) =>
          props.setNewAccount({ ...props.newAccount, description })
        }
      />
      <Flex
        className='name-and-color-example-flex'
        verticalAlignment='center'
        gap='small'
      >
        <img
          src={AccountDescriptionExample}
          alt=''
          className='description-example'
        />
        <Text>
          Eine sinnvolle Beschreibung hilft dir und anderen später beim Buchen
          zu erkennen, welche Arten von Buchungen du für dieses Konto vorgesehen
          hast.
        </Text>
      </Flex>
      <AccountNameWarning name={props.newAccount.name} />
    </>
  )
}

export default NameAndColorStep
