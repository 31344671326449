import DocumentIcon from 'components/document-icon/DocumentIcon'
import ExtendedInvoiceListItemContainer from 'invoices/shared-components/large-invoice-list/ExtendedInvoiceListItemContainer'
import ExtendedInvoiceListItemDepiction from 'invoices/shared-components/large-invoice-list/ExtendedInvoiceListItemDepiction'
import IDocument from 'model/Document'

interface ExtendedDocumentListItemProps {
  document: IDocument
  onClick?: () => void
}

const ExtendedDocumentListItem = (props: ExtendedDocumentListItemProps) => (
  <ExtendedInvoiceListItemContainer onClick={props.onClick}>
    <DocumentIcon thumbnailURL={props.document.thumbnail} />
    <ExtendedInvoiceListItemDepiction title={props.document.title} />
  </ExtendedInvoiceListItemContainer>
)

export default ExtendedDocumentListItem
