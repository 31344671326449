import { ITransaction } from '@nextbusiness/infinity-finance-api'
import stringSimilarity from 'string-similarity'
import { generateRandomId } from 'utility/StringUtilities'
import { TypedKeyValueStore } from 'utility/types'
import { IAccount } from '../../../../model/Account'
import TransactionUtilities from '../../../../utility/TransactionUtilities'
import { longestCommonSubstringInArray } from './LongestCommonSubstring'

export interface ITransactionGroup {
  id: string
  title: string
  transactions: ITransaction[]
  sum: number
}

export const tidyUpKeyNames = (
  data: TypedKeyValueStore<ITransaction[]>,
  currentAccount: IAccount
): ITransactionGroup[] => {
  const existingTitles: string[] = []
  const groups: ITransactionGroup[] = []

  Object.keys(data).forEach((key) => {
    const titles = data[key].map((transaction) =>
      transaction.description.replace('Eröffnung ', '').replaceAll('-', ' ')
    )
    let title = longestCommonSubstringInArray(titles)
    if (!title || existingTitles.includes(title)) {
      title = key
    }

    const group: ITransactionGroup = {
      id: generateRandomId(),
      title: title.trim(),
      transactions: data[key],
      sum: TransactionUtilities.sumBalanceOfTransactions(
        data[key],
        currentAccount
      ),
    }

    if (existingTitles.includes(group.title)) {
      const existingGroup = groups.find((group) => group.title === key)
      if (existingGroup) {
        existingGroup.transactions = [
          ...existingGroup.transactions,
          ...group.transactions,
        ]
        existingGroup.sum += group.sum
        return
      }
    }

    existingTitles.push(group.title)
    groups.push(group)
  })

  return groups.sort((a, b) => b.sum - a.sum)
}

const normalisedTransactionDescription = (description: string) =>
  description
    .replace('Eröffnung ', '')
    .replace('abonnement', '')
    .replace('-', '')

export const compareTransactions = (
  transactions: ITransaction[],
  currentAccount: IAccount
) => {
  const data: TypedKeyValueStore<ITransaction[]> = {}

  const SIMILARITY_THRESHOLD = 0.67

  transactions?.forEach((transaction) => {
    const normalisedDescription = normalisedTransactionDescription(
      transaction.description
    )

    let highestSimilarity = 0
    let highestSimilarityKey: string | null = null

    Object.keys(data).forEach((key) => {
      const similarity = stringSimilarity.compareTwoStrings(
        normalisedDescription,
        key
      )
      if (similarity > SIMILARITY_THRESHOLD) {
        if (!highestSimilarityKey || similarity > highestSimilarity) {
          highestSimilarityKey = key
          highestSimilarity = similarity
        }
      }
    })

    if (!highestSimilarityKey) {
      data[normalisedDescription] = [transaction]
    } else {
      data[highestSimilarityKey] = [...data[highestSimilarityKey], transaction]
    }
  })

  return tidyUpKeyNames(data, currentAccount)
}
