import { INewCustomerInvoice } from 'model/CustomerInvoice'
import { useContext, useEffect, useRef } from 'react'
import DocumentRendererContext from '../../DocumentRendererContext'
import SetupColumnsFlyout from './flyout/SetupColumnsFlyout'

interface DocumentPositionsHeaderRowProps {
  readonly: boolean
  document: Partial<INewCustomerInvoice>
  registerEdit: (edit: Partial<INewCustomerInvoice>) => void
}

const DocumentPositionsHeaderRow = (props: DocumentPositionsHeaderRowProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const renderContext = useContext(DocumentRendererContext)
  const columns = props.document.columns

  const isVATHidden =
    props.document.vatPresentationOptions?.hideVATInQuotes &&
    props.document.isQuote

  useEffect(() => {
    renderContext.updateHeights({
      positionsHeaderRow: ref.current?.clientHeight ?? 0,
    })
  }, [])

  return (
    <div className='positions-table-header' ref={ref}>
      <SetupColumnsFlyout
        readonly={props.readonly}
        document={props.document}
        registerEdit={props.registerEdit}
      >
        <div className='positions-row'>
          {columns?.includes('quantity') && (
            <div className='positions-column quantity'>Menge</div>
          )}
          {columns?.includes('unit') && (
            <div className='positions-column unit'>Einheit</div>
          )}
          {columns?.includes('articleNumber') && (
            <div className='positions-column article'>Artikel</div>
          )}
          <div className='positions-column name'>Beschreibung</div>
          {columns?.includes('vatDebt') && !isVATHidden && (
            <div className='positions-column vat'>MWST</div>
          )}
          {columns?.includes('singleAmount') && (
            <div className='positions-column single-amount'>Einzelpreis</div>
          )}
          <div className='positions-column total-amount'>Gesamtpreis</div>
        </div>
      </SetupColumnsFlyout>
    </div>
  )
}

export default DocumentPositionsHeaderRow
