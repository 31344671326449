import React from 'react'
import ContentLoader from 'react-content-loader'

const LoaderFinancialHealthProfitability = () => (
  <ContentLoader
    speed={2}
    width={344}
    height={230}
    viewBox='0 0 344 230'
    backgroundColor='#f3f3f3'
    foregroundColor='#e5e5e5'
  >
    <circle cx='33' cy='44' r='33' />
    <rect x='91' y='23' rx='1' ry='1' width='10' height='10' />
    <rect x='113' y='22' rx='3' ry='3' width='54' height='14' />
    <rect x='223' y='22' rx='3' ry='3' width='108' height='14' />
    <rect x='113' y='50' rx='3' ry='3' width='54' height='14' />
    <rect x='223' y='50' rx='3' ry='3' width='108' height='14' />
    <rect x='32' y='100' rx='3' ry='3' width='37' height='17' />
    <rect x='86' y='94' rx='3' ry='3' width='83' height='14' />
    <rect x='86' y='114' rx='3' ry='3' width='170' height='14' />
    <rect x='91' y='51' rx='1' ry='1' width='10' height='10' />
    <rect x='33' y='147' rx='3' ry='3' width='37' height='17' />
    <rect x='87' y='141' rx='3' ry='3' width='83' height='14' />
    <rect x='87' y='161' rx='3' ry='3' width='170' height='14' />
    <rect x='33' y='193' rx='3' ry='3' width='37' height='17' />
    <rect x='87' y='187' rx='3' ry='3' width='83' height='14' />
    <rect x='87' y='207' rx='3' ry='3' width='170' height='14' />
  </ContentLoader>
)

export default LoaderFinancialHealthProfitability
