import { Button, Flex, Text } from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import SmallTransactionView from 'invoices/customer-invoices/editor/sidebar/panels/payment/SmallTransactionView'
import { observer } from 'mobx-react'
import { SettledVATPeriod } from 'model/VAT'
import DocumentsPage, { DocumentsPagePaths } from 'pages/DocumentsPage'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import Utilities from 'utility/Utilities'
import VATPaymentModal from './VATPaymentModal'
import './VATSettledPeriodSection.scss'

interface VATSettledPeriodSectionProps {
  page: DocumentsPage
  period: SettledVATPeriod
}

const VATSettledPeriodSection = (props: VATSettledPeriodSectionProps) => {
  const { vatPeriodStore } = useRootStore()
  const taxAmount = Math.max(props.period.taxPayable, props.period.taxCredit)
  const isTaxCredit = props.period.taxCredit > 0

  const [isVATPaymentModalOpen, setIsVATPaymentModalOpen] =
    useState<boolean>(false)

  return (
    <Flex className='settled-vat-period'>
      <div className='info-column'>
        <Flex className='status' verticalAlignment='center'>
          <Icon icon='check' size={IconSize.Button} />
          <span>Periode wurde abgerechnet</span>
        </Flex>
        <Button
          iconLeft='document'
          onClick={() =>
            props.page.open(DocumentsPagePaths.VATReport, props.period._id)
          }
        >
          Abrechnung
        </Button>
      </div>
      <div className='payment-column'>
        {props.period.postedTaxAmount === taxAmount ? (
          <>
            <Text className='payment-status'>
              {isTaxCredit ? 'Steuerguthaben' : 'Steuerzahlung'} von{' '}
              {Utilities.centsToCHF(taxAmount)} CHF verbucht
            </Text>
            {props.period.paymentTransactions.map((transactionId) => (
              <SmallTransactionView
                key={transactionId}
                transactionId={transactionId}
                accountDisplay={isTaxCredit ? 'debit' : 'credit'}
                invalidateQuery={() => vatPeriodStore.loadPeriods()}
                dissallowDeletion
                reasonForDisallowingDeletion={`${
                  isTaxCredit ? 'Steuerguthaben' : 'Steuerzahlungen'
                } können nicht gelöscht werden. Das Datum kann jedoch über 'Buchen' im entsprechenden Konto bearbeitet werden.`}
              />
            ))}
          </>
        ) : taxAmount === 0 ? (
          <Text className='payment-status' variant='subtle'>
            Für diese Periode ist keine Steuerzahlung notwendig.
          </Text>
        ) : (
          <>
            <Text className='payment-status'>
              {isTaxCredit ? 'Steuerguthaben' : 'Steuerzahlung'} von{' '}
              {Utilities.centsToCHF(taxAmount)} CHF noch nicht verbucht
            </Text>
            <Button
              variant='primary'
              iconLeft='receipt-approved'
              onClick={() => setIsVATPaymentModalOpen(true)}
            >
              Zahlung verbuchen
            </Button>
            <VATPaymentModal
              isOpen={isVATPaymentModalOpen}
              onClose={() => setIsVATPaymentModalOpen(false)}
              period={props.period}
              amount={taxAmount}
              isTaxCredit={isTaxCredit}
            />
          </>
        )}
      </div>
    </Flex>
  )
}

export default observer(VATSettledPeriodSection)
