import { MenuItem } from '@nextbusiness/infinity-ui'
import MoreMenu from 'components/more-menu/MoreMenu'
import IDocument from 'model/Document'
import { useState } from 'react'
import AttachDocumentToInvoiceModal from './AttachDocumentToInvoiceModal'
import DeleteDocumentModal from './DeleteDocumentModal'

interface DocumentCardMoreMenuProps {
  document: IDocument
  previewDocument: () => void
}

const DocumentCardMoreMenu = (props: DocumentCardMoreMenuProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [
    isAttachDocumentToInvoiceModalOpen,
    setIsAttachDocumentToInvoiceModalOpen,
  ] = useState<boolean>(false)

  const actions: MenuItem[] = [
    {
      text: 'Vorschau',
      icon: 'view',
      onClick: props.previewDocument,
    },
    {
      text: 'An Rechnung anhängen',
      icon: 'attach',
      onClick: () => setIsAttachDocumentToInvoiceModalOpen(true),
    },
    {
      text: 'Löschen',
      icon: 'delete',
      onClick: () => setIsDeleteModalOpen(true),
    },
  ]

  return (
    <>
      <MoreMenu actions={[{ buttons: actions }]} />
      <DeleteDocumentModal
        isOpen={isDeleteModalOpen}
        closeModal={() => setIsDeleteModalOpen(false)}
        document={props.document}
        documentId={props.document.id}
      />
      <AttachDocumentToInvoiceModal
        isOpen={isAttachDocumentToInvoiceModalOpen}
        closeModal={setIsAttachDocumentToInvoiceModalOpen}
        document={props.document}
      />
    </>
  )
}

export default DocumentCardMoreMenu
