import { APIError } from '@nextbusiness/infinity-finance-api'
import {
  Button,
  Flex,
  Heading,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import FeatureGateButton from 'components/billing/feature-gate/FeatureGateButton'
import useSubscription from 'components/billing/subscription-context/useSubscription'
import UploadArea from 'components/upload-area/UploadArea'
import { Upload } from 'documents/document-creator/DocumentUpload'
import SubscriptionCapability from 'libs/subscription/SubscriptionCapability'
import { observer } from 'mobx-react'
import { useState } from 'react'
import './TemplateInspectorBackgroundUploadArea.scss'

interface TemplateInspectorBackgroundUploadAreaProps {
  kind: string
  uploadBackground: (upload: Upload) => Promise<unknown>
  removeBackground: () => Promise<unknown>
  onSuccessfulUpload: () => void
  hasBackground: boolean
  hideUpgradeButton?: boolean
}

const TemplateInspectorBackgroundUploadArea = (
  props: TemplateInspectorBackgroundUploadAreaProps
) => {
  const notificationCenter = useNotificationCenter()
  const subscription = useSubscription()

  const [isRemovingBackground, setIsRemovingBackground] =
    useState<boolean>(false)
  const hasCapability = subscription?.hasCapability(
    SubscriptionCapability.AdvancedTemplating
  )

  const removeBackground = async () => {
    setIsRemovingBackground(true)
    try {
      await props.removeBackground()
      props.onSuccessfulUpload()
      notificationCenter.addNotification({
        children: 'Hintergrundpapier erfolgreich entfernt',
        variant: 'success',
      })
    } catch (error: any) {
      notificationCenter.addNotification({
        children: (error as APIError).humanText('de'),
        variant: 'error',
      })
    } finally {
      setIsRemovingBackground(false)
    }
  }

  const uploadBackground = async (upload: Upload) => {
    try {
      await props.uploadBackground(upload)
      props.onSuccessfulUpload()
      notificationCenter.addNotification({
        children: 'Hintergrundpapier erfolgreich hochgeladen',
        variant: 'success',
      })
    } catch (error: any) {
      notificationCenter.addNotification({
        children: (error as APIError).humanText('de'),
        variant: 'error',
      })
    }
  }

  return (
    <div className='background-upload'>
      <Flex verticalAlignment='center' className='upload-label'>
        <Heading type='h4' bare>
          {props.kind}
        </Heading>
        {props.hasBackground && (
          <Button
            iconOnly='delete'
            tooltip='Hintergrund entfernen'
            className='remove-background-button'
            variant='quaternary'
            onClick={removeBackground}
            isLoading={isRemovingBackground}
          />
        )}
      </Flex>
      <UploadArea
        icon={props.hasBackground ? 'available-updates' : 'image'}
        uploadItemKind={props.kind}
        onUploadSelected={uploadBackground}
        dropzoneOptions={{
          accept: {
            'image/*': ['.png', '.jpeg', '.jpg'],
            'application/pdf': ['.pdf'],
          },
        }}
        disabled={!hasCapability}
        isEmpty={!props.hasBackground}
      />
      {!hasCapability && !props.hideUpgradeButton && (
        <FeatureGateButton
          requiredCapability={SubscriptionCapability.AdvancedTemplating}
          customFeatureDescription='Eigener Hintergrund für Forderungen und Offerten'
          variant='primary'
        >
          Upgrade zu Pro
        </FeatureGateButton>
      )}
    </div>
  )
}

export default observer(TemplateInspectorBackgroundUploadArea)
