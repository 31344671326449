import Filters from 'base-components/table/Filters'
import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import VendorInvoiceAttachmentFilter from './VendorInvoiceAttachmentFilter'
import VendorInvoiceCreditorFilter from './VendorInvoiceCreditorFilter'
import VendorInvoiceDateFilter from './VendorInvoiceDateFilter'
import VendorInvoiceStatusFilter from './VendorInvoiceStatusFilter'

const VendorInvoicesFilterOptions = () => (
  <MagicSheetContainer>
    <Filters>
      <VendorInvoiceCreditorFilter />
      <VendorInvoiceDateFilter />
      <VendorInvoiceStatusFilter />
      <VendorInvoiceAttachmentFilter />
    </Filters>
  </MagicSheetContainer>
)

export default VendorInvoicesFilterOptions
