import { Card, Flex, RadioOption, Text } from '@nextbusiness/infinity-ui'
import { Icon, IconItem, IconSize } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import './CardRadioOption.scss'

interface CardRadioOptionProps {
  label: React.ReactNode
  isChecked: boolean
  onOptionSelected: () => void
  description?: React.ReactNode
  iconURL?: string
  icon?: IconItem
  children?: React.ReactNode
  compact?: boolean
}

const CardRadioOption = (props: CardRadioOptionProps) => {
  return (
    // Option can be selected using the radio option instead, which is keyboard accessible.
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className='card-radio-option-item' onClick={props.onOptionSelected}>
      <Card
        className={classNames('card-radio-option-card', {
          selected: props.isChecked,
          compact: props.compact,
        })}
      >
        <Flex verticalAlignment='center' className='card-radio-option-flex'>
          <RadioOption
            className='radio-option'
            isChecked={props.isChecked}
            onChange={(isChecked) => {
              if (isChecked) props.onOptionSelected()
            }}
          />
          {props.iconURL ? (
            <img src={props.iconURL} alt='' />
          ) : props.icon ? (
            <Icon icon={props.icon} size={IconSize.Medium} />
          ) : null}
          <div className='card-radio-option-text'>
            <Text className='card-radio-option-title'>{props.label}</Text>
            {props.description && (
              <Text className='card-radio-option-description'>
                {props.description}
              </Text>
            )}
            {props.children}
          </div>
        </Flex>
      </Card>
    </div>
  )
}

export default CardRadioOption
