import {
  Button,
  FeedbackBanner,
  Heading,
  Text,
} from '@nextbusiness/infinity-ui'
import React from 'react'
import AssistantStepScreen, {
  AssistantStepVisual,
} from '../AssistantStepScreen'
import FinancialClosingAssistant from '../FinancialClosingAssistant'

interface ClosingWelcomeStepProps {
  assistant: FinancialClosingAssistant
}

const ClosingWelcomeStep = (props: ClosingWelcomeStepProps) => (
  <AssistantStepScreen
    visual={AssistantStepVisual.Welcome}
    actions={
      <Button variant='primary' onClick={() => props.assistant.nextStep()}>
        Fortfahren
      </Button>
    }
    depiction={
      <>
        <Heading type='h2'>Jahresabschluss erstellen</Heading>
        <Text type='paragraph'>
          Immer nach einem Geschäftsjahr musst du einen Jahresabschluss
          erstellen, um das bestehende Jahr zu schliessen und ein neues Jahr
          einzurichten.
        </Text>
      </>
    }
  >
    <Text type='paragraph'>
      Dieser Assistent hilft dir dabei, alle notwendigen Schritte dafür
      vorzubereiten.
    </Text>
    <FeedbackBanner variant='info' title='Hast du alles verbucht?'>
      Du wirst nach dem Jahresabschluss keine Buchungen im alten Jahr mehr
      erstellen oder bearbeiten können. Stelle deshalb sicher, dass du alles bis
      zum {props.assistant.formattedClosingDate} vollständig und korrekt
      verbucht hast.
    </FeedbackBanner>
  </AssistantStepScreen>
)

export default ClosingWelcomeStep
