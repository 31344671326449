import { Button } from '@nextbusiness/infinity-ui'
import { CustomerInvoiceEditorSidebarTopActionsProps } from './CustomerInvoiceEditorSidebarTopActions'

const EditorSaveAction = (
  props: CustomerInvoiceEditorSidebarTopActionsProps
) => {
  return (
    <Button
      variant='primary'
      disabled={props.isSaving}
      isLoading={props.isSaving}
      onClick={() => {
        if (!props.canSave) {
          props.showSaveValidationMessage()
          return
        }
        props.clearSaveValidationMessage()
        props.save()
      }}
    >
      {props.document.isDraft ? 'Fertig' : 'Speichern'}
    </Button>
  )
}

export default EditorSaveAction
