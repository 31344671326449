import { ITransactionChanges } from '@nextbusiness/infinity-finance-api'
import {
  DateDisplayFormatOptions,
  DatePicker,
  Text,
} from '@nextbusiness/infinity-ui'
import SettingsFieldList from 'settings/general/SettingsFieldList'
import DateUtilities from 'utility/DateUtilites'
import './ChangeDateView.scss'

interface ChangeDateBatchEditingViewProps {
  changesToTransaction: Partial<ITransactionChanges>
  setChangesToTransaction: (
    changesToTransaction: Partial<ITransactionChanges>
  ) => void
}

const ChangeDateBatchEditingView = (props: ChangeDateBatchEditingViewProps) => {
  const { date } = props.changesToTransaction

  const formatDate = (dateToFormat: number) => {
    if (dateToFormat)
      return new Date(dateToFormat).toLocaleString(
        'de-CH',
        DateDisplayFormatOptions
      )
  }

  return (
    <>
      <Text>
        Wähle ein neues Datum aus, auf welches die ausgewählten Transaktionen
        datiert werden sollen.
      </Text>
      <div className='batch-edit-change-date-view'>
        <SettingsFieldList>
          <DatePicker
            inputFieldProps={{
              icon: 'schedule',
              fullWidth: true,
            }}
            placeholder={formatDate(Date.now())}
            value={date ? new Date(date) : undefined}
            onChange={(changedDate: Date | undefined) =>
              props.setChangesToTransaction({
                ...props.changesToTransaction,
                date: changedDate
                  ? DateUtilities.startOfDay(changedDate).getTime()
                  : undefined,
              })
            }
          />
        </SettingsFieldList>
      </div>
    </>
  )
}

export default ChangeDateBatchEditingView
