import { Button, Flex, useNotificationCenter } from '@nextbusiness/infinity-ui'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react'
import { IVendorInvoice } from 'model/VendorInvoice'
import Documents from 'networking/Documents'
import VendorInvoices from 'networking/VendorInvoices'
import { useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import { useRootStore } from 'stores/RootStoreContext'
import { useVendorInvoiceEditor } from '../detail/VendorInvoiceEditorContext'
import LiveCaptureContactSuggestor from '../live-capture/LiveCaptureContactSuggestor'
import { useVendorInvoiceCreator } from './VendorInvoiceCreationContext'

interface VendorInvoiceCreateActionsProps {
  onDismiss: () => void
}

const VendorInvoiceCreateActions = (props: VendorInvoiceCreateActionsProps) => {
  const { authenticationStore, vendorInvoiceStore } = useRootStore()
  const notificationCenter = useNotificationCenter()
  const intercom = useIntercom()

  const { invoiceDraft, setShouldValidate, isValid } = useVendorInvoiceEditor()
  const { upload } = useVendorInvoiceCreator()
  const [isCreatingInvoice, setIsCreatingInvoice] = useState<boolean>(false)

  const onCreateInvoice = async () => {
    setShouldValidate(true)
    if (!isValid) return
    try {
      setIsCreatingInvoice(true)
      let creditor = invoiceDraft.creditor
      if (creditor === LiveCaptureContactSuggestor.SUGGESTED_NEW_CONTACT_ID) {
        creditor = undefined
      }
      const invoice = await VendorInvoices.createVendorInvoice(
        authenticationStore.organisationIdentifier,
        {
          ...invoiceDraft,
          transactionDate: invoiceDraft.openingDate!,
          openingDate: invoiceDraft.openingDate!,
          dueDate: invoiceDraft.dueDate!,
          creditor,
        }
      )
      if (upload) {
        await attachUploadToInvoice(invoice)
      }
      intercom.trackEvent('vendor-invoice-created')
      MixpanelAnalytics.event('Vendor invoice created')
      await vendorInvoiceStore.onInvoicesChanged()
      props.onDismiss()
    } catch (error: any) {
      notificationCenter.addNotification({
        children: error.message || 'Die Rechnung konnte nicht erstellt werden.',
        variant: 'error',
      })
    } finally {
      setIsCreatingInvoice(false)
    }
  }

  const attachUploadToInvoice = async (invoice: IVendorInvoice) => {
    if (!upload) return
    try {
      await Documents.attachDocumentToInvoice(
        authenticationStore.organisationIdentifier,
        upload.id,
        invoice.id,
        authenticationStore.user!.id
      )
    } catch (error: any) {
      notificationCenter.addNotification({
        title:
          'Rechnung wurde erstellt, aber das Dokument konnte nicht angehängt werden. Lade dieses bitte erneut hoch.',
        children: error.message,
        variant: 'warning',
      })
    }
  }

  return (
    <Flex verticalAlignment='center' gap='tiny' style={{ marginLeft: 'auto' }}>
      <Button onClick={props.onDismiss}>Verwerfen</Button>
      <Button
        variant='primary'
        onClick={onCreateInvoice}
        isLoading={isCreatingInvoice}
      >
        Rechnung verbuchen
      </Button>
    </Flex>
  )
}

export default observer(VendorInvoiceCreateActions)
