import { APIError, Finance } from '@nextbusiness/infinity-finance-api'
import { useNotificationCenter } from '@nextbusiness/infinity-ui'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import LedgerStore from 'stores/LedgerStore'
import { useRootStore } from 'stores/RootStoreContext'
import AccountUtilities from 'utility/AccountUtilities'

const LiveAccountingRedirectFromBankPage = () => {
  const notificationCenter = useNotificationCenter()
  const { ledgerStore, accountStore, preferencesStore } = useRootStore()
  const history = useHistory()
  const intercom = useIntercom()

  const urlParams = new URLSearchParams(window.location.search)
  const externalReference = urlParams.get('externalReference')

  const currentAccountNumber = ledgerStore.currentRootView
  const currentAccount =
    accountStore.newestAccountWithNumber(currentAccountNumber)
  const useCurrentAccount =
    currentAccount &&
    AccountUtilities.isEligibleForLiveAccounting(currentAccount)
  const accountToConnect = useCurrentAccount
    ? currentAccount
    : accountStore.newestAccountWithNumber(
        LedgerStore.INITIAL_DEFAULT_ROOT_VIEW
      )

  useEffect(() => {
    if (!accountToConnect?.id) return
    if (!externalReference) {
      notificationCenter.addNotification({
        variant: 'error',
        children: 'Externe Referenz fehlt',
      })
      return
    }
    const completeConnection = async () => {
      try {
        const connectedAccount =
          await Finance.Ledger.completeLiveAccountingConnection(
            accountToConnect.id,
            externalReference
          )
        notificationCenter.addNotification({
          variant: 'success',
          children: 'Live Account verbunden',
        })
        intercom.trackEvent('live-accounting-setup-complete')
        MixpanelAnalytics.event('Live accounting connection enabled')
        await accountStore.loadAccounts()
        await preferencesStore.setFlags({
          ledgerConnectionStepCompleted: true,
        })
        if (connectedAccount.bankAccountId) {
          history.push('/buchen/synchronisieren')
        } else {
          history.push('/buchen')
        }
      } catch (error) {
        notificationCenter.addNotification({
          variant: 'error',
          children: (error as APIError).humanText('de'),
        })
      }
    }
    completeConnection()
  }, [accountToConnect?.id])

  return <LoadingScreen />
}

export default observer(LiveAccountingRedirectFromBankPage)
