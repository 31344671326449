import QuotesOverview from 'invoices/customer-invoices/QuotesOverview'
import MagicSheet from '../components/magic-sheet/MagicSheet'

class QuotesPage extends MagicSheet {
  rootView = () => {
    return <QuotesOverview />
  }

  componentDidMount() {
    this.presentViewModally({
      title: () => 'Offerten',
      view: this.rootView(),
    })
  }
}

export default QuotesPage
