import { Button, Flyout } from '@nextbusiness/infinity-ui'
import { IconItem } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import { useEffect, useMemo, useRef, useState } from 'react'
import './Filter.scss'
import FilterList, { FilterItem } from './FilterList'

export interface FilterProps<T extends string | boolean> {
  icon?: IconItem
  label: string
  items?: FilterItem<T>[]
  selectedValue?: T | null
  onValueSelected?: (value: T | null) => void
  sortAlphabetically?: boolean
  menuSize?: 'default' | 'wide'
}

const Filter = <T extends string | boolean = string>(props: FilterProps<T>) => {
  const searchFieldRef = useRef<HTMLInputElement>(null)
  const [searchQuery, setSearchQuery] = useState<string>('')

  const [isFlyoutOpen, setIsFlyoutOpen] = useState<boolean>(false)
  const selectedItem = useMemo(
    () => props.items?.find((item) => item.value === props.selectedValue),
    [props.items, props.selectedValue]
  )

  useEffect(() => {
    if (isFlyoutOpen) {
      searchFieldRef.current?.focus()
      setSearchQuery('')
    }
  }, [isFlyoutOpen])

  const isFilterActive = selectedItem !== undefined && selectedItem !== null

  return (
    <Flyout
      className='ui-filter'
      trigger={
        <Button
          iconLeft={selectedItem?.icon ?? props.icon ?? 'filter'}
          className={classNames('ui-filter-chip', {
            active: isFilterActive,
          })}
          onClick={() => setIsFlyoutOpen(!isFlyoutOpen)}
        >
          {selectedItem?.label ?? props.label}
        </Button>
      }
      triggerIsButton
      isActive={isFlyoutOpen}
      setIsActive={setIsFlyoutOpen}
    >
      <FilterList
        ref={searchFieldRef}
        items={props.items}
        selectedValue={props.selectedValue}
        onValueSelected={(value) => props.onValueSelected?.(value as T)}
        closeMenu={() => setIsFlyoutOpen(false)}
        searchQuery={searchQuery}
        onSearchQueryChanged={setSearchQuery}
        sortAlphabetically={props.sortAlphabetically}
        wide={props.menuSize === 'wide'}
      />
    </Flyout>
  )
}

export default Filter
