import { Flex, Text } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import React, { Key, useEffect, useRef } from 'react'
import StringUtilities from 'utility/StringUtilities'

export interface ResourceOptionProps<ValueType, ItemType> {
  key: Key
  item: ItemType
  value: ValueType
  searchableText: string
  customTitle?: React.ReactNode
  description?: React.ReactNode
  leftAccessory?: React.ReactNode
  rightAccessory?: React.ReactNode
  index: number
  isActive: boolean
  isSelected: boolean
  activeOption: number | undefined
  enteredValue: string
  onClick: () => void
  hasSearched?: boolean
  onMouseEnter: () => void
  onMouseLeave: () => void
}

const ResourceOption = <ValueType, ItemType>(
  props: ResourceOptionProps<ValueType, ItemType>
) => {
  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (props.isActive) {
      ref.current?.addEventListener('mouseover', () => props.onMouseEnter())
      ref.current?.addEventListener('mouseleave', () => props.onMouseLeave())
    }

    return () => {
      ref.current?.removeEventListener('mouseover', () => props.onMouseEnter())
      ref.current?.removeEventListener('mouseleave', () => props.onMouseLeave())
    }
  }, [props.isActive, props.enteredValue])

  const dynamicSearchResult = () => {
    const [, indicesOfRelevantLetters] = StringUtilities.rateResultBySearch(
      props.searchableText || '',
      props.enteredValue.toLowerCase().trim()
    )

    return (
      <Text type='inline' className='name'>
        {props.hasSearched
          ? props.searchableText?.split('').map((letter, index) =>
              indicesOfRelevantLetters.includes(index) ? (
                <span key={index} className='highlighted-letter'>
                  {letter}
                </span>
              ) : (
                letter
              )
            )
          : props.searchableText}
      </Text>
    )
  }

  return (
    /* Accessible navigation is handled in ResourceSelect with custom handlers. */
    /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */
    <div
      className={classNames('resource-option-button ui-button shell', {
        active: props.activeOption === props.index,
        selected: props.isSelected,
      })}
      onClick={props.onClick}
      data-id={props.value}
      ref={ref}
      role='menuitem'
    >
      <Flex className='resource-option' horizontalAlignment='space-between'>
        {props.leftAccessory && (
          <div className='left-accessory'>{props.leftAccessory}</div>
        )}
        <Flex className='text' direction='vertical'>
          {props.customTitle ?? dynamicSearchResult()}
          {props.description && (
            <Text type='inline' className='description' variant='subtle'>
              {props.description}
            </Text>
          )}
        </Flex>
        {props.rightAccessory}
      </Flex>
    </div>
  )
}

export default React.memo(ResourceOption)
