import { ITemplate } from '@nextbusiness/infinity-finance-api'
import { INewCustomerInvoicePosition } from 'model/CustomerInvoice'
import React, {
  JSXElementConstructor,
  ReactElement,
  useContext,
  useEffect,
  useRef,
} from 'react'
import { Draggable, DraggableProvided } from 'react-beautiful-dnd'
import { createPortal } from 'react-dom'
import { ITemplateDraft } from '../../../../../../model/Template'
import DocumentRendererContext from '../../DocumentRendererContext'

interface ElementRef {
  element?: HTMLDivElement
}

const useDraggableInPortal = (template: ITemplate | ITemplateDraft) => {
  const self = useRef<ElementRef>({}).current

  useEffect(() => {
    const div = document.createElement('div')

    div.style.position = 'absolute'
    div.style.pointerEvents = 'none'

    div.style.top = '0'
    div.style.width = '100%'
    div.style.height = '100%'

    div.classList.add(
      'draggable-portal',
      'document-typography-wrapper',
      `page-font-${template.font}`
    )

    self.element = div

    document.body.appendChild(div)
    return () => {
      document.body.removeChild(div)
    }
  }, [self])

  return (
      render: (
        provided: DraggableProvided,
        ...args: any
      ) => ReactElement<HTMLElement, string | JSXElementConstructor<any>>
    ) =>
    (provided: DraggableProvided, ...args: any) => {
      const element = render(provided, ...args)
      const position = (provided.draggableProps.style as any).position
      if (position === 'fixed') {
        return createPortal(element, self.element!)
      }
      return element
    }
}

interface DocumentPositionDraggableProps {
  id: string
  index: number
  children?: React.ReactNode
  className?: string
  template: ITemplate | ITemplateDraft
  position: INewCustomerInvoicePosition
  isReadOnly?: boolean
}

const DocumentPositionDraggable = (props: DocumentPositionDraggableProps) => {
  const renderDraggable = useDraggableInPortal(props.template)
  const ref = useRef<HTMLDivElement>(null)
  const renderContext = useContext(DocumentRendererContext)

  useEffect(() => {
    if (ref.current)
      renderContext.updatePositionHeights(
        props.index,
        ref.current?.clientHeight
      )

    return () => {
      renderContext.deletePositionHeight(props.index, props.id)
    }
  }, [props.position, props.index])

  if (props.isReadOnly)
    return (
      <div ref={ref}>
        <div
          className={
            'positions-row document-position-draggable' +
            (props.className ? ' ' + props.className : '')
          }
        >
          {props.children}
        </div>
      </div>
    )

  return (
    <Draggable draggableId={props.id} index={props.index}>
      {renderDraggable((provided) => (
        <div ref={ref}>
          <div
            className={
              'positions-row document-position-draggable' +
              (props.className ? ' ' + props.className : '')
            }
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {props.children}
          </div>
        </div>
      ))}
    </Draggable>
  )
}

export default DocumentPositionDraggable
