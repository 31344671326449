import { APIError } from '@nextbusiness/infinity-finance-api'
import { NonIdealState } from '@nextbusiness/infinity-ui'
import { IconSize } from '@nextbusiness/infinity-ui-icons'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import { useAccountPageContext } from './AccountPageContext'

interface AccountSheetErrorProps {
  error: Error
}

const AccountSheetError = (props: AccountSheetErrorProps) => {
  const { accountStore } = useRootStore()
  const { loadTransactions } = useAccountPageContext()

  const humanReadableErrorMessage = (error: Error) => {
    if ((props.error as APIError).humanText) {
      return (props.error as APIError).humanText('de')
    } else if (error.message === 'Failed to fetch') {
      return 'Verbindung fehlgeschlagen. Bitte überprüfe deine Internetverbindung.'
    } else {
      return error.message
    }
  }

  return (
    <NonIdealState
      icon='error'
      iconSize={IconSize.Medium}
      actions={[
        {
          children: 'Erneut versuchen',
          onClick: () => {
            accountStore.loadAccounts()
            loadTransactions()
          },
        },
      ]}
    >
      {humanReadableErrorMessage(props.error)}
    </NonIdealState>
  )
}

export default observer(AccountSheetError)
