import Lockup from 'components/text/Lockup'
import { AnimatePresence, motion } from 'framer-motion'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'

interface DocumentCardPendingAnalysisInfoProps {
  isAnalysing: boolean
}

const DocumentCardPendingAnalysisInfo = (
  props: DocumentCardPendingAnalysisInfoProps
) => {
  const { preferencesStore } = useRootStore()
  if (!preferencesStore.organisationPreferences?.isLiveCaptureEnabled)
    return null

  return (
    <AnimatePresence>
      {props.isAnalysing && (
        <motion.div
          initial={{ opacity: 0, y: -20, height: 0 }}
          animate={{ opacity: 0.6, y: 0, height: 'auto' }}
          exit={{ opacity: 0, y: -20, height: 0 }}
          transition={{ duration: 0.15, easings: [0.5, 0, 0.5, 1] }}
          className='document-live-capture-status'
        >
          <Lockup icon='ocr' small>
            Live Capture analysiert…
          </Lockup>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default observer(DocumentCardPendingAnalysisInfo)
