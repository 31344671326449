import { Button, Flex, Text } from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import { useLedgerContext } from 'ledger/LedgerContext'
import { LiveCaptureAttachmentChildProps } from './LiveCaptureAttachment'
import LiveCaptureAttachmentDepiction from './LiveCaptureAttachmentDepiction'
import LiveCaptureAttachmentThumbnail from './LiveCaptureAttachmentThumbnail'

const LiveCaptureAttachmentPreview = (
  props: LiveCaptureAttachmentChildProps
) => {
  const { openDocumentPageModally } = useLedgerContext()

  return (
    <Button
      className='attachment-preview'
      variant='quaternary'
      onClick={() => openDocumentPageModally(props.document)}
    >
      <LiveCaptureAttachmentThumbnail thumbnailURL={props.document.thumbnail} />
      <LiveCaptureAttachmentDepiction document={props.document} />
      <Flex
        className='preview-action-depiction'
        verticalAlignment='center'
        gap={1}
      >
        <Icon icon='view' size={IconSize.Button} />
        <Text>Beleg ansehen</Text>
      </Flex>
    </Button>
  )
}

export default LiveCaptureAttachmentPreview
