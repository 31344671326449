import { IPreset } from '@nextbusiness/infinity-finance-api'
import { useNotificationCenter } from '@nextbusiness/infinity-ui'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react-lite'
import { Contact } from 'model/Contact'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { useRootStore } from 'stores/RootStoreContext'
import ICustomerInvoice from '../../../model/CustomerInvoice'
import CustomerInvoices from '../../../networking/CustomerInvoices'
import CustomerInvoiceEditor from './CustomerInvoiceEditor'

interface CustomerInvoiceEditorPageProps {
  isQuote?: boolean
}

const CustomerInvoiceEditorPage = (props: CustomerInvoiceEditorPageProps) => {
  const { productStore, contactStore, presetStore } = useRootStore()
  const { id } = useParams<{ id?: string }>()

  const intercom = useIntercom()
  const history = useHistory()
  const notificationCenter = useNotificationCenter()

  const [invoice, setInvoice] = useState<ICustomerInvoice>()
  const [preset, setPreset] = useState<IPreset>()

  const [attachedContact, setAttachedContact] = useState<Contact>()
  const isLoadingInvoice = id !== undefined && !invoice
  const isLoadingPreset = presetStore.selectedPreset !== null && !preset && !id

  const navigateToInvoice = (invoiceId: string, shouldPush?: boolean) => {
    const url = `/forderungen/detail/${invoiceId}`
    if (shouldPush) {
      history.push(url)
    } else {
      history.replace(url)
    }
  }

  const loadInvoice = async () => {
    if (!id) return
    const resolvedInvoice = await CustomerInvoices.getCustomerInvoice(id)
    setInvoice(resolvedInvoice)
  }

  const loadPreset = async () => {
    if (!presetStore.selectedPreset || id) return
    try {
      const resolvedPreset = await presetStore.getSelectedPresetContent()
      setPreset(resolvedPreset)
    } catch (error) {
      notificationCenter.addNotification({
        variant: 'error',
        title: 'Fehler beim Laden der Vorlage',
        children: `Der Inhalt der Vorlage "${presetStore.selectedPreset.title}" konnte nicht geladen werden.`,
      })
    } finally {
      presetStore.setSelectedPreset(null)
    }
  }

  const loadAttachedContact = () => {
    if (contactStore.contactBeingAttached) {
      setAttachedContact(contactStore.contactBeingAttached)
      contactStore.attachContactToNewInvoice(undefined)
    }
  }

  useEffect(() => {
    loadInvoice()
    MixpanelAnalytics.event('Customer invoice viewed')
  }, [id])

  useEffect(() => {
    loadPreset()
    loadAttachedContact()
    productStore.loadAllProducts()
  }, [])

  return (
    <div className='document-editor-page'>
      {isLoadingInvoice || isLoadingPreset ? (
        <LoadingScreen />
      ) : (
        <CustomerInvoiceEditor
          key={invoice?.id}
          currentInvoice={invoice}
          isQuote={props.isQuote}
          navigateToInvoice={navigateToInvoice}
          notificationCenter={notificationCenter}
          onInvoiceCreated={(document) => {
            intercom.trackEvent('customer-invoice-created')
            MixpanelAnalytics.event('Customer invoice created', {
              quote: document.invoice.isDraft,
              isQRInvoice: document.invoice.isQrInvoice,
            })
          }}
          contact={attachedContact}
          preset={preset}
        />
      )}
    </div>
  )
}

export default observer(CustomerInvoiceEditorPage)
