import { ITemplate } from '@nextbusiness/infinity-finance-api'
import { FeedbackBanner, Text } from '@nextbusiness/infinity-ui'
import { TemplateInspectorProps } from '../TemplateInspector'
import TemplateInspectorGroup from '../TemplateInspectorGroup'
import TemplateInspectorCoverBackgroundUpload from './TemplateInspectorCoverBackgroundUpload'
import TemplateInspectorSubsequentPagesBackgroundUpload from './TemplateInspectorSubsequentPagesBackgroundUpload'

const TemplateInspectorCustomBackground = (props: TemplateInspectorProps) => (
  <TemplateInspectorGroup>
    {props.isFirstTimeCreation ? (
      <FeedbackBanner>
        Speichere das Dokumentdesign ab, um später einen Hintergrund
        hinzuzufügen.
      </FeedbackBanner>
    ) : (
      <>
        <TemplateInspectorCoverBackgroundUpload
          template={props.template as ITemplate}
          reloadTemplate={props.reloadTemplate}
        />
        <TemplateInspectorSubsequentPagesBackgroundUpload
          template={props.template as ITemplate}
          reloadTemplate={props.reloadTemplate}
        />
        <Text className='explainer'>
          Hintergründe in A4-Dimensionen können hier als PDF oder Bild
          hochgeladen werden.
        </Text>
      </>
    )}
  </TemplateInspectorGroup>
)

export default TemplateInspectorCustomBackground
