import { Heading, Text } from '@nextbusiness/infinity-ui'
import { Icon, IconItem, IconSize } from '@nextbusiness/infinity-ui-icons'
import React from 'react'
import './FeaturePresentationItem.scss'

interface FeaturePresentationItemProps {
  icon: IconItem
  title: React.ReactNode
  description: React.ReactNode
  lozenge?: React.ReactNode
}

const FeaturePresentationItem = (props: FeaturePresentationItemProps) => (
  <div className='feature-presentation-item'>
    <div className='icon'>
      <Icon icon={props.icon} size={IconSize.Medium} />
    </div>
    <div className='depiction'>
      <Heading type='h4'>
        <span>{props.title}</span>
        {props.lozenge}
      </Heading>
      <Text>{props.description}</Text>
    </div>
  </div>
)

export default FeaturePresentationItem
