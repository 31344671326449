import { Card, Flex, Text } from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import { taxOptionFromTaxCode } from 'components/vat/vat-picker/TaxCodes'
import { DateTime } from 'luxon'
import Account from './Account'
import TransactionAmount from './TransactionAmount'
import './VirtualTransactionView.scss'
import { TaxCode } from '@nextbusiness/infinity-finance-api'

interface VirtualTransactionViewProps {
  debitAccount: number
  creditAccount: number
  description: string
  amount: number
  swapArrowDirection?: boolean
  date?: number
  taxCode?: string
}

const VirtualTransactionView = (props: VirtualTransactionViewProps) => {
  const date = props.date ? DateTime.fromMillis(props.date) : undefined
  const textForTaxCode = () => {
    if (!props.taxCode) return undefined
    const vatOption = taxOptionFromTaxCode(props.taxCode as TaxCode)
    if (!vatOption?.rate) return vatOption.shortenedName
    else return `${vatOption.rate}% ${vatOption.shortenedName}`
  }

  return (
    <div className='transaction detailed virtual-transaction'>
      <Card bare>
        <Flex className='upper-half' verticalAlignment='center'>
          {date && (
            <Text type='inline' className='date'>
              {date.toJSDate().toLocaleString('de-De', {
                dateStyle: 'short',
              })}
            </Text>
          )}
          <Flex className='accounts' verticalAlignment='center' gap='tiny'>
            <Account accountNumber={props.debitAccount} />
            <Icon
              icon={props.swapArrowDirection ? 'arrow-right' : 'arrow-left'}
              size={IconSize.Tiny}
            />
            <Account accountNumber={props.creditAccount} />
          </Flex>
          <Text type='inline' className='description'>
            {props.description}
          </Text>
          {props.taxCode && (
            <Text type='inline' className='tax-code'>
              {textForTaxCode()}
            </Text>
          )}
          <TransactionAmount amount={props.amount} />
        </Flex>
      </Card>
    </div>
  )
}

export default VirtualTransactionView
