import FinancialHealthContext from '../FinancialHealthContext'
import FinancialHealthMetric from '../FinancialHealthMetric'
import FinancialHealthStatusGroup from '../FinancialHealthStatusGroup'
import MetricDegreeOfLiquidity1 from '../metrics/MetricDegreeOfLiquidity1'
import MetricDegreeOfLiquidity2 from '../metrics/MetricDegreeOfLiquidity2'

class StatusGroupSolvency extends FinancialHealthStatusGroup {
  title = 'Zahlungsfähigkeit'

  setupMetrics(context: FinancialHealthContext): FinancialHealthMetric[] {
    return [
      new MetricDegreeOfLiquidity1(context),
      new MetricDegreeOfLiquidity2(context),
    ]
  }
}

export default StatusGroupSolvency
