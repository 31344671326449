import { APIError, Finance } from '@nextbusiness/infinity-finance-api'
import { Button, useNotificationCenter } from '@nextbusiness/infinity-ui'
import { IconSize } from '@nextbusiness/infinity-ui-icons'
import { useAccountSheetContext } from 'ledger/accounts/AccountSheetContext'
import { useState } from 'react'
import { useTransactionEditorContext } from '../../TransactionEditorContext'

const TransactionResetManualReconciliation = () => {
  const [isResettingReconciliation, setIsResettingReconciliation] =
    useState<boolean>(false)
  const notificationCenter = useNotificationCenter()

  const { originalTransaction } = useTransactionEditorContext()
  const { replaceTransactions } = useAccountSheetContext()

  const resetReconciliation = async () => {
    setIsResettingReconciliation(true)
    try {
      const updatedTransactions =
        await Finance.Ledger.manuallyReconcileTransaction(
          originalTransaction.id,
          { regenerate: true }
        )
      replaceTransactions([originalTransaction], updatedTransactions)
    } catch (error) {
      notificationCenter.addNotification({
        variant: 'error',
        children: (error as APIError).humanText('de'),
      })
    } finally {
      setIsResettingReconciliation(false)
    }
  }

  return (
    <Button
      onClick={resetReconciliation}
      variant='quaternary'
      iconLeft='undo'
      iconSize={IconSize.Tiny}
      isLoading={isResettingReconciliation}
    >
      Neu generieren
    </Button>
  )
}

export default TransactionResetManualReconciliation
