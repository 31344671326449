import Filters from 'base-components/table/Filters'
import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import CustomerInvoiceDateFilter from './CustomerInvoiceDateFilter'
import CustomerInvoiceQuoteStatusFilter from './CustomerInvoiceQuoteStatusFilter'
import CustomerInvoiceRecipientFilter from './CustomerInvoiceRecipientFilter'
import CustomerInvoiceStatusFilter from './CustomerInvoiceStatusFilter'

interface CustomerInvoicesFilterOptionsProps {
  type: 'invoices' | 'quotes'
}

const CustomerInvoicesFilterOptions = (
  props: CustomerInvoicesFilterOptionsProps
) => (
  <MagicSheetContainer>
    <Filters>
      <CustomerInvoiceRecipientFilter />
      <CustomerInvoiceDateFilter />
      {props.type === 'invoices' && <CustomerInvoiceStatusFilter />}
      {props.type === 'quotes' && <CustomerInvoiceQuoteStatusFilter />}
    </Filters>
  </MagicSheetContainer>
)

export default CustomerInvoicesFilterOptions
