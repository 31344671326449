import { SpecialAccount } from 'components/transactions/SpecialAccounts'
import { IAccount } from 'model/Account'
import React, { useContext, useMemo } from 'react'
import {
  ResolvedRuleSet,
  resolveMatchingRulesForAccount,
} from './data/AccountRules'

interface AccountPageContextType {
  currentAccount: IAccount | SpecialAccount
  loadTransactions: () => Promise<void>
  resetTransactions: () => void
  loadingError: Error | null
  canLoadMoreTransactions: boolean
  viewId: string
  rules: ResolvedRuleSet
}

export const AccountPageContext =
  React.createContext<AccountPageContextType | null>(null)

export const useAccountPageContext = () => {
  const context = useContext(AccountPageContext)
  if (!context) {
    throw new Error(
      'useAccountPageContext must be used within an AccountPageProvider'
    )
  }
  return context
}

const AccountPageProvider = (
  props: React.PropsWithChildren<Omit<AccountPageContextType, 'rules'>>
) => {
  const {
    children,
    currentAccount,
    loadTransactions,
    resetTransactions,
    loadingError,
    canLoadMoreTransactions,
    viewId,
  } = props

  const rules = useMemo(
    () => resolveMatchingRulesForAccount(currentAccount),
    [currentAccount]
  )

  const value: AccountPageContextType = useMemo(() => {
    return {
      currentAccount,
      loadTransactions,
      resetTransactions,
      loadingError,
      canLoadMoreTransactions,
      viewId,
      rules,
    }
  }, [
    currentAccount,
    loadTransactions,
    resetTransactions,
    loadingError,
    canLoadMoreTransactions,
    viewId,
    rules,
  ])

  return (
    <AccountPageContext.Provider value={value}>
      {children}
    </AccountPageContext.Provider>
  )
}

export default AccountPageProvider
