import {
  AmountMode,
  VATPresentationOptions,
} from '@nextbusiness/infinity-finance-api'
import { Checkbox, RadioGroup } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { INewCustomerInvoice } from 'model/CustomerInvoice'
import React from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import ConfigurableAreaFlyout from './ConfigurableAreaFlyout'

interface VATPresentationOptionsFlyoutProps {
  children: React.ReactNode
  readonly: boolean | undefined
  document: Partial<INewCustomerInvoice>
  registerEdit: (edit: Partial<INewCustomerInvoice>) => void
}

const VATPresentationOptionsFlyout = (
  props: VATPresentationOptionsFlyoutProps
) => {
  const { preferencesStore } = useRootStore()
  if (!preferencesStore.organisationPreferences?.VAT)
    return <>{props.children}</>

  const changeOptions = (options: Partial<VATPresentationOptions>) =>
    props.registerEdit({
      vatPresentationOptions: {
        ...props.document.vatPresentationOptions,
        ...options,
      },
    })

  const canHideVAT =
    props.document.isQuote && props.document.amountMode === AmountMode.Net
  const isHidingVAT =
    canHideVAT && props.document.vatPresentationOptions?.hideVATInQuotes
  const canCustomiseNetAmount = !isHidingVAT

  return (
    <ConfigurableAreaFlyout
      className='vat-presentation-options-flyout'
      tooltip='MWST-Optionen'
      settings={
        <div>
          <span className='configurable-area-title'>Mehrwertsteuer</span>
          <RadioGroup
            options={[
              {
                label: 'Zu Preisen dazurechnen',
                value: AmountMode.Net,
              },
              {
                label: 'In Preisen bereits enthalten',
                value: AmountMode.Gross,
              },
            ]}
            value={props.document.amountMode}
            onChange={(amountMode) => props.registerEdit({ amountMode })}
          />
          <span className='configurable-area-title'>Summenzeilen</span>
          {canCustomiseNetAmount && (
            <Checkbox
              label='Netto-Summe anzeigen'
              isChecked={
                props.document.vatPresentationOptions?.showNetAmountSubtotal
              }
              onChange={(isChecked) =>
                changeOptions({ showNetAmountSubtotal: isChecked })
              }
            />
          )}
          {canHideVAT && (
            <Checkbox
              label='Nur Total exkl. MWST'
              isChecked={props.document.vatPresentationOptions?.hideVATInQuotes}
              onChange={(isChecked) =>
                changeOptions({ hideVATInQuotes: isChecked })
              }
            />
          )}
        </div>
      }
      readonly={props.readonly}
    >
      {props.children}
    </ConfigurableAreaFlyout>
  )
}

export default observer(VATPresentationOptionsFlyout)
