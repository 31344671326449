import {
  APIError,
  Finance,
  LiveAccountingProvider,
} from '@nextbusiness/infinity-finance-api'
import {
  Button,
  Card,
  Heading,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { motion } from 'framer-motion'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react'
import { IAccount } from 'model/Account'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { useRootStore } from 'stores/RootStoreContext'

interface LiveAccountingProviderCardProps {
  account: IAccount
  provider: LiveAccountingProvider
  overrideLogo?: string
  limitationsArticle?: string
}

const LiveAccountingProviderCard = (props: LiveAccountingProviderCardProps) => {
  const { authenticationStore, accountStore } = useRootStore()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const notificationCenter = useNotificationCenter()
  const intercom = useIntercom()
  const history = useHistory()

  const selectProvider = async () => {
    if (!authenticationStore.user) return

    intercom.trackEvent('live-accounting-provider-selected', {
      provider: props.provider.name,
    })
    MixpanelAnalytics.event('Live accounting provider selected', {
      provider: props.provider.name,
    })
    setIsLoading(true)
    try {
      const redirect = await Finance.Ledger.startLiveAccountingConnection(
        props.account.id,
        props.provider.providerId,
        authenticationStore.user.email
      )
      history.push('/blink/continue')
      // Wait for the navigation transition to finish before loading the accounts
      // to avoid a flash of a ledger screen with a semi-set-up live accounting
      // connection.
      window.setTimeout(async () => {
        await accountStore.loadAccounts()
        window.location.href = redirect.authorisationUri
      }, 1000)
    } catch (error) {
      notificationCenter.addNotification({
        children: (error as APIError).humanText('de'),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <motion.div
      initial={{ translateY: '6rem', opacity: 0 }}
      animate={{ translateY: '0rem', opacity: 1 }}
      transition={{
        velocity: 0.05,
        stiffness: 50,
        mass: 0.5,
        type: 'spring',
      }}
    >
      <Card className='provider' bare>
        {props.limitationsArticle && (
          <a
            href={props.limitationsArticle}
            target='_blank'
            rel='noopener noreferrer'
            className='limitations-link'
          >
            Mit Einschränkungen
          </a>
        )}
        <img
          src={props.overrideLogo ?? props.provider.logo}
          className='logo'
          alt=''
        />
        <Heading type='h4'>{props.provider.name}</Heading>
        <Button
          variant='secondary'
          isLoading={isLoading}
          onClick={() => selectProvider()}
        >
          Auswählen
        </Button>
      </Card>
    </motion.div>
  )
}

export default observer(LiveAccountingProviderCard)
