import { LegalType } from '@nextbusiness/infinity-finance-api'
import {
  Checkbox,
  FeedbackBanner,
  Text,
  TypedKeyValueStore,
} from '@nextbusiness/infinity-ui'
import IconSection from 'components/icon-section/IconSection'
import Check from 'documents/financial-closing/assistant/components/Check'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import SetupAccountBalanceSimpleField from './SetupAccountBalanceSimpleField'
import useSetupAccountBalanceSums from './useSetupAccountBalanceSums'

interface SetupAccountBalanceSimpleFormProps {
  openingDate: string
  accountBalances: TypedKeyValueStore<number | null>
  editBalance: (accountNumber: number, balance: number | null) => void
}

const equityQuestionText = (legalForm: LegalType | undefined) => {
  switch (legalForm) {
    case LegalType.SoleProprietorship:
    case LegalType.GeneralPartnership:
      return 'Wie viel Geld wurde in die Firma eingebracht? (Eigenkapital)'
    case LegalType.LimitedCompany:
      return 'Wie hoch ist das Aktienkapital der Firma?'
    case LegalType.LLC:
      return 'Wie hoch ist das Stammkapital der Firma?'
    default:
      return 'Wie hoch ist das Eigenkapital der Firma?'
  }
}

const SetupAccountBalanceSimpleForm = (
  props: SetupAccountBalanceSimpleFormProps
) => {
  const { accountStore, preferencesStore } = useRootStore()
  const [showAll, setShowAll] = useState<boolean>(false)

  const legalForm = preferencesStore.organisationPreferences?.legalType
  const { sumOfAssets, sumOfLiabilities } = useSetupAccountBalanceSums(
    accountStore.allAccounts,
    props.accountBalances
  )

  useEffect(() => {
    if (!showAll) {
      props.editBalance(1000, null)
      props.editBalance(1500, null)
    }
  }, [showAll])

  return (
    <IconSection icon='numbers-input-form'>
      <Text className='instructions'>
        Trage hier die Anfangsbestände für deine Buchhaltung per{' '}
        {props.openingDate} ein.
      </Text>
      <div className='simplified-setup'>
        <SetupAccountBalanceSimpleField
          label={`Banksaldo per ${props.openingDate}`}
          value={props.accountBalances[1020]}
          onChange={(balance) => props.editBalance(1020, balance)}
        />
        <SetupAccountBalanceSimpleField
          label={equityQuestionText(legalForm)}
          value={props.accountBalances[2800]}
          onChange={(balance) => props.editBalance(2800, balance)}
          hint='Dazu gehört einbezahltes Kapital oder auch Sacheinlagen bei der Gründung.'
        />
        <Checkbox
          label='Es bestehen Kassenguthaben oder Sacheinlagen'
          isChecked={showAll}
          onChange={setShowAll}
        />
        {showAll && (
          <>
            <Text variant='subtle'>
              Gib hier die Anfangsbestände per Gründungsdatum ein.
            </Text>
            <SetupAccountBalanceSimpleField
              label='Anfangsbestand der Kasse'
              value={props.accountBalances[1000]}
              onChange={(balance) => props.editBalance(1000, balance)}
            />
            <SetupAccountBalanceSimpleField
              label='Anfangsbestand für Maschinen und Apparate'
              value={props.accountBalances[1500]}
              onChange={(balance) => props.editBalance(1500, balance)}
            />
            <SetupAccountBalanceSimpleField
              label='Anfangsbestand für Mobiliar und Einrichtungen'
              value={props.accountBalances[1510]}
              onChange={(balance) => props.editBalance(1510, balance)}
            />
            <SetupAccountBalanceSimpleField
              label='Anfangsbestand für Fahrzeuge'
              value={props.accountBalances[1530]}
              onChange={(balance) => props.editBalance(1530, balance)}
            />
            <FeedbackBanner
              variant='help'
              title='Noch andere Anfangsbestände (z.B. immaterielle Vermögenswerte)?'
            >
              Verwende die Option «Ich habe bereits eine Buchhaltung geführt»,
              um Anfangsbestände für beliebige Konten einzugeben.
            </FeedbackBanner>
          </>
        )}
      </div>
      <Check isFulfilled={sumOfAssets === sumOfLiabilities}>
        Die Buchhaltung muss zum Eröffnungsdatum ausgeglichen sein.
      </Check>
    </IconSection>
  )
}

export default observer(SetupAccountBalanceSimpleForm)
