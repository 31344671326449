import { ITransaction } from '@nextbusiness/infinity-finance-api'
import { useEffect, useRef } from 'react'
import AccountStore from 'stores/AccountStore'
import PreferencesStore from 'stores/PreferencesStore'
import { store } from 'stores/RootStoreContext'
import TaxUtilities from 'utility/TaxUtilities'
import useIsVATDisabled from './useIsVATDisabled'

const useAutoSuggestVAT = (
  transaction: Pick<
    Partial<ITransaction>,
    'creditAccount' | 'debitAccount' | 'taxCode' | 'id' | 'date'
  >,
  updateTransaction: (changes: Partial<ITransaction>) => void,
  contraAccountSide: 'creditAccount' | 'debitAccount',
  accountStore: AccountStore,
  preferencesStore: PreferencesStore
) => {
  const firstRender = useRef(true)
  const contraAccountNumber = transaction[contraAccountSide]

  const { isVATDisabled } = useIsVATDisabled(preferencesStore, transaction)

  useEffect(() => {
    if (isVATDisabled) return

    if (
      transaction.creditAccount &&
      transaction.debitAccount &&
      !firstRender.current
    )
      updateTransaction({
        taxCode:
          TaxUtilities.autoSuggestVAT(
            {
              number: contraAccountNumber,
              account: accountStore.find(contraAccountNumber),
            },
            store.preferencesStore.organisationPreferences,
            transaction.date
          ) ?? '',
      })
    firstRender.current = false
  }, [transaction.creditAccount, transaction.debitAccount])
}

export default useAutoSuggestVAT
