import { InitialSetupContextProvider } from './InitialSetupContext'
import OnboardingContentView from './OnboardingContentView'
import './OnboardingInitialSetup.scss'
import OnboardingSetupSteps from './OnboardingSetupSteps'
import OnboardingStepper from './OnboardingStepper'

const OnboardingInitialSetup = () => (
  <InitialSetupContextProvider>
    <OnboardingContentView header={<OnboardingStepper />}>
      <OnboardingSetupSteps />
    </OnboardingContentView>
  </InitialSetupContextProvider>
)

export default OnboardingInitialSetup
