import { Finance } from '@nextbusiness/infinity-finance-api'
import {
  Button,
  Flex,
  InputField,
  LoadingIndicator,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { useMixpanelEvent } from 'integrations/useMixpanelEvent'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import SettingsFieldList from 'settings/general/SettingsFieldList'
import SettingsGroup from 'settings/general/SettingsGroup'
import SettingsPage from 'settings/general/SettingsPage'
import NotificationsIcon from '../../assets/settings-icons/notification-settings-icon.svg'
import useBackendQuery from '../../libs/networking/BackendQuery'
import { useRootStore } from '../../stores/RootStoreContext'

const NotificationSettingsPage = () => {
  const { preferencesStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [loadedSettings, isLoading] = useBackendQuery(() =>
    Finance.Organisation.current()
  )

  const [notificationEmail, setNotificationEmail] = useState<string>('')

  const [isSaving, setIsSaving] = useState<boolean>(false)

  const [showValidationError, setShowValidationError] = useState<boolean>(false)

  const saveChanges = async () => {
    setIsSaving(true)
    try {
      const hasEnteredValidEmail =
        /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/.test(
          notificationEmail
        ) || notificationEmail === ''

      setShowValidationError(!hasEnteredValidEmail)
      if (hasEnteredValidEmail) {
        const preferences = await Finance.Organisation.setPreferences({
          notificationEmail,
        })
        runInAction(() => {
          preferencesStore.organisationPreferences = preferences
        })
        notificationCenter.addNotification({
          children: 'Einstellungen gespeichert',
          variant: 'success',
        })
      }
    } catch (error: any) {
      notificationCenter.addNotification({
        title: 'Einstellungen konnten nicht gespeichert werden',
        children: error?.message,
        variant: 'error',
      })
    } finally {
      setIsSaving(false)
    }
  }

  useEffect(() => {
    if (loadedSettings?.notificationEmail)
      setNotificationEmail(loadedSettings.notificationEmail)
  }, [loadedSettings])

  useEffect(() => {
    const keyDownEventHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') saveChanges()
    }
    document.addEventListener('keyup', keyDownEventHandler)
    return () => document.removeEventListener('keyup', keyDownEventHandler)
  }, [saveChanges])

  useMixpanelEvent('Settings viewed - Notifications')

  if (isLoading)
    return (
      <Flex
        horizontalAlignment='center'
        verticalAlignment='center'
        fillContainer
      >
        <LoadingIndicator />
      </Flex>
    )

  return (
    <SettingsPage
      title='Benachrichtigungen'
      iconURL={NotificationsIcon}
      className='notification-settings-page'
    >
      <SettingsGroup
        title='E-Mail Adresse für Benachrichtigungen'
        description='Falls hier eine E-Mail festgelegt ist, wird diese für wichtige Benachrichtigungen verwendet – zum Beispiel, wenn ein Kunde eine Offerte per Link akzeptiert.'
      >
        <SettingsFieldList>
          <InputField
            type='text'
            placeholder='E-Mail Adresse eingeben'
            value={notificationEmail}
            onChange={(value) => setNotificationEmail(value)}
            hasError={showValidationError}
            helperText={
              showValidationError
                ? 'Du musst eine gültige E-Mail Adresse eingeben'
                : ''
            }
          />
        </SettingsFieldList>
      </SettingsGroup>
      <Button
        variant='primary'
        isLoading={isSaving}
        onClick={() => saveChanges()}
      >
        Speichern
      </Button>
    </SettingsPage>
  )
}

export default observer(NotificationSettingsPage)
