import { KeyValueStore } from '@nextbusiness/infinity-ui'

export const accountDescriptions: KeyValueStore = {
  1000: 'Verbuche hier das Bargeld, das du z.B. in der Firmenkasse hälst.',
  1020: 'Hier kannst du abbilden, welche Einnahmen und Ausgaben auf deinem Geschäftskonto stattfinden.',
  1100: 'Forderungen aus Lieferungen und Leistungen sind ausstehende Einnahmen aus offenen Rechnungen, die du deinen Kunden bereits gestellt hast.',
  1109: 'Mit dem Delkrederekonto kannst du deine Forderungen um mögliche geschätzte Zahlungsverluste korrigieren.',
  1170: 'Hier werden MWST-Beträge gesammelt, die du bei Material- und Dienstleistungskäufen bezahlst hast, und wieder zurückfordern kannst.',
  1171: 'Hier werden MWST-Beträge gesammelt, die du bei Investitionen und anderem Betriebsaufwand bezahlst hast, und wieder zurückfordern kannst.',
  1176: 'In dieses Konto kannst du Verrechnungssteuerbeträge buchen, die du vom Staat zurückfordern kannst.',
  1200: 'Materielle Güter in deinem Lager, die du für deinen Geschäftszweck benötigst.',
  1210: 'Rohstoffe in deinem Lager, die du für die Produktion deiner Produkte benötigst.',
  1300: 'Dieses Konto wird für die Abgrenzung beim Jahresabschluss verwendet. Verbuche hier Leistungen, du du bereits bezahlt hast, aber erst im Folgejahr erhältst.',
  1301: 'Dieses Konto wird für die Abgrenzung beim Jahresabschluss verwendet. Vebruche hier Leistungen, die du bereits an deine Kunden geliefert hast, aber wofür du noch keine Rechnung gestellt hast.',
  1400: 'Verbuche hier Wertschriften des Anlagevermögens – hierzu zählen nicht börsenkotierte langfristige Anlagen im Geschäftsvermögen.',
  1480: 'Verbuche hier stimmberechtigte Beteiligungen an anderen Unternehmen von mindestens 20%.',
  1500: 'Alle Maschinen und andere technische Anlagen in deinem Betrieb',
  1509: 'Über Zeit verlieren deine Maschinen und Anlagen an Wert. Über dieses Konto buchst du diesen Wertverlust.',
  1510: 'Deine Geschäftsaustattung und Einrichtungen wie Büromobilar, Regale, etc.',
  1519: 'Über Zeit verliert deine Geschäftsaustattung an Wert. Über dieses Konto buchst du diesen Wertverlust.',
  1520: 'Büromaschinen, Informatik und Telekommunikationseinrichtungen',
  1529: 'Über Zeit verlieren deine Büromaschinen und Austattung an Wert. Über dieses Konto buchst du diesen Wertverlust.',
  1530: 'Geschäftsfahrzeuge, die du für deinen Betrieb benötigst.',
  1539: 'Über Zeit verlieren deine Geschäftsfahrzeuge an Wert. Über dieses Konto buchst du diesen Wertverlust.',
  1540: 'Werkzeuge und andere Geräte, die du für deinen Betrieb benötigst.',
  1549: 'Über Zeit verlieren deine Werkzeuge und andere Geräte an Wert. Über dieses Konto buchst du diesen Wertverlust.',
  1600: 'Dieses Konto kann für Geschäftsliegenschaften verwendet werden.',
  1609: 'Über Zeit verlieren deine Geschäftsliegenschaften an Wert. Über dieses Konto buchst du diesen Wertverlust.',
  1700: 'Hier kannst du erworbene immaterielle Vermögenswerte wie Patente oder Lizenzen deiner Firma verbuchen.',
  2000: 'Verbindlichkeiten aus Lieferungen und Leistungen sind die offenen Rechnungen von deinen Lieferanten, die du noch nicht bezahlt hast.',
  2100: 'Kurzfristige verzinsliche Verbindlichkeiten gegenüber deiner Bank, mit unter 12 Monaten Laufzeit.',
  2200: 'In diesem Konto werden automatisch die Mehrwertsteuer auf Verkäufe gesammelt, die du an den Staat bezahlen musst.',
  2201: 'In diesem Konto wird die zu bezahlende Mehrwertsteuer mit den Beträgen, die du zurückfordern kannst, verrechnet.',
  2300: 'Dieses Konto wird für die Abgrenzung beim Jahresabschluss verwendet. Verbuche hier Aufwände, die bereits angefallen sind, aber erst im Folgejahr in Rechnung gestellt werden.',
  2301: 'Dieses Konto wird für die Abgrenzung beim Jahresabschluss verwendet. Verbuche hier Erträge, die du bereits erhalten hast, aber deren Leistungen noch nicht erbracht sind.',
  2330: 'Mit kurzfristigen Rückstellungen verbuchst du Aufwände, die mit ausreichender Sicherheit in der nächsten Periode zu erwarten sind, aber noch nicht anfallen. Zum Beispiel können nicht versicherte Schäden, Rückbaupflichten oder Mehrleistungen des Personals dazu gehören.',
  2400: 'Langfristige Bankverbindlichkeiten wie Darlehen, mit einer Laufzeit von mindestens 12 Monaten.',
  2420: 'Beim Finanzierungsleasing verbuchst du die Leasingraten als Verbindlichkeiten.',
  2450: 'Wenn du einen Kredit aufnimmst, kannst du die Schulden gegenüber dem Kreditgeber hier eintragen.',
  2500: 'Verbuche hier übrige langfrstige Verbindlichkeiten, die unverzinslich sind.',
  2600: 'In den langfrstigen Rückstellungen kannst du Aufwände verbuchen, die mit ausreichender Sicherheit in Zukunft zu erwarten sind, zum Beispiel absehbare Kosten für Rechtsverfahren.',
  2800: 'Das Eigenkapital sind die Schulden der Unternehmung gegenüber ihren Eigentümern.',
  2900: 'Das Gesetz schreibt für juristische Personen gewisse Reserven für das Kapital vor.',
  2950: 'Das Gesetz schreibt für juristische Personen gewisse Reserven vor, die sie über Gewinne aufbauen müssen.',
  2970: 'Der Gewinnvortrag ist der provisorische Gewinn des laufenden Geschäftsjahres.',
  2979: 'Im Konto Jahresgewinn oder Jahresverlust sind zurückbehaltene Profite aus vergangenen Geschäftsjahren.',
  3000: 'Einnahmen, die du durch den Verkauf deiner hergestellten Produkte erzielt hast.',
  3200: 'Einnahmen, die du durch den Handel von Produkten erzielt hast.',
  3400: 'Einnahmen, die du durch das Erbringen von Dienstleistungen erzielt hast.',
  3600: 'Übrige Erlöse aus Lieferungen und Leistungen, die du gegenüber deinen Kunden erbracht hast.',
  3805: 'Forderungen gegenüber deinen Kunden, die du aufgrund von Zahlungsunfähigkeit abschreiben musstest.',
  3900: 'Verbuche über dieses Konto beim Abschluss die Bestandesänderung von "Unfertige Erzeugnisse".',
  3901: 'Verbuche über dieses Konto beim Abschluss die Bestandesänderung von "Fertige Erzeugnisse".',
  3940: 'Verbuche über dieses Konto beim Abschluss die Bestandesänderung von "Nicht fakturierte Dienstleistungen".',
  4000: 'Materialaufwände, die für die Produktion anfallen.',
  4200: 'Ausgaben für den Einkauf von materiellen Güter, die du für deinen Geschäftszweck benötigst.',
  4400: 'Aufwand für Dienstleistungen von Dritten, die du für die Leistungserbringung bezogen hast, zum Beispiel wenn du Fertigungsschritte auslagerst.',
  4500: 'Energiekosten, die im Bereich der direkten Produktion angefallen sind.',
  5000: 'Bruttobeträge für die Löhne und Gehälter, die du deinen Mitarbeitern bezahlt hast.',
  5800: 'Übriger Personalaufwand wie Recruitingkosten, Aus- und Weiterbildung, Spesen, etc.',
  6000: 'Mietzinse, die du für die Nutzung von Geschäftsräumen bezahlt hast.',
  6100: 'Unterhalt, Reparaturen und Ersatz von mobilen Sachanlagen wie Maschinen, Betriebsmobiliar und Bürogeräten.',
  6200: 'Treibstoff, Reparaturen, Verkehrsabgaben, Fahrzeugversicherungen und weitere Ausgaben für die Nutzung von Fahrzeugen.',
  6280: 'Aufwände für Transporte durch einen Spediteur',
  6300: 'Sachversicherungen wie Betriebshaftpflicht, Garantie, Rechtsschutz, und ähnliche.',
  6400: 'Energiekosten sowie Entsorgungsgebühren, die angefallen sind.',
  6500: 'Zum Verwaltungsaufwand gehören Ausgaben wie Büromaterial, Telefon, Buchführung, etc.',
  6570: 'Zum Informatikaufwand gehören Ausgaben wie Internet, Lizenzen, Wartung, etc.',
  6600: 'Werbeausgaben für Inserate, Werbedrucksachen, Plakate, Online-Werbung, etc.',
  6800: 'Mit Abschreibungen korrigierst du den Wert von Anlagen, die über Zeit an Wert verlieren.',
  6900: 'Zum Finanzaufwand gehören Bankzinsen, Spesen, Gebühren und ähnliches.',
  6950: 'Zum Finanzertrag gehören Bankzinsen und andere Finanzeinnahmen.',
  9200: 'Über dieses Hilfskonto werden alle anderen Konten am Anfang des Geschäftsjahres geöffnet.',
}
