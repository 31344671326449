import {
  Button,
  ButtonProps,
  Flex,
  ThemeProvider,
} from '@nextbusiness/infinity-ui'
import { ReactComponent as UpgradeArrow } from 'assets/upgrade-arrow.svg'
import classNames from 'classnames'
import { useState } from 'react'
import useSubscription from '../subscription-context/useSubscription'
import { FeatureGateProps } from './FeatureGate'
import './FeatureGateButton.scss'
import FeatureUpgradeModal from './FeatureUpgradeModal'

type FeatureGateButtonProps = ButtonProps &
  Omit<FeatureGateProps, 'children'> & {
    alwaysEnableFeature?: boolean
  }

const FeatureGateButton = (props: FeatureGateButtonProps) => {
  const {
    requiredCapability,
    customFeatureDescription,
    customFeatureImage,
    customFeatureRequiredSubscription,
    ...buttonProps
  } = props

  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState<boolean>(false)
  const subscription = useSubscription()

  if (
    !subscription?.hasCapability(props.requiredCapability) &&
    !props.alwaysEnableFeature
  ) {
    return (
      <>
        <ThemeProvider primaryColor='lavender'>
          <Button
            {...buttonProps}
            onClick={() => setIsUpgradeModalOpen(true)}
            className={classNames(buttonProps.className, 'button-upgrade')}
          >
            <Flex gap={0.8} verticalAlignment='center'>
              {props.children} <UpgradeArrow className='upgrade-arrow' />
            </Flex>
          </Button>
        </ThemeProvider>
        <FeatureUpgradeModal
          isOpen={isUpgradeModalOpen}
          onDismiss={() => setIsUpgradeModalOpen(false)}
          requiredCapability={requiredCapability}
          customFeatureDescription={customFeatureDescription}
          customFeatureImage={customFeatureImage}
          customFeatureRequiredSubscription={customFeatureRequiredSubscription}
        />
      </>
    )
  }
  return <Button {...buttonProps} />
}

export default FeatureGateButton
