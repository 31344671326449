import { Dialog } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { Contact } from '../model/Contact'
import contactDialogStore from '../stores/ContactDialogStore'
import Contacts from './Contacts'
import './ContactsDialog.scss'

export type Mode = 'viewing' | 'editing' | 'creating'
export enum AbortIntent {
  CancelEdit,
  SelectOtherContact,
  ExitContacts,
  None,
}

export type ContactsDialogMode = 'standalone' | 'picker'

interface ContactsDialogProps {
  mode?: ContactsDialogMode
  onContactPicked?: (contact: Contact) => void
  onContactCreated?: (contact: Contact) => void
  retainState?: boolean
}

const ContactsDialog = (props: ContactsDialogProps) => {
  const { isContactsOpen, setIsContactsOpen, mode, abortEditing } =
    contactDialogStore

  const onContactPicked = (contact: Contact) => {
    setIsContactsOpen(false)
    if (props.onContactPicked) props.onContactPicked(contact)
  }

  return (
    <Dialog
      className='contacts-dialog'
      isOpen={isContactsOpen}
      dismissable
      onDismiss={() => {
        if (mode === 'creating' || mode === 'editing') {
          abortEditing(() => setIsContactsOpen(false), AbortIntent.CancelEdit)
        } else setIsContactsOpen(false)
      }}
    >
      <Contacts
        mode={props.mode}
        onContactPicked={onContactPicked}
        onContactCreated={props.onContactCreated}
        dismissable
        retainState={props.retainState}
      />
    </Dialog>
  )
}

export default observer(ContactsDialog)
