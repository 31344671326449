import { APIError, Finance } from '@nextbusiness/infinity-finance-api'
import { Text, useNotificationCenter } from '@nextbusiness/infinity-ui'
import * as Sentry from '@sentry/react'
import ConfirmDeletionModal from 'components/dialogs/ConfirmDeletionModal'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react'
import { IAccount } from 'model/Account'
import { useEffect } from 'react'
import { useRootStore } from 'stores/RootStoreContext'

interface AccountLiveMenuDisconnectModalProps {
  isOpen: boolean
  onDismiss: () => void
  currentAccount: IAccount
}

const AccountLiveMenuDisconnectModal = (
  props: AccountLiveMenuDisconnectModalProps
) => {
  const { accountStore } = useRootStore()
  const notificationCenter = useNotificationCenter()
  const newestAccount = accountStore.newestAccountWithNumber(
    props.currentAccount.accountNumber
  )

  const disconnectBankAccount = async () => {
    if (!newestAccount) return
    try {
      await Finance.Ledger.disconnectLiveAccounting(newestAccount.id)
      await accountStore.loadAccounts()
      MixpanelAnalytics.event('Bank account disconnected')
    } catch (error) {
      notificationCenter.addNotification({
        variant: 'error',
        title: 'Bankverbindung konnte nicht getrennt werden',
        children: (error as APIError).humanText('de'),
      })
      MixpanelAnalytics.event('Bank account disconnection failed')
      Sentry.captureException(error)
    }
  }

  useEffect(() => {
    if (props.isOpen) {
      MixpanelAnalytics.event('Bank account disconnection modal opened')
    }
  }, [props.isOpen])

  return (
    <ConfirmDeletionModal
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      title='Bankverbindung trennen'
      deleteActionCopy='Bankverbindung trennen'
      onDelete={disconnectBankAccount}
    >
      <Text>
        Möchtest du wirklich diese Bankverbindung vom Konto «
        {props.currentAccount.name}» trennen?
      </Text>
      <Text>
        Das Konto wird damit nicht mehr synchronisiert und du kannst keine neuen
        Zahlungen mehr auslösen.
      </Text>
      <Text variant='subtle'>
        Beachte, dass du auch in deinem E-Banking allenfalls die Berechtigung
        entfernen musst, wenn du den Zugriff von Infinity Finance vollständig
        entziehen möchtest.
      </Text>
    </ConfirmDeletionModal>
  )
}

export default observer(AccountLiveMenuDisconnectModal)
