import {
  Button,
  DateDisplayFormatOptions,
  Flex,
  Text,
} from '@nextbusiness/infinity-ui'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReportPrintHeader from 'reports/ReportPrintHeader'
import './IncomeStatement.print.scss'
import './IncomeStatement.scss'
import IncomeStatementContext from './IncomeStatementContext'
import IncomeStatementSegment from './IncomeStatementSegment'
import { ISegmentizedIncomeStatement } from './IncomeStatementUtil'

interface IncomeStatementProps {
  incomeStatement: ISegmentizedIncomeStatement
  fromDateCopy: Date
  toDateCopy: Date
  setHasScrolled?: (hasScrolled: boolean) => void
}

const IncomeStatement = (props: IncomeStatementProps) => {
  const incomeStatementRef = useRef<HTMLDivElement>(null)
  const history = useHistory()

  const [areAllExpanded, setAreAllExpanded] = useState<boolean>(true)
  const [areAllCollapsed, setAreAllCollapsed] = useState<boolean>(false)

  useEffect(() => {
    const incomeStatement = incomeStatementRef.current

    const handleScroll = () => {
      if (!incomeStatement || !props.setHasScrolled) return
      if (
        incomeStatement.scrollTop > 0 &&
        incomeStatement.scrollHeight > incomeStatement.clientHeight * 1.5
      )
        props.setHasScrolled(true)
      else if (incomeStatement.scrollTop === 0) props.setHasScrolled(false)
    }

    incomeStatement?.addEventListener('scroll', handleScroll)

    return () => {
      incomeStatement?.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (areAllExpanded) setAreAllCollapsed(false)
    if (areAllCollapsed) setAreAllExpanded(false)
  }, [areAllExpanded, areAllCollapsed])

  const fromDate = props.fromDateCopy.toLocaleString(
    'de-CH',
    DateDisplayFormatOptions
  )
  const toDate = props.toDateCopy.toLocaleString(
    'de-CH',
    DateDisplayFormatOptions
  )
  return (
    <IncomeStatementContext.Provider
      value={{
        areAllExpanded: areAllExpanded,
        setAreAllExpanded: (value: boolean) => setAreAllExpanded(value),
        areAllCollapsed: areAllCollapsed,
        setAreAllCollapsed: (value: boolean) => setAreAllCollapsed(value),
        history: history,
      }}
    >
      <div className='income-statement' ref={incomeStatementRef}>
        <ReportPrintHeader
          title={`Erfolgsrechnung von ${fromDate} bis ${toDate}`}
        />
        <div className='income-statement-layer top'>
          <Flex className='row top'>
            <Text className='name depth-0 top-row'>
              <Button
                className='expand-all-button'
                variant='quaternary'
                onClick={() =>
                  areAllExpanded
                    ? setAreAllCollapsed(true)
                    : setAreAllExpanded(true)
                }
              >
                <span className='expand-all-text'>
                  {areAllExpanded ? 'Alles zuklappen' : 'Alles aufklappen'}
                </span>
              </Button>
            </Text>
            <Text className='balance depth-0 top-row'>
              {`${fromDate}-${toDate}`}
            </Text>
          </Flex>
        </div>

        <IncomeStatementSegment
          segment={props.incomeStatement.operatingResult}
        />
        <IncomeStatementSegment segment={props.incomeStatement.personell} />
        <IncomeStatementSegment segment={props.incomeStatement.EBITDA} />
        <IncomeStatementSegment segment={props.incomeStatement.EBIT} />
        <IncomeStatementSegment segment={props.incomeStatement.EBT} />
        <IncomeStatementSegment segment={props.incomeStatement.netIncome} />
      </div>
    </IncomeStatementContext.Provider>
  )
}

export default IncomeStatement
