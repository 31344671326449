import NavigatableMagicSheet from 'components/magic-sheet/NavigatableMagicSheet'
import DocumentCreator from 'documents/document-creator/DocumentCreator'
import DocumentsHome from 'documents/DocumentsHome'
import CreateExpenditureView from 'documents/expenditures/CreateExpenditureView'
import FinancialClosingAssistant from 'documents/financial-closing/assistant/FinancialClosingAssistant'
import FinancialClosingOverview from 'documents/financial-closing/FinancialClosingOverview'
import ClosedFiscalYearBalanceSheet from 'documents/financial-closing/reports/ClosedFiscalYearBalanceSheet'
import ClosedFiscalYearIncomeStatement from 'documents/financial-closing/reports/ClosedFiscalYearIncomeStatement'
import AllInboxDocuments from 'documents/inbox-documents/AllInboxDocuments'
import DocumentView from 'documents/processed-documents/DocumentView'
import ProcessedDocumentsSheet from 'documents/processed-documents/ProcessedDocumentsSheet'
import VATPeriodsOverview from 'documents/vat-closing/overview/VATPeriodsOverview'
import VATReportPage from 'documents/vat-closing/report/VATReportPage'
import VATReportTransactionsPage from 'documents/vat-closing/transactions/VATReportTransactionsPage'

export enum DocumentsPagePaths {
  DocumentsHome = '/dokumente',
  FinancialClosingOverview = '/dokumente/jahresabschluss',
  FinancialClosingBalanceSheet1 = '/dokumente/jahresabschluss/schlussbilanz-1/:param',
  FinancialClosingBalanceSheet2 = '/dokumente/jahresabschluss/schlussbilanz-2/:param',
  FinancialClosingIncomeStatement = '/dokumente/jahresabschluss/erfolgsrechnung/:param',
  FinancialClosingAssistant = '/dokumente/jahresabschluss/assistent',
  VATPeriodsOverview = '/dokumente/mwst',
  VATReport = '/dokumente/mwst/periode/:param',
  VATReportTransactions = '/dokumente/mwst/periode/:param/transactions',
  DocumentDetailView = '/dokumente/detail/:param',
  DocumentUpload = '/dokumente/hochladen',
  AllInboxDocuments = '/dokumente/eingang',
  AllInboxDocumentDetailView = '/dokumente/eingang/detail/:param',
  CreateExpenditureFromInbox = '/dokumente/aufwand/:param',
  CreateExpenditure = '/dokumente/eingang/aufwand/:param',
  ProcessedDocuments = '/dokumente/erledigte',
  ProcessedDocumentsDetailView = '/dokumente/erledigte/detail/:param',
}

class DocumentsPage extends NavigatableMagicSheet {
  className = 'documents-page-stack'
  basePath = DocumentsPagePaths.DocumentsHome

  protected registerPaths(): void {
    this.registerView({
      url: DocumentsPagePaths.DocumentsHome,
      title: () => 'Dokumente',
      view: <DocumentsHome page={this} />,
    })
    this.registerView({
      url: DocumentsPagePaths.FinancialClosingOverview,
      title: () => 'Jahresabschluss',
      view: <FinancialClosingOverview page={this} />,
    })
    this.registerView({
      url: DocumentsPagePaths.FinancialClosingBalanceSheet1,
      title: () => 'Schlussbilanz 1',
      view: <ClosedFiscalYearBalanceSheet page={this} />,
    })
    this.registerView({
      url: DocumentsPagePaths.FinancialClosingBalanceSheet2,
      title: () => 'Schlussbilanz 2',
      view: <ClosedFiscalYearBalanceSheet page={this} afterProfitClose />,
    })
    this.registerView({
      url: DocumentsPagePaths.FinancialClosingIncomeStatement,
      title: () => 'Erfolgsrechnung',
      view: <ClosedFiscalYearIncomeStatement page={this} />,
    })
    this.registerView({
      url: DocumentsPagePaths.FinancialClosingAssistant,
      title: () => 'Jahresabschluss-Assistent',
      view: <FinancialClosingAssistant page={this} />,
    })
    this.registerView({
      url: DocumentsPagePaths.VATPeriodsOverview,
      title: () => 'Mehrwertsteuer',
      view: <VATPeriodsOverview page={this} />,
    })
    this.registerView({
      url: DocumentsPagePaths.VATReport,
      title: () => 'Mehrwertsteuerperiode',
      view: <VATReportPage page={this} />,
    })
    this.registerView({
      url: DocumentsPagePaths.VATReportTransactions,
      title: () => 'Transaktionen prüfen',
      view: <VATReportTransactionsPage page={this} />,
    })
    this.registerView({
      url: DocumentsPagePaths.DocumentDetailView,
      title: () => 'Detailansicht',
      view: <DocumentView page={this} />,
    })
    this.registerView({
      url: DocumentsPagePaths.DocumentUpload,
      title: () => 'Neue Belege',
      view: <DocumentCreator page={this} />,
    })
    this.registerView({
      url: DocumentsPagePaths.AllInboxDocuments,
      title: () => 'Alle neuen Belege',
      view: <AllInboxDocuments page={this} />,
    })
    this.registerView({
      url: DocumentsPagePaths.AllInboxDocumentDetailView,
      title: () => 'Alle neuen Belege – Detailansicht',
      view: <DocumentView page={this} />,
    })
    this.registerView({
      url: DocumentsPagePaths.CreateExpenditure,
      title: () => 'Neuer Aufwand',
      view: <CreateExpenditureView page={this} />,
    })
    this.registerView({
      url: DocumentsPagePaths.CreateExpenditureFromInbox,
      title: () => 'Neuer Aufwand aus Eingang',
      view: <CreateExpenditureView page={this} />,
    })
    this.registerView({
      url: DocumentsPagePaths.ProcessedDocuments,
      title: () => 'Erledigte Belege',
      view: <ProcessedDocumentsSheet page={this} />,
    })
    this.registerView({
      url: DocumentsPagePaths.ProcessedDocumentsDetailView,
      title: () => 'Erledigte Belege – Detailansicht',
      view: <DocumentView page={this} />,
    })
  }

  open(path: DocumentsPagePaths, param?: string) {
    if (param) return this.props.history.push(path.replace(':param', param))
    this.props.history.push(path)
  }
}

export default DocumentsPage
