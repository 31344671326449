import { Flex, Text } from '@nextbusiness/infinity-ui'
import VirtualAccount from 'components/transactions/VirtualAccount'
import { useAccountPageContext } from 'ledger/accounts/AccountPageContext'
import AccountSelect from 'ledger/accounts/account-select/AccountSelect'
import './MoveToDifferentAccountView.scss'

interface MoveToDifferentAccountProps {
  setNewAccountNumber: (newAccountNumber: number | undefined) => void
}

const MoveToDifferentAccount = (props: MoveToDifferentAccountProps) => {
  const { currentAccount } = useAccountPageContext()
  return (
    <>
      <Text>
        Wähle aus, in welches Konto die ausgewählten Transaktionen verschoben
        werden sollen.
      </Text>
      <Flex className='move-to-account-form' gap='tiny'>
        <div className='column'>
          <Text className='title-for-input-fields'>Aktuell</Text>
          <div className='current-account-preview'>
            <VirtualAccount
              name={currentAccount.name}
              colour={currentAccount.colour}
            />
          </div>
        </div>
        <div className='column'>
          <Text className='title-for-input-fields'>Verschieben nach</Text>
          <AccountSelect
            inputFieldProps={{ fullWidth: true }}
            allowAccountCreation
            currentAccountNumber={currentAccount.accountNumber}
            suggestions={[]}
            onChange={(accountNumber: number | undefined) =>
              props.setNewAccountNumber(accountNumber)
            }
          />
        </div>
      </Flex>
    </>
  )
}

export default MoveToDifferentAccount
