import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import interpolate from 'color-interpolate'
import TransactionAmount from 'components/transactions/TransactionAmount'
import { KeyboardEvent, MouseEvent, useState } from 'react'

export interface ProfitabilityVisualGroupPart {
  title: string
  amount: number
}

interface ProfitabilityVisualGroupProps {
  title: string
  sum: number
  preceedingAmount?: number
  groupAmount: number
  parts: ProfitabilityVisualGroupPart[]
  reverse?: boolean
}

const ProfitabilityVisualGroup = (props: ProfitabilityVisualGroupProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const colourmap = interpolate(
    !props.reverse
      ? ['#098C70', '#06AC6C', '#1EDC7F']
      : props.parts.length > 4
      ? ['#FF8C19', '#E1273E', '#FFBF19']
      : ['#FF8C19', '#E1273E']
  )

  const onGroupSelected = (e: MouseEvent | KeyboardEvent) => {
    e.stopPropagation()
    setIsExpanded(!isExpanded)
  }

  return (
    <div className={'profitability-group' + (isExpanded ? ' expanded' : '')}>
      <div
        className='profitability-group-header'
        role='rowheader'
        tabIndex={0}
        onClick={onGroupSelected}
        onKeyDown={(e) => {
          if (e.key === 'Enter') onGroupSelected(e)
        }}
      >
        <div className='depiction'>
          <Icon
            className={'icon' + (isExpanded ? ' expanded' : '')}
            icon='expand'
            size={IconSize.ExtraTiny}
          />
          <div className='title'>{props.title}</div>
        </div>
        <div className='visual'>
          <div className='visual-bar'>
            <div
              className='visual-bar-inner'
              style={{
                width: `${
                  (1 - Math.abs(props.preceedingAmount ?? 0) / props.sum) * 100
                }%`,
              }}
            >
              {(props.reverse ? [...props.parts].reverse() : props.parts).map(
                (part, index) => {
                  const yIndex = props.reverse
                    ? props.parts.length - index
                    : index + 1
                  const yTranslation = !isExpanded ? 0 : yIndex * 3.5 + 0.6
                  const colour =
                    props.reverse && index === 0
                      ? '#d4d7e0'
                      : colourmap(index / Math.max(props.parts.length - 1, 1))

                  return (
                    <div
                      key={index}
                      className='visual-bar-part'
                      style={{
                        width: `${Math.max(
                          (Math.abs(part.amount) / (props.groupAmount || 1)) *
                            100,
                          0.4
                        )}%`,
                        transform: `translateY(${yTranslation}rem)`,
                        backgroundColor: colour,
                      }}
                      title={part.title}
                    />
                  )
                }
              )}
            </div>
          </div>
        </div>
        <div className='amount'>
          <TransactionAmount amount={Math.abs(props.groupAmount)} />
        </div>
      </div>
      <div className='profitability-group-content'>
        {props.parts.map((part, index) => (
          <div
            className='profitability-group-row'
            key={`${part.title}-${index}`}
          >
            <div className='depiction'>
              <div className='title'>{part.title}</div>
            </div>
            <div className='visual'>
              <div className='visual-placeholder' />
            </div>
            <div className='amount'>
              <TransactionAmount amount={Math.abs(part.amount)} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProfitabilityVisualGroup
