import classNames from 'classnames'
import './ScrollWell.scss'

interface ScrollWellProps {
  className?: string
  children?: React.ReactNode
  fill?: boolean
}

const ScrollWell = (props: ScrollWellProps) => (
  <div
    className={classNames(
      'ui-scroll-well',
      props.fill && 'fill',
      props.className
    )}
  >
    {props.children}
  </div>
)

export default ScrollWell
