import { Flex, Heading, TypedKeyValueStore } from '@nextbusiness/infinity-ui'
import { IAccount } from 'model/Account'
import Utilities from 'utility/Utilities'
import AccountBalanceItem from './AccountBalanceItem'

const RECOMMENDED_ACCOUNTS = [
  1000, 1020, 1200, 1500, 1510, 1530, 1540, 2400, 2500, 2800, 2900, 2979,
]

interface AccountBalancesColumnProps {
  accountBalances: TypedKeyValueStore<number | null>
  onAccountBalanceChange: (
    accountNumber: number,
    balance: number | null
  ) => void
  title: string
  accounts: IAccount[]
  showAllAccounts?: boolean
  sum: number
}

const AccountBalancesColumn = (props: AccountBalancesColumnProps) => {
  return (
    <div className='accounts-column'>
      <Flex verticalAlignment='baseline'>
        <Heading type='h3' bareTop>
          {props.title}
        </Heading>
        <div className='sum'>
          {Utilities.centsToCHF(props.sum)} <small>CHF</small>
        </div>
      </Flex>
      <Flex className='accounts-list' direction='vertical' gap={0.2}>
        {props.accounts
          .filter((account) => {
            if (props.showAllAccounts) {
              return true
            }
            const balance = props.accountBalances[account.accountNumber]
            return (
              RECOMMENDED_ACCOUNTS.includes(account.accountNumber) ||
              (balance !== null && balance > 0)
            )
          })
          .map((account) => (
            <AccountBalanceItem
              account={account}
              balance={props.accountBalances[account.accountNumber]}
              onChange={(balance) =>
                props.onAccountBalanceChange(account.accountNumber, balance)
              }
              key={account.accountNumber}
            />
          ))}
      </Flex>
    </div>
  )
}

export default AccountBalancesColumn
