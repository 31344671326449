import ErrorScreen from 'base-components/layout/ErrorScreen'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'

interface VATReportErrorPageProps {
  onRetry: () => void
  onClose: () => void
  error?: Error
}

const VATReportErrorPage = (props: VATReportErrorPageProps) => (
  <MagicSheetPage>
    <ErrorScreen
      title='Bericht kann nicht geladen werden'
      onRetry={props.onRetry}
      onClose={props.onClose}
      defaultMessage='Beim Laden des Berichts ist ein Fehler aufgetreten. Bitte versuche es erneut.'
      error={props.error}
    />
  </MagicSheetPage>
)

export default VATReportErrorPage
