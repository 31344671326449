import { createContext } from 'react'

export type ContactRelatedItemsTab =
  | 'vendor-invoices'
  | 'customer-invoices'
  | 'quotes'
  | 'projects'

interface ContactPageStackContextType {
  openContact: (contactId: string, tab?: ContactRelatedItemsTab) => void
}

export const ContactPageStackContext =
  createContext<ContactPageStackContextType | null>(null)
