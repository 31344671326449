import { Checkbox } from '@nextbusiness/infinity-ui'
import CustomerInvoiceFactory from 'invoices/customer-invoices/editor/CustomerInvoiceFactory'
import { observer } from 'mobx-react'
import {
  ICustomerInvoiceColumn,
  INewCustomerInvoice,
} from 'model/CustomerInvoice'
import { useRootStore } from 'stores/RootStoreContext'

interface ColumnPickerProps {
  document: Partial<INewCustomerInvoice>
  registerEdit: (edit: Partial<INewCustomerInvoice>) => void
}

const COLUMN_OPTIONS: { columns: ICustomerInvoiceColumn[]; label: string }[] = [
  { columns: ['quantity', 'singleAmount'], label: 'Menge' },
  { columns: ['vatDebt'], label: 'MWST' },
  { columns: ['unit'], label: 'Einheit' },
  { columns: ['articleNumber'], label: 'Artikelnummer' },
]

const ColumnPicker = (props: ColumnPickerProps) => {
  const { preferencesStore } = useRootStore()
  return (
    <div>
      {COLUMN_OPTIONS.map(({ columns, label }) => {
        const isVATEnabled = preferencesStore.organisationPreferences?.VAT
        const isVATHidden =
          props.document.vatPresentationOptions?.hideVATInQuotes &&
          props.document.isQuote
        const showVATOption = isVATEnabled && !isVATHidden

        if (columns.includes('vatDebt') && !showVATOption) return null
        return (
          <Checkbox
            key={columns.join('-')}
            label={label}
            isChecked={columns.every((column) =>
              props.document.columns?.includes(column)
            )}
            onChange={() =>
              props.registerEdit({
                columns: toggleColumns(props.document, columns),
              })
            }
          />
        )
      })}
    </div>
  )
}

const toggleColumns = (
  currentDocument: Partial<INewCustomerInvoice>,
  columnsToToggle: ICustomerInvoiceColumn[]
) => {
  const enabledColumns =
    currentDocument.columns ?? CustomerInvoiceFactory.defaultColumns
  const [firstColumn] = columnsToToggle

  if (enabledColumns.includes(firstColumn)) {
    return enabledColumns.filter((column) => !columnsToToggle.includes(column))
  } else {
    return [...enabledColumns, ...columnsToToggle]
  }
}

export default observer(ColumnPicker)
