import { DatePicker } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import { useExpenditureCreateContext } from './ExpenditureCreateContext'

const ExpenditureDatePicker = () => {
  const { accountStore } = useRootStore()
  const expenditureCreateContext = useExpenditureCreateContext()

  const transactionDate = expenditureCreateContext.transaction.date
    ? new Date(expenditureCreateContext.transaction.date)
    : undefined

  const isDateMissing = !transactionDate
  const isDateOutsideFiscalYear =
    transactionDate && !accountStore.isDateWithinOpenFiscalYear(transactionDate)
  const hasError = isDateMissing || isDateOutsideFiscalYear

  return (
    <>
      <div className='expenditure-input-title'>Belegdatum</div>
      <div className='expenditure-input-field'>
        <DatePicker
          onChange={(date) =>
            expenditureCreateContext.updateTransaction({
              date: date?.getTime(),
            })
          }
          value={transactionDate}
          inputFieldProps={{
            fullWidth: true,
            hasError: expenditureCreateContext.shouldValidate && hasError,
            helperText: !expenditureCreateContext.shouldValidate
              ? undefined
              : isDateMissing
              ? 'Gültiges Datum ist erforderlich'
              : isDateOutsideFiscalYear
              ? 'Datum liegt ausserhalb der offenen Geschäftsjahre'
              : undefined,
          }}
        />
      </div>
    </>
  )
}

export default observer(ExpenditureDatePicker)
