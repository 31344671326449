import { ITransaction } from '@nextbusiness/infinity-finance-api'
import { Button } from '@nextbusiness/infinity-ui'

interface AttachmentShowButtonProps {
  transaction: ITransaction & { attachments: string[] }
  onClick: () => void
}

const AttachmentShowButton = (props: AttachmentShowButtonProps) => (
  <Button iconLeft='attach' onClick={props.onClick}>
    {props.transaction.attachments.length}
  </Button>
)

export default AttachmentShowButton
