import { APIError, Finance, Work } from '@nextbusiness/infinity-finance-api'
import {
  DatePicker,
  Dialog,
  FeedbackBanner,
  Flex,
  InputField,
  MoneyField,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react'
import { ProjectContext } from 'projects/project-view/ProjectView'
import Tabs, { Tab } from 'proto-ui-components/Tabs'
import { useContext, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import DateUtilities from 'utility/DateUtilites'
import './AddUnbilledWorkModal.scss'
import HourlyRateFields from './HourlyRateFields'

type BillingMethod = 'hourly-rate' | 'fixed-price' | 'non-billable'
const BILLING_METHOD_TABS: Tab<BillingMethod>[] = [
  {
    identifier: 'hourly-rate',
    title: 'Nach Stunden',
  },
  {
    identifier: 'fixed-price',
    title: 'Pauschal',
  },
  {
    identifier: 'non-billable',
    title: 'Nicht abrechenbar',
  },
]

interface AddUnbilledWorkModalProps {
  projectId: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const AddUnbilledWorkModal = (props: AddUnbilledWorkModalProps) => {
  const { projectStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const projectContext = useContext(ProjectContext)
  const [billingMethod, setBillingMethod] =
    useState<BillingMethod>('hourly-rate')
  const [date, setDate] = useState<Date | undefined>(
    DateUtilities.startOfDay(new Date())
  )
  const [description, setDescription] = useState<string>('')
  const [hours, setHours] = useState<number>()
  const [minutes, setMinutes] = useState<number>()
  const [hourlyRate, setHourlyRate] = useState<number | null>(null)
  const [fixedPrice, setFixedPrice] = useState<number | null>(null)
  const [workIsBeingAdded, setWorkIsBeingAdded] = useState<boolean>(false)

  const clearInputs = () => {
    setBillingMethod('hourly-rate')
    setDate(DateUtilities.startOfDay(new Date()))
    setDescription('')
    setHours(undefined)
    setMinutes(undefined)
    setHourlyRate(null)
    setFixedPrice(null)
    setWorkIsBeingAdded(false)
  }
  const onDismiss = () => {
    clearInputs()
    props.setIsOpen(false)
  }
  const addWork = async () => {
    setWorkIsBeingAdded(true)
    let work: Omit<Work, 'id'> = {
      date: date!.getTime(),
      description,
      wasBilled: false,
    }
    switch (billingMethod) {
      case 'hourly-rate':
        work = {
          ...work,
          hours: hours ?? 0,
          minutes: minutes ?? 0,
          rate: hourlyRate! * 100,
        } as Omit<Work, 'id'>
        break
      case 'fixed-price':
        work = {
          ...work,
          price: fixedPrice! * 100,
        } as Omit<Work, 'id'>
        break
      case 'non-billable':
        break
    }
    try {
      await Finance.Projects.addWork(work as Work, props.projectId)
      await projectContext?.refreshProject()
      MixpanelAnalytics.event('Project work added', {
        type: billingMethod,
      })
      projectStore.fetchAllProjects()
      onDismiss()
    } catch (error) {
      notificationCenter.addNotification({
        children: (error as APIError).humanText('de'),
      })
    } finally {
      setWorkIsBeingAdded(false)
    }
  }
  const isMissingRequiredFields = () => {
    switch (billingMethod) {
      case 'hourly-rate':
        return !date || !description || (!hours && !minutes) || !hourlyRate
      case 'fixed-price':
        return !date || !description || !fixedPrice
      case 'non-billable':
        return !date || !description
    }
  }
  const hasEnteredInvalidTime = () =>
    !!(
      billingMethod === 'hourly-rate' &&
      ((hours && hours < 0) ||
        (minutes && minutes < 0) ||
        (minutes && minutes > 59))
    )
  const handleEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key === 'Enter' &&
      !isMissingRequiredFields() &&
      !hasEnteredInvalidTime()
    ) {
      await addWork()
    }
  }

  return (
    <Dialog
      className='add-unbilled-work-modal'
      title='Neuer Eintrag'
      isOpen={props.isOpen}
      onDismiss={onDismiss}
      initialFocusIndex={4}
      actions={[
        {
          children: 'Abbrechen',
          onClick: onDismiss,
        },
        {
          children: 'Hinzufügen',
          onClick: addWork,
          variant: 'primary',
          isLoading: workIsBeingAdded,
          disabled: isMissingRequiredFields() || hasEnteredInvalidTime(),
        },
      ]}
    >
      <Tabs
        currentTabId={billingMethod}
        tabs={BILLING_METHOD_TABS}
        onTabSelected={(tab) => setBillingMethod(tab.identifier)}
      />
      <Flex className='input-row' gap='tiny'>
        <DatePicker value={date} onChange={(date) => setDate(date)} />
        <InputField
          className='description'
          placeholder='Beschreibung'
          value={description}
          onChange={(input) => setDescription(input)}
          onKeyDown={handleEnter}
        />
        {billingMethod === 'hourly-rate' && (
          <HourlyRateFields
            hours={hours}
            minutes={minutes}
            hourlyRate={hourlyRate}
            setHours={setHours}
            setMinutes={setMinutes}
            setHourlyRate={setHourlyRate}
            handleEnter={handleEnter}
            hasEnteredInvalidMinutes={hasEnteredInvalidTime()}
          />
        )}
        {billingMethod === 'fixed-price' && (
          <MoneyField
            value={fixedPrice}
            onChange={(input) => setFixedPrice(input)}
            onKeyDown={handleEnter}
          />
        )}
      </Flex>
      {hasEnteredInvalidTime() && (
        <FeedbackBanner variant='error'>
          Die Minuten müssen zwischen 0 und 59 liegen.
        </FeedbackBanner>
      )}
    </Dialog>
  )
}

export default observer(AddUnbilledWorkModal)
