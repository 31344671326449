import { InputFieldProps } from '@nextbusiness/infinity-ui'
import AccountSelect from 'ledger/accounts/account-select/AccountSelect'
import { ResolvedSetOfSuggestedAccounts } from 'ledger/accounts/data/AccountRules'

interface ExpenditureAccountSelectProps {
  title: string
  initialAccountNumber?: number
  currentAccountNumber?: number
  onChange: (accountNumber: number) => void
  suggestions: ResolvedSetOfSuggestedAccounts
  inputFieldProps?: Partial<InputFieldProps>
}

const ExpenditureAccountSelect = (props: ExpenditureAccountSelectProps) => (
  <>
    <div className='expenditure-input-title'>{props.title}</div>
    <div className='expenditure-input-field'>
      <AccountSelect
        initialAccountNumber={props.initialAccountNumber}
        currentAccountNumber={props.currentAccountNumber}
        onChange={(accountNumber) =>
          accountNumber && props.onChange(accountNumber)
        }
        suggestions={props.suggestions}
        onlyAllowSuggestedAccounts
        fullWidth
        inputFieldProps={props.inputFieldProps}
      />
    </div>
  </>
)

export default ExpenditureAccountSelect
