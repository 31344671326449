import { RouterHistory } from '@nextbusiness/infinity-ui/dist/layout/header/HeaderNavigationTabs'
import Fuse, { IFuseOptions } from 'fuse.js'
import EverythingSearchProviderResult from '../EverythingSearchProviderResult'
import EverythingSearchResultsProvider from '../EverythingSearchResultsProvider'
import { NAVIGATION_ITEMS, NavigationItem } from './NavigationSearchItems'

class NavigationSearchProvider implements EverythingSearchResultsProvider {
  providerName = 'Navigation'

  private resultForNavigationItem(
    item: NavigationItem
  ): EverythingSearchProviderResult {
    return {
      id: item.href,
      icon: item.icon ?? 'arrow-right',
      title: item.title,
      type: 'navigation',
    }
  }

  resultsForQuery(query: string): Promise<EverythingSearchProviderResult[]> {
    const options: IFuseOptions<NavigationItem> = {
      threshold: 0.5,
      keys: ['title', 'relatedTerms'],
    }
    const fuse = new Fuse(NAVIGATION_ITEMS, options)
    const results = fuse.search(query)

    return Promise.resolve(
      results.map((result) => this.resultForNavigationItem(result.item))
    )
  }

  onResultSelected(
    result: EverythingSearchProviderResult,
    history: RouterHistory
  ) {
    history.push(result.id)
  }
}

export default NavigationSearchProvider
