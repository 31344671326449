import { Button, Flyout, MenuItem, MenuItems } from '@nextbusiness/infinity-ui'
import { ReactComponent as WaffleDark } from 'assets/home-waffle.svg'
import { EverythingMenuContext } from 'components/everything-menu/EverythingMenuProvider'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import StringUtilities from 'utility/StringUtilities'
import DownloadAccountSheetDialog from '../reports/DownloadAccountSheetDialog'
import DownloadJournalDialog from '../reports/DownloadJournalDialog'

interface AccountWaffleMenuProps {
  openChartOfAccountsPage: () => void
}

const AccountWaffleMenu = (props: AccountWaffleMenuProps) => {
  const { accountStore, ledgerStore } = useRootStore()
  const everythingMenuContext = useContext(EverythingMenuContext)

  const [isWaffleMenuOpen, setIsWaffleMenuOpen] = useState<boolean>(false)
  const [showDownloadJournalDialog, setShowDownloadJournalDialog] =
    useState<boolean>(false)
  const [showAccountSheetDialog, setShowAccountSheetDialog] =
    useState<boolean>(false)

  const recents: MenuItem[] = useMemo(() => {
    const recentAccounts = ledgerStore.recentAccountNumbers.filter(
      (accountNumber) => accountNumber !== ledgerStore.currentRootView
    )
    return recentAccounts.map((accountNumber): MenuItem => {
      const title =
        accountStore.find(accountNumber)?.name ?? `Konto ${accountNumber}`
      return {
        text: StringUtilities.truncate(title, 18),
        onClick: () => {
          MixpanelAnalytics.event('Account opened - from recents')
          ledgerStore.setCurrentRootView(accountNumber)
        },
        icon: 'time-machine',
      }
    })
  }, [
    accountStore.allAccounts,
    ledgerStore.recentAccountNumbers,
    ledgerStore.currentRootView,
  ])

  useEffect(() => {
    if (isWaffleMenuOpen) {
      MixpanelAnalytics.event('Account navigator opened')
    }
  }, [isWaffleMenuOpen])

  return (
    <Flyout
      isActive={isWaffleMenuOpen}
      setIsActive={setIsWaffleMenuOpen}
      trigger={
        <Button
          className='account-menu-waffle'
          variant='quaternary'
          onClick={() => setIsWaffleMenuOpen(!isWaffleMenuOpen)}
          tooltip='Konto wechseln'
        >
          <WaffleDark />
        </Button>
      }
      triggerIsButton
    >
      <MenuItems
        groups={[
          ...(recents.length > 0
            ? [
                {
                  title: 'Zuletzt verwendet',
                  buttons: recents,
                },
              ]
            : []),
          {
            title: 'Andere Konten',
            buttons: [
              {
                text: 'Konto suchen',
                onClick: () => {
                  MixpanelAnalytics.event('Account navigator - Search opened')
                  everythingMenuContext?.openMenu()
                },
                icon: 'search',
              },
              {
                text: 'Alle Konten',
                onClick: () => props.openChartOfAccountsPage(),
                icon: 'more',
              },
            ],
          },
          {
            title: 'Export',
            buttons: [
              {
                text: 'Kontenblatt',
                onClick: () => setShowAccountSheetDialog(true),
                icon: 'report-file',
              },
              {
                text: 'Buchungsjournal',
                onClick: () => setShowDownloadJournalDialog(true),
                icon: 'journal',
              },
            ],
          },
        ]}
        closeMenu={() => setIsWaffleMenuOpen(false)}
      />
      <DownloadJournalDialog
        isOpen={showDownloadJournalDialog}
        onDismiss={() => setShowDownloadJournalDialog(false)}
      />
      <DownloadAccountSheetDialog
        isOpen={showAccountSheetDialog}
        onDismiss={() => setShowAccountSheetDialog(false)}
      />
    </Flyout>
  )
}

export default observer(AccountWaffleMenu)
