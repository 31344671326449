import { Flex, Text } from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'
import { DateTime, Interval } from 'luxon'
import { QuoteStatus } from 'model/CustomerInvoice'
import './QuoteStatusBox.scss'

interface QuoteStatusBoxProps {
  daysSinceOpened: number
  quoteStatus?: QuoteStatus
  signingPerson?: string
  signedAt?: string
  invoicesFromQuote?: string[]
}

const QuoteStatusBox = (props: QuoteStatusBoxProps) => {
  const daysSinceOpenedText = () => {
    if (Number.isNaN(props.daysSinceOpened)) return 'Offerte wurde gestellt.'
    switch (props.daysSinceOpened) {
      case 0:
        return 'Offerte wurde heute gestellt.'
      case 1:
        return 'Offerte wurde gestern gestellt.'
      default:
        return `Offerte wurde vor ${props.daysSinceOpened} Tagen gestellt.`
    }
  }
  if (props.invoicesFromQuote?.length) {
    return (
      <Flex className='quote-status-box accepted'>
        <Icon icon='paid-bill' size={22} tint='teal' />
        <Text type='inline'>Offerte wurde in Rechnung gestellt.</Text>
      </Flex>
    )
  }

  if (
    props.quoteStatus === QuoteStatus.Accepted &&
    props.signingPerson &&
    props.signedAt
  ) {
    const signedAt = DateTime.fromISO(props.signedAt)
    const daysSinceSigned =
      Interval.fromDateTimes(signedAt, new Date()).count('days') - 1
    const daysSinceSignedText = `vor ${daysSinceSigned} Tag${
      daysSinceSigned > 1 ? 'en' : ''
    }`

    return (
      <Flex className='quote-status-box accepted'>
        <Icon icon='success' size={22} tint='teal' />
        <Text type='inline'>
          Offerte wurde {daysSinceSigned === 0 ? 'heute' : daysSinceSignedText}{' '}
          von {props.signingPerson} akzeptiert.
        </Text>
      </Flex>
    )
  }

  if (props.quoteStatus === QuoteStatus.Accepted)
    return (
      <Flex className='quote-status-box accepted'>
        <Icon icon='success' size={22} tint='teal' />
        <Text type='inline'>Offerte wurde akzeptiert.</Text>
      </Flex>
    )

  if (props.quoteStatus === QuoteStatus.Rejected)
    return (
      <Flex className='quote-status-box rejected'>
        <Icon icon='error' size={22} tint='#BF0D23' />
        <Text type='inline'>Offerte wurde abgelehnt.</Text>
      </Flex>
    )

  return (
    <Flex className='quote-status-box'>
      <Icon icon='waiting' size={22} tint='default' />
      <Text type='inline'>{daysSinceOpenedText()}</Text>
    </Flex>
  )
}

export default QuoteStatusBox
