import {
  Dialog,
  Flex,
  InputField,
  MoneyField,
  RadioGroup,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import AccountSelect from 'ledger/accounts/account-select/AccountSelect'
import { observer } from 'mobx-react'
import Depreciation, {
  DegressiveDepreciation,
  LinearDepreciation,
} from 'model/financial-close/Depreciation'
import { useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import './CreateClosingEntityModal.scss'

interface CreateDepreciationModalProps {
  isOpen: boolean
  onDismiss: () => void
}

const CreateDepreciationModal = (props: CreateDepreciationModalProps) => {
  const { financialCloseStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [isSaving, setIsSaving] = useState<boolean>(false)

  const [percentageAmount, setPercentageAmount] = useState<number>()
  const [fixedAmount, setFixedAmount] = useState<number>()
  const [description, setDescription] = useState<string>()
  const [contraAccount, setContraAccount] = useState<number>()
  const [type, setType] = useState<'linear' | 'degressive'>('linear')

  const isIncomplete = () =>
    (type === 'linear' && !fixedAmount) ||
    (type === 'degressive' && !percentageAmount) ||
    !contraAccount

  const createDepreciation = async () => {
    if (isIncomplete()) return
    setIsSaving(true)
    try {
      let depreciation: Depreciation
      if (type === 'linear') {
        const linearDepreciation: LinearDepreciation = {
          type: 'linear',
          absoluteAmount: Math.round(fixedAmount! * 100),
          affectedAccount: contraAccount!,
          description,
        }
        depreciation = linearDepreciation
      } else {
        const degressiveDepreciation: DegressiveDepreciation = {
          type: 'degressive',
          percentageAmount: percentageAmount! / 100,
          affectedAccount: contraAccount!,
          description,
        }
        depreciation = degressiveDepreciation
      }
      const depreciations: Depreciation[] = [
        ...(financialCloseStore.financialCloseSettings?.depreciations ?? []),
        depreciation,
      ]
      await financialCloseStore.update({ depreciations })
      props.onDismiss()
    } catch (error) {
      notificationCenter.addNotification({
        variant: 'error',
        title: 'Fehler beim Erstellen der Abschreibung',
        children:
          (error as Error).message ??
          'Es ist ein unbekannter Fehler aufgetreten.',
      })
    } finally {
      setIsSaving(false)
    }
  }

  const resetFields = () => {
    setPercentageAmount(undefined)
    setFixedAmount(undefined)
    setContraAccount(undefined)
    setDescription('')
    setType('linear')
  }

  useEffect(() => {
    if (props.isOpen) resetFields()
  }, [props.isOpen])

  return (
    <Dialog
      className='create-depreciation-modal create-closing-entity-modal'
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      title='Neue Abschreibung'
      actions={[
        {
          children: 'Abbrechen',
          onClick: () => props.onDismiss(),
        },
        {
          children: 'Erstellen',
          variant: 'primary',
          isLoading: isSaving,
          disabled: isIncomplete(),
          onClick: () => createDepreciation(),
        },
      ]}
    >
      <Text>Welche Art von Abschreibung möchtest du erstellen?</Text>
      <div className='depreciation-type'>
        <RadioGroup
          value={type}
          onChange={(type) => setType(type)}
          options={[
            { label: 'Um einen fixen Betrag abschreiben', value: 'linear' },
            { label: 'Um einen Prozentsatz abschreiben', value: 'degressive' },
          ]}
        />
      </div>
      <div className='entity-fields-well'>
        <div className='entity-fields'>
          <Flex verticalAlignment='center'>
            <InputField
              placeholder='Beschreibung (optional)'
              value={description ?? ''}
              onChange={(text) => setDescription(text)}
              fullWidth
            />
            <AccountSelect
              currentAccountNumber={6800}
              suggestions={[
                { title: 'Anlagevermögen', accounts: [[1400, 1999]] },
              ]}
              onChange={(accountNumber) => setContraAccount(accountNumber)}
              inputFieldProps={{
                placeholder: 'Abzuschreibendes Objekt',
                fullWidth: true,
              }}
              onlyAllowSuggestedAccounts
            />
            {type === 'linear' && (
              <MoneyField
                value={fixedAmount ?? null}
                placeholder='Betrag'
                onChange={(updatedAmount) =>
                  setFixedAmount(updatedAmount ?? undefined)
                }
              />
            )}
            {type === 'degressive' && (
              <MoneyField
                currency='%'
                value={percentageAmount ?? null}
                placeholder='Prozentsatz'
                onChange={(updatedAmount) =>
                  setPercentageAmount(updatedAmount ?? undefined)
                }
              />
            )}
          </Flex>
        </div>
      </div>
    </Dialog>
  )
}

export default observer(CreateDepreciationModal)
