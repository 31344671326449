import {
  Button,
  Flyout,
  MenuItem,
  MenuItemGroup,
  MenuItems,
} from '@nextbusiness/infinity-ui'
import { IconItem } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import './MoreMenu.scss'

interface MoreMenuProps {
  className?: string
  actions: MenuItemGroup[]
  footerActions?: MenuItem[]
  iconOnly?: boolean
  showIndicator?: boolean
  customIcon?: IconItem
  customButtonText?: string
}

const MoreMenu = (props: MoreMenuProps) => {
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState<boolean>(false)

  const onTriggerClick = () => setIsMoreMenuOpen(!isMoreMenuOpen)

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') setIsMoreMenuOpen(false)
    }
    if (isMoreMenuOpen) {
      document.addEventListener('keydown', handleEscape)
    }
    return () => {
      document.removeEventListener('keydown', handleEscape)
    }
  }, [isMoreMenuOpen])

  return (
    <Flyout
      className={classNames('more-menu', props.className)}
      isActive={isMoreMenuOpen}
      setIsActive={setIsMoreMenuOpen}
      triggerIsButton
      trigger={
        props.iconOnly ? (
          <>
            <Button
              iconOnly={props.customIcon ?? 'more'}
              onClick={onTriggerClick}
            />
            {props.showIndicator && <div className='indicator' />}
          </>
        ) : (
          <>
            <Button
              iconLeft={props.customIcon ?? 'view-more'}
              onClick={onTriggerClick}
            >
              {props.customButtonText ?? 'Mehr'}
            </Button>
            {props.showIndicator && <div className='indicator' />}
          </>
        )
      }
    >
      <MenuItems
        groups={props.actions}
        footerButtons={props.footerActions}
        closeMenu={() => setIsMoreMenuOpen(false)}
      />
    </Flyout>
  )
}

export default MoreMenu
