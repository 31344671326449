import {
  ButtonProps,
  Flyout,
  FlyoutProps,
  InputFieldProps,
} from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import React, { Component, Key } from 'react'
import StringUtilities from 'utility/StringUtilities'
import { ResourceOptionProps } from './ResourceOption'
import './ResourceSelect.scss'
import ResourceSelectAction from './ResourceSelectAction'
import ResourceSelectInput from './ResourceSelectInput'
import ResourceSelectSegment from './ResourceSelectSegment'

export interface ResourceSelectOption<ValueType, ItemType> {
  /** The value of this option. Must be unique. */
  value: ValueType
  /** The original item associated with this option. Can be any object. */
  item: ItemType
}

export interface ResourceSelectSection<ValueType, ItemType> {
  /** The displayed title of the section. */
  title: string
  /** The selectable options presented within this section. */
  options: ResourceSelectOption<ValueType, ItemType>[]
  /** If true, the section is presented with a special highlight and a star icon. */
  isSuggestedSection?: boolean
  dividerAbove?: boolean
}

interface ResourceSelectProps<ValueType, ItemType> {
  /** Optional content that is shown at the top of the flyout. */
  flyoutHeaderContent?: React.ReactNode
  /** The sections with their options that are currently presented as choices. */
  sections: ResourceSelectSection<ValueType, ItemType>[]
  /** The currently selected value (corresponding to the unique value of an option), if set. */
  value: ValueType | undefined
  /** Called when the user has selected a new value or nothing using the resource select. */
  onChange: (selectedValue: ValueType | undefined) => void
  /** If true, the value is reset as soon as the query doesn't match the selected value anymore. */
  resetOnMismatchingQuery?: boolean
  /** The placeholder text that is shown when no value is selected. */
  placeholderText: string
  /** Function used to extract the text that will be used for search from any given option item. */
  searchableTextForItem: (item: ItemType) => string
  /** Function used to determine the text visible in the input field for the selected option. */
  displayTextForCurrentItem?: (value: ItemType) => string
  /** Function used to render an option item into a `ResourceOption`. */
  optionForItem: (
    item: ItemType,
    defaultProps: ResourceOptionProps<ValueType, ItemType>
  ) => React.ReactNode
  /** Optional function used to render the left accessory of the currently selected item in the select input. */
  leftAccessory?: (selectedItem?: ItemType) => React.ReactNode
  /** If true, the expand icon is shown on the right in the resource select input. */
  displayExpandable?: boolean
  /** Optional list of searchable items in case more items should be searchable than are displayed at a time. */
  searchableItems?: ResourceSelectOption<ValueType, ItemType>[]
  /** Optional function to override the default search and rank behaviour. */
  customSearchAndRank?: (
    items: ResourceSelectOption<ValueType, ItemType>[],
    query: string
  ) => ResourceSelectOption<ValueType, ItemType>[]
  /** Optional function to override the default matcher to determine if a user has typed in a matching option. */
  customEnteredTextMatcher?: (item: ItemType, enteredText: string) => boolean
  /** Class name attached to the resource select's outermost wrapper. */
  className?: string
  /** Any props that should be passed to the input field in the resource select. */
  inputFieldProps?: Partial<InputFieldProps>
  /** If true, only sections marked as suggested will be shown at first (except in search results). */
  onlyDisplaySuggestedSections?: boolean
  /** Action menu items shown on the bottom of the resource select flyout. */
  actions?: ButtonProps[]
  /** Optional function that is called when the flyout is opened or closed. */
  onFlyoutActivation?: (isActive?: boolean) => void
  /** Any props that should be passed to the flyout in the resource select.
   *  The following props are managed internally and cannot be overridden:
   * - `trigger`
   * - `isActive`
   * - `setIsActive`
   * - `className` (use the `className` prop of the resource select instead)
   */
  flyoutProps?: Partial<FlyoutProps>
}

interface ResourceSelectState<ValueType> {
  isActive: boolean
  selectedValue?: ValueType
  enteredQuery: string
  hasSearched: boolean
  activeOptionIndex?: number
  shouldIgnoreMouse: boolean
  hasScrolled: boolean
}

class ResourceSelect<ValueType, ItemType> extends Component<
  ResourceSelectProps<ValueType, ItemType>,
  ResourceSelectState<ValueType>
> {
  constructor(props: ResourceSelectProps<ValueType, ItemType>) {
    super(props)
    this.state = {
      isActive: false,
      selectedValue: props.value,
      enteredQuery: this.textForValue(props.value),
      hasSearched: false,
      shouldIgnoreMouse: false,
      hasScrolled: false,
    }
  }

  public componentDidMount() {
    window.addEventListener('keydown', this.handleKeyboardEvent)
  }

  public componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyboardEvent)

    if (this.state.shouldIgnoreMouse)
      window.removeEventListener('mousemove', this.onMouseActivity)
  }

  public componentDidUpdate(
    previousProps: ResourceSelectProps<ValueType, ItemType>
  ) {
    if (previousProps.value !== this.props.value) {
      this.setState({
        selectedValue: this.props.value,
        enteredQuery: this.textForValue(this.props.value),
        hasSearched: false,
      })
    }
  }

  /**
   * Returns the display text for the option with the given value.
   */
  private textForValue = (value: ValueType | undefined) => {
    const item = this.itemForValue(value)
    if (!item) return ''
    return (
      this.props.displayTextForCurrentItem?.(item) ??
      this.props.searchableTextForItem(item)
    )
  }

  /**
   * Returns the associated item for the option with the given value.
   */
  private itemForValue = (value: ValueType | undefined) =>
    this.allOptions.find((option) => value === option.value)?.item ??
    this.props.searchableItems?.find((option) => value === option.value)?.item

  /**
   * Returns the associated item for the currently selected option.
   */
  private get selectedItem() {
    return this.itemForValue(this.props.value)
  }

  /**
   * Returns all options that are available in the resource select across all sections.
   */
  private get allOptions() {
    return this.props.sections.map((section) => section.options).flat()
  }

  /**
   * Performs a ranked search for the given options and the currently entered query,
   * returning the matching options in the order of their relevance.
   */
  private performRankedSearch = (
    options: ResourceSelectOption<ValueType, ItemType>[] | undefined
  ) => {
    if (!options) return []
    if (this.props.customSearchAndRank)
      return this.props.customSearchAndRank(options, this.state.enteredQuery)

    const searchValue = this.state.enteredQuery
    const searchResults = new Set(
      StringUtilities.searchDataByValue(
        options.map((option) => this.props.searchableTextForItem(option.item)),
        searchValue
      )
    )
    const searchedItems = Array.from(searchResults).map(
      (itemText) =>
        options.find(
          (option) => this.props.searchableTextForItem(option.item) === itemText
        )!
    )
    return searchedItems
  }

  /**
   * True, if the resource select is currently being filtered by a search query.
   */
  private get shouldSearch() {
    return !!this.state.enteredQuery.trim() && this.state.hasSearched
  }

  /**
   * Returns the currently available options that should be displayed in the resource select.
   */
  private get displayedOptions() {
    if (this.shouldSearch) {
      return this.performRankedSearch(
        this.props.searchableItems ?? this.allOptions
      )
    } else {
      return this.allOptions
    }
  }

  /**
   * Returns the number of options that were rendered up to (excluding) the first option of
   * the section at the given index.
   */
  private numberOfOptionsBeforeSection = (atIndex: number) => {
    let numberOfOptions = 0

    const passedSections = this.props.sections.slice(0, atIndex)
    passedSections.forEach((section) => {
      numberOfOptions += section.options.length
    })
    return numberOfOptions
  }

  /**
   * Selects the option at the given index.
   */
  private selectOption = (
    option: ResourceSelectOption<ValueType, ItemType>,
    index: number | undefined
  ) =>
    this.setState(
      {
        enteredQuery: this.textForValue(option.value),
        selectedValue: option.value,
        activeOptionIndex: index,
        isActive: false,
        hasSearched: false,
      },
      () => {
        this.props.onChange(option.value)
      }
    )

  /**
   * Handles a query change in the input field by the user.
   */
  private onInputFieldChange = (input: string) => {
    const options = this.props.searchableItems ?? this.allOptions
    const enteredOption = options.find((option) => {
      if (this.props.customEnteredTextMatcher) {
        return this.props.customEnteredTextMatcher(option.item, input)
      } else {
        const searchableText = this.props.searchableTextForItem(option.item)
        return searchableText.toLowerCase() === input.toLowerCase()
      }
    })
    this.setState(
      {
        isActive: true,
        activeOptionIndex: this.displayedOptions.length > 0 ? 0 : undefined,
        hasSearched: true,
        selectedValue: enteredOption?.value ?? undefined,
        enteredQuery: enteredOption
          ? this.textForValue(enteredOption.value)
          : input,
      },
      () => {
        ;(enteredOption || this.props.resetOnMismatchingQuery) &&
          this.props.onChange(enteredOption?.value)
      }
    )
  }

  /**
   * Handles a key press by the user while the resource select is active.
   */
  private handleKeyboardEvent = (event: KeyboardEvent) => {
    if (!this.state.isActive) return
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault()
        return this.onArrowUp()
      case 'ArrowDown':
        event.preventDefault()
        return this.onArrowDown()
      case 'Enter':
        return this.onEnterPressed()
    }
  }

  /**
   * Handles an arrow up navigation, selecting the option above the currently active one.
   */
  private onArrowUp = () => {
    this.setState((prevState) => {
      const activatingOption = prevState.activeOptionIndex
        ? prevState.activeOptionIndex - 1
        : this.displayedOptions.length - 1

      return { activeOptionIndex: activatingOption }
    }, this.onArrowNavigationPerformed)
  }

  /**
   * Handles an arrow down navigation, selecting the option below the currently active one.
   */
  private onArrowDown = () => {
    this.setState((prevState) => {
      const activatingOption =
        prevState.activeOptionIndex !== undefined &&
        prevState.activeOptionIndex !== this.displayedOptions.length - 1
          ? prevState.activeOptionIndex + 1
          : 0

      return { activeOptionIndex: activatingOption }
    }, this.onArrowNavigationPerformed)
  }

  private onMouseActivity = () => {
    this.setState({ shouldIgnoreMouse: false }, () =>
      window.removeEventListener('mousemove', this.onMouseActivity)
    )
  }

  private scrollIntoView = (optionWithValue: ValueType | undefined) => {
    this.state.isActive &&
      optionWithValue !== undefined &&
      document
        .querySelector(`.resource-option-button[data-id="${optionWithValue}"]`)
        ?.scrollIntoView({
          block: 'nearest',
        })
  }

  /**
   * Scrolls to the active option after an arrow navigation was performed.
   */
  private onArrowNavigationPerformed = () => {
    if (this.state.activeOptionIndex === undefined) return

    this.setState({ shouldIgnoreMouse: true }, () => {
      if (this.state.activeOptionIndex !== undefined) {
        const activeOption = this.displayedOptions[this.state.activeOptionIndex]
        this.scrollIntoView(activeOption.value)
      }
      window.addEventListener('mousemove', this.onMouseActivity)
    })
  }

  /**
   * Handles an enter press by the user, selecting the currently active option.
   */
  private onEnterPressed = () => {
    if (this.state.activeOptionIndex === undefined) return
    const correspondingOption =
      this.displayedOptions[this.state.activeOptionIndex]

    if (!correspondingOption) return
    this.selectOption(correspondingOption, this.state.activeOptionIndex)
  }

  private setActiveOptionIndexByMouse = (index: number | undefined) => {
    if (!this.state.shouldIgnoreMouse)
      this.setState({ activeOptionIndex: index })
  }

  private handleFlyoutActivation = (isActive: boolean) => {
    if (this.state.isActive !== isActive) {
      const selectedOptionIndex = this.displayedOptions.findIndex(
        (option) => option.value === this.state.selectedValue
      )
      this.setState(
        {
          isActive,
          activeOptionIndex:
            selectedOptionIndex !== -1 ? selectedOptionIndex : 0,
        },
        () => {
          setTimeout(() => this.scrollIntoView(this.state.selectedValue), 5)
        }
      )
      this.props.onFlyoutActivation?.(isActive)
    }
  }

  private renderHeaderContent = () => {
    const shouldDisplayHeaderContent =
      !this.shouldSearch && this.props.flyoutHeaderContent
    if (!shouldDisplayHeaderContent) return null
    return (
      <div
        className={classNames('flyout-header-content', {
          'has-scrolled': this.state.hasScrolled,
        })}
      >
        {this.props.flyoutHeaderContent}
      </div>
    )
  }

  /**
   * Renders the current resource select list.
   */
  private renderList = () => {
    return (
      <div
        className='resource-list'
        onScroll={(e) => {
          const scrollTop = (e.nativeEvent.target as HTMLDivElement).scrollTop
          this.setState({ hasScrolled: scrollTop !== 0 })
        }}
      >
        {!this.shouldSearch
          ? this.props.sections.map((segment, segmentIndex) =>
              this.renderSection(segment, segmentIndex)
            )
          : this.displayedOptions.map((option, index) =>
              this.renderOption(option, index)
            )}
      </div>
    )
  }

  /**
   * Renders a section in the resource select and its options.
   */
  private renderSection = (
    section: ResourceSelectSection<ValueType, ItemType>,
    sectionIndex: number
  ) => {
    const isHidden =
      this.props.onlyDisplaySuggestedSections && !section.isSuggestedSection

    if (isHidden) return null
    return (
      <ResourceSelectSegment
        key={sectionIndex}
        section={section}
        sectionIndex={sectionIndex}
        renderOption={this.renderOption}
      />
    )
  }

  /**
   * Renders a single option in the resource select.
   */
  private renderOption = (
    option: ResourceSelectOption<ValueType, ItemType>,
    optionIndex: number,
    sectionIndex = 0
  ) => {
    const indexInFullList =
      optionIndex + this.numberOfOptionsBeforeSection(sectionIndex)

    return this.props.optionForItem(option.item, {
      key: (option.value as Key) + indexInFullList.toString(),
      index: indexInFullList,
      isActive: this.state.isActive,
      isSelected: this.props.value === option.value,
      activeOption: this.state.activeOptionIndex,
      enteredValue: this.state.enteredQuery,
      item: option.item,
      value: option.value,
      searchableText: this.props.searchableTextForItem(option.item),
      onClick: () => this.selectOption(option, optionIndex),
      hasSearched: this.state.hasSearched,
      onMouseEnter: () => this.setActiveOptionIndexByMouse(indexInFullList),
      onMouseLeave: () => this.setActiveOptionIndexByMouse(undefined),
    })
  }

  render() {
    return (
      <Flyout
        {...this.props.flyoutProps}
        className={classNames('resource-select', this.props.className)}
        isActive={this.state.isActive}
        setIsActive={this.handleFlyoutActivation}
        trigger={
          <ResourceSelectInput
            query={this.state.enteredQuery}
            onQueryChange={this.onInputFieldChange}
            placeholderText={this.props.placeholderText}
            inputFieldProps={this.props.inputFieldProps}
            leftAccessory={this.props.leftAccessory?.(this.selectedItem)}
            displayExpandable={this.props.displayExpandable}
            isActive={this.state.isActive}
            closeFlyout={() =>
              this.setState({
                isActive: false,
                activeOptionIndex: 0,
              })
            }
          />
        }
      >
        {this.renderHeaderContent()}
        {this.renderList()}
        {this.props.actions && <div className='divider' />}
        {this.props.actions?.map((action, index) => (
          <ResourceSelectAction
            {...action}
            key={action.children?.toString() + index.toString()}
            closeFlyout={() => this.setState({ isActive: false })}
          />
        ))}
      </Flyout>
    )
  }
}

export default ResourceSelect
