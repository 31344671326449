import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export type VendorInvoiceStatusFilter = 'paid' | 'unpaid'

interface VendorInvoiceFilter {
  creditor?: string | null
  status?: VendorInvoiceStatusFilter | null
  openingMonth?: string | null
  attachments?: boolean | null
}

type VendorInvoicesFilterContextType = {
  activeFilter: VendorInvoiceFilter
  editFilter: (changes: Partial<VendorInvoiceFilter>) => void
  resetFilter: () => void
}

const VendorInvoicesFilterContext =
  createContext<VendorInvoicesFilterContextType | null>(null)

export const VendorInvoicesFilterProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [currentFilter, setCurrentFilter] = useState<VendorInvoiceFilter>({})
  const editFilter = useCallback(
    (changes: Partial<VendorInvoiceFilter>) =>
      setCurrentFilter((currentFilter) => ({ ...currentFilter, ...changes })),
    []
  )
  const resetFilter = useCallback(() => setCurrentFilter({}), [])

  const contextValue = useMemo(
    () => ({ activeFilter: currentFilter, editFilter, resetFilter }),
    [currentFilter, editFilter, resetFilter]
  )

  return (
    <VendorInvoicesFilterContext.Provider value={contextValue}>
      {children}
    </VendorInvoicesFilterContext.Provider>
  )
}

export const useVendorInvoicesFilter = (): VendorInvoicesFilterContextType => {
  const context = useContext(VendorInvoicesFilterContext)
  if (!context)
    throw new Error(
      'useVendorInvoicesFilter must be used within a VendorInvoicesFilterProvider'
    )
  return context
}
