import { Button, Text } from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import CardRadioGroup from 'ledger/accounts/account-creation/CardRadioGroup'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useRootStore } from 'stores/RootStoreContext'
import { UsageType, useInitialSetupContext } from './InitialSetupContext'
import OnboardingCard from './OnboardingCard'
import { OnboardingStep } from './OnboardingStepper'

const OnboardingUsageTypeStep = () => {
  const { setCurrentStep, setUsageType, usageType } = useInitialSetupContext()
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)

  const history = useHistory()

  const { accountStore } = useRootStore()
  const isLoading =
    accountStore.isLoadingAccounts || accountStore.isLoadingFiscalYears

  const onContinue = () => {
    setShouldValidate(true)
    if (usageType) {
      setCurrentStep(OnboardingStep.LegalForm)
    }
  }

  useEffect(() => {
    if (isLoading) return
    if (accountStore.currentFiscalYear && accountStore.allAccounts.length > 0) {
      history.push('/')
    } else {
      MixpanelAnalytics.event('Onboarding started')
    }
  }, [isLoading])

  return (
    <OnboardingCard
      icon={<Icon icon='workspace' />}
      title='Wie möchtest du Infinity Finance nutzen?'
      actions={
        <Button variant='primary' onClick={onContinue}>
          Fortfahren
        </Button>
      }
    >
      <div>
        <CardRadioGroup
          onChange={setUsageType}
          value={usageType}
          options={[
            {
              value: UsageType.SelfEmployed,
              label: 'Selbstständig/Freelancer',
              description:
                'Stelle Forderungen und führe Buchhaltung für dich selbst',
              icon: 'hearts',
            },
            {
              value: UsageType.Startup,
              label: 'Startup',
              description: 'Wenn du ein Startup neu gegründet hast',
              icon: 'idea',
            },
            {
              value: UsageType.SmallBusiness,
              label: 'Kleinunternehmen',
              description:
                'Für dein bestehendes Kleinunternehmen oder deine Agentur',
              icon: 'business',
            },
            {
              value: UsageType.Accountant,
              label: 'Buchhalter oder Treuhänder',
              description:
                'Wenn du Buchhaltungsexperte bist und für Kunden buchst',
              icon: 'accounting',
            },
          ]}
        />
        {shouldValidate && !usageType && (
          <Text variant='warning'>Bitte wähle eine Option aus.</Text>
        )}
      </div>
    </OnboardingCard>
  )
}

export default observer(OnboardingUsageTypeStep)
