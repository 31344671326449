import { useHistory } from 'react-router-dom'
import { ICustomerInvoicePreview } from '../../../model/CustomerInvoice'
import ExtendedInvoiceListSection from '../../shared-components/large-invoice-list/ExtendedInvoiceListSection'
import ExtendedCustomerInvoiceListItem from './ExtendedCustomerInvoiceListItem'

interface CustomerInvoiceDraftsProps {
  drafts: ICustomerInvoicePreview[] | undefined
}

const CustomerInvoiceDrafts = (props: CustomerInvoiceDraftsProps) => {
  const history = useHistory()
  return (
    <ExtendedInvoiceListSection
      isLoading={!props.drafts}
      isEmpty={props.drafts?.length === 0}
      emptyStateContent='Keine Entwürfe'
      emptyStateIcon='blueprint'
    >
      {props.drafts?.map((invoice: ICustomerInvoicePreview) => (
        <ExtendedCustomerInvoiceListItem
          key={invoice.id}
          invoice={invoice}
          onClick={() => history.push(`/forderungen/detail/${invoice.id}`)}
        />
      ))}
    </ExtendedInvoiceListSection>
  )
}

export default CustomerInvoiceDrafts
