import Lockup from 'components/text/Lockup'
import TransactionLiveAcountingDetailProps from './TransactionLiveAcountingDetailProps'

const LiveAccountingIBAN = (props: TransactionLiveAcountingDetailProps) => {
  if (!props.details.counterparty?.account) return null

  const formattedIBAN = props.details.counterparty.account.identification
    .replace(/(.{4})/g, '$1 ')
    .replace(/(^\s+|\s+$)/, '')
    .toUpperCase()

  return (
    <Lockup icon='numbers-input-form' tooltip='IBAN' small subtle truncate>
      {formattedIBAN}
    </Lockup>
  )
}

export default LiveAccountingIBAN
