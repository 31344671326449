import { Button } from '@nextbusiness/infinity-ui'
import { ReactComponent as CreateIcon } from 'assets/list-item-create-icon.svg'
import './ContactListCreateItem.scss'

interface ContactListCreateItemProps {
  children?: React.ReactNode
  onClick?: () => void
}

const ContactListCreateItem = (props: ContactListCreateItemProps) => {
  return (
    <Button
      variant='quaternary'
      className='contact-list-create-item'
      onClick={props.onClick}
    >
      <div className='contact-list-create-item-icon'>
        <CreateIcon />
      </div>
      <span className='create-label'>{props.children}</span>
    </Button>
  )
}

export default ContactListCreateItem
