import { ITemplate } from '@nextbusiness/infinity-finance-api'
import { TypedKeyValueStore } from '@nextbusiness/infinity-ui'
import CustomerInvoicePositions from 'invoices/customer-invoices/CustomerInvoicePositions'
import ICustomerInvoice, { INewCustomerInvoice } from 'model/CustomerInvoice'
import { Component } from 'react'
import { ITemplateDraft } from '../../../../model/Template'
import DocumentPage from './DocumentPage'
import DocumentQRSlipPreview from './DocumentQRSlipPreview'
import './DocumentRenderer.scss'
import { DEFAULT_HEIGHTS } from './DocumentRendererContext'
import DocumentRendererContextProvider from './DocumentRendererContextProvider'
import IDocumentPage from './IDocumentPage'
import IDocumentSectionHeights from './IDocumentSectionHeights'
import DocumentPositionsDragDropContext from './elements/positions/DocumentPositionsDragDropContext'

const DEFAULT_PAGE: IDocumentPage = {
  pageNumber: 1,
  header: true,
  title: true,
  infoBlock: true,
  introductoryText: true,
  positionsHeaderRow: true,
  positions: [],
  positionsSumRowExclVAT: true,
  vatSumRow25: true,
  vatSumRow37: true,
  vatSumRow77: true,
  vatSumRow26: true,
  vatSumRow38: true,
  vatSumRow81: true,
  positionsSumRowInclVAT: true,
  closingText: true,
}

interface DocumentRendererProps {
  template: ITemplate | ITemplateDraft
  document: Partial<INewCustomerInvoice>
  existingInvoice?: ICustomerInvoice
  isEditable?: boolean
  registerEdit?: (changes: Partial<INewCustomerInvoice>) => void
}

interface DocumentRendererState {
  sectionHeights: IDocumentSectionHeights
  pages: IDocumentPage[]
  pageHeight: number
  positionTableMargin: number
  footerHeight: number
}

class DocumentRenderer extends Component<
  DocumentRendererProps,
  DocumentRendererState
> {
  constructor(props: DocumentRendererProps) {
    super(props)
    const positions: TypedKeyValueStore<number> = {}
    this.props.document.positions?.forEach((position) => {
      positions[position.identifier] = 0
    })
    this.state = {
      sectionHeights: DEFAULT_HEIGHTS,
      pages: [
        {
          ...DEFAULT_PAGE,
          positions:
            this.props.document.positions?.map((_, index) => index) ?? [],
        },
      ],
      pageHeight: 0,
      positionTableMargin: 0,
      footerHeight: 0,
    }
  }

  setPageHeight = (pageHeight: number) => {
    this.setState({ pageHeight })
  }

  setPositionTableMargin = (positionTableMargin: number) => {
    this.setState({ positionTableMargin })
  }

  setFooterHeight = (footerHeight: number) => {
    this.setState({ footerHeight })
  }

  updateHeights = (newHeights: Partial<IDocumentSectionHeights>) => {
    this.setState((previousState) => ({
      sectionHeights: { ...previousState.sectionHeights, ...newHeights },
    }))
  }

  updatePositionHeights = (index: number, newHeight: number) => {
    this.setState((previousState) => {
      const positions = [...(previousState.sectionHeights.positions || [])]
      positions.splice(index, 1, newHeight)
      return {
        sectionHeights: {
          ...previousState.sectionHeights,
          positions,
        },
      }
    })
  }

  deletePositionHeight = (index: number, identifier: string) => {
    this.setState((previousState, props) => {
      const positions = [...(previousState.sectionHeights.positions || [])]
      /* This check is needed because this function is called on every unmount of the position component */
      const wasPositionDeleted = !props.document.positions?.find(
        (position) => position.identifier === identifier
      )
      if (wasPositionDeleted) positions.splice(index, 1)
      return {
        sectionHeights: {
          ...previousState.sectionHeights,
          positions,
        },
      }
    })
  }

  calculatePages = () => {
    let remainingSections = Object.keys(
      this.state.sectionHeights
    ) as (keyof IDocumentSectionHeights)[]
    let remainingPositions =
      this.props.document.positions?.map((_, index) => index) ?? []
    const pages: IDocumentPage[] = []
    if (this.state.pageHeight === 0) return
    while (remainingSections.length > 0) {
      const newPage = this.newPage(
        remainingSections,
        remainingPositions,
        pages.length + 1
      )
      pages.push(newPage)
      remainingPositions = remainingPositions.filter(
        (position) => !newPage.positions?.includes(position)
      )
      remainingSections = this.newRemainingSections(
        remainingSections,
        remainingPositions,
        newPage
      )
    }
    return pages
  }

  newRemainingSections = (
    oldRemainingSections: (keyof IDocumentSectionHeights)[],
    newRemainingPositions: number[],
    newPage: IDocumentPage
  ) => {
    const sections = Object.keys(newPage) as (keyof IDocumentPage)[]
    /* This will never filter out the 'positions' section, as that is of type number[].
       This is fine, as the 'positions' section is handled separately. */
    const usedSections = sections.filter((section) => newPage[section] === true)
    let remainingSections = oldRemainingSections.filter(
      (section) => !usedSections.includes(section)
    )
    if (newRemainingPositions.length === 0)
      remainingSections = remainingSections.filter(
        (section) => section !== 'positions'
      )
    return remainingSections
  }

  newPage = (
    remainingSections: (keyof IDocumentSectionHeights)[],
    remainingPositions: number[],
    pageNumber: number
  ) => {
    const newPage: IDocumentPage = { pageNumber }
    let remainingSpace =
      this.state.pageHeight -
      this.state.footerHeight -
      (pageNumber > 1 ? 60 : 30)
    for (const section of remainingSections) {
      if (section === 'positions') {
        const [newPagePositions, newRemainingSpace, shouldPositionsWrap] =
          this.newPagePositions(remainingPositions, remainingSpace)
        newPage.positions = newPagePositions
        remainingSpace = newRemainingSpace
        if (shouldPositionsWrap) break
      } else {
        const [doesSectionFitOnPage, newRemainingSpace] =
          this.doesSectionFitOnPage(section, remainingSpace, newPage)
        if (!doesSectionFitOnPage) break
        remainingSpace = newRemainingSpace
        newPage[section] = true
      }
    }
    return newPage
  }

  newPagePositions = (
    remainingPositions: number[],
    remainingSpace: number
  ): [number[], number, boolean] => {
    const newPagePositions: number[] = []
    let shouldWrap = false
    let newRemainingSpace = remainingSpace
    for (const position of remainingPositions) {
      const positionHeight = this.state.sectionHeights.positions[position]
      newRemainingSpace -= positionHeight
      shouldWrap = newRemainingSpace - this.state.positionTableMargin < 0
      /* Prevents crash while line wrapping is not implemented */
      const totalAvailableSpace =
        this.state.pageHeight -
        this.state.footerHeight -
        this.state.positionTableMargin
      const isOnlyPositionOnPage = newPagePositions.length === 0
      if (positionHeight > totalAvailableSpace && isOnlyPositionOnPage) {
        newPagePositions.push(position)
      }
      if (shouldWrap) break
      newPagePositions.push(position)
    }
    return [newPagePositions, newRemainingSpace, shouldWrap]
  }

  doesSectionFitOnPage = (
    section: keyof Omit<IDocumentSectionHeights, 'positions'>,
    remainingSpace: number,
    newPage: IDocumentPage
  ): [boolean, number] => {
    const sectionHeight = this.state.sectionHeights[section]
    let newRemainingSpace = remainingSpace - sectionHeight
    const pageHasPositions = !!newPage.positions?.length
    const shouldAdjustForMargin =
      pageHasPositions ||
      !!newPage.positionsHeaderRow ||
      !!newPage.positionsSumRowInclVAT ||
      !!newPage.positionsSumRowExclVAT
    const marginAdjustment = shouldAdjustForMargin
      ? this.state.positionTableMargin
      : 0
    let doesSectionFitOnPage = newRemainingSpace - marginAdjustment > 0
    /* Prevents crash while line wrapping is not implemented */
    const totalAvailableSpace = this.state.pageHeight - this.state.footerHeight
    const isOnlySectionOnPage = Object.keys(newPage).length === 1 // Only the 'pageNumber' key
    if (sectionHeight > totalAvailableSpace && isOnlySectionOnPage) {
      doesSectionFitOnPage = true
      newRemainingSpace = 0
    }
    return [doesSectionFitOnPage, newRemainingSpace]
  }

  refreshPages = () => {
    const pages = this.calculatePages()
    if (pages) this.setState({ pages })
  }

  havePagesChanged = (newPages: IDocumentPage[]) =>
    JSON.stringify(this.state.pages) !== JSON.stringify(newPages)

  haveSectionHeightsChanged = (newSectionHeights: IDocumentSectionHeights) =>
    JSON.stringify(this.state.sectionHeights) !==
    JSON.stringify(newSectionHeights)

  shouldComponentUpdate(
    nextProps: DocumentRendererProps,
    nextState: DocumentRendererState
  ) {
    return (
      this.props.template !== nextProps.template ||
      this.props.document !== nextProps.document ||
      this.haveSectionHeightsChanged(nextState.sectionHeights) ||
      this.havePagesChanged(nextState.pages)
    )
  }

  componentDidMount() {
    this.refreshPages()
  }

  componentDidUpdate() {
    this.refreshPages()
  }

  get invoiceTotal() {
    const invoicePositions = new CustomerInvoicePositions(
      this.props.document as INewCustomerInvoice
    )
    return invoicePositions.grossTotal
  }

  render() {
    return (
      <div className='document-preview-panel'>
        <div className='document-renderer'>
          <DocumentRendererContextProvider
            heights={this.state.sectionHeights}
            tableColumns={this.props.document.columns}
            setPageHeight={this.setPageHeight}
            setPositionTableMargin={this.setPositionTableMargin}
            setFooterHeight={this.setFooterHeight}
            updateHeights={this.updateHeights}
            updatePositionHeights={this.updatePositionHeights}
            deletePositionHeight={this.deletePositionHeight}
          >
            <DocumentPositionsDragDropContext
              {...this.props}
              registerEdit={this.props.registerEdit ?? (() => {})}
            >
              {this.state.pages.map((page) => (
                <DocumentPage
                  {...this.props}
                  key={page.pageNumber}
                  registerEdit={this.props.registerEdit ?? (() => {})}
                  page={page}
                  existingInvoice={this.props.existingInvoice}
                />
              ))}
            </DocumentPositionsDragDropContext>
          </DocumentRendererContextProvider>
          {!this.props.document.isQuote && this.props.document.isQrInvoice && (
            <DocumentQRSlipPreview
              recipientContactId={this.props.document.recipient}
              amount={this.invoiceTotal}
              openingDate={this.props.document.openingDate ?? Date.now()}
              invoiceNumber={this.props.document.invoiceNumber}
              isAnnulled={this.props.existingInvoice?.isAnnulled}
            />
          )}
        </div>
      </div>
    )
  }
}

export default DocumentRenderer
