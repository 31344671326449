import { Button, Heading, Text } from '@nextbusiness/infinity-ui'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import useBackendQuery from 'libs/networking/BackendQuery'
import { observer } from 'mobx-react-lite'
import FinancialClose from 'networking/FinancialClose'
import Tabs, { Tab } from 'proto-ui-components/Tabs'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import AssistantStepScreen, {
  AssistantStepVisual,
} from '../AssistantStepScreen'
import FinancialClosingAssistant from '../FinancialClosingAssistant'
import ClosingBalanceSheetPreview from '../components/ClosingBalanceSheetPreview'
import ClosingTransactionPreviewList from '../components/ClosingTransactionPreviewList'

interface ClosingReviewStepProps {
  assistant: FinancialClosingAssistant
}

type ReviewTab = 'closing-transactions' | 'balance-sheet-1' | 'balance-sheet-2'
const REVIEW_TABS: Tab<ReviewTab>[] = [
  { title: 'Abschlussbuchungen', identifier: 'closing-transactions' },
  { title: 'Schlussbilanz 1', identifier: 'balance-sheet-1' },
  { title: 'Schlussbilanz 2', identifier: 'balance-sheet-2' },
]

const ClosingReviewStep = (props: ClosingReviewStepProps) => {
  const { authenticationStore } = useRootStore()
  const organisationId = authenticationStore.organisationIdentifier

  const [currentTab, setCurrentTab] = useState<ReviewTab>(
    'closing-transactions'
  )

  const [balanceSheet1, isLoadingBalanceSheet1] = useBackendQuery(() =>
    FinancialClose.previewClosingBalanceSheet(false, organisationId)
  )
  const [balanceSheet2, isLoadingBalanceSheet2] = useBackendQuery(() =>
    FinancialClose.previewClosingBalanceSheet(true, organisationId)
  )
  const [closingTransactions, isLoadingClosingTransactions] = useBackendQuery(
    () => FinancialClose.getClosingTransactions(organisationId)
  )

  const isLoading =
    isLoadingBalanceSheet1 ||
    isLoadingBalanceSheet2 ||
    isLoadingClosingTransactions

  return (
    <AssistantStepScreen
      visual={AssistantStepVisual.Confirmation}
      actions={
        <>
          <Button onClick={() => props.assistant.previousStep()}>Zurück</Button>
          <Button
            onClick={() => props.assistant.performClosing()}
            variant='primary'
            disabled={isLoading}
          >
            Geschäftsjahr abschliessen
          </Button>
        </>
      }
      depiction={
        <>
          <Heading type='h2'>Überprüfen</Heading>
          <Text type='paragraph'>
            Bitte überprüfe, ob hier alles korrekt ist, bevor du das
            Geschäftsjahr {(props.assistant.dueFiscalYear?.year ?? 0) + 1} per{' '}
            {props.assistant.formattedClosingDate} abschliesst.
          </Text>
        </>
      }
    >
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Tabs
            onTabSelected={(tab) => setCurrentTab(tab.identifier)}
            currentTabId={currentTab}
            tabs={REVIEW_TABS}
          />
          {currentTab === 'closing-transactions' &&
            closingTransactions !== undefined && (
              <ClosingTransactionPreviewList
                closingTransactions={closingTransactions}
              />
            )}
          {currentTab === 'balance-sheet-1' && balanceSheet1 !== undefined && (
            <ClosingBalanceSheetPreview
              balanceSheet={balanceSheet1}
              closingDate={props.assistant.closingDate!.toJSDate()}
            />
          )}
          {currentTab === 'balance-sheet-2' && balanceSheet2 !== undefined && (
            <ClosingBalanceSheetPreview
              balanceSheet={balanceSheet2}
              closingDate={props.assistant.closingDate!.toJSDate()}
            />
          )}
        </>
      )}
    </AssistantStepScreen>
  )
}

export default observer(ClosingReviewStep)
