import { ICustomerInvoicePreview } from 'model/CustomerInvoice'
import ContactListRenderCustomerInvoices from './ContactListRenderCustomerInvoices'

interface ContactQuotesListProps {
  data: ICustomerInvoicePreview[]
}

const ContactQuotesList = (props: ContactQuotesListProps) => {
  return <ContactListRenderCustomerInvoices isQuote data={props.data} />
}

export default ContactQuotesList
