import { VATCalculationMode } from '@nextbusiness/infinity-finance-api'
import { Button, InputField, RadioGroup, Text } from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'
import Lockup from 'components/text/Lockup'
import CardRadioGroup from 'ledger/accounts/account-creation/CardRadioGroup'
import { useState } from 'react'
import { useInitialSetupContext } from './InitialSetupContext'
import OnboardingCard from './OnboardingCard'
import OnboardingOptionProperty from './OnboardingOptionProperty'
import { OnboardingStep } from './OnboardingStepper'

const autofixVATNumber = (vatNumber: string) => {
  const normalisedVATNumber = vatNumber.trim().toUpperCase()
  if (normalisedVATNumber.length === 'CHE-123.456.789'.length)
    return normalisedVATNumber + ' MWST'
  return normalisedVATNumber
}
const isValidVATNumber = (vatNumber: string) =>
  /^CHE-\d{3}\.\d{3}\.\d{3} MWST$/.test(autofixVATNumber(vatNumber))

const OnboardingVATStep = () => {
  const {
    setCurrentStep,
    isVATEnabled,
    setIsVATEnabled,
    vatCalculationMode,
    setVatCalculationMode,
    vatRegistrationNumber,
    setVatRegistrationNumber,
  } = useInitialSetupContext()
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)

  const hasEnteredValidVATNumber = isValidVATNumber(vatRegistrationNumber)

  const onContinue = () => {
    setShouldValidate(true)
    const isComplete =
      isVATEnabled === false || isValidVATNumber(vatRegistrationNumber)
    if (isComplete) {
      setVatRegistrationNumber(autofixVATNumber(vatRegistrationNumber))
      setCurrentStep(OnboardingStep.Review)
    }
  }

  return (
    <OnboardingCard
      icon={<Icon icon='dividends' />}
      title='Bist du bei der Mehrwertsteuer angemeldet?'
      actions={
        <Button variant='primary' onClick={onContinue}>
          Fortfahren
        </Button>
      }
    >
      <div>
        <CardRadioGroup
          onChange={setIsVATEnabled}
          value={isVATEnabled}
          options={[
            { value: true, label: 'Ja, ich bin bei der MWST angemeldet' },
            { value: false, label: 'Nein, ich bin nicht angemeldet' },
          ]}
        />
        {shouldValidate && isVATEnabled === undefined && (
          <Text variant='warning'>Bitte wähle eine Option aus.</Text>
        )}
      </div>
      <Lockup icon='edit' cushion='tiny' subtle>
        Du kannst dies später noch ändern.
      </Lockup>
      {isVATEnabled && (
        <>
          <OnboardingOptionProperty title='MWST-Nummer'>
            <Text>
              Die MWST-Nummer hast du bei der Anmeldung von der Steuerverwaltung
              erhalten. Wenn du noch keine hast, bist du noch nicht angemeldet.
            </Text>
            <InputField
              placeholder='Mehrwertsteuernummer'
              value={vatRegistrationNumber}
              onChange={setVatRegistrationNumber}
              helperText='Im Format CHE-123.456.789 MWST'
              fullWidth
              hasError={shouldValidate && !hasEnteredValidVATNumber}
            />
          </OnboardingOptionProperty>
          <OnboardingOptionProperty title='Abrechnungsmethode'>
            <Text>
              Falls du dir einen Wechsel überlegst, empfehlen wir die effektive
              Abrechnung mit Infinity Finance.
            </Text>
            <RadioGroup
              onChange={setVatCalculationMode}
              value={vatCalculationMode}
              options={[
                {
                  value: VATCalculationMode.Effective,
                  label: 'Effektive Abrechnung (Standard)',
                },
                {
                  value: VATCalculationMode.Simplified,
                  label: 'Saldosteuersatz',
                },
              ]}
            />
          </OnboardingOptionProperty>
        </>
      )}
    </OnboardingCard>
  )
}

export default OnboardingVATStep
