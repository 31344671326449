import { Button, Card, Flex, Text } from '@nextbusiness/infinity-ui'
import { IPresetListItem, PresetType } from 'model/Preset'
import PresetIconBlank from '../../assets/preset-icons/preset-icon-blank.png'
import PresetIconConsulting from '../../assets/preset-icons/preset-icon-consulting.png'
import PresetIconCustom from '../../assets/preset-icons/preset-icon-custom.png'
import PresetIconFreelancing from '../../assets/preset-icons/preset-icon-freelancing.png'
import PresetIconServices from '../../assets/preset-icons/preset-icon-services.png'
import './PresetCard.scss'

interface PresetCardProps {
  preset: IPresetListItem
  onClick: () => void
}

const presetIconForType = (type: PresetType) => {
  switch (type) {
    case 'blank':
      return PresetIconBlank
    case 'consulting':
      return PresetIconConsulting
    case 'custom':
      return PresetIconCustom
    case 'freelancing':
      return PresetIconFreelancing
    case 'services':
      return PresetIconServices
  }
}

const PresetCard = (props: PresetCardProps) => {
  const icon = presetIconForType(props.preset.type)
  const description = () => {
    switch (props.preset.type) {
      case 'blank':
        return 'Mit leerem Dokument beginnen'
      case 'custom':
        return 'Eigene Vorlage'
      default:
        return props.preset.description
    }
  }

  return (
    <Button variant='shell' onClick={props.onClick} className='preset-card'>
      <Card elevation='low' bare>
        {props.preset.type !== 'blank' && (
          <span className='preset-icon-invoice-title'>
            {props.preset.invoice.title}
          </span>
        )}
        <img className='preset-icon' src={icon} alt='' />
        <Flex
          className='preset-depiction'
          direction='vertical'
          horizontalAlignment='space-between'
        >
          <Text className='preset-title' type='inline'>
            {props.preset.title}
          </Text>
          {description() && (
            <Text className='preset-description' type='inline'>
              {description()}
            </Text>
          )}
        </Flex>
      </Card>
    </Button>
  )
}

export default PresetCard
