import ExtendedInvoiceListSection from 'invoices/shared-components/large-invoice-list/ExtendedInvoiceListSection'
import { observer } from 'mobx-react'
import DocumentsPage, { DocumentsPagePaths } from 'pages/DocumentsPage'
import { useRootStore } from 'stores/RootStoreContext'
import ExtendedDocumentListItem from './ExtendedDocumentListItem'
import ProcessedDocumentsStack from './ProcessedDocumentsStack'
import './RecentlyProcessedDocuments.scss'

interface RecentlyProcessedDocumentsProps {
  page: DocumentsPage
}

const MAX_RECENT_DOCUMENTS = 12

const RecentlyProcessedDocuments = (props: RecentlyProcessedDocumentsProps) => {
  const { documentStore } = useRootStore()

  const isLoading = !documentStore.allDocuments
  const recentDocuments = documentStore.processedDocuments?.slice(
    0,
    MAX_RECENT_DOCUMENTS
  )

  return (
    <ExtendedInvoiceListSection
      className='processed-documents-section'
      title='Erledigte Belege'
      emptyStateContent='Keine erledigten Belege'
      emptyStateIcon='inbox'
      isLoading={isLoading}
      isEmpty={recentDocuments?.length === 0}
      stacks={
        <ProcessedDocumentsStack
          onClick={() => props.page.open(DocumentsPagePaths.ProcessedDocuments)}
        />
      }
    >
      {recentDocuments?.map((processedDocument) => (
        <ExtendedDocumentListItem
          key={processedDocument.id}
          document={processedDocument}
          onClick={() =>
            props.page.open(
              DocumentsPagePaths.DocumentDetailView,
              processedDocument.id
            )
          }
        />
      ))}
    </ExtendedInvoiceListSection>
  )
}

export default observer(RecentlyProcessedDocuments)
