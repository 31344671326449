import { Button, Heading, NonIdealState, Text } from '@nextbusiness/infinity-ui'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import useBackendQuery from 'libs/networking/BackendQuery'
import { observer } from 'mobx-react-lite'
import FinancialClose from 'networking/FinancialClose'
import { useMemo } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import AssistantStepScreen, {
  AssistantStepVisual,
} from '../AssistantStepScreen'
import PreCloseChecklist from '../components/PreCloseChecklist'
import FinancialClosingAssistant from '../FinancialClosingAssistant'

interface ClosingPreCloseChecksProps {
  assistant: FinancialClosingAssistant
}

const ClosingPreCloseChecks = (props: ClosingPreCloseChecksProps) => {
  const { authenticationStore } = useRootStore()
  const [checks, isLoading, error] = useBackendQuery(() =>
    FinancialClose.getPreCloseChecks(authenticationStore.organisationIdentifier)
  )

  const canClose = useMemo(
    () => checks !== undefined && Object.values(checks).every((check) => check),
    [checks]
  )

  return (
    <AssistantStepScreen
      visual={AssistantStepVisual.Checks}
      actions={
        <>
          <Button onClick={() => props.assistant.previousStep()}>Zurück</Button>
          <Button
            onClick={() => props.assistant.nextStep()}
            variant='primary'
            disabled={!canClose || isLoading}
          >
            Fortfahren
          </Button>
        </>
      }
      depiction={
        <>
          <Heading type='h2'>Checks</Heading>
          <Text type='paragraph'>
            Alle Voraussetzungen müssen erfüllt sein, damit du fortfahren
            kannst.
          </Text>
        </>
      }
    >
      {isLoading ? (
        <LoadingScreen />
      ) : error || !checks ? (
        <NonIdealState icon='error'>{error?.displayMessage}</NonIdealState>
      ) : (
        <PreCloseChecklist canClose={canClose} checks={checks} />
      )}
    </AssistantStepScreen>
  )
}

export default observer(ClosingPreCloseChecks)
