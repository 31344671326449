import { IDocumentAnalysis } from '@nextbusiness/infinity-finance-api'
import { Contact } from 'model/Contact'
import ContactStore from 'stores/ContactStore'
import { store } from 'stores/RootStoreContext'
import StringUtilities from 'utility/StringUtilities'

/**
 * Suggests the relevant invoice contact based on the document analysis.
 */
class LiveCaptureContactSuggestor {
  public static readonly SUGGESTED_NEW_CONTACT_ID = 'live-capture-suggestion'
  private documentAnalysis: IDocumentAnalysis
  private static readonly MINIMUM_NAME_SIMILARITY = 0.5
  private static readonly MINIMUM_ADDRESS_SIMILARITY = 0.9

  constructor(documentAnalysis: IDocumentAnalysis) {
    this.documentAnalysis = documentAnalysis
  }

  /**
   * The suggested contact id based on the document analysis, using
   * the counterparty name, address or VAT number as a similarity metric.
   * If no contact is found but a new contact can be suggested, the id of a newly suggested contact (`live-capture-suggestion`) is returned.
   */
  public get suggestedContactId(): string | undefined {
    const { counterpartyName, counterpartyVatNumber, counterpartyAddress } =
      this.documentAnalysis
    const noContactInformationAvailable = !(
      counterpartyName ??
      counterpartyAddress ??
      counterpartyVatNumber
    )
    if (noContactInformationAvailable) return undefined

    const contacts = store.contactStore.allContacts
    const nameMatch = StringUtilities.findClosestMatch(
      counterpartyName,
      contacts.map(ContactStore.nameOfContact),
      LiveCaptureContactSuggestor.MINIMUM_NAME_SIMILARITY
    )
    if (nameMatch) return contacts[nameMatch]?.id

    const addressMatch = StringUtilities.findClosestMatch(
      counterpartyAddress,
      contacts.map((contact) => `${contact.address} ${contact.postCode}`),
      LiveCaptureContactSuggestor.MINIMUM_ADDRESS_SIMILARITY
    )
    if (addressMatch) return contacts[addressMatch]?.id

    const contactByVATNumber = contacts.find(
      (contact) => contact.vatNumber === counterpartyVatNumber
    )
    if (counterpartyVatNumber && contactByVATNumber)
      return contactByVATNumber.id

    return this.newContactSuggestion?.id
  }

  public get newContactSuggestion(): Contact | undefined {
    if (!this.canSuggestNewContact) return undefined

    const newContact: Contact = {
      category: 'suppliers',
      id: LiveCaptureContactSuggestor.SUGGESTED_NEW_CONTACT_ID,
      companyName: this.documentAnalysis.counterpartyName,
      vatNumber: this.documentAnalysis.counterpartyVatNumber,
      address: this.documentAnalysis.counterpartyAddress,
      postCode: this.documentAnalysis.counterpartyPostCode,
      country: this.documentAnalysis.counterpartyCountry,
      phone: this.documentAnalysis.counterpartyPhoneNumber,
      email: this.documentAnalysis.counterpartyEmail,
    }
    return newContact
  }

  private get canSuggestNewContact(): boolean {
    return !!this.documentAnalysis.counterpartyName
  }
}

export default LiveCaptureContactSuggestor
