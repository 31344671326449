import './DocumentSidebarDraftInfo.scss'

interface DocumentSidebarDraftInfoProps {
  isQuote?: boolean
}

const DocumentSidebarDraftInfo = (props: DocumentSidebarDraftInfoProps) => (
  <>
    <div className='document-sidebar-draft'>
      <span className='title'>
        Diese {props.isQuote ? 'Offerte' : 'Forderung'} ist ein Entwurf.
      </span>
      <p>
        {props.isQuote
          ? 'Du kannst sie als PDF oder über einen Link teilen, sobald du sie stellst.'
          : 'Sie wird erst in deinen Auswertungen berücksichtigt, sobald du sie stellst.'}
      </p>
    </div>
  </>
)

export default DocumentSidebarDraftInfo
