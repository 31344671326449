import MobileApplication from 'application/MobileApplication'
import React, { useEffect, useState } from 'react'
import { useIsTouchDevice } from 'utility/hooks'
import MobileHint from './MobileHint'

interface MobileGuardProps {
  children?: React.ReactNode
}

const MOBILE_GUARD_THRESHOLD_WIDTH = 640

const MobileGuard = (props: MobileGuardProps) => {
  const isMobileDevice = useIsTouchDevice()
  const [isScreenSmall, setIsScreenSmall] = useState<boolean>(false)

  useEffect(() => {
    const handleResize = () =>
      setIsScreenSmall(window.innerWidth < MOBILE_GUARD_THRESHOLD_WIDTH)
    handleResize()

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (!isScreenSmall) return <>{props.children}</>
  if (isMobileDevice) return <MobileApplication />

  return (
    <>
      {/**
       * We still keep the children in the render tree to avoid loss of state
       * in child components during intermittent resizing.
       */}
      {props.children}
      <MobileHint title='Mache dein Browserfenster breiter, um Infinity Finance zu nutzen.' />
    </>
  )
}

export default MobileGuard
