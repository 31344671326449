import { Heading } from '@nextbusiness/infinity-ui'
import PreviewTransaction, {
  TransactionPreviewType,
} from './PreviewTransaction'

interface LedgerDisplayTransactionPreviewProps {
  displayVATTransactions: boolean
  showTransactionIndicators: boolean
}

const LedgerDisplayTransactionPreview = (
  props: LedgerDisplayTransactionPreviewProps
) => {
  const previewProps: Partial<React.ComponentProps<typeof PreviewTransaction>> =
    { indicators: props.showTransactionIndicators }

  return (
    <div className='ledger-display-transaction-preview'>
      <Heading type='h4'>Vorschau</Heading>
      <div className='transactions'>
        {props.displayVATTransactions ? (
          <>
            <PreviewTransaction
              type={TransactionPreviewType.Normal}
              {...previewProps}
            />
            <PreviewTransaction
              type={TransactionPreviewType.VAT}
              {...previewProps}
            />
          </>
        ) : (
          <PreviewTransaction
            type={TransactionPreviewType.Normal}
            {...previewProps}
            useGrossAmount
          />
        )}
      </div>
    </div>
  )
}

export default LedgerDisplayTransactionPreview
