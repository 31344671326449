import { Flex, Text } from '@nextbusiness/infinity-ui'
import VirtualAccount from 'components/transactions/VirtualAccount'
import { AccountType, INewAccount } from 'model/Account'
import { KeyValueStore } from 'utility/types'
import { subgroupForChartOfAccountsMapping } from '../data/AccountGroups'
import './ChecksStep.scss'

interface ChecksStepProps {
  accountType: AccountType | undefined
  newAccount: Partial<INewAccount>
  accountColours: KeyValueStore
}

const ChecksStep = (props: ChecksStepProps) => {
  const accountTypeString = (accountType: AccountType | undefined) => {
    switch (accountType) {
      case 'assets':
        return 'Vermögenskonto'
      case 'liabilities':
        return 'Schuldkonto'
      case 'income':
        return 'Ertragskonto'
      case 'expense':
        return 'Aufwandskonto'
      default:
        return ''
    }
  }

  const matchedSubGroup = subgroupForChartOfAccountsMapping(
    props.accountType,
    props.newAccount.accountGroups!
  )

  return (
    <>
      <Text>Wenn du fortfährst, wird folgendes Konto erstellt.</Text>
      <Flex gap='regular'>
        <Text className='checks-step-title'>Name</Text>
        <Flex verticalAlignment='center'>
          <VirtualAccount
            name={props.newAccount.name ?? '–'}
            colour={props.newAccount.colour}
          />
        </Flex>
      </Flex>
      <Flex gap='regular'>
        <Text className='checks-step-title'>Kontoart</Text>
        <Text className='checks-step-value'>
          {accountTypeString(props.accountType)}
        </Text>
      </Flex>
      <Flex gap='regular'>
        <Text className='checks-step-title'>Kategorie</Text>
        <Text className='checks-step-value'>{matchedSubGroup?.title}</Text>
      </Flex>
      <Flex gap='regular'>
        <Text className='checks-step-title'>Beschreibung</Text>
        <Text className='checks-step-value'>
          {props.newAccount.description ?? '-'}
        </Text>
      </Flex>
    </>
  )
}

export default ChecksStep
