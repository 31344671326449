import {
  ITransaction,
  TransactionOrigin,
} from '@nextbusiness/infinity-finance-api'
import { SpecialAccount } from 'components/transactions/SpecialAccounts'
import { IAccount } from '../model/Account'

class TransactionUtilities {
  /**
   * Merges two lists of transactions, removing duplicates and sorting them.
   */
  public static mergedTransactions(
    newTransactions: ITransaction[],
    existingTransactions: ITransaction[] = []
  ) {
    const mergedTransactions = [...existingTransactions]

    newTransactions.forEach((newTransaction) => {
      const index = mergedTransactions.findIndex(
        (transaction) => transaction.id === newTransaction.id
      )
      if (index !== -1) {
        mergedTransactions[index] = newTransaction
      } else {
        mergedTransactions.push(newTransaction)
      }
    })
    return TransactionUtilities.sortedTransactions(mergedTransactions)
  }

  /**
   * Sorts transactions by date and order of creation.
   */
  public static sortedTransactions(transaction: ITransaction[]) {
    return transaction.sort((a, b) => {
      if (b.date !== a.date) {
        return b.date - a.date
      } else if (b.createdAt && a.createdAt && b.createdAt !== a.createdAt) {
        return b.createdAt - a.createdAt
      } else if (b.origin !== a.origin) {
        return b.origin === TransactionOrigin.Manual ? 1 : -1
      } else {
        return 0
      }
    })
  }

  /**
   * Returns the sum of all transactions of the current account,
   * based on the account type.
   */
  public static sumBalanceOfTransactions(
    transactions: ITransaction[],
    currentAccount: IAccount
  ): number {
    let sum = 0
    transactions.forEach((transaction) => {
      const isCurrentAccountDebit =
        transaction.debitAccount === currentAccount.accountNumber

      const amount = isCurrentAccountDebit
        ? transaction.amount
        : -transaction.amount

      switch (currentAccount.accountType) {
        case 'assets':
        case 'expense':
          sum += amount
          break
        case 'income':
        case 'liabilities':
          sum -= amount
      }
    })
    return sum
  }

  /**
   * Returns the gross amount of any net transaction.
   * @param netTransaction - The net transaction object.
   */
  public static grossAmountForNetTransaction(
    netTransaction: ITransaction
  ): number {
    return netTransaction.grossAmount
  }

  /**
   * Returns true if the transaction should be hidden if the option to display
   * VAT transactions is disabled, based on the current account.
   * @param transaction - The transaction to be checked.
   * @param withinCurrentAccount - The current account within which the transaction is
   * being displayed.
   */
  public static shouldHideVATTransaction(
    transaction: ITransaction,
    withinCurrentAccount: IAccount | SpecialAccount
  ): boolean {
    const isInVATSystemAccount =
      this.isAccountVATSystemAccount(withinCurrentAccount)
    return transaction.origin === TransactionOrigin.VAT && !isInVATSystemAccount
  }

  /**
   * Returns true if the given account is a VAT system account.
   */
  public static isAccountVATSystemAccount(account: IAccount | SpecialAccount) {
    return (
      (account.accountNumber >= 1170 && account.accountNumber <= 1175) ||
      (account.accountNumber >= 2200 && account.accountNumber <= 2203)
    )
  }

  /**
   * Returns true if the given account supports displaying gross amounts.
   */
  public static doesAccountSupportGrossAmounts(
    account: IAccount | SpecialAccount
  ) {
    if ('accountType' in account) {
      const isBalanceSheetAccount =
        account.accountType === 'assets' ||
        account.accountType === 'liabilities'
      const isVATSystemAccount = this.isAccountVATSystemAccount(account)

      return isBalanceSheetAccount && !isVATSystemAccount
    }
    return false
  }
}

export default TransactionUtilities
