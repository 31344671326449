import {
  Button,
  DatePicker,
  FeedbackBanner,
  Flex,
  Heading,
  RadioGroup,
  Text,
} from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import DateUtilities from 'utility/DateUtilites'
import './BankAccountMethodSetup.scss'
import BankAccountSetupStep from './BankAccountSetupStep'

interface BankAccountInitialSyncStepProps {
  onComplete: () => void
  isSaving: boolean
  syncFrom: Date | undefined
  syncTo: Date | undefined
  setSyncFrom: (date: Date | undefined) => void
  setSyncTo: (date: Date | undefined) => void
}

enum SyncType {
  StartToday = 'start-today',
  CustomPeriod = 'custom-period',
}

const BankAccountInitialSyncStep = (props: BankAccountInitialSyncStepProps) => {
  const { setSyncFrom, setSyncTo } = props
  const { accountStore } = useRootStore()

  const [syncType, setSyncType] = useState<SyncType>(SyncType.StartToday)

  const hasInvalidDateOrder = () => {
    if (!props.syncFrom || !props.syncTo) return false
    return props.syncFrom.getTime() > props.syncTo.getTime()
  }
  const areDatesInFuture = () => {
    if (!props.syncFrom || !props.syncTo) return false
    return (
      props.syncFrom.getTime() > Date.now() ||
      props.syncTo.getTime() > Date.now()
    )
  }
  const areDatesOutsideOfFiscalYear = () => {
    if (!props.syncFrom || !props.syncTo) return false
    return (
      !accountStore.isDateWithinOpenFiscalYear(props.syncFrom) ||
      !accountStore.isDateWithinOpenFiscalYear(props.syncTo)
    )
  }
  const hasError =
    hasInvalidDateOrder() || areDatesInFuture() || areDatesOutsideOfFiscalYear()

  useEffect(() => {
    if (syncType === SyncType.StartToday) {
      setSyncFrom(DateUtilities.startOfDay(new Date()))
      setSyncTo(DateUtilities.startOfDay(new Date()))
    }
  }, [syncType, setSyncFrom, setSyncTo])

  return (
    <BankAccountSetupStep>
      <Flex className='bank-account-method-setup' gap='regular'>
        <div>
          <Icon icon='synchronise' size={IconSize.Regular} />
        </div>
        <div>
          <Heading type='h2' bareTop>
            Bankbewegungen importieren
          </Heading>
          <Text>
            Wenn du möchtest, kannst du hiermit auch bisherige Bankbewegungen
            importieren.
          </Text>
          <div className='setup-options'>
            <RadioGroup
              value={syncType}
              onChange={(value) => setSyncType(value as SyncType)}
              options={[
                {
                  label: 'Ab heute synchronisieren',
                  value: SyncType.StartToday,
                },
                {
                  label: 'Auch bisherige Bankbewegungen importieren',
                  value: SyncType.CustomPeriod,
                },
              ]}
            />
            {syncType === SyncType.CustomPeriod && (
              <Flex
                verticalAlignment='center'
                gap='small'
                className='import-date-picker'
              >
                <span>Von</span>
                <DatePicker
                  value={props.syncFrom}
                  onChange={setSyncFrom}
                  inputFieldProps={{ fullWidth: true, hasError }}
                />
                <span>Bis</span>
                <DatePicker
                  value={props.syncTo}
                  onChange={setSyncTo}
                  inputFieldProps={{ fullWidth: true, hasError }}
                />
              </Flex>
            )}
            {hasInvalidDateOrder() ? (
              <Text variant='error'>
                Das Startdatum muss vor dem Enddatum liegen.
              </Text>
            ) : areDatesInFuture() ? (
              <Text variant='error'>
                Das Start- und Enddatum darf nicht in der Zukunft liegen.
              </Text>
            ) : areDatesOutsideOfFiscalYear() ? (
              <Text variant='error'>
                Start- und Enddatum müssen beide innerhalb eines offenen
                Geschäftsjahres liegen.
              </Text>
            ) : null}
            {syncType === SyncType.CustomPeriod && (
              <FeedbackBanner variant='info'>
                Stelle sicher, dass nur den Zeitraum importierst, den du noch
                nicht manuell verbucht hast, um doppelte Buchungen zu vermeiden.
              </FeedbackBanner>
            )}
          </div>
          <Button
            variant='primary'
            onClick={props.onComplete}
            isLoading={props.isSaving}
            disabled={!props.syncFrom || !props.syncTo || hasError}
          >
            Konto verbinden
          </Button>
        </div>
      </Flex>
    </BankAccountSetupStep>
  )
}

export default observer(BankAccountInitialSyncStep)
