import { ITemplate } from '@nextbusiness/infinity-finance-api'
import { ITemplateDraft, ITemplatePreview } from '../model/Template'
import { Current } from './Authentication'
import NetworkingConstants from './NetworkingConstants'
import NetworkingErrorHandler from './NetworkingErrorHandler'

const getTemplates = async (
  organisationId: string
): Promise<ITemplatePreview[]> => {
  const response = await fetch(
    `${NetworkingConstants.HOST}/organisation/${organisationId}/templates`,
    { method: 'GET', headers: Current.defaultHeaders }
  )

  const body = await response.json()

  if (!response.ok || !body.templates) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.templates as ITemplatePreview[]
}

const getTemplate = async (withId: string): Promise<ITemplate> => {
  const response = await fetch(
    `${NetworkingConstants.HOST}/template/${withId}`,
    { method: 'GET' }
  )

  const body = await response.json()

  if (!response.ok || !body.template) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.template as ITemplate
}

const createTemplate = async (
  template: ITemplateDraft,
  organisationIdentifier: string
) => {
  const response = await fetch(
    `${NetworkingConstants.HOST}/organisation/${organisationIdentifier}/template`,
    {
      method: 'POST',
      headers: Current.defaultHeaders,
      body: JSON.stringify({ ...template }),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.template) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.template as ITemplate
}

const updateTemplate = async (
  withId: string,
  template: Partial<ITemplateDraft>
) => {
  const response = await fetch(
    `${NetworkingConstants.HOST}/template/${withId}`,
    {
      method: 'PATCH',
      headers: Current.defaultHeaders,
      body: JSON.stringify({ ...template }),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.template) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.template as ITemplate
}

const uploadLogo = async (
  file: File,
  title: string,
  organisationIdentifier: string,
  templateId: string
) => {
  const formData = new FormData()

  formData.append('title', title)
  formData.append('file', file)

  const response = await fetch(
    `${NetworkingConstants.HOST}/organisation/${organisationIdentifier}/template/${templateId}/logo`,
    {
      method: 'POST',
      headers: { Authorization: Current.authHeader },
      body: formData,
    }
  )
  const body = await response.json()

  if (!response.ok) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
}

const Templates = {
  getTemplates,
  getTemplate,
  createTemplate,
  updateTemplate,
  uploadLogo,
}

export default Templates
