import { TypedKeyValueStore } from '@nextbusiness/infinity-ui'
import { IIncomeStatementGroup } from '../../model/Report'

export interface IIncomeStatementSegment {
  statement: IIncomeStatementGroup[]
  subGroupNumberLowerLimit?: number
  subGroupNumberUpperLimit?: number
  groupDescriptions?: { groupNumber: number; description: string }
  isVirtualSegment?: boolean
  balance?: number
  sum: number
  sumDescription: string
  shouldSkipSum: boolean
}

export interface ISegmentizedIncomeStatement {
  operatingResult: IIncomeStatementSegment
  personell: IIncomeStatementSegment
  EBITDA: IIncomeStatementSegment
  EBIT: IIncomeStatementSegment
  EBT: IIncomeStatementSegment
  netIncome: IIncomeStatementSegment
}

export const segmentizeIncomeStatement = (
  incomeStatement: IIncomeStatementGroup[]
): ISegmentizedIncomeStatement => {
  const segmentizedIncomeStatement: TypedKeyValueStore<IIncomeStatementSegment> =
    {}

  const operatingResultSegment = incomeStatement.filter(
    (group) => group.number === 3 || group.number === 4
  )

  const sales =
    operatingResultSegment.find((group) => group.number === 3)?.balance ?? 0

  const operatingExpenses =
    operatingResultSegment.find((group) => group.number === 4)?.balance ?? 0

  const operatingResult = sales + operatingExpenses

  const ebitdaSegment = incomeStatement.filter(
    (group) => group.number === 5 || group.number === 6
  )

  const personnelExpenses =
    ebitdaSegment.find((group) => group.number === 5)?.balance ?? 0

  const otherOperatingExpenses =
    ebitdaSegment
      .find((group) => group.number === 6)
      ?.subgroups.reduce((accumulator, subgroup): number => {
        const currentValue = subgroup.number < 6800 ? subgroup.balance : 0
        return accumulator + currentValue
      }, 0) ?? 0

  const EBITDA = operatingResult + personnelExpenses + otherOperatingExpenses

  const ebitSegment = incomeStatement.find((group) => group.number === 6)
  const filteredEbitSegment = (
    fromSubgroupNumber: number,
    toSubgroupNumber: number
  ): IIncomeStatementGroup | undefined => {
    if (!ebitSegment) return undefined
    return {
      ...ebitSegment,
      subgroups: ebitSegment.subgroups.filter(
        (subgroup) =>
          subgroup.number >= fromSubgroupNumber &&
          subgroup.number <= toSubgroupNumber
      ),
    }
  }
  const ebitdaPersonnelSegment = ebitdaSegment.find(
    (group) => group.number === 5
  )
  const ebitdaOtherOperatingExpenses = filteredEbitSegment(6000, 6799)
  const ebitDepreciationAndAmortisationSegment = filteredEbitSegment(6800, 6899)
  const ebitFinancialResultSegment = filteredEbitSegment(6900, 6999)

  const depreciationAndAmortisation =
    ebitSegment?.subgroups.find((group) => group.number === 6800)?.balance ?? 0

  const EBIT = EBITDA + depreciationAndAmortisation

  const interestExpense =
    ebitSegment?.subgroups.find((group) => group.number === 6900)?.balance ?? 0
  const interestIncome =
    ebitSegment?.subgroups.find((group) => group.number === 6950)?.balance ?? 0

  const EBT = EBIT + interestIncome + interestExpense

  const netIncomeSegment = incomeStatement.filter(
    (group) => group.number === 7 || group.number === 8
  )

  const operationalSecondarySucces =
    netIncomeSegment.find((group) => group.number === 7)?.balance ?? 0

  const nonOperationalEtcIncomeOrExpenses =
    netIncomeSegment.find((group) => group.number === 8)?.balance ?? 0

  const netIncome =
    EBT + operationalSecondarySucces + nonOperationalEtcIncomeOrExpenses

  segmentizedIncomeStatement['operatingResult'] = {
    statement: operatingResultSegment,
    sum: operatingResult,
    sumDescription: 'Bruttogewinn',
    shouldSkipSum: false,
  }

  segmentizedIncomeStatement['personell'] = {
    statement: ebitdaPersonnelSegment ? [ebitdaPersonnelSegment] : [],
    subGroupNumberUpperLimit: 5999,
    groupDescriptions: {
      groupNumber: 5,
      description: 'Personalaufwand',
    },
    sum: EBITDA - personnelExpenses,
    isVirtualSegment: true,
    sumDescription: 'Ergebnis vor Personalaufwand',
    shouldSkipSum: true,
  }

  segmentizedIncomeStatement['EBITDA'] = {
    statement: ebitdaOtherOperatingExpenses
      ? [ebitdaOtherOperatingExpenses]
      : [],
    subGroupNumberUpperLimit: 6799,
    groupDescriptions: {
      groupNumber: 6,
      description: 'Übriger betrieblicher Aufwand',
    },
    isVirtualSegment: true,
    sum: EBITDA,
    sumDescription: 'Gewinn vor Zinsen, Abschreibungen und Steuern (EBITDA)',
    shouldSkipSum: !ebitSegment,
  }

  segmentizedIncomeStatement['EBIT'] = {
    statement: ebitDepreciationAndAmortisationSegment
      ? [ebitDepreciationAndAmortisationSegment]
      : [],
    subGroupNumberLowerLimit: 6800,
    subGroupNumberUpperLimit: 6899,
    groupDescriptions: {
      groupNumber: 6,
      description: 'Abschreibungen und Wertberichtigungen',
    },
    isVirtualSegment: true,
    balance: -depreciationAndAmortisation,
    sum: EBIT,
    sumDescription: 'Gewinn vor Zinsen und Steuern (EBIT)',
    shouldSkipSum: depreciationAndAmortisation === 0,
  }

  segmentizedIncomeStatement['EBT'] = {
    statement: ebitFinancialResultSegment ? [ebitFinancialResultSegment] : [],
    subGroupNumberLowerLimit: 6900,
    subGroupNumberUpperLimit: 6999,
    groupDescriptions: {
      groupNumber: 6,
      description: 'Finanzerfolg',
    },
    balance: interestIncome + interestExpense,
    sum: EBT,
    isVirtualSegment: true,
    sumDescription: 'Gewinn vor Steuern (EBT)',
    shouldSkipSum:
      Math.abs(operationalSecondarySucces) +
        Math.abs(nonOperationalEtcIncomeOrExpenses) ===
      0,
  }

  segmentizedIncomeStatement['netIncome'] = {
    statement: netIncomeSegment,
    sum: netIncome,
    sumDescription: netIncome < 0 ? 'Reinverlust' : 'Reingewinn',
    shouldSkipSum: false,
  }

  return segmentizedIncomeStatement as unknown as ISegmentizedIncomeStatement
}
