import { APIError, ErrorCode } from '@nextbusiness/infinity-finance-api'
import { useNotificationCenter as useNotificationCentre } from '@nextbusiness/infinity-ui'
import { useState } from 'react'

/**
 * Options to customise the `useAPIAction` hook behaviour.
 */
interface APIActionOptions {
  /** The message to display in the notification when the action is successful. No message is triggered if unset. */
  successMessage?: string
  /** The message to display in the notification when the action encounters an error. This will be used as the title, while
   * the API error message will be used as the body. */
  errorMessage?: string
  /** Determines whether notifications should be triggered on error. Defaults to `true`. .*/
  errorNotifications?: boolean
  /** Callback function to be executed when the action is successful */
  onSuccess?: () => void
  /** Callback function to be executed when the action encounters an error */
  onError?: () => void
  /** Callback function to be executed when the action is completed (regardless of success or error) */
  onFinally?: () => void
}

const defaultAPIActionOptions: APIActionOptions = {
  errorNotifications: true,
}

export const useAPIAction = <T = void>(
  action: () => Promise<T>,
  options?: APIActionOptions
) => {
  const notificationCentre = useNotificationCentre()
  const actionOptions = {
    ...defaultAPIActionOptions,
    ...options,
  }
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<APIError | null>(null)

  const perform = async () => {
    setIsLoading(true)
    try {
      await action()
      if (actionOptions.successMessage) {
        notificationCentre.addNotification({
          children: actionOptions.successMessage,
          variant: 'success',
        })
      }
      actionOptions.onSuccess?.()
    } catch (error) {
      let apiError: APIError
      if (error instanceof APIError) {
        apiError = error
      } else {
        apiError = new APIError(ErrorCode.Unknown)
      }
      if (actionOptions.errorNotifications) {
        notificationCentre.addNotification({
          title: actionOptions.errorMessage,
          children: apiError.humanText('de'),
          variant: 'error',
        })
      }
      setError(apiError)
      actionOptions.onError?.()
    } finally {
      setIsLoading(false)
      actionOptions.onFinally?.()
    }
  }

  return { perform, isLoading, error }
}
