import { Dialog, Text } from '@nextbusiness/infinity-ui'
import React from 'react'

interface CustomerInvoiceDiscardChangesConfirmationDialogProps {
  isOpen: boolean
  onDismiss: () => void
  onDiscardChanges: () => void
}

const CustomerInvoiceDiscardChangesConfirmationDialog = (
  props: CustomerInvoiceDiscardChangesConfirmationDialogProps
) => (
  <Dialog
    isOpen={props.isOpen}
    onDismiss={props.onDismiss}
    icon='warning'
    title='Änderungen verwerfen?'
    actions={[
      {
        children: 'Abbrechen',
        onClick: () => props.onDismiss(),
      },
      {
        children: 'Verwerfen',
        variant: 'destructive',
        onClick: () => props.onDiscardChanges(),
      },
    ]}
  >
    <Text>
      Wenn du fortfährst, werden deine bisherigen Änderungen verworfen.
    </Text>
  </Dialog>
)

export default CustomerInvoiceDiscardChangesConfirmationDialog
