import { TaxCode } from '@nextbusiness/infinity-finance-api'
import { IconItem } from '@nextbusiness/infinity-ui-icons'
import {
  descriptionForTaxCode,
  iconForTaxCode,
  nameForTaxCode,
  shortenedTaxCodeName,
  taxRateForTaxCode,
} from 'utility/TaxUtilities'
import { deduplicate } from 'utility/Utilities'

export type TaxRate = 7.7 | 3.7 | 2.5 | 8.1 | 3.8 | 2.6
export type TaxRateType = 'normal' | 'special' | 'reduced'
export type TaxRateEra = '2018-reform' | '2024-reform'

export const STRUCTURED_TAX_RATES = {
  '2018-reform': {
    normal: 7.7,
    special: 3.7,
    reduced: 2.5,
  },
  '2024-reform': {
    normal: 8.1,
    special: 3.8,
    reduced: 2.6,
  },
} as const

export interface ITaxOption {
  name: string
  shortenedName: string
  description?: string
  rate?: TaxRate
  code: TaxCode
  icon: IconItem
}

interface ITaxOptionGroup {
  title: string
  options: ITaxOption[]
}

export interface IStructuredTaxCodes {
  income: Record<string, TaxCode[]>
  expense: Record<string, TaxCode[]>
}

const STRUCTURED_TAX_CODES: IStructuredTaxCodes = {
  income: {
    Umsatz: [
      TaxCode.UN81,
      TaxCode.UN77,
      TaxCode.US38,
      TaxCode.US37,
      TaxCode.UR26,
      TaxCode.UR25,
    ],
    Ausland: [TaxCode.UEX, TaxCode.ULA],
    Keine: [TaxCode.UNO, TaxCode.None],
  },
  expense: {
    Vorsteuer: [
      TaxCode.VM81,
      TaxCode.VM77,
      TaxCode.VM38,
      TaxCode.VM37,
      TaxCode.VM26,
      TaxCode.VM25,
      TaxCode.VB81,
      TaxCode.VB77,
      TaxCode.VB38,
      TaxCode.VB37,
      TaxCode.VB26,
      TaxCode.VB25,
    ],
    Ausland: [TaxCode.ES81, TaxCode.ES77, TaxCode.ES26, TaxCode.ES25], // NOTE: BZB81 and BZB77 are currently not included because we don't have them right yet
    Keine: [TaxCode.None],
  },
}

const COMBINED_STRUCTURED_TAX_CODES = {
  Umsatz: STRUCTURED_TAX_CODES.income.Umsatz,
  Vorsteuer: STRUCTURED_TAX_CODES.expense.Vorsteuer,
  Ausland: deduplicate([
    ...STRUCTURED_TAX_CODES.income.Ausland,
    ...STRUCTURED_TAX_CODES.expense.Ausland,
  ]),
  Keine: deduplicate([
    ...STRUCTURED_TAX_CODES.income.Keine,
    ...STRUCTURED_TAX_CODES.expense.Keine,
  ]),
}

export const taxOptionFromTaxCode = (
  code: TaxCode,
  type?: keyof IStructuredTaxCodes
): ITaxOption => {
  return {
    name: nameForTaxCode(code) ?? code,
    shortenedName: shortenedTaxCodeName(code) ?? code,
    description: descriptionForTaxCode(code, type),
    code,
    rate: taxRateForTaxCode(code),
    icon: iconForTaxCode(code) ?? 'tax',
  }
}

export const taxOptionGroups = (filter?: {
  type?: keyof IStructuredTaxCodes
  taxRate?: TaxRate
  includeNoneOption?: boolean
}): ITaxOptionGroup[] => {
  const groups = Object.entries(
    filter?.type
      ? STRUCTURED_TAX_CODES[filter.type]
      : COMBINED_STRUCTURED_TAX_CODES
  )
  const taxCodeFilter = (code: TaxCode) => {
    if (!filter?.includeNoneOption && !code) return false
    const taxRate = taxRateForTaxCode(code)
    if (filter?.taxRate !== undefined)
      return taxRate === filter.taxRate || !taxRate
    else return true
  }

  return groups.map(([title, taxCodes]) => {
    const options = taxCodes
      .filter(taxCodeFilter)
      .map((code) => taxOptionFromTaxCode(code, filter?.type))
    return { title, options }
  })
}
