import { IProject } from '@nextbusiness/infinity-finance-api'
import {
  Dialog,
  Flex,
  InputField,
  Text,
  Textarea,
} from '@nextbusiness/infinity-ui'
import ContactSelect from 'components/contact-select/ContactSelect'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { CreateProjectModalProps } from './CreateProjectModal'
import './EditOrCreateProjectModal.scss'

interface EditOrCreateProjectModalProps extends CreateProjectModalProps {
  projectToBeModified?: IProject
  titleOfModal: string
  saveActionCopy: string
  /**
   * Can only be called when all required fields are filled out.
   */
  onSave: (title: string, description: string, client?: string) => Promise<void>
}

const EditOrCreateProjectModal = (props: EditOrCreateProjectModalProps) => {
  const { projectStore } = useRootStore()

  const [title, setTitle] = useState<string>(
    props.projectToBeModified?.title || ''
  )
  const [client, setClient] = useState<string | undefined>(
    props.options?.contact?.id ?? ''
  )

  const [description, setDescription] = useState<string>(
    props.projectToBeModified?.description ?? ''
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const isCreatingNewProject = !props.projectToBeModified
  const isMissingRequiredFields =
    !title.trim() || (isCreatingNewProject && !client)

  const saveAction = async () => {
    setIsLoading(true)
    await props.onSave(title.trim(), description.trim(), client)
    projectStore.fetchAllProjects()
    setIsLoading(false)
    props.onDismiss()
  }
  const saveOnEnter = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !isMissingRequiredFields) {
      saveAction()
    }
  }

  const resetState = () => {
    setTitle(props.projectToBeModified?.title || '')
    setClient(undefined)
    setDescription(props.projectToBeModified?.description || '')
  }

  useEffect(() => {
    resetState()
    if (props.options?.contact?.id) setClient(props.options.contact.id)
  }, [props.isOpen])

  return (
    <Dialog
      className='project-details-modal'
      title={props.titleOfModal}
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      actions={[
        {
          children: 'Abbrechen',
          onClick: props.onDismiss,
        },
        {
          children: props.saveActionCopy,
          onClick: saveAction,
          isLoading,
          disabled: isMissingRequiredFields,
          variant: 'primary',
        },
      ]}
    >
      <Flex className='project-detail-fields' direction='vertical' gap={1}>
        <Flex
          className='field title'
          direction='horizontal'
          verticalAlignment='center'
        >
          <Text className='label' type='inline'>
            Titel
          </Text>
          <InputField
            className='input-field'
            value={title}
            onChange={(title) => {
              if (title.length > 56) return
              setTitle(title)
            }}
            placeholder='Titel'
            onKeyDown={saveOnEnter}
          />
        </Flex>
        {isCreatingNewProject && (
          <Flex
            className='field client'
            direction='horizontal'
            verticalAlignment='center'
          >
            <Text className='label' type='inline'>
              Kunde
            </Text>
            <Flex className='client-input-wrapper'>
              <ContactSelect contactId={client} onChange={setClient} />
            </Flex>
          </Flex>
        )}
        <Flex
          className='field description'
          direction='horizontal'
          verticalAlignment='flex-start'
        >
          <Text className='label' type='inline'>
            Beschreibung
          </Text>
          <Flex className='description-input-wrapper'>
            <Textarea
              placeholder='Optional'
              value={description || ''}
              onChange={setDescription}
              onKeyDown={(event) =>
                (event.ctrlKey || event.metaKey) && saveOnEnter(event)
              }
            />
          </Flex>
        </Flex>
      </Flex>
    </Dialog>
  )
}

export default observer(EditOrCreateProjectModal)
