import { ITransaction } from '@nextbusiness/infinity-finance-api'
import { Checkbox } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import TransactionEditorUtilities from '../editor/TransactionEditorUtilities'
import { useBatchEditingContext } from './BatchEditingContext'

interface TransactionBatchSelectionCheckboxProps {
  transaction: ITransaction
  handleBatchEditSelection: (
    transaction: ITransaction,
    isTransactionSelected: boolean
  ) => void
}

const TransactionBatchSelectionCheckbox = (
  props: TransactionBatchSelectionCheckboxProps
) => {
  const { accountStore, vatPeriodStore } = useRootStore()
  const batchEditingContext = useBatchEditingContext()

  const isTransactionSelected =
    batchEditingContext.selectedTransactions.includes(props.transaction.id)

  return (
    <Checkbox
      className='batch-edit-selection-checkbox'
      isChecked={isTransactionSelected}
      onChange={() =>
        props.handleBatchEditSelection(props.transaction, isTransactionSelected)
      }
      isDisabled={TransactionEditorUtilities.isTransactionEditingRestricted(
        props.transaction,
        accountStore,
        vatPeriodStore
      )}
    />
  )
}

export default observer(TransactionBatchSelectionCheckbox)
