import { Text } from '@nextbusiness/infinity-ui'
import DateUtilities from 'utility/DateUtilites'
import Utilities from 'utility/Utilities'
import { LiveCaptureAttachmentChildProps } from './LiveCaptureAttachment'

const LiveCaptureAttachmentDetails = (
  props: LiveCaptureAttachmentChildProps
) => {
  const { documentDate, currency, amountTotal, documentId } =
    props.document.document.analysis
  const readableDate = documentDate
    ? DateUtilities.humanFormatNumeric(documentDate)
    : undefined
  const formattedAmount = Utilities.centsToCHF(amountTotal)
  const value = amountTotal ? `${currency ?? ''} ${formattedAmount}` : ''

  const details = [readableDate, value, documentId]
    .map((detail) => detail?.trim())
    .filter((detail) => !!detail)
    .join('  ・  ')

  return (
    <Text type='inline' className='document-remaining-details'>
      {details}
    </Text>
  )
}

export default LiveCaptureAttachmentDetails
