import { Flex, Text } from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import VendorInvoicePositionList from '../vendor-invoice-creation/VendorInvoicePositionList'
import { useVendorInvoiceEditor } from './VendorInvoiceEditorContext'

const VendorInvoiceDetailTabPositions = () => {
  const { accountStore } = useRootStore()
  const { isInvoiceDraftTouched, invoiceDraft, makeEdit } =
    useVendorInvoiceEditor()

  const hasTransactions =
    !!invoiceDraft.paymentTransactions?.length ||
    !!invoiceDraft.discountTransactions?.length
  const isPastFiscalYear =
    !!invoiceDraft.openingDate &&
    !!accountStore.currentFiscalYear &&
    invoiceDraft.openingDate < accountStore.currentFiscalYear.from

  const isInEditMode = !!isInvoiceDraftTouched
  const isReadOnly = (hasTransactions || isPastFiscalYear) && !isInEditMode

  return (
    <>
      {isReadOnly && (
        <Flex className='no-edit-message' verticalAlignment='center' gap='tiny'>
          <Icon icon='no-edit' size={IconSize.Small} tint='#767676' />
          <Text type='inline' variant='subtle'>
            {isPastFiscalYear
              ? 'Diese Rechnung kann nicht mehr bearbeitet werden, da sie in einem geschlossenen Geschäftsjahr eröffnet wurde.'
              : hasTransactions
              ? 'Rechnungen mit Zahlungen oder Rabatten können nicht mehr bearbeitet werden.'
              : 'Diese Rechnung kann nicht mehr bearbeitet werden.'}
          </Text>
        </Flex>
      )}
      <VendorInvoicePositionList
        showTotal
        positions={invoiceDraft.positions}
        setPositions={(positions) => makeEdit({ positions })}
        isReadOnly={isReadOnly}
      />
    </>
  )
}

export default observer(VendorInvoiceDetailTabPositions)
