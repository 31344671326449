import { Button, Flex, Text } from '@nextbusiness/infinity-ui'
import { IconSize } from '@nextbusiness/infinity-ui-icons'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react'
import { IAccount } from 'model/Account'
import { useEffect, useRef } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import StringUtilities from 'utility/StringUtilities'

interface AccountOptionProps extends Partial<IAccount> {
  id: string
  index: number
  isActive: boolean
  activeOption: number | undefined
  enteredValue: string
  onClick: () => void
  onMouseEnter: () => void
  onMouseLeave: () => void
  onOpenAccountClicked?: () => void
  hasSearched?: boolean
}

const AccountOption = (props: AccountOptionProps) => {
  const { preferencesStore } = useRootStore()
  const accountOptionRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (props.isActive) {
      accountOptionRef.current?.addEventListener('mouseover', () =>
        props.onMouseEnter()
      )
      accountOptionRef.current?.addEventListener('mouseleave', () =>
        props.onMouseLeave()
      )
    }

    return () => {
      accountOptionRef.current?.removeEventListener('mouseover', () =>
        props.onMouseEnter()
      )
      accountOptionRef.current?.removeEventListener('mouseleave', () =>
        props.onMouseLeave()
      )
    }
  }, [props.isActive, props.enteredValue])

  const isSelected =
    props.enteredValue.toLowerCase() === props.name?.toLowerCase()

  const dynamicSearchResult = () => {
    const [, indicesOfRelevantLetters] = StringUtilities.rateResultBySearch(
      props.name ?? '',
      props.enteredValue.toLowerCase().trim()
    )

    return (
      <Text type='inline' className='name'>
        {props.hasSearched
          ? props.name?.split('').map((letter, index) =>
              indicesOfRelevantLetters.includes(index) ? (
                <span
                  key={letter + index.toString()}
                  className='highlighted-letter'
                >
                  {letter}
                </span>
              ) : (
                letter
              )
            )
          : props.name}
      </Text>
    )
  }

  return (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events
    <div
      className={`account-option-button ui-button shell ${
        props.activeOption === props.index ? 'active' : ''
      } ${isSelected ? 'selected' : ''}`.trim()}
      onClick={props.onClick}
      ref={accountOptionRef}
      data-id={props.id}
      role='menuitem'
    >
      <Flex className='account-option' horizontalAlignment='space-between'>
        <div
          className='color-swatch'
          style={{ backgroundColor: props.colour ?? 'grey' }}
        />
        {preferencesStore.showAccountNumbers && (
          <div className='account-number'>{props.accountNumber}</div>
        )}
        <Flex className='text' direction='vertical'>
          {dynamicSearchResult()}
          {props.description && (
            <Text type='inline' className='description' variant='subtle'>
              {props.description}
            </Text>
          )}
        </Flex>
        {props.onOpenAccountClicked && (
          <Button
            className='icon'
            variant='quaternary'
            iconOnly='enlarge'
            iconSize={IconSize.Tiny}
            aria-label='Konto anzeigen'
            onClick={
              ((e: Event) => {
                e.stopPropagation()
                MixpanelAnalytics.event('Account opened - from account select')
                if (props.onOpenAccountClicked) props.onOpenAccountClicked()
              }) as () => void
            }
          />
        )}
      </Flex>
    </div>
  )
}

export default observer(AccountOption)
