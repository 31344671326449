import {
  INewTransaction,
  ITransaction,
} from '@nextbusiness/infinity-finance-api'
import { useAccountPageContext } from 'ledger/accounts/AccountPageContext'
import { useAccountSheetContext } from 'ledger/accounts/AccountSheetContext'
import { observer } from 'mobx-react'
import { useCallback, useMemo, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { TransactionCreatorProps } from './TransactionCreator'
import {
  TransactionCreatorContext,
  TransactionCreatorContextType,
} from './TransactionCreatorContext'
import useAutoSuggestVAT from './hooks/useAutoSuggestVAT'
import useCorrectTransactionSides from './hooks/useCorrectTransactionSides'
import useCreateTransaction from './hooks/useCreateTransaction'
import useMarkUnpostedTransaction from './hooks/useMarkUnpostedTransaction'
import useTransactionCreatorUtilities from './hooks/useTransactionCreatorUtilities'

const TransactionCreatorProvider = (
  props: React.PropsWithChildren<TransactionCreatorProps>
) => {
  const { ledgerStore, accountStore, preferencesStore } = useRootStore()
  const { viewId } = useAccountPageContext()
  const { setIsTransactionCreatorOpen, highlightTransactionWithIds } =
    useAccountSheetContext()

  const { activeAction, setActiveAction, setShouldCurrentAccountIncrease } =
    props
  const utilities = useTransactionCreatorUtilities(props)

  const [isCreating, setIsCreating] = useState<boolean>(false)
  const [numberKey, setNumberKey] = useState<number>(0)
  const [transaction, setTransaction] = useState<Partial<INewTransaction>>(
    utilities.emptyTransaction()
  )
  const updateTransaction = (changes: Partial<INewTransaction>) =>
    setTransaction({ ...transaction, ...changes })
  const resetTransaction = (withChanges: Partial<INewTransaction>) =>
    setTransaction({ ...utilities.emptyTransaction(), ...withChanges })

  useCorrectTransactionSides(transaction, updateTransaction, utilities, props)
  useAutoSuggestVAT(
    transaction,
    updateTransaction,
    utilities.contraAccountSide,
    accountStore,
    preferencesStore
  )
  useMarkUnpostedTransaction(transaction, utilities)

  const closeTransactionCreator = useCallback(() => {
    ledgerStore.resolveUnpostedTransaction(viewId)
    setIsTransactionCreatorOpen(false)
  }, [setIsTransactionCreatorOpen])

  const onTransactionCreated = useCallback(
    (transactions: ITransaction[]) => {
      const transactionIds = transactions.map(
        (createdTransaction) => createdTransaction.id
      )
      resetTransaction({ date: transaction.date })
      setNumberKey(numberKey + 1)
      highlightTransactionWithIds(transactionIds)
      closeTransactionCreator()
    },
    [closeTransactionCreator, highlightTransactionWithIds]
  )
  const createTransaction = useCreateTransaction(
    transaction,
    onTransactionCreated,
    setIsCreating,
    utilities
  )

  const value: TransactionCreatorContextType = useMemo(
    () => ({
      numberKey,
      transaction,
      utilities,
      activeAction,
      changeAction: setActiveAction,
      isCreating,
      incrementNumberKey: () => setNumberKey(numberKey + 1),
      updateTransaction,
      closeTransactionCreator,
      setShouldCurrentAccountIncrease,
      createTransaction,
    }),
    [
      numberKey,
      transaction,
      utilities,
      isCreating,
      activeAction,
      setActiveAction,
      closeTransactionCreator,
      setShouldCurrentAccountIncrease,
      createTransaction,
    ]
  )

  return (
    <TransactionCreatorContext.Provider value={value}>
      {props.children}
    </TransactionCreatorContext.Provider>
  )
}

export default observer(TransactionCreatorProvider)
