import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { ICustomerInvoicePreview } from '../../../model/CustomerInvoice'
import { useRootStore } from '../../../stores/RootStoreContext'
import ExtendedInvoiceListSection from '../../shared-components/large-invoice-list/ExtendedInvoiceListSection'
import ExtendedCustomerInvoiceListItem from './ExtendedCustomerInvoiceListItem'

const UnpaidCustomerInvoices = () => {
  const { customerInvoiceStore } = useRootStore()
  const history = useHistory()

  // Due to a similar issue as with the vendor invoices, we can't use
  // customerInvoiceStore.unpaidCustomerInvoices here, because the filter
  // does not work correctly in the backend.
  // Remove this once IYFB-213 (https://nxb.atlassian.net/browse/IYFB-213) is fixed.
  const unpaidInvoices = customerInvoiceStore.allCustomerInvoices?.filter(
    (invoice) => !invoice.isPaid
  )

  return (
    <ExtendedInvoiceListSection
      isLoading={!unpaidInvoices}
      isEmpty={unpaidInvoices?.length === 0}
      emptyStateContent='Alle Forderungen wurden bezahlt'
    >
      {unpaidInvoices?.map((invoice: ICustomerInvoicePreview) => (
        <ExtendedCustomerInvoiceListItem
          key={invoice.id}
          invoice={invoice}
          onClick={() => history.push(`/forderungen/detail/${invoice.id}`)}
        />
      ))}
    </ExtendedInvoiceListSection>
  )
}

export default observer(UnpaidCustomerInvoices)
