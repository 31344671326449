import { Flex } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import React from 'react'
import './ProjectViewContentBox.scss'

interface ProjectViewContentBoxProps {
  children: React.ReactNode
  isEmpty?: boolean
}

const ProjectViewContentBox = (props: ProjectViewContentBoxProps) => (
  <div
    className={classNames(
      'project-content-box',
      props.isEmpty && 'empty-state'
    )}
  >
    <Flex className='inner-list' gap={0.1} direction='vertical' fillContainer>
      {props.children}
    </Flex>
  </div>
)

export default ProjectViewContentBox
