import { InputField, InputFieldProps } from '@nextbusiness/infinity-ui'
import { useEffect, useRef, useState } from 'react'
import { useIsFirstRender } from 'utility/hooks'
import './ColourInput.scss'

interface ColourInputProps {
  selectedColour: string
  setSelectedColour: (selectedColour: string) => void
  inputFieldProps?: Partial<InputFieldProps>
}

const ColourInput = (props: ColourInputProps) => {
  const isFirstRender = useIsFirstRender()
  const [input, setInput] = useState<string>(props.selectedColour)
  const inputRef = useRef<HTMLInputElement>(null)
  const isHexColour = (colour: string) => /^#?[0-9A-F]{6}$/i.test(colour)
  const inputIsHexColour = isHexColour(input)

  useEffect(() => {
    if (!isFirstRender && isHexColour(input)) {
      const hexCode = input.startsWith('#') ? input : `#${input}`
      props.setSelectedColour(hexCode.toUpperCase())
    }
  }, [input])

  useEffect(() => {
    setInput(props.selectedColour)
  }, [props.selectedColour])

  return (
    <div className='colour-input'>
      <div
        className='colour-swatch'
        onClick={() => inputRef.current?.focus()}
        style={{
          backgroundColor: props.selectedColour,
          boxShadow:
            props.selectedColour === '#FFFFFF'
              ? '0 0 0.1rem 0.05rem rgba(0,0,0,0.2)'
              : undefined,
        }}
        aria-hidden
      />
      <InputField
        ref={inputRef}
        placeholder={props.selectedColour}
        className='colour-input-field'
        value={input}
        onChange={(input) => {
          setInput(input)
        }}
        fullWidth
        hasError={!inputIsHexColour}
        helperText={!inputIsHexColour ? 'Ungültiger Farbcode' : ''}
        {...props.inputFieldProps}
      />
    </div>
  )
}

export default ColourInput
