import { Button } from '@nextbusiness/infinity-ui'
import { useLedgerContext } from 'ledger/LedgerContext'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'

export const OPENING_ACCOUNT_NUMBER = 9100

const OpeningSpecialAccountActions = () => {
  const { openSetupAccountBalancesPage } = useLedgerContext()
  const { transactionStore, accountStore } = useRootStore()

  const hasTransactions =
    !!transactionStore.transactions[OPENING_ACCOUNT_NUMBER]?.length
  const isFirstYear = accountStore.ealiestOpenFiscalYear?.year === 0

  const disabledReason = () => {
    if (!isFirstYear)
      return 'Die Buchhaltung kann nur im ersten Geschäftsjahr eröffnet werden. Dieses ist bereits abgeschlossen.'
    if (hasTransactions)
      return 'Es sind bereits Eröffnungsbuchungen vorhanden. Lösche diese, um die Buchhaltung erneut zu eröffnen.'
  }

  return (
    <Button
      onClick={openSetupAccountBalancesPage}
      iconLeft='firework-explosion'
      disabled={!!disabledReason()}
      tooltip={disabledReason()}
    >
      Buchhaltung eröffnen
    </Button>
  )
}

export default observer(OpeningSpecialAccountActions)
