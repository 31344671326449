import { Finance, IDocument } from '@nextbusiness/infinity-finance-api'
import { Dialog } from '@nextbusiness/infinity-ui'
import { CurrentLedgerNavigationStackRef } from 'ledger/LedgerNavigationStack'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import { useAPIAction } from 'utility/hooks/useAPIAction'

interface RenameDocumentModalProps {
  isOpen: boolean
  document: IDocument
  closeModal: () => void
  reloadDocument: () => Promise<void>
}

const RenameDocumentModal = (props: RenameDocumentModalProps) => {
  const { documentStore, transactionStore } = useRootStore()

  const unlinkDocumentFromTransaction = async () => {
    const linkedTransaction = transactionStore.allTransactions.find(
      (transaction) => transaction.attachments?.includes(props.document.id)
    )
    if (!linkedTransaction) return
    await transactionStore.refreshTransactions([linkedTransaction.id])
  }

  const unfileDocument = useAPIAction(
    () => Finance.Documents.unfileDocument(props.document.id),
    {
      onSuccess: async () => {
        props.reloadDocument()
        await unlinkDocumentFromTransaction()
        CurrentLedgerNavigationStackRef?.popCurrentView()
      },
      onFinally: () => {
        documentStore.loadAllDocuments()
        props.closeModal()
      },
      successMessage: 'Beleg erfolgreich zurückgelegt',
      errorMessage: 'Beleg konnte nicht zurückgelegt werden',
    }
  )

  return (
    <Dialog
      isOpen={props.isOpen}
      title='Beleg zurücklegen'
      titleProps={{ divider: true }}
      initialFocusIndex={1}
      actions={[
        {
          children: 'Abbrechen',
          onClick: props.closeModal,
        },
        {
          variant: 'destructive',
          children: 'Fortfahren',
          isLoading: unfileDocument.isLoading,
          onClick: unfileDocument.perform,
        },
      ]}
    >
      Dieser Beleg wird von der zugehörigen Transaktion oder Rechnung abgehängt
      und zurück in die Inbox gelegt.
    </Dialog>
  )
}

export default observer(RenameDocumentModal)
