import { NumberField } from '@nextbusiness/infinity-ui'
import { useTransactionCreatorContext } from '../TransactionCreatorContext'

const TransactionCreatorExchangeRate = () => {
  const { transaction, updateTransaction, createTransaction } =
    useTransactionCreatorContext()
  if (!transaction.originalCurrency) return null
  return (
    <NumberField
      className='transaction-creator-exchange-rate'
      value={transaction.exchangeRate ?? null}
      onChange={(value) =>
        updateTransaction({
          exchangeRate: value ?? undefined,
        })
      }
      onKeyDown={(e) => {
        if (e.key === 'Enter') createTransaction()
      }}
      fullWidth
      decimalPlaces={6}
      formatToDecimalPlaces={6}
      icon='available-updates'
    />
  )
}

export default TransactionCreatorExchangeRate
