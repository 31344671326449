import {
  Button,
  DatePicker,
  Flyout,
  MenuItems,
} from '@nextbusiness/infinity-ui'
import { IconItem } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import { useContext, useEffect, useRef, useState } from 'react'
import CustomerInvoiceCustomPaymentTermDialog from '../../CustomerInvoiceCustomPaymentTermDialog'
import DocumentRendererContext from '../DocumentRendererContext'
import DocumentElementInvoiceNumber from './DocumentElementInvoiceNumber'
import DocumentElementProps from './DocumentElementProps'

const DEFAULT_PAYMENT_TERMS = [14, 30, 60, 90]

const DocumentElementInfoBlock = (props: DocumentElementProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const renderContext = useContext(DocumentRendererContext)

  const [isPaymentTermsFlyoutOpen, setIsPaymentTermsFlyoutOpen] =
    useState<boolean>(false)
  const [isCustomPaymentTermDialogOpen, setIsCustomPaymentTermDialogOpen] =
    useState<boolean>(false)

  const openingDate = props.document.openingDate ?? Date.now()
  const today = DateTime.fromMillis(openingDate).toLocaleString({
    dateStyle: 'long',
    locale: 'de-CH',
  })

  useEffect(() => {
    const infoBlock = ref.current
    if (infoBlock) {
      const infoBlockStyle = window.getComputedStyle(infoBlock)
      const margin = parseFloat(infoBlockStyle.marginBottom)

      renderContext.updateHeights({
        infoBlock: infoBlock.clientHeight + margin,
      })
    }
  }, [props.document])

  return (
    <div
      className={classNames('page-info-block', {
        disableBackground: props.template.disableInfoBlockBackground,
      })}
      ref={ref}
    >
      <div className='document-opening-date'>
        <span className='opening-date-text'>{today}</span>
        <DatePicker
          inputFieldProps={{ disabled: !props.isEditable }}
          className='document-editor-date-picker'
          value={new Date(openingDate)}
          onChange={(date) =>
            props.registerEdit({ openingDate: date ? +date : undefined })
          }
          quickDates
        />
      </div>
      <DocumentElementInvoiceNumber {...props} />
      <Flyout
        isActive={isPaymentTermsFlyoutOpen}
        setIsActive={setIsPaymentTermsFlyoutOpen}
        className='document-payment-terms'
        trigger={
          <Button
            disabled={!props.isEditable}
            className='payment-terms-text'
            variant='quaternary'
            onClick={() => setIsPaymentTermsFlyoutOpen(true)}
            tooltip={
              props.document.isQuote
                ? 'Gültigkeitsdauer ändern'
                : 'Zahlungsfrist ändern'
            }
          >
            {props.document.isQuote ? 'Gültig für' : 'Zahlbar innert'}{' '}
            {props.document.payableInDays ?? '–'}{' '}
            {(props.document.payableInDays || 0) > 1
              ? props.document.isQuote
                ? 'Tage'
                : 'Tagen'
              : 'Tag'}
          </Button>
        }
        triggerIsButton
      >
        <MenuItems
          closeMenu={() => setIsPaymentTermsFlyoutOpen(false)}
          groups={[
            {
              title: props.document.isQuote
                ? 'Offerte gültig für'
                : 'Zahlungsfrist',
              buttons: [
                ...DEFAULT_PAYMENT_TERMS.map((paymentTerm) => ({
                  text: `${paymentTerm} Tage`,
                  icon: 'hourglass' as IconItem,
                  onClick: () => {
                    setIsPaymentTermsFlyoutOpen(false)
                    props.registerEdit({ payableInDays: paymentTerm })
                  },
                })),
                {
                  text: 'Eigene Frist',
                  icon: 'more',
                  onClick: () => setIsCustomPaymentTermDialogOpen(true),
                },
              ],
            },
          ]}
        />
      </Flyout>
      <CustomerInvoiceCustomPaymentTermDialog
        isOpen={isCustomPaymentTermDialogOpen}
        onDismiss={() => setIsCustomPaymentTermDialogOpen(false)}
        openingDate={openingDate}
        onSelectCustomTerm={(numberOfDays) =>
          props.registerEdit({ payableInDays: numberOfDays })
        }
        isQuote={props.document.isQuote}
      />
    </div>
  )
}

export default DocumentElementInfoBlock
