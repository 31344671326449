import AccountSelect from 'ledger/accounts/account-select/AccountSelect'
import { ResolvedSetOfSuggestedAccounts } from 'ledger/accounts/data/AccountRules'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import { DocumentPositionFlyoutProps } from '../flyout/DocumentPositionFlyout'
import TaxUtilities from 'utility/TaxUtilities'

const suggestedAccounts: ResolvedSetOfSuggestedAccounts = [
  { title: 'Erträge', accounts: ['income'] },
]

const PositionContraAccount = (props: DocumentPositionFlyoutProps) => {
  const { preferencesStore, accountStore } = useRootStore()
  if (props.position.type !== 'custom-product') return null
  return (
    <AccountSelect
      // Force rerender after product is selected because AccountSelect does
      // not properly reset after initialAccountNumber is changed
      key={props.position.product}
      currentAccountNumber={1100}
      initialAccountNumber={props.position.contraAccount}
      onChange={(contraAccount) => {
        props.onChangePosition(props.position.identifier, {
          contraAccount,
          taxCode:
            TaxUtilities.autoSuggestVAT(
              {
                number: contraAccount,
                account: accountStore.find(contraAccount),
              },
              preferencesStore.organisationPreferences,
              props.openingDate
            ) ?? undefined,
        })
        props.setIsActive(false)
      }}
      suggestions={suggestedAccounts}
      placeholderText='Ertragskonto'
      onlyAllowSuggestedAccounts
      allowAccountCreation
    />
  )
}

export default observer(PositionContraAccount)
