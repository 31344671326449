import {
  AccountMethod,
  APIError,
  Finance,
} from '@nextbusiness/infinity-finance-api'
import {
  Dialog,
  FeedbackBanner,
  Flex,
  LoadingIndicator,
  Spacer,
  Text,
} from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react'
import { IAccount } from 'model/Account'
import { useCallback, useEffect, useState } from 'react'
import LedgerStore from 'stores/LedgerStore'
import { useRootStore } from 'stores/RootStoreContext'
import { truncateString } from 'utility/Utilities'
import AccountSelect from '../account-select/AccountSelect'
import './DeleteAccountModal.scss'

interface DeleteAccountModalProps {
  account: IAccount
  isOpen: boolean
  closeModal: () => void
  onDeleteCompleted: (usingReplacementAccount?: number) => void
}

const DeleteAccountModal = (props: DeleteAccountModalProps) => {
  const [isCheckingTransactions, setIsCheckingTransactions] =
    useState<boolean>(true)
  const { accountStore, ledgerStore, transactionStore } = useRootStore()

  const [selectedReplacementAccount, setSelectedReplacementAccount] =
    useState<number>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [errorMessage, setErrorMessage] = useState<string>()
  const [isAlreadyInUse, setIsAlreadyInUse] = useState<boolean>()
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)

  const deleteDisabledReason = () => {
    if (props.account.isSystemAccount)
      return 'Systemkonten können nicht gelöscht werden.'
    if (props.account.accountNumber === LedgerStore.INITIAL_DEFAULT_ROOT_VIEW)
      return 'Das Standard-Bankkonto kann nicht gelöscht werden.'
    if (props.account.accountMethod === AccountMethod.Live)
      return 'Dieses Konto ist noch mit einem Bankkonto verbunden. Trenne zuerst die Verbindung.'
    return undefined
  }
  const canDelete = !deleteDisabledReason()

  const loadTransactions = useCallback(async () => {
    setIsCheckingTransactions(true)
    setIsAlreadyInUse(false)
    await transactionStore.loadTransactionsForAccount(
      props.account.accountNumber
    )
    setSelectedReplacementAccount(undefined)
    setIsAlreadyInUse(
      (transactionStore.transactions[props.account.accountNumber]?.length ??
        1) > 0
    )
    setIsCheckingTransactions(false)
  }, [props.account.accountNumber])

  useEffect(() => {
    if (props.isOpen) loadTransactions()
  }, [props.isOpen, loadTransactions])

  const deleteAccount = async () => {
    setShouldValidate(true)

    if (isCheckingTransactions) return
    if (isAlreadyInUse && !selectedReplacementAccount) return
    if (selectedReplacementAccount === props.account.accountNumber) return

    setIsLoading(true)
    try {
      await Finance.Ledger.deleteAccount(
        props.account.id,
        selectedReplacementAccount
      )
      await accountStore.loadAccounts()
      ledgerStore.removeFromRecents(props.account.accountNumber)
      props.closeModal()
      props.onDeleteCompleted(selectedReplacementAccount)
      MixpanelAnalytics.event('Account deleted', {
        accountNumber: props.account.accountNumber,
        usingReplacement: !!selectedReplacementAccount,
      })
    } catch (error: any) {
      setErrorMessage((error as APIError).humanText('de'))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Dialog
        className='delete-account-modal'
        isOpen={props.isOpen}
        onDismiss={props.closeModal}
        title={`Konto ${
          props.account.name || props.account.accountNumber
        } löschen`}
        icon='delete'
        actions={[
          {
            children: 'Abbrechen',
            onClick: () => props.closeModal(),
          },
          {
            children: 'Konto löschen',
            variant: 'destructive',
            disabled: !canDelete || isCheckingTransactions,
            onClick: () => deleteAccount(),
          },
        ]}
      >
        <Text type='paragraph'>
          Möchtest du dieses Konto wirklich unwiderruflich löschen?
        </Text>
        {isCheckingTransactions && <LoadingIndicator />}
        {isAlreadyInUse && (
          <div className='account-replacement'>
            <Text type='paragraph'>
              In diesem Konto befinden sich bereits Transaktionen im aktuellen
              Geschäftsjahr. Wähle ein anderes Konto aus, in welches diese
              Transaktionen verschoben werden sollen.
            </Text>
            <Flex verticalAlignment='center'>
              <div className='account-current'>
                <div
                  className='colour-swatch'
                  style={{ backgroundColor: props.account.colour ?? 'grey' }}
                />
                {truncateString(
                  props.account.name ?? `Konto ${props.account.accountNumber}`,
                  19
                )}
              </div>
              <Icon
                icon='arrow-right'
                size={IconSize.Small}
                className='to-icon'
              />
              <AccountSelect
                currentAccountNumber={props.account.accountNumber}
                onChange={(selectedAccountNumber: number | undefined) =>
                  setSelectedReplacementAccount(selectedAccountNumber)
                }
                suggestions={[]}
                allowAccountCreation
                inputFieldProps={{
                  hasError: shouldValidate && !selectedReplacementAccount,
                }}
              />
            </Flex>
            {selectedReplacementAccount && (
              <Text type='paragraph'>
                Die betroffenen Transaktionen werden ins Konto{` `}
                {accountStore.find(selectedReplacementAccount)?.name ??
                  selectedReplacementAccount}
                {` `}verschoben.
              </Text>
            )}
          </div>
        )}
        {!canDelete && (
          <FeedbackBanner variant='warning'>
            {deleteDisabledReason()}
          </FeedbackBanner>
        )}
        {errorMessage && (
          <FeedbackBanner variant='error' title='Fehler beim Löschen'>
            {errorMessage}
          </FeedbackBanner>
        )}
      </Dialog>
      <Dialog className='loading-dialog' isOpen={isLoading}>
        <Spacer direction='vertical' />
        <LoadingIndicator loadingText='Konto wird gelöscht.' />
        <Spacer direction='vertical' />
      </Dialog>
    </>
  )
}

export default observer(DeleteAccountModal)
