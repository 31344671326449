import FinancialHealthMetric from '../FinancialHealthMetric'
import { FinancialHealthAssessment } from '../FinancialHealthAssessment'

class MetricReturnOnEquity extends FinancialHealthMetric {
  title = 'Eigenkapitalrendite'
  description = 'Erzielte Rendite auf dem investierten Eigenkapital'

  getAssessment = (): FinancialHealthAssessment =>
    FinancialHealthMetric.assessmentFromStatus(
      this.context.profitability?.returnOnEquity.status
    )

  getStatusText = (): string =>
    this.context.profitability?.returnOnEquity.status ?? 'Keine Angabe'

  getActualValue = () => {
    const roe = this.context.profitability?.returnOnEquity.currentYear ?? 0
    return `${(roe * 100).toFixed(1)}%`
  }
}

export default MetricReturnOnEquity
