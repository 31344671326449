import {
  AccountMethod,
  ErrorCode,
  Finance,
} from '@nextbusiness/infinity-finance-api'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import { ErrorType, isBackendError } from '../libs/networking/Errors'
import { AccountType, IAccount } from '../model/Account'
import { IFiscalYear } from '../model/FiscalYear'
import Accounts from '../networking/Accounts'
import FiscalYears from '../networking/FiscalYears'
import RootStore from './RootStore'
import Store from './Store'

export default class AccountStore extends Store {
  @observable isLoadingAccounts = false
  @observable isLoadingFiscalYears = false

  @persist('list') @observable allAccounts: IAccount[] = []
  @observable accountsInPreviousYears: { [year: number]: IAccount[] } = {}

  @observable specificAccounts: IAccount[] = []
  @observable currentlySyncingAccountIds: Set<string> = new Set<string>()

  @persist('object') @observable currentFiscalYear?: IFiscalYear
  @persist('object') @observable allFiscalYears?: IFiscalYear[]
  @observable noFiscalYearAvailable?: boolean

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  onHydrate() {
    this.getFiscalYear()
  }

  @action
  reset() {
    this.allAccounts = []
    this.specificAccounts = []

    this.currentFiscalYear = undefined
    this.allFiscalYears = undefined
  }

  fiscalYear(year: number) {
    return this.allFiscalYears?.find((fiscalYear) => fiscalYear.year === year)
  }

  isDateWithinOpenFiscalYear(date: Date) {
    const fiscalYears = this.allFiscalYears ?? []
    return fiscalYears.some((fiscalYear) => {
      if (fiscalYear.isClosed) return false
      const startDate = new Date(fiscalYear.from)
      const endDate = new Date(fiscalYear.to)

      return date >= startDate && date <= endDate
    })
  }

  onFiscalYearReady() {
    if (!this.currentFiscalYear) return
    this.loadAccounts()
  }

  async getFiscalYear() {
    if (!this.rootStore.authenticationStore.organisationIdentifier)
      return this.rootStore.authenticationStore.logout()
    if (!this.rootStore.authenticationStore.applicationAccessToken) return

    this.getAllFiscalYears()
    try {
      const fiscalYear = await FiscalYears.getFiscalYear(
        this.rootStore.authenticationStore.organisationIdentifier
      )
      runInAction(() => {
        this.currentFiscalYear = fiscalYear
        this.noFiscalYearAvailable = false
        this.onFiscalYearReady()
      })
    } catch (error: any) {
      if (isBackendError(error)) {
        if (error.type === ErrorType.NotFound) {
          runInAction(() => {
            this.currentFiscalYear = undefined
            this.noFiscalYearAvailable = true
          })
        }
      } else if (error.code === ErrorCode.Unauthorised) {
        // We already logout the user when the session has expired in
        // Fabric, but we also need to logout the user if the Finance
        // token has expired (these might be different).
        this.rootStore.authenticationStore.logout()
      }
    }
  }

  async getAllFiscalYears() {
    if (this.isLoadingFiscalYears) return
    this.isLoadingFiscalYears = true
    try {
      const fiscalYears = await FiscalYears.listAllFiscalYears(
        this.rootStore.authenticationStore.organisationIdentifier
      )
      runInAction(() => {
        this.allFiscalYears = fiscalYears
        this.isLoadingFiscalYears = false
      })
    } catch {
      // We already handle all necessary cases in getFiscalYear.
      runInAction(() => {
        this.isLoadingFiscalYears = false
      })
    }
  }

  @computed
  get ealiestOpenFiscalYear() {
    const sortedYears = [...(this.allFiscalYears ?? [])].sort(
      (a, b) => a.year - b.year
    )
    return sortedYears.find((fiscalYear) => !fiscalYear.isClosed)
  }

  @computed
  get anyFiscalYearDueForClose() {
    if (!this.allFiscalYears) return false
    return this.allFiscalYears.some((fiscalYear) => {
      return !fiscalYear.isClosed && fiscalYear.to < Date.now()
    })
  }

  @action
  async loadAccounts() {
    if (!this.rootStore.authenticationStore.isHydrated) return
    if (!this.rootStore.authenticationStore.organisationIdentifier)
      return this.rootStore.authenticationStore.logout()

    this.isLoadingAccounts = true
    const loadedAccounts = await Finance.Ledger.allOpenAccounts()
    loadedAccounts.sort((a, b) => a.accountNumber - b.accountNumber)
    runInAction(() => {
      this.isLoadingAccounts = false
      this.allAccounts = loadedAccounts
      if (
        this.allAccounts.length === 0 &&
        !document.location.pathname.startsWith('/setup')
      )
        document.location.href = '/setup'
    })
  }

  async loadAccountsForYear(year: number) {
    if (!this.rootStore.authenticationStore.isHydrated) return
    if (!this.rootStore.authenticationStore.organisationIdentifier)
      return this.rootStore.authenticationStore.logout()

    const loadedAccounts = await Accounts.getAccounts(
      this.rootStore.authenticationStore.organisationIdentifier,
      year,
      year
    )
    loadedAccounts.sort((a, b) => a.accountNumber - b.accountNumber)
    runInAction(() => {
      this.specificAccounts = loadedAccounts
    })
  }

  find(
    accountNumber: number | undefined,
    types?: AccountType[] | AccountType
  ): IAccount | undefined {
    if (!accountNumber) return undefined

    let haystack = this.allAccounts
    if (types) haystack = this.accountsOfType(types) || []

    return haystack.find((account) => account.accountNumber === accountNumber)
  }

  accountsOfType(
    accountTypes?: AccountType[] | AccountType,
    currentAccountNumber?: number
  ): IAccount[] {
    if (!accountTypes) return []
    if (!Array.isArray(accountTypes)) accountTypes = [accountTypes]

    const result: IAccount[] = []

    accountTypes.forEach((accountType) =>
      result.push(
        ...this.allAccounts.filter(
          (account) =>
            account.accountType === accountType &&
            account.accountNumber !== currentAccountNumber
        )
      )
    )
    return result
  }

  findAccountsWithName = (name: string): IAccount[] =>
    name
      ? this.allAccounts.filter(
          (account) => account.name.toLowerCase() === name.toLowerCase()
        )
      : []

  public newestAccountWithNumber(accountNumber: number): IAccount | undefined {
    const accountsWithNumber = this.allAccounts.filter(
      (account) => account.accountNumber === accountNumber
    )
    if (accountsWithNumber.length) {
      return accountsWithNumber.sort((a, b) => b.fiscalYear - a.fiscalYear)[0]
    } else {
      return undefined
    }
  }

  @computed
  get hasLiveAccounts() {
    return this.allAccounts.some(
      (account) =>
        account.accountMethod === AccountMethod.Live && account.bankAccountId
    )
  }

  @action
  public async syncAccount(account: IAccount, from: Date, to: Date) {
    if (this.currentlySyncingAccountIds.has(account.id)) return

    this.currentlySyncingAccountIds.add(account.id)
    try {
      await Finance.Ledger.syncLiveAccount(
        account.id,
        from.getTime(),
        to.getTime()
      )
    } catch (error) {
      // Fail silently (for now)
      console.error(
        `Failed to sync transactions for account ${account.id}`,
        error
      )
    } finally {
      await Promise.all([
        this.rootStore.transactionStore.loadTransactionsForAccount(
          account.accountNumber
        ),
        this.loadAccounts(),
      ])
      this.currentlySyncingAccountIds.delete(account.id)
    }
  }
}
