import { ITemplate } from '@nextbusiness/infinity-finance-api'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { ITemplateDraft, ITemplatePreview } from '../model/Template'
import Templates from '../networking/Templates'
import RootStore from './RootStore'
import Store from './Store'

export default class TemplateStore extends Store {
  @observable templates: ITemplatePreview[] | undefined
  @observable currentTemplate: ITemplate | undefined

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  loadTemplates = async () => {
    const templates = await Templates.getTemplates(
      this.rootStore.authenticationStore.organisationIdentifier
    )
    runInAction(() => {
      this.templates = templates
    })
  }

  loadCurrentTemplate = async () => {
    if (this.templates === undefined) await this.loadTemplates()
    if ((this.templates?.length ?? 0) === 0) return

    const template = await Templates.getTemplate(this.templates![0].id)
    runInAction(() => {
      this.currentTemplate = template
    })
  }

  createTemplate = async (template: ITemplateDraft) => {
    return Templates.createTemplate(
      template,
      this.rootStore.authenticationStore.organisationIdentifier
    )
  }

  @action
  reset() {
    this.currentTemplate = undefined
    this.templates = undefined
  }
}
