import useBackendQuery from 'libs/networking/BackendQuery'
import { IVendorInvoice } from 'model/VendorInvoice'
import VendorInvoices from 'networking/VendorInvoices'
import React, { useContext, useEffect, useMemo } from 'react'
import VendorInvoiceDetailErrorView from './VendorInvoiceDetailErrorView'
import VendorInvoiceDetailLoading from './VendorInvoiceDetailLoading'
import VendorInvoiceEditorProvider from './VendorInvoiceEditorContext'

interface VendorInvoiceContextType {
  invoice: IVendorInvoice
  reloadInvoice: (updatedInvoice?: IVendorInvoice) => void
}

const VendorInvoiceContext =
  React.createContext<VendorInvoiceContextType | null>(null)

interface VendorInvoiceProviderProps {
  invoiceId: string
  onDismiss: () => void
  children: React.ReactNode
}

const VendorInvoiceProvider = (props: VendorInvoiceProviderProps) => {
  const { invoiceId } = props

  const [invoice, isLoadingInvoice, loadingError, invalidateQuery] =
    useBackendQuery(() => VendorInvoices.getInvoice(props.invoiceId))

  useEffect(() => {
    if (invoiceId) invalidateQuery()
  }, [invoiceId])

  const contextValue = useMemo(
    () => ({
      // The context provider isn't rendered unless the invoice is loaded anyway.
      invoice: invoice!,
      reloadInvoice: invalidateQuery,
    }),
    [invoice, invalidateQuery]
  )

  if (!contextValue.invoice) {
    if (isLoadingInvoice) {
      return <VendorInvoiceDetailLoading />
    } else {
      return (
        <VendorInvoiceDetailErrorView
          error={loadingError}
          onDismiss={props.onDismiss}
          onRetry={invalidateQuery}
        />
      )
    }
  }

  return (
    <VendorInvoiceContext.Provider value={contextValue}>
      <VendorInvoiceEditorProvider existingInvoice={contextValue.invoice}>
        {props.children}
      </VendorInvoiceEditorProvider>
    </VendorInvoiceContext.Provider>
  )
}

export const useVendorInvoiceContext = (): VendorInvoiceContextType => {
  const context = useContext(VendorInvoiceContext)
  if (!context)
    throw new Error(
      'useVendorInvoiceContext must be used within a VendorInvoiceProvider'
    )
  return context
}

export default VendorInvoiceProvider
