import { Flex, Heading } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import React from 'react'
import './DocumentsDuePrompt.scss'

type DocumentDuePromptAppearance = 'prominent' | 'subtle'

interface DocumentsDuePromptProps {
  className?: string
  icon?: React.ReactNode
  title?: string
  children?: React.ReactNode
  appearance?: DocumentDuePromptAppearance
}

const DocumentsDuePrompt = (props: DocumentsDuePromptProps) => (
  <Flex
    className={classNames(
      'documents-due-prompt',
      props.className,
      props.appearance ?? 'prominent'
    )}
    verticalAlignment='center'
  >
    <div className='icon'>{props.icon}</div>
    <div className='content'>
      {props.title && (
        <Heading type='h4' bare>
          {props.title}
        </Heading>
      )}
      {props.children}
    </div>
  </Flex>
)

export default DocumentsDuePrompt
