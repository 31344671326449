import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { useRootStore } from 'stores/RootStoreContext'
import { primaryNavigationTabs } from './AppNavigation'
import { applicationMenu } from './ApplicationMenu'
import CommonApplicationFrame from './CommonApplicationFrame'

interface ApplicationFrameProps {
  children: React.ReactNode
}

const ApplicationFrame = (props: ApplicationFrameProps) => {
  const { accountStore, preferencesStore } = useRootStore()
  const history = useHistory()
  const primaryNavigation = primaryNavigationTabs(
    accountStore.anyFiscalYearDueForClose
  )

  return (
    <CommonApplicationFrame
      primaryNavigation={primaryNavigation}
      appMenu={applicationMenu(history, preferencesStore.betaFeatures)}
    >
      {props.children}
    </CommonApplicationFrame>
  )
}

export default observer(ApplicationFrame)
