import { NonIdealState } from '@nextbusiness/infinity-ui'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import { observer } from 'mobx-react'
import { QuoteStatus } from 'model/CustomerInvoice'
import { useHistory } from 'react-router-dom'
import { useRootStore } from 'stores/RootStoreContext'
import CustomerInvoiceListItem from '../regular-list/CustomerInvoiceListItem'

const RejectedQuotesView = () => {
  const { customerInvoiceStore } = useRootStore()
  const history = useHistory()

  const rejectedQuotes = customerInvoiceStore.allQuotes
    ?.filter((quote) => quote.quoteStatus === QuoteStatus.Rejected)
    .reverse()

  const hasRejectedQuotes = rejectedQuotes?.length

  return (
    <MagicSheetPage
      header={<MagicSheetTitleBar title='Abgelehnte Offerten' />}
      containerClassName='invoices-page-container'
    >
      {!hasRejectedQuotes ? (
        <NonIdealState>Noch keine abgelehnten Offerten</NonIdealState>
      ) : (
        rejectedQuotes?.map((rejectedQuote) => (
          <CustomerInvoiceListItem
            invoice={rejectedQuote}
            key={rejectedQuote.id}
            onClick={() =>
              history.push(`/forderungen/detail/${rejectedQuote.id}`)
            }
          />
        ))
      )}
    </MagicSheetPage>
  )
}

export default observer(RejectedQuotesView)
