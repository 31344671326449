import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import IDocument, { DocumentLocation } from 'model/Document'
import Documents from 'networking/Documents'
import RootStore from './RootStore'
import Store from './Store'

class DocumentStore extends Store {
  @observable allDocuments: IDocument[] | null = null
  @observable documentBeingAttached: IDocument | null = null

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  @action
  attachDocumentToNewInvoice = (document: IDocument | null) => {
    this.documentBeingAttached = document
  }

  loadAllDocuments = async () => {
    const allDocuments = await Documents.getAllDocumentsIn(
      'all',
      this.rootStore.authenticationStore.organisationIdentifier
    )
    runInAction(() => {
      this.allDocuments = allDocuments.toSorted((a, b) => {
        const uploadedAtA = a.uploadedAt ?? 0
        const uploadedAtB = b.uploadedAt ?? 0

        return uploadedAtB - uploadedAtA
      })
    })
  }

  deleteDocument = async (documentId: string) => {
    await Documents.deleteDocument(
      this.rootStore.authenticationStore.organisationIdentifier,
      documentId
    )
  }

  @computed
  get processedDocuments() {
    return this.allDocuments?.filter(
      (document) => document.location !== DocumentLocation.Inbox
    )
  }

  @computed
  get inboxDocuments() {
    return this.allDocuments?.filter(
      (document) => document.location === DocumentLocation.Inbox
    )
  }
}

export default DocumentStore
