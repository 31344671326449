import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AtLeastOne } from './types'

export const useURLQuery = () => new URLSearchParams(useLocation().search)

export const useAsyncEffect = (
  effect: (isMounted: () => boolean) => any,
  destroy?: (result?: any) => void
) => {
  const hasDestroy = typeof destroy === 'function'

  React.useEffect(function () {
    let result: any
    let mounted = true

    const maybePromise = effect(function () {
      return mounted
    })

    Promise.resolve(maybePromise).then(function (value) {
      result = value
    })

    return function () {
      mounted = false

      if (hasDestroy && destroy) {
        destroy(result)
      }
    }
  })
}

export function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, (value: T) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key)
      if (!item) return initialValue
      if (key.includes('date')) return new Date(JSON.parse(item))
      return JSON.parse(item)
    } catch (error: any) {
      console.log(error)
      return initialValue
    }
  })

  const setValue = (value: T) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error: any) {
      console.log(error)
    }
  }

  return [storedValue, setValue]
}
export function useSessionStorage<T>(
  key: string,
  initialValue: T
): [T, (value: T) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.sessionStorage.getItem(key)
      if (!item) return initialValue
      if (key.includes('date')) return new Date(JSON.parse(item))
      return JSON.parse(item)
    } catch (error: any) {
      console.log(error)
      return initialValue
    }
  })

  const setValue = (value: T) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      window.sessionStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error: any) {
      console.log(error)
    }
  }

  return [storedValue, setValue]
}

export const useIsKeyDown = (key: string) => {
  const [isKeyDown, setIsKeyDown] = useState<boolean>(false)
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (key === event.key) setIsKeyDown(true)
    }
    const onKeyUp = (event: KeyboardEvent) => {
      if (key === event.key) setIsKeyDown(false)
    }

    document.addEventListener('keydown', onKeyDown)
    document.addEventListener('keyup', onKeyUp)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
      document.removeEventListener('keyup', onKeyUp)
    }
  })
  return isKeyDown
}

/**
 * Used to debounce values that are updated frequently, in order to prevent
 * e.g. frequent API calls when the user is typing in a search field.
 * @param delay Frequency at which to update the debounced value in milliseconds
 * @returns Debounced value
 */
export const useDebounce = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])
  return debouncedValue
}

export const useEnter = (callback: () => void, condition?: boolean) => {
  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        e.key === 'Enter' &&
        (e.target as HTMLElement).tagName !== 'TEXTAREA' &&
        (e.target as HTMLElement).tagName !== 'BUTTON' &&
        condition !== false
      ) {
        callback()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [callback, condition])
}

export const useIsTouchDevice = () => {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
}

interface PluralOptions {
  plural?: string
  suffix?: string
}

/**
 * Used to pluralise a string based on a value.
 * @param pluralOptions Either a plural string or a suffix to append to the singular string.
 */
export const usePluralise = (
  value: number,
  singular: string,
  pluralOptions: AtLeastOne<PluralOptions>,
  emptyCaseText?: string
): string => {
  if (value === 1) return `${value} ${singular}`
  let valueDescription = `${value}`
  if (value === 0 && emptyCaseText) {
    valueDescription = emptyCaseText
  }
  if (pluralOptions.plural) return `${valueDescription} ${pluralOptions.plural}`
  if (pluralOptions.suffix)
    return `${valueDescription} ${singular}${pluralOptions.suffix}`
  throw new Error('No plural options provided')
}

export const useIsFirstRender = () => {
  const isFirstRenderRef = useRef(true)
  useEffect(() => {
    isFirstRenderRef.current = false
  }, [])
  return isFirstRenderRef.current
}

export const useHasScrolled = (scrollRef: React.RefObject<HTMLElement>) => {
  const [hasScrolled, setHasScrolled] = useState(false)
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = scrollRef.current?.scrollTop ?? 0
      if (scrollTop > 0) {
        setHasScrolled(true)
      } else {
        setHasScrolled(false)
      }
    }
    scrollRef.current?.addEventListener('scroll', handleScroll)
    return () => {
      scrollRef.current?.removeEventListener('scroll', handleScroll)
    }
  }, [scrollRef])
  return hasScrolled
}
