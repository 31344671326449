import { Avatar } from '@nextbusiness/infinity-ui'
import TransactionAmount from 'components/transactions/TransactionAmount'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'

interface CompactVendorInvoiceViewProps {
  invoiceId: string
}

const CompactVendorInvoiceView = (props: CompactVendorInvoiceViewProps) => {
  const { contactStore, vendorInvoiceStore } = useRootStore()

  const vendorInvoice = vendorInvoiceStore.allInvoices.find(
    (invoice) => invoice.id === props.invoiceId
  )
  if (!vendorInvoice) return null
  return (
    <div className='compact-row'>
      <div className='vendor-depiction'>
        <Avatar
          className='avatar'
          name={contactStore.displayNameForContactWithId(
            vendorInvoice.creditor
          )}
        />
        <div className='title'>{vendorInvoice.title}</div>
      </div>
      <div className='amount'>
        <TransactionAmount amount={Math.abs(vendorInvoice.totalAmount)} />
      </div>
    </div>
  )
}

export default observer(CompactVendorInvoiceView)
