import BalanceSheetIcon from '../assets/balance-sheet-icon.svg'
import IncomeStatementIcon from '../assets/income-statement-icon.svg'
import ReportPage, { ReportPagePaths } from '../pages/ReportPage'
import ReportCard from './ReportCard'
import './ReportingReports.scss'

interface ReportingReportsProps {
  reportPage: ReportPage
}

const ReportingReports = (props: ReportingReportsProps) => (
  <div className='reporting-section reports'>
    <ReportCard
      tintColor='#01AD87'
      onClick={() => props.reportPage.open(ReportPagePaths.BalanceSheet)}
      iconURL={BalanceSheetIcon}
      title='Bilanz'
      description='Vermögen und Schulden an einem bestimmten Stichtag'
    />
    <ReportCard
      tintColor='#01A4AD'
      onClick={() => props.reportPage.open(ReportPagePaths.IncomeStatement)}
      iconURL={IncomeStatementIcon}
      title='Erfolgsrechnung'
      description='Einnahmen und Ausgaben über einen bestimmten Zeitraum'
    />
  </div>
)

export default ReportingReports
