import { RouterHistory } from '@nextbusiness/infinity-ui/dist/layout/header/HeaderNavigationTabs'
import React from 'react'

interface IncomeStatementContextProps {
  areAllExpanded: boolean
  setAreAllExpanded: (areAllExpanded: boolean) => void
  areAllCollapsed: boolean
  setAreAllCollapsed: (areAllCollapsed: boolean) => void
  history?: RouterHistory
}

const IncomeStatementContext = React.createContext<IncomeStatementContextProps>(
  {
    areAllExpanded: true,
    setAreAllExpanded: () => {},
    areAllCollapsed: false,
    setAreAllCollapsed: () => {},
  }
)

export default IncomeStatementContext
