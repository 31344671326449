import { APIError } from '@nextbusiness/infinity-finance-api'
import { ButtonProps, Flex, NonIdealState } from '@nextbusiness/infinity-ui'
import { IconSize } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import './ErrorScreen.scss'

interface ErrorScreenProps {
  title: string
  onRetry?: () => void
  onClose?: () => void
  error?: Error
  defaultMessage?: string
  small?: boolean
  contained?: boolean
}

const ErrorScreen = (props: ErrorScreenProps) => {
  const retryAction: ButtonProps = {
    children: 'Erneut versuchen',
    variant: 'primary',
    onClick: () => props.onRetry?.(),
  }
  const closeAction: ButtonProps = {
    children: 'Schliessen',
    onClick: () => props.onClose?.(),
  }

  return (
    <Flex
      className={classNames('ui-error-screen', {
        contained: props.contained,
      })}
      fillContainer
      horizontalAlignment='center'
      verticalAlignment='center'
    >
      <NonIdealState
        title={props.title}
        icon='error'
        actions={[
          ...(props.onRetry ? [retryAction] : []),
          ...(props.onClose ? [closeAction] : []),
        ]}
        iconSize={props.small ? IconSize.Small : undefined}
      >
        {props.error instanceof APIError
          ? props.error.humanText('de')
          : props.error?.message ?? props.defaultMessage}
      </NonIdealState>
    </Flex>
  )
}

export default ErrorScreen
