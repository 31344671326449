import { Button } from '@nextbusiness/infinity-ui'
import { IconItem } from '@nextbusiness/infinity-ui-icons'
import React from 'react'
import './DocumentPositionEmptyAction.scss'

interface DocumentPositionEmptyActionProps {
  icon: IconItem
  children: React.ReactNode
  onClick: () => void
}

const DocumentPositionEmptyAction = (
  props: DocumentPositionEmptyActionProps
) => (
  <Button
    variant='secondary'
    iconLeft={props.icon}
    className='document-position-empty-action'
    onClick={props.onClick}
  >
    {props.children}
  </Button>
)

export default DocumentPositionEmptyAction
