import {
  Finance,
  IQRInvoicePreferences,
} from '@nextbusiness/infinity-finance-api'
import useBackendQuery from 'libs/networking/BackendQuery'
import { observer } from 'mobx-react'
import Contacts from 'networking/Contacts'
import { useEffect } from 'react'
import ContactStore from 'stores/ContactStore'
import { useRootStore } from 'stores/RootStoreContext'
import StringUtilities from 'utility/StringUtilities'
import Utilities from 'utility/Utilities'
import DocumentQRSlipErrorMessage from './DocumentQRSlipErrorMessage'
import { formatInvoiceNumber } from './elements/DocumentElementInvoiceNumber'

interface DocumentQRSlipPreviewOverlayProps {
  amount: number
  recipientContactId: string | undefined
  settings: IQRInvoicePreferences
  invoiceNumber: number | undefined
  isAnnulled?: boolean
}

const DocumentQRSlipPreviewOverlay = (
  props: DocumentQRSlipPreviewOverlayProps
) => {
  const { settings } = props
  const { authenticationStore, preferencesStore } = useRootStore()

  const [recipient, , , invalidateQuery] = useBackendQuery(async () => {
    if (!props.recipientContactId) return undefined
    return Contacts.getContact(
      authenticationStore.fabricOrganisationIdentifier!,
      props.recipientContactId,
      authenticationStore.applicationAccessToken!
    )
  })
  useEffect(() => {
    invalidateQuery()
  }, [props.recipientContactId])

  const isContactIncomplete = recipient && !recipient.postCode

  const accountHolder = (
    <>
      {settings.iban}
      <br />
      {settings.accountHolder?.organisationOrPerson}
      <br />
      {settings.accountHolder?.addressLine1}
      <br />
      {settings.accountHolder?.addressLine2}
    </>
  )

  const payableBy = recipient ? (
    <>
      {recipient.companyName
        ? recipient.companyName
        : ContactStore.nameOfContact(recipient)}
      <br />
      {recipient.address}
      <br />
      {recipient.postCode}
    </>
  ) : null

  const amount = Utilities.centsToCHF(props.amount)

  const invoiceNumber = () => {
    const invoiceNumberingStart =
      preferencesStore.organisationPreferences?.customerInvoiceNumberingStart
    if (!props.invoiceNumber) {
      return null
    }
    return formatInvoiceNumber(
      'RE-',
      props.invoiceNumber,
      invoiceNumberingStart
    )
  }
  const reference = Finance.CustomerInvoice.generateCreditorReference(
    invoiceNumber() ?? ''
  )
  const formattedReference = StringUtilities.insertSpaces(reference, {
    every: 4,
  })

  if (isContactIncomplete)
    return (
      <DocumentQRSlipErrorMessage icon='address-book'>
        Hinterlege beim ausgewählten Kontakt eine PLZ und Ort, um einen gültigen
        QR-Zahlteil generieren zu können.
      </DocumentQRSlipErrorMessage>
    )

  if (props.isAnnulled)
    return (
      <DocumentQRSlipErrorMessage icon='close'>
        Diese Forderung wurde storniert und kann nicht mehr bezahlt werden.
      </DocumentQRSlipErrorMessage>
    )

  return (
    <div className='document-qr-overlays'>
      <div className='receipt receipt-account-holder'>{accountHolder}</div>
      <div className='receipt receipt-payable-by'>{payableBy}</div>
      <div className='receipt receipt-amount'>{amount}</div>

      <div className='payslip payslip-account-holder'>{accountHolder}</div>
      <div className='payslip payslip-reference'>{formattedReference}</div>
      <div className='payslip payslip-additional'></div>
      <div className='payslip payslip-payable-by'>{payableBy}</div>
      <div className='payslip payslip-amount'>{amount}</div>
    </div>
  )
}

export default observer(DocumentQRSlipPreviewOverlay)
