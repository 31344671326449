import { InputField } from '@nextbusiness/infinity-ui'
import FormField from 'base-components/layout/FormField'
import SettingsGroup from 'settings/general/SettingsGroup'
import { ProductEditFieldsProps } from '../ProductEditFields'

const ProductFieldsDetails = (props: ProductEditFieldsProps) => (
  <SettingsGroup title='Optionale Details'>
    <FormField label='Artikelnummer'>
      <InputField
        value={props.product.articleNumber ?? ''}
        onChange={(articleNumber) => props.editProduct({ articleNumber })}
        placeholder='z.B. AR-00011'
        fullWidth
      />
    </FormField>
    <FormField label='Einheit'>
      <InputField
        value={props.product.unit ?? ''}
        onChange={(unit) => {
          if (unit.length <= 4) props.editProduct({ unit })
        }}
        hasError={Boolean(
          props.shouldValidate &&
            props.product.unit &&
            props.product.unit.length > 4
        )}
        placeholder='z.B. Stk., h, m2, ...'
        helperText='Maximal 4 Zeichen'
        fullWidth
      />
    </FormField>
  </SettingsGroup>
)

export default ProductFieldsDetails
