import { TemplateInspectorProps } from '../TemplateInspector'
import TemplateInspectorGroup from '../TemplateInspectorGroup'
import './TemplateInspectorTypeface.scss'
import TemplateInspectorTypefacePicker from './TemplateInspectorTypefacePicker'

const TemplateInspectorTypeface = (props: TemplateInspectorProps) => (
  <TemplateInspectorGroup className='template-inspector-typeface'>
    <TemplateInspectorTypefacePicker
      selectedFont={props.template.font}
      onFontChange={(font) => props.modifyTemplate({ font })}
    />
  </TemplateInspectorGroup>
)

export default TemplateInspectorTypeface
