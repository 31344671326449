import { Button, Heading } from '@nextbusiness/infinity-ui'
import { ReactComponent as Closed } from 'assets/disclosure-indicators/disclosure-indicator-closed.svg'
import { ReactComponent as Open } from 'assets/disclosure-indicators/disclosure-indicator-open.svg'
import './AccordionItem.scss'

export interface IAccordionItem {
  /** Should be different from other items in the same accordion */
  title: string
  content: React.ReactNode
  onOpen?: () => void
}

interface AccordionItemProps extends IAccordionItem {
  isOpen: boolean
  openItem: () => void
}

const AccordionItem = (props: AccordionItemProps) => {
  return (
    <li className='accordion-element'>
      <Heading type='h4' className='accordion-element-title' bare>
        <Button
          className='accordion-element-title-button'
          variant='shell'
          onClick={props.openItem}
        >
          {props.isOpen ? (
            <Open className='indicator open' />
          ) : (
            <Closed className='indicator closed' />
          )}
          {props.title}
        </Button>
      </Heading>
      {props.isOpen && (
        <div className='accordion-element-content'>{props.content}</div>
      )}
    </li>
  )
}

export default AccordionItem
