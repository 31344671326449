import { INewTransaction } from '@nextbusiness/infinity-finance-api'
import { useAccountPageContext } from 'ledger/accounts/AccountPageContext'
import { useEffect } from 'react'
import { store } from 'stores/RootStoreContext'
import TransactionCreatorUtilities from '../TransactionCreatorUtilities'

const useMarkUnpostedTransaction = (
  transaction: Partial<INewTransaction>,
  utilities: TransactionCreatorUtilities
) => {
  const { viewId } = useAccountPageContext()
  useEffect(() => {
    if (utilities.hasEditedTransaction(transaction)) {
      store.ledgerStore.addUnpostedTransaction(viewId)
    } else {
      store.ledgerStore.resolveUnpostedTransaction(viewId)
    }
  }, [transaction, viewId])
}

export default useMarkUnpostedTransaction
