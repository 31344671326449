import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import { useMixpanelEvent } from 'integrations/useMixpanelEvent'
import { observer } from 'mobx-react'
import ProjectPage from 'pages/ProjectPage'
import ProjectBoard from './ProjectBoard'
import { ProjectBoardProvider } from './ProjectBoardContext'
import ProjectBoardTitleBar from './ProjectBoardTitleBar'

interface ProjectBoardPageProps {
  page: ProjectPage
}

const ProjectBoardPage = (props: ProjectBoardPageProps) => {
  useMixpanelEvent('Project board viewed')
  return (
    <MagicSheetPage
      className='project-board-page'
      header={<ProjectBoardTitleBar />}
      noTrailingNegativeSpace
    >
      <MagicSheetContainer>
        <ProjectBoardProvider page={props.page}>
          <ProjectBoard />
        </ProjectBoardProvider>
      </MagicSheetContainer>
    </MagicSheetPage>
  )
}

export default observer(ProjectBoardPage)
