import { Flex, Heading } from '@nextbusiness/infinity-ui'
import BetaLozenge from 'components/lozenge/BetaLozenge'
import React from 'react'
import './OnboardScreen.scss'

interface OnboardScreenProps {
  className?: string
  image: string
  title?: string
  children?: React.ReactNode
  beta?: boolean
}

const OnboardScreen = (props: OnboardScreenProps) => (
  <Flex
    className={
      'onboard-screen' + (props.className ? ` ${props.className}` : '')
    }
    horizontalAlignment='center'
    verticalAlignment='center'
    fillContainer
    gap='small'
  >
    <div className='hero-image'>
      <img src={props.image} alt='' />
    </div>
    <div className='hero-text'>
      {props.title && (
        <Heading type='h2'>
          {props.title}
          {props.beta && <BetaLozenge prominent />}
        </Heading>
      )}
      {props.children}
    </div>
  </Flex>
)

export default OnboardScreen
