import { Provider } from 'mobx-react'
import { createContext, useContext } from 'react'
import RootStore from './RootStore'

export const store: RootStore = new RootStore()

const StoreContext = createContext<RootStore | undefined>(undefined)
const RootStoreProvider = ({ children }: { children?: React.ReactNode }) => {
  const root = store
  return (
    <StoreContext.Provider value={root}>
      <Provider
        rootStore={root}
        authenticationStore={root.authenticationStore}
        transactionStore={root.transactionStore}
        contactStore={root.contactStore}
        accountStore={root.accountStore}
        ledgerStore={root.ledgerStore}
        templateStore={root.templateStore}
        vendorInvoiceStore={root.vendorInvoiceStore}
        customerInvoiceStore={root.customerInvoiceStore}
        financialCloseStore={root.financialCloseStore}
        documentStore={root.documentStore}
        preferencesStore={root.preferencesStore}
        vatPeriodStore={root.vatPeriodStore}
        currencyStore={root.currencyStore}
      >
        {children}
      </Provider>
    </StoreContext.Provider>
  )
}

export const useRootStore = (): RootStore => {
  const context = useContext(StoreContext)
  if (context === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider')
  }
  return context
}

export default RootStoreProvider
