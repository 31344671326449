import { Button, Checkbox, Heading, Text } from '@nextbusiness/infinity-ui'
import { useState } from 'react'
import AssistantStepScreen, {
  AssistantStepVisual,
} from '../AssistantStepScreen'
import FinancialClosingAssistant from '../FinancialClosingAssistant'

interface ClosingVATSimplifiedCheckStepProps {
  assistant: FinancialClosingAssistant
}

const ClosingVATSimplifiedCheckStep = (
  props: ClosingVATSimplifiedCheckStepProps
) => {
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false)
  const canContinue = isConfirmed

  return (
    <AssistantStepScreen
      visual={AssistantStepVisual.Vat}
      actions={
        <>
          <Button onClick={() => props.assistant.previousStep()}>Zurück</Button>
          <Button
            variant='primary'
            onClick={() => props.assistant.nextStep()}
            disabled={!canContinue}
          >
            Fortfahren
          </Button>
        </>
      }
      depiction={
        <>
          <Heading type='h2'>Mehrwertsteuer</Heading>
          <Text type='paragraph'>
            Stelle sicher, dass alle steuerrelevanten Transaktionen im alten
            Geschäftsjahr korrekt verbucht sind, da du diese nach dem
            Jahresabschluss nicht mehr korrigieren kannst.
          </Text>
        </>
      }
    >
      Bitte überprüfe vor dem Jahresabschluss die folgenden Punkte:
      <ul className='checkpoints'>
        <li>
          <b>
            Du hast alle MWST-Perioden, welche dieses Geschäftsjahr betreffen,
            der Steuerverwaltung gemeldet.
          </b>{' '}
          Du wirst keine Änderungen mehr vornehmen können, nachdem du den
          Jahresabschluss erstellt hast.
        </li>
        <li>
          <b>
            Du hast die geschuldete Steuer im Konto «Geschuldete MWST» (2200)
            auf deine geschuldete MWST nach deinen Saldosteuersätzen angepasst.
          </b>{' '}
          Im Konto 2200 sammelt Infinity Finance die Mehrwertsteuer, die auf
          deinen Forderungen ausgewiesen ist. Bei der Abrechnung via
          Saldosteuersatz solltest du den Steuerbetrag in diesem Konto mit einer
          Umbuchung zu Ertrag auf den tatsächlich fälligen Steuerbetrag nach
          deinen individuellen Saldosteuersätzen reduzieren.
        </li>
      </ul>
      <Checkbox
        isChecked={isConfirmed}
        onChange={(checked) => setIsConfirmed(checked)}
        label='Ich bestätige, diese Punkte überprüft zu haben.'
      />
    </AssistantStepScreen>
  )
}

export default ClosingVATSimplifiedCheckStep
