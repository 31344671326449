import {
  Dialog,
  InputField,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import CardRadioOption from 'ledger/accounts/account-creation/CardRadioOption'
import { observer } from 'mobx-react-lite'
import IDocument from 'model/Document'
import Documents from 'networking/Documents'
import { useEffect, useMemo, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { IVendorInvoice } from '../../model/VendorInvoice'
import './AttachDocumentToInvoiceModal.scss'

interface AttachDocumentToInvoiceModalProps {
  isOpen: boolean
  closeModal: (isOpen: boolean) => void
  document: IDocument
}

const AttachDocumentToInvoiceModal = (
  props: AttachDocumentToInvoiceModalProps
) => {
  const {
    vendorInvoiceStore,
    authenticationStore,
    contactStore,
    documentStore,
  } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string>()
  const [isAttaching, setIsAttaching] = useState<boolean>(false)
  const [searchQuery, setSearchQuery] = useState<string>('')

  const filteredInvoices: IVendorInvoice[] = useMemo(() => {
    if (!searchQuery) return vendorInvoiceStore.allInvoices

    const lowerCaseSearchQuery = searchQuery.toLowerCase()

    const matchingInvoices: IVendorInvoice[] = []

    vendorInvoiceStore.allInvoices.forEach((vendorInvoice) => {
      if (
        vendorInvoice.title.toLowerCase().includes(lowerCaseSearchQuery) ||
        contactStore
          .displayNameForContactWithId(vendorInvoice.creditor)
          .toLowerCase()
          .includes(lowerCaseSearchQuery)
      ) {
        matchingInvoices.push(vendorInvoice)
      }
    })

    return matchingInvoices
  }, [vendorInvoiceStore.allInvoices, searchQuery])

  const attachInvoiceToDocument = async () => {
    if (!selectedInvoiceId) return
    setIsAttaching(true)
    try {
      await Documents.attachDocumentToInvoice(
        authenticationStore.organisationIdentifier,
        props.document.id,
        selectedInvoiceId,
        authenticationStore.user!.id
      )
      notificationCenter.addNotification({
        children: 'Dokument wurde an die Rechnung angehängt',
        variant: 'success',
      })
      vendorInvoiceStore.loadInvoices()
    } catch (error: any) {
      notificationCenter.addNotification({
        title: 'Dokument konnte nicht an die Rechnung angehängt werden',
        children: error.message,
        variant: 'error',
      })
    } finally {
      documentStore.loadAllDocuments()
      setIsAttaching(false)
      props.closeModal(false)
    }
  }

  useEffect(() => {
    if (props.isOpen) {
      setSelectedInvoiceId(undefined)
      setSearchQuery('')
    }
  }, [props.isOpen])

  useEffect(() => {
    vendorInvoiceStore.loadInvoices()
  }, [])

  return (
    <Dialog
      isOpen={props.isOpen}
      className='attach-document-to-invoice-modal'
      title='Dokument an Rechnung anhängen'
      titleProps={{ divider: true }}
      actions={[
        {
          children: 'Abbrechen',
          onClick: () => props.closeModal(false),
        },
        {
          children: 'An Rechnung anhängen',
          variant: 'primary',
          disabled: !selectedInvoiceId,
          isLoading: isAttaching,
          onClick: () => attachInvoiceToDocument(),
        },
      ]}
    >
      <Text>
        Du kannst hier das Dokument "{props.document.title}" an eine bestehende
        Rechnung anhängen, die noch keinen Anhang hat.
      </Text>
      <InputField
        className='filter-documents'
        fullWidth
        placeholder='Dokument suchen'
        icon='search'
        value={searchQuery}
        onChange={setSearchQuery}
      />
      <div className='document-scrollable-list'>
        {filteredInvoices
          .filter((invoice) => !invoice.attachments?.length)
          .map((invoice) => (
            <CardRadioOption
              key={invoice.id}
              isChecked={selectedInvoiceId === invoice.id}
              label={invoice.title}
              description={contactStore.displayNameForContactWithId(
                invoice.creditor
              )}
              onOptionSelected={() => {
                setSelectedInvoiceId(invoice.id)
              }}
            />
          ))}
      </div>
    </Dialog>
  )
}

export default observer(AttachDocumentToInvoiceModal)
