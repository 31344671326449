import { Finance } from '@nextbusiness/infinity-finance-api'
import { Flex } from '@nextbusiness/infinity-ui'
import ErrorScreen from 'base-components/layout/ErrorScreen'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import DocumentUploadPreview from 'invoices/vendor-invoices/DocumentUploadPreview'
import useAPIQuery from 'libs/networking/APIQuery'
import DocumentsPage from 'pages/DocumentsPage'
import DocumentMoreMenu from './DocumentMoreMenu'
import './DocumentView.scss'

interface DocumentViewProps {
  documentId?: string
  page?: DocumentsPage
}

const DocumentView = (props: DocumentViewProps) => {
  if (!props.documentId && !props.page)
    throw new Error(
      'Either `documentId` or `page` is required for <DocumentView /> component.'
    )

  const [response, isLoading, error, reloadDocument] = useAPIQuery(() =>
    Finance.Documents.document(props.documentId ?? props.page!.currentParam!)
  )

  return (
    <MagicSheetPage
      noTrailingNegativeSpace
      header={
        <MagicSheetTitleBar title={response?.document.title}>
          {response && (
            <DocumentMoreMenu
              completeDocument={response}
              reloadDocument={reloadDocument}
            />
          )}
        </MagicSheetTitleBar>
      }
    >
      <MagicSheetContainer>
        <Flex className='document-view'>
          {isLoading && <LoadingScreen />}
          {error && (
            <ErrorScreen
              title='Dokument kann nicht geladen werden'
              error={error}
            />
          )}
          {response && <DocumentUploadPreview document={response} />}
        </Flex>
      </MagicSheetContainer>
    </MagicSheetPage>
  )
}

export default DocumentView
