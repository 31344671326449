import { Permission } from 'base-components/system/Permission'
import RequestPermission from 'base-components/system/RequestPermission'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { useMixpanelEvent } from 'integrations/useMixpanelEvent'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import contactDialogStore from 'stores/ContactDialogStore'
import { useRootStore } from 'stores/RootStoreContext'

const ContactPermissionRequest = () => {
  const { contactStore } = useRootStore()
  const { setIsContactsOpen } = contactDialogStore

  useMixpanelEvent('Permission request - Contacts')

  return (
    <RequestPermission
      permissions={[Permission.ReadContacts, Permission.WriteContacts]}
      onPermissionDenied={() => {
        MixpanelAnalytics.event('Permission denied - Contacts')
        setIsContactsOpen(false)
      }}
      onPermissionGranted={() => {
        runInAction(() => {
          MixpanelAnalytics.event('Permission granted - Contacts')
          contactStore.hasContactsPermissions = true
          contactStore.loadContacts()
        })
      }}
    />
  )
}

export default observer(ContactPermissionRequest)
