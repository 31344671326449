import React from 'react'
import './MagicSheetContainer.scss'

interface MagicSheetContainerProps {
  children?: React.ReactNode
}

const MagicSheetContainer = (props: MagicSheetContainerProps) => (
  <div className='magic-sheet-container'>{props.children}</div>
)

export default MagicSheetContainer
