import FeatureUpgradeModal from 'components/billing/feature-gate/FeatureUpgradeModal'
import useSubscription from 'components/billing/subscription-context/useSubscription'
import MoreMenu from 'components/more-menu/MoreMenu'
import SubscriptionCapability from 'libs/subscription/SubscriptionCapability'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import ManageProductsModal from './products/ManageProductsModal'

const CustomerInvoicesMoreMenu = () => {
  const history = useHistory()

  const [isManageProductsOpen, setIsManageProductsOpen] =
    useState<boolean>(false)
  const [isProductsUpgradeModalOpen, setIsProductsUpgradeModalOpen] =
    useState<boolean>(false)

  const subscription = useSubscription()
  const canUseProducts = subscription?.hasCapability(
    SubscriptionCapability.Products
  )

  const openProducts = () => {
    if (canUseProducts) {
      setIsManageProductsOpen(true)
    } else {
      setIsProductsUpgradeModalOpen(true)
    }
  }

  return (
    <>
      <MoreMenu
        actions={[
          {
            buttons: [
              {
                text: 'Produkte',
                icon: 'package-delivery-logistics',
                onClick: openProducts,
              },
              {
                text: 'Design anpassen',
                icon: 'appearance',
                onClick: () => history.push('/optionen/design'),
              },
            ],
          },
        ]}
      />
      <ManageProductsModal
        isOpen={isManageProductsOpen}
        onDismiss={() => setIsManageProductsOpen(false)}
      />
      <FeatureUpgradeModal
        isOpen={isProductsUpgradeModalOpen}
        onDismiss={() => setIsProductsUpgradeModalOpen(false)}
        requiredCapability={SubscriptionCapability.Products}
      />
    </>
  )
}

export default CustomerInvoicesMoreMenu
