import {
  Finance,
  IProjectPreview,
  IProjectStage,
  ProjectStatus,
} from '@nextbusiness/infinity-finance-api'
import { action, computed, makeObservable, observable } from 'mobx'
import RootStore from './RootStore'
import Store from './Store'

class ProjectStore extends Store {
  @observable projectStages: IProjectStage[] = []

  @observable activeProjects: IProjectPreview[] | undefined = []
  @observable archivedProjects: IProjectPreview[] | undefined = []
  @observable areProjectsLoaded = false

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  onHydrate(): void {
    this.fetchAllProjects()
  }

  projectsInStage(stage: IProjectStage): IProjectPreview[] {
    return (
      this.activeProjects?.filter(
        (project) => project.currentStage === stage.id
      ) ?? []
    )
  }

  @computed
  public get projectsOutsideOfStages(): IProjectPreview[] {
    return (
      this.activeProjects?.filter(
        (project) =>
          !this.projectStages.some((stage) => stage.id === project.currentStage)
      ) ?? []
    )
  }

  groupProjectsByStage(
    projects: IProjectPreview[]
  ): Record<string, IProjectPreview[]> {
    const stageTitles = this.projectStages.reduce((titleForStage, stage) => {
      titleForStage[stage.id] = stage.title
      return titleForStage
    }, {} as Record<string, string>)
    return projects.reduce((projectsByStage, project) => {
      const title = stageTitles[project.currentStage]
      projectsByStage[title] = projectsByStage[title] ?? []
      projectsByStage[title].push(project)
      return projectsByStage
    }, {} as Record<string, IProjectPreview[]>)
  }

  @action
  fetchActiveProjects = async () => {
    this.activeProjects = await Finance.Projects.projects({
      status: ProjectStatus.Active,
    })
  }

  @action
  fetchArchivedProjects = async () => {
    this.archivedProjects = await Finance.Projects.projects({
      status: 'archived',
    })
  }

  @action
  fetchProjectStages = async () => {
    this.projectStages = await Finance.Projects.projectStages()
  }

  @action
  fetchAllProjects = async () => {
    if (!this.rootStore.authenticationStore.isAuthenticated) return
    await Promise.all([
      this.fetchProjectStages(),
      this.fetchActiveProjects(),
      this.fetchArchivedProjects(),
    ])
    this.areProjectsLoaded = true
  }

  projectById(id: string): IProjectPreview | undefined {
    return (
      this.activeProjects?.find((project) => project.id === id) ??
      this.archivedProjects?.find((project) => project.id === id)
    )
  }

  /**
   * Moves a project to a different stage with an optimistic update.
   * @param projectId The ID of the project to move.
   * @param toStageWithId The ID of the stage to move the project to.
   */
  @action
  moveProject = async (projectId: string, toStageWithId: string) => {
    this.activeProjects = this.activeProjects?.map((project) => {
      if (project.id === projectId)
        return { ...project, currentStage: toStageWithId }
      return project
    })
    try {
      await Finance.Projects.modifyProject(projectId, {
        currentStage: toStageWithId,
      })
    } finally {
      await this.fetchActiveProjects()
    }
  }
}

export default ProjectStore
