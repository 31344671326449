import {
  APIError,
  ErrorCode,
  Finance,
} from '@nextbusiness/infinity-finance-api'
import { useNotificationCenter } from '@nextbusiness/infinity-ui'
import ErrorScreen from 'base-components/layout/ErrorScreen'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react'
import { IAccount } from 'model/Account'
import { useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'

interface BankAccountAbortConnectionFlowProps {
  account: IAccount
}

const BankAccountAbortConnectionFlow = (
  props: BankAccountAbortConnectionFlowProps
) => {
  const { accountStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const accountId = props.account.id
  const [abortError, setAbortError] = useState<APIError>()

  useEffect(() => {
    const resetConnection = async () => {
      try {
        await Finance.Ledger.abortLiveAccountingSetup(accountId)
        await accountStore.loadAccounts()

        notificationCenter.addNotification({
          variant: 'warning',
          children: 'Die Live Accounting-Einrichtung wurde abgebrochen.',
        })
        MixpanelAnalytics.event('Live accounting setup aborted')
      } catch (error) {
        if (error instanceof APIError) {
          setAbortError(error)
          MixpanelAnalytics.event('Live accounting setup abort failure', {
            code: error.code,
          })
        } else {
          setAbortError(new APIError(ErrorCode.Unknown))
        }
      }
    }
    window.setTimeout(resetConnection, 1500)
  }, [accountId])

  if (abortError) {
    // Something has gone seriously wrong here, at this point we can only
    // display an error message.
    return <ErrorScreen title='Etwas ist schiefgelaufen' error={abortError} />
  }

  return <LoadingScreen />
}

export default observer(BankAccountAbortConnectionFlow)
