import useBackendQuery from 'libs/networking/BackendQuery'
import { observer } from 'mobx-react'
import { IExpensesOrIncome } from 'model/FinancialHealth'
import FinancialHealth from 'networking/FinancialHealth'
import { ReportPagePaths } from 'pages/ReportPage'
import { useRootStore } from 'stores/RootStoreContext'
import FinancialHealthIncomeOrExpensesCard from './FinancialHealthIncomeOrExpensesCard'

const FinancialHealthIncomeCard = () => {
  const { authenticationStore, accountStore } = useRootStore()

  const fiscalYear = accountStore.currentFiscalYear
  const fiscalYearEnd = fiscalYear?.to ?? Date.now()

  const income = useBackendQuery<IExpensesOrIncome>(
    () =>
      FinancialHealth.getIncome(
        authenticationStore.organisationIdentifier,
        fiscalYearEnd
      ),
    undefined,
    [fiscalYearEnd]
  )

  return (
    <FinancialHealthIncomeOrExpensesCard
      query={income}
      title='Erträge'
      linkTo={ReportPagePaths.FinancialHealthIncome}
    />
  )
}

export default observer(FinancialHealthIncomeCard)
