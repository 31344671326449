import { useAccountPageContext } from 'ledger/accounts/AccountPageContext'
import { useMemo } from 'react'
import { TransactionCreatorProps } from '../TransactionCreator'
import TransactionCreatorUtilities from '../TransactionCreatorUtilities'

const useTransactionCreatorUtilities = (props: TransactionCreatorProps) => {
  const { currentAccount } = useAccountPageContext()
  const { activeAction, shouldCurrentAccountIncrease } = props

  const utilites = useMemo(() => {
    return new TransactionCreatorUtilities(
      currentAccount,
      activeAction.currentAccountSide,
      shouldCurrentAccountIncrease
    )
  }, [currentAccount, activeAction, shouldCurrentAccountIncrease])

  return utilites
}

export default useTransactionCreatorUtilities
