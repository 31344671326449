import { Dialog, KeyValueStore, Text } from '@nextbusiness/infinity-ui'
import useBackendQuery from 'libs/networking/BackendQuery'
import { observer } from 'mobx-react-lite'
import { Contact } from 'model/Contact'
import Contacts from 'networking/Contacts'
import contactDialogStore from 'stores/ContactDialogStore'
import { useRootStore } from 'stores/RootStoreContext'

interface CustomerInvoiceEmailSetupDialogProps {
  isOpen: boolean
  onDismiss: () => void
  recipient: Contact
}

const CustomerInvoiceEmailSetupDialog = (
  props: CustomerInvoiceEmailSetupDialogProps
) => {
  const { authenticationStore } = useRootStore()
  const {
    setIsContactsOpen,
    setActiveContact,
    setDraftContact,
    setMode,
    setIsPickerMode,
  } = contactDialogStore

  const [fullContact] = useBackendQuery(async () => {
    if (!props.recipient) return undefined
    return Contacts.getContact(
      authenticationStore.fabricOrganisationIdentifier!,
      props.recipient.id,
      authenticationStore.applicationAccessToken!
    )
  })

  const openContactEditor = () => {
    setIsContactsOpen(true)
    queueMicrotask(() => {
      setActiveContact(fullContact ?? props.recipient)
      setDraftContact(fullContact as unknown as KeyValueStore)
      setIsPickerMode(false)
      setMode('editing')
    })
  }

  return (
    <Dialog
      title='Kontakt ohne E-Mail-Adresse'
      icon='address-book'
      style={{ maxWidth: '54rem' }}
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      actions={[
        {
          children: 'Abbrechen',
          onClick: props.onDismiss,
        },
        {
          children: 'E-Mail-Adresse hinzufügen',
          variant: 'primary',
          onClick: openContactEditor,
          isLoading: !fullContact,
        },
      ]}
    >
      <Text>
        Bei diesem Kontakt ist noch keine E-Mail-Adresse hinterlegt. Füge eine
        E-Mail-Adresse hinzu, um Forderungen und Offerten an diesen Kontakt
        versenden zu können.
      </Text>
    </Dialog>
  )
}

export default observer(CustomerInvoiceEmailSetupDialog)
