import { Button } from '@nextbusiness/infinity-ui'
import React, { useState } from 'react'
import CreateProductModal from './CreateProductModal'

interface ProductsListActionsProps {
  children: React.ReactNode
}

const ProductsListActions = (props: ProductsListActionsProps) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false)

  return (
    <div className='product-list-actions'>
      <Button iconLeft='plus' onClick={() => setIsCreateModalOpen(true)}>
        Neues Produkt
      </Button>
      <div className='dialog-actions'>{props.children}</div>
      <CreateProductModal
        isOpen={isCreateModalOpen}
        onDismiss={() => setIsCreateModalOpen(false)}
      />
    </div>
  )
}

export default ProductsListActions
