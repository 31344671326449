import React from 'react'
import './FormField.scss'

interface FormFieldProps {
  label: React.ReactNode
  fieldId?: string
  children: React.ReactNode
}

const FormField = (props: FormFieldProps) => (
  <div className='ui-form-field'>
    <div className='ui-form-field-label'>
      <label htmlFor={props.fieldId}>{props.label}</label>
    </div>
    <div className='ui-form-field-control'>{props.children}</div>
  </div>
)

export default FormField
