import { Contact } from 'model/Contact'

const personName = (contact?: Contact, includeFormOfAddress?: boolean) => {
  if (!contact) return ''
  let fullName = `${contact?.firstname ?? ''} ${contact?.lastname ?? ''}`.trim()
  if (includeFormOfAddress && contact.formOfAddress) {
    fullName = `${contact.formOfAddress} ${fullName}`
  }
  return fullName
}

const ContactUtilities = {
  personName,
}

export default ContactUtilities
