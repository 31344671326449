import { Button, Heading, Spacer, Text } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import AssistantStepScreen, {
  AssistantStepVisual,
} from '../AssistantStepScreen'
import ClosingDepreciationCard from '../components/ClosingDepreciationCard'
import FinancialClosingAssistant from '../FinancialClosingAssistant'
import CreateDepreciationModal from '../modals/CreateDepreciationModal'

interface ClosingDepreciationsStepProps {
  assistant: FinancialClosingAssistant
}

const ClosingDepreciationsStep = (props: ClosingDepreciationsStepProps) => {
  const { financialCloseStore } = useRootStore()
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false)

  return (
    <AssistantStepScreen
      visual={AssistantStepVisual.Depreciations}
      actions={
        <>
          <Button onClick={() => props.assistant.previousStep()}>Zurück</Button>
          {financialCloseStore.financialCloseSettings?.depreciations.length ? (
            <Button
              variant='primary'
              onClick={() => props.assistant.nextStep()}
            >
              Fortfahren
            </Button>
          ) : (
            <Button onClick={() => props.assistant.nextStep()}>
              Überspringen
            </Button>
          )}
        </>
      }
      depiction={
        <>
          <Heading type='h2'>Abschreibungen</Heading>
          <Text type='paragraph'>
            Über Zeit verliert dein Anlagevermögen an Wert. Mit Abschreibungen
            kannst du diese Wertverluste in der Buchhaltung abbilden.
          </Text>
        </>
      }
    >
      <Button
        variant='secondary'
        iconLeft='plus'
        onClick={() => setIsCreateModalOpen(true)}
      >
        Neue Abschreibung
      </Button>
      <Spacer size='tiny' />
      <div className='depreciations-list'>
        {financialCloseStore.financialCloseSettings?.depreciations?.map(
          (depreciation) => (
            <ClosingDepreciationCard
              depreciation={depreciation}
              key={depreciation._id}
            />
          )
        )}
      </div>
      <CreateDepreciationModal
        isOpen={isCreateModalOpen}
        onDismiss={() => setIsCreateModalOpen(false)}
      />
    </AssistantStepScreen>
  )
}

export default observer(ClosingDepreciationsStep)
