import { RouterHistory } from '@nextbusiness/infinity-ui/dist/layout/header/HeaderNavigationTabs'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { CurrentLedgerNavigationStackRef } from 'ledger/LedgerNavigationStack'
import { IAccount } from 'model/Account'
import { store } from 'stores/RootStoreContext'
import EverythingSearchProviderResult from '../EverythingSearchProviderResult'
import EverythingSearchResultsProvider from '../EverythingSearchResultsProvider'

class AccountSearchProvider implements EverythingSearchResultsProvider {
  providerName = 'Konten'

  private resultForAccount(account: IAccount): EverythingSearchProviderResult {
    return {
      id: account.id,
      reference: account.accountNumber,
      customIconView: (
        <div
          className='account-swatch'
          style={{ backgroundColor: account.colour ?? '#ddd' }}
        />
      ),
      title: account.name,
      details: store.preferencesStore.showAccountNumbers
        ? account.accountNumber
        : undefined,
      type: 'account',
    }
  }

  resultsForQuery(query: string): Promise<EverythingSearchProviderResult[]> {
    const results: EverythingSearchProviderResult[] = []

    for (const item of store.accountStore.allAccounts) {
      if (
        item.name.toLowerCase().includes(query.toLowerCase()) ||
        String(item.accountNumber).startsWith(query)
      ) {
        results.push(this.resultForAccount(item))
      }
    }
    return Promise.resolve(results)
  }

  onResultSelected(
    result: EverythingSearchProviderResult,
    history?: RouterHistory
  ) {
    const accountNumber = result.reference as number
    MixpanelAnalytics.event('Account opened - from search')
    store.ledgerStore.setCurrentRootView(accountNumber)
    if (!CurrentLedgerNavigationStackRef) {
      history?.push('/buchen')
    }
  }
}

export default AccountSearchProvider
