import { ICompleteDocument } from '@nextbusiness/infinity-finance-api'
import MagicSheet, {
  NavigationIntent,
  View,
} from 'components/magic-sheet/MagicSheet'
import DocumentView from 'documents/processed-documents/DocumentView'
import AccountPage from 'ledger/accounts/AccountPage'
import ChartOfAccountsPage from 'ledger/accounts/chart-of-accounts/ChartOfAccountsPage'
import SetupAccountBalancesPage from 'ledger/accounts/onboarding/SetupAccountBalancesPage'
import { inject, observer } from 'mobx-react'
import { IAccount } from 'model/Account'
import AccountStore from 'stores/AccountStore'
import LedgerStore from 'stores/LedgerStore'
import LedgerProvider from './LedgerContext'
import LiveAccountingSetup from './accounts/onboarding/live-accounting-setup/LiveAccountingSetup'

interface LedgerNavigationStackProps {
  ledgerStore?: LedgerStore
  accountStore?: AccountStore
  rootAccountNumber: number
}

export let CurrentLedgerNavigationStackRef: LedgerNavigationStack | null = null

@inject('ledgerStore', 'accountStore')
@observer
class LedgerNavigationStack extends MagicSheet<LedgerNavigationStackProps> {
  componentDidMount() {
    CurrentLedgerNavigationStackRef = this
    this.presentViewModally(
      this.accountViewForAccountNumber(
        this.props.ledgerStore!.currentRootView,
        this
      )
    )
  }

  componentWillUnmount() {
    CurrentLedgerNavigationStackRef = null
  }

  private accountViewForAccountNumber = (
    accountNumber: number,
    magicSheet: MagicSheet
  ): View => {
    return {
      title: () =>
        this.props.accountStore?.find(accountNumber)?.name ??
        `Konto ${accountNumber}`,
      view: (
        <LedgerProvider
          currentAccountNumber={accountNumber}
          goToRootView={magicSheet.goToRootView.bind(magicSheet)}
          popCurrentView={magicSheet.popCurrentView.bind(magicSheet)}
          openAccountPageModally={(accountNumberToOpen) =>
            this.openAccountPageModally(accountNumberToOpen, magicSheet)!
          }
          openSetupAccountBalancesPage={() =>
            this.openSetupAccountBalancesPage()
          }
          openChartOfAccountsPage={() => this.openChartOfAccountsPage()}
          openDocumentPageModally={(document) =>
            this.openDocumentPageModally(document, magicSheet)
          }
          openSetupLiveAccountingPage={(account) =>
            this.openSetupLiveAccountingPage(account)
          }
        >
          <AccountPage />
        </LedgerProvider>
      ),
    }
  }

  private openAccountPageModally = (
    accountNumber: number,
    magicSheet: MagicSheet
  ) =>
    magicSheet.presentViewModally(
      this.accountViewForAccountNumber(accountNumber, magicSheet)
    )

  private openDocumentPageModally = (
    document: ICompleteDocument,
    magicSheet: MagicSheet
  ) =>
    magicSheet.presentViewModally({
      title: () => document.document.title,
      view: <DocumentView documentId={document.document.id} />,
    })

  switchRootToAccount(accountNumber: number) {
    const view = this.accountViewForAccountNumber(accountNumber, this)
    this.setNewRootView(view)
  }

  openSetupAccountBalancesPage() {
    this.presentViewModally({
      title: () => 'Mit Anfangsbeständen starten',
      view: (
        <SetupAccountBalancesPage
          dismiss={() => this.goToRootView()}
          openChartOfAccountsPage={() => this.openChartOfAccountsPage()}
        />
      ),
    })
  }

  openChartOfAccountsPage() {
    this.presentViewModally({
      title: () => 'Kontenplan',
      view: <ChartOfAccountsPage onDismiss={() => this.popCurrentView()} />,
    })
  }

  openSetupLiveAccountingPage(account: IAccount) {
    const viewIsAlreadyOpen = this.currentViews.some(
      (view) => view.title() === 'Live Accounting einrichten'
    )
    if (viewIsAlreadyOpen) return

    this.presentViewModally({
      title: () => 'Live Accounting einrichten',
      view: (
        <LiveAccountingSetup
          account={account}
          dismiss={() => this.goToRootView()}
        />
      ),
    })
  }

  componentDidUpdate(prevProps: LedgerNavigationStackProps) {
    if (prevProps.rootAccountNumber !== this.props.rootAccountNumber) {
      this.switchRootToAccount(this.props.rootAccountNumber)
    }
  }

  protected isNavigationAllowed(navigationIntent: NavigationIntent): boolean {
    switch (navigationIntent) {
      case NavigationIntent.PopCurrentView: {
        const viewHasUnsavedChanges = this.props.ledgerStore!.hasUnsavedChanges(
          this.state.navigationStack[this.state.navigationStack.length - 1].id
        )

        if (viewHasUnsavedChanges)
          this.props.ledgerStore?.setAbortIntent('previous view')
        return !viewHasUnsavedChanges
      }
      case NavigationIntent.GoToRootView: {
        const canNavigateToRoot = this.props.ledgerStore!.canNavigateToRoot()
        if (!canNavigateToRoot)
          this.props.ledgerStore?.setAbortIntent('root view')
        return canNavigateToRoot
      }
      default:
        return true
    }
  }
}

export default LedgerNavigationStack
