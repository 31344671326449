import { FinancialHealthAssessment } from '../FinancialHealthAssessment'
import FinancialHealthMetric from '../FinancialHealthMetric'

class MetricOverdueAccountsPayable extends FinancialHealthMetric {
  title = 'Überfällige Rechnungen'
  description = 'Verhältnis unbezahlter Rechnungen an dich, die überfällig sind'

  getAssessment = (): FinancialHealthAssessment =>
    FinancialHealthMetric.assessmentFromStatus(
      this.context.maturities?.accountsPayable.status
    )

  getStatusText = (): string =>
    this.context.maturities?.accountsPayable.status ?? 'Keine Angabe'

  getActualValue = () => {
    const count = this.context.maturities?.accountsPayable.overdue.length
    return `${count} überfällige Rechnung${count !== 1 ? 'en' : ''}`
  }
}

export default MetricOverdueAccountsPayable
