import { useContext, useEffect, useRef } from 'react'
import EditableDocumentText from '../controls/EditableDocumentText'
import DocumentRendererContext from '../DocumentRendererContext'
import DocumentElementProps from './DocumentElementProps'

const DocumentElementIntroductoryText = (props: DocumentElementProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const renderContext = useContext(DocumentRendererContext)

  useEffect(() => {
    renderContext.updateHeights({
      introductoryText: ref.current?.clientHeight || 0,
    })
  }, [props.document.introductoryText])

  return props.document.introductoryText !== null ? (
    <div className='page-start-text' ref={ref}>
      <EditableDocumentText
        onChange={(introductoryText) =>
          props.registerEdit({ introductoryText })
        }
        isReadOnly={!props.isEditable}
        value={props.document.introductoryText ?? ''}
        placeholder='Einführungstext hier eingeben'
        isMultiline
      />
    </div>
  ) : null
}

export default DocumentElementIntroductoryText
