import { FinancialHealthAssessment } from '../FinancialHealthAssessment'
import FinancialHealthMetric from '../FinancialHealthMetric'

class MetricOverdueAccountsReceivable extends FinancialHealthMetric {
  title = 'Überfällige Forderungen'
  description =
    'Verhältnis unbezahlter Forderungen von deinen Kunden, die überfällig sind'

  getAssessment = (): FinancialHealthAssessment =>
    FinancialHealthMetric.assessmentFromStatus(
      this.context.maturities?.accountsReceivable.status
    )

  getStatusText = (): string =>
    this.context.maturities?.accountsReceivable.status ?? 'Keine Angabe'

  getActualValue = () => {
    const count = this.context.maturities?.accountsReceivable.overdue.length
    if (count === undefined) return 'Keine Angabe'
    return `${count} überfällige Forderung${count !== 1 ? 'en' : ''}`
  }
}

export default MetricOverdueAccountsReceivable
