import { Flex } from '@nextbusiness/infinity-ui'
import { RouterHistory } from '@nextbusiness/infinity-ui/dist/layout/header/HeaderNavigationTabs'
import DateUtilities from 'utility/DateUtilites'
import ContactSwatch from '../../../invoices/shared-components/ContactSwatch'
import { IVendorInvoice } from '../../../model/VendorInvoice'
import VendorInvoices from '../../../networking/VendorInvoices'
import { CurrentVendorInvoiceNavigationStackRef } from '../../../pages/VendorInvoicePage'
import { store } from '../../../stores/RootStoreContext'
import EverythingSearchProviderResult from '../EverythingSearchProviderResult'
import EverythingSearchResultsProvider from '../EverythingSearchResultsProvider'

class VendorInvoiceSearchProvider implements EverythingSearchResultsProvider {
  providerName = 'Rechnungen'

  private resultForInvoice(
    invoice: IVendorInvoice,
    query: string
  ): EverythingSearchProviderResult {
    const contact = invoice.creditor
    const contactName =
      store.contactStore.displayNameForContactWithId(contact) ?? 'Kein Kontakt'
    const containsInPositions = !invoice.title
      .toLowerCase()
      .includes(query.toLowerCase())

    return {
      id: invoice.id,
      title: invoice.title,
      type: 'invoice',
      icon: 'bill',
      reference: invoice,
      details: (
        <div className='invoice-preview'>
          <Flex
            className='invoice-details'
            gap='tiny'
            verticalAlignment='center'
          >
            <ContactSwatch contactId={contact} small />
            <span className='contact-name'>{contactName}</span>
            <span className='date'>
              {DateUtilities.humanFormatNumeric(invoice.openingDate)}
            </span>
          </Flex>
          {containsInPositions && (
            <div>
              Beinhaltet <b>"{query}"</b> in Positionen
            </div>
          )}
        </div>
      ),
    }
  }

  async resultsForQuery(
    query: string
  ): Promise<EverythingSearchProviderResult[]> {
    const results: EverythingSearchProviderResult[] = []

    if (query.length > 2) {
      try {
        const invoices = await VendorInvoices.listInvoices(
          store.authenticationStore.organisationIdentifier,
          {
            text: query,
          }
        )
        results.push(
          ...invoices.map((transaction) =>
            this.resultForInvoice(transaction, query)
          )
        )
      } catch {
        // Don't bother
      }
    }
    return results
  }

  onResultSelected(
    result: EverythingSearchProviderResult,
    history?: RouterHistory
  ) {
    const invoice = result.reference as IVendorInvoice
    if (CurrentVendorInvoiceNavigationStackRef) {
      CurrentVendorInvoiceNavigationStackRef?.goToRootView()
      CurrentVendorInvoiceNavigationStackRef?.openInvoice(invoice)
    } else {
      history?.push('/rechnungen')
      window.setTimeout(() => {
        // This is a temporary solution until MagicSheet can handle URL navigation
        CurrentVendorInvoiceNavigationStackRef?.openInvoice(invoice)
      }, 250)
    }
  }
}

export default VendorInvoiceSearchProvider
