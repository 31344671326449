import { Text } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react-lite'
import PreCloseChecks from 'model/financial-close/PreCloseChecks'
import React from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import Check from './Check'

interface PreCloseChecklistProps {
  checks: PreCloseChecks
  canClose: boolean
}

const PreCloseChecklist = (props: PreCloseChecklistProps) => {
  const { preferencesStore } = useRootStore()
  const { canClose, checks } = props
  const showAccountNumbers = preferencesStore.showAccountNumbers

  return (
    <div className='pre-close-checklist'>
      {canClose ? (
        <Text variant='success'>Alle Voraussetzungen sind erfüllt.</Text>
      ) : (
        <Text variant='warning'>
          Da einzelne Checks nicht erfüllt sind, hast du womöglich noch Fehler
          in der Buchhaltung. Wir empfehlen dir, deine Buchungen nochmals zu
          überprüfen.
        </Text>
      )}
      <Check isFulfilled={checks.closingBalance2IsZero}>
        Kein unverbuchter Gewinn
      </Check>
      <Check isFulfilled={checks.profitLossMethodCompatible}>
        Ausgewählte Methode für Gewinnverwendung ist anwendbar
      </Check>
      <Check isFulfilled={checks.totalAssetsEqualsTotalLiabilities}>
        Summe des Vermögen entspricht Summe der Schulden nach Gewinnverbuchung
      </Check>
      <Check isFulfilled={checks.totalIncomeEqualsTotalExpenses}>
        Summe der Einnahmen entspricht Summe der Ausgaben und Gewinn
      </Check>
      <Check isFulfilled={checks.balanceOf9000IsZero}>
        Saldo von Konto{' '}
        {showAccountNumbers ? '9000 (Erfolgsrechnung)' : 'Erfolgsrechnung'} ist
        null
      </Check>
      <Check isFulfilled={checks.balanceOf9100IsZero}>
        Saldo von Konto{' '}
        {showAccountNumbers ? '9100 (Eröffnungsbilanz)' : 'Eröffnungsbilanz'}{' '}
        ist null
      </Check>
      <Check isFulfilled={checks.balanceOf9101IsZero}>
        Saldo von Konto{' '}
        {showAccountNumbers ? '9101 (Schlussbilanz)' : 'Schlussbilanz'} ist null
      </Check>
      <Check isFulfilled={checks.balanceOf9900IsZero}>
        Saldo von Konto{' '}
        {showAccountNumbers ? '9900 (Korrekturen)' : 'Korrekturen'} ist null
      </Check>
    </div>
  )
}

export default observer(PreCloseChecklist)
