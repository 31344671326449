import mixpanel from 'mixpanel-browser'

class MixpanelAnalytics {
  public static get shouldInitialise() {
    return import.meta.env.VITE_MIXPANEL_TOKEN !== undefined
  }

  public static initialise() {
    mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
      debug: import.meta.env.DEV,
      track_pageview: true,
      persistence: 'localStorage',
    })
  }

  public static event(eventName: string, properties?: Record<string, any>) {
    if (!this.shouldInitialise) return
    try {
      mixpanel.track(eventName, properties)
    } catch {
      // Ignore unsuccessful Mixpanel tracking events
    }
  }
}

export default MixpanelAnalytics
