import { Flex } from '@nextbusiness/infinity-ui'
import VendorInvoiceListItem from 'invoices/vendor-invoices/all-invoices/VendorInvoiceListItem'
import { observer } from 'mobx-react'
import { IVendorInvoice } from 'model/VendorInvoice'
import { CurrentVendorInvoiceNavigationStackRef } from 'pages/VendorInvoicePage'
import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useRootStore } from 'stores/RootStoreContext'
import { queueNavigationTask } from 'utility/Utilities'
import { usePluralise } from 'utility/hooks'
import { ContactContext } from './ContactContext'
import { FilterContext } from './ContactFilterContext'
import ContactListCreateItem from './ContactListCreateItem'
import ContactListEmptyState from './ContactListEmptyState'
import './ContactListRenderVendorInvoices.scss'
import ContactStack from './ContactStack'

interface ContactListRenderVendorInvoicesProps {
  data: IVendorInvoice[]
  stack: ContactStack
}

const ContactListRenderVendorInvoices = (
  props: ContactListRenderVendorInvoicesProps
) => {
  const { contactStore } = useRootStore()
  const openVendorInvoice = (invoice: IVendorInvoice) =>
    props.stack.openVendorInvoice(invoice)
  const history = useHistory()
  const contact = useContext(ContactContext)
  const attachContact = () => {
    contactStore.attachContactToNewInvoice(contact)
    history.push('/rechnungen')
    queueNavigationTask(() => {
      CurrentVendorInvoiceNavigationStackRef?.openCreateInvoiceModal()
    })
  }
  const { isFiltering, searchValue } = useContext(FilterContext)

  const resultText = usePluralise(
    props.data.length,
    'Rechnung',
    {
      plural: 'Rechnungen',
    },
    'Keine'
  )

  if (props.data.length === 0 && !isFiltering) {
    return (
      <ContactListEmptyState
        listType='vendorInvoices'
        onActionClick={attachContact}
      />
    )
  }

  return (
    <>
      <ContactListCreateItem onClick={attachContact}>
        Rechnung verbuchen
      </ContactListCreateItem>

      {isFiltering && (
        <div className='contact-list-filtering'>{`${resultText} für «${searchValue}» gefunden`}</div>
      )}
      <Flex className='data-items' direction='vertical'>
        {props.data.map((invoice: IVendorInvoice) => (
          <VendorInvoiceListItem
            key={invoice.id}
            invoice={invoice}
            onClick={() => openVendorInvoice(invoice)}
          />
        ))}
      </Flex>
    </>
  )
}

export default observer(ContactListRenderVendorInvoices)
