import StatusLabel from 'components/status-label/StatusLabel'
import { DateTime, Interval } from 'luxon'

export type DueStatus = 'due' | 'overdue' | 'paid' | 'no-due-date' | 'annulled'

interface InvoiceStatusProps {
  dueDate: string | number | undefined
  isPaid: boolean
  isAnnulled?: boolean
  isDraft?: boolean
}

const swatchColourFor = (status: DueStatus) => {
  switch (status) {
    case 'due':
      return '#0aa8bf'
    case 'overdue':
      return '#e0139b'
    case 'paid':
      return '#31c35d'
    case 'no-due-date':
      return '#ccc'
    case 'annulled':
      return '#3f5e5e'
  }
}
const convertToDateTime = (date: string | number) =>
  typeof date === 'string' //TODO: fix when backend response is fixed
    ? DateTime.fromISO(date)
    : DateTime.fromMillis(date)

const InvoiceStatus = (props: InvoiceStatusProps) => {
  const dueDate = props.dueDate
    ? convertToDateTime(props.dueDate).endOf('day')
    : undefined
  const currentDate = DateTime.now()
  const dueStatusFor = (dueDate?: DateTime): DueStatus => {
    if (!dueDate) return 'no-due-date'
    if (props.isAnnulled) return 'annulled'
    if (props.isPaid) return 'paid'
    const isOverdue = dueDate.toMillis() < currentDate.toMillis()
    return isOverdue ? 'overdue' : 'due'
  }
  const dueIntervalFor = (status: DueStatus) => {
    if (!dueDate) return null
    switch (status) {
      case 'due':
        return Interval.fromDateTimes(currentDate, dueDate).count('days') - 1
      case 'overdue':
        return Interval.fromDateTimes(dueDate, currentDate).count('days') - 1
    }
  }
  const status = dueStatusFor(dueDate)
  const textFor = (status: DueStatus) => {
    const dueInterval = dueIntervalFor(status)
    if (dueInterval === 0) {
      return 'Heute fällig'
    }
    switch (status) {
      case 'due':
        if (dueInterval === 1) return 'In 1 Tag fällig'
        else return `In ${dueInterval} Tagen fällig`
      case 'overdue':
        if (dueInterval === 1) return 'Überfällig seit 1 Tag'
        else return `Überfällig seit ${dueInterval} Tagen`
      case 'paid':
        return 'Bezahlt'
      case 'no-due-date':
        return 'Kein Fälligkeitsdatum'
      case 'annulled':
        return 'Storniert'
    }
  }

  return (
    <StatusLabel
      swatchColour={swatchColourFor(props.isDraft ? 'no-due-date' : status)}
      text={props.isDraft ? 'Entwurf' : textFor(status)}
    />
  )
}

export default InvoiceStatus
