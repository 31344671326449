import {
  APIError,
  Finance,
  IDocument,
} from '@nextbusiness/infinity-finance-api'
import {
  Dialog,
  InputField,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { useEnter } from 'utility/hooks'

interface RenameDocumentModalProps {
  isOpen: boolean
  document: IDocument
  closeModal: () => void
  reloadDocument: () => Promise<void>
}

const RenameDocumentModal = (props: RenameDocumentModalProps) => {
  const { documentStore } = useRootStore()
  const notificationCenter = useNotificationCenter()
  const [title, setTitle] = useState<string>(props.document.title)
  const [isRenaming, setIsRenaming] = useState<boolean>(false)

  const renameDocument = async (newTitle: string) => {
    if (!newTitle || newTitle.trim() === '') {
      return
    }
    setIsRenaming(true)
    try {
      await Finance.Documents.modifyDocument(props.document.id, {
        title: newTitle,
      })
      notificationCenter.addNotification({
        children: 'Beleg erfolgreich umbenannt',
        variant: 'success',
      })
      await props.reloadDocument()
    } catch (error: any) {
      notificationCenter.addNotification({
        title: 'Beleg konnte nicht umbenannt werden',
        children: (error as APIError).humanText('de'),
        variant: 'error',
      })
    } finally {
      setIsRenaming(false)
      documentStore.loadAllDocuments()
      props.closeModal()
    }
  }

  useEffect(() => {
    const resetModal = () => {
      setTitle(props.document.title)
    }
    if (props.isOpen) {
      resetModal()
    }
  }, [props.isOpen])

  useEnter(() => renameDocument(title))

  return (
    <Dialog
      isOpen={props.isOpen}
      title='Beleg umbennen'
      titleProps={{ divider: true }}
      actions={[
        {
          children: 'Abbrechen',
          onClick: props.closeModal,
        },
        {
          variant: 'primary',
          children: 'Umbenennen',
          isLoading: isRenaming,
          onClick: () => renameDocument(title),
          disabled: title === '',
        },
      ]}
    >
      <InputField
        value={title}
        onChange={(input) => setTitle(input)}
        helperText='Name des Belegs'
      />
    </Dialog>
  )
}

export default observer(RenameDocumentModal)
