import { Icon, IconItem, IconSize } from '@nextbusiness/infinity-ui-icons'

type GuideItemType = 'article' | 'video'
const GUIDE_ICONS_BY_TYPE: Record<GuideItemType, IconItem> = {
  article: 'book',
  video: 'video-camera',
}

interface WelcomeCentreGuideItemProps {
  title: string
  description: string
  href: string
  type: GuideItemType
}

const WelcomeCentreGuideItem = (props: WelcomeCentreGuideItemProps) => {
  const icon = GUIDE_ICONS_BY_TYPE[props.type]
  return (
    <a
      className='guide-item'
      href={props.href}
      target='_blank'
      rel='noopener noreferrer'
    >
      <div className='icon'>
        <Icon icon={icon} size={IconSize.Medium} tint='teal' />
      </div>
      <div className='depiction'>
        <span className='title'>{props.title}</span>
        <span className='description'>{props.description}</span>
      </div>
    </a>
  )
}

export default WelcomeCentreGuideItem
