import {
  DocumentLocation,
  ICompleteDocument,
} from '@nextbusiness/infinity-finance-api'
import { MenuItem } from '@nextbusiness/infinity-ui'
import MoreMenu from 'components/more-menu/MoreMenu'
import { observer } from 'mobx-react'
import { useState } from 'react'
import RenameDocumentModal from './RenameDocumentModal'
import UnfileDocumentModal from './UnfileDocumentModal'

interface DocumentMoreMenuProps {
  completeDocument: ICompleteDocument
  reloadDocument: () => Promise<void>
}

const DocumentMoreMenu = (props: DocumentMoreMenuProps) => {
  const [isRenameModalOpen, setIsRenameModalOpen] = useState<boolean>(false)
  const [isUnfilingModalOpen, setIsUnfilingModalOpen] = useState<boolean>(false)

  function downloadBlob(blob: Blob, filename: string) {
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')

    a.href = url
    a.download = filename || 'download'

    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url)
        removeEventListener('click', clickHandler)
      }, 150)
    }

    a.addEventListener('click', clickHandler, false)
    a.click()
  }

  const downloadFile = async () => {
    const contents = await fetch(props.completeDocument.cloudFile.url)
    const blob = await contents.blob()

    const filaName = props.completeDocument.document.title
    downloadBlob(blob, filaName)
  }

  const documentActions: MenuItem[] = [
    {
      text: 'Beleg umbennen',
      icon: 'rename',
      onClick: () => setIsRenameModalOpen(true),
    },
  ]

  if (props.completeDocument.document.location !== DocumentLocation.Inbox) {
    documentActions.push({
      text: 'Beleg zurücklegen',
      icon: 'link-broken',
      onClick: () => setIsUnfilingModalOpen(true),
    })
  }

  return (
    <>
      <MoreMenu
        actions={[
          {
            buttons: documentActions,
          },
          {
            title: 'Export',
            buttons: [
              {
                text: 'Herunterladen',
                icon: 'file-download',
                onClick: downloadFile,
              },
            ],
          },
        ]}
      />
      <RenameDocumentModal
        isOpen={isRenameModalOpen}
        closeModal={() => setIsRenameModalOpen(false)}
        document={props.completeDocument.document}
        reloadDocument={props.reloadDocument}
      />
      <UnfileDocumentModal
        isOpen={isUnfilingModalOpen}
        closeModal={() => setIsUnfilingModalOpen(false)}
        document={props.completeDocument.document}
        reloadDocument={props.reloadDocument}
      />
    </>
  )
}

export default observer(DocumentMoreMenu)
