import { Text } from '@nextbusiness/infinity-ui'
import { DetailedVATPeriod } from 'model/VAT'
import DateUtilities from 'utility/DateUtilites'

interface VATReportStatusProps {
  isPeriodDue: boolean
  isPeriodBlocked: boolean
  isReportInvalid: boolean
  period: DetailedVATPeriod
}

const VATReportStatus = (props: VATReportStatusProps) => {
  return (
    <div className='report-status'>
      {props.period.wasSettled ? (
        <Text variant='success'>
          Diese Periode wurde am{' '}
          {DateUtilities.humanFormat(props.period.settledAt)} abgerechnet.
        </Text>
      ) : !props.isPeriodDue ? (
        <Text variant='warning'>
          Du kannst diese Periode erst nach dem{' '}
          {DateUtilities.humanFormat(props.period.end)} definitiv abrechnen.
        </Text>
      ) : props.isPeriodBlocked ? (
        <Text variant='warning'>
          Diese Periode kann erst abgerechnet werden, sobald alle früheren
          Perioden abgerechnet worden sind.
        </Text>
      ) : props.isReportInvalid ? (
        <Text variant='warning'>
          Es bestehen noch Fehler in dieser MWST-Periode. Überprüfe, ob alle
          Transaktionen korrekt verbucht sind.
        </Text>
      ) : null}
    </div>
  )
}

export default VATReportStatus
