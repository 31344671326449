import { Flex } from '@nextbusiness/infinity-ui'
import React from 'react'

interface SidebarInfoItemProps {
  label: string
  value: React.ReactNode
}

const SidebarInfoItem = (props: SidebarInfoItemProps) => (
  <Flex className='sidebar-info-item' direction='vertical' gap={0.8}>
    <div className='info-item-label'>{props.label}</div>
    <div className='info-item-value'>{props.value}</div>
  </Flex>
)

export default SidebarInfoItem
