import { APIError, Finance, IProduct } from '@nextbusiness/infinity-finance-api'
import { useNotificationCenter } from '@nextbusiness/infinity-ui'
import ConfirmDeletionModal from 'components/dialogs/ConfirmDeletionModal'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'

interface DeleteProductModalProps {
  product: IProduct
  isOpen: boolean
  onDismiss: () => void
  onDeleteComplete: () => void
}

const DeleteProductModal = (props: DeleteProductModalProps) => {
  const { productStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const onDelete = async () => {
    try {
      await Finance.Products.deleteProduct(props.product.id)
      await productStore.loadAllProducts()
      notificationCenter.addNotification({
        children: 'Produkt gelöscht',
        variant: 'success',
      })
      props.onDeleteComplete()
    } catch (error) {
      notificationCenter.addNotification({
        title: 'Produkt konnte nicht gelöscht werden',
        children: (error as APIError).humanText('de'),
        variant: 'error',
      })
    }
  }
  return (
    <ConfirmDeletionModal
      isOpen={props.isOpen}
      onDelete={onDelete}
      onDismiss={props.onDismiss}
      title='Produkt löschen'
    >
      Möchtest du das Produkt «{props.product.name}» wirklich löschen?
    </ConfirmDeletionModal>
  )
}

export default observer(DeleteProductModal)
