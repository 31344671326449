import { IProjectPreview } from '@nextbusiness/infinity-finance-api'
import classNames from 'classnames'
import ContactSwatch from 'invoices/shared-components/ContactSwatch'
import ExtendedInvoiceIconAmount from 'invoices/shared-components/invoice-icon/ExtendedInvoiceIconAmount'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import './ProjectCard.scss'

export interface ProjectCardProps {
  project: IProjectPreview
}

const ProjectCard = (props: ProjectCardProps) => {
  const { contactStore } = useRootStore()
  const clientName = contactStore.displayNameForContactWithId(
    props.project.client
  )

  return (
    <div
      className={classNames('project-card', {
        'has-unbilled-work': props.project.hasUnbilledWork,
      })}
    >
      <div className='project-card-icon'>
        <ContactSwatch contactId={props.project.client} small />
      </div>
      <div className='project-card-depiction'>
        <div className='metadata'>
          <span className='client'>{clientName}</span>
          <span className='value'>
            <ExtendedInvoiceIconAmount totalAmount={props.project.totalValue} />
          </span>
        </div>
        <span className='title'>{props.project.title}</span>
      </div>
    </div>
  )
}

export default observer(ProjectCard)
