import { Flex } from '@nextbusiness/infinity-ui'
import { usePluralise } from 'utility/hooks'
import { PublicInvoiceSidebarProps } from './PublicInvoiceSidebar'
import SidebarInfoItem from './SidebarInfoItem'

const PublicInvoiceSidebarInfo = (props: PublicInvoiceSidebarProps) => {
  const payableInDays = usePluralise(props.invoice.payableInDays, 'Tag', {
    plural: 'Tagen',
  })
  return (
    <Flex className='sidebar-info' direction='vertical' gap={3.2}>
      {props.template.showCustomerInvoiceNumber && (
        <SidebarInfoItem label='Rechnungsnummer' value={props.invoiceNumber} />
      )}
      <SidebarInfoItem
        label='Gesendet von'
        value={
          <span>
            {props.template.organisationName}
            <br />
            {props.template.addressLine}
            <br />
            {props.template.zipCode} {props.template.city}
          </span>
        }
      />
      <SidebarInfoItem label='Zahlungsfrist' value={payableInDays} />
    </Flex>
  )
}

export default PublicInvoiceSidebarInfo
