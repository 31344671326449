import {
  APIError,
  ErrorCode,
  Finance,
  IQRInvoicePreferences,
  QRInvoicingMethod,
} from '@nextbusiness/infinity-finance-api'
import {
  Button,
  FeedbackBanner,
  InputField,
  RadioGroup,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { useMixpanelEvent } from 'integrations/useMixpanelEvent'
import { useEffect, useState } from 'react'
import SettingsQRCode from '../../assets/settings-icons/qr-code.svg'
import SettingsFieldList from '../general/SettingsFieldList'
import SettingsGroup from '../general/SettingsGroup'
import SettingsPage from '../general/SettingsPage'

const defaultQRInvoicePreferences = (): IQRInvoicePreferences => ({
  invoicingMethod: QRInvoicingMethod.None,
})

interface QRSettingsPageProps {
  onSave?: () => void
}

const isQRIBAN = (iban: string) => {
  const ibanWithoutSpaces = iban.replace(/\s/g, '')
  if (ibanWithoutSpaces.length < 10) return false

  const ibanInstitutionIdentifier = parseInt(ibanWithoutSpaces.slice(4, 9))

  const isSwissIBAN = iban.toUpperCase().startsWith('CH')
  const isQRIID =
    ibanInstitutionIdentifier >= 30000 && ibanInstitutionIdentifier <= 31999

  return isSwissIBAN && isQRIID
}

const QRSettingsPage = (props: QRSettingsPageProps) => {
  const notificationCenter = useNotificationCenter()

  const [settings, setSettings] = useState<IQRInvoicePreferences | null>(null)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [error, setError] = useState<APIError>()

  const showInvalidIBANError = error?.code === ErrorCode.QRSettingsInvalidIBAN
  const showWrongMethodWarning =
    isQRIBAN(settings?.iban ?? '') &&
    settings?.invoicingMethod !== QRInvoicingMethod.QR_IBAN

  const isValid = () => {
    if (!settings) return false

    const isAccountHolderComplete =
      !!settings.accountHolder &&
      !!settings.accountHolder.organisationOrPerson &&
      !!settings.accountHolder.addressLine1 &&
      !!settings.accountHolder.addressLine2

    switch (settings.invoicingMethod) {
      case QRInvoicingMethod.None:
        return true
      case QRInvoicingMethod.IBAN:
        return !!settings.iban && isAccountHolderComplete
      case QRInvoicingMethod.QR_IBAN:
        return (
          !!settings.iban && isAccountHolderComplete && !!settings.qrReferenceId
        )
    }
  }

  const loadSettings = async () => {
    try {
      const loadedSettings = await Finance.Organisation.QRPreferences.current()
      setSettings(loadedSettings)
    } catch (e) {
      setSettings(defaultQRInvoicePreferences())

      const error = e as APIError
      if (error.code !== ErrorCode.QRSettingsNotFound)
        notificationCenter.addNotification({
          variant: 'error',
          children: 'Fehler beim Laden der aktuellen QR-Einstellungen.',
        })
    }
  }

  const saveSettings = async () => {
    if (!settings) return
    setIsSaving(true)
    setError(undefined)
    try {
      await Finance.Organisation.QRPreferences.modify({
        ...settings,
        accountHolder: {
          countryCode: 'CH',
          ...settings.accountHolder,
        },
      })
      notificationCenter.addNotification({
        children: 'Einstellungen gespeichert',
        variant: 'success',
      })
      if (props.onSave) props.onSave()
      MixpanelAnalytics.event('QR invoicing settings set')
    } catch (e) {
      const error = e as APIError
      if (error.code !== ErrorCode.QRSettingsInvalidIBAN) {
        notificationCenter.addNotification({
          children: error.humanText('de'),
          variant: 'error',
        })
      }
      setError(error)
    } finally {
      setIsSaving(false)
    }
  }

  useEffect(() => {
    loadSettings()
  }, [])

  useMixpanelEvent('Settings viewed - QR settings')

  if (!settings) {
    return <LoadingScreen />
  }

  return (
    <SettingsPage
      iconURL={SettingsQRCode}
      title='QR-Rechnung'
      className='qr-settings-page'
    >
      <SettingsGroup
        title='Methode'
        description='Wähle hier aus, welche Art von QR-Rechnungen du erstellen möchtest. Wenn du dir nicht sicher bist, wähle "QR-Rechnungen mit IBAN", da du bei den anderen Methoden zusätzliche Angaben von deiner Bank benötigst.'
      >
        <RadioGroup
          value={settings.invoicingMethod}
          onChange={(invoicingMethod) =>
            setSettings({ ...settings, invoicingMethod })
          }
          options={[
            {
              label: 'Keine QR-Rechnung',
              value: QRInvoicingMethod.None,
            },
            {
              label: 'QR-Rechnung mit normaler IBAN (Empfohlen)',
              value: QRInvoicingMethod.IBAN,
            },
            {
              label: 'QR-Rechnung mit QR-IBAN',
              value: QRInvoicingMethod.QR_IBAN,
            },
          ]}
        />
      </SettingsGroup>
      {settings.invoicingMethod !== QRInvoicingMethod.None && (
        <>
          <SettingsGroup title='Bankkonto'>
            <SettingsFieldList>
              <InputField
                placeholder={
                  settings.invoicingMethod === QRInvoicingMethod.QR_IBAN
                    ? 'QR-IBAN'
                    : 'IBAN'
                }
                value={settings.iban ?? ''}
                onChange={(iban) => setSettings({ ...settings, iban })}
                hasError={showInvalidIBANError}
                helperText={
                  showInvalidIBANError ? error.humanText('de') : undefined
                }
              />
            </SettingsFieldList>
            {showWrongMethodWarning && (
              <FeedbackBanner variant='warning' title='Dies ist eine QR-IBAN.'>
                Du hast eine QR-IBAN eingegeben. Wechsle entweder zur Methode
                "QR-Rechnung mit QR-IBAN", damit du die notwendigen
                Referenzinformationen noch ergänzen kannst, oder verwende die
                normale IBAN deines Bankkontos.
              </FeedbackBanner>
            )}
          </SettingsGroup>
          <SettingsGroup title='Kontoinhaber'>
            <SettingsFieldList>
              <InputField
                placeholder='Name des Kontoinhabers'
                value={settings.accountHolder?.organisationOrPerson ?? ''}
                onChange={(organisationOrPerson) =>
                  setSettings({
                    ...settings,
                    accountHolder: {
                      ...settings.accountHolder,
                      organisationOrPerson,
                    },
                  })
                }
              />
              <InputField
                placeholder='Adresse'
                value={settings.accountHolder?.addressLine1 ?? ''}
                onChange={(addressLine1) =>
                  setSettings({
                    ...settings,
                    accountHolder: { ...settings.accountHolder, addressLine1 },
                  })
                }
              />
              <InputField
                placeholder='PLZ und Ort'
                value={settings.accountHolder?.addressLine2 ?? ''}
                onChange={(addressLine2) =>
                  setSettings({
                    ...settings,
                    accountHolder: { ...settings.accountHolder, addressLine2 },
                  })
                }
              />
            </SettingsFieldList>
          </SettingsGroup>
        </>
      )}
      {settings.invoicingMethod === QRInvoicingMethod.QR_IBAN && (
        <SettingsGroup
          title='Referenzinformationen'
          description='Diese Angaben hast du von deiner Bank erhalten. Wenn du diese Angaben nicht hast, musst du entweder deine Bank anfragen oder eine andere Methode wählen.'
        >
          <SettingsFieldList>
            <InputField
              placeholder='QR-Referenz-ID'
              value={settings.qrReferenceId ?? ''}
              onChange={(qrReferenceId) =>
                setSettings({ ...settings, qrReferenceId })
              }
            />
          </SettingsFieldList>
        </SettingsGroup>
      )}
      <Button
        variant='primary'
        isLoading={isSaving}
        onClick={() => saveSettings()}
        disabled={!isValid()}
      >
        Speichern
      </Button>
    </SettingsPage>
  )
}

export default QRSettingsPage
