import Utilities from 'utility/Utilities'
import EditableDocumentText from '../../../controls/EditableDocumentText'
import { DocumentCustomProductPositionProps } from '../DocumentCustomProductPosition'

const PositionTotalAmountField = (
  props: DocumentCustomProductPositionProps
) => {
  const isSingleAmountVisible = props.columns?.includes('singleAmount')
  const canEditTotalAmount = !props.isReadOnly && !isSingleAmountVisible

  const totalAmount =
    (props.position.singleAmount ?? 0) * (props.position.quantity ?? 1)
  const displayTotalAmount = totalAmount
    ? Utilities.centsToCHF(totalAmount)
    : ''

  const updateTotalAmount = (value: string) =>
    props.onChange({
      quantity: 1,
      singleAmount: Utilities.parseToCents(value, { treatZeroAsEmpty: true }),
    })

  if (!canEditTotalAmount)
    return (
      <div className='positions-column total-amount readonly'>
        {Utilities.centsToCHF(totalAmount)} CHF
      </div>
    )

  return (
    <div className='positions-column total-amount'>
      <EditableDocumentText
        key={props.position.quantity}
        placeholder='Gesamtpreis'
        isCurrency
        value={displayTotalAmount}
        onChange={updateTotalAmount}
        style={{
          textAlign: 'right',
          width: !totalAmount ? '100%' : undefined,
        }}
      />
      {totalAmount ? ' CHF' : ''}
    </div>
  )
}

export default PositionTotalAmountField
