import { ICompleteDocument } from '@nextbusiness/infinity-finance-api'
import DocumentUploadPreview from 'invoices/vendor-invoices/DocumentUploadPreview'
import SplitView from 'proto-ui-components/SplitView'
import ExpenditureEntryForm from './ExpenditureEntryForm'

interface CreateExpenditureSplitViewProps {
  document: ICompleteDocument
}

const CreateExpenditureSplitView = (props: CreateExpenditureSplitViewProps) => (
  <SplitView
    rightPane={<DocumentUploadPreview document={props.document} />}
    leftPane={<ExpenditureEntryForm document={props.document.document} />}
    snapLeftAt={45}
    snapRightAt={72.5}
    defaultDividerPosition={45}
  />
)

export default CreateExpenditureSplitView
