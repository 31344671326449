import {
  Button,
  FeedbackBanner,
  Flex,
  Heading,
  NonIdealState,
  Spacer,
  Text,
} from '@nextbusiness/infinity-ui'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react'
import { IFiscalYear } from 'model/FiscalYear'
import { useRootStore } from 'stores/RootStoreContext'
import FiscalYearDuePrompt from './FiscalYearDuePrompt'
import './FiscalYearListItem.scss'

interface FiscalYearListItemProps {
  fiscalYear: IFiscalYear
  openBalanceSheet1?: () => void
  openBalanceSheet2?: () => void
  openIncomeStatement?: () => void
}

const FiscalYearListItem = (props: FiscalYearListItemProps) => {
  const { accountStore } = useRootStore()

  const isEarliestOpenFiscalYear = accountStore.allFiscalYears?.some(
    (fiscalYear) =>
      fiscalYear.from > props.fiscalYear.to && !fiscalYear.isClosed
  )
  const isDueForClose =
    !props.fiscalYear.isClosed && props.fiscalYear.to < Date.now()

  const endDate = DateTime.fromMillis(props.fiscalYear.to).toLocaleString({
    dateStyle: 'long',
  })

  return (
    <Flex className='fiscal-year-list-item'>
      <div className='fiscal-year-label'>
        <Heading type='h3' bare>
          Geschäftsjahr {props.fiscalYear.year + 1}
        </Heading>
      </div>
      <div className='fiscal-year-details'>
        {props.fiscalYear.isClosed ? (
          <Flex gap='tiny' direction='vertical'>
            <FeedbackBanner variant='success'>
              Dieses Geschäftsjahr ist geschlossen.
            </FeedbackBanner>
            <Flex gap='tiny'>
              <Button
                iconLeft='document'
                variant='quaternary'
                onClick={() => props.openBalanceSheet1?.()}
              >
                Schlussbilanz 1
              </Button>
              <Button
                iconLeft='document'
                variant='quaternary'
                onClick={() => props.openBalanceSheet2?.()}
              >
                Schlussbilanz 2
              </Button>
              <Button
                iconLeft='document'
                variant='quaternary'
                onClick={() => props.openIncomeStatement?.()}
              >
                Erfolgsrechnung
              </Button>
            </Flex>
          </Flex>
        ) : null}
        {isDueForClose &&
          (isEarliestOpenFiscalYear ? (
            <FiscalYearDuePrompt {...props} />
          ) : (
            <Flex
              className='fiscal-year-open'
              direction='vertical'
              verticalAlignment='center'
              horizontalAlignment='center'
            >
              Schliesse zuerst das ältere Geschäftsjahr ab.
              <Spacer />
            </Flex>
          ))}
        {!isDueForClose && !props.fiscalYear.isClosed && (
          <Flex
            className='fiscal-year-open'
            verticalAlignment='center'
            horizontalAlignment='center'
          >
            <NonIdealState icon='clock' title='Geschäftsjahr noch laufend'>
              <Text style={{ maxWidth: '44rem', textAlign: 'center' }}>
                Sobald das Geschäftsjahr am {endDate} endet, wirst du hier den
                Jahresabschluss erledigen können.
              </Text>
            </NonIdealState>
          </Flex>
        )}
      </div>
    </Flex>
  )
}

export default observer(FiscalYearListItem)
