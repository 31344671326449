import { AmountMode, TaxCode } from '@nextbusiness/infinity-finance-api'
import {
  IPosition,
  IVendorInvoice,
  IVendorInvoiceDraft,
} from 'model/VendorInvoice'
import AccountStore from 'stores/AccountStore'
import { taxRateForTaxCode } from 'utility/TaxUtilities'

/**
 * Utility class for working with vendor invoice editor.
 */
class VendorInvoiceEditorUtilities {
  /**
   * Checks if the vendor invoice is valid.
   *
   * @param invoice - The vendor invoice draft to validate.
   * @param accountStore - A reference to the account store.
   * @returns True if the vendor invoice is valid, false otherwise.
   */
  public static isValid(
    invoice: IVendorInvoiceDraft,
    accountStore: AccountStore
  ) {
    return Boolean(
      invoice.title &&
        invoice.openingDate &&
        invoice.dueDate &&
        VendorInvoiceEditorUtilities.areAllPositionsComplete(
          invoice.positions
        ) &&
        accountStore.isDateWithinOpenFiscalYear(new Date(invoice.openingDate))
    )
  }

  /**
   * Checks if all positions in the vendor invoice are complete in terms
   * of minimum required fields.
   *
   * @param positions - The potentially incomplete vendor invoice positions
   * to validate.
   * @returns True if all positions are complete, false otherwise.
   */
  public static areAllPositionsComplete(
    positions: Partial<IPosition>[] | undefined
  ) {
    return (
      positions?.length &&
      !positions
        .map((position) => this.isPositionComplete(position))
        .includes(false)
    )
  }

  /**
   * Checks if a position in the vendor invoice is complete.
   *
   * @param position - The position to validate.
   * @returns True if the position is complete, false otherwise.
   */
  public static isPositionComplete(position: Partial<IPosition>): boolean {
    return (
      !!position.contraAccount && !!position.singleAmount && !!position.text
    )
  }

  /**
   * Calculates the total sum of the vendor invoice.
   *
   * @param invoice - The vendor invoice or vendor invoice draft.
   * @returns The total sum of the vendor invoice.
   */
  public static totalSumOfInvoiceWithVAT(
    invoice: IVendorInvoice | IVendorInvoiceDraft
  ) {
    return this.sumOfPositionsWithVAT(
      invoice.positions ?? [],
      invoice.amountMode
    )
  }

  public static totalSumOfInvoice(
    invoice: IVendorInvoice | IVendorInvoiceDraft
  ) {
    return this.sumOfPositions(invoice.positions ?? [])
  }

  public static sumOfPositions(positions: Partial<IPosition>[]) {
    return positions.reduce((sum, position) => {
      sum += this.totalAmountOfPosition(position)
      return sum
    }, 0)
  }

  /**
   * Calculates the total sum of the provided vendor invoice positions.
   *
   * @param positions - The positions to calculate the sum for.
   * @returns The sum of positions in the vendor invoice.
   */
  public static sumOfPositionsWithVAT(
    positions: Partial<IPosition>[],
    amountMode = AmountMode.Gross
  ) {
    return positions.reduce((sum, position) => {
      sum += this.totalAmountOfPositionWithVAT(position, amountMode)
      return sum
    }, 0)
  }

  public static totalAmountOfPosition(position: Partial<IPosition>) {
    return (position.quantity || 1) * (position.singleAmount ?? 0)
  }

  public static totalAmountOfPositionWithVAT(
    position: Partial<IPosition>,
    amountMode = AmountMode.Gross
  ) {
    return (
      this.totalAmountOfPosition(position) + this.vatDebt(position, amountMode)
    )
  }

  public static vatDebt(position: Partial<IPosition>, amountMode: AmountMode) {
    if (!position.singleAmount) return 0
    const taxRate = taxRateForTaxCode(position.taxCode as TaxCode)
    const totalAmount = this.totalAmountOfPosition(position)
    return position.taxCode &&
      taxRate &&
      position.singleAmount &&
      amountMode === AmountMode.Net
      ? totalAmount * (taxRate / 100)
      : 0
  }
}

export default VendorInvoiceEditorUtilities
