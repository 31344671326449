import { AccountMethod } from '@nextbusiness/infinity-finance-api'
import { Flex, useNotificationCenter } from '@nextbusiness/infinity-ui'
import * as Sentry from '@sentry/react'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useRootStore } from 'stores/RootStoreContext'
import DateUtilities from 'utility/DateUtilites'
import BankAccountInitialSyncStep from './BankAccountInitialSyncStep'
import './BankAccountMethodSetup.scss'

const BankAccountSyncPage = () => {
  const { accountStore, ledgerStore } = useRootStore()
  const history = useHistory()

  const account = accountStore.newestAccountWithNumber(
    ledgerStore.currentRootView
  )
  const [syncFrom, setSyncFrom] = useState<Date | undefined>(
    DateUtilities.startOfDay(new Date())
  )
  const [syncTo, setSyncTo] = useState<Date | undefined>(
    DateUtilities.startOfDay(new Date())
  )

  const [isSaving, setIsSaving] = useState<boolean>(false)
  const notificationCenter = useNotificationCenter()

  const syncTransactions = async () => {
    if (!account || !syncFrom || !syncTo) return
    setIsSaving(true)
    try {
      void accountStore.syncAccount(account, syncFrom, syncTo)
      await accountStore.loadAccounts()

      const daysToSync = DateUtilities.daysBetween(syncFrom, syncTo) + 1
      MixpanelAnalytics.event('Live accounting manual sync performed', {
        syncRange: daysToSync,
      })
      history.push('/buchen')
    } catch (error) {
      notificationCenter.addNotification({
        variant: 'error',
        title: 'Fehler beim Synchronisieren',
        children:
          'Die Synchronisierung des Kontos konnte nicht gestartet werden. Bitte versuche es erneut.',
      })
      Sentry.captureException(error)
    } finally {
      setIsSaving(false)
    }
  }

  if (!account || account.accountMethod !== AccountMethod.Live) {
    return null
  }

  return (
    <Flex verticalAlignment='center' horizontalAlignment='center' fillContainer>
      <BankAccountInitialSyncStep
        isSaving={isSaving}
        onComplete={syncTransactions}
        syncFrom={syncFrom}
        setSyncFrom={setSyncFrom}
        syncTo={syncTo}
        setSyncTo={setSyncTo}
      />
    </Flex>
  )
}

export default observer(BankAccountSyncPage)
