import {
  Finance,
  IProject,
  ProjectStatus,
} from '@nextbusiness/infinity-finance-api'
import {
  Button,
  Flex,
  Heading,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import ConfirmDeletionModal from 'components/dialogs/ConfirmDeletionModal'
import MoreMenu from 'components/more-menu/MoreMenu'
import { observer } from 'mobx-react'
import { CurrentCustomerInvoicesNavigationStackRef } from 'pages/CustomerInvoicesPage'
import ExtendedProjectIcon from 'projects/icon/ExtendedProjectIcon'
import { useContext, useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { ProjectContext } from './ProjectView'
import './ProjectViewSidebar.scss'
import ArchiveProjectModal from './archive-project/ArchiveProjectModal'
import EditProjectModal from './edit-or-create-project/EditProjectModal'

interface ProjectViewSidebarProps {
  project: IProject
}

const ProjectViewSidebar = (props: ProjectViewSidebarProps) => {
  const { contactStore, projectStore } = useRootStore()
  const notificationCenter = useNotificationCenter()
  const projectContext = useContext(ProjectContext)

  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState<boolean>(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)

  const isArchived =
    props.project.status === ProjectStatus.Abandoned ||
    props.project.status === ProjectStatus.Completed

  const handleArchivation = async () => {
    if (isArchived) {
      await Finance.Projects.modifyProject(props.project.id, {
        status: ProjectStatus.Active,
      })
      await projectStore.fetchActiveProjects()
      await projectStore.fetchArchivedProjects()
      CurrentCustomerInvoicesNavigationStackRef?.goToRootView()
      CurrentCustomerInvoicesNavigationStackRef?.openProject(props.project.id)
      notificationCenter.addNotification({
        children: 'Das Projekt wurde erfolgreich wiederhergestellt.',
        variant: 'success',
      })
    } else {
      setIsArchiveModalOpen(true)
    }
  }
  const deleteProject = async () => {
    await Finance.Projects.deleteProject(props.project.id)
    await projectStore.fetchAllProjects()
    CurrentCustomerInvoicesNavigationStackRef?.popCurrentView()
    notificationCenter.addNotification({
      children: 'Das Projekt wurde erfolgreich gelöscht.',
      variant: 'success',
    })
  }

  useEffect(() => {
    if (!isArchiveModalOpen) projectContext?.refreshProject()
  }, [isArchiveModalOpen])

  return (
    <Flex className='project-sidebar' direction='vertical'>
      <ExtendedProjectIcon
        client={props.project.client}
        value={props.project.totalValue}
      />
      <Text className='project-client' type='inline'>
        {contactStore.displayNameForContactWithId(props.project.client)}
      </Text>
      <Heading className='project-title' type='h2'>
        {props.project.title}
      </Heading>
      {props.project.description && (
        <Text className='project-description'>{props.project.description}</Text>
      )}
      <Flex className='project-sidebar-actions' gap={1} direction='horizontal'>
        <Button iconLeft='edit' onClick={() => setIsEditModalOpen(true)}>
          Bearbeiten
        </Button>
        <MoreMenu
          actions={[
            {
              buttons: [
                {
                  current: isArchived,
                  text: isArchived ? 'Archiviert' : 'Archivieren',
                  icon: 'archive',
                  onClick: handleArchivation,
                },
                {
                  text: 'Löschen',
                  icon: 'delete',
                  onClick: () => setIsDeleteModalOpen(true),
                },
              ],
            },
          ]}
        />
      </Flex>
      <EditProjectModal
        projectToBeModified={props.project}
        isOpen={isEditModalOpen}
        onDismiss={() => setIsEditModalOpen(false)}
      />
      <ArchiveProjectModal
        projectId={props.project.id}
        isOpen={isArchiveModalOpen}
        onDismiss={() => setIsArchiveModalOpen(false)}
      />
      <ConfirmDeletionModal
        title='Projekt löschen'
        isOpen={isDeleteModalOpen}
        onDismiss={() => setIsDeleteModalOpen(false)}
        onDelete={deleteProject}
      >
        Willst du das Projekt "{props.project.title}" wirklich löschen?
        Forderungen, die mit diesem Projekt zusammenhängen, bleiben erhalten.
      </ConfirmDeletionModal>
    </Flex>
  )
}

export default observer(ProjectViewSidebar)
