import { Finance, Work } from '@nextbusiness/infinity-finance-api'
import { Button, Flex, useNotificationCenter } from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import TransactionAmount from 'components/transactions/TransactionAmount'
import { useContext, useState } from 'react'
import DateUtilities from 'utility/DateUtilites'
import { ProjectContext } from '../ProjectView'
import './UnbilledWorkItem.scss'
import WorkUtilities from './WorkUtilities'

interface UnbilledWorkItemProps {
  workItem: Work
  projectId: string
}

const UnbilledWorkItem = (props: UnbilledWorkItemProps) => {
  const notificationCenter = useNotificationCenter()
  const projectContext = useContext(ProjectContext)
  const humanReadableDate = DateUtilities.humanFormatNumeric(
    props.workItem.date
  )
  const value = WorkUtilities.valueOf(props.workItem)
  const icon = WorkUtilities.iconFor(props.workItem)
  const pricingDescription = WorkUtilities.pricingDescriptionFor(props.workItem)
  const [isBeingDeleted, setIsBeingDeleted] = useState<boolean>(false)

  const deleteWorkItem = async () => {
    setIsBeingDeleted(true)
    await Finance.Projects.removeWork(props.workItem.id, props.projectId)
    setIsBeingDeleted(false)
    projectContext?.refreshProject()
    notificationCenter.addNotification({
      children: 'Die ausgewählte Arbeit wurde erfolgreich gelöscht.',
      variant: 'success',
    })
  }

  return (
    <Flex className='project-content-list-item' verticalAlignment='center'>
      <span className='date'>{humanReadableDate}</span>
      <span className='description'>{props.workItem.description}</span>
      <Flex
        className='pricing'
        gap={0.7}
        direction='horizontal'
        verticalAlignment='center'
      >
        <Icon icon={icon} size={IconSize.Button} />
        <span className='pricing-text'>{pricingDescription}</span>
      </Flex>
      <TransactionAmount
        amount={value}
        isHidden={value === undefined || isNaN(value)}
      />
      <Button
        className='delete-button'
        iconOnly='delete'
        variant='quaternary'
        onClick={deleteWorkItem}
        isLoading={isBeingDeleted}
        tooltip='Arbeitseintrag löschen'
      />
    </Flex>
  )
}

export default UnbilledWorkItem
