import VATPeriod from 'model/VAT'
import DocumentsPage from 'pages/DocumentsPage'
import VATPeriodSection from './VATPeriodSection'

interface VATPeriodsListProps {
  periods: VATPeriod[]
  page: DocumentsPage
}

const VATPeriodsList = (props: VATPeriodsListProps) => {
  if (!props.periods.length) return null

  const settleablePeriod = props.periods
    .filter((period) => !period.wasSettled)
    .toSorted((a, b) => a.start - b.start)[0]
  return (
    <div className='vat-periods'>
      {props.periods.map((period) => {
        return (
          <VATPeriodSection
            key={period._id}
            page={props.page}
            period={period}
            settleable={settleablePeriod._id === period._id}
          />
        )
      })}
    </div>
  )
}

export default VATPeriodsList
