import { Button, Flex, Heading, Text } from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import { RouterHistory } from '@nextbusiness/infinity-ui/dist/layout/header/HeaderNavigationTabs'
import classNames from 'classnames'
import GrowthIndicatorIcon from 'components/growth-indicator/GrowthIndicatorIcon'
import { observer } from 'mobx-react'
import { KeyboardEvent, MouseEvent, useState } from 'react'
import { IAccount } from '../../model/Account'
import { useRootStore } from '../../stores/RootStoreContext'
import { centsToCHF } from '../../utility/Utilities'

interface BalanceSheetLayerProps {
  name: string
  balance: number
  depth: 0 | 1 | 2 | 3
  showComparison?: boolean
  pastBalance?: number
  date?: string
  pastDate?: string
  account?: IAccount
  history?: RouterHistory
  nestedLayer?: React.ReactNode
  dontShowExpansionChevron?: boolean
  className?: string
  sumOnly?: boolean
}

const BalanceSheetLayer = (props: BalanceSheetLayerProps) => {
  const { ledgerStore, preferencesStore } = useRootStore()

  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    props.depth > 1 ? true : false
  )

  const relativeChange = () => {
    const relativeChange = props.pastBalance
      ? Math.round(((1 / props.pastBalance) * props.balance - 1) * 1000) / 10
      : 0

    const changeIsVerySmall = 1 > relativeChange && relativeChange > -1

    return changeIsVerySmall ? relativeChange : Math.round(relativeChange)
  }

  const relativeChangeContent = () => {
    const change = relativeChange()
    return (
      <span className='content'>
        {!change ? '–' : `${change}%`}
        <GrowthIndicatorIcon change={change} />
      </span>
    )
  }

  const onLayerSelected = (e: MouseEvent | KeyboardEvent) => {
    e.stopPropagation()
    setIsCollapsed(!isCollapsed)
  }

  return (
    <div
      className={classNames('balance-sheet-layer', props.className, {
        collapsed: isCollapsed,
      })}
      role='row'
      tabIndex={0}
      onClick={onLayerSelected}
      onKeyDown={(e) => {
        if (e.key === 'Enter') onLayerSelected(e)
      }}
    >
      {props.depth === 0 ? (
        <>
          {!props.sumOnly && (
            <Flex className='row'>
              <Heading className='name depth-0' type='h3'>
                {props.name}
              </Heading>
              <Heading
                className={
                  'balance past-column depth-0' +
                  (!props.pastDate || !props.showComparison ? ' hidden' : '')
                }
                type='h3'
              >
                <span className='content'>{props.pastDate}</span>
              </Heading>
              <Heading className='balance depth-0' type='h3'>
                {props.date}
              </Heading>
              <Heading
                className={
                  'balance depth-0' +
                  (!props.pastDate || !props.showComparison ? ' hidden' : '')
                }
                type='h3'
              >
                <span className='content'>Veränderung</span>
              </Heading>
            </Flex>
          )}
        </>
      ) : props.depth === 1 ? (
        <Flex className='row'>
          <Heading className='name depth-1' type='h4'>
            {!props.dontShowExpansionChevron && (
              <Icon
                icon={isCollapsed ? 'forward' : 'expand'}
                size={IconSize.ExtraTiny}
              />
            )}
            {props.name}
          </Heading>
          <Heading
            className={
              'balance past-column depth-1' +
              ((!props.pastBalance && props.pastBalance !== 0) ||
              !props.showComparison
                ? ' hidden'
                : '')
            }
            type='h4'
          >
            <span className='content'>{centsToCHF(props.pastBalance)}</span>
          </Heading>
          <Heading className='balance depth-1' type='h4'>
            <span className='content'>{centsToCHF(props.balance)}</span>
          </Heading>
          <Heading
            className={`balance change depth-${props.depth} ${
              (!props.pastBalance && props.pastBalance !== 0) ||
              !props.showComparison
                ? 'hidden'
                : ''
            }`}
            type='h4'
          >
            {relativeChangeContent()}
          </Heading>
        </Flex>
      ) : (
        <Flex className='row'>
          <Text className={`name depth-${props.depth}`} type='inline'>
            {props.account ? (
              <Button
                className='account-button'
                variant='quaternary'
                onClick={
                  ((e: Event) => {
                    e.stopPropagation()
                    ledgerStore.setCurrentRootView(props.account!.accountNumber)
                    props.history?.push('/buchen')
                  }) as () => void
                }
              >
                <div
                  className='colour-swatch'
                  style={{
                    backgroundColor: props.account.colour || 'grey',
                  }}
                />
                {preferencesStore.showAccountNumbers && (
                  <span className='account-number'>
                    {props.account.accountNumber}
                  </span>
                )}
                <span>{props.name}</span>
              </Button>
            ) : (
              <>
                {!props.dontShowExpansionChevron && (
                  <Icon
                    icon={isCollapsed ? 'forward' : 'expand'}
                    size={IconSize.ExtraTiny}
                  />
                )}
                {props.name}
              </>
            )}
          </Text>
          {[props.pastBalance, props.balance].map((balance, index) => (
            <Text
              key={index}
              className={`balance past-column depth-${props.depth} ${
                balance === undefined || (!props.showComparison && index === 0)
                  ? 'hidden'
                  : ''
              }`}
              type='inline'
            >
              <span className='content'>{centsToCHF(balance)}</span>
            </Text>
          ))}
          <Text
            className={`balance change depth-${props.depth} ${
              props.pastBalance === undefined || !props.showComparison
                ? 'hidden'
                : ''
            }`}
            type='inline'
          >
            {relativeChangeContent()}
          </Text>
        </Flex>
      )}
      <div
        className='nested-layer'
        style={{ height: isCollapsed && props.depth !== 0 ? 0 : 'fit-content' }}
      >
        {props.nestedLayer}
      </div>
      {props.depth === 0 && (
        <Flex className='row sum'>
          <Heading className='name depth-0' type='h3'>
            {!props.sumOnly && ' Summe'} {props.name}
          </Heading>
          {[props.pastBalance, props.balance].map((balance, index) => (
            <Heading
              key={index}
              className={
                'balance past-column depth-0' +
                (balance === undefined || (!props.showComparison && index === 0)
                  ? ' hidden'
                  : '')
              }
              type='h3'
            >
              <span className='content'>{centsToCHF(balance)}</span>
            </Heading>
          ))}
          <Heading
            className={
              'balance change depth-0' +
              (props.pastBalance === undefined || !props.showComparison
                ? ' hidden'
                : '')
            }
            type='h3'
          >
            {relativeChangeContent()}
          </Heading>
        </Flex>
      )}
    </div>
  )
}

export default observer(BalanceSheetLayer)
