import { DatePicker, Dialog, Flex, Text } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import ICustomerInvoice from 'model/CustomerInvoice'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'

interface CreateAnnulmentModalProps {
  isOpen: boolean
  closeModal: () => void
  onPerformAnnulment: (date: Date) => void
  invoice: ICustomerInvoice
}

const CreateAnnulmentModal = (props: CreateAnnulmentModalProps) => {
  const { accountStore, vatPeriodStore } = useRootStore()
  const [date, setDate] = useState<Date | undefined>(new Date())

  const errorForDate = () => {
    if (!date) return 'Ein Datum ist erforderlich.'
    if (!accountStore.isDateWithinOpenFiscalYear(date))
      return 'Das Datum muss in einem offenen Geschäftsjahr liegen.'
    if (!vatPeriodStore.isDateWithinOpenVATPeriod(date))
      return 'Das Datum kann nicht in einer bereits abgerechneten Mehrwertsteuerperiode liegen.'
    if (date.getTime() < props.invoice.openingDate)
      return 'Das Datum kann nicht vor dem Rechnungsdatum liegen.'

    return undefined
  }
  const currentDateError = errorForDate()

  return (
    <Dialog
      initialFocusIndex={1}
      isOpen={props.isOpen}
      className='create-annulment-modal'
      title='Forderung stornieren'
      icon='error'
      actions={[
        {
          children: 'Abbrechen',
          onClick: props.closeModal,
        },
        {
          variant: 'primary',
          children: 'Stornieren',
          disabled: !date || currentDateError !== undefined,
          onClick: () => {
            props.onPerformAnnulment(date!)
            props.closeModal()
          },
        },
      ]}
    >
      <Flex direction='vertical'>
        <Text>Per welchem Datum soll die Forderung storniert werden?</Text>
        <DatePicker
          value={date}
          onChange={setDate}
          inputFieldProps={{ hasError: currentDateError !== undefined }}
        />
        {currentDateError && (
          <Text type='inline' variant='error'>
            {currentDateError}
          </Text>
        )}
      </Flex>
    </Dialog>
  )
}

export default observer(CreateAnnulmentModal)
