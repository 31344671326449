import { Finance, IProject } from '@nextbusiness/infinity-finance-api'
import { useContext } from 'react'
import { ProjectContext } from '../ProjectView'
import EditOrCreateProjectModal from './EditOrCreateProjectModal'

interface EditProjectModalProps {
  projectToBeModified: IProject
  isOpen: boolean
  onDismiss: () => void
}

const EditProjectModal = (props: EditProjectModalProps) => {
  const projectContext = useContext(ProjectContext)
  const editProject = async (title: string, description: string) => {
    await Finance.Projects.modifyProject(props.projectToBeModified.id, {
      title,
      description,
    })
    await projectContext?.refreshProject()
  }
  return (
    <EditOrCreateProjectModal
      titleOfModal='Projekt bearbeiten'
      saveActionCopy='Änderungen speichern'
      onSave={editProject}
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      projectToBeModified={props.projectToBeModified}
    />
  )
}

export default EditProjectModal
