import { FinancialHealthAssessment } from '../FinancialHealthAssessment'
import FinancialHealthMetric from '../FinancialHealthMetric'

class MetricOverdueAccountsReceivableRatio extends FinancialHealthMetric {
  title = 'Verhältnis überfällige/offene Forderungen'
  description = 'Wie viele deiner Forderungen überfällig sind'

  isRelevant = () => this.context.maturities?.accountsReceivable.ratio !== null

  getAssessment = (): FinancialHealthAssessment =>
    FinancialHealthMetric.assessmentFromStatus(
      this.context.maturities?.accountsReceivable.ratioStatus
    )

  getStatusText = (): string =>
    this.context.maturities?.accountsReceivable.ratioStatus ?? 'Keine Angabe'

  getActualValue = () => {
    const ratio = this.context.maturities?.accountsReceivable.ratio ?? 0
    const ratioAsPercentage = ratio * 100
    const displayedPercentage = ratioAsPercentage.toFixed(1).replace('.0', '')
    return `${displayedPercentage}% überfällige Forderungen`
  }
}

export default MetricOverdueAccountsReceivableRatio
