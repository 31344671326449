import { IconItem } from '@nextbusiness/infinity-ui-icons'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import OnboardingSuggestion from 'ledger/accounts/onboarding/OnboardingSuggestion'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

type Suggestion = 'ledger' | 'customer-invoices' | 'documents' | 'contacts'

interface WelcomeCentreSuggestion {
  suggestion: Suggestion
  title: string
  description: string
  icon: IconItem
  tint: 'default' | 'teal' | 'magenta' | 'lavender'
  href: string
  previewVideo: string
}

const WELCOME_CENTRE_SUGGESTIONS: WelcomeCentreSuggestion[] = [
  {
    suggestion: 'ledger',
    title: 'Buchhaltung einrichten',
    description:
      'Wenn du bereit bist, deine erste Einnahme oder Ausgabe zu buchen.',
    icon: 'library',
    tint: 'default',
    href: '/buchen',
    previewVideo:
      'https://hosted-content.nextcreative.ch/infinity.swiss/finance/feature-buchen.mp4',
  },
  {
    suggestion: 'customer-invoices',
    title: 'Forderungen und Offerten stellen',
    description:
      'Richte professionelle QR-Forderungen und Offerten für deine Kunden ein.',
    icon: 'purchase-order',
    tint: 'magenta',
    href: '/forderungen',
    previewVideo:
      'https://hosted-content.nextcreative.ch/infinity.swiss/finance/feature-forderungen-zahlungen.mp4',
  },
  {
    suggestion: 'contacts',
    title: 'Kunden und Lieferanten eintragen',
    description:
      'Speichere deine Kunden und Lieferanten als Kontakte für Forderungen oder Rechnungen.',
    icon: 'contacts',
    tint: 'teal',
    href: '/kontakte',
    previewVideo:
      'https://hosted-content.nextcreative.ch/infinity.swiss/finance/feature-forderungen-kontakte.mp4',
  },
]

const WelcomeCentreSuggestions = () => {
  const history = useHistory()
  const [highlightedSuggestion, setHighlightedSuggestion] =
    useState<Suggestion>('ledger')

  const currentSuggestion = WELCOME_CENTRE_SUGGESTIONS.find(
    (suggestion) => suggestion.suggestion === highlightedSuggestion
  )
  const currentPreviewVideo = currentSuggestion?.previewVideo

  return (
    <div className='welcome-centre-suggestions'>
      <div className='suggestion-list'>
        {WELCOME_CENTRE_SUGGESTIONS.map((suggestionBlock) => (
          <OnboardingSuggestion
            key={suggestionBlock.suggestion}
            title={suggestionBlock.title}
            description={suggestionBlock.description}
            icon={suggestionBlock.icon}
            tint={suggestionBlock.tint}
            actionText='Beginnen'
            onActionClick={() => {
              MixpanelAnalytics.event('Welcome centre suggestion clicked', {
                suggestion: suggestionBlock.suggestion,
              })
              history.push(suggestionBlock.href)
            }}
            variant='primary'
            highlighted={suggestionBlock.suggestion === highlightedSuggestion}
            onMouseOver={() =>
              setHighlightedSuggestion(suggestionBlock.suggestion)
            }
          />
        ))}
      </div>
      <div className='suggestion-preview'>
        {currentSuggestion && (
          <video
            autoPlay
            loop
            playsInline
            muted
            key={currentSuggestion.suggestion}
          >
            <source src={currentPreviewVideo} type='video/mp4' />
          </video>
        )}
      </div>
    </div>
  )
}

export default WelcomeCentreSuggestions
