import { TaxCode, TransactionOrigin } from '@nextbusiness/infinity-finance-api'
import { Button, Flex } from '@nextbusiness/infinity-ui'
import { useAccountPageContext } from 'ledger/accounts/AccountPageContext'
import { useAccountSheetContext } from 'ledger/accounts/AccountSheetContext'
import VATOptions from 'ledger/transactions/VATOptions'
import TransactionUtilities from 'utility/TransactionUtilities'
import { useTransactionEditorContext } from '../TransactionEditorContext'
import Lockup from 'components/text/Lockup'
import TaxUtilities, { displayTextForTaxCode } from 'utility/TaxUtilities'

const TransactionEditorVATOptions = () => {
  const {
    draftTransaction,
    updateDraftTransaction,
    contraAccountSide,
    canMakeChanges,
    originalTransaction,
  } = useTransactionEditorContext()

  const { setTransactionBeingEdited } = useAccountSheetContext()
  const { currentAccount } = useAccountPageContext()

  if (
    originalTransaction.origin === TransactionOrigin.VAT &&
    originalTransaction.linkedTransactions.length === 1
  ) {
    const [netTransactionId] = originalTransaction.linkedTransactions
    if (TransactionUtilities.isAccountVATSystemAccount(currentAccount))
      return <div className='vat-options' />
    return (
      <div className='vat-options'>
        <Button
          variant='secondary'
          iconLeft='edit'
          onClick={() => setTransactionBeingEdited(netTransactionId)}
        >
          Hauptbuchung bearbeiten
        </Button>
      </div>
    )
  }

  if (!canMakeChanges && !draftTransaction.taxCode)
    return <div className='vat-options' />

  if (!canMakeChanges)
    return (
      <div className='vat-options'>
        <Lockup icon='tax' subtle truncate>
          {TaxUtilities.displayTextForTaxCode(
            draftTransaction.taxCode as TaxCode
          )}
        </Lockup>
      </div>
    )

  return (
    <VATOptions
      transaction={draftTransaction}
      contraAccountSide={contraAccountSide}
      onChange={(taxCode) => updateDraftTransaction({ taxCode })}
      taxCode={draftTransaction.taxCode}
      isDisabled={!canMakeChanges}
      originalTransaction={originalTransaction}
    />
  )
}

export default TransactionEditorVATOptions
