import {
  APIError,
  ErrorCode,
  TransactionFilter,
} from '@nextbusiness/infinity-finance-api'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import AbortEditDialog from 'components/dialogs/AbortEditDialog'
import { SpecialAccount } from 'components/transactions/SpecialAccounts'
import { LedgerContext } from 'ledger/LedgerContext'
import { TransactionFilterProvider } from 'ledger/transactions/filter/TransactionFilterContext'
import { COOLDOWN_AFTER_PAGINATION_REHYDRATION } from 'ledger/transactions/list/TransactionListScrollView'
import { runInAction } from 'mobx'
import { inject, observer } from 'mobx-react'
import React from 'react'
import AccountStore from 'stores/AccountStore'
import AuthenticationStore from 'stores/AuthenticationStore'
import LedgerStore from 'stores/LedgerStore'
import PreferencesStore from 'stores/PreferencesStore'
import TransactionStore from 'stores/TransactionStore'
import VATPeriodStore from 'stores/VATPeriodStore'
import AccountPageProvider from './AccountPageContext'
import AccountSheet from './AccountSheet'
import OnboardingSheet from './onboarding/OnboardingSheet'

interface AccountPageProps {
  accountStore?: AccountStore
  authenticationStore?: AuthenticationStore
  transactionStore?: TransactionStore
  ledgerStore?: LedgerStore
  vatPeriodStore?: VATPeriodStore
  preferencesStore?: PreferencesStore
}

interface AccountPageState {
  loadingError: Error | null
  canLoadMoreTransactions: boolean
  canLoadMoreTransactionsForFilter: boolean
  viewId: string
  isFiltering: boolean
  appliedFilters: TransactionFilter | null
  isInitialLoad: boolean
  hasDismissedOnboarding: boolean
}

@inject(
  'transactionStore',
  'authenticationStore',
  'accountStore',
  'ledgerStore',
  'vatPeriodStore',
  'preferencesStore'
)
@observer
class AccountPage extends React.Component<AccountPageProps, AccountPageState> {
  private isNetworkRequestRunning = false
  private loadedTransactions = 0
  private hasLoadedAllTransactions = false
  private loadedTransactionsForFilter = 0
  private hasLoadedAllTransactionsForFilter = false

  static contextType = LedgerContext
  static paginationSize = 60

  declare context: React.ContextType<typeof LedgerContext>

  constructor(props: AccountPageProps) {
    super(props)
    this.state = {
      loadingError: null,
      canLoadMoreTransactions: true,
      canLoadMoreTransactionsForFilter: true,
      viewId: this.props.ledgerStore!.freshlyAddedView,
      isFiltering: false,
      appliedFilters: null,
      isInitialLoad: true,
      hasDismissedOnboarding: false,
    }
  }

  componentDidMount() {
    this.resetTransactions()
    this.loadTransactions()
    this.getAccounts()
    this.loadVATPeriods()
  }

  private loadVATPeriods = async () => {
    try {
      await this.props.vatPeriodStore?.loadPeriods()
    } catch (error: any) {
      if (error instanceof APIError) {
        if (error.code === ErrorCode.Unauthorised) return
      }
      this.setState({ loadingError: error })
    }
  }

  get currentAccount() {
    const accountNumber = this.context!.currentAccountNumber
    const account = this.props.accountStore!.find(accountNumber)
    if (!account) {
      return {
        accountNumber,
        name: `Konto ${accountNumber}`,
      } as SpecialAccount
    }
    return account
  }

  couldDisplayOnboarding = () => {
    const isFirstFiscalYear =
      this.props.accountStore!.currentFiscalYear?.year === 0

    return isFirstFiscalYear
  }

  shouldDisplayOnboarding = () => {
    const canDisplay = this.couldDisplayOnboarding()
    const wasDismissed = this.state.hasDismissedOnboarding
    const hasLoaded = !this.state.isInitialLoad

    const metadata =
      this.props.preferencesStore?.organisationPreferences?.metadata

    const isUsingNewOnboarding = metadata?.useGuidedLedgerOnboarding
    const isNewOnboardingComplete =
      metadata?.ledgerAccountBalancesSetupCompleted &&
      metadata?.ledgerChartOfAccountsSetupCompleted &&
      metadata?.ledgerConnectionStepCompleted
    const showNewOnboarding = isUsingNewOnboarding && !isNewOnboardingComplete

    const isEmpty =
      !this.isFilterActive() &&
      this.props.transactionStore!.transactions[1020]?.length === 0 &&
      this.context!.currentAccountNumber === 1020 &&
      !isUsingNewOnboarding

    return (
      canDisplay && hasLoaded && !wasDismissed && (isEmpty || showNewOnboarding)
    )
  }

  resetPaginationForFilteredTransactions = () => {
    this.loadedTransactionsForFilter = 0
    this.hasLoadedAllTransactionsForFilter = false
    this.setState({ canLoadMoreTransactionsForFilter: true })
  }

  isFilterActive = () => this.state.isFiltering && this.state.appliedFilters

  hasLoadedAllNeededTransactions = () =>
    this.isFilterActive()
      ? this.hasLoadedAllTransactionsForFilter
      : this.hasLoadedAllTransactions

  public resetTransactions = () => {
    const accountNumber = this.context!.currentAccountNumber

    this.loadedTransactions = 0
    this.loadedTransactionsForFilter = 0

    this.hasLoadedAllTransactions = false
    this.hasLoadedAllTransactionsForFilter = false
    this.isNetworkRequestRunning = false
    this.setState({
      isInitialLoad: true,
    })

    runInAction(() => {
      this.props.transactionStore!.clearFilteredTransactionsForAccount(
        accountNumber
      )
      this.props.transactionStore!.transactions[accountNumber] = []
    })
  }

  async loadTransactions() {
    if (this.isNetworkRequestRunning || this.hasLoadedAllNeededTransactions())
      return
    if (!this.props.authenticationStore!.organisationIdentifier)
      return this.props.authenticationStore!.logout()

    this.setState({ loadingError: null })
    try {
      this.isNetworkRequestRunning = true
      if (this.isFilterActive()) {
        await this.fetchFilteredTransactions()
      } else {
        await this.fetchUnfilteredTransactions()
      }
    } catch (error: any) {
      this.setState({ loadingError: error })
    } finally {
      setTimeout(() => {
        this.isNetworkRequestRunning = false
      }, COOLDOWN_AFTER_PAGINATION_REHYDRATION)
    }
  }

  async fetchUnfilteredTransactions() {
    const numberOfLoadedTransactions =
      await this.props.transactionStore!.loadTransactionsForAccount(
        this.context!.currentAccountNumber,
        { limit: AccountPage.paginationSize, skip: this.loadedTransactions }
      )
    this.setState({ isInitialLoad: false })
    this.loadedTransactions += numberOfLoadedTransactions
    if (numberOfLoadedTransactions < AccountPage.paginationSize) {
      this.hasLoadedAllTransactions = true
      this.setState({ canLoadMoreTransactions: false })
    }
  }

  async fetchFilteredTransactions() {
    const numberOfLoadedTransactions =
      await this.props.transactionStore!.loadFilteredTransactionsForAccount(
        this.context!.currentAccountNumber,
        this.state.appliedFilters,
        {
          limit: AccountPage.paginationSize,
          skip: this.loadedTransactionsForFilter,
        }
      )
    this.loadedTransactionsForFilter += numberOfLoadedTransactions
    if (numberOfLoadedTransactions < AccountPage.paginationSize) {
      this.hasLoadedAllTransactionsForFilter = true
      this.setState({ canLoadMoreTransactionsForFilter: false })
    }
  }

  async getAccounts() {
    if (!this.props.authenticationStore!.organisationIdentifier)
      return this.props.authenticationStore!.logout()
    try {
      await this.props.accountStore!.loadAccounts()
    } catch (error: any) {
      this.setState({ loadingError: error })
    }
  }

  canLoadMoreTransactions = () =>
    this.isFilterActive()
      ? this.state.canLoadMoreTransactionsForFilter
      : this.state.canLoadMoreTransactions

  setIsFiltering = (isFilteringTransactions: boolean) =>
    this.setState({ isFiltering: isFilteringTransactions })

  updateAppliedFilters = (filters: TransactionFilter) =>
    this.setState((prevState) => ({
      appliedFilters: { ...prevState.appliedFilters, ...filters },
    }))

  clearFilters = (filtersToClear?: (keyof TransactionFilter)[]) => {
    if (!filtersToClear) {
      this.setState({
        appliedFilters: null,
      })
    } else {
      const updatedFilters = { ...this.state.appliedFilters }
      let shouldPerformStateUpdate = false
      filtersToClear.forEach((filter) => {
        if (updatedFilters[filter]) {
          delete updatedFilters[filter]
          shouldPerformStateUpdate = true
        }
      })
      if (shouldPerformStateUpdate) {
        this.setState({ appliedFilters: updatedFilters })
      }
    }
  }

  onAbort = () => {
    if (this.props.ledgerStore?.abortIntent === 'previous view') {
      this.props.ledgerStore.clearUnsavedChanges(this.state.viewId)
      this.context!.popCurrentView()
    } else {
      this.props.ledgerStore?.clearAllExceptRoot()
      this.context!.goToRootView()
    }
  }

  render() {
    const mightNeedToShowOnboarding = this.couldDisplayOnboarding()
    const waitForOnboarding =
      mightNeedToShowOnboarding && this.state.isInitialLoad

    if (
      !this.props.accountStore?.allAccounts.length ||
      !this.props.accountStore.currentFiscalYear ||
      waitForOnboarding
    )
      return <LoadingScreen />

    if (this.shouldDisplayOnboarding())
      return (
        <OnboardingSheet
          openLedger={() => this.setState({ hasDismissedOnboarding: true })}
        />
      )

    return (
      <AccountPageProvider
        currentAccount={this.currentAccount}
        loadTransactions={() => this.loadTransactions()}
        resetTransactions={() => this.resetTransactions()}
        loadingError={this.state.loadingError}
        canLoadMoreTransactions={this.canLoadMoreTransactions()}
        viewId={this.state.viewId}
      >
        <TransactionFilterProvider
          appliedFilters={this.state.appliedFilters}
          isFiltering={this.state.isFiltering}
          setIsFiltering={this.setIsFiltering}
          updateAppliedFilters={this.updateAppliedFilters}
          clearFilters={this.clearFilters}
          resetPaginationForFilteredTransactions={
            this.resetPaginationForFilteredTransactions
          }
        >
          <AccountSheet />
        </TransactionFilterProvider>
        <AbortEditDialog
          isAbortingEdit={this.props.ledgerStore!.abortIntent !== undefined}
          onAbort={this.onAbort}
          onCancel={() => this.props.ledgerStore?.setAbortIntent(undefined)}
        />
      </AccountPageProvider>
    )
  }
}

export default AccountPage
