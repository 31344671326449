import { Flex, LoadingIndicator } from '@nextbusiness/infinity-ui'
import React from 'react'

interface LoadingScreenProps {
  className?: string
  loadingText?: string
}

const LoadingScreen = (props: LoadingScreenProps) => (
  <Flex
    horizontalAlignment='center'
    verticalAlignment='center'
    fillContainer
    className={props.className}
  >
    <LoadingIndicator loadingText={props.loadingText} />
  </Flex>
)

export default LoadingScreen
