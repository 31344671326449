interface CurrencyListItemDepictionProps {
  currencyCode: string
  currencyName: string
}

const CurrencyListItemDepiction = (props: CurrencyListItemDepictionProps) => {
  return (
    <div className='currency-list-item-depiction'>
      <div>{props.currencyCode}</div>
      <div>{props.currencyName}</div>
    </div>
  )
}

export default CurrencyListItemDepiction
