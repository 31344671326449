import { Heading } from '@nextbusiness/infinity-ui'
import { ReactComponent as LiveMagicIcon } from 'assets/live-accounting/live-magic.svg'
import { motion } from 'framer-motion'
import { observer } from 'mobx-react'
import './UnreviewedBankingTransactions.scss'

const SyncingBankingTransactionsBanner = () => (
  <motion.div
    className='unreviewed-banking-transactions syncing'
    layoutId='unreviewed-transactions'
    layout
    initial={{ opacity: 0, translateY: -30 }}
    animate={{ opacity: 1, translateY: 0 }}
    transition={{ velocity: 10, stiffness: 100, mass: 0.2, type: 'spring' }}
  >
    <Heading className='section-title' type='h3' bareTop>
      <LiveMagicIcon />
      <span>Bankbewegungen werden synchronisiert...</span>
    </Heading>
  </motion.div>
)

export default observer(SyncingBankingTransactionsBanner)
