import { DatePicker } from '@nextbusiness/infinity-ui'

interface FiscalYearOnboardingFieldProps {
  title: React.ReactNode
  value: Date | undefined
  onChange: (date: Date | undefined) => void
  updateOtherField?: (date: Date) => void
  shouldValidate: boolean
}

const FiscalYearOnboardingField = (props: FiscalYearOnboardingFieldProps) => (
  <div className='fiscal-year-field'>
    <span>{props.title}</span>
    <DatePicker
      value={props.value}
      onChange={(date) => {
        props.onChange(date)
        if (date) props.updateOtherField?.(date)
      }}
      inputFieldProps={{
        fullWidth: true,
        hasError: props.shouldValidate && !props.value,
      }}
    />
  </div>
)

export default FiscalYearOnboardingField
