import {
  IFixedAmountDiscountPosition,
  IPercentageDiscountPosition,
} from '@nextbusiness/infinity-finance-api'
import { INewDiscountPosition } from '../../../../../../model/CustomerInvoice'
import DocumentFixedAmountDiscountPosition from './DocumentFixedAmountDiscountPosition'
import DocumentPercentageDiscountPosition from './DocumentPercentageDiscountPosition'
import DocumentPositionProps from './DocumentPositionProps'

interface DocumentDiscountPositionProps extends DocumentPositionProps {
  position: INewDiscountPosition
}

const DocumentDiscountPosition = (props: DocumentDiscountPositionProps) => {
  const onChange = (
    changes: Partial<IFixedAmountDiscountPosition | IPercentageDiscountPosition>
  ) => props.onChange(changes)

  return 'amount' in props.position ? (
    <DocumentFixedAmountDiscountPosition
      position={props.position}
      onChange={onChange}
      isReadOnly={props.isReadOnly}
    />
  ) : (
    <DocumentPercentageDiscountPosition
      position={props.position}
      onChange={onChange}
      isReadOnly={props.isReadOnly}
    />
  )
}

export default DocumentDiscountPosition
