import { Checkbox } from '@nextbusiness/infinity-ui'
import CustomerInvoiceFactory from '../../CustomerInvoiceFactory'
import SidebarGroup from '../SidebarGroup'
import DocumentSidebarPanelProps from './DocumentSidebarPanelProps'

const DocumentSidebarCustomisePartsPanel = (
  props: DocumentSidebarPanelProps
) => (
  <SidebarGroup title='Anpassen'>
    <Checkbox
      label='Einleitungstext'
      isChecked={props.document.introductoryText !== null}
      onChange={(isChecked) =>
        props.registerEdit({
          introductoryText: isChecked
            ? CustomerInvoiceFactory.defaultIntroductoryText(
                props.template,
                props.document.isQuote ?? false
              )
            : null,
        })
      }
    />
    <Checkbox
      label='Schlusstext'
      isChecked={props.document.closingText !== null}
      onChange={(isChecked) =>
        props.registerEdit({
          closingText: isChecked
            ? CustomerInvoiceFactory.defaultClosingText(
                props.template,
                props.document.isQuote ?? false
              )
            : null,
        })
      }
    />
    {!props.document.isQuote && (
      <Checkbox
        label='QR-Zahlteil'
        isChecked={props.document.isQrInvoice}
        onChange={(isQrInvoice) => props.registerEdit({ isQrInvoice })}
      />
    )}
  </SidebarGroup>
)

export default DocumentSidebarCustomisePartsPanel
