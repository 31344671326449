import { Button, Card, Flex, Text } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import React from 'react'
import './ActionBar.scss'

interface ActionBarProps {
  children?: React.ReactNode
  className?: string
  leftHandText?: string
  onDone?: () => void
}

const ActionBar = (props: ActionBarProps) => (
  <Flex
    style={{ flexGrow: 1 }}
    horizontalAlignment='center'
    direction='vertical'
    className={classNames('action-bar', props.className)}
  >
    <Card className='action-bar-card'>
      <Flex
        className='action-bar-inner'
        gap='tiny'
        verticalAlignment='baseline'
      >
        {props.leftHandText && (
          <Text className='action-bar-title'>{props.leftHandText}</Text>
        )}
        <Flex
          className='action-bar-content'
          gap='tiny'
          verticalAlignment='center'
          wrap
        >
          {props.children}
          <Button
            className='done-button'
            variant='primary'
            onClick={props.onDone}
          >
            Fertig
          </Button>
        </Flex>
      </Flex>
    </Card>
  </Flex>
)

export default ActionBar
