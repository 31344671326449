import { useNotificationCenter } from '@nextbusiness/infinity-ui'
import { useEffect } from 'react'

interface CustomerInvoiceSavingErrorProps {
  savingError?: Error
  isQuote?: boolean
}

const CustomerInvoiceSavingError = (props: CustomerInvoiceSavingErrorProps) => {
  const notificationCenter = useNotificationCenter()

  useEffect(() => {
    if (props.savingError) {
      const errorTitle = props.isQuote
        ? 'Offerte konnte nicht gespeichert werden'
        : 'Forderung konnte nicht gespeichert werden'
      const errorDescription =
        props.savingError.message ?? 'Ein unbekannter Fehler ist aufgetreten.'

      notificationCenter.addNotification({
        title: errorTitle,
        children: errorDescription,
        variant: 'error',
      })
    }
  }, [props.savingError])

  return null
}

export default CustomerInvoiceSavingError
