import { Flex } from '@nextbusiness/infinity-ui'
import {
  ContactPageStackContext,
  ContactRelatedItemsTab,
} from 'contacts/ContactPageStackContext'
import { useContext } from 'react'
import './ContactRelatedItems.scss'
import ContactRelatedItemsAction from './ContactRelatedItemsAction'

interface ContactRelatedItemsProps {
  contactId: string
}

const ContactRelatedItems = (props: ContactRelatedItemsProps) => {
  const contactStack = useContext(ContactPageStackContext)
  if (!contactStack) return null

  const openContact = (tab?: ContactRelatedItemsTab) =>
    contactStack.openContact(props.contactId, tab)

  return (
    <Flex className='key-value-pair contact-related-items-row'>
      <span className='contact-related-items-label key'>Ansehen</span>
      <div className='contact-related-items'>
        <ContactRelatedItemsAction
          icon='purchase-order'
          label='Forderungen'
          onClick={() => openContact('customer-invoices')}
        />
        <ContactRelatedItemsAction
          icon='brochure'
          label='Offerten'
          onClick={() => openContact('quotes')}
        />
        <ContactRelatedItemsAction
          icon='outline'
          label='Projekte'
          onClick={() => openContact('projects')}
        />
        <ContactRelatedItemsAction
          icon='bill'
          label='Rechnungen'
          onClick={() => openContact('vendor-invoices')}
        />
      </div>
    </Flex>
  )
}

export default ContactRelatedItems
