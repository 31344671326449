import { Dialog, Text } from '@nextbusiness/infinity-ui'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'

const SignInAgainModal = () => {
  const { authenticationStore } = useRootStore()
  const dismiss = () => {
    runInAction(() => {
      authenticationStore.isSessionExpired = false
    })
  }
  return (
    <Dialog
      isOpen={
        authenticationStore.isHydrated &&
        authenticationStore.isAuthenticated &&
        authenticationStore.isSessionExpired
      }
      onDismiss={dismiss}
      title='Du wurdest abgemeldet.'
      actions={[
        {
          children: 'Erneut anmelden',
          variant: 'primary',
          onClick: () => authenticationStore.logout(),
        },
      ]}
      style={{ maxWidth: '46rem' }}
      appearance='system'
    >
      <Text type='paragraph'>
        Nach längerer Inaktivität wurdest du aus Sicherheitsgründen abgemeldet.
        Melde dich erneut an, um weiterzuarbeiten.
      </Text>
    </Dialog>
  )
}

export default observer(SignInAgainModal)
