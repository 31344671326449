import { Finance, ITransaction } from '@nextbusiness/infinity-finance-api'
import {
  Flex,
  NonIdealState,
  RichSelect,
  Text,
} from '@nextbusiness/infinity-ui'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import VirtualTransactionView from 'components/transactions/VirtualTransactionView'
import { observer } from 'mobx-react'
import { VATReport } from 'model/VAT'
import DocumentsPage from 'pages/DocumentsPage'
import { useCallback, useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import AccountUtilities from 'utility/AccountUtilities'
import VATReportTransactionsErrorPage from './VATReportTransactionsErrorPage'
import './VATReportTransactionsPage.scss'

const DIGIT_DROPDOWN_OPTIONS: {
  label: string
  value: keyof VATReport
}[] = [
  { value: '220', label: '220 – Befreite Leistungen, u.a. Exporte' },
  { value: '221', label: '221 – Leistungen im Ausland' },
  {
    value: '230',
    label: '230 – Nicht-optierte ausgenommene Inlandleistungen',
  },
  { value: '235', label: '235 – Entgeltsminderungen' },
  { value: '302', label: '302 – Leistungen zum Normalsatz (7.7%)' },
  { value: '303', label: '303 – Leistungen zum Normalsatz (8.1%)' },
  { value: '312', label: '312 – Leistungen zum reduzierten Satz (2.5%)' },
  { value: '313', label: '313 – Leistungen zum reduzierten Satz (2.6%)' },
  { value: '343', label: '343 – Leistungen zum Beherbergungssatz (3.8%)' },
  { value: '342', label: '342 – Leistungen zum Beherbergungssatz (3.7%)' },
  { value: '382', label: '382 – Bezugsteuer (bis 31.12.2023)' },
  { value: '383', label: '382 – Bezugsteuer (ab 01.01.2024)' },
  { value: '400', label: '400 – Vorsteuer Material/DL' },
  { value: '405', label: '405 – Vorsteuer Investitionen/übriger BA' },
  { value: '410', label: '410 – Einlageentsteuerung' },
  { value: '415', label: '415 – Vorsteuerkorrekturen' },
  { value: '420', label: '420 – Vorsteuerkürzungen' },
]

interface VATReportTransactionsPageProps {
  page: DocumentsPage
}

const VATReportTransactionsPage = (props: VATReportTransactionsPageProps) => {
  const { authenticationStore, vatPeriodStore, accountStore } = useRootStore()
  const periodId = props.page.currentParam

  const [selectedDigit, setSelectedDigit] = useState<keyof VATReport>('302')
  const [transactions, setTransactions] = useState<ITransaction[]>()
  const [error, setError] = useState<Error>()
  const [isLoading, setIsLoading] = useState(false)

  const loadTransactionsForSelectedDigit = useCallback(async () => {
    if (!periodId) return
    setIsLoading(true)
    setError(undefined)
    try {
      if (
        !vatPeriodStore.periodBeingViewed ||
        vatPeriodStore.periodBeingViewed._id !== periodId
      ) {
        await vatPeriodStore.loadPeriodToView(periodId)
      }
      if (!vatPeriodStore.periodBeingViewed) return
      const transactions = await Promise.all(
        vatPeriodStore.periodBeingViewed.report[
          selectedDigit
        ].transactionIds.map(async (transactionId) => {
          const transaction = await Finance.Ledger.transactions({
            id: transactionId,
          })
          return transaction[0]
        })
      )
      setTransactions(transactions)
    } catch (loadingError) {
      setError(loadingError as Error)
    } finally {
      setIsLoading(false)
    }
  }, [vatPeriodStore.periodBeingViewed, selectedDigit])

  useEffect(() => {
    loadTransactionsForSelectedDigit()
  }, [loadTransactionsForSelectedDigit, selectedDigit])

  useEffect(() => {
    accountStore.loadAccounts()
  }, [authenticationStore.organisationIdentifier])

  if (isLoading)
    return (
      <MagicSheetPage>
        <LoadingScreen loadingText='Transaktionen werden geladen.' />
      </MagicSheetPage>
    )

  if (error || !transactions)
    return (
      <VATReportTransactionsErrorPage
        onRetry={() => loadTransactionsForSelectedDigit()}
        onClose={() => props.page.popCurrentView()}
        error={error}
      />
    )

  return (
    <MagicSheetPage
      className='vat-report-transactions-page'
      header={<MagicSheetTitleBar title='Transaktionen prüfen' />}
      noTrailingNegativeSpace
    >
      <MagicSheetContainer>
        <Flex
          className='transactions-list-header'
          verticalAlignment='baseline'
          gap={1}
        >
          <Text type='inline'>Transaktionen anzeigen für Ziffer</Text>
          <RichSelect
            className='ui-select ui-rich-select vat-form-digit-select'
            options={DIGIT_DROPDOWN_OPTIONS}
            value={selectedDigit}
            onChange={(value) => setSelectedDigit(value as keyof VATReport)}
          />
        </Flex>
        <Flex className='transactions-list-body' gap={0.1} direction='vertical'>
          {!transactions.length && (
            <NonIdealState className='empty'>
              Keine Transaktionen für diese Steuerart
            </NonIdealState>
          )}
          {transactions.map((transaction) => {
            const debitAccount = accountStore.find(transaction.debitAccount)
            const swapArrowDirection = debitAccount
              ? AccountUtilities.decreasingSideOfAccount(debitAccount) ===
                'debit'
              : false
            return (
              <VirtualTransactionView
                key={transaction?.id}
                swapArrowDirection={swapArrowDirection}
                {...transaction}
              />
            )
          })}
        </Flex>
      </MagicSheetContainer>
    </MagicSheetPage>
  )
}

export default observer(VATReportTransactionsPage)
