import classNames from 'classnames'
import Tabs from 'proto-ui-components/Tabs'
import { useState } from 'react'
import VendorInvoiceDetailHeader from './VendorInvoiceDetailHeader'
import './VendorInvoiceDetailMainPane.scss'
import VendorInvoiceDetailTabPayments from './VendorInvoiceDetailTabPayments'
import VendorInvoiceDetailTabPositions from './VendorInvoiceDetailTabPositions'
import { useVendorInvoiceEditor } from './VendorInvoiceEditorContext'

type VendorInvoiceDetailTab = 'positions' | 'payments'

const VendorInvoiceDetailMainPane = () => {
  const { isInvoiceDraftTouched } = useVendorInvoiceEditor()

  const [currentTab, setCurrentTab] =
    useState<VendorInvoiceDetailTab>('positions')
  const [hasScrolled, setHasScrolled] = useState<boolean>(false)

  const onContainerScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const scrollTop = (e.nativeEvent.target as HTMLDivElement).scrollTop
    setHasScrolled(scrollTop !== 0)
  }

  return (
    <div
      className={classNames(
        'vendor-invoice-detail-main',
        hasScrolled && 'has-scrolled',
        isInvoiceDraftTouched && 'edit-mode'
      )}
    >
      <div className='vendor-invoice-detail-header'>
        <VendorInvoiceDetailHeader />
        <Tabs<VendorInvoiceDetailTab>
          tabs={[
            { identifier: 'positions', title: 'Positionen' },
            { identifier: 'payments', title: 'Zahlungen' },
          ]}
          currentTabId={currentTab}
          onTabSelected={(tab) => setCurrentTab(tab.identifier)}
        />
      </div>
      <div
        className='vendor-invoice-detail-container'
        onScroll={onContainerScroll}
      >
        {currentTab === 'positions' ? (
          <VendorInvoiceDetailTabPositions />
        ) : (
          <VendorInvoiceDetailTabPayments />
        )}
      </div>
    </div>
  )
}

export default VendorInvoiceDetailMainPane
