import { ICurrency } from '@nextbusiness/infinity-finance-api'
import CurrencyOption from './CurrencyOption'

interface CurrencyOptionsProps {
  currencies: ICurrency[]
  selectedCurrencyId: string
  highlightedCurrencyId?: string
  setCurrency: (currency: ICurrency) => void
}

const CurrencyOptions = (props: CurrencyOptionsProps) => (
  <div className='currency-options'>
    {props.currencies.map((currency) => (
      <CurrencyOption
        key={currency.id}
        isSelected={currency.id === props.selectedCurrencyId}
        isHighlighted={currency.id === props.highlightedCurrencyId}
        currencyName={currency.currencyName}
        currencyCode={currency.currencyCode}
        onClick={() => props.setCurrency(currency)}
      />
    ))}
  </div>
)

export default CurrencyOptions
