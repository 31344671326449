import { Button, Flyout, MenuItems, Text } from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'
import { ReactComponent as LiveMenuBadge } from 'assets/live-accounting/live-active.svg'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { useState } from 'react'
import { useAccountPageContext } from '../AccountPageContext'
import { isDefinedAccount } from '../data/AccountRules'
import './AccountLiveMenu.scss'
import AccountLiveMenuDisconnectModal from './AccountLiveMenuDisconnectModal'

const AccountLiveMenu = () => {
  const { currentAccount } = useAccountPageContext()
  if (!isDefinedAccount(currentAccount))
    throw new Error(
      'Attempted to render AccountLiveMenu with an unsupported account type (special account).'
    )

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [isDisconnectModalOpen, setIsDisconnectModalOpen] =
    useState<boolean>(false)

  const onTriggerClick = () => {
    if (!isMenuOpen) MixpanelAnalytics.event('Account live menu opened')
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <Flyout
      className='account-live-menu'
      isActive={isMenuOpen}
      setIsActive={setIsMenuOpen}
      triggerIsButton
      trigger={
        <Button
          onClick={onTriggerClick}
          className='account-live-menu-button'
          variant='quaternary'
          tooltip='Live Accounting-Optionen'
        >
          <LiveMenuBadge className='account-live-menu-badge' />
          <Icon icon='expand' size={12} />
        </Button>
      }
    >
      <div className='live-accounting-status'>
        <div className='live-accounting-provider'>
          <div className='provider-logo'>
            <img src='/assets/bank-providers/logo-blink.svg' alt='' />
          </div>
          <div className='provider-depiction'>
            <small>Live Accounting</small>
            <span className='bank-provider'>Verbunden</span>
          </div>
        </div>
        <Text variant='subtle' className='live-accounting-status-text'>
          Verbunden mit «{currentAccount.designation}». Letzter Abgleich heute.
        </Text>
      </div>
      <MenuItems
        groups={[
          {
            buttons: [
              {
                text: 'Bankverbindung trennen',
                icon: 'disconnected',
                onClick: () => setIsDisconnectModalOpen(true),
              },
            ],
          },
        ]}
        closeMenu={() => setIsMenuOpen(false)}
      />
      <AccountLiveMenuDisconnectModal
        isOpen={isDisconnectModalOpen}
        onDismiss={() => setIsDisconnectModalOpen(false)}
        currentAccount={currentAccount}
      />
    </Flyout>
  )
}

export default AccountLiveMenu
