import { ITransaction } from '@nextbusiness/infinity-finance-api'
import { TypedKeyValueStore } from '@nextbusiness/infinity-ui'
import {
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  LinearScale,
} from 'chart.js'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react-lite'
import { IAccount } from 'model/Account'
import React from 'react'
import { Bar } from 'react-chartjs-2'
import { useRootStore } from 'stores/RootStoreContext'
import TransactionUtilities from 'utility/TransactionUtilities'
import Utilities from 'utility/Utilities'

interface AccountDetailBarChartProps {
  transactions: ITransaction[]
  account: IAccount
  fiscalYear: number
}

ChartJS.register(CategoryScale, LinearScale, BarElement)

const humanReadableMonthFromDate = (date: DateTime): string =>
  date.toLocaleString({
    month: 'long',
    year: 'numeric',
    locale: 'de-CH',
  })

const AccountDetailBarChart = (props: AccountDetailBarChartProps) => {
  const { accountStore } = useRootStore()

  const transactionsByMonth = React.useMemo(() => {
    const accumulator: TypedKeyValueStore<ITransaction[]> = {}
    const fiscalYear = accountStore.fiscalYear(props.fiscalYear)
    const startDate = DateTime.fromMillis(fiscalYear!.from)

    for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
      const date = humanReadableMonthFromDate(
        startDate.plus({ months: monthIndex })
      )
      accumulator[date] = []
    }

    props.transactions.forEach((transaction) => {
      const dateTime = DateTime.fromMillis(transaction.date)
      const date = humanReadableMonthFromDate(dateTime)

      if (accumulator[date]) accumulator[date].push(transaction)
    })

    return Object.keys(accumulator).map((month) => {
      return {
        month,
        transactions: accumulator[month],
      }
    })
  }, [props.transactions, props.fiscalYear])

  const labels = transactionsByMonth.map((group) => group.month.substring(0, 1))
  const monthlySums = transactionsByMonth.map(
    (group) =>
      TransactionUtilities.sumBalanceOfTransactions(
        group.transactions,
        props.account
      ) / 100
  )

  const data: ChartData<'bar', number[], string> = {
    labels,
    datasets: [
      {
        data: monthlySums,
        backgroundColor: props.account.colour ?? '#DDDDDD',
        borderRadius: 3,
        barThickness: 12,
      },
    ],
  }

  return (
    <div className='account-detail-report-bar-chart'>
      <Bar
        data={data}
        options={{
          responsive: true,
          scales: {
            x: {
              grid: {
                drawOnChartArea: false,
                drawTicks: false,
              },
              border: {
                display: false,
              },
              ticks: {
                padding: 10,
                color: '#222',
                font: {
                  family: 'Neue Plak Text, sans-serif',
                },
              },
            },
            y: {
              grid: {
                drawOnChartArea: true,
                drawTicks: false,
              },
              border: {
                display: false,
                dash: [2, 3],
              },
              ticks: {
                padding: 10,
                color: '#222',
                format: {
                  style: 'currency',
                  currency: 'CHF',
                },
                font: {
                  family: 'Neue Plak Text, sans-serif',
                },
              },
            },
          },
        }}
        height={260}
      />
      <div className='total'>
        <span className='value'>
          {Utilities.centsToCHF(props.account.balance)}
        </span>
        <span className='label'>Total dieses Geschäftsjahr</span>
      </div>
    </div>
  )
}

export default observer(AccountDetailBarChart)
