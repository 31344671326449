import { Flex, SpacerSize, Text, Tooltip } from '@nextbusiness/infinity-ui'
import { Icon, IconItem, IconSize } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import React from 'react'
import './Lockup.scss'

interface LockupProps {
  icon: IconItem | null
  tooltip?: React.ReactNode
  children: React.ReactNode
  subtle?: boolean
  small?: boolean
  gap?: SpacerSize | number
  cushion?: SpacerSize | number
  className?: string
  truncate?: boolean
}

const Lockup = (props: LockupProps) => {
  const lockupInner = (
    <Flex
      className={classNames(
        'lockup',
        props.className,
        props.cushion && `cushioned-${props.cushion}`,
        props.truncate && 'truncate'
      )}
      verticalAlignment='center'
      gap={props.gap ?? 'tiny'}
    >
      {props.icon && (
        <Icon
          icon={props.icon}
          size={props.small ? 20 : IconSize.Small}
          tint={props.subtle ? '#767676' : undefined}
        />
      )}
      <Text type='inline' variant={props.subtle ? 'subtle' : 'default'}>
        {props.children}
      </Text>
    </Flex>
  )
  if (props.tooltip) {
    return <Tooltip content={props.tooltip}>{lockupInner}</Tooltip>
  } else {
    return lockupInner
  }
}

export default Lockup
