import PaymentStatusRow from 'invoices/shared-components/PaymentStatusRow'
import { useVendorInvoiceEditor } from './VendorInvoiceEditorContext'

const VendorInvoiceDetailPaymentRow = () => {
  const { invoiceDraft, isInvoiceDraftTouched, invoiceDraftTotal } =
    useVendorInvoiceEditor()

  return (
    <PaymentStatusRow
      small
      unpaidAmountInCents={
        isInvoiceDraftTouched
          ? invoiceDraftTotal - (invoiceDraft.totalAmountPaid ?? 0)
          : invoiceDraft.totalRestAmount ?? 0
      }
      paidAmountInCents={invoiceDraft.totalAmountPaid ?? 0}
      totalAmountInCents={
        isInvoiceDraftTouched
          ? invoiceDraftTotal
          : invoiceDraft.totalAmount ?? 0
      }
    />
  )
}

export default VendorInvoiceDetailPaymentRow
