import { Button } from '@nextbusiness/infinity-ui'
import { Icon, IconItem, IconSize } from '@nextbusiness/infinity-ui-icons'
import React from 'react'

interface ContactRelatedItemsActionProps {
  icon: IconItem
  label: React.ReactNode
  onClick?: () => void
}

const ContactRelatedItemsAction = (props: ContactRelatedItemsActionProps) => (
  <Button
    className='contact-related-items-action'
    onClick={props.onClick}
    variant='quaternary'
  >
    <Icon icon={props.icon} size={IconSize.Small} />
    <span>{props.label}</span>
  </Button>
)

export default ContactRelatedItemsAction
