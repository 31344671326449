import { useEffect, useState } from 'react'
import { BackendError, ErrorType } from './Errors'

export type BackendQuery<T> = [
  T | undefined,
  boolean,
  BackendError | undefined,
  (invalidateWithNewState?: T) => void
]

/**
 * Convenience hook that simplifies a backend query.
 * May only be used for queries that don't affect any store.
 *
 * @param networkFunction The promise that will be executed on load
 * @returns A tuple consisting of the requested object or undefined, a boolean indicating whether
 * the request is still loading, an optional error and a function to invalidate/refresh the data.
 */
const useBackendQuery = <T>(
  networkFunction: () => Promise<T>,
  completionHandler?: (requestedObject: T) => void,
  dependencies?: any[]
): BackendQuery<T> => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [data, setData] = useState<T>()
  const [error, setError] = useState<BackendError>()

  const resolveWithData = (data: T) => {
    setError(undefined)
    setData(data)
    if (completionHandler) completionHandler(data)
  }

  const loadData = async () => {
    setIsLoading(true)
    try {
      const response = await networkFunction()
      resolveWithData(response)
    } catch (error: any) {
      setError(
        error instanceof BackendError
          ? error
          : new BackendError(
              error.type ?? ErrorType.ServerError,
              error.message,
              error.additionalData
            )
      )
    } finally {
      setIsLoading(false)
    }
  }
  const invalidateData = (invalidateWithNewState?: T) => {
    if (isLoading) return
    if (invalidateWithNewState) {
      resolveWithData(invalidateWithNewState)
      return
    }
    loadData()
  }

  useEffect(() => {
    loadData()
  }, dependencies ?? [])

  return [data, isLoading, error, invalidateData]
}

export default useBackendQuery
