import { IVendorInvoice } from 'model/VendorInvoice'
import ContactListRenderVendorInvoices from './ContactListRenderVendorInvoices'
import ContactStack from './ContactStack'

interface ContactVendorInvoicesListProps {
  data: IVendorInvoice[]
  stack: ContactStack
}

const ContactVendorInvoicesList = (props: ContactVendorInvoicesListProps) => {
  return (
    <ContactListRenderVendorInvoices stack={props.stack} data={props.data} />
  )
}

export default ContactVendorInvoicesList
