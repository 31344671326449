import { RouterHistory } from '@nextbusiness/infinity-ui/dist/layout/header/HeaderNavigationTabs'
import Fuse, { IFuseOptions } from 'fuse.js'
import EverythingSearchProviderResult from './EverythingSearchProviderResult'
import EverythingSearchResultsProvider from './EverythingSearchResultsProvider'

export type EverythingSearchResult = EverythingSearchProviderResult & {
  provider: string
}

class EverythingSearch {
  static providers: EverythingSearchResultsProvider[] = []

  static async resultsForQuery(
    query: string
  ): Promise<EverythingSearchResult[]> {
    const results: EverythingSearchResult[] = []

    if (!query || query.trim() === '' || query.length < 2) return []

    const providerPromises = this.providers.map((provider) =>
      provider.resultsForQuery(query)
    )
    const providerResults = await Promise.all(providerPromises)

    for (let i = 0; i < providerResults.length; i++) {
      const provider = this.providers[i]
      const resultsForProvider = providerResults[i]

      for (const result of resultsForProvider) {
        results.push({ ...result, provider: provider.providerName })
      }
    }
    return this.rankResults(results, query)
  }

  private static rankResults(
    results: EverythingSearchResult[],
    query: string
  ): EverythingSearchResult[] {
    const options: IFuseOptions<EverythingSearchResult> = {
      threshold: 1,
      keys: ['title'],
    }
    const fuse = new Fuse(results, options)
    const resultsForQuery = fuse.search(query)

    return resultsForQuery.map((result) => result.item)
  }

  static onResultSelected(
    result: EverythingSearchResult,
    history: RouterHistory
  ) {
    this.providers
      .find((provider) => provider.providerName === result.provider)
      ?.onResultSelected(result, history)
  }

  static registerProvider(provider: EverythingSearchResultsProvider) {
    if (
      EverythingSearch.providers.find(
        (p) => p.providerName === provider.providerName
      )
    )
      return console.warn(
        'Unable to register provider. A provider with the same name already exists.'
      )

    this.providers.push(provider)
  }
}

export default EverythingSearch
