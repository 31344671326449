import { Heading, NonIdealState } from '@nextbusiness/infinity-ui'
import InvoiceListItemLoading from 'invoices/shared-components/regular-invoice-list/InvoiceListItemLoading'
import { observer } from 'mobx-react'
import { IVendorInvoice } from '../../../model/VendorInvoice'
import { useRootStore } from '../../../stores/RootStoreContext'
import './AllVendorInvoices.scss'
import VendorInvoicesFilteredList from './filters/VendorInvoiceFilteredList'
import { VendorInvoicesFilterProvider } from './filters/VendorInvoicesFilterContext'
import VendorInvoicesFilterOptions from './filters/VendorInvoicesFilterOptions'

interface AllVendorInvoicesProps {
  isLoading: boolean
  openVendorInvoice: (invoice: IVendorInvoice) => void
}

const AllVendorInvoices = (props: AllVendorInvoicesProps) => {
  const { vendorInvoiceStore } = useRootStore()
  return (
    <div className='all-vendor-invoices'>
      <Heading type='h3' className='heading' bare>
        Alle Rechnungen
      </Heading>
      {props.isLoading ? (
        <InvoiceListItemLoading />
      ) : vendorInvoiceStore.allInvoices?.length === 0 ? (
        <NonIdealState>Noch keine Rechnungen verbucht</NonIdealState>
      ) : (
        <VendorInvoicesFilterProvider>
          <VendorInvoicesFilterOptions />
          <VendorInvoicesFilteredList
            invoices={vendorInvoiceStore.allInvoices}
            openVendorInvoice={props.openVendorInvoice}
          />
        </VendorInvoicesFilterProvider>
      )}
    </div>
  )
}

export default observer(AllVendorInvoices)
