import { ProjectStatus } from '@nextbusiness/infinity-finance-api'
import StatusLabel from 'components/status-label/StatusLabel'

interface ProjectStatusIndicatorProps {
  status: ProjectStatus
}

const swatchColourFor = (status: ProjectStatus) => {
  switch (status) {
    case ProjectStatus.Active:
      return '#0aa8bf'
    case ProjectStatus.Completed:
      return '#31c35d'
    case ProjectStatus.Abandoned:
      return '#7a8891'
  }
}
const textFor = (status: ProjectStatus) => {
  switch (status) {
    case ProjectStatus.Active:
      return 'Aktiv'
    case ProjectStatus.Completed:
      return 'Abgeschlossen'
    case ProjectStatus.Abandoned:
      return 'Abgebrochen'
  }
}

const ProjectStatusIndicator = (props: ProjectStatusIndicatorProps) => (
  <StatusLabel
    swatchColour={swatchColourFor(props.status)}
    text={textFor(props.status)}
  />
)

export default ProjectStatusIndicator
