import { makeObservable, observable, runInAction } from 'mobx'
import VATPeriod, { DetailedVATPeriod } from 'model/VAT'
import VAT from 'networking/VAT'
import RootStore from './RootStore'
import Store from './Store'

class VATPeriodStore extends Store {
  @observable periods: VATPeriod[] | null = null
  @observable periodBeingViewed: DetailedVATPeriod | null = null

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  public async loadPeriods(): Promise<void> {
    if (!this.rootStore.authenticationStore.organisationIdentifier)
      return this.rootStore.authenticationStore.logout()
    const periods = await VAT.getAllPeriods(
      this.rootStore.authenticationStore.organisationIdentifier
    )
    periods.sort((a, b) => b.start - a.start)
    runInAction(() => {
      this.periods = periods
    })
  }

  public async loadPeriodToView(periodId: string): Promise<void> {
    if (!this.rootStore.authenticationStore.organisationIdentifier)
      return this.rootStore.authenticationStore.logout()
    const period = await VAT.getDetailedVATPeriod(
      periodId,
      this.rootStore.authenticationStore.organisationIdentifier
    )
    runInAction(() => {
      this.periodBeingViewed = period
    })
  }

  public periodForDate(date: number) {
    return this.periods?.find((period) => {
      return period.start <= date && date <= period.end
    })
  }

  public isDateWithinOpenVATPeriod(date: Date) {
    const period = this.periodForDate(date.getTime())
    return !period?.wasSettled
  }
}

export default VATPeriodStore
