import DocumentAttachments from '../DocumentAttachments'
import { useVendorInvoiceContext } from './VendorInvoiceContext'
import './VendorInvoiceDetailPreviewPane.scss'

const VendorInvoiceDetailPreviewPane = () => {
  const { invoice, reloadInvoice } = useVendorInvoiceContext()
  return (
    <div className='vendor-invoice-detail-preview'>
      <DocumentAttachments
        attachmentIds={invoice.attachments}
        invoiceId={invoice.id}
        onUpload={() => reloadInvoice()}
      />
    </div>
  )
}

export default VendorInvoiceDetailPreviewPane
