import { Dialog } from '@nextbusiness/infinity-ui'
import { useState } from 'react'

interface ConfirmDeletionModalProps {
  children: React.ReactNode
  title: string
  isOpen: boolean
  onDismiss: () => void
  onDelete: () => Promise<void>
  /**
   * Use this prop to override the default copy "Löschen" of the delete action button.
   */
  deleteActionCopy?: string
}

const ConfirmDeletionModal = (props: ConfirmDeletionModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onDelete = async () => {
    setIsLoading(true)
    await props.onDelete()
    setIsLoading(false)
    props.onDismiss()
  }

  return (
    <Dialog
      title={props.title}
      icon='warning'
      initialFocusIndex={0}
      isOpen={props.isOpen}
      actions={[
        {
          children: 'Abbrechen',
          variant: 'tertiary',
          onClick: props.onDismiss,
        },
        {
          children: props.deleteActionCopy ?? 'Löschen',
          variant: 'destructive',
          onClick: onDelete,
          isLoading,
        },
      ]}
    >
      {props.children}
    </Dialog>
  )
}

export default ConfirmDeletionModal
