import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import DocumentCard from 'documents/document-card/DocumentCard'
import { observer } from 'mobx-react'
import DocumentsPage, { DocumentsPagePaths } from 'pages/DocumentsPage'
import { useRootStore } from 'stores/RootStoreContext'
import './AllInboxDocuments.scss'

interface AllInboxDocumentsProps {
  page: DocumentsPage
}

const AllInboxDocuments = (props: AllInboxDocumentsProps) => {
  const { documentStore } = useRootStore()
  return (
    <MagicSheetPage header={<MagicSheetTitleBar title='Alle neuen Belege' />}>
      <MagicSheetContainer>
        <div className='all-inbox-documents'>
          <div className='all-inbox-documents-content'>
            {documentStore.inboxDocuments?.map((inboxDocument) => (
              <DocumentCard
                key={inboxDocument.id}
                document={inboxDocument}
                previewDocument={() =>
                  props.page.open(
                    DocumentsPagePaths.AllInboxDocumentDetailView,
                    inboxDocument.id
                  )
                }
                createExpenditure={() =>
                  props.page.open(
                    DocumentsPagePaths.CreateExpenditure,
                    inboxDocument.id
                  )
                }
              />
            ))}
          </div>
        </div>
      </MagicSheetContainer>
    </MagicSheetPage>
  )
}

export default observer(AllInboxDocuments)
