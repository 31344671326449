import {
  AmountMode,
  TaxCode,
  VATCalculationMode,
} from '@nextbusiness/infinity-finance-api'
import { Flex, InputField, MoneyField, Text } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import VATPicker from 'components/vat/vat-picker/VATPicker'
import AccountSelect from 'ledger/accounts/account-select/AccountSelect'
import { ResolvedSetOfSuggestedAccounts } from 'ledger/accounts/data/AccountRules'
import { observer } from 'mobx-react-lite'
import { IPosition } from 'model/VendorInvoice'
import { useRootStore } from 'stores/RootStoreContext'
import TaxUtilities from 'utility/TaxUtilities'
import Utilities from 'utility/Utilities'
import { useVendorInvoiceEditor } from '../detail/VendorInvoiceEditorContext'
import VendorInvoiceEditorUtilities from '../detail/VendorInvoiceEditorUtilitites'
import './VendorInvoicePosition.scss'

interface VendorInvoicePositionProps {
  position: Partial<IPosition>
  setPosition: (position: Partial<IPosition>) => void
  isReadOnly?: boolean
}

const suggestedAccounts: ResolvedSetOfSuggestedAccounts = [
  { title: 'Aufwände', accounts: ['expense'] },
]

const VendorInvoicePosition = (props: VendorInvoicePositionProps) => {
  const { shouldValidate, invoiceDraft } = useVendorInvoiceEditor()
  const { preferencesStore, accountStore } = useRootStore()
  const { quantity, text, singleAmount, contraAccount, taxCode } =
    props.position

  const getTotalAmount = () => {
    if (!singleAmount) return '–'

    const totalAmount =
      VendorInvoiceEditorUtilities.totalAmountOfPositionWithVAT(
        props.position,
        invoiceDraft.amountMode
      )
    return `${Utilities.centsToCHF(totalAmount)} CHF`
  }

  const isUsingSimplifiedVATMode =
    preferencesStore.organisationPreferences?.vatCalculationMode ===
    VATCalculationMode.Simplified

  const showVAT =
    preferencesStore.organisationPreferences?.VAT && !isUsingSimplifiedVATMode
  const showAsIncomplete =
    shouldValidate &&
    !VendorInvoiceEditorUtilities.isPositionComplete(props.position)

  return (
    <Flex
      className={classNames('vendor-invoice-position', {
        incomplete: showAsIncomplete,
      })}
      direction='horizontal'
    >
      <InputField
        className='quantity'
        value={quantity?.toString() ?? ''}
        onChange={(input) =>
          props.setPosition({ ...props.position, quantity: parseFloat(input) })
        }
        type='number'
        bare
        placeholder='Stk'
        disabled={props.isReadOnly}
      />
      <Flex className='left-side' direction='vertical' style={{ flexGrow: 1 }}>
        <InputField
          className='description'
          value={text ?? ''}
          onChange={(input) =>
            props.setPosition({ ...props.position, text: input })
          }
          bare
          placeholder='Beschreibung'
          disabled={props.isReadOnly}
        />
        <AccountSelect
          currentAccountNumber={2000}
          initialAccountNumber={contraAccount}
          onChange={(accountNumber) =>
            props.setPosition({
              ...props.position,
              contraAccount: accountNumber,
              taxCode:
                TaxUtilities.autoSuggestVAT(
                  {
                    number: contraAccount,
                    account: accountStore.find(contraAccount),
                  },
                  preferencesStore.organisationPreferences,
                  invoiceDraft.openingDate
                ) ?? props.position.taxCode,
            })
          }
          allowAccountCreation
          onlyAllowSuggestedAccounts
          suggestions={suggestedAccounts}
          inputFieldProps={{ bare: true, disabled: props.isReadOnly }}
        />
      </Flex>
      <Flex
        className='right-side'
        direction='vertical'
        verticalAlignment='flex-end'
      >
        <MoneyField
          value={singleAmount ? singleAmount / 100 : null}
          onChange={(value) =>
            props.setPosition({
              ...props.position,
              singleAmount: value ? Math.round(value * 100) : undefined,
            })
          }
          bare
          placeholder={
            invoiceDraft.amountMode === AmountMode.Net
              ? 'Nettoeinzelpreis'
              : 'Einzelpreis'
          }
          className={classNames(
            'single-amount-field',
            props.position.singleAmount && 'has-value'
          )}
          disabled={props.isReadOnly}
          fullWidth
        />
        {showVAT && (
          <VATPicker
            type='expense'
            taxCode={(taxCode ?? '') as TaxCode}
            onTaxCodeSelected={(taxCode) =>
              props.setPosition({ ...props.position, taxCode })
            }
            isDisabled={props.isReadOnly}
          />
        )}
      </Flex>
      <Text className='total-amount' type='inline'>
        {getTotalAmount()}
        <div className='total-label'>
          {invoiceDraft.amountMode === AmountMode.Net
            ? 'Total inkl. MWST'
            : 'Total'}
        </div>
      </Text>
    </Flex>
  )
}

export default observer(VendorInvoicePosition)
