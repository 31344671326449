import classNames from 'classnames'
import { useBatchEditingContext } from 'ledger/transactions/batch-editing/BatchEditingContext'
import TransactionCreator from 'ledger/transactions/creator/TransactionCreator'
import FilterActionBar from 'ledger/transactions/filter/FilterActionBar'
import { TransactionFilterContext } from 'ledger/transactions/filter/TransactionFilterContext'
import { observer } from 'mobx-react'
import { IAccount } from 'model/Account'
import { useContext, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { useAccountPageContext } from '../AccountPageContext'
import { useAccountSheetContext } from '../AccountSheetContext'
import { ResolvedAccountAction, isDefinedAccount } from '../data/AccountRules'
import AccountDepiction from './AccountDepiction'
import AccountTitleBar from './AccountTitleBar'
import BatchEditingActionBar from './BatchEditingActionBar'

export type TransactionType = 'income' | 'expense' | 'transfer'

const AccountHeader = () => {
  const { preferencesStore } = useRootStore()

  const transactionFilterContext = useContext(TransactionFilterContext)
  const batchEditingContext = useBatchEditingContext()
  const accountSheetContext = useAccountSheetContext()
  const { currentAccount, rules } = useAccountPageContext()

  const [activeAction, setActiveAction] = useState<ResolvedAccountAction>()
  const [shouldCurrentAccountIncrease, setShouldCurrentAccountIncrease] =
    useState<boolean>(false)

  const depictionOrActionBar = () => {
    if (batchEditingContext.isInMultiEdit) {
      return <BatchEditingActionBar />
    } else if (transactionFilterContext?.isFiltering) {
      return <FilterActionBar />
    } else {
      return <AccountDepiction setActiveAction={setActiveAction} />
    }
  }

  const isTransactionCreatorActive =
    accountSheetContext.isTransactionCreatorOpen && activeAction

  return (
    <div
      className={classNames('account-header', {
        'has-scrolled': accountSheetContext.hasScrolled,
        'is-collapsed':
          accountSheetContext.isHeaderCollapsed ||
          accountSheetContext.hasListReachedBottom,
        'restricted-account': rules.discourageEditingWithDisclaimer,
      })}
    >
      <AccountTitleBar
        title={currentAccount.name}
        accountNumber={currentAccount.accountNumber}
        showAccountNumber={preferencesStore.showAccountNumbers}
        balance={(currentAccount as IAccount)?.balance}
      />
      {isDefinedAccount(currentAccount) &&
        (isTransactionCreatorActive ? (
          <TransactionCreator
            activeAction={activeAction}
            setActiveAction={setActiveAction}
            shouldCurrentAccountIncrease={shouldCurrentAccountIncrease}
            setShouldCurrentAccountIncrease={setShouldCurrentAccountIncrease}
          />
        ) : (
          depictionOrActionBar()
        ))}
    </div>
  )
}

export default observer(AccountHeader)
