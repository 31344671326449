import FinancialHealthContext from '../FinancialHealthContext'
import FinancialHealthMetric from '../FinancialHealthMetric'
import FinancialHealthStatusGroup from '../FinancialHealthStatusGroup'
import MetricNetProfit from '../metrics/MetricNetProfit'
import MetricReturnOnEquity from '../metrics/MetricReturnOnEquity'
import MetricTargetNetProfitMargin from '../metrics/MetricTargetGrossNetMargin'
import MetricTargetGrossProfitMargin from '../metrics/MetricTargetGrossProfitMargin'

class StatusGroupProfitability extends FinancialHealthStatusGroup {
  title = 'Profitabilität'

  setupMetrics(context: FinancialHealthContext): FinancialHealthMetric[] {
    return [
      new MetricNetProfit(context),
      new MetricReturnOnEquity(context),
      new MetricTargetGrossProfitMargin(context),
      new MetricTargetNetProfitMargin(context),
    ]
  }
}

export default StatusGroupProfitability
