import './TabsCountNumber.scss'

interface TabsCountNumberProps {
  number?: number
}

const TabsCountNumber = (props: TabsCountNumberProps) => {
  return (
    <div className='count-number'>
      <span className='count-number-span'>{props.number}</span>
    </div>
  )
}

export default TabsCountNumber
