import { InputField, NonIdealState } from '@nextbusiness/infinity-ui'
import { IconSize } from '@nextbusiness/infinity-ui-icons'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import { observer } from 'mobx-react'
import DocumentsPage, { DocumentsPagePaths } from 'pages/DocumentsPage'
import { useDeferredValue, useMemo, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import DocumentListItem from './DocumentListItem'

interface ProcessedDocumentsSheetProps {
  page: DocumentsPage
}

const ProcessedDocumentsSheet = (props: ProcessedDocumentsSheetProps) => {
  const { documentStore } = useRootStore()
  const processedDocuments = documentStore.processedDocuments

  const [searchQuery, setSearchQuery] = useState<string>('')
  const deferredSearchQuery = useDeferredValue(searchQuery)

  const filteredDocuments = useMemo(
    () =>
      processedDocuments?.filter(
        (document) =>
          document.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          document.note?.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    [processedDocuments, deferredSearchQuery]
  )

  return (
    <MagicSheetPage
      header={
        <MagicSheetTitleBar title='Erledigte Belege'>
          <InputField
            icon='search'
            placeholder='Nach Beleg suchen'
            value={searchQuery}
            onChange={setSearchQuery}
          />
        </MagicSheetTitleBar>
      }
    >
      {!filteredDocuments?.length && (
        <NonIdealState icon='inbox' iconSize={IconSize.Medium}>
          Keine Ergebnisse
        </NonIdealState>
      )}
      {filteredDocuments?.map((processedDocument) => (
        <DocumentListItem
          key={processedDocument.id}
          document={processedDocument}
          onClick={() =>
            props.page.open(
              DocumentsPagePaths.ProcessedDocumentsDetailView,
              processedDocument.id
            )
          }
        />
      ))}
    </MagicSheetPage>
  )
}

export default observer(ProcessedDocumentsSheet)
