import CustomerInvoicePositions from 'invoices/customer-invoices/CustomerInvoicePositions'
import Utilities from 'utility/Utilities'
import { DocumentCustomProductPositionProps } from '../DocumentCustomProductPosition'

const PositionVATField = (props: DocumentCustomProductPositionProps) => {
  if (!props.columns?.includes('vatDebt')) return null
  if (props.vatPresentationOptions?.hideVATInQuotes && props.isQuote)
    return null

  const vatDebt =
    CustomerInvoicePositions.vatDebtForPosition(
      props.position,
      props.amountMode
    ) || 0

  return (
    <div className='positions-column vat readonly'>
      {Utilities.centsToCHF(vatDebt)} CHF
    </div>
  )
}

export default PositionVATField
