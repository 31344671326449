import { Card } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { useBatchEditingContext } from './batch-editing/BatchEditingContext'

interface TransactionCardProps {
  isHighlighted?: boolean
  isBeingEdited?: boolean
  isBeingDeleted?: boolean
  isTallEditor?: boolean
  isClosing?: boolean
  hasLiveCaptureAttachment?: boolean
  isLiveAccountingTransaction?: boolean
  onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void
  children?: React.ReactNode
  dropzoneOptions?: DropzoneOptions
  isUploading?: boolean
}

const TransactionCard = (props: TransactionCardProps) => {
  const { isInMultiEdit } = useBatchEditingContext()
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noKeyboard: true,
    noClick: true,
    noDrag: !props.dropzoneOptions,
    ...props.dropzoneOptions,
  })

  return (
    <div
      className={classNames('transaction', {
        'edit-mode': props.isBeingEdited,
        highlighted: props.isHighlighted,
        'will-be-deleted': props.isBeingDeleted,
        'will-be-closed': props.isClosing,
        'tall-editor': props.isTallEditor,
        'with-live-capture-attachment': props.hasLiveCaptureAttachment,
        'live-accounting': props.isLiveAccountingTransaction,
        'is-drag-active': isDragActive,
        'live-capture-upload-indicator': props.isUploading,
      })}
      role={props.isBeingEdited || isInMultiEdit ? undefined : 'button'}
      tabIndex={props.isBeingEdited || isInMultiEdit ? undefined : 0}
      onMouseDown={
        isInMultiEdit
          ? (event) => {
              // This is to prevent accidental text selection when shift-clicking
              // on a transaction card to select multiple transactions.
              if (event.shiftKey) event.preventDefault()
            }
          : undefined
      }
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          props.onClick?.(e)
        }
      }}
      {...getRootProps()}
      onClick={(event) => props.onClick?.(event)}
    >
      <Card bare>{props.children}</Card>
      <input {...getInputProps()} />
    </div>
  )
}

export default TransactionCard
