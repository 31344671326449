import { Heading } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import React from 'react'

interface TemplateInspectorGroupProps {
  title?: string
  children?: React.ReactNode
  className?: string
}

const TemplateInspectorGroup = (props: TemplateInspectorGroupProps) => (
  <div className={classNames('template-inspector-group', props.className)}>
    {props.title && <Heading type='h4'>{props.title}</Heading>}
    <div className='group-inner'>{props.children}</div>
  </div>
)

export default TemplateInspectorGroup
