import { VATCalculationMode } from '@nextbusiness/infinity-finance-api'
import {
  Button,
  FeedbackBanner,
  Heading,
  NonIdealState,
  Text,
} from '@nextbusiness/infinity-ui'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import { displayNameForPeriod } from 'documents/vat-closing/overview/VATPeriodSection'
import { BackendError } from 'libs/networking/Errors'
import { observer } from 'mobx-react'
import { useEffect, useMemo, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import AssistantStepScreen, {
  AssistantStepVisual,
} from '../AssistantStepScreen'
import FinancialClosingAssistant from '../FinancialClosingAssistant'
import Check from '../components/Check'
import ClosingVATSimplifiedCheckStep from './ClosingVATSimplifiedCheckStep'

interface ClosingVATCheckStepProps {
  assistant: FinancialClosingAssistant
}

const ClosingVATCheckStep = (props: ClosingVATCheckStepProps) => {
  const { vatPeriodStore, preferencesStore } = useRootStore()

  const [isLoadingPeriods, setIsLoadingPeriods] = useState<boolean>(false)
  const [periodLoadingError, setPeriodLoadingError] = useState<BackendError>()

  const allPeriods = vatPeriodStore.periods ?? []
  const fiscalYear = props.assistant.dueFiscalYear

  const relevantPeriods = useMemo(() => {
    if (!fiscalYear) return []
    return allPeriods.filter(
      (period) => period.start >= fiscalYear.from && period.end <= fiscalYear.to
    )
  }, [allPeriods, fiscalYear])
  const periodsAreSettled = relevantPeriods.every((period) => period.wasSettled)

  const canContinue =
    !isLoadingPeriods && !periodLoadingError && periodsAreSettled

  useEffect(() => {
    const loadPeriods = async () => {
      setIsLoadingPeriods(true)
      setPeriodLoadingError(undefined)
      try {
        await vatPeriodStore.loadPeriods()
      } catch (error) {
        setPeriodLoadingError(error as BackendError)
      } finally {
        setIsLoadingPeriods(false)
      }
    }
    loadPeriods()
  }, [])

  if (
    preferencesStore.organisationPreferences?.vatCalculationMode ===
    VATCalculationMode.Simplified
  ) {
    return <ClosingVATSimplifiedCheckStep assistant={props.assistant} />
  }

  return (
    <AssistantStepScreen
      visual={AssistantStepVisual.Vat}
      actions={
        <>
          <Button onClick={() => props.assistant.previousStep()}>Zurück</Button>
          <Button
            variant='primary'
            onClick={() => props.assistant.nextStep()}
            disabled={!canContinue}
          >
            Fortfahren
          </Button>
        </>
      }
      depiction={
        <>
          <Heading type='h2'>Mehrwertsteuer</Heading>
          <Text type='paragraph'>
            Stelle sicher, dass alle steuerrelevanten Transaktionen im alten
            Geschäftsjahr korrekt verbucht sind, da du diese nach dem
            Jahresabschluss nicht mehr korrigieren kannst.
          </Text>
        </>
      }
    >
      {isLoadingPeriods ? (
        <LoadingScreen />
      ) : periodLoadingError ? (
        <NonIdealState title='Mehrwertsteuerperioden konnten nicht geladen werden'>
          {periodLoadingError.humanText()}
        </NonIdealState>
      ) : (
        <div>
          {relevantPeriods.map((period) => (
            <Check isFulfilled={period.wasSettled} key={period._id}>
              {displayNameForPeriod(period)}
            </Check>
          ))}
          {!periodsAreSettled && (
            <FeedbackBanner
              title='Offene Abrechnungen überprüfen'
              variant='warning'
            >
              Du hast noch offene Abrechnungen. Rechne diese zuerst ab, bevor du
              fortfährst.
            </FeedbackBanner>
          )}
        </div>
      )}
    </AssistantStepScreen>
  )
}

export default observer(ClosingVATCheckStep)
