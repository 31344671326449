import ProjectPage from 'pages/ProjectPage'
import React, { createContext, useMemo } from 'react'

interface IProjectBoardContext {
  page: ProjectPage
}

const ProjectBoardContext = createContext<IProjectBoardContext | null>(null)

interface ProjectBoardProviderProps {
  page: ProjectPage
  children: React.ReactNode
}

const ProjectBoardProvider = (props: ProjectBoardProviderProps) => {
  const { page } = props
  const contextValue = useMemo(() => ({ page }), [page])
  return (
    <ProjectBoardContext.Provider value={contextValue}>
      {props.children}
    </ProjectBoardContext.Provider>
  )
}

const useProjectBoardContext = () => {
  const context = React.useContext(ProjectBoardContext)
  if (!context)
    throw new Error(
      'useProjectBoardContext must be used within a ProjectBoardProvider'
    )
  return context
}

export { ProjectBoardContext, ProjectBoardProvider, useProjectBoardContext }
