import EditableDecimalAmount from '../../../controls/EditableDecimalAmount'
import { DocumentCustomProductPositionProps } from '../DocumentCustomProductPosition'

const PositionQuantityField = (props: DocumentCustomProductPositionProps) => {
  if (!props.columns?.includes('quantity')) return null
  return (
    <div className='positions-column quantity'>
      <EditableDecimalAmount
        placeholder='Anzahl'
        value={props.position.quantity}
        onChange={(quantity) => props.onChange({ quantity })}
        style={{ width: '4rem' }}
        isReadOnly={props.isReadOnly}
      />
    </div>
  )
}

export default PositionQuantityField
