import React from 'react'
import ContentLoader from 'react-content-loader'

const LoaderFinancialHealthLiquidity = () => (
  <ContentLoader
    speed={2}
    width={344}
    height={230}
    viewBox='0 0 344 230'
    backgroundColor='#f3f3f3'
    foregroundColor='#e5e5e5'
  >
    <rect x='0' y='20' rx='3' ry='3' width='87' height='15' />
    <rect x='0' y='45' rx='3' ry='3' width='122' height='18' />
    <rect x='173' y='20' rx='3' ry='3' width='87' height='15' />
    <rect x='173' y='47' rx='3' ry='3' width='122' height='18' />
    <rect x='106' y='96' rx='3' ry='3' width='235' height='18' />
    <rect x='106' y='124' rx='3' ry='3' width='235' height='18' />
    <rect x='0' y='96' rx='3' ry='3' width='70' height='18' />
    <rect x='0' y='126' rx='3' ry='3' width='71' height='17' />
    <rect x='0' y='169' rx='3' ry='3' width='63' height='19' />
    <rect x='0' y='202' rx='3' ry='3' width='64' height='20' />
    <rect x='76' y='173' rx='3' ry='3' width='245' height='14' />
    <rect x='76' y='202' rx='3' ry='3' width='245' height='14' />
  </ContentLoader>
)

export default LoaderFinancialHealthLiquidity
