import {
  Button,
  Checkbox,
  Flex,
  Text,
  TypedKeyValueStore,
} from '@nextbusiness/infinity-ui'
import IconSection from 'components/icon-section/IconSection'
import Check from 'documents/financial-closing/assistant/components/Check'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import DateUtilities from 'utility/DateUtilites'
import AccountBalancesColumn from './AccountBalancesColumn'
import useSetupAccountBalanceSums from './useSetupAccountBalanceSums'

interface SetupAccountBalanceFromExistingProps {
  accountBalances: TypedKeyValueStore<number | null>
  editBalance: (accountNumber: number, balance: number | null) => void
  openChartOfAccountsPage: () => void
}

const ONE_DAY = 24 * 60 * 60 * 1000

const SetupAccountBalanceFromExisting = (
  props: SetupAccountBalanceFromExistingProps
) => {
  const { accountStore } = useRootStore()
  const [showAllAccounts, setShowAllAccounts] = useState<boolean>(false)

  const fiscalYear = accountStore.ealiestOpenFiscalYear!
  const lastClosingDate = DateUtilities.humanFormat(fiscalYear.from - ONE_DAY)

  const { sumOfAssets, sumOfLiabilities, assetAccounts, liabilityAccounts } =
    useSetupAccountBalanceSums(accountStore.allAccounts, props.accountBalances)

  return (
    <IconSection icon='abacus'>
      <Text className='instructions'>
        Übertrage hier deine letzte Schlussbilanz per {lastClosingDate}.
      </Text>
      <div className='checks'>
        <Check isFulfilled={sumOfAssets === sumOfLiabilities}>
          Vermögen und Schulden müssen zum Eröffnungszeitpunkt einer Buchhaltung
          übereinstimmen.
        </Check>
      </div>
      <Flex gap='small' verticalAlignment='center'>
        <Checkbox
          label='Alle Konten anzeigen'
          isChecked={showAllAccounts}
          onChange={setShowAllAccounts}
        />
        <span style={{ marginLeft: 'auto' }}>
          Du benötigst ein eigenes Konto?
        </span>
        <Button iconLeft='accounting' onClick={props.openChartOfAccountsPage}>
          Kontenplan anpassen
        </Button>
      </Flex>
      <div className='accounts'>
        <AccountBalancesColumn
          title='Vermögen'
          accounts={assetAccounts}
          accountBalances={props.accountBalances}
          onAccountBalanceChange={props.editBalance}
          showAllAccounts={showAllAccounts}
          sum={sumOfAssets}
        />
        <AccountBalancesColumn
          title='Schulden'
          accounts={liabilityAccounts}
          accountBalances={props.accountBalances}
          onAccountBalanceChange={props.editBalance}
          showAllAccounts={showAllAccounts}
          sum={sumOfLiabilities}
        />
      </div>
    </IconSection>
  )
}

export default observer(SetupAccountBalanceFromExisting)
