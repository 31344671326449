import Utilities from '../../../../utility/Utilities'
import FinancialHealthMetric from '../FinancialHealthMetric'
import { FinancialHealthAssessment } from '../FinancialHealthAssessment'

class MetricNetProfit extends FinancialHealthMetric {
  title = 'Gewinn/Verlust'
  description = 'Positiver Gewinn im laufenden Geschäftsjahr'

  getAssessment = (): FinancialHealthAssessment =>
    FinancialHealthMetric.assessmentFromStatus(
      this.context.profitability?.netProfit.status
    )

  getStatusText = (): string =>
    this.context.profitability?.netProfit.status ?? 'Keine Angabe'

  getActualValue = () =>
    `CHF ${Utilities.centsToCHF(
      this.context.profitability?.netProfit.currentYear
    )}`
}

export default MetricNetProfit
