import { Flex } from '@nextbusiness/infinity-ui'
import ExtendedInvoiceListItemContact from './ExtendedInvoiceListItemContact'
import ExtendedInvoiceListItemTitle from './ExtendedInvoiceListItemTitle'

interface ExtendedInvoiceListItemDepictionProps {
  contactId?: string
  title: string
}

const ExtendedInvoiceListItemDepiction = (
  props: ExtendedInvoiceListItemDepictionProps
) => {
  return (
    <Flex direction='vertical' className='depiction'>
      {props.contactId && (
        <ExtendedInvoiceListItemContact contactId={props.contactId} />
      )}
      <ExtendedInvoiceListItemTitle title={props.title} />
    </Flex>
  )
}

export default ExtendedInvoiceListItemDepiction
