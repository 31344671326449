import {
  ITransaction,
  VATCalculationMode,
} from '@nextbusiness/infinity-finance-api'
import { DateTime } from 'luxon'
import PreferencesStore from 'stores/PreferencesStore'

/** We changed the way the simplified VAT calculation works from the second half of 2024 onwards.
 *  Transactions created after this date (only in simplified mode) should not have a `taxCode`.
 *  Due to an oversight, this was not enforced properly, so we now check retroactively. */
const SIMPLIFIED_MODE_VAT_CUTOFF_DATE = DateTime.fromFormat(
  '01.07.2024',
  'dd.MM.yyyy',
  { locale: 'de-CH' }
).toMillis()

const useIsVATDisabled = (
  preferencesStore: PreferencesStore,
  /** VAT is only disabled in simplified mode for transactions created after 01.07.2024 */
  forTransaction?: Pick<Partial<ITransaction>, 'taxCode' | 'date'>
) => {
  const isVATEnabled = preferencesStore.organisationPreferences?.VAT === true
  const isUsingSimplifiedVATMode =
    preferencesStore.organisationPreferences?.vatCalculationMode ===
    VATCalculationMode.Simplified

  const shouldVATBeDisabled = !isVATEnabled || isUsingSimplifiedVATMode
  const wasCreatedAfterCutoffDate =
    forTransaction?.date &&
    forTransaction.date > SIMPLIFIED_MODE_VAT_CUTOFF_DATE
  const isPastTransactionWithVAT =
    !!forTransaction?.taxCode && !wasCreatedAfterCutoffDate

  const isVATDisabled = shouldVATBeDisabled && !isPastTransactionWithVAT
  return { isVATDisabled }
}

export default useIsVATDisabled
