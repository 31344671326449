import { Text } from '@nextbusiness/infinity-ui'
import { useAccountPageContext } from 'ledger/accounts/AccountPageContext'
import AccountSelect from 'ledger/accounts/account-select/AccountSelect'
import './ChangeContraAccountView.scss'

interface ChangeContraAccountProps {
  newAccountNumber: number | undefined
  setNewAccountNumber: (newAccountNumber: number | undefined) => void
}

const ChangeContraAccount = (props: ChangeContraAccountProps) => {
  const { currentAccount } = useAccountPageContext()
  return (
    <>
      <Text>
        Wähle hier ein anderes Gegenkonto für die ausgewählten Transaktionen
        aus.
      </Text>
      <div className='change-contra-account-form'>
        <AccountSelect
          className='change-contra-account-select'
          inputFieldProps={{ fullWidth: true }}
          currentAccountNumber={currentAccount.accountNumber}
          suggestions={[]}
          onChange={(accountNumber: number | undefined) =>
            props.setNewAccountNumber(accountNumber)
          }
        />
      </div>
    </>
  )
}

export default ChangeContraAccount
