import { Flex, NonIdealState } from '@nextbusiness/infinity-ui'
import { IVendorInvoice } from 'model/VendorInvoice'
import VendorInvoiceListItem from '../VendorInvoiceListItem'
import useFilteredVendorInvoices from './useFilteredVendorInvoices'

interface VendorInvoicesFilteredListProps {
  invoices: IVendorInvoice[]
  openVendorInvoice: (invoice: IVendorInvoice) => void
}

const VendorInvoicesFilteredList = (props: VendorInvoicesFilteredListProps) => {
  const filteredInvoices = useFilteredVendorInvoices(props.invoices)
  return (
    <>
      {!filteredInvoices?.length ? (
        <NonIdealState>Keine Rechnungen</NonIdealState>
      ) : (
        <Flex direction='vertical' className='vendor-invoice-list'>
          {filteredInvoices?.map((invoice: IVendorInvoice) => (
            <div key={invoice.id}>
              <VendorInvoiceListItem
                invoice={invoice}
                onClick={() => props.openVendorInvoice(invoice)}
              />
            </div>
          ))}
        </Flex>
      )}
    </>
  )
}

export default VendorInvoicesFilteredList
