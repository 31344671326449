import { Button, Flex } from '@nextbusiness/infinity-ui'
import { useAccountPageContext } from 'ledger/accounts/AccountPageContext'
import Tabs, { Tab } from 'proto-ui-components/Tabs'
import { useTransactionCreatorContext } from './TransactionCreatorContext'

const TransactionCreatorTabs = () => {
  const { activeAction, changeAction, closeTransactionCreator } =
    useTransactionCreatorContext()
  const { rules } = useAccountPageContext()

  return (
    <Flex className='tabs-wrapper' verticalAlignment='center' gap='tiny'>
      <Tabs
        currentTabId={activeAction.displayName}
        tabs={rules.actions.map(
          (action): Tab => ({
            identifier: action.displayName,
            title: action.displayName,
            onClick: () => changeAction(action),
          })
        )}
      />
      <div style={{ flexGrow: 1 }} />
      <Button
        className='cancel-button'
        iconOnly='dismiss'
        variant='quaternary'
        onClick={() => closeTransactionCreator()}
      />
    </Flex>
  )
}

export default TransactionCreatorTabs
