import { ITransaction } from '@nextbusiness/infinity-finance-api'
import { TypedKeyValueStore } from '@nextbusiness/infinity-ui'
import { DateTime } from 'luxon'
import { IFiscalYear } from 'model/FiscalYear'
import { useContext, useMemo } from 'react'
import { TransactionFilterContext } from '../filter/TransactionFilterContext'

type TransactionsByMonth = TypedKeyValueStore<{
  transactions: ITransaction[]
  endOfFiscalYear?: number
  start: number
  end: number
}>

const useTransactionsGroupedByMonth = (
  filteredTransactions: ITransaction[] | undefined,
  allTransactions: ITransaction[] | undefined,
  fiscalYears: IFiscalYear[] = []
) => {
  const filterContext = useContext(TransactionFilterContext)

  const transactions =
    filterContext?.isFiltering && filterContext?.appliedFilters
      ? filteredTransactions
      : allTransactions

  const transactionsByMonth = useMemo(() => {
    const accumulator: TransactionsByMonth = {}

    transactions?.forEach((transaction) => {
      const dateTime = DateTime.fromMillis(transaction.date)
      const month = dateToDisplayMonth(transaction.date)
      if (!accumulator[month]) {
        accumulator[month] = {
          transactions: [],
          start: dateTime.startOf('month').toMillis(),
          end: dateTime.endOf('month').toMillis(),
        }
      }
      accumulator[month].transactions.push(transaction)
    })

    return insertFiscalYearEnds(fiscalYears, accumulator)
  }, [transactions])

  return { transactionsByMonth, transactions }
}

const insertFiscalYearEnds = (
  fiscalYears: IFiscalYear[],
  fromAccumulator: TransactionsByMonth
) => {
  const transactionsByMonth = { ...fromAccumulator }
  const reversedFiscalYears = fiscalYears.slice().reverse()
  reversedFiscalYears.forEach((fiscalYear, index) => {
    if (index === 0) return
    const date = findMonthForFiscalYearEnd(fiscalYear, transactionsByMonth)
    if (date) {
      transactionsByMonth[date] = {
        ...transactionsByMonth[date],
        endOfFiscalYear: fiscalYear.year,
      }
    }
  })
  return transactionsByMonth
}

const findMonthForFiscalYearEnd = (
  fiscalYear: IFiscalYear,
  transactionsByMonth: TransactionsByMonth
) => {
  const fiscalYearEndMonth = dateToDisplayMonth(fiscalYear.to)
  if (transactionsByMonth[fiscalYearEndMonth]) {
    return fiscalYearEndMonth
  } else {
    const lastMonth = Object.keys(transactionsByMonth)
      .reverse()
      .find((key) => {
        const month = transactionsByMonth[key]
        return (
          month.transactions.length > 0 &&
          month.start >= fiscalYear.from &&
          month.end <= fiscalYear.to
        )
      })
    return lastMonth
  }
}

const dateToDisplayMonth = (timestamp: number) => {
  const dateTime = DateTime.fromMillis(timestamp)
  const date = dateTime.toLocaleString({
    locale: 'de-CH',
    month: 'long',
    year: dateTime.year === DateTime.now().year ? undefined : 'numeric',
  })
  return date
}

export default useTransactionsGroupedByMonth
