import {
  INewTransaction,
  ITransaction,
} from '@nextbusiness/infinity-finance-api'
import { observer } from 'mobx-react'
import { IAccount } from 'model/Account'
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import TransactionEditorUtilities from './TransactionEditorUtilities'
import TransactionNoEditReason from './TransactionNoEditReason'
import useSaveTransactionChanges from './hooks/useSaveTransactionChanges'

export type TransactionEditorContextType = {
  isSaving: boolean
  isDraftIncomplete: boolean
  canMakeChanges: boolean
  canChangeDescription: boolean
  canDelete: boolean
  noEditReason: TransactionNoEditReason | null
  hasEditedTransaction: boolean
  onSaveChanges: () => void
  onDeleteClicked: () => void
  closeEditor: () => void
  draftTransaction: INewTransaction
  updateDraftTransaction: (changes: Partial<INewTransaction>) => void
  isDeletionModalOpen: boolean
  contraAccountSide: 'debitAccount' | 'creditAccount'
  originalTransaction: ITransaction
}

const TransactionEditorContext =
  createContext<TransactionEditorContextType | null>(null)

type TransactionEditorProviderProps = {
  transaction: ITransaction
  currentAccount: IAccount
  closeEditor: () => void
  openDeletionModal: () => void
  isDeletionModalOpen: boolean
  children: React.ReactNode
}

const TransactionEditorProvider = (props: TransactionEditorProviderProps) => {
  const { accountStore, vatPeriodStore } = useRootStore()
  const {
    transaction,
    currentAccount,
    openDeletionModal,
    isDeletionModalOpen,
    closeEditor,
  } = props

  const initialEditorAmount = TransactionEditorUtilities.defaultEditorAmount(
    transaction,
    currentAccount
  )
  const [draftTransaction, setDraftTransaction] = useState<INewTransaction>({
    ...transaction,
    amount: initialEditorAmount,
  })

  useEffect(() => {
    setDraftTransaction({
      ...draftTransaction,
      description: transaction.description,
      taxCode: transaction.taxCode,
    })
  }, [transaction.description, transaction.taxCode])

  const editorUtilities = new TransactionEditorUtilities(
    transaction,
    draftTransaction,
    currentAccount,
    accountStore,
    vatPeriodStore
  )

  const canChangeDescription = !editorUtilities.isDescriptionEditingRestricted
  const canMakeChanges = !editorUtilities.isTransactionEditingRestricted
  const canDelete = !editorUtilities.isTransactionDeletionRestricted
  const noEditReason = editorUtilities.noEditReasonForTransaction
  const isDraftIncomplete = editorUtilities.isDraftIncomplete
  const hasEditedTransaction = editorUtilities.hasEditedTransaction

  const contraAccountSide = editorUtilities.contraAccountSide

  const { isSaving, saveChanges } = useSaveTransactionChanges(
    transaction,
    editorUtilities,
    closeEditor
  )
  const updateDraftTransaction = (changes: Partial<INewTransaction>) =>
    setDraftTransaction({ ...draftTransaction, ...changes })

  const contextValue: TransactionEditorContextType = useMemo(
    () => ({
      canMakeChanges,
      canChangeDescription,
      canDelete,
      closeEditor,
      draftTransaction,
      hasEditedTransaction,
      isDraftIncomplete,
      isSaving,
      onDeleteClicked: openDeletionModal,
      onSaveChanges: saveChanges,
      updateDraftTransaction,
      isDeletionModalOpen,
      noEditReason,
      contraAccountSide,
      originalTransaction: transaction,
    }),
    [
      canMakeChanges,
      canDelete,
      closeEditor,
      draftTransaction,
      hasEditedTransaction,
      isDraftIncomplete,
      isSaving,
      openDeletionModal,
      saveChanges,
      updateDraftTransaction,
      isDeletionModalOpen,
      noEditReason,
      contraAccountSide,
      transaction,
    ]
  )

  return (
    <TransactionEditorContext.Provider value={contextValue}>
      {props.children}
    </TransactionEditorContext.Provider>
  )
}

export const useTransactionEditorContext = () => {
  const context = useContext(TransactionEditorContext)
  if (!context)
    throw new Error(
      'useTransactionEditorContext must be used within a TransactionEditorProvider'
    )
  return context
}

export default observer(TransactionEditorProvider)
