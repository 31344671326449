import { Dialog } from '@nextbusiness/infinity-ui'

interface ConfirmDeleteContactProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  contactName: string
  onConfirm: () => void
  isLoading: boolean
}

const ConfirmDeleteContact = (props: ConfirmDeleteContactProps) => (
  <Dialog
    title={`Kontakt "${props.contactName}" löschen?`}
    icon='warning'
    initialFocusIndex={0}
    isOpen={props.isOpen}
    actions={[
      {
        children: 'Abbrechen',
        variant: 'tertiary',
        onClick: () => {
          props.setIsOpen(false)
        },
      },
      {
        children: 'Löschen',
        variant: 'destructive',
        onClick: () => props.onConfirm(),
        isLoading: props.isLoading,
      },
    ]}
  >
    Der Kontakt wird unwiderruflich gelöscht werden.
  </Dialog>
)

export default ConfirmDeleteContact
