import {
  DatePicker,
  Flex,
  InputField,
  Spacer,
  Text,
} from '@nextbusiness/infinity-ui'
import { DateSuggestion } from '@nextbusiness/infinity-ui/dist/input/datepicker/DatePicker'
import ContactSelect from 'components/contact-select/ContactSelect'
import { observer } from 'mobx-react'
import { useContext } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { IVendorInvoiceDraft } from '../../../model/VendorInvoice'
import { useVendorInvoiceEditor } from '../detail/VendorInvoiceEditorContext'
import { VendorInvoiceCreationContext } from './VendorInvoiceCreationContext'
import './VendorInvoiceGeneralFields.scss'

interface VendorInvoiceGeneralFieldsProps {
  invoice: IVendorInvoiceDraft
  onChange: (updatedInvoice: IVendorInvoiceDraft) => void
  shouldValidate?: boolean
}

const dueDateSuggestionsForOpeningDate = (
  fromTimestamp: number
): DateSuggestion[] => {
  const suggestions: DateSuggestion[] = []
  try {
    const openingDate = new Date(fromTimestamp)
    const datePeriods = [14, 30, 60]

    datePeriods.forEach((period) => {
      const dueDate = new Date(+openingDate)
      dueDate.setDate(dueDate.getDate() + period)

      suggestions.push({
        date: dueDate,
        description: `Frist von ${period} Tagen`,
        icon: 'hourglass',
      })
    })
  } catch {
    // Don't bother
  }
  return suggestions
}

const VendorInvoiceGeneralFields = (props: VendorInvoiceGeneralFieldsProps) => {
  const { accountStore } = useRootStore()

  const editorContext = useVendorInvoiceEditor()
  const creationContext = useContext(VendorInvoiceCreationContext)
  const shouldValidate = props.shouldValidate ?? editorContext.shouldValidate
  const newContactSuggestion =
    creationContext?.liveCaptureTransformer?.newContactSuggestion

  const isTitleMissing = shouldValidate && !props.invoice.title

  const isOpeningDateMissing = shouldValidate && !props.invoice.openingDate
  const isOpeningDateOutsideFiscalYear =
    shouldValidate &&
    !!props.invoice.openingDate &&
    !accountStore.isDateWithinOpenFiscalYear(
      new Date(props.invoice.openingDate)
    )

  const isDueDateMissing = shouldValidate && !props.invoice.dueDate

  return (
    <Flex
      className='vendor-invoice-general-fields'
      direction='vertical'
      gap='tiny'
    >
      <Flex className='title' direction='horizontal' verticalAlignment='center'>
        <Text className='label' type='inline'>
          Titel
        </Text>
        <InputField
          className='input-field'
          value={props.invoice.title ?? ''}
          onChange={(title) => props.onChange({ ...props.invoice, title })}
          placeholder='Titel'
          hasError={isTitleMissing}
          helperText={isTitleMissing ? 'Titel ist erforderlich.' : undefined}
        />
      </Flex>
      <Flex
        className='creditor'
        direction='horizontal'
        verticalAlignment='center'
      >
        <Text className='label' type='inline'>
          Lieferant
        </Text>
        <Flex className='creditor-input-wrapper'>
          <ContactSelect
            contactId={props.invoice.creditor}
            onChange={(creditor) =>
              props.onChange({ ...props.invoice, creditor })
            }
            liveCaptureSuggestion={newContactSuggestion}
          />
        </Flex>
      </Flex>
      <Flex className='date' direction='horizontal' verticalAlignment='center'>
        <Text className='label' type='inline'>
          Datum
        </Text>
        <Flex className='date-input-wrapper' direction='horizontal'>
          <DatePicker
            value={
              props.invoice.openingDate
                ? new Date(props.invoice.openingDate)
                : undefined
            }
            onChange={(date) => {
              const openingDate = date ? date.getTime() : undefined
              props.onChange({ ...props.invoice, openingDate })
            }}
            placeholder='Rechnungsdatum'
            inputFieldProps={{
              icon: 'schedule',
              hasError:
                !!isOpeningDateMissing || !!isOpeningDateOutsideFiscalYear,
              helperText: isOpeningDateMissing
                ? 'Datum ist erforderlich.'
                : isOpeningDateOutsideFiscalYear
                ? 'Datum ist ausserhalb aller offenen Geschäftsjahre.'
                : undefined,
            }}
            quickDates
          />
          <Spacer size='tiny' direction='horizontal' />
          <DatePicker
            value={
              props.invoice.dueDate
                ? new Date(props.invoice.dueDate)
                : undefined
            }
            onChange={(date) => {
              const dueDate = date ? date.getTime() : undefined
              props.onChange({ ...props.invoice, dueDate })
            }}
            placeholder='Zahlbar bis'
            inputFieldProps={{
              icon: 'date-to',
              hasError: isDueDateMissing,
              helperText: isDueDateMissing
                ? '"Zahlbar bis" ist erforderlich.'
                : undefined,
            }}
            additionalDates={
              props.invoice.openingDate
                ? dueDateSuggestionsForOpeningDate(props.invoice.openingDate)
                : undefined
            }
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default observer(VendorInvoiceGeneralFields)
