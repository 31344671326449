import { Heading, NonIdealState } from '@nextbusiness/infinity-ui'
import { IconSize } from '@nextbusiness/infinity-ui-icons'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import { observer } from 'mobx-react-lite'
import Tabs from 'proto-ui-components/Tabs'
import { useEffect } from 'react'
import { useSessionStorage } from 'utility/hooks'
import MagicSheetPage from '../../components/magic-sheet/MagicSheetPage'
import { useRootStore } from '../../stores/RootStoreContext'
import '../shared-components/InvoicesPage.scss'
import './CustomerInvoices.scss'
import CustomerInvoicesHeader from './CustomerInvoicesHeader'
import CustomerInvoicesNoTemplateScreen from './CustomerInvoicesNoTemplateScreen'
import CustomerInvoiceDrafts from './large-list/CustomerInvoiceDrafts'
import UnpaidCustomerInvoices from './large-list/UnpaidCustomerInvoices'
import CustomerInvoicesList from './regular-list/CustomerInvoicesList'

type InvoicesTab = 'drafts' | 'unpaid'

const CustomerInvoices = () => {
  const { accountStore, templateStore, customerInvoiceStore } = useRootStore()

  const [currentTab, setCurrentTab] = useSessionStorage<InvoicesTab>(
    'currentCustomerInvoicesTab',
    'drafts'
  )

  useEffect(() => {
    customerInvoiceStore.loadAll()
  }, [accountStore.currentFiscalYear])

  if (!templateStore.templates || !customerInvoiceStore.allCustomerInvoices)
    return <LoadingScreen />

  const invoiceDrafts = customerInvoiceStore.invoiceDrafts
  const allInvoices = customerInvoiceStore.allCustomerInvoices

  const hasDrafts = invoiceDrafts && invoiceDrafts.length > 0
  const hasInvoices = allInvoices && allInvoices.length > 0

  const hasAnything = hasDrafts || hasInvoices

  return (
    <MagicSheetPage
      className='customer-invoices-page'
      header={<CustomerInvoicesHeader title='Forderungen an Kunden' />}
      containerClassName='invoices-page-container'
    >
      {!hasAnything ? (
        <CustomerInvoicesNoTemplateScreen />
      ) : (
        <>
          <div className='tabs-and-invoices-section'>
            <Tabs
              currentTabId={currentTab}
              onTabSelected={(tab) =>
                setCurrentTab(tab.identifier as InvoicesTab)
              }
              tabs={[
                { identifier: 'drafts', title: 'Entwürfe' },
                { identifier: 'unpaid', title: 'Unbezahlt' },
              ]}
            />
            {currentTab === 'drafts' && (
              <CustomerInvoiceDrafts drafts={invoiceDrafts} />
            )}
            {currentTab === 'unpaid' && <UnpaidCustomerInvoices />}
          </div>
          <Heading type='h3' className='heading' bare>
            Alle Forderungen
          </Heading>
          {hasInvoices ? (
            <CustomerInvoicesList invoices={allInvoices} type='invoices' />
          ) : (
            <div className='customer-invoices-empty'>
              <NonIdealState icon='purchase-order' iconSize={IconSize.Medium}>
                Hier wirst du alle Forderungen sehen, die du jemals gestellt
                hast.
              </NonIdealState>
            </div>
          )}
        </>
      )}
    </MagicSheetPage>
  )
}

export default observer(CustomerInvoices)
