import { Flex } from '@nextbusiness/infinity-ui'
import React from 'react'

interface TransactionCreatorFieldsProps {
  children: React.ReactNode
}

const TransactionCreatorFields = (props: TransactionCreatorFieldsProps) => (
  <Flex
    className='input-fields'
    verticalAlignment='center'
    horizontalAlignment='space-between'
    gap='tiny'
  >
    {props.children}
  </Flex>
)

export default TransactionCreatorFields
