import { Flex } from '@nextbusiness/infinity-ui'
import { usePluralise } from 'utility/hooks'
import { PublicInvoiceSidebarProps } from './PublicInvoiceSidebar'
import SidebarInfoItem from './SidebarInfoItem'

const PublicQuoteSidebarInfo = (props: PublicInvoiceSidebarProps) => {
  const validForDays = usePluralise(props.invoice.payableInDays, 'Tag', {
    plural: 'Tage',
  })
  return (
    <Flex className='sidebar-info' direction='vertical' gap={3.2}>
      {props.template.showQuoteNumber && (
        <SidebarInfoItem label='Offertennummer' value={props.invoiceNumber} />
      )}
      <SidebarInfoItem
        label='Gesendet von'
        value={
          <span>
            {props.template.organisationName}
            <br />
            {props.template.addressLine}
            <br />
            {props.template.zipCode} {props.template.city}
          </span>
        }
      />
      <SidebarInfoItem label='Gültig für' value={validForDays} />
    </Flex>
  )
}

export default PublicQuoteSidebarInfo
