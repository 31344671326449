import { Flex, LoadingIndicator, Text } from '@nextbusiness/infinity-ui'
import { Icon, IconItem } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import { Upload } from 'documents/document-creator/DocumentUpload'
import { useCallback, useState } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import './UploadArea.scss'

interface UploadAreaProps {
  icon: IconItem
  uploadItemKind: string
  onUploadSelected: (upload: Upload) => Promise<void> | void
  isEmpty: boolean
  disabled?: boolean
  dropzoneOptions?: DropzoneOptions
}

const UploadArea = (props: UploadAreaProps) => {
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const disabled = props.disabled || props.dropzoneOptions?.disabled

  const onUploadSelected = async (upload: Upload) => {
    setIsUploading(true)
    await props.onUploadSelected(upload)
    setIsUploading(false)
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles) {
      const [acceptedFile] = acceptedFiles
      const upload: Upload = {
        file: acceptedFile,
        title: acceptedFile.name.replace(/\.[^/.]+$/, ''),
      }
      onUploadSelected(upload)
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    ...props.dropzoneOptions,
    disabled,
  })

  return (
    <div
      className={classNames('upload-area', {
        'is-dropping': isDragActive,
        disabled,
      })}
      {...getRootProps()}
    >
      <Flex
        direction='vertical'
        verticalAlignment='center'
        horizontalAlignment='center'
        className='content-area'
      >
        {isUploading ? (
          <LoadingIndicator />
        ) : (
          <>
            {!isDragActive && <Icon size={30} icon={props.icon} />}
            <Text className='upload-text' type='inline'>
              {`${props.uploadItemKind} ${
                props.isEmpty ? 'hochladen' : 'ersetzen'
              }`}
            </Text>
          </>
        )}
      </Flex>
      <input {...getInputProps()} />
    </div>
  )
}

export default UploadArea
