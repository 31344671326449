import { VATFormGroupTaxCalculationProps } from './VATFormGroupTaxCalculation'
import VATFormHeader1 from './VATFormHeader1'
import VATFormHeader2 from './VATFormHeader2'
import VATFormRow from './VATFormRow'

const VATFormGroupTaxEra2024 = (props: VATFormGroupTaxCalculationProps) => (
  <>
    <VATFormHeader1 title='II. Steuerberechnung' />
    <div className='vat-form-group-highlight'>
      <VATFormHeader2
        title='Leistungen zu neuen Steuersätzen (ab 01.01.2024)'
        digitColumn1='Ziffer'
        valueColumn1='Leistungen'
        valueColumn2='Steuer'
        digitColumn2='Satz'
      />
      <VATFormRow
        title='Leistungen zum Normalsatz'
        digitColumn1='303'
        value1={props.report['303'].amount}
        value2={Math.round(props.report['303'].amount * 0.081)}
        value2Sign='plus'
        digitColumn2='8.1%'
      />
      <VATFormRow
        title='Leistungen zum reduzierten Satz'
        digitColumn1='313'
        value1={props.report['313'].amount}
        value2={Math.round(props.report['313'].amount * 0.026)}
        value2Sign='plus'
        digitColumn2='2.6%'
      />
      <VATFormRow
        title='Leistungen zum Beherbergungssatz'
        digitColumn1='343'
        value1={props.report['343'].amount}
        value2={Math.round(props.report['343'].amount * 0.038)}
        value2Sign='plus'
        digitColumn2='3.8%'
      />
    </div>
    <VATFormHeader2 title='Leistungen zu alten Steuersätzen (bis 31.12.2023)' />
    <VATFormRow
      title='Leistungen zum Normalsatz'
      digitColumn1='302'
      value1={props.report['302'].amount}
      value2={Math.round(props.report['302'].amount * 0.077)}
      value2Sign='plus'
      digitColumn2='7.7%'
    />
    <VATFormRow
      title='Leistungen zum reduzierten Satz'
      digitColumn1='312'
      value1={props.report['312'].amount}
      value2={Math.round(props.report['312'].amount * 0.025)}
      value2Sign='plus'
      digitColumn2='2.5%'
    />
    <VATFormRow
      title='Leistungen zum Beherbergungssatz'
      digitColumn1='342'
      value1={props.report['342'].amount}
      value2={Math.round(props.report['342'].amount * 0.037)}
      value2Sign='plus'
      digitColumn2='3.7%'
    />
    <VATFormRow
      title='Steuerbarer Gesamtumsatz (wie Ziff. 299)'
      digitColumn1='379'
      value1={props.report['379'].amount}
      value1Sign='equals'
    />
    <VATFormHeader2
      title='Bezugsteuer'
      digitColumn1='Ziffer'
      valueColumn1='Leistungen'
      valueColumn2='Steuer'
    />
    <VATFormRow
      title='Bezugsteuer zu neuen Steuersätzen (netto, exkl. MWST)'
      digitColumn1='383'
      value1={props.report['383'].amount}
      value2={Math.round(props.report['383'].amount * 0.081)}
      value2Sign='plus'
    />
    <VATFormRow
      title='Bezugsteuer zu alten Steuersätzen (netto, exkl. MWST)'
      digitColumn1='382'
      value1={props.report['382'].amount}
      value2={Math.round(props.report['382'].amount * 0.077)}
      value2Sign='plus'
    />
    <VATFormRow
      title='Total geschuldete Steuer (Ziff. 301 bis 382)'
      digitColumn1='399'
      value2={props.report['399'].amount}
      value2Sign='equals'
    />
  </>
)

export default VATFormGroupTaxEra2024
