import { APPLICATION_IDENTIFIER } from 'application/ApplicationConstants'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import Authentication, {
  Current,
  ORIGIN,
} from '../../networking/Authentication'
import { useRootStore } from '../../stores/RootStoreContext'
import {
  InfinityResponse,
  InfinityTrustedSuccessResponse,
} from './InfinityBaseMessaging'
import { Permission } from './Permission'
import './RequestPermission.scss'

interface RequestPermissionProps {
  permissions: Permission[]
  onPermissionGranted?: (reloadRequired?: boolean) => void
  onPermissionDenied?: () => void
}

const MODAL_TRANSITION_DELAY = 1000

const RequestPermission = (props: RequestPermissionProps) => {
  const { authenticationStore } = useRootStore()
  const [isModalActive, setIsModalActive] = useState<boolean>(true)

  const handlePermissionGranted = async (relaunchToken?: string) => {
    const fabricId = authenticationStore.fabricOrganisationIdentifier
    if (!relaunchToken || !fabricId) {
      // We can't auto-relaunch because we're not on a trusted domain (e.g. when running
      // locally on a dev servers), so we will hard-reload the app
      return authenticationStore.logout()
    }
    try {
      const credentials = await Authentication.authenticate(
        fabricId,
        relaunchToken
      )
      if (!credentials.applicationAccessToken || !credentials.userDataToken)
        return

      Current.credentials = credentials
      runInAction(() => {
        authenticationStore.setCurrentTokens(
          credentials.applicationAccessToken,
          credentials.userDataToken,
          credentials.sessionToken,
          credentials.hmac
        )
        authenticationStore.onLogin()
        if (props.onPermissionGranted)
          props.onPermissionGranted(relaunchToken !== undefined)
      })
    } catch (error) {
      // Auto-relaunch failed for some reason, so we will hard-reload the app
      authenticationStore.logout()
    }
  }

  const onInfinityBaseReady = () => {
    const iframe = document.getElementById(
      'permission-iframe'
    ) as HTMLIFrameElement
    const message = { type: 'infinity.request.start' }
    iframe.contentWindow?.postMessage(JSON.stringify(message), ORIGIN)
  }

  const handleEventMessage = (event: MessageEvent<any>) => {
    if (event.origin !== ORIGIN) return

    const payload = JSON.parse(event.data) as InfinityResponse
    switch (payload.type) {
      case 'infinity.request.ready':
        onInfinityBaseReady()
        break
      case 'infinity.response.success':
        if (props.onPermissionGranted) void handlePermissionGranted()
        break
      case 'infinity.response.trusted.success':
        const response = payload as InfinityTrustedSuccessResponse
        if (props.onPermissionGranted)
          void handlePermissionGranted(response.token)
        break
      case 'infinity.response.cancel':
        if (props.onPermissionDenied) props.onPermissionDenied()
        break
      default:
        break
    }
    if (payload.shouldDismissFrame)
      setTimeout(() => setIsModalActive(false), MODAL_TRANSITION_DELAY)
  }

  useEffect(() => {
    window.addEventListener('message', handleEventMessage, false)
    return () =>
      window.removeEventListener('message', handleEventMessage, false)
  })

  return isModalActive
    ? ReactDOM.createPortal(
        <div className='request-permission-flow'>
          <iframe
            id='permission-iframe'
            src={`${ORIGIN}/system/ask-permission?app=${APPLICATION_IDENTIFIER}&permissions=${props.permissions
              .map((permission) => encodeURIComponent(permission))
              .join(',')}&organisation=${
              authenticationStore.fabricOrganisationIdentifier
            }`}
            title='Zugriff erteilen für App'
          />
        </div>,
        document.getElementById('frame-root')!
      )
    : null
}

export default observer(RequestPermission)
