import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { ProjectPagePaths } from 'pages/ProjectPage'
import './DraggableProjectCard.scss'
import { useProjectBoardContext } from './ProjectBoardContext'
import ProjectCard, { ProjectCardProps } from './ProjectCard'

const DraggableProjectCard = (props: ProjectCardProps) => {
  const { page } = useProjectBoardContext()
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: props.project.id,
    })
  const style = {
    transform: CSS.Translate.toString(transform),
    zIndex: isDragging ? 1000 : 1,
  }

  return (
    <button
      className='project-card-draggable'
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      onClick={() => page.open(ProjectPagePaths.Project, props.project.id)}
    >
      <ProjectCard {...props} />
    </button>
  )
}

export default DraggableProjectCard
