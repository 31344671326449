import { APPLICATION_IDENTIFIER } from 'application/ApplicationConstants'
import Subscription from 'libs/subscription/Subscription'
import { observer } from 'mobx-react-lite'
import NetworkingConstants from 'networking/NetworkingConstants'
import React, { useEffect, useMemo, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import UpgradeReminder from '../upgrade-reminder/UpgradeReminder'

interface SubscriptionContextProps {
  children?: React.ReactNode
}

interface ISubscriptionContext {
  showUpgradeReminder: boolean
  subscription?: Subscription
}

export const SubscriptionContext =
  React.createContext<ISubscriptionContext | null>(null)

const SubscriptionContextProvider = (props: SubscriptionContextProps) => {
  const { authenticationStore } = useRootStore()
  const [subscription, setSubscription] = useState<Subscription>()

  const getSubscription = async () => {
    const response = await fetch(
      NetworkingConstants.FABRIC_HOST +
        `/organisation/${authenticationStore.fabricOrganisationIdentifier}/application/${APPLICATION_IDENTIFIER}/subscription?applicationAccessToken=${authenticationStore.applicationAccessToken}`,
      { method: 'GET', headers: { 'Content-Type': 'application/json' } }
    )
    const body = await response.json()
    if (!response.ok || !body.subscription) return

    setSubscription(new Subscription(body.subscription))
  }

  const showUpgradeReminder = !!subscription && subscription.isTrialing
  const subscriptionContext = useMemo(
    () => ({
      showUpgradeReminder,
      subscription,
    }),
    [showUpgradeReminder, subscription]
  )

  useEffect(() => {
    if (authenticationStore.isAuthenticated) getSubscription()
  }, [authenticationStore.isAuthenticated])

  return (
    <SubscriptionContext.Provider value={subscriptionContext}>
      {props.children}
      {showUpgradeReminder && <UpgradeReminder subscription={subscription} />}
    </SubscriptionContext.Provider>
  )
}

export default observer(SubscriptionContextProvider)
