import { KeyValueStore } from '@nextbusiness/infinity-ui'

export const accountIllustrations: KeyValueStore = {
  1000: 'cash.svg',
  1020: 'bank-building.svg',
  1100: 'payment-history.svg',
  1109: 'expired.svg',
  1170: 'tax.svg',
  1171: 'tax.svg',
  1176: 'tax.svg',
  1200: 'package-delivery-logistics.svg',
  1210: 'coal.svg',
  1300: 'time-span.svg',
  1301: 'time-span.svg',
  1400: 'bonds.svg',
  1480: 'chart.svg',
  1500: 'cogs.svg',
  1509: 'decline.svg',
  1510: 'office.svg',
  1519: 'decline.svg',
  1520: 'home-office.svg',
  1529: 'decline.svg',
  1530: 'car.svg',
  1539: 'decline.svg',
  1540: 'full-tool-storage-box.svg',
  1549: 'decline.svg',
  1600: 'skyscrapers.svg',
  1609: 'decline.svg',
  1700: 'creativity.svg',
  2000: 'order-history.svg',
  2100: 'debt.svg',
  2200: 'tax.svg',
  2300: 'time-span.svg',
  2301: 'time-span.svg',
  2330: 'umbrella.svg',
  2400: 'debt.svg',
  2420: 'debt.svg',
  2450: 'debt.svg',
  2500: 'debt.svg',
  2600: 'umbrella.svg',
  2800: 'chart.svg',
  2900: 'safe.svg',
  2950: 'safe.svg',
  2970: 'profit.svg',
  2979: 'profit.svg',
  3000: 'craft-work.svg',
  3200: 'basket.svg',
  3400: 'service-bell.svg',
  3600: 'tips.svg',
  3805: 'financial-changes.svg',
  3900: 'financial-changes.svg',
  3901: 'financial-changes.svg',
  3940: 'financial-changes.svg',
  4000: 'buy.svg',
  4200: 'buy.svg',
  4400: 'service-bell.svg',
  4500: 'transmission-tower.svg',
  5000: 'badge.svg',
  5800: 'badge.svg',
  6000: 'skyscrapers.svg',
  6100: 'maintenance.svg',
  6200: 'truck.svg',
  6400: 'recycling.svg',
  6500: 'administrative-tools.svg',
  6570: 'workstation.svg',
  6600: 'marketing.svg',
  6700: 'accounting.svg',
  6800: 'decline.svg',
  6900: 'estimate.svg',
  6950: 'estimate.svg',
  8000: 'view-more.svg',
  8100: 'view-more.svg',
  8500: 'view-more.svg',
  8510: 'view-more.svg',
  8900: 'tax.svg',
  9200: 'project-setup.svg',
}
