import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'

const TemplatePreviewTable = () => {
  const { preferencesStore } = useRootStore()
  return (
    <div className='page-positions'>
      <table>
        <thead>
          <tr>
            <th>Menge</th>
            <th>Beschreibung</th>
            <th>Einzelpreis</th>
            <th>Gesamtpreis</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>30</td>
            <td>Dies ist eine Beispielposition 1</td>
            <td>100.00 CHF</td>
            <td>3'000.00 CHF</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Dies ist eine Beispielposition 2</td>
            <td>30.00 CHF</td>
            <td>300.00 CHF</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={3}>
              {preferencesStore.organisationPreferences?.VAT
                ? 'Gesamttotal inkl. MWST'
                : 'Gesamttotal (MWST-befreit)'}
            </th>
            <th>3'300.00 CHF</th>
          </tr>
        </tfoot>
      </table>
      <div className='example-hint'>
        Dies ist ein Beispiel, wie Positionen in Forderungen und Offerten
        aussehen werden.
      </div>
    </div>
  )
}

export default observer(TemplatePreviewTable)
