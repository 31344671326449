import { Card, Flex } from '@nextbusiness/infinity-ui'
import { useQuery } from '@tanstack/react-query'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import classNames from 'classnames'
import { BackendError, ErrorType } from 'libs/networking/Errors'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import CustomerInvoices from 'networking/CustomerInvoices'
import Templates from 'networking/Templates'
import { useParams } from 'react-router-dom'
import { useRootStore } from 'stores/RootStoreContext'
import { useIsTouchDevice } from 'utility/hooks'
import CustomerInvoiceFactory from '../editor/CustomerInvoiceFactory'
import DocumentElementLogo from '../editor/document-renderer/elements/DocumentElementLogo'
import PublicInvoiceContent from './PublicInvoiceContent'
import PublicInvoiceFooter from './PublicInvoiceFooter'
import PublicInvoiceHeader from './PublicInvoiceHeader'
import './PublicInvoicePage.scss'
import PublicInvoicePageError from './PublicInvoicePageError'
import PublicInvoiceSidebar from './sidebar/PublicInvoiceSidebar'

const PublicInvoicePage = () => {
  const { preferencesStore } = useRootStore()
  const { id } = useParams<{ id: string }>()
  const isMobileDevice = useIsTouchDevice()

  const retrieveInvoiceAndTemplate = async () => {
    const [invoice, vatSettings, invoiceNumber] =
      await CustomerInvoices.retrievePublicInvoiceOrQuote(id)
    const document = CustomerInvoiceFactory.fromInvoice(invoice)
    const template = await Templates.getTemplate(invoice.template)
    runInAction(() => {
      preferencesStore.organisationPreferences = vatSettings
    })
    return { invoice, document, template, invoiceNumber }
  }

  const { data, isPending, error } = useQuery({
    queryKey: ['invoice', id],
    queryFn: retrieveInvoiceAndTemplate,
    retry(failureCount, error) {
      if ((error as BackendError).type === ErrorType.NotFound) return false
      return failureCount < 3
    },
  })

  if (isPending)
    return (
      <div className='public-invoice-page loading'>
        <LoadingScreen />
      </div>
    )
  if (error) return <PublicInvoicePageError />

  return (
    <Flex
      className={classNames('public-invoice-page', {
        'mobile-device': isMobileDevice,
      })}
      direction='vertical'
      verticalAlignment='center'
      gap={3.4}
    >
      <Card className='public-invoice' bare>
        <Flex className='invoice-main' gap={5.4} direction='vertical'>
          {data.template.logo && (
            <DocumentElementLogo
              document={data.document}
              template={data.template}
              registerEdit={() => {}}
              alwaysVisible
            />
          )}
          <Flex className='invoice-inner' gap={3.2} direction='vertical'>
            <PublicInvoiceHeader invoice={data.invoice} />
            {isMobileDevice && (
              <PublicInvoiceSidebar
                shareId={id}
                invoice={data.invoice}
                invoiceNumber={data.invoiceNumber}
                template={data.template}
              />
            )}
            <PublicInvoiceContent
              document={data.document}
              invoice={data.invoice}
              template={data.template}
            />
          </Flex>
        </Flex>
        {!isMobileDevice && (
          <PublicInvoiceSidebar
            shareId={id}
            invoice={data.invoice}
            invoiceNumber={data.invoiceNumber}
            template={data.template}
          />
        )}
      </Card>
      <PublicInvoiceFooter
        organisationName={data.template.organisationName}
        isQuote={data.invoice.isQuote}
      />
    </Flex>
  )
}

export default observer(PublicInvoicePage)
