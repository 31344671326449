import { IAccount } from 'model/Account'
import AccountDetailTransactionGroup from './AccountDetailTransactionGroup'
import './AccountDetailTransactionGroupList.scss'
import { ITransactionGroup } from './StringSimilarity'

interface AccountDetailTransactionGroupListProps {
  account: IAccount
  groups: ITransactionGroup[]
}

const AccountDetailTransactionGroupList = (
  props: AccountDetailTransactionGroupListProps
) => {
  return (
    <div className='account-detail-report-groups'>
      {props.groups.map((group) => (
        <AccountDetailTransactionGroup
          group={group}
          account={props.account}
          key={group.id}
          maxGroupSum={props.groups[0].sum}
        />
      ))}
    </div>
  )
}

export default AccountDetailTransactionGroupList
