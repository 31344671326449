import * as Sentry from '@sentry/react'
import useSubscription from 'components/billing/subscription-context/useSubscription'
import mixpanel from 'mixpanel-browser'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import MixpanelAnalytics from './MixpanelProductAnalytics'

interface MixpanelProviderProps {
  children: React.ReactNode
}

const MixpanelProvider = (props: MixpanelProviderProps) => {
  const { authenticationStore, preferencesStore } = useRootStore()

  const { isAuthenticated, user } = authenticationStore
  const organisation = preferencesStore.organisationPreferences
  const subscription = useSubscription()

  useEffect(() => {
    if (!MixpanelAnalytics.shouldInitialise) return
    if (!isAuthenticated || !user) return

    try {
      mixpanel.identify(user.id)
      mixpanel.people.set({
        trialing: subscription?.isTrialing,
        plan: subscription?.tier.title,
        legalForm: organisation?.legalType,
        vatEnabled: organisation?.VAT,
      })
    } catch (error: any) {
      // Silently handle Mixpanel initialisation errors
      Sentry.captureException(error)
    }
  }, [isAuthenticated, user, subscription, organisation])

  return <>{props.children}</>
}

export default observer(MixpanelProvider)
