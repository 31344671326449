import Lockup from 'components/text/Lockup'
import TransactionLiveAcountingDetailProps from './TransactionLiveAcountingDetailProps'

const LiveAccountingAdditionalInfo = (
  props: TransactionLiveAcountingDetailProps
) => {
  if (
    !props.details.additionalTransactionInformation &&
    !props.details.additionalEntryInformation
  )
    return null

  return (
    <Lockup
      icon='chat-bubble'
      tooltip='Zusätzliche Information'
      small
      subtle
      truncate
    >
      {props.details.additionalTransactionInformation ??
        props.details.additionalEntryInformation}
    </Lockup>
  )
}

export default LiveAccountingAdditionalInfo
