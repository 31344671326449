import ContentLoader from 'react-content-loader'

const LoaderFinancialHealthIncomeOrExpense = () => (
  <ContentLoader
    speed={2}
    width={344}
    height={230}
    viewBox='0 0 344 230'
    backgroundColor='#f3f3f3'
    foregroundColor='#e5e5e5'
  >
    <rect x='1' y='19' rx='3' ry='3' width='104' height='15' />
    <rect x='1' y='44' rx='3' ry='3' width='140' height='20' />
    <rect x='161' y='44' rx='3' ry='3' width='60' height='20' />
    <rect x='1' y='78' rx='3' ry='3' width='253' height='16' />

    <rect x='0' y='118' rx='3' ry='3' width='12' height='12' />
    <rect x='30' y='117' rx='3' ry='3' width='34' height='15' />
    <rect x='78' y='117' rx='3' ry='3' width='146' height='15' />
    <rect x='239' y='117' rx='3' ry='3' width='96' height='15' />

    <rect x='0' y='147' rx='3' ry='3' width='12' height='12' />
    <rect x='30' y='146' rx='3' ry='3' width='34' height='15' />
    <rect x='78' y='146' rx='3' ry='3' width='146' height='15' />
    <rect x='239' y='146' rx='3' ry='3' width='96' height='15' />

    <rect x='0' y='177' rx='3' ry='3' width='12' height='12' />
    <rect x='30' y='176' rx='3' ry='3' width='34' height='15' />
    <rect x='78' y='176' rx='3' ry='3' width='146' height='15' />
    <rect x='239' y='176' rx='3' ry='3' width='96' height='15' />

    <rect x='0' y='206' rx='3' ry='3' width='12' height='12' />
    <rect x='30' y='205' rx='3' ry='3' width='34' height='15' />
    <rect x='78' y='205' rx='3' ry='3' width='146' height='15' />
    <rect x='239' y='205' rx='3' ry='3' width='96' height='15' />
  </ContentLoader>
)

export default LoaderFinancialHealthIncomeOrExpense
