import { Flex } from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { ReactComponent as IndicatorSuccess } from '../../../../assets/indicator-success.svg'
import { ReactComponent as IndicatorWarning } from '../../../../assets/indicator-warning.svg'
import RadialGauge from '../../../../components/gauges/RadialGauge'
import MagicSheetPage from '../../../../components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from '../../../../components/magic-sheet/MagicSheetTitleBar'
import { useRootStore } from '../../../../stores/RootStoreContext'
import { FinancialHealthGroupStatus } from '../../status/FinancialHealthStatusGroup'
import './FinancialHealthStatusDetailPage.scss'

const FinancialHealthStatusDetailPage = () => {
  const intercom = useIntercom()

  const { reportingStore } = useRootStore()
  const status = reportingStore.status()

  useEffect(() => {
    intercom.trackEvent('financial-health-status-viewed')
    MixpanelAnalytics.event('Report viewed: Hints and warnings')
  }, [])

  return (
    <MagicSheetPage
      className='financial-health-detail-page page-status'
      header={<MagicSheetTitleBar title='Hinweise und Probleme' />}
    >
      <div className='status-overview'>
        <Flex fillContainer verticalAlignment='flex-start'>
          <div className='status-gauge'>
            <RadialGauge
              level={status.summaryStatusGauge}
              label={status.summaryStatusLabel}
              size='large'
              coloured
            />
          </div>
          <div className='status-details'>
            {status.metricGroups.map((group) => {
              const groupStatus = group.getOverallStatus()
              return (
                <div className='status-group' key={group.title}>
                  <div className='group-header'>
                    <div className='indicator'>
                      {groupStatus === FinancialHealthGroupStatus.Perfect ? (
                        <IndicatorSuccess />
                      ) : (
                        <IndicatorWarning />
                      )}
                    </div>
                    <div className='title'>{group.title}</div>
                  </div>
                  <div className='group-metrics'>
                    {group.metrics
                      .filter((metric) => metric.isRelevant())
                      .map((metric) => (
                        <div
                          className={`group-metric ${metric.assessmentClassName()}`}
                          key={metric.title}
                        >
                          <div className='metric-depiction'>
                            <div className='metric-title'>{metric.title}</div>
                            <div className='metric-description'>
                              {metric.description}
                            </div>
                          </div>
                          <div className='metric-value'>
                            <span>{metric.getActualValue()}</span>
                            {metric.getTarget?.() && (
                              <small className='metric-target'>
                                <Icon icon='target' size={14} />
                                <Link to='/optionen/profitabilitaet'>
                                  Ziel:{' '}
                                </Link>
                                {metric.getTarget()}
                              </small>
                            )}
                          </div>
                          <div className='metric-status'>
                            <span>{metric.getStatusText()}</span>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )
            })}
          </div>
        </Flex>
      </div>
    </MagicSheetPage>
  )
}

export default observer(FinancialHealthStatusDetailPage)
