import './GrowthIndicator.scss'
import GrowthIndicatorIcon from './GrowthIndicatorIcon'

interface GrowthIndicatorProps {
  change?: number | null
  values?: { new: number; old: number }
}

const GrowthIndicator = (props: GrowthIndicatorProps) => {
  if (!props.change && !props.values) return null
  const change =
    (props.change ?? props.values!.new / props.values!.old - 1) * 100
  const sign = change > 0 ? '+' : ''

  return (
    <span className='growth-indicator'>
      <GrowthIndicatorIcon change={change} />
      {sign}
      {change.toFixed(1)}%
    </span>
  )
}

export default GrowthIndicator
