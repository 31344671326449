import { Finance } from '@nextbusiness/infinity-finance-api'
import { Dialog, Text } from '@nextbusiness/infinity-ui'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import useFileDownload from 'utility/hooks/useFileDownload'

interface DownloadJournalDialogProps {
  isOpen: boolean
  onDismiss: () => void
}

const DownloadJournalDialog = (props: DownloadJournalDialogProps) => {
  const { authenticationStore } = useRootStore()
  const { download: downloadJournal, isPending } = useFileDownload(
    Finance.Ledger.ledgerJournalAsCsv,
    {
      fileName: `Buchungsjournal ${authenticationStore.currentOrganisationName}.csv`,
    }
  )

  return (
    <Dialog
      title='Buchungsjournal herunterladen'
      icon='journal'
      actions={[
        {
          children: 'Abbrechen',
          onClick: props.onDismiss,
        },
        {
          children: 'Als CSV-Datei herunterladen',
          variant: 'primary',
          isLoading: isPending,
          onClick: async () => {
            MixpanelAnalytics.event('Journal export downloaded')
            await downloadJournal()
            props.onDismiss()
          },
        },
      ]}
      initialFocusIndex={1}
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      dismissable
      style={{ maxWidth: '54rem' }}
    >
      <Text>
        Das Buchungsjournal beinhaltet alle Transaktionen aus allen Konten,
        absteigend nach Datum sortiert.
      </Text>
      <Text>
        Das heruntergeladene Journal ist im CSV-Format, welches problemlos in
        Excel oder ähnlichen Programmen geöffnet werden kann.
      </Text>
    </Dialog>
  )
}

export default observer(DownloadJournalDialog)
