import { Flex, LoadingIndicator } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react-lite'
import { Redirect } from 'react-router'
import { useRootStore } from '../stores/RootStoreContext'

interface StartPageProps {
  redirectToOnceReady: string
}

const StartPage = (props: StartPageProps) => {
  const { accountStore } = useRootStore()

  return (
    <Flex fillContainer verticalAlignment='center' horizontalAlignment='center'>
      <LoadingIndicator />
      {accountStore.currentFiscalYear && (
        <Redirect to={props.redirectToOnceReady} />
      )}
      {accountStore.noFiscalYearAvailable && <Redirect to='/setup' />}
    </Flex>
  )
}

export default observer(StartPage)
