import NavigatableMagicSheet from 'components/magic-sheet/NavigatableMagicSheet'
import ArchivedProjectsView from 'projects/ArchivedProjectsView'
import ProjectBoardPage from 'projects/board/ProjectBoardPage'
import ProjectView from 'projects/project-view/ProjectView'

export enum ProjectPagePaths {
  ProjectsBoard = '/projekte',
  ArchivedProjects = '/projekte/archiviert',
  Project = '/projekte/detail/:param',
  ArchivedProject = '/projekte/archiviert/:param',
}

class ProjectPage extends NavigatableMagicSheet {
  className = 'project-page-stack'
  basePath = ProjectPagePaths.ProjectsBoard

  protected registerPaths(): void {
    this.registerView({
      url: ProjectPagePaths.ProjectsBoard,
      title: () => 'Projekte',
      view: <ProjectBoardPage page={this} />,
    })
    this.registerView({
      url: ProjectPagePaths.ArchivedProjects,
      title: () => 'Archivierte Projekte',
      view: <ArchivedProjectsView openProject={this.openArchivedProject} />,
    })
    this.registerView({
      url: ProjectPagePaths.Project,
      title: () => 'Projekt',
      view: (
        <ProjectView
          projectPage={this}
          dismissView={() => this.popCurrentView()}
        />
      ),
    })
    this.registerView({
      url: ProjectPagePaths.ArchivedProject,
      title: () => 'Archiviertes Projekt',
      view: (
        <ProjectView
          projectPage={this}
          dismissView={() => this.popCurrentView()}
        />
      ),
    })
  }

  openArchivedProject = (withId: string) => {
    this.open(ProjectPagePaths.ArchivedProject, withId)
  }

  open(path: ProjectPagePaths, param?: string) {
    if (param) return this.props.history.push(path.replace(':param', param))
    this.props.history.push(path)
  }
}

export default ProjectPage
