import classNames from 'classnames'
import { inject } from 'mobx-react'
import { Component } from 'react'
import DocumentCustomProductPosition from './DocumentCustomProductPosition'
import DocumentDiscountPosition from './DocumentDiscountPosition'
import DocumentPositionDraggable from './DocumentPositionDraggable'
import { DocumentPositionRootProps } from './DocumentPositionRootProps'
import DocumentTextLinePosition from './DocumentTextLinePosition'
import DocumentPositionFlyout from './flyout/DocumentPositionFlyout'

interface DocumentPositionState {
  isFlyoutOpen?: boolean
  shouldSuppressFlyout?: boolean
}

@inject('preferencesStore')
class DocumentPosition extends Component<
  DocumentPositionRootProps,
  DocumentPositionState
> {
  state: DocumentPositionState = {
    isFlyoutOpen: false,
  }

  setSuppressFlyout = (suppressFlyout: boolean) => {
    this.setState({ shouldSuppressFlyout: suppressFlyout })
  }

  renderPositionInner = () => {
    const { position, index } = this.props
    switch (position.type) {
      case 'custom-product':
        return (
          <DocumentCustomProductPosition
            key={position.identifier}
            position={position}
            onChange={(changes) =>
              this.props.onChangePosition(position.identifier, changes)
            }
            index={index}
            isReadOnly={this.props.isReadOnly}
            columns={this.props.columns}
            amountMode={this.props.amountMode}
            setSuppressFlyout={this.setSuppressFlyout}
            openingDate={this.props.openingDate}
            vatPresentationOptions={this.props.vatPresentationOptions}
            isQuote={this.props.isQuote}
          />
        )
      case 'text-line':
        return (
          <DocumentTextLinePosition
            key={position.identifier}
            position={position}
            onChange={(changes) =>
              this.props.onChangePosition(position.identifier, changes)
            }
            index={index}
            isReadOnly={this.props.isReadOnly}
          />
        )
      case 'discount':
        return (
          <DocumentDiscountPosition
            key={position.identifier}
            position={position}
            onChange={(changes) =>
              this.props.onChangePosition(position.identifier, changes)
            }
            index={index}
            isReadOnly={this.props.isReadOnly}
          />
        )
    }
  }

  renderFlyout = () => (
    <DocumentPositionFlyout
      {...this.props}
      trigger={this.renderPositionInner()}
      isActive={Boolean(
        this.state.isFlyoutOpen && !this.state.shouldSuppressFlyout
      )}
      setIsActive={(isFlyoutOpen) => this.setState({ isFlyoutOpen })}
    />
  )

  render = () => {
    return (
      <DocumentPositionDraggable
        className={classNames({
          active: this.state.isFlyoutOpen && !this.state.shouldSuppressFlyout,
        })}
        id={this.props.position.identifier}
        index={this.props.index}
        template={this.props.template}
        position={this.props.position}
        isReadOnly={this.props.isReadOnly}
      >
        {this.props.isReadOnly ? (
          <div className='position-row-inner'>{this.renderPositionInner()}</div>
        ) : (
          <>
            <div
              className='position-drag-handle'
              onClick={() =>
                this.setState({ isFlyoutOpen: !this.state.isFlyoutOpen })
              }
              aria-hidden='true'
            />
            {this.renderFlyout()}
          </>
        )}
      </DocumentPositionDraggable>
    )
  }
}

export default DocumentPosition
