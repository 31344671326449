import { Flex, RadioGroup } from '@nextbusiness/infinity-ui'
import ColourInput from 'components/colour-input/ColourInput'
import { useEffect, useState } from 'react'
import { useIsFirstRender } from 'utility/hooks'
import { TemplateInspectorProps } from '../TemplateInspector'
import TemplateInspectorGroup from '../TemplateInspectorGroup'
import './TemplateInspectorAccentColours.scss'

const TemplateInspectorAccentColours = (props: TemplateInspectorProps) => {
  const isFirstRender = useIsFirstRender()
  const firstColour = props.template.colourValues[0]
  const secondColour = props.template.colourValues[1]

  const initalStyle = () => {
    if (props.template.disableDecorativeGradient) return 'none'
    if (firstColour === secondColour) return 'single'
    return 'gradient'
  }
  const [accentStyle, setAccentStyle] = useState<
    'none' | 'single' | 'gradient'
  >(initalStyle())

  useEffect(() => {
    if (!isFirstRender) {
      if (accentStyle === 'none') {
        props.modifyTemplate({ disableDecorativeGradient: true })
      } else {
        props.modifyTemplate({ disableDecorativeGradient: false })
        if (accentStyle === 'single') {
          props.modifyTemplate({
            colourValues: [firstColour, firstColour],
          })
        }
      }
    }
  }, [accentStyle])

  return (
    <TemplateInspectorGroup className='template-inspector-accent-colours'>
      <RadioGroup
        options={[
          { label: 'Keine', value: 'none' },
          { label: 'Einfarbig', value: 'single' },
          { label: 'Farbverlauf', value: 'gradient' },
        ]}
        value={accentStyle}
        onChange={setAccentStyle}
      />
      {accentStyle !== 'none' && (
        <Flex className='colour-inputs' gap={1.5}>
          <ColourInput
            selectedColour={firstColour}
            setSelectedColour={(colour) =>
              props.modifyTemplate({
                colourValues: [
                  colour,
                  accentStyle === 'single' ? colour : secondColour,
                ],
              })
            }
          />
          {accentStyle === 'gradient' && (
            <ColourInput
              selectedColour={secondColour}
              setSelectedColour={(colour) =>
                props.modifyTemplate({
                  colourValues: [firstColour, colour],
                })
              }
            />
          )}
        </Flex>
      )}
    </TemplateInspectorGroup>
  )
}

export default TemplateInspectorAccentColours
