import InvoiceDateFilter from 'invoices/shared-components/filters/InvoiceDateFilter'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import { useCustomerInvoicesFilter } from './CustomerInvoicesFilterContext'

const CustomerInvoiceDateFilter = () => {
  const { customerInvoiceStore } = useRootStore()
  const { activeFilter, editFilter } = useCustomerInvoicesFilter()

  const allDocuments = customerInvoiceStore.allDocuments

  return (
    <InvoiceDateFilter
      invoices={allDocuments}
      selectedValue={activeFilter.openingMonth}
      onValueSelected={(openingMonth) => editFilter({ openingMonth })}
    />
  )
}

export default observer(CustomerInvoiceDateFilter)
