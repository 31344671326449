import { ClosingTransactionsPreview } from 'model/financial-close/ClosingTransactionPreview'
import './ClosingTransactionPreviewList.scss'
import ClosingTransactionsListGroup from './ClosingTransactionsListGroup'

interface ClosingTransactionPreviewListProps {
  closingTransactions: ClosingTransactionsPreview
}

const ClosingTransactionPreviewList = (
  props: ClosingTransactionPreviewListProps
) => (
  <div className='transaction-preview-list'>
    <ClosingTransactionsListGroup
      title='Abgrenzungen'
      transactions={props.closingTransactions.accruals}
    />
    <ClosingTransactionsListGroup
      title='Abschreibungen'
      transactions={props.closingTransactions.depreciations}
    />
    <ClosingTransactionsListGroup
      title='Erfolgsverbuchung'
      transactions={props.closingTransactions.profitClose}
    />
  </div>
)

export default ClosingTransactionPreviewList
