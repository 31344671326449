import { AccountMethod } from '@nextbusiness/infinity-finance-api'
import { Button, Flex, Heading } from '@nextbusiness/infinity-ui'
import Lockup from 'components/text/Lockup'
import { useLedgerContext } from 'ledger/LedgerContext'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import LedgerStore from 'stores/LedgerStore'
import { useRootStore } from 'stores/RootStoreContext'
import './LedgerOnboardingScreen.scss'
import LedgerOnboardingTask from './LedgerOnboardingTask'

interface LedgerOnboardingScreenProps {
  openLedger: () => void
}

const LedgerOnboardingScreen = (props: LedgerOnboardingScreenProps) => {
  const { accountStore, preferencesStore, ledgerStore } = useRootStore()
  const ledgerContext = useLedgerContext()

  const defaultBankAccount = accountStore.find(
    LedgerStore.INITIAL_DEFAULT_ROOT_VIEW
  )

  useEffect(() => {
    // Reset to default Bank account page when the onboarding is visible, so
    // Live Accounting setup will work correctly.
    ledgerStore.setCurrentRootView(LedgerStore.INITIAL_DEFAULT_ROOT_VIEW)
  }, [])

  return (
    <div className='ledger-onboarding'>
      <div className='ledger-onboarding-header'>
        <div className='logo' />
        <Heading type='h2' bare>
          Buchhaltung einrichten
        </Heading>
      </div>
      <div className='ledger-onboarding-tasks'>
        <Heading type='h3'>Einrichten</Heading>
        <LedgerOnboardingTask
          title='Konto einrichten'
          description='Richte dein Konto mit Rechtsform, Geschäftsjahr und Mehrwertsteuer ein.'
          isDone
        />
        <LedgerOnboardingTask
          title='Buchhaltung eröffnen'
          description='Trage die Anfangsbestände für deine Buchhaltung ein, um zu beginnen.'
          actionText='Einrichten'
          onClick={() => ledgerContext.openSetupAccountBalancesPage()}
          isDone={
            preferencesStore.organisationPreferences?.metadata
              ?.ledgerAccountBalancesSetupCompleted === true
          }
        />
        <LedgerOnboardingTask
          title='Kontenplan einrichten'
          description='Passe die Aufwände und Erträge falls nötig an dein Geschäft an.'
          actionText='Einrichten'
          actionTextAfterDone='Anpassen'
          onClick={() => {
            ledgerContext.openChartOfAccountsPage()
            preferencesStore.setFlags({
              ledgerChartOfAccountsSetupCompleted: true,
            })
          }}
          isDone={
            preferencesStore.organisationPreferences?.metadata
              ?.ledgerChartOfAccountsSetupCompleted === true
          }
          canBeDoneAgain
        />
        <Heading type='h3'>Buchen</Heading>
        {defaultBankAccount && (
          <LedgerOnboardingTask
            title='Bankkonto verbinden'
            description='Erhalte mit Live Accounting automatische AI-Buchungsvorschläge.'
            actionText='Einrichten'
            onClick={() =>
              ledgerContext.openSetupLiveAccountingPage(defaultBankAccount)
            }
            isDone={defaultBankAccount.accountMethod === AccountMethod.Live}
          />
        )}
        <Lockup icon='info' gap='small' cushion='small'>
          Wenn du dein Bankkonto nicht verbinden möchtest, kannst du auch
          manuell buchen oder später ein Konto verbinden.
        </Lockup>
        <Flex horizontalAlignment='flex-end'>
          <Button
            onClick={() => {
              props.openLedger()
              preferencesStore.setFlags({ ledgerConnectionStepCompleted: true })
            }}
          >
            Ohne Bankkonto fortfahren
          </Button>
        </Flex>
      </div>
    </div>
  )
}

export default observer(LedgerOnboardingScreen)
