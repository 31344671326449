import { Dialog, FeedbackBanner, Flex, Text } from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import VATPicker from 'components/vat/vat-picker/VATPicker'
import AccountSelect from 'ledger/accounts/account-select/AccountSelect'
import { ResolvedSetOfSuggestedAccounts } from 'ledger/accounts/data/AccountRules'
import { observer } from 'mobx-react'
import {
  INewCustomProductPosition,
  INewCustomerInvoice,
} from 'model/CustomerInvoice'
import { useRootStore } from 'stores/RootStoreContext'
import { truncateString } from 'utility/Utilities'
import './CustomerInvoiceAskForAccountsDialog.scss'
import TaxUtilities from 'utility/TaxUtilities'
import { TaxCode } from '@nextbusiness/infinity-finance-api'

interface CustomerInvoiceAskForAccountsDialogProps {
  isOpen: boolean
  onDismiss: () => void
  document: Partial<INewCustomerInvoice>
  registerEdit: (
    changes: Partial<INewCustomerInvoice>,
    shouldSave?: boolean,
    saveMessage?: string
  ) => void
  save: (saveMessage?: string) => void
}

const suggestedAccounts: ResolvedSetOfSuggestedAccounts = [
  { title: 'Erträge', accounts: ['income'] },
]

const CustomerInvoiceAskForAccountsDialog = (
  props: CustomerInvoiceAskForAccountsDialogProps
) => {
  const { preferencesStore, accountStore } = useRootStore()
  const isVATEnabled = preferencesStore.organisationPreferences?.VAT

  const documentTypeName = props.document.isQuote ? 'Offerte' : 'Forderung'

  const customProductPositions =
    (props.document?.positions?.filter(
      (position) => position.type === 'custom-product'
    ) as INewCustomProductPosition[]) ?? []

  const canContinue = !customProductPositions.some(
    (position) => !position.contraAccount
  )
  const CANCEL_BUTTON_FOCUS_INDEX =
    customProductPositions.length * (isVATEnabled ? 2 : 1)

  return (
    <Dialog
      initialFocusIndex={CANCEL_BUTTON_FOCUS_INDEX}
      className={classNames('customer-invoice-ask-for-accounts-dialog', {
        'with-vat': isVATEnabled,
      })}
      title='Ertragskonten auswählen'
      titleProps={{ divider: true }}
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      actions={[
        {
          children: 'Abbrechen',
          onClick: props.onDismiss,
        },
        {
          children: documentTypeName + ' stellen',
          variant: 'primary',
          disabled: !canContinue,
          onClick: () => {
            props.save(
              documentTypeName +
                ' wurde gestellt.' +
                (props.document.isQuote
                  ? ''
                  : ' Sie wird nun in deinen Auswertungen erscheinen.')
            )
            props.onDismiss()
          },
        },
      ]}
    >
      <Text className='instructions'>
        Wähle für jede Position ein passendes Ertragskonto aus.
      </Text>
      <div className='positions'>
        {customProductPositions.map((position) => {
          const [firstTextLine] = position.name?.split('\n') ?? ['']
          const updatePosition = (
            changes: Partial<INewCustomProductPosition>
          ) => {
            const updatedPositions = [...(props.document.positions ?? [])]
            const updatedPosition = { ...position, ...changes }
            updatedPositions[updatedPositions.indexOf(position)] =
              updatedPosition
            props.registerEdit({ positions: updatedPositions })
          }

          return (
            <Flex
              key={position.identifier}
              verticalAlignment='center'
              className='position'
            >
              <Text className='position-name'>
                {truncateString(firstTextLine, 72)}
              </Text>
              <Icon icon='arrow-right' size={20} />
              <div className='position-account'>
                <AccountSelect
                  currentAccountNumber={1100}
                  initialAccountNumber={position.contraAccount}
                  onChange={(contraAccount) =>
                    updatePosition({
                      contraAccount,
                      taxCode:
                        TaxUtilities.autoSuggestVAT(
                          {
                            number: contraAccount,
                            account: accountStore.find(contraAccount),
                          },
                          preferencesStore.organisationPreferences,
                          props.document.openingDate
                        ) ?? undefined,
                    })
                  }
                  suggestions={suggestedAccounts}
                  onlyAllowSuggestedAccounts
                  placeholderText='Ertragskonto'
                  allowAccountCreation
                />
              </div>
              {preferencesStore.organisationPreferences?.VAT && (
                <div className='position-vat'>
                  <VATPicker
                    taxCode={(position.taxCode ?? '') as TaxCode}
                    onTaxCodeSelected={(taxCode) => updatePosition({ taxCode })}
                    hideNoneOption
                  />
                </div>
              )}
            </Flex>
          )
        })}
      </div>
      {!canContinue && (
        <FeedbackBanner>
          {isVATEnabled
            ? `Einige Positionen in deiner ${documentTypeName} haben noch kein Konto oder Mehrwertsteuer.`
            : `Einige Positionen in deiner ${documentTypeName} haben noch kein Konto.`}
        </FeedbackBanner>
      )}
    </Dialog>
  )
}

export default observer(CustomerInvoiceAskForAccountsDialog)
