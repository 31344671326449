import { Finance } from '@nextbusiness/infinity-finance-api'
import { useNotificationCenter } from '@nextbusiness/infinity-ui'
import { useQuery } from '@tanstack/react-query'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import DocumentAttachmentsError from './DocumentAttachmentsError'
import DocumentUpload from './DocumentUpload'
import DocumentUploadPreview from './DocumentUploadPreview'
import IDocument from 'model/Document'

interface DocumentAttachmentsProps {
  attachmentIds: string[] | undefined
  invoiceId: string | null
  onUpload: (uploadedDocument: IDocument) => void
  disableAnalysisOnUpload?: boolean
}

const DocumentAttachments = (props: DocumentAttachmentsProps) => {
  const attachmentId = props.attachmentIds?.first()
  const notificationCenter = useNotificationCenter()
  const [isAttaching, setIsAttaching] = useState<boolean>(false)

  const {
    data: attachment,
    isPending,
    error,
    refetch,
  } = useQuery({
    queryKey: ['attachment', attachmentId],
    queryFn: async () => {
      if (!attachmentId) return null
      return Finance.Documents.document(attachmentId)
    },
  })

  const attachDocumentToInvoice = async (document: IDocument) => {
    if (!attachmentId && props.invoiceId) {
      setIsAttaching(true)
      try {
        await Finance.Documents.attachDocumentToInvoice(
          document.id,
          props.invoiceId
        )
        refetch()
      } catch (attachingError: any) {
        notificationCenter.addNotification({
          title: 'Beleg konnte nicht an Rechnung angehängt werden.',
          children:
            attachingError.message ?? 'Bitte versuche es später erneut.',
          variant: 'error',
        })
        return
      } finally {
        setIsAttaching(false)
      }
    }
    props.onUpload(document)
  }

  if (isPending || isAttaching) {
    return <LoadingScreen />
  }
  if (error) {
    return <DocumentAttachmentsError retry={refetch} />
  }

  return attachment ? (
    <DocumentUploadPreview document={attachment} />
  ) : (
    <DocumentUpload
      onDocumentUploaded={attachDocumentToInvoice}
      notificationCenter={notificationCenter}
      disableAnalysisOnUpload={props.disableAnalysisOnUpload}
    />
  )
}

export default observer(DocumentAttachments)
