import { Dialog, Text } from '@nextbusiness/infinity-ui'
import { isBackendError } from 'libs/networking/Errors'

interface ClosingErrorModalProps {
  error: Error
  isOpen?: boolean
  onDismiss: () => void
}

const ClosingErrorModal = (props: ClosingErrorModalProps) => {
  const { error, ...dialogProps } = props
  return (
    <Dialog
      className='closing-error-modal'
      icon='error'
      title='Geschäftsjahr konnte nicht abgeschlossen werden'
      isOpen={false}
      {...dialogProps}
    >
      <Text type='paragraph'>
        Beim Durchführen des Jahresabschluss ist ein Fehler aufgetreten.
      </Text>
      <Text type='paragraph'>
        {isBackendError(error) ? error.displayMessage : error.message}
      </Text>
    </Dialog>
  )
}

export default ClosingErrorModal
