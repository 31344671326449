import { useDroppable } from '@dnd-kit/core'
import { IProjectStage } from '@nextbusiness/infinity-finance-api'
import { Button } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import Lozenge from 'components/lozenge/Lozenge'
import { observer } from 'mobx-react'
import { ProjectPagePaths } from 'pages/ProjectPage'
import { useMemo } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import DraggableProjectCard from './DraggableProjectCard'
import { useProjectBoardContext } from './ProjectBoardContext'
import './ProjectStageColumn.scss'

interface ProjectStageColumnProps {
  stage: IProjectStage
  isDefaultStage?: boolean
  showArchiveAction?: boolean
}

const ProjectStageColumn = (props: ProjectStageColumnProps) => {
  const { projectStore } = useRootStore()
  const { page } = useProjectBoardContext()

  const projects = projectStore.projectsInStage(props.stage)
  const projectsOutsideOfStage = props.isDefaultStage
    ? projectStore.projectsOutsideOfStages
    : []
  const projectsSortedByClient = useMemo(
    () =>
      [...projects, ...projectsOutsideOfStage].sort((a, b) =>
        a.client.localeCompare(b.client)
      ),
    [projects, projectsOutsideOfStage]
  )

  const { isOver, setNodeRef } = useDroppable({
    id: props.stage.id,
  })

  return (
    <div
      className={classNames('project-stage-column', {
        'dragging-over': isOver,
      })}
    >
      <div className='column-header'>
        <span className='column-title'>
          <Lozenge tint={props.stage.colour}>{props.stage.title}</Lozenge>
        </span>
      </div>
      <div className='column-projects' ref={setNodeRef}>
        {projectsSortedByClient.map((project) => (
          <DraggableProjectCard key={project.id} project={project} />
        ))}
      </div>
      {props.showArchiveAction && (
        <div className='column-footer'>
          <Button
            iconLeft='archive'
            variant='quaternary'
            className='column-footer-action'
            onClick={() => page.open(ProjectPagePaths.ArchivedProjects)}
          >
            Archivierte anzeigen
          </Button>
        </div>
      )}
    </div>
  )
}

export default observer(ProjectStageColumn)
