import { Flex } from '@nextbusiness/infinity-ui'
import { useTransactionEditorContext } from '../TransactionEditorContext'
import TransactionNoEditMessage from '../TransactionNoEditMessage'
import TransactionAttachments from '../attachments/TransactionAttachments'
import TransactionEditorCancelButton from './TransactionEditorCancelButton'
import TransactionEditorDeleteAction from './TransactionEditorDeleteAction'
import TransactionEditorSaveButton from './TransactionEditorSaveButton'

const TransactionEditorActions = () => {
  const { canMakeChanges, canDelete, canChangeDescription } =
    useTransactionEditorContext()

  if (!canMakeChanges) {
    return (
      <Flex className='no-edit-actions' verticalAlignment='center' gap='tiny'>
        <TransactionNoEditMessage />
        {canDelete && <TransactionEditorDeleteAction />}
        <TransactionAttachments />
        <div className='divider' />
        <TransactionEditorCancelButton title='Schliessen' />
        {canChangeDescription && <TransactionEditorSaveButton />}
      </Flex>
    )
  }

  return (
    <>
      {canDelete && <TransactionEditorDeleteAction />}
      <TransactionAttachments />
      <div className='divider' />
      <TransactionEditorCancelButton title='Abbrechen' />
      <TransactionEditorSaveButton />
    </>
  )
}

export default TransactionEditorActions
