import NavigatableMagicSheet from 'components/magic-sheet/NavigatableMagicSheet'
import VendorInvoiceDetailSheet from 'invoices/vendor-invoices/detail/VendorInvoiceDetailSheet'
import VendorInvoiceCreationView from 'invoices/vendor-invoices/vendor-invoice-creation/VendorInvoiceCreationView'
import VendorInvoices from '../invoices/vendor-invoices/VendorInvoices'
import { IVendorInvoice } from '../model/VendorInvoice'

export let CurrentVendorInvoiceNavigationStackRef: VendorInvoicePage | null =
  null

export enum VendorInvoicePagePaths {
  VendorInvoices = '/rechnungen',
  VendorInvoiceCreation = '/rechnungen/erstellen',
  VendorInvoiceDetail = '/rechnungen/rechnung/:param',
}

class VendorInvoicePage extends NavigatableMagicSheet {
  className = 'vendor-invoice-page-stack'
  basePath = VendorInvoicePagePaths.VendorInvoices

  protected registerPaths(): void {
    this.registerView({
      url: VendorInvoicePagePaths.VendorInvoices,
      title: () => 'Rechnungen an mich',
      view: (
        <VendorInvoices
          openInvoice={this.openInvoice}
          openCreateInvoiceModal={this.openCreateInvoiceModal}
        />
      ),
    })
    this.registerView({
      url: VendorInvoicePagePaths.VendorInvoiceCreation,
      title: () => 'Neue Rechnung',
      view: <VendorInvoiceCreationView onDismiss={() => this.goToRootView()} />,
    })
    this.registerView({
      url: VendorInvoicePagePaths.VendorInvoiceDetail,
      title: () => 'Rechnung',
      view: <VendorInvoiceDetailSheet page={this} />,
    })
  }

  openCreateInvoiceModal = () =>
    this.open(VendorInvoicePagePaths.VendorInvoiceCreation)

  openInvoice = (invoice: IVendorInvoice) =>
    this.open(VendorInvoicePagePaths.VendorInvoiceDetail, invoice.id)

  open(path: VendorInvoicePagePaths, param?: string) {
    if (param) return this.props.history.push(path.replace(':param', param))
    this.props.history.push(path)
  }

  override componentDidMount() {
    super.componentDidMount()
    CurrentVendorInvoiceNavigationStackRef = this
  }

  override componentWillUnmount() {
    super.componentWillUnmount()
    CurrentVendorInvoiceNavigationStackRef = null
  }
}

export default VendorInvoicePage
