import { INewCustomProductPosition } from '../../../../../../model/CustomerInvoice'
import DocumentPositionProps from './DocumentPositionProps'
import PositionArticleNumberField from './fields/PositionArticleNumberField'
import PositionNameField from './fields/PositionNameField'
import PositionQuantityField from './fields/PositionQuantityField'
import PositionSingleAmountField from './fields/PositionSingleAmountField'
import PositionTotalAmountField from './fields/PositionTotalAmountField'
import PositionUnitField from './fields/PositionUnitField'
import PositionVATField from './fields/PositionVATField'

export interface DocumentCustomProductPositionProps
  extends DocumentPositionProps {
  position: INewCustomProductPosition
  setSuppressFlyout: (suppressFlyout: boolean) => void
}

const DocumentCustomProductPosition = (
  props: DocumentCustomProductPositionProps
) => (
  <>
    <PositionQuantityField {...props} />
    <PositionUnitField {...props} />
    <PositionArticleNumberField {...props} />
    <PositionNameField {...props} />
    <PositionVATField {...props} />
    <PositionSingleAmountField {...props} />
    <PositionTotalAmountField {...props} />
  </>
)

export default DocumentCustomProductPosition
