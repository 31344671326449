import {
  Button,
  Card,
  FileIcon,
  Flex,
  InputField,
  Text,
} from '@nextbusiness/infinity-ui'
import DocumentIcon from 'components/document-icon/DocumentIcon'
import ContactPicker from 'contacts/ContactPicker'
import { useEffect, useState } from 'react'
import { Upload } from './DocumentUpload'
import './UploadedDocumentCard.scss'

interface UploadedDocumentCardProps {
  upload: Upload
  editUpload: (changes: Partial<Upload>) => void
  removeFile: () => void
  hasContact?: boolean
}

const UploadedDocumentCard = (props: UploadedDocumentCardProps) => {
  const file = props.upload.file
  const [previewURL, setPreviewURL] = useState<string | null>(null)

  const SUPPORTED_IMAGE_PREVIEW_TYPES = [
    'image/png',
    'image/jpeg',
    'image/tiff',
  ]

  const canShowImagePreview = SUPPORTED_IMAGE_PREVIEW_TYPES.includes(file.type)
  const fileExtension = file.name.split('.').pop()

  useEffect(() => {
    const objectUrl = URL.createObjectURL(props.upload.file)
    setPreviewURL(objectUrl)

    // Free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [file])

  return (
    <Card className='uploaded-document-card'>
      <Flex
        className='uploaded-document-card-inner'
        gap='tiny'
        verticalAlignment='center'
      >
        <div className='uploaded-document-card-icon'>
          {canShowImagePreview && previewURL ? (
            <DocumentIcon thumbnailURL={previewURL} small />
          ) : (
            <FileIcon name='' suffix={fileExtension ?? '?'} />
          )}
        </div>
        <Flex
          className='uploaded-document-inner-content'
          gap='small'
          horizontalAlignment='center'
        >
          <Flex direction='vertical'>
            <Text className='label-for-inputfield' type='inline'>
              Titel
            </Text>
            <Text className='label-for-inputfield' type='inline'>
              Notiz
            </Text>
          </Flex>
          <Flex
            direction='vertical'
            horizontalAlignment='center'
            className='uploaded-document-inner-content-fields'
          >
            <InputField
              fullWidth
              value={props.upload.title ?? ''}
              onChange={(title) => props.editUpload({ title })}
            />
            <InputField
              fullWidth
              value={props.upload.note ?? ''}
              placeholder='Füge hier einen Kommentar hinzu, der später das Zuordnen vereinfacht'
              onChange={(note) => props.editUpload({ note })}
            />
          </Flex>
          {props.hasContact && (
            <Flex
              gap='small'
              verticalAlignment='baseline'
              horizontalAlignment='center'
            >
              <Text className='label-for-inputfield'>Kontakt</Text>
              <ContactPicker
                contactId={props.upload.contactId ?? undefined}
                setContactId={(contactId) => props.editUpload({ contactId })}
              />
            </Flex>
          )}
        </Flex>
        <Button
          variant='quaternary'
          iconOnly='delete'
          className='delete-button'
          onClick={() => props.removeFile()}
          tooltip='Dokument löschen'
        />
      </Flex>
    </Card>
  )
}

export default UploadedDocumentCard
