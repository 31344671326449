import { Button } from '@nextbusiness/infinity-ui'
import PayInvoiceModal from 'invoices/shared-components/PayInvoiceModal'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { useVendorInvoiceContext } from './VendorInvoiceContext'

const VendorInvoicePaymentAction = () => {
  const { vendorInvoiceStore } = useRootStore()
  const { invoice, reloadInvoice } = useVendorInvoiceContext()
  const [isPayInvoiceModalOpen, setIsPayInvoiceModalOpen] =
    useState<boolean>(false)

  if (invoice.isPaid) return null

  return (
    <>
      <Button variant='primary' onClick={() => setIsPayInvoiceModalOpen(true)}>
        Zahlung verbuchen
      </Button>
      <PayInvoiceModal
        type='vendor invoice'
        invoice={invoice}
        isOpen={isPayInvoiceModalOpen}
        onDismiss={() => setIsPayInvoiceModalOpen(false)}
        onSaved={() => {
          reloadInvoice()
          vendorInvoiceStore.onInvoicesChanged()
        }}
      />
    </>
  )
}

export default observer(VendorInvoicePaymentAction)
