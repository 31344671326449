import { Heading } from '@nextbusiness/infinity-ui'
import WelcomeCentreGuideItem from './WelcomeCentreGuideItem'
import { ONBOARDING_VIDEO_URL } from './WelcomeCentreHelpMenu'

const WelcomeCentreGuides = () => (
  <section className='guides-section'>
    <Heading type='h4'>Nicht sicher, wo du beginnen sollst?</Heading>
    <div className='guide-list'>
      <WelcomeCentreGuideItem
        title='Einführung in die Finanzbuchhaltung'
        description='Lerne die Basics der Fibu mit Infinity in 5 Minuten'
        type='video'
        href={ONBOARDING_VIDEO_URL}
      />
      <WelcomeCentreGuideItem
        title='Grundlagen von Infinity Finance kennenlernen'
        description='Kurzartikel über alle fünf Tabs in Infinity Finance'
        type='article'
        href='https://help.infinity.swiss/de/collections/3641906-grundlagen-von-infinity-finance'
      />
    </div>
  </section>
)

export default WelcomeCentreGuides
