import ErrorScreen from 'base-components/layout/ErrorScreen'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import { observer } from 'mobx-react'
import DocumentsPage from 'pages/DocumentsPage'
import { useCallback, useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import VATPeriodsList from './VATPeriodsList'

interface VATPeriodsOverviewProps {
  page: DocumentsPage
}

const VATPeriodsOverview = (props: VATPeriodsOverviewProps) => {
  const { authenticationStore, vatPeriodStore } = useRootStore()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error>()

  const loadPeriods = useCallback(async () => {
    setIsLoading(true)
    setError(undefined)
    try {
      await vatPeriodStore.loadPeriods()
    } catch (loadingError) {
      setError(loadingError as Error)
    } finally {
      setIsLoading(false)
    }
  }, [authenticationStore.organisationIdentifier])

  useEffect(() => {
    loadPeriods()
  }, [loadPeriods])

  return (
    <MagicSheetPage header={<MagicSheetTitleBar title='Mehrwertsteuer' />}>
      <MagicSheetContainer>
        {isLoading && <LoadingScreen loadingText='Perioden werden geladen.' />}
        {error && (
          <ErrorScreen
            title='Übersicht kann nicht geladen werden'
            onRetry={loadPeriods}
            onClose={() => props.page.popCurrentView()}
            defaultMessage='Perioden konnte nicht geladen werden.'
            error={error}
          />
        )}
        {!!vatPeriodStore.periods && (
          <VATPeriodsList periods={vatPeriodStore.periods} page={props.page} />
        )}
      </MagicSheetContainer>
    </MagicSheetPage>
  )
}

export default observer(VATPeriodsOverview)
