import { action, computed, makeObservable, observable } from 'mobx'
import {
  IFinancialSolvency,
  IMaturities,
  IProfitability,
} from '../model/FinancialHealth'
import FinancialHealth from '../networking/FinancialHealth'
import FinancialHealthContext from '../reports/financial-health/status/FinancialHealthContext'
import FinancialHealthStatus from '../reports/financial-health/status/FinancialHealthStatus'
import RootStore from './RootStore'
import Store from './Store'

class ReportingStore extends Store {
  @observable financialSolvency?: IFinancialSolvency
  @observable maturities?: IMaturities
  @observable profitability?: IProfitability

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  @action
  public setFinancialSolvency = (
    financialSolvency: IFinancialSolvency | undefined
  ) => {
    this.financialSolvency = financialSolvency
  }

  @action
  public setMaturities = (maturities: IMaturities | undefined) => {
    this.maturities = maturities
  }

  @action
  public setProfitability = (profitability: IProfitability | undefined) => {
    this.profitability = profitability
  }

  @computed
  get context(): FinancialHealthContext {
    return {
      financialSolvency: this.financialSolvency,
      maturities: this.maturities,
      profitability: this.profitability,
      fiscalYear: this.rootStore.accountStore.currentFiscalYear,
    }
  }

  status(): FinancialHealthStatus {
    return new FinancialHealthStatus(this.context)
  }

  public loadFinancialSolvency = async () => {
    const financialSolvency = await FinancialHealth.getFinancialSolvency(
      this.rootStore.authenticationStore.organisationIdentifier
    )
    this.setFinancialSolvency(financialSolvency)
  }

  public loadMaturities = async () => {
    const maturities = await FinancialHealth.getMaturities(
      this.rootStore.authenticationStore.organisationIdentifier
    )
    this.setMaturities(maturities)
  }

  public loadProfitability = async () => {
    const profitability = await FinancialHealth.getProfitability(
      this.rootStore.authenticationStore.organisationIdentifier,
      Date.now()
    )
    this.setProfitability(profitability)
  }
}

export default ReportingStore
