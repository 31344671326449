import { Button } from '@nextbusiness/infinity-ui'
import { CustomerInvoiceEditorSidebarTopActionsProps } from './CustomerInvoiceEditorSidebarTopActions'

const EditorEditModeAction = (
  props: CustomerInvoiceEditorSidebarTopActionsProps
) => {
  if (!props.isEditable) return null
  return (
    <Button variant='primary' onClick={() => props.registerEdit({})}>
      Bearbeiten
    </Button>
  )
}

export default EditorEditModeAction
