import { Avatar } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useRootStore } from '../../stores/RootStoreContext'
import './ContactSwatch.scss'

interface ContactSwatchProps {
  contactId: string
  small?: boolean
}

const ContactSwatch = (props: ContactSwatchProps) => {
  const { contactStore } = useRootStore()

  return (
    <Avatar
      name={contactStore.displayNameForContactWithId(props.contactId)}
      className={
        'contact-swatch' +
        (props.small ? ' small' : '') +
        (!props.contactId ? ' unknown' : '')
      }
    />
  )
}

export default observer(ContactSwatch)
