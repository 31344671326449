/*
Copyright 2020 Adobe
All Rights Reserved.

NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it. If you have received this file from a source other than Adobe,
then your use, modification, or distribution of it requires the prior
written permission of Adobe.
*/

import { KeyValueStore } from '@nextbusiness/infinity-ui'
import AcrobatContent from './AcrobatContent'
import AcrobatMetadata from './AcrobatMetadata'
import AcrobatViewerConfig from './AcrobatViewerConfig'

declare global {
  interface Window {
    AdobeDC: any
  }
}

class AcrobatViewSDKClient {
  private readyPromise: Promise<void>
  private adobeDCView: any
  private clientId: string

  constructor(clientId: string) {
    this.readyPromise = new Promise((resolve) => {
      if (window.AdobeDC) {
        resolve()
      } else {
        /* Wait for Adobe Acrobat Services PDF Embed API to be ready */
        document.addEventListener('adobe_dc_view_sdk.ready', () => {
          resolve()
        })
      }
    })
    this.adobeDCView = undefined
    this.clientId = clientId
  }

  ready() {
    return this.readyPromise
  }

  previewFile(
    divId: string,
    content: AcrobatContent,
    metadata: AcrobatMetadata,
    viewerConfig: AcrobatViewerConfig
  ): Promise<ArrayBuffer> {
    const config: KeyValueStore = { clientId: this.clientId }
    if (divId) {
      config.divId = divId
    }
    /* Initialize the AdobeDC View object */
    this.adobeDCView = new window.AdobeDC.View(config)

    /* Invoke the file preview API on Adobe DC View object */
    const previewFilePromise = this.adobeDCView.previewFile(
      {
        content: { location: { url: content.url, headers: content.headers } },
        metaData: { fileName: metadata.fileName, id: metadata.fileId },
      },
      viewerConfig
    )
    return previewFilePromise
  }

  previewFileUsingFilePromise(
    divId: string,
    filePromise: Promise<ArrayBuffer>,
    fileName: string
  ) {
    /* Initialize the AdobeDC View object */
    this.adobeDCView = new window.AdobeDC.View({
      clientId: this.clientId,
      divId,
    })

    /* Invoke the file preview API on Adobe DC View object */
    this.adobeDCView.previewFile(
      { content: { promise: filePromise }, metaData: { fileName: fileName } },
      {}
    )
  }

  registerEventsHandler() {
    /* Register the callback to receive the events */
    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
      (event: unknown) => {
        console.log(event)
      }
    )
  }
}

export default AcrobatViewSDKClient
