import Filter from 'base-components/table/Filter'
import { useVendorInvoicesFilter } from './VendorInvoicesFilterContext'

const VendorInvoiceAttachmentFilter = () => {
  const { activeFilter, editFilter } = useVendorInvoicesFilter()
  return (
    <Filter
      icon='attach'
      label='Beleg'
      items={[
        { label: 'Kein Beleg', value: false, icon: 'link-broken' },
        { label: 'Beleg vorhanden', value: true, icon: 'link' },
      ]}
      selectedValue={activeFilter.attachments}
      onValueSelected={(attachments) => editFilter({ attachments })}
    />
  )
}

export default VendorInvoiceAttachmentFilter
