import { Finance } from '@nextbusiness/infinity-finance-api'
import {
  Dialog,
  FeedbackBanner,
  Text,
  TypedKeyValueStore,
} from '@nextbusiness/infinity-ui'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import { useRootStore } from 'stores/RootStoreContext'
import Utilities from 'utility/Utilities'

interface SetupAccountBalancesModalProps {
  isOpen: boolean
  onDismiss: () => void
  onCompleted: () => void
  sumOfAssets: number
  sumOfLiabilities: number
  accountBalances: TypedKeyValueStore<number | null>
}

const OPENING_SYSTEM_ACCOUNT = 9100

const SetupAccountBalancesModal = (props: SetupAccountBalancesModalProps) => {
  const { accountStore, transactionStore, preferencesStore } = useRootStore()
  const intercom = useIntercom()

  const [isApplying, setIsApplying] = useState<boolean>(false)
  const [failedAccounts, setFailedAccounts] = useState<number[]>()
  const [hasAttempted, setHasAttempted] = useState<boolean>(false)

  const isCorrectionNecessary = props.sumOfAssets !== props.sumOfLiabilities

  const applyOpeningBalanceForAccount = async (accountNumber: number) => {
    let balance = props.accountBalances[accountNumber]

    if (accountNumber === 2979 && isCorrectionNecessary)
      balance = (props.sumOfAssets - props.sumOfLiabilities) / 100

    if (!balance) return

    const account = accountStore.allAccounts.find(
      (account) => account.accountNumber === accountNumber
    )

    let isAccountOnDebitSide = account?.accountType === 'assets'
    if (balance < 0) {
      isAccountOnDebitSide = !isAccountOnDebitSide
    }

    const debitAccount: number = isAccountOnDebitSide
      ? accountNumber
      : OPENING_SYSTEM_ACCOUNT
    const creditAccount: number = isAccountOnDebitSide
      ? OPENING_SYSTEM_ACCOUNT
      : accountNumber

    const fiscalYear = accountStore.ealiestOpenFiscalYear!
    const transactionDate = fiscalYear.from

    await Finance.Ledger.createTransaction({
      debitAccount,
      creditAccount,
      amount: Math.round(Math.abs(balance) * 100),
      date: transactionDate,
      fiscalYear: fiscalYear.year,
      description: `Anfangsbestand ${
        account?.name ?? `Konto ${accountNumber}`
      }`,
    })
  }

  const applyOpeningBalances = async () => {
    const accountsFilledIn = Object.keys(props.accountBalances).map((key) =>
      Number(key)
    )
    const accountsToCreate: number[] =
      hasAttempted && failedAccounts?.length ? failedAccounts : accountsFilledIn

    if (isCorrectionNecessary) accountsToCreate.push(2979)

    setIsApplying(true)
    setHasAttempted(true)

    for (const accountNumber of accountsToCreate) {
      try {
        await applyOpeningBalanceForAccount(accountNumber)
      } catch (error) {
        setFailedAccounts((failedAccounts) => [
          ...(failedAccounts ?? []),
          accountNumber,
        ])
      }
    }

    intercom.trackEvent('opening-balances-applied')
    MixpanelAnalytics.event('Opening balances applied')

    await accountStore.loadAccounts()
    await transactionStore.loadTransactionsForAccount(1020)
    await transactionStore.loadTransactionsForAccount(OPENING_SYSTEM_ACCOUNT)

    setIsApplying(false)
    if (!failedAccounts?.length) {
      props.onCompleted()
      await preferencesStore.setFlags({
        ledgerAccountBalancesSetupCompleted: true,
      })
    }
  }

  const hasErrors = hasAttempted && failedAccounts?.length
  return (
    <Dialog
      title='Anfangsbestände eintragen'
      titleProps={{ divider: true }}
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      icon='calculator'
      style={{ maxWidth: '64rem' }}
      actions={[
        {
          children: 'Abbrechen',
          onClick: () => props.onDismiss(),
        },
        {
          children: hasErrors
            ? 'Erneut versuchen'
            : isCorrectionNecessary
            ? 'Korrigieren und eintragen'
            : 'Eintragen',
          variant: 'primary',
          isLoading: isApplying,
          onClick: applyOpeningBalances,
        },
      ]}
    >
      <Text>
        Wenn du fortfährst, werden die eingegebenen Anfangsbestände definitiv in
        die Konten eingebucht.
      </Text>
      {hasErrors ? (
        <FeedbackBanner variant='error'>
          Für einige Konten konnte der Anfangsbestand nicht eingetragen werden:
          <ul>
            {failedAccounts.map((accountNumber) => {
              const accountName = accountStore.allAccounts.find(
                (account) => account.accountNumber === accountNumber
              )?.name
              return (
                <li key={accountNumber}>
                  {accountName ?? `Konto ${accountNumber}`}
                </li>
              )
            })}
          </ul>
        </FeedbackBanner>
      ) : isCorrectionNecessary ? (
        <>
          <FeedbackBanner
            variant='warning'
            title='Vermögen und Schulden stimmen nicht überein'
          >
            Da die eingegebenen Anfangsbestände von Vermögen und Schulden nicht
            übereinstimmen, wird der Eröffnungsbetrag des Kontos “Jahresgewinn
            oder -verlust“ automatisch auf{' '}
            <b>
              {Utilities.centsToCHF(props.sumOfAssets - props.sumOfLiabilities)}{' '}
              CHF
            </b>{' '}
            korrigiert, wenn du fortfährst.
          </FeedbackBanner>
          <Text variant='warning'>
            Bitte überprüfe, ob du die Anfangsbestände für alle Konten korrekt
            eingegeben hast, bevor du fortfährst.
          </Text>
        </>
      ) : null}
    </Dialog>
  )
}

export default observer(SetupAccountBalancesModal)
