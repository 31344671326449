import { TaxCode } from '@nextbusiness/infinity-finance-api'
import { TaxRate } from 'components/vat/vat-picker/TaxCodes'
import CustomerInvoicePositions from 'invoices/customer-invoices/CustomerInvoicePositions'
import { inject } from 'mobx-react'
import React, { Component } from 'react'
import TaxUtilities from 'utility/TaxUtilities'
import {
  INewCustomerInvoice,
  INewCustomerInvoicePosition,
} from '../../../../../model/CustomerInvoice'
import { TypedKeyValueStore } from '../../../../../utility/types'
import DocumentRendererContext from '../DocumentRendererContext'
import DocumentElementProps from './DocumentElementProps'
import DocumentPosition from './positions/DocumentPosition'
import DocumentPositionsDropZone from './positions/DocumentPositionsDropZone'
import DocumentPositionsHeaderRow from './positions/DocumentPositionsHeaderRow'
import DocumentPositionsTotals from './positions/DocumentPositionsTotals'
import DocumentPositionsVATRow, {
  vatSumRow,
} from './positions/DocumentPositionsVATRow'

interface DocumentElementPositionsState {
  vatDebts: Record<TaxRate, number> | null
}

@inject('preferencesStore')
class DocumentElementPositions extends Component<
  DocumentElementProps,
  DocumentElementPositionsState
> {
  constructor(props: DocumentElementProps) {
    super(props)
    this.state = {
      vatDebts: null,
    }
  }
  static contextType = DocumentRendererContext
  context: React.ContextType<typeof DocumentRendererContext> | null = null
  ref = React.createRef<HTMLDivElement>()

  componentDidMount() {
    const renderContext = this.context
    const positionTable = this.ref.current
    if (positionTable && renderContext) {
      const positionTableStyle = window.getComputedStyle(positionTable)
      const margin =
        parseFloat(positionTableStyle.marginTop) +
        parseFloat(positionTableStyle.marginBottom)

      renderContext.setPositionTableMargin(margin)
    }
    this.calculateSumsOfVAT()
  }

  onChangePosition = (
    positionId: string,
    changes: Partial<INewCustomerInvoicePosition>,
    deleteKeys?: string[]
  ) =>
    this.props.registerEdit({
      positions: this.updatedPositionsWithChanges(
        positionId,
        changes,
        deleteKeys
      ),
    })

  deletePosition = (positionId: string) => {
    const positions = [...(this.props.document.positions ?? [])]
    const positionIndex = positions.findIndex(
      (position) => position.identifier === positionId
    )
    positions.splice(positionIndex, 1)

    this.props.registerEdit({ positions })
  }

  duplicatePosition = (positionId: string) => {
    const positions = [...(this.props.document.positions ?? [])]
    const positionIndex = positions.findIndex(
      (position) => position.identifier === positionId
    )
    const position = positions[positionIndex]

    const duplicatedPosition: INewCustomerInvoicePosition = {
      ...position,
      identifier: Math.random().toString(),
    }
    positions.splice(positionIndex + 1, 0, duplicatedPosition)

    this.props.registerEdit({ positions })
  }

  updatedPositionsWithChanges = (
    positionId: string,
    changes: Partial<INewCustomerInvoicePosition>,
    deleteKeys?: string[]
  ): INewCustomerInvoicePosition[] | undefined => {
    const currentPositions = this.props.document.positions
    const positionIndex = currentPositions?.findIndex(
      (position) => position.identifier === positionId
    )
    if (positionIndex === undefined) return currentPositions

    const positions = [...(currentPositions ?? [])]

    const existingPosition: TypedKeyValueStore<any> = {
      ...positions[positionIndex],
    }
    for (const key in positions[positionIndex]) {
      if (!positions[positionIndex].hasOwnProperty(key)) continue
      if (deleteKeys?.includes(key)) delete existingPosition[key]
    }
    const updatedPosition: INewCustomerInvoicePosition = {
      ...existingPosition,
      ...changes,
    } as INewCustomerInvoicePosition

    positions[positionIndex] = updatedPosition
    return positions
  }

  calculateSumsOfVAT = () => {
    if (!this.props.document.positions) {
      return
    }
    const vatDebts: Record<TaxRate, number> = {
      '7.7': 0,
      '3.7': 0,
      '2.5': 0,
      '8.1': 0,
      '3.8': 0,
      '2.6': 0,
    }
    const { amountMode, positions } = this.props.document
    const customerInvoicePositions = new CustomerInvoicePositions(
      this.props.document as INewCustomerInvoice
    )
    positions.forEach((position) => {
      if (position.type !== 'custom-product' || !position.taxCode) {
        return
      }
      const discountAdjustment =
        customerInvoicePositions.vatDiscountAdjustmentForPosition(position)
      const vatDebt =
        CustomerInvoicePositions.vatDebtForPosition(position, amountMode) -
        discountAdjustment
      const taxRate = TaxUtilities.taxRateForTaxCode(
        position.taxCode as TaxCode
      )
      if (!taxRate) {
        return
      }
      vatDebts[taxRate] += vatDebt
    })
    const vatDebtsHaveChanged = (
      Object.keys(vatDebts) as unknown as TaxRate[]
    ).some((taxRate) => vatDebts[taxRate] !== this.state.vatDebts?.[taxRate])
    if (this.state.vatDebts === null || vatDebtsHaveChanged)
      this.setState({ vatDebts })
  }

  showVATSumRow = (taxRate: TaxRate) => {
    return (
      this.state.vatDebts &&
      this.state.vatDebts[taxRate] > 0 && (
        <DocumentPositionsVATRow
          key={taxRate}
          rate={taxRate}
          vatSum={this.state.vatDebts[taxRate]}
          amountMode={this.props.document.amountMode}
          positions={
            new CustomerInvoicePositions(
              this.props.document as INewCustomerInvoice
            )
          }
        />
      )
    )
  }

  vatSumRows = () => {
    const taxRates: TaxRate[] = [7.7, 3.7, 2.5, 8.1, 3.8, 2.6]
    return taxRates.map(
      (taxRate) =>
        (!this.props.page || this.props.page[vatSumRow(taxRate)]) &&
        this.showVATSumRow(taxRate)
    )
  }

  componentDidUpdate() {
    this.calculateSumsOfVAT()
  }

  render() {
    return (
      <div className='page-positions'>
        <div className='positions-table' ref={this.ref}>
          {(!this.props.page || this.props.page.positionsHeaderRow) && (
            <DocumentPositionsHeaderRow
              readonly={!this.props.isEditable}
              document={this.props.document}
              registerEdit={this.props.registerEdit}
            />
          )}
          <DocumentPositionsDropZone>
            {this.props.document.positions?.map((position, index) =>
              !this.props.page || this.props.page.positions?.includes(index) ? (
                <DocumentPosition
                  key={position.identifier}
                  index={index}
                  onChangePosition={this.onChangePosition}
                  deletePosition={this.deletePosition}
                  duplicatePosition={this.duplicatePosition}
                  position={position}
                  template={this.props.template}
                  isReadOnly={!this.props.isEditable}
                  columns={this.props.document.columns}
                  amountMode={this.props.document.amountMode}
                  openingDate={this.props.document.openingDate}
                  vatPresentationOptions={
                    this.props.document.vatPresentationOptions
                  }
                  isQuote={this.props.document.isQuote}
                />
              ) : null
            )}
          </DocumentPositionsDropZone>
          <DocumentPositionsTotals
            {...this.props}
            vatSumRows={this.vatSumRows()}
          />
        </div>
      </div>
    )
  }
}

export default DocumentElementPositions
