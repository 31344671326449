import { Button } from '@nextbusiness/infinity-ui'
import { useTransactionEditorContext } from '../TransactionEditorContext'

const TransactionEditorSaveButton = () => {
  const { isDraftIncomplete, onSaveChanges, isSaving } =
    useTransactionEditorContext()

  return (
    <Button
      className='save-button'
      variant='primary'
      disabled={isDraftIncomplete}
      onClick={() => onSaveChanges()}
      isLoading={isSaving}
    >
      Speichern
    </Button>
  )
}

export default TransactionEditorSaveButton
