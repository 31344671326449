import { IconItem } from '@nextbusiness/infinity-ui-icons'
import Lockup from 'components/text/Lockup'
import TransactionLiveAcountingDetailProps from './TransactionLiveAcountingDetailProps'

const RemittanceTypes: Record<string, string> = {
  QRR: 'QR-Rechnung',
  SCOR: 'Strukturierte Kreditorreferenz',
  ESR: 'ESR-Referenz',
}

const iconForRemittanceType = (type: string): IconItem => {
  if (type === 'QRR') {
    return 'qr-code'
  } else {
    return 'tag-window'
  }
}

const LiveAccountingReference = (
  props: TransactionLiveAcountingDetailProps
) => {
  if (!props.details.remittanceReference) return null

  const remittanceType = props.details.remittanceReference?.type
  const displayedRemittanceType =
    RemittanceTypes[remittanceType] ?? remittanceType

  return (
    <Lockup
      icon={iconForRemittanceType(remittanceType)}
      tooltip='Buchungsreferenz'
      small
      subtle
      truncate
    >
      {displayedRemittanceType}
    </Lockup>
  )
}

export default LiveAccountingReference
