import { ITemplate } from '@nextbusiness/infinity-finance-api'
import { Heading } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import { useState } from 'react'
import ICustomerInvoice, {
  INewCustomerInvoice,
} from '../../../../model/CustomerInvoice'
import CustomerInvoiceDraftSaveValidationMessage from './CustomerInvoiceDraftSaveValidationMessage'
import './CustomerInvoiceEditorSidebar.scss'
import CustomerInvoiceEditorSidebarPanels from './CustomerInvoiceEditorSidebarPanels'
import CustomerInvoiceEditorSidebarToggle from './CustomerInvoiceEditorSidebarToggle'
import CustomerInvoiceSavingError from './CustomerInvoiceSavingError'
import CustomerInvoiceEditorSidebarTopActions from './top-actions/CustomerInvoiceEditorSidebarTopActions'

export interface CustomerInvoiceEditorSidebarProps {
  template: ITemplate
  existingInvoice?: ICustomerInvoice
  setExistingInvoice: (invoice: ICustomerInvoice) => void
  document: Partial<INewCustomerInvoice>
  registerEdit: (
    changes: Partial<INewCustomerInvoice>,
    shouldSave?: boolean,
    saveMessage?: string
  ) => void
  save: (saveMessage?: string) => Promise<void>
  reload: (reloadMessage?: string) => Promise<void>
  canSave?: boolean
  isSaving?: boolean
  isAutosaving?: boolean
  hasChanges?: boolean
  isExistingDocument?: boolean
  isGeneratingPDF?: boolean
  isEditable: boolean
  loadPDF: () => void
  canSaveAsNonDraft?: boolean
  savingError?: Error
  autosavingError?: Error
  navigateToInvoice: (invoiceId: string) => void
  discardChanges: () => void
}

const CustomerInvoiceEditorSidebar = (
  props: CustomerInvoiceEditorSidebarProps
) => {
  const [isFixedSidebarToggled, setIsFixedSidebarToggled] =
    useState<boolean>(false)
  const [showSaveValidationMessage, setShowSaveValidationMessage] =
    useState<boolean>(false)

  return (
    <div
      className={classNames('document-editor-sidebar', {
        open: isFixedSidebarToggled,
        draft: props.document.isDraft,
      })}
    >
      <CustomerInvoiceSavingError
        isQuote={props.document.isQuote}
        savingError={props.savingError}
      />
      <CustomerInvoiceEditorSidebarToggle
        isFixedSidebarToggled={isFixedSidebarToggled}
        setIsFixedSidebarToggled={setIsFixedSidebarToggled}
      />
      <CustomerInvoiceEditorSidebarTopActions
        {...props}
        showSaveValidationMessage={() => setShowSaveValidationMessage(true)}
        clearSaveValidationMessage={() => setShowSaveValidationMessage(false)}
      />
      {showSaveValidationMessage && !props.canSave && (
        <CustomerInvoiceDraftSaveValidationMessage {...props} />
      )}
      <Heading type='h2' divider>
        {props.document.isQuote ? 'Offerte' : 'Forderung'}
      </Heading>
      <div style={{ opacity: props.isSaving ? 0.25 : 1 }}>
        <CustomerInvoiceEditorSidebarPanels {...props} />
      </div>
    </div>
  )
}

export default CustomerInvoiceEditorSidebar
