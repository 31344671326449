import { ICompleteDocument } from '@nextbusiness/infinity-finance-api'
import {
  Button,
  Flex,
  Heading,
  NonIdealState,
  Text,
} from '@nextbusiness/infinity-ui'
import { IconSize } from '@nextbusiness/infinity-ui-icons'
import AcrobatViewer from 'components/acrobat/AcrobatViewer'
import './DocumentUploadPreview.scss'

interface DocumentUploadPreviewProps {
  document: ICompleteDocument
  disableInteractivity?: boolean
}

const PREVIEWABLE_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif']

const DocumentUploadPreview = (props: DocumentUploadPreviewProps) => {
  const canPreviewAsImage = PREVIEWABLE_IMAGE_TYPES.includes(
    props.document.cloudFile.type
  )
  const downloadFile = () => {
    const link = document.createElement('a')
    link.href = props.document.cloudFile.url
    link.download = props.document.cloudFile.name
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Flex
      className='document-preview'
      style={{ pointerEvents: props.disableInteractivity ? 'none' : undefined }}
    >
      {props.document.cloudFile.type === 'application/pdf' ? (
        <AcrobatViewer
          url={props.document.cloudFile.url}
          fileId={props.document.cloudFile.id}
          fileName={props.document.cloudFile.name}
        />
      ) : canPreviewAsImage ? (
        <div
          className='document-preview-image'
          style={{ backgroundImage: `url('${props.document.cloudFile.url}')` }}
        />
      ) : (
        <NonIdealState
          actions={[
            {
              children: 'Herunterladen',
              iconLeft: 'download',
              onClick: downloadFile,
            },
          ]}
          icon='attach'
          iconSize={IconSize.Medium}
        >
          <Flex direction='vertical' verticalAlignment='center'>
            <Heading type='h3'>
              Dokument {props.document.cloudFile.name}
            </Heading>
            <Text variant='subtle' type='inline'>
              Für dieses Dokument ist keine Vorschau möglich.
            </Text>
          </Flex>
        </NonIdealState>
      )}
      <Button
        className='enlarge-button'
        iconOnly='enlarge'
        onClick={() => window.open(props.document.cloudFile.url, '_blank')}
        tooltip='Dokument in neuem Tab öffnen'
      />
    </Flex>
  )
}

export default DocumentUploadPreview
