import { Button } from '@nextbusiness/infinity-ui'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import React from 'react'

interface DocumentsHomeHeaderProps {
  openCreateReceiptModal: () => void
}

const DocumentsHomeHeader = (props: DocumentsHomeHeaderProps) => {
  return (
    <MagicSheetTitleBar title='Dokumente'>
      <Button
        variant='primary'
        iconLeft='plus'
        onClick={props.openCreateReceiptModal}
      >
        Neuer Beleg
      </Button>
    </MagicSheetTitleBar>
  )
}

export default DocumentsHomeHeader
