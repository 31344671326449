import { motion } from 'framer-motion'

interface InitialSetupTransitionGroupProps {
  children: React.ReactNode
  staggerDelay?: number
}

const InitialSetupTransitionGroup = (
  props: InitialSetupTransitionGroupProps
) => (
  <motion.div
    initial={{ opacity: 0, translateY: -50 }}
    animate={{ opacity: 1, translateY: 0 }}
    transition={{
      type: 'spring',
      stiffness: 200,
      damping: 50,
      mass: 0.9,
      delay: 0.5 + (props.staggerDelay ?? 0),
    }}
  >
    {props.children}
  </motion.div>
)

export default InitialSetupTransitionGroup
