import { Button, useNotificationCenter } from '@nextbusiness/infinity-ui'
import { isBackendError } from 'libs/networking/Errors'
import CustomerInvoices from 'networking/CustomerInvoices'
import { useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import DocumentSidebarPanelProps from '../DocumentSidebarPanelProps'
import CustomerInvoiceShareDialog from './CustomerInvoiceShareDialog'

const CustomerInvoiceShareAction = (props: DocumentSidebarPanelProps) => {
  const notificationCenter = useNotificationCenter()
  const intercom = useIntercom()
  const invoiceType = props.document.isQuote ? 'Offerte' : 'Forderung'

  const [isShareDialogOpen, setIsShareDialogOpen] = useState<boolean>(false)
  const [
    isShareCustomerInvoiceInProgress,
    setIsShareCustomerInvoiceInProgress,
  ] = useState<boolean>(false)

  const [shareURL, setShareURL] = useState<string>()

  const shareCustomerInvoice = async () => {
    setIsShareCustomerInvoiceInProgress(true)
    try {
      const receivedShareId = await CustomerInvoices.shareCustomerInvoice(
        props.existingInvoice!.id
      )
      intercom.trackEvent('invoice-shared-via-link')
      setShareURL(
        document.location.host +
          `/${props.document.isQuote ? 'offerte' : 'rechnung'}/` +
          receivedShareId
      )
      await props.reload('Die Forderung wurde erfolgreich geteilt.')
      setIsShareDialogOpen(true)
    } catch (error: any) {
      notificationCenter.addNotification({
        children:
          (isBackendError(error) ? error.displayMessage : error.message) ||
          `Die ${invoiceType} konnte nicht geteilt werden. Bitte versuche es erneut.`,
        variant: 'error',
      })
    } finally {
      setIsShareCustomerInvoiceInProgress(false)
    }
  }

  return (
    <>
      {!props.document.isDraft && (
        <Button
          variant='quaternary'
          iconLeft='link'
          className='sidebar-action'
          isLoading={isShareCustomerInvoiceInProgress}
          onClick={() => shareCustomerInvoice()}
          tooltip={`Teile diese ${invoiceType} über einen Link, den du deinen Kunden senden kannst.`}
        >
          {`${invoiceType} per Link teilen`}
        </Button>
      )}
      <CustomerInvoiceShareDialog
        {...props}
        isOpen={isShareDialogOpen}
        onDismiss={() => setIsShareDialogOpen(false)}
        shareURL={shareURL}
      />
    </>
  )
}

export default CustomerInvoiceShareAction
