import { IAccount, ITransaction } from '@nextbusiness/infinity-finance-api'
import { SpecialAccount } from 'components/transactions/SpecialAccounts'

class AccountUtilities {
  public static isEligibleForLiveAccounting = (account: IAccount) => {
    const isBankAccount =
      account.accountNumber >= 1020 && account.accountNumber <= 1059
    return isBankAccount
  }

  public static shouldDisplayNegativeAmount = (
    currentAccount: IAccount | SpecialAccount,
    transaction: ITransaction
  ) => {
    const decreasingSideOfAccount =
      AccountUtilities.decreasingSideOfAccount(currentAccount)

    if (currentAccount.accountNumber === transaction.debitAccount) {
      return decreasingSideOfAccount === 'debit'
    } else {
      return decreasingSideOfAccount === 'credit'
    }
  }

  public static increasingSideOfAccount(account: IAccount | SpecialAccount) {
    if (account.accountNumber === 3800) {
      // Special case for VAT
      return 'debit'
    }
    const accountType = this.getOrInferAccountType(account)
    if (accountType === 'assets' || accountType === 'expense') {
      return 'debit'
    } else {
      return 'credit'
    }
  }

  public static decreasingSideOfAccount(account: IAccount | SpecialAccount) {
    return this.increasingSideOfAccount(account) === 'debit'
      ? 'credit'
      : 'debit'
  }

  public static getOrInferAccountType(account: IAccount | SpecialAccount) {
    const accountType = (account as IAccount).accountType
    if (accountType) {
      return accountType
    } else {
      const accountNumber = account.accountNumber
      if (accountNumber >= 1000 && accountNumber < 2000) {
        return 'assets'
      } else if (accountNumber >= 2000 && accountNumber < 3000) {
        return 'liabilities'
      } else if (accountNumber >= 3000 && accountNumber < 4000) {
        return 'income'
      } else {
        return 'expense'
      }
    }
  }
}

export default AccountUtilities
