import { BillableWork, Work } from '@nextbusiness/infinity-finance-api'

const pricingOf = (workItem: Work) => {
  if ('rate' in workItem) return 'hourly'
  else if ('price' in workItem) return 'fixed'
  else return 'non-billable'
}
const iconFor = (workItem: Work) => {
  if (pricingOf(workItem) === 'hourly') return 'timer'
  else if (pricingOf(workItem) === 'fixed') return 'estimates'
  else return 'not-allowed'
}
type WorkValue<T> = T extends BillableWork ? number : undefined
const valueOf = <T extends Work>(workItem: T): WorkValue<T> => {
  if ('rate' in workItem) {
    const hours = (workItem.hours ?? 0) + (workItem.minutes ?? 0) / 60
    return (hours * workItem.rate) as WorkValue<T>
  } else if ('price' in workItem) {
    return workItem.price as WorkValue<T>
  } else return undefined as WorkValue<T>
}
const pricingDescriptionFor = (workItem: Work) => {
  if ('rate' in workItem) {
    const hours = workItem.hours ? `${workItem.hours} h ` : ''
    const minutes = workItem.minutes ? `${workItem.minutes} min` : ''

    return `${hours}${minutes}`
  } else if ('price' in workItem) {
    return 'Pauschal'
  } else return 'Nicht abrechenbar'
}

const WorkUtilities = {
  pricingOf,
  iconFor,
  valueOf,
  pricingDescriptionFor,
}

export default WorkUtilities
