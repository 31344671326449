/*
Copyright 2020 Adobe
All Rights Reserved.

NOTICE: Adobe permits you to use, modify, and distribute this file in
accordance with the terms of the Adobe license agreement accompanying
it. If you have received this file from a source other than Adobe,
then your use, modification, or distribution of it requires the prior
written permission of Adobe.
*/

import { Component } from 'react'
import { generateRandomId } from 'utility/StringUtilities'
import AcrobatViewSDKClient from './AcrobatViewSDKClient'
import './AcrobatViewer.scss'

interface AcrobatViewerProps {
  url: string
  fileName: string
  fileId: string
}

interface AcrobatViewerState {
  id: string
}

class AcrobatViewer extends Component<AcrobatViewerProps, AcrobatViewerState> {
  public state: AcrobatViewerState
  private isSDKReady = false
  private viewSDKClient: AcrobatViewSDKClient

  constructor(props: AcrobatViewerProps) {
    super(props)
    this.state = {
      id: `acrobat-${generateRandomId()}`,
    }
    this.viewSDKClient = new AcrobatViewSDKClient(
      import.meta.env.VITE_ACROBAT_CLIENT_ID
    )
  }

  componentDidMount() {
    this.viewSDKClient.ready().then(() => {
      this.isSDKReady = true
      this.invokeFilePreview()
    })
  }

  componentDidUpdate(prevProps: Readonly<AcrobatViewerProps>) {
    if (prevProps.url !== this.props.url) {
      this.invokeFilePreview()
    }
  }

  invokeFilePreview() {
    if (!this.isSDKReady) return
    this.viewSDKClient.previewFile(
      this.state.id,
      {
        url: this.props.url,
      },
      {
        fileId: this.props.fileId,
        fileName: this.props.fileName,
      },
      {
        embedMode: 'IN_LINE',
        showZoomControl: true,
        defaultViewMode: 'FIT_WIDTH',
        showDownloadPDF: true,
        showPrintPDF: true,
      }
    )
  }

  render() {
    return (
      <div className='acrobat-viewer-container'>
        <div className='acrobat-viewer-scroll-view'>
          <div id={this.state.id} className='acrobat-viewer' />
        </div>
      </div>
    )
  }
}

export default AcrobatViewer
