import VATOptions from 'ledger/transactions/VATOptions'
import { useExpenditureCreateContext } from './ExpenditureCreateContext'

const ExpenditureVATPicker = () => {
  const expenditureCreateContext = useExpenditureCreateContext()
  return (
    <div className='expenditure-input-field'>
      <div className='vat'>
        <VATOptions
          onChange={(taxCode) =>
            expenditureCreateContext.updateTransaction({ taxCode })
          }
          taxCode={expenditureCreateContext.transaction.taxCode}
          transaction={expenditureCreateContext.transaction}
          contraAccountSide='creditAccount'
        />
      </div>
    </div>
  )
}

export default ExpenditureVATPicker
