import { Heading, NonIdealState } from '@nextbusiness/infinity-ui'
import { IconSize } from '@nextbusiness/infinity-ui-icons'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import MagicSheetPage from '../../components/magic-sheet/MagicSheetPage'
import { useRootStore } from '../../stores/RootStoreContext'
import '../shared-components/InvoicesPage.scss'
import './CustomerInvoices.scss'
import CustomerInvoicesHeader from './CustomerInvoicesHeader'
import CustomerInvoicesNoTemplateScreen from './CustomerInvoicesNoTemplateScreen'
import CustomerInvoiceDrafts from './large-list/CustomerInvoiceDrafts'
import CustomerInvoicesList from './regular-list/CustomerInvoicesList'

const QuotesOverview = () => {
  const { accountStore, templateStore, customerInvoiceStore } = useRootStore()

  useEffect(() => {
    customerInvoiceStore.loadAll()
  }, [accountStore.currentFiscalYear])

  if (!templateStore.templates || !customerInvoiceStore.allCustomerInvoices)
    return <LoadingScreen />

  const quoteDrafts = customerInvoiceStore.quoteDrafts
  const allQuotes = customerInvoiceStore.allQuotes

  const hasDrafts = quoteDrafts && quoteDrafts.length > 0
  const hasQuotes = allQuotes && allQuotes.length > 0

  const hasAnything = hasDrafts || hasQuotes

  return (
    <MagicSheetPage
      className='customer-invoices-page'
      header={<CustomerInvoicesHeader title='Offerten' isQuote />}
      containerClassName='invoices-page-container'
    >
      {!hasAnything ? (
        <CustomerInvoicesNoTemplateScreen />
      ) : (
        <>
          <div className='tabs-and-invoices-section'>
            <Heading type='h3' className='heading' bare>
              Entwürfe
            </Heading>
            <CustomerInvoiceDrafts drafts={quoteDrafts} />
          </div>
          <Heading type='h3' className='heading' bare>
            Alle Offerten
          </Heading>
          {hasQuotes ? (
            <CustomerInvoicesList invoices={allQuotes} type='quotes' />
          ) : (
            <div className='customer-invoices-empty'>
              <NonIdealState icon='purchase-order' iconSize={IconSize.Medium}>
                Hier wirst du alle Offerten sehen, die du jemals gestellt hast.
              </NonIdealState>
            </div>
          )}
        </>
      )}
    </MagicSheetPage>
  )
}

export default observer(QuotesOverview)
