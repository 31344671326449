/** The possible durations for which a VAT period can span across. */
export enum VATPeriodDuration {
  Quarter = 'quarterly',
  HalfYear = 'bi-annual',
  FullYear = 'annual',
}

/**
 * Common properties of settled and non-settled VAT periods.
 * You're likely looking for the type `VATPeriod` instead.
 */
interface AnyVATPeriod {
  _id: string
  /** The VAT period's start date as a unix timestamp in milliseconds */
  start: number
  /** The VAT period's end date as a unix timestamp in milliseconds */
  end: number
  /** The duration of this VAT period. If unset, it is assumed to be a quarter. */
  duration?: VATPeriodDuration
}

/** A VAT period that has not been settled yet. */
export interface OpenVATPeriod extends AnyVATPeriod {
  /** Whether the VAT period has been settled */
  wasSettled: false
}

/** A VAT period that has been settled. */
export interface SettledVATPeriod extends AnyVATPeriod {
  /** Whether the VAT period has been settled */
  wasSettled: true
  /** The VAT period's settlement date as a unix timestamp in milliseconds */
  settledAt: number
  /** The user id of the user that settled this VAT period */
  settledBy: string
  /** Final tax amount to pay for this VAT period in cents */
  taxPayable: number
  /** Final tax credit for this VAT period in cents */
  taxCredit: number
  /** Amount of the tax for which transactions have been posted */
  postedTaxAmount: number
  /** Transaction identifiers for tax payment transactions pertaining to this VAT period */
  paymentTransactions: string[]
}

/** Represents the information relevant for a given Digit on a VAT form. */
export interface VATReportDigit {
  /** The calculated amount in cents for this Digit. */
  amount: number
  /** List of transaction ids that were considered for the calculation of this Digit. */
  transactionIds: string[]
}

/** The calculated report for a VAT period, including all of its Digits. */
export interface VATReport {
  /** Digit 200 represents income. */
  '200': VATReportDigit
  /** Digit 205 is exempt income that the organisation has opted for (tax codes starting in `UO`). */
  '205': VATReportDigit
  /** Digit 220 is tax-exempt income, such as from exported goods (tax code `UEX`). */
  '220': VATReportDigit
  /** Digit 221 includes services offered outside of the country (tax code `ULA`). */
  '221': VATReportDigit
  /** Digit 225 includes transfers in the "Meldeverfahren" (Art. 38 MWSTG). */
  '225': VATReportDigit
  /** Digit 230 is exempt domestic income, which the organisation has not opted for (tax code `UNO`). */
  '230': VATReportDigit
  /** Digit 235 are reductions to the income such as cash discounts and rebates. */
  '235': VATReportDigit
  /** Digit 280 are other miscellaneous deductions. */
  '280': VATReportDigit
  /** Digit 289 is the sum of all deductions. */
  '289': VATReportDigit
  /** Digit 299 is the overall taxable income, calculated through Digits 200–289. */
  '299': VATReportDigit
  /** Digit 302 are goods and services sold with the standard VAT rate (7.7%) from 2018–2023. */
  '302': VATReportDigit
  /** Digit 312 are goods and services sold with the reduced VAT rate (2.5%) from 2018–2023. */
  '312': VATReportDigit
  /** Digit 342 are goods and services sold with the special VAT rate for accomodation (3.7%) from 2018–2023. */
  '342': VATReportDigit
  /** Digit 302 are goods and services sold with the standard VAT rate (8.1%) from 2024–current. */
  '303': VATReportDigit
  /** Digit 312 are goods and services sold with the reduced VAT rate (2.6%) from 2024–current. */
  '313': VATReportDigit
  /** Digit 342 are goods and services sold with the special VAT rate for accomodation (3.8%) from 2024–current. */
  '343': VATReportDigit
  /**
   * Digit 379 is the overall taxable income, calculated through Digits 302–342.
   *
   * Must be equal to Digit 299 for a valid VAT report.
   */
  '379': VATReportDigit
  /** Digit 382 represents imported services affected by import tax (tax codes `BZM77` and `BZB77`). */
  '382': VATReportDigit
  /** Digit 383 represents imported services affected by import tax (tax codes `BZM81` and `BZB81`). */
  '383': VATReportDigit
  /** Digit 399 is the total payable tax before VAT input tax deductions. */
  '399': VATReportDigit
  /** Digit 400 represents the incurred deductable VAT input tax from COGS (tax codes `VM77`, `VM37`, `VM25`). */
  '400': VATReportDigit
  /** Digit 405 represents the incurred deductable VAT input tax from other business expenses or investments (tax codes `VB77`, `VB37`, `VB25`). */
  '405': VATReportDigit
  /** Digit 410 is the correction of previously non-deductable VAT input tax, if the usage purpose has changed appropriately. */
  '410': VATReportDigit
  /** Digit 415 is the reduction of deductable VAT input tax for the extent of which the goods are not used for operational business purposes. */
  '415': VATReportDigit
  /** Digit 420 is the reduction of deductable VAT input tax if the organisation receives subsidies and similar types of non-income. */
  '420': VATReportDigit
  /** Digit 479 is the overall deductable VAT input tax after reductions. */
  '479': VATReportDigit
  /** Digit 500 is the overall payable tax after deductable VAT input tax. Zero if the organisation receives a tax credit. */
  '500': VATReportDigit
  /** Digit 510 is the overall tax credit the organisation receives if the VAT deductions exceed the total payable tax. Otherwise zero. */
  '510': VATReportDigit
  /** Digit 900 is the amount of non-income through subsidies and public grants. */
  '900': VATReportDigit
  /** Digit 910 is the amount of non-income through donations, dividends, received damages, etc. */
  '910': VATReportDigit
}

/** A VAT period is a timeframe for which VAT is reported to the government. */
type VATPeriod = OpenVATPeriod | SettledVATPeriod

/** A VAT period including its calculated VAT report. */
export type DetailedVATPeriod = VATPeriod & { report: VATReport }

export default VATPeriod
