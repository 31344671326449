import { Button, Heading, Spacer, Text } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import AssistantStepScreen, {
  AssistantStepVisual,
} from '../AssistantStepScreen'
import ClosingAccrualCard from '../components/ClosingAccrualCard'
import FinancialClosingAssistant from '../FinancialClosingAssistant'
import CreateAccrualModal from '../modals/CreateAccrualModal'

interface ClosingAccrualsStepProps {
  assistant: FinancialClosingAssistant
}

const ClosingAccrualsStep = (props: ClosingAccrualsStepProps) => {
  const { financialCloseStore } = useRootStore()
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false)

  return (
    <AssistantStepScreen
      visual={AssistantStepVisual.Accruals}
      actions={
        <>
          <Button onClick={() => props.assistant.previousStep()}>Zurück</Button>
          {financialCloseStore.financialCloseSettings?.accruals.length ? (
            <Button
              variant='primary'
              onClick={() => props.assistant.nextStep()}
            >
              Fortfahren
            </Button>
          ) : (
            <Button onClick={() => props.assistant.nextStep()}>
              Überspringen
            </Button>
          )}
        </>
      }
      depiction={
        <>
          <Heading type='h2'>Abgrenzungen</Heading>
          <Text type='paragraph'>
            Mit Abgrenzungen kannst du zeitliche Korrekturen vornehmen, damit
            deine Buchhaltung die effektiv angefallenen Tatsachen im Zeitraum
            abbildet.
          </Text>
          <Text type='paragraph'>
            Beispielsweise kannst du hiermit Aufwände, die zwar noch während dem
            Geschäftsjahr angefallen sind, aber deren Rechnung du bis zum{' '}
            {props.assistant.formattedClosingDate} noch nicht erhalten hast,
            ergänzen.
          </Text>
        </>
      }
    >
      <Button
        variant='secondary'
        iconLeft='plus'
        onClick={() => setIsCreateModalOpen(true)}
      >
        Neue Abgrenzung
      </Button>
      <Spacer size='tiny' />
      <div className='accruals-list'>
        {financialCloseStore.financialCloseSettings?.accruals?.map(
          (accrual) => (
            <ClosingAccrualCard accrual={accrual} key={accrual._id} />
          )
        )}
      </div>
      <CreateAccrualModal
        isOpen={isCreateModalOpen}
        onDismiss={() => setIsCreateModalOpen(false)}
      />
    </AssistantStepScreen>
  )
}

export default observer(ClosingAccrualsStep)
