import { NonIdealState } from '@nextbusiness/infinity-ui'
import { IconSize } from '@nextbusiness/infinity-ui-icons'
import useBackendQuery from 'libs/networking/BackendQuery'
import { observer } from 'mobx-react'
import { IMaturities } from 'model/FinancialHealth'
import FinancialHealth from 'networking/FinancialHealth'
import Tabs from 'proto-ui-components/Tabs'
import { useState } from 'react'
import FinancialHealthCard from 'reports/financial-health/components/FinancialHealthCard'
import LoaderFinancialHealthMaturities from 'reports/financial-health/loaders/LoaderFinancialHealthMaturities'
import { useRootStore } from 'stores/RootStoreContext'
import './FinancialHealthMaturitiesCard.scss'
import FinancialHealthMaturitiesRow from './FinancialHealthMaturitiesRow'

type MaturitiesTab = 'accountsPayable' | 'accountsReceivable'

const FinancialHealthMaturitiesCard = () => {
  const { authenticationStore, reportingStore } = useRootStore()

  const [maturities, isLoading, error] = useBackendQuery<IMaturities>(
    () =>
      FinancialHealth.getMaturities(authenticationStore.organisationIdentifier),
    reportingStore.setMaturities
  )
  const [currentTab, setCurrentTab] = useState<MaturitiesTab>('accountsPayable')

  const currentMaturities = maturities ? maturities[currentTab] : null
  const allMaturities = currentMaturities
    ? [
        ...currentMaturities.overdue,
        ...currentMaturities.dueToday,
        ...currentMaturities.dueTheNextSevenDays,
        ...currentMaturities.dueTheNext30Days,
        ...currentMaturities.dueLater,
      ]
    : null

  return (
    <FinancialHealthCard
      title='Fälligkeiten'
      isLoading={isLoading}
      customLoadingState={<LoaderFinancialHealthMaturities />}
      error={error}
      className='financial-health-card-maturities'
    >
      <Tabs
        currentTabId={currentTab}
        onTabSelected={(tab) => setCurrentTab(tab.identifier as MaturitiesTab)}
        tabs={[
          {
            identifier: 'accountsPayable',
            title: 'Rechnungen',
          },
          {
            identifier: 'accountsReceivable',
            title: 'Forderungen',
          },
        ]}
      />
      {allMaturities?.length === 0 && (
        <NonIdealState icon='check' iconSize={IconSize.Small}>
          Alles bezahlt
        </NonIdealState>
      )}
      {currentMaturities && (
        <>
          <FinancialHealthMaturitiesRow
            invoices={currentMaturities.overdue}
            timeframe='überfällig'
            total={currentMaturities.sumOfInvoicesOverdue}
          />
          <FinancialHealthMaturitiesRow
            invoices={currentMaturities.dueToday}
            timeframe='fällig heute'
            total={currentMaturities.sumOfInvoicesDueToday}
          />
          <FinancialHealthMaturitiesRow
            invoices={currentMaturities.dueTheNextSevenDays}
            timeframe='fällig in 7 Tagen'
            total={currentMaturities.sumOfInvoicesDueTheNextSevenDays}
          />
          <FinancialHealthMaturitiesRow
            invoices={currentMaturities.dueTheNext30Days}
            timeframe='fällig in 30 Tagen'
            total={currentMaturities.sumOfInvoicesDueTheNext30Days}
          />
          <FinancialHealthMaturitiesRow
            invoices={currentMaturities.dueLater}
            timeframe='später fällig'
            total={currentMaturities.sumOfInvoicesDueLater}
          />
        </>
      )}
    </FinancialHealthCard>
  )
}

export default observer(FinancialHealthMaturitiesCard)
