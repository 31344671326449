import { DocumentPageProps } from '../DocumentPage'
import DocumentElementClosingText from '../elements/DocumentElementClosingText'
import DocumentElementInfoBlock from '../elements/DocumentElementInfoBlock'
import DocumentElementIntroductoryText from '../elements/DocumentElementIntroductoryText'
import DocumentElementPositions from '../elements/DocumentElementPositions'
import DocumentElementTitle from '../elements/DocumentElementTitle'
import './DocumentPageBody.scss'

const DocumentPageBody = (props: DocumentPageProps) => (
  <div className='document-page-body'>
    {props.page.title && <DocumentElementTitle {...props} />}
    {props.page.infoBlock && <DocumentElementInfoBlock {...props} />}
    {props.page.introductoryText && (
      <DocumentElementIntroductoryText {...props} />
    )}
    {(props.page.positions ||
      props.page.positionsHeaderRow ||
      props.page.positionsSumRowExclVAT ||
      props.page.positionsSumRowInclVAT) && (
      <DocumentElementPositions {...props} />
    )}
    {props.page.closingText && <DocumentElementClosingText {...props} />}
  </div>
)

export default DocumentPageBody
