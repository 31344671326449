import { NumberField } from '@nextbusiness/infinity-ui'
import { useTransactionEditorContext } from '../TransactionEditorContext'

const TransactionEditorExchangeRate = () => {
  const { draftTransaction, updateDraftTransaction, canMakeChanges } =
    useTransactionEditorContext()

  if (!draftTransaction.originalCurrency) return null

  return (
    <NumberField
      bare
      className='exchange-rate'
      value={draftTransaction.exchangeRate ?? null}
      onChange={(value) =>
        updateDraftTransaction({
          exchangeRate: value ?? undefined,
        })
      }
      fullWidth
      decimalPlaces={6}
      formatToDecimalPlaces={6}
      icon='available-updates'
      disabled={!canMakeChanges}
    />
  )
}
export default TransactionEditorExchangeRate
