import { Flex } from '@nextbusiness/infinity-ui'

interface TransactionCreatorContainerProps {
  children: React.ReactNode
}

const TransactionCreatorContainer = (
  props: TransactionCreatorContainerProps
) => (
  <Flex
    className='transaction-creator'
    direction='vertical'
    horizontalAlignment='space-between'
  >
    {props.children}
  </Flex>
)

export default TransactionCreatorContainer
