import VATPeriod, { DetailedVATPeriod } from 'model/VAT'
import { BackendError, ErrorType } from '../libs/networking/Errors'
import { Current } from './Authentication'
import NetworkingConstants from './NetworkingConstants'
import NetworkingErrorHandler from './NetworkingErrorHandler'

const getAllPeriods = async (organisationId: string): Promise<VATPeriod[]> => {
  const response = await fetch(
    `${NetworkingConstants.HOST}/organisation/${organisationId}/vat-periods`,
    { method: 'GET', headers: Current.defaultHeaders }
  )
  const body = await response.json()

  if (!response.ok || !body.vatPeriods) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.vatPeriods as VATPeriod[]
}

const getDetailedVATPeriod = async (
  periodId: string,
  organisationId: string
): Promise<DetailedVATPeriod> => {
  const response = await fetch(
    `${NetworkingConstants.HOST}/organisation/${organisationId}/vat-period/${periodId}`,
    { method: 'GET', headers: Current.defaultHeaders }
  )
  const body = await response.json()

  if (!response.ok || !body.vatPeriod) {
    NetworkingErrorHandler.throwFromResponse(response, body)
  }
  return body.vatPeriod as DetailedVATPeriod
}

const settlePeriod = async (
  periodId: string,
  organisationId: string
): Promise<void> => {
  const response = await fetch(
    `${NetworkingConstants.HOST}/organisation/${organisationId}/vat-period/${periodId}/settle`,
    { method: 'POST', headers: Current.defaultHeaders }
  )
  const body = await response.json()

  if (!response.ok) {
    if (body.status === 400) {
      throw new BackendError(ErrorType.NotAllowed, body.message, {
        invalidDigits: body.negativeDigits,
      })
    } else {
      NetworkingErrorHandler.throwFromResponse(response, body)
    }
  }
}

const postPeriodPayment = async (
  periodId: string,
  organisationId: string,
  account: number,
  date: number
): Promise<void> => {
  const response = await fetch(
    `${NetworkingConstants.HOST}/organisation/${organisationId}/vat-period/${periodId}/payment`,
    {
      method: 'POST',
      headers: Current.defaultHeaders,
      body: JSON.stringify({
        account,
        date,
      }),
    }
  )
  const body = await response.json()

  if (!response.ok) {
    switch (body.status) {
      case 400:
        if (
          body.message ===
            'You must first settle this vat period before you can post a payment' ||
          body.message === 'There is no payment necessary for this vat period'
        ) {
          throw new BackendError(ErrorType.NotAllowed, body.message)
        } else {
          throw new BackendError(ErrorType.BadRequest, body.message)
        }
      default:
        NetworkingErrorHandler.throwFromResponse(response, body)
    }
  }
}

const VAT = {
  getAllPeriods,
  getDetailedVATPeriod,
  settlePeriod,
  postPeriodPayment,
}

export default VAT
