import { Dialog, Flex, LoadingIndicator, Text } from '@nextbusiness/infinity-ui'
import React from 'react'
import './ClosingInProgressModal.scss'

interface ClosingInProgressModalProps {
  isOpen?: boolean
  onDismiss: () => void
}

const ClosingInProgressModal = (props: ClosingInProgressModalProps) => (
  <Dialog className='closing-in-progress-modal' isOpen={false} {...props}>
    <Flex
      className='loading-inner'
      verticalAlignment='center'
      direction='vertical'
    >
      <LoadingIndicator />
      <Text>
        Jahresabschluss ist im Gange. Dies kann einige Momente dauern.
      </Text>
    </Flex>
  </Dialog>
)

export default ClosingInProgressModal
