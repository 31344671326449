import { Button, Flex, Text } from '@nextbusiness/infinity-ui'
import PayInvoiceModal from 'invoices/shared-components/PayInvoiceModal'
import PaymentStatusRow from 'invoices/shared-components/PaymentStatusRow'
import useBackendQuery from 'libs/networking/BackendQuery'
import { observer } from 'mobx-react'
import ICustomerInvoice from 'model/CustomerInvoice'
import CustomerInvoices from 'networking/CustomerInvoices'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import SidebarGroup from '../../SidebarGroup'
import './DocumentSidebarPaymentPanel.scss'
import SmallTransactionView from './SmallTransactionView'

interface DocumentSidebarPaymentPanelProps {
  invoice: ICustomerInvoice
  setInvoice: (invoice: ICustomerInvoice) => void
}

const DocumentSidebarPaymentPanel = (
  props: DocumentSidebarPaymentPanelProps
) => {
  const { preferencesStore } = useRootStore()

  const [isPayInvoiceModalOpen, setIsPayInvoiceModalOpen] =
    useState<boolean>(false)
  const [isCurrentPaymentDiscount, setIsCurrentPaymentDiscount] =
    useState<boolean>(false)

  const [, , , invalidateQuery] = useBackendQuery(
    () => CustomerInvoices.getCustomerInvoice(props.invoice.id),
    (loadedInvoice) => props.setInvoice(loadedInvoice)
  )

  const isVATEnabled = preferencesStore.organisationPreferences?.VAT
  const isFullyPaid = props.invoice.totalRestAmount === 0

  if (props.invoice.isAnnulled)
    return (
      <SidebarGroup className='customer-invoice-payment-panel'>
        <Text variant='warning'>Diese Forderung ist storniert.</Text>
        <Text variant='subtle' type='inline'>
          Um die Forderung erneut zu bearbeiten, stelle sie wieder her.
        </Text>
      </SidebarGroup>
    )

  return (
    <SidebarGroup className='customer-invoice-payment-panel' title='Zahlungen'>
      <PaymentStatusRow
        unpaidAmountInCents={props.invoice.totalRestAmount}
        paidAmountInCents={props.invoice.totalAmountPaid}
        totalAmountInCents={props.invoice.totalAmount}
        small
      />
      <Flex className='transactions' direction='vertical' gap='tiny'>
        {props.invoice.paymentTransactions.map((transactionID) => (
          <SmallTransactionView
            key={transactionID}
            transactionId={transactionID}
            invalidateQuery={invalidateQuery}
            accountDisplay='debit'
          />
        ))}
      </Flex>
      {!isFullyPaid && (
        <>
          <Button
            variant='quaternary'
            iconLeft='plus'
            className='sidebar-action'
            onClick={() => setIsPayInvoiceModalOpen(true)}
            disabled={isFullyPaid}
            tooltip='Verbuche einen Zahlungseingang'
          >
            Neue Zahlung
          </Button>
          {/** Temporarily disabled due to incorrect transaction behaviour. */}
          {!isVATEnabled && (
            <Button
              variant='quaternary'
              iconLeft='plus'
              className='sidebar-action'
              onClick={() => {
                setIsCurrentPaymentDiscount(true)
                setIsPayInvoiceModalOpen(true)
              }}
              disabled={isFullyPaid}
              tooltip='Mindere den offenen Betrag, z.B. für Skonto, nachträglichen Rabatt oder Teilerlass'
            >
              Preisnachlass
            </Button>
          )}
        </>
      )}
      <PayInvoiceModal
        type='customer invoice'
        invoice={props.invoice}
        isOpen={isPayInvoiceModalOpen}
        isCustomerInvoiceDiscount={isCurrentPaymentDiscount}
        onDismiss={() => {
          setIsPayInvoiceModalOpen(false)
          setIsCurrentPaymentDiscount(false)
        }}
        onSaved={() => {
          invalidateQuery()
          setIsCurrentPaymentDiscount(false)
        }}
      />
    </SidebarGroup>
  )
}

export default observer(DocumentSidebarPaymentPanel)
