import { InputField, Text } from '@nextbusiness/infinity-ui'
import { INewAccount } from 'model/Account'
import { useMemo, useState } from 'react'
import SettingsFieldList from 'settings/general/SettingsFieldList'
import AccountGroup, { accountGroupForType } from '../data/AccountGroups'
import CardRadioGroup from './CardRadioGroup'
import './CategoryStep.scss'

interface CategoryStepProps {
  newAccount: Partial<INewAccount>
  setNewAccount: (newAccount: Partial<INewAccount>) => void
  setAccountGroups: (accountGroups: string[]) => void
}

const CategoryStep = (props: CategoryStepProps) => {
  const accountGroups = accountGroupForType(props.newAccount.accountType)
  const [searchQuery, setSearchQuery] = useState<string>('')

  const filteredAccountsGroup: AccountGroup[] = useMemo(() => {
    if (!searchQuery) return accountGroups

    const lowerCaseSearchQuery = searchQuery.toLowerCase()

    const matchingAccountGroups: AccountGroup[] = []
    const accountGroupWithMatchingSubgroups: AccountGroup[] = []

    accountGroups.forEach((accountGroup) => {
      if (accountGroup.title.toLowerCase().includes(lowerCaseSearchQuery)) {
        matchingAccountGroups.push(accountGroup)
      } else {
        const allMatchingSubGroups = accountGroup.subgroups.filter((subGroup) =>
          subGroup.title.toLowerCase().includes(lowerCaseSearchQuery)
        )
        if (allMatchingSubGroups.length > 0) {
          accountGroupWithMatchingSubgroups.push({
            title: accountGroup.title,
            subgroups: allMatchingSubGroups,
          })
        }
      }
    })

    return [...matchingAccountGroups, ...accountGroupWithMatchingSubgroups]
  }, [accountGroups, searchQuery])

  return (
    <>
      <Text>
        Wähle hier eine Kategorie aus. Das Konto wird entsprechend in deinen
        Auswertungen eingegliedert.
      </Text>
      <SettingsFieldList>
        <InputField
          className='category-step-search-field'
          placeholder='Kategorie suchen'
          icon='search'
          value={searchQuery}
          onChange={setSearchQuery}
        />
      </SettingsFieldList>
      <div className='category-step-scroll'>
        {filteredAccountsGroup.map((accountGroup) => (
          <div className='category-step-group' key={accountGroup.title}>
            <Text className='category-step-title'>{accountGroup.title}</Text>
            <CardRadioGroup
              onChange={props.setAccountGroups}
              value={props.newAccount.accountGroups}
              options={accountGroup.subgroups.map((group) => {
                return {
                  value: group.mapsToChartOfAccounts,
                  label: group.title,
                  description: group.description,
                }
              })}
            />
          </div>
        ))}
      </div>
    </>
  )
}

export default CategoryStep
