import AssetsIcon from 'assets/account-types/account-type-assets.svg'
import ExpenseIcon from 'assets/account-types/account-type-expense.svg'
import IncomeIcon from 'assets/account-types/account-type-income.svg'
import LiabilitiesIcon from 'assets/account-types/account-type-liabilities.svg'
import { AccountType } from 'model/Account'
import CardRadioGroup from './CardRadioGroup'

interface TypeOfAccountStepProps {
  accountType: AccountType | undefined
  setAccountType: (accountType: AccountType) => void
}

const TypeOfAccountStep = (props: TypeOfAccountStepProps) => {
  return (
    <CardRadioGroup
      onChange={props.setAccountType}
      value={props.accountType}
      options={[
        {
          value: 'assets',
          label: 'Vermögenskonto',
          description:
            'Geldmittel, Wertschriften und Anlagen wie Mobiliar, Fahrzeuge und weitere',
          iconURL: AssetsIcon,
        },
        {
          value: 'liabilities',
          label: 'Schuldkonto',
          description:
            'Schulden gegenüber Banken, Darlehensgeber, Eigentümern und anderen',
          iconURL: LiabilitiesIcon,
        },
        {
          value: 'income',
          label: 'Ertragskonto',
          description:
            'Einnahmen aus Geschäftstätigkeit, Nebenerwerb oder andere Erträge',
          iconURL: IncomeIcon,
        },
        {
          value: 'expense',
          label: 'Aufwandskonto',
          description:
            'Geschäftsausgaben wie Produktion, Miete, Informatik, Löhne und weitere',
          iconURL: ExpenseIcon,
        },
      ]}
    />
  )
}

export default TypeOfAccountStep
