import { APIError, Finance } from '@nextbusiness/infinity-finance-api'
import {
  Dialog,
  InputField,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import './InvoiceNumberingChangeStartDialog.scss'

interface InvoiceNumberingChangeStartDialogProps {
  isOpen: boolean
  onDismiss: () => void
}

const InvoiceNumberingChangeStartDialog = (
  props: InvoiceNumberingChangeStartDialogProps
) => {
  const { preferencesStore } = useRootStore()
  const preferences = preferencesStore.organisationPreferences

  const [customerInvoiceNumberingStart, setCustomerInvoiceNumberingStart] =
    useState<number | undefined>(1)
  const [quoteNumberingStart, setQuoteNumberingStart] = useState<
    number | undefined
  >(1)

  const [isSaving, setIsSaving] = useState<boolean>(false)
  const notificationCenter = useNotificationCenter()

  const savePreferences = async () => {
    setIsSaving(true)
    try {
      const updatedPreferences = await Finance.Organisation.setPreferences({
        customerInvoiceNumberingStart: customerInvoiceNumberingStart ?? 1,
        quoteNumberingStart: quoteNumberingStart ?? 1,
      })
      runInAction(() => {
        preferencesStore.organisationPreferences = updatedPreferences
      })
      props.onDismiss()
    } catch (error) {
      notificationCenter.addNotification({
        variant: 'error',
        title: 'Nummerierungs-Einstellungen konnten nicht gespeichert werden',
        children: (error as APIError).humanText('de'),
      })
    } finally {
      setIsSaving(false)
    }
  }

  useEffect(() => {
    if (props.isOpen && preferences) {
      setCustomerInvoiceNumberingStart(
        preferences.customerInvoiceNumberingStart ?? 1
      )
      setQuoteNumberingStart(preferences.quoteNumberingStart ?? 1)
    }
  }, [preferences, props.isOpen])

  return (
    <Dialog
      className='invoice-numbering-preferences-modal'
      title='Anfangszahl ändern'
      titleProps={{ divider: true }}
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      actions={[
        { children: 'Abbrechen', onClick: props.onDismiss },
        {
          children: 'Speichern',
          onClick: savePreferences,
          isLoading: isSaving,
          variant: 'primary',
        },
      ]}
    >
      <Text>
        Hier kannst du einstellen, bei welcher Zahl die automatische
        fortlaufende Nummerierung für diese Organisation beginnen soll.
      </Text>
      <div className='numbering-start-preferences'>
        <div className='numbering-start-row'>
          <span>Forderungen beginnen bei</span>
          <InputField
            placeholder='1'
            type='number'
            value={customerInvoiceNumberingStart?.toString() ?? ''}
            onChange={(value) => {
              if (!value || isNaN(Number(value)))
                return setCustomerInvoiceNumberingStart(undefined)
              return setCustomerInvoiceNumberingStart(Number(value))
            }}
          />
        </div>
        <div className='numbering-start-row'>
          <span>Offerten beginnen bei</span>
          <InputField
            placeholder='1'
            type='number'
            value={quoteNumberingStart?.toString() ?? ''}
            onChange={(value) => {
              if (!value || isNaN(Number(value)))
                return setQuoteNumberingStart(undefined)
              return setQuoteNumberingStart(Number(value))
            }}
          />
        </div>
      </div>
    </Dialog>
  )
}

export default observer(InvoiceNumberingChangeStartDialog)
