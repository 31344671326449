import { AccountSheetFormat } from '@nextbusiness/infinity-finance-api'
import { Checkbox, RadioGroup, Text } from '@nextbusiness/infinity-ui'
import SettingsGroup from 'settings/general/SettingsGroup'

interface AccountSheetOptionsProps {
  format: AccountSheetFormat
  setFormat: (value: AccountSheetFormat) => void
  skipEmptyAccounts: boolean
  setSkipEmptyAccounts: (value: boolean) => void
  grossTransactionsOnly: boolean
  setGrossTransactionsOnly: (value: boolean) => void
}

const AccountSheetOptions = (props: AccountSheetOptionsProps) => (
  <>
    <SettingsGroup title='Format' compact>
      <RadioGroup
        value={props.format}
        onChange={props.setFormat}
        options={[
          { value: 'pdf', label: 'Bericht (PDF)' },
          { value: 'csv', label: 'Tabelle (CSV)' },
        ]}
      />
    </SettingsGroup>
    <SettingsGroup title='Optionen' compact>
      <Checkbox
        isChecked={props.skipEmptyAccounts}
        onChange={props.setSkipEmptyAccounts}
        label='Leere Konten überspringen'
      />
      <Checkbox
        isChecked={props.grossTransactionsOnly}
        onChange={(value) => {
          props.setGrossTransactionsOnly(value)
          queueMicrotask(() => {
            document
              .querySelector('.gross-transactions-only-note')
              ?.scrollIntoView({ behavior: 'smooth' })
          })
        }}
        label='Nur Bruttobuchungen anzeigen'
      />
      {props.grossTransactionsOnly && (
        <Text variant='subtle' className='gross-transactions-only-note'>
          In Aktiv- und Passivkonten werden Buchungen mit MWST nur mit dem
          kombinierten Bruttobetrag angezeigt. Nützlich, um Beträge auf dem
          Bankkonto zu kontrollieren, ist aber für kontenübergreifende Kontrolle
          nicht sinnvoll.
        </Text>
      )}
    </SettingsGroup>
  </>
)

export default AccountSheetOptions
