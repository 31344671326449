import { Button } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import DocumentSidebarPanelProps from '../DocumentSidebarPanelProps'
import CustomerInvoiceEmailDialog from './CustomerInvoiceEmailDialog'

const CustomerInvoiceEmailAction = (props: DocumentSidebarPanelProps) => {
  const { contactStore } = useRootStore()
  const recipient = contactStore.allContacts.find(
    (contact) => contact.id === props.existingInvoice?.recipient
  )
  const invoiceType = props.document.isQuote ? 'Offerte' : 'Forderung'

  const [isShareDialogOpen, setIsShareDialogOpen] = useState<boolean>(false)
  const shareCustomerInvoice = () => setIsShareDialogOpen(true)

  if (!props.existingInvoice) return null
  if (props.existingInvoice.isAnnulled) return null

  return (
    <>
      {!props.document.isDraft && (
        <Button
          variant='quaternary'
          iconLeft='envelope'
          className='sidebar-action'
          onClick={shareCustomerInvoice}
          disabled={!recipient}
          tooltip={
            recipient
              ? `Versende diese ${invoiceType} via E-Mail an deine Kunden.`
              : 'Empfänger ist erforderlich'
          }
        >
          {invoiceType} versenden
        </Button>
      )}
      <CustomerInvoiceEmailDialog
        invoice={props.existingInvoice}
        isOpen={isShareDialogOpen}
        onDismiss={() => setIsShareDialogOpen(false)}
        onShared={async () => {
          await props.reload(
            'Die Forderung wurde erfolgreich per E-Mail versendet.'
          )
        }}
      />
    </>
  )
}

export default observer(CustomerInvoiceEmailAction)
