import { BackendError, ErrorType } from '../libs/networking/Errors'
import { Contact } from '../model/Contact'
import NetworkingConstants from './NetworkingConstants'
import NetworkingErrorHandler from './NetworkingErrorHandler'

const getAllContacts = async (
  organisationIdentifier: string,
  applicationAccessToken: string
): Promise<Contact[]> => {
  const response = await fetch(
    NetworkingConstants.FABRIC_HOST +
      `/organisation/${organisationIdentifier}/datalake/contacts?applicationAccessToken=${applicationAccessToken}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const body = await response.json()

  if (!response.ok || !body.contacts) {
    if (body.message === 'Missing required permissions') {
      throw new BackendError(ErrorType.InsufficientPermissions, body.message)
    } else {
      NetworkingErrorHandler.throwFromResponse(response, body)
    }
  }
  return body.contacts as Contact[]
}

const getContact = async (
  organisationIdentifier: string,
  contactIdentifier: string,
  applicationAccessToken: string
): Promise<Contact> => {
  const response = await fetch(
    NetworkingConstants.FABRIC_HOST +
      `/organisation/${organisationIdentifier}/datalake/contact/${contactIdentifier}?applicationAccessToken=${applicationAccessToken}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const body = await response.json()

  if (!response.ok || !body.contacts || body.contacts.length < 1) {
    switch (body.message) {
      case 'Missing required permissions':
        throw new BackendError(ErrorType.InsufficientPermissions, body.message)
      case 'Contact not found or insufficient access rights':
        throw new BackendError(ErrorType.NotFound, body.message)
      default:
        throw new BackendError(ErrorType.ServerError, body?.message)
    }
  }
  return body.contacts[0] as Contact
}

const createContact = async (
  organisationIdentifier: string,
  applicationAccessToken: string,
  contact: {
    companyName?: string
    firstname?: string
    lastname?: string
    address?: string
    postCode?: string
    position?: string
    email?: string
    mobile?: string
    phone?: string
    website?: string
  }
): Promise<Contact> => {
  const response = await fetch(
    NetworkingConstants.FABRIC_HOST +
      `/organisation/${organisationIdentifier}/datalake/contact?applicationAccessToken=${applicationAccessToken}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...contact }),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.contacts) {
    switch (body.message) {
      case 'Missing required permissions':
        throw new BackendError(ErrorType.InsufficientPermissions, body.message)
      default:
        throw new BackendError(ErrorType.ServerError, body?.message)
    }
  }
  return body.contacts[0] as Contact
}

const editContact = async (
  organisationIdentifier: string,
  contactIdentifier: string,
  applicationAccessToken: string,
  contact: Partial<Contact>
): Promise<Contact> => {
  const response = await fetch(
    NetworkingConstants.FABRIC_HOST +
      `/organisation/${organisationIdentifier}/datalake/contact/${contactIdentifier}?applicationAccessToken=${applicationAccessToken}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contact),
    }
  )
  const body = await response.json()

  if (!response.ok || !body.contacts) {
    switch (body.message) {
      case 'Missing required permissions':
        throw new BackendError(ErrorType.InsufficientPermissions, body.message)
      case 'Contact not found or insufficient access rights':
        throw new BackendError(ErrorType.NotFound, body.message)
      default:
        throw new BackendError(ErrorType.ServerError, body?.message)
    }
  }
  return body.contacts[0] as Contact
}

const deleteContact = async (
  organisationIdentifier: string,
  contactIdentifier: string,
  applicationAccessToken: string
): Promise<Contact> => {
  const response = await fetch(
    NetworkingConstants.FABRIC_HOST +
      `/organisation/${organisationIdentifier}/datalake/contact/${contactIdentifier}?applicationAccessToken=${applicationAccessToken}`,
    {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const body = await response.json()

  if (!response.ok || !body.contacts) {
    switch (body.message) {
      case 'Missing required permissions':
        throw new BackendError(ErrorType.InsufficientPermissions, body.message)
      case 'Contact not found':
        throw new BackendError(ErrorType.NotFound, body.message)
      default:
        throw new BackendError(ErrorType.ServerError, body?.message)
    }
  }
  return body.contacts[0] as Contact
}

const Contacts = {
  getAllContacts,
  getContact,
  createContact,
  editContact,
  deleteContact,
}

export default Contacts
