import { useContext, useEffect, useRef } from 'react'
import DocumentRendererContext from '../DocumentRendererContext'
import EditableDocumentText from '../controls/EditableDocumentText'
import DocumentElementProps from './DocumentElementProps'

const DocumentElementTitle = (props: DocumentElementProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const renderContext = useContext(DocumentRendererContext)

  useEffect(() => {
    const title = ref.current
    if (title) {
      const titleStyle = window.getComputedStyle(title)
      const margin = parseFloat(titleStyle.marginBottom)

      renderContext.updateHeights({
        title: title.clientHeight + margin,
      })
    }
  }, [props.document.title])

  const displayedTitle =
    (props.document.title ?? '') +
    (props.existingInvoice?.isAnnulled ? ' (storniert)' : '')

  return (
    <div
      className={'page-title' + (displayedTitle.length > 42 ? ' long' : '')}
      ref={ref}
    >
      <EditableDocumentText
        onChange={(title) => {
          if (title.length > 56) return
          props.registerEdit({ title })
        }}
        isReadOnly={!props.isEditable}
        value={displayedTitle}
        placeholder='Titel hier eingeben'
      />
    </div>
  )
}

export default DocumentElementTitle
