import { Button } from '@nextbusiness/infinity-ui'
import React from 'react'
import './ExtendedInvoiceListItem.scss'

interface ExtendedInvoiceListItemContainerProps {
  children?: React.ReactNode
  onClick?: () => void
  className?: string
}

const ExtendedInvoiceListItemContainer = (
  props: ExtendedInvoiceListItemContainerProps
) => {
  return (
    <Button
      className={
        'extended-invoice-list-item' +
        (props.className ? ' ' + props.className : '')
      }
      variant='shell'
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  )
}

export default ExtendedInvoiceListItemContainer
