import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import './ReportPrintHeader.print.scss'

interface ReportPrintHeaderProps {
  title?: string
}

const ReportPrintHeader = (props: ReportPrintHeaderProps) => {
  const { preferencesStore, templateStore } = useRootStore()
  const template = templateStore.currentTemplate

  useEffect(() => {
    templateStore.loadCurrentTemplate()
  }, [])

  return (
    <div className='report-print-header'>
      <div className='company-name'>
        {preferencesStore.organisationPreferences?.legalName}
        {template && (
          <>
            , {template.addressLine}, {template.zipCode} {template.city}
          </>
        )}
      </div>
      {props.title && <div className='report-title'>{props.title}</div>}
    </div>
  )
}

export default observer(ReportPrintHeader)
