import { AmountMode, ITemplate } from '@nextbusiness/infinity-finance-api'
import { BLANK_TEMPLATE } from 'model/Template'
import CustomerInvoiceFactory from '../CustomerInvoiceFactory'
import DocumentRenderer from '../document-renderer/DocumentRenderer'
import CustomerInvoiceEditorSetupModal from './CustomerInvoiceEditorSetupModal'
import './CustomerInvoiceEditorSetupScreen.scss'

interface CustomerInvoiceEditorSetupScreenProps {
  onSetupComplete: (amountMode?: AmountMode) => void
}

const CustomerInvoiceEditorSetupScreen = (
  props: CustomerInvoiceEditorSetupScreenProps
) => (
  <>
    <div className='document-editor-wrapper document-editor-setup'>
      <DocumentRenderer
        template={BLANK_TEMPLATE}
        document={CustomerInvoiceFactory.emptyDocument(
          BLANK_TEMPLATE as ITemplate
        )}
      />
      <div className='document-editor-sidebar' />
    </div>
    <CustomerInvoiceEditorSetupModal onSetupComplete={props.onSetupComplete} />
  </>
)

export default CustomerInvoiceEditorSetupScreen
