import { UniqueIdentifier } from '@dnd-kit/core'
import {
  AccountCardProperties,
  FinancialHealthCardProperties,
  FinancialHealthCardType,
  IFinancialHealthCard,
} from '@nextbusiness/infinity-finance-api'
import { Button, Card, Flex, Text } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import MoveDots from 'components/drag-and-drop/MoveDots'
import useDragDrop from 'components/drag-and-drop/useDragDrop'
import FinancialHealthCardExplainer from '../components/FinancialHealthCardExplainer'
import AccountCardEditFields from './AccountCardEditFields'
import './FinancialHealthEditCard.scss'

export const titles = {
  [FinancialHealthCardType.HintsAndProblems]: 'Hinweise und Probleme',
  [FinancialHealthCardType.FinancialSolvency]: 'Zahlungsfähigkeit',
  [FinancialHealthCardType.Maturities]: 'Fälligkeiten',
  [FinancialHealthCardType.InspectorExpenses]: 'Aufwände',
  [FinancialHealthCardType.InspectorIncome]: 'Erträge',
  [FinancialHealthCardType.Profitability]: 'Profitabilität',
  [FinancialHealthCardType.Runway]: 'Runway',
  [FinancialHealthCardType.Account]: 'Konto',
  [FinancialHealthCardType.Metric]: 'Kennzahl',
}

interface FinancialHealthEditCardProps {
  card: IFinancialHealthCard
  editCard: (
    withId: UniqueIdentifier,
    properties: Partial<FinancialHealthCardProperties>
  ) => void
  removeCard: (withId: UniqueIdentifier) => void
  className?: string
}

const FinancialHealthEditCard = (props: FinancialHealthEditCardProps) => {
  const { renderDraggable } = useDragDrop(props.card.id)

  const body = () => {
    switch (props.card.kind) {
      case FinancialHealthCardType.Account:
        return (
          <AccountCardEditFields
            properties={props.card.properties as AccountCardProperties}
            editCard={(properties) => props.editCard(props.card.id, properties)}
          />
        )
      case FinancialHealthCardType.Metric:
        return <FinancialHealthCardExplainer kind={props.card.kind} /> // todo: replace with metric card edit fields
      default:
        return <FinancialHealthCardExplainer kind={props.card.kind} />
    }
  }

  return renderDraggable(
    <Card
      elevation='low'
      className={classNames('financial-health-card edit-card', props.className)}
    >
      <Flex
        direction='vertical'
        style={{ alignItems: 'stretch' }}
        fillContainer
      >
        <Flex
          className='financial-health-card-header'
          horizontalAlignment='space-between'
        >
          <Flex verticalAlignment='center' gap={1.6}>
            <MoveDots />
            <Text className='card-title' style={{ fontWeight: 600 }}>
              {titles[props.card.kind]}
            </Text>
          </Flex>
          <Button
            iconLeft='close'
            variant='quaternary'
            onClick={() => props.removeCard(props.card.id)}
          >
            Entfernen
          </Button>
        </Flex>
        {body()}
      </Flex>
    </Card>
  )
}

export default FinancialHealthEditCard
