import { DocumentAnalysisStatus } from '@nextbusiness/infinity-finance-api'
import ICloudFile from './CloudFile'

export enum DocumentLocation {
  /** Default location for new documents. The documents in the inbox are considered unprocessed. */
  Inbox = 'inbox',
  /** Location for documents that are attached to invoices. */
  Invoices = 'invoices',
  /** Location for documents that are attached to transactions. */
  Receipts = 'receipts',
  /** Use this location when filing a document in a custom, user-specified folder. */
  CustomFolder = 'custom-folder',
}

interface IDocument {
  id: string
  /** User-set display title of the document. */
  title: string
  /** Current virtual folder location of the document. */
  location: DocumentLocation
  /** Note message provided by the uploading user. */
  note?: string
  /** ID of the contact associated with this `Document` by the uploading user. */
  contactId?: string
  /** Custom folder this `Document` is located in, referenced by its object id.
   * Only relevant when `location` is set to `DocumentLocation.CustomFolder`. */
  customFolderId?: string
  /** User that uploaded the document initially. */
  uploadedBy: string
  /** Moment in time when the document was uploaded. */
  uploadedAt: number
  /** Moment in time when the document was processed from the inbox to another location. */
  processedAt?: number
  /** User that processed the document from the inbox to another location. */
  processedBy?: string
  /** Cloud file associated to this document */
  file: ICloudFile
  /** URL for the thumbnail image */
  thumbnail?: string
  /** The status of the Computer Vision analysis of this document. */
  analysisStatus?: DocumentAnalysisStatus
}

export default IDocument
