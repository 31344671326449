import { IAccount, INewTransaction } from '@nextbusiness/infinity-finance-api'
import { SpecialAccount } from 'components/transactions/SpecialAccounts'
import { EntrySide } from 'ledger/accounts/data/AccountRules'
import AccountUtilities from 'utility/AccountUtilities'
import DateUtilities from 'utility/DateUtilites'

class TransactionCreatorUtilities {
  public readonly currentAccount: IAccount | SpecialAccount
  public readonly currentActionSide: EntrySide
  public readonly shouldCurrentAccountIncrease: boolean

  constructor(
    currentAccount: IAccount | SpecialAccount,
    currentActionSide: EntrySide,
    shouldCurrentAccountIncrease: boolean
  ) {
    this.currentAccount = currentAccount
    this.currentActionSide = currentActionSide
    this.shouldCurrentAccountIncrease = shouldCurrentAccountIncrease
  }

  public hasEditedTransaction = (transaction: Partial<INewTransaction>) => {
    const defaultNewTransaction = this.emptyTransaction()
    return !(
      transaction.description === defaultNewTransaction.description &&
      transaction[this.contraAccountSide] ===
        defaultNewTransaction[this.contraAccountSide] &&
      transaction.amount === defaultNewTransaction.amount
    )
  }

  public emptyTransaction = (): Partial<INewTransaction> => ({
    date: DateUtilities.startOfDay(new Date()).getTime(),
    description: '',
    [this.currentAccountSide]: this.currentAccount.accountNumber,
    taxCode: '',
  })

  public get currentAccountSide(): 'debitAccount' | 'creditAccount' {
    switch (this.currentActionSide) {
      case 'debit':
      case 'credit':
        return `${this.currentActionSide}Account`
      case 'ask':
        const decreasingSideOfCurrentAccount =
          AccountUtilities.decreasingSideOfAccount(this.currentAccount)
        if (decreasingSideOfCurrentAccount === 'credit')
          return this.shouldCurrentAccountIncrease
            ? 'debitAccount'
            : 'creditAccount'
        else
          return this.shouldCurrentAccountIncrease
            ? 'creditAccount'
            : 'debitAccount'
      default:
        throw new Error(
          `Unknown side for current action: ${this.currentActionSide}`
        )
    }
  }

  public get contraAccountSide() {
    if (this.currentAccountSide === 'debitAccount') {
      return 'creditAccount'
    } else {
      return 'debitAccount'
    }
  }
}

export default TransactionCreatorUtilities
