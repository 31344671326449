import { Flex } from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'
import { Contact } from 'model/Contact'
import { useContext } from 'react'
import { ContactContext } from './ContactContext'
import './SingleContactInfoSection.scss'
import ComponentTripletInfo from './SingleContactTripletInfo'

const SingleContactInfoSection = () => {
  const contact = useContext(ContactContext)
  if (!contact) {
    return null
  }
  const infoListFirst = [
    'address',
    'secondaryAddressLine',
    'postCode',
    'country',
  ]
  const infoListSecond = ['mobile', 'phone', 'email']
  const infoListThird = ['website', 'vatNumber']

  function checkContactProperties(contact: Contact) {
    const allProperties = [
      ...infoListFirst,
      ...infoListSecond,
      ...infoListThird,
    ]
    for (const property of allProperties) {
      if ((contact as any)[property] !== undefined) {
        return true
      }
    }
    return false
  }

  if (!checkContactProperties(contact)) {
    return null
  }

  return (
    <div className='contact-all-info'>
      <div className='contact-main-info'>
        <ComponentTripletInfo infoList={infoListFirst} />
        <ComponentTripletInfo infoList={infoListSecond} />
        <ComponentTripletInfo infoList={infoListThird} />
      </div>
      {contact.note && (
        <Flex gap={0.6}>
          <Icon icon='note' size={24} />
          <div className='contact-note-info'>{contact.note}</div>
        </Flex>
      )}
    </div>
  )
}

export default SingleContactInfoSection
