import { APIError, ErrorCode } from '@nextbusiness/infinity-finance-api'
import { useNotificationCenter } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import MagicSheetPage from '../../components/magic-sheet/MagicSheetPage'
import { IVendorInvoice } from '../../model/VendorInvoice'
import { useRootStore } from '../../stores/RootStoreContext'
import '../shared-components/InvoicesPage.scss'
import VendorInvoicesEmptyScreen from './VendorInvoicesEmptyScreen'
import VendorInvoicesHeader from './VendorInvoicesHeader'
import AllVendorInvoices from './all-invoices/AllVendorInvoices'
import UnpaidVendorInvoices from './unpaid-vendor-invoices/UnpaidVendorInvoices'

interface VendorInvoicesProps {
  openCreateInvoiceModal: () => void
  openInvoice: (invoice: IVendorInvoice) => void
}

const VendorInvoices = (props: VendorInvoicesProps) => {
  const { authenticationStore, vendorInvoiceStore, accountStore } =
    useRootStore()
  const notificationCenter = useNotificationCenter()

  const [hasLoadedInvoices, setHasLoadedInvoices] = useState<boolean>(false)

  const getInvoices = async () => {
    if (!authenticationStore.organisationIdentifier)
      return authenticationStore.logout()
    try {
      await vendorInvoiceStore.loadInvoices()
      setHasLoadedInvoices(true)
    } catch (error: any) {
      if (error instanceof APIError) {
        if (error.code === ErrorCode.Unauthorised) return
      }
      notificationCenter.addNotification({
        children:
          error.displayMessage ?? 'Die Rechnungen konnten nicht geladen werden',
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    accountStore.loadAccounts()
    getInvoices()
  }, [])

  return (
    <MagicSheetPage
      className='vendor-invoices-page'
      header={
        <VendorInvoicesHeader
          openCreateInvoiceModal={props.openCreateInvoiceModal}
        />
      }
      containerClassName='invoices-page-container'
    >
      {hasLoadedInvoices && vendorInvoiceStore.allInvoices.length === 0 ? (
        <VendorInvoicesEmptyScreen />
      ) : (
        <>
          <UnpaidVendorInvoices openVendorInvoice={props.openInvoice} />
          <AllVendorInvoices
            isLoading={!hasLoadedInvoices}
            openVendorInvoice={props.openInvoice}
          />
        </>
      )}
    </MagicSheetPage>
  )
}

export default observer(VendorInvoices)
