import { APIError, ICompleteDocument } from '@nextbusiness/infinity-finance-api'
import { LoadingIndicator } from '@nextbusiness/infinity-ui'
import { useLedgerContext } from 'ledger/LedgerContext'
import TransactionAttachmentMenu from './TransactionAttachmentMenu'

interface TransactionAttachmentFlyoutContentProps {
  isLoadingAttachments: boolean
  transactionAttachments: (ICompleteDocument | APIError)[]
  closeMenu: () => void
  openUploadModal: () => void
}

const TransactionAttachmentFlyoutContent = (
  props: TransactionAttachmentFlyoutContentProps
) => {
  const { openDocumentPageModally } = useLedgerContext()

  return props.isLoadingAttachments ? (
    <LoadingIndicator
      style={{
        paddingTop: '2rem',
        paddingBottom: '2rem',
        paddingLeft: '3rem',
        overflow: 'hidden',
      }}
    />
  ) : (
    <TransactionAttachmentMenu
      attachments={props.transactionAttachments}
      closeMenu={props.closeMenu}
      onAttachmentClicked={openDocumentPageModally}
      onAddNewAttachmentClicked={props.openUploadModal}
    />
  )
}

export default TransactionAttachmentFlyoutContent
