import { MenuItemGroup } from '@nextbusiness/infinity-ui'
import MoreMenu from 'components/more-menu/MoreMenu'
import DocumentPositionTextUtilities from '../DocumentPositionTextUtilities'
import DocumentPositionUtilities from '../DocumentPositionUtilities'
import { DocumentPositionFlyoutProps } from './DocumentPositionFlyout'

const DocumentCustomProductPositionMoreMenu = (
  props: DocumentPositionFlyoutProps
) => {
  if (props.position.type !== 'custom-product') return null

  const productActions: MenuItemGroup = {
    title: 'Produkt ausgewählt',
    buttons: [
      {
        text: 'Produkt entkoppeln',
        onClick: () =>
          DocumentPositionUtilities.unlinkProductFromPosition(props),
        icon: 'link-broken',
      },
      {
        text: 'Produkt entfernen',
        onClick: () => {
          DocumentPositionUtilities.resetPosition(props)
          props.setIsActive(false)
        },
        icon: 'undo',
      },
    ],
  }
  const positionActions: MenuItemGroup = {
    title: 'Position',
    buttons: [
      {
        text: DocumentPositionTextUtilities.hasBulletPoints(
          props.position.name ?? ''
        )
          ? 'Aufzählung entfernen'
          : 'Aufzählung einfügen',
        onClick: () =>
          DocumentPositionUtilities.togglePositionFormatting(props),
        icon: 'bulleted-list',
      },
      {
        text: 'Duplizieren',
        onClick: () => {
          props.setIsActive(false)
          props.duplicatePosition(props.position.identifier)
        },
        icon: 'add-row',
      },
    ],
  }

  const actions: MenuItemGroup[] = []

  if (props.position.product) {
    actions.push(productActions)
  }

  // General position actions should be always last, before context-specific actions
  actions.push(positionActions)

  return <MoreMenu className='position-more-menu' iconOnly actions={actions} />
}

export default DocumentCustomProductPositionMoreMenu
