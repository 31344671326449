import { RadioGroupOption } from '@nextbusiness/infinity-ui'
import { IconItem } from '@nextbusiness/infinity-ui-icons'
import CardRadioOption from './CardRadioOption'

interface CardRadioGroupOption extends Omit<RadioGroupOption, 'label'> {
  label: React.ReactNode
  iconURL?: string
  icon?: IconItem
  description?: string
}

interface CardRadioGroupProps {
  options: CardRadioGroupOption[]
  onChange: (selectedValue: any) => void
  value: any
  compact?: boolean
}

const CardRadioGroup = (props: CardRadioGroupProps) => {
  return (
    <>
      {props.options.map((option) => (
        <CardRadioOption
          key={option.value}
          {...option}
          label={option.label}
          isChecked={props.value === option.value}
          onOptionSelected={() => props.onChange(option.value)}
          compact={props.compact}
        />
      ))}
    </>
  )
}

export default CardRadioGroup
