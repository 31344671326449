class DocumentPositionTextUtilities {
  private static readonly BULLET_POINT = '• '

  /**
   * Returns true if the text contains bullet points.
   */
  public static hasBulletPoints = (text: string) => {
    return text.includes('\n' + this.BULLET_POINT)
  }

  /**
   * Returns true if the editor should auto-continue new bullet points
   * when the user presses enter.
   */
  public static shouldContinueBulletPoints = (
    text: string,
    atCaretPosition: number
  ) => {
    const lines = text.substring(0, atCaretPosition).split('\n') ?? ['']
    const lastLine = lines[lines.length - 1]

    return (
      lastLine.startsWith(this.BULLET_POINT) && lastLine !== this.BULLET_POINT
    )
  }

  /**
   * Returns a new text and updated caret position after pressing enter
   * with an insertion of a new bullet point.
   */
  public static lineWithNewBulletPoint = (
    text: string,
    atCaretPosition: number
  ) => {
    const textBeforeCaret = text.substring(0, atCaretPosition)
    const textAfterCaret = text.substring(atCaretPosition)
    const insertedLine = '\n' + this.BULLET_POINT

    return {
      updatedText: textBeforeCaret + insertedLine + textAfterCaret,
      updatedCaretPosition: textBeforeCaret.length + insertedLine.length,
    }
  }

  /**
   * Transforms an existing text to either add or remove bullet point
   * formatting.
   */
  public static transformPositionFormatting = (
    text: string,
    useBulletPoints: boolean | undefined
  ) => {
    if (useBulletPoints) {
      return this.addBulletPoints(text)
    } else {
      return this.removeBulletPoints(text)
    }
  }

  private static addBulletPoints = (text: string) => {
    // Add bullet points to each non-empty line starting from the second line
    const lines = text.split('\n')
    const bulletPointLines = lines.map((line, index) => {
      if (index === 0) return line
      if (!line.trim()) return line
      return this.BULLET_POINT + line
    })
    // If it's only one line or the last line is empty, add an empty bullet point at
    // the end to allow the user to add more lines
    if (lines.length === 1) {
      bulletPointLines.push(this.BULLET_POINT)
    } else if (!lines[lines.length - 1].trim()) {
      bulletPointLines[bulletPointLines.length - 1] = this.BULLET_POINT
    }
    return bulletPointLines.join('\n')
  }

  private static removeBulletPoints = (text: string) => {
    // Remove bullet points from each line
    const lines = text.split('\n')
    const nonBulletPointLines = lines.map((line) => {
      if (line.startsWith(this.BULLET_POINT)) return line.substring(2)
      return line
    })
    // Trim empty lines at the end
    while (
      nonBulletPointLines.length > 0 &&
      !nonBulletPointLines[nonBulletPointLines.length - 1].trim()
    ) {
      nonBulletPointLines.pop()
    }
    return nonBulletPointLines.join('\n')
  }
}

export default DocumentPositionTextUtilities
