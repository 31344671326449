import { Button } from '@nextbusiness/infinity-ui'

interface CustomerInvoiceEditorSidebarToggleProps {
  isFixedSidebarToggled: boolean
  setIsFixedSidebarToggled: (isFixedSidebarToggled: boolean) => void
}

const CustomerInvoiceEditorSidebarToggle = (
  props: CustomerInvoiceEditorSidebarToggleProps
) => (
  <Button
    iconLeft={props.isFixedSidebarToggled ? 'close-pane' : 'open-pane'}
    aria-label='Sidebar anzeigen'
    variant='secondary'
    className='sidebar-toggle'
    onClick={() => props.setIsFixedSidebarToggled(!props.isFixedSidebarToggled)}
  >
    Optionen
  </Button>
)

export default CustomerInvoiceEditorSidebarToggle
