import { QuoteStatus } from '@nextbusiness/infinity-finance-api'
import InvoiceFilterUtilities from 'invoices/shared-components/filters/InvoiceFilterUtilities'
import { ICustomerInvoicePreview } from 'model/CustomerInvoice'
import { useMemo } from 'react'
import {
  CustomerInvoiceStatusFilter,
  useCustomerInvoicesFilter,
} from './CustomerInvoicesFilterContext'

const useFilteredCustomerInvoices = (
  allInvoices: ICustomerInvoicePreview[] | undefined
) => {
  const { activeFilter } = useCustomerInvoicesFilter()
  const filteredInvoices = useMemo(() => {
    const { recipient, status, openingMonth } = activeFilter

    return allInvoices?.filter((invoice) => {
      const matchesRecipient = !recipient || invoice.recipient === recipient
      const matchesStatus = !status || doesInvoiceMatchStatus(invoice, status)
      const matchesOpeningMonth =
        !openingMonth ||
        InvoiceFilterUtilities.doesOpeningMonthMatch(invoice, openingMonth)

      return matchesRecipient && matchesStatus && matchesOpeningMonth
    })
  }, [allInvoices, activeFilter])
  return filteredInvoices
}

const doesInvoiceMatchStatus = (
  invoice: ICustomerInvoicePreview,
  status: CustomerInvoiceStatusFilter
) => {
  switch (status) {
    case 'paid':
      return invoice.isPaid && !invoice.isAnnulled
    case 'unpaid':
      return !invoice.isPaid
    case 'cancelled':
      return invoice.isAnnulled
    case 'open':
      return !invoice.quoteStatus || invoice.quoteStatus === QuoteStatus.Open
    case 'accepted':
      return invoice.quoteStatus === QuoteStatus.Accepted
    case 'rejected':
      return invoice.quoteStatus === QuoteStatus.Rejected
    default:
      return true
  }
}

export default useFilteredCustomerInvoices
