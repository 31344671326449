import { AmountMode } from '@nextbusiness/infinity-finance-api'
import { Button } from '@nextbusiness/infinity-ui'
import { ReactComponent as SwitchIcon } from 'assets/switch.svg'

interface AmountModeSwitcherProps {
  isUsingEffectiveVAT: boolean
  amountMode: AmountMode
  onAmountModeChange: () => void
  isReadOnly?: boolean
}

const AmountModeSwitcher = (props: AmountModeSwitcherProps) => (
  <div>
    {props.isUsingEffectiveVAT ? (
      <Button
        variant='quaternary'
        className='amount-mode-switcher'
        onClick={props.onAmountModeChange}
        tooltip={
          props.amountMode === AmountMode.Gross
            ? 'Zu Netto wechseln'
            : 'Zu Brutto wechseln'
        }
        disabled={props.isReadOnly}
      >
        {props.amountMode === AmountMode.Gross
          ? 'Gesamttotal inkl. MWST'
          : 'Gesamttotal exkl. MWST'}
        {!props.isReadOnly && (
          <SwitchIcon className='amount-mode-switcher-icon' />
        )}
      </Button>
    ) : (
      'Gesamttotal'
    )}
  </div>
)

export default AmountModeSwitcher
