import { Checkbox, Heading } from '@nextbusiness/infinity-ui'
import { TemplateInspectorProps } from '../TemplateInspector'
import TemplateInspectorGroup from '../TemplateInspectorGroup'

const TemplateInspectorVisibility = (props: TemplateInspectorProps) => {
  return (
    <TemplateInspectorGroup>
      <Heading type='h4'>Elemente anzeigen</Heading>
      {props.template.logo && (
        <Checkbox
          isChecked={!props.template.disableLogo}
          label='Logo'
          onChange={(isChecked) =>
            props.modifyTemplate({ disableLogo: !isChecked })
          }
        />
      )}
      <Checkbox
        isChecked={!props.template.disableSenderBlock}
        label='Absenderblock'
        onChange={(isChecked) =>
          props.modifyTemplate({ disableSenderBlock: !isChecked })
        }
      />
      <Checkbox
        isChecked={!props.template.disableSenderRowLine}
        label='Absenderzeile in Couvertfenster'
        onChange={(isChecked) =>
          props.modifyTemplate({ disableSenderRowLine: !isChecked })
        }
      />
      <Checkbox
        isChecked={!props.template.disableInfoBlockBackground}
        label='Hervorhebung für Infobalken'
        onChange={(isChecked) =>
          props.modifyTemplate({ disableInfoBlockBackground: !isChecked })
        }
      />
    </TemplateInspectorGroup>
  )
}

export default TemplateInspectorVisibility
