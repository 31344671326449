import CommonApplicationSwitch from 'application/CommonApplicationSwitch'
import LicensingAssistants from 'components/billing/assistants/LicensingAssistants'
import ContactsPageStack from 'contacts/ContactsPageStack'
import BankAccountContinuePage from 'ledger/accounts/onboarding/live-accounting-setup/BankAccountContinuePage'
import BankAccountSyncPage from 'ledger/accounts/onboarding/live-accounting-setup/BankAccountSyncPage'
import LiveAccountingRedirectFromBankPage from 'ledger/accounts/onboarding/live-accounting-setup/LiveAccountingRedirectFromBankPage'
import OnboardingInitialSetup from 'oobe/initial-setup/OnboardingInitialSetup'
import WelcomeCentrePage from 'oobe/welcome-centre/WelcomeCentrePage'
import DocumentsPage from 'pages/DocumentsPage'
import MaintenancePage from 'pages/MaintenancePage'
import ProjectPage from 'pages/ProjectPage'
import QuotesPage from 'pages/QuotesPage'
import { Redirect, Route } from 'react-router-dom'
import CurrencySettingsPage from 'settings/currencies/CurrencySettingsPage'
import NotificationSettingsPage from 'settings/notifications/NotificationSettingsPage'
import PresetSettingsPage from 'settings/presets/PresetSettingsPage'
import TargetSettingsPage from 'settings/profitability/TargetSettingsPage'
import SingleContactPage from '../contacts/page/SingleContactPage'
import CustomerInvoiceEditorPage from '../invoices/customer-invoices/editor/CustomerInvoiceEditorPage'
import CustomerInvoicesPage from '../pages/CustomerInvoicesPage'
import LedgerPage from '../pages/LedgerPage'
import NotFoundPage from '../pages/NotFoundPage'
import ReportPage from '../pages/ReportPage'
import VendorInvoicePage from '../pages/VendorInvoicePage'
import AuthenticatedRoute from '../routes/AuthenticatedRoute'
import FeaturesPage from '../settings/features/FeaturesPage'
import QRSettingsPage from '../settings/qr-settings/QRSettingsPage'
import TemplateEditorPage from '../settings/templates/TemplateEditorPage'
import VATSettingsPage from '../settings/vat-settings/VATSettingsPage'
import ApplicationFrame from './ApplicationFrame'
import SignInAgainModal from './SignInAgainModal'

const IS_MAINTENANCE_MODE = import.meta.env.VITE_MAINTENANCE_MODE === 'true'

const ApplicationSwitch = () => {
  if (IS_MAINTENANCE_MODE) {
    return (
      <CommonApplicationSwitch
        className='utility-page-switch'
        defaultPage='/buchen'
      >
        <Route path='*'>
          <MaintenancePage />
        </Route>
      </CommonApplicationSwitch>
    )
  }
  return (
    <ApplicationFrame>
      <CommonApplicationSwitch defaultPage='/buchen'>
        <AuthenticatedRoute path='/auswerten' component={ReportPage} />
        <AuthenticatedRoute path='/kontakte/:id' exact>
          <SingleContactPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/buchen' exact>
          <LedgerPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/buchen/synchronisieren' exact>
          <BankAccountSyncPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/blink/consent'>
          <LiveAccountingRedirectFromBankPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/blink/continue'>
          <BankAccountContinuePage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/rechnungen' component={VendorInvoicePage} />
        <AuthenticatedRoute path='/forderungen' exact>
          <CustomerInvoicesPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/offerten' exact>
          <QuotesPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/forderungen/erstellen/forderung' exact>
          <CustomerInvoiceEditorPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/forderungen/erstellen/offerte' exact>
          <CustomerInvoiceEditorPage isQuote />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/forderungen/detail/:id' exact>
          <CustomerInvoiceEditorPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/forderungen/einrichten' exact>
          <TemplateEditorPage isInitialSetupProcess />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/projekte' component={ProjectPage} />
        <AuthenticatedRoute path='/dokumente' component={DocumentsPage} />
        <AuthenticatedRoute path='/optionen' exact>
          <Redirect to='/' />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/optionen/design' exact>
          <TemplateEditorPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/optionen/qr' exact>
          <QRSettingsPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/optionen/mwst' exact>
          <VATSettingsPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/optionen/features' exact>
          <FeaturesPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/optionen/benachrichtigungen' exact>
          <NotificationSettingsPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/optionen/profitabilitaet' exact>
          <TargetSettingsPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/optionen/vorlagen' exact>
          <PresetSettingsPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/optionen/waehrungen' exact>
          <CurrencySettingsPage />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/kontakte'>
          <ContactsPageStack />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/setup'>
          <OnboardingInitialSetup />
        </AuthenticatedRoute>
        <AuthenticatedRoute path='/welcome'>
          <WelcomeCentrePage />
        </AuthenticatedRoute>
        <Route>
          <NotFoundPage />
        </Route>
      </CommonApplicationSwitch>
      <LicensingAssistants />
      <SignInAgainModal />
    </ApplicationFrame>
  )
}

export default ApplicationSwitch
