import {
  Button,
  Heading,
  RadioGroup,
  Spacer,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import AccountSelect from 'ledger/accounts/account-select/AccountSelect'
import useBackendQuery from 'libs/networking/BackendQuery'
import { observer } from 'mobx-react-lite'
import ProfitCloseMethod from 'model/financial-close/ProfitCloseMethod'
import FinancialClose from 'networking/FinancialClose'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import Utilities from 'utility/Utilities'
import AssistantStepScreen, {
  AssistantStepVisual,
} from '../AssistantStepScreen'
import FinancialClosingAssistant from '../FinancialClosingAssistant'

interface ClosingProfitCloseStepProps {
  assistant: FinancialClosingAssistant
}

const ClosingProfitCloseStep = (props: ClosingProfitCloseStepProps) => {
  const { financialCloseStore, authenticationStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [balanceSheet, isLoading] = useBackendQuery(() =>
    FinancialClose.previewClosingBalanceSheet(
      false,
      authenticationStore.organisationIdentifier
    )
  )

  const [profitCloseMethod, setProfitCloseMethod] = useState<
    ProfitCloseMethod | null | undefined
  >(financialCloseStore.financialCloseSettings?.profitCloseMethod)

  const contraAccounts =
    financialCloseStore.financialCloseSettings?.profitCloseContraAccounts
  const [closingAccountNumber, setClosingAccountNumber] = useState<
    number | undefined
  >(contraAccounts ? contraAccounts[0] : undefined)

  const [isSaving, setIsSaving] = useState<boolean>(false)

  const profit = balanceSheet?.unbookedProfit ?? 0
  const requiresContraAccount =
    profitCloseMethod !== ProfitCloseMethod.Manual && profit !== 0

  const changeMethod = async (
    method: ProfitCloseMethod,
    withContraAccount?: number
  ) => {
    const previousMethod = profitCloseMethod
    const previousContraAccount = closingAccountNumber

    setProfitCloseMethod(method)
    if (withContraAccount) setClosingAccountNumber(withContraAccount)

    setIsSaving(true)

    try {
      await financialCloseStore.update({
        profitCloseMethod: method,
        profitCloseContraAccounts: withContraAccount
          ? [withContraAccount]
          : financialCloseStore.financialCloseSettings
              ?.profitCloseContraAccounts ?? [],
      })
    } catch (error: any) {
      notificationCenter.addNotification({
        title: 'Angaben für Erfolgsverbuchung konnte nicht gespeichert werden.',
        children: error.message ?? 'Versuche es in einigen Momenten erneut.',
        variant: 'error',
      })
      setProfitCloseMethod(previousMethod)
      setClosingAccountNumber(previousContraAccount)
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <AssistantStepScreen
      visual={AssistantStepVisual.ProfitClose}
      actions={
        <>
          <Text variant='subtle'>
            Wenn du die Erfolgsverbuchung bereits manuell gemacht hast, solltest
            du diesen Schritt überspringen.
          </Text>
          <div style={{ flexGrow: 1 }} />
          <Button onClick={() => props.assistant.previousStep()}>Zurück</Button>
          <Button
            onClick={() => props.assistant.nextStep()}
            isLoading={isSaving}
            disabled={
              isLoading || (requiresContraAccount && !closingAccountNumber)
            }
            variant={
              profitCloseMethod === ProfitCloseMethod.Manual
                ? 'tertiary'
                : 'primary'
            }
          >
            {profitCloseMethod === ProfitCloseMethod.Manual
              ? 'Überspringen'
              : 'Fortfahren'}
          </Button>
        </>
      }
      depiction={
        <>
          <Heading type='h2'>Erfolgsverbuchung</Heading>
          {!isLoading &&
            (profit >= 0 ? (
              <Text type='paragraph'>
                Du hast in diesem Geschäftsjahr einen Gewinn von{' '}
                {Utilities.centsToCHF(profit)} CHF erzielt. Wähle aus, wie du
                diesen Profit verbuchen möchtest.
              </Text>
            ) : (
              <Text type='paragraph'>
                In diesem Geschäftsjahr liegt ein Verlust von{' '}
                {Utilities.centsToCHF(profit)} CHF vor.
              </Text>
            ))}
        </>
      }
    >
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div>
          {profit >= 0 ? (
            <RadioGroup
              value={profitCloseMethod}
              onChange={(method) => changeMethod(method as ProfitCloseMethod)}
              options={[
                {
                  value: ProfitCloseMethod.KeepProfit,
                  label: 'Gewinn im Unternehmen behalten oder später auszahlen',
                },
                {
                  value: ProfitCloseMethod.Manual,
                  label:
                    'Ich habe bereits eine manuelle Gewinnverbuchung gemacht (überspringen)',
                },
              ]}
            />
          ) : (
            <RadioGroup
              value={profitCloseMethod}
              onChange={(method) => changeMethod(method as ProfitCloseMethod)}
              options={[
                {
                  value: ProfitCloseMethod.ReduceEquityByLoss,
                  label: 'Verlust mit Eigenkapital verrechnen',
                },
                {
                  value: ProfitCloseMethod.Manual,
                  label:
                    'Ich habe bereits eine manuelle Verlustverbuchung gemacht (überspringen)',
                },
              ]}
            />
          )}
          {requiresContraAccount && (
            <>
              <Spacer direction='vertical' size='tiny' />
              <Text>
                Wähle aus, in welches Gegenkonto du den Erfolg buchen möchtest.
              </Text>
              <AccountSelect
                currentAccountNumber={9200}
                initialAccountNumber={closingAccountNumber}
                onChange={(number) =>
                  changeMethod(
                    profitCloseMethod ?? ProfitCloseMethod.DistributeProfit,
                    number
                  )
                }
                suggestions={[
                  {
                    title: 'Jahresgewinn- und verlust',
                    accounts: [[2890, 2899], 2979],
                  },
                ]}
                onlyAllowSuggestedAccounts
              />
            </>
          )}
        </div>
      )}
    </AssistantStepScreen>
  )
}

export default observer(ClosingProfitCloseStep)
