import { useInitialSetupContext } from './InitialSetupContext'
import OnboardingLegalFormStep from './OnboardingLegalFormStep'
import OnboardingReviewStep from './OnboardingReviewStep'
import { OnboardingStep } from './OnboardingStepper'
import OnboardingUsageTypeStep from './OnboardingUsageTypeStep'
import OnboardingVATStep from './OnboardingVATStep'

const OnboardingSetupSteps = () => {
  const { currentStep } = useInitialSetupContext()

  if (currentStep === OnboardingStep.UsageType) {
    return <OnboardingUsageTypeStep />
  } else if (currentStep === OnboardingStep.LegalForm) {
    return <OnboardingLegalFormStep />
  } else if (currentStep === OnboardingStep.VAT) {
    return <OnboardingVATStep />
  } else if (currentStep === OnboardingStep.Review) {
    return <OnboardingReviewStep />
  }

  return null
}

export default OnboardingSetupSteps
