import {
  ErrorBoundary,
  Flex,
  LoadingIndicator,
  NonIdealState,
} from '@nextbusiness/infinity-ui'
import { DateSuggestion } from '@nextbusiness/infinity-ui/dist/input/datepicker/DatePicker'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useIntercom } from 'react-use-intercom'
import { IFiscalYear } from '../../model/FiscalYear'
import { IBalanceSheet } from '../../model/Report'
import Reports from '../../networking/Reports'
import { useRootStore } from '../../stores/RootStoreContext'
import DateUtilities from '../../utility/DateUtilites'
import { useLocalStorage, useSessionStorage } from '../../utility/hooks'
import BalanceSheet from './BalanceSheet'
import BalanceSheetHeader from './BalanceSheetHeader'
import './BalanceSheetPage.scss'

export const yearBefore = (date: Date) =>
  DateUtilities.dateSubtract(date, { years: 1 })

const BalanceSheetPage = () => {
  const { authenticationStore, accountStore } = useRootStore()
  const intercom = useIntercom()

  const [balanceSheet, setBalanceSheet] = useState<IBalanceSheet>()
  const [pastBalanceSheet, setPastBalanceSheet] = useState<IBalanceSheet>()

  const [showComparison, setShowComparison] = useSessionStorage<boolean>(
    'balance-sheet-show-comparison-' +
      authenticationStore.organisationIdentifier,
    false
  )
  const [date, setDate] = useLocalStorage<Date | undefined>(
    'balance-sheet-date-' + authenticationStore.organisationIdentifier,
    DateTime.now().toJSDate()
  )
  const [dateCopy, setDateCopy] = useState<Date>(DateTime.now().toJSDate())
  const [fiscalYearEndDates, setFiscalYearEndDates] = useState<
    DateSuggestion[]
  >([])

  const [hasScrolled, setHasScrolled] = useState<boolean>(false)

  const [allowComparison, setAllowComparison] = useState<boolean>(true)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const getBalanceSheet = async (
    fetchDate?: Date,
    isPastBalanceSheet?: boolean
  ) => {
    if (!authenticationStore.organisationIdentifier)
      return authenticationStore.logout()
    if (!fetchDate) return setErrorMessage('Bitte wähle einen validen Stichtag')
    if (!isPastBalanceSheet) setIsLoading(true)
    try {
      const loadedBalanceSheet = await Reports.getBalanceSheet(
        authenticationStore.organisationIdentifier,
        DateUtilities.endOfDay(fetchDate).valueOf()
      )
      if (isPastBalanceSheet) {
        setPastBalanceSheet(loadedBalanceSheet)
      } else {
        setBalanceSheet(loadedBalanceSheet)
      }
    } catch (error: any) {
      if (error.message === 'Could not find a current fiscal year') {
        if (isPastBalanceSheet) setPastBalanceSheet(undefined)
        else setBalanceSheet(undefined)
        return setErrorMessage(
          'Der ausgewählte Stichtag liegt ausserhalb aller Geschäftsjahre.'
        )
      }
    } finally {
      if (!isPastBalanceSheet) setIsLoading(false)
    }
  }

  const getFiscalYears = async () => {
    if (!accountStore.allFiscalYears) {
      try {
        await accountStore.getAllFiscalYears()
        setErrorMessage(undefined)
      } catch (error: any) {
        if (error.message === 'Could not find a current fiscal year')
          return setErrorMessage(
            'Es konnte kein aktuelles Geschäftsjahr gefunden werden.'
          )
      }
    }
    populateFiscalYearDates(accountStore.allFiscalYears ?? [])
  }

  const canCompareToPreviousYear = () =>
    !!(
      date &&
      accountStore.allFiscalYears &&
      accountStore.currentFiscalYear &&
      accountStore.allFiscalYears[0].from <= yearBefore(date).getTime() &&
      date.getTime() <= accountStore.currentFiscalYear?.to
    )

  const populateFiscalYearDates = (fiscalYears: IFiscalYear[]) => {
    const suggestions: DateSuggestion[] = fiscalYears.map((fiscalYear) => {
      const endDate: DateSuggestion = {
        icon: 'event',
        description: `Ende Geschäftsjahr ${fiscalYear.year + 1}`,
        date: new Date(fiscalYear.to),
      }
      return endDate
    })
    setFiscalYearEndDates(suggestions)
  }

  useEffect(() => {
    accountStore.loadAccounts()
    getFiscalYears()

    intercom.trackEvent('balance-sheet-viewed')
    MixpanelAnalytics.event('Report viewed: Balance Sheet')
  }, [])

  useEffect(() => {
    setAllowComparison(canCompareToPreviousYear())
  }, [accountStore.allFiscalYears, accountStore.currentFiscalYear])

  useEffect(() => {
    if (date) {
      setDateCopy(date)

      getBalanceSheet(date)
      const isComparisonPossible = canCompareToPreviousYear()
      if (isComparisonPossible) getBalanceSheet(yearBefore(date), true)
      setAllowComparison(isComparisonPossible)
    }
  }, [date, canCompareToPreviousYear()])

  return (
    <div className='balance-sheet-page'>
      <BalanceSheetHeader
        date={date}
        setDate={setDate}
        fiscalYearEndDates={fiscalYearEndDates}
        showComparison={showComparison}
        setShowComparison={setShowComparison}
        hasScrolled={hasScrolled}
        allowComparison={allowComparison}
        dataHasFullyLoaded={!isLoading}
      />
      {isLoading ? (
        <Flex
          className='loading-indicator-wrapper'
          verticalAlignment='center'
          horizontalAlignment='center'
        >
          <LoadingIndicator />
        </Flex>
      ) : balanceSheet ? (
        <ErrorBoundary
          errorView={
            <NonIdealState>
              Beim Anzeigen der Bilanz ist ein Fehler aufgetreten.
            </NonIdealState>
          }
        >
          <BalanceSheet
            balanceSheet={balanceSheet}
            pastBalanceSheet={pastBalanceSheet}
            date={date}
            dateCopy={dateCopy}
            showComparison={showComparison && allowComparison}
            setHasScrolled={setHasScrolled}
          />
        </ErrorBoundary>
      ) : (
        errorMessage && (
          <Flex
            className='error-wrapper'
            verticalAlignment='center'
            horizontalAlignment='center'
          >
            <NonIdealState icon='error'>{errorMessage}</NonIdealState>
          </Flex>
        )
      )}
    </div>
  )
}

export default observer(BalanceSheetPage)
