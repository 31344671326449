import { Button } from '@nextbusiness/infinity-ui'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { useIntercom } from 'react-use-intercom'
import { CustomerInvoiceEditorSidebarTopActionsProps } from './CustomerInvoiceEditorSidebarTopActions'

const EditorPDFAction = (
  props: CustomerInvoiceEditorSidebarTopActionsProps
) => {
  const intercom = useIntercom()

  if (props.document.isDraft) return null
  return (
    <Button
      isLoading={props.isGeneratingPDF}
      onClick={() => {
        props.loadPDF()
        intercom.trackEvent('pdf-created')
        MixpanelAnalytics.event('Customer invoice PDF created')
      }}
      iconLeft='file-download'
      tooltip='Als PDF herunterladen'
    >
      PDF
    </Button>
  )
}

export default EditorPDFAction
