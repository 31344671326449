import {
  DndContext,
  DragEndEvent,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable'
import { useState } from 'react'
import SmartPointerSensor from './SmartPointerSensor'

type IdentifiableObject = object & { id: UniqueIdentifier }

interface DragDropContextProps {
  children: React.ReactNode
}

/**
 * This hook is used to create a drag and drop context.
 * It allows children to be made sortable by drag and drop via the `useDragDrop` hook.
 *
 * @param items Initial values for the sortable items.
 * @returns An object containing the `DragDropContext` component and a reference to `state` and `setState` of the sortable items.
 */
const useDragDropContext = <T extends IdentifiableObject>(items?: T[]) => {
  const [state, setState] = useState<T[]>(items ?? [])

  const moveItem = (
    sourceId: UniqueIdentifier,
    destinationId: UniqueIdentifier
  ) => {
    if (sourceId === destinationId) return
    const sourceIndex = state.findIndex((item) => item.id === sourceId)
    const destinationIndex = state.findIndex(
      (item) => item.id === destinationId
    )
    if (sourceIndex === -1 || destinationIndex === -1) return
    const newItems = [...state]
    const [removed] = newItems.splice(sourceIndex, 1)
    newItems.splice(destinationIndex, 0, removed)
    setState(newItems)
  }
  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event
    if (over && active.id !== over.id) {
      moveItem(active.id, over.id)
    }
  }

  const DragDropContext = (props: DragDropContextProps) => {
    const sensor = useSensors(useSensor(SmartPointerSensor))

    return (
      <DndContext onDragEnd={onDragEnd} sensors={sensor}>
        <SortableContext items={state} strategy={rectSortingStrategy}>
          {props.children}
        </SortableContext>
      </DndContext>
    )
  }

  return { DragDropContext, state, setState }
}

export default useDragDropContext
