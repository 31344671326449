import { Dialog, useNotificationCenter } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { isBackendError } from '../../../libs/networking/Errors'
import {
  IVendorInvoice,
  IVendorInvoiceDraft,
} from '../../../model/VendorInvoice'
import VendorInvoices from '../../../networking/VendorInvoices'
import { useRootStore } from '../../../stores/RootStoreContext'
import VendorInvoiceGeneralFields from '../vendor-invoice-creation/VendorInvoiceGeneralFields'
import VendorInvoiceEditorUtilities from './VendorInvoiceEditorUtilitites'

interface EditVendorInvoiceFieldsModalProps {
  isOpen: boolean
  onDismiss: () => void
  onSaved: (updatedInvoice: IVendorInvoice) => void
  invoice: IVendorInvoiceDraft
}

const EditVendorInvoiceFieldsModal = (
  props: EditVendorInvoiceFieldsModalProps
) => {
  const { authenticationStore, accountStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [invoiceDraft, setInvoiceDraft] = useState<IVendorInvoiceDraft>({
    ...props.invoice,
  })
  const [isSavingInvoice, setIsSavingInvoice] = useState<boolean>(false)
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)

  const isValid = VendorInvoiceEditorUtilities.isValid(
    invoiceDraft,
    accountStore
  )

  const commitChanges = async () => {
    setShouldValidate(true)
    if (!isValid) return
    try {
      setIsSavingInvoice(true)
      const updatedInvoice = await VendorInvoices.updateVendorInvoice(
        authenticationStore.organisationIdentifier,
        props.invoice.id!,
        {
          title: invoiceDraft.title,
          transactionDate: invoiceDraft.openingDate,
          openingDate: invoiceDraft.openingDate,
          dueDate: invoiceDraft.dueDate,
          creditor: invoiceDraft.creditor,
        }
      )
      props.onSaved(updatedInvoice)
      props.onDismiss()
      setShouldValidate(false)
    } catch (error: any) {
      notificationCenter.addNotification({
        variant: 'error',
        children:
          (isBackendError(error) ? error.displayMessage : error.message) ??
          'Die Änderungen konnten nicht gespeichert werden.',
      })
    } finally {
      setIsSavingInvoice(false)
    }
  }

  useEffect(() => {
    if (props.isOpen) {
      setInvoiceDraft({ ...props.invoice })
      setShouldValidate(false)
    }
  }, [props.isOpen])

  return (
    <Dialog
      title='Angaben ändern'
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      actions={[
        {
          children: 'Abbrechen',
          disabled: isSavingInvoice,
          onClick: props.onDismiss,
        },
        {
          children: 'Änderungen speichern',
          isLoading: isSavingInvoice,
          onClick: commitChanges,
          variant: 'primary',
        },
      ]}
    >
      <VendorInvoiceGeneralFields
        invoice={invoiceDraft}
        onChange={setInvoiceDraft}
        shouldValidate={shouldValidate}
      />
    </Dialog>
  )
}

export default observer(EditVendorInvoiceFieldsModal)
