import classNames from 'classnames'
import AuthenticationPage from 'pages/AuthenticationPage'
import StartPage from 'pages/StartPage'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AnimatedSwitch } from 'react-router-transition'
import AuthenticatedRoute from 'routes/AuthenticatedRoute'
import { useURLQuery } from 'utility/hooks'

interface CommonApplicationSwitchProps {
  className?: string
  children: React.ReactNode
  defaultPage: string
}

const CommonApplicationSwitch = (props: CommonApplicationSwitchProps) => {
  const query = useURLQuery()

  const oneTimeToken = query.get('token')
  const organisationIdentifier = query.get('organisationIdentifier')

  const loginRedirectUrl =
    oneTimeToken && organisationIdentifier
      ? `/login?token=${encodeURIComponent(
          oneTimeToken
        )}&organisationIdentifier=${encodeURIComponent(organisationIdentifier)}`
      : '/login'

  return (
    <AnimatedSwitch
      atActive={{ opacity: 1 }}
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 0 }}
      className={classNames('application-switch', props.className)}
    >
      <AuthenticatedRoute
        path='/'
        exact
        redirectToIfUnauthenticated={loginRedirectUrl}
      >
        {oneTimeToken && organisationIdentifier ? (
          <Redirect to={loginRedirectUrl} />
        ) : (
          <StartPage redirectToOnceReady={props.defaultPage} />
        )}
      </AuthenticatedRoute>
      <Route path='/login' exact>
        <AuthenticationPage />
      </Route>
      {props.children}
    </AnimatedSwitch>
  )
}

export default CommonApplicationSwitch
