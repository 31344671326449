import { ITransaction, TaxCode } from '@nextbusiness/infinity-finance-api'
import { displayTextForTaxCode } from 'utility/TaxUtilities'
import { usePluralise } from 'utility/hooks'
import TransactionIndicator from './TransactionIndicator'
import './TransactionIndicators.scss'

interface TransactionIndicatorsProps {
  transaction: ITransaction
}

const TransactionIndicators = (props: TransactionIndicatorsProps) => {
  const numberOfAttachments = props.transaction.attachments?.length ?? 0

  const hasAttachments = numberOfAttachments > 0
  const hasVAT = Boolean(props.transaction.taxCode)

  const attachmentsLabel = usePluralise(numberOfAttachments, 'Beleg', {
    suffix: 'e',
  })
  return (
    <div className='transaction-indicators'>
      {hasAttachments && (
        <TransactionIndicator
          icon='attach'
          tooltip={attachmentsLabel}
          className='indicator--attachment'
        />
      )}
      {hasVAT && (
        <TransactionIndicator
          icon='percentage'
          tooltip={
            displayTextForTaxCode(props.transaction.taxCode as TaxCode) +
            ' MWST'
          }
          className='indicator--vat'
        />
      )}
    </div>
  )
}

export default TransactionIndicators
