import {
  Dialog,
  Flex,
  InputField,
  RadioGroup,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react'
import { INewCustomerInvoice } from 'model/CustomerInvoice'
import CustomerInvoices from 'networking/CustomerInvoices'
import { useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import './DuplicateCustomerInvoiceModal.scss'

interface DuplicateCustomerInvoiceModalProps {
  isOpen: boolean
  closeModal: () => void
  document: Partial<INewCustomerInvoice>
  navigateToInvoice: (invoiceId: string) => void
}

const DuplicateCustomerInvoiceModal = (
  props: DuplicateCustomerInvoiceModalProps
) => {
  const { authenticationStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const isQuote = props.document.isQuote

  const [titleForDuplicated, setTitleForDuplicated] = useState<string>('')
  const [typeIsQuote, setTypeIsQuote] = useState<boolean>(isQuote ?? false)
  const [isDuplicating, setIsDuplicating] = useState<boolean>(false)

  const duplicateInvoice = async () => {
    setIsDuplicating(true)
    try {
      const duplicatedCustomerInvoice =
        await CustomerInvoices.createCustomerInvoice(
          authenticationStore.organisationIdentifier,
          {
            ...props.document,
            title: titleForDuplicated || props.document.title,
            isQuote: typeIsQuote,
            transactionDate: +new Date(),
            openingDate: +new Date(),
          } as INewCustomerInvoice,
          true
        )
      props.navigateToInvoice(duplicatedCustomerInvoice.id)
      notificationCenter.addNotification({
        title: (isQuote ? 'Offerte' : 'Forderung') + ' erfolgreich dupliziert',
        children:
          'Du befindest dich jetzt in der duplizierten ' +
          (isQuote ? 'Offerte' : 'Forderung'),
        variant: 'success',
      })
      MixpanelAnalytics.event('Duplicated customer invoice')
    } catch (error: any) {
      notificationCenter.addNotification({
        title:
          (isQuote ? 'Offerte' : 'Forderung') +
          ' konnte nicht dupliziert werden',
        children: error?.message,
        variant: 'error',
      })
    } finally {
      setIsDuplicating(false)
      props.closeModal()
    }
  }

  useEffect(() => {
    const resetModal = () => {
      setTypeIsQuote(isQuote ?? false)
      setTitleForDuplicated('')
    }
    if (props.isOpen) {
      resetModal()
    }
  }, [props.isOpen])

  useEffect(() => {
    const keyDownEventHandler = (event: KeyboardEvent) => {
      if (!props.isOpen) return
      if (event.key === 'Enter') duplicateInvoice()
    }
    document.addEventListener('keyup', keyDownEventHandler)
    return () => document.removeEventListener('keyup', keyDownEventHandler)
  }, [duplicateInvoice, props.isOpen])

  return (
    <Dialog
      isOpen={props.isOpen}
      className='duplicate-customer-invoice-modal'
      title={(isQuote ? 'Offerte' : 'Forderung') + ' duplizieren'}
      titleProps={{ divider: true }}
      actions={[
        {
          children: 'Abbrechen',
          onClick: props.closeModal,
        },
        {
          variant: 'primary',
          children: 'Duplizieren',
          isLoading: isDuplicating,
          onClick: duplicateInvoice,
        },
      ]}
    >
      <Flex direction='vertical'>
        <Text className='subtitle-for-section' type='inline'>
          Name des neuen Dokuments
        </Text>
        <InputField
          value={titleForDuplicated ?? ''}
          onChange={(changedDocumentTitle) =>
            setTitleForDuplicated(changedDocumentTitle)
          }
          placeholder={props.document.title}
        />
      </Flex>
      <Text className='subtitle-for-section'>Duplizieren als</Text>
      <RadioGroup
        value={typeIsQuote}
        onChange={() => {
          setTypeIsQuote(!typeIsQuote)
        }}
        options={[
          {
            value: false,
            label: 'Forderung',
          },
          {
            value: true,
            label: 'Offerte',
          },
        ]}
      />
    </Dialog>
  )
}

export default observer(DuplicateCustomerInvoiceModal)
