import { Flex } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import ContactSwatch from 'invoices/shared-components/ContactSwatch'
import { useContext } from 'react'
import ContactUtilities from 'utility/ContactUtilites'
import { ContactContext } from './ContactContext'
import './ContactHeading.scss'
import ContactHeadingAdditionalInformation from './ContactHeadingAdditionalInformation'

const ContactHeadingInner = () => {
  const contact = useContext(ContactContext)
  if (!contact) {
    return null
  }

  const personName = ContactUtilities.personName(contact)
  // Ignore sonarlint(typescript:S6606) because of false positive. The nullish
  // coalescing operator would lead to incorrect results here.
  // https://github.com/SonarSource/SonarJS/issues/3936
  const avatarName = contact?.companyName || personName
  const displayName =
    avatarName === contact?.companyName
      ? avatarName
      : ContactUtilities.personName(contact, true)

  const showAdditionalInformation =
    contact?.position ||
    (contact?.companyName && (contact?.firstname || contact?.lastname))

  return (
    <Flex className='contact-heading' gap='tiny'>
      <ContactSwatch contactId={contact.id} />
      <Flex direction='vertical'>
        <h3
          className={classNames('contact-heading-title', {
            'with-additional-information': showAdditionalInformation,
          })}
        >
          {displayName}
        </h3>
        <ContactHeadingAdditionalInformation contact={contact} />
      </Flex>
    </Flex>
  )
}

export default ContactHeadingInner
