import ICustomerInvoice from 'model/CustomerInvoice'
import { CustomerInvoiceEditorSidebarProps } from './CustomerInvoiceEditorSidebar'
import DocumentSidebarActionsPanel from './panels/DocumentSidebarActionsPanel'
import DocumentSidebarAddPositionsPanel from './panels/DocumentSidebarAddPositionsPanel'
import DocumentSidebarCustomiseColumnsPanel from './panels/DocumentSidebarCustomiseColumnsPanel'
import DocumentSidebarCustomisePartsPanel from './panels/DocumentSidebarCustomisePartsPanel'
import DocumentSidebarDraftInfo from './panels/DocumentSidebarDraftInfo'
import DocumentSidebarQuoteStatusPanel from './panels/DocumentSidebarQuoteStatusPanel'
import DocumentSidebarPaymentPanel from './panels/payment/DocumentSidebarPaymentPanel'

const CustomerInvoiceEditorSidebarPanels = (
  props: CustomerInvoiceEditorSidebarProps
) => {
  const isInEditMode = props.hasChanges && !props.isSaving

  const showDraftInfo = props.document.isDraft
  const showPayments =
    props.existingInvoice &&
    !props.existingInvoice.isQuote &&
    !props.existingInvoice.isDraft
  const showQuoteStatus = !props.document.isDraft && props.document.isQuote

  if (isInEditMode)
    return (
      <>
        <DocumentSidebarAddPositionsPanel {...props} />
        <DocumentSidebarCustomisePartsPanel {...props} />
        <DocumentSidebarCustomiseColumnsPanel {...props} />
      </>
    )

  return (
    <>
      {showDraftInfo && (
        <DocumentSidebarDraftInfo isQuote={props.document.isQuote} />
      )}
      <DocumentSidebarActionsPanel {...props} />
      {showPayments && (
        <DocumentSidebarPaymentPanel
          invoice={props.existingInvoice!}
          setInvoice={(invoice: ICustomerInvoice) =>
            props.setExistingInvoice(invoice)
          }
        />
      )}
      {showQuoteStatus && (
        <DocumentSidebarQuoteStatusPanel
          document={props.document}
          existingInvoice={props.existingInvoice}
          setExistingInvoice={(invoice: ICustomerInvoice) =>
            props.setExistingInvoice(invoice)
          }
        />
      )}
    </>
  )
}

export default CustomerInvoiceEditorSidebarPanels
