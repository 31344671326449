import { IDocument } from '@nextbusiness/infinity-finance-api'
import { Spacer } from '@nextbusiness/infinity-ui'
import { useExpenditureCreateContext } from 'documents/expenditures/ExpenditureCreateContext'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import TaxUtilities from 'utility/TaxUtilities'
import ExpenditureAccountSelect from './ExpenditureAccountSelect'
import ExpenditureCreditInputForm from './ExpenditureCreditInputForm'
import ExpenditureDatePicker from './ExpenditureDatePicker'
import ExpenditureDescriptionInput from './ExpenditureDescriptionInput'
import './ExpenditureEntryForm.scss'
import ExpenditureVATPicker from './ExpenditureVATPicker'
import NoteBox from './NoteBox'

interface ExpenditureEntryFormProps {
  document: IDocument
}

const ExpenditureEntryForm = (props: ExpenditureEntryFormProps) => {
  const { preferencesStore, accountStore } = useRootStore()

  const expenditureCreateContext = useExpenditureCreateContext()
  const helperText = 'Erforderlich'

  const errorFields = (condition: boolean) => ({
    ...(expenditureCreateContext.shouldValidate && condition
      ? { hasError: true, helperText }
      : {}),
  })

  return (
    <div className='expenditure-form-container'>
      Hiermit kannst du den Beleg "{props.document.title}" als Aufwand
      verbuchen.
      <NoteBox document={props.document} />
      <ExpenditureDatePicker />
      <ExpenditureDescriptionInput errorFields={errorFields} />
      <ExpenditureAccountSelect
        currentAccountNumber={expenditureCreateContext.transaction.debitAccount}
        onChange={(debitAccount) => {
          expenditureCreateContext.updateTransaction({
            debitAccount,
            taxCode:
              TaxUtilities.autoSuggestVAT(
                {
                  number: debitAccount,
                  account: accountStore.find(debitAccount),
                },
                preferencesStore.organisationPreferences,
                expenditureCreateContext.transaction.date
              ) ?? expenditureCreateContext.transaction.taxCode,
          })
        }}
        suggestions={[{ title: 'Aufwände', accounts: ['expense'] }]}
        title='Aufwand'
        inputFieldProps={errorFields(
          !expenditureCreateContext.transaction.debitAccount
        )}
      />
      <ExpenditureVATPicker />
      <ExpenditureCreditInputForm errorFields={errorFields} />
      <Spacer />
    </div>
  )
}

export default observer(ExpenditureEntryForm)
