import { ITemplate } from '@nextbusiness/infinity-finance-api'
import { Flex } from '@nextbusiness/infinity-ui'
import ICustomerInvoice, { INewCustomerInvoice } from 'model/CustomerInvoice'
import DocumentElementPositions from '../editor/document-renderer/elements/DocumentElementPositions'
import DocumentPositionsDragDropContext from '../editor/document-renderer/elements/positions/DocumentPositionsDragDropContext'

interface PublicInvoiceContentProps {
  invoice: ICustomerInvoice
  document: Partial<INewCustomerInvoice>
  template: ITemplate
}

const PublicInvoiceContent = (props: PublicInvoiceContentProps) => (
  <Flex className='invoice-content' gap={3.2} direction='vertical'>
    <div className='info-block'>{props.invoice.introductoryText}</div>
    <DocumentPositionsDragDropContext
      // needed as children of DocumentElementPositions expect this context to be available and crash otherwise
      document={props.document}
      template={props.template}
      registerEdit={() => {}}
    >
      <DocumentElementPositions
        document={props.document}
        registerEdit={() => {}}
        template={props.template}
        isEditable={false}
      />
    </DocumentPositionsDragDropContext>
    <div className='info-block closing-text'>{props.invoice.closingText}</div>
  </Flex>
)

export default PublicInvoiceContent
