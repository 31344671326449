import {
  Button,
  Checkbox,
  DatePicker,
  DateSuggestion,
  Text,
} from '@nextbusiness/infinity-ui'
import BalanceSheetIcon from 'assets/balance-sheet-icon.svg'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import './BalanceSheetHeader.scss'

interface BalanceSheetHeaderProps {
  date: Date | undefined
  setDate: (date: Date | undefined) => void
  fiscalYearEndDates: DateSuggestion[]
  showComparison: boolean
  setShowComparison: (showComparison: boolean) => void
  hasScrolled: boolean
  allowComparison: boolean
  dataHasFullyLoaded: boolean
}

const BalanceSheetHeader = (props: BalanceSheetHeaderProps) => (
  <div
    className={
      'balance-sheet-header' + (props.hasScrolled ? ' has-scrolled' : '')
    }
  >
    <MagicSheetTitleBar title='Bilanz' />
    <div className='depiction-grid'>
      <div className='illustration'>
        <img src={BalanceSheetIcon} alt='' height='96' />
      </div>
      <Text className='description'>
        In der Bilanz siehst du eine Gegenüberstellung von allen Vermögen und
        Schulden an einem bestimmten Stichtag.
      </Text>
      <DatePicker
        value={props.date}
        onChange={props.setDate}
        additionalDates={props.fiscalYearEndDates}
      />
      <Checkbox
        className='checkbox'
        isChecked={props.showComparison && props.allowComparison}
        onChange={props.setShowComparison}
        isDisabled={!props.allowComparison}
        label='Veränderung zum Vorjahr'
      />
      {props.dataHasFullyLoaded && (
        <Button
          className='pdf-button'
          onClick={() => window.print()}
          iconLeft='print'
          tooltip='Bilanz drucken oder als PDF abspeichern'
        >
          Drucken
        </Button>
      )}
    </div>
  </div>
)

export default BalanceSheetHeader
