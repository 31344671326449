import { ITemplate } from '@nextbusiness/infinity-finance-api'
import { Button, Flex, Text } from '@nextbusiness/infinity-ui'
import ICustomerInvoice, { QuoteStatus } from 'model/CustomerInvoice'
import { useState } from 'react'
import PublicQuotePageDialog from '../PublicQuotePageDialog'

interface PublicQuoteSidebarActionsProps {
  quote: ICustomerInvoice
  template: ITemplate
  shareId: string
  onDownload: () => void
}

const PublicQuoteSidebarActions = (props: PublicQuoteSidebarActionsProps) => {
  const [isPublicQuotePageDialogOpen, setIsPublicQuotePageDialogOpen] =
    useState<boolean>(false)

  return (
    <>
      <Flex className='sidebar-actions' gap={1} wrap>
        {props.quote.quoteStatus !== QuoteStatus.Accepted && (
          <Button
            variant='primary'
            iconLeft='check'
            onClick={() => setIsPublicQuotePageDialogOpen(true)}
          >
            Akzeptieren
          </Button>
        )}
        <PublicQuotePageDialog
          shareId={props.shareId}
          organisationName={props.template.organisationName}
          isOpen={isPublicQuotePageDialogOpen}
          onClose={() => setIsPublicQuotePageDialogOpen(false)}
          invalidateUsingNewQuote={() => document.location.reload()}
        />
        {props.quote.publicPdfUrl && (
          <Button
            variant='tertiary'
            onClick={props.onDownload}
            iconLeft='file-download'
          >
            PDF
          </Button>
        )}
      </Flex>
      {props.quote.quoteStatus === QuoteStatus.Accepted && (
        <Text className='accepted-replacement' variant='subtle'>
          Angebot wurde akzeptiert.
        </Text>
      )}
    </>
  )
}

export default PublicQuoteSidebarActions
