import { Heading } from '@nextbusiness/infinity-ui'
import React from 'react'

interface SidebarGroupProps {
  title?: string
  children?: React.ReactNode
  className?: string
}

const SidebarGroup = (props: SidebarGroupProps) => (
  <div
    className={'sidebar-group' + (props.className ? ' ' + props.className : '')}
  >
    {props.title && <Heading type='h4'>{props.title}</Heading>}
    <div className='group-inner'>{props.children}</div>
  </div>
)

export default SidebarGroup
