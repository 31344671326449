import { Button } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import { ReactComponent as CheckIcon } from '../../../assets/check.svg'
import './LedgerOnboardingTask.scss'

interface LedgerOnboardingTaskProps {
  isDone?: boolean
  title: string
  description: string
  actionText?: string
  actionTextAfterDone?: string
  onClick?: () => void
  canBeDoneAgain?: boolean
}

const LedgerOnboardingTask = (props: LedgerOnboardingTaskProps) => (
  <div
    className={classNames('ledger-onboarding-task', {
      'is-done': props.isDone,
    })}
  >
    <div className='status'>{props.isDone && <CheckIcon />}</div>
    <div className='depiction'>
      <div className='title'>{props.title}</div>
      <div className='description'>{props.description}</div>
    </div>
    <div className='action'>
      <Button
        onClick={props.onClick}
        variant={props.isDone ? 'tertiary' : 'primary'}
        disabled={props.isDone && !props.canBeDoneAgain}
        iconLeft={props.isDone && !props.canBeDoneAgain ? 'check' : undefined}
      >
        {props.isDone && !props.canBeDoneAgain
          ? 'Erledigt'
          : props.isDone
          ? props.actionTextAfterDone ?? props.actionText
          : props.actionText}
      </Button>
    </div>
  </div>
)

export default LedgerOnboardingTask
