import { observer } from 'mobx-react-lite'
import DateUtilities from 'utility/DateUtilites'
import ICustomerInvoice, {
  ICustomerInvoicePreview,
} from '../../../model/CustomerInvoice'
import ExtendedInvoiceListItemContainer from '../../shared-components/large-invoice-list/ExtendedInvoiceListItemContainer'
import ExtendedInvoiceListItemDepiction from '../../shared-components/large-invoice-list/ExtendedInvoiceListItemDepiction'
import ExtendedCustomerInvoiceIcon from '../invoice-icons/ExtendedCustomerInvoiceIcon'

interface ExtendedCustomerInvoiceListItemProps {
  invoice: ICustomerInvoice | ICustomerInvoicePreview
  onClick?: () => void
}

const ExtendedCustomerInvoiceListItem = (
  props: ExtendedCustomerInvoiceListItemProps
) => {
  return (
    <ExtendedInvoiceListItemContainer onClick={props.onClick}>
      <ExtendedCustomerInvoiceIcon invoice={props.invoice} showAmount />
      <ExtendedInvoiceListItemDepiction
        contactId={props.invoice.recipient ?? 'no-contact'}
        title={
          props.invoice.title ??
          `Entwurf vom ${DateUtilities.humanFormat(props.invoice.openingDate)}`
        }
      />
    </ExtendedInvoiceListItemContainer>
  )
}

export default observer(ExtendedCustomerInvoiceListItem)
