import {
  APIError,
  Finance,
  INewTransaction,
  ITransaction,
} from '@nextbusiness/infinity-finance-api'
import { useNotificationCenter } from '@nextbusiness/infinity-ui'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { useCallback } from 'react'
import { useIntercom } from 'react-use-intercom'
import { store } from 'stores/RootStoreContext'
import TransactionCreatorUtilities from '../TransactionCreatorUtilities'

const useCreateTransaction = (
  transaction: Partial<ITransaction>,
  onTransactionCreated: (transactions: ITransaction[]) => void,
  setIsCreating: (isCreating: boolean) => void,
  utilities: TransactionCreatorUtilities
) => {
  const notificationCenter = useNotificationCenter()
  const intercom = useIntercom()

  const createTransaction = useCallback(async () => {
    if (!store.authenticationStore.organisationIdentifier)
      return store.authenticationStore.logout()
    if (!transaction) return

    try {
      setIsCreating(true)
      const createdTransactions = await Finance.Ledger.createTransaction(
        transaction as INewTransaction
      )
      store.transactionStore.replaceTransactionsInStore([], createdTransactions)
      store.accountStore.loadAccounts()
      onTransactionCreated(createdTransactions)
      intercom.trackEvent('transaction-created')
      MixpanelAnalytics.event('Transaction created')
    } catch (error: any) {
      const apiError = error as APIError
      return notificationCenter.addNotification({
        title: 'Transaktion konnte nicht erstellt werden',
        children: apiError.humanText('de'),
        variant: 'error',
      })
    } finally {
      setIsCreating(false)
    }
  }, [transaction, utilities, setIsCreating, notificationCenter, intercom])

  return createTransaction
}

export default useCreateTransaction
