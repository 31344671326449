import FeatureGate from 'components/billing/feature-gate/FeatureGate'
import SubscriptionCapability from 'libs/subscription/SubscriptionCapability'
import React from 'react'

interface FinancialHealthAdvancedReportingPageProps {
  children: React.ReactNode
}

const FinancialHealthAdvancedReportingPage = (
  props: FinancialHealthAdvancedReportingPageProps
) => (
  <FeatureGate requiredCapability={SubscriptionCapability.AdvancedReporting}>
    {props.children}
  </FeatureGate>
)

export default FinancialHealthAdvancedReportingPage
