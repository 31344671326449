import { RouterHistory } from '@nextbusiness/infinity-ui/dist/layout/header/HeaderNavigationTabs'
import MagicSheet, { View } from './MagicSheet'

interface NavigatableMagicSheetProps {
  history: RouterHistory
}

interface LocatableView extends Omit<View, 'id'> {
  url: string
}

abstract class NavigatableMagicSheet<P = object> extends MagicSheet<
  NavigatableMagicSheetProps & P
> {
  private currentPath: string | null = null
  private registeredViews: LocatableView[] = []

  public abstract basePath: string

  private recognisesPath(path: string): boolean {
    return this.matchingTemplatePath(path) !== null
  }

  private matchingTemplatePath(path: string): string | null {
    if (!path.startsWith(this.basePath)) return null
    return (
      this.registeredViews.find((view) =>
        NavigatableMagicSheet.doesPathMatch(view.url, path)
      )?.url ?? null
    )
  }

  private recognisesViewTitle(title: string): boolean {
    return this.registeredViews.some((view) => view.title() === title)
  }

  static doesPathMatch(templatePath: string, currentPath: string): boolean {
    const templatePathComponents = templatePath.split('/')
    const currentPathComponents = currentPath.split('/')

    if (templatePathComponents.length !== currentPathComponents.length)
      return false

    let matchingPaths = 0
    templatePathComponents.forEach((component, index) => {
      if (component === ':param' || component === currentPathComponents[index])
        matchingPaths++
    })

    return matchingPaths === templatePathComponents.length
  }

  public registerView(view: LocatableView) {
    if (this.recognisesPath(view.url)) {
      throw new Error(`View with path ${view.url} already registered`)
    }
    if (this.recognisesViewTitle(view.title())) {
      throw new Error(`View with title ${view.title()} already registered`)
    }
    this.registeredViews.push(view)
  }

  private viewStackForPath = (path: string): LocatableView[] => {
    const view = this.registeredViews.find((registeredView) =>
      NavigatableMagicSheet.doesPathMatch(registeredView.url, path)
    )
    if (!view) {
      return [...this.parentViewsForPath(path)]
    }
    return [...this.parentViewsForPath(path), view]
  }

  private parentViewsForPath = (path: string): LocatableView[] => {
    const pathSegments = path.split('/')
    if (pathSegments.length < 2) {
      return []
    }
    const parentPath = pathSegments.slice(0, pathSegments.length - 1).join('/')
    if (parentPath === '') return []
    return this.viewStackForPath(parentPath)
  }

  protected abstract registerPaths(): void

  componentDidMount() {
    this.registerPaths()
    this.onUserNavigated()
    window.addEventListener('popstate', this.onUserNavigated)
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.onUserNavigated)
  }

  componentDidUpdate() {
    if ((this.props.history as any).location.pathname !== this.currentPath) {
      this.onUserNavigated()
    }
  }

  public get currentParam(): string | null {
    const currentTemplatePath = this.matchingTemplatePath(
      document.location.pathname
    )
    if (!currentTemplatePath) return null

    const templatePathComponents = currentTemplatePath.split('/')
    const paramIndex = templatePathComponents.findIndex(
      (component) => component === ':param'
    )
    if (paramIndex === -1) return null

    const path = document.location.pathname
    const pathComponents = path.split('/')
    if (pathComponents.length <= paramIndex) return null
    return pathComponents[paramIndex]
  }

  private pathForView(view: Omit<View, 'id'> | LocatableView): string | null {
    const viewTitle = view.title
    const locatableView = this.registeredViews.find(
      (registeredView) =>
        ('url' in view && registeredView.url === view.url) ||
        registeredView.title === viewTitle
    )
    if (!locatableView) return null

    return locatableView.url
  }

  protected onNavigationStackChanged(): void {
    const currentView = this.currentViews[this.currentViews.length - 1]
    if (!currentView) return

    const updatedURL: string | null = this.pathForView(currentView)
    if (
      updatedURL &&
      !NavigatableMagicSheet.doesPathMatch(updatedURL, this.currentPath ?? '')
    ) {
      this.currentPath = document.location.pathname
      this.props.history.push(
        updatedURL.replace(':param', this.currentParam ?? '')
      )
    }
  }

  private onUserNavigated = () => {
    if (!document.location.pathname.startsWith(this.basePath)) return

    const views = this.viewStackForPath(document.location.pathname)
    if (views.length === 0) {
      document.location.href = this.basePath
      return
    }

    if (!this.currentPath) {
      this.setCurrentNavigationStack(views)
    } else if (this.currentPath !== document.location.pathname) {
      this.navigateToUpdatedStack(views)
    }

    this.currentPath = document.location.pathname
  }

  private navigateToUpdatedStack(updatedStack: Omit<View, 'id'>[]) {
    const viewActions =
      this.viewActionsForNavigatingToUpdatedStack(updatedStack)

    viewActions.forEach((action, index) =>
      window.setTimeout(() => action(), index * 250)
    )
  }

  private viewActionsForNavigatingToUpdatedStack(
    updatedStack: Omit<View, 'id'>[]
  ) {
    const alreadyOpenViews: View[] = []
    const viewsToOpen: Omit<View, 'id'>[] = []
    let viewsToClose: View[] = []

    const viewActions: (() => void)[] = []

    updatedStack.forEach((view, index) => {
      if (view.title === this.currentViews[index]?.title) {
        alreadyOpenViews.push(view)
      } else {
        viewsToOpen.push(view)
      }
    })

    if (this.currentViews.length > alreadyOpenViews.length) {
      viewsToClose = this.currentViews.slice(alreadyOpenViews.length)
    }
    viewsToClose.forEach(() => {
      viewActions.push(() => this.popCurrentView())
    })
    viewsToOpen.forEach((view) => {
      viewActions.push(() => this.presentViewModally(view))
    })
    return viewActions
  }
}

export default NavigatableMagicSheet
