import { BackendQuery } from 'libs/networking/BackendQuery'
import { IExpensesOrIncome } from 'model/FinancialHealth'
import {
  CurrentReportStackNavigatorRef,
  ReportPagePaths,
} from 'pages/ReportPage'
import FinancialHealthCard from 'reports/financial-health/components/FinancialHealthCard'
import FinancialHealthSumOverview from 'reports/financial-health/components/FinancialHealthSumOverview'
import LoaderFinancialHealthIncomeOrExpense from 'reports/financial-health/loaders/LoaderFinancialHealthIncomeOrExpenses'
import Utilities from 'utility/Utilities'
import './FinancialHealthIncomeOrExpensesCard.scss'

interface FinancialHealthIncomeOrExpensesCardProps {
  query: BackendQuery<IExpensesOrIncome>
  title: string
  linkTo: ReportPagePaths
}

const FinancialHealthIncomeOrExpensesCard = (
  props: FinancialHealthIncomeOrExpensesCardProps
) => {
  const [incomeOrExpenses, isLoading, error] = props.query

  return (
    <FinancialHealthCard
      title={props.title}
      isLoading={isLoading}
      customLoadingState={<LoaderFinancialHealthIncomeOrExpense />}
      error={error}
      className='financial-health-card-accounts'
      onClick={() => CurrentReportStackNavigatorRef?.open(props.linkTo)}
    >
      <FinancialHealthSumOverview
        currentSum={incomeOrExpenses?.sumOfAllAccounts ?? 0}
        label={`Total ${props.title}`}
        previousSum={incomeOrExpenses?.sumOfAllAccountsPastYear}
      />
      <div className='accounts-list'>
        {incomeOrExpenses?.accountsCurrentYear
          ?.filter((account) => account.balance !== 0)
          .map((account) => (
            <div className='account-list-item' key={account._id}>
              <div
                className='account-colour-swatch'
                style={{
                  backgroundColor: account.colour,
                }}
              />
              <span className='percentage'>
                {Math.round(account.percentage * 100)}%
              </span>
              <span className='title'>{account.name}</span>
              <span className='balance'>
                CHF {Utilities.centsToCHF(account.balance)}
              </span>
            </div>
          ))}
      </div>
    </FinancialHealthCard>
  )
}

export default FinancialHealthIncomeOrExpensesCard
