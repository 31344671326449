import { ITemplate } from '@nextbusiness/infinity-finance-api'
import {
  Dialog,
  Flex,
  LoadingIndicator,
  NonIdealState,
} from '@nextbusiness/infinity-ui'
import { inject, observer } from 'mobx-react'
import { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { BLANK_TEMPLATE, ITemplateDraft } from '../../model/Template'
import Templates from '../../networking/Templates'
import TemplateStore from '../../stores/TemplateStore'
import TemplateInspector from './inspector/TemplateInspector'
import TemplatePreview from './preview/TemplatePreview'

type TemplateEditorProps = RouteComponentProps & {
  templateStore?: TemplateStore
  isInitialSetupProcess?: boolean
  onTemplateSaved?: () => void
}

interface TemplateEditorState {
  template?: ITemplate | ITemplateDraft
  currentTemplateId: string | null
  hasLoaded?: boolean
  hasUnsavedChanges?: boolean
  isSaving?: boolean
  errorLoadingTemplates?: Error
  errorSavingTemplate?: Error
  showQuotePreview: boolean
}

@inject('templateStore')
@observer
class TemplateEditor extends Component<
  TemplateEditorProps,
  TemplateEditorState
> {
  state: TemplateEditorState = {
    currentTemplateId: null,
    showQuotePreview: false,
  }

  componentDidMount() {
    this.loadTemplates()
  }

  loadTemplates = async () => {
    this.setState({
      errorLoadingTemplates: undefined,
      hasLoaded: false,
    })
    try {
      await this.props.templateStore?.loadTemplates()
      if (this.props.templateStore!.templates!.length > 0) {
        await this.openTemplate(this.props.templateStore!.templates![0].id)
      } else {
        this.openEmptyTemplate()
      }
    } catch (error: any) {
      this.setState({
        errorLoadingTemplates: error as Error,
      })
    }
  }

  openTemplate = async (id: string) => {
    try {
      const fullTemplate = await Templates.getTemplate(id)
      this.setState({
        currentTemplateId: fullTemplate.id,
        template: fullTemplate,
        hasLoaded: true,
      })
    } catch (error: any) {
      this.setState({
        errorLoadingTemplates: error as Error,
      })
    }
  }

  openEmptyTemplate = () => {
    this.setState({
      currentTemplateId: null,
      template: this.generateDefaultEmptyTemplate(),
      hasLoaded: true,
    })
  }

  generateDefaultEmptyTemplate = (): ITemplateDraft => ({
    ...BLANK_TEMPLATE,
  })

  resetPropertyToDefault = (property: keyof ITemplateDraft) => {
    this.modifyTemplate({
      [property]: this.generateDefaultEmptyTemplate()[property],
    })
  }

  modifyTemplate = (changes: Partial<ITemplate>) => {
    if (!this.state.template) return
    this.setState({
      hasUnsavedChanges: true,
      template: {
        ...this.state.template,
        ...changes,
      },
    })
  }

  canSaveTemplate = () =>
    this.state.hasUnsavedChanges &&
    Boolean(
      this.state.template?.organisationName &&
        this.state.template?.addressLine &&
        this.state.template?.zipCode &&
        this.state.template?.city &&
        this.state.template?.colourValues &&
        this.state.template?.font &&
        this.state.template?.addressLinePosition
    )

  saveTemplate = () => {
    this.setState({
      isSaving: true,
      errorSavingTemplate: undefined,
    })
    this.props.onTemplateSaved?.()
    if (this.state.currentTemplateId) {
      this.saveExistingTemplate()
    } else {
      this.saveNewlyCreatedTemplate()
    }
  }

  saveExistingTemplate = async () => {
    try {
      const template = await Templates.updateTemplate(
        this.state.currentTemplateId!,
        this.state.template!
      )
      this.setState({
        isSaving: false,
        hasUnsavedChanges: false,
        template,
      })
    } catch (error: any) {
      this.setState({
        errorSavingTemplate: error as Error,
        isSaving: false,
      })
    }
  }

  saveNewlyCreatedTemplate = async () => {
    try {
      const template = await this.props.templateStore!.createTemplate({
        name: 'default',
        ...this.state.template!,
      })
      this.setState({
        isSaving: false,
        currentTemplateId: template.id,
        hasUnsavedChanges: false,
        template,
      })
      if (this.props.isInitialSetupProcess) {
        await this.props.templateStore!.loadTemplates()
        this.props.history?.push('/forderungen')
      }
    } catch (error: any) {
      this.setState({
        errorSavingTemplate: error as Error,
        isSaving: false,
      })
    }
  }

  changePreviewType = (showQuotePreview: boolean) => {
    this.setState({ showQuotePreview })
  }

  render() {
    if (this.state.errorLoadingTemplates && !this.state.template)
      return (
        <Flex
          className='template-editor-error'
          verticalAlignment='center'
          horizontalAlignment='center'
          fillContainer
        >
          <NonIdealState
            icon='error'
            actions={[
              {
                children: 'Erneut versuchen',
                variant: 'primary',
                onClick: () => this.loadTemplates(),
              },
            ]}
            title='Design konnte nicht geladen werden.'
          >
            Beim Laden des Dokumentdesign ist ein technisches Problem
            aufgetreten.
          </NonIdealState>
        </Flex>
      )

    if (!this.state.hasLoaded || !this.state.template)
      return (
        <Flex
          className='template-editor-loading'
          verticalAlignment='center'
          horizontalAlignment='center'
          fillContainer
        >
          <LoadingIndicator loadingText='Design wird geladen.' />
        </Flex>
      )

    return (
      <div className='template-editor'>
        <TemplatePreview
          template={this.state.template}
          isFirstTimeCreation={!this.state.currentTemplateId}
          showQuotePreview={this.state.showQuotePreview}
          showNotice
        />
        <TemplateInspector
          template={this.state.template}
          modifyTemplate={this.modifyTemplate}
          onSave={() => this.saveTemplate()}
          canSave={this.canSaveTemplate()}
          isSaving={this.state.isSaving}
          resetPropertyToDefault={this.resetPropertyToDefault}
          isFirstTimeCreation={!this.state.currentTemplateId}
          reloadTemplate={() =>
            this.openTemplate(this.state.currentTemplateId!)
          }
          changePreviewType={this.changePreviewType}
        />
        <Dialog
          isOpen={this.state.errorSavingTemplate !== undefined}
          onDismiss={() => this.setState({ errorSavingTemplate: undefined })}
          actions={[
            {
              children: 'Schliessen',
              onClick: () => this.setState({ errorSavingTemplate: undefined }),
            },
            {
              children: 'Erneut versuchen',
              variant: 'primary',
              onClick: () => this.saveTemplate(),
            },
          ]}
          title='Fehler beim Speichern'
          icon='error'
        >
          Das Design konnte nicht gespeichert werden. Bitte überprüfe, ob alle
          Angaben gültig sind und versuche es in einigen Momenten erneut.
        </Dialog>
      </div>
    )
  }
}

export default withRouter(TemplateEditor)
