import { observer } from 'mobx-react'
import contactDialogStore from 'stores/ContactDialogStore'
import { useRootStore } from 'stores/RootStoreContext'
import ContactPermissionRequest from './ContactPermissionRequest'
import ContactsDialog from './ContactsDialog'

interface ContactPickerDialogProps {
  onContactPicked: (contactId: string) => void
  onContactCreated?: (contact: string) => void
  retainState?: boolean
}

const ContactPickerDialog = (props: ContactPickerDialogProps) => {
  const { contactStore } = useRootStore()
  const { isContactsOpen } = contactDialogStore

  return !contactStore.hasContactsPermissions && isContactsOpen ? (
    <ContactPermissionRequest />
  ) : (
    <ContactsDialog
      mode='picker'
      onContactPicked={(contact) => props.onContactPicked(contact.id)}
      onContactCreated={(contact) => {
        props.onContactCreated?.(contact.id)
      }}
      retainState={props.retainState}
    />
  )
}

export default observer(ContactPickerDialog)
