import { APIError, ICompleteDocument } from '@nextbusiness/infinity-finance-api'
import {
  MenuItem,
  MenuItemGroup,
  MenuItems,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import { useMemo } from 'react'
import { useTransactionEditorContext } from '../TransactionEditorContext'
import { menuItemsFromAttachments } from './TransactionAttachmentMenuItems'

interface TransactionAttachmentMenuItemsProps {
  closeMenu: () => void
  onAttachmentClicked: (attachment: ICompleteDocument) => void
  onAddNewAttachmentClicked: () => void
  attachments: (ICompleteDocument | APIError)[]
}

const TransactionAttachmentMenu = (
  props: TransactionAttachmentMenuItemsProps
) => {
  const { canMakeChanges } = useTransactionEditorContext()
  const notificationCenter = useNotificationCenter()

  const onErrorClicked = (error: APIError) => {
    props.closeMenu()
    notificationCenter.addNotification({
      variant: 'error',
      children: error.humanText('de'),
    })
  }
  const { attachments, errors } = useMemo(
    () =>
      menuItemsFromAttachments(
        props.attachments,
        props.onAttachmentClicked,
        onErrorClicked
      ),
    [props.attachments]
  )

  const moreMenuItems: MenuItem[] = []
  if (canMakeChanges) {
    moreMenuItems.push({
      text: 'Beleg anhängen',
      icon: 'plus',
      onClick: props.onAddNewAttachmentClicked,
    })
  }

  const moreMenuGroups: MenuItemGroup[] = []
  if (moreMenuItems.length > 0) {
    moreMenuGroups.push({
      title: 'Weitere',
      buttons: moreMenuItems,
    })
  }
  moreMenuGroups.push({
    title: 'Belege',
    buttons: [...attachments, ...errors],
  })

  return <MenuItems closeMenu={props.closeMenu} groups={moreMenuGroups} />
}

export default TransactionAttachmentMenu
