import { Card, Heading, useNotificationCenter } from '@nextbusiness/infinity-ui'
import DocumentUpload from 'documents/document-creator/DocumentUpload'
import ExtendedDocumentListItem from 'documents/processed-documents/ExtendedDocumentListItem'
import ExtendedInvoiceListSection from 'invoices/shared-components/large-invoice-list/ExtendedInvoiceListSection'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import './MobileDocumentsPage.scss'

const MobileDocumentsPage = () => {
  const { documentStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const loadDocuments = async () => {
      setIsLoading(true)
      await documentStore.loadAllDocuments()
      setIsLoading(false)
    }
    loadDocuments()
  }, [])

  const inboxDocuments = [...(documentStore.inboxDocuments ?? [])].reverse()

  return (
    <div className='mobile-documents-page'>
      <Card>
        <Heading type='h3'>Neuen Beleg hochladen</Heading>
        <DocumentUpload notificationCenter={notificationCenter} />
      </Card>
      <Card>
        <ExtendedInvoiceListSection
          bare
          className='uploaded-documents-section'
          title='Zuletzt in den Eingang hochgeladen'
          emptyStateContent='Keine Belege im Eingang'
          emptyStateIcon='inbox'
          isLoading={isLoading}
          isEmpty={inboxDocuments.length === 0}
        >
          {inboxDocuments.map((document) => (
            <ExtendedDocumentListItem
              key={document.id}
              document={document}
              onClick={() => window.open(document.file.url)}
            />
          ))}
        </ExtendedInvoiceListSection>
      </Card>
    </div>
  )
}

export default observer(MobileDocumentsPage)
