import {
  Finance,
  IFeature,
  IModifiablePreferences,
} from '@nextbusiness/infinity-finance-api'
import { useNotificationCenter } from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'
import { useMixpanelEvent } from 'integrations/useMixpanelEvent'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import FeaturesIcon from '../../assets/oobe/oobe-features.svg'
import FeatureOption from '../../oobe/FeatureOption'
import SettingsGroup from '../general/SettingsGroup'
import SettingsPage from '../general/SettingsPage'
import BetaFeatureSettings from './BetaFeatureSettings'

enum FeatureOperationType {
  Adding,
  Removing,
}

const FeaturesPage = () => {
  const { preferencesStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const features = preferencesStore.organisationPreferences?.features ?? []
  const canEditBetaFeatures =
    import.meta.env.DEV || preferencesStore.isUsingBetaFeature('*')

  const updatePreferences = async (
    changes: IModifiablePreferences,
    operationType: FeatureOperationType
  ) => {
    try {
      const preferences = await Finance.Organisation.setPreferences(changes)
      runInAction(() => {
        preferencesStore.organisationPreferences = preferences
      })
      notificationCenter.addNotification({
        children:
          operationType === FeatureOperationType.Adding
            ? 'Feature wurde aktiviert.'
            : 'Feature wurde deaktiviert.',
        variant: 'success',
      })
    } catch {
      notificationCenter.addNotification({
        children:
          operationType === FeatureOperationType.Adding
            ? 'Feature konnte nicht aktiviert werden. Versuche es erneut.'
            : 'Feature konnte nicht deaktiviert werden. Versuche es erneut.',
        variant: 'error',
      })
    }
  }

  const saveFeatures = (
    updatedFeatures: IFeature[],
    operationType: FeatureOperationType
  ) => {
    updatePreferences({ features: updatedFeatures }, operationType)
  }

  const addFeature = (feature: IFeature) => {
    if (features.includes(feature)) return
    const updatedFeatures = [...features, feature]
    saveFeatures(updatedFeatures, FeatureOperationType.Adding)
  }

  const removeFeature = (feature: IFeature) => {
    if (!features.includes(feature)) return
    const updatedFeatures = features.filter((f) => f !== feature)
    saveFeatures(updatedFeatures, FeatureOperationType.Removing)
  }

  const toggleFeature = (feature: IFeature) => {
    if (features.includes(feature)) removeFeature(feature)
    else addFeature(feature)
  }

  const toggleLiveCapture = () => {
    const shouldEnable =
      !preferencesStore.organisationPreferences?.isLiveCaptureEnabled
    const operationType = shouldEnable
      ? FeatureOperationType.Adding
      : FeatureOperationType.Removing
    updatePreferences({ isLiveCaptureEnabled: shouldEnable }, operationType)
  }

  useMixpanelEvent('Settings viewed - Features')

  return (
    <SettingsPage
      title='Funktionen'
      iconURL={FeaturesIcon}
      className='features-settings-page'
    >
      <SettingsGroup title='Für fortgeschrittene Nutzer'>
        <FeatureOption
          label='Kontonummern'
          description='Wenn du aus anderen Buchhaltungsprogrammen bereits mit Kontonummern vertraut bist, kannst du diese anzeigen. Zum Beispiel entspricht die Nummer 1020 dem Konto "Bank".'
          icon={<Icon icon='numbers-input-form' />}
          onChange={() => toggleFeature('account-numbers')}
          isChecked={features.includes('account-numbers')}
        />
      </SettingsGroup>
      <SettingsGroup title='AI-Funktionen'>
        <FeatureOption
          label='Live Capture'
          description='Beleginhalte automatisch mit AI erkennen und ausfüllen.'
          icon={<Icon icon='ocr' />}
          onChange={toggleLiveCapture}
          isChecked={
            preferencesStore.organisationPreferences?.isLiveCaptureEnabled
          }
        />
      </SettingsGroup>
      {canEditBetaFeatures && <BetaFeatureSettings />}
    </SettingsPage>
  )
}

export default observer(FeaturesPage)
