import { Button } from '@nextbusiness/infinity-ui'
import './CommunicationsOption.scss'
import { useInitialSetupContext } from './InitialSetupContext'

const CommunicationsOption = () => {
  const { hasEnabledCommunications, setHasEnabledCommunications } =
    useInitialSetupContext()
  return (
    <div className='onboarding-communications-options'>
      {hasEnabledCommunications
        ? 'Wir informieren dich über Praxisänderungen, Neuerungen und gelegentliche Tipps.'
        : 'Wir senden nur erforderliche Kommunikation.'}
      <Button
        className='toggle-communications'
        variant='quaternary'
        onClick={() => setHasEnabledCommunications(!hasEnabledCommunications)}
      >
        {hasEnabledCommunications
          ? 'Deaktivieren'
          : 'Über Praxisänderungen & Tipps informiert werden'}
      </Button>
    </div>
  )
}

export default CommunicationsOption
