import { Finance, ITransaction } from '@nextbusiness/infinity-finance-api'
import { useNotificationCenter } from '@nextbusiness/infinity-ui'
import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import { observer } from 'mobx-react-lite'
import { CurrentReportStackNavigatorRef } from 'pages/ReportPage'
import { useEffect, useMemo, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import AccountDetailBarChart from './AccountDetailBarChart'
import './AccountDetailReportPage.scss'
import AccountDetailTransactionGroupList from './AccountDetailTransactionGroupList'
import { compareTransactions } from './StringSimilarity'

const extractAccountParams = (accountParam?: string | null) => {
  if (!accountParam) return {}
  if (!accountParam.includes('-'))
    return { accountNumber: Number(accountParam) }

  const paramComponents = accountParam.split('-')
  return {
    fiscalYear: Number(paramComponents[0]),
    accountNumber: Number(paramComponents[1]),
  }
}

const AccountDetailReportPage = () => {
  const { accountStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [transactions, setTransactions] = useState<ITransaction[]>()

  const { accountNumber, fiscalYear: paramFiscalYear } = extractAccountParams(
    CurrentReportStackNavigatorRef?.currentParam
  )
  const fiscalYear = paramFiscalYear ?? accountStore.currentFiscalYear!.year

  const account = [
    ...accountStore.specificAccounts,
    ...accountStore.allAccounts,
  ].find(
    (account) =>
      account.accountNumber === accountNumber &&
      account.fiscalYear === fiscalYear
  )

  const transactionGroups = useMemo(
    () =>
      transactions && account
        ? compareTransactions(transactions, account)
        : null,
    [transactions, account]
  )

  const getTransactions = async () => {
    if (!accountNumber) return
    try {
      const transactionInAccount = await Finance.Ledger.transactionsInAccount(
        accountNumber,
        { fiscalYear }
      )
      setTransactions(transactionInAccount)
    } catch (error: any) {
      notificationCenter.addNotification({
        children: 'Fehler beim Laden der Übersicht für dieses Konto',
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    if (account) getTransactions()
  }, [account])

  return (
    <MagicSheetPage
      header={
        <MagicSheetTitleBar title={account?.name ?? `Konto ${accountNumber}`} />
      }
    >
      <MagicSheetContainer>
        <div className='account-detail-report-page'>
          {!!transactions && !!account ? (
            <AccountDetailBarChart
              account={account}
              transactions={transactions}
              fiscalYear={fiscalYear}
            />
          ) : null}
          <div className='account-detail-report-transactions'>
            {transactionGroups && account ? (
              <AccountDetailTransactionGroupList
                groups={transactionGroups}
                account={account}
              />
            ) : null}
          </div>
        </div>
      </MagicSheetContainer>
    </MagicSheetPage>
  )
}

export default observer(AccountDetailReportPage)
