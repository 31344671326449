import { TaxCode } from '@nextbusiness/infinity-finance-api'
import VATPicker from 'components/vat/vat-picker/VATPicker'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import { DocumentPositionFlyoutProps } from '../flyout/DocumentPositionFlyout'

const PositionVATPicker = (props: DocumentPositionFlyoutProps) => {
  const { preferencesStore } = useRootStore()

  if (props.position.type !== 'custom-product') return null
  if (!preferencesStore.organisationPreferences?.VAT) return null

  return (
    <VATPicker
      type='income'
      taxCode={(props.position.taxCode ?? '') as TaxCode}
      onTaxCodeSelected={(taxCode) =>
        props.onChangePosition(props.position.identifier, {
          taxCode,
        })
      }
      hideNoneOption
    />
  )
}

export default observer(PositionVATPicker)
