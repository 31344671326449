import { Flex, Text } from '@nextbusiness/infinity-ui'
import { Icon, IconSize } from '@nextbusiness/infinity-ui-icons'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import { SUPPORTED_FILE_TYPES } from 'documents/document-creator/DocumentUploadConstants'
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import './DocumentUploadArea.scss'

interface DocumentUploadAreaProps {
  onFileSelected: (file: File) => void
  isUploading?: boolean
}

const DocumentUploadArea = (props: DocumentUploadAreaProps) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles[0]) props.onFileSelected(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: SUPPORTED_FILE_TYPES,
    multiple: false,
  })

  return (
    <div
      className={'document-upload-area' + (isDragActive ? ' dropzone' : '')}
      {...getRootProps()}
    >
      <Flex
        direction='vertical'
        horizontalAlignment='center'
        style={{ height: '100%' }}
      >
        {props.isUploading ? (
          <LoadingScreen loadingText='Wird hochgeladen' />
        ) : (
          <>
            {!isDragActive && (
              <Icon icon='list-is-empty' size={IconSize.Medium} />
            )}
            <Text className='text'>
              {isDragActive
                ? 'Dokument hier hinziehen'
                : 'PDF oder Foto hochladen'}
            </Text>
          </>
        )}
      </Flex>
      <input {...getInputProps()} />
    </div>
  )
}

export default DocumentUploadArea
