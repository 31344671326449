import { Finance } from '@nextbusiness/infinity-finance-api'
import { Dialog, Text, useNotificationCenter } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'

interface DeletePresetModalProps {
  isOpen: boolean
  closeModal: () => void
  presetTitle: string
  presetId: string
}

const DeletePresetModal = (props: DeletePresetModalProps) => {
  const { presetStore } = useRootStore()

  const notificationCenter = useNotificationCenter()
  const [isDeletionInProgress, setIsDeletionInProgress] =
    useState<boolean>(false)

  const deletePreset = async () => {
    setIsDeletionInProgress(true)
    try {
      await Finance.CustomerInvoice.deletePreset(props.presetId)
      await presetStore.loadPresets()

      notificationCenter.addNotification({
        variant: 'success',
        children: 'Vorlage erfolgreich gelöscht.',
      })
    } catch (error: any) {
      notificationCenter.addNotification({
        variant: 'error',
        title: 'Fehler beim Löschen der Vorlage.',
        children: error.message,
      })
    } finally {
      setIsDeletionInProgress(false)
      props.closeModal()
    }
  }

  return (
    <Dialog
      title='Vorlage unwiderruflich löschen?'
      isOpen={props.isOpen}
      onDismiss={props.closeModal}
      actions={[
        {
          children: 'Abbrechen',
          disabled: isDeletionInProgress,
          onClick: props.closeModal,
        },
        {
          children: 'Löschen',
          variant: 'destructive',
          onClick: deletePreset,
          isLoading: isDeletionInProgress,
        },
      ]}
    >
      <Text type='inline'>
        Möchtest du die Vorlage "{props.presetTitle}" wirklich löschen?
      </Text>
    </Dialog>
  )
}

export default observer(DeletePresetModal)
