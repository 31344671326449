import { Dialog, useNotificationCenter } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import IDocument from 'model/Document'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'

interface DeleteDocumentModalProps {
  isOpen: boolean
  closeModal: () => void
  onDeletionSuccess?: () => void
  document?: IDocument
  documentId: string
}

const DeleteDocumentModal = (props: DeleteDocumentModalProps) => {
  const { documentStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  const deleteDocument = async () => {
    setIsDeleting(true)
    try {
      await documentStore.deleteDocument(props.documentId)
      props.onDeletionSuccess?.()
    } catch (error: any) {
      notificationCenter.addNotification({
        children: 'Dokument konnte nicht gelöscht werden',
        variant: 'error',
      })
    } finally {
      documentStore.loadAllDocuments()
      setIsDeleting(false)
      props.closeModal()
    }
  }

  return (
    <Dialog
      isOpen={props.isOpen}
      title='Beleg löschen'
      icon='delete'
      actions={[
        {
          children: 'Abbrechen',
          onClick: props.closeModal,
        },
        {
          children: 'Beleg löschen',
          variant: 'destructive',
          isLoading: isDeleting,
          onClick: deleteDocument,
        },
      ]}
    >
      {props.document?.title
        ? `Möchtest du den Beleg "${props.document.title}" endgültig löschen?`
        : 'Möchtest du diesen hochgeladenen Beleg endgültig löschen?'}
    </Dialog>
  )
}

export default observer(DeleteDocumentModal)
