import { InputField, Textarea } from '@nextbusiness/infinity-ui'
import SettingsGroup from 'settings/general/SettingsGroup'
import { ProductEditFieldsProps } from '../ProductEditFields'

const ProductFieldsTitle = (props: ProductEditFieldsProps) => {
  const { product, shouldValidate } = props

  const title = product.name
  const isTitleMissing = shouldValidate && !title?.trim()
  const isTitleTooLong = shouldValidate && !!title && title.length > 60

  return (
    <SettingsGroup title='Titel'>
      <InputField
        placeholder='Produktname'
        value={props.product.name ?? ''}
        onChange={(name) => props.editProduct({ name })}
        hasError={isTitleMissing || isTitleTooLong}
        helperText={
          isTitleMissing
            ? 'Erforderlich'
            : isTitleTooLong
            ? 'Maximal 60 Zeichen'
            : undefined
        }
        fullWidth
      />
      <Textarea
        placeholder='Anzeigetext für Kunden (optional)'
        value={props.product.nameOnInvoice ?? ''}
        onChange={(nameOnInvoice) => props.editProduct({ nameOnInvoice })}
        helperText='Abweichender Name für Forderungen und Offerten'
        fullWidth
      />
    </SettingsGroup>
  )
}

export default ProductFieldsTitle
