import { NonIdealState } from '@nextbusiness/infinity-ui'
import { IconSize } from '@nextbusiness/infinity-ui-icons'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import ProjectListItem from './list/ProjectListItem'

interface ArchivedProjectsViewProps {
  openProject: (projectId: string) => void
}

const ArchivedProjectsView = (props: ArchivedProjectsViewProps) => {
  const { projectStore } = useRootStore()
  const hasArchivedProjects = projectStore.archivedProjects?.length

  useEffect(() => {
    projectStore.fetchArchivedProjects()
  }, [])

  return (
    <MagicSheetPage
      header={<MagicSheetTitleBar title='Archivierte Projekte' />}
    >
      {!hasArchivedProjects ? (
        <NonIdealState icon='archive' iconSize={IconSize.Medium}>
          Noch keine archivierten Projekte
        </NonIdealState>
      ) : (
        projectStore.archivedProjects?.map((project) => (
          <ProjectListItem
            key={project.id}
            project={project}
            onClick={() => props.openProject(project.id)}
          />
        ))
      )}
    </MagicSheetPage>
  )
}

export default observer(ArchivedProjectsView)
