import { Button, Flex } from '@nextbusiness/infinity-ui'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react'
import { useState } from 'react'
import ContactStore from 'stores/ContactStore'
import AbortEditDialog from '../../components/dialogs/AbortEditDialog'
import { ErrorType } from '../../libs/networking/Errors'
import { Contact } from '../../model/Contact'
import Contacts from '../../networking/Contacts'
import contactDialogStore from '../../stores/ContactDialogStore'
import { useRootStore } from '../../stores/RootStoreContext'
import { KeyValueStore } from '../../utility/types'
import { AbortIntent } from '../ContactsDialog'
import ConfirmDeleteContact from './ConfirmDeleteContact'
import './SingleContactFooter.scss'

interface SingleContactFooterProps {
  createContact: (newContact: KeyValueStore) => void
  setLocalErrorMessage: (localErrorMessage: string | undefined) => void
  onContactPicked?: (contact: Contact) => void
  missingName: boolean
}

const SingleContactFooter = (props: SingleContactFooterProps) => {
  const { authenticationStore, contactStore } = useRootStore()
  const { cancelAbort } = contactDialogStore

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const {
    mode,
    setMode,
    activeContact,
    setActiveContact,
    draftContact,
    setDraftContact,
    reasonForAborting,
    abortEditing,
  } = contactDialogStore

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)

  const editContact = async (newContact: KeyValueStore) => {
    if (
      !authenticationStore.organisationIdentifier ||
      !authenticationStore.fabricOrganisationIdentifier ||
      !authenticationStore.applicationAccessToken
    )
      return authenticationStore.logout()
    if (!activeContact)
      return props.setLocalErrorMessage('Kein Kontakt ausgewählt.')

    try {
      setIsSaving(true)
      setActiveContact(
        await Contacts.editContact(
          authenticationStore.fabricOrganisationIdentifier,
          activeContact.id,
          authenticationStore.applicationAccessToken,
          newContact as unknown as Contact
        )
      )
      contactStore.loadContacts()
      props.setLocalErrorMessage(undefined)
      setMode('viewing')
      MixpanelAnalytics.event('Contact edited')
    } catch (error: any) {
      switch (error.type) {
        case ErrorType.InsufficientPermissions:
          return props.setLocalErrorMessage(
            'Finance hat keine Berechtigung, Kontakte zu erstellen oder bearbeiten'
          )
        case ErrorType.NotFound:
          return props.setLocalErrorMessage(error.message)
        default:
          props.setLocalErrorMessage(error.message)
      }
    } finally {
      setIsSaving(false)
    }
  }

  const deleteContact = async () => {
    if (
      !authenticationStore.organisationIdentifier ||
      !authenticationStore.fabricOrganisationIdentifier ||
      !authenticationStore.applicationAccessToken
    )
      return authenticationStore.logout()
    if (!activeContact)
      return props.setLocalErrorMessage('Kein Kontakt ausgwählt.')
    try {
      setIsLoading(true)
      await Contacts.deleteContact(
        authenticationStore.fabricOrganisationIdentifier,
        activeContact.id,
        authenticationStore.applicationAccessToken
      )

      setActiveContact(undefined)
      contactStore.loadContacts()
      props.setLocalErrorMessage(undefined)
      setMode('viewing')
      MixpanelAnalytics.event('Contact deleted')
    } catch (error: any) {
      switch (error.type) {
        case ErrorType.InsufficientPermissions:
          return props.setLocalErrorMessage(
            'Finance hat keine Berechtigung, Kontakte zu erstellen oder bearbeiten'
          )
        case ErrorType.NotFound:
          return props.setLocalErrorMessage(error.message)
        default:
          props.setLocalErrorMessage(error.message)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {mode === 'editing' || mode === 'creating' ? (
        <>
          <Flex
            className='contact-footer'
            gap='tiny'
            horizontalAlignment='flex-end'
          >
            <Button
              iconOnly='delete'
              onClick={() => {
                if (mode === 'creating') {
                  abortEditing(() => setMode('viewing'), AbortIntent.CancelEdit)
                } else setIsDeleteDialogOpen(true)
              }}
              tooltip='Kontakt löschen'
            />
            <Button className='add-field-button' style={{ display: 'none' }}>
              Weiteres Feld
            </Button>
            <div className='footer-spacer' />
            <Button
              onClick={() => {
                abortEditing(() => setMode('viewing'), AbortIntent.CancelEdit)
              }}
            >
              Abbrechen
            </Button>
            <Button
              disabled={props.missingName}
              variant='primary'
              onClick={() => {
                switch (mode) {
                  case 'creating':
                    return props.createContact(draftContact)
                  case 'editing':
                    return editContact(draftContact)
                }
              }}
              tooltip={
                props.missingName
                  ? 'Ein Kontakt muss immer entweder einen Vor- oder Nachnamen oder einen Firmennamen besitzen.'
                  : null
              }
              isLoading={isSaving}
            >
              {mode === 'creating' && 'Kontakt erstellen'}
              {mode === 'editing' && 'Fertig'}
            </Button>
          </Flex>

          <AbortEditDialog
            isAbortingEdit={reasonForAborting === AbortIntent.CancelEdit}
            onAbort={() => setMode('viewing')}
            onCancel={cancelAbort}
          />
        </>
      ) : (
        <Flex className='contact-footer'>
          <Flex gap='tiny'>
            <Button
              iconOnly='delete'
              onClick={() => setIsDeleteDialogOpen(true)}
              tooltip='Kontakt löschen'
            />
            <Button className='add-field-button' style={{ display: 'none' }}>
              Weiteres Feld
            </Button>
          </Flex>
          <div className='footer-spacer' />
          <Flex gap='tiny'>
            <Button
              onClick={() => {
                setDraftContact({ ...activeContact } as KeyValueStore)
                setMode('editing')
              }}
            >
              Bearbeiten
            </Button>
            {contactDialogStore.isPickerMode && props.onContactPicked && (
              <Button
                variant='primary'
                onClick={() =>
                  props.onContactPicked?.(contactDialogStore.activeContact!)
                }
                disabled={!contactDialogStore.activeContact}
              >
                Kontakt auswählen
              </Button>
            )}
          </Flex>
        </Flex>
      )}
      <ConfirmDeleteContact
        isOpen={isDeleteDialogOpen}
        setIsOpen={setIsDeleteDialogOpen}
        contactName={
          activeContact ? ContactStore.nameOfContact(activeContact) : ''
        }
        onConfirm={() => deleteContact()}
        isLoading={isLoading}
      />
    </>
  )
}

export default observer(SingleContactFooter)
