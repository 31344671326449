import ColumnPicker from 'invoices/customer-invoices/editor/sidebar/panels/components/ColumnPicker'
import { INewCustomerInvoice } from 'model/CustomerInvoice'
import React from 'react'
import ConfigurableAreaFlyout from './ConfigurableAreaFlyout'
import './SetupColumnsFlyout.scss'

interface SetupColumnsFlyoutProps {
  children: React.ReactNode
  document: Partial<INewCustomerInvoice>
  registerEdit: (edit: Partial<INewCustomerInvoice>) => void
  readonly: boolean
}

const SetupColumnsFlyout = (props: SetupColumnsFlyoutProps) => (
  <ConfigurableAreaFlyout
    className='setup-columns-flyout'
    title='Spalten anpassen'
    tooltip='Spalten anpassen'
    icon='view-column'
    settings={
      <ColumnPicker
        document={props.document}
        registerEdit={props.registerEdit}
      />
    }
    readonly={props.readonly}
  >
    {props.children}
  </ConfigurableAreaFlyout>
)

export default SetupColumnsFlyout
