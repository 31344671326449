import { ITemplate } from '@nextbusiness/infinity-finance-api'
import { Text, useNotificationCenter } from '@nextbusiness/infinity-ui'
import UploadArea from 'components/upload-area/UploadArea'
import { Upload } from 'documents/document-creator/DocumentUpload'
import { observer } from 'mobx-react'
import Templates from 'networking/Templates'
import { useRootStore } from 'stores/RootStoreContext'

interface TemplateInspectorLogoUploadProps {
  onSuccessfulUpload: () => void
  template: ITemplate
}

const TemplateInspectorLogoUpload = (
  props: TemplateInspectorLogoUploadProps
) => {
  const notificationCenter = useNotificationCenter()
  const { authenticationStore } = useRootStore()

  const uploadLogo = async (upload: Upload) => {
    try {
      await Templates.uploadLogo(
        upload.file,
        upload.title ?? '',
        authenticationStore.organisationIdentifier,
        props.template.id
      )
      props.onSuccessfulUpload()
      notificationCenter.addNotification({
        children: 'Logo erfolgreich hochgeladen',
        variant: 'success',
      })
    } catch (error: any) {
      notificationCenter.addNotification({
        children: 'Logo konnte nicht hochgeladen werden',
        variant: 'error',
      })
    }
  }

  return (
    <div className='logo-upload'>
      <UploadArea
        icon='image'
        uploadItemKind='Logo'
        onUploadSelected={uploadLogo}
        dropzoneOptions={{
          accept: {
            'image/*': ['.png', '.jpeg', '.jpg'],
          },
        }}
        isEmpty={!props.template.logo}
      />
      <Text variant='subtle'>
        Es werden .png, .jpeg und .jpg Dateien unterstützt.
      </Text>
    </div>
  )
}

export default observer(TemplateInspectorLogoUpload)
