import { Heading } from '@nextbusiness/infinity-ui'
import VirtualTransactionView from 'components/transactions/VirtualTransactionView'
import ClosingTransactionPreview from 'model/financial-close/ClosingTransactionPreview'

interface ClosingTransactionsListGroupProps {
  title: string
  transactions: ClosingTransactionPreview[]
}

const PROFIT_CLOSE_ACCOUNT = 9200
const ClosingTransactionsListGroup = (
  props: ClosingTransactionsListGroupProps
) => {
  if (props.transactions.length === 0) return null
  return (
    <div className='transactions-group'>
      <Heading type='h4' bare>
        {props.title}
      </Heading>
      {props.transactions.map((transaction) => (
        <VirtualTransactionView
          key={transaction.id}
          debitAccount={transaction.debitAccount}
          creditAccount={transaction.creditAccount}
          swapArrowDirection={transaction.debitAccount === PROFIT_CLOSE_ACCOUNT}
          amount={transaction.amount}
          description={transaction.description}
        />
      ))}
    </div>
  )
}
export default ClosingTransactionsListGroup
