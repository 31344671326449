import classNames from 'classnames'
import { useInitialSetupContext } from './InitialSetupContext'
import './OnboardingStepper.scss'

export enum OnboardingStep {
  Registration = 'Konto erstellen',
  UsageType = 'Art der Nutzung',
  LegalForm = 'Rechtsform',
  VAT = 'Mehrwertsteuer',
  Review = 'Überprüfen',
}

const OnboardingStepper = () => {
  const { currentStep } = useInitialSetupContext()
  const currentStepIndex = Object.values(OnboardingStep).indexOf(currentStep)

  const totalSteps = Object.values(OnboardingStep).length

  const previousProgress = currentStepIndex
  const ongoingStepProgress = (currentStepIndex + 1) / totalSteps

  const overallProgress = (previousProgress + ongoingStepProgress) / totalSteps

  return (
    <div className='onboarding-stepper'>
      <div className='onboarding-stepper-bar'>
        <div
          className='onboarding-stepper-bar-progress'
          style={{
            width: `${overallProgress * 100}%`,
          }}
        />
      </div>
      <div className='onboarding-stepper-bar-labels'>
        {Object.values(OnboardingStep).map((step, index) => (
          <div
            key={index}
            className={classNames('onboarding-stepper-bar-label', {
              current: index === currentStepIndex,
              completed: index < currentStepIndex,
            })}
          >
            {step}
          </div>
        ))}
      </div>
    </div>
  )
}

export default OnboardingStepper
