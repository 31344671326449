import { InputField } from '@nextbusiness/infinity-ui'
import { useExpenditureCreateContext } from './ExpenditureCreateContext'

interface ExpenditureDescriptionInputProps {
  errorFields: (condition: boolean) => Record<string, unknown>
}

const ExpenditureDescriptionInput = (
  props: ExpenditureDescriptionInputProps
) => {
  const expenditureCreateContext = useExpenditureCreateContext()
  return (
    <>
      <div className='expenditure-input-title'>Beschreibung</div>
      <InputField
        value={expenditureCreateContext.transaction.description || ''}
        onChange={(description) =>
          expenditureCreateContext.updateTransaction({ description })
        }
        placeholder='z.B. Mietzins Büro'
        fullWidth
        {...props.errorFields(
          !expenditureCreateContext.transaction.description
        )}
      />
    </>
  )
}

export default ExpenditureDescriptionInput
