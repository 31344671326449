import { Button, Flex, Flyout } from '@nextbusiness/infinity-ui'
import React from 'react'
import { DocumentPositionRootProps } from '../DocumentPositionRootProps'
import DocumentCustomProductPositionFlyout from './DocumentCustomProductPositionFlyout'
import DocumentDiscountPositionFlyout from './DocumentDiscountPositionFlyout'

export interface DocumentPositionFlyoutProps extends DocumentPositionRootProps {
  trigger?: React.ReactNode
  isActive: boolean
  setIsActive: (isActive: boolean) => void
}

const DocumentPositionFlyout = (props: DocumentPositionFlyoutProps) => (
  <Flyout
    className='position-row-inner'
    trigger={props.trigger}
    isActive={props.isActive}
    setIsActive={props.setIsActive}
    positionOptions={{ prefer: 'below', minHeight: 100 }}
  >
    <Flex className='position-actions' direction='vertical'>
      <Flex gap={0.8}>
        <Button
          iconOnly='delete'
          aria-label='Position löschen'
          onClick={() => props.deletePosition(props.position.identifier)}
          tooltip='Position löschen'
        />
        {props.position.type === 'custom-product' && (
          <DocumentCustomProductPositionFlyout {...props} />
        )}
        {props.position.type === 'discount' && (
          <DocumentDiscountPositionFlyout {...props} />
        )}
        <Button variant='primary' onClick={() => props.setIsActive(false)}>
          Fertig
        </Button>
      </Flex>
    </Flex>
  </Flyout>
)

export default DocumentPositionFlyout
