import { ReactComponent as TriangleFilledDown } from '../../assets/triangle-filled-down.svg'
import { ReactComponent as TriangleFilledUp } from '../../assets/triangle-filled-up.svg'
import './GrowthIndicatorIcon.scss'

interface GrowthIndicatorIconProps {
  change: number
}

const GrowthIndicatorIcon = (props: GrowthIndicatorIconProps) => {
  if (!props.change) return null
  if (props.change > 0) {
    return (
      <TriangleFilledUp className='growth-indicator-icon triangle-filled-up' />
    )
  }
  return (
    <TriangleFilledDown className='growth-indicator-icon triangle-filled-down' />
  )
}

export default GrowthIndicatorIcon
