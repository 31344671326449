import { Text } from '@nextbusiness/infinity-ui'
import { useAccountPageContext } from 'ledger/accounts/AccountPageContext'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import TransactionListLoadingState from './TransactionLoadingState'

const TransactionListPaginationControl = () => {
  const { transactionStore } = useRootStore()
  const { canLoadMoreTransactions, currentAccount } = useAccountPageContext()

  const numberOfTransactions =
    transactionStore.transactions[currentAccount.accountNumber]?.length

  return canLoadMoreTransactions ? (
    <TransactionListLoadingState isContinuation={!!numberOfTransactions} />
  ) : (
    <Text type='inline' variant='subtle' className='no-more-note'>
      {numberOfTransactions
        ? 'Keine weiteren Transaktionen'
        : 'In diesem Konto befinden sich noch keine Transaktionen.'}
    </Text>
  )
}

export default observer(TransactionListPaginationControl)
