import Filter from 'base-components/table/Filter'
import { useCustomerInvoicesFilter } from './CustomerInvoicesFilterContext'

const CustomerInvoiceQuoteStatusFilter = () => {
  const { activeFilter, editFilter } = useCustomerInvoicesFilter()
  return (
    <Filter
      icon='checked-checkbox'
      label='Status'
      items={[
        { label: 'Offen', value: 'open', icon: 'ask-question' },
        { label: 'Akzeptiert', value: 'accepted', icon: 'success' },
        { label: 'Abgelehnt', value: 'rejected', icon: 'error' },
      ]}
      selectedValue={activeFilter.status}
      onValueSelected={(status) => editFilter({ status })}
    />
  )
}

export default CustomerInvoiceQuoteStatusFilter
