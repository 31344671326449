import {
  Card,
  Dialog,
  FeedbackBanner,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { useRootStore } from 'stores/RootStoreContext'
import DocumentSidebarPanelProps from '../DocumentSidebarPanelProps'
import './CustomerInvoiceShareDialog.scss'

interface CustomerInvoiceShareDialogProps extends DocumentSidebarPanelProps {
  isOpen: boolean
  onDismiss: () => void
  shareURL: string | undefined
}

const CustomerInvoiceShareDialog = (props: CustomerInvoiceShareDialogProps) => {
  const { preferencesStore } = useRootStore()

  const notificationCenter = useNotificationCenter()
  const fullShareURL =
    document.location.hostname === 'localhost'
      ? `http://${props.shareURL}`
      : `https://${props.shareURL}`
  const invoiceType = props.document.isQuote ? 'Offerte' : 'Forderung'

  return (
    <Dialog
      title={`${invoiceType} per Link teilen`}
      titleProps={{ divider: true }}
      isOpen={props.isOpen}
      dismissable
      className='share-invoice-dialog'
      onDismiss={() => props.onDismiss()}
      actions={[
        {
          children: 'Schliessen',
          variant: 'tertiary',
          className: 'close-action',
          onClick: () => props.onDismiss(),
        },
        {
          children: 'Vorschau',
          variant: 'tertiary',
          onClick: () => window.open(fullShareURL),
        },
        {
          children: 'Link kopieren',
          iconLeft: 'copy',
          variant: 'primary',
          onClick: () => {
            props.onDismiss()
            navigator.clipboard.writeText(fullShareURL)
            notificationCenter.addNotification({
              children:
                'Der Link wurde erfolgreich in die Zwischenablage kopiert.',
              variant: 'success',
            })
            MixpanelAnalytics.event('Customer invoice shared via link', {
              isQuote: props.document.isQuote,
            })
          },
        },
      ]}
    >
      <FeedbackBanner variant='success'>
        Die {invoiceType} ist nun über den folgenden Link zugänglich.
      </FeedbackBanner>
      <Card className='share-url' elevation='none'>
        {props.shareURL}
      </Card>
      <Text>
        {props.document.isQuote
          ? 'Kopiere diesen Link und sende ihn an deine Kunden, damit sie die Offerte ansehen und per Klick akzeptieren können.'
          : 'Kopiere diesen Link und sende ihn an deine Kunden, damit sie die Forderung ansehen können.'}
      </Text>
      {!preferencesStore.organisationPreferences?.notificationEmail &&
        props.document.isQuote && (
          <Text variant='help'>
            In den{' '}
            <Link
              to='/optionen/benachrichtigungen'
              className='link'
              onClick={() => props.onDismiss()}
            >
              Einstellungen
            </Link>{' '}
            kannst du Benachrichtigungen aktivieren, wenn Kunden eine Offerte
            akzeptieren.
          </Text>
        )}
    </Dialog>
  )
}

export default observer(CustomerInvoiceShareDialog)
