import { Button, Flex } from '@nextbusiness/infinity-ui'

interface PublicInvoiceSidebarActionsProps {
  onDownload: () => void
  isQrInvoice: boolean | undefined
}

const PublicInvoiceSidebarActions = (
  props: PublicInvoiceSidebarActionsProps
) => (
  <Flex className='sidebar-actions' gap={1} wrap>
    <Button
      variant='primary'
      onClick={props.onDownload}
      iconLeft='file-download'
    >
      PDF{props.isQrInvoice && ' mit QR-Zahlteil'}
    </Button>
  </Flex>
)

export default PublicInvoiceSidebarActions
