import ResourceSelect from 'components/resource-select/ResourceSelect'
import { observer } from 'mobx-react'
import { ICustomerInvoicePreview } from 'model/CustomerInvoice'
import { useEffect, useMemo } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import CustomerInvoiceSelectOption from './CustomerInvoiceSelectOption'

interface CustomerInvoiceSelectProps {
  customerInvoiceId?: string
  onCustomerInvoiceSelected: (customerInvoiceId: string | undefined) => void
  inputFieldProps?: Partial<
    React.ComponentProps<typeof ResourceSelect>['inputFieldProps']
  >
  filter?: { maxAmount?: number }
}

const CustomerInvoiceSelect = (props: CustomerInvoiceSelectProps) => {
  const { customerInvoiceStore } = useRootStore()
  const filter = props.filter ?? {}

  const openCustomerInvoices = customerInvoiceStore.unpaidCustomerInvoices
  const openCustomerInvoicesItems = useMemo(
    () =>
      openCustomerInvoices
        ?.filter((invoice) => {
          const isAboveMaxAmount =
            filter.maxAmount && invoice.totalAmount > filter.maxAmount
          return !isAboveMaxAmount
        })
        ?.map((invoice) => ({
          value: invoice.id,
          item: invoice,
        })) ?? [],
    [openCustomerInvoices, filter]
  )
  const sections = [
    { title: 'Offene Forderungen', options: openCustomerInvoicesItems },
  ]

  useEffect(() => {
    customerInvoiceStore.loadUnpaidCustomerInvoices()
  }, [])

  return (
    <ResourceSelect<string, ICustomerInvoicePreview>
      sections={sections}
      onChange={(invoiceId) => props.onCustomerInvoiceSelected(invoiceId)}
      value={props.customerInvoiceId}
      placeholderText={
        openCustomerInvoices?.length === 0
          ? 'Keine unbezahlten Forderungen'
          : props.filter && openCustomerInvoicesItems?.length === 0
          ? 'Keine passenden Forderungen'
          : 'Forderung auswählen'
      }
      searchableTextForItem={(invoice) => invoice.title}
      optionForItem={(invoice, props) => (
        <CustomerInvoiceSelectOption
          customerInvoice={invoice}
          optionProps={props}
        />
      )}
      inputFieldProps={{
        fullWidth: true,
        ...props.inputFieldProps,
      }}
    />
  )
}

export default observer(CustomerInvoiceSelect)
