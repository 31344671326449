import ContentLoader from 'react-content-loader'

interface LoaderFinancialHealthAccountProps {
  showYoYComparison?: boolean
}

const LoaderFinancialHealthAccount = (
  props: LoaderFinancialHealthAccountProps
) => (
  <ContentLoader
    speed={2}
    width={344}
    height={230}
    viewBox='0 0 344 230'
    backgroundColor='#f3f3f3'
    foregroundColor='#e5e5e5'
  >
    <rect x='1' y='19' rx='3' ry='3' width='104' height='15' />
    <rect x='1' y='44' rx='3' ry='3' width='140' height='20' />
    {props.showYoYComparison ? (
      <>
        <rect x='161' y='44' rx='3' ry='3' width='60' height='20' />
        <rect x='1' y='78' rx='3' ry='3' width='253' height='16' />
        <rect x='1' y='117' rx='3' ry='3' width='330' height='100' />
      </>
    ) : (
      <rect x='1' y='90' rx='3' ry='3' width='330' height='130' />
    )}
  </ContentLoader>
)

export default LoaderFinancialHealthAccount
