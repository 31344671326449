import { APIError, ErrorCode } from '@nextbusiness/infinity-finance-api'
import { action, makeObservable, observable } from 'mobx'
import { AbortIntent, Mode } from '../contacts/ContactsDialog'
import { Contact } from '../model/Contact'
import Contacts from '../networking/Contacts'
import { areIdentical, areStringsEmpty } from '../utility/Utilities'
import { KeyValueStore } from '../utility/types'
import { store } from './RootStoreContext'

class ContactDialogStore {
  blankContact = {
    firstname: '',
    lastname: '',
    companyName: '',
  }

  @observable isContactsOpen = false
  @observable isPickerMode = false
  @observable mode: Mode = 'viewing'
  @observable draftContact: KeyValueStore = this.blankContact
  @observable activeContact: Contact | undefined = undefined
  @observable reasonForAborting: AbortIntent = AbortIntent.None
  @observable errorMessage: string | undefined = undefined

  constructor() {
    makeObservable(this)
  }

  @action
  getContact = async (id: string) => {
    if (id === this.activeContact?.id) return
    if (!store.authenticationStore.fabricOrganisationIdentifier)
      throw new APIError(ErrorCode.Unauthorised, 'Du bist nicht angemeldet.')

    this.setActiveContact(
      await Contacts.getContact(
        store.authenticationStore.fabricOrganisationIdentifier,
        id,
        store.authenticationStore.applicationAccessToken!
      )
    )
  }

  @action
  abortEditing = (callbackWhenIdentical: () => void, intent: AbortIntent) => {
    if (
      areStringsEmpty(this.draftContact) ||
      areIdentical(
        this.draftContact,
        this.activeContact as unknown as KeyValueStore
      )
    ) {
      callbackWhenIdentical()
    } else {
      this.reasonForAborting = intent
    }
  }

  @action
  setIsContactsOpen = (isContactsOpen: boolean) => {
    this.isContactsOpen = isContactsOpen
  }

  @action
  setMode = (mode: Mode) => {
    this.mode = mode
  }

  @action
  setDraftContact = (contact: KeyValueStore) => {
    this.draftContact = contact
  }

  @action
  setActiveContact = (contact: Contact | undefined) => {
    this.activeContact = contact
  }

  @action
  cancelAbort = () => {
    this.reasonForAborting = AbortIntent.None
  }

  @action
  setErrorMessage = (errorMessage: string | undefined) => {
    this.errorMessage = errorMessage
  }

  @action
  setIsPickerMode = (isPickerMode: boolean) => {
    this.isPickerMode = isPickerMode
  }
}

const contactDialogStore = new ContactDialogStore()

export default contactDialogStore
