import Accordion from 'components/accordion/Accordion'
import { IAccordionItem } from 'components/accordion/AccordionItem'
import { scrollToBottom, scrollToTop } from 'utility/Utilities'
import { TemplateInspectorProps } from '../TemplateInspector'
import TemplateInspectorAccentColours from './TemplateInspectorAccentColours'
import TemplateInspectorAddressPosition from './TemplateInspectorAddressPosition'
import './TemplateInspectorAppearanceTab.scss'
import TemplateInspectorCustomBackground from './TemplateInspectorCustomBackground'
import TemplateInspectorLogo from './TemplateInspectorLogo'
import TemplateInspectorTypeface from './TemplateInspectorTypeface'

const TemplateInspectorAppearanceTab = (props: TemplateInspectorProps) => {
  const preview = document.querySelector('.template-preview-panel')

  const accordionItems: IAccordionItem[] = [
    {
      title: 'Schrift',
      content: <TemplateInspectorTypeface {...props} />,
    },
    {
      title: 'Akzentfarbe',
      content: <TemplateInspectorAccentColours {...props} />,
      onOpen: () => scrollToBottom(preview, { behavior: 'smooth' }),
    },
    {
      title: 'Logo',
      content: <TemplateInspectorLogo {...props} />,
      onOpen: () => scrollToTop(preview, { behavior: 'smooth' }),
    },
    {
      title: 'Empfänger',
      content: <TemplateInspectorAddressPosition {...props} />,
      onOpen: () => scrollToTop(preview, { behavior: 'smooth' }),
    },
    {
      title: 'Eigener Hintergrund',
      content: <TemplateInspectorCustomBackground {...props} />,
    },
  ]
  return (
    <div className='template-inspector-tab tab-appearance'>
      <Accordion accordionItems={accordionItems} openFirstByDefault />
    </div>
  )
}

export default TemplateInspectorAppearanceTab
