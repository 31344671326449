import { Card, LoadingIndicator, Text } from '@nextbusiness/infinity-ui'
import CustomerInvoiceFactory from 'invoices/customer-invoices/editor/CustomerInvoiceFactory'
import DocumentElementInfoBlock from 'invoices/customer-invoices/editor/document-renderer/elements/DocumentElementInfoBlock'
import DocumentElementIntroductoryText from 'invoices/customer-invoices/editor/document-renderer/elements/DocumentElementIntroductoryText'
import DocumentElementTitle from 'invoices/customer-invoices/editor/document-renderer/elements/DocumentElementTitle'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import './../../../../invoices/customer-invoices/editor/document-renderer/sections/DocumentPageBody.scss'
import './TitleAndDateStep.scss'

interface TitleAndDateStepProps {
  title: string | undefined
  setTitle: (title: string | undefined) => void
  date: number | undefined
  setDate: (date: number | undefined) => void
  payableInDays: number | undefined
  setPayableInDays: (payableInDays: number | undefined) => void
}

const TitleAndDateStep = (props: TitleAndDateStepProps) => {
  const { templateStore, preferencesStore } = useRootStore()
  useEffect(() => {
    templateStore.loadCurrentTemplate()
  }, [])

  return (
    <>
      <Text type='paragraph' className='invoicing-assistant-description'>
        Bitte setze einen Titel und passe bei Bedarf das Rechnungsdatum und die
        Zahlungsfrist an.
      </Text>
      <div className='invoice-slice-container'>
        {templateStore.currentTemplate ? (
          <Card className='document-page-body' elevation='medium'>
            <div className='vat-number'>
              {preferencesStore.organisationPreferences?.VAT
                ? preferencesStore.organisationPreferences.vatRegistrationNumber
                : ''}
            </div>
            <DocumentElementTitle
              document={{ title: props.title }}
              registerEdit={(changes) => props.setTitle(changes.title)}
              template={templateStore.currentTemplate}
              isEditable
            />
            <DocumentElementInfoBlock
              document={{
                openingDate: props.date,
                payableInDays: props.payableInDays,
                isQuote: false,
              }}
              registerEdit={(changes) => {
                if (changes.openingDate) props.setDate(changes.openingDate)
                if (changes.payableInDays)
                  props.setPayableInDays(changes.payableInDays)
              }}
              template={templateStore.currentTemplate}
              isEditable
            />
            <DocumentElementIntroductoryText
              document={{
                introductoryText:
                  CustomerInvoiceFactory.defaultIntroductoryText(
                    templateStore.currentTemplate,
                    false
                  ),
              }}
              template={templateStore.currentTemplate}
              registerEdit={() => {}}
            />
          </Card>
        ) : (
          <LoadingIndicator />
        )}
      </div>
    </>
  )
}

export default observer(TitleAndDateStep)
