import { Button } from '@nextbusiness/infinity-ui'
import { useHistory } from 'react-router-dom'

const EditorDoneAction = () => {
  const history = useHistory()
  return (
    <div className='editor-back-navigation'>
      <Button
        onClick={() => history.goBack()}
        iconOnly='back'
        variant='quaternary'
        tooltip='Zurück zu allen Forderungen'
      />
    </div>
  )
}

export default EditorDoneAction
