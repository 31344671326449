import {
  AmountMode,
  ICustomerInvoicePosition,
  ICustomProductPosition,
  IPreset,
  ITemplate,
} from '@nextbusiness/infinity-finance-api'
import { store } from 'stores/RootStoreContext'
import ICustomerInvoice, {
  ICustomerInvoiceColumn,
  INewCustomerInvoice,
  INewCustomerInvoicePosition,
  INewCustomProductPosition,
  INewDiscountPosition,
  INewTextLinePosition,
} from '../../../model/CustomerInvoice'
import { generateRandomId } from '../../../utility/StringUtilities'
import CustomerInvoicePositionFactory from './CustomerInvoicePositionFactory'

class CustomerInvoiceFactory {
  private static readonly DEFAULT_PAYMENT_TERM = 30
  private static readonly DEFAULT_COLUMNS: ICustomerInvoiceColumn[] = [
    'quantity',
    'name',
    'singleAmount',
    'totalAmount',
  ]

  public static get defaultColumns(): ICustomerInvoiceColumn[] {
    if (store.preferencesStore.organisationPreferences?.VAT)
      return ['quantity', 'name', 'vatDebt', 'singleAmount', 'totalAmount']

    return this.DEFAULT_COLUMNS
  }

  public static get defaultAmountMode(): AmountMode {
    return (
      store.preferencesStore.organisationPreferences
        ?.customerInvoiceAmountMode ?? AmountMode.Gross
    )
  }

  public static emptyDocument = (
    usingTemplate: ITemplate,
    isQuote = false
  ): Partial<INewCustomerInvoice> => ({
    template: usingTemplate.id,
    introductoryText: CustomerInvoiceFactory.defaultIntroductoryText(
      usingTemplate,
      isQuote
    ),
    closingText: CustomerInvoiceFactory.defaultClosingText(
      usingTemplate,
      isQuote
    ),
    positions: [CustomerInvoicePositionFactory.newCustomProductPosition()],
    payableInDays: CustomerInvoiceFactory.DEFAULT_PAYMENT_TERM,
    openingDate: +new Date(),
    isDraft: true,
    isQuote,
    columns: this.defaultColumns,
    amountMode: this.defaultAmountMode,
    isQrInvoice: !isQuote,
  })

  public static fromPreset = (
    usingPreset: IPreset,
    fallbackTemplate: ITemplate,
    isQuote?: boolean
  ): Partial<INewCustomerInvoice> => {
    const taxCode = store.preferencesStore.organisationPreferences?.VAT
      ? CustomerInvoicePositionFactory.DEFAULT_TAX_CODE
      : undefined
    return {
      ...this.emptyDocument(fallbackTemplate, isQuote),
      ...usingPreset.invoice,
      columns:
        (usingPreset.invoice.columns as ICustomerInvoiceColumn[]) ??
        this.defaultColumns,
      positions: usingPreset.invoice.positions
        .map((position) => this.transformPositionToDocumentPosition(position))
        .filter((position) => position !== null)
        .map((position) => ({
          ...position,
          taxCode: (position as ICustomProductPosition).taxCode ?? taxCode,
        })) as INewCustomerInvoicePosition[],
      openingDate: +new Date(),
      isDraft: true,
      isQuote,
    }
  }

  public static defaultIntroductoryText = (
    template: ITemplate,
    isQuote: boolean
  ): string => {
    if (isQuote) {
      if (template?.defaultQuoteOpeningText)
        return template.defaultQuoteOpeningText
      return 'Vielen Dank für Ihr Interesse.\nGerne unterbreiten wir Ihnen folgendes Angebot.'
    } else {
      if (template?.defaultOpeningText) return template.defaultOpeningText
      return 'Vielen Dank für Ihr Vertrauen in unser Geschäft.\nHiermit stellen wir Ihnen folgende Posten in Rechnung:'
    }
  }

  public static defaultClosingText = (
    template: ITemplate,
    isQuote: boolean
  ): string => {
    if (isQuote) {
      if (template?.defaultQuoteClosingText)
        return template.defaultQuoteClosingText
      return `Freundliche Grüsse,\nIhr ${template.organisationName}-Team`
    } else {
      if (template?.defaultClosingText) return template.defaultClosingText
      return `Freundliche Grüsse,\nIhr ${template.organisationName}-Team`
    }
  }

  public static fromInvoice = (
    invoice: ICustomerInvoice
  ): Partial<INewCustomerInvoice> => ({
    template: invoice.template,
    introductoryText: invoice.introductoryText,
    closingText: invoice.closingText,
    isQrInvoice: invoice.isQrInvoice,
    openingDate: invoice.openingDate,
    payableInDays: invoice.payableInDays,
    positions: invoice.positions
      .map((position) => this.transformPositionToDocumentPosition(position))
      .filter((position) => position !== null) as INewCustomerInvoicePosition[],
    recipient: invoice.recipient,
    title: invoice.title,
    isDraft: invoice.isDraft,
    isQuote: invoice.isQuote,
    columns: invoice.columns ?? this.DEFAULT_COLUMNS,
    amountMode: invoice.amountMode,
    invoiceNumber: invoice.invoiceNumber,
    vatPresentationOptions: invoice.vatPresentationOptions,
  })

  private static transformPositionToDocumentPosition(
    position: ICustomerInvoicePosition
  ): INewCustomerInvoicePosition | null {
    if ('name' in position) {
      const documentPosition: INewCustomProductPosition = {
        type: 'custom-product',
        identifier: generateRandomId(),
        name: position.name,
        contraAccount: position.contraAccount,
        articleNumber: position.articleNumber,
        quantity: position.quantity,
        singleAmount: position.singleAmount,
        taxCode: position.taxCode,
        unit: position.unit,
        product: position.product,
      }
      return documentPosition
    } else if ('content' in position) {
      const documentPosition: INewTextLinePosition = {
        type: 'text-line',
        identifier: generateRandomId(),
        content: position.content,
      }
      return documentPosition
    } else if ('amount' in position) {
      const documentPosition: INewDiscountPosition = {
        type: 'discount',
        identifier: generateRandomId(),
        amount: position.amount,
      }
      return documentPosition
    } else if ('percentage' in position) {
      const documentPosition: INewDiscountPosition = {
        type: 'discount',
        identifier: generateRandomId(),
        percentage: position.percentage,
      }
      return documentPosition
    } else {
      return null
    }
  }
}

export default CustomerInvoiceFactory
