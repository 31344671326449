import FinancialHealthMetric from '../FinancialHealthMetric'
import { FinancialHealthAssessment } from '../FinancialHealthAssessment'

class MetricDegreeOfLiquidity1 extends FinancialHealthMetric {
  title = 'Liquiditätsgrad 1'
  description = 'Zahlungsfähigkeit der offenen Rechnungen mit Cash'

  getAssessment = (): FinancialHealthAssessment =>
    FinancialHealthMetric.assessmentFromStatus(
      this.context.financialSolvency?.degreeOfLiquidity1?.status
    )

  getStatusText = (): string =>
    this.context.financialSolvency?.degreeOfLiquidity1?.status ?? 'Keine Angabe'

  getActualValue = () =>
    `${(
      (this.context.financialSolvency?.degreeOfLiquidity1?.value ?? 1) * 100
    ).toFixed(1)}%`
}

export default MetricDegreeOfLiquidity1
