import { MoneyField } from '@nextbusiness/infinity-ui'
import { useExpenditureCreateContext } from './ExpenditureCreateContext'

interface ExpenditureMoneyInputFieldProps {
  errorFields: (condition: boolean) => Record<string, unknown>
}

const ExpenditureMoneyInputField = (props: ExpenditureMoneyInputFieldProps) => {
  const expenditureCreateContext = useExpenditureCreateContext()
  return (
    <>
      <div className='expenditure-input-title'>Betrag</div>
      <div className='expenditure-input-field'>
        <MoneyField
          onChange={(amount) =>
            amount && expenditureCreateContext.updateTransaction({ amount })
          }
          value={expenditureCreateContext.transaction.amount || null}
          fullWidth
          {...props.errorFields(!expenditureCreateContext.transaction.amount)}
        />
      </div>
    </>
  )
}

export default ExpenditureMoneyInputField
