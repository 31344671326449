import React from 'react'
import './FinancialHealthPercentageRow.scss'

interface FinancialHealthPercentageRowProps {
  percentage?: number
  defaultFallback?: number
  label?: string
  description?: string
}

const FinancialHealthPercentageRow = (
  props: FinancialHealthPercentageRowProps
) => {
  const actualPercentage = Math.round(
    (props.percentage ?? props.defaultFallback ?? 0) * 100
  )
  const upperBound = 1000
  const displayUpperBound = actualPercentage > upperBound

  return (
    <div className='percentage-row'>
      <span className='percentage'>
        {displayUpperBound ? `>${upperBound}` : actualPercentage}%
      </span>
      <div className='label'>
        <span className='title'>{props.label}</span>
        {props.description && (
          <span className='description'>{props.description}</span>
        )}
      </div>
    </div>
  )
}

export default FinancialHealthPercentageRow
