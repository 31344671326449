import { APIError } from '@nextbusiness/infinity-finance-api'
import { useNotificationCenter } from '@nextbusiness/infinity-ui'
import { useEffect, useState } from 'react'

const useFileDownload = (
  query: () => Promise<Blob | null>,
  options?: { fileName?: string; customErrorHandler?: (error: Error) => void }
) => {
  const notificationCenter = useNotificationCenter()
  const [isPending, setIsPending] = useState<boolean>(false)
  const [error, setError] = useState<Error>()

  useEffect(() => {
    if (error) {
      if (options?.customErrorHandler) options.customErrorHandler(error)
      else if (error instanceof APIError)
        notificationCenter.addNotification({
          variant: 'error',
          children: error.humanText('de'),
        })
    }
  }, [error])

  const download = async () => {
    setIsPending(true)
    setError(undefined)
    try {
      const blob = await query()
      if (!blob) return
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      if (options?.fileName) {
        link.download = options.fileName
      }
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      notificationCenter.addNotification({
        variant: 'success',
        children: 'Download gestartet',
      })
    } catch (error: any) {
      setError(error)
    } finally {
      setIsPending(false)
    }
  }

  useEffect(() => {
    return () => setError(undefined)
  }, [])

  return { download, isPending, error }
}

export default useFileDownload
