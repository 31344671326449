import { Flex, NonIdealState } from '@nextbusiness/infinity-ui'
import { BackendError } from 'libs/networking/Errors'

interface SolvencyErrorViewProps {
  error?: BackendError
}

const SolvencyErrorView = (props: SolvencyErrorViewProps) => (
  <Flex
    className='vendor-invoice-detail errored'
    fillContainer
    horizontalAlignment='center'
    verticalAlignment='center'
  >
    <NonIdealState title='Konnte nicht geladen werden' icon='error'>
      {props.error?.displayMessage ??
        'Beim Laden der Details für die Zahlungsfähigkeit ist ein Fehler aufgetreten. Bitte versuche es erneut.'}
    </NonIdealState>
  </Flex>
)

export default SolvencyErrorView
