import {
  Finance,
  IModifiablePreferences,
  VATCalculationMode,
} from '@nextbusiness/infinity-finance-api'
import { Dialog, Flex, useNotificationCenter } from '@nextbusiness/infinity-ui'
import CardRadioGroup from 'ledger/accounts/account-creation/CardRadioGroup'
import SubscriptionCapability from 'libs/subscription/SubscriptionCapability'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import FeatureGateButton from '../feature-gate/FeatureGateButton'

enum VATLicensingIssueSolution {
  /** The user will keep their current VAT settings. This requires an upgrade. */
  KeepSettings = 0,
  /** Switching settings to Simplified VAT. */
  ChangeToSimplifiedVAT = 1,
  /** Turning off the VAT feature altogether for this organisation. */
  DisableVAT = 2,
}

interface VATLicensingIssueAssistantProps {
  isOpen: boolean
  onResolve: () => void
}

const newPreferencesForSolution = (
  solution: VATLicensingIssueSolution
): IModifiablePreferences => {
  switch (solution) {
    case VATLicensingIssueSolution.ChangeToSimplifiedVAT:
      return { vatCalculationMode: VATCalculationMode.Simplified }
    case VATLicensingIssueSolution.DisableVAT:
      return { VAT: false }
    default:
      return {}
  }
}

const VATLicensingIssueAssistant = (props: VATLicensingIssueAssistantProps) => {
  const { preferencesStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  const [selectedSolution, setSelectedSolution] =
    useState<VATLicensingIssueSolution>()
  const [isApplyingSolution, setIsApplyingSolution] = useState<boolean>(false)

  const applySolution = async () => {
    setIsApplyingSolution(true)
    try {
      await saveOrganisationChanges()
      props.onResolve()
      notificationCenter.addNotification({
        children: 'Mehrwertsteuer-Einstellungen wurden aktualisiert.',
        variant: 'success',
      })
    } catch (error) {
      notificationCenter.addNotification({
        title: 'Die neuen Einstellungen konnten nicht gespeichert werden.',
        children: (error as Error).message,
        variant: 'error',
      })
    } finally {
      setIsApplyingSolution(false)
    }
  }

  const saveOrganisationChanges = async () => {
    if (selectedSolution === undefined) return
    if (selectedSolution === VATLicensingIssueSolution.KeepSettings)
      return document.location.reload()

    const updatedPreferences = await Finance.Organisation.setPreferences(
      newPreferencesForSolution(selectedSolution)
    )
    runInAction(() => {
      preferencesStore.organisationPreferences = updatedPreferences
    })
  }

  return (
    <Dialog
      className='licensing-assistant-dialog'
      title='Mehrwertsteuer-Einstellungen prüfen'
      isOpen={props.isOpen}
      icon='transitions-browser'
    >
      <p className='explanation'>
        Du hast in deiner Organisation effektive Mehrwertsteuer eingestellt,
        aber du benötigst Infinity Finance Pro, um dies zu nutzen.
      </p>
      <CardRadioGroup
        options={[
          {
            label: 'Effektive Abrechnung beibehalten',
            value: VATLicensingIssueSolution.KeepSettings,
            description:
              'Behalte deine MWST-Einstellungen. Infinity Finance Pro erforderlich.',
          },
          {
            label: 'Saldosteuersatz verwenden',
            value: VATLicensingIssueSolution.ChangeToSimplifiedVAT,
            description:
              'Wenn du dich bei der Steuerverwaltung für Saldosteuersatz registriert hast, kannst du diesen verwenden.',
          },
          {
            label: 'Mehrwertsteuer deaktivieren',
            value: VATLicensingIssueSolution.DisableVAT,
            description:
              'Wenn du nicht MWST-pflichtig bist, kannst du sie deaktivieren.',
          },
        ]}
        value={selectedSolution}
        onChange={setSelectedSolution}
      />
      <Flex horizontalAlignment='flex-end' className='actions'>
        <FeatureGateButton
          variant='primary'
          disabled={selectedSolution === undefined}
          alwaysEnableFeature={
            selectedSolution !== VATLicensingIssueSolution.KeepSettings
          }
          requiredCapability={SubscriptionCapability.AdvancedVAT}
          onClick={() => applySolution()}
          isLoading={isApplyingSolution}
        >
          Fortfahren
        </FeatureGateButton>
      </Flex>
    </Dialog>
  )
}

export default observer(VATLicensingIssueAssistant)
