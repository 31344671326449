import { useNotificationCenter } from '@nextbusiness/infinity-ui'
import DocumentUpload from 'invoices/vendor-invoices/DocumentUpload'
import { observer } from 'mobx-react'
import IDocument from 'model/Document'
import { useRootStore } from 'stores/RootStoreContext'

interface UploadTransactionAttachmentAreaProps {
  attachDocumentToTransaction: (document: IDocument) => void
}

const UploadTransactionAttachmentArea = (
  props: UploadTransactionAttachmentAreaProps
) => {
  const { authenticationStore } = useRootStore()
  const notificationCenter = useNotificationCenter()

  return (
    <div className='upload-attachment-area'>
      <DocumentUpload
        notificationCenter={notificationCenter}
        onDocumentUploaded={props.attachDocumentToTransaction}
        authenticationStore={authenticationStore}
      />
    </div>
  )
}

export default observer(UploadTransactionAttachmentArea)
