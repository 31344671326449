import { Text } from '@nextbusiness/infinity-ui'
import OnboardScreen from 'components/onboard-screen/OnboardScreen'
import CustomerInvoicesSetupHeroImage from '../../assets/hero-imagery/customer-invoices-setup.png'

const CustomerInvoicesNoTemplateScreen = () => (
  <OnboardScreen
    className='no-template-screen'
    image={CustomerInvoicesSetupHeroImage}
    title='Forderungen und Offerten für deine Kunden erstellen'
  >
    <Text type='paragraph'>
      Erstelle eigene Offerten und Forderungen mit QR-Zahlteil, mit denen du bei
      deinen Kunden einen professionellen Eindruck hinterlässt.
    </Text>
    <Text type='paragraph'>
      Um loszulegen, klicke auf «Erstellen», um deine erste Offerte oder
      Forderung zu erstellen.
    </Text>
  </OnboardScreen>
)

export default CustomerInvoicesNoTemplateScreen
