import { NonIdealState, Text } from '@nextbusiness/infinity-ui'
import { IconItem, IconSize } from '@nextbusiness/infinity-ui-icons'
import React from 'react'

interface DocumentQRSlipErrorMessageProps {
  icon?: IconItem
  children?: React.ReactNode
}

const DocumentQRSlipErrorMessage = (props: DocumentQRSlipErrorMessageProps) => (
  <div className='document-qr-error-message'>
    <NonIdealState icon={props.icon} iconSize={IconSize.Regular}>
      <Text type='paragraph'>{props.children}</Text>
    </NonIdealState>
  </div>
)

export default DocumentQRSlipErrorMessage
