import React from 'react'
import './RangeSlider.scss'

interface RangeSliderProps {
  min: number
  max: number
  step?: number
  value: number | undefined
  onChange: (value: number) => void
  onDoubleClick?: (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => void
}

const RangeSlider = (props: RangeSliderProps) => (
  <input
    className='ui-range-slider'
    type='range'
    min={props.min}
    max={props.max}
    step={props.step}
    value={props.value}
    onChange={(event) => props.onChange(event.target.valueAsNumber)}
    onDoubleClick={props.onDoubleClick}
  />
)

export default RangeSlider
