import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useIntercom } from 'react-use-intercom'
import { useRootStore } from 'stores/RootStoreContext'
import MagicSheetPage from '../components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from '../components/magic-sheet/MagicSheetTitleBar'
import ReportPage from '../pages/ReportPage'
import ReportingReports from './ReportingReports'
import './ReportingScreen.scss'
import ReportingFinancialHealth from './financial-health/ReportingFinancialHealth'

interface ReportingScreenProps {
  reportPage: ReportPage
}

const ReportingScreen = (props: ReportingScreenProps) => {
  const { accountStore } = useRootStore()

  const intercom = useIntercom()
  useEffect(() => {
    intercom.trackEvent('reporting-viewed')
    MixpanelAnalytics.event('Reporting viewed')
  }, [])

  return (
    <MagicSheetPage
      header={<MagicSheetTitleBar title='Auswerten' />}
      className='reporting-screen'
    >
      <MagicSheetContainer>
        <ReportingReports reportPage={props.reportPage} />
        {accountStore.currentFiscalYear && (
          <ReportingFinancialHealth reportPage={props.reportPage} />
        )}
      </MagicSheetContainer>
    </MagicSheetPage>
  )
}

export default observer(ReportingScreen)
