import { create } from 'mobx-persist'
import AccountStore from './AccountStore'
import AuthenticationStore from './AuthenticationStore'
import ContactStore from './ContactStore'
import CustomerInvoiceStore from './CustomerInvoiceStore'
import DocumentStore from './DocumentStore'
import FinancialCloseStore from './FinancialCloseStore'
import LedgerStore from './LedgerStore'

import CurrencyStore from './CurrencyStore'
import OrganisationStore from './OrganisationStore'
import PreferencesStore from './PreferencesStore'
import PresetStore from './PresetStore'
import ProductStore from './ProductStore'
import ProjectStore from './ProjectStore'
import ReportingStore from './ReportingStore'
import TemplateStore from './TemplateStore'
import TransactionStore from './TransactionStore'
import VATPeriodStore from './VATPeriodStore'
import VendorInvoiceStore from './VendorInvoiceStore'

const hydrate = create({
  storage: localStorage,
  jsonify: true,
})

const APPLICATION_STORE_PREFIX = 'finance-'

export class RootStore {
  authenticationStore: AuthenticationStore
  contactStore: ContactStore
  transactionStore: TransactionStore
  accountStore: AccountStore
  ledgerStore: LedgerStore
  vendorInvoiceStore: VendorInvoiceStore
  customerInvoiceStore: CustomerInvoiceStore
  reportingStore: ReportingStore
  currencyStore: CurrencyStore
  templateStore: TemplateStore
  financialCloseStore: FinancialCloseStore
  preferencesStore: PreferencesStore
  documentStore: DocumentStore
  organisationStore: OrganisationStore
  presetStore: PresetStore
  vatPeriodStore: VATPeriodStore
  projectStore: ProjectStore
  productStore: ProductStore

  constructor() {
    this.authenticationStore = new AuthenticationStore(this)
    this.contactStore = new ContactStore(this)
    this.transactionStore = new TransactionStore(this)
    this.accountStore = new AccountStore(this)
    this.ledgerStore = new LedgerStore(this)
    this.vendorInvoiceStore = new VendorInvoiceStore(this)
    this.customerInvoiceStore = new CustomerInvoiceStore(this)
    this.reportingStore = new ReportingStore(this)
    this.currencyStore = new CurrencyStore(this)
    this.templateStore = new TemplateStore(this)
    this.financialCloseStore = new FinancialCloseStore(this)
    this.preferencesStore = new PreferencesStore(this)
    this.documentStore = new DocumentStore(this)
    this.organisationStore = new OrganisationStore(this)
    this.presetStore = new PresetStore(this)
    this.vatPeriodStore = new VATPeriodStore(this)
    this.projectStore = new ProjectStore(this)
    this.productStore = new ProductStore(this)

    this.hydrateStores()
  }

  private async hydrateStoresWithPersistantProperties() {
    await hydrate(
      APPLICATION_STORE_PREFIX + 'authenticationStore',
      this.authenticationStore
    )
    await hydrate(APPLICATION_STORE_PREFIX + 'contactStore', this.contactStore)
    await hydrate(APPLICATION_STORE_PREFIX + 'ledgerStore', this.ledgerStore)
    await hydrate(APPLICATION_STORE_PREFIX + 'accountStore', this.accountStore)
    await hydrate(
      APPLICATION_STORE_PREFIX + 'preferencesStore',
      this.preferencesStore
    )
  }

  async hydrateStores() {
    await this.hydrateStoresWithPersistantProperties()

    this.authenticationStore.onHydrate()
    this.contactStore.onHydrate()
    this.currencyStore.onHydrate()
    this.transactionStore.onHydrate()
    this.accountStore.onHydrate()
    this.ledgerStore.onHydrate()
    this.vendorInvoiceStore.onHydrate()
    this.customerInvoiceStore.onHydrate()
    this.reportingStore.onHydrate()
    this.currencyStore.onHydrate()
    this.templateStore.onHydrate()
    this.financialCloseStore.onHydrate()
    this.documentStore.onHydrate()
    this.organisationStore.onHydrate()
    this.presetStore.onHydrate()
    this.vatPeriodStore.onHydrate()
    this.preferencesStore.onHydrate()
    this.projectStore.onHydrate()
    this.productStore.onHydrate()
  }
}

export default RootStore
