import { AccountMethod } from '@nextbusiness/infinity-finance-api'
import { MenuItemGroup } from '@nextbusiness/infinity-ui'
import FeatureUpgradeModal from 'components/billing/feature-gate/FeatureUpgradeModal'
import useSubscription from 'components/billing/subscription-context/useSubscription'
import MoreMenu from 'components/more-menu/MoreMenu'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { useLedgerContext } from 'ledger/LedgerContext'
import { useBatchEditingContext } from 'ledger/transactions/batch-editing/BatchEditingContext'
import { TransactionFilterContext } from 'ledger/transactions/filter/TransactionFilterContext'
import LedgerDisplayOptionsModal from 'ledger/transactions/options/LedgerDisplayOptionsModal'
import SubscriptionCapability from 'libs/subscription/SubscriptionCapability'
import { useContext, useState } from 'react'
import AccountUtilities from 'utility/AccountUtilities'
import EditAccountModal from './account-editing/EditAccountModal'
import { useAccountPageContext } from './AccountPageContext'
import { isDefinedAccount } from './data/AccountRules'
import SynchronizeBankAccountModal from './SynchronizeBankAccountModal'

const AccountMoreMenu = () => {
  const transactionFilterContext = useContext(TransactionFilterContext)
  const ledgerContext = useLedgerContext()

  const { currentAccount } = useAccountPageContext()
  const { setIsInMultiEdit } = useBatchEditingContext()

  const subscription = useSubscription()
  const canFilter = subscription?.hasCapability(
    SubscriptionCapability.AdvancedFiltering
  )

  const [isShowingFilterUpgradeModal, setIsShowingFilterUpgradeModal] =
    useState<boolean>(false)
  const [isShowingEditAccountModal, setIsShowingEditAccountModal] =
    useState<boolean>(false)
  const [isShowingDisplayOptionsModal, setIsShowingDisplayOptionsModal] =
    useState<boolean>(false)
  const [
    isShowingSynchronizeBankAccountModal,
    setIsShowingSynchronizeBankAccountModal,
  ] = useState<boolean>(false)

  if (!isDefinedAccount(currentAccount)) return null
  const isLiveAccounting = currentAccount.accountMethod === AccountMethod.Live

  const moreMenuActions = [
    {
      buttons: [
        {
          text: 'Auswählen',
          icon: 'bulleted-list',
          onClick: () => {
            MixpanelAnalytics.event('Batch editing activated')
            setIsInMultiEdit(true)
          },
        },
        {
          text: 'Filtern',
          icon: 'filter',
          onClick: () => {
            if (!canFilter) return setIsShowingFilterUpgradeModal(true)
            MixpanelAnalytics.event('Ledger filter activated')
            transactionFilterContext?.setIsFiltering(true)
          },
        },
        {
          text: 'Konto bearbeiten',
          icon: 'edit',
          onClick: () => setIsShowingEditAccountModal(true),
        },
        {
          text: 'Anzeigeoptionen',
          icon: 'settings',
          onClick: () => setIsShowingDisplayOptionsModal(true),
        },
      ],
    },
  ] as MenuItemGroup[]

  if (isLiveAccounting) {
    moreMenuActions[0].buttons.push({
      text: 'Bankbewegungen importieren',
      icon: 'synchronise',
      onClick: () => setIsShowingSynchronizeBankAccountModal(true),
    })
  } else if (AccountUtilities.isEligibleForLiveAccounting(currentAccount)) {
    moreMenuActions[0].buttons.push({
      text: 'Bankkonto verbinden',
      icon: 'synchronise',
      onClick: () => ledgerContext.openSetupLiveAccountingPage(currentAccount),
    })
  }

  return (
    <>
      <MoreMenu actions={moreMenuActions} />
      <FeatureUpgradeModal
        isOpen={isShowingFilterUpgradeModal}
        onDismiss={() => setIsShowingFilterUpgradeModal(false)}
        requiredCapability={SubscriptionCapability.AdvancedFiltering}
      />
      <EditAccountModal
        account={currentAccount}
        isOpen={isShowingEditAccountModal}
        closeModal={() => setIsShowingEditAccountModal(false)}
      />
      <LedgerDisplayOptionsModal
        isOpen={isShowingDisplayOptionsModal}
        onDismiss={() => setIsShowingDisplayOptionsModal(false)}
      />
      <SynchronizeBankAccountModal
        isOpen={isShowingSynchronizeBankAccountModal}
        onDismiss={() => setIsShowingSynchronizeBankAccountModal(false)}
      />
    </>
  )
}

export default AccountMoreMenu
