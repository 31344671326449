import { Flex } from '@nextbusiness/infinity-ui'
import CustomerInvoiceListItem from 'invoices/customer-invoices/regular-list/CustomerInvoiceListItem'
import PresetModal from 'invoices/presets/PresetModal'
import { ICustomerInvoicePreview } from 'model/CustomerInvoice'
import { useContext, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { usePluralise } from 'utility/hooks'
import { ContactContext } from './ContactContext'
import { FilterContext } from './ContactFilterContext'
import ContactListCreateItem from './ContactListCreateItem'
import ContactListEmptyState from './ContactListEmptyState'

interface ContactListRenderCustomerInvoicesProps {
  data: ICustomerInvoicePreview[]
  isQuote: boolean
}

const ContactListRenderCustomerInvoices = (
  props: ContactListRenderCustomerInvoicesProps
) => {
  const contact = useContext(ContactContext)
  const text = props.isQuote ? 'Neue Offerte' : 'Neue Forderung'

  const { data } = props
  const sortedInvoices = useMemo(
    () =>
      props.data.toSorted((a, b) => {
        if (a.openingDate === b.openingDate) {
          return a.title.localeCompare(b.title)
        }
        return a.openingDate < b.openingDate ? 1 : -1
      }),
    [data]
  )

  const history = useHistory()
  const [isPresetSelectionModalOpen, setIsPresetSelectionModalOpen] =
    useState(false)
  const [isNewDocumentQuote, setIsNewDocumentQuote] = useState(true)

  const singular = props.isQuote ? 'Offerte' : 'Forderung'
  const plural = props.isQuote ? 'Offerten' : 'Forderungen'
  const listType = props.isQuote ? 'quotes' : 'customerInvoices'

  const resultText = usePluralise(
    props.data.length,
    singular,
    { plural },
    'Keine'
  )
  const { isFiltering, searchValue } = useContext(FilterContext)

  const presetModal = (
    <PresetModal
      isOpen={isPresetSelectionModalOpen}
      closeModal={() => setIsPresetSelectionModalOpen(false)}
      isNewDocumentQuote={isNewDocumentQuote}
      contact={contact}
    />
  )

  if (props.data.length === 0 && !isFiltering) {
    return (
      <>
        <ContactListEmptyState
          listType={listType}
          onActionClick={() => {
            setIsNewDocumentQuote(props.isQuote)
            setIsPresetSelectionModalOpen(true)
          }}
        />
        {presetModal}
      </>
    )
  }

  return (
    <>
      <ContactListCreateItem
        onClick={() => {
          setIsNewDocumentQuote(props.isQuote)
          setIsPresetSelectionModalOpen(true)
        }}
      >
        {text}
      </ContactListCreateItem>
      {presetModal}
      {isFiltering && (
        <div className='contact-list-filtering'>{`${resultText} für «${searchValue}» gefunden`}</div>
      )}
      <Flex className='data-items' direction='vertical'>
        {sortedInvoices?.map((invoice: ICustomerInvoicePreview) => (
          <CustomerInvoiceListItem
            key={invoice.id}
            invoice={invoice}
            onClick={() => history.push(`/forderungen/detail/${invoice.id}`)}
          />
        ))}
      </Flex>
    </>
  )
}

export default ContactListRenderCustomerInvoices
