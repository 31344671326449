import {
  DatePicker,
  Dialog,
  InputField,
  MoneyField,
} from '@nextbusiness/infinity-ui'
import MoreMenu from 'components/more-menu/MoreMenu'
import VATPicker from 'components/vat/vat-picker/VATPicker'
import { useAccountPageContext } from 'ledger/accounts/AccountPageContext'
import AccountSelect from 'ledger/accounts/account-select/AccountSelect'
import ActionBar from 'ledger/accounts/header/ActionBar'
import { TransactionFilterContext } from 'ledger/transactions/filter/TransactionFilterContext'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react'
import { useContext, useEffect, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import DateUtilities from 'utility/DateUtilites'
import { useDebounce } from 'utility/hooks'
import './FilterActionBar.scss'
import { TaxCode } from '@nextbusiness/infinity-finance-api'

const FilterActionBar = () => {
  const { transactionStore } = useRootStore()

  const transactionFilterContext = useContext(TransactionFilterContext)
  const { currentAccount, loadTransactions } = useAccountPageContext()

  const [fromDate, setFromDate] = useState<Date>()
  const [toDate, setToDate] = useState<Date>()
  const [description, setDescription] = useState<string>('')
  const [contraAccountNumber, setContraAccountNumber] = useState<number>()
  const [taxCode, setTaxCode] = useState<string>()
  const [amount, setAmount] = useState<number | null>(null)

  const [isVATFilterModalOpen, setIsVATFilterModalOpen] =
    useState<boolean>(false)
  const [isAmountFilterModalOpen, setIsAmountFilterModalOpen] =
    useState<boolean>(false)

  const debouncedDescription = useDebounce(description, 300)
  const debouncedAmount = useDebounce(amount, 300)

  const maxToDate = DateTime.now().plus({ years: 2 }).toMillis()

  const reloadFilteredTransactions = () => {
    transactionStore.clearFilteredTransactionsForAccount(
      currentAccount.accountNumber
    )
    transactionFilterContext?.resetPaginationForFilteredTransactions()
    loadTransactions()
  }

  useEffect(() => {
    reloadFilteredTransactions()
  }, [
    fromDate,
    toDate,
    debouncedDescription,
    contraAccountNumber,
    taxCode,
    debouncedAmount,
  ])

  return (
    <ActionBar
      className='filter-action-bar'
      leftHandText='Filtern nach Transaktionen'
      onDone={() => {
        transactionFilterContext?.setIsFiltering(false)
        transactionStore.clearFilteredTransactionsForAccount(
          currentAccount.accountNumber
        )
        transactionFilterContext?.clearFilters()
      }}
    >
      <DatePicker
        onChange={(date) => {
          const newFromDate = date ? DateUtilities.startOfDay(date) : undefined
          setFromDate(newFromDate)
          transactionFilterContext?.updateAppliedFilters({
            from: newFromDate?.getTime() ?? 0,
            to: toDate?.getTime() ?? maxToDate,
          })
        }}
        value={fromDate}
        placeholder='Von'
      />
      <DatePicker
        onChange={(date) => {
          const newToDate = date ? DateUtilities.endOfDay(date) : undefined
          setToDate(newToDate)
          transactionFilterContext?.updateAppliedFilters({
            from: fromDate?.getTime() ?? 0,
            to: newToDate?.getTime() ?? maxToDate,
          })
        }}
        value={toDate}
        placeholder='Bis'
      />
      <InputField
        className='text-filter'
        icon='search'
        value={description}
        onChange={(description) => {
          setDescription(description)
          transactionFilterContext?.updateAppliedFilters({
            description,
          })
        }}
        placeholder='Buchungstext'
      />
      <AccountSelect
        currentAccountNumber={currentAccount.accountNumber}
        onChange={(accountNumber) => {
          setContraAccountNumber(accountNumber)
          if (accountNumber) {
            transactionFilterContext?.updateAppliedFilters({
              contraAccount: accountNumber,
            })
          } else {
            transactionFilterContext?.clearFilters(['contraAccount'])
          }
        }}
        allowAccountCreation={false}
        placeholderText='Gegenkonto'
        suggestions={[]}
      />
      <MoreMenu
        iconOnly
        showIndicator={!!taxCode || amount !== null}
        actions={[
          {
            buttons: [
              {
                text: 'Nach Mehrwertsteuer filtern',
                icon: 'tax',
                onClick: () => setIsVATFilterModalOpen(true),
              },
              {
                text: 'Nach Betrag filtern',
                icon: 'numbers-input-form',
                onClick: () => setIsAmountFilterModalOpen(true),
              },
            ],
          },
        ]}
      />
      <Dialog
        className='vat-filter-dialog'
        title='Nach Mehrwertsteuer filtern'
        isOpen={isVATFilterModalOpen}
        dismissable
        onDismiss={() => setIsVATFilterModalOpen(false)}
        actions={[
          {
            children: 'MWST-Filter entfernen',
            onClick: () => {
              setTaxCode(undefined)
              transactionFilterContext?.clearFilters(['taxCode'])
              setIsVATFilterModalOpen(false)
            },
          },
          {
            children: 'Schliessen',
            onClick: () => setIsVATFilterModalOpen(false),
          },
        ]}
      >
        <VATPicker
          taxCode={(taxCode ?? '') as TaxCode}
          onTaxCodeSelected={(taxCode) => {
            setTaxCode(taxCode)
            if (taxCode) {
              transactionFilterContext?.updateAppliedFilters({
                taxCode,
              })
            } else {
              transactionFilterContext?.clearFilters(['taxCode'])
            }
          }}
          hideNoneOption
        />
      </Dialog>
      <Dialog
        className='amount-filter-dialog'
        title='Nach Betrag filtern'
        isOpen={isAmountFilterModalOpen}
        dismissable
        onDismiss={() => setIsAmountFilterModalOpen(false)}
        actions={[
          {
            children: 'Betrag-Filter entfernen',
            onClick: () => {
              setAmount(null)
              transactionFilterContext?.clearFilters(['amount'])
              setIsAmountFilterModalOpen(false)
            },
          },
          {
            children: 'Schliessen',
            onClick: () => setIsAmountFilterModalOpen(false),
          },
        ]}
      >
        <MoneyField
          value={amount}
          onChange={(amount) => {
            setAmount(amount)
            if (amount !== null) {
              transactionFilterContext?.updateAppliedFilters({
                amount: Math.round(amount * 100),
              })
            } else {
              transactionFilterContext?.clearFilters(['amount'])
            }
          }}
        />
      </Dialog>
    </ActionBar>
  )
}

export default observer(FilterActionBar)
