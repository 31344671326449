import { AnimatePresence } from 'framer-motion'
import React from 'react'
import SplitViewSnapIndicator from './SplitViewSnapIndicator'

interface SplitViewPaneProps {
  children: React.ReactNode
  side: 'left' | 'right'
  width?: number
  showSnapIndicator: boolean
  isSnappingFromEdge: boolean
}

const SplitViewPane = (props: SplitViewPaneProps) => (
  <div
    className={`ui-split-view-pane ui-split-view-${props.side}`}
    style={{
      width: props.width !== undefined ? `${props.width}%` : undefined,
    }}
  >
    {props.children}
    <AnimatePresence>
      {props.showSnapIndicator && (
        <SplitViewSnapIndicator
          side={props.side}
          isSnappingFromEdge={props.isSnappingFromEdge}
        />
      )}
    </AnimatePresence>
  </div>
)

export default SplitViewPane
