export enum ErrorType {
  BadRequest,
  TooManyRequests,
  Conflict,
  InvalidCredentials,
  MissingRequiredFields,
  VerificationPending,
  NotFound,
  NotAllowed,
  InsufficientSubscription,
  InsufficientPermissions,
  InvalidTaxType,
  PastFiscalYearNonModifyable,
  InvalidQueryParams,
  ServerError,
  Other,
}

export class BackendError extends Error {
  type: ErrorType
  additionalData?: any

  constructor(type: ErrorType, message?: string, additionalData?: any) {
    super(message)
    this.type = type
    this.additionalData = additionalData
  }

  get displayMessage() {
    if (this.message === 'Failed to fetch')
      return 'Verbindungsfehler. Bitte überprüfe deine Netzwerkverbindung und versuche es erneut.'

    return this.message
  }

  humanText() {
    return this.displayMessage
  }
}

export const isBackendError = (error: any): error is BackendError =>
  'type' in error
