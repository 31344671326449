import { IIncomeStatement } from 'model/Report'
import React from 'react'
import { groupWithNumber, partsForGroup } from './ProfitabilityUtilities'
import ProfitabilityVisualGroup from './ProfitabilityVisualGroup'
import './ProfitabilityVisualisation.scss'

interface ProfitabilityVisualisationProps {
  incomeStatement: IIncomeStatement
}

const ProfitabilityVisualisation = (props: ProfitabilityVisualisationProps) => {
  const incomeGroup = groupWithNumber(props.incomeStatement, 3)
  const grossExpensesGroup = groupWithNumber(props.incomeStatement, 4)
  const payrollExpensesGroup = groupWithNumber(props.incomeStatement, 5)
  const operatingExpensesGroup = groupWithNumber(props.incomeStatement, 6)
  const otherGroup = props.incomeStatement.groups.find(
    (group) => group.number > 6
  )!

  const incomeParts = partsForGroup(incomeGroup, 'accounts', true)
  const grossProfitParts = partsForGroup(grossExpensesGroup, 'accounts')
  const netProfitParts = [
    ...partsForGroup(payrollExpensesGroup, 'subgroups'),
    ...partsForGroup(operatingExpensesGroup, 'subgroups'),
    ...partsForGroup(otherGroup, 'accounts'),
  ]

  const income = incomeGroup.balance
  const grossProfit = incomeGroup.balance + grossExpensesGroup.balance
  const netProfit =
    incomeGroup.balance +
    grossExpensesGroup.balance +
    payrollExpensesGroup.balance +
    operatingExpensesGroup.balance +
    otherGroup.balance

  grossProfitParts.push({ amount: grossProfit, title: 'Bruttogewinn' })
  netProfitParts.push({ amount: netProfit, title: 'Reingewinn' })

  return (
    <div className='profitability-visualisation'>
      <ProfitabilityVisualGroup
        title='Umsatz'
        parts={incomeParts}
        groupAmount={income}
        sum={income}
      />
      <ProfitabilityVisualGroup
        title='Bruttogewinn'
        parts={grossProfitParts}
        groupAmount={grossProfit}
        sum={income}
        reverse
      />
      <ProfitabilityVisualGroup
        title='Reingewinn'
        parts={netProfitParts}
        sum={income}
        groupAmount={netProfit}
        preceedingAmount={income - grossProfit}
        reverse
      />
    </div>
  )
}

export default ProfitabilityVisualisation
