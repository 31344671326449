import { APIError, ITransaction } from '@nextbusiness/infinity-finance-api'
import { Dialog, useNotificationCenter } from '@nextbusiness/infinity-ui'
import { AccountSheetContext } from 'ledger/accounts/AccountSheetContext'
import { observer } from 'mobx-react'
import { useContext, useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import { centsToCHF } from 'utility/Utilities'

interface TransactionDeleteConfirmationProps {
  transaction: ITransaction
  isOpen: boolean
  setIsOpen: (show: boolean) => void
}

const TransactionDeleteConfirmation = (
  props: TransactionDeleteConfirmationProps
) => {
  const [isDeletionInProgress, setIsDeletionInProgress] =
    useState<boolean>(false)
  const { transactionStore, accountStore } = useRootStore()

  const accountSheetContext = useContext(AccountSheetContext)
  const notificationCenter = useNotificationCenter()

  const onDelete = async () => {
    props.setIsOpen(false)
    accountSheetContext?.setTransactionsToBeDeleted([
      props.transaction.id,
      ...(props.transaction.linkedTransactions ?? []),
    ])
    try {
      await transactionStore.deleteTransactions([props.transaction])
      accountStore.loadAccounts()
    } catch (error: any) {
      const apiError = error as APIError
      notificationCenter.addNotification({
        variant: 'error',
        children: apiError.humanText('de'),
      })
    } finally {
      accountSheetContext?.setTransactionsToBeDeleted(undefined)
      setIsDeletionInProgress(false)
    }
  }

  return (
    <Dialog
      title='Transaktion unwiderruflich löschen?'
      actions={[
        {
          children: 'Abbrechen',
          onClick: () => props.setIsOpen(false),
          disabled: isDeletionInProgress,
        },
        {
          children: 'Löschen',
          variant: 'destructive',
          isLoading: isDeletionInProgress,
          onClick: onDelete,
        },
      ]}
      isOpen={props.isOpen}
      onDismiss={() => props.setIsOpen(false)}
      dismissable
    >
      Möchtest du die Transaktion "{props.transaction.description}" in der Höhe
      von {centsToCHF(props.transaction.amount)} wirklich löschen?
    </Dialog>
  )
}

export default observer(TransactionDeleteConfirmation)
