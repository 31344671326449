import { APIError } from '@nextbusiness/infinity-finance-api'
import {
  Button,
  Card,
  Flex,
  LoadingIndicator,
  NonIdealState,
} from '@nextbusiness/infinity-ui'
import { IconSize } from '@nextbusiness/infinity-ui-icons'
import classNames from 'classnames'
import { BackendError } from 'libs/networking/Errors'
import React from 'react'
import './FinancialHealthCard.scss'
import FinancialHealthCardHeader from './FinancialHealthCardHeader'

export interface FinancialHealthCardProps {
  onClick?: () => void
  title: string
  children?: React.ReactNode
  className?: string
  isLoading?: boolean
  error?: BackendError | APIError
  customLoadingState?: React.ReactNode
}

const FinancialHealthCard = (props: FinancialHealthCardProps) => {
  const extraordinaryStateApplies = !!(props.isLoading || props.error)
  const extraordinaryState = () => {
    if (props.isLoading) {
      return (
        <>
          <FinancialHealthCardHeader title={props.title} />
          <Flex
            className='financial-health-card-content'
            horizontalAlignment='center'
            direction='vertical'
          >
            {props.customLoadingState ?? (
              <Flex
                verticalAlignment='center'
                direction='vertical'
                fillContainer
                className='loading-state'
              >
                <LoadingIndicator />
              </Flex>
            )}
          </Flex>
        </>
      )
    } else if (props.error) {
      return (
        <NonIdealState
          title='Konnte nicht geladen werden'
          icon='error'
          iconSize={IconSize.Small}
          className='error-state'
        >
          {props.error instanceof APIError
            ? props.error.humanText()
            : props.error.displayMessage}
        </NonIdealState>
      )
    }
  }

  return (
    <Button
      variant='shell'
      onClick={props.onClick}
      className={classNames('financial-health-card', props.className)}
    >
      <Card elevation='low'>
        <Flex
          direction='vertical'
          style={{ alignItems: 'stretch' }}
          fillContainer
        >
          {extraordinaryStateApplies ? (
            extraordinaryState()
          ) : (
            <>
              <FinancialHealthCardHeader title={props.title} />
              <Flex
                className='financial-health-card-content loaded'
                horizontalAlignment='center'
                direction='vertical'
              >
                {props.children}
              </Flex>
            </>
          )}
        </Flex>
      </Card>
    </Button>
  )
}

export default FinancialHealthCard
