import './LinearGauge.scss'

interface LinearGaugeProps {
  value: number
  colour?: string
}

const LinearGauge = (props: LinearGaugeProps) => (
  <div className='linear-gauge'>
    <div
      className='gauge-fill'
      style={{
        width: `${Math.max(Math.min(props.value, 1), 0) * 100}%`,
        backgroundColor: props.colour ?? undefined,
      }}
    />
  </div>
)

export default LinearGauge
