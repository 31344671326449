import { ICustomerInvoicePreview } from 'model/CustomerInvoice'
import ContactListRenderCustomerInvoices from './ContactListRenderCustomerInvoices'

interface ContactCustomerInvoicesListProps {
  data: ICustomerInvoicePreview[]
}

const ContactCustomerInvoicesList = (
  props: ContactCustomerInvoicesListProps
) => {
  return <ContactListRenderCustomerInvoices isQuote={false} data={props.data} />
}

export default ContactCustomerInvoicesList
