import ICustomerInvoice from 'model/CustomerInvoice'
import SidebarGroup from '../SidebarGroup'
import DocumentSidebarNoEditMessage from './DocumentSidebarNoEditMessage'
import { DocumentSidebarPostedViewProps } from './DocumentSidebarPanelProps'
import CustomerInvoiceDuplicateAction from './actions/CustomerInvoiceDuplicateAction'
import CustomerInvoiceMarkAsPaid from './actions/CustomerInvoiceMarkAsPaid'
import CustomerInvoiceQuoteToBill from './actions/CustomerInvoiceQuoteToBill'
import CustomerInvoiceToggleAnnulmentAction from './actions/CustomerInvoiceToggleAnnulmentAction'
import CustomerInvoiceToggleDraftAction from './actions/CustomerInvoiceToggleDraftAction'
import CustomerInvoiceToggleQuoteAction from './actions/CustomerInvoiceToggleQuoteAction'
import CustomerInvoiceEmailAction from './sharing/CustomerInvoiceEmailAction'
import CustomerInvoiceShareAction from './sharing/CustomerInvoiceShareAction'

const DocumentSidebarPostedView = (props: DocumentSidebarPostedViewProps) => {
  const canEdit = props.isEditable
  const canAnnul = !props.document.isQuote && props.isExistingDocument
  return (
    <>
      <SidebarGroup className='customer-invoice-actions-panel'>
        {!props.document.isQuote ? (
          <CustomerInvoiceMarkAsPaid
            invoice={props.existingInvoice!}
            setInvoice={(invoice: ICustomerInvoice) =>
              props.setExistingInvoice(invoice)
            }
          />
        ) : (
          <CustomerInvoiceQuoteToBill {...props} />
        )}
        <CustomerInvoiceDuplicateAction {...props} />
        {canEdit && <CustomerInvoiceToggleDraftAction {...props} />}
        {canEdit && <CustomerInvoiceToggleQuoteAction {...props} />}
        <DocumentSidebarNoEditMessage {...props} />
        {canAnnul && <CustomerInvoiceToggleAnnulmentAction {...props} />}
      </SidebarGroup>
      <SidebarGroup title='Teilen' className='customer-invoice-actions-panel'>
        <CustomerInvoiceShareAction {...props} />
        <CustomerInvoiceEmailAction {...props} />
      </SidebarGroup>
    </>
  )
}

export default DocumentSidebarPostedView
