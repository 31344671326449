import {
  Finance,
  ICustomerInvoicePosition,
  IPresetInvoice,
} from '@nextbusiness/infinity-finance-api'
import {
  Dialog,
  Flex,
  InputField,
  Text,
  useNotificationCenter,
} from '@nextbusiness/infinity-ui'
import MixpanelAnalytics from 'integrations/MixpanelProductAnalytics'
import { observer } from 'mobx-react-lite'
import { INewCustomerInvoice } from 'model/CustomerInvoice'
import { useState } from 'react'
import { useRootStore } from 'stores/RootStoreContext'

interface CreatePresetModalProps {
  isOpen: boolean
  closeModal: () => void
  document: Partial<INewCustomerInvoice>
}

const CreatePresetModal = (props: CreatePresetModalProps) => {
  const { presetStore } = useRootStore()
  const notificationCenter = useNotificationCenter()
  const [title, setTitle] = useState<string>('')
  const [isSavingInProgress, setIsSavingInProgress] = useState<boolean>(false)

  const createPreset = async () => {
    setIsSavingInProgress(true)
    try {
      const invoice: IPresetInvoice = {
        positions: props.document.positions as ICustomerInvoicePosition[],
        template: props.document.template!,
        introductoryText: props.document.introductoryText ?? undefined,
        closingText: props.document.closingText ?? undefined,
        isQrInvoice: props.document.isQrInvoice,
        isQuote: props.document.isQuote,
        payableInDays: props.document.payableInDays,
        title: props.document.title,
        columns: props.document.columns,
      }
      await Finance.CustomerInvoice.createPreset({
        title: title || props.document.title || 'Unbennante Vorlage',
        invoice,
      })
      await presetStore.loadPresets()
      notificationCenter.addNotification({
        title: 'Vorlage gespeichert',
        children: 'Du kannst nun neue Dokumente mit dieser Vorlage erstellen.',
        variant: 'success',
      })
      MixpanelAnalytics.event('Preset created from customer invoice')
    } catch (error: any) {
      notificationCenter.addNotification({
        title: 'Vorlage konnte nicht gespeichert werden',
        children: error?.message,
        variant: 'error',
      })
    } finally {
      setIsSavingInProgress(false)
      props.closeModal()
    }
  }

  return (
    <Dialog
      isOpen={props.isOpen}
      className='create-preset-modal'
      title='Als Vorlage speichern'
      titleProps={{ divider: true }}
      actions={[
        {
          children: 'Abbrechen',
          disabled: isSavingInProgress,
          onClick: props.closeModal,
        },
        {
          variant: 'primary',
          children: 'Speichern',
          isLoading: isSavingInProgress,
          onClick: createPreset,
        },
      ]}
    >
      <Flex direction='vertical'>
        <Text
          className='subtitle-for-section'
          type='inline'
          style={{ fontWeight: 600 }}
        >
          Name der Vorlage
        </Text>
        <InputField
          value={title}
          onChange={(input) => setTitle(input)}
          helperText='Max. 40 Zeichen'
          /**
           * TODO: Uncomment once UI InputField supports length limits
           * maxLength={40}
           */
          placeholder={props.document.title}
        />
      </Flex>
    </Dialog>
  )
}

export default observer(CreatePresetModal)
