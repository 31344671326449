import { DocumentPositionRootProps } from './DocumentPositionRootProps'
import DocumentPositionTextUtilities from './DocumentPositionTextUtilities'

class DocumentPositionUtilities {
  public static resetPosition(props: DocumentPositionRootProps) {
    props.onChangePosition(props.position.identifier, {}, [
      'product',
      'name',
      'quantity',
      'unit',
      'taxCode',
      'contraAccount',
      'singleAmount',
    ])
  }

  public static unlinkProductFromPosition(props: DocumentPositionRootProps) {
    props.onChangePosition(props.position.identifier, {}, ['product'])
  }

  public static togglePositionFormatting(props: DocumentPositionRootProps) {
    if (props.position.type !== 'custom-product') return

    props.onChangePosition(props.position.identifier, {
      name: DocumentPositionTextUtilities.transformPositionFormatting(
        props.position.name ?? '',
        !props.position.name?.includes('\n• ')
      ),
    })
  }
}

export default DocumentPositionUtilities
