import { Finance, IProduct } from '@nextbusiness/infinity-finance-api'
import { makeObservable, observable, runInAction } from 'mobx'
import RootStore from './RootStore'
import Store from './Store'

class ProductStore extends Store {
  @observable public products: IProduct[] = []

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  public async loadAllProducts() {
    const products = await Finance.Products.products()
    runInAction(() => {
      this.products = products
    })
  }
}

export default ProductStore
