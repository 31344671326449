import { Button } from '@nextbusiness/infinity-ui'
import PayInvoiceModal from 'invoices/shared-components/PayInvoiceModal'
import useBackendQuery from 'libs/networking/BackendQuery'
import ICustomerInvoice from 'model/CustomerInvoice'
import CustomerInvoices from 'networking/CustomerInvoices'
import { useState } from 'react'

interface CustomerInvoiceMarkAsPaidProps {
  invoice: ICustomerInvoice
  setInvoice: (invoice: ICustomerInvoice) => void
}

const CustomerInvoiceMarkAsPaid = (props: CustomerInvoiceMarkAsPaidProps) => {
  const isFullyPaid = props.invoice.totalRestAmount === 0
  const [isPayInvoiceModalOpen, setIsPayInvoiceModalOpen] =
    useState<boolean>(false)
  const [, , , invalidateQuery] = useBackendQuery(
    () => CustomerInvoices.getCustomerInvoice(props.invoice.id),
    (loadedInvoice) => props.setInvoice(loadedInvoice)
  )

  return (
    <>
      {!isFullyPaid && (
        <Button
          variant='quaternary'
          iconLeft='submit-for-approval'
          className='sidebar-action'
          onClick={() => setIsPayInvoiceModalOpen(true)}
          tooltip='Verbuche einen Zahlungseingang'
        >
          Als bezahlt markieren
        </Button>
      )}
      <PayInvoiceModal
        type='customer invoice'
        invoice={props.invoice}
        isOpen={isPayInvoiceModalOpen}
        onDismiss={() => {
          setIsPayInvoiceModalOpen(false)
        }}
        onSaved={() => {
          invalidateQuery()
        }}
      />
    </>
  )
}

export default CustomerInvoiceMarkAsPaid
