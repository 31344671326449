import Lockup from 'components/text/Lockup'
import { CustomerInvoiceEditorSidebarTopActionsProps } from './CustomerInvoiceEditorSidebarTopActions'
import { motion } from 'framer-motion'

const EditorAutosaveStatus = (
  props: CustomerInvoiceEditorSidebarTopActionsProps
) => (
  <>
    {props.isAutosaving ? (
      <motion.div
        className='autosave-status'
        initial={{ opacity: 0.33 }}
        animate={{ opacity: 1 }}
        transition={{ opacity: 0.15 }}
      >
        <Lockup icon={null} subtle small>
          Wird gespeichert…
        </Lockup>
      </motion.div>
    ) : (
      <div className='autosave-status'>
        <Lockup icon='check' subtle small>
          Entwurf gespeichert
        </Lockup>
      </div>
    )}
    <div style={{ flexGrow: 1 }} />
  </>
)

export default EditorAutosaveStatus
