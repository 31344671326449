import {
  Button,
  Flex,
  InputField,
  LoadingSpinner,
  TooltipProvider,
} from '@nextbusiness/infinity-ui'
import { Icon } from '@nextbusiness/infinity-ui-icons'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDebounce } from 'utility/hooks'
import './EverythingMenu.scss'
import EverythingSearch, { EverythingSearchResult } from './EverythingSearch'

interface EverythingMenuProps {
  isOpen: boolean
  onDismiss: () => void
}

const EverythingMenu = (props: EverythingMenuProps) => {
  const ref = React.useRef<HTMLInputElement>(null)
  const history = useHistory()

  const [searchQuery, setSearchQuery] = useState<string>('')
  const debouncedSearchQuery = useDebounce(searchQuery, 100)
  const [results, setResults] = useState<EverythingSearchResult[]>([])

  const { data, isFetching } = useQuery({
    queryKey: ['resultsForQuery', debouncedSearchQuery],
    queryFn: () => EverythingSearch.resultsForQuery(debouncedSearchQuery),
    placeholderData: keepPreviousData,
  })

  const [currentlySelectedIndex, setCurrentlySelectedIndex] =
    useState<number>(0)

  useEffect(() => {
    if (props.isOpen) ref.current?.focus()
  }, [props.isOpen])

  useEffect(() => {
    setResults(data ?? [])
    setCurrentlySelectedIndex(0)
  }, [data])

  const onResultSelected = (result: EverythingSearchResult) => {
    EverythingSearch.onResultSelected(result, history)
    props.onDismiss()
  }

  useEffect(() => {
    const scrollNewIndexIntoView = (newIndex: number) => {
      document
        .querySelector(
          `.everything-menu .result-inner[data-id="${results[newIndex]?.id}"]`
        )
        ?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        })
    }

    const onKeyDown = (event: KeyboardEvent) => {
      if (results.length === 0) return
      if (event.key === 'ArrowDown' || event.key === 'Down') {
        let newIndex = currentlySelectedIndex + 1
        if (newIndex > results.length - 1) newIndex = 0
        setCurrentlySelectedIndex(newIndex)
        scrollNewIndexIntoView(newIndex)
      } else if (event.key === 'ArrowUp' || event.key === 'Up') {
        let newIndex = currentlySelectedIndex - 1
        if (newIndex < 0) newIndex = Math.max(results.length - 1, 0)
        setCurrentlySelectedIndex(newIndex)
        scrollNewIndexIntoView(newIndex)
      } else if (event.key === 'Enter') {
        const selectedResult = results[currentlySelectedIndex]
        if (selectedResult) onResultSelected(selectedResult)
      }
    }
    document.addEventListener('keydown', onKeyDown)
    return () => document.removeEventListener('keydown', onKeyDown)
  }, [currentlySelectedIndex, results])

  return (
    <TooltipProvider>
      <div className='everything-menu'>
        <Flex className='search-field' verticalAlignment='center'>
          <InputField
            className='search-field-input'
            bare
            placeholder='Suche nach Konten, Rechnungen, Kontakten, …'
            fullWidth
            value={searchQuery}
            ref={ref}
            onChange={(query) => setSearchQuery(query)}
          />
          <motion.div
            key='spinner'
            initial='hidden'
            animate={isFetching ? 'visible' : 'hidden'}
            variants={{
              hidden: { opacity: 0, scale: 0.4 },
              visible: { opacity: 1, scale: 1 },
            }}
            transition={{ duration: 0.4, ease: [0.22, 1, 0.36, 1], delay: 0.2 }}
          >
            <LoadingSpinner />
          </motion.div>
        </Flex>
        <div className='results'>
          {results.map((result, index) => (
            <motion.div
              key={result.id}
              layout
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0 }}
              transition={{ duration: 0.4, ease: [0.22, 1, 0.36, 1] }}
              className='result-item'
            >
              <Button
                className={classNames(
                  'result',
                  `result-type-${result.type}`,
                  index === currentlySelectedIndex && 'selected'
                )}
                variant='shell'
                onClick={() => onResultSelected(result)}
              >
                <div className='result-inner' data-id={result.id}>
                  <div className='result-icon'>
                    {result.icon ? (
                      <Icon icon={result.icon} size={20} />
                    ) : (
                      result.customIconView
                    )}
                  </div>
                  <div className='result-content'>
                    <div className='title'> {result.title}</div>
                    {result.details && (
                      <div className='details'>{result.details}</div>
                    )}
                  </div>
                </div>
              </Button>
            </motion.div>
          ))}
        </div>
      </div>
    </TooltipProvider>
  )
}

export default EverythingMenu
