import ResourceSelect from 'components/resource-select/ResourceSelect'
import { observer } from 'mobx-react'
import { IVendorInvoice } from 'model/VendorInvoice'
import { useEffect, useMemo } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import VendorInvoiceSelectOption from './VendorInvoiceSelectOption'

interface VendorInvoiceSelectProps {
  vendorInvoiceId?: string
  onVendorInvoiceSelected: (vendorInvoiceId: string | undefined) => void
  inputFieldProps?: Partial<
    React.ComponentProps<typeof ResourceSelect>['inputFieldProps']
  >
  filter?: {
    maxAmount?: number
    maxOpenAmount?: number
  }
}

const VendorInvoiceSelect = (props: VendorInvoiceSelectProps) => {
  const { vendorInvoiceStore } = useRootStore()
  const filter = props.filter ?? {}

  const openVendorInvoices = vendorInvoiceStore.unpaidInvoices
  const openVendorInvoicesItems = useMemo(
    () =>
      openVendorInvoices
        .filter((invoice) => {
          const isAboveMaxAmount =
            filter.maxAmount && invoice.totalAmount > filter.maxAmount
          const isAboveMaxOpenAmount =
            filter.maxOpenAmount && invoice.totalAmount < filter.maxOpenAmount

          return !isAboveMaxAmount && !isAboveMaxOpenAmount
        })
        .map((invoice) => ({
          value: invoice.id,
          item: invoice,
        })),
    [openVendorInvoices, filter]
  )
  const sections = [
    { title: 'Offene Rechnungen', options: openVendorInvoicesItems },
  ]

  useEffect(() => {
    vendorInvoiceStore.loadUnpaidInvoices()
  }, [])

  return (
    <ResourceSelect<string, IVendorInvoice>
      sections={sections}
      onChange={(invoiceId) => props.onVendorInvoiceSelected(invoiceId)}
      value={props.vendorInvoiceId}
      placeholderText={
        openVendorInvoices?.length === 0
          ? 'Keine offenen Rechnungen'
          : props.filter && openVendorInvoicesItems?.length === 0
          ? 'Keine passenden Rechnungen'
          : 'Rechnung auswählen'
      }
      searchableTextForItem={(invoice) => invoice.title}
      optionForItem={(invoice, props) => (
        <VendorInvoiceSelectOption
          vendorInvoice={invoice}
          optionProps={props}
        />
      )}
      inputFieldProps={{
        fullWidth: true,
        ...props.inputFieldProps,
      }}
    />
  )
}

export default observer(VendorInvoiceSelect)
