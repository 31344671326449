import classNames from 'classnames'
import useSubscription from 'components/billing/subscription-context/useSubscription'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import { ContactPageStackContext } from './ContactPageStackContext'
import ContactPermissionRequest from './ContactPermissionRequest'
import Contacts from './Contacts'
import './ContactsPage.scss'

interface ContactsPageProps {
  openContactDetail: (contactId: string) => void
}

const ContactsPage = (props: ContactsPageProps) => {
  const { contactStore } = useRootStore()

  const subscription = useSubscription()
  const isTrialBannerVisible = subscription?.isTrialing

  return !contactStore.hasContactsPermissions ? (
    <ContactPermissionRequest />
  ) : (
    <ContactPageStackContext.Provider
      value={{ openContact: props.openContactDetail }}
    >
      <div
        className={classNames('contacts-page', {
          'with-padded-bottom-actions': isTrialBannerVisible,
        })}
      >
        <Contacts mode='standalone' />
      </div>
    </ContactPageStackContext.Provider>
  )
}

export default observer(ContactsPage)
