import { Dialog } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React, { useEffect, useMemo, useState } from 'react'
import { useRootStore } from '../../stores/RootStoreContext'
import EverythingMenu from './EverythingMenu'

interface EverythingMenuProviderProps {
  children?: React.ReactNode
}

interface IEverythingMenuContext {
  openMenu: () => void
}

export const EverythingMenuContext =
  React.createContext<IEverythingMenuContext | null>(null)

const EverythingMenuProvider = (props: EverythingMenuProviderProps) => {
  const { accountStore } = useRootStore()
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  const isMenuAvailable = !accountStore.noFiscalYearAvailable

  const menuContext = useMemo((): IEverythingMenuContext | null => {
    if (!isMenuAvailable) return null
    return {
      openMenu: () => setIsMenuOpen(true),
    }
  }, [isMenuAvailable])

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'k' && (event.ctrlKey || event.metaKey)) {
        setIsMenuOpen(!isMenuOpen)
        event.preventDefault()
      }
    }
    document.addEventListener('keydown', onKeyDown)
    return () => document.removeEventListener('keydown', onKeyDown)
  })

  return (
    <EverythingMenuContext.Provider value={menuContext}>
      {props.children}
      {isMenuAvailable && (
        <Dialog
          className='everything-menu-dialog'
          isOpen={isMenuOpen}
          onDismiss={() => setIsMenuOpen(!isMenuOpen)}
          dismissable
        >
          <EverythingMenu
            isOpen={isMenuOpen}
            onDismiss={() => setIsMenuOpen(!isMenuOpen)}
          />
        </Dialog>
      )}
    </EverythingMenuContext.Provider>
  )
}

export default observer(EverythingMenuProvider)
