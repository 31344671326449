import { Checkbox } from '@nextbusiness/infinity-ui'
import { useTransactionCreatorContext } from '../TransactionCreatorContext'

const TransactionCreatorInboundCheckbox = () => {
  const { activeAction, utilities, setShouldCurrentAccountIncrease } =
    useTransactionCreatorContext()

  if (activeAction.currentAccountSide !== 'ask') return null

  return (
    <Checkbox
      className='inbound-transaction-checkbox'
      label='Eingehende Transaktion'
      isChecked={utilities.shouldCurrentAccountIncrease}
      onChange={() =>
        setShouldCurrentAccountIncrease(!utilities.shouldCurrentAccountIncrease)
      }
    />
  )
}

export default TransactionCreatorInboundCheckbox
