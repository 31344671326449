import { Flex } from '@nextbusiness/infinity-ui'
import classNames from 'classnames'
import Utilities from '../../utility/Utilities'
import './PaymentStatusRow.scss'

interface PaymentStatusRowProps {
  unpaidAmountInCents: number
  paidAmountInCents: number
  totalAmountInCents: number
  small?: boolean
  hideWhenFullyPaid?: boolean
}

const PaymentStatusRow = (props: PaymentStatusRowProps) => {
  const fullyPaid = props.paidAmountInCents === props.totalAmountInCents
  const hasCredit = props.totalAmountInCents < 0

  if (fullyPaid && props.hideWhenFullyPaid) return null
  if (fullyPaid) {
    return (
      <Flex
        className={
          'payment-status-row fully-paid' + (props.small ? ' small' : '')
        }
      >
        <div className='payment-status-box paid'>
          <span>
            {Utilities.centsToCHF(Math.abs(props.totalAmountInCents))}
          </span>
          <small>Vollständig {hasCredit ? 'erhalten' : 'bezahlt'}</small>
        </div>
      </Flex>
    )
  }

  return (
    <Flex className={classNames('payment-status-row', { small: props.small })}>
      <div
        className={classNames('payment-status-box unpaid', {
          credit: hasCredit,
        })}
      >
        <span>{Utilities.centsToCHF(Math.abs(props.unpaidAmountInCents))}</span>
        <small>{hasCredit ? 'Guthaben' : 'Offen'}</small>
      </div>
      <div className='payment-status-box paid'>
        <span>{Utilities.centsToCHF(Math.abs(props.paidAmountInCents))}</span>
        <small>{hasCredit ? 'Erhalten' : 'Bezahlt'}</small>
      </div>
      <div className='payment-status-box total'>
        <span>{Utilities.centsToCHF(props.totalAmountInCents)}</span>
        <small>Gesamt</small>
      </div>
    </Flex>
  )
}

export default PaymentStatusRow
