import { Flex, Text } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import {
  IIncomeStatementGroup,
  IIncomeStatementSubGroup,
  IReportAccount,
} from '../../model/Report'
import { useRootStore } from '../../stores/RootStoreContext'
import { centsToCHF } from '../../utility/Utilities'
import IncomeStatementLayer from './IncomeStatementLayer'
import { IIncomeStatementSegment } from './IncomeStatementUtil'

interface IncomeStatementSegmentProps {
  segment: IIncomeStatementSegment
}

const IncomeStatementSegment = (props: IncomeStatementSegmentProps) => {
  const { accountStore } = useRootStore()
  return (
    <>
      {props.segment.statement.map((group: IIncomeStatementGroup) => {
        const nestedLayers = group.subgroups?.map(
          (subGroup: IIncomeStatementSubGroup) => {
            if (
              (props.segment.subGroupNumberUpperLimit &&
                subGroup.number > props.segment.subGroupNumberUpperLimit) ||
              (props.segment.subGroupNumberLowerLimit &&
                subGroup.number < props.segment.subGroupNumberLowerLimit)
            )
              return null
            else
              return (
                <IncomeStatementLayer
                  key={subGroup.number}
                  name={subGroup.subgroupName}
                  balance={subGroup.balance}
                  depth={1}
                  nestedLayer={subGroup.accounts?.map(
                    (reportAccount: IReportAccount) => {
                      const account = accountStore.find(reportAccount.number)
                      const shouldInvertBalance =
                        account?.accountType === 'expense'
                      return (
                        <IncomeStatementLayer
                          key={reportAccount.number}
                          name={reportAccount.name}
                          balance={
                            reportAccount.balance *
                            (shouldInvertBalance ? -1 : 1)
                          }
                          account={account}
                          depth={2}
                        />
                      )
                    }
                  )}
                />
              )
          }
        )
        const groupSum = () =>
          group.subgroups?.reduce((sum, subGroup) => sum + subGroup.balance, 0)
        const balance =
          props.segment.statement.length === 1
            ? props.segment.balance ?? group.balance
            : group.balance
        return (
          <IncomeStatementLayer
            key={group.number}
            name={
              props.segment.groupDescriptions?.groupNumber === group.number
                ? props.segment.groupDescriptions.description
                : group.groupName
            }
            balance={props.segment.isVirtualSegment ? groupSum() : balance}
            depth={0}
            nestedLayer={
              nestedLayers.every((entry) => entry === null)
                ? undefined
                : nestedLayers
            }
          />
        )
      })}
      {!props.segment.shouldSkipSum && (
        <div className='income-statement-layer sum'>
          <Flex className='row sum'>
            <Text className='name depth-0 sum'>
              {props.segment.sumDescription}
            </Text>
            <Text className='balance depth-0 sum'>
              {centsToCHF(props.segment.sum)}
            </Text>
          </Flex>
        </div>
      )}
    </>
  )
}

export default observer(IncomeStatementSegment)
