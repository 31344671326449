import { WorkBillingMethod } from '@nextbusiness/infinity-finance-api'
import { Text } from '@nextbusiness/infinity-ui'
import CombinedPositionIcon from 'assets/invoicing-assistant-icons/combined-position-icon.svg'
import DetailedPositionsIcon from 'assets/invoicing-assistant-icons/detailed-positions-icon.svg'
import TotalsPositionsIcon from 'assets/invoicing-assistant-icons/totals-positions-icon.svg'
import CardRadioGroup from 'ledger/accounts/account-creation/CardRadioGroup'

interface WorkBillingMethodStepProps {
  workBillingMethod: WorkBillingMethod | undefined
  setWorkBillingMethod: (workBillingMethod: WorkBillingMethod) => void
}

const WorkBillingMethodStep = (props: WorkBillingMethodStepProps) => (
  <>
    <Text type='paragraph' className='invoicing-assistant-description'>
      Wie möchtest du die Arbeiten auf der Rechnung auflisten?
    </Text>
    <div>
      <CardRadioGroup
        onChange={props.setWorkBillingMethod}
        value={props.workBillingMethod}
        options={[
          {
            value: WorkBillingMethod.DetailedWithHourlyRate,
            label: 'Detailiert mit Stundenangaben',
            description:
              'Arbeiten einzeln auflisten mit Anzahl Stunden, falls eingetragen',
            iconURL: DetailedPositionsIcon,
          },
          {
            value: WorkBillingMethod.OnlyAmounts,
            label: 'Nur Gesamtbeträge',
            description: 'Arbeiten einzeln auflisten ohne Anzahl Stunden',
            iconURL: TotalsPositionsIcon,
          },
          {
            value: WorkBillingMethod.MergeAll,
            label: 'Zusammenführen',
            description: 'Arbeiten in einer Forderungsposition zusammenführen',
            iconURL: CombinedPositionIcon,
          },
        ]}
      />
    </div>
  </>
)

export default WorkBillingMethodStep
