import { NonIdealState } from '@nextbusiness/infinity-ui'
import LoadingScreen from 'base-components/layout/LoadingScreen'
import MagicSheetContainer from 'components/magic-sheet/MagicSheetContainer'
import MagicSheetPage from 'components/magic-sheet/MagicSheetPage'
import MagicSheetTitleBar from 'components/magic-sheet/MagicSheetTitleBar'
import { useMixpanelEvent } from 'integrations/useMixpanelEvent'
import useBackendQuery from 'libs/networking/BackendQuery'
import { observer } from 'mobx-react-lite'
import { IIncomeStatement } from 'model/Report'
import Reports from 'networking/Reports'
import { useEffect } from 'react'
import { useRootStore } from 'stores/RootStoreContext'
import FinancialHealthAdvancedReportingPage from '../expenses-or-income/FinancialHealthAdvancedReportingPage'
import './ProfitabilityReportPage.scss'
import ProfitabilityVisualisation from './ProfitabilityVisualisation'

const ProfitabilityReportPage = () => {
  const { authenticationStore, accountStore } = useRootStore()

  const from = accountStore.currentFiscalYear?.from
  const to = accountStore.currentFiscalYear?.to

  const [incomeStatement, isLoading, error, invalidateQuery] = useBackendQuery(
    () => {
      if (!from || !to)
        return new Promise<IIncomeStatement | undefined>(() => undefined)
      return Reports.getIncomeStatement(
        authenticationStore.organisationIdentifier,
        from,
        to
      )
    }
  )

  useEffect(() => {
    if (from && to) invalidateQuery()
  }, [accountStore.currentFiscalYear])

  useMixpanelEvent('Report viewed: Profitability')

  return (
    <MagicSheetPage
      header={<MagicSheetTitleBar title='Profitabilität' />}
      className='profitability-report-page'
    >
      <FinancialHealthAdvancedReportingPage>
        <MagicSheetContainer>
          {error ? (
            <NonIdealState title='Fehler beim Laden der Profitabilität'>
              {error.displayMessage ??
                error.message ??
                'Ein unbekannter Fehler ist aufgetreten.'}
            </NonIdealState>
          ) : isLoading || !incomeStatement ? (
            <LoadingScreen />
          ) : (
            <ProfitabilityVisualisation incomeStatement={incomeStatement} />
          )}
        </MagicSheetContainer>
      </FinancialHealthAdvancedReportingPage>
    </MagicSheetPage>
  )
}

export default observer(ProfitabilityReportPage)
