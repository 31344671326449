import { useLedgerContext } from 'ledger/LedgerContext'
import AccountSelect from 'ledger/accounts/account-select/AccountSelect'
import { useTransactionCreatorContext } from '../TransactionCreatorContext'

const TransactionCreatorContraAccount = () => {
  const { openAccountPageModally } = useLedgerContext()
  const { transaction, updateTransaction, numberKey, utilities, activeAction } =
    useTransactionCreatorContext()

  return (
    <AccountSelect
      className='account'
      key={`account-select-${numberKey}`}
      currentAccountNumber={utilities.currentAccount.accountNumber}
      suggestions={activeAction?.suggestedAccounts}
      initialAccountNumber={transaction[utilities.contraAccountSide]}
      onChange={(accountNumber?: number) =>
        updateTransaction({ [utilities.contraAccountSide]: accountNumber })
      }
      openAccountPageModally={openAccountPageModally}
      allowAccountCreation
    />
  )
}

export default TransactionCreatorContraAccount
