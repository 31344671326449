import { Flex, Text } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import { useRootStore } from 'stores/RootStoreContext'
import './InvoiceListItemDepiction.scss'

interface InvoiceListItemDepictionProps {
  contactId: string
  title: string
}

const InvoiceListItemDepiction = (props: InvoiceListItemDepictionProps) => {
  const { contactStore } = useRootStore()

  return (
    <Flex
      direction='vertical'
      horizontalAlignment='flex-start'
      className='invoice-depiction'
    >
      <Text type='inline' className='contact'>
        {contactStore.displayNameForContactWithId(props.contactId)}
      </Text>
      <Text type='inline'>{props.title}</Text>
    </Flex>
  )
}

export default observer(InvoiceListItemDepiction)
